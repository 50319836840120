import { Pipe, PipeTransform } from '@angular/core';
import { Med } from '../../models/Med.model';
import { MedRate } from '../../models/med/MedRate.model';
import { MedService } from '../../services/order/med.service';

@Pipe({ name: 'medRate' })
export class MedRatePipe implements PipeTransform {
  constructor(private _medService: MedService) {
  }

  transform(med: Med): MedRate {
    return this._medService.getRate(med);
  }
}
