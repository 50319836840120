import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
  transform(time: string): string {
    const timeArray = time.split(':');
    const hr = timeArray[0];
    const min = timeArray[1];

    const newHr = +hr < 10 ? `0${hr}` : hr;
    const newMin = +min < 10 ? `0${min}` : min;

    return `${newHr}:${newMin}`;
  }
}
