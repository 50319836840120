import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AssignedUserInterface } from "../../models/assigned-users.model";
import { LogoService } from "src/app/services/logo.service";
import { UtilService } from "src/app/services/util.service";
import { AssignedUsersService } from "../../services/assigned-users.service";

@Component({
  selector: "cp-assigned-users",
  templateUrl: "./assigned-users.component.html",
  styleUrls: ["./assigned-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedUsersComponent implements OnInit, OnDestroy {
  @Input("cpmrn") cpmrn: string = "";
  @Input("unitID") unitID: string = "";
  @Input("encounter") encounter: number = 0;
  @Input("commandCenterID") commandCenterID: string = "";
  public $unsubscribe = new Subject();
  public onHover: boolean = false;
  public isResetOnMouseout: boolean = false;
  public isError: boolean = false;
  public fromCache: boolean = false;

  public allAssignedUsers: AssignedUserInterface[] = [];

  public loading: boolean = false;

  constructor(
    private _logoService: LogoService,
    private _utilService: UtilService,
    private _assignedUsersService: AssignedUsersService
  ) {}

  ngOnDestroy(): void {
    this._assignedUsersService.destroySockets();
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  ngOnInit(): void {
    this.initializeSockets();
    this.initializeListeners();
  }

  initializeListeners() {
    this._assignedUsersService.resetCache$
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((data) => {
        const { patients, unitIds } = data;
        if (patients.includes(this.cpmrn) || unitIds.includes(this.unitID)) {
          this.resetAssigned();
        }
      });
  }

  resetAssigned(): void {
    if (this.onHover) {
      this.isResetOnMouseout = true;
      return;
    }
    this.fromCache = false;
    this.allAssignedUsers = [];
  }

  initializeSockets() {
    // open the channel
    this._assignedUsersService.initializeSockets(this.commandCenterID);
  }

  public gethospitalLogo(user: AssignedUserInterface): string {
    const logo = encodeURI(user.logo);
    return this._utilService.isValidHttpUrl(logo)
      ? logo
      : this._logoService.formLogoUrl(logo);
  }

  public onMouseEnter() {
    this.onHover = true;
    this.loadAssignedUsers();
  }

  public loadAssignedUsers() {
    if (this.fromCache) return;
    this.loading = true;
    this._assignedUsersService
      .getAssignedUsers(this.cpmrn, this.encounter)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(
        (data) => {
          this.initializeValues(data);
          this.loading = false;
          this.isError = false;
          this.fromCache = true;
        },
        (err) => {
          console.log("error", err);
          this.loading = false;
          this.isError = true;
        }
      );
  }

  initializeValues(users: AssignedUserInterface[]) {
    if (this.allAssignedUsers?.length) return;
    this.allAssignedUsers = users;
  }

  public onMouseLeave() {
    this.onHover = false;
    if (this.isResetOnMouseout) {
      this.resetAssigned();
    }
  }
}
