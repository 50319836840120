import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimezoneLabelComponent } from "./components/timezone-label/timezone-label.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [TimezoneLabelComponent],
  imports: [CommonModule, MatTooltipModule],
  exports: [TimezoneLabelComponent],
})
export class TimezoneLabelModule {}
