import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { bradenFormValidator } from "../custom-validators";

@Injectable({
  providedIn: "root",
})
export class ThompsonFormService {
  constructor() {}

  initThompsonScoreForm(): FormGroup {
    return new FormGroup(
      {
        thompsonScore: new FormControl(null),
        tone: new FormControl(null),
        conciousness: new FormControl(null),
        fits: new FormControl(null),
        posture: new FormControl(null),
        moroReflex: new FormControl(null),
        grasping: new FormControl(null),
        sucking: new FormControl(null),
        breathing: new FormControl(null),
        fontanelle: new FormControl(null),
        timestamp: new FormControl(
          new Date(new Date().setSeconds(0, 0)),
          Validators.required
        ),
      },
      {
        validators: bradenFormValidator,
      }
    );
  }
}
