import * as moment from "moment";
import { padStart, sortBy } from "lodash-es";
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormBuilder,
  FormGroup,
} from "@angular/forms";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { Observable } from "rxjs";
import { OrderFormService } from "src/app/services/order-form.service";
import { TimezoneService } from "src/app/services/timezone.service";

@Component({
  selector: "app-dose-scheduler",
  templateUrl: "./dose-scheduler.component.html",
  styleUrls: ["./dose-scheduler.component.scss"],
})
export class DoseSchedulerComponent implements OnInit, AfterViewChecked {
  startTime: Date;
  isStartNow: boolean;
  doseScheduleList = [];
  availableTimeSlots = [];
  private _tz = inject(TimezoneService);

  @Input() config;
  @Input() formType;
  @Input() selectedUrgency: string;

  private _scheduleSelected: string;
  @Input()
  set scheduleSelected(value: string) {
    this._scheduleSelected = value;
    if (value && this.form.get("startTime").enabled) {
      this.setTime();
    } else if (value) {
      this.setEditDoseScheduleList();
    } else {
      this.availableTimeSlots = [];
    }
  }

  get startNowForm() {
    return this.form.get("startNow");
  }

  get scheduleSelected() {
    return this._scheduleSelected;
  }

  get form() {
    return this.controlContainer.control;
  }

  get skipSchedule(): UntypedFormArray {
    return this.form.get("skipSchedule") as UntypedFormArray;
  }

  get scheduleSelector(): string {
    return this.form.get("scheduleSelector").value;
  }

  get urgency(): "STAT" | "ASAP" {
    return this.form.get("urgency").value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    public controlContainer: ControlContainer,
    private orderFormService: OrderFormService
  ) {}

  ngOnInit(): void {
    if (this.formType === "edit" && this.form.get("startTime").enabled) {
      //reorder case - autopopulate dose-schedule
      this.scheduleSelected = this.form.get("scheduleSelector").value;
      this.isStartNow = this.form.get("startNow").value;
      this.startTime = new Date();
      if (this.scheduleSelected) {
        this.setTime();
        this.setDoseScheduleList();
      }
    }

    this.form.get("numberOfDoses").valueChanges.subscribe((_) => {
      this.setDoseScheduleList();
    });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  setDoseScheduleList() {
    if (!this.startTime || !this.form.dirty) return;

    this.skipSchedule.clear();
    const timeStampList = this.getTimeStampList(this.startTime);
    this.updateTimeStamp(timeStampList);
  }

  setEditDoseScheduleList() {
    let startTime = this.startTime;
    if (this.form.get("startTime").disabled) {
      startTime = this.form.get("startTime.date").value;
    }

    const timeStampList = this.getTimeStampList(startTime);
    this.skipSchedule.clear();
    this.updateTimeStamp(timeStampList);
  }

  private updateTimeStamp(timeStampList): void {
    timeStampList.forEach((ts) => {
      this.skipSchedule.push(
        this.fb.group({
          timeStamp: this.fb.control(ts),
          skip: this.fb.control(false),
        })
      );
    });
  }

  getTimeStampList(startTime = null) {
    const scheduleSelector = this.scheduleSelector;
    const formType = this.formType;
    const noOfDoses = this.form.get("numberOfDoses").value;

    return this.orderFormService.generateTimeStampList({
      scheduleSelector,
      startTime,
      formType,
      doseLimit: noOfDoses,
    });
  }

  setTime() {
    if (this.startNowForm.value) {
      this.startTimeChangeHandler(new Date());
      return;
    }

    if (!this.scheduleSelected) return;

    const next = this.getTimeStampList();
    this.startTimeChangeHandler(next[0]);
  }

  startTimeChangeHandler(startTime) {
    this.startTime = startTime;
    this.cdRef.detectChanges();
    this.setDoseScheduleList();
  }

  startNowChangeHandler(startNow) {
    this.isStartNow = startNow;
    this.setTime();
  }

  getDisplayTime(ts: number) {
    const date = this._tz.transformIntoTimezoneObj(new Date(ts));
    const hours = padStart(date.hours(), 2, 0);
    const minutes = padStart(date.minutes(), 2, 0);
    const day = date.toString().substring(4, 10).trim();

    return day + ", " + hours + ":" + minutes;
  }

  checkIfSameDate(date) {
    const day = this.form.get("startTime.date").value;
    const hour = this.form.get("startTime.hour").value;
    const minute = this.form.get("startTime.minute").value;

    if (day) {
      return !(
        moment(day).isSame(moment(date), "day") &&
        moment(date).format("HH") == hour &&
        moment(date).format("mm") == minute
      );
    } else {
      return true;
    }
  }
}
