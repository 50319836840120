import * as fromvitalReducer from "./vitals.reducer";
import * as fromvitalFormReducer from "./vitals-form.reducer";
import * as fromvitalInfoReducer from "./vitals-info.reducer";
// import { AppState } from "../..";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface vitalState {
  data: fromvitalReducer.State;
  form: fromvitalFormReducer.State;
  info: fromvitalInfoReducer.State;
}

export interface State {
  vitals: vitalState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromvitalReducer.reducer,
  form: fromvitalFormReducer.reducer,
  info: fromvitalInfoReducer.reducer,
};

/**
 * SELECTOR setup
 */

/**
 * @description get the vital parent state
 */
export const getVitalDataState = createFeatureSelector<vitalState>("vitals");

/**
 * @description get the vital data state from parent
 */
export const getVitalData = createSelector(
  getVitalDataState,
  (state) => state.data
);

/**
 * @description get the vital data from vital data state
 */
export const getVitaldays = createSelector(
  getVitalData,
  // (state) => state.vitals
  fromvitalReducer.selectAllVitals
);

/**
 * @description get the vital info state
 */
export const getVitalInfo = createSelector(
  getVitalDataState,
  (state) => state.info
);

/**
 * @description get the clicked day from the vital info state
 */
export const getVitalClickedDay = createSelector(
  getVitalInfo,
  (state) => state.clickedDay
);

export const getActiveAssessment = createSelector(
  getVitalInfo,
  (state) => state.activeAssessment
);

export const canvasStatus = createSelector(
  getVitalInfo,
  (state) => state.canvasStatus
);

export const resetTime = createSelector(
  getVitalInfo,
  (state) => state.resetTimestamp
);

// export const getFormTime = createSelector(
//   getVitalData,
//   (state) => state.changedTime
// );

// export const getSubmittedData = createSelector(
//   getVitalData,
//   (state) => state.submittedData
// );

/**
 * @description Get the vital form state from the parent state
 */
export const getVitalForm = createSelector(
  getVitalDataState,
  (state) => state.form
);
