import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { AuthService } from "./../services/auth.service";
import { AuditService } from "../services/audit.service";
import * as QuicksightEmbedding from "amazon-quicksight-embedding-sdk";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user";
import { User } from "../models/user";
import { DomSanitizer } from "@angular/platform-browser";

export interface RecentDashboards {
  dashboardId: string;
  vistedDate: Date;
}
@Component({
  selector: "app-audit",
  templateUrl: "./audit.component.html",
  styleUrls: ["./audit.component.scss"],
})
export class AuditComponent implements OnInit, OnDestroy {
  public displayContent = false;
  $destroy: Subject<any> = new Subject<any>();
  faCalendarAlt = faCalendarAlt;
  dashboard: any;
  currentUser: User;
  public showDashboardFlag: boolean = false;
  dashboardsList = [];
  dashboardEmbededUrl: any = "";
  searchDashboard: string;
  searchDasboardList = [];
  matchSideNavItem: string = "dashboard";
  showData: boolean = false;
  showSpinner: boolean = true;
  getUser$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  // Uncommit while going to deploy
  userName: string;
  favoriteDashboards = [];
  recentViewDashboards = [];
  recentDashboard: RecentDashboards;
  previousView: string;
  constructor(
    private store: Store<{}>,
    private fb: UntypedFormBuilder,
    private _authSerice: AuthService
  ) {}

  ngOnInit() {
    // check if not admin
    this._authSerice.checkIfnotAudit();
    this.getUser$.pipe(takeUntil(this.$destroy)).subscribe((user) => {
      this.currentUser = user;
      this.userName = this.currentUser.email.substring(
        0,
        this.currentUser.email.search("@")
      );
    });
  }

  onSearch(value: string) {
    if (value) {
      this.matchSideNavItem = "searchList";
      this.searchDasboardList = this.dashboardsList.filter((dashboards) => {
        let item = dashboards.Name.toLowerCase().match(value.toLowerCase());
        if (item) {
          return dashboards;
        }
      });
    } else {
      this.matchSideNavItem = "dashboard";
      this.showDashboardFlag = false;
    }
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
