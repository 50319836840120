import { createAction, props } from "@ngrx/store";
import { TrendCommonReducerInterface } from "../state";

const SET_TREND_CONFIG = "[TRENDS - CONFIG] set trend config";
const RESET_TREND_CONFIG = "[TRENDS - CONFIG] reset trend config";

export const setTrendCommonConfig = createAction(
  SET_TREND_CONFIG,
  props<{ config: TrendCommonReducerInterface }>()
);

export const resetTrendConfig = createAction(RESET_TREND_CONFIG);
