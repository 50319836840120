<div class="left-panel-container">
  <div
    class="graph-label cursor-pointer"
    (mouseenter)="currHoverGraph = labelArray[0]"
    (mouseleave)="currHoverGraph = null"
    [ngClass]="{
      'graph-label--active': (selectedGraphsNames$ | async)?.includes(
        labelArray[1]
      ),
      'graph-label--hover': currHoverGraph === labelArray[0]
    }"
    *ngFor="let labelArray of labelNames"
  >
    <div class="label-text" (click)="replaceGraph(labelArray[0])">
      {{ labelArray[1] }}
    </div>
    <div class="label-add-icon">
      <ng-container *ngIf="currHoverGraph === labelArray[0]">
        <mat-icon
          (click)="addGraph(labelArray[0])"
          *ngIf="!(selectedGraphsNames$ | async)?.includes(labelArray[1])"
          >add</mat-icon
        >
      </ng-container>
      <ng-container
        *ngIf="
          (selectedGraphsNames$ | async)?.length > 1 &&
          (selectedGraphsNames$ | async)?.includes(labelArray[1])
        "
      >
        <mat-icon (click)="removeGraph(labelArray[0])">remove</mat-icon>
      </ng-container>
    </div>
  </div>
</div>
