import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { MultiSelectItem } from "../../models/general.model";

import { Subject } from "rxjs-compat";

import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-multi-select",
  template: `
    <mat-form-field class="multi-select" floatLabel="always">
      <mat-label class="dischargeLabel">{{ label }}</mat-label>
      <mat-select
        [formControl]="multiSelectInput"
        multiple
        [placeholder]="placeholder"
      >
        <mat-checkbox
          class="mat-option cp-multiselect-checkbox"
          [checked]="isAllChecked()"
          (click)="$event.stopPropagation()"
          (change)="onSelectAll($event.checked)"
        >
          <span [ngClass]="{ 'active-text': isAllChecked() }">Select All</span>
        </mat-checkbox>
        <ng-container *ngFor="let selectData of data; let i = index">
          <mat-option *ngIf="selectData" [value]="selectData.id">{{
            selectData.text
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ["./multi-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() label: string;
  @Input() placeholder: string;
  @Input() data: MultiSelectItem[];

  public multiSelectInput: UntypedFormControl;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(private fb: UntypedFormBuilder) {
    this.multiSelectInput = fb.control([null]);
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.initializeEvents();
  }

  initializeEvents() {
    this.multiSelectInput.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedItems) => {
        this.onSelection(selectedItems);
      });
  }

  onChange = (value) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    if (obj) {
      this.setmultiSelectInput(obj);
    }
  }

  setmultiSelectInput(obj: any) {
    this.multiSelectInput.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) return this.multiSelectInput.disable();

    this.multiSelectInput.enable();
  }

  /*
   * NAME: onSelection
   * PURPOSE: fires when user selects a value
   * DESCRIPTION:
   *   - basis on checked property fires a event
   *   - updates selectedValue property
   *   - updates selectedInput property
   * PARAMS:
   *   - checked:boolean - true if option is checked
   *   - item:MultiSelectItem
   * RETURNS:
   *   @fires:onSelect - if it is checked
   *   @fires:deSelect - if it is not checked
   * USED BY: template
   * CREATED DATE: 07/02/20
   * AUTHOR: Gunjit Agrawal
   */
  onSelection(items: MultiSelectItem[]): void {
    this.onTouched();
    this.onChange(items);
  }

  isAllChecked() {
    return this.multiSelectInput?.value?.length === this.data?.length;
  }

  /*
   * NAME: onSelectAll
   * PURPOSE: if select all button is clicked then all options are selected
   * DESCRIPTION:
   * PARAMS: checked:boolean - true if it is checked
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 10/02/20
   * AUTHOR: Gunjit Agrawal
   */
  onSelectAll(checked: boolean): void {
    let selectedValues = [];
    if (checked) {
      selectedValues = this.data.map((option) => option.id);
    } else {
      selectedValues = [];
    }
    this.multiSelectInput.setValue(selectedValues);
    this.onChange(selectedValues);
  }
}
