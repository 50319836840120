import { createAction, props } from '@ngrx/store';
import { FileProgressBar } from '../../../models/File.model';
import { FileSignedBE } from '../../../models/file/File.model';
import { S3FileSignedBE } from '../../../models/file/S3FileSigned.model';
import { GoogleFileSignedBE } from '../../../models/file/GoogleFileSigned.model';

export const getSignedUrlSuccess = createAction(
  '[File Api] Get Signed Url Success',
  props<{ file: FileSignedBE }>()
);

export const uploadFile = createAction(
  '[File Api] Upload File',
  props<{ url: string, file: File, id?: number }>()
);

export const uploadFileSuccess = createAction(
  '[File Api] Upload File Success',
  props<{ data: FileProgressBar }>()
);

export const saveFileProgress = createAction(
  '[File Api] Save File Progress',
  props<{ data: FileProgressBar }>()
);

export const deleteFileProgress = createAction(
  '[File Api] Delete File Progress',
  props<{ id: number }>()
);

export const updateFile = createAction(
  '[File Api] Update File',
  props<{ id: string | number, data: any }>()
);

export const updateFilesUploadedState = createAction(
  '[File Api] Update Files Uploaded',
  props<{ id: number }>()
);

export const saveFileToStore = createAction(
  '[File Api] Save File To Store',
  props<{ file: File, id: number | string }>()
);
