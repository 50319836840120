import { createReducer, on, Action } from "@ngrx/store";

import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  loading: boolean;
  error: any;
  loadType: string;
  showSuccess: boolean;
}

const initialState: State = {
  loading: false,
  error: null,
  loadType: null,
  showSuccess: false,
};

const ioFormReducer = createReducer(
  initialState,
  on(ioActions.submitForm, (state, { loadType }) => ({
    ...state,
    loading: true,
    loadType: loadType,
  })),
  on(ioActions.submitSuccess, (state) => ({
    ...initialState,
    showSuccess: true,
  })),
  on(ioActions.hideSuccess, (state) => ({
    ...initialState,
    showSuccess: false,
  })),
  on(ioActions.submitFail, (state, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return ioFormReducer(state, action);
}
