import { Action, createReducer, on } from '@ngrx/store';
import * as fromPatError from 'src/app/store/actions/patients/patient-error.action';

export interface State{
    status: number | null;
    message: string | null;
} 

const initialState: State = {
    status: null,
    message: null
}

const errorReducer = createReducer(
    initialState,
    on(
        fromPatError.patError,
        (state, { status, message }) => ({ status, message })
    )
  );

  export function reducer(state: State | undefined, action: Action) {
    return errorReducer(state, action);
  }
  
  export const getErrorStatus = (state: State) => state.status;
  export const getErrorMessage = (state: State) => state.message;