<section [ngClass]="hideVitalInput ? 'vitals-print-section' : 'vitals-section'">
  <div class="display-flex gap-2">
    <app-day-selector
      (setSelectedDay)="getSelectedDate($event)"
      (collapseEmptyRows)="getCollapseStatus($event)"
      [days]="days"
      [vitals]="vitals"
      [selectedDay]="selectedDay"
      [selectedDate]="selectedDate"
      [currPatient]="currPatient"
    ></app-day-selector>
  </div>

  <div class="vital-view-entry">
    <app-vital-view
      *ngIf="vitals?.length; else noDataFound"
      [selectedDay]="selectedDay"
      [selectedDate]="selectedDate"
      [vitals]="vitals"
      [currPatient]="currPatient"
      [showVitalTrends]="'true'"
      [collapseEmptyRows]="collapseEmptyRows"
    ></app-vital-view>

    <ng-container *ngxPermissionsOnly="['create_vital']">
      <ng-container *ngIf="!isDischargeTimeElapsed && !hideVitalInput">
        <app-vital-input
          [entryType]="'vital'"
          [currPatient]="currPatient"
        ></app-vital-input>
      </ng-container>
    </ng-container>
  </div>
</section>

<ng-template #noDataFound>
  <div class="no_data_found_block">No vitals found</div>
</ng-template>
