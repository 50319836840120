import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderFilter",
})
export class OrderFilterPipe implements PipeTransform {
  transform(orders: any, ...args: any[]): any {
    const name = args[0]?.toLowerCase(),
      route = args[1],
      protocol = args[2],
      status = args[3];

    if (name) {
      orders = orders.filter(
        (order) =>
          order.name?.toLowerCase()?.includes(name) ||
          order.pType?.toLowerCase()?.includes(name) ||
          order.airway?.toLowerCase()?.includes(name)
      );
    }

    if (route) {
      orders = orders.filter((order) => order.route === route);
    }

    if (protocol) {
      orders = orders.filter((order) => order.protocol === protocol);
    }

    if (status) {
      orders = orders.filter((order) => order.status === status);
    }

    return orders;
  }
}
