import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { PatientMonitorApiService } from "../services/patient-monitor-api.service";
import { PatientMonitorVitalService } from "../services/patient-monitor-vital.service";
import { PatientMonitorSocketService } from "../services/patient-monitor-socket.service";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../store/actions/root.actions";
import {
  PatientMonitorApiActions,
  PatientMonitorListPageActions,
  PatientMonitorVitalPageActions,
} from "../actions";

@Injectable()
export class PatientMonitorEffects {
  constructor(
    private _patientMonitorApiService: PatientMonitorApiService,
    private _patientMonitorSocketService: PatientMonitorSocketService,
    private _actions$: Actions,
    private _patientMonitorVitalService: PatientMonitorVitalService
  ) {}

  /**
   * Executes on getPatientMonitors action.
   * Whenever patient monitor list page gets load the effect gets called.
   */
  loadPatientMonitors$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMonitorListPageActions.getPatientMonitors),
      switchMap(() =>
        this._patientMonitorApiService.getPatientMonitors(null).pipe(
          map((data) =>
            PatientMonitorApiActions.addAllPatientMonitors({
              monitors: data.data,
            })
          ),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  /*
   * Executes on submitNewPatientMonitorForm action.
   * called on clicking of submit on patient monitor form. (only when creating)
   * */
  submitNewPatientMonitorForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMonitorListPageActions.submitNewPatientMonitorForm),
      switchMap(({ data }) =>
        this._patientMonitorApiService.newPatientMonitor(data).pipe(
          switchMap((apiData) => {
            return of(
              PatientMonitorApiActions.addPatientMonitor({
                monitor: apiData.data,
              }),
              displaySuccessMessage({ message: "Successfully Created" })
            );
          }),
          catchError((error: any) => {
            if (error && error.error && error.error.message) {
              return of(displayErrorMessage({ message: error.error.message }));
            }

            return of(displayErrorMessage({ message: "Server Error" }));
          })
        )
      )
    )
  );

  /*
   * Executes on submitEditPatientMonitorForm action.
   * called on clicking of submit on patient monitor form. (only when updating)
   * */
  submitEditPatientMonitorForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMonitorListPageActions.submitEditPatientMonitorForm),
      switchMap(({ data, id }) =>
        this._patientMonitorApiService.updatePatientMonitor(data, id).pipe(
          switchMap((apiData) => {
            return of(
              PatientMonitorApiActions.updatePatientMonitor({
                monitor: {
                  id,
                  changes: apiData.data,
                },
              }),
              displaySuccessMessage({ message: "Successfully Updated" })
            );
          }),
          catchError((error: any) => {
            if (error && error.error && error.error.message) {
              return of(displayErrorMessage({ message: error.error.message }));
            }

            return of(displayErrorMessage({ message: "Server Error" }));
          })
        )
      )
    )
  );

  /**
   * Executes on clickDeletePatientMonitor action.
   */
  clickDeletePatientMonitor$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMonitorListPageActions.clickDeletePatientMonitor),
      switchMap(({ id }) =>
        this._patientMonitorApiService.deletePatientMonitor(id).pipe(
          switchMap((_) => {
            return of(
              PatientMonitorApiActions.deletePatientMonitor({ id }),
              displaySuccessMessage({ message: "Successfully Deleted" })
            );
          }),
          catchError((error: any) => {
            if (error && error.error && error.error.message) {
              return of(displayErrorMessage({ message: error.error.message }));
            }

            return of(displayErrorMessage({ message: "Server Error" }));
          })
        )
      )
    )
  );

  /**
   * Executes on clickDeleteRAlertDevice action.
   */
  clickOnGetLatestData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PatientMonitorVitalPageActions.clickOnGetLatestData),
      switchMap(({ deviceId }) =>
        this._patientMonitorApiService
          .getPatientMonitorLatestData(deviceId)
          .pipe(
            switchMap((data) => {
              if (
                data.data &&
                data.data.vitals &&
                this._patientMonitorVitalService.checkIfVitalsIsEmpty(
                  data.data.vitals
                )
              ) {
                return of(
                  PatientMonitorApiActions.setLatestPatientMonitorVitals({
                    data: data.data,
                  })
                );
              } else {
                return of(
                  PatientMonitorApiActions.setLatestPatientMonitorVitals({
                    data: data.data,
                  }),
                  displaySuccessMessage({ message: "Successfully Fetched" })
                );
              }
            }),
            catchError((error: any) => {
              return of(
                displayErrorMessage({
                  message: "Server Error",
                  description: "Monitor might be offline.",
                })
              );
            })
          )
      )
    )
  );

  /**
   * Get the online status for all patient monitors
   * Passes the necessary data in socket.
   */
  getAllPatientMonitorStatus$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(PatientMonitorListPageActions.getAllPatientMonitorStatus),
        tap(({ monitors }) =>
          this._patientMonitorSocketService.emitAllDeviceStatusMessage(monitors)
        )
      ),
    { dispatch: false }
  );
}
