import { Component, Input, OnInit } from "@angular/core";
import { Action, Store, select } from "@ngrx/store";
import * as fromTrendsStore from "../../../store";
import * as fromLabsStore from "src/app/labs-scans-module/store";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  AttributeResult,
  LabTrendService,
} from "../lab-utility/lab-trend.service";

import {
  LabTrendChartConfig,
  LabTrendChartDataProvider,
  LabTrendsConfig,
} from "../lab-utility/lab-trends.config";
import { Documents } from "src/app/labs-scans-module/labs-scans/models/labs-scans";

@Component({
  selector: "cp-lab-trends-left-panel",
  templateUrl: "./lab-trends-left-panel.component.html",
  styleUrls: ["./lab-trends-left-panel.component.scss"],
})
export class LabTrendsLeftPanelComponent implements OnInit {
  constructor(
    private _trendsStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    private _labStore: Store<fromLabsStore.LabState>,
    private _labTrendService: LabTrendService
  ) {}
  currHoverGraph: string | null = null;
  public _selectedLabAttributesArray: string[];
  @Input("selectedLabAttributesArray")
  set selectedLabAttributesArray(value: string[]) {
    this._selectedLabAttributesArray = value.sort((a, b) => a.localeCompare(b));
  }

  get selectedLabAttributesArray() {
    return this._selectedLabAttributesArray;
  }

  @Input() set selectedAttribute(selectedAttribute: string) {
    this.makeSelectedAttributeGraphDataPoints(selectedAttribute);
    this.currHoverGraph = selectedAttribute;
  }
  selectedGraphs: string[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();

  public selectedLabAttributeNames$ = this._trendsStore.pipe(
    select(fromTrendsStore.getAllLabTrendsNames),
    takeUntil(this.unsubscribe$)
  );

  public allDocuments$ = this._labStore.pipe(
    select(fromLabsStore.getAllDocument),
    take(1)
  );

  ngOnInit(): void {
    this.selectedLabAttributeNames$.subscribe((data: string[]) => {
      this.selectedGraphs = data;
    });
  }

  async makeSelectedAttributeGraphDataPoints(
    selectedAttribute: string,
    options: { updateGraph: boolean } = { updateGraph: false }
  ) {
    const labDocuments = await this.allDocuments$.toPromise();
    const { updateGraph } = options;

    const attributeValues = await this.getAttributeValues(
      labDocuments,
      selectedAttribute
    );

    if (attributeValues?.length) {
      const graphConfigs = this.processAttributeData(
        attributeValues,
        selectedAttribute
      );
      const action = this.createAction(updateGraph, graphConfigs);
      this.dispatchAction(action);
    }
  }

  returnZero() {
    return 0;
  }

  async getAttributeValues(
    labDocuments: Documents[],
    selectedAttribute: string
  ) {
    return await this._labTrendService
      .findAttributeAndTimestamp(labDocuments, selectedAttribute)
      .toPromise();
  }

  createAction(updateGraph: boolean, graphConfigs: LabTrendsConfig) {
    const actionCreator = updateGraph
      ? fromTrendsStore.labTrendsActions.addNewGrowthGraph
      : fromTrendsStore.labTrendsActions.setInitialGraphAndData;
    return actionCreator({ graphConfigs });
  }

  dispatchAction(action: Action) {
    this._trendsStore.dispatch(action);
  }

  processAttributeData(
    attributeValues: AttributeResult[],
    selectedAttribute: string
  ) {
    const { reportedAt, attributes } = attributeValues[0];
    const firstReportedAt = reportedAt;
    const lastReportedAt =
      attributeValues[attributeValues.length - 1].reportedAt;

    const initialYValue = +attributes.validationRange.min || 0;
    const finalYValue = +attributes.validationRange.max || 100;

    const graphConfig = LabTrendChartConfig.create(
      firstReportedAt,
      lastReportedAt,
      initialYValue,
      finalYValue
    );

    const graphDataPoints = LabTrendChartDataProvider.create(attributeValues);
    return {
      graphName: selectedAttribute,
      graphConfig,
      graphData: graphDataPoints,
      errorRange: [attributes.errorRange.min, attributes.errorRange.max],
    };
  }

  addRemoveGraph(attributes: string) {
    return this.selectedGraphs?.includes(attributes)
      ? this.removeGraph(attributes)
      : this.makeNewGraph(attributes);
  }

  addGraph(attributes: string) {
    this.makeSelectedAttributeGraphDataPoints(attributes, {
      updateGraph: true,
    });
  }

  makeNewGraph(attributes: string) {
    this.makeSelectedAttributeGraphDataPoints(attributes);
  }

  removeGraph(attributes: string) {
    // do not remove graph if only one graph is selected
    if (this.selectedGraphs?.length <= 1) return;
    this._trendsStore.dispatch(
      fromTrendsStore.labTrendsActions.removeGrowthGraph({
        graphName: attributes,
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
