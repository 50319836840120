import { Component, Input, OnInit } from "@angular/core";
import { schedules } from "../../../form.data";

@Component({
  selector: "app-history-common-view",
  template: `
    <div class="details-modal__text">
      <div class="details-modal__text__title">Frequency:</div>
      <div class="details-modal__text__content">
        <span *ngIf="data.frequency?.fType == 'continuous'"> Continuous </span>
        <span *ngIf="data.frequency?.fType == 'once'"> Once </span>
        <span *ngIf="data.frequency?.fType == 'every'">
          Every
          <span *ngIf="data.frequency.days; else elseHours">
            {{ data.frequency?.days }} days
          </span>
          <ng-template #elseHours>
            <span
              >{{
                data.frequency?.hours ? data.frequency?.hours : 0
              }}
              hours</span
            >
            <span>{{
              data.frequency?.mins ? " " + data.frequency?.mins + " mins" : ""
            }}</span>
          </ng-template>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col details-modal__text">
        <div class="details-modal__text__title">SOS:</div>
        <div class="details-modal__text__content">
          {{ data.sos === false ? "No" : data.sos ? "Yes" : "-" }}
        </div>
      </div>

      <div class="col details-modal__text">
        <div class="details-modal__text__title">Urgency:</div>
        <div class="details-modal__text__content">
          {{ data.urgency ? data.urgency : "-" }}
        </div>
      </div>
      <div class="col details-modal__text">
        <div class="details-modal__text__title">No. of doses:</div>
        <div class="details-modal__text__content">
          {{ data.numberOfDoses ? data.numberOfDoses : "-" }}
        </div>
      </div>
    </div>

    <div class="details-modal__text">
      <div class="details-modal__text__title">SOS Reason:</div>
      <div class="details-modal__text__content">
        {{ data.sosReason ? data.sosReason : "-" }}
      </div>
    </div>
    <div class="details-modal__text">
      <div class="details-modal__text__title">Time Period:</div>
      <div class="details-modal__text__content">
        {{
          data.timePeriodDetail?.period
            ? data.timePeriodDetail?.period +
              " " +
              data.timePeriodDetail?.periodOption
            : "-"
        }}
      </div>
    </div>

    <div class="details-modal__text">
      <div class="details-modal__text__title">Schedule:</div>
      <div class="details-modal__text__content">
        {{ scheduleSelected || "-" }}
      </div>
    </div>

    <div class="details-modal__text">
      <div class="details-modal__text__title">Instructions:</div>
      <div class="details-modal__text__content">
        <pre>{{ data.instructions ? data.instructions : "-" }}</pre>
      </div>
    </div>
    <div class="details-modal__text">
      <div class="details-modal__text__title">Information:</div>
      <div class="details-modal__text__content">
        <pre>{{
          data.additionalInformation ? data.additionalInformation : "-"
        }}</pre>
      </div>
    </div>
    <div class="details-modal__text">
      <div class="details-modal__text__title">Start Time:</div>
      <div class="details-modal__text__content">
        {{ data.startTime | timezone: "hh:mm:ss A" }}
      </div>
    </div>
    <div class="details-modal__text">
      <div class="details-modal__text__title">End Time:</div>
      <div class="details-modal__text__content">
        {{ data.endTime ? (data.endTime | timezone: "hh:mm:ss A") : "-" }}
      </div>
    </div>
  `,
  styleUrls: ["../details-modal-common.scss"],
})
export class HistoryCommonViewComponent implements OnInit {
  @Input() data;

  scheduleSelected = null;

  ngOnInit() {
    if (this.data.scheduleSelector) {
      const index = this.data.scheduleSelector.split("-").length - 1;
      const keys = Object.keys(schedules);

      this.scheduleSelected = schedules[keys[index]].find(
        (schedule) => schedule.value === this.data.scheduleSelector
      ).label;
    }
  }
}
