import { createAction, props } from '@ngrx/store';
import { AcknowledgeMessageApi } from '../../models/RAlert/message.model';

export const loadRAlertMessages = createAction(
  '[RAlert List Page] Load All RAlert Messages'
);

export const clickAcknowledgeMessageBtn = createAction(
  '[RAlert List Page] Click Ralert Acknowledge Btn',
  props<{ id: string, data: AcknowledgeMessageApi }>()
);
