import { transform } from "lodash-es";
import { Injectable } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { VentForm, VentOrderFE } from "../../models/vital/vent/Vent.model";
import { VentFormTemplateService } from "./vent-form-template.service";
import { GeneralFormService } from "../../services/general-form.service";
import { OrderTime } from "../../models/OrderTime.model";

@Injectable({ providedIn: "root" })
export class VentFormService {
  constructor(
    private generalFormService: GeneralFormService,
    private ventFormTemplateService: VentFormTemplateService,
    private fb: UntypedFormBuilder
  ) {}

  private isPatientNiv(niv, ISOVentDateTime, patVentType) {
    let result = {};

    if (niv) {
      result = niv;
    }

    if (
      Object.keys(result).length === 0 ||
      ISOVentDateTime.getTime() >= niv.timestamp
    ) {
      if (patVentType === "NIV") {
        result = { value: true, timestamp: ISOVentDateTime.getTime() };
      } else {
        result = { value: false, timestamp: ISOVentDateTime.getTime() };
      }
    }

    return result;
  }

  private isPatientIntubated(intubated, ISOVentDateTime, patVentAirway) {
    let result = {};

    if (intubated) {
      result = intubated;
    }

    if (
      Object.keys(result).length === 0 ||
      ISOVentDateTime.getTime() >= intubated.timestamp
    ) {
      if (
        patVentAirway === "ETT" ||
        patVentAirway === "ETT+" ||
        patVentAirway === "ETT + T-piece"
      ) {
        result = { value: true, timestamp: ISOVentDateTime.getTime() };
      } else {
        result = { value: false, timestamp: ISOVentDateTime.getTime() };
      }
    }

    return result;
  }

  private isPatientTrach(trach, ISOVentDateTime, patVentAirway) {
    let result = {};

    if (trach) {
      result = trach;
    }

    if (
      Object.keys(result).length === 0 ||
      ISOVentDateTime.getTime() >= trach.timestamp
    ) {
      if (
        patVentAirway === "Trach" ||
        patVentAirway === "Trach+" ||
        patVentAirway === "Trach Collar" ||
        patVentAirway === "Trach + T-piece"
      ) {
        result = { value: true, timestamp: ISOVentDateTime.getTime() };
      } else {
        result = { value: false, timestamp: ISOVentDateTime.getTime() };
      }
    }

    return result;
  }

  private isPatientHFNC(hfnc, ISOVentDateTime, patVentType) {
    let result = {};

    if (hfnc) {
      result = hfnc;
    }

    if (
      Object.keys(result).length === 0 ||
      ISOVentDateTime.getTime() >= hfnc.timestamp
    ) {
      if (patVentType === "Heated High Flow") {
        result = { value: true, timestamp: ISOVentDateTime.getTime() };
      } else {
        result = { value: false, timestamp: ISOVentDateTime.getTime() };
      }
    }

    return result;
  }

  getVentPropsForSubmission(currentPatient, formValue) {
    const ISOVentDateTime = this.generalFormService.transformTime(
      formValue.patVentDate,
      formValue.patVentTime
    );

    // const admitDate = new Date(currentPatient.ICUAdmitDate);
    // const admitDateAt0000 = new Date(
    //   admitDate.getFullYear(),
    //   admitDate.getMonth(),
    //   admitDate.getDate()
    // );

    // const dayNumber = Math.ceil(
    //   (ISOVentDateTime.getTime() - admitDateAt0000.getTime()) / 86400000
    // );

    const NIV: any = this.isPatientNiv(
      currentPatient.isNIV,
      ISOVentDateTime,
      formValue.patVentType
    );
    const intubated: any = this.isPatientIntubated(
      currentPatient.isIntubated,
      ISOVentDateTime,
      formValue.patVentAirway
    );
    const trach: any = this.isPatientTrach(
      currentPatient.isTrach,
      ISOVentDateTime,
      formValue.patVentAirway
    );
    const HFNC: any = this.isPatientHFNC(
      currentPatient.isHFNC,
      ISOVentDateTime,
      formValue.patVentType
    );

    return [
      // {
      //   propName: 'daysDayNumber',
      //   value: dayNumber,
      // },
      // {
      //   propName: 'daysDate',
      //   value: ISOVentDateTime,
      // },
      // {
      //   propName: 'daysHourNumber',
      //   // value: patDataFromForm.patVentTime.hour
      //   value: ISOVentDateTime.getHours(),
      // },
      // {
      //   propName: 'daysMinuteNumber',
      //   // value: patDataFromForm.patVentTime.minute
      //   value: ISOVentDateTime.getMinutes(),
      // },
      {
        propName: "timestamp",
        value: new Date(ISOVentDateTime.setSeconds(0, 0)),
      },
      {
        propName: "daysVentAirway",
        value: formValue.patVentAirway,
      },
      {
        propName: "daysVentOralAirway",
        value: formValue.patVentOralAirway,
      },
      {
        propName: "daysVentType",
        value: formValue.patVentType,
      },
      {
        propName: "daysIntType",
        value: formValue.patIntType,
      },
      {
        propName: "daysVentMode",
        value: formValue.patVentMode,
      },
      {
        propName: "daysVentSubMode",
        value: formValue.patVentSubMode,
      },
      {
        propName: "daysVentI",
        value: formValue.patVentI,
      },
      {
        propName: "daysVentE",
        value: formValue.patVentE,
      },
      {
        propName: "daysVentMV",
        value: formValue.patVentMV,
      },
      {
        propName: "daysVentTubeSize",
        value: formValue.patVentTubeSize,
      },
      {
        propName: "daysFiO2",
        value: formValue.patVentFiO2, // FiO2 is the same as FiO2 in vitals
      },
      {
        propName: "daysVentVt",
        value: formValue.patVentVt,
      },
      {
        propName: "daysRR", // RR(total) is the same as respiratory rate in vitals
        value: formValue.patVentRRTot,
      },
      {
        propName: "daysVentRRset",
        value: formValue.patVentRRset,
      },
      {
        propName: "daysVentPplat",
        value: formValue.patVentPplat,
      },
      {
        propName: "daysVentPpeak",
        value: formValue.patVentPpeak,
      },
      {
        propName: "daysVentMAP",
        value: formValue.patVentMAP,
      },
      {
        propName: "daysVentPinsp",
        value: formValue.patVentPinsp,
      },
      {
        propName: "daysVentPsupport",
        value: formValue.patVentPsupport,
      },
      {
        propName: "daysVentPEEP",
        value: formValue.patVentPEEP,
      },
      {
        propName: "isNIV",
        value: NIV,
      },
      {
        propName: "isIntubated",
        value: intubated,
      },
      {
        propName: "isTrach",
        value: trach,
      },
      {
        propName: "isHFNC",
        value: HFNC,
      },
      {
        propName: "severity",
        value:
          formValue.patVentFiO2 > 80 || intubated.value
            ? "Unstable"
            : currentPatient.severity,
      },
      {
        propName: "daysFlowRate",
        value: formValue.patFlowRate,
      },
      {
        propName: "daysVentTube",
        value: formValue.patVentTube,
      },
      { propName: "daysEttTieLevel", value: formValue.patEttTieLevel },
    ];
  }

  public initForm(config: any, value = null): UntypedFormGroup {
    let _id = null;
    let presetName = null;
    let patVentAirway = null;
    let patVentTube = null;
    let patVentOralAirway = "none";
    let patVentType = null;
    let patIntType = null;
    let patVentMode = null;
    let patVentSubMode = null;
    let instructions = null;
    let additionalInformation = null;
    let patVentI = null;
    let patVentE = null;
    let patVentMV = null;
    let patVentTubeSize = null;
    let patVentFiO2 = null;
    let patVentVt = null;
    let patVentRRTot = null;
    let patVentRRset = null;
    let patVentPplat = null;
    let patVentPpeak = null;
    let patVentMAP = null;
    let patVentPinsp = null;
    let patVentPsupport = null;
    let patVentPEEP = null;
    let patVentDate = null;
    let patVentTime = null;
    let patFlowRate = null;
    let patEttTieLevel = null;
    let bedsideOrder = null;
    let displayAsShortcut = null;
    let protocol = null;
    let protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let defaultField = false;
    let startNow = null;

    if (value) {
      _id = value._id;
      presetName = value.presetName;
      patVentAirway = value.patVentAirway;
      patVentTube = value.patVentTube;
      patVentOralAirway = value.patVentOralAirway;
      patVentType = value.patVentType;
      patIntType = value.patIntType;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      patVentMode = value.patVentMode;
      patVentSubMode = value.patVentSubMode;
      patVentI = value.patVentI;
      patVentE = value.patVentE;
      patVentMV = value.patVentMV;
      patVentTubeSize = value.patVentTubeSize;
      patVentFiO2 = value.patVentFiO2;
      patVentVt = value.patVentVt;
      patVentRRTot = value.patVentRRTot;
      patVentRRset = value.patVentRRset;
      patVentPplat = value.patVentPplat;
      patVentPpeak = value.patVentPpeak;
      patVentMAP = value.patVentMAP;
      patVentPinsp = value.patVentPinsp;
      patVentPsupport = value.patVentPsupport;
      patVentPEEP = value.patVentPEEP;
      patVentDate = value.patVentDate;
      patVentTime = value.patVentTime;
      patFlowRate = value.patFlowRate;
      patEttTieLevel = value.ettTieLevel;
      bedsideOrder = value.bedsideOrder;
      displayAsShortcut = value.displayAsShortcut;
      protocol = value.protocol;
      defaultField = value.default || false;
      startNow = value.startNow;

      if (value.startTime) {
        startTime.date = new Date(value.startTime);
        (startTime.hour = value.startTime.hour),
          (startTime.minute = value.startTime.minute);
      }

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(new UntypedFormControl(id));
        }
      }
    }

    return this.fb.group({
      _id: this.fb.control({ value: _id, disabled: !config._id.present }),
      presetName: this.fb.control(
        { value: presetName, disabled: !config.presetName.present },
        [Validators.required]
      ),
      patVentAirway: [patVentAirway, Validators.required],
      patVentTube: [patVentTube],
      patVentOralAirway: [patVentOralAirway],
      patVentType: [patVentType, Validators.required],
      patIntType: [patIntType],
      patVentMode: [patVentMode],
      patVentSubMode: [patVentSubMode],
      patVentI: [patVentI],
      patVentE: [patVentE],
      patVentMV: [patVentMV],
      patVentTubeSize: [patVentTubeSize],
      patVentFiO2: [patVentFiO2],
      patVentVt: [patVentVt],
      patVentRRTot: [patVentRRTot],
      patVentRRset: [patVentRRset],
      patVentPplat: [patVentPplat],
      patVentPpeak: [patVentPpeak],
      patVentMAP: [patVentMAP],
      patVentPinsp: [patVentPinsp],
      patVentPsupport: [patVentPsupport],
      patVentPEEP: [patVentPEEP],
      instructions: this.fb.control(instructions, [Validators.maxLength(5000)]),
      additionalInformation: this.fb.control(additionalInformation, [
        Validators.maxLength(5000),
      ]),
      patientType: config.patientType.present
        ? this.fb.control(value?.patientType, [Validators.required])
        : this.fb.control({ value: "", disabled: true }),
      patEttTieLevel: [
        patEttTieLevel,
        [Validators.pattern(/^\d*(\.\d{1,2})?$/), Validators.max(1000)],
      ],
      patVentDate: config.defaultDate.present
        ? this.fb.control(patVentDate, [Validators.required])
        : this.fb.control({ value: "", disabled: true }),
      patVentTime: config.defaultDate.present
        ? this.fb.control(patVentTime, [Validators.required])
        : this.fb.control({ value: "", disabled: true }),
      patFlowRate: [patFlowRate],
      startTime: config.startTime.present
        ? this.fb.group({
            date: this.fb.control(startTime.date, [Validators.required]),
            hour: this.fb.control(startTime.hour, [Validators.required]),
            minute: this.fb.control(startTime.minute, [Validators.required]),
          })
        : this.fb.control({ value: "", disabled: true }),
      startNow: this.fb.control(startNow),
      bedsideOrder: this.fb.control(bedsideOrder),
      protocols:
        config.protocols && config.protocols.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
      displayAsShortcut: this.fb.control({
        value: displayAsShortcut,
        disabled: !config.displayAsShortcut.present,
      }),
      protocol: this.fb.control(protocol),
      default: this.fb.control({
        value: defaultField,
        disabled: !config.default.present,
      }),
    });
  }

  /*
   * NAME: filterAllowedValues
   * PURPOSE: set not allowed fields to null in form values.
   * DESCRIPTION:
   *   - transforms the formValue and sets the not allowed fields to null
   *   - if value is empty string (''), then it is set to null
   * PARAMS:
   *   - formValue:VentForm
   *   - allowedFields:string[]
   * RETURNS: VentForm
   * USED BY: vent-form.component.ts.submitVent()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  public filterAllowedValues(formValue, allowedFields) {
    return transform(
      formValue,
      (result, value, key) => {
        const updatedValue = value === "" ? null : value;

        if (allowedFields.indexOf(key) === -1) {
          result[key] = null;
        } else {
          result[key] = updatedValue;
        }
      },
      {}
    );
  }

  getAllowedFieldsInVent(value: VentForm) {
    let result = [
      "patVentType",
      "patVentDate",
      "patVentTime",
      "patVentAirway",
      "patVentOralAirway",
    ];

    if (value.startTime) {
      result = [...result, "startTime"];
    }

    if (value.startNow) {
      result.push("startNow");
    }

    if (value.instructions) {
      result.push("instructions");
    }

    if (value.additionalInformation) {
      result.push("additionalInformation");
    }

    if (this.ventFormTemplateService.canViewTube(value.patVentAirway)) {
      result.push("patVentTube");
    }

    if (this.ventFormTemplateService.canViewEttTieLevel(value.patVentAirway)) {
      result.push("patEttTieLevel");
    }

    if (
      this.ventFormTemplateService.canViewTubeSize(
        value.patVentAirway,
        value.patIntType
      )
    ) {
      result.push("patVentTubeSize");
    }

    if (this.ventFormTemplateService.canViewIntType(value.patVentAirway)) {
      result.push("patIntType");
    }

    if (this.ventFormTemplateService.canViewFlowRate(value.patVentType)) {
      result.push("patFlowRate");
    }

    if (this.ventFormTemplateService.canViewFio2(value.patVentType)) {
      result.push("patVentFiO2");
    }

    if (
      this.ventFormTemplateService.canViewBasicForm(
        value.patVentType,
        value.patVentAirway
      )
    ) {
      result = [
        ...result,
        "patVentMode",
        "patVentSubMode",
        "patVentVtAbove",
        "patVentPinsp",
        "patVentRRset",
        "patVentPEEP",
        "patVentFiO2",
        "patFlowRate",
        "patVentVt",
        "patVentPinsp",
        "patVentPsupport",
        "patVentPplat",
        "patVentMAP",
        "patVentMV",
        "patVentRRTot",
        "patVentPpeak",
        "patVentI",
        "patVentE",
      ];
    }

    return result;
  }

  /*
   * NAME: transformVentFormToVentOrder
   * PURPOSE: converts vent form value to vent order form
   * DESCRIPTION: returned value is stored in DB
   * PARAMS: value:VentForm - form value
   * RETURNS: VentOrderFE - value converted to order type
   * USED BY: order-submit.component.ts.onSubmit()
   * CREATED DATE: 17/02/20
   * AUTHOR: Gunjit Agrawal
   */
  transformVentFormToVentOrder(value: VentForm): VentOrderFE {
    return {
      airway: value.patVentAirway,
      tube: value.patVentTube,
      oralAirway: value.patVentOralAirway,
      ventType: value.patVentType,
      intType: value.patIntType,
      mode: value.patVentMode,
      subMode: value.patVentSubMode,
      inspiratory: value.patVentI,
      expiratory: value.patVentE,
      instructions: value.instructions,
      additionalInformation: value.additionalInformation,
      mv: value.patVentMV,
      tubeSize: value.patVentTubeSize,
      fio2: value.patVentFiO2,
      vt: value.patVentVt,
      rrtot: value.patVentRRTot,
      rrset: value.patVentRRset,
      pplat: value.patVentPplat,
      ppeak: value.patVentPpeak,
      map: value.patVentMAP,
      pinsp: value.patVentPinsp,
      psupport: value.patVentPsupport,
      peep: value.patVentPEEP,
      flowRate: value.patFlowRate,
      startTime: value.startTime,
      startNow: value.startNow,
      endTime: value.endTime,
      ettTieLevel: value.patEttTieLevel,
    };
  }
}
