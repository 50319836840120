import { createAction, props } from '@ngrx/store';

export const setSbarList = createAction(
    '[Sbar] set sbar',
    props<{sbarList: any}>()
)

export const setOneSbar = createAction(
    '[Sbar] set one sbar',
    props<{sbar: any}>()
)

export const updateSbars = createAction(
    '[Sbar] update many sbar',
    props<{sbars: any}>()
)

export const removeOneSbar = createAction(
    '[Sbar] remove one sbar',
    props<{sbar: any}>()
)

export const removeManySbar = createAction(
    '[Sbar] remove many sbar',
    props<{sbarList: any}>()
)

export const resetSbar = createAction(
    '[Sbar] reset sbar'
)