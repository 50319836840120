import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FileObj } from "../model/carousel.model";
import { ZoomPanContainerComponent } from "../zoom-pan-container/zoom-pan-container.component";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnChanges {
  @ViewChild(ZoomPanContainerComponent)
  zoomPanContainerComponent: ZoomPanContainerComponent;

  @Input() slides: FileObj[];
  @Input() zoom: boolean;
  currentSlide = 0;
  rotation: 0 | 90 | 180 | 270 | 360 = 0;
  state: string = "default";

  hoverTimer: ReturnType<typeof setTimeout>;
  isShowElements: boolean = false;
  private readonly hoverTiming = 5000;

  get slidesLength(): number {
    return this.slides.length;
  }

  get islastSlide(): boolean {
    return this.currentSlide + 1 == this.slidesLength;
  }

  get isFirstSlide(): boolean {
    return this.currentSlide == 0;
  }

  get isPdfSlide(): boolean {
    return this.slides[this.currentSlide].fileType == "pdf";
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges?.slides) {
      this.resetCarousel();
    }
  }

  resetCarousel(): void {
    this.rotation = 0;
    this.currentSlide = 0;
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  zoomInImage() {
    this.zoomPanContainerComponent.zoomInImage();
  }

  zoomOutImage() {
    this.zoomPanContainerComponent.zoomOutImage();
  }

  rotateImageClockwise() {
    if (this.rotation <= -360) this.rotation = 0;
    this.rotation -= 90;
  }

  public showElements(): void {
    clearTimeout(this.hoverTimer);
    this.isShowElements = true;
    this.hoverTimer = setTimeout(() => {
      this.isShowElements = false;
    }, this.hoverTiming);
  }
}
