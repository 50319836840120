import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";

import { specimenTypes } from "../../../form.data";
import { UtilService } from "../../../../services/util.service";
import { LabPresetBE } from "../../../../models/preset/LabPreset.model";

@Component({
  selector: "app-lab-form",
  templateUrl: "./lab-form.component.html",
  styleUrls: ["./lab-form.component.scss"],
})
export class LabFormComponent implements OnInit {
  @Input() value?: LabPresetBE;
  @Input() config: any;
  @Input() formType: string;
  @Input() showBedsideForm = true;

  public specimenTypes = specimenTypes;

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {}

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for presetName field.
   *
   * @returns {AbstractControl}
   */
  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  /**
   * Get accessor for investigation field.
   *
   * @returns {AbstractControl}
   */
  get investigation(): AbstractControl {
    return this.form.get("investigation");
  }

  /**
   * Get accessor for discipline field.
   *
   * @returns {AbstractControl}
   */
  get discipline(): AbstractControl {
    return this.form.get("discipline");
  }

  /**
   * If bedside is checked then resets home med checkbox.
   */
  onBedsideChange() {
    this.form
      .get("bedsideOrder")
      .patchValue(!!!this.form.get("bedsideOrder").value);
  }
}
