import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { LabsScansService } from "../../services/labs-scans.service";
import * as labFormActions from "../actions/lab-forms.actions";

@Injectable()
export class LabFormEffects {
  constructor(
    private actions$: Actions,
    private _labsScanService: LabsScansService,
    private _alertService: AlertService
  ) {}

  loadLabForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labFormActions.LOAD_LAB_FORMS),
      map((action: labFormActions.LoadLabForm) => action.payload),
      switchMap(({ labs }) => {
        return this._labsScanService.getLabForms(labs).pipe(
          map((code: []) => new labFormActions.LoadLabFormSuccess(code)),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Server error!",
            });
            return of(new labFormActions.LoadLabFormFail(error));
          })
        );
      })
    )
  );

  loadLabFormsDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labFormActions.LOAD_LAB_FORMS_DELETE),
      map((action: labFormActions.LoadLabFormDelete) => action.payload),
      switchMap(({ CPMRN, encounters, refId }) => {
        return this._labsScanService.removeLabs(CPMRN, encounters, refId).pipe(
          map((code: []) => new labFormActions.LoadLabFormSuccessMessage()),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Lab delete error!",
            });
            return of(new labFormActions.LoadLabFormFail(error));
          })
        );
      })
    )
  );
}
