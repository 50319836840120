<!-- <section class="alert-notification" [ngClass]="{ 'alert-success': data.type === 'Success', 'alert-danger': data.type === 'Info' }">
    <mat-card>
        <mat-card-title>{{data.type}}</mat-card-title>
        <mat-card-content>{{data.message}}</mat-card-content>
    </mat-card>
</section> -->

<mat-dialog-content
  class="iris-alert"
  [ngClass]="{
    'alert-success': data.type === 'Success',
    'alert-info': data.type === 'Info',
    'alert-error': data.type === 'Error',
    'alert-warning': data.type === 'Warning'
  }"
>
  <div>
    <div class="iris-alert__message">
      <mat-icon
        class="iris-alert__message__icon cp-mat-icon-overflow-initial"
        >{{ getAlertIcon() }}</mat-icon
      >
      <span class="iris-alert__message__type">{{ data.type }}</span>
    </div>
    <div class="iris-alert__content">
      {{ data.message }}
    </div>
  </div>
</mat-dialog-content>
