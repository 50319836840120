<div class="ralert-list">
  <div class="row ralert-list__row">
    <div class="col-2">Hospital</div>
    <div class="col-1">Unit</div>
    <div class="col-1">Bed No.</div>
    <div class="col-1">Message</div>
    <div class="col-1"></div>
    <div class="col-2">Date</div>
    <div class="col-2">Time passed</div>
    <div class="col-2">Raised by</div>
    <!-- Single column header -->
  </div>

  <div
    class="row"
    *ngIf="messages?.length === 0 && rAlertMessages?.length === 0"
  >
    <div class="col text-center">No messages to display</div>
  </div>

  <div
    class="row ralert-list__row"
    *ngFor="let message of messages"
    [class.ralert-list__row--new]="
      time?.diff(message.momentTimestamp) + 1000 < 6000
    "
    [class.ralert-list__row--pulse]="
      time?.diff(message.momentTimestamp) + 1000 > alertTime
    "
    [class.ralert-list__row--code-blue]="message.message === 'code_blue'"
  >
    <div class="col-2">
      <img
        [title]="message.hospitalName"
        src="{{ message.hospitalLogo }}"
        width="50"
        onerror="this.src='https://s3.ap-south-1.amazonaws.com/emr-public/assets/images/default.png';"
        alt="Error in loading {{ message.hospitalName }} logo"
      />
      <span class="ralert-list__text ralert-list__text--hospital">{{
        message.hospitalName
      }}</span>
    </div>
    <div class="col-1">
      {{ message.unitName }}
    </div>
    <div class="col-1">
      {{ message.bedNo || "-" }}
    </div>
    <div class="col-1">
      <span *ngIf="message.message === 'code_blue'">Code Blue</span>
      <span *ngIf="message.message === 'call'">Help</span>
    </div>
    <div class="col-1 ralert-list__btn">
      <button class="cp-btn cp-btn--code-blue" (click)="onAcknowledge(message)">
        Acknowledge
      </button>
    </div>
    <div class="col-2">
      {{ message.timestamp | date : "dd/MM/yy, hh:mm a" : "IST" }}
    </div>
    <div class="col-2">
      {{
        time?.diff(message.momentTimestamp) + 1000 | date : "H:mm:ss" : "UTC"
      }}
    </div>
    <div *ngIf="message?.raisedBy" class="col-2">
      {{ message?.raisedBy?.name }}, {{ message?.raisedBy?.role }}
    </div>
  </div>

  <!-- ADDING alert messages from AI socket -->
  <div
    class="row ralert-list__row"
    *ngFor="let message of rAlertMessages"
    [class.ralert-list__row--new]="
      time?.diff(message.momentTimestamp) + 1000 < 6000
    "
    [class.ralert-list__row--pulse]="
      time?.diff(message.momentTimestamp) + 1000 > alertTime
    "
    [class.ralert-list__row--code-blue]="message.message === 'code_blue'"
  >
    <div class="col-2">
      <img
        [title]="message.hospitalName"
        src="{{ message.hospitalLogo }}"
        width="50"
        onerror="this.src='https://s3.ap-south-1.amazonaws.com/emr-public/assets/images/default.png';"
        alt="Error in loading {{ message.hospitalName }} logo"
      />
      <span class="ralert-list__text ralert-list__text--hospital">{{
        message.hospitalName
      }}</span>
    </div>
    <div class="col-1">
      {{ message.unitName }}
    </div>
    <div class="col-1">
      {{ message.bedNo || "-" }}
    </div>
    <div class="col-1">
      <span *ngIf="message.message === 'code_blue'">Code Blue</span>
      <span *ngIf="message.message === 'call'">Help</span>
    </div>
    <div class="col-1 ralert-list__btn">
      <button
        class="cp-btn cp-btn--code-blue"
        (click)="onAcknowledgeAlert(message)"
      >
        Acknowledge
      </button>
    </div>
    <div class="col-2">
      {{ message.timestamp | date : "dd/MM/yy, hh:mm a" : "IST" }}
    </div>
    <div class="col-2">
      {{
        time?.diff(message.momentTimestamp) + 1000 | date : "H:mm:ss" : "UTC"
      }}
    </div>
    <div *ngIf="message?.raisedBy" class="col-2">
      {{ message?.raisedBy?.name }}, {{ message?.raisedBy?.role }}
    </div>
  </div>
</div>
