import { AttributeResult } from "./lab-trend.service";
import {
  GrowthGraphConfig,
  GrowthTrendsConfig,
} from "../../../models/growth-trends.model";

export class LabTrendChartConfig {
  static create(
    firstReportedAt: Date,
    lastReportedAt: Date,
    initialYValue: number,
    finalYValue: number
  ): GrowthGraphConfig {
    const xDomainRange = [firstReportedAt, lastReportedAt];
    const yDomainRange = [+initialYValue, +finalYValue];

    return {
      xAxisConfig: {
        labelName: "",
        domainRange: xDomainRange,
        ticks: 9,
        tickValues: [0, 3, 6, 9, 12, 15, 18, 21, 24],
        niceDisable: true,
      },
      yAxisConfig: {
        labelName: "",
        domainRange: yDomainRange,
        ticks: 10,
      },
    };
  }
}

export class LabTrendChartDataProvider {
  static create(attributeValues: AttributeResult[]): LabDataPoints[] {
    return attributeValues
      .filter((item) => item.attributes?.value !== "") // Filter out items with empty 'value'
      .map((item) => ({
        yValue: item.attributes?.value,
        xValue: item.reportedAt,
      }));
  }
}

export const tranformLabMaxMinData = (dataPoints: LabDataPoints[]) => {
  return dataPoints?.reduce((arr, currObj) => {
    if (!arr?.length) return [currObj];

    let firstEle = arr[0];
    if (arr?.length == 1) {
      firstEle.yValue > currObj.yValue
        ? arr.push(currObj)
        : arr.unshift(currObj);
      return arr;
    }
    let secondEle = arr[1];
    arr[0] = currObj.yValue > firstEle.yValue ? currObj : firstEle;
    arr[1] = currObj.yValue < secondEle.yValue ? currObj : secondEle;
    return arr;
  }, []);
};

export interface LabDataPoints {
  yValue: number | string;
  xValue: Date;
}

export interface LabTrendsConfig
  extends Omit<GrowthTrendsConfig, "graphName" | "graphData"> {
  graphName: string;
  graphData: LabDataPoints[];
}
