import { Pipe, PipeTransform } from "@angular/core";
import { ShiftsEnum } from "src/app/models/patient";
import { WorkSpace } from "src/app/models/workspace.model";

@Pipe({
  name: "filterWorkspace",
})
export class FilterWorkspacePipe implements PipeTransform {
  transform(
    workspace: WorkSpace[],
    shift: ShiftsEnum = ShiftsEnum.DAY
  ): WorkSpace[] {
    return workspace.filter(
      (workspace) => workspace.shift === shift || !workspace.shift
    );
  }
}
