<section class="vital-input-section">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <button mat-button [disableRipple]="true" class="label-buttton">
        <span class="label">Care plan</span>
      </button>
    </div>
    <div class="icon cp-cursor-pointer" (click)="openModal()">
      <mat-icon>add_box</mat-icon>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div>
    <app-ncp-input-form
      *ngIf="
        (ncpList$ | async)?.length > 0 || (ncpEditList$ | async).length > 0;
        else noCarePlan
      "
      [_data]="ncpList$ | async"
      [currentPatient]="currentPatient"
    ></app-ncp-input-form>
  </div>
</section>

<ng-template #noCarePlan>
  <div class="no_data_found_block">
    <div class="additional_info">
      Select diagnoses and parameters to display the care plan here
    </div>
  </div>
  <div class="date-time-row">
    <div class="content">
      <app-date-time-picker
        [isNcp]="true"
        [showTime]="true"
        [showNowButton]="true"
      ></app-date-time-picker>

      <div class="b-holder">
        <button
          (click)="clearAll()"
          mat-flat-button
          disabled
          class="button-secondary mr"
          type="button"
        >
          Clear all
        </button>
        <button
          [disabled]="true"
          mat-flat-button
          class="button-primary"
          type="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>
