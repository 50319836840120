import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { ToasterService } from "../services/toaster.service";
import * as rootActions from "src/app/store/actions/root.actions";

@Injectable()
export class ToasterEffects {
  // on logout remove all toasters
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rootActions.logout),
        tap(() => {
          this.toasterService.closeAllToasters();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private toasterService: ToasterService
  ) {}
}
