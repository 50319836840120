import { createReducer, on, Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import * as sbarActions from "src/app/store/actions/patients/sbar-data.action";
import * as rootActions from "src/app/store/actions/root.actions";

interface Sbar {
  _id: string;
  createDateTime: string;
  taskCreator: string;
  location: string;
  unitName: string;
  bedNo: string;
  CPMRN: string;
  patientName: string;
  urgency: string;
  bedsidePhysician: string;
  issues: string;
  action: string;
  comments: string;
  module: string;
  timeReviewed: string;
  bedSidePhone: string;
  isCurrentlyAdmitted: string;
  encounters: string;
  newAction: string;
  newComments: string;
  type: string;
}

export interface State extends EntityState<Sbar> {}

export function selectSbarId(a: Sbar): string {
  //In this case this would be optional since primary key is _id
  return a._id;
}

export const adapter: EntityAdapter<Sbar> = createEntityAdapter<Sbar>({
  selectId: selectSbarId,
});

export const initialState: State = adapter.getInitialState();

const sbarListReducer = createReducer(
  initialState,
  on(sbarActions.setSbarList, (state, { sbarList }) => {
    return adapter.setAll(sbarList, state);
  }),
  on(sbarActions.setOneSbar, (state, { sbar }) => {
    return adapter.upsertOne(sbar, state);
  }),
  on(sbarActions.updateSbars, (state, { sbars }) => {
    return adapter.upsertMany(sbars, state);
  }),
  on(sbarActions.removeOneSbar, (state, { sbar }) => {
    return adapter.removeOne(sbar._id, state);
  }),
  on(sbarActions.removeManySbar, (state, { sbarList }) => {
    return adapter.removeMany(sbarList, state);
  }),
  on(sbarActions.resetSbar, (state) => {
    return adapter.removeAll(state);
  })
);

const { selectAll } = adapter.getSelectors();

export const getAllSbar = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return sbarListReducer(state, action);
}
