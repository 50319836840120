<div
  class="bed-unit-popup cp-flex-container"
  [ngClass]="{
    'bed-unit-popup--tv-view': isTvView
  }"
>
  <div
    class="header"
    [ngClass]="{
      '--blue': headerColor == headerColors.BLUE,
      '--default': headerColor == headerColors.DEFAULT
    }"
  >
    <ng-content select=".heading"></ng-content>
  </div>

  <div
    class="content cp-flex-container bg-white"
    *ngIf="isContentLoaded; else noContentBlock"
  >
    <!-- Hospital - Selection  -->
    <ng-container *ngIf="displayHospitalSelection; else displayHospitalBlock">
      <div class="hospital-search">
        <div class="hosp-search-label">
          {{ hospitalLabel }}
        </div>
        <div class="search-input">
          <mat-form-field [floatLabel]="'always'">
            <mat-label> </mat-label>
            <input
              matInput
              #searchText
              type="text"
              placeholder="Search hospital"
              autocomplete="off"
              class="iris-input"
            />
          </mat-form-field>
        </div>
      </div>
      <!-- No-data (hospital)-found  -->
      <div
        class="no-hospitals"
        *ngIf="
          !(hospitals | hospFilter: searchText.value).length;
          else hospitalNamesBlock
        "
      >
        No data found
      </div>
      <ng-template #hospitalNamesBlock>
        <div class="hospitals cp-flex-container">
          <div class="hosp-header">Hospital</div>
          <div class="options">
            <div
              class="hospitalName display-flex align-items-center cp-px-2"
              (click)="selectHospital(hospital)"
              *ngFor="let hospital of hospitals | hospFilter: searchText.value"
            >
              {{ hospital?.name }}
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #displayHospitalBlock>
      <div class="display-hosp-name">
        <div class="dull-header">Hospital:</div>
        <div class="hospName">{{ hospitalName?.value }}</div>
      </div>
    </ng-template>

    <!-- Unit - Selection  -->
    <ng-container *ngIf="displayUnitSelection">
      <div class="cp-flex-container options-selector">
        <div class="unit-selection">
          <ng-container *ngIf="hospitalUnits.length; else noUnitsBlock">
            <div class="hosp-search-label">Select unit</div>
            <div class="select-unit">
              <div class="option-text" *ngFor="let unit of hospitalUnits">
                <label
                  mat-flat-button
                  popoverTrigger="hover"
                  position="top-center"
                  [ngClass]="{ active: unitName?.value == unit.name }"
                  class="secondary-checkbox display-flex center form__covid-label age-group"
                >
                  {{ unit.name }}
                  <input
                    hidden
                    type="radio"
                    (click)="selectUnit(unit)"
                    [value]="unit"
                  />
                </label>
              </div>
            </div>
          </ng-container>
          <ng-template #noUnitsBlock>
            <div class="hosp-search-label">No units found</div>
          </ng-template>
        </div>

        <!-- Bed - Selection  -->
        <ng-container *ngIf="displayBedSelection">
          <div class="bed-selection">
            <div class="hosp-search-label">Select bed</div>
            <div class="select-bed">
              <div class="option-text" *ngFor="let bedObj of unitBeds">
                <label
                  mat-flat-button
                  popoverTrigger="hover"
                  position="top-center"
                  [ngClass]="{ active: bedNo?.value?.number == bedObj.number }"
                  class="secondary-checkbox display-flex center form__covid-label age-group"
                >
                  {{ bedObj.number }}
                  <input
                    hidden
                    type="radio"
                    [formControl]="bedNo"
                    [value]="bedObj"
                  />
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Buttons -->
      <div class="submit-buttons">
        <button
          mat-flat-button
          class="button-secondary back-button"
          (click)="handleBack()"
        >
          Back
        </button>
        <button
          mat-flat-button
          class="button-primary"
          [disabled]="form.invalid"
          (click)="submit()"
        >
          {{ buttonText }}
        </button>
      </div>
    </ng-container>
  </div>
  <ng-template #noContentBlock>
    <div class="loader-box display-flex center">
      <mat-spinner [diameter]="loaderDiameter"></mat-spinner>
    </div>
  </ng-template>
</div>
