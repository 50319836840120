import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BedUnitInfoComponent } from "./bed-unit-info.component";
import { HospFilterPipeModule } from "@shared-modules/pipes/hosp-filter-pipe/hosp-filter-pipe.module";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";

@NgModule({
  declarations: [BedUnitInfoComponent],
  imports: [
    CommonModule,
    HospFilterPipeModule,
    MatInputModule,
    MatProgressSpinnerModule,
    IrisComponentsModule,
  ],
  exports: [BedUnitInfoComponent],
})
export class BedUnitInfoModule {}
