import { Action, createReducer, on } from "@ngrx/store";

import * as ncpCreateAction from "../action/ncp.action";
import { NcpList } from "../../type/ncp-data.interface";

export interface State {
  isEdit: boolean;
  loading: boolean;
  data: NcpList[];
}

const initialState: State = {
  isEdit: false,
  loading: false,
  data: [],
};

const ncpEditReducer = createReducer(
  initialState,

  on(
    ncpCreateAction.editData,

    (state, action) => {
      return { loading: false, isEdit: action.isEdit, data: action.data };
    }
  ),
  on(ncpCreateAction.resetState, () => {
    return initialState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ncpEditReducer(state, action);
}
