import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Task } from '../../../models/task/Task.model';
import { PatientSocketActions } from '../../actions/socket';
import * as documentActions from '../../actions/patient-chart/documents/documents.actions';

export interface State extends EntityState<Task> {
}

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>();

export const initialState: State = adapter.getInitialState();

const taskReducer = createReducer(
  initialState,
  on(
    documentActions.createTask,
    (state, { task }) => adapter.addOne(task, state)
  ),
  on(
    PatientSocketActions.updateTask,
    (state, { task }) => adapter.updateOne(task, state)
  ),
  on(
    documentActions.deleteVisionTask,
    (state, { id }) => adapter.removeOne(id, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const selectTaskIds = selectIds;

export const selectTaskEntities = selectEntities;

export const selectAllTasks = selectAll;

export const selectTaskTotal = selectTotal;
