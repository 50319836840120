import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class VitalInputService {
  constructor() {}
  private patientVitalsSource = new Subject<any>();
  public patientVitals$ = this.patientVitalsSource.asObservable();
  passpatientVitals(patientVitals) {
    if (patientVitals.length) {
      this.patientVitalsSource.next(patientVitals);
    }
  }
}
