import {
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export const frequencyValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const frequency = control.value;
  const fType = frequency?.fType;
  const days = frequency.days;
  const hours = frequency.hours;
  const mins = frequency.mins;
  const timeOfDay = frequency.timeOfDay;

  if (
    fType === "every" &&
    ((!days && !hours && !mins) ||
      (days == 0 && hours == 0 && mins == 0) ||
      (!days && !hours && mins == 0) ||
      (!days && hours == 0 && !mins))
  ) {
    return { invalidFrequency: true };
  }

  return null;
};
