import { Action, createReducer, on } from '@ngrx/store';

import { FileApiActions } from '../../actions/file';
import * as documentActions from '../../actions/patient-chart/documents/documents.actions';
import { resetCompleteFileState } from '../../actions/patient-chart/documents/documents.actions';

export interface State {
  filesToBeUploaded: number[];
  filesUploaded: number[];
}

export const initialState: State = {
  filesToBeUploaded: [],
  filesUploaded: []
};

const fileStatusReducer = createReducer(
  initialState,
  on(
    documentActions.updateFilesToBeUploaded,
    (state, { id }) => ({ ...state, filesToBeUploaded: [ ...state.filesToBeUploaded, id ] })
  ),
  on(
    FileApiActions.updateFilesUploadedState,
    (state, { id }) => ({ ...state, filesUploaded: [ ...state.filesUploaded, id ] })
  ),
  on(
    // currently complete state is removed, we dont want that, only remove those files which are uploaded
    resetCompleteFileState,
    (state) => ({ ...initialState })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return fileStatusReducer(state, action);
}

export const getFileStateFilesToBeUploaded = (state: State) => state.filesToBeUploaded;

export const getFileStateFilesUploaded = (state: State) => state.filesUploaded;
