import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ventTube' })
export class VentTubePipe implements PipeTransform {

  /**
   * Reformats tube to a proper string to be used in template
   *
   * @param {string} tube
   * @returns {string} - reformated tube
   */
  transform(tube: string): string {
    if (tube === 'neonate') {
      return 'Neonate (1)';
    } else if (tube === 'infant') {
      return 'Infant (1½)';
    } else if (tube === 'smPediatric') {
      return 'Small Pediatric (2)';
    } else if (tube === 'lgPediatric') {
      return 'Large Pediatric (2½)';
    } else if (tube === 'smAdult') {
      return 'Small Adult (3)';
    } else if (tube === 'mdAdult') {
      return 'Medium Adult (4)';
    } else if (tube === 'lgAdult') {
      return 'Large Adult (5)';
    } else if (tube === 'other') {
      return 'Other';
    }

    return '';
  }
}
