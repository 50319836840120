import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalSearchService {
  public apiUrl = environment.apiUrl; 

  constructor(private http: HttpClient) {}

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getAdmittedPatients(data: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}patients/getAdmitted`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }
}
