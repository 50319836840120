export const environment = {
    production: false,
    apiUrl: "https://demo-test.cloudphysicianworld.org/api/",
    devResUrl: "https://demo-test.cloudphysicianworld.org/api/",
    vitalsUrl: "https://demo-test.cloudphysicianworld.org/api/",
    ptzApiUrl: "https://demo-test.cloudphysicianworld.org/api/",
    socketUrl: "https://demo-test.cloudphysicianworld.org/",
    aiSocketUrl: "https://demo-test.cloudphysicianworld.org/api_ai/",
    billingUrl: "https://demo-test.cloudphysicianworld.org/api/",
    recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
    passwordSecretKey: "CloudPhysician",
    wikiDomain: "api-public.cloudphysicianworld.org",
    s3region: "ap-south-1",
    socketPath: "/api/CP-EMR",
    s3bucket: "emr-dev-public",
    POSTHOG_KEY: "phc_3q6TfycTfxufk9CFMXjrFAwj9tBQKB30WzGAOsy8fyF",
    POSTHOG_HOST: "https://us.i.posthog.com"
  };