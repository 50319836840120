<div class="general-toaster-container">
  <ng-container *ngFor="let toaster of activeToasters">
    <div
      *ngIf="
        [notifications.codeblueRaised, notifications.eCallRaised].includes(
          toaster?.type
        )
      "
    >
      <app-codeblue-toaster
        [toasterData]="toaster"
        (closeEvent)="closeToasterEvent($event)"
        (endCallEvent)="endCodeBlueEvent($event)"
      >
      </app-codeblue-toaster>
    </div>
  </ng-container>
</div>
