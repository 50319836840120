<ng-container *ngIf="isLongFormat; else shortFormat">
  <span>Time zone:</span>
  <span
    class="cp-text-normal"
    *ngIf="timeZoneDetail?.name"
    matTooltip="{{ timeZoneDetail?.abbreviation_fullForm }}"
    [matTooltipPosition]="'above'"
  >
    {{ timeZoneDetail?.abbreviation }}
  </span>
</ng-container>

<ng-template #shortFormat>
  <span
    *ngIf="timeZoneDetail?.name"
    matTooltip="{{ timeZoneDetail?.abbreviation_fullForm }}"
    [matTooltipPosition]="'above'"
    class="cp-timezone-abbr-text"
    [ngStyle]="{ color: textColor }"
    >({{ timeZoneDetail?.abbreviation || "-" }})</span
  >
</ng-template>
