<section class="iris-modal-container form-container change-role">
  <div class="form-title">Change role</div>
  <div class="form-body">
    <form
      class="iris-form"
      autocomplete="off"
      [formGroup]="changeRoleForm"
      (ngSubmit)="changeRole(changeRoleForm.value)"
    >
      <div class="info">
        <div class="info-title">Current role: {{ currentUser.role }}</div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Switch to</mat-label>
              <mat-select
                placeholder="Select role"
                class="iris-input"
                formControlName="role"
              >
                <ng-container *ngFor="let role of currentUser.allowedRoles">
                  <mat-option
                    *ngIf="role !== currentUser.role"
                    [value]="role"
                    >{{ role }}</mat-option
                  >
                </ng-container>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Password</mat-label>
              <input
                class="iris-input"
                formControlName="password"
                matInput
                [type]="showPasswordC ? 'text' : 'password'"
                placeholder="Enter the password"
              />
              <mat-icon
                matRipple
                (click)="showPasswordC = !showPasswordC"
                class="password-eye"
                matSuffix
                >{{ showPasswordC ? "visibility" : "visibility_off" }}</mat-icon
              >
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <div *ngIf="serverError" class="iris-error mr">
          {{ serverError }}
        </div>

        <button
          type="button"
          class="button-secondary"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
        <button
          type="submit"
          class="button-primary"
          [disabled]="!changeRoleForm.valid || isSubmitted"
          mat-flat-button
        >
          Submit
          <app-loader [buttonLoader]="true" *ngIf="isSubmitted"></app-loader>
        </button>
      </mat-dialog-actions>

      <div class="refresh-warning">
        <small class="display-flex">
          <mat-icon inline="true">warning</mat-icon> Your page will refresh
          after hitting submit</small
        >
      </div>
    </form>
  </div>
</section>
