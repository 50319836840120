const nurse = {
  active: {
    communication: true,
    edit: false,
    discard: true,
    delete: false,
  },
  activeD: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  inactive: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  pta: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  pending: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  completed: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
};

// permissions for np and ccrn users.
const np = {
  active: {
    communication: true,
    edit: true,
    discard: true,
    delete: false,
  },
  activeD: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  inactive: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  pta: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  pending: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  completed: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
};

const physician = {
  active: {
    communication: false,
    edit: true,
    discard: true,
    delete: false,
  },
  activeD: {
    communication: false,
    edit: false,
    discard: true,
    delete: false,
    revert: true,
  },
  inactive: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  pta: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  pending: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
    sign: true,
  },
  completed: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
};

const physicianAssociate = {
  active: {
    communication: true,
    edit: true,
    discard: true,
    delete: false,
  },
  activeD: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  inactive: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
  pta: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  pending: {
    communication: false,
    edit: true,
    discard: false,
    delete: true,
  },
  completed: {
    communication: false,
    edit: false,
    discard: false,
    delete: false,
  },
};
export { nurse, physician, np, physicianAssociate };
