import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-diet-history-view',
  template: `
      <div class="details-modal__text">
        <span class="details-modal__text__title">Rate:</span>
        <div class="details-modal__text__content">
            <span *ngIf="order?.rate?.value">
                  {{ order.rate.value }} ml / {{ order?.rate?.unit }}
            </span>
            <span *ngIf="!order?.rate?.value">-</span>
        </div>
      </div>
      <app-history-common-view [data]="order"></app-history-common-view>
  `,
  styleUrls: ['../details-modal-common.scss']
})
export class DietHistoryViewComponent {
  @Input() order;
}
