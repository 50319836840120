import { Action, createReducer, on } from "@ngrx/store";

import { OrderableApiActions } from "../../../actions/orderable";
import {
  OrderableRequest,
  OrderableRequestStatus,
} from "../../../../models/orderable/OrderableRequest.model";

export interface State {
  pending: OrderableRequest[];
  rejected: OrderableRequest[];
}

export const initialState: State = {
  pending: [],
  rejected: [],
};

const orderableRequestDataReducer = createReducer(
  initialState,
  on(OrderableApiActions.getOrderableRequestSuccess, (state, { data }) => ({
    ...data,
  })),
  on(
    OrderableApiActions.acceptOrderableRequestSuccess,
    (state, { id, listType }) => {
      if (listType === OrderableRequestStatus.Pending) {
        const pending = state.pending.filter((request) => request._id !== id);

        return { ...state, pending };
      } else if (listType === OrderableRequestStatus.Rejected) {
        const rejected = state.rejected.filter((request) => request._id !== id);

        return { ...state, rejected };
      }

      return { ...state };
    }
  ),
  on(OrderableApiActions.rejectOrderableRequestSuccess, (state, { id }) => {
    let request;

    const pending = state.pending.reduce((acc, val) => {
      if (val._id !== id) {
        acc.push(val);
      } else {
        request = val;
      }

      return acc;
    }, []);

    return { pending, rejected: [...state.rejected, request] };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return orderableRequestDataReducer(state, action);
}

export const getPendingRequest = (state: State) => state.pending;

export const getRejectedRequest = (state: State) => state.rejected;
