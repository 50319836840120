import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { ThompsonFormService } from "../thomson-score.service";
import { Store, select } from "@ngrx/store";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { takeUntil } from "rxjs/operators";
import * as fromVitalReducer from "../../../../store/reducers";
import { Vitals } from "src/app/vitals/models/Vital";
import { valExists } from "src/app/support-functions/util";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import * as vitalActions from "../../../../store/actions/vitals.actions";

@Component({
  selector: "app-thompson-score",
  templateUrl: "./thompson-score.component.html",
  styleUrls: ["./thompson-score.component.scss"],
})
export class ThompsonScoreComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public thompsonForm: FormGroup = this.service.initThompsonScoreForm();
  public currentPatient;

  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }
  get formDate() {
    return this.thompsonForm.get("timestamp");
  }

  get thompsonScore() {
    return this.thompsonForm.get("thompsonScore").value;
  }
  @Input() minDate;
  @Input() maxDate;
  @Input() admitFormIsParent: boolean = false;
  constructor(
    private service: ThompsonFormService,
    private store: Store<{}>,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "THOMPSON score updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }
  setFormValues(vitalData: Vitals | null): void {
    this.thompsonForm.patchValue({
      thompsonScore: valExists(vitalData?.thompson?.thompsonScore)
        ? vitalData?.thompson?.thompsonScore
        : null,
      tone: valExists(vitalData?.thompson?.tone)
        ? vitalData?.thompson?.tone
        : null,
      conciousness: valExists(vitalData?.thompson?.conciousness)
        ? vitalData?.thompson?.conciousness
        : null,
      fits: valExists(vitalData?.thompson?.fits)
        ? vitalData?.thompson?.fits
        : null,
      posture: valExists(vitalData?.thompson?.posture)
        ? vitalData?.thompson?.posture
        : null,
      moroReflex: valExists(vitalData?.thompson?.moroReflex)
        ? vitalData?.thompson?.moroReflex
        : null,
      grasping: valExists(vitalData?.thompson?.grasping)
        ? vitalData?.thompson?.grasping
        : null,
      sucking: valExists(vitalData?.thompson?.sucking)
        ? vitalData?.thompson?.sucking
        : null,
      breathing: valExists(vitalData?.thompson?.breathing)
        ? vitalData?.thompson?.breathing
        : null,
      fontanelle: valExists(vitalData?.thompson?.fontanelle)
        ? vitalData?.thompson?.fontanelle
        : null,
    });

    if (vitalData.timestamp)
      setFormVal(this.thompsonForm, "timestamp", vitalData.timestamp);
  }

  onChange(): void {
    this.mapNullValuesToZero(this.thompsonForm.value);

    const {
      tone,
      conciousness,
      fits,
      posture,
      moroReflex,
      grasping,
      sucking,
      breathing,
      fontanelle,
    } = this.thompsonForm.value;
    this.thompsonForm.patchValue({
      thompsonScore:
        tone +
        conciousness +
        fits +
        posture +
        moroReflex +
        grasping +
        sucking +
        breathing +
        fontanelle,
    });
  }
  private mapNullValuesToZero(obj: any) {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = 0;
      }
    }
  }

  reset(): void {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.thompsonForm.reset();
    this.thompsonForm.get("timestamp").setValue(null);

    this.formState.error = null;
  }

  submit(patDataFromForm): void {
    this.store.dispatch(vitalActions.submitForm({ loadType: "thompson" }));

    const thompsonProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      thompson: {
        thompsonScore: patDataFromForm.thompsonScore,
        tone: patDataFromForm.tone,
        conciousness: patDataFromForm.conciousness,
        fits: patDataFromForm.fits,
        posture: patDataFromForm.posture,
        moroReflex: patDataFromForm.moroReflex,
        grasping: patDataFromForm.grasping,
        sucking: patDataFromForm.sucking,
        breathing: patDataFromForm.breathing,
        fontanelle: patDataFromForm.fontanelle,
      },
    };
    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: thompsonProps },
      })
    );
  }
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.thompsonForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
