import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import { Socket } from 'ngx-socket-io';
import { Store, select } from "@ngrx/store";
import * as actions from "src/app/store/actions/order/order-main.actions";
import { ApiResponse } from "../models/api-response.model";
import { Order } from "../models/Order.model";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import * as env from "../../environments/environment";
import { AddTimeToDatePipe } from "../add-time-to-date.pipe";
import {
  frequencyDisplayTitle,
  OrderFrequency,
} from "../models/OrderFrequency.model";

import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromUserReducer from "src/app/store/reducers/user/index";
import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import { NgxPermissionsService } from "ngx-permissions";
import { socket } from "../config/socket";
import { PatientParamInterface } from "../models/patient";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  env = environment;
  public user;
  public apiUrl = env.environment.apiUrl;
  public listOfEvents;

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );

  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));

  constructor(
    private http: HttpClient,
    public store: Store<{}>,
    private httpErrorHandler: HttpErrorHandlerService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  startListening(CPMRN, encounters) {
    this.listOfEvents = [
      {
        event: CPMRN + encounters + "Order new",
        type: actions.ActionTypes.newOrder,
      },
      {
        event: CPMRN + encounters + "Order update",
        type: actions.ActionTypes.update,
      },
      {
        event: CPMRN + encounters + "Order remove",
        type: actions.ActionTypes.remove,
      },
      {
        event: CPMRN + encounters + "New Orders",
        type: actions.ActionTypes.AddOrders,
      },
      {
        event: CPMRN + encounters + "Add Remove Orders",
        type: actions.ActionTypes.AddRemoveOrders,
      },
      {
        event: CPMRN + encounters + "Remove Orders",
        type: actions.ActionTypes.RemoveOrders,
      },
      {
        event: CPMRN + encounters + "Update Orders",
        type: actions.ActionTypes.UpdateOrders,
      },
      {
        event: CPMRN + encounters + "Update Multiple Orders",
        type: actions.ActionTypes.UpdateMultipleOrders,
      },
      {
        event: CPMRN + encounters + "Add Active Protocol",
        type: actions.ActionTypes.AddActiveProtocol,
      },
    ];
    this.listOfEvents?.forEach((order) => {
      this.socketListen(order.event, order.type, { CPMRN, encounters });
    });
  }

  socketListen(event, type, patData) {
    socket?.on(event, (data) => {
      this.store.dispatch({
        type: type,
        payload: data,
      });

      if (
        this.ngxPermissionsService.getPermission("get_io") &&
        event !== `${patData.CPMRN + patData.encounters}Update Inactive Orders`
      ) {
        this.store.dispatch(
          ioActions.getIo({
            CPMRN: patData.CPMRN,
            encounters: patData.encounters,
          })
        );
      }
    });
  }

  stopListening() {
    this.listOfEvents?.forEach((order) => {
      socket?.removeAllListeners(order.event);
    });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  placeOrder(orderProps, patientData: PatientParamInterface): Observable<any> {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/new",
      orderProps,
      this.httpOptions
    );
  }

  updatePressor(patientData: PatientParamInterface) {
    return this.http
      .patch(
        this.env.apiUrl +
          "patients/" +
          patientData.cpmrn +
          "/" +
          patientData.encounters +
          "/pressor",
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  editOrder(orderProps, patientData: PatientParamInterface): Observable<any> {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/edit",
      { ...orderProps },
      this.httpOptions
    );
  }

  disContinueOrder(orderProps, patientData: PatientParamInterface): any {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/discontinue",
      { ...orderProps },
      this.httpOptions
    );
  }

  signOrder(orderProps, patientData: PatientParamInterface): any {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/sign",
      { ...orderProps },
      this.httpOptions
    );
  }

  moveOrder(orderProps, patientData: PatientParamInterface): any {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/move",
      orderProps,
      this.httpOptions
    );
  }

  communicateAction(orderProps, patientData: PatientParamInterface): any {
    return this.http.patch(
      this.env.apiUrl +
        "patients/" +
        patientData.cpmrn +
        "/" +
        patientData.encounters +
        "/orders/action",
      orderProps,
      this.httpOptions
    );
  }

  getPatientOrders(type, CPMRN, encounters): Observable<any> {
    return this.http
      .get(
        this.env.apiUrl +
          "patients/" +
          CPMRN +
          "/" +
          encounters +
          "/orders?option=" +
          type,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  get() {
    return this.http
      .get<ApiResponse>(`${this.apiUrl}order`, this.httpOptions)
      .pipe(catchError(this.httpErrorHandler.handleError()));
  }

  create(value: Order) {
    return this.http
      .post<ApiResponse>(`${this.apiUrl}order`, value, this.httpOptions)
      .pipe(catchError(this.httpErrorHandler.handleError()));
  }

  transformDateTime(date, time) {
    const addTimePipe = new AddTimeToDatePipe();

    return addTimePipe.transform(date, time);
  }

  getFrequencyDisplayName(frequency: OrderFrequency): string {
    let title = frequencyDisplayTitle.get(frequency?.fType);

    if (frequency.timeOfDay) {
      title += ` ${frequency.timeOfDay}`;
    }

    if (frequency?.fType === "every") {
      title = frequency.days
        ? `${title} ${frequency.days} days`
        : `${title} 0 days`;
      if (!frequency.days) {
        title = frequency.hours
          ? `${title} ${frequency.hours} hours`
          : `${title} 0 hours`;
        title = frequency.mins ? `${title} ${frequency.mins} mins` : `${title}`;
      }
    }

    return title;
  }

  getIconForOrder(type: string) {
    if (type === "blood") {
      return ["order_blood"];
    } else if (type === "comm") {
      return ["order_communication"];
    } else if (type === "diet") {
      return ["order_diet"];
    } else if (type === "lab") {
      return ["order_lab"];
    } else if (type === "med") {
      return ["order_med"];
    } else {
      return ["order_procedure"];
    }
  }

  getIconClassForOrder(type: string): string {
    if (type === "blood") {
      return "ot-icon ot-icon--blood";
    } else if (type === "comm") {
      return "ot-icon ot-icon--comm";
    } else if (type === "diet") {
      return "ot-icon ot-icon--diet";
    } else if (type === "lab") {
      return "ot-icon ot-icon--lab";
    } else if (type === "med") {
      return "ot-icon ot-icon--med";
    } else {
      return "ot-icon ot-icon--procedure";
    }
  }

  /**
   * Converts new order type to old order type
   *
   * @param {string} type
   * @returns {string}
   */
  convertNewTypeToOldType(type: string): string {
    if (type === "blood") {
      return "bloods";
    } else if (type === "comm") {
      return "communications";
    } else if (type === "diet") {
      return "diets";
    } else if (type === "lab") {
      return "labs";
    } else if (type === "med") {
      return "medications";
    } else if (type === "procedure") {
      return "procedures";
    } else if (type === "vents") {
      return "vents";
    }
  }

  checkIfOrderExistInProtocol(
    order: Order | any,
    activeProtocols: string[]
  ): boolean {
    if (activeProtocols.length === 0 || !order.protocol) {
      return false;
    }

    return activeProtocols.indexOf(order.protocol) !== -1;
  }
}
