import { map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";

import { Order } from "../../models/Order.model";
import { ApiResponse } from "../../models/api-response.model";
import { Protocol } from "../../models/protocol/Protocol.model";
import { environment } from "../../../environments/environment";
import { PlaceProtocolForm } from "../../models/protocol/PlaceProtocolForm.model";
import { HttpErrorHandlerService } from "../../services/http-error-handler.service";
import * as fromPatientHeader from "../../store/reducers/patient-chart/patient-header";
import { PatientParamInterface } from "src/app/models/patient";

@Injectable({ providedIn: "root" })
export class ProtocolService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  private searchSource = new Subject<string>();
  public search$: Observable<string> = this.searchSource.asObservable();

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService
  ) {}

  get(text = null): Observable<any> {
    return this.http.get<ApiResponse>(`${this.apiUrl}protocol`, {
      ...this.httpOptions,
      params: new HttpParams().set("name", text),
    });
  }

  getProtocol(id: string): Observable<any> {
    return this.http
      .get<ApiResponse>(`${this.apiUrl}protocol/${id}`, this.httpOptions)
      .pipe(
        map((response) => {
          const presets = response.data.presets.reduce((acc, preset) => {
            if (preset._id) {
              acc.push({ ...preset, view: preset.view });
            }

            return acc;
          }, []);

          return { ...response, data: { ...response.data, presets } };
        })
      );
  }

  create(protocol: Protocol): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}protocol`,
      protocol,
      this.httpOptions
    );
  }

  edit(id: string, protocol: Protocol): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}protocol/${id}`,
      protocol,
      this.httpOptions
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete<ApiResponse>(
      `${this.apiUrl}protocol/${id}`,
      this.httpOptions
    );
  }

  getSignedUrl(): Observable<any> {
    return this.http.get<ApiResponse>(
      `${this.apiUrl}protocol/getSignedUrl`,
      this.httpOptions
    );
  }

  uploadFile(url: string, file: File): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/pdf",
      DoNotIntercept: "true",
    });

    const req = new HttpRequest("PUT", url, file, {
      headers,
      reportProgress: true,
    });

    return this.http.request(req);
  }

  placeProtocol(
    value: PlaceProtocolForm,
    patientData: PatientParamInterface
  ): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}protocol/${patientData.cpmrn}/${patientData.encounters}/place`,
      value,
      this.httpOptions
    );
  }

  /*
   * NAME: discontinueProtocol
   * PURPOSE: discontinue the passed orders.
   * DESCRIPTION: discontinue the passed orders.
   * PARAMS: orders:Order[] - orders array passed(can be of different protocols)
   * RETURNS: Observable<any> - http response as observable
   * USED BY: protocol.effects.ts
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  discontinueProtocol(
    orders: Order[],
    patientData: PatientParamInterface
  ): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}protocol/${patientData.cpmrn}/${patientData.encounters}/discontinue`,
      { orders },
      this.httpOptions
    );
  }

  /*
   * NAME: signProtocol
   * PURPOSE: sign the passed orders.
   * DESCRIPTION: sign the passed orders.
   * PARAMS: orders:Order[] - orders array passed(can be of different protocols)
   * RETURNS: Observable<any> - http response as observable
   * USED BY: protocol.effects.ts
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  signProtocol(
    orders: Order[],
    user: string,
    patientData: PatientParamInterface
  ): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}protocol/${patientData.cpmrn}/${patientData.encounters}/sign`,
      { orders, user },
      this.httpOptions
    );
  }

  /*
   * NAME: getProtocolFile
   * PURPOSE: fetch file related to a protocol
   * DESCRIPTION: returns null if file is not present
   * PARAMS: protocol:string - name of protocol
   * RETURNS: Observable<any> - http response as observable
   * USED BY: protocol.effects.ts
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getProtocolFile(protocol: string): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}protocol/file`,
      { protocol },
      this.httpOptions
    );
  }

  sendSearchData(text: string): void {
    this.searchSource.next(text);
  }
}
