import { Component, HostListener, Input, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { VentsFormService } from "./services/vents-form.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientStatusService } from "./services/patient-status.service";
import * as fromLabStore from "../../../../../app/labs-scans-module/store";
import {
  breathSeq_pc_tc,
  breathSeq_vc,
  controlVariable,
  neonatalMode,
  adultMode,
} from "./data/invasive-ventilation.data";
import moment from "moment";
import { VitalInputService } from "../vital-input.service";
import { TimeZoneDetails } from "src/app/models/hospital";

@Component({
  selector: "app-vents",
  templateUrl: "./vents.component.html",
  styleUrls: ["./vents.component.scss"],
})
export class VentsComponent implements OnInit {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description pain form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  ventsForm: UntypedFormGroup = this.ventsFormService.initVentForm();

  /**
   * @description Gets the abstract control of the therapy form from the vents form
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get therapyForm(): AbstractControl {
    return this.ventsForm.get("therapyForm");
  }

  @Input() set patVitals(patVitals) {}

  /**
   * @description Gets the abstract control of the vent mode from the therapyForm
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get ventMode(): AbstractControl {
    return this.therapyForm.get("daysVentMode");
  }
  //for invasive vennt@sheethalkhan  24-04-2023
  get invasiveMode(): AbstractControl {
    return this.therapyForm.get("daysInvasiveMode");
  }

  /**
   * @description Gets the abstract control of the therapy form from the vents form
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get airwayForm(): AbstractControl {
    return this.ventsForm.get("airwayForm");
  }

  /**
   * @description To get the abstract control of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.ventsForm.get("timestamp");
  }

  get nasopharyngealAirway(): AbstractControl {
    return this.airwayForm.get("nasopharyngealAirway");
  }

  get oralAirway(): AbstractControl {
    return this.airwayForm.get("oralAirway");
  }

  get biteBlock(): AbstractControl {
    return this.airwayForm.get("biteBlock");
  }

  get supraglotticAirway(): AbstractControl {
    return this.airwayForm.get("supraglotticAirway");
  }

  get cooksAirway(): AbstractControl {
    return this.airwayForm.get("cooksAirway");
  }

  get oralEndotrachealTube(): AbstractControl {
    return this.airwayForm.get("oralEndotrachealTube");
  }

  get nasalEndotrachealTube(): AbstractControl {
    return this.airwayForm.get("nasalEndotrachealTube");
  }

  get tracheostomyTube(): AbstractControl {
    return this.airwayForm.get("tracheostomyTube");
  }

  get otherAirway(): AbstractControl {
    return this.airwayForm.get("otherAirway");
  }

  /**
   * @description Gets the abstract control of the airway values
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get airwayValuesFromForm(): AbstractControl {
    return this.airwayForm.get("daysVentAirway");
  }

  therapyValuesPediatric: string[] = ["Oxygen hood", "Nasal Bubble CPAP"];

  therapyValuesDefault: string[] = [
    "Room air",
    "Invasive ventilation",
    "NIV",
    "Nasal cannula",
    "Face mask",
    "Venturi mask",
    "Non-rebreathing mask",
    "HFNC",
    "T piece",
    "Ambu bag",
    "Trach mask",
  ];

  /**
   * Values of therapy devices
   * @type {Array}
   */
  therapyValues: string[];

  validO2Devices: string[] = [];

  /**
   * Values of airway
   * @type {Array}
   */
  airwayValues: string[] = [
    "Nasopharyngeal airway",
    "Oral airway",
    "Bite block",
    "Supraglottic airway",
    "Cooks airway",
    "Oral endotracheal tube with cuff",
    "Nasal endotracheal tube",
    "Tracheostomy tube",
  ];

  /**
   * Values of supraglotic values
   * @type {Array}
   */
  supragloticValues: string[] = [
    "Classic LMA",
    "Igel",
    "LMA supreme",
    "Proseal",
    "Fastrach",
    "Other",
  ];

  /**
   * Values of supraglotic values
   * @type {Array}
   */
  breathSequenceValues: string[] = ["CMV", "IMV"];

  /**
   * @description to hide show the formcontrols based on the selection
   * Refer https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   */
  showO2: boolean = false;
  showFio2: boolean = false;
  showMode: boolean = false;
  showPEEP: boolean = false;
  showNasoph: boolean = false;
  showOral: boolean = false;
  showBite: boolean = false;
  showSupraglotic: boolean = false;
  showCooks: boolean = false;
  showSupraOther: boolean = false;
  showOralEndo: boolean = false;
  showNasalEndo: boolean = false;
  showTracheostomy: boolean = false;

  //booleans for invasive ventillation mode @sheethalkhan
  //refer  https://docs.google.com/spreadsheets/d/1KJHC3an9sNGEp-FB2I1QA52CuCe4J9kMP8_0vU3mky0/edit#gid=0
  isHfov: boolean = false;
  isSimvvg: boolean = false;
  isSippvvg: boolean = false;
  isVentilatorCrap: boolean = false;
  isSimvpc: boolean = false;
  isOther: boolean = false;
  isInvasiveVentilation: boolean = false;

  public controlVariablearray = controlVariable;
  public neonatalMode = neonatalMode;
  public adultMode = adultMode;
  public breathSeq_vc = breathSeq_vc;
  public breathSeq_pc_tc = breathSeq_pc_tc;
  public breathSequence = [];
  public selectedOption = "Other";
  public latestMapValue: number;
  public latestSpo2Value: number;
  public latestFio2Value: number;
  public latestPao2Value: number;
  public osiValue: number;
  public oiValue: number;

  public vitalsData$ = this.store.pipe(
    select(fromVitalReducer.getVitaldays),
    takeUntil(this.unsubscribe$)
  );

  public vitalClickedDay$ = this.store.pipe(
    select(fromVitalReducer.getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  public labData$ = this.store.pipe(
    select(fromLabStore.getAllLabs),
    takeUntil(this.unsubscribe$)
  );
  /**
   * @description Calculates the fio2 when fio2 DOM input is focussed
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  calculateFio2(): void {
    let fio2 = this.therapyForm.get("daysFiO2");

    let therapyDevice = this.therapyForm.get("daysTherapyDevice").value;

    if (therapyDevice.includes("Room air")) {
      fio2.setValue(21);
    }
  }

  /**
   * @description Triggers when the therapy device value is changed
   * This hides and shows the formcontrols based on https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  therapyChanged(): void {
    this.resetModeSettings();
    //this.therapyForm.get("daysFiO2").setValue(null);

    let therapyVal = this.therapyForm.get("daysTherapyDevice").value || [];

    let isInvasive = therapyVal.includes("Invasive ventilation");
    let isNiv = therapyVal.includes("NIV");
    let isRoomair = therapyVal.includes("Room air");
    const isOxygenHood = therapyVal.includes("Oxygen hood");
    const isNasalCPAP = therapyVal.includes("Nasal Bubble CPAP");

    this.isBooleanCheckInvasiveVent();
    this.isInvasiveVentilation = false;
    if (!therapyVal.length) {
      this.isBooleanCheckVents();
      this.isInvasiveVentilation = false;
      this.resetModeSettings();
      return;
    }

    this.showFio2 = true;
    this.showO2 = true;
    this.showPEEP = false;

    if (therapyVal.length === 1) {
      if (isInvasive || isNiv || isRoomair) {
        this.showO2 = false;

        //this.therapyForm.get("daysOxygenFlow").reset();
      }

      if (isOxygenHood) {
        this.showFio2 = false;
      }
    }

    if (isNiv) {
      this.showMode = true;
      if (isNiv) {
        this.ventMode.setValue("VC");
      }
    } else if (isInvasive) {
      //if therapy selected as invasive ventilation @sheethalkhan
      this.isBooleanCheckVents();
      this.isInvasiveVentilation = true;
      if (
        this.currentPatient?.patientType == "adult" ||
        this.currentPatient?.patientType == "pediatric"
      ) {
        this.therapyForm.get("daysInvasiveMode").setValue("Other");
        this.selectedOption = "Other";
        this.isOther = true;
        this.showFio2 = true;
        this.showMode = true;
      }
    } else {
      if (isNasalCPAP) this.showPEEP = true;
      this.showMode = false;
      //this.resetModeSettings();
    }
  }

  /**
   * @description Triggers when the therapy device value is changed
   * This hides and shows the formcontrols
   * This also adds values to the form array based on the selection
   * Refer https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  airwayChanged(): void {
    let airwayVal = this.airwayForm.get("daysVentAirway").value;

    // if (!airwayVal?.length) {
    //   return;
    // }

    // To show the Nasopharyngeal fields
    if (airwayVal?.includes("Nasopharyngeal airway")) {
      this.showNasoph = true;
    } else {
      this.showNasoph = false;
      this.nasopharyngealAirway.reset();
    }

    // To show the Oral fields
    if (airwayVal?.includes("Oral airway")) {
      this.showOral = true;
    } else {
      this.showOral = false;
      this.oralAirway.reset();
    }

    // To show the Bite fields
    if (airwayVal?.includes("Bite block")) {
      this.showBite = true;
    } else {
      this.showBite = false;
      this.biteBlock.reset();
    }

    // To show the supraglotic fields
    if (airwayVal?.includes("Supraglottic airway")) {
      this.showSupraglotic = true;
    } else {
      this.showSupraglotic = false;
      this.supraglotticAirway.reset();
    }

    // To show the cooks fields
    if (airwayVal?.includes("Cooks airway")) {
      this.showCooks = true;
    } else {
      this.showCooks = false;
      this.cooksAirway.reset();
    }

    // To check if oral is present
    if (airwayVal?.includes("Oral endotracheal tube with cuff")) {
      this.showOralEndo = true;
    } else {
      this.showOralEndo = false;
      this.oralEndotrachealTube.reset();
    }

    // To check if nasal is present
    if (airwayVal?.includes("Nasal endotracheal tube")) {
      this.showNasalEndo = true;
    } else {
      this.showNasalEndo = false;
      this.nasalEndotrachealTube.reset();
    }

    // To check if Tracheostomy is present
    if (airwayVal?.includes("Tracheostomy tube")) {
      this.showTracheostomy = true;
    } else {
      this.showTracheostomy = false;
      this.tracheostomyTube.reset();
    }
  }

  /**
   * @description Triggers when supragloctic is changed to show the other field
   * @param event
   */
  supraGloticChanged(): void {
    let supraVal = this.supraglotticAirway.value.daysSupraglotticAirway;

    if (supraVal === "Other") {
      this.showSupraOther = true;
    } else {
      this.showSupraOther = false;
    }
  }

  /**
   * @description Triggers when mode is changed to show the other field
   * @param event
   */
  modeChanged(): void {
    let modeVal = this.therapyForm.value.daysVentMode;

    if (modeVal === "VC") {
      this.breathSequenceValues = ["CMV", "IMV"];
    } else if (modeVal === "PC") {
      this.breathSequenceValues = ["CMV", "IMV", "CSV"];
    }
  }

  /**
   * @description This resets the values of the mode settings when the therapy device is changed
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */

  resetModeSettings(): void {
    this.therapyForm.reset({
      daysTherapyDevice: this.therapyForm.get("daysTherapyDevice").value,
    });
  }

  //resetting form excpt below values @sheethalkhan  24-04-2023
  resetModeSettingsInvasive() {
    this.therapyForm.reset({
      daysTherapyDevice: this.therapyForm.get("daysTherapyDevice").value,
      daysInvasiveMode: this.therapyForm.get("daysInvasiveMode").value,
      daysVentMode: this.therapyForm.get("daysVentMode").value,
      daysVentBreathSequence: this.therapyForm.get("daysVentBreathSequence")
        .value,
    });
  }

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  timeZoneDetail: TimeZoneDetails;
  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.timeZoneDetail =
        this.currentPatient?.timeZoneDetail ||
        this.currentPatient?.hospitalInfo?._timeZoneDetail;
      // creating therapy values as per patient type
      this.addRemoveTherapyValues();
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  public _clickedDay: Vitals;
  @Input()
  set clickedDay(day: Vitals | null) {
    day = day ?? <Vitals>{};
    this._clickedDay = day;
    if (day) {
      this.setFormValues(day);
    }
  }

  get clickedDay() {
    return this._clickedDay;
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    const setTherapyForm = () => {
      setFormVal(
        this.therapyForm,
        "daysTherapyDevice",
        vitalData.daysTherapyDevice || null
      );
      this.therapyChanged();

      setFormVal(
        this.therapyForm,
        "daysOxygenFlow",
        vitalData.daysOxygenFlow || null
      );

      setFormVal(this.therapyForm, "daysFiO2", vitalData.daysFiO2 || null);

      setFormVal(
        this.therapyForm,
        "daysVentMode",
        vitalData.daysVentMode || null
      );

      this.modeChanged();

      setFormVal(this.therapyForm, "daysVentVt", vitalData.daysVentVt || null);

      setFormVal(
        this.therapyForm,
        "daysVentPEEP",
        vitalData.daysVentPEEP || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentRRset",
        vitalData.daysVentRRset || null
      );

      setFormVal(this.therapyForm, "daysRR", vitalData.daysRR || null);

      setFormVal(
        this.therapyForm,
        "daysVentPinsp",
        vitalData.daysVentPinsp || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPsupport",
        vitalData.daysVentPsupport || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPpeak",
        vitalData.daysVentPpeak || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPplat",
        vitalData.daysVentPplat || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPmean",
        vitalData.daysVentPmean || null
      );

      setFormVal(this.therapyForm, "daysVentMV", vitalData.daysVentMV || null);

      setFormVal(this.therapyForm, "daysVentI", vitalData.daysVentI || null);

      setFormVal(this.therapyForm, "daysVentE", vitalData.daysVentE || null);

      //for invasive vent modes @sheethalkhan  25-05-2023
      setFormVal(
        this.therapyForm,
        "daysInvasiveMode",
        vitalData.daysInvasiveMode || null
      );

      setFormVal(this.therapyForm, "daysVentTi", vitalData.daysVentTi || null);
      setFormVal(
        this.therapyForm,
        "daysVentPip",
        vitalData.daysVentPip || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentIFR",
        vitalData.daysVentIFR || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentTidalVolume",
        vitalData.daysVentTidalVolume || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentMeanMAP",
        vitalData.daysVentMeanMAP || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentFrequency",
        vitalData.daysVentFrequency || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentDeltaP",
        vitalData.daysVentDeltaP || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentBreathSequence",
        vitalData.daysVentBreathSequence || null
      );
      this.calculateOI();
      this.calculateMAP_OI();
      setFormVal(
        this.therapyForm,
        "daysVentOI",
        vitalData?.daysVentOI || this.oiValue || null
      );
      setFormVal(
        this.therapyForm,
        "daysVentOSI",
        vitalData.daysVentOSI || null
      );
      if (
        this.therapyForm
          .get("daysTherapyDevice")
          .value?.includes("Invasive ventilation")
      ) {
        this.controlVariableChanged();
        this.modeChangedForInvasiveVent(true);
      }
    };

    const setAirwayForm = () => {
      setFormVal(
        this.airwayForm,
        "daysVentAirway",
        vitalData.daysVentAirway || null
      );
      this.airwayChanged();

      // nasal
      setFormVal(
        this.nasopharyngealAirway,
        "daysVentTubeSize",
        vitalData?.nasopharyngealAirway?.daysVentTubeSize || null
      );

      // oral
      setFormVal(
        this.oralAirway,
        "daysVentTubeSize",
        vitalData?.oralAirway?.daysVentTubeSize || null
      );

      // bite block
      setFormVal(
        this.biteBlock,
        "daysVentTubeSize",
        vitalData?.biteBlock?.daysVentTubeSize || null
      );

      // supraglotic
      setFormVal(
        this.supraglotticAirway,
        "daysVentTubeSize",
        vitalData?.supraglotticAirway?.daysVentTubeSize || null
      );
      if (
        vitalData?.supraglotticAirway?.daysSupraglotticAirway &&
        !this.supragloticValues.includes(
          vitalData?.supraglotticAirway?.daysSupraglotticAirway
        )
      ) {
        setFormVal(this.supraglotticAirway, "daysSupraglotticAirway", "Other");
        this.supraGloticChanged();
        setFormVal(
          this.supraglotticAirway,
          "supraGloticOther",
          vitalData?.supraglotticAirway?.daysSupraglotticAirway || null
        );
      } else {
        setFormVal(
          this.supraglotticAirway,
          "daysSupraglotticAirway",
          vitalData?.supraglotticAirway?.daysSupraglotticAirway || null
        );
      }

      // cooks
      setFormVal(
        this.cooksAirway,
        "daysVentTubeSize",
        vitalData?.cooksAirway?.daysVentTubeSize || null
      );

      // nasal endo
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentCuff",
        vitalData?.nasalEndotrachealTube?.daysVentCuff
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysEttTieLevel",
        vitalData?.nasalEndotrachealTube?.daysEttTieLevel || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentTubeSize",
        vitalData?.nasalEndotrachealTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentCuffPressure",
        vitalData?.nasalEndotrachealTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "isClosedSuction",
        vitalData?.nasalEndotrachealTube?.isClosedSuction
      );

      // oral endo
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentCuff",
        vitalData?.oralEndotrachealTube?.daysVentCuff
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysEttTieLevel",
        vitalData?.oralEndotrachealTube?.daysEttTieLevel || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentTubeSize",
        vitalData?.oralEndotrachealTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentCuffPressure",
        vitalData?.oralEndotrachealTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "isClosedSuction",
        vitalData?.oralEndotrachealTube?.isClosedSuction
      );

      // trach endo
      setFormVal(
        this.tracheostomyTube,
        "daysVentCuff",
        vitalData?.tracheostomyTube?.daysVentCuff
      );
      setFormVal(
        this.tracheostomyTube,
        "daysVentTubeSize",
        vitalData?.tracheostomyTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.tracheostomyTube,
        "daysVentCuffPressure",
        vitalData?.tracheostomyTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.tracheostomyTube,
        "isClosedSuction",
        vitalData?.tracheostomyTube?.isClosedSuction
      );
    };

    if (vitalData.timestamp)
      setFormVal(this.ventsForm, "timestamp", vitalData.timestamp);

    setTherapyForm();
    setAirwayForm();
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.ventsForm.reset();
    this.ventsForm.get("timestamp").setValue(null);

    this.showO2 = false;
    this.showFio2 = false;
    this.showPEEP = false;
    this.showMode = false;
    this.showNasoph = false;
    this.showOral = false;
    this.showBite = false;
    this.showSupraglotic = false;
    this.showCooks = false;
    this.showSupraOther = false;
    this.showOralEndo = false;
    this.showNasalEndo = false;
    this.showTracheostomy = false;
    this.formState.error = null;
    this.isInvasiveVentilation = false;
  }

  /**
   * @description To submit the pain score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  // @HostListener("click")
  // clickInside() {
  //   if (
  //     this.therapyForm.value.daysFiO2 &&
  //     this.therapyForm.value.daysVentMeanMAP
  //   ) {
  //     this.calculateOI();
  //   }

  //   if (
  //     this.therapyForm.value.daysVentTi &&
  //     this.therapyForm.value.daysRR &&
  //     this.therapyForm.value.daysVentPip &&
  //     this.therapyForm.value.daysVentPEEP
  //   ) {
  //     this.calculateMAP_OI();
  //   }
  // }
  constructor(
    private store: Store<{}>,
    private _alertService: AlertService,
    private ventsFormService: VentsFormService,
    private _vitalService: VitalInputService,
    private patientStatusService: PatientStatusService
  ) {}

  ngOnInit(): void {
    // creating therapy values as per patient type
    this.addRemoveTherapyValues();

    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Vents updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description add therapy device value according to patient-type
   * @author Rajat Saini
   * @Date Oct 31 2022
   */
  addRemoveTherapyValues() {
    if (this.currentPatient?.patientType == "adult") {
      this.therapyValues = [...this.therapyValuesDefault];
      return;
    }

    this.therapyValues = [
      ...this.therapyValuesDefault,
      ...this.therapyValuesPediatric,
    ];
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.ventsForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * @description To submit the vents form to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitVents(): void {
    /** Init the form loading */
    this.store.dispatch(vitalActions.submitForm({ loadType: "vents" }));
    let ventsValue = this.ventsForm.value;

    let therapyForm = ventsValue.therapyForm;
    // if patient is adult/pediatric, no need to send below controls
    if (this.currentPatient?.patientType !== "neonatal") {
      delete therapyForm.daysVentTi;
      delete therapyForm.daysVentPip;
      delete therapyForm.daysVentTidalVolume;
      delete therapyForm.daysVentIFR;
      delete therapyForm.daysVentOI;
      delete therapyForm.daysVentOSI;

      delete therapyForm.daysVentMeanMAP;
      delete therapyForm.daysVentFrequency;
      delete therapyForm.daysVentDeltaP;
    }

    let airwayForm = ventsValue.airwayForm;

    //delete this.formData.phoneNumber;
    let vents = {
      timestamp: new Date(ventsValue.timestamp),
      ...therapyForm,
    };

    // Submit daysRR only if it exists
    if (therapyForm.daysRR) {
      vents.daysRR = therapyForm.daysRR;
    } else {
      delete vents.daysRR;
    }

    // If other, send the value of the free text as the supraglotic airway
    let supraGlocticAirwayVal = airwayForm.supraglotticAirway;
    if (supraGlocticAirwayVal.daysSupraglotticAirway === "Other") {
      supraGlocticAirwayVal.daysSupraglotticAirway =
        supraGlocticAirwayVal.supraGloticOther;
    }

    vents = {
      ...vents,
      ...airwayForm,
      supraglotticAirway: supraGlocticAirwayVal,
    };

    let patientInfo = this.patientStatusService.patientTherapyStatus(
      this.currentPatient,
      therapyForm.daysTherapyDevice,
      airwayForm.daysVentAirway,
      ventsValue.timestamp
    );

    /** Dispatch the action */
    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: vents, patientInfo },
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  controlVariableChanged(): void {
    let modeVal = this.therapyForm.value.daysVentMode;

    if (modeVal === "VC") {
      this.breathSequence = this.breathSeq_vc;
    } else if (modeVal === "PC" || modeVal === "TC") {
      this.breathSequence = this.breathSeq_pc_tc;
    }
  }

  modeChangedForInvasiveVent(isEdit?: boolean): void {
    this.isBooleanCheckInvasiveVent();
    this.isBooleanCheckVents();
    this.clearValues();
    let modeVal = this.therapyForm.value.daysInvasiveMode;

    if (modeVal === "HFO V") {
      this.isHfov = true;
    } else if (modeVal === "SIMV VG") {
      this.isSimvvg = true;
    } else if (modeVal === "SIPPV VG") {
      this.isSippvvg = true;
    } else if (modeVal === "Ventilator CPAP") {
      this.isVentilatorCrap = true;
    } else if (modeVal === "SIMV PC") {
      this.isSimvpc = true;
    } else if (modeVal === "Other") {
      this.isOther = true;
      this.showFio2 = true;
      this.showMode = true;
    }
    if (!isEdit) {
      this.resetModeSettingsInvasive();
    }
  }
  isBooleanCheckInvasiveVent() {
    this.isHfov = false;
    this.isSimvvg = false;
    this.isSippvvg = false;
    this.isVentilatorCrap = false;
    this.isSimvpc = false;
    this.isOther = false;
  }
  isBooleanCheckVents() {
    this.showO2 = false;
    this.showFio2 = false;
    this.showMode = false;
    this.showPEEP = false;
  }
  //for hfo v mode @sheethalkhan  31-05-2023
  calculateOI() {
    this.latestMapValue = this.therapyForm.value.daysVentMeanMAP;
    this.latestFio2Value = this.therapyForm.value.daysFiO2;

    this.getSpo2();
    this.getPao2();

    if (this.latestMapValue && this.latestSpo2Value && this.latestFio2Value) {
      this.osiValue =
        (this.latestMapValue * this.latestFio2Value) / this.latestSpo2Value;
      this.therapyForm
        .get("daysVentOSI")
        .setValue(Math.round(this.osiValue * 100) / 100);
    }

    if (this.latestMapValue && this.latestPao2Value && this.latestFio2Value) {
      let value =
        (this.latestMapValue * this.latestFio2Value) / this.latestPao2Value;
      this.oiValue = Math.round(value * 100) / 100;

      this.therapyForm.get("daysVentOI").setValue(this.oiValue);
    }
  }
  //for simv vg mode @sheethalkhan  31-05-2023
  calculateMAP_OI() {
    if (
      this.therapyForm.value.daysVentTi &&
      this.therapyForm.value.daysRR &&
      this.therapyForm.value.daysVentPip &&
      this.therapyForm.value.daysVentPEEP
    ) {
      this.latestMapValue =
        ((this.therapyForm.value.daysVentTi * this.therapyForm.value.daysRR) /
          60) *
          (this.therapyForm.value.daysVentPip -
            this.therapyForm.value.daysVentPEEP) +
        this.therapyForm.value.daysVentPEEP;
      this.latestFio2Value = this.therapyForm.value.daysFiO2;

      this.getPao2();
      if (this.latestFio2Value && this.latestPao2Value && this.latestMapValue) {
        let value =
          (this.latestMapValue * this.latestFio2Value) / this.latestPao2Value;
        this.oiValue = Math.round(value * 100) / 100;
        this.therapyForm.get("daysVentOI").setValue(this.oiValue);
      }
    }
  }

  getPao2() {
    this.labData$.subscribe((data) => {
      let isABG = false;
      for (const element of data) {
        //edit case:  we r taking pao2 value at reporting time lesser but near to  clickedday timme @sheethalkhan  22-06-2023
        if (this._clickedDay?.timestamp) {
          if (
            moment(element.reportedAt).startOf("minute") <=
            moment(this._clickedDay.timestamp).startOf("minute")
          ) {
            if (element.alias) {
              for (const alias of element.alias) {
                if (alias === "ABG") {
                  this.latestPao2Value = element.attributes?.PaO2.value;
                  isABG = true;
                  break;
                }
              }
            }
          }
        } else {
          // newly adding case
          if (element.alias) {
            for (const alias of element.alias) {
              if (alias === "ABG") {
                this.latestPao2Value = element.attributes?.PaO2.value;
                isABG = true;
                break;
              }
            }
          }
        }

        if (isABG) break;
      }
    });
  }

  getSpo2() {
    this.vitalsData$.subscribe((data) => {
      for (const element of data) {
        if (element.daysSpO2) {
          this.latestSpo2Value = Number(element.daysSpO2);
          break;
        }
      }
    });

    this._vitalService.patientVitals$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data.length) return;
        for (const element of data) {
          if (element.daysSpO2) {
            this.latestSpo2Value = Number(element.daysSpO2);
            break;
          }
        }
      });
  }
  clearValues() {
    this.latestFio2Value = null;
    this.latestMapValue = null;
    this.latestPao2Value = null;
    this.latestSpo2Value = null;
  }
}
