import { AppState } from "../..";
import * as fromDocumentReducer from "./documents.reducer";
import * as fromDocumentFormReducer from "./documents-form.reducer";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import { getActiveCulturesCount } from "../../order";

export interface documentState {
  data: fromDocumentReducer.State;
  form: fromDocumentFormReducer.State;
}

export interface State extends AppState {
  documents: documentState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromDocumentReducer.reducer,
  form: fromDocumentFormReducer.reducer,
};

/** SELECTOR setup */
export const getDocumentDataState =
  createFeatureSelector<documentState>("documents");

export const getDocumentData = createSelector(
  getDocumentDataState,
  (state) => state.data
);

export const getDocuments = createSelector(
  getDocumentData,
  (state) => state.documents
);

export const getDocumentdays = createSelector(
  getDocumentData,
  (state) => state.documents
);

// Form
export const getDocForm = createSelector(
  getDocumentDataState,
  (state) => state.form
);

export const getCulturesDocumentsCount = createSelector(
  getDocuments,
  (documents) =>
    documents.reduce((acc, document: any) => {
      if (document.tags.indexOf("Cultures") > -1 && !document.isInactive) {
        acc.push(document);
      }

      return acc;
    }, []).length
);

export const getCovidDocumentsCount = createSelector(
  getDocuments,
  (documents) =>
    documents.reduce((acc, document: any) => {
      if (document.tags.indexOf("COVID-19") > -1 && !document.isInactive) {
        acc.push(document);
      }

      return acc;
    }, []).length
);
