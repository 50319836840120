<div class="sbar-alert-container">
  <ng-container *ngFor="let sbarAlert of $sbarAlert | async">
    <div
      *ngIf="!sbarAlert?.patientAssignmentMsg?.length; else patientAssignAlert"
      class="sbar-alert-item"
      (click)="alertCLicked(sbarAlert)"
      [ngClass]="{
        high: sbarAlert.urgency == 'High',
        med: sbarAlert.urgency == 'Medium',
        low: sbarAlert.urgency == 'Low',
        new: sbarAlert.urgency == 'New Admission'
      }"
    >
      <div
        class="close cp-btn cp-hvr-info-s m-0"
        aria-label="Close"
        (click)="$event.stopPropagation(); onClose(sbarAlert)"
      >
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="title">Urgency: {{ sbarAlert.urgency }}</div>
      <div class="info">
        <li><b>Name: </b> {{ sbarAlert.patientName }}</li>
        <li><b>Issue: </b> {{ sbarAlert.issue }}</li>
        <li>
          <b>Hospital: </b> {{ sbarAlert.hospitalName }} -
          {{ sbarAlert.unitName }}
        </li>
      </div>
    </div>
    <ng-template #patientAssignAlert>
      <div class="sbar-alert-item low">
        <div
          class="close cp-btn cp-hvr-info-s m-0"
          aria-label="Close"
          (click)="
            $event.stopPropagation(); onClosePatientAssignAlert(sbarAlert)
          "
        >
          <span aria-hidden="true">&times;</span>
        </div>
        <div class="title">{{ sbarAlert.patientAssignmentMsg }}</div>
      </div>
    </ng-template>
  </ng-container>
</div>
