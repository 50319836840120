import { createAction, props } from "@ngrx/store";
import { PublicHospital } from "../../models/hospital";

export const getHospitalsPatients = createAction(
  "[Patient List] get hospitals and patients",
  props<{ queryParams?: string }>()
);

export const getNewHospitalsPatients = createAction(
  "[Patient List] get new hospitals and patients",
  props<{ queryParams?: string }>()
);

export const getHospitals = createAction("[Patient List] get hospitals");

export const setHospitals = createAction(
  "[Patient List] set hospitals",
  props<{ hospitals: any }>()
);

export const resetHospitals = createAction("[Patient List] reset hospitals");

export const setHospitalFilters = createAction(
  "[Patient List] set hospital filters",
  props<{ hospitalFilter: any }>()
);

export const setPublicHospitals = createAction(
  "[Hospital] Set Public Hospitals",
  props<{ hospitals: PublicHospital[] }>()
);
