import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NcpModalComponent } from "../ncp-modal/ncp-modal.component";
import { MatDialog } from "@angular/material/dialog";

import { Store, select } from "@ngrx/store";
import * as fromNcpSelector from "../../store/selector/ncp.selector";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { NcpList } from "../../type/ncp-data.interface";
import * as fromNcpAction from "../../store/action/ncp.action";

@Component({
  selector: "app-ncp-input",
  templateUrl: "./ncp-input.component.html",
  styleUrls: ["./ncp-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NcpInputComponent implements OnInit {
  @Input() currentPatient = null;
  isEdit: boolean = false;
  ncpEditList: NcpList[];
  ncpList: NcpList[];
  private unsubscribe$: Subject<any> = new Subject<any>();
  get ncpList$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpListData),
      takeUntil(this.unsubscribe$)
    );
  }

  get ncpEditList$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpEditListData),
      takeUntil(this.unsubscribe$)
    );
  }

  constructor(private _dialog: MatDialog, private store: Store<any>) {}

  ngOnInit(): void {}
  openModal() {
    this.ncpEditList$.subscribe((data) => {
      this.ncpEditList = data;
    });
    this.ncpList$.subscribe((data) => {
      this.ncpList = data;
    });
    //passing edit
    this._dialog.open(NcpModalComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        selectedNcp: this.ncpEditList,
        newlyAddedNcp: this.ncpList,
      },
    });
  }

  clearAll() {}
}
