export const bloodProduct = [
  { propName: "prbc", name: "PRBC" },
  { propName: "wholeBlood", name: "Whole Blood" },
  { propName: "ffp", name: "FFP" },
  { propName: "conPlasma", name: "Convalescent plasma" },
  { propName: "coagFactor", name: "Coagulation Factor VII a" },
  { propName: "granulocytes", name: "Granulocytes" },
  { propName: "pronthrombin", name: "Prothrombin complex concentrate" },
  { propName: "cyro", name: "Cryoprecipitate" },
  { propName: "sdp", name: "Single Donor Platelets	" },
  { propName: "rdp", name: "Random Donor Platelets	" },
  { propName: "pRPlasma", name: "Platelet-rich plasma	" },
  { propName: "platelets", name: "Platelets" },
];
