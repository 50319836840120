import * as fromIoReducer from "./io.reducers";
import * as fromIoFormReducer from "./io-form.reducer";
import { AppState } from "../..";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface ioState {
  data: fromIoReducer.State;
  form: fromIoFormReducer.State;
}

export interface State extends AppState {
  io: ioState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromIoReducer.reducer,
  form: fromIoFormReducer.reducer,
};

/** SELECTOR setup */
export const getIoDataState = createFeatureSelector<ioState>("io");

export const getIoData = createSelector(getIoDataState, (state) => state.data);

export const getClickedDay = createSelector(
  getIoData,
  (state) => state.clickedDay
);

export const getChangedTime = createSelector(
  getIoData,
  (state) => state.changedTime
);

export const getOutputProps = createSelector(
  getIoData,
  (state) => state.outputProps
);

// Form
export const getIoForm = createSelector(getIoDataState, (state) => state.form);
