import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IcuDaysPipe } from "./icu-days.pipe";

@NgModule({
  declarations: [IcuDaysPipe],
  imports: [CommonModule],
  exports: [IcuDaysPipe],
  providers: [IcuDaysPipe],
})
export class IcuDaysPipeModule {}
