import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "findObjectInArrayWithKey",
})
export class FindObjectInArrayWithKeyPipe implements PipeTransform {
  transform(value: any[], objectKey: string, keyToFind: string): unknown {
    if (!keyToFind || !value?.length) return null;

    return value?.find((arrayObj) => arrayObj[objectKey] == keyToFind);
  }
}
