import { createAction, props } from "@ngrx/store";
import { PresetRequest } from "src/app/models/orderable/PresetRequest.model";
import { Orderable } from "../../../models/Orderable.model";
import { OrderableRequestBEApiResponse } from "../../../models/orderable/OrderableRequest.model";

export enum OrderableApiActionTypes {
  AddOrderableSuccess = "[Orderable Api] Add Orderable Success",
  AddOrderableFailure = "[Orderable Api] Add Orderable Failure",
  UpdateOrderableSuccess = "[Orderable Api] Update Orderable Success",
  UpdateOrderableFailure = "[Orderable Api] Update Orderable Failure",
  DeleteOrderableSuccess = "[Orderable Api] Delete Orderable Success",
  DeleteOrderableFailure = "[Orderable Api] Delete Orderable Failure",
  GetOrderableSuccess = "[Orderable Api] Get Orderable Success",
  GetOrderableFailure = "[Orderable Api] Get Orderable Failure",
  GetOrderablesSuccess = "[Orderable Api] Get Orderables Success",
  GetOrderablesFailure = "[Orderable Api] Get Orderables Failure",
  UpdateOrderablesSuccess = "[Orderable Api] Update Orderables Success",
  UpdateOrderablesFailure = "[Orderable Api] Update Orderables Failure",
  SubmitOrderableRequestSuccess = "[Orderable Api] Submit Orderable Request Success",
  SubmitOrderableRequestFailure = "[Orderable Api] Submit Orderable Request Failure",
  GetOrderableRequestSuccess = "[Orderable Api] Get Orderable Request Success",
  GetOrderableRequestFailure = "[Orderable Api] Get Orderable Request Failure",
  AcceptOrderableRequestSuccess = "[Orderable Api] Accept Orderable Request Success",
  RejectOrderableRequestSuccess = "[Orderable Api] Reject Orderable Request Success",
}

export const addOrderableSuccess = createAction(
  OrderableApiActionTypes.AddOrderableSuccess,
  props<{ orderable: Orderable }>()
);

export const addOrderableFailure = createAction(
  OrderableApiActionTypes.AddOrderableFailure,
  props<{ error: any }>()
);

export const updateOrderableSuccess = createAction(
  OrderableApiActionTypes.UpdateOrderableSuccess,
  props<{ orderable: Orderable }>()
);

export const updateOrderableFailure = createAction(
  OrderableApiActionTypes.UpdateOrderableFailure,
  props<{ error: any }>()
);

export const deleteOrderableSuccess = createAction(
  OrderableApiActionTypes.DeleteOrderableSuccess
);

export const deleteOrderableFailure = createAction(
  OrderableApiActionTypes.DeleteOrderableFailure,
  props<{ error: string }>()
);

export const getOrderableSuccess = createAction(
  OrderableApiActionTypes.GetOrderableSuccess,
  props<{ orderable: Orderable }>()
);

export const getOrderableFailure = createAction(
  OrderableApiActionTypes.GetOrderableFailure,
  props<{ error: string }>()
);

export const getOrderablesSuccess = createAction(
  OrderableApiActionTypes.GetOrderablesSuccess,
  props<{ orderables: Orderable[] }>()
);

export const getOrderablesFailure = createAction(
  OrderableApiActionTypes.GetOrderablesFailure,
  props<{ error: string }>()
);

export const updateOrderablesSuccess = createAction(
  OrderableApiActionTypes.UpdateOrderablesSuccess,
  props<{ orderables: Orderable[] }>()
);

export const updateOrderablesFailure = createAction(
  OrderableApiActionTypes.UpdateOrderablesFailure,
  props<{ error: string }>()
);

export const submitOrderableRequestSuccess = createAction(
  OrderableApiActionTypes.SubmitOrderableRequestSuccess
);

export const submitOrderableRequestFailure = createAction(
  OrderableApiActionTypes.SubmitOrderableRequestFailure,
  props<{ error: string }>()
);

export const getOrderableRequestSuccess = createAction(
  OrderableApiActionTypes.GetOrderableRequestSuccess,
  props<{ data: OrderableRequestBEApiResponse }>()
);

export const getOrderableRequestFailure = createAction(
  OrderableApiActionTypes.GetOrderableRequestFailure,
  props<{ error: string }>()
);

export const acceptOrderableRequestSuccess = createAction(
  OrderableApiActionTypes.AcceptOrderableRequestSuccess,
  props<{ id: string; listType: string }>()
);

export const rejectOrderableRequestSuccess = createAction(
  OrderableApiActionTypes.RejectOrderableRequestSuccess,
  props<{ id: string }>()
);
