import { Pipe, PipeTransform } from "@angular/core";

const labName = new Map([
  ["RFT", ["Glucose", "Creatinine", "Sodium", "Potassium", "Chloride", "Urea"]],
  [
    "CBC",
    [
      "Hb",
      "Platelets",
      "MCV",
      "Neutrophils",
      "Eosinophils",
      "Lymphocytes",
      "Atypicals",
      "Basophils",
      "Total count",
    ],
  ],
  [
    "ABG",
    [
      "pH",
      "PaCO2",
      "PaO2",
      "Bicarb",
      "Lactic",
      "Sodium",
      "Potassium",
      "Creatinine",
    ],
  ],
  [
    "Electrolytes",
    [
      "Sodium",
      "Potassium",
      "Carbon dioxide, total",
      "Magnesium",
      "Calcium",
      "Phosphate",
      "Chloride",
    ],
  ],
]);

interface Attributes {
  [key: string]: { value: number };
}

@Pipe({
  name: "filterLabDatas",
})
export class FilterLabDatasPipe implements PipeTransform {
  transform(attribute: Attributes, lab: string): Attributes {
    if (attribute) {
      const asArray = Object.entries(attribute);
      const filtered = asArray.filter(([key, value]) =>
        labName.get(lab).includes(key)
      );
      return (<any>Object).fromEntries(filtered);
    }
    return labName
      .get(lab)
      .reduce((lab, attribute) => ((lab[attribute] = ""), lab), {});
  }
}
