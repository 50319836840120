import { Action, createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { PatientMonitor } from "../../models/patientMonitor/patientMonitor.model";
import {
  PatientMonitorApiActions,
  PatientMonitorListPageActions,
} from "../actions";

export interface State extends EntityState<PatientMonitor> {}

export const adapter: EntityAdapter<PatientMonitor> =
  createEntityAdapter<PatientMonitor>({
    selectId: (monitor: PatientMonitor) => monitor._id,
  });

export const initialState: State = adapter.getInitialState();

const patientMonitorReducer = createReducer(
  initialState,
  on(PatientMonitorApiActions.addAllPatientMonitors, (state, { monitors }) => {
    const mappedDevices = monitors.map((device) => ({
      ...device,
      online: false,
    }));

    return adapter.setAll(mappedDevices, state);
  }),
  on(PatientMonitorApiActions.addPatientMonitor, (state, { monitor }) => {
    return adapter.addOne({ ...monitor, online: false }, state);
  }),
  on(PatientMonitorApiActions.deletePatientMonitor, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  on(PatientMonitorApiActions.updatePatientMonitor, (state, { monitor }) =>
    adapter.updateOne(monitor, state)
  ),
  on(
    PatientMonitorListPageActions.setPatientMonitorStatus,
    (state, { monitor, online }) => {
      const data = { id: monitor._id, changes: { ...monitor, online } };

      return adapter.updateOne(data, state);
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return patientMonitorReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectPatientMonitorIds = selectIds;

export const selectPatientMonitorEntities = selectEntities;

export const selectAllPatientMonitors = selectAll;

export const selectPatientMonitorTotal = selectTotal;
