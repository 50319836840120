import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VitalViewService {
  public transformAirway(airway: string): string {
    if (airway === 'High Flow') {
      return 'High Flow Cannula';
    } else if (airway === 'Venturi') {
      return 'Venturi Mask';
    } else if (airway === 'Cannula') {
      return 'Nasal Cannula';
    }

    return airway;
  }
}
