import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientType } from "src/app/models/patient";
import { Patient } from "src/app/models/patient";
import { Vitals } from "../../models/Vital";
import { getActiveAssessment, getVitalClickedDay } from "../../store/reducers";
import { ACTIVE_ASSESSMENT } from "../../support/vitals.support";
import * as vitalActions from "src/app/vitals/store/actions/vitals.actions";
import { VitalInputService } from "./vital-input.service";
import { TimezoneService } from "src/app/services/timezone.service";

@Component({
  selector: "app-vital-input",
  templateUrl: "./vital-input.component.html",
  styleUrls: ["./vital-input.component.scss"],
})
export class VitalInputComponent implements OnInit, OnDestroy {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description To observe the clicked day
   * @type {Observable}
   */
  public vitalClickedDay$ = this.store.pipe(
    select(getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  public activeAssessment$ = this.store.pipe(
    select(getActiveAssessment),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Holds the value of clicked day from the observable
   * @type {Vitals}
   */
  clickedDay: Vitals | null = null;

  /**
   * @description Holds the current tab index
   * Change the indices respectively if you change the order of the tabs
   * Tabindices are as follows
   * 0 - vitals gcs form
   * 1 - vents form
   * 2 - pain form
   * 3 - rass form
   * 4 - fall form
   * 5 - braden form
   * @type {number}
   */
  currentTabIndex: number = 0;

  currentPatient: Patient;

  private _timeZone = inject(TimezoneService);
  /**
   * Set the min and max date based on admit date & tommorow
   */
  minDate: string = this._timeZone.getCurrentTimestampStr();
  maxDate: string = this._timeZone.getCurrentTimestampStr();

  /**
   * @description Triggers when the tab changes
   * @param {MatTabChangeEvent} event
   * @author Suraj Shenoy
   * @date 23 June 2021
   */
  tabChanged(event): void {
    this.currentTabIndex = event.index;
  }

  /**
   * @description Input data of the current patient
   */
  @Input()
  set currPatient(patient: Patient) {
    this.currentPatient = patient;
    this.setDate();
  }

  @Input()
  entryType: string = "vital";

  @Input() set patVitals(patVitals) {
    if (patVitals?.length) this._vitalsService.passpatientVitals(patVitals);
  }

  TAB_INDEX_MAP = {
    [PatientType.Neonatal]: {
      [ACTIVE_ASSESSMENT.CRIB]: 0,
      [ACTIVE_ASSESSMENT.APGAR]: 1,
      [ACTIVE_ASSESSMENT.SNAPP]: 2,
      [ACTIVE_ASSESSMENT.THOMPSON]: 3,
    },
    [PatientType.Pediatric]: {
      [ACTIVE_ASSESSMENT.PAIN]: 0,
      [ACTIVE_ASSESSMENT.RASS]: 1,
      [ACTIVE_ASSESSMENT.FALL]: 2,
      [ACTIVE_ASSESSMENT.BRADEN]: 3,
    },
    [PatientType.Adult]: {
      [ACTIVE_ASSESSMENT.PAIN]: 0,
      [ACTIVE_ASSESSMENT.RASS]: 1,
      [ACTIVE_ASSESSMENT.FALL]: 2,
      [ACTIVE_ASSESSMENT.BRADEN]: 3,
    },
  };

  get PatientType() {
    return PatientType;
  }

  constructor(
    private store: Store<any>,
    private _vitalsService: VitalInputService
  ) {}

  ngOnInit(): void {
    this.listenToClickedDay();
    this.listenToAssessmentChange();
  }

  /**
   * @description To set the default date
   * @author Suraj Shenoy
   * @date 26th Jul 2021
   */
  setDate(): void {
    this.minDate = this._timeZone.transformIntoTimezoneStr(
      this.currentPatient.ICUAdmitDate
    );
  }

  /**
   * @description When the date column is clicked
   */
  listenToClickedDay(): void {
    this.vitalClickedDay$.subscribe((clickedDay) => {
      this.clickedDay = clickedDay;
    });
  }

  /**
   * @description when assessment view changed
   */
  listenToAssessmentChange(): void {
    this.activeAssessment$.subscribe((activeAssessment) => {
      this.currentTabIndex =
        activeAssessment && this.entryType != "vital"
          ? this.TAB_INDEX_MAP[this.currentPatient?.patientType][
              activeAssessment
            ]
          : 0;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
