import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-procedure-history-view',
  template: `

  `,
  styleUrls: ['../details-modal-common.scss']
})
export class ProcedureHistoryViewComponent {
  @Input() order;
}

