<div
  class="bg-white"
  #quillEditor
  [ngClass]="{ hasSelected: isSelected, 'text-editor-border': hasBorder }"
>
  <div
    class="text-editor-content"
    *ngIf="!isSelected"
    [innerHtml]="contentControl?.value | sanitizeHtml"
  ></div>
  <quill-editor
    *ngIf="isSelected"
    [ngStyle]="{ 'height.rem': height }"
    class="cp-quill-editor cp-quill-editor--toolbar-scroll"
    (onEditorCreated)="setEditor($event)"
    [formControl]="contentControl"
    [modules]="quillConfig"
    [bounds]="quillEditor"
  ></quill-editor>
</div>

<div *ngIf="serverError" class="error-text mt">Unable to save notes.</div>
