import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, map, switchMap } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as _moment from "moment-timezone";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment-timezone";

import { AudioService } from "../../shared/audio/audio.service";
import { RalertApiService } from "../services/ralert-api.service";
import { displayErrorMessage } from "../../store/actions/root.actions";
import { RAlertApiActions, RAlertMessageListPageActions } from "../actions";

const moment = _rollupMoment || _moment;
const alertTime = 300000;

@Injectable()
export class RalertMessagesEffects {
  constructor(
    private _ralertApiService: RalertApiService,
    private actions$: Actions,
    private _audioService: AudioService
  ) {}

  /**
   * Executes on loadRAlertMessages action.
   * Whenever ralert list page gets load the effect gets called.
   */
  loadRAlertMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertMessageListPageActions.loadRAlertMessages),
      switchMap(() =>
        this._ralertApiService.getMessages().pipe(
          map((data) => {
            let messages =
              data.data && data.data.messages ? data.data.messages : [];
            const newMessages = messages.map((message) => {
              const currentTime = moment.tz("Asia/Kolkata");
              const momentTimestamp = moment.tz(
                message.timestamp,
                "Asia/Kolkata"
              );

              const timeDiff = currentTime.diff(momentTimestamp);
              let id;

              if (timeDiff < alertTime) {
                id = setTimeout(() => {
                  this._audioService.playRalertAlertAudio(message.message);
                }, alertTime - timeDiff);
              }

              return { ...message, momentTimestamp, alertTimeoutId: id };
            });

            return RAlertApiActions.addAllRalertMessages({
              messages: newMessages,
            });
          }),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  clickAcknowledgeBtn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertMessageListPageActions.clickAcknowledgeMessageBtn),
      switchMap(({ id, data }) =>
        this._ralertApiService.acknowledgeMessage(id, data).pipe(
          map((data) => RAlertApiActions.acknowledgeMessage({ id })),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );
}
