import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { PatientTypes } from "src/app/data/patientType-data";

function checkBlankVitals(
  control: AbstractControl
): { [key: string]: any } | null {
  const vitalForm = control.get("vitalsForm");

  const temp = vitalForm.get("daysTemperature");
  const avpu = vitalForm.get("daysAVPU");
  const hr = vitalForm.get("daysHR");
  const rr = vitalForm.get("daysRR");
  const bp = vitalForm.get("daysBP");
  const map = vitalForm.get("daysMAP");
  const cvp = vitalForm.get("daysCVP");
  const sp = vitalForm.get("daysSpO2");
  const fi = vitalForm.get("daysFiO2");

  // pupillary form
  const pupillaryForm = control.get("pupillaryForm");
  const leftCond = pupillaryForm.get("daysLeftPupilaryCondition");
  const leftSize = pupillaryForm.get("daysLeftPupillarySize");
  const rightCond = pupillaryForm.get("daysRightPupilaryCondition");
  const rightSize = pupillaryForm.get("daysRightPupillarySize");

  // gcs values
  const gcsForm = control.get("gcsForm");
  const eyes = gcsForm.get("daysGCSeyes");
  const verbal = gcsForm.get("daysGCSverbal");
  const motor = gcsForm.get("daysGCSmotor");

  let errObj = {};

  // check for blank
  if (
    !(
      (temp.value == "" || temp.value == null) &&
      (avpu.value == "" || avpu.value == null) &&
      (hr.value == "" || hr.value == null) &&
      (rr.value == "" || rr.value == null) &&
      (bp.value == "" || bp.value == null) &&
      (map.value == "" || map.value == null) &&
      map.value != 0 &&
      (cvp.value == "" || cvp.value == null) &&
      cvp.value != 0 &&
      (sp.value == "" || sp.value == null) &&
      (fi.value == "" || fi.value == null)
    ) ||
    !(
      (eyes.value == "" || eyes.value == null) &&
      (verbal.value === "" || verbal.value === null) &&
      (motor.value == "" || motor.value == null)
    ) ||
    !(
      (leftCond.value == "" || leftCond.value == null) &&
      (leftSize.value == "" || leftSize.value == null) &&
      (rightCond.value == "" || rightCond.value == null) &&
      (rightSize.value == "" || rightSize.value == null)
    )
  ) {
    delete errObj["blankForm"];
  } else {
    errObj["blankForm"] = true;
  }

  // check if errObj is empty
  if (Object.keys(errObj).length === 0) {
    return null;
  } else {
    return errObj;
  }
}

function vitalFormValidator(patientType: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const patientPos = control.get("daysPatPosition").value;
    const patientPosOther = control.get("daysPatPositionOther").value
      ? control.get("daysPatPositionOther").value.trim()
      : null;
    const rr = control.get("daysRR").value;
    const sp = control.get("daysSpO2").value;
    const fi = control.get("daysFiO2").value;

    const errObj: ValidationErrors = {};

    if (patientType === PatientTypes.ADULT) {
      if (rr || sp || fi) {
        errObj["roxValues"] = true;
      }
      if (rr && sp && fi) {
        delete errObj["roxValues"];
      }
    } else if (
      patientType === PatientTypes.NEONATAL ||
      patientType === PatientTypes.PEDIATRIC
    ) {
      if (rr) {
        errObj["roxValues"] = true;
      }
      if (rr) {
        delete errObj["roxValues"];
      }
    }

    if (patientPos === "Other" && !patientPosOther) {
      errObj["patientPos"] = "Please specify Patient Position";
    }

    return errObj;
  };
}

function checkBlankGcs(
  control: AbstractControl
): { [key: string]: any } | null {
  const eyes =
    control.get("daysGCSeyes").value === null ||
    typeof control.get("daysGCSeyes").value === "undefined"
      ? false
      : true;
  const verbal =
    control.get("daysGCSverbal").value === null ||
    typeof control.get("daysGCSverbal").value === "undefined"
      ? false
      : true;
  const motor =
    control.get("daysGCSmotor").value === null ||
    typeof control.get("daysGCSmotor").value === "undefined"
      ? false
      : true;

  let errObj = {};

  if (eyes || verbal || motor) {
    if (!(eyes && verbal && motor)) {
      errObj["gcs_error"] = "All the three GCS values needs to be populated";
    }
  }

  // check if errObj is empty
  if (Object.keys(errObj).length === 0) {
    return null;
  } else {
    return errObj;
  }
}

function checkPupillaryBlank(
  control: AbstractControl
): { [key: string]: any } | null {
  const daysLeftPupillarySize =
    control.get("daysLeftPupillarySize").value === null ||
    typeof control.get("daysLeftPupillarySize").value === "undefined"
      ? false
      : true;
  const daysRightPupillarySize =
    control.get("daysRightPupillarySize").value === null ||
    typeof control.get("daysRightPupillarySize").value === "undefined"
      ? false
      : true;
  const daysLeftPupilaryCondition =
    control.get("daysLeftPupilaryCondition").value === null ||
    typeof control.get("daysLeftPupilaryCondition").value === "undefined"
      ? false
      : true;

  const daysRightPupilaryCondition =
    control.get("daysRightPupilaryCondition").value === null ||
    typeof control.get("daysRightPupilaryCondition").value === "undefined"
      ? false
      : true;

  let errObj = {};

  if (
    daysLeftPupillarySize ||
    daysRightPupillarySize ||
    daysLeftPupilaryCondition ||
    daysRightPupilaryCondition
  ) {
    if (
      !(
        daysLeftPupillarySize &&
        daysRightPupillarySize &&
        daysLeftPupilaryCondition &&
        daysRightPupilaryCondition
      )
    ) {
      errObj["pupillary_error"] = "All the values needs to be populated";
    }
  }

  // check if errObj is empty
  if (Object.keys(errObj).length === 0) {
    return null;
  } else {
    return errObj;
  }
}

const THERAPY_DEVICE_MAP = {
  "Room air": "isRoomair",
  "Nasal cannula": "isNasalCannula",
  "Face mask": "isFaceMask",
  "Venturi mask": "isVenturiMask",
  "Non-rebreathing mask": "isNonBreatheMask",
  HFNC: "isHfnc",
  "T piece": "isTpiece",
  "Trach mask": "isTrachMask",
  "Ambu bag": "isAmbubag",
  "Invasive ventilation": "isInvasive",
  NIV: "isNiv",
  "Oxygen hood": "isOxygenHood",
  "Nasal Bubble CPAP": "isNasalCPAP",
};

/**
 * @description This has the combination of the therapy devices that can coexist with each other
 * Follow https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=0 for more details
 * @author Suraj Shenoy
 * @date Jul 8 2021
 * @param {AbstractControl} control
 * @returns Object or null
 */
function checkTherapyDevice(
  control: AbstractControl
): { [key: string]: any } | null {
  let therapyDevice = control.value;
  let errObj = {};

  // If blank return null
  if (!therapyDevice?.length) {
    return null;
  }

  const fieldVariables = {
    isRoomair: false,
    isNasalCannula: false,
    isFaceMask: false,
    isVenturiMask: false,
    isNonBreatheMask: false,
    isHfnc: false,
    isTpiece: false,
    isTrachMask: false,
    isAmbubag: false,
    isInvasive: false,
    isNiv: false,
    isOxygenHood: false,
    isNasalCPAP: false,
  };

  therapyDevice.forEach((device) => {
    if (
      device in THERAPY_DEVICE_MAP &&
      THERAPY_DEVICE_MAP[device] in fieldVariables
    )
      fieldVariables[THERAPY_DEVICE_MAP[device]] = true;
  });

  const {
    isRoomair,
    isNasalCannula,
    isFaceMask,
    isVenturiMask,
    isNonBreatheMask,
    isHfnc,
    isTpiece,
    isTrachMask,
    isAmbubag,
    isInvasive,
    isNiv,
    isOxygenHood,
    isNasalCPAP,
  } = fieldVariables;

  const onlyTheseCoExist = (...ventNames) => {
    const invalidKeys = Object.keys(fieldVariables).filter(
      (key) => !ventNames.includes(key)
    );
    return areTheseCoExist(...invalidKeys);
  };

  const areTheseCoExist = (...ventNames) => {
    return ventNames.some((key) => fieldVariables[key]);
  };

  // room air cant coexist with others
  if (isRoomair && therapyDevice.length > 1) {
    return { therapyDeviceError: "Room air cant coexist with other devices" };
  }

  // Invasive ventilation cant coexist with others
  if (isInvasive && onlyTheseCoExist("isOxygenHood", "isInvasive")) {
    return {
      therapyDeviceError:
        "Invasive ventilation can coexist only with Oxygen hood",
    };
  }

  // check for nasal cannula
  if (isNasalCannula && areTheseCoExist("isInvasiv", "isHfnc")) {
    return {
      therapyDeviceError:
        "Nasal cannula cant coexist with Invasive ventilation and HFNC",
    };
  }

  if (isNiv && onlyTheseCoExist("isNiv", "isNasalCannula", "isNasalCPAP")) {
    return {
      therapyDeviceError:
        "NIV can coexist only with Nasal canula and Nasal bubble CPAP ",
    };
  }

  if (
    isFaceMask &&
    onlyTheseCoExist(
      "isNasalCannula",
      "isFaceMask",
      "isHfnc",
      "isTpiece",
      "isTrachMask"
    )
  ) {
    return {
      therapyDeviceError:
        "Face mask can coexist only with Nasal cannula, HFNC, T piece, Trach  mask",
    };
  }

  if (
    isVenturiMask &&
    onlyTheseCoExist(
      "isVenturiMask",
      "isNasalCannula",
      "isTpiece",
      "isTrachMask"
    )
  ) {
    return {
      therapyDeviceError:
        "Venturi mask can coexist only with Nasal cannula, Venturi mask, T piece, Trach mask",
    };
  }

  if (
    isNonBreatheMask &&
    onlyTheseCoExist(
      "isNonBreatheMask",
      "isNasalCannula",
      "isHfnc",
      "isTpiece",
      "isTrachMask"
    )
  ) {
    return {
      therapyDeviceError:
        "Non-rebreathing mask can coexist only with Nasal cannula, HFNC, T piece, Trach mask",
    };
  }

  if (
    isHfnc &&
    areTheseCoExist(
      "isNasalCannula",
      "isVenturiMask",
      "isAmbubag",
      "isNiv",
      "isInvasive"
    )
  ) {
    return {
      therapyDeviceError:
        "HFNC cant coexits with Nasal cannula, Venturi mask, Ambu bag, NIV, Invasive ventilation",
    };
  }

  if (
    isTpiece &&
    onlyTheseCoExist(
      "isTpiece",
      "isNasalCannula",
      "isFaceMask",
      "isVenturiMask",
      "isNonBreatheMask"
    )
  ) {
    return {
      therapyDeviceError:
        "T piece can coexist only with Nasal cannula, Face mask, Venturi mask, Non-rebreathing mask, T piece",
    };
  }

  if (
    isAmbubag &&
    areTheseCoExist(
      "isFaceMask",
      "isNonBreatheMask",
      "isTpiece",
      "isTrachMask",
      "isNiv",
      "isInvasive"
    )
  ) {
    return {
      therapyDeviceError:
        "Ambu bag cant coexits with Face mask, Non-rebreathing mask, T piece, Trach mask, NIV, Invasive ventilation",
    };
  }

  if (
    isTrachMask &&
    onlyTheseCoExist(
      "isTrachMask",
      "isNasalCannula",
      "isFaceMask",
      "isNonBreatheMask"
    )
  ) {
    return {
      therapyDeviceError:
        "Trach mask can coexist only with Nasal cannula, Face mask, Non-rebreathing mask",
    };
  }

  if (
    isOxygenHood &&
    areTheseCoExist(
      "isFaceMask",
      "isVenturiMask",
      "isNonBreatheMask",
      "isTpiece",
      "isTrachMask",
      "isNiv"
    )
  ) {
    return {
      therapyDeviceError:
        "Oxygen hood cant coexits with Face mask, Venturi mask, Non-rebreathing mask, T piece, Trach mask, NIV",
    };
  }

  if (
    isNasalCPAP &&
    areTheseCoExist(
      "isFaceMask",
      "isVenturiMask",
      "isNonBreatheMask",
      "isTpiece",
      "isTrachMask",
      "isInvasive"
    )
  ) {
    return {
      therapyDeviceError:
        "Nasal bubble cant coexits with Face mask, Venturi mask, Non-rebreathing mask, T piece, Trach mask, Invasive",
    };
  }

  return null;
}

function checkBlankVents(
  control: AbstractControl
): { [key: string]: any } | null {
  const therapyDevice = control.get("therapyForm").value.daysTherapyDevice;
  const airwayDevice = control.get("airwayForm").value.daysVentAirway;

  if (!therapyDevice?.length && !airwayDevice?.length) {
    return { blankForm: true };
  } else {
    return null;
  }
}

// export const bradenFormValidator = (formGroup: FormGroup) => {
//   // extracting timestamp from other fields for validation
//   const { timestamp, ...formFields } = formGroup.value;
//   if (atLeastOneValueShouldBeThere(formFields)) {
//     return null;
//   }

//   return { blankForm: true };
// };

// const atLeastOneValueShouldBeThere = (obj) => {
//   return Object.values(obj).some((value) => value != null);
// };

export const bradenFormValidator = (formGroup: UntypedFormGroup) => {
  // extracting timestamp from other fields for validation
  const { timestamp, ...formFields } = formGroup.value;
  if (allValuesShouldBeThere(formFields)) {
    return null;
  }

  return { blankForm: true };
};

const allValuesShouldBeThere = (obj) => {
  return Object.values(obj).every((value) => value != null);
};

export {
  checkBlankVitals,
  checkBlankGcs,
  checkPupillaryBlank,
  checkTherapyDevice,
  checkBlankVents,
  vitalFormValidator,
};
