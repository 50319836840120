<div
  class="popover-content"
  *ngIf="content"
  [class]="positionClass"
  [ngClass]="{ 'popover-content--max-width': contentType === 'string' }"
>
  <div class="content">
    <p class="title" *ngIf="title">
      {{ title }}
    </p>
    <ng-container [ngSwitch]="contentType">
      <ng-container *ngSwitchCase="'string'">
        <div>
          <div [innerText]="content"></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'template'">
        <ng-container
          *ngTemplateOutlet="content; context: context"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="close-icon" *ngIf="showCloseButton">
    <img
      (click)="closeToolTip()"
      src="../../../assets/icons/cancel_rounded.svg"
    />
  </div>
</div>
