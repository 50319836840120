<section class="tab-section">
  <cp-toggle-header
    [tabs]="toggleHeaderPayload"
    [activeTab]="activeTab"
    [customTabValue]="assessmentTabs.INF"
    (emitActiveTab)="setActiveTab($event)"
  >
    <button
      class="inf-mat-button"
      [ngClass]="{ active: activeTab === assessmentTabs.INF }"
      (click)="setActiveTab(assessmentTabs.INF)"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      Infection bundle
      <img src="assets/icons/arrow_down.svg" alt="" />
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let option of infectionDropDownList"
        (click)="activeInfBundle = option.value"
      >
        {{ option.label }}
      </button>
    </mat-menu>
  </cp-toggle-header>

  <ng-container [ngSwitch]="activeTab"
    ><app-assessment *ngSwitchCase="assessmentTabs.ASSESSMENT"></app-assessment>
    <section *ngSwitchCase="assessmentTabs.NCP">
      <div class="vital-view-entry">
        <app-ncp-view></app-ncp-view>
        <ng-container *ngxPermissionsOnly="['create_vital']">
          <app-ncp-input
            [currentPatient]="patientHeader$ | async"
          ></app-ncp-input>
        </ng-container>
      </div>
    </section>
    <app-infection-bundle
      *ngSwitchCase="assessmentTabs.INF"
    ></app-infection-bundle>
  </ng-container>
</section>
