import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownTransformPipe } from "./dropdown-tranform.pipe";

@NgModule({
  declarations: [DropdownTransformPipe],
  imports: [CommonModule],
  exports: [DropdownTransformPipe],
  providers: [DropdownTransformPipe],
})
export class DropdownTransformModule {}
