import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  DocumentAttributes,
  Documents,
} from "src/app/labs-scans-module/labs-scans/models/labs-scans";

export interface AttributeResult {
  attributes: DocumentAttributes;
  reportedAt: Date;
}

@Injectable({
  providedIn: "root",
})
export class LabTrendService {
  constructor() {}

  findAttributeAndTimestamp(
    documents: Documents[],
    attributeName: string
  ): Observable<AttributeResult[]> {
    const results = documents.filter(
      (item) => attributeName in item.attributes
    );
    if (results.length > 0) {
      const attributeResults = results.map((result) => ({
        attributes: result.attributes[attributeName],
        reportedAt: result.reportedAt,
      }));
      return of(attributeResults);
    } else {
      return of(null);
    }
  }
}
