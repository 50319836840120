import { createAction, props } from "@ngrx/store";

export const getIo = createAction(
  "[Patient IO] get io",
  props<{ CPMRN: string; encounters: number }>()
);

export const setIo = createAction(
  "[Patient IO] set io",
  props<{
    ioObj: any;
    intakeProps: any;
    outputProps: any;
    proceduresConnected: any;
  }>()
);

export const setClickedTime = createAction(
  "[Patient IO] set clicked day",
  props<{ day: any }>()
);

export const setChangedTime = createAction(
  "[Patient IO] set changed time",
  props<{ dateObj: any }>()
);

export const updateIo = createAction(
  "[Patient IO] update Io",
  props<{ obj: any; CPMRN: string; ioType: string; encounters: number }>()
);

export const submitForm = createAction(
  "[Patient IO] io form submit",
  props<{ loadType: string }>()
);

export const submitSuccess = createAction("[Patient IO] io form success");

export const hideSuccess = createAction("[Patient IO] io form hide success");

export const submitFail = createAction(
  "[Patient IO] io form fail",
  props<{ error: any }>()
);

export const resetClickedTime = createAction("[Patient IO] reset clicked day");
