import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SecurityContext,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Directive({
  selector: "[appTextHighlight]",
})
export class TextHighlightDirective implements OnChanges, AfterViewInit {
  @Input() appTextHighlight: string = "";

  constructor(private _ele: ElementRef, private _sanitizer: DomSanitizer) {}
  ngAfterViewInit(): void {
    this.setSearchColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appTextHighlight) {
      this.setSearchColor();
    }
  }

  setSearchColor() {
    const hasMarkedEl = (htmlStr: string) => {
      const regex = /<mark\b[^>]*>[\s\S]*?<\/mark>/i;
      return regex.test(htmlStr);
    };
    const content = this._ele.nativeElement as HTMLElement;
    const text = (this._ele.nativeElement as HTMLElement).textContent;
    if (!text) return;
    const regex = new RegExp(this.appTextHighlight, "gi");
    const isTextMatch = regex.test(text);
    const isHilightEmpty = this.appTextHighlight === "";
    if ((isHilightEmpty || !isTextMatch) && hasMarkedEl(content.innerHTML)) {
      content.innerText = text;
      return;
    }
    if (isHilightEmpty) return;
    const newText = text.replace(regex, (match: string) => {
      return `<mark class="cp-highlight-text">${match}</mark>`;
    });
    const sanitized = this._sanitizer.sanitize(SecurityContext.HTML, newText);
    if (hasMarkedEl(sanitized)) {
      content.innerHTML = sanitized;
    }
  }
}
