import { Component, Inject, OnInit } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { AlertInline } from "../model/iris-component.model";

@Component({
  selector: "app-alert-inline",
  templateUrl: "./alert-inline.component.html",
  styleUrls: ["./alert-inline.component.scss"],
})
export class AlertInlineComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: AlertInline,
    private snackbarRef: MatSnackBarRef<AlertInlineComponent>
  ) {}

  ngOnInit(): void {}

  /**
   * @description This is to get the type of the icon based on the data
   * @author Suraj Shenoy
   * @date Jan 31 2022
   * @returns {string} Type of the icon
   */
  getAlertIcon(): string {
    switch (this.data.type) {
      case "Info":
        return "info";
      case "Warning":
        return "warning";
      case "Success":
        return "check_circle";
      case "Error":
        return "error";
    }
  }

  /**
   * @description To close the alert
   * @author Suraj Shenoy
   * @date Jan 31 2022
   */
  close() {
    this.snackbarRef.dismiss();
  }
}
