<div class="vital-info">
  <div class="left-side">
    <div
      *ngIf="vitals?.length"
      class="day-selector display-flex justify-content-around cp-pr-3"
    >
      <button
        [disabled]="disablePrev"
        matRipple
        (click)="changeDay('prev')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_left</mat-icon
        >
      </button>

      <mat-form-field [floatLabel]="'always'">
        <mat-select
          [value]="selectedDay"
          (selectionChange)="dayChanged($event.value)"
          class="iris-input"
        >
          <mat-option *ngFor="let day of vitals | uniqueDay" [value]="day"
            >Day {{ day }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        [disabled]="disableNext"
        matRipple
        (click)="changeDay('next')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_right</mat-icon
        >
      </button>

      <mat-form-field floatLabel="always" class="date-selector">
        <input
          class="date-picker-input"
          [matDatepicker]="dob"
          placeholder="yyyy/mm/dd"
          [value]="selectedDate"
          [matDatepickerFilter]="dateFilter"
          (dateChange)="onClickOfDate($event?.value)"
          matInput
        />
        <mat-label></mat-label>
        <mat-datepicker-toggle matSuffix [for]="dob"> </mat-datepicker-toggle>
        <mat-datepicker #dob></mat-datepicker>
      </mat-form-field>

      <ng-container *ngIf="vitals?.length && showVitalReviewButtons">
        <mat-checkbox
          [(ngModel)]="collapseValue"
          (change)="onCollapseChange($event)"
          class="cp-mat-checkbox clear-all-text collapse-cells align-self-end hide-on-print"
          >Collapse empty rows</mat-checkbox
        >
      </ng-container>
    </div>
  </div>

  <div class="right-side cp-ml-auto">
    <div class="nice-chart-btn">
      <button
        *ngIf="
          displayGraphButtons &&
          currPatient?.patientType === patientType.Neonatal
        "
        class="button-secondary"
        (click)="openNiceChartTrends()"
      >
        View NICE chart
      </button>
      <button
        *ngIf="
          displayGraphButtons &&
          currPatient?.patientType === patientType.Neonatal
        "
        class="button-secondary"
        (click)="openGrownTrends()"
      >
        View Growth chart
      </button>
      <ng-container *ngIf="showVitalReviewButtons">
        <ng-container *ngIf="vitals?.length && !hideGenerateButton">
          <button
            (click)="openPrintView()"
            mat-raised-btn
            class="button-primary cp-ml-auto"
          >
            Review
            <mat-icon class="display-flex align-item-center cp-ml-1 scale"
              >open_in_new</mat-icon
            >
          </button>
        </ng-container>
        <ng-container *ngIf="vitals?.length && !!hideGenerateButton">
          <button
            (click)="getPDF()"
            mat-raised-btn
            class="button-primary cp-ml-auto hide-on-print"
          >
            Print
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #newGenderBlock>
  <div class="iris-modal-container">
    <div class="form-title">
      <ng-container *ngIf="isGenderOthers"
        >Since the gender of the patient is ‘Other’, </ng-container
      >Please select the gender to generate the growth chart
    </div>
    <div class="form-body">
      <label
        mat-flat-button
        *ngFor="let sex of sexList; index as i"
        [for]="'f2Sex' + i"
        class="secondary-checkbox display-flex center form__covid-label"
        style="width: '49%'"
        [ngClass]="{
          mr: i == 0,
          active: genderControl?.value === sex?.value
        }"
      >
        {{ sex?.label }}
        <input
          [id]="'f2Sex' + i"
          hidden
          type="radio"
          [value]="sex?.value"
          [formControl]="genderControl"
        />
      </label>
      <mat-dialog-actions align="end">
        <button
          type="button"
          class="button-secondary"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
        <button
          type="submit"
          class="button-primary"
          mat-flat-button
          [disabled]="genderControl?.invalid || isAutoOpenGrowth"
          (click)="saveGender()"
        >
          Submit
          <app-loader
            [buttonLoader]="true"
            *ngIf="isAutoOpenGrowth"
          ></app-loader>
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</ng-template>
