import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vent-detail-view',
  template: `
	  <div class="row">
		  <div class="col-3 details-modal__text" *ngIf="order.tubeSize || order.tube">
			  <div class="details-modal__text__title">
				  Tube Size:
			  </div>
			  <div class="details-modal__text__detail">
				  {{ order.tube | ventTube }} {{ order.tubeSize }}
			  </div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.ettTieLevel">
			  <div class="details-modal__text__title">ETT Tie Level:</div>
			  <div class="details-modal__text__detail">{{ order.ettTieLevel }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.intType">
			  <div class="details-modal__text__title">Intubation Type:</div>
			  <div class="details-modal__text__detail">{{ order.intType }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text"
		       *ngIf="order.oralAirway && order.oralAirway === 'none'">
			  <div class="details-modal__text__title">Oral Airway:</div>
			  <div class="details-modal__text__detail">{{ order.oralAirway }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.pinsp">
			  <div class="details-modal__text__title">
				  P<sub>insp</sub>/IPAP:
			  </div>
			  <div class="details-modal__text__detail">{{ order.pinsp }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.rrset">
			  <div class="details-modal__text__title">
				  RR<sub>set</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.rrset }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.peep">
			  <div class="details-modal__text__title">
				  {{ order.ventType === 'NIV' ? 'CPAP/EPAP' : 'PEEP/EPAP' }}
			  </div>
			  <div class="details-modal__text__detail">{{ order.peep }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.flowRate">
			  <div class="details-modal__text__title">Flow Rate:</div>
			  <div class="details-modal__text__detail">{{ order.flowRate }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.vt">
			  <div class="details-modal__text__title">
				  Tidal Volume (V<sub>t</sub>):
			  </div>
			  <div class="details-modal__text__detail">{{ order.vt }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.psupport">
			  <div class="details-modal__text__title">PS:</div>
			  <div class="details-modal__text__detail">{{ order.psupport }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.pplat">
			  <div class="details-modal__text__title">
				  P<sub>plat</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.pplat }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.map">
			  <div class="details-modal__text__title">MAP (Airway):</div>
			  <div class="details-modal__text__detail">{{ order.map }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.mv">
			  <div class="details-modal__text__title">MV:</div>
			  <div class="details-modal__text__detail">{{ order.mv }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.rrtot">
			  <div class="details-modal__text__title">
				  RR<sub>total</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.rrtot }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.ppeak">
			  <div class="details-modal__text__title">
				  P<sub>peak</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.ppeak }}</div>
		  </div>
		  
		  <div class="col-3 details-modal__text" *ngIf="order.inspiratory || order.expiratory">
			  <div class="details-modal__text__title">I:E :</div>
			  <div class="details-modal__text__detail">
				  {{ order.inspiratory }}:{{ order.expiratory }}
			  </div>
		  </div>
	  </div>
  `,
  styleUrls: [ '../details-modal-common.scss' ]
})
export class VentDetailViewComponent {
  @Input() order;
}
