import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromTrendsStore from "../../store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GROWTH_CHART_OPTIONS } from "../../constants/growth-trends.data";
import { TrendType } from "../../constants/trends.data";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { Patient } from "src/app/models/patient";
import { GrowthChartSupportService } from "../../services/growth-chart-support.service";

@Component({
  selector: "app-trends-left-panel",
  templateUrl: "./trends-left-panel.component.html",
  styleUrls: ["./trends-left-panel.component.scss"],
})
export class TrendsLeftPanelComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  public selectedGraphsNames$ = this._trendsStore.pipe(
    select(fromTrendsStore.getAllGrowthTrendsNames),
    takeUntil(this.unsubscribe$)
  );

  public trendCommonConfig$ = this._trendsStore.pipe(
    select(fromTrendsStore.getTrendCommonConfig),
    takeUntil(this.unsubscribe$)
  );

  public patientHeader$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  get growthChartNames() {
    return GROWTH_CHART_OPTIONS;
  }

  labelNames: any;
  selectedGraphs: string[] = [];
  currHoverGraph: GROWTH_CHART_OPTIONS | null = null;
  patientInfo: Patient;

  constructor(
    private _trendsStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    private _store: Store<any>,
    private _gcs: GrowthChartSupportService
  ) {}

  ngOnInit(): void {
    this.selectedGraphsNames$.subscribe((data: string[]) => {
      this.selectedGraphs = data;
    });

    this.trendCommonConfig$.subscribe((data) => {
      switch (data.trendType) {
        case TrendType.growthChart:
          this.labelNames = Object.entries(this.growthChartNames);
          break;
      }
    });

    /* Get Patient info */
    this.patientHeader$.subscribe((patient) => {
      this.patientInfo = JSON.parse(JSON.stringify(patient));
    });
  }

  replaceGraph(graphKey: GROWTH_CHART_OPTIONS) {
    const payloadToBeReplaced = this._gcs.makeGraphDataAndConfigToBePushed(
      graphKey,
      this.patientInfo
    );

    this._trendsStore.dispatch(
      fromTrendsStore.replaceGrowthGraph({ graphConfigs: payloadToBeReplaced })
    );
  }

  addGraph(graphKeyToBeAdded: GROWTH_CHART_OPTIONS) {
    const payloadToBeSend = this._gcs.makeGraphDataAndConfigToBePushed(
      graphKeyToBeAdded,
      this.patientInfo
    );

    this._trendsStore.dispatch(
      fromTrendsStore.addNewGrowthGraph({ graphConfigs: payloadToBeSend })
    );
  }

  removeGraph(graphKeyToBeRemoved: GROWTH_CHART_OPTIONS) {
    // do not remove graph if only one graph is selected
    if (this.selectedGraphs?.length <= 1) return;

    this._trendsStore.dispatch(
      fromTrendsStore.removeGrowthGraph({
        graphName: GROWTH_CHART_OPTIONS[graphKeyToBeRemoved],
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
