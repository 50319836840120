import { createReducer, on, Action } from "@ngrx/store";

import * as vitalActions from "../actions/vitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Vitals } from "../../models/Vital";

export interface State extends EntityState<Vitals> {}

export function selectVitalId(a: Vitals): string {
  return a._id;
}

export function sortByTimestamp(a: Vitals, b: Vitals): number {
  let aTime = new Date(a.timestamp).getTime();
  let bTime = new Date(b.timestamp).getTime();

  return bTime - aTime;
}

export const adapter: EntityAdapter<Vitals> = createEntityAdapter<Vitals>({
  selectId: selectVitalId,
  sortComparer: sortByTimestamp,
});

export const initialState: State = adapter.getInitialState();

const vitalDataReducer = createReducer(
  initialState,
  on(vitalActions.setVitals, (state, { vitals }) =>
    adapter.setAll(vitals, state)
  ),
  on(vitalActions.updateVital, (state, { vital }) =>
    adapter.upsertOne(vital, state)
  ),
  // on(vitalActions.setClickedTime, (state, { day }) => ({
  //   ...state,
  //   clickedDay: day,
  //   changedTime: null,
  // })),
  // on(vitalActions.resetClickedTime, (state) => ({ ...state, clickedDay: {} })),
  // on(vitalActions.submitSuccess, (state) => ({ ...state, clickedDay: {} })),
  // on(vitalActions.setChangedTime, (state, { dateObj }) => ({
  //   ...state,
  //   changedTime: dateObj,
  // })),
  // on(vitalActions.setSubmittedData, (state, { vitData }) => ({
  //   ...state,
  //   submittedData: vitData,
  // })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

// Get selectors
const { selectAll } = adapter.getSelectors();

// select the array of vitals
export const selectAllVitals = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return vitalDataReducer(state, action);
}
