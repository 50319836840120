import { Action, createReducer, on } from '@ngrx/store';

import * as fromAuth from '../../actions/auth.actions';

export interface State {
  status: number | null;
  message: string | null;
}

const initialState: State = {
  status: null,
  message: null
};

const authErrorReducer = createReducer(
  initialState,
  on(
    fromAuth.authError,
    (state, { status, message }) => ({ status, message })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return authErrorReducer(state, action);
}

export const getAuthErrorStatus = (state: State) => state.status;
export const getAuthErrorMessage = (state: State) => state.message;
