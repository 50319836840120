<div
  class="cp-light-theme bg-light"
  (window:mouseup)="mouseup()"
  (window:contextmenu)="onContextMenu($event)"
>
  <!-- Cloudphysician Header -->
  <app-cp-header class="hide-on-print"></app-cp-header>
  <div class="app-body">
    <router-outlet></router-outlet>
  </div>
  <!-- footer -->
  <app-cp-footer></app-cp-footer>
</div>

<app-loader *ngIf="loader"></app-loader>
