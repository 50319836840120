import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromTypesActions from "../actions/note-data-types.actions";
import { NoteFormsDataModel } from "@notes/models/notes.model";

export interface NoteTypeState extends EntityState<NoteFormsDataModel> {}

export const noteTypeAdaptor = createEntityAdapter<NoteFormsDataModel>({
  selectId: selectNoteId,
});

export const initialState: NoteTypeState = noteTypeAdaptor.getInitialState({});

export function selectNoteId(a: NoteFormsDataModel): string {
  return a.noteType;
}

const _noteTypeReducer = createReducer(
  initialState,
  on(FromTypesActions.loadNoteTypes, (state) => ({
    ...state,
  })),
  on(FromTypesActions.loadNoteTypeSuccess, (state, { noteContents }) =>
    noteTypeAdaptor.setAll(noteContents, state)
  )
);

export function reducer(state: NoteTypeState, action: Action) {
  return _noteTypeReducer(state, action);
}
