import {
  Input,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
} from "@angular/core";
import { MAT_INPUT_VALUE_ACCESSOR } from "@angular/material/input";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: "input[MatInputPreceedingZero]",
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: MatInputPreceedingZeroDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputPreceedingZeroDirective),
      multi: true,
    },
  ],
})
export class MatInputPreceedingZeroDirective {
  // tslint:disable-next-line:variable-name
  private _value: string | null;

  @Input("value")
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  get value(): string | null {
    return this._value;
  }

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  private formatValue(value: string | null) {
    if (+value > 0 && +value < 10) {
      this.elementRef.nativeElement.value = `${value}`.padStart(2, "0");
    } else if (+value >= 10) {
      this.elementRef.nativeElement.value = value;
    }
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    if (+value > 0 && +value < 10) {
      this._value = value.replace(/^0+/, "");
      this.elementRef.nativeElement.value = this._value;
    } else if (+value >= 10) {
      this.elementRef.nativeElement.value = this._value;
    }
  }

  @HostListener("input", ["$event.target.value"])
  onInput(value) {
    if (+value > 0 && +value < 10) {
      this._value = value.replace(/^0+/, "");
      this._onChange(this._value); // here to notify Angular Validators
    } else {
      this._value = value;
      this._onChange(this._value);
    }
  }

  @HostListener("blur")
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener("focus")
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void {}

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
