import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlagIconsComponent } from "./flag-icons.component";

@NgModule({
  declarations: [FlagIconsComponent],
  imports: [CommonModule],
  exports: [FlagIconsComponent],
})
export class FlagIconsModule {}
