import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Patient } from "../models/patient";

@Injectable({
  providedIn: "root",
})
export class SiblingService {
  private patient: Patient | any = null;
  constructor() {}
  private resetFlag = new BehaviorSubject<any>("");
  private messageSource = new BehaviorSubject(this.patient);
  notifyNewPatient = this.messageSource.asObservable();

  private currentPatientSource = new BehaviorSubject(this.patient);
  notifyCurrentPatient = this.currentPatientSource.asObservable();

  private editPatientSource = new Subject();
  notifyEditPatient = this.editPatientSource.asObservable();

  private daysTimeSource = new BehaviorSubject("");
  notifyDaysTime = this.daysTimeSource.asObservable();

  private modifyOrderSource = new Subject();
  public notifyModifyOrder = this.modifyOrderSource.asObservable();

  private timeChangedSource = new Subject();
  notifyTimeChanged = this.timeChangedSource.asObservable();

  timeChanged(date) {
    this.timeChangedSource.next(date);
  }

  newPatientFound(patient: Patient | any) {
    this.messageSource.next(patient);
  }

  currentPatient(patient: Patient) {
    this.currentPatientSource.next(patient);
  }

  editPatient(CPMRN) {
    this.editPatientSource.next(CPMRN);
  }

  daysTimeClicked(time) {
    this.daysTimeSource.next(time);
  }

  modifyOrder(order: any) {
    this.modifyOrderSource.next(order);
  }
  public getResetFlag(): Observable<string> {
    return this.resetFlag.asObservable();
  }
  public sendResetFlag(message: string): void {
    this.resetFlag.next(message);
  }
}
