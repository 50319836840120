import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { catchError, map } from "rxjs/operators";
import * as fromloaderStateReducer from "@shared-modules/store";
import * as fromNoteStore from "@notes-view/store";
import * as fromInputNoteStore from "@notes/store";

import * as fromNoteTypeActions from "@notes-view/store/actions";
import { CoreHttpService } from "../services/base-service/core-http.service";
import { CustomRouterState } from "../store/router/custom-serialize";
import { DraftNotes, FinalNotes, NotesAPIResponse } from "./model/notes-model";
import { CTAType } from "@notes/models/notes.model";

@Injectable({
  providedIn: "root",
})
export class NoteResolverService
  extends CoreHttpService
  implements Resolve<any>
{
  constructor(
    _routeStore: Store<CustomRouterState>,
    private http: HttpClient,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {
    super(_routeStore);
  }

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.getNoteData();
  }

  private getNoteData() {
    const { patientCPMRN, patientEncounters } = this.params;
    const notesApiUrl = `${this.env.apiUrl}notes/${patientCPMRN}/${patientEncounters}`;

    this.http
      .get<{ data: NotesAPIResponse; message: string; status: string }>(
        notesApiUrl
      )
      .pipe(
        map(({ data }) => (data ? this.processNotesData(data) : null)),
        catchError(this.errorHandler)
      )
      .subscribe(
        (_) => {},
        () => {}
      );
  }

  private processNotesData(data: NotesAPIResponse) {
    const restructuredFinalNotes = this.restructureFinalNotes(
      data.notes.finalNotes
    );
    this.handleLoadingSpinner(false);
    this.loadFinalNotes(restructuredFinalNotes);
  }

  private restructureFinalNotes(finalNotes: FinalNotes[]): FinalNotes[] {
    return finalNotes.map((finalNote) => ({
      refId: finalNote["_id"],
      lock: finalNote["lock"],
      createdTimestamp: finalNote.createdTimestamp,
      isDeleteEnabled: finalNote.isDeleteEnabled,
      ...finalNote["content"][0],
    }));
  }

  private handleLoadingSpinner(status: boolean) {
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({ status })
    );
  }

  private loadFinalNotes(finalNotes: FinalNotes[]) {
    if (finalNotes)
      this._noteStore.dispatch(
        fromNoteTypeActions.loadFinalNote({ notes: finalNotes })
      );
  }
}
