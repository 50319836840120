import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { bradenFormValidator } from "../custom-validators";

@Injectable({
  providedIn: "root",
})
export class ApgarFormService {
  constructor() {}

  initAPGARScoreForm(): FormGroup {
    return new FormGroup(
      {
        apgarScore: new FormControl(null),
        activity: new FormControl(null),
        pulse: new FormControl(null),
        grimace: new FormControl(null),
        appearance: new FormControl(null),
        respiration: new FormControl(null),
        timestamp: new FormControl(
          new Date(new Date().setSeconds(0, 0)),
          Validators.required
        ),
      },
      {
        validators: bradenFormValidator,
      }
    );
  }
}
