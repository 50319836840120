<section class="iris-modal-container form-container change-password">
  <div class="form-title">Change password</div>

  <div class="form-body">
    <form
      class="iris-form"
      autocomplete="off"
      [formGroup]="changePasswordForm"
      (ngSubmit)="submitChange(changePasswordForm.value)"
    >
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" [hideRequiredMarker]="true">
              <mat-label>Current password</mat-label>
              <input
                type="password"
                class="iris-input"
                formControlName="currPass"
                matInput
                placeholder="Type in your current password"
                [type]="showPasswordC ? 'text' : 'password'"
              />
              <mat-icon
                matRipple
                (click)="showPasswordC = !showPasswordC"
                class="password-eye"
                matSuffix
                >{{ showPasswordC ? "visibility" : "visibility_off" }}</mat-icon
              >
              <mat-error>Enter a valid password</mat-error>
            </mat-form-field>
          </div>

          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>New password</mat-label>
              <input
                type="password"
                class="iris-input"
                formControlName="newPass"
                matInput
                placeholder="Type in your new password"
                [type]="showPasswordN ? 'text' : 'password'"
              />
              <mat-icon
                matRipple
                (click)="showPasswordN = !showPasswordN"
                class="password-eye"
                matSuffix
                >{{ showPasswordN ? "visibility" : "visibility_off" }}</mat-icon
              >
              <mat-error>Enter a valid password</mat-error>
            </mat-form-field>
          </div>

          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" [hideRequiredMarker]="true">
              <mat-label>Confirm new password</mat-label>
              <input
                type="password"
                class="iris-input"
                formControlName="confNewPass"
                matInput
                placeholder="Confirm your new password"
                [type]="showPasswordU ? 'text' : 'password'"
              />
              <mat-icon
                matRipple
                (click)="showPasswordU = !showPasswordU"
                class="password-eye"
                matSuffix
                >{{ showPasswordU ? "visibility" : "visibility_off" }}</mat-icon
              >
              <mat-error>Enter a valid password</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row password-error-row">
            <mat-checkbox
              disabled
              [checked]="
                newPass.value &&
                !validatorService.hasPasswordError(
                  changePasswordForm,
                  'newPass',
                  'minlength'
                )
              "
              >Must be at least 8 characters long</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                newPass.value &&
                !validatorService.hasPasswordError(
                  changePasswordForm,
                  'newPass',
                  'hasNumber'
                )
              "
              >Must contain at least 1 number</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                newPass.value &&
                !validatorService.hasPasswordError(
                  changePasswordForm,
                  'newPass',
                  'hasCapitalCase'
                )
              "
              >Must contain at least 1 uppercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                newPass.value &&
                !validatorService.hasPasswordError(
                  changePasswordForm,
                  'newPass',
                  'hasSmallCase'
                )
              "
              >Must contain at least 1 lowercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                newPass.value &&
                !validatorService.hasPasswordError(
                  changePasswordForm,
                  'newPass',
                  'hasSpecialCharacters'
                )
              "
              >Must contain at least 1 special character</mat-checkbox
            >
          </div>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <div *ngIf="changePasswordForm.errors" class="iris-error mr">
          {{ changePasswordForm?.errors?.errors }}
        </div>

        <div *ngIf="errorFound" class="iris-error mr">
          {{ errorFound }}
        </div>
        <button
          class="button-secondary"
          type="button"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
        <button
          [disabled]="!changePasswordForm.valid || formLoading"
          type="submit"
          class="button-primary"
          mat-flat-button
        >
          Submit
          <app-loader [buttonLoader]="true" *ngIf="formLoading"></app-loader>
        </button>
      </mat-dialog-actions>

      <div class="refresh-warning">
        <small class="display-flex">
          <mat-icon inline="true" class="mr">warning</mat-icon> You will be
          logged out of your current session.</small
        >
      </div>
    </form>
  </div>
</section>
