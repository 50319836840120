<div class="orders-view-comp" *ngxPermissionsOnly="['read_orders']">
  <!-- Procedures -->
  <div class="procedure_section" *ngIf="proceduresConnected['foley']">
    <!-- <h5>. of Lines and Drains: {{ proceduresConnected.total }}</h5> -->
    <h5>No. of days of Lines and Drains:</h5>
    <div class="procedure-container">
      <ng-container *ngIf="proceduresConnected.foley.length; else foleyBlock">
        <ng-container
          *ngFor="let foley of proceduresConnected.foley; index as i"
        >
          <div
            [title]="
              foley.days > 0
                ? 'Since ' + (foley.timestamp | date: 'dd-MM-yyyy HH:mm')
                : ''
            "
            class="p-row"
          >
            <div class="row_name">Foley:</div>
            <div class="row_days">{{ foley.days }}</div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #foleyBlock>
        <div class="p-row">
          <div class="row_name">Foley:</div>
          <div class="row_days"></div>
        </div>
      </ng-template>
      <ng-container
        *ngIf="proceduresConnected.arterial.length; else arterialBlock"
      >
        <ng-container
          *ngFor="let arterial of proceduresConnected.arterial; index as i"
        >
          <div
            [title]="
              arterial.days > 0
                ? 'Since ' + (arterial.timestamp | date: 'dd-MM-yyyy HH:mm')
                : ''
            "
            class="p-row"
          >
            <div class="row_name">Arterial:</div>
            <div class="row_days">
              {{ arterial.days > 0 ? arterial.days : "" }}
            </div>
            <div class="row_info" *ngIf="arterial.days > 0">
              <div class="lat"><b>Laterality </b> {{ arterial.lat }}</div>
              <div class="site"><b>Site </b> {{ arterial.site }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #arterialBlock>
        <div class="p-row">
          <div class="row_name">Arterial:</div>
          <div class="row_days"></div>
        </div>
      </ng-template>
      <ng-container *ngIf="proceduresConnected.cvc.length; else cvcBlock">
        <ng-container *ngFor="let cvc of proceduresConnected.cvc; index as i">
          <div
            [title]="
              cvc.days > 0
                ? 'Since ' + (cvc.timestamp | date: 'dd-MM-yyyy HH:mm')
                : ''
            "
            class="p-row"
          >
            <div class="row_name">CVC:</div>
            <div class="row_days">
              {{ cvc.days > 0 ? cvc.days : "" }}
            </div>
            <div class="row_info" *ngIf="cvc.days > 0">
              <div class="lat"><b>Laterality </b> {{ cvc.lat }}</div>
              <div class="site"><b>Site </b> {{ cvc.site }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #cvcBlock>
        <div class="p-row">
          <div class="row_name">CVC:</div>
          <div class="row_days"></div>
        </div>
      </ng-template>
      <ng-container *ngIf="proceduresConnected.piv.length; else pivBlock">
        <ng-container *ngFor="let piv of proceduresConnected.piv; index as i">
          <div
            [title]="
              piv.days > 0
                ? 'Since ' + (piv.timestamp | date: 'dd-MM-yyyy HH:mm')
                : ''
            "
            class="p-row"
          >
            <div class="row_name">PIV:</div>
            <div class="row_days">
              {{ piv.days > 0 ? piv.days : "" }}
            </div>
            <div class="row_info" *ngIf="piv.days > 0">
              <div class="lat"><b>Laterality </b> {{ piv.lat }}</div>
              <div class="site"><b>Site </b> {{ piv.site }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #pivBlock>
        <div class="p-row">
          <div class="row_name">PIV:</div>
          <div class="row_days"></div>
        </div>
      </ng-template>

      <ng-container *ngIf="proceduresConnected?.hdcp?.length; else hdcpBlock">
        <ng-container *ngIf="proceduresConnected.hdcp[0] as hdcp">
          <div
            [title]="
              hdcp.days > 0
                ? 'Since ' + (hdcp.timestamp | date: 'dd-MM-yyyy HH:mm')
                : ''
            "
            class="p-row"
          >
            <div class="row_name">HD Catheter Placement:</div>
            <div class="row_days">
              {{ hdcp.days > 0 ? hdcp.days : "" }}
            </div>
            <div class="row_info" *ngIf="hdcp.days > 0">
              <div class="lat"><b>Laterality </b> {{ hdcp.lat }}</div>
              <div class="site"><b>Site </b> {{ hdcp.site }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #hdcpBlock>
        <div class="p-row">
          <div class="row_name">HD Catheter Placement:</div>
          <div class="row_days"></div>
        </div>
      </ng-template>

      <ng-container *ngFor="let line of proceduresConnected.others">
        <div
          class="p-row"
          [title]="
            line.days > 0
              ? 'Since ' + (line.timestamp | date: 'dd-MM-yyyy HH:mm')
              : ''
          "
        >
          <div class="row_name">{{ line.type }}</div>
          <div class="row_days">{{ line.days }}</div>
          <div class="row_info">
            <div class="lat"><b>Laterality </b> {{ line.lat }}</div>
            <div class="site"><b>Site </b> {{ line.site }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div id="ordersViewContent">
    <mat-tab-group
      [animationDuration]="0"
      (selectedTabChange)="tabChanged($event)"
      [(selectedIndex)]="selectedTabIndex"
      class="orders-tabs mat-tab-container"
    >
      <mat-tab id="active">
        <ng-template mat-tab-label>
          Active
          <span
            [ngClass]="[
              selectedTabIndex === 0 ? 'primary-bg-circle' : 'light-bg-circle'
            ]"
            >{{
              orders.active.count + orders.activeD.count > 0
                ? orders.active.count + orders.activeD.count
                : 0
            }}</span
          >
        </ng-template>

        <div class="active-orders-cont">
          <!-- <ng-container *ngTemplateOutlet="filtersCont"></ng-container> -->
          <ng-container *ngTemplateOutlet="protocolsCont"></ng-container>

          <div class="orders-table-cont">
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: {
                  category: 'active',
                  state: state
                }
              "
            >
            </ng-container>

            <div class="discardedLabel d-flex mt-5">
              <span class="align-self-center"
                ><b>Marked for discontinuation</b></span
              >
            </div>
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: { category: 'activeD', state: getActiveDiscard() }
              "
            >
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="pending">
        <ng-template mat-tab-label
          >Pending
          <span
            [ngClass]="[
              selectedTabIndex === 1 ? 'primary-bg-circle' : 'light-bg-circle'
            ]"
            >{{ orders.pending.count > 0 ? orders.pending.count : 0 }}</span
          ></ng-template
        >

        <div class="active-orders-cont">
          <!-- <ng-container *ngTemplateOutlet="filtersCont"></ng-container> -->
          <ng-container *ngTemplateOutlet="protocolsCont"></ng-container>

          <div class="orders-table-cont">
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: { category: 'pending', state: state }
              "
            >
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="completed">
        <ng-template mat-tab-label>
          Completed<span
            [ngClass]="[
              selectedTabIndex === 2 ? 'primary-bg-circle' : 'light-bg-circle'
            ]"
            >{{ orders.completed.count > 0 ? orders.completed.count : 0 }}</span
          >
        </ng-template>

        <div class="active-orders-cont">
          <!-- <ng-container *ngTemplateOutlet="filtersCont"></ng-container> -->
          <ng-container *ngTemplateOutlet="protocolsCont"></ng-container>

          <div class="orders-table-cont">
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: { category: 'completed', state: state }
              "
            >
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="inactive">
        <ng-template mat-tab-label>
          Discontinued<span
            [ngClass]="[
              selectedTabIndex === 3 ? 'primary-bg-circle' : 'light-bg-circle'
            ]"
            >{{ orders.inactive.count > 0 ? orders.inactive.count : 0 }}</span
          >
        </ng-template>

        <div class="active-orders-cont">
          <!-- <ng-container *ngTemplateOutlet="filtersCont"></ng-container> -->
          <ng-container *ngTemplateOutlet="protocolsCont"></ng-container>

          <div class="orders-table-cont">
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: { category: 'inactive', state: state }
              "
            >
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="pta">
        <ng-template mat-tab-label>
          Home meds
          <span
            [ngClass]="[
              selectedTabIndex === 4 ? 'primary-bg-circle' : 'light-bg-circle'
            ]"
            >{{ orders.pta.count > 0 ? orders.pta.count : 0 }}</span
          >
        </ng-template>

        <div class="active-orders-cont">
          <!-- <ng-container *ngTemplateOutlet="filtersCont"></ng-container> -->
          <ng-container *ngTemplateOutlet="protocolsCont"></ng-container>

          <div class="orders-table-cont">
            <ng-container
              *ngTemplateOutlet="
                ordersBlock;
                context: { category: 'pta', state: state }
              "
            >
            </ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #protocolsCont>
  <div
    class="d-flex justify-content-between protocols"
    [formGroup]="protocolsForm"
    *ngIf="
      orders && activeProtocolKey && protocolsForm?.value?.protocols.length > 0;
      else elseProtocol
    "
  >
    <div class="d-flex flex-wrap align-items-center" formArrayName="protocols">
      <strong>{{ protocolTitle }}:</strong>
      <div
        class="btn-tags"
        *ngFor="let protocolName of protocolsForm?.value?.protocols; index as i"
      >
        <app-btn-tag
          [tagName]="protocolName.name"
          [iconLeft]="protocolName.attachmentIcon"
          [leftIconTooltip]="leftIconTooltip"
          [rightIconTooltip]="rightIconTooltip"
          [iconRight]="isDischargeTimeElapsed ? null : iconRight"
          (iconClick)="
            onIconClick($event, protocolName, discontinue_modal, fileModal)
          "
          (selected)="addProtocol($event, i)"
        ></app-btn-tag>

        <ng-template #fileModal let-modal>
          <div class="show-file-dialog">
            <div class="header">
              <span class="title">Attached File</span>
              <img
                src="assets/icons/close.svg"
                class="cp-cursor-pointer"
                matDialogClose
              />
            </div>
            <div
              class="body"
              *ngIf="
                getProtocolFile(protocolName.name) | async as downloadedFile
              "
            >
              <embed
                *ngIf="downloadedFile && downloadedFile.data"
                [src]="getFileData(downloadedFile.data)"
                style="width: 100%; height: 60rem"
              />
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="button-group-horizontal">
      <ng-template
        [ngxPermissionsOnly]="['sign_protocol']"
        *ngIf="!isDischargeTimeElapsed"
      >
        <button
          mat-flat-button
          class="button-primary"
          type="submit"
          *ngIf="activeProtocolKey === 'pending'"
          (click)="sign(); open(sign_modal); $event.stopPropagation()"
          [disabled]="selectedProtocols && selectedProtocols.length === 0"
        >
          Sign
        </button>
      </ng-template>

      <ng-container *ngTemplateOutlet="orderSearchAndPrint"></ng-container>
      <!-- <ng-template [ngxPermissionsOnly]="['discontinue_protocol']">
				<button
					mat-flat-button
					class="button-error"
					type="submit"
					*ngIf="
						activeProtocolKey === 'pending' ||
						activeProtocolKey === 'activeTotal'
					"
					(click)="
						discontinue(); open(discontinue_modal); $event.stopPropagation()
					"
					[disabled]="selectedProtocols && selectedProtocols.length === 0"
				>
					Discontinue
				</button>
			</ng-template> -->
    </div>
  </div>

  <ng-template #elseProtocol>
    <div class="top-row">
      <ng-container *ngTemplateOutlet="orderSearchAndPrint"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #InfoBlock>
  <div class="no_data_found_block">No orders found</div>
</ng-template>

<ng-template let-category="category" let-state="state" #ordersBlock>
  <ng-container
    *ngIf="orders[category] && orders[category].count > 0; else InfoBlock"
  >
    <app-order-panel
      [category]="category"
      [state]="state"
      [activeProtocols]="selectedProtocols"
      [filterControls]="filterControls"
      [highlightText]="searchTextToBeHighlighted"
      [currentPatient]="currentPatient"
      (discontinue)="onDiscontinueOrder($event)"
      (clickViewDetails)="showDetailsModal($event)"
    >
    </app-order-panel>
  </ng-container>
</ng-template>

<ng-template #discontinue_modal>
  <div class="modal-popup">
    <div class="header">
      <div class="title">
        <img src="assets/icons/warning_24px.svg" />
        <div>Are you sure ?</div>
      </div>
      <!-- <img matDialogClose src="assets/icons/close.svg" /> -->
    </div>
    <div class="body">
      <div
        *ngIf="
          (!!ngxPermissionsService.getPermission('discontinue_order') &&
            !!!ngxPermissionsService.getPermission(
              'mark_to_discontinue_order'
            )) ||
          currentTab !== 'activeTotal'
        "
      >
        You want to discontinue.
        <br />The following protocol will be discontinued:
        <div class="font-weight-bold">
          {{ discontinueProtocol }}
        </div>
      </div>

      <div
        *ngIf="
          ngxPermissionsService.getPermission('mark_to_discontinue_order') &&
          currentTab === 'activeTotal'
        "
      >
        You want to Mark this Protocol as Discontinued.
        <br />The following protocol will be Marked for Discontinuation:
        <div class="font-weight-bold">
          {{ discontinueProtocol }}
        </div>
      </div>

      <!-- <div *ngIf="selectedOrders.length === 0">
				All orders are already marked for discontinue.
			</div> -->
    </div>
    <div class="footer">
      <button
        mat-flat-button
        matDialogClose
        class="button-error"
        (click)="onDiscontinue(discontinueProtocol)"
      >
        {{
          ngxPermissionsService.getPermission("mark_to_discontinue_order") &&
          currentTab === "activeTotal"
            ? "Mark"
            : "Discontinue Protocol"
        }}
      </button>
      <button mat-flat-button matDialogClose class="button-secondary">
        {{ selectedOrders.length > 0 ? "Cancel" : "Close" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #sign_modal>
  <div class="modal-popup">
    <div class="header">
      <div class="title">
        <img src="assets/icons/warning_24px.svg" />
        <div>Are you sure ?</div>
      </div>
      <img matDialogClose src="assets/icons/close.svg" />
    </div>
    <div class="body">
      You want to sign.
      <br />The following protocols will be signed: <br />
      <div
        class="font-weight-bold"
        *ngFor="let protocolName of selectedProtocols"
      >
        {{ protocolName }}
      </div>
    </div>
    <div class="footer">
      <button
        mat-flat-button
        matDialogClose
        class="button-primary"
        (click)="onSign()"
        *ngIf="selectedOrders.length > 0"
      >
        Sign Protocol
      </button>
      <button mat-flat-button matDialogClose class="button-secondary">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<ng-template #filtersCont>
  <div class="filters-cont">
    <div class="filter-label">
      Filters
      <div
        matRipple
        class="arrow-down cp-cursor-pointer"
        (click)="hideFilters(false)"
        *ngIf="!showFilters"
      >
        <img class="filter-arrow" src="assets/icons/arrow_down.svg" />
      </div>
    </div>
    <div class="filters" *ngIf="showFilters">
      <div class="order-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Order:</mat-label>
          <input
            matInput
            [(ngModel)]="filterControls.orderName"
            placeholder="Filter orders"
            autocomplete="off"
            class="iris-input"
          />
        </mat-form-field>
      </div>
      <div class="protocol-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Protocol:</mat-label>
          <mat-select
            matInput
            [(ngModel)]="filterControls.protocol"
            placeholder="Filter protocol"
            autocomplete="off"
            class="iris-input"
          >
            <mat-option
              [value]="protocol.name"
              *ngFor="let protocol of protocolsForm?.value?.protocols"
            >
              {{ protocol.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="route-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Route:</mat-label>
          <mat-select
            matInput
            [(ngModel)]="filterControls.route"
            placeholder="Filter route"
            autocomplete="off"
            class="iris-input"
          >
            <mat-option [value]="route" *ngFor="let route of routes">
              {{ route }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="status-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Status:</mat-label>
          <mat-select
            class="iris-input"
            [(ngModel)]="filterControls.status"
            autocomplete="off"
            placeholder="Show all"
          >
            <mat-option value="Not communicated">Not communicated</mat-option>
            <mat-option value="Communicated">Communicated</mat-option>
            <mat-option value="Active">Active</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          !filterControls.protocol &&
          !filterControls.orderName &&
          !filterControls.route &&
          !filterControls.status
        "
        class="clear-all-text clear-all-disabled"
      >
        Clear all
      </div>
      <div
        matRipple
        *ngIf="
          filterControls.protocol ||
          filterControls.orderName ||
          filterControls.route ||
          filterControls.status
        "
        (click)="clearFilters()"
        class="clear-all-text primary cp-cursor-pointer"
      >
        <b>Clear all</b>
      </div>
    </div>
    <div
      matRipple
      class="arrow-up cp-cursor-pointer"
      (click)="hideFilters(true)"
      *ngIf="showFilters"
    >
      <img class="filter-arrow" src="assets/icons/arrow_up.svg" />
    </div>
  </div>
</ng-template>

<ng-template #orderSearchAndPrint>
  <cp-search-box
    (reset)="resetSearch()"
    (search)="onSearch()"
    placeholder="Search order/brand"
    [(ngModel)]="searchText"
  >
    <div class="search-dropdown" #searchDropdown *ngIf="searchDropdown">
      {{ searchResultCount }} results found
    </div>
  </cp-search-box>
  <div *ngIf="state?.type == 'active'" class="print-btn">
    <app-print-orders
      [currentPatient]="currentPatient"
      [type]="state?.type || null"
    >
    </app-print-orders>
  </div>
</ng-template>

<ng-template [ngxPermissionsExcept]="['read_orders']">
  <div class="no_data_found_block pt-5">
    You are not authorized to view this page
  </div>
</ng-template>
