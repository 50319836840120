import { Action, createReducer, on } from "@ngrx/store";
import {
  OrderableApiActions,
  OrderableRequestPageActions,
} from "../../../actions/orderable";

export interface State {
  error: string | null;
  loading: boolean;
}

export const initialState: State = {
  error: null,
  loading: false,
};

const orderableRequestStatusReducer = createReducer(
  initialState,
  on(OrderableRequestPageActions.getOrderableRequests, (state) => ({
    error: null,
    loading: true,
  })),
  on(OrderableApiActions.getOrderableRequestSuccess, (state) => ({
    error: null,
    loading: false,
  })),
  on(OrderableApiActions.getOrderableFailure, (state, { error }) => ({
    error,
    loading: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return orderableRequestStatusReducer(state, action);
}

export const getOrderableRequestError = (state: State) => state.error;

export const getOrderableRequestLoading = (state: State) => state.loading;
