import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-netra-not-verified-notification",
  templateUrl: "./netra-not-verified-notification.component.html",
  styleUrls: ["./netra-not-verified-notification.component.scss"],
})
export class NetraNotVerifiedNotificationComponent implements OnInit {
  public displayedColumns: string[] = [
    "CPMRN",
    "Name",
    "Hospital",
    "Unit",
    "Bed",
    "Time",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public notificationData: any,
    private dialogRef: MatDialogRef<NetraNotVerifiedNotificationComponent>
  ) {}

  ngOnInit(): void {}

  transformTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  closeTab(): void {
    this.dialogRef.close();
  }
}
