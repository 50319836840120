<section
  class="vitals-gcs-form input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form"
    autocomplete="off"
    [formGroup]="vitalsGcsForm"
    (ngSubmit)="submitVitals()"
  >
    <!-- Vitals form -->
    <div class="vitals-form" formGroupName="vitalsForm">
      <div class="view-added-image" *ngIf="showNetraImage">
        <button
          mat-flat-button
          class="button-secondary"
          type="button"
          (click)="openNetraImage()"
        >
          View
          <mat-icon inline="true">image</mat-icon>
        </button>
      </div>

      <!--Temperature and HR-->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row temp-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Temperature:</mat-label>
              <input
                class="iris-input"
                formControlName="daysTemperature"
                matInput
                type="number"
              />
              <mat-hint
                *ngIf="
                  vitalsForm.get('daysTemperature').value &&
                  vitalsForm.get('daysTemperatureUnit').value == 'F'
                "
                >={{
                  (vitalsForm.get("daysTemperature").value - 32) * (5 / 9)
                    | number: "1.0-2"
                }}&#8451;</mat-hint
              >
              <mat-hint
                *ngIf="
                  vitalsForm.get('daysTemperature').value &&
                  vitalsForm.get('daysTemperatureUnit').value == 'C'
                "
                >={{
                  vitalsForm.get("daysTemperature").value * (9 / 5) + 32
                    | number: "1.0-2"
                }}&#8457;</mat-hint
              >
              <mat-error>Invalid temperature</mat-error>
            </mat-form-field>

            <mat-button-toggle-group
              formControlName="daysTemperatureUnit"
              aria-label="Font Style"
            >
              <mat-button-toggle [value]="'F'">F</mat-button-toggle>
              <mat-button-toggle [value]="'C'">C</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Heart rate:</mat-label>
              <input
                type="number"
                class="iris-input"
                formControlName="daysHR"
                matInput
              />
              <mat-error>Invalid HR</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--RR and Blood Pressure-->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Respiratory rate:</mat-label>
              <input
                type="number"
                class="iris-input"
                formControlName="daysRR"
                matInput
              />
              <mat-error>Invalid RR</mat-error>
              <span
                class="iris-error"
                *ngIf="
                  admitFormIsParent &&
                  !vitalsForm.get('daysRR').value &&
                  vitalsForm.errors &&
                  vitalsForm.errors.roxValues
                "
              >
                RR is mandatory field.
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Blood pressure:</mat-label>
              <input
                (change)="onChangeForMAP()"
                placeholder="Systolic/diastolic (Eg. 120/80)"
                class="iris-input"
                formControlName="daysBP"
                type="text"
                matInput
              />
              <mat-error>Invalid BP</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--MAP and CVP-->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>MAP:</mat-label>
              <input
                type="number"
                placeholder="Mean Arterial Pressure"
                class="iris-input"
                formControlName="daysMAP"
                matInput
              />
              <mat-error>Invalid MAP</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>CVP:</mat-label>
              <input
                type="number"
                placeholder="Central Venous Pressure"
                class="iris-input"
                formControlName="daysCVP"
                matInput
              />
              <mat-error>Invalid CVP</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--Spo2 and Fio2-->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>SpO<sub>2</sub>% </mat-label>
              <input
                type="number"
                placeholder="%"
                class="iris-input"
                formControlName="daysSpO2"
                matInput
              />
              <mat-error>Invalid SpO2</mat-error>
              <span
                class="iris-error"
                *ngIf="
                  admitFormIsParent &&
                  !vitalsForm.get('daysSpO2').value &&
                  vitalsForm.errors &&
                  vitalsForm.errors.roxValues
                "
              >
                SpO2 is mandatory field.
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>FiO<sub>2</sub>% </mat-label>
              <input
                type="number"
                placeholder="%"
                class="iris-input"
                formControlName="daysFiO2"
                matInput
              />
              <mat-error>Invalid FiO2</mat-error>
              <span
                class="iris-error"
                *ngIf="
                  admitFormIsParent &&
                  !vitalsForm.get('daysFiO2').value &&
                  vitalsForm.errors &&
                  vitalsForm.errors.roxValues
                "
              >
                FiO2 is mandatory field.
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--APVU and comments-->
      <div class="iris-v-row" *ngIf="!admitFormIsParent">
        <div class="row-left">
          <div class="iris-form-row avpu-row">
            <label for="daysAVPU">AVPU:</label>
            <mat-button-toggle-group
              formControlName="daysAVPU"
              aria-label="Font Style"
            >
              <mat-button-toggle [value]="'A'">A</mat-button-toggle>
              <mat-button-toggle [value]="'V'">V</mat-button-toggle>
              <mat-button-toggle [value]="'P'">P</mat-button-toggle>
              <mat-button-toggle [value]="'U'">U</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Comments:</mat-label>
              <input
                class="iris-input"
                formControlName="daysReasonBP"
                matInput
              />
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Patient position -->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Patient position:</mat-label>
              <mat-select formControlName="daysPatPosition">
                <mat-option
                  *ngFor="let pos of patientPositionValues"
                  [value]="pos"
                >
                  {{ pos }}
                </mat-option>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div *ngIf="patientPosition.value === 'Other'" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Other*</mat-label>
              <input
                class="iris-input"
                formControlName="daysPatPositionOther"
                matInput
              />
              <span class="iris-error" *ngIf="vitalsForm.errors?.patientPos">
                {{ vitalsForm.errors.patientPos }}
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- Pupillary form -->
    <div class="pupillary-form" formGroupName="pupillaryForm">
      <ng-conatiner *ngIf="!admitFormIsParent; else smallHeading">
        <div class="sub-tittle">Pupillary Scale :</div>
        <hr />
      </ng-conatiner>
      <ng-template #smallHeading>
        <p class="sub-tittle-2">Pupillary score:</p>
        <hr class="assess-line" />
      </ng-template>
      <!-- Condition -->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Left Pupillary condition</mat-label>
              <mat-select formControlName="daysLeftPupilaryCondition">
                <mat-option
                  *ngFor="let pup of patientPupillaryValues"
                  [value]="pup"
                >
                  {{ pup }}
                </mat-option>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Right Pupillary condition</mat-label>
              <mat-select formControlName="daysRightPupilaryCondition">
                <mat-option
                  *ngFor="let pup of patientPupillaryValues"
                  [value]="pup"
                >
                  {{ pup }}
                </mat-option>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Size -->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <label for="">Left Pupillary size(mm)</label>
            <mat-button-toggle-group formControlName="daysLeftPupillarySize">
              <mat-button-toggle
                *ngFor="let size of [1, 2, 3, 4, 5, 6, 7, 8]"
                [value]="size"
              >
                <div class="size-holder">
                  <span [class]="'size size-' + size"></span>
                </div>
                {{ size }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <label for="">Right Pupillary size(mm)</label>
            <mat-button-toggle-group formControlName="daysRightPupillarySize">
              <mat-button-toggle
                *ngFor="let size of [1, 2, 3, 4, 5, 6, 7, 8]"
                [value]="size"
              >
                <div class="size-holder">
                  <span [class]="'size size-' + size"></span>
                </div>
                {{ size }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>

    <!-- GCS Form -->
    <div class="gcs-form" formGroupName="gcsForm">
      <ng-conatiner *ngIf="!admitFormIsParent; else smallHeading2">
        <div class="sub-tittle">Glasgow coma scale score:</div>
        <hr />
      </ng-conatiner>
      <ng-template #smallHeading2>
        <p class="sub-tittle-2">GCS score:</p>
        <hr class="assess-line" />
      </ng-template>
      <div class="input-form-header">
        <div class="score">
          <div
            [ngClass]="{
              'background-success': GCSScore > 12,
              'background-warning': GCSScore > 9 && GCSScore <= 12,
              'background-danger': GCSScore <= 9 && GCSScore > 2
            }"
          >
            {{ GCSScore ? +GCSScore : "-" }}
            <span *ngIf="gcsForm.get('daysGCSverbal').value == 0">T</span>
          </div>
        </div>
        <div class="info">
          <div class="emoji-section">
            <div class="text-success">
              13+ Mild&nbsp;<mat-icon inline="true"
                >sentiment_satisfied</mat-icon
              >
            </div>
            <div class="text-warning ml-1">
              10-12 Moderate&nbsp;<mat-icon inline="true"
                >sentiment_neutral</mat-icon
              >
            </div>
            <div class="text-danger">
              3-9 Strong&nbsp;<mat-icon inline="true"
                >sentiment_dissatisfied</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Eyesight -->
      <div class="gcs-eyesight">
        <div class="left-eye">
          <label for=""> EYE </label>
          <img src="../../../../../assets/icons/Eye.svg" alt="" />
        </div>
        <div class="right-eye-options">
          <label class="option-sub-heading" for=""
            >How are the eyes responding?</label
          >

          <div class="options">
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSeyes == 1 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Does not open eyes
                <input
                  hidden
                  type="radio"
                  [value]="1"
                  formControlName="daysGCSeyes"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSeyes == 2 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Opens eyes in response to pressure
                <input
                  hidden
                  type="radio"
                  [value]="2"
                  formControlName="daysGCSeyes"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSeyes == 3 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Opens eyes in response to voice
                <input
                  hidden
                  type="radio"
                  [value]="3"
                  formControlName="daysGCSeyes"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSeyes == 4 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Opens eyes spontaneously
                <input
                  hidden
                  type="radio"
                  [value]="4"
                  formControlName="daysGCSeyes"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- verbal -->
      <div class="gcs-eyesight">
        <div class="left-eye">
          <label for=""> VERBAL </label>
          <img src="../../../../../assets/icons/speak.svg" alt="" />
        </div>
        <div class="right-eye-options">
          <label class="option-sub-heading" for=""
            >How is the verbal response?</label
          >

          <div class="options">
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 0 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Intubated
                <input
                  hidden
                  type="radio"
                  [value]="0"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 1 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Makes no sounds
                <input
                  hidden
                  type="radio"
                  [value]="1"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 2 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Makes sounds
                <input
                  hidden
                  type="radio"
                  [value]="2"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 3 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Words
                <input
                  hidden
                  type="radio"
                  [value]="3"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 4 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Confused, disoriented
                <input
                  hidden
                  type="radio"
                  [value]="4"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSverbal == 5 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Oriented, converses normally
                <input
                  hidden
                  type="radio"
                  [value]="5"
                  formControlName="daysGCSverbal"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- motor -->
      <div class="gcs-eyesight">
        <div class="left-eye">
          <label for=""> MOTOR </label>
          <img src="../../../../../assets/images/arm.png" alt="" />
        </div>
        <div class="right-eye-options">
          <label class="option-sub-heading" for=""
            >How are motor reflexes?</label
          >

          <div class="options">
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 1 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Makes no movements
                <input
                  hidden
                  type="radio"
                  [value]="1"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 2 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Extension to painful stimuli
                <input
                  hidden
                  type="radio"
                  [value]="2"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 3 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Abnormal flexion to painful stimuli
                <input
                  hidden
                  type="radio"
                  [value]="3"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 4 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Flexion/withdrawal to painful stimuli
                <input
                  hidden
                  type="radio"
                  [value]="4"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 5 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Localizes to painful stimuli
                <input
                  hidden
                  type="radio"
                  [value]="5"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
            <div class="option-text">
              <label
                mat-flat-button
                popoverTrigger="hover"
                position="top-center"
                [ngClass]="{ active: this.gcsForm.value.daysGCSmotor == 6 }"
                class="secondary-checkbox display-flex center form__covid-label age-group"
              >
                Obeys commands
                <input
                  hidden
                  type="radio"
                  [value]="6"
                  formControlName="daysGCSmotor"
                  (change)="onGcsChange()"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Date/Time and Submit button-->
    <div class="date-time-row" *ngIf="!admitFormIsParent">
      <div class="warning-holder">
        <span
          class="iris-error"
          *ngIf="vitalsForm.errors && vitalsForm.errors.roxValues"
        >
          FiO2, SpO2 and RR are mandatory fields.
        </span>

        <span class="iris-error" *ngIf="formState.error">
          {{ formState.error || "Server down! Please try after some time." }}
        </span>
      </div>

      <div class="content">
        <app-date-time-picker
          [showTime]="true"
          [showNowButton]="true"
          formControlName="timestamp"
          [minValidDate]="minDate"
          [maxValidDate]="maxDate"
          [timeZoneDetail]="timeZoneDetail"
        ></app-date-time-picker>

        <div class="b-holder">
          <button
            (click)="resetVitals()"
            mat-flat-button
            class="button-secondary mr"
            type="button"
          >
            Reset
          </button>
          <button
            [disabled]="!vitalsGcsForm.valid || formState.loading"
            mat-flat-button
            class="button-primary"
            type="submit"
          >
            Submit
            <app-loader
              [buttonLoader]="true"
              *ngIf="formState.loadType === 'vitals'"
            ></app-loader>
          </button>
        </div>
      </div>
    </div>
  </form>
</section>
