import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusChipComponent } from "./components/status-chip/status-chip.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [StatusChipComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [StatusChipComponent],
})
export class StatusChipModule {}
