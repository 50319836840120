import { Action, createReducer, on } from "@ngrx/store";
import { AuthApiActions } from "../../actions/auth";
import { AuthScreenEnum } from "../../../models/auth/Auth.model";
import * as fromAuth from "../../actions/auth.actions";

export interface State {
  screen: AuthScreenEnum;
  loading: boolean;
  error: string;
  successMessage: string;
  resetPassSuccess: boolean;
}

const initialState: State = {
  screen: AuthScreenEnum.LoginPage,
  loading: false,
  error: null,
  successMessage: null,
  resetPassSuccess: false,
};

const authMiscReducer = createReducer(
  initialState,
  on(
    AuthApiActions.verifyOtpSuccess,
    AuthApiActions.showLoginAuthScreen,
    (state) => initialState
  ),

  on(AuthApiActions.showOtpAuthScreen, (state) => {
    return { ...initialState, screen: AuthScreenEnum.OtpPage };
  }),

  on(AuthApiActions.showForgotPasswordAuthScreen, (state) => {
    return { ...initialState, screen: AuthScreenEnum.ForgotPassword };
  }),

  on(AuthApiActions.forgotPasswordSendOtp, (state) => {
    return { ...state, loading: true };
  }),
  on(AuthApiActions.forgotPasswordSendOtpSuccess, (state) => {
    return { ...initialState, screen: AuthScreenEnum.ResetPassword };
  }),
  on(AuthApiActions.forgotPasswordSendOtpFailure, (state, { error }) => {
    return { ...initialState, error, screen: AuthScreenEnum.ForgotPassword };
  }),

  on(AuthApiActions.forgotPasswordResendOtp, (state) => {
    return { ...state, successMessage: null, loading: true };
  }),
  on(AuthApiActions.forgotPasswordResendOtpSuccess, (state, { message }) => {
    return { ...state, successMessage: message, loading: false };
  }),
  on(AuthApiActions.forgotPasswordResendOtpFailure, (state, { error }) => {
    return { ...state, error, loading: false };
  }),

  on(AuthApiActions.forgotPasswordVerifyOtp, (state) => {
    return { ...state, loading: true };
  }),
  on(AuthApiActions.forgotPasswordVerifyOtpSuccess, (state) => {
    return { ...initialState, screen: AuthScreenEnum.ResetPassword };
  }),
  on(AuthApiActions.forgotPasswordVerifyOtpFailure, (state, { error }) => {
    return { ...initialState, error, screen: AuthScreenEnum.OtpPage };
  }),

  on(AuthApiActions.forgotPassword, (state) => {
    return { ...state, loading: true };
  }),
  on(AuthApiActions.resetPasswordSuccess, (state) => {
    return { ...state, resetPassSuccess: true, loading: false };
  }),
  on(AuthApiActions.resetPasswordFailure, (state, { error }) => {
    return {
      ...state,
      error,
      resetPassSuccess: false,
      loading: false,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return authMiscReducer(state, action);
}

export const getAuthMiscScreen = (state: State) => state.screen;
export const getAuthMiscLoading = (state: State) => state.loading;
export const getAuthMiscError = (state: State) => state.error;
export const getAuthMiscSuccessMsg = (state: State) => state.successMessage;
export const getResetPasswordState = (state: State) => state.resetPassSuccess;
