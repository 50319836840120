// import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

import { PatientMonitorStatus } from '../../models/patientMonitor/patientMonitor.model';
import { socket } from '../../config/socket';

@Injectable({ providedIn: 'root' })
export class PatientMonitorSocketService {
    constructor() {
    }

    emitAllDeviceStatusMessage(data: PatientMonitorStatus[]) {
        socket.emit('patientMonitor:status', data);
    }
}
