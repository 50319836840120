import { Pipe, PipeTransform } from "@angular/core";
import { DocumentViews } from "../models/labs-scans";

@Pipe({
  name: "attrNames",
})
export class AttrNamePipe implements PipeTransform {
  transform(labs: DocumentViews[]): string[] {
    let attributeName: string[] = [];
    labs.map(
      (element) =>
        (attributeName = [
          ...new Set(
            attributeName.concat(
              Object.keys(element.attributes).map((attr) => attr.trim()) // trim db data with extra space
            )
          ),
        ])
    );
    return attributeName;
  }
}
