import { createSelector } from "@ngrx/store";
import * as fromViewState from "../reducers";
import { noteTypeAdaptor } from "../reducers/final-notes.reducers";
import { SubmitTypes } from "@notes/models/notes.model";

export const getFinalNotesType = createSelector(
  fromViewState.getNoteState,
  (state) => state.finalNotes
);

export const { selectEntities: getNoteEntities } =
  noteTypeAdaptor.getSelectors(getFinalNotesType);

export const { selectIds: getNoteById } =
  noteTypeAdaptor.getSelectors(getFinalNotesType);

export const { selectAll: getAllNoteDatas } =
  noteTypeAdaptor.getSelectors(getFinalNotesType);

export const getAllPendedNotes = createSelector(getAllNoteDatas, (notes) =>
  notes.filter((note) => note.pendOrSigned === SubmitTypes.Pend)
);

export const getAllSignedNotes = createSelector(getAllNoteDatas, (notes) =>
  notes.filter((note) => note.pendOrSigned === SubmitTypes.Sign)
);

// Count selectors
export const getPendedNotesCount = createSelector(
  getAllPendedNotes,
  (pendedNotes) => pendedNotes.length
);

export const getSignedNotesCount = createSelector(
  getAllSignedNotes,
  (signedNotes) => signedNotes.length
);
