import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { QuillModule } from "ngx-quill";
import { MatDialogModule } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

import { HandoverNotesComponent } from "./components/handover-notes/handover-notes.component";
import { NotePopupComponent } from "./components/note-popup/note-popup.component";
import { HandoverViewComponent } from "./components/handover-view/handover-view.component";
import { SanitizeHtmlModule } from "src/app/shared-modules/pipes/sanitize-html/sanitize-html.module";
import { LoaderModule } from "src/app/iris-components/loader/loader.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { TextEditorModule } from "../shared/modules/text-editor";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";

@NgModule({
  declarations: [
    HandoverNotesComponent,
    NotePopupComponent,
    HandoverViewComponent,
  ],
  imports: [
    CommonModule,
    LoaderModule,
    TextEditorModule,
    QuillModule,
    MatIconModule,
    OverlayModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    SanitizeHtmlModule,
    TimezoneLabelModule,
    TimezonePipeModule,
  ],
  exports: [HandoverNotesComponent],
})
export class HandoverNotesModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._matIconRegistry.addSvgIcon(
      `cp-handover`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/handover.svg"
      )
    );
    this._matIconRegistry.addSvgIcon(
      `cp-handover-active`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/handover_active.svg"
      )
    );
  }
}
