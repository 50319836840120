import { LabPreset } from "./preset/LabPreset.model";
import { DietPreset } from "./preset/DietPreset.model";
import { BloodPreset } from "./preset/BloodPreset.model";
import { ProcedurePreset } from "./preset/ProcedurePreset.model";
import { MedPreset } from "./preset/MedPreset.model";
import { CommunicationPreset } from "./preset/CommunicationPreset.model";

export type presetsType =
  | BloodPreset
  | CommunicationPreset
  | DietPreset
  | LabPreset
  | MedPreset
  | ProcedurePreset;
export interface Orderable {
  _id: string;
  name: string;
  type?: string;
  canDelete?: boolean;
  snomed?: Snomed;
  selectedBrand?: string;
  brands: string[];
  presets:
    | BloodPreset[]
    | CommunicationPreset[]
    | DietPreset[]
    | LabPreset[]
    | MedPreset[]
    | ProcedurePreset[]
    | null;
  highRisk?: boolean;
  highRiskHospitals?: string[];
  patientType: string;
}

interface Snomed {
  code: number;
  name: string | null;
}

export interface SetDefaultApi {
  orderableId: string;
  presetId: string;
  default: boolean;
  displayMessage: boolean;
}

export interface DeletePresetApi {
  orderableId: string;
  presetId: string;
}

export interface DisplayShortcutApi {
  orderableId: string;
  presetId: string;
  display: boolean;
}

export const presetType = [
  "blood",
  "comm",
  "diet",
  "lab",
  "med",
  "procedure",
  "vents",
];
