import { createReducer, on, Action } from "@ngrx/store";

import * as vitalActions from "../actions/vitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { Vitals } from "../../models/Vital";
import { ACTIVE_ASSESSMENT } from "../../support/vitals.support";

export interface State {
  clickedDay: Vitals | null;
  canvasStatus: boolean;
  resetTimestamp: any;
  activeAssessment: ACTIVE_ASSESSMENT | null;
}

const initialState: State = {
  clickedDay: null,
  canvasStatus: false,
  resetTimestamp: null,
  activeAssessment: null,
};

const vitalInfoReducer = createReducer(
  initialState,
  on(vitalActions.setClickedDay, (state, { day, activeAssessment = null }) => ({
    ...state,
    clickedDay: day,
    activeAssessment: activeAssessment,
  })),
  on(vitalActions.resetClickedDay, (state) => ({
    ...state,
    clickedDay: null,
  })),
  on(vitalActions.canvasStatus, (state, { val }) => ({
    ...state,
    canvasStatus: val,
  })),
  on(vitalActions.resetTimestamp, (state, { val }) => ({
    ...state,
    resetTimestamp: val,
  })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return vitalInfoReducer(state, action);
}
