<div class="confirm-dialog">
  <div class="confirm-dialog__header">
    <div>
      <mat-icon class="confirm-dialog__header__et-icon">warning</mat-icon>
      <div class="confirm-dialog__header__text">
        {{ data.headerText || "Attention" }}
      </div>
    </div>
    <mat-icon
      *ngIf="data?.showClose"
      class="confirm-dialog__header__c-icon"
      (click)="closeDialog()"
      >close</mat-icon
    >
  </div>

  <div style="white-space: pre-line" class="confirm-dialog__body">
    {{ data.message }}
  </div>

  <div class="confirm-dialog__footer">
    <button
      class="button-secondary"
      type="button"
      mat-flat-button
      *ngIf="data?.showClose"
      [mat-dialog-close]="false"
    >
      {{ data.secondaryButtonText || "Cancel" }}
    </button>

    <button
      mat-flat-button
      [ngClass]="statusData.class"
      [mat-dialog-close]="true"
    >
      {{ data?.buttonText || statusData.text }}
    </button>
  </div>
</div>
