import { Injectable } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { bradenFormValidator } from "../custom-validators";
@Injectable({
  providedIn: "root",
})
export class FallRiskService {
  constructor() {}

  initFallRiskForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        fallHistory: new UntypedFormControl(null),
        secDiagnoses: new UntypedFormControl(null),
        ambAssist: new UntypedFormControl(null),
        ivTherapy: new UntypedFormControl(null),
        gait: new UntypedFormControl(null),
        mentalStatus: new UntypedFormControl(null),
        timestamp: new UntypedFormControl(
          new Date(new Date().setSeconds(0, 0)),
          Validators.required
        ),
      },
      {
        validators: bradenFormValidator,
      }
    );
  }
}
