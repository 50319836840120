import { Mar } from "@mar/models/mar.model";
import { createAction, props } from "@ngrx/store";

export const setMarNormal = createAction(
  "[MAR] Set MAR - Normal medication",
  props<{ mar: Mar[] }>()
);

export const upsertMarNormal = createAction(
  "[MAR] Upsert MAR - Normal medication",
  props<{ mar: Mar[] }>()
);

export const resetMarNormal = createAction(
  "[MAR] Reset to initial state - Normal medication"
);
