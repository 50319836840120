import * as fromTvUnitView from "./tv-unit-view";
import * as fromRoot from "../../reducers";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromTVUnitView from "./tv-unit-view";

export interface TvUnitViewState {
  data: fromTvUnitView.State;
}

export interface State extends fromRoot.AppState {
  tvUnitView: TvUnitViewState;
}

export const reducers: ActionReducerMap<TvUnitViewState, any> = {
  data: fromTvUnitView.reducer,
};

export const getTvUnitViewState =
  createFeatureSelector<TvUnitViewState>("tvUnitView");

export const getTvUnitViewData = createSelector(
  getTvUnitViewState,
  (state) => state.data
);

export const getTVUnitDataSelector = createSelector(
  getTvUnitViewData,
  (state) => state.unit
);

export const selectAllPatientsSelector = createSelector(
  getTvUnitViewData,
  fromTVUnitView.selectAllPatients
);

export const getTVUnitErrorSelector = createSelector(
  getTvUnitViewData,
  (state) => state.error
);

export const getTVUnitLoadingSelector = createSelector(
  getTvUnitViewData,
  (state) => state.loading
);

export const getApiSuccessSelector = createSelector(
  getTvUnitViewData,
  (state) => state.apiSuccess
);

export const getTVUnitName = createSelector(
  getTvUnitViewData,
  (state) => state.unitName
);
