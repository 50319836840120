import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
  map,
  catchError,
  switchMap,
  concatMap,
  mergeMap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";

import * as patientListActions from "../actions/patients/patient-list.action";
import * as unitActions from "../actions/unit.actions";
import * as dischargedPatientListActions from "../actions/patients/dischargedPatientList.action";

import { PatientService } from "src/app/services/patient.service";
import { ShiftService } from "src/app/services/shift.service";

@Injectable()
export class PatientListEffects {
  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private shiftService: ShiftService
  ) {}

  // getPatients$ = createEffect( () => this.actions$.pipe(
  //     ofType(patientListActions.getPatients),
  //     switchMap(({email}) => this.patientService.getAllPatients(email).pipe(
  //         map(patients => {
  //             return patientListActions.setPatients({patients: patients['patients']});
  //         }),
  //         catchError(() => EMPTY)
  //     ))
  // ));

  assignUnitToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignShift),
      concatMap(({ shift }) => {
        return this.shiftService.assignShifts(shift).pipe(
          mergeMap(({ message, data }) => {
            return [
              patientListActions.assignShiftSuccess({ unitId: shift?.unit }),
              // unitActions.updateUnitInfo({ unit: data.units[0] }),
            ];
          }),
          catchError((error) => EMPTY)
        );
      })
    )
  );

  assignAllUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignAllShift),
      switchMap((data) =>
        this.shiftService.assignAllShifts(data).pipe(
          map((response) => {
            return patientListActions.assignAllShiftSuccess({
              name: data?.name,
            });
          }),
          catchError((error) => EMPTY)
        )
      )
    )
  );

  assignAllWorkSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientListActions.assignAllWorkspace),
      switchMap((data) =>
        this.shiftService.assignAllWorkspace(data.workspace).pipe(
          map((response) => {
            return patientListActions.assignAllWorkspaceSuccess({
              workspaceId: data?.workspace?.workspaceId,
            });
          }),
          catchError((error) => EMPTY)
        )
      )
    )
  );

  getAllDischargedPatients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dischargedPatientListActions.getAllDischargedPatients),
      switchMap(({ query }) =>
        this.patientService.getAllDischargedPatients(query).pipe(
          map((patients) => {
            if (patients && patients["data"])
              return dischargedPatientListActions.setAllDischargedPatients({
                patients: patients["data"],
              });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
