import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromUserReducer from "src/app/store/reducers/user";
import { select, Store } from "@ngrx/store";
import { PatientService } from "src/app/services/patient.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: "app-intake-output",
  templateUrl: "./intake-output.component.html",
  styleUrls: ["./intake-output.component.scss"],
})
export class IntakeOutputComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public CPMRN;
  public encounters;

  public currUser;
  public currPatient;
  public isDischargeTimeElapsed = false;

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  constructor(
    private route: ActivatedRoute,
    private store: Store<any>,
    private _patientService: PatientService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    // get user
    this.users$.subscribe((data) => {
      this.currUser = data.currentUser;
    });

    // get patient
    this.patientHeader$.subscribe((patient) => {
      this.currPatient = patient;
      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(this.currPatient);
    });
  }
  public checkUserPermission(): any {
    return this.ngxPermissionsService.getPermission("update_io");
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
