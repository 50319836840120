import * as fromUploadFiles from "../actions/upload-file.action";

export interface UploadFileState {
  entities: any;
  loaded: boolean;
  loading: boolean;
  gcpUploads: any;
}

export const initialState: UploadFileState = {
  entities: [],
  loaded: false,
  loading: false,
  gcpUploads: [],
};

export function reducer(state = initialState, action): UploadFileState {
  switch (action.type) {
    case fromUploadFiles.UPLOAD_FILE: {
      return {
        ...state,
        loading: true,
        loaded: false,
        gcpUploads: [],
      };
    }

    case fromUploadFiles.UPLOAD_FILE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        gcpUploads: [],
      };
    }

    case fromUploadFiles.UPLOAD_FILE_SUCCESS: {
      const entities = action.payload.data;
      const updateEntities = [...state.entities, entities];
      return {
        ...state,
        loading: false,
        loaded: true,
        entities: updateEntities,
        gcpUploads: [],
      };
    }

    case fromUploadFiles.UPLOAD_FILE_GGP: {
      return {
        ...state,
        loading: true,
        loaded: true,
      };
    }

    case fromUploadFiles.UPLOAD_GCP_SUCCESS: {
      const entities = action.payload;
      const updateGcpUploads = entities.status
        ? [...state.gcpUploads, entities.status]
        : state.gcpUploads;
      return {
        ...state,
        loading: action.payload.status === 200 ? false : true,
        loaded: true,
        gcpUploads: updateGcpUploads,
      };
    }
    case "[Root Actions] patient chart closed": {
      return initialState;
    }
    case fromUploadFiles.UPLOAD_FILE_RESET: {
      return initialState;
    }
  }

  return state;
}

export const getUploadFileEntities = (state: UploadFileState) => state.entities;
export const getUploadFileLoading = (state: UploadFileState) => state.loading;
export const getUploadFileLoaded = (state: UploadFileState) => state.loaded;
export const getUploadFileGcpUploads = (state: UploadFileState) =>
  state.gcpUploads;
