import { Component, Input } from "@angular/core";
import { Component as NoteComponent } from "@notes/models/notes.model";
import { PatientCPMRNEncounters } from "../../models/patient";
import { AiraApiService } from "../services/aira-api.service";
import { AiraNoteService } from "../services/aira-note.serivce";
import { MatDialog } from "@angular/material/dialog";
import { AiraNote, AiraNoteResponse } from "../model/AiraNote.model";
import { NotesAiraModalComponent } from "src/app/aira/notes-aira-modal/notes-aira-modal.component";
import { disableAiraScope } from "../aira-scope";
import * as fromNoteStore from "@notes/store";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { FinalNotes } from "@notes-view/model/notes-model";

@Component({
  selector: "app-aira-btn",
  templateUrl: "./aira-btn.component.html",
  styleUrls: ["./aira-btn.component.scss"],
})
export class AiraBtnComponent {
  private _patient: PatientCPMRNEncounters;

  @Input()
  set patient(patient: PatientCPMRNEncounters) {
    this._patient = patient;
    if (patient) {
      this.disableBtn = !!disableAiraScope(patient.note.noteType)(
        patient.note.noteSubType
      )
        ? false
        : true;
    }
  }

  get patient() {
    return this._patient;
  }

  constructor(
    private _airaApiService: AiraApiService,
    private _modalService: MatDialog,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {}

  public disableBtn = false;
  public enableLoader = false;

  get(): void {
    this.disableBtn = true;
    this.enableLoader = true;

    this._airaApiService
      .get(this.patient.cpmrn, this.patient.encounters, this.patient.note)
      .subscribe((data) => {
        this.disableBtn = false;
        this.enableLoader = false;

        let lambdaData = data;
        // if (lambdaData.statusCode !== 200) {
        //   console.log(lambdaData);
        //   return;
        // }
        let airaNote: AiraNoteResponse = lambdaData.data;
        // Retrieve existing data and merge
        this.setupPatientNotesForm((notetemplate) => {
          this.newAiraNoteTemplate(airaNote, notetemplate);
        });
      });
  }

  private setupPatientNotesForm(callback: (data: FinalNotes) => void): void {
    this._noteStore
      .select(fromNoteStore.selectAllNoteContentData)
      .pipe(distinctUntilChanged(), take(1))
      .subscribe(callback);
  }

  newAiraNoteTemplate(airaNote: AiraNoteResponse, noteTemplate: FinalNotes) {
    const componentMap = new Map<string, NoteComponent>();

    // Map existing components from the noteTemplate
    noteTemplate.components.forEach((component) => {
      componentMap.set(component.displayName, component);
    });

    // Replace or add components from the airaNote
    airaNote?.component?.forEach((newComponent) => {
      componentMap.set(newComponent.displayName, newComponent);
    });

    // Convert the map back to an array and assign it to the noteTemplate
    noteTemplate.components = Array.from(componentMap.values());
    noteTemplate.noteType = airaNote.notesType.noteType;
    noteTemplate.noteSubType = airaNote.notesType.noteSubType;

    this.openAiraPopup(noteTemplate);
  }

  openAiraPopup(airaNoteData: FinalNotes) {
    const aira = this._modalService.open(NotesAiraModalComponent, {
      autoFocus: false,
    });
    aira.componentInstance.finalNotes = airaNoteData;
  }
}
