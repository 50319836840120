import { Injectable } from '@angular/core';
import { AddTimeToDatePipe } from '../add-time-to-date.pipe';

@Injectable({ providedIn: 'root' })
export class GeneralFormService {
  constructor() {
  }

  transformTime(date: Date, time) {
    let addTimePipe = new AddTimeToDatePipe();

    return addTimePipe.transform(date, time);
  }
}
