import { Patient } from "src/app/models/patient";
import { createReducer, on, Action } from "@ngrx/store";
import * as rootActions from "src/app/store/actions/root.actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import * as fromDischargedPatientListActions from "src/app/store/actions/patients/dischargedPatientList.action";

export interface DischargePatientState extends EntityState<Patient> {}

export function selectPatientId(a: Patient): string {
  return a._id;
}

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
  selectId: selectPatientId,
});

export const initialState: DischargePatientState = adapter.getInitialState();

const dischargedPatientListReducer = createReducer(
  initialState,
  on(
    fromDischargedPatientListActions.setAllDischargedPatients,
    (state, { patients }) => {
      return adapter.setAll(patients, state);
    }
  ),
  on(fromDischargedPatientListActions.removeAllDischargedPatients, (state) =>
    adapter.removeAll({ ...state })
  ),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state }))
);

const { selectAll } = adapter.getSelectors();

export const selectAllDischargedPatients = selectAll;

export function reducer(
  state: DischargePatientState | undefined,
  action: Action
) {
  return dischargedPatientListReducer(state, action);
}
