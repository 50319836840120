import { createFeatureSelector, createSelector } from "@ngrx/store";
import { User } from "src/app/models/user";

export interface State {
  roundar: {
    on: boolean;
  };
}

export const initialState: State = {
  roundar: {
    on: false,
  },
};

export const getUserPreferenceRoundar = (state: State) => state.roundar;

const userFeatureSelector = createFeatureSelector<User>("user");

export const isLoggedIn$ = createSelector(
  userFeatureSelector,
  (state) => state?.currentUserState
);
