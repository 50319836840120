import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/models/api-response.model";

import { environment } from "src/environments/environment";
import { CreateApiResponse } from "../models/createApiResponse.interface";
import { CreateHandoverParams } from "../models/createHandoverParams.interface";
import { GetHandoverApiParamsInterface } from "../models/getApiParams.interface";
import { GetHandoverNotes } from "../models/getHandoverNotes.interface";
import { UpdateHandoverParams } from "../models/updateHandoverParams.interface";

@Injectable({
  providedIn: "root",
})
export class HandoverNotesService {
  public apiUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public getHandoverNotes({
    handoverStatus,
    cpmrn,
    encounter,
    limit,
    sortBy,
    orderBy = "dsc",
  }: GetHandoverApiParamsInterface): Observable<GetHandoverNotes[]> {
    let params = new HttpParams();
    if (handoverStatus !== undefined) {
      params = params.set("handoverStatus", handoverStatus.toString());
    }
    if (cpmrn !== undefined) {
      params = params.set("cpmrn", cpmrn);
    }
    if (encounter !== undefined) {
      params = params.set("encounter", encounter.toString());
    }
    if (limit !== undefined) {
      params = params.set("limit", limit.toString());
    }
    if (sortBy != undefined) {
      params = params.set("sortBy", sortBy.toString());
      params = params.set("orderBy", orderBy.toString());
    }
    return this._http
      .get<ApiResponse>(`${this.apiUrl}handover_notes`, {
        params,
      })
      .pipe(map((handover) => handover.data));
  }

  public updateHandoverNotes(
    updateParams: UpdateHandoverParams[]
  ): Observable<ApiResponse> {
    return this._http.patch<ApiResponse>(
      `${this.apiUrl}handover_notes`,
      updateParams
    );
  }

  public postHandoverNotes(
    createParams: CreateHandoverParams
  ): Observable<CreateApiResponse> {
    return this._http
      .post<ApiResponse>(`${this.apiUrl}handover_notes`, createParams)
      .pipe(map((handover) => handover.data));
  }
}
