import moment from "moment-timezone";
import { bloodProds } from "src/app/models/io";
import { MedService } from "../../../services/order/med.service";
import { TimezoneService } from "src/app/services/timezone.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GetOrderService {
  constructor(private _tz: TimezoneService, private _medService: MedService) {}

  populateOrder(orderObj, icuDate, ioObj, weight, dischargeDate) {
    let tempOrderDays = [];
    let infusionNames = [];
    let bolusNames = [];

    let tempIoObj = JSON.parse(JSON.stringify(ioObj));

    // closure functions
    const generateOrderData = (arr, orderType = "") => {
      arr.forEach((element, i) => {
        let elemDateobj = this._tz.transformIntoTimezoneObj(element.startTime);
        if (elemDateobj < this._tz.transformIntoTimezoneObj(icuDate)) {
          return false;
        }

        // prepare the prop
        let tempObj = [];
        if (element.type == "medications") {
          if (
            element.route &&
            (element.route == "IV" || element.route == "IO")
          ) {
            let tempUnit = element.unit.split("/");

            let liquidFound = false;
            let perBodyWeight = false;
            let perLitre = false;
            let perMinute = false;
            let setPlaceholer = true;
            tempUnit.forEach((elem, index) => {
              if (
                elem == "l" ||
                elem == "ml" ||
                tempUnit[tempUnit.length - 1] === "min" ||
                tempUnit[tempUnit.length - 1] === "hr"
              ) {
                liquidFound = true;
                setPlaceholer = false;
              }

              if (elem == "l") {
                perLitre = true;
              }

              if (elem == "kg") {
                perBodyWeight = true;
              }

              if (elem == "min") {
                perMinute = true;
              }
            });

            let tempDate = elemDateobj;
            let endDateObj;
            if (element.endTime) {
              endDateObj = this._tz.transformIntoTimezoneObj(element.endTime);
            } else {
              let today = this._tz.getCurrentTimeObj();
              endDateObj = this._tz.getCurrentTimeObj();
              endDateObj.date(today.date() + 1);
            }
            let freq = 1;
            let multiplier = 1;

            if (perLitre) {
              multiplier *= 1000;
            }

            if (perBodyWeight) {
              multiplier *= weight;
            }

            if (perMinute) {
              multiplier *= 60;
            }

            if (
              element.frequency?.fType == "every" ||
              element.frequency?.fType == "continuous"
            ) {
              // if (element.frequency.fType == 'continuous') {
              let firstHour = true;
              while (tempDate < this._tz.getCurrentTimeObj()) {
                let tDate =
                  tempDate.year() +
                  "-" +
                  tempDate.month() +
                  "-" +
                  tempDate.date();
                let eDate =
                  endDateObj.year() +
                  "-" +
                  endDateObj.month() +
                  "-" +
                  endDateObj.date();
                let tempAmount = 0;

                // get concentration
                const concRate = this._medService.getRate(element);
                const quantity =
                  element.concentration &&
                  element.concentration.value &&
                  concRate &&
                  concRate.rate
                    ? +concRate.rate.min
                    : element.quantity * multiplier;

                // if first hour
                if (element.frequency?.fType == "continuous" && firstHour) {
                  // tempAmount = Math.round((element.quantity * multiplier)  * (60 - elemDateobj.getMinutes()) / 60 *100) / 100;
                  tempAmount =
                    (((quantity * (60 - elemDateobj.minute())) / 60) * 100) /
                    100;
                } else {
                  // tempAmount = (element.quantity * multiplier);
                  tempAmount = quantity;
                }

                tempAmount =
                  tempAmount < 1
                    ? +tempAmount.toFixed(4)
                    : +tempAmount.toFixed(1);

                // if last hour
                if (
                  element.frequency?.fType == "continuous" &&
                  tDate == eDate &&
                  tempDate.hour() == endDateObj.hour()
                ) {
                  // tempAmount = Math.round((element.quantity * multiplier) * (endDateObj.getMinutes()) / 60 *100) / 100;
                  tempAmount =
                    (((quantity * endDateObj.minute()) / 60) * 100) / 100;
                }

                // calculate date
                let tMonth = tempDate.month() + 1;
                let sub = "";

                if (element.frequency?.fType == "continuous") {
                  if (infusionNames.indexOf(element.name) < 0) {
                    infusionNames.push(element.name);
                  }

                  sub = "infusion";
                } else if (element.frequency?.fType == "every") {
                  freq = getHours(element.frequency);
                  if (
                    element.frequency.mins != "" &&
                    element.frequency.mins > 0
                  ) {
                    freq++;
                  }

                  if (bolusNames.indexOf(element.name) < 0) {
                    bolusNames.push(element.name);
                  }

                  sub = "bolus";
                }

                // check if inactive
                let updatedAt = this._tz.transformIntoTimezoneObj(
                  element.updatedAt
                );
                if (
                  orderType == "inactive" &&
                  updatedAt.valueOf() < tempDate.valueOf()
                ) {
                  break;
                }

                // check if discharged
                if (dischargeDate) {
                  let dDate = this._tz.transformIntoTimezoneObj(dischargeDate);
                  if (dDate.valueOf() < tempDate.valueOf()) {
                    break;
                  }
                }

                // push object
                tempOrderDays.push({
                  type: "meds",
                  sub: sub,
                  setPlaceholder: setPlaceholer,
                  name: element.name,
                  unit: element.unit,
                  note: element.additionalInformation,
                  amount: tempAmount,
                  hourName: tempDate.hour(),
                  minuteName: tempDate.minute(),
                  dayDate:
                    tempDate.date() + "-" + tMonth + "-" + tempDate.year(),
                  freq: freq,
                  timestamp: this._tz.transformIntoTimezoneStr(
                    tempDate.year() + "-" + tMonth + "-" + tempDate.date()
                  ),
                  stoppedAt: orderType == "inactive" ? element.updatedAt : "",
                });

                // increment date
                tempDate = this._tz.transformIntoTimezoneObj(
                  tempDate.hour(tempDate.hour() + freq)
                );

                // first hour is false
                firstHour = false;

                // if last date then exit
                if (tDate == eDate && tempDate.hour() > endDateObj.hour()) {
                  break;
                }
              }
            } else {
              let tMonth = tempDate.month() + 1;
              tempOrderDays.push({
                type: "meds",
                sub: "bolus",
                setPlaceholder: setPlaceholer,
                name: element.name,
                unit: element.unit,
                note: element.additionalInformation,
                amount: element.quantity / multiplier,
                hourName: tempDate.hour(),
                minuteName: tempDate.minute(),
                dayDate: tempDate.date() + "-" + tMonth + "-" + tempDate.year(),
                freq: freq,
                timestamp: this._tz.transformIntoTimezoneStr(
                  tempDate.year() + "-" + tMonth + "-" + tempDate.date()
                ),
              });

              if (bolusNames.indexOf(element.name) < 0) {
                bolusNames.push(element.name);
              }
            }
          }
        } else if (element.type == "bloods") {
          let unitM = 300;
          let tMonth = elemDateobj.month() + 1;
          tempOrderDays.push({
            type: "blood",
            name: element.title,
            note: element.additionalInformation,
            amount: parseInt(element.quantity) * unitM,
            hourName: elemDateobj.hour(),
            minuteName: elemDateobj.minute(),
            dayDate:
              elemDateobj.date() + "-" + tMonth + "-" + elemDateobj.year(),
            freq: 1,
            timestamp: this._tz.transformIntoTimezoneStr(
              elemDateobj.year() + "-" + tMonth + "-" + elemDateobj.date()
            ),
          });
        } else if (
          element.type == "diets" &&
          element.rate &&
          element.rate.value &&
          element.name
        ) {
          let sub = "";
          if (element.name.toLowerCase().indexOf("oral") >= 0) {
            sub = "oral";
          } else if (element.name.toLowerCase().indexOf("tube") >= 0) {
            sub = "tube";
          }

          if (sub == "oral" || sub == "tube") {
            let tempUnit = element.rate.unit;
            let multiplier = tempUnit == "hr" ? 1 : 60;
            let freq = 1;

            let tempDate = elemDateobj;
            let endDateObj;
            if (element.endTime) {
              endDateObj = this._tz.transformIntoTimezoneObj(element.endTime);
            } else {
              let today = this._tz.getCurrentTimeObj();
              endDateObj = this._tz.getCurrentTimeObj();
              endDateObj.date(today.date() + 1);
            }

            if (element.frequency?.fType == "continuous") {
              let firstHour = true;
              while (tempDate < this._tz.getCurrentTimeObj()) {
                let tDate =
                  tempDate.year() +
                  "-" +
                  tempDate.month() +
                  "-" +
                  tempDate.date();
                let eDate =
                  endDateObj.year() +
                  "-" +
                  endDateObj.month() +
                  "-" +
                  endDateObj.date();
                let tempAmount = 0;

                const quantity = parseInt(element.rate.value) * multiplier;

                // if first hour
                if (element.frequency?.fType == "continuous" && firstHour) {
                  // tempAmount = Math.round((element.quantity * multiplier)  * (60 - elemDateobj.getMinutes()) / 60 *100) / 100;
                  tempAmount =
                    (((quantity * (60 - elemDateobj.minute())) / 60) * 100) /
                    100;
                } else {
                  tempAmount = quantity;
                }

                tempAmount =
                  tempAmount < 1
                    ? +tempAmount.toFixed(4)
                    : +tempAmount.toFixed(1);

                // if last hour
                if (
                  element.frequency?.fType == "continuous" &&
                  tDate == eDate &&
                  tempDate.hour() == endDateObj.hour()
                ) {
                  // tempAmount = Math.round((element.quantity * multiplier) * (endDateObj.getMinutes()) / 60 *100) / 100;
                  tempAmount =
                    (((quantity * endDateObj.minute()) / 60) * 100) / 100;
                }

                // calculate date
                let tMonth = tempDate.month() + 1;

                // check if inactive
                let updatedAt = this._tz.transformIntoTimezoneObj(
                  element.updatedAt
                );
                if (
                  orderType == "inactive" &&
                  updatedAt.valueOf() < tempDate.valueOf()
                ) {
                  break;
                }

                // check if discharged
                if (dischargeDate) {
                  let dDate = this._tz.transformIntoTimezoneObj(dischargeDate);
                  if (dDate.valueOf() < tempDate.valueOf()) {
                    break;
                  }
                }

                // push object
                tempOrderDays.push({
                  type: "feeds",
                  sub: sub,
                  setPlaceholder: false,
                  name: element.name,
                  unit: "ml",
                  note: element.additionalInformation,
                  amount: tempAmount,
                  hourName: tempDate.hour(),
                  minuteName: tempDate.minute(),
                  dayDate:
                    tempDate.date() + "-" + tMonth + "-" + tempDate.year(),
                  freq: freq,
                  timestamp: this._tz.transformIntoTimezoneStr(
                    tempDate.year() + "-" + tMonth + "-" + tempDate.date()
                  ),
                  stoppedAt: orderType == "inactive" ? element.updatedAt : "",
                });

                // increment date
                tempDate = this._tz.transformIntoTimezoneObj(
                  tempDate.hour(tempDate.hour() + freq)
                );

                // first hour is false
                firstHour = false;

                // if last date then exit
                if (tDate == eDate && tempDate.hour() > endDateObj.hour()) {
                  break;
                }
              }
            }
          }
        }
      });
    };

    const populateDayObj = (arr) => {
      arr.forEach((element) => {
        let elemDate: string = element.dayDate;

        let dayFound = false;

        tempIoObj.forEach((day) => {
          let dayDateObj = this._tz.transformIntoTimezoneObj(day.dayDate);
          let tMonth = dayDateObj.month() + 1;
          let dayDate =
            dayDateObj.date() + "-" + tMonth + "-" + dayDateObj.year();

          if (dayDate == elemDate) {
            //   Day found
            dayFound = true;

            let hourFound = false;

            day.hours.forEach((hour) => {
              if (element.hourName == hour.hourName) {
                //   Hour found
                hourFound = true;

                let minuteFound = false;

                hour.minutes.forEach((minute) => {
                  if (element.minuteName == minute.minuteName) {
                    minuteFound = true;
                    if (!minute.intake) {
                      minute.intake = {
                        feeds: {},
                        meds: {},
                        bloodProducts: {},
                      };
                    }

                    if (!minute.output) {
                      minute.output = {
                        drain: [],
                        procedure: [],
                        dialysis: [],
                      };
                    }

                    if (!minute.intake.meds) {
                      minute.intake.meds = {};
                    }
                    if (!minute.intake.bloodProducts) {
                      minute.intake.bloodProducts = {};
                    }
                    if (!minute.intake.feeds) {
                      minute.intake.feeds = {};
                    }

                    this.fillProps(element, minute);
                  }
                });

                if (minuteFound == false) {
                  let tempMinute = {
                    minuteName: element.minuteName,
                    intake: {
                      feeds: {},
                      meds: {},
                      bloodProducts: {},
                    },
                    output: {
                      drain: [],
                      procedure: [],
                      dialysis: [],
                    },
                  };

                  this.fillProps(element, tempMinute);
                  hour.minutes.push(tempMinute);
                }
              }
            });

            if (hourFound == false) {
              let tempHour = {
                hourName: element.hourName,
                minutes: [],
              };

              let tempMinute = {
                minuteName: element.minuteName,
                intake: {
                  feeds: {},
                  meds: {},
                  bloodProducts: {},
                },
                output: {
                  drain: [],
                  procedure: [],
                  dialysis: [],
                },
              };

              this.fillProps(element, tempMinute);
              tempHour.minutes.push(tempMinute);
              day.hours.push(tempHour);
            }
          }
        });

        if (dayFound == false) {
          //   Create new day
          let tempDay = {
            dayDate: element.timestamp,
            dayNumber: this.calcDayNumber(
              this._tz.transformIntoTimezoneObj(icuDate),
              this._tz.transformIntoTimezoneObj(element.timestamp)
            ),
            hours: [],
          };

          let tempHour = {
            hourName: element.hourName,
            minutes: [],
          };

          let tempMinute = {
            minuteName: element.minuteName,
            intake: {
              feeds: {},
              meds: {},
              bloodProducts: {},
            },
            output: {
              drain: [],
              procedure: [],
              dialysis: [],
            },
          };

          this.fillProps(element, tempMinute);
          tempHour.minutes.push(tempMinute);
          tempDay.hours.push(tempHour);
          tempIoObj.push(tempDay);
        }
      });
    };

    generateOrderData(orderObj.active.medications);
    generateOrderData(orderObj.inactive.medications, "inactive");
    generateOrderData(orderObj.completed.medications, "inactive");

    generateOrderData(orderObj.active.bloods);
    generateOrderData(orderObj.inactive.bloods);
    generateOrderData(orderObj.completed.bloods);

    generateOrderData(orderObj.active.diets);
    generateOrderData(orderObj.inactive.diets, "inactive");
    generateOrderData(orderObj.completed.diets, "inactive");

    populateDayObj(tempOrderDays);

    let connectedProcedure = this.getConnectedProcedures(
      orderObj.active.procedures,
      dischargeDate
    );

    // sorting the day
    tempIoObj.sort(function (a, b) {
      return a["dayNumber"] - b["dayNumber"];
    });
    tempIoObj.reverse();

    // sorting the hours
    tempIoObj.forEach((element) => {
      element.hours.sort(function (a, b) {
        return a["hourName"] - b["hourName"];
      });
      element.hours.reverse();
    });

    return {
      ioObj: tempIoObj,
      infNames: infusionNames,
      bolNames: bolusNames,
      proceduresConnected: connectedProcedure,
    };
  }

  // Support functions
  fillProps(prop, minute) {
    if (prop.type == "meds") {
      let propFound = false;
      if (!minute.intake.meds[prop.sub]) {
        minute.intake.meds[prop.sub] = [];
      } else {
        minute.intake.meds[prop.sub].forEach((element) => {
          if (element.name == prop.name) {
            propFound = true;
          }
        });
      }

      if (!propFound) {
        if (prop.sub == "infusion" || prop.sub == "bolus") {
          let tAmt = prop.amount;
          if (prop.setPlaceholder) {
            tAmt = 0;
          }

          minute.intake.meds[prop.sub].push({
            name: prop.name,
            note: prop.note,
            amount: tAmt,
            orders: true,
            setPlaceholder: prop.setPlaceholder,
          });
        }
      }
    } else if (prop.type == "blood") {
      let type = "";
      bloodProds.forEach((bp) => {
        if (bp.name == prop.name) {
          type = bp.prop;
        }
      });

      // exit if its manually added
      if (
        minute.intake.bloodProducts[type] &&
        minute.intake.bloodProducts[type].edited
      ) {
        return false;
      }

      if (!minute.intake.bloodProducts[type]) {
        let tNote = prop.note ? prop.note : "";
        minute.intake.bloodProducts[type] = {
          amount: prop.amount,
          note: tNote,
          orders: true,
        };
      } else {
        let tNote = prop.note ? prop.note : "";
        minute.intake.bloodProducts[type].amount += prop.amount;
        minute.intake.bloodProducts[type].note += ", " + tNote;
      }
    } else if (prop.type == "feeds") {
      if (!minute.intake.feeds[prop.sub]) {
        minute.intake.feeds[prop.sub] = {};
      }

      if (!minute.intake.feeds[prop.sub]["amount"]) {
        if (prop.sub == "oral" || prop.sub == "tube") {
          let tAmt = prop.amount;

          minute.intake.feeds[prop.sub] = {
            name: prop.name,
            note: prop.note,
            amount: tAmt,
            orders: true,
          };
        }
      }
    }
  }

  calcDayNumber(icuDate, nowDate) {
    let aDay = 1000 * 60 * 60 * 24;
    icuDate.startOf("day");
    nowDate.startOf("day");

    let dIcu = icuDate.valueOf();
    let dNow = nowDate.valueOf();

    let diff = Math.abs(dNow - dIcu);

    // Convert back to days and return
    return Math.ceil(diff / aDay) + 1;
  }

  getConnectedProcedures(arr, dischargeDate) {
    let connProc = {
      foley: [],
      arterial: [],
      cvc: [],
      piv: [],
      hdcp: [],
      others: [],
      total: 0,
    };
    arr.forEach((procedure) => {
      let currDate = this._tz.getCurrentTimeObj();

      if (
        !procedure.endTime ||
        currDate < this._tz.transformIntoTimezoneObj(procedure.endTime)
      ) {
        const endTime = dischargeDate || this._tz.getCurrentTimestampStr();
        let totalDays = moment(endTime).diff(procedure.startTime, "days") + 1;

        connProc.total++;

        const pType = procedure.pType ? procedure.pType.toLowerCase() : "";

        switch (pType) {
          case "insert foley catheter":
            connProc.foley.push({
              days: totalDays,
              timestamp: procedure.startTime,
            });
            break;

          case "cvc (central line insertion)":
            connProc.cvc.push({
              days: totalDays,
              lat: procedure.laterality,
              site: procedure.site,
              type: procedure.pType,
              timestamp: procedure.startTime,
            });
            break;

          case "piv":
            connProc.piv.push({
              days: totalDays,
              lat: procedure.laterality,
              site: procedure.site,
              type: procedure.pType,
              timestamp: procedure.startTime,
            });
            break;

          case "arterial":
            connProc.arterial.push({
              days: totalDays,
              lat: procedure.laterality,
              site: procedure.site,
              type: procedure.pType,
              timestamp: procedure.startTime,
            });
            break;

          case "hd catheter placement":
            connProc.hdcp.push({
              days: totalDays,
              lat: procedure.laterality,
              site: procedure.site,
              type: procedure.pType,
              timestamp: procedure.startTime,
            });
            break;

          default:
            connProc.others.push({
              days: totalDays,
              lat: procedure.laterality,
              site: procedure.site,
              type: procedure.pType,
              timestamp: procedure.startTime,
            });
            break;
        }
      }
    });

    return connProc;
  }
}

function getHours(frequency): number {
  if (!frequency) return;
  const { days, hours } = frequency;
  return (+days || 0) * 24 + (+hours || 0);
}
