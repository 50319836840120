import { Action, createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { RAlertDevice } from "../../models/RAlert/device.model";
import { RAlertApiActions, RAlertListPageActions } from "../actions";

export interface State extends EntityState<RAlertDevice> {}

export function sortByTime(a: RAlertDevice, b: RAlertDevice): number {
  if (a._id === b._id) {
    return 0;
  } else if (a._id > b._id) {
    return -1;
  } else {
    return 1;
  }
}

export const adapter: EntityAdapter<RAlertDevice> =
  createEntityAdapter<RAlertDevice>({
    selectId: (message: RAlertDevice) => message._id,
    sortComparer: sortByTime,
  });

export const initialState: State = adapter.getInitialState();

const rAlertDeviceReducer = createReducer(
  initialState,
  on(RAlertApiActions.addAllRalertDevices, (state, { devices }) => {
    const mappedDevices = devices.map((device) => ({
      ...device,
      online: false,
    }));

    return adapter.setAll(mappedDevices, state);
  }),
  on(RAlertApiActions.addRalertDevice, (state, { device }) =>
    adapter.addOne({ ...device, online: false }, state)
  ),
  on(RAlertApiActions.updateRalertDevice, (state, { device }) =>
    adapter.updateOne(device, state)
  ),
  on(RAlertApiActions.deleteRalertDevice, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  on(
    RAlertListPageActions.setRalertDeviceStatus,
    (state, { device, status }) => {
      const data = {
        id: device._id,
        changes: { ...device, online: status === "online" },
      };

      return adapter.updateOne(data, state);
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return rAlertDeviceReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectDeviceIds = selectIds;

export const selectDeviceEntities = selectEntities;

export const selectAllDevices = selectAll;

export const selectDeviceTotal = selectTotal;
