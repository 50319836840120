import { RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomRouterState } from "./custom-serialize";

export const getRouterState =
  createFeatureSelector<RouterReducerState<CustomRouterState>>("router");

export const getCurrentRoute = createSelector(getRouterState, (router) => {
  return router?.state;
});
