import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Addendum, Cosign } from "@notes/models/notes.model";
import { map, catchError } from "rxjs/operators";
import { CoreHttpService } from "src/app/services/base-service/core-http.service";
import { User } from "src/app/models/user";
import * as fromUserReducer from "src/app/store/reducers/user/index";
import { Store, select } from "@ngrx/store";
import { FormBuilder } from "@angular/forms";
import { CustomRouterState } from "src/app/store/router/custom-serialize";
import { FinalNotes, NotesAPIResponse } from "@notes-view/model/notes-model";
import { BehaviorSubject, Observable } from "rxjs";

const COSIGNAUTHOR = "Physician note";

@Injectable({
  providedIn: "root",
})
export class NoteService extends CoreHttpService {
  public user: User;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  author: string;
  constructor(
    private http: HttpClient,
    private store: Store<{}>,
    private _fb: FormBuilder,
    _routeStore: Store<CustomRouterState>
  ) {
    super(_routeStore);
    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
        let author = "";

        if (data.title) {
          author += data.title + " ";
        }

        author += data.name;

        if (data["qualification"]) {
          author += " " + data["qualification"];
        }
        this.author = author;
      }
    });
  }

  private openPopUpSource = new BehaviorSubject<boolean>(false);
  public openPopUpTrigger$ = this.openPopUpSource.asObservable();

  private buttonClickSource = new BehaviorSubject<boolean>(false);
  public buttonClickTrigger$ = this.buttonClickSource.asObservable();

  openPopUpTrigger(state: boolean) {
    this.openPopUpSource.next(state);
  }

  buttonPopUpTrigger(state: boolean) {
    this.buttonClickSource.next(state);
  }

  getAddendumAndCosignMerge(element: FinalNotes): Addendum[] | Cosign[] {
    if (!element.cosign && !element.addendum.length) return [];

    const allNotes = [] as Cosign[] | Addendum[];

    if (element.cosign && element.cosign.physicianMessage) {
      const cosignNote = {
        name: COSIGNAUTHOR,
        attestedOn: element.cosign.attestedOn ?? null,
        note: element.cosign.physicianMessage,
      };
      allNotes.push(cosignNote);
    }

    if (element.addendum && element.addendum.length) {
      const addendumNotes = element.addendum
        .map((addendum: Addendum) => {
          if (addendum.name && addendum.timestamp && addendum.note) {
            return {
              name: addendum.name,
              attestedOn: addendum.timestamp,
              note: addendum.note,
            };
          }
        })
        .filter((note) => note);
      allNotes.push(...addendumNotes);
    }

    allNotes.sort((a, b) => {
      const dateA = a.attestedOn;
      const dateB = b.attestedOn;

      return dateA instanceof Date && dateB instanceof Date
        ? dateA.getTime() - dateB.getTime()
        : 0;
    });

    return allNotes;
  }

  getNoteData() {
    const { patientCPMRN, patientEncounters } = this.params;
    return this.http
      .get<{
        data: { components: NotesAPIResponse };
        message: string;
        status: string;
      }>(`${this.env.apiUrl}notes/${patientCPMRN}/${patientEncounters}`)
      .pipe(
        map(({ data }) => data),
        catchError(this.errorHandler)
      );
  }

  deletePendedNote(noteReferenceId: string): Observable<any> {
    return this.http
      .put<any>(
        this.env.apiUrl + "notes/deletepended/",
        {
          CPMRN: this.params.patientCPMRN,
          encounters: this.params.patientEncounters,
          noteId: noteReferenceId,
        },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
}
