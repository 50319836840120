import { createAction, props } from '@ngrx/store';
import { RAlertDevice, RAlertDeviceForm, RAlertDeviceStatus } from '../../models/RAlert/device.model';

export const getRalertDevices = createAction(
  '[Ralert List Page] Get All Ralert Devices'
);

export const submitNewRalertDeviceForm = createAction(
  '[Ralert Form Modal] Submit New RAlert Device Form',
  props<{ data: RAlertDeviceForm }>()
);

export const submitEditRalertDeviceForm = createAction(
  '[Ralert Form Modal] Submit Edit RAlert Device Form',
  props<{ data: RAlertDeviceForm, id: string }>()
);

export const clickDeleteRAlertDevice = createAction(
  '[Ralert List Page] Click RAlert Device Delete',
  props<{ id: string }>()
);

export const getAllRalertDeviceStatus = createAction(
  '[Ralert List Page] Get All RAlert Device Status',
  props<{ devices: RAlertDeviceStatus[] }>()
);

export const setRalertDeviceStatus = createAction(
  '[Ralert List Page] Set RAlert Device Status',
  props<{ device: RAlertDevice, status: string }>()
);
