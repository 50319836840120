export interface File {
  file: File;
  id: string;
}

export interface FileState {
  filesToBeUploaded: number;
  filesUploaded: number;
}

export interface FileSigned {
  id?: number;
  name: string;
  size?: number;
  type: string;
}

export interface FileSignedBE {
  id: number;
  name: string;
  key: string;
  url: string;
}

export interface FetchFile {
  key: string;
  type: string;
}

export type fileType = FileTypes.IMAGE | FileTypes.PDF | FileTypes.VIDEO;

export enum FileTypes {
  IMAGE = "image",
  PDF = "pdf",
  VIDEO = "video",
}

export const imagesTypes = ["image/png", "image/jpeg"];
export const pdfType = ["application/pdf"];
export const videoTypes = [
  "video/m4v",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/ogv",
];

export const supportedVideoTypes = [
  "video/m4v",
  "video/mp4",
  "video/webm",
  "video/ogv",
];

export const supportedFiles = [
  ...imagesTypes,
  ...pdfType,
  ...supportedVideoTypes,
];
