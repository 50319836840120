import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { NotesService } from "../../services/notes.service";

import * as finalNoteActions from "../actions/final-notes.actions";

@Injectable()
export class FinalNoteEffects {
  constructor(
    private actions$: Actions,
    private _noteService: NotesService,
    private _alertService: AlertService
  ) {}

  createFinalNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalNoteActions.CREATE_NOTE),
      map((action: finalNoteActions.NoteAction) => action.payload),
      switchMap(({ CPMRN, encounters, notes }) => {
        return this._noteService.createNote(CPMRN, encounters, notes).pipe(
          map((code: any) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Notes submitted successfully!",
            });
            return new finalNoteActions.CreateNoteSucess(code);
          }),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: error.error.message,
            });
            return of(new finalNoteActions.CreateNoteFail(error));
          })
        );
      })
    )
  );

  autoSaveDraftNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalNoteActions.NOTE_AUTO_SAVE),
      map((action: finalNoteActions.NoteAction) => action.payload),
      switchMap(({ CPMRN, encounters, draftNotes }) => {
        return this._noteService
          .updateDraftNote(CPMRN, encounters, draftNotes)
          .pipe(
            map((code: any) => new finalNoteActions.NoteAutoSaveSucess(code)),
            catchError((error) =>
              of(new finalNoteActions.NoteAutoSaveFail(error))
            )
          );
      })
    )
  );

  deleteNotess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalNoteActions.NOTE_DELETE),
      map((action: finalNoteActions.NoteAction) => action.payload),
      switchMap(({ CPMRN, encounters, refId }) => {
        return this._noteService.deleteDraft(CPMRN, encounters, refId).pipe(
          map((code: any) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Draft note deleted successfully!",
            });
            return new finalNoteActions.NoteDeleteSucess(code);
          }),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: error.error.message,
            });
            return of(new finalNoteActions.NoteDeleteFail(error));
          })
        );
      })
    )
  );

  attestNotess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalNoteActions.ATTEST_NOTE),
      map((action: finalNoteActions.NoteAction) => action.payload),
      switchMap(
        ({
          CPMRN,
          encounters,
          refId,
          impactCase,
          chargeable,
          cosign,
          addendumArray,
        }) => {
          return this._noteService
            .attestNote(
              CPMRN,
              encounters,
              refId,
              impactCase,
              chargeable,
              cosign,
              addendumArray
            )
            .pipe(
              map((code: any) => {
                this._alertService.showNotification({
                  type: "Success",
                  message: "Request closed!",
                });
                return new finalNoteActions.UpdateNoteSucess(code);
              }),
              catchError((error) => {
                this._alertService.showNotification({
                  type: "Error",
                  message: "Server error!",
                });
                return of(new finalNoteActions.UpdateNoteFail(error));
              })
            );
        }
      )
    )
  );

  updateNotess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(finalNoteActions.NOTE_UPDATE),
      map((action: finalNoteActions.NoteAction) => action.payload),
      switchMap(({ CPMRN, encounters, refId, notes }) => {
        return this._noteService
          .updateNote(CPMRN, encounters, refId, notes)
          .pipe(
            map((code: any) => {
              this._alertService.showNotification({
                type: "Success",
                message: "Note updated successfully!",
              });
              return new finalNoteActions.UpdateNoteSucess(code);
            }),
            catchError((error) => {
              this._alertService.showNotification({
                type: "Error",
                message: error.error.message,
              });
              return of(new finalNoteActions.UpdateNoteFail(error));
            })
          );
      })
    )
  );

  refreshTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        finalNoteActions.CREATE_NOTE_SUCCESS,
        finalNoteActions.NOTE_DELETE_SUCCESS,
        finalNoteActions.NOTE_UPDATE_SUCCESS
      ),
      map((data) => {
        return new finalNoteActions.RefreshTabStatus(data);
      })
    )
  );
}
