import { createAction, props } from "@ngrx/store";
import {
  NoteFormsDataModel,
  NoteTypeCriteria,
} from "@notes/models/notes.model";

export const LOAD_NOTE_TYPES = "[NOTE-INPUT-FORM-DATA] load note types";
export const LOAD_NOTE_TYPES_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] load note types success";

export const loadNoteTypes = createAction(
  LOAD_NOTE_TYPES,
  props<{ notetypeCriteria: NoteTypeCriteria }>()
);

export const loadNoteTypeSuccess = createAction(
  LOAD_NOTE_TYPES_SUCCESS,
  props<{ noteContents: NoteFormsDataModel[] }>()
);
