import { createSelector } from "@ngrx/store";
import * as fromTrendsReducer from "../reducers";
import * as fromGrowthTrendsReducer from "../reducers/growth-trends.reducer";

export const getGrowthTrendSelector = createSelector(
  fromTrendsReducer.getTrendsState,
  (state) => state.growthTrends
);

export const {
  selectAll: getAllGrowthTrendsData,
  selectEntities: getAllGrowthTrendsDictionay,
  selectIds: getAllGrowthTrendsNames,
} = fromGrowthTrendsReducer.adapter.getSelectors(getGrowthTrendSelector);
