import { createSelector } from "@ngrx/store";
import * as fromInfectionReducer from "../reducers";

const getInfectionQuestionState = createSelector(
  fromInfectionReducer.getInfectionBundleState,
  (state) => state.question
);

export const getAllQuestionSelector = createSelector(
  getInfectionQuestionState,
  (state) => state.questions
);
