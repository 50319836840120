import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class transferRowClickSelection {
  public transferRowClick = new BehaviorSubject<Object>({});
  transferSelections = this.transferRowClick.asObservable();

  constructor() { }
  transferSelection(Data) {
    this.transferRowClick.next(Data)
  }
}