import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightText",
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: any, searchInput): unknown {
    const reText = new RegExp(searchInput, "gi");
    value = value.replace(reText, `<span class='highlight-text'>$&</span>`);
    return value;
  }
}
