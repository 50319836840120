import { createAction, props } from '@ngrx/store';

export const loadProtocols = createAction(
  '[List Protocol Page] Load Protocols'
);

export const selectProtocol = createAction(
  '[List Protocol Page] Select Protocol',
  props<{ id: string }>()
);

export const searchProtocols = createAction(
  '[List Protocol Page] Search Protocols',
  props<{ text: string }>()
);
