<section class="iris-modal-container form-container add-device">
  <div class="form-title">Device registration</div>
  <div class="form-body">
    <form
      class="iris-form"
      autocomplete="off"
      [formGroup]="form"
      (submit)="onSubmit(form.value)"
    >
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label for="deviceId" class="mr-3">Device Id*</mat-label>
              <input
                matInput
                type="text"
                class="iris-input"
                formControlName="deviceId"
                id="deviceId"
              />
              <mat-error> Mandatory field </mat-error>
            </mat-form-field>
          </div>
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label for="comment" class="mr-3">Comment</mat-label>
              <input
                matInput
                type="text"
                class="iris-input"
                formControlName="comment"
                id="comment"
              />
            </mat-form-field>
          </div>
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label for="hospitalName">Hospital name*</mat-label>
              <mat-select
                formControlName="hospitalName"
                id="hospitalName"
                class="iris-input"
                (selectionChange)="onHospitalChange($event.value)"
              >
                <mat-option
                  *ngFor="let hospital of hospitals"
                  [value]="hospital.name"
                >
                  {{ hospital.name }}
                </mat-option>
              </mat-select>
              <mat-error> Mandatory field </mat-error>
            </mat-form-field>
          </div>
          <div class="iris-form-row" *ngIf="units?.length > 0">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label for="unitName" class="mr-3">Unit name*</mat-label>
              <mat-select
                formControlName="unitName"
                id="unitName"
                class="iris-input"
                (selectionChange)="onUnitChange()"
              >
                <mat-option>Select unit</mat-option>
                <mat-option
                  *ngFor="let unit of units"
                  [disabled]="
                    unit.ralertDeviceExists && unit.name !== data?.unitName
                  "
                  [value]="unit.name"
                >
                  {{ unit.name }}
                </mat-option>
              </mat-select>
              <mat-error> Mandatory field </mat-error>
            </mat-form-field>
          </div>
          <div class="iris-form-row" *ngIf="units?.length && beds.length">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label for="unitName" class="mr-3">Bed</mat-label>
              <mat-select formControlName="bedNo" id="bedNo" class="iris-input">
                <mat-option>Select bed</mat-option>
                <mat-option *ngFor="let bed of beds" [value]="bed">
                  {{ bed }}
                </mat-option>
              </mat-select>
              <mat-error> Mandatory field </mat-error>
            </mat-form-field>
          </div>
          <div class="iris-form-row">
            <button
              type="button"
              class="button-secondary"
              mat-flat-button
              (click)="addSecondWifi()"
              *ngIf="wifi?.controls?.length === 1"
            >
              Add secondary wifi
            </button>
          </div>
        </div>
        <div class="row-right">
          <div formArrayName="wifi">
            <div class="wifi" *ngFor="let wifi of wifi.controls; let i = index">
              <div class="wifi-header">
                <div>Wifi {{ i + 1 }}</div>
                <fa-icon
                  [icon]="['far', 'trash-alt']"
                  class="error-text ml-2 cp-cursor-pointer"
                  matTooltip="Delete"
                  *ngIf="i === 1"
                  (click)="removeSecondaryWifi()"
                ></fa-icon>
              </div>

              <div class="wifi-shortcuts">
                <span class="mr">Wifi shortcuts: </span>
                <div>
                  <button
                    mat-flat-button
                    class="button-primary mr"
                    type="button"
                    (click)="clickOnWifiShortcut('robocraze', i)"
                  >
                    Robocraze
                  </button>

                  <button
                    mat-flat-button
                    class="button-primary"
                    type="button"
                    (click)="clickOnWifiShortcut('cp', i)"
                  >
                    CP
                  </button>
                </div>
              </div>

              <ng-container [formGroupName]="i">
                <div class="wifi-settings">
                  <div class="iris-form-row mr">
                    <mat-form-field
                      [floatLabel]="'always'"
                      hideRequiredMarker="true"
                    >
                      <mat-label [for]="'ssid-' + i" class="mr-3"
                        >SSID*:</mat-label
                      >
                      <input
                        type="text"
                        matInput
                        type="text"
                        class="iris-input"
                        formControlName="ssid"
                        [id]="'ssid-' + i"
                      />
                      <mat-error> SSID is required </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="iris-form-row">
                    <mat-form-field
                      [floatLabel]="'always'"
                      hideRequiredMarker="true"
                    >
                      <mat-label [for]="'psk-' + i" class="mr-3"
                        >Password*:</mat-label
                      >
                      <input
                        matInput
                        type="text"
                        class="iris-input"
                        formControlName="psk"
                        [id]="'psk-' + i"
                      />
                      <mat-error> Password is required </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="getAdvance(i)?.value">
                  <div class="advanced-row mb" formArrayName="dns">
                    <label [for]="'dns-' + i" class="wifi-label">DNS*:</label>
                    <input
                      *ngFor="let dns of getDns(i)?.controls; let i = index"
                      type="number"
                      min="0"
                      max="999"
                      class="cp-input wifi-no"
                      [formControlName]="i"
                    />
                  </div>

                  <div class="advanced-row mb" formArrayName="ip">
                    <label [for]="'ip-' + i" class="wifi-label">IP*:</label>
                    <input
                      *ngFor="let dns of getIp(i)?.controls; let i = index"
                      type="number"
                      class="cp-input wifi-no"
                      [formControlName]="i"
                    />
                  </div>

                  <div class="advanced-row mb" formArrayName="subnet">
                    <label [for]="'subnet-' + i" class="wifi-label"
                      >Subnet*:</label
                    >
                    <input
                      *ngFor="let dns of getSubnet(i)?.controls; let i = index"
                      type="number"
                      class="cp-input wifi-no"
                      [formControlName]="i"
                    />
                  </div>

                  <div class="advanced-row mb" formArrayName="gateway">
                    <label [for]="'gateway-' + i" class="wifi-label"
                      >Gateway*:</label
                    >
                    <input
                      *ngFor="let dns of getGateway(i)?.controls; let i = index"
                      type="number"
                      class="cp-input wifi-no"
                      [formControlName]="i"
                    />
                  </div>
                </div>

                <!-- <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [id]="'advance-' + i"
                    formControlName="advanced"
                    (change)="onAdvanceChange($event.target.checked, i)"
                  />
                  <label
                    class="custom-control-label cp-cursor-pointer advance-label"
                    [for]="'advance-' + i"
                    >Advanced Options</label
                  >
                </div> -->

                <mat-slide-toggle
                  [id]="'advance-' + i"
                  formControlName="advanced"
                  (change)="onAdvanceChange($event.checked, i)"
                  [checked]="false"
                  >Advanced options</mat-slide-toggle
                >
              </ng-container>

              <hr />
            </div>
          </div>
        </div>
      </div>

      <div class="iris-error mb">
        <span class="font-weight-bold mr-2">Note:</span>
        <span>Only 2.4 GHz band is supported</span>
      </div>

      <mat-dialog-actions align="end">
        <div *ngIf="serverError" class="iris-error mr">
          {{ serverError }}
        </div>

        <button
          mat-flat-button
          type="submit"
          class="button-primary"
          [disabled]="formLoading || !form.valid"
        >
          Submit
          <app-loader [buttonLoader]="true" *ngIf="formLoading"></app-loader>
        </button>

        <button
          type="button"
          class="button-secondary"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </form>
  </div>

  <div class="loading_parent" *ngIf="isLoading">
    <app-loader [buttonLoader]="true"></app-loader>
  </div>
</section>
