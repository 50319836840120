<div class="note-main__right-sec">
  <!-- <app-aira-btn class="note-main__right-sec--item"> </app-aira-btn> -->
  <div class="note-main__right-sec--item">
    <!-- <button mat-flat-button class="button-secondary">Disclaimer</button> -->
  </div>
  <div class="note-main__right-sec--item">
    <!-- <button mat-flat-button class="button-secondary">New Note</button> -->
  </div>
  <div title="Press Ctrl+Alt to turn on mic" class="note-main__right-sec--item">
    <!-- <button mat-flat-button>
      <img
        src="../../../../assets/icons/icons8-google-meet.svg"
        alt="mic"
      />swaᴙa
    </button> -->
    <!-- </div> -->
  </div>
  <!-- <div class="note-main--hyper-text">
      <div *ngIf="hyperText.show" id="hyperText" class="col-sm-12">
        {{ hyperText.val }}
      </div>
    </div> -->
  <!-- <div *ngIf="editor && cursorIndex" class="note-main__branding">
      swaᴙa<mat-divider vertical></mat-divider>Powered by Augnito.
    </div> -->
  <div class="cp-w-100" [formGroup]="patientNotesForm">
    <div class="note-edit-addendum add">
      <ng-container
        *ngFor="let componentControl of componentsFormArray; let i = index"
      >
        <div [innerHtml]="componentControl.value.value"></div>
      </ng-container>
      <div *ngIf="getMergeNotes?.length" class="note-edit-addendum__section">
        <ng-container *ngFor="let note of getMergeNotes">
          <mat-divider></mat-divider>
          <div class="note-edit-addendum__section__heading">
            <div>{{ note.name }}</div>
          </div>
          <div [innerHtml]="note.note"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="note-edit">
    <div class="editor__heading">
      <div>Physician note</div>
    </div>
    <div class="editor">
      <div id="quillAddendum" class="editor__quill">
        <quill-editor
          [formControl]="attestationStatusMsg"
          [modules]="quillConfig"
        ></quill-editor>
      </div>
    </div>
  </div>
  <cp-note-action
    [patientNotesForm]="patientNotesForm"
    [attestationStatusMsg]="attestationStatusMsg"
  ></cp-note-action>
</div>
