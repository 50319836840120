import { Pipe, PipeTransform } from '@angular/core';
import { Vitals } from '../models/Vital';

@Pipe({
  name: 'vitalDay',
  pure: true,
})
export class VitalDayPipe implements PipeTransform {
  transform(vitals: Vitals[], dayNum: number): Vitals[] {
    let days = [];

    /** If day is falsy, then return latest 6 values (this is used in vitals batch upload) */
    if (!dayNum) {
      return vitals.slice(0, 6);
    }

    days = vitals.filter((vital) => +vital.dayNum === +dayNum);

    return days;
  }
}
