import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Observable, Subject } from "rxjs-compat";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.scss"],
})
export class PopoverComponent implements OnInit {
  @Input() content: string | TemplateRef<any>;
  @Input() position = "";
  @Input() title = "";
  @Input() showCloseButton: boolean = false;

  contentType: "template" | "string";

  constructor() {}

  ngOnInit(): void {
    if (typeof this.content === "string") {
      this.contentType = "string";
    } else if (this.content instanceof TemplateRef) {
      this.contentType = "template";
    }
  }

  get positionClass(): String {
    if (!this.position) return null;
    return this.position?.split("-").join(" ");
  }

  private toolTipClose: Subject<any> = new Subject();

  onToolTipClose(): Observable<any> {
    return this.toolTipClose.asObservable();
  }

  closeToolTip() {
    this.toolTipClose.next();
    this.toolTipClose.complete();
  }
}
