import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { TvUnitViewService } from "src/app/services/tv-unit-view.service";
import * as fromTvViewActions from "../actions/tv-unit-view";

@Injectable()
export class UnitTVViewEffects {
  constructor(
    private actions$: Actions,
    private _tvUnitViewService: TvUnitViewService
  ) {}

  getUnitTvInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTvViewActions.getUnitTvInfoAction),
      switchMap((data) =>
        this._tvUnitViewService.getTVUnit(data).pipe(
          switchMap((data) => {
            // switchmap since it returns the result out of the observable
            return of(fromTvViewActions.getUnitTvInfoSuccessAction({ data }));
          }),
          catchError((err) => {
            return of(
              fromTvViewActions.getUnitTvInfoFailureAction({
                error: err?.error?.message,
              })
            );
          })
        )
      )
    )
  );
}
