export const countryList = [
  {
    value: "+93",
    data: {
      countryName: "Afghanistan (افغانستان)",
      isoCountryCode: "AF",
    },
  },
  {
    value: "+355",
    data: {
      countryName: "Albania (Shqipëri)",
      isoCountryCode: "AL",
    },
  },
  {
    value: "+213",
    data: {
      countryName: "Algeria (الجزائر)",
      isoCountryCode: "DZ",
    },
  },
  {
    value: "+1684",
    data: {
      countryName: "American Samoa",
      isoCountryCode: "AS",
    },
  },
  {
    value: "+376",
    data: {
      countryName: "Andorra",
      isoCountryCode: "AD",
    },
  },
  {
    value: "+244",
    data: {
      countryName: "Angola",
      isoCountryCode: "AO",
    },
  },
  {
    value: "+1264",
    data: {
      countryName: "Anguilla",
      isoCountryCode: "AI",
    },
  },
  {
    value: "+1268",
    data: {
      countryName: "Antigua and Barbuda",
      isoCountryCode: "AG",
    },
  },
  {
    value: "+54",
    data: {
      countryName: "Argentina",
      isoCountryCode: "AR",
    },
  },
  {
    value: "+374",
    data: {
      countryName: "Armenia (Հայաստան)",
      isoCountryCode: "AM",
    },
  },
  {
    value: "+297",
    data: {
      countryName: "Aruba",
      isoCountryCode: "AW",
    },
  },
  {
    value: "+61",
    data: {
      countryName: "Australia",
      isoCountryCode: "AU",
    },
  },
  {
    value: "+43",
    data: {
      countryName: "Austria (Österreich)",
      isoCountryCode: "AT",
    },
  },
  {
    value: "+994",
    data: {
      countryName: "Azerbaijan (Azərbaycan)",
      isoCountryCode: "AZ",
    },
  },
  {
    value: "+1242",
    data: {
      countryName: "Bahamas",
      isoCountryCode: "BS",
    },
  },
  {
    value: "+973",
    data: {
      countryName: "Bahrain (البحرين)",
      isoCountryCode: "BH",
    },
  },
  {
    value: "+880",
    data: {
      countryName: "Bangladesh (বাংলাদেশ)",
      isoCountryCode: "BD",
    },
  },
  {
    value: "+1246",
    data: {
      countryName: "Barbados",
      isoCountryCode: "BB",
    },
  },
  {
    value: "+375",
    data: {
      countryName: "Belarus (Беларусь)",
      isoCountryCode: "BY",
    },
  },
  {
    value: "+32",
    data: {
      countryName: "Belgium (België)",
      isoCountryCode: "BE",
    },
  },
  {
    value: "+501",
    data: {
      countryName: "Belize",
      isoCountryCode: "BZ",
    },
  },
  {
    value: "+229",
    data: {
      countryName: "Benin (Bénin)",
      isoCountryCode: "BJ",
    },
  },
  {
    value: "+1441",
    data: {
      countryName: "Bermuda",
      isoCountryCode: "BM",
    },
  },
  {
    value: "+975",
    data: {
      countryName: "Bhutan (འབྲུག)",
      isoCountryCode: "BT",
    },
  },
  {
    value: "+591",
    data: {
      countryName: "Bolivia",
      isoCountryCode: "BO",
    },
  },
  {
    value: "+387",
    data: {
      countryName: "Bosnia and Herzegovina (Босна и Херцеговина)",
      isoCountryCode: "BA",
    },
  },
  {
    value: "+267",
    data: {
      countryName: "Botswana",
      isoCountryCode: "BW",
    },
  },
  {
    value: "+55",
    data: {
      countryName: "Brazil (Brasil)",
      isoCountryCode: "BR",
    },
  },
  {
    value: "+246",
    data: {
      countryName: "British Indian Ocean Territory",
      isoCountryCode: "IO",
    },
  },
  {
    value: "+1284",
    data: {
      countryName: "British Virgin Islands",
      isoCountryCode: "VG",
    },
  },
  {
    value: "+673",
    data: {
      countryName: "Brunei",
      isoCountryCode: "BN",
    },
  },
  {
    value: "+359",
    data: {
      countryName: "Bulgaria (България)",
      isoCountryCode: "BG",
    },
  },
  {
    value: "+226",
    data: {
      countryName: "Burkina Faso",
      isoCountryCode: "BF",
    },
  },
  {
    value: "+257",
    data: {
      countryName: "Burundi (Uburundi)",
      isoCountryCode: "BI",
    },
  },
  {
    value: "+855",
    data: {
      countryName: "Cambodia (កម្ពុជា)",
      isoCountryCode: "KH",
    },
  },
  {
    value: "+237",
    data: {
      countryName: "Cameroon (Cameroun)",
      isoCountryCode: "CM",
    },
  },
  {
    value: "+1",
    data: {
      countryName: "Canada",
      isoCountryCode: "CA",
    },
  },
  {
    value: "+238",
    data: {
      countryName: "Cape Verde (Kabu Verdi)",
      isoCountryCode: "CV",
    },
  },
  {
    value: "+599",
    data: {
      countryName: "Caribbean Netherlands",
      isoCountryCode: "BQ",
    },
  },
  {
    value: "+1345",
    data: {
      countryName: "Cayman Islands",
      isoCountryCode: "KY",
    },
  },
  {
    value: "+236",
    data: {
      countryName: "Central African Republic (République centrafricaine)",
      isoCountryCode: "CF",
    },
  },
  {
    value: "+235",
    data: {
      countryName: "Chad (Tchad)",
      isoCountryCode: "TD",
    },
  },
  {
    value: "+56",
    data: {
      countryName: "Chile",
      isoCountryCode: "CL",
    },
  },
  {
    value: "+86",
    data: {
      countryName: "China (中国)",
      isoCountryCode: "CN",
    },
  },
  {
    value: "+61",
    data: {
      countryName: "Christmas Island",
      isoCountryCode: "CX",
    },
  },
  {
    value: "+61",
    data: {
      countryName: "Cocos (Keeling) Islands",
      isoCountryCode: "CC",
    },
  },
  {
    value: "+57",
    data: {
      countryName: "Colombia",
      isoCountryCode: "CO",
    },
  },
  {
    value: "+269",
    data: {
      countryName: "Comoros (جزر القمر)",
      isoCountryCode: "KM",
    },
  },
  {
    value: "+243",
    data: {
      countryName: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
      isoCountryCode: "CD",
    },
  },
  {
    value: "+242",
    data: {
      countryName: "Congo (Republic) (Congo-Brazzaville)",
      isoCountryCode: "CG",
    },
  },
  {
    value: "+682",
    data: {
      countryName: "Cook Islands",
      isoCountryCode: "CK",
    },
  },
  {
    value: "+506",
    data: {
      countryName: "Costa Rica",
      isoCountryCode: "CR",
    },
  },
  {
    value: "+225",
    data: {
      countryName: "Côte d’Ivoire",
      isoCountryCode: "CI",
    },
  },
  {
    value: "+385",
    data: {
      countryName: "Croatia (Hrvatska)",
      isoCountryCode: "HR",
    },
  },
  {
    value: "+53",
    data: {
      countryName: "Cuba",
      isoCountryCode: "CU",
    },
  },
  {
    value: "+599",
    data: {
      countryName: "Curaçao",
      isoCountryCode: "CW",
    },
  },
  {
    value: "+357",
    data: {
      countryName: "Cyprus (Κύπρος)",
      isoCountryCode: "CY",
    },
  },
  {
    value: "+420",
    data: {
      countryName: "Czech Republic (Česká republika)",
      isoCountryCode: "CZ",
    },
  },
  {
    value: "+45",
    data: {
      countryName: "Denmark (Danmark)",
      isoCountryCode: "DK",
    },
  },
  {
    value: "+253",
    data: {
      countryName: "Djibouti",
      isoCountryCode: "DJ",
    },
  },
  {
    value: "+1767",
    data: {
      countryName: "Dominica",
      isoCountryCode: "DM",
    },
  },
  {
    value: "+1",
    data: {
      countryName: "Dominican Republic (República Dominicana)",
      isoCountryCode: "DO",
    },
  },
  {
    value: "+593",
    data: {
      countryName: "Ecuador",
      isoCountryCode: "EC",
    },
  },
  {
    value: "+20",
    data: {
      countryName: "Egypt (مصر)",
      isoCountryCode: "EG",
    },
  },
  {
    value: "+503",
    data: {
      countryName: "El Salvador",
      isoCountryCode: "SV",
    },
  },
  {
    value: "+240",
    data: {
      countryName: "Equatorial Guinea (Guinea Ecuatorial)",
      isoCountryCode: "GQ",
    },
  },
  {
    value: "+291",
    data: {
      countryName: "Eritrea",
      isoCountryCode: "ER",
    },
  },
  {
    value: "+372",
    data: {
      countryName: "Estonia (Eesti)",
      isoCountryCode: "EE",
    },
  },
  {
    value: "+251",
    data: {
      countryName: "Ethiopia",
      isoCountryCode: "ET",
    },
  },
  {
    value: "+500",
    data: {
      countryName: "Falkland Islands (Islas Malvinas)",
      isoCountryCode: "FK",
    },
  },
  {
    value: "+298",
    data: {
      countryName: "Faroe Islands (Føroyar)",
      isoCountryCode: "FO",
    },
  },
  {
    value: "+679",
    data: {
      countryName: "Fiji",
      isoCountryCode: "FJ",
    },
  },
  {
    value: "+358",
    data: {
      countryName: "Finland (Suomi)",
      isoCountryCode: "FI",
    },
  },
  {
    value: "+33",
    data: {
      countryName: "France",
      isoCountryCode: "FR",
    },
  },
  {
    value: "+594",
    data: {
      countryName: "French Guiana (Guyane française)",
      isoCountryCode: "GF",
    },
  },
  {
    value: "+689",
    data: {
      countryName: "French Polynesia (Polynésie française)",
      isoCountryCode: "PF",
    },
  },
  {
    value: "+241",
    data: {
      countryName: "Gabon",
      isoCountryCode: "GA",
    },
  },
  {
    value: "+220",
    data: {
      countryName: "Gambia",
      isoCountryCode: "GM",
    },
  },
  {
    value: "+995",
    data: {
      countryName: "Georgia (საქართველო)",
      isoCountryCode: "GE",
    },
  },
  {
    value: "+49",
    data: {
      countryName: "Germany (Deutschland)",
      isoCountryCode: "DE",
    },
  },
  {
    value: "+233",
    data: {
      countryName: "Ghana (Gaana)",
      isoCountryCode: "GH",
    },
  },
  {
    value: "+350",
    data: {
      countryName: "Gibraltar",
      isoCountryCode: "GI",
    },
  },
  {
    value: "+30",
    data: {
      countryName: "Greece (Ελλάδα)",
      isoCountryCode: "GR",
    },
  },
  {
    value: "+299",
    data: {
      countryName: "Greenland (Kalaallit Nunaat)",
      isoCountryCode: "GL",
    },
  },
  {
    value: "+1473",
    data: {
      countryName: "Grenada",
      isoCountryCode: "GD",
    },
  },
  {
    value: "+590",
    data: {
      countryName: "Guadeloupe",
      isoCountryCode: "GP",
    },
  },
  {
    value: "+1671",
    data: {
      countryName: "Guam",
      isoCountryCode: "GU",
    },
  },
  {
    value: "+502",
    data: {
      countryName: "Guatemala",
      isoCountryCode: "GT",
    },
  },
  {
    value: "+44",
    data: {
      countryName: "Guernsey",
      isoCountryCode: "GG",
    },
  },
  {
    value: "+224",
    data: {
      countryName: "Guinea (Guinée)",
      isoCountryCode: "GN",
    },
  },
  {
    value: "+245",
    data: {
      countryName: "Guinea-Bissau (Guiné Bissau)",
      isoCountryCode: "GW",
    },
  },
  {
    value: "+592",
    data: {
      countryName: "Guyana",
      isoCountryCode: "GY",
    },
  },
  {
    value: "+509",
    data: {
      countryName: "Haiti",
      isoCountryCode: "HT",
    },
  },
  {
    value: "+504",
    data: {
      countryName: "Honduras",
      isoCountryCode: "HN",
    },
  },
  {
    value: "+852",
    data: {
      countryName: "Hong Kong (香港)",
      isoCountryCode: "HK",
    },
  },
  {
    value: "+36",
    data: {
      countryName: "Hungary (Magyarország)",
      isoCountryCode: "HU",
    },
  },
  {
    value: "+354",
    data: {
      countryName: "Iceland (Ísland)",
      isoCountryCode: "IS",
    },
  },
  {
    value: "+91",
    data: {
      countryName: "India (भारत)",
      isoCountryCode: "IN",
    },
  },
  {
    value: "+62",
    data: {
      countryName: "Indonesia",
      isoCountryCode: "ID",
    },
  },
  {
    value: "+98",
    data: {
      countryName: "Iran (ایران)",
      isoCountryCode: "IR",
    },
  },
  {
    value: "+964",
    data: {
      countryName: "Iraq (العراق)",
      isoCountryCode: "IQ",
    },
  },
  {
    value: "+353",
    data: {
      countryName: "Ireland",
      isoCountryCode: "IE",
    },
  },
  {
    value: "+44",
    data: {
      countryName: "Isle of Man",
      isoCountryCode: "IM",
    },
  },
  {
    value: "+972",
    data: {
      countryName: "Israel (ישראל)",
      isoCountryCode: "IL",
    },
  },
  {
    value: "+39",
    data: {
      countryName: "Italy (Italia)",
      isoCountryCode: "IT",
    },
  },
  {
    value: "+1",
    data: {
      countryName: "Jamaica",
      isoCountryCode: "JM",
    },
  },
  {
    value: "+81",
    data: {
      countryName: "Japan (日本)",
      isoCountryCode: "JP",
    },
  },
  {
    value: "+44",
    data: {
      countryName: "Jersey",
      isoCountryCode: "JE",
    },
  },
  {
    value: "+962",
    data: {
      countryName: "Jordan (الأردن)",
      isoCountryCode: "JO",
    },
  },
  {
    value: "+7",
    data: {
      countryName: "Kazakhstan (Казахстан)",
      isoCountryCode: "KZ",
    },
  },
  {
    value: "+254",
    data: {
      countryName: "Kenya",
      isoCountryCode: "KE",
    },
  },
  {
    value: "+686",
    data: {
      countryName: "Kiribati",
      isoCountryCode: "KI",
    },
  },
  {
    value: "+383",
    data: {
      countryName: "Kosovo",
      isoCountryCode: "XK",
    },
  },
  {
    value: "+965",
    data: {
      countryName: "Kuwait (الكويت)",
      isoCountryCode: "KW",
    },
  },
  {
    value: "+996",
    data: {
      countryName: "Kyrgyzstan (Кыргызстан)",
      isoCountryCode: "KG",
    },
  },
  {
    value: "+856",
    data: {
      countryName: "Laos (ລາວ)",
      isoCountryCode: "LA",
    },
  },
  {
    value: "+371",
    data: {
      countryName: "Latvia (Latvija)",
      isoCountryCode: "LV",
    },
  },
  {
    value: "+961",
    data: {
      countryName: "Lebanon (لبنان)",
      isoCountryCode: "LB",
    },
  },
  {
    value: "+266",
    data: {
      countryName: "Lesotho",
      isoCountryCode: "LS",
    },
  },
  {
    value: "+231",
    data: {
      countryName: "Liberia",
      isoCountryCode: "LR",
    },
  },
  {
    value: "+218",
    data: {
      countryName: "Libya (ليبيا)",
      isoCountryCode: "LY",
    },
  },
  {
    value: "+423",
    data: {
      countryName: "Liechtenstein",
      isoCountryCode: "LI",
    },
  },
  {
    value: "+370",
    data: {
      countryName: "Lithuania (Lietuva)",
      isoCountryCode: "LT",
    },
  },
  {
    value: "+352",
    data: {
      countryName: "Luxembourg",
      isoCountryCode: "LU",
    },
  },
  {
    value: "+853",
    data: {
      countryName: "Macau (澳門)",
      isoCountryCode: "MO",
    },
  },
  {
    value: "+389",
    data: {
      countryName: "Macedonia (FYROM) (Македонија)",
      isoCountryCode: "MK",
    },
  },
  {
    value: "+261",
    data: {
      countryName: "Madagascar (Madagasikara)",
      isoCountryCode: "MG",
    },
  },
  {
    value: "+265",
    data: {
      countryName: "Malawi",
      isoCountryCode: "MW",
    },
  },
  {
    value: "+60",
    data: {
      countryName: "Malaysia",
      isoCountryCode: "MY",
    },
  },
  {
    value: "+960",
    data: {
      countryName: "Maldives",
      isoCountryCode: "MV",
    },
  },
  {
    value: "+223",
    data: {
      countryName: "Mali",
      isoCountryCode: "ML",
    },
  },
  {
    value: "+356",
    data: {
      countryName: "Malta",
      isoCountryCode: "MT",
    },
  },
  {
    value: "+692",
    data: {
      countryName: "Marshall Islands",
      isoCountryCode: "MH",
    },
  },
  {
    value: "+596",
    data: {
      countryName: "Martinique",
      isoCountryCode: "MQ",
    },
  },
  {
    value: "+222",
    data: {
      countryName: "Mauritania (موريتانيا)",
      isoCountryCode: "MR",
    },
  },
  {
    value: "+230",
    data: {
      countryName: "Mauritius (Moris)",
      isoCountryCode: "MU",
    },
  },
  {
    value: "+262",
    data: {
      countryName: "Mayotte",
      isoCountryCode: "YT",
    },
  },
  {
    value: "+52",
    data: {
      countryName: "Mexico (México)",
      isoCountryCode: "MX",
    },
  },
  {
    value: "+691",
    data: {
      countryName: "Micronesia",
      isoCountryCode: "FM",
    },
  },
  {
    value: "+373",
    data: {
      countryName: "Moldova (Republica Moldova)",
      isoCountryCode: "MD",
    },
  },
  {
    value: "+377",
    data: {
      countryName: "Monaco",
      isoCountryCode: "MC",
    },
  },
  {
    value: "+976",
    data: {
      countryName: "Mongolia (Монгол)",
      isoCountryCode: "MN",
    },
  },
  {
    value: "+382",
    data: {
      countryName: "Montenegro (Crna Gora)",
      isoCountryCode: "ME",
    },
  },
  {
    value: "+1664",
    data: {
      countryName: "Montserrat",
      isoCountryCode: "MS",
    },
  },
  {
    value: "+212",
    data: {
      countryName: "Morocco (المغرب)",
      isoCountryCode: "MA",
    },
  },
  {
    value: "+258",
    data: {
      countryName: "Mozambique (Moçambique)",
      isoCountryCode: "MZ",
    },
  },
  {
    value: "+95",
    data: {
      countryName: "Myanmar (Burma) (မြန်မာ)",
      isoCountryCode: "MM",
    },
  },
  {
    value: "+264",
    data: {
      countryName: "Namibia (Namibië)",
      isoCountryCode: "NA",
    },
  },
  {
    value: "+674",
    data: {
      countryName: "Nauru",
      isoCountryCode: "NR",
    },
  },
  {
    value: "+977",
    data: {
      countryName: "Nepal (नेपाल)",
      isoCountryCode: "NP",
    },
  },
  {
    value: "+31",
    data: {
      countryName: "Netherlands (Nederland)",
      isoCountryCode: "NL",
    },
  },
  {
    value: "+687",
    data: {
      countryName: "New Caledonia (Nouvelle-Calédonie)",
      isoCountryCode: "NC",
    },
  },
  {
    value: "+64",
    data: {
      countryName: "New Zealand",
      isoCountryCode: "NZ",
    },
  },
  {
    value: "+505",
    data: {
      countryName: "Nicaragua",
      isoCountryCode: "NI",
    },
  },
  {
    value: "+227",
    data: {
      countryName: "Niger (Nijar)",
      isoCountryCode: "NE",
    },
  },
  {
    value: "+234",
    data: {
      countryName: "Nigeria",
      isoCountryCode: "NG",
    },
  },
  {
    value: "+683",
    data: {
      countryName: "Niue",
      isoCountryCode: "NU",
    },
  },
  {
    value: "+672",
    data: {
      countryName: "Norfolk Island",
      isoCountryCode: "NF",
    },
  },
  {
    value: "+850",
    data: {
      countryName: "North Korea (조선 민주주의 인민 공화국)",
      isoCountryCode: "KP",
    },
  },
  {
    value: "+1670",
    data: {
      countryName: "Northern Mariana Islands",
      isoCountryCode: "MP",
    },
  },
  {
    value: "+47",
    data: {
      countryName: "Norway (Norge)",
      isoCountryCode: "NO",
    },
  },
  {
    value: "+968",
    data: {
      countryName: "Oman (عُمان)",
      isoCountryCode: "OM",
    },
  },
  {
    value: "+92",
    data: {
      countryName: "Pakistan (پاکستان)",
      isoCountryCode: "PK",
    },
  },
  {
    value: "+680",
    data: {
      countryName: "Palau",
      isoCountryCode: "PW",
    },
  },
  {
    value: "+970",
    data: {
      countryName: "Palestine (فلسطين)",
      isoCountryCode: "PS",
    },
  },
  {
    value: "+507",
    data: {
      countryName: "Panama (Panamá)",
      isoCountryCode: "PA",
    },
  },
  {
    value: "+675",
    data: {
      countryName: "Papua New Guinea",
      isoCountryCode: "PG",
    },
  },
  {
    value: "+595",
    data: {
      countryName: "Paraguay",
      isoCountryCode: "PY",
    },
  },
  {
    value: "+51",
    data: {
      countryName: "Peru (Perú)",
      isoCountryCode: "PE",
    },
  },
  {
    value: "+63",
    data: {
      countryName: "Philippines",
      isoCountryCode: "PH",
    },
  },
  {
    value: "+48",
    data: {
      countryName: "Poland (Polska)",
      isoCountryCode: "PL",
    },
  },
  {
    value: "+351",
    data: {
      countryName: "Portugal",
      isoCountryCode: "PT",
    },
  },
  {
    value: "+1",
    data: {
      countryName: "Puerto Rico",
      isoCountryCode: "PR",
    },
  },
  {
    value: "+974",
    data: {
      countryName: "Qatar (قطر)",
      isoCountryCode: "QA",
    },
  },
  {
    value: "+262",
    data: {
      countryName: "Réunion (La Réunion)",
      isoCountryCode: "RE",
    },
  },
  {
    value: "+40",
    data: {
      countryName: "Romania (România)",
      isoCountryCode: "RO",
    },
  },
  {
    value: "+7",
    data: {
      countryName: "Russia (Россия)",
      isoCountryCode: "RU",
    },
  },
  {
    value: "+250",
    data: {
      countryName: "Rwanda",
      isoCountryCode: "RW",
    },
  },
  {
    value: "+590",
    data: {
      countryName: "Saint Barthélemy",
      isoCountryCode: "BL",
    },
  },
  {
    value: "+290",
    data: {
      countryName: "Saint Helena",
      isoCountryCode: "SH",
    },
  },
  {
    value: "+1869",
    data: {
      countryName: "Saint Kitts and Nevis",
      isoCountryCode: "KN",
    },
  },
  {
    value: "+1758",
    data: {
      countryName: "Saint Lucia",
      isoCountryCode: "LC",
    },
  },
  {
    value: "+508",
    data: {
      countryName: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
      isoCountryCode: "PM",
    },
  },
  {
    value: "+1784",
    data: {
      countryName: "Saint Vincent and the Grenadines",
      isoCountryCode: "VC",
    },
  },
  {
    value: "+685",
    data: {
      countryName: "Samoa",
      isoCountryCode: "WS",
    },
  },
  {
    value: "+378",
    data: {
      countryName: "San Marino",
      isoCountryCode: "SM",
    },
  },
  {
    value: "+239",
    data: {
      countryName: "São Tomé and Príncipe (São Tomé e Príncipe)",
      isoCountryCode: "ST",
    },
  },
  {
    value: "+966",
    data: {
      countryName: "Saudi Arabia (المملكة العربية السعودية)",
      isoCountryCode: "SA",
    },
  },
  {
    value: "+221",
    data: {
      countryName: "Senegal (Sénégal)",
      isoCountryCode: "SN",
    },
  },
  {
    value: "+381",
    data: {
      countryName: "Serbia (Србија)",
      isoCountryCode: "RS",
    },
  },
  {
    value: "+248",
    data: {
      countryName: "Seychelles",
      isoCountryCode: "SC",
    },
  },
  {
    value: "+232",
    data: {
      countryName: "Sierra Leone",
      isoCountryCode: "SL",
    },
  },
  {
    value: "+65",
    data: {
      countryName: "Singapore",
      isoCountryCode: "SG",
    },
  },
  {
    value: "+1721",
    data: {
      countryName: "Sint Maarten",
      isoCountryCode: "SX",
    },
  },
  {
    value: "+421",
    data: {
      countryName: "Slovakia (Slovensko)",
      isoCountryCode: "SK",
    },
  },
  {
    value: "+386",
    data: {
      countryName: "Slovenia (Slovenija)",
      isoCountryCode: "SI",
    },
  },
  {
    value: "+677",
    data: {
      countryName: "Solomon Islands",
      isoCountryCode: "SB",
    },
  },
  {
    value: "+252",
    data: {
      countryName: "Somalia (Soomaaliya)",
      isoCountryCode: "SO",
    },
  },
  {
    value: "+27",
    data: {
      countryName: "South Africa",
      isoCountryCode: "ZA",
    },
  },
  {
    value: "+82",
    data: {
      countryName: "South Korea (대한민국)",
      isoCountryCode: "KR",
    },
  },
  {
    value: "+211",
    data: {
      countryName: "South Sudan (جنوب السودان)",
      isoCountryCode: "SS",
    },
  },
  {
    value: "+34",
    data: {
      countryName: "Spain (España)",
      isoCountryCode: "ES",
    },
  },
  {
    value: "+94",
    data: {
      countryName: "Sri Lanka (ශ්‍රී ලංකාව)",
      isoCountryCode: "LK",
    },
  },
  {
    value: "+249",
    data: {
      countryName: "Sudan (السودان)",
      isoCountryCode: "SD",
    },
  },
  {
    value: "+597",
    data: {
      countryName: "Suriname",
      isoCountryCode: "SR",
    },
  },
  {
    value: "+47",
    data: {
      countryName: "Svalbard and Jan Mayen",
      isoCountryCode: "SJ",
    },
  },
  {
    value: "+268",
    data: {
      countryName: "Swaziland",
      isoCountryCode: "SZ",
    },
  },
  {
    value: "+46",
    data: {
      countryName: "Sweden (Sverige)",
      isoCountryCode: "SE",
    },
  },
  {
    value: "+41",
    data: {
      countryName: "Switzerland (Schweiz)",
      isoCountryCode: "CH",
    },
  },
  {
    value: "+963",
    data: {
      countryName: "Syria (سوريا)",
      isoCountryCode: "SY",
    },
  },
  {
    value: "+886",
    data: {
      countryName: "Taiwan (台灣)",
      isoCountryCode: "TW",
    },
  },
  {
    value: "+992",
    data: {
      countryName: "Tajikistan",
      isoCountryCode: "TJ",
    },
  },
  {
    value: "+255",
    data: {
      countryName: "Tanzania",
      isoCountryCode: "TZ",
    },
  },
  {
    value: "+66",
    data: {
      countryName: "Thailand (ไทย)",
      isoCountryCode: "TH",
    },
  },
  {
    value: "+670",
    data: {
      countryName: "Timor-Leste",
      isoCountryCode: "TL",
    },
  },
  {
    value: "+228",
    data: {
      countryName: "Togo",
      isoCountryCode: "TG",
    },
  },
  {
    value: "+690",
    data: {
      countryName: "Tokelau",
      isoCountryCode: "TK",
    },
  },
  {
    value: "+676",
    data: {
      countryName: "Tonga",
      isoCountryCode: "TO",
    },
  },
  {
    value: "+1868",
    data: {
      countryName: "Trinidad and Tobago",
      isoCountryCode: "TT",
    },
  },
  {
    value: "+216",
    data: {
      countryName: "Tunisia (تونس)",
      isoCountryCode: "TN",
    },
  },
  {
    value: "+90",
    data: {
      countryName: "Turkey (Türkiye)",
      isoCountryCode: "TR",
    },
  },
  {
    value: "+993",
    data: {
      countryName: "Turkmenistan",
      isoCountryCode: "TM",
    },
  },
  {
    value: "+1649",
    data: {
      countryName: "Turks and Caicos Islands",
      isoCountryCode: "TC",
    },
  },
  {
    value: "+688",
    data: {
      countryName: "Tuvalu",
      isoCountryCode: "TV",
    },
  },
  {
    value: "+1340",
    data: {
      countryName: "U.S. Virgin Islands",
      isoCountryCode: "VI",
    },
  },
  {
    value: "+256",
    data: {
      countryName: "Uganda",
      isoCountryCode: "UG",
    },
  },
  {
    value: "+380",
    data: {
      countryName: "Ukraine (Україна)",
      isoCountryCode: "UA",
    },
  },
  {
    value: "+971",
    data: {
      countryName: "United Arab Emirates (الإمارات العربية المتحدة)",
      isoCountryCode: "AE",
    },
  },
  {
    value: "+44",
    data: {
      countryName: "United Kingdom",
      isoCountryCode: "GB",
    },
  },
  {
    value: "+1",
    data: {
      countryName: "United States",
      isoCountryCode: "US",
    },
  },
  {
    value: "+598",
    data: {
      countryName: "Uruguay",
      isoCountryCode: "UY",
    },
  },
  {
    value: "+998",
    data: {
      countryName: "Uzbekistan (Oʻzbekiston)",
      isoCountryCode: "UZ",
    },
  },
  {
    value: "+678",
    data: {
      countryName: "Vanuatu",
      isoCountryCode: "VU",
    },
  },
  {
    value: "+39",
    data: {
      countryName: "Vatican City (Città del Vaticano)",
      isoCountryCode: "VA",
    },
  },
  {
    value: "+58",
    data: {
      countryName: "Venezuela",
      isoCountryCode: "VE",
    },
  },
  {
    value: "+84",
    data: {
      countryName: "Vietnam (Việt Nam)",
      isoCountryCode: "VN",
    },
  },
  {
    value: "+681",
    data: {
      countryName: "Wallis and Futuna (Wallis-et-Futuna)",
      isoCountryCode: "WF",
    },
  },
  {
    value: "+212",
    data: {
      countryName: "Western Sahara (الصحراء الغربية)",
      isoCountryCode: "EH",
    },
  },
  {
    value: "+967",
    data: {
      countryName: "Yemen (اليمن)",
      isoCountryCode: "YE",
    },
  },
  {
    value: "+260",
    data: {
      countryName: "Zambia",
      isoCountryCode: "ZM",
    },
  },
  {
    value: "+263",
    data: {
      countryName: "Zimbabwe",
      isoCountryCode: "ZW",
    },
  },
  {
    value: "+358",
    data: {
      countryName: "Åland Islands",
      isoCountryCode: "AX",
    },
  },
];

// These are the isocodes of the countries to show at top of the list
export const topCountriesISO = new Set(["BD", "IN"]);
