import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as fromNoteStore from "@notes/store";
import { FinalNotes } from "@notes-view/model/notes-model";

@Component({
  selector: "app-notes-aira-modal",
  templateUrl: "./notes-aira-modal.component.html",
  styleUrls: ["./notes-aira-modal.component.scss"],
})
export class NotesAiraModalComponent {
  constructor(
    private dialogRef: MatDialogRef<NotesAiraModalComponent>,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {}

  @Input() finalNotes: FinalNotes;

  get checkifErrortemplate() {
    return this.finalNotes.components.find(
      (component) => component.displayName == "Error"
    );
  }

  submitUserInfo(type) {
    if (type === "yes") {
      this.setNoteTemplateForms(this.finalNotes);
      this.dialogRef.close();
    } else {
    }
  }

  setNoteTemplateForms(noteContents: FinalNotes) {
    setTimeout(() => {
      this._noteStore.dispatch(
        fromNoteStore.loadNoteContentSuccess({
          noteContents,
        })
      );
    }, 0);
  }
}
