import { Action, createReducer, on } from '@ngrx/store';
import { ListProtocolPageActions, ProtocolApiActions } from '../actions';

export interface State {
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  loaded: false,
  loading: false
};

const protocolViewReducer = createReducer(
  initialState,
  on(
    ListProtocolPageActions.loadProtocols,
    ListProtocolPageActions.searchProtocols,
    (state) => ({ loaded: false, loading: true })
  ),
  on(
    ProtocolApiActions.loadProtocolsSuccess,
    ProtocolApiActions.searchProtocolsSuccess,
    (state, { protocols }) => ({ loaded: true, loading: false })
  ),
  on(
    ProtocolApiActions.loadProtocolsFailure,
    ProtocolApiActions.searchProtocolsFailure,
    (state, { error }) => ({ loaded: false, loading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return protocolViewReducer(state, action);
}

export const getProtocolViewLoaded = (state: State) => state.loaded;

export const getProtocolViewLoading = (state: State) => state.loading;
