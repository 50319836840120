import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dropdownTransform",
})
export class DropdownTransformPipe implements PipeTransform {
  transform(
    dropDownArr: any,
    isNcp?: boolean
  ): { value: string; label: string }[] {
    if (!Array.isArray(dropDownArr)) return [];

    return dropDownArr.map((dropDownValue) => {
      if (!isNcp) {
        const inactiveText = dropDownValue?.isActive ? "" : "(Inactive)";
        return {
          value: dropDownValue?._id,
          label: dropDownValue?.name + inactiveText,
        };
      } else {
        return {
          value: dropDownValue?.name,
          label: dropDownValue?.name,
        };
      }
    });
  }
}
