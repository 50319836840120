import { Cosign, NoteContent } from "@notes/models/notes.model";

export class FinalNotes extends NoteContent {
  isDeleteEnabled?: boolean;
  timestamp?: Date;
  createdTimestamp?: Date;
  lock?: { name: string; uniqueID: string };
  cosign?: Cosign;
  author?: Author;
  authorIsClient?: boolean;
  authorSpeciality?: string;
  registration_id?: string;
  impactCase?: boolean;
  chargeable?: boolean;
}

export interface Author {
  name: String;
  email: String;
  role: String;
  speciality: String;
}

export class DraftNotes extends NoteContent {
  author: Author;
  authorIsClient: boolean;
  timestamp: Date;
  isAddendum: boolean;
  authorSpeciality?: string;
  authorId: string;
  registration_id: string;
  authorType: string;
  impactCase: boolean;
  chargeable: boolean;
}

export class NotesAPIResponse {
  notes: {
    draftNotes: DraftNotes[];
    finalNotes: FinalNotes[];
  };
}

export class FinalNotesAPIResponse {
  notes: {
    finalNotes: FinalNotes;
  };
}

export class DraftNotesAPIResponse {
  notes: {
    draftNotes: DraftNotes;
  };
}
