import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ncpParams } from "../../data/data";

import { NcpService } from "../../service/ncp.service";
import { Store, select } from "@ngrx/store";
import * as fromNcpAction from "../../store/action/ncp.action";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { take, takeUntil } from "rxjs/operators";
import { ChangeDetectionStrategy } from "@angular/compiler";
import * as fromNcpSelector from "../../store/selector/ncp.selector";

@Component({
  selector: "app-ncp-modal",
  templateUrl: "./ncp-modal.component.html",
  styleUrls: ["./ncp-modal.component.scss"],
})
export class NcpModalComponent implements OnInit {
  @ViewChild("otherInput", { static: false }) otherInput: ElementRef;
  private _onDestroy = new Subject<void>();
  public ncpParams; // have params array
  public selectedDiagnose: string[]; // have edit data

  public tempSelectedDiagnose: string[]; // have both edit and newly added diagnose
  public tempSelectedParams: string[];
  public selectedNcp;
  public selectedParams: string[]; // have selected parameters
  private unsubscribe$: Subject<any> = new Subject<any>();
  public diagnosisData;
  public isOther: boolean = false;

  public ncpFetchData$ = this.store.pipe(
    select(fromNcpSelector.getNcpFetchData),
    takeUntil(this._onDestroy)
  );

  constructor(
    public dialogRef: MatDialogRef<NcpModalComponent>,
    public fb: FormBuilder,
    public ncpService: NcpService,
    public store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    //if edit data isthere wil map diadnose to selectedDiagnose array for showing the checkbox as selected and disabled.

    if (data.selectedNcp?.length > 0) {
      this.selectedNcp = data.selectedNcp;
      this.selectedDiagnose = data.selectedNcp.map((obj) => obj.diagnose);

      this.selectedParams = data.selectedNcp.flatMap((obj) =>
        Object.keys(obj.fields)
      );
      //storing params in tempSelectedParams for disabling feature
      this.tempSelectedParams = this.selectedParams;
      const array = data.newlyAddedNcp.map((obj) => obj.diagnose);
      //for showing as selected for both edit and add diagnose
      this.tempSelectedDiagnose = [...this.selectedDiagnose, ...array];
      this.diagnosisName.setValue(this.tempSelectedDiagnose);
    }
    if (data.newlyAddedNcp?.length > 0) {
      //if there is only newly added data then will map diagnose in tempselecteddiagnose array.

      const array = data.newlyAddedNcp.map((obj) => obj.diagnose);
      this.tempSelectedDiagnose = array;
      this.diagnosisName.setValue(this.tempSelectedDiagnose);
      this.selectedParams = data.newlyAddedNcp.flatMap((obj) =>
        Object.keys(obj.fields)
      );
    }
    //mapping parameters in the format of ncpParams

    const capitalizedKeys = this.capitalizedKeyFunc(this.selectedParams);
    // this is for transforming already added(edit) parameters in order to disable it in the matoption
    if (this.tempSelectedParams) {
      const capitalizedKeys = this.capitalizedKeyFunc(this.tempSelectedParams);
      this.tempSelectedParams = capitalizedKeys;
    }

    //autoselected the selected parameters
    this.params.setValue(capitalizedKeys);
  }

  ncpSubmitForm: FormGroup;
  ncpForm: UntypedFormGroup = this.fb.group({
    diagnose: this.fb.group({
      name: ["", [Validators.required]],
      value: [""],
    }),
    parameters: ["", [Validators.required]],
  });

  get diagnosisValue(): AbstractControl {
    return this.ncpForm.get("diagnose.value");
  }

  get diagnosisName(): AbstractControl {
    return this.ncpForm.get("diagnose.name") as FormControl;
  }

  get params(): AbstractControl {
    return this.ncpForm.get("parameters") as FormControl;
  }

  capitalizedKeyFunc(arrayOfKeys) {
    return arrayOfKeys?.map((key) => {
      if (key === "goalsandoutcomes") {
        return "Goals and Outcomes";
      } else {
        return key
          .replace(/([A-Z])/g, " $1")
          .trim()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
    });
  }

  ngOnInit(): void {
    this.preFetchParameterFromRecentNCP();

    //calling api to get diagnoses list @sheethalkhan  08-07-2023
    this.ncpService.getdiagnoses().subscribe((data) => {
      this.diagnosisData = data.data.map((data) => ({ name: data }));
    });
    this.ncpParams = ncpParams.map((item) => ({ name: item }));

    this.diagnosisName.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedItems) => {
        if (selectedItems?.includes("Other")) {
          this.isOther = true;
          setTimeout(() => {
            this.scrollToOtherInput();
          }, 0);
          if (this.diagnosisValue.value != "") {
            const otherValues = this.diagnosisValue.value
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "");
            selectedItems = [...selectedItems, ...otherValues];
          }
        } else {
          this.isOther = false;
          this.diagnosisValue.setValue("");
        }
      });
  }

  /**
   * @description if we are creating new ncp then pre-populate parameters in ncp-modal from recent ncp ( if exists )
   * @author Rajat Saini
   * @date Jan 23, 2024
   */
  preFetchParameterFromRecentNCP() {
    if (this.selectedParams || this.data?.selectedNCP?.length) {
      // if modal is opened by edit-NCP
      return;
    }

    this.ncpFetchData$.subscribe(([data]) => {
      if (!data?.nursingPlan?.length) {
        return;
      }

      const recentNCPFields = this.capitalizedKeyFunc(
        Object.keys(data?.nursingPlan?.pop()?.fields)
      );
      this.params.setValue(recentNCPFields);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onClose() {
    this.dialogRef.close();
  }

  Submit() {
    //setting value of "other" to diagnosis array @sheethalkhan  08-07-2023

    // if (this.isOther) {
    this.ncpForm.value.diagnose.name.forEach((element, index) => {
      if (element === "Other") {
        this.ncpForm.value.diagnose.name.splice(index, 1);
      }
    });
    //}

    //transforming parameters into lowercase without space
    let transformedArray = this.ncpForm.value.parameters.map((item) =>
      item.toLowerCase().replace(/\s/g, "")
    );

    //form which is submitting
    this.ncpSubmitForm = this.fb.group({
      ncpEntries: this.fb.array([]),
    });

    const ncpEntriesArray = this.ncpSubmitForm.get("ncpEntries") as FormArray;

    //both edit and newly data is there...will pass edit data to update api and newly added data to add api
    let selectedNcpEnabledOnly;

    if (this.selectedDiagnose?.length > 0) {
      selectedNcpEnabledOnly = this.ncpForm.value.diagnose.name.filter(
        (selected) => !this.selectedDiagnose.includes(selected)
      );
    } else {
      selectedNcpEnabledOnly = this.ncpForm.value.diagnose.name;
    }

    //this is for adding edit data
    if (this.selectedNcp) {
      this.selectedNcp.forEach((entry) => {
        const entryGroup = this.fb.group({
          _id: [entry?._id],
          createdAt: [entry.createdAt],
          diagnose: [entry.diagnose],
          fields: this.fb.group({}),
        });

        const fieldsGroup = entryGroup.get("fields") as FormGroup;

        let array = entry.fields;
        // getting as key and value and setting key as formcontrol and value as formcontrols value

        const outputArray = Object.entries(array).map(([key, value]) => ({
          [key]: value,
        }));

        outputArray.forEach((params) => {
          let key = Object.keys(params);
          let value = Object.values(params);
          fieldsGroup.addControl(key[0], new FormControl(value[0]));
        });
        transformedArray.forEach((element) => {
          outputArray.forEach((params) => {
            let key = Object.keys(params);

            if (element != key[0]) {
              fieldsGroup.addControl(element, new FormControl(""));
            }
          });
        });
        ncpEntriesArray.push(entryGroup);
      });
    }
    //this is for ading newly added one
    if (selectedNcpEnabledOnly) {
      selectedNcpEnabledOnly.forEach((entry) => {
        const entryGroup = this.fb.group({
          createdAt: "",
          diagnose: [entry],
          fields: this.fb.group({}),
        });

        const fieldsGroup = entryGroup.get("fields") as FormGroup;

        transformedArray.forEach((element) => {
          fieldsGroup.addControl(element, new FormControl(""));
        });

        ncpEntriesArray.push(entryGroup);
      });
    }

    this.store.dispatch(
      fromNcpAction.addInputData({
        data: this.ncpSubmitForm.value.ncpEntries,
        isEdit: true,
      })
    );
    this.dialogRef.close();
  }

  //moving scroll to end
  scrollToOtherInput() {
    if (this.isOther && this.otherInput && this.otherInput.nativeElement) {
      const dropdownPanel = document.querySelector(".mat-select-panel");
      if (dropdownPanel) {
        dropdownPanel.scrollTop = dropdownPanel.scrollHeight;
      }
    }
  }

  onOtherInputChange() {
    setTimeout(() => {
      // Split the input value by comma and trim each value
      const otherValues = this.diagnosisValue.value
        .split(",")
        .filter((value) => value !== ""); // Removing empty values

      if (this.diagnosisValue.value == "") {
        this.diagnosisName.value.forEach((element, index) => {
          if (element == "Other") {
            this.diagnosisName.value.splice(index, 1);
            this.isOther = false;
          }
        });
      }

      // Combine the "Other" values with the existing selected items

      const selectedItems = [...this.diagnosisName.value, ...otherValues];

      this.diagnosisName.setValue(selectedItems);

      this.cdr.detectChanges();
    }, 0);
  }
}
