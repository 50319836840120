import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType, Effect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/iris-components/service/alert.service";
import * as fromNoteTypeActions from "../actions";
import { NoteService } from "@notes/services/note.service";
import * as fromloaderStateReducer from "@shared-modules/store";
import { Store } from "@ngrx/store";
import * as fromNoteStore from "@notes/store";
import { CTAType } from "@notes/models/notes.model";

@Injectable()
export class NoteContentEffects {
  constructor(
    private actions$: Actions,
    private _alertService: AlertService,
    private _noteService: NoteService,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {}

  @Effect()
  noteData$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.loadNoteContents),
    switchMap(({ noteFormContents }) => {
      return this._noteService.getNoteTemplates(noteFormContents).pipe(
        map((data) => {
          this._loaderStore.dispatch(
            fromloaderStateReducer.setLoadingSpinner({ status: false })
          );
          this._noteStore.dispatch(fromNoteStore.setCTA({ cta: CTAType.New }));
          return fromNoteTypeActions.loadNoteContentSuccess({
            noteContents: { ...data, refId: "new" },
          });
        }),
        catchError((error) => {
          this._loaderStore.dispatch(
            fromloaderStateReducer.setLoadingSpinner({ status: false })
          );
          this._alertService.showNotification({
            type: "Error",
            message: error.error.message,
          });
          return of([]);
        })
      );
    })
  );
}
