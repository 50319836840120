import { NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faReply,
  faBan,
  faBed,
  faPen,
  faHome,
  faInfo,
  faLock,
  faPlus,
  faSort,
  faSync,
  faRedo,
  faMinus,
  faPhone,
  faPrint,
  faTimes,
  faTrash,
  faClock,
  faCheck,
  faSlash,
  faVideo,
  faSignal,
  faFilter,
  faSearch,
  faUndoAlt,
  faSpinner,
  faHistory,
  faArrowUp,
  faBackward,
  faUserLock,
  faQuestion,
  faDotCircle,
  faArrowLeft,
  faAngleDown,
  faCaretDown,
  faCaretLeft,
  faPaperclip,
  faPencilAlt,
  faPhoneSlash,
  faCaretRight,
  faWaveSquare,
  faTransgender,
  faUserFriends,
  faFileMedicalAlt,
  faQuestionCircle,
  faInfoCircle,
  faTint as fasTint,
  faBell as fasBell,
  faExternalLinkAlt,
  faChevronCircleDown,
  faFlask as fasFlask,
  faExclamationTriangle,
  faComment as fasComment,
  faComments as fasComments,
  faUtensils as fasUtensils,
  faChevronUp as fasChevronUp,
  faProcedures as fasProcedures,
  faCheckCircle as fasCheckCircle,
  faTimesCircle as fasTimesCircle,
  faChevronDown as fasChevronDown,
  faCommentMedical as fasCommentMedical,
  faPrescriptionBottleAlt as fasPrescriptionBottleAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMeh as farMeh,
  faCopy as farCopy,
  faBell as farBell,
  faEdit as farEdit,
  faUser as farUser,
  faSmile as farSmile,
  faFrown as farFrown,
  faSquare as farSquare,
  faCircle as farCircle,
  faFilePdf as farFilePdf,
  faTrashAlt as farTrashAlt,
  faClipboard as farClipboard,
  faCalendarAlt as farCalendarAlt,
  faCommentDots as farCommentDots,
  faShareSquare as farShareSquare,
  faTimesCircle as farTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faProductHunt as fabProductHunt } from "@fortawesome/free-brands-svg-icons";


const icons = [
  fasTint, fasComments, fasProcedures, fasPrescriptionBottleAlt, fasFlask, fasUtensils, fasChevronDown, fasChevronUp,
  farCalendarAlt, farTrashAlt, fabProductHunt, fasCommentMedical, faQuestionCircle, faPen, faArrowUp, faBan, faTrash, faChevronCircleDown,
  faCaretLeft, faCaretRight, faHome, faPaperclip, faSpinner, faTransgender, faUserFriends, faVideo, farBell, fasBell, faPlus, farEdit,
  farShareSquare, faBed, farFilePdf, faPrint, faCheck, farSmile, farMeh, farFrown, farCommentDots, faSearch, faSort, faExternalLinkAlt,
  farClipboard, faFileMedicalAlt, faPhoneSlash, faPhone, farCircle, faQuestion, faTimes, faPencilAlt, faLock, faMinus, faAngleDown,
  faFilter, faExclamationTriangle, fasCheckCircle, fasTimesCircle, faUserLock, farTimesCircle, faClock, faRedo, faInfo, farCopy, fasComment,
  faReply, faSync, faSignal, faSlash, faDotCircle, faBackward, farUser, faUndoAlt, farSquare, faWaveSquare, faArrowLeft, faInfoCircle,
  faCaretDown, faHistory
];

@NgModule()
export class IconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...icons);
  }
}
