import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ApiResponse } from "src/app/models/api-response.model";
import { environment } from "src/environments/environment";
import {
  AssignedUserInterface,
  AssignedUsersSocketInterface,
  SocketChannels,
} from "../models/assigned-users.model";
import { socket } from "src/app/config/socket";

@Injectable({
  providedIn: "root",
})
export class AssignedUsersService {
  constructor(private _http: HttpClient) {}

  private resetCacheSubject = new Subject<AssignedUsersSocketInterface>();
  public resetCache$ = this.resetCacheSubject.asObservable();

  public totalListeners: number = 0;

  private isSocketInitialized: boolean = false;

  public initializeSockets(commandCenterId: string) {
    this.incrementListeners();
    if (this.isSocketInitialized) return;
    this.isSocketInitialized = true;
    socket.on(
      `${SocketChannels.GET_ASSIGNED_USERS}${commandCenterId}`,
      (data: AssignedUsersSocketInterface) => {
        this.resetCacheSubject.next(data);
      }
    );
  }

  public destroySockets() {
    this.decrementListeners();
    if (this.totalListeners == 0) {
      this.isSocketInitialized = false;
      socket.removeAllListeners(SocketChannels.GET_ASSIGNED_USERS);
    }
  }

  private incrementListeners() {
    this.totalListeners++;
  }

  private decrementListeners() {
    this.totalListeners--;
  }

  public getAssignedUsers(
    cpmrn: string,
    encounters: number
  ): Observable<AssignedUserInterface[]> {
    return this._http
      .get<ApiResponse>(
        `${environment.apiUrl}patients/${cpmrn}/${encounters}/assignedstaff`
      )
      .map((data) => data.data?.users);
  }
}
