import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment as env } from "src/environments/environment";
import { ApiResponse } from "../models/api-response.model";
import {
  TvUnitAPIDataInterface,
  TVUnitViewParamsInterface,
} from "../models/tv-unit-view.model";

@Injectable({
  providedIn: "root",
})
export class TvUnitViewService {
  constructor(private _http: HttpClient) {}

  public env = env;
  public getTVUnit({ hospital, unit }: TVUnitViewParamsInterface) {
    return this._http
      .get<ApiResponse<TvUnitAPIDataInterface>>(
        `${this.env.apiUrl}tv/get_unit_info/${hospital}/${unit}`
      )
      .pipe(map((response) => response.data));
  }
}
