import { Action, createReducer, on } from "@ngrx/store";

import * as fromAuth from "../../actions/auth.actions";
import { AuthApiActions } from "../../actions/auth";
import { AuthFamilyOtp } from "src/app/models/auth/Otp.model";

export interface State {
  loading: boolean;
  error: string;
  data: AuthFamilyOtp;
  success: boolean;
}

const initialState: State = {
  loading: false,
  error: "",
  data: null,
  success: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthApiActions.generateFamilyOtp, () => ({
    ...initialState,
    loading: true,
  })),
  on(AuthApiActions.generateFamilyOtpFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
    success: false,
  })),
  on(AuthApiActions.generateFamilyOtpSuccess, (state, { data }) => ({
    ...initialState,
    loading: false,
    data,
    success: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
