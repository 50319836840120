import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject, Subject, Subscription } from "rxjs";
import { Topic } from "../enums/topic.enum";
import * as fromUserReducer from "src/app/store/reducers/user";
import { takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class AISocketService implements OnDestroy {
  private aiServerSocket: any;
  private readyStateSubject: BehaviorSubject<boolean>;
  private messageTopic: { [key: string]: BehaviorSubject<any> } = {};
  private userToken: string;
  private currentUser: string;
  private maxAttempts: number = 5;
  private attempt: number = 1;
  private userEmail: string;
  private userRole: string;
  private isActiveTab: boolean = false;
  private socketURL = `${environment.aiSocketUrl.replace(
    /^http/,
    "ws"
  )}ws?name=AI-Server-Socket`;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private subscription: Subscription;

  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  constructor(private store: Store<{}>) {
    this.readyStateSubject = new BehaviorSubject<boolean>(false);
    this.createMessageTopics();
    // listion for user login change before trying to get user token for AI socket
    this.users$.subscribe((user) => {
      if (user["currentUserState"]) {
        this.connect();
      } else {
        // close the connection and do not retry if user is not loged in
        this.attempt = this.maxAttempts;
        this.close();
      }
    });
    // listion for active tab
    document.addEventListener("visibilitychange", () => {
      this.isActiveTab = !document.hidden;
      if (this.isActiveTab) {
        this.connect();
      } else {
        this.close(1000, "Active tab changed.");
      }
    });
    // subscribe to connected status
    this.subscription = this.subscribeToTopic(Topic.connected).subscribe(
      (message) => {
        if (message) {
          this.attempt = 1;
        }
      }
    );
  }

  ngOnDestroy(): void {
    // emit a value to complete all observables using takeUntil
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
    this.close();
  }

  private createMessageTopics(): void {
    for (const key in Topic) {
      const value = Topic[key];
      this.messageTopic[value] = new BehaviorSubject<any>(null);
    }
  }

  private connect(): void {
    // avoid creating new connection if one already exists or is being opened
    if (
      this.aiServerSocket &&
      (this.aiServerSocket.readyState === WebSocket.OPEN ||
        this.aiServerSocket.readyState === WebSocket.CONNECTING)
    ) {
      return;
    }
    // ensure any existing connection is closed before connecting
    this.close();
    this.aiServerSocket = new WebSocket(this.socketURL);

    // sending user tocken on open to authenticate the connection
    this.aiServerSocket.addEventListener("open", () => {
      this.userToken = localStorage.getItem("token");
      this.currentUser = localStorage.getItem("currentUser");
      if (this.userToken && this.currentUser) {
        const authentication = {
          currentUser: this.currentUser,
          userToken: this.userToken,
        };
        const currentUserObject = JSON.parse(this.currentUser);
        this.userEmail = currentUserObject["email"];
        this.userRole = currentUserObject["role"];
        this.aiServerSocket.send(JSON.stringify(authentication));
        this.readyStateSubject.next(true);
      } else {
        console.error("User Token is not available or CurrentUser is not set!");
      }
    });
    this.aiServerSocket.addEventListener("close", (event) => {
      this.readyStateSubject.next(false);
      console.warn(
        "AI WebSocket disconnected: " + event.reason || "No reason provided"
      );
      // reconnect if the connection is closed unexpectedly
      if (this.attempt < this.maxAttempts) {
        // retry only if current tab is active
        if (this.isActiveTab) {
          setTimeout(() => this.connect(), 5000); // retry every 5 seconds
          this.attempt = this.attempt + 1;
        }
      }
    });
    this.aiServerSocket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      this.handleMessage(message);
    });
    this.aiServerSocket.addEventListener("error", (error) => {
      console.error("AI WebSocket error:", error);
      this.readyStateSubject.next(false);
      this.close(); // close the socket on error to trigger reconnection
    });
  }

  private handleMessage(message: any): void {
    if (message.topic && this.messageTopic[message.topic]) {
      this.messageTopic[message.topic].next(message);
    } else {
      console.error("Unknown message recieved from AI server!");
    }
  }

  get readyState(): Observable<boolean> {
    return this.readyStateSubject.asObservable();
  }

  subscribeToTopic(topic: Topic): Observable<any> {
    return this.messageTopic[topic].asObservable();
  }

  send(topic: Topic, message: any): void {
    if (this.aiServerSocket.readyState === WebSocket.OPEN) {
      // add topic to message
      message["topic"] = topic;
      message["email"] = this.userEmail;
      message["role"] = this.userRole;
      this.aiServerSocket.send(JSON.stringify(message));
    } else {
      console.error("AI WebSocket is not open. Message not sent.");
    }
  }

  close(code?: number, reason?: string): void {
    if (
      this.aiServerSocket &&
      !(
        this.aiServerSocket.readyState === WebSocket.CLOSED ||
        this.aiServerSocket.readyState === WebSocket.CLOSING
      )
    ) {
      this.aiServerSocket.close(code, reason);
    }
  }
}
