<div
  class="cp-phone-input-comp cp-custom-mat-input"
  [formGroup]="phoneInputForm"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
>
  <span #countryCodeContainer class="cursor-pointer">
    <cp-searchable-dropdown
      formControlName="countryCode"
      [data]="countryCodes"
      [topList]="topCountriesArr"
      dataKeySearch="countryName"
      searchPlaceholder="Search country"
      matSelectClass="font-size-12"
      [isMatSelectTrigger]="true"
      (selectionChange)="handleInput()"
      #countryCodeSelect
    >
      <div mat-select-trigger class="display-flex align-items-center">
        <cp-flag-icons
          flagClass="ff-md"
          [isoCountryCode]="getFlag"
          class="cp-mr-1"
        ></cp-flag-icons>
        {{ countryCode.value }}
      </div>
      <ng-template #topOptionTemplate let-countryCode>
        <ng-template
          [ngTemplateOutlet]="phoneOption"
          [ngTemplateOutletContext]="{ countryCode: countryCode }"
        ></ng-template>
      </ng-template>

      <ng-template #optionTemplate let-countryCode>
        <ng-template
          [ngTemplateOutlet]="phoneOption"
          [ngTemplateOutletContext]="{ countryCode: countryCode }"
        ></ng-template>
      </ng-template>
    </cp-searchable-dropdown>
  </span>
  <span>
    <img
      src="assets/icons/whatsapp.svg"
      *ngIf="!isIndianCountryCode"
      alt=""
      srcset=""
      class="whatsapp-icon"
    />
    <input
      #mobileNumberInput
      class="cp-phone-input-comp__input"
      [ngClass]="{
        'whatsapp-icon-padding': !isIndianCountryCode
      }"
      formControlName="mobileNumber"
      type="tel"
      matInput
      (input)="handleInput()"
      [placeholder]="placeholder"
    />
    <ng-content></ng-content>
  </span>
</div>

<ng-template #phoneOption let-countryCode="countryCode">
  <div class="display-flex align-items-center" #countryEl>
    <cp-flag-icons
      flagClass="ff-md"
      [isoCountryCode]="countryCode.data.isoCountryCode"
    ></cp-flag-icons>
    <span class="cp-ml-1">{{ countryCode?.value }}</span>
    <span class="cp-ml-1 text-overflow-ellipses">{{
      countryCode?.data?.countryName
    }}</span>
  </div>
</ng-template>
