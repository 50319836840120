import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { BloodPreset } from "../../../models/preset/BloodPreset.model";

@Component({
  selector: "app-protocol-blood-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="blood.patientType"
        [ageGroup]="blood.patientType"
        [id]="blood._id"
      ></age-group-view>
      <div class="name"><strong>Title: </strong> {{ blood.title }}</div>
      <div class="">
        <strong>Quantity: </strong> {{ blood.quantity }}
        {{ blood.quantityUnit ? blood.quantityUnit : "unit" }}
      </div>

      <div class="" *ngIf="blood.numberOfDoses">
        <strong>Instances: </strong>{{ blood.numberOfDoses }}
      </div>
      <div class="" *ngIf="blood.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{ orderService.getFrequencyDisplayName(blood.frequency) }}</span>
      </div>
      <div class="" *ngIf="blood.urgency">
        <strong>Urgency: </strong>{{ blood.urgency }}
      </div>

      <div class=" details" *ngIf="blood.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ blood.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="blood.instructions">
        <strong>Instructions</strong>:
        <em>{{ blood.instructions }}</em>
      </div>

      <div class=" details" *ngIf="blood.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ blood.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolBloodViewComponent {
  @Input() blood: BloodPreset;

  constructor(public orderService: OrderService) {}
}
