import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CodeSheet } from "../model/code-sheet.model";
import { Store, select } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user/index";

@Injectable({
  providedIn: "root",
})
export class CodeSheetService {
  env = environment;
  public user;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));

  constructor(private http: HttpClient, private store: Store<{}>) {
    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
      }
    });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  updateCodesheet(CPMRN, encounters, codesheet): Observable<CodeSheet> {
    return this.http
      .put<CodeSheet>(
        this.env.apiUrl +
          "notes/codesheet/updateCodeSheet/" +
          CPMRN +
          "/" +
          encounters +
          "/",
        codesheet,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  saveCodesheetDraft(CPMRN, encounters, codeSheet): Observable<any> {
    return this.http
      .post<CodeSheet>(
        this.env.apiUrl +
          "notes/codesheet/codesheetdraft/" +
          CPMRN +
          "/" +
          encounters +
          "/",
        codeSheet,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  deleteCodesheet(delInfo): Observable<any> {
    return this.http
      .put<any>(
        this.env.apiUrl + "notes/deleteCodesheet/",
        delInfo,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
}
