import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AugnitoService {
  public cursorIndex: any;
  public contentChangeNotes: string;
  public augnitoText$ = new Subject<string>();
  public augnitoBtnActive = false;
  public hyperText = {
    show: false,
    val: "",
  };

  constructor() {}

  onFocus(event: any, form: any) {
    if (event.range) {
      this.cursorIndex = { event: event, form: form };
    }
  }

  contentChanged(event: any) {
    this.contentChangeNotes = event.html;
  }

  augnitoText(event: string) {
    if (event) this.augnitoText$.next(event);
  }

  micStatus(event: boolean) {
    this.augnitoBtnActive = event;
  }

  partialTextAugnitochk(event: any) {
    this.hyperText = event;
  }
}
