import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromChatMessage from "./chat-data.reducer";
import * as fromChatView from "./chat-view.reducer";

export interface chatState {
  data: fromChatMessage.State;
  view: fromChatView.State;
}

export interface State {
  chat: chatState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromChatMessage.reducer,
  view: fromChatView.reducer,
};

/** SELECTOR setup */
export const getChatState = createFeatureSelector<chatState>("chat");

export const getChatData = createSelector(getChatState, (state) => state.data);

export const getChatMessages = createSelector(
  getChatData,
  // (state: chatState) => (state.data)
  fromChatMessage.selectAllMessages
);

export const getChatView = createSelector(
  getChatState,
  (state: chatState) => state.view
);

export const getChatStatus = createSelector(
  getChatView,
  (state) => state.chatStatus
);

export const getDocImage = createSelector(
  getChatView,
  (state) => state.docImageKey
);

export const getChatReceipts = createSelector(
  getChatView,
  (state) => state.readReciepts
);

export const getNotificationStatus = createSelector(
  getChatView,
  (state) => state.showNotification
);

export const getDraftChat = createSelector(
  getChatView,
  (state) => state.draftText
);

export const getChatMinimised = createSelector(
  getChatView,
  (state) => state.minimised
);

export const getChatTabOpen = createSelector(
  getChatView,
  (state) => state.chatTabOpen
);
