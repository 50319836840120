import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { AVATAR_SIZE } from "src/app/iris-components/avatar/avatar.component";
import { Cosign, Intensivist, Notes } from "../../model/notes.model";
import { NotesService } from "../../services/notes.service";
import * as fromStore from "../../store";
import { flatten } from "lodash-es";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

@Component({
  selector: "app-request-attest",
  templateUrl: "./request-attest.component.html",
  styleUrls: ["./request-attest.component.scss"],
})
export class RequestAttestComponent implements OnInit {
  authorCosign = new UntypedFormControl();
  cosignIntensivists: Intensivist[];
  noUserMsg: string;
  private unsubscribe$: Subject<any> = new Subject<any>();
  AVATAR_SIZE: typeof AVATAR_SIZE = AVATAR_SIZE;

  constructor(
    private _noteService: NotesService,
    private _dialogRef: MatDialogRef<RequestAttestComponent>,
    private store: Store<fromStore.NoteState>,
    private dialog: MatDialog
  ) {}

  @Input() patientInfo: {
    commandCenterID: string;
    hospitalID: string;
  };

  @Input() set noteReferenceID(_id: string) {
    if (_id) {
      combineLatest([
        this.store.select(fromStore.getAllSignedNotes),
        this.store.select(fromStore.getAllPendedNotes),
      ])
        .pipe(take(1))
        .subscribe(([...finalNotes]) => {
          const note: Notes = flatten(finalNotes).find((notes: Notes) => {
            return notes["_id"] === _id;
          });
          if (note) {
            const cosignDetails: Cosign = note.content["cosign"];
            if (cosignDetails?.requestedTo) {
              this.selectedIntensivist = {
                author: cosignDetails.requestedTo.author,
                name: cosignDetails.requestedTo.uniqueID.name,
                email: cosignDetails.requestedTo.uniqueID.email,
              };
            }
            if (cosignDetails?.status) {
              this.selectedIntensivist.status = cosignDetails.status;
              this.authorCosign.disable({ emitEvent: false });
            }
            if (cosignDetails?.physicianMessage)
              this.selectedIntensivist.physicianMessage =
                cosignDetails.physicianMessage;
          }
        });
    }
  }

  private _user: { name: string; email: string; author: string };
  @Input() set userInfo({ name, email }) {
    this._user = {
      name,
      email,
      author: this._noteService.author,
    };
  }
  get userInfo() {
    return this._user;
  }

  ngOnInit(): void {
    this.authorCosign.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((authorName: string) => {
        if (authorName && authorName != this.stopSubscriptionCheckValue) {
          this._noteService
            .getCosignIntensivist(
              this.patientInfo.commandCenterID,
              this.patientInfo.hospitalID,
              authorName
            )
            .subscribe(
              (response) => {
                if (response.status === 200) {
                  this.noUserMsg = null;
                  this.cosignIntensivists = response.body;
                } else if (response.status === 204) {
                  this.noUserMsg = "No user found";
                  this.cosignIntensivists = [];
                }
              },
              (error) => {
                this.noUserMsg = null;
                console.log("Server error!!");
              }
            );
        }
      });
  }

  remove(): void {
    let dialogRef = this.dialog.open(CloseRequestAttestModal, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status) {
        this.selectedIntensivist = null;
        this.authorCosign.enable({ emitEvent: false });
      }
    });
  }

  public selectedIntensivist: Intensivist;
  getcosignIntensivistDetails(intensivist: Intensivist) {
    this.authorCosign.disable({ emitEvent: false });
    this.selectedIntensivist = intensivist;
  }

  stopSubscriptionCheckValue: string;
  onSelectionChanged(data: MatAutocompleteSelectedEvent) {
    this.stopSubscriptionCheckValue = data.option.value;
  }

  onSubmit() {
    this._dialogRef.close(this.selectedIntensivist);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

@Component({
  template: `<section class="iris-modal-container cc-status-wrapper">
    <div mat-dialog-title class="title">
      <mat-icon>warning</mat-icon>
      Do you want to delete the attestation request?
    </div>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close class="button-secondary">
        Cancel
      </button>
      <button
        mat-flat-button
        mat-dialog-close
        class="button-error"
        (click)="deleteStatus()"
      >
        Delete
      </button>
    </mat-dialog-actions>
  </section> `,
  styleUrls: ["../close-modal.component.scss"],
})
export class CloseRequestAttestModal {
  constructor(private dialogRef: MatDialogRef<CloseRequestAttestModal>) {}

  deleteStatus() {
    this.dialogRef.close({ status: true });
  }
}
