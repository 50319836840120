export enum AuthScreenEnum {
  LoginPage = "LoginPage",
  OtpPage = "OtpPage",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
}

export interface TwoFaRemember {
  email: string;
  token: string;
  timestamp: Date;
}

export enum LoginMethods {
  EMAIL = "Email",
  PHONE = "Phone",
}

export type loginMethodType = LoginMethods.EMAIL | LoginMethods.PHONE;
