<div
  class="toaster-container"
  [class]="isCodeBlue ? 'codeblue-toaster' : 'ecall-toaster'"
  [style.cursor]="'default'"
>
  <div class="toaster-heading">
    <div class="toaster-heading-start">
      <img
        [src]="
          isCodeBlue
            ? 'assets/icons/code_blue.svg'
            : 'assets/icons/notification-icons/ecall_black.svg'
        "
      />
      <div class="toaster-heading-text">
        {{ alertHeader }}
      </div>
    </div>
    <div>
      <img
        class="close-icon"
        [src]="
          isCodeBlue
            ? 'assets/icons/close_white.svg'
            : 'assets/icons/close_black.svg'
        "
        (click)="closeDialog('cross on dialog clicked')"
        [style.cursor]="'pointer'"
      />
    </div>
  </div>
  <div class="toaster-body">
    {{ alertText }}
    <div *ngIf="isRunning" class="toaster-body-bold">
      {{ "Time passed: " + runningFor }}
    </div>
    <div class="toaster-body-bold">
      {{ "Raised by: " + toasterData.raised_by_name }}
    </div>
    <div></div>
    <!-- adding empty space  -->
    <div></div>
    <a
      *ngIf="isRunning"
      [href]="convertToHTTPS(toasterData.google_meet_link)"
      target="_blank"
    >
      <div class="toaster-meet-link" (click)="closeDialog('meet link clicked')">
        <img
          src="/assets/icons/notification-icons/google_meet.svg"
          [style.cursor]="'pointer'"
        />
        <div class="toaster-meet-link-text">Join with Google Meet</div>
      </div>
    </a>
    <div class="toaster-meet-link" *ngIf="isRunning">
      <img src="/assets/icons/notification-icons/end_call.svg" />
      <div
        class="toaster-meet-link-text"
        [style.cursor]="'pointer'"
        (click)="endAlertEvent()"
      >
        {{ "End " + (isCodeBlue ? "Codeblue call" : "eCall") }}
      </div>
    </div>
    <div *ngIf="!isRunning" class="toaster-body-bold">
      Call Duration: {{ calculateTimeDuration() }}
    </div>
  </div>
</div>
