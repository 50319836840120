<div class="my-3 protocol-title text-center font-weight-bold">
  {{ protocol.name }}
</div>

<div
  class="mb-3 file-name"
  *ngIf="protocol && protocol.file && protocol.file.data"
>
  File Attached:
  <span
    class="cp-cursor-pointer primary"
    (click)="openFileModal(showAttachment, protocol.file.data)"
  >
    {{ protocol.file.name }}
  </span>
</div>

<div class="protocol_header" *ngIf="presets?.controls?.length > 0">
  <mat-checkbox
    class="protocol_header_checkbox"
    id="select-all"
    name="selectAllPreset"
    [(ngModel)]="selectAllPreset"
    (change)="selectAllPresets()"
  ></mat-checkbox>
  <span
    class="protocol_header_title"
    (click)="selectAllPreset = !selectAllPreset; selectAllPresets()"
  >
    Select All Presets
  </span>
</div>

<form [formGroup]="form">
  <div class="protocol-accordion" formArrayName="presets">
    <mat-accordion
      [multi]="true"
      *ngIf="presets && presets.controls && presets.controls.length > 0"
    >
      <mat-expansion-panel
        *ngFor="let preset of presets.controls; let i = index"
        [id]="'panel-' + i"
        #expPanels
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="exp-panel-title">
            <div class="display-flex justify-content-between width--100">
              <div class="display-flex">
                <div>
                  <mat-checkbox
                    [id]="'checkbox' + i"
                    (click)="$event.stopPropagation()"
                    [checked]="selectedPresets?.has(preset.value._id)"
                    [disabled]="
                      selectedPresets?.has('required' + preset.value._id)
                        ? 'true'
                        : 'false'
                    "
                    (change)="
                      toggleSelectedPreset($event.checked, preset.value._id, i)
                    "
                  ></mat-checkbox>
                </div>
                <div class="protocol-accordion__header__title cp-text-overflow">
                  <div>
                    <img
                      *ngIf="
                        getPreset(preset.value._id).type !== 'vents';
                        else elseVentIcon
                      "
                      [src]="getIcon(getPreset(preset.value._id).type)"
                    />

                    <ng-template #elseVentIcon>
                      <img src="assets/icons/vent.svg" />
                    </ng-template>
                    <span
                      [matTooltip]="
                        getPreset(preset.value._id)?.presetName || 'Preset'
                      "
                    >
                      {{
                        getPreset(preset.value._id)
                          ? getPreset(preset.value._id).presetName
                          : "Preset"
                      }}
                    </span>
                  </div>
                  <!-- <span class="error-text" *ngIf="preset.invalid">
                    Validation Error
                  </span> -->
                </div>
              </div>
              <div *ngIf="getPrescribed(preset.value.name)">
                <span [matTooltip]="getPrescribed(preset.value.name)?.message"
                  >Prescribed</span
                >
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div
            class="protocol-accordion__body"
            [ngSwitch]="getPresetType(preset.value._id)"
          >
            <app-blood-form
              *ngSwitchCase="'blood'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              formType="new"
            >
            </app-blood-form>

            <app-communication-form
              *ngSwitchCase="'comm'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              formType="new"
            >
            </app-communication-form>

            <app-diet-form
              *ngSwitchCase="'diet'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              formType="new"
            >
            </app-diet-form>

            <app-lab-form
              *ngSwitchCase="'lab'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              formType="new"
            >
            </app-lab-form>

            <app-med-form
              *ngSwitchCase="'med'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [orderableName]="
                preset.value && preset.value.name ? preset.value.name : null
              "
              [medSchedule]="medSchduleArr[i]"
              formType="new"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              [orderInHospital]="false"
            >
            </app-med-form>

            <app-procedure-form
              *ngSwitchCase="'procedure'"
              [config]="getConfig(getPresetType(preset.value._id))"
              [formGroupName]="i"
              [value]="preset.value"
              [showBedsideForm]="canPlaceBedsideOrder$ | async"
              formType="new"
            >
            </app-procedure-form>

            <app-vent-form
              *ngSwitchCase="'vents'"
              [formGroupName]="i"
              [config]="getConfig(getPresetType(preset.value._id))"
              [value]="preset.value"
              [minDate]="currentPatient?.formDates?.minDate"
              [maxDate]="currentPatient?.formDates?.maxDate"
              [currentPatient]="currentPatient"
            >
            </app-vent-form>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="prescribedOrders" class="mt">
      <span
        class="cp-text--normal display-flex align-items-center protocol-accordion__alert"
      >
        <mat-icon>error</mat-icon>
        <span class="ml"
          >Note: Duplication alerts are shown only for drug orders.</span
        >
      </span>
    </div>
  </div>

  <div class="button-group-horizontal protocol-buttons">
    <button
      mat-flat-button
      class="button-primary"
      (click)="$event.preventDefault(); open(preview); selectedItems()"
      [disabled]="selectedPresets?.size === 0 || checkFormInvalid()"
    >
      Preview
    </button>
    <button
      mat-flat-button
      type="button"
      class="button-secondary"
      (click)="onCancel('cancel')"
    >
      Cancel
    </button>
  </div>

  <ng-template #preview>
    <div class="preview-dialog bg-light">
      <div class="header">
        <span class="title">Preview: {{ protocol.name }}</span>
        <img
          matDialogClose
          class="close cp-cursor-pointer"
          src="assets/icons/close.svg"
        />
      </div>
      <div class="body">
        <ng-container *ngFor="let preset of presetArray; let i = index">
          <div class="order-name">
            <mat-checkbox
              [checked]="confirmedPresets.has(preset._id)"
              class="cp-mat-checkbox"
              [disabled]="
                selectedPresets?.has('required' + preset._id) ? 'true' : 'false'
              "
              (change)="toggleConfirmed($event.checked, preset._id)"
            >
              <div *ngIf="preset.type !== 'vents'; else elseIconBlock">
                <img [src]="getIcon(preset.type)" />
                {{
                  getPreset(preset._id)
                    ? getPreset(preset._id).presetName
                    : "Preset"
                }}
              </div>
              <ng-template #elseIconBlock>
                <div class="order-name">
                  <img src="assets/icons/vent.svg" />
                  {{
                    getPreset(preset._id)
                      ? getPreset(preset._id).presetName
                      : "Preset"
                  }}
                </div>
              </ng-template>
            </mat-checkbox>
          </div>

          <div [ngSwitch]="preset.type" class="order-details">
            <app-protocol-blood-view
              [blood]="preset"
              *ngSwitchCase="'blood'"
            ></app-protocol-blood-view>
            <app-protocol-comm-view
              [comm]="preset"
              *ngSwitchCase="'comm'"
            ></app-protocol-comm-view>
            <app-protocol-diet-view
              [diet]="preset"
              *ngSwitchCase="'diet'"
            ></app-protocol-diet-view>
            <app-protocol-lab-view
              [lab]="preset"
              *ngSwitchCase="'lab'"
            ></app-protocol-lab-view>
            <app-protocol-med-view [med]="preset" *ngSwitchCase="'med'">
              <div
                *ngIf="getPrescribed(preset?.name)?.allStatus?.length"
                class="display-flex justify-content-end med-additional-details"
              >
                <div class="cp-mr-1">
                  <span>Present in</span>
                </div>
                <cp-status-chip
                  class="cp-ml-1"
                  *ngFor="
                    let statusObj of getPrescribed(preset?.name)?.allStatus
                  "
                  [cpPopover]="statusObj?.message"
                  position="top-center"
                  [statusConfig]="{
                    buttonText: statusObj?.status,
                    colorClass: 'status-chip-default'
                  }"
                  colorClass="status-light"
                ></cp-status-chip>
              </div>
            </app-protocol-med-view>
            <app-protocol-procedure-view
              [procedure]="preset"
              *ngSwitchCase="'procedure'"
            >
            </app-protocol-procedure-view>
            <app-vital-preset-view
              [vent]="preset"
              *ngSwitchCase="'vents'"
            ></app-vital-preset-view>
          </div>
        </ng-container>
      </div>
      <div class="footer">
        <button
          mat-flat-button
          type="submit"
          class="button-primary"
          [disabled]="this.confirmedPresets.size == 0"
          matDialogClose
          (click)="onSubmit()"
          [disabled]="loading"
        >
          {{ orderFormService.getFormSubmitText(user.role) }}
        </button>
        <button
          mat-flat-button
          type="button"
          class="button-secondary"
          matDialogClose
        >
          Close
        </button>
      </div>
    </div>
  </ng-template>
</form>

<ng-template #showAttachment>
  <div class="show-file-dialog">
    <div class="header">
      <span class="title">Attached File</span>
      <img
        class="close cp-cursor-pointer"
        matDialogClose
        src="assets/icons/close.svg"
      />
    </div>
    <div class="body">
      <embed
        *ngIf="protocol && protocol.file && protocol.file.data"
        [src]="getFileData(protocol.file.data)"
        style="width: 100%; height: 60rem"
      />
    </div>
  </div>
</ng-template>
