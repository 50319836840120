import { createAction, props } from "@ngrx/store";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteForm } from "@notes/models/notes.model";
import { LoaderNames } from "@notes/utils/loader_name";

export const SUBMIT_DRAFT_NOTE =
  "[NOTE-INPUT-FORM-DATA] submit draft note contents";
export const DELETE_DRAFT_NOTE =
  "[NOTE-INPUT-FORM-DATA] delete draft note contents";

export const SUMBIT_FINAL_NOTE_CONTENTS =
  "[NOTE-INPUT-FORM-DATA] submit final note contents";

export const UPDATE_FINAL_NOTE_CONTENTS =
  "[NOTE-INPUT-FORM-DATA] update final note contents";
export const ATTEST_FINAL_NOTE =
  "[NOTE-INPUT-FORM-DATA] submit attested note contents";
export const NOTE_SUBMISSION_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] submit attested note contents success";
export const NOTE_SUBMISSION_FAILED =
  "[NOTE-INPUT-FORM-DATA] submit attested note contents failed";

export const submitDraftNote = createAction(
  SUBMIT_DRAFT_NOTE,
  props<{ draftNotes: NoteForm }>()
);

export const deleteDraftNote = createAction(
  DELETE_DRAFT_NOTE,
  props<{ refId: string }>()
);

export const submitFinalNote = createAction(
  SUMBIT_FINAL_NOTE_CONTENTS,
  props<{ noteContents: FinalNotes; loaderName: LoaderNames }>()
);

export const updateFinalNote = createAction(
  UPDATE_FINAL_NOTE_CONTENTS,
  props<{ noteContents: FinalNotes; loaderName: LoaderNames }>()
);

export const attestFinalNote = createAction(
  ATTEST_FINAL_NOTE,
  props<{ noteContents: FinalNotes; loaderName: LoaderNames }>()
);

export const noteSubmissionSuccess = createAction(NOTE_SUBMISSION_SUCCESS);
export const noteSubmissionFail = createAction(NOTE_SUBMISSION_FAILED);
