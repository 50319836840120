import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromRoot from "src/app/reducers";
import * as fromOrder from "./orders.reducer";

export interface OrderState {
  order: fromOrder.State;
}

export interface State extends fromRoot.AppState {
  order: OrderState;
}

export const reducers: ActionReducerMap<OrderState, any> = {
  order: fromOrder.reducer,
};

export const getOrderState = createFeatureSelector<OrderState>("order");

export const getInputTabActive = createSelector(
  getOrderState,
  fromOrder.getInputTabActive
);

export const getOrders = createSelector(getOrderState, fromOrder.getOrders);

export const getActiveProtocolsByCategory = createSelector(
  getOrders,
  (orders, category) => {
    return orders && orders[category]
      ? orders[category]["activeProtocols"]
      : [];
  }
);

export const getActiveCultureOrders = createSelector(getOrders, (orders) =>
  orders["activeTotal"]["labs"].reduce((cultures, lab) => {
    if (lab?.investigation) {
      const investigationArray = lab.investigation.split(" ");

      if (investigationArray.indexOf("Culture") > -1) {
        cultures.push(lab);
      }
    }

    return cultures;
  }, [])
);

export const getActiveCovidOrders = createSelector(getOrders, (orders) =>
  orders["activeTotal"]["labs"].reduce((covids, lab) => {
    const investigation = lab.investigation
      ? lab.investigation.toLowerCase()
      : "";

    if (investigation.includes("covid")) {
      covids.push(lab);
    }

    return covids;
  }, [])
);

export const getActiveCulturesCount = createSelector(
  getActiveCultureOrders,
  (cultures) => {
    if (!cultures) {
      return 0;
    }

    return cultures.reduce((acc, culture) => {
      const startTime = new Date(culture.startTime).getTime();
      const secondDay = startTime + 172800000;
      const thirdDay = startTime + 259200000;
      const currentTime = new Date().getTime();

      if (
        (currentTime > secondDay && currentTime < thirdDay) ||
        currentTime > thirdDay
      ) {
        acc.push(culture);
      }

      return acc;
    }, []).length;
  }
);

export const getActiveCovidOrdersCount = createSelector(
  getActiveCovidOrders,
  (orders) => {
    if (!orders) {
      return 0;
    }

    return orders.reduce((acc, order) => {
      const startTime = new Date(order.startTime).getTime();
      const firstDay = startTime + 86400000;
      const currentTime = new Date().getTime();

      if (currentTime > firstDay) {
        acc.push(order);
      }

      return acc;
    }, []).length;
  }
);

export const getActiveProtocolState = createSelector(
  getOrderState,
  fromOrder.getActiveProtocols
);

export const doesProtocolHaveFile = createSelector(
  getActiveProtocolState,
  (protocols, name) => {
    const filteredProtocols = protocols.filter(
      (protocol) => protocol.name === name
    );

    if (filteredProtocols.length === 1 && filteredProtocols[0].file) {
      return filteredProtocols[0].file;
    }

    return null;
  }
);

export const getOrderByCategoryAndType = createSelector(
  getOrders,
  (orders, data) => {
    const category = data.category === "active" ? "activeTotal" : data.category;
    const type = data.type;
    const id = data.orderId;

    const ordersArray = orders[category][type];

    if (!ordersArray || ordersArray.length === 0) {
      return null;
    }

    const filteredOrders = ordersArray.filter((order) => order._id === id);

    if (!filteredOrders || filteredOrders.length === 0) {
      return null;
    }

    return filteredOrders[0];
  }
);

export const checkIfReplaceOrderIsPresent = createSelector(
  getOrders,
  (orders, data) => {
    return false;
    /*  const id = data.id;
    const type = data.type;
    const category = data.cateogry ? data.category : 'pending';

    const ordersByType =
      orders[category] && orders[category][type] ? orders[category][type] : [];

    if (ordersByType.length === 0) {
      return false;
    }

    return !!find(ordersByType, (order) => order.replaceOrderId === id);*/
  }
);

/*
 * Order Form Selector
 * */
export const getOrderFormState = createSelector(
  getOrderState,
  (state: any) => state.form
);

export const getOrderFormLoading = createSelector(
  getOrderFormState,
  (state) => state.loading
);
