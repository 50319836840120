import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keyvalueWithoutsorting",
})
export class KeyvalueWithoutsortingPipe implements PipeTransform {
  transform(value: { [key: string]: any }): any[] {
    if (!value) {
      return [];
    }

    return Object.keys(value).map((key) => ({
      key: key,
      value: value[key],
    }));
  }
}
