import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ProfilePicService } from "src/app/admit-form/services/profile-pic.service";

import {
  AvatarParams,
  AVATAR_SIZE,
} from "src/app/iris-components/avatar/avatar.component";
import { Patient, PatientType } from "src/app/models/patient";
import { PatientHeaderComponent } from "src/app/patient/patient-header/patient-header.component";
import { PatientService } from "src/app/services/patient.service";
import { displayAge } from "src/app/support-functions/calculateAge";
import {
  CALCULATE_WEIGHT,
  calculateWeight,
} from "src/app/support-functions/calculateWeight";

@Component({
  selector: "cp-patient-card",
  templateUrl: "./patient-card.component.html",
  styleUrls: ["./patient-card.component.scss"],
  host: {
    class: "bg-white",
  },
})
export class PatientCardComponent
  extends PatientHeaderComponent
  implements OnInit
{
  @Input("patientData") patientData: Patient = null;

  PatientType = PatientType;

  public get age(): number {
    return this.patientData?.age?.year;
  }
  profilePic: string;

  constructor(
    dialog: MatDialog,
    router: Router,
    store: Store<any>,
    _actions$: Actions,
    pageTitle: Title,
    public _patientService: PatientService,
    private _service: ProfilePicService
  ) {
    super(dialog, router, store, _actions$, pageTitle, _patientService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get getPatientAge() {
    const ageObj = this.patient?.age;
    return displayAge(ageObj, true, this.patient.patientType, this.patient.dob);
  }

  getRoundedValue(value) {
    return Math.round(value * 100) / 100;
  }

  get patAdminWStr() {
    const payload: CALCULATE_WEIGHT = {
      weightObj: this.patient?.weightObj,
      patientType: this.patient?.patientType,
      weightInGrams: true,
    };
    return calculateWeight(payload) || "-";
  }

  get patientAvatar() {
    if (this.patient?.profilePicture && !this.profilePic) {
      this._service
        .getImage(this.patient.CPMRN, this.patient.encounters)
        .subscribe((res) => {
          this.profilePic = res.data.signedUrl;
          return [
            {
              name: this.patient?.name,
              size: AVATAR_SIZE.L,
              imageUrl: this.profilePic,
            },
          ];
        });
    } else {
      return [
        {
          name: this.patient?.name,
          size: AVATAR_SIZE.L,
        },
      ];
    }
  }
}
