import { createReducer, on, Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import * as fromUnitListActions from "../../actions/unit.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { Unit } from "src/app/models/hospital";

export interface State extends EntityState<Unit> {}

export function selectUnitId(unit: Unit): string {
  //In this case this would be optional since primary key is _id
  return unit._id;
}

export function sortByName(a: Unit, b: Unit): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<Unit> = createEntityAdapter<Unit>({
  selectId: selectUnitId,
  // sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState();

const unitListReducer = createReducer(
  initialState,
  on(fromUnitListActions.setUnits, (state, { units }) => {
    return adapter.setAll(units, state);
  }),
  on(fromUnitListActions.updateUnitInfo, (state, { unit }) => {
    return adapter.upsertOne(unit, state);
  }),
  on(fromUnitListActions.updateBulkUnits, (state, { units }) => {
    return adapter.upsertMany(units, state);
  }),
  on(fromUnitListActions.resetUnits, (state) =>
    adapter.removeAll({ ...state })
  ),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state }))
);

const { selectAll } = adapter.getSelectors();

// select the array of units
export const selectAllUnits = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return unitListReducer(state, action);
}
