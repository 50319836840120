<div class="freq-comp" [formGroup]="form">
  <div class="select-freq-type" formGroupName="frequency">
    <span> This medication is to be given: </span>
    <mat-button-toggle-group
      class="button-toggle-secondary border"
      formControlName="fType"
      (change)="onFrequencyChange($event.value)"
    >
      <mat-button-toggle
        value="every"
        [disabled]="validateFtype && frequencyType?.value == 'continuous'"
        [ngClass]="{
          disabledDiv: validateFtype && frequencyType?.value == 'continuous'
        }"
      >
        <span>Every </span>
      </mat-button-toggle>
      <mat-button-toggle
        value="once"
        [disabled]="validateFtype && frequencyType?.value == 'continuous'"
        [ngClass]="{
          disabledDiv: validateFtype && frequencyType?.value == 'continuous'
        }"
      >
        <span>Once </span>
      </mat-button-toggle>
      <mat-button-toggle
        value="continuous"
        [disabled]="validateFtype && frequencyType?.value != 'continuous'"
        [ngClass]="{
          disabledDiv: validateFtype && frequencyType?.value != 'continuous'
        }"
      >
        <span>Continuous </span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="freq-cont">
    <div class="days-hours-mins-cont" formGroupName="frequency">
      <div
        class="days-cont"
        [ngClass]="{
          disabledDiv:
            frequencyType.value !== 'every' ||
            frequencyHours?.value ||
            frequencyMins?.value
        }"
      >
        <label>Days</label>
        <input
          matInput
          class="order-input"
          type="number"
          placeholder="DD"
          min="2"
          [tabindex]="frequencyHours?.value || frequencyMins?.value ? '-1' : ''"
          formControlName="days"
          (input)="fDaysUpdated()"
        />
      </div>
      <span
        class="colon"
        [ngClass]="{
          disabledDiv: frequencyType.value !== 'every'
        }"
        >:
      </span>
      <div
        class="hours-cont"
        [ngClass]="{
          disabledDiv: frequencyType.value !== 'every' || frequencyDays?.value
        }"
      >
        <label>Hours</label>
        <input
          matInput
          MatInputPreceedingZero
          class="order-input"
          type="number"
          min="0"
          max="47"
          placeholder="HH"
          [tabindex]="frequencyDays?.value ? '-1' : ''"
          formControlName="hours"
          (input)="fHoursUpdated($event.target.value)"
        />
      </div>
      <span
        class="colon"
        [ngClass]="{
          disabledDiv: frequencyType.value !== 'every'
        }"
      >
        :
      </span>
      <div
        class="mins-cont"
        [ngClass]="{
          disabledDiv: frequencyType.value !== 'every' || frequencyDays?.value
        }"
      >
        <label>Minutes</label>
        <input
          matInput
          class="order-input"
          type="number"
          min="0"
          max="59"
          placeholder="MM"
          [tabindex]="frequencyDays?.value ? '-1' : ''"
          formControlName="mins"
          (input)="fMinsUpdated($event.target.value)"
        />
      </div>
    </div>
    <div
      class="interval-list-cont"
      [ngClass]="{ disabledDiv: frequencyType.value !== 'every' }"
    >
      <div class="sos-cont" (click)="onSosChange()">
        <mat-button-toggle-group
          class="button-toggle-secondary"
          formControlName="sos"
          ><mat-button-toggle [checked]="sos?.value">SOS</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="every-freq-choices">
        <label>Frequency:</label>
        <mat-button-toggle-group
          class="button-toggle-secondary border"
          formControlName="everyFreRadio"
          (change)="onEveryFreqChange($event.value)"
        >
          <mat-button-toggle [value]="'24'">
            <span matTooltip="Every 24 hrs">OD</span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'12'">
            <span matTooltip="Every 12 hrs">BID </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'8'">
            <span matTooltip="Every 8 hrs">TID</span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'6'">
            <span matTooltip="Every 6 hrs">QID</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>

  <div class="error-cont">
    <span
      class="error-text"
      *ngIf="
        frequencyDays.invalid && (frequencyDays.touched || frequencyDays.dirty)
      "
    >
      {{
        frequencyDays?.errors?.min
          ? "Days need to be atleast 2"
          : "Days is invalid"
      }}
    </span>

    <span
      class="error-text"
      *ngIf="
        frequencyHours.invalid &&
        (frequencyHours.touched || frequencyHours.dirty)
      "
    >
      Hours is invalid
    </span>

    <span
      class="error-text"
      *ngIf="
        frequencyMins.invalid && (frequencyMins.touched || frequencyMins.dirty)
      "
    >
      Minutes is invalid
    </span>
  </div>

  <div class="sos-reason-cont" *ngIf="sos.value">
    <label>SOS Reason</label>
    <textarea matInput rows="2" formControlName="sosReason"></textarea>
    <span
      class="error"
      *ngIf="
        utilService.hasError(form, 'sosReason', 'maxlength') &&
        (sosReason.touched || sosReason.dirty)
      "
    >
      Max 5000 character
    </span>
  </div>

  <div class="dose-scheduler">
    <app-dose-scheduler
      [config]="config"
      [formType]="formType"
      [scheduleSelected]="scheduleSelected"
      [selectedUrgency]="selectedUrgency"
      *ngIf="config.startTime.present || config.endTime.present"
    >
    </app-dose-scheduler>
    <app-meta-frequency
      [config]="config"
      [formType]="formType"
      [frequencyType]="frequencyType.value"
      [freqHourSelected]="freqHourSelected"
      (daysChange)="onDaysChange($event)"
      (doseChange)="onDoseChange($event)"
      (scheduleChange)="onScheduleChange($event)"
      (urgencyChange)="onUrgencyChange($event)"
    ></app-meta-frequency>
  </div>
</div>
