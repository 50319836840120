<div class="shift-assign-comp">
  <div class="table-cont">
    <div class="display-flex align-items-center justify-content-between">
      <div class="header">Hospital list</div>
      <div class="display-flex align-items-center">
        <cp-search-box
          [width]="28"
          [(ngModel)]="searchText"
          placeholder="Search workspaces/hospitals/users"
        >
        </cp-search-box>
        <cp-shift-toggle-button
          *ngIf="showShiftToggle"
          class="cp-ml-2 cp-mt-1"
        ></cp-shift-toggle-button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="table cp-mt-2">
      <div
        *ngFor="
          let workspace of workSpaceInfo | filterWorkspace : (shift$ | async);
          trackBy: identify
        "
        class="cp-mb-1"
        [hidden]="
          workspace
            | unitListFilter : searchText : unitIdMapping
            | allUnitsHidden
        "
      >
        <ng-container>
          <mat-accordion class="shift-assign-comp__accordion">
            <mat-expansion-panel
              [@.disabled]="true"
              [expanded]="workSpaceInfo?.length === 1"
              class="shift-assign-comp__accordion__pannel cp-mat-expansion-panel-no-padding"
            >
              <mat-expansion-panel-header
                class="shift-assign-comp__accordion__pannel__header"
              >
                <mat-panel-title>
                  <span [appTextHighlight]="searchText" class="cp-text-normal">
                    {{ workspace.name }}
                  </span>
                </mat-panel-title>
                <mat-panel-description
                  class="display-flex justify-content-end cp-w-100"
                >
                  <button
                    *ngIf="workspace.name != otherWorkspaceName"
                    mat-flat-button
                    class="button-primary shift-assign-comp__accordion__pannel__header__assign-all-btn"
                    (click)="
                      assignAllFlag = true;
                      openAssignAllConfirmationModal(
                        assignAll,
                        workspace._id,
                        workspace.name
                      );
                      worksSpaceName = workspace.name;
                      $event.stopPropagation()
                    "
                    [ngClass]="{
                      disabled:
                        (workspaceAssignLoaderList$ | async).includes(
                          workspace._id
                        ) ||
                        (!assignAllButtonDisabled[workspace.name] &&
                          workspace.name == hiddenWorkspaceName)
                    }"
                  >
                    {{
                      !assignAllButtonDisabled[workspace.name] &&
                      workspace.name != hiddenWorkspaceName
                        ? "Unassign all"
                        : "Assign all"
                    }}
                    <app-loader
                      *ngIf="
                        (workspaceAssignLoaderList$ | async).includes(
                          workspace._id
                        )
                      "
                    ></app-loader>
                  </button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <table
                  matSort
                  mat-table
                  (matSortChange)="onSort($event, workspace.name)"
                  [dataSource]="
                    workspace
                      | unitListFilter : searchText : unitIdMapping
                      | sortShiftAssign : sortParam : workspace.name
                  "
                >
                  <ng-container matColumnDef="hospital">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Hospital | Unit
                    </th>
                    <td class="table-sticky" mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{ unit?.hospitalInfo?.name }} | {{ unit?.name }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="physician">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Physician
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{
                          unit?.docAssigned?.name ? unit?.docAssigned?.name : ""
                        }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="specialist">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Specialist
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{
                          unit?.specialistAssigned?.name
                            ? unit?.specialistAssigned?.name
                            : ""
                        }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="registrar">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Registrar
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{
                          unit?.ccaPhysicianAssigned?.name
                            ? unit?.ccaPhysicianAssigned?.name
                            : ""
                        }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nurseNP">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Nurse(CCA)
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{
                          unit?.npAssigned?.name ? unit?.npAssigned?.name : ""
                        }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nurse">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Nurse(RN)
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{ unit?.rnAssigned?.name }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nurseCCRN">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Nurse(CCRN)
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <span [appTextHighlight]="searchText">
                        {{ unit?.ccrnAssigned?.name }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone no</th>
                    <td
                      mat-cell
                      *matCellDef="let unit"
                      (click)="callToPhone(i + 'phone')"
                    >
                      <span
                        *ngIf="unit.phone"
                        matTooltip="Click and press F8 to make a call"
                      >
                        {{ unit.phone }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="speedDial">
                    <th mat-header-cell *matHeaderCellDef>Speed dial</th>
                    <td
                      mat-cell
                      *matCellDef="let unit"
                      (click)="callToPhone(i + 'speed')"
                    >
                      <span
                        *ngIf="unit.speedDial"
                        matTooltip="Click and press F8 to make a call"
                      >
                        {{ unit.speedDial }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="anydeskId">
                    <th mat-header-cell *matHeaderCellDef class="link-column-2">
                      Anydesk ID
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <a
                        target="_blank"
                        *ngIf="unit.anydeskId"
                        [href]="unit.anydeskId"
                      >
                        <img
                          class="anydesk-icon"
                          matTooltip="Anydesk ID"
                          src="assets/icons/anydesk.svg"
                          alt="Anydesk ID"
                      /></a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="pacs">
                    <th mat-header-cell *matHeaderCellDef class="link-column-1">
                      PACS
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <a
                        target="_blank"
                        class="primary"
                        *ngIf="unit.pacsLink"
                        [href]="updateLink(unit.pacsLink)"
                        >PACS
                      </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lis">
                    <th mat-header-cell *matHeaderCellDef class="link-column-1">
                      LIS
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <a
                        target="_blank"
                        class="primary"
                        *ngIf="unit.lisLink"
                        [href]="updateLink(unit.lisLink)"
                      >
                        LIS
                      </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="meetLink">
                    <th mat-header-cell *matHeaderCellDef class="link-column-2">
                      Meet Link
                    </th>
                    <td mat-cell *matCellDef="let unit">
                      <a
                        target="_blank"
                        class="primary"
                        *ngIf="unit.meetLink"
                        [href]="updateLink(unit.meetLink)"
                      >
                        <img
                          matTooltip="Google meet link"
                          src="assets/icons/icons8-google-meet.svg"
                          alt="Google Meet "
                      /></a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="assign">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="assign-btn-column"
                    ></th>
                    <td class="table-sticky" mat-cell *matCellDef="let unit">
                      <button
                        mat-flat-button
                        [disabled]="
                          (unitAssignLoaderList$ | async).includes(unit._id)
                        "
                        class="assign-btn"
                        [class]="[
                          unit.assignButtonText === 'Assign'
                            ? 'button-tertiary'
                            : 'button-secondary'
                        ]"
                        (click)="
                          onAssign(unit, unit.assignButtonText === 'Assign')
                        "
                      >
                        {{ unit.assignButtonText }}

                        <app-loader
                          [buttonLoader]="true"
                          *ngIf="
                            (unitAssignLoaderList$ | async).includes(unit._id)
                          "
                        ></app-loader>
                      </button>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="shiftAssignTableColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    [hidden]="row?.isHidden"
                    *matRowDef="let row; columns: shiftAssignTableColumns"
                  ></tr>
                </table>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>

      <div *ngIf="!(workSpaceInfo | filterWorkspace : shift)?.length">
        <div *ngIf="shift === shiftEnum.DAY" class="no_data_found_block">
          No workspaces for day shift
        </div>
        <div *ngIf="shift === shiftEnum.NIGHT" class="no_data_found_block">
          No workspaces for night shift
        </div>
      </div>
      <div
        *ngIf="
          workSpaceInfo | isWorkspaceEmpty : searchText : unitIdMapping : shift
        "
      >
        <div class="no_data_found_block">No data for "{{ searchText }}"</div>
      </div>
    </div>

    <!-- <mat-paginator
			#paginator
			[length]="unitsList.length"
			[hidePageSize]="true"
			[pageSize]="12"
			showFirstLastButtons
		>
		</mat-paginator> -->
  </div>

  <div class="assigned-patients-list">
    <div class="numbers">
      <div class="assigned-patients">
        <div class="count text-orange">{{ assignedPatientsCount }}</div>
        <div class="label">Assigned patients</div>
      </div>
      <div class="total-patients">
        <div class="count">{{ totalPatientsCount }}</div>
        <div class="label">Total patients</div>
      </div>
    </div>
    <div class="list">
      <mat-tab-group [selectedIndex]="0" #assignedPatList>
        <mat-tab id="physician" label="Physicians">
          <div
            class="count-info"
            *ngIf="
              currentUser?.role === PHYSICIAN ||
              currentUser?.role === REGISTRAR ||
              currentUser?.role == SPECIALIST
            "
          >
            <div class="name primary">You</div>
            <div class="count text-orange">
              <span>
                {{ assignedPatientsCount }}
              </span>
            </div>
          </div>
          <ng-container
            *ngFor="
              let doc of (patientCount.docs || [])
                .concat(patientCount.registrar || [])
                .concat(patientCount.specialist || [])
            "
          >
            <div class="count-info" *ngIf="doc.email !== currentUser?.email">
              <div class="name">
                {{ doc.name }}
              </div>
              <div class="count">
                {{ doc.count }}
              </div>
            </div>
          </ng-container>
        </mat-tab>
        <mat-tab label="Nurse">
          <div class="count-info" *ngIf="currUserIsNurse">
            <div class="name primary">You</div>
            <div class="count text-orange">
              <span>{{ assignedPatientsCount }}</span>
            </div>
          </div>
          <ng-container class="count-info" *ngFor="let np of patientCount.np">
            <div
              class="count-info"
              *ngIf="
                np.email !== currentUser?.email || currentUser.role !== CCA
              "
            >
              <div class="name">{{ np.name }}</div>
              <div class="count">{{ np.count }}</div>
            </div>
          </ng-container>
          <ng-container class="count-info" *ngFor="let rn of patientCount.rn">
            <div
              class="count-info"
              *ngIf="
                currentUser.role !== 'Nurse' || rn.email !== currentUser?.email
              "
            >
              <div class="name">{{ rn.name }}</div>
              <div class="count">{{ rn.count }}</div>
            </div>
          </ng-container>
          <ng-container
            class="count-info"
            *ngFor="let ccrn of patientCount.ccrn"
          >
            <div
              class="count-info"
              *ngIf="
                currentUser.role !== CCN || ccrn.email !== currentUser?.email
              "
            >
              <div class="name">{{ ccrn.name }}</div>
              <div class="count">{{ ccrn.count }}</div>
            </div>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #assignAll let-modal>
  <div class="dialog-container">
    <div class="dialog-title">
      <img class="warning-icon" src="assets/icons/warning_24px.svg" />
      <b>Attention</b>
      <img
        matDialogClose="cancel"
        class="dialog-close-icon"
        src="assets/icons/close.svg"
      />
    </div>
    <div class="dialog-content">{{ contentToDisplay }}</div>
    <mat-dialog-actions class="dialog-actions">
      <button
        mat-flat-button
        class="button-secondary"
        matDialogClose="cancel"
        color="primary"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="button-primary"
        matDialogClose="yes"
        color="warn"
      >
        {{ actionToDo }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
