import { createReducer, on, Action } from "@ngrx/store";

import * as docActions from "src/app/store/actions/patient-chart/documents/documents.actions";
import * as rootActions from 'src/app/store/actions/root.actions';

export interface State {
    loading: boolean;
    error: any;
    loadType: string;
    showSuccess: boolean;
}

const initialState: State = {
    loading: false,
    error: null,
    loadType: null,
    showSuccess: false
}

const docFormReducer = createReducer(
    initialState,
    on(
        docActions.submitForm,
        (state, {loadType}) => ({...state, loading: true, loadType: loadType})
    ),
    on(
        docActions.submitSuccess,
        (state) => ({...initialState, showSuccess: true})
    ),
    on(
        docActions.hideSuccess,
        (state) => ({...initialState, showSuccess: false})
    ),
    on(
        docActions.submitFail,
        (state, {error}) => ({...initialState, error: error})
    ),
    on(
        rootActions.logout,
        (state) => (initialState)
    ),
    on(
        rootActions.patientChartReset,
        (state) => (initialState)
    )
)

export function reducer(state: State | undefined, action: Action) {
    return docFormReducer(state, action)
}