<!-- carousel -->
<div class="carousel" (mousemove)="showElements()">
  <ng-container *ngFor="let key of slides; let i = index">
    <ng-container *ngIf="i === currentSlide">
      <div
        class="carousel__title carousel__hover display-flex justify-content-between"
        [ngClass]="{ 'carousel__hover--show': isShowElements || isPdfSlide }"
      >
        <span class="display-flex align-items-center">
          <svg
            *ngIf="key.fileType == 'pdf'; else imageLogo"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM11.5 9.5C11.5 10.33 10.83 11 10 11H9V12.25C9 12.66 8.66 13 8.25 13C7.84 13 7.5 12.66 7.5 12.25V8C7.5 7.45 7.95 7 8.5 7H10C10.83 7 11.5 7.67 11.5 8.5V9.5ZM15 13C15.83 13 16.5 12.33 16.5 11.5V8.5C16.5 7.67 15.83 7 15 7H13C12.72 7 12.5 7.22 12.5 7.5V12.5C12.5 12.78 12.72 13 13 13H15ZM20.5 7.75C20.5 8.16 20.16 8.5 19.75 8.5H19V9.5H19.75C20.16 9.5 20.5 9.84 20.5 10.25C20.5 10.66 20.16 11 19.75 11H19V12.25C19 12.66 18.66 13 18.25 13C17.84 13 17.5 12.66 17.5 12.25V8C17.5 7.45 17.95 7 18.5 7H19.75C20.16 7 20.5 7.34 20.5 7.75ZM10 9.5H9V8.5H10V9.5ZM3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20H5C4.45 20 4 19.55 4 19V7C4 6.45 3.55 6 3 6ZM15 11.5H14V8.5H15V11.5Z"
              fill="white"
            />
          </svg>
          <ng-template #imageLogo>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2ZM8 13.51L5.9 10.98C5.69 10.73 5.31 10.74 5.12 11L2.63 14.2C2.37 14.53 2.6 15.01 3.02 15.01H15.01C15.42 15.01 15.66 14.54 15.41 14.21L11.9 9.53C11.7 9.26 11.3 9.26 11.1 9.52L8 13.51Z"
                fill="white"
              />
            </svg>
          </ng-template>
          <span class="ml">{{ key?.name || "Untitled" }}</span>
        </span>
        <span class="carousel__title__slides"
          >{{ currentSlide + 1 }}/{{ slidesLength }}</span
        >
      </div>
      <div class="carousel__slide">
        <app-zoom-pan-container>
          <div
            class="carousel__slideContainer"
            [ngStyle]="{ transform: 'rotate(' + rotation + 'deg)' }"
          >
            <iframe
              *ngIf="key.fileType == 'pdf' && key.pdfUrl; else imageElement"
              [draggable]="false"
              [attr.src]="key.pdfUrl"
              type="application/pdf"
              title="slide pdf"
              class="carousel__slideContainer__iframe"
            ></iframe>
            <ng-template #imageElement>
              <img
                [draggable]="false"
                class="carousel__slideContainer__img"
                *ngIf="key.fileType == 'image' && key.imgData != ''"
                [src]="key.imgData"
                alt="slide image"
              />
            </ng-template>
          </div>
        </app-zoom-pan-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- controls -->
  <div class="carousel__actions display-flex">
    <ng-container *ngIf="slides[currentSlide]">
      <button
        (click)="zoomInImage()"
        matRipple
        matTooltipPosition="above"
        matTooltip="Zoom in"
        class="carousel__buttons"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.9707 14.4716H15.1807L14.9007 14.2016C16.1007 12.8016 16.7207 10.8916 16.3807 8.86157C15.9107 6.08157 13.5907 3.86157 10.7907 3.52157C6.56072 3.00157 3.01072 6.56157 3.52072 10.7916C3.86072 13.5916 6.08072 15.9116 8.86072 16.3816C10.8907 16.7216 12.8007 16.1016 14.2007 14.9016L14.4707 15.1816V15.9716L18.7307 20.2216C19.1407 20.6316 19.8007 20.6316 20.2107 20.2216L20.2207 20.2116C20.6307 19.8016 20.6307 19.1416 20.2207 18.7316L15.9707 14.4716ZM9.97072 14.4716C7.48072 14.4716 5.47072 12.4616 5.47072 9.97157C5.47072 7.48157 7.48072 5.47157 9.97072 5.47157C12.4607 5.47157 14.4707 7.48157 14.4707 9.97157C14.4707 12.4616 12.4607 14.4716 9.97072 14.4716ZM9.47072 7.97157C9.47072 7.69157 9.69072 7.47157 9.97072 7.47157C10.2507 7.47157 10.4707 7.69157 10.4707 7.97157V9.47157H11.9707C12.2507 9.47157 12.4707 9.69157 12.4707 9.97157C12.4707 10.2516 12.2507 10.4716 11.9707 10.4716H10.4707V11.9716C10.4707 12.2516 10.2507 12.4716 9.97072 12.4716C9.69072 12.4716 9.47072 12.2516 9.47072 11.9716V10.4716H7.97072C7.69072 10.4716 7.47072 10.2516 7.47072 9.97157C7.47072 9.69157 7.69072 9.47157 7.97072 9.47157H9.47072V7.97157Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        matRipple
        matTooltipPosition="above"
        matTooltip="Zoom out"
        (click)="zoomOutImage()"
        class="carousel__buttons"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.9716 14.4716H15.1816L14.9016 14.2016C16.1016 12.8016 16.7216 10.8916 16.3816 8.86157C15.9116 6.08157 13.5916 3.86157 10.7916 3.52157C6.56157 3.00157 3.00157 6.56157 3.52157 10.7916C3.86157 13.5916 6.08157 15.9116 8.86157 16.3816C10.8916 16.7216 12.8016 16.1016 14.2016 14.9016L14.4716 15.1816V15.9716L18.7316 20.2216C19.1416 20.6316 19.8016 20.6316 20.2116 20.2216L20.2216 20.2116C20.6316 19.8016 20.6316 19.1416 20.2216 18.7316L15.9716 14.4716ZM9.97157 14.4716C7.48157 14.4716 5.47157 12.4616 5.47157 9.97157C5.47157 7.48157 7.48157 5.47157 9.97157 5.47157C12.4616 5.47157 14.4716 7.48157 14.4716 9.97157C14.4716 12.4616 12.4616 14.4716 9.97157 14.4716ZM12.4716 9.97157C12.4716 9.69157 12.2516 9.47157 11.9716 9.47157H7.97157C7.69157 9.47157 7.47157 9.69157 7.47157 9.97157C7.47157 10.2516 7.69157 10.4716 7.97157 10.4716H11.9716C12.2516 10.4716 12.4716 10.2516 12.4716 9.97157Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        matRipple
        (click)="rotateImageClockwise()"
        class="carousel__buttons"
        matTooltipPosition="above"
        matTooltip="Rotate 90 degrees to the left"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.1525 3.91702C15.4525 3.91702 17.7625 4.79702 19.5125 6.55702C23.0325 10.067 23.0325 15.767 19.5125 19.277C17.7625 21.037 15.4525 21.917 13.1525 21.917C12.1425 21.917 11.1425 21.747 10.1825 21.407C9.5025 21.177 9.3425 20.287 9.8425 19.787C10.1125 19.517 10.5025 19.407 10.8625 19.527C11.6025 19.787 12.3725 19.917 13.1525 19.917C15.1725 19.917 17.1825 19.057 18.6025 17.317C20.6525 14.797 20.6525 11.047 18.6025 8.52702C17.1825 6.77702 15.1725 5.91702 13.1525 5.91702V6.74702C13.1525 7.63702 12.0725 8.07702 11.4525 7.44702L9.6225 5.61702C9.2325 5.22702 9.2325 4.59702 9.6225 4.20702L11.4425 2.37702C12.0725 1.74702 13.1525 2.19702 13.1525 3.08702V3.91702ZM6.0825 7.74702L2.4325 11.407C1.6525 12.187 1.6525 13.457 2.4325 14.237L6.0925 17.897C6.8725 18.677 8.1425 18.677 8.9225 17.897L12.5825 14.247C13.3625 13.467 13.3625 12.197 12.5825 11.417L8.9125 7.73702C8.1225 6.95702 6.8625 6.95702 6.0825 7.74702ZM4.5525 12.107L6.8025 9.85702C7.1925 9.46702 7.8225 9.46702 8.2225 9.85702L10.4625 12.097C10.8525 12.487 10.8525 13.117 10.4625 13.507L8.2125 15.757C7.8225 16.147 7.1925 16.147 6.7925 15.757L4.5525 13.527C4.1625 13.137 4.1625 12.497 4.5525 12.107Z"
            fill="white"
          />
        </svg>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="slides.length > 1">
    <button
      *ngIf="!isFirstSlide"
      mat-raised-button
      class="carousel__control carousel__buttons carousel__hover carousel__hover prev"
      [ngClass]="{ 'carousel__hover--show': isShowElements || isPdfSlide }"
      matTooltipPosition="above"
      matTooltip="Go to the previous slide"
      (click)="onPreviousClick()"
    >
      <span class="carousel__control--arrow left"></span>
    </button>
    <button
      *ngIf="!islastSlide"
      mat-raised-button
      class="carousel__control carousel__hover carousel__buttons next"
      [ngClass]="{ 'carousel__hover--show': isShowElements || isPdfSlide }"
      matTooltipPosition="above"
      matTooltip="Go to the next slide"
      (click)="onNextClick()"
    >
      <span class="carousel__control--arrow right"></span>
    </button>
  </ng-container>
</div>
