import { EMPTY, of } from "rxjs";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from "rxjs/operators";

import { OrderPaneActions } from "../actions/order";
import { OrderableService } from "../../services/orderable.service";
import { SearchService } from "../../services/order-search.service";
import * as OrderActions from "../../store/actions/order/order-main.actions";
import {
  OrderableApiActions,
  OrderableEditPageActions,
  OrderablePageActions,
  OrderableProtocolPageActions,
} from "../actions/orderable";
import { OrderableApiActionTypes } from "../actions/orderable/orderable-api.actions";
import {
  displayErrorMessage,
  displaySuccessMessage,
  emptyAction,
} from "../actions/root.actions";
import { AlertService } from "src/app/iris-components/service/alert.service";

@Injectable()
export class OrderableEffects {
  constructor(
    private orderableService: OrderableService,
    private alertService: AlertService,
    private searchService: SearchService,
    private actions$: Actions,
    private router: Router
  ) {}

  getOrderable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderPaneActions.getOrderable,
        OrderableEditPageActions.getOrderable,
        OrderableProtocolPageActions.getOrderable
      ),
      switchMap(({ id, brand }) =>
        this.orderableService.get(id, brand).pipe(
          take(1),
          mergeMap((data: any) => {
            if (!data.data) {
              return [
                new OrderActions.CloseInputTab(),
                OrderableApiActions.getOrderableFailure({
                  error: "Orderable does not exist",
                }),
              ];
            }
            return [
              OrderableApiActions.getOrderableSuccess({
                orderable: data.data,
              }),
            ];
          }),
          catchError((error: any) =>
            of(
              OrderableApiActions.getOrderableFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  getOrderables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.getOrderables),
      switchMap(({ orderableType }) =>
        this.searchService.search(null, "patient", orderableType).pipe(
          take(1),
          map((data: any) => {
            if (data && data[orderableType]) {
              return OrderableApiActions.getOrderablesSuccess({
                orderables: data[orderableType],
              });
            }

            return OrderableApiActions.getOrderablesSuccess({ orderables: [] });
          }),
          catchError((error: any) =>
            of(
              OrderableApiActions.getOrderablesFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  updateOrderables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.updateOrderables),
      switchMap(({ orderableType }) =>
        this.searchService.search(null, "patient", orderableType).pipe(
          take(1),
          map((data: any) => {
            if (data && data[orderableType]) {
              return OrderableApiActions.updateOrderablesSuccess({
                orderables: data[orderableType],
              });
            }

            return OrderableApiActions.updateOrderablesSuccess({
              orderables: [],
            });
          }),
          catchError((error: any) =>
            of(
              OrderableApiActions.updateOrderablesFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  addOrderable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.addOrderable),
      switchMap(({ orderable }) =>
        this.orderableService.create(orderable).pipe(
          take(1),
          map((data: any) =>
            OrderableApiActions.addOrderableSuccess({ orderable: data.data })
          ),
          catchError((error: any) =>
            of(
              OrderableApiActions.addOrderableFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  updateOrderable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.updateOrderable),
      switchMap(({ id, orderable }) =>
        this.orderableService.edit(id, orderable).pipe(
          take(1),
          map((data: any) =>
            OrderableApiActions.updateOrderableSuccess({ orderable: data.data })
          ),
          catchError((error: any) =>
            of(
              OrderableApiActions.updateOrderableFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  deleteOrderable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.deleteOrderable),
      switchMap(({ id }) =>
        this.orderableService.delete(id).pipe(
          take(1),
          map((data: any) => OrderableApiActions.deleteOrderableSuccess()),
          catchError((error: any) => {
            let msg = "Server Error";

            if (error.error.message !== "Server Error") {
              msg = "Invalid Id";
            }

            return of(
              OrderableApiActions.deleteOrderableFailure({ error: msg })
            );
          })
        )
      )
    )
  );

  setDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.setDefault),
      concatMap(({ data }) =>
        this.orderableService.setDefault(data).pipe(
          take(1),
          map((_) => {
            if (data.displayMessage) {
              return displaySuccessMessage({ message: "Successfully Updated" });
            }

            return emptyAction();
          }),
          catchError(() => of(displayErrorMessage({ message: "Server Error" })))
        )
      )
    )
  );

  orderableSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrderableApiActions.addOrderableSuccess,
          OrderableApiActions.updateOrderableSuccess
        ),
        tap(({ orderable, type }) => {
          let message = "Successfully Created";

          if (type === OrderableApiActionTypes.UpdateOrderableSuccess) {
            message = "Successfully Updated";
          }

          this.alertService.showNotification({
            type: "Success",
            message: message,
          });
        })
      ),
    { dispatch: false }
  );

  orderableFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrderableApiActions.addOrderableFailure,
          OrderableApiActions.updateOrderableFailure,
          OrderableApiActions.getOrderableFailure
        ),
        tap((err) =>
          this.alertService.showNotification(
            {
              type: "Error",
              message: err?.error || "Error Occurred. Try Again",
            },
            "center",
            "bottom",
            3000
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * On successful preset delete shows a message and redirects to preset edit page.
   *
   * Executes on deleteOrderableSuccess action.
   * Issues a message through alertService.
   * Navigates to edit preset page.
   */
  deleteOrderableSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderableApiActions.deleteOrderableSuccess),
        tap(() => {
          this.alertService.showNotification({
            type: "Success",
            message: "Successfully Deleted",
          });
          // this.router.navigate([
          //   "hospital-admin/orderable/edit",
          //   { delete: true },
          // ]);
        })
      ),
    { dispatch: false }
  );

  /**
   * On unsuccessful preset delete shows a error message.
   *
   * Executes on deleteProtocolsFailure action.
   * Issues a error message through alertService.
   */
  deleteOrderableFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderableApiActions.deleteOrderableFailure),
        tap(({ error }) => {
          this.alertService.showNotification({
            type: "Error",
            message: error,
          });
        })
      ),
    { dispatch: false }
  );

  deletePreset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.deletePreset),
      switchMap(({ orderableId, presetId }) =>
        this.orderableService.deletePreset(orderableId, presetId).pipe(
          take(1),
          map(() => displaySuccessMessage({ message: "Successfully Deleted" })),
          catchError(() => of(displayErrorMessage({ message: "Server Error" })))
        )
      )
    )
  );

  setDisplayShortcut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderablePageActions.setDisplayShortcut),
      concatMap(({ data }) =>
        this.orderableService.setDisplayShortcut(data).pipe(
          take(1),
          map((_) => {
            return displaySuccessMessage({ message: "Successfully Updated" });
          }),
          catchError(() => of(displayErrorMessage({ message: "Server Error" })))
        )
      )
    )
  );
}
