<div class="selection-tab" [formGroup]="selectionForm">
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-chip-list class="cp-mat-chips" #selectionList>
      <mat-chip
        class="cp-mat-chips__chip p-1 m-1"
        *ngFor="let selection of matChipListSelectedList"
        [removable]="true"
        (removed)="onSelectionRemoved(selection)"
      >
        {{ selection?.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #selectionInput
        [placeholder]="placeHolder"
        [matChipInputFor]="selectionList"
        (click)="openDropDown()"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addSelection($event)"
        [matAutocomplete]="selectionAutocomplete"
        formControlName="selectionInput"
        [disabled]="disableAutoComplete"
        [ngStyle]="{ height: inputHeight ? inputHeight : '' }"
      />
    </mat-chip-list>
    <mat-autocomplete
      #selectionAutocomplete="matAutocomplete"
      (optionSelected)="selectItem($event)"
    >
      <mat-option
        *ngFor="let selection of filteredSelection$ | async"
        [value]="selection"
        [matTooltip]="
          toolTip_length && selection.name.length > toolTip_length
            ? selection.name
            : ''
        "
        matTooltipClass="cp-mat-tooltip"
      >
        {{ selection.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
