import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserFormService {
  public transformUserForm(userForm: any): any {
    const { countryCode, mobileNumber: phone } = userForm.phone;
    return {
      ...userForm,
      phone,
      countryCode,
    };
  }
}
