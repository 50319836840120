import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-field-errors",
  templateUrl: "./field-errors.component.html",
  styleUrls: ["./field-errors.component.scss"],
})
export class FieldErrorsComponent implements OnInit {
  @Input()
  public formField: UntypedFormControl;

  @Input()
  public customErrorMessage: UntypedFormControl;

  constructor() {}

  ngOnInit(): void {}
}
