import Quill from "quill";
const Inline = Quill.import("blots/inline");

class PlainClipboard extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this["replaceWith"](new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this["remove"]();

    return span;
  }
}

PlainClipboard["blotName"] = "customColor";
PlainClipboard["tagName"] = "FONT";

export default PlainClipboard;
