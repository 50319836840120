import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PatientMonitor } from "../../models/patientMonitor/patientMonitor.model";
import { Injectable } from "@angular/core";

@Injectable()
export class PatientMonitorFormService {
  public getPatientMonitorForm(value: PatientMonitor): UntypedFormGroup {
    const deviceId = value && value.deviceId ? value.deviceId : null;
    const hospitalName =
      value && value.hospitalName ? value.hospitalName : null;
    const unitName = value && value.unitName ? value.unitName : null;

    return new UntypedFormGroup({
      deviceId: new UntypedFormControl(deviceId, [
        Validators.required,
        Validators.maxLength(30),
      ]),
      hospitalName: new UntypedFormControl(hospitalName, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      unitName: new UntypedFormControl(unitName, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
  }
}
