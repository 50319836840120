<div class="patient-card bg-white cp-mb-2">
  <div class="display-flex align-items-center justify-content-start">
    <app-avatar [avatarParams]="patientAvatar"></app-avatar>
    <div class="ml">
      <span class="cp-label-text cp-text-bold"
        >{{ patient.name + " " + (patient?.lastName || "") }}
      </span>
      <div>
        <span>{{ getPatientAge }}</span>
        <span *ngIf="patient.sex === 'O'">
          <fa-icon [icon]="['fas', 'transgender']" title="Other"></fa-icon>
        </span>
        <span *ngIf="patient.sex !== 'O'">
          {{
            patient.sex === "M" ? "Male" : patient.sex === "F" ? "Female" : ""
          }}
        </span>
      </div>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="display-flex align-items-center justify-content-start">
    <div class="display-flex flex-column align-items-center">
      <img
        class="hosp-logo align-self-start"
        [src]="patient.hospitalLogo"
        alt="Hospital Logo"
        onerror="this.onerror = null; this.src='assets/icons/hospital_logo.svg'"
      />
      <div class="cp-small-font cp-color-normal cp-text-normal">
        <div>{{ patient?.hospitalName }}</div>
        <div>{{ patient?.unitName }}</div>
      </div>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="patient-card--sections">
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Blood group:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{
        patient.bloodGroup || "-"
      }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">Height:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{
        patient.height ? getRoundedValue(patient.height) + "cm" : "-"
      }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >{{
          patient?.patientType === PatientType.Neonatal ? "Admn wt" : "Weight"
        }}:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ patAdminWStr }}</span>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="patient-card--sections">
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">Bed no :</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{
        patient.bedNo || "-"
      }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">CPMRN:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{
        patient.CPMRN || "-"
      }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">MRN:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{
        patient.MRN || "-"
      }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Admit date:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal"
        >{{
          patient.ICUAdmitDate
            ? (patient.ICUAdmitDate | timezone: "MMM D Y, HH:mm") +
              " (" +
              (patient.ICUAdmitDate
                | icuDays
                  : !patient?.isCurrentlyAdmitted &&
                      patient?.ICUDischargeDate) +
              ")"
            : "-"
        }}
        <cp-timezone-label></cp-timezone-label
      ></span>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="patient-card--sections">
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Primary MD:
      </span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal"
        >{{ patient.PCP || "-" }}
      </span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Allergies:</span
      >
    </div>
    <div>
      <span class="display-flex flex-wrap cp-small-font cp-color-normal">{{
        patient.allergies || "-"
      }}</span>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="display-flex align-items-center flex-wrap cp-w-75">
    <span
      *ngIf="patient.qsofa !== null"
      class="qSofa cp-badge"
      [ngClass]="{
        'cp-clr-misc-p': patient.qsofa == 1,
        'cp-clr-danger': patient.qsofa >= 2,
        'cp-clr-misc-s': patient.qsofa < 1
      }"
    >
      <strong>qSOFA: {{ patient.qsofa }}</strong>
    </span>
    <span
      *ngIf="rox?.value"
      class="cp-badge cp-inline"
      [ngClass]="{
        'bg-red': rox.color == 'red',
        'bg-green': rox.color == 'green',
        'bg-yellow': rox.color == 'yellow'
      }"
    >
      <strong>ROX: {{ rox?.value }}</strong>
    </span>
    <ng-container *ngFor="let isolation of patient?.isolation">
      <span
        *ngIf="isolation.precautionType && isolation.precautionType !== 'NA'"
        class="cp-badge isolation {{
          isolation.precautionType.toLowerCase() + '-prec'
        }}"
        [cpPopover]="isolation?.reason"
        position="bottom-start"
      >
        <b>{{ isolation?.precautionType }}</b>
      </span>
    </ng-container>
    <span
      class="patient_niv cp-badge primary-bg-light"
      *ngIf="patient.isNIV?.value"
    >
      <strong>NIV</strong>
    </span>

    <span
      class="patient_intubated cp-badge primary-bg-light"
      *ngIf="patient.isIntubated?.value"
    >
      <strong>INTUBATED</strong>
    </span>
    <span
      class="patient_trach cp-badge primary-bg-light mr-1"
      *ngIf="patient.isTrach?.value"
    >
      <strong>Trach</strong>
    </span>
    <span
      *ngIf="
        patient?.patientType == 'adult' &&
        patient.DI?.currentValue &&
        patient.DI.currentValue < patient.DI.currentThreshold
      "
      class="aligning-width cp-badge mr-3"
    >
      <strong>
        <span class="di"> DI: </span>
        <span class="di-text">
          {{ roundValue(patient.DI.currentValue) }}&nbsp;
        </span>
      </strong>

      <img
        *ngIf="patient.DI?.varienceValue > 0"
        src="../../../assets/icons/deterioration_index_up.svg"
      />
      <img
        *ngIf="patient.DI?.varienceValue < 0"
        src="../../../assets/icons/deterioration_index_down.svg"
      />
      <strong
        [ngClass]="{
          'red-text': patient.DI?.varienceValue > 0,
          'green-text': patient.DI?.varienceValue < 0
        }"
      >
        <span class="delta_di_text"
          >&nbsp;{{
            roundValue(
              patient.DI.varienceValue >= 0
                ? patient.DI.varienceValue
                : -patient.DI.varienceValue
            )
          }}</span
        >
      </strong>
    </span>
    <span
      *ngIf="
        patient?.patientType == 'adult' &&
        patient.DI?.currentValue &&
        patient.DI.currentValue >= patient.DI.currentThreshold
      "
      class="aligning-width-text cp-badge mr-3"
    >
      <strong>
        <span class="di"> DI: </span>
        <span class="di-text-red"> Very high </span>
      </strong>
    </span>
    <span
      [cpPopover]="tooltip"
      *ngIf="patient.isPressor"
      position="right-start"
      class="patient_press cp-badge cp-clr-danger"
    >
      <strong>Pressor</strong>
    </span>

    <span
      class="patient_niv cp-badge primary-bg-light mr-1"
      *ngIf="patient?.isHFNC?.value"
    >
      <strong>HFNC</strong>
    </span>
    <span
      class="p-code p-imp-badge cp-badge cp-inline mr-3"
      [ngClass]="
        patient.code === 'Full Code' ? 'cp-clr-comfort' : 'cp-clr-danger'
      "
      *ngIf="patient.code"
    >
      <b>{{ patient.code }}</b>
    </span>
  </div>
</div>
