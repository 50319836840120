<div class="cp-search-input">
  <mat-icon
    [class.active]="showSearch"
    (click)="showSearch = !showSearch; resetSearch(); focusInput()"
    class="search-icon cp-icon"
    >search</mat-icon
  >
  <mat-icon
    class="search-close cp-icon"
    (click)="resetSearch()"
    *ngIf="showSearch && searchText?.length"
    popoverTrigger="hover"
    cpPopover="Clear"
    position="bottom-center"
    >close</mat-icon
  >
  <input
    *ngIf="showSearch"
    #searchInput
    type="text"
    class="form-control"
    id="inputValidation"
    [style.width.rem]="width"
    [placeholder]="placeholder"
    [(ngModel)]="searchText"
    autocomplete="off"
    aria-describedby="search-icon"
    (input)="setInput()"
    (keyup.enter)="onSearch()"
    [disabled]="disabled"
  />
  <ng-container *ngIf="showSearch">
    <ng-content></ng-content>
  </ng-container>
</div>
