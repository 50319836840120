import { Pipe, PipeTransform } from '@angular/core';
import { VitalViewService } from '../../services/vital/vital-view.service';

@Pipe({ name: 'ventAirway' })
export class VentAirwayPipe implements PipeTransform {
  constructor(private _vitalViewService: VitalViewService) {
  }
  /**
   * Transforms vent airway for template
   *
   * @param {string} airway
   * @returns {string} - transformed airway
   */
  transform(airway: string): string {
    return this._vitalViewService.transformAirway(airway);
  }
}
