<div class="reply-message-container display-flex" #replyMessage>
  <mat-icon
    *ngIf="isClose"
    class="reply-message-container__close cp-icon cp-cursor-pointer"
    (click)="closeReply()"
    >close</mat-icon
  >
  <div class="reply-message-container__msg">
    <div class="display-flex flex-column">
      <span class="primary cp-text--normal">{{ name }}</span>
      <span class="cp-mt-1 cp-text-overflow cp-text-overflow--line-2">
        <ng-container [ngSwitch]="fileType">
          <mat-icon
            *ngSwitchCase="fileTypes.VIDEO"
            class="cp-icon cp-cursor-pointer"
            >videocam</mat-icon
          >
          <img
            alt="image icon"
            matRipple
            class="photo-icon cp-cursor-pointer"
            src="assets/icons/photo.svg"
            *ngSwitchCase="fileTypes.IMAGE"
          />
          <img
            matRipple
            alt="pdf icon"
            src="assets/icons/pdf-file.svg"
            *ngSwitchCase="fileTypes.PDF"
            class="reply-message-container__msg__pdf-icon cp-cursor-pointer"
          />
        </ng-container>
        {{ message }}
      </span>
    </div>
  </div>
  <div *ngIf="link">
    <ng-container [ngSwitch]="fileType">
      <img
        *ngSwitchCase="fileTypes.IMAGE"
        [src]="link"
        class="reply-message-container__img"
        alt="image"
      />
      <video
        *ngSwitchCase="fileTypes.VIDEO"
        class="reply-message-container__img"
        [src]="link"
      ></video>
    </ng-container>
  </div>
</div>
