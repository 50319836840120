import SocketIOClient from "socket.io-client";
import { environment } from "src/environments/environment";

export let socket = null;

export function initiateSocketConnection() {
  socket = SocketIOClient(environment.socketUrl, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ["websocket"],
    path: environment.socketPath,
    // query: `token=${localStorage.getItem("token")}&device_info=WEB`,
    query: `device_info=WEB`,
    auth: (cb) => {
      cb({
        token: localStorage.getItem("token"),
      });
    },
  });
  return socket;
}
