<section
  class="fall-risk-container input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form fall-risk-form"
    autocomplete="off"
    [formGroup]="thompsonForm"
    (ngSubmit)="submit(thompsonForm.value)"
  >
    <div class="sub-tittle" *ngIf="!admitFormIsParent">THOMPSON Score</div>
    <hr *ngIf="!admitFormIsParent" />
    <div class="input-form-header">
      <div
        class="score"
        [ngClass]="{
          'table-warning': thompsonScore >= 11 && thompsonScore <= 14,
          'table-danger': thompsonScore >= 15,
          'table-green':
            thompsonScore <= 10 && thompsonScore >= 0 && thompsonScore != null
        }"
      >
        <div>
          {{ thompsonScore == null ? "NA" : thompsonScore }}
        </div>
      </div>
    </div>
    <div class="form_fields">
      <div class="iris-form-row">
        <label for="tone">Tone </label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="tone"
          formControlName="tone"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Hyper</mat-button-toggle>
          <mat-button-toggle [value]="2">Hypo</mat-button-toggle>
          <mat-button-toggle [value]="3">Flaccid</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="conciousness">Level of Consciousness </label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="conciousness"
          formControlName="conciousness"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Hyperalert, Stare</mat-button-toggle>
          <mat-button-toggle [value]="2">Lethargic</mat-button-toggle>
          <mat-button-toggle [value]="3">Comatouse</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="fits">Fits </label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="fits"
          formControlName="fits"
        >
          <mat-button-toggle [value]="0">None</mat-button-toggle>
          <mat-button-toggle [value]="1"
            >Infrequent, &lt;3 per hour</mat-button-toggle
          >
          <mat-button-toggle [value]="2"
            >Frequent&gt;2 per hour</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="posture">Posture</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="posture"
          formControlName="posture"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Fisting, Cycling</mat-button-toggle>
          <mat-button-toggle [value]="2"
            >Strong distal flexion</mat-button-toggle
          >
          <mat-button-toggle [value]="3">Decerbrate</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="moroReflex">Moro Reflex</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="moroReflex"
          formControlName="moroReflex"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Partial</mat-button-toggle>
          <mat-button-toggle [value]="2">Absent</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="grasping">Grasping</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="grasping"
          formControlName="grasping"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Poor</mat-button-toggle>
          <mat-button-toggle [value]="2">Absent</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="sucking">Sucking</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="sucking"
          formControlName="sucking"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Poor</mat-button-toggle>
          <mat-button-toggle [value]="2"
            >Absent &#8723; bites</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="breathing">Breathing</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="breathing"
          formControlName="breathing"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Hyperventilation</mat-button-toggle>
          <mat-button-toggle [value]="2">Brief apnea</mat-button-toggle>
          <mat-button-toggle [value]="3">IPPV (apnea)</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="fontanelle">Fontanelle</label>
        <mat-button-toggle-group
          (change)="onChange()"
          name="fontanelle"
          formControlName="fontanelle"
        >
          <mat-button-toggle [value]="0">Normal</mat-button-toggle>
          <mat-button-toggle [value]="1">Full, not tense</mat-button-toggle>
          <mat-button-toggle [value]="2">Tense</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--Date/Time and Submit button-->
      <div class="date-time-row" *ngIf="!admitFormIsParent">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!thompsonForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'thompson'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
