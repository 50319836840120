import { inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { padStart, sortBy } from "lodash-es";
import { UtilService } from "./util.service";
import { LabOrderFE } from "../models/Lab.model";
import { MedConcentration, MedOrderFE } from "../models/Med.model";
import { DietOrderFE } from "../models/Diet.model";
import { PatientService } from "./patient.service";
import { BloodOrderFE } from "../models/Blood.model";
import { OrderTime } from "../models/OrderTime.model";
import { ProcedureOrderFE } from "../models/Procedure.model";
import { LabPresetBE } from "../models/preset/LabPreset.model";
import { MedPresetBE } from "../models/preset/MedPreset.model";
import { OrderFrequency } from "../models/OrderFrequency.model";
import { DietPresetBE } from "../models/preset/DietPreset.model";
import { BloodPresetBE } from "../models/preset/BloodPreset.model";
import { CommunicationOrderFE } from "../models/Communication.model";
import { ProcedurePresetBE } from "../models/preset/ProcedurePreset.model";
import { CommunicationPresetBE } from "../models/preset/CommunicationPreset.model";
import { frequencyValidator } from "../shared/validators/order-frequency.validator";
import * as fromPatientHeaderReducers from "../store/reducers/patient-chart/patient-header";
import { orderConcentrationValidator } from "../shared/validators/order/order-concentration.validator";
import moment from "moment";
import { UserRolesMap } from "../shared/accessControl/roleInterface";
import { Observable } from "rxjs-compat";
import { ApiResponse } from "../models/api-response.model";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { OrderItemInterface } from "../models/OrderItem.model";
import { of } from "rxjs";
import {
  calculateCurrentWeight,
  CALCULATE_CURRENT_WEIGHT,
} from "../support-functions/calculateWeight";
import { rangeValidators } from "../shared/validators/range.validators";
import { TimezoneService } from "./timezone.service";
import { Moment } from "moment-timezone";
import { valExists } from "../support-functions/util";

function rangeValidator(control: AbstractControl): ValidationErrors | null {
  const quantity = control.get("quantity");
  const maxDose = control.get("maxDose");

  if (!valExists(maxDose.value)) {
    return null;
  }

  return maxDose.value < quantity.value
    ? { range: { value: maxDose.value } }
    : null;
}

@Injectable({ providedIn: "root" })
export class OrderFormService extends UserRolesMap {
  public currentPatient: any;
  private numberRegex = /^\d*$/;
  private _tz = inject(TimezoneService);
  public apiUrl = environment.apiUrl;
  public orderItemsData: OrderItemInterface = null;

  private frequencyDaysValidator = [
    Validators.min(2),
    Validators.pattern(this.numberRegex),
  ];
  private frequencyHourValidator = [
    Validators.min(0),
    Validators.max(47),
    Validators.pattern(this.numberRegex),
  ];
  private frequencyMinuteValidator = [
    Validators.min(0),
    Validators.max(60),
    Validators.pattern(this.numberRegex),
  ];

  /**
   * Creates a new protocol field.
   * In edit, a value is passed and set.
   *
   * @param {string} value
   * @returns {FormControl}
   */
  static newProtocolField(value: string): UntypedFormControl {
    return new UntypedFormControl(value);
  }

  constructor(
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private store: Store<{}>,
    private utilService: UtilService,
    private http: HttpClient
  ) {
    super();
    this.store
      .pipe(select(fromPatientHeaderReducers.getPatHeaderData))
      .subscribe((patient) => {
        this.currentPatient = patient;
      });
  }

  public getMinTime(): string {
    const admitDate = this.currentPatient?.ICUAdmitDate || new Date();
    return moment(admitDate).subtract(180, "d").toISOString();
  }

  public getMaxTime(): string {
    const admitDate = new Date();
    return moment(admitDate).add(30, "d").toISOString();
  }

  // Validation if either one(date/time) exist and the other doesn't then return error
  mutualDateTimeValidator(date: string, hour: string, minute: string) {
    return (group: UntypedFormGroup) => {
      const formDate = group.controls[date];
      const formHour = group.controls[hour];
      const formMin = group.controls[minute];

      if (!!formDate.value) {
        if (!!!formHour.value)
          return {
            endTimeExistent: true,
          };
      } else if (!!formHour.value) {
        if (!!!formDate.value)
          return {
            endDateExistent: true,
          };
      }
    };
  }

  /**
   * Defines a Blood Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {BloodPresetBE | null} [value=null] - BloodPresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newBloodForm(
    value: BloodPresetBE | any = null,
    config: any
  ): UntypedFormGroup {
    let _id = null;
    let title = null;
    let presetName = null;
    let quantity = null;
    let instructions = null;
    let bedsideOrder = null;
    let additionalInformation = null;
    let defaultField = false;
    let protocol = null;
    let sos = false;
    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let urgency = null;
    let scheduleSelector = null;
    let numberOfDoses = null;
    let displayAsShortcut = false;
    let quantityUnit = "units";

    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    if (value) {
      _id = value._id;
      title = value.title;
      presetName = value.presetName;
      quantity = value.quantity;
      quantityUnit = value.quantityUnit;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      defaultField = value.default || false;
      bedsideOrder = value.bedsideOrder;
      protocol = value.protocol;
      sos = value.sos;
      sosReason = value.sosReason;
      urgency = value.urgency;
      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;

      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        timeOfDay = value.frequency.timeOfDay;
        days = value.frequency.days;
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          { value: presetName, disabled: !config.presetName.present },
          [Validators.required, Validators.maxLength(100)]
        ),
        title: this.fb.control(title),
        quantity: this.fb.control(quantity, [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern(/^[0-9]*$/),
          rangeValidators(0),
        ]),
        quantityUnit: this.fb.control(quantityUnit, [
          Validators.required,
          Validators.maxLength(20),
        ]),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        sos: this.fb.control(sos),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            days: this.fb.control(days, this.frequencyDaysValidator),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        numberOfDoses: this.fb.control(numberOfDoses),
        urgency: this.fb.control(urgency),
        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      { validators: [this.endTimeValidator] }
    );
  }

  /**
   * Defines a Communication Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {CommunicationPresetBE | any} [value=null] - CommunicationPresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newCommForm(
    value: CommunicationPresetBE | any = null,
    config: any
  ): UntypedFormGroup {
    let _id = null;
    let title = null;
    let presetName = null;
    let gcs = null;
    let instructions = null;
    let additionalInformation = null;
    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let bedsideOrder = false;
    let defaultField = false;
    let protocol;
    let sos = false;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let urgency = null;
    let scheduleSelector = null;
    let numberOfDoses = null;
    let displayAsShortcut = false;

    if (value) {
      _id = value._id;
      title = value.title;
      presetName = value.presetName;
      gcs = value.gcs;
      instructions = value.instructions;
      bedsideOrder = value.bedsideOrder;
      additionalInformation = value.additionalInformation;
      defaultField = value.default || false;
      protocol = value.protocol;
      sos = value.sos;
      sosReason = value.sosReason;
      urgency = value.urgency;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;

      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        timeOfDay = value.frequency.timeOfDay;
        days = value.frequency.days;
      }

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          { value: presetName, disabled: !config.presetName.present },
          [Validators.required, Validators.maxLength(100)]
        ),
        title: this.fb.control(title),
        gcs: this.fb.control(gcs),
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            days: this.fb.control(days, this.frequencyDaysValidator),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        sos: this.fb.control(sos),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        numberOfDoses: this.fb.control(numberOfDoses),
        urgency: this.fb.control(urgency),
        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      { validators: [this.endTimeValidator] }
    );
  }

  /**
   * Defines a Diet Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {DietPresetBE | any} [value=null] - DietPresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newDietForm(value: DietPresetBE | any = null, config: any): UntypedFormGroup {
    let _id = null;
    let name = null;
    let presetName = null;
    let instructions = null;
    let additionalInformation = null;
    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let defaultField = false;
    let bedsideOrder = false;
    let protocol = null;
    let sos = false;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let urgency = null;
    let scheduleSelector = null;
    let numberOfDoses = null;
    let rate = { value: null, unit: "hr" };
    let displayAsShortcut = false;

    if (value) {
      _id = value._id;
      name = value.name;
      presetName = value.presetName;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      defaultField = value.default || false;
      bedsideOrder = value.bedsideOrder;
      protocol = value.protocol;
      sos = value.sos;
      sosReason = value.sosReason;
      urgency = value.urgency;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;

      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        days = value.frequency.days;
        timeOfDay = value.frequency.timeOfDay;
      }

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }

      if (value.rate) {
        rate.unit = value.rate.unit;
        rate.value = value.rate.value;
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          { value: presetName, disabled: !config.presetName.present },
          [Validators.required, Validators.maxLength(100)]
        ),
        name: this.fb.control(name),
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            days: this.fb.control(days, this.frequencyDaysValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        sos: this.fb.control(sos),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        numberOfDoses: this.fb.control(numberOfDoses),
        urgency: this.fb.control(urgency),
        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        rate: this.fb.group({
          value: this.fb.control(rate.value),
          unit: this.fb.control(rate.unit),
        }),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      { validators: [this.endTimeValidator] }
    );
  }

  /**
   * Defines a Lab Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {LabPresetBE | any} [value=null] - LabPresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newLabForm(value: LabPresetBE | any = null, config = null): UntypedFormGroup {
    let _id = null;
    let presetName = null;
    let investigation = null;
    let discipline = null;
    let specimenType = null;
    let instructions = null;
    let additionalInformation = null;
    let defaultField = false;
    let bedsideOrder = false;
    let protocol = null;
    let sos = false;
    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let urgency = null;
    let scheduleSelector = null;
    let numberOfDoses = null;
    let displayAsShortcut = false;

    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    if (value) {
      _id = value._id;
      presetName = value.presetName;
      investigation = value.investigation;
      discipline = value.discipline;
      specimenType = value.specimenType;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      defaultField = value.default || false;
      bedsideOrder = value.bedsideOrder;
      protocol = value.protocol;
      sos = value.sos;
      sosReason = value.sosReason;
      urgency = value.urgency;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;

      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        timeOfDay = value.frequency.timeOfDay;
        days = value.frequency.days;
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          {
            value: presetName || "New Preset",
            disabled: !config.presetName.present,
          },
          [Validators.required, Validators.maxLength(100)]
        ),
        investigation: this.fb.control(investigation, [
          Validators.maxLength(500),
        ]),
        discipline: this.fb.control(discipline, [Validators.maxLength(100)]),
        specimenType: this.fb.control(specimenType),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            days: this.fb.control(days, this.frequencyDaysValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        sos: this.fb.control(sos),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        numberOfDoses: this.fb.control(numberOfDoses),
        urgency: this.fb.control(urgency),
        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      { validators: [this.endTimeValidator] }
    );
  }

  /**
   * Defines a med Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {MedPresetBE | null} [value=null] - MedPresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newMedForm(
    value: MedPresetBE | MedOrderFE | any = null,
    config = null
  ): UntypedFormGroup {
    let _id = null;
    let brandName = null;
    let presetName = null;
    let name = null;
    let quantity = null;
    let unit = null;
    let pta = null;
    let bedsideOrder = false;
    let route = null;
    let numberOfDoses = null;
    let form = null;
    let urgency = null;
    let scheduleSelector = null;
    const combination = new UntypedFormArray([]);
    let instructions = null;
    let additionalInformation = null;
    let defaultField = false;
    let protocol = null;
    let sos = false;
    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let concentration = { value: null, unit: null };
    let maxDose = null;
    let doseRange = false;
    let bodyWeight = null;
    let totalDose = null;
    let displayAsShortcut = false;
    let noOfDays = null;
    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    const currentWeightPayload: CALCULATE_CURRENT_WEIGHT = {
      weightHistory: this.currentPatient?.weightHistory,
      weightObj: this.currentPatient?.weightObj,
      patientType: this.currentPatient?.patientType,
      weightInGrams: false,
      weight: this.currentPatient?.weight,
      addUnits: false,
    };
    bodyWeight = calculateCurrentWeight(currentWeightPayload);

    if (value) {
      _id = value._id;
      presetName = value.presetName;
      brandName = value.brandName;
      name = value.name;
      quantity = value.quantity;
      unit = value.unit;
      route = value.route;
      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      noOfDays = (value.noOfDays && parseInt(value.noOfDays)) || null;
      form = value.form;
      pta = value.pta;
      bedsideOrder = value.bedsideOrder;
      urgency = value.urgency;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      sosReason = value.sosReason;
      defaultField = value.default || false;
      protocol = value.protocol;
      sos = value.sosReason ? true : value.sos;
      maxDose = value.maxDose;
      doseRange = value.maxDose;
      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      if (value.bodyWeight) {
        bodyWeight = value.bodyWeight;
      }
      totalDose = value?.totalDose;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        days = value.frequency.days;
        timeOfDay = value.frequency.timeOfDay;
      }

      if (value.combination && config.comb.present) {
        for (const comb of value.combination) {
          combination.push(
            this.newCombinationFormGroup(
              comb.name,
              comb.quantity,
              comb.unit,
              comb?.concentration
            )
          );
        }
      }

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }

      if (value.concentration) {
        concentration.value = value.concentration.value;
        concentration.unit = value.concentration.unit;
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          { value: presetName, disabled: !config.presetName.present },
          [Validators.required, Validators.maxLength(100)]
        ),
        brandName: this.fb.control(brandName, [Validators.maxLength(100)]),
        name: this.fb.control(name, [Validators.maxLength(500)]),
        quantity: this.fb.control(quantity, [
          Validators.required,
          rangeValidators(0),
        ]),
        unit: this.fb.control(unit, [Validators.required]),
        route: this.fb.control(route, [Validators.required]),
        numberOfDoses: this.fb.control(numberOfDoses),
        noOfDays: this.fb.control(noOfDays, [Validators.pattern(/[0-9]*/)]),
        form: this.fb.control(form),
        urgency: this.fb.control(urgency),

        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        pta: this.fb.control({ value: pta, disabled: !config.pta.present }),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        combination: combination,
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            days: this.fb.control(days, this.frequencyDaysValidator),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        sos: this.fb.control(sos),
        concentration: this.fb.group({
          value: this.fb.control(concentration.value),
          unit: this.fb.control(concentration.unit),
        }),
        doseRange: this.fb.control(doseRange),
        maxDose: this.fb.control(maxDose),
        bodyWeight: this.fb.control(bodyWeight),
        totalDose: this.fb.control(totalDose),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      {
        validators: [
          rangeValidator,
          orderConcentrationValidator(!config?.bodyWeight?.present),
          this.endTimeValidator,
        ],
      }
    );
  }

  /**
   * Defines a procedure Order Form.
   * During edit, a value is passed and set to necessary fields.
   *
   * @param {ProcedurePresetBE | any} [value=null] - ProcedurePresetBE value used in editing
   * @param {any} config [value=null]
   * @returns {FormGroup}
   */
  newProcedureForm(
    value: ProcedurePresetBE | any = null,
    config = null
  ): UntypedFormGroup {
    let _id = null;
    let presetName = null;
    let name = null;
    let pType = null;
    let site = null;
    let laterality = null;
    let instructions = null;
    let additionalInformation = null;
    let defaultField = false;
    let bedsideOrder = false;
    let protocol = null;
    let sos = false;
    const protocols = new UntypedFormArray([]);
    let startTime = { date: null, hour: null, minute: null };
    let endTime = { date: null, hour: null, minute: null };
    let urgency = null;
    let scheduleSelector = null;
    let numberOfDoses = null;
    let displayAsShortcut = false;

    let fType = "every";
    let hours = null;
    let mins = null;
    let days = null;
    let timeOfDay = null;
    let sosReason = null;
    let period = null;
    let periodOption = null;
    let startNow = null;
    let doseList = [];

    if (value) {
      _id = value._id;
      presetName = value.presetName;
      name = value.name;
      pType = value.pType;
      site = value.site;
      laterality = value.laterality;
      instructions = value.instructions;
      additionalInformation = value.additionalInformation;
      defaultField = value.default || false;
      bedsideOrder = value.bedsideOrder;
      protocol = value.protocol;
      sos = value.sos;
      sosReason = value.sosReason;
      urgency = value.urgency;
      scheduleSelector = value.scheduleSelector;
      period = value.timePeriodDetail?.period;
      periodOption = value.timePeriodDetail?.periodOption;
      startNow = value.startNow;

      numberOfDoses =
        (value.numberOfDoses && parseInt(value.numberOfDoses)) || null;
      displayAsShortcut = value.displayAsShortcut
        ? value.displayAsShortcut
        : false;

      startTime = this.getStartDate(
        value.startTime,
        value.urgency,
        value.scheduleSelector
      );
      endTime = this.getTimeObj(value.endTime);

      if (value.protocols) {
        for (const id of value.protocols) {
          protocols.push(OrderFormService.newProtocolField(id));
        }
      }

      if (value.frequency) {
        fType = value.frequency.fType;
        hours = this.hourHack(value.frequency.hours);
        mins = value.frequency.mins;
        days = value.frequency.days;
        timeOfDay = value.frequency.timeOfDay;
      }
    }

    const skipScheduleFormArray = this.getSkipScheduleValue(value);

    return new UntypedFormGroup(
      {
        _id: this.fb.control({ value: _id, disabled: !config._id.present }),
        presetName: this.fb.control(
          { value: presetName, disabled: !config.presetName.present },
          [Validators.required, Validators.maxLength(100)]
        ),
        name: this.fb.control(name, [Validators.maxLength(500)]),
        pType: this.fb.control(pType),
        site: this.fb.control(site, [Validators.required]),
        laterality: this.fb.control(laterality),
        bedsideOrder: this.fb.control({
          value: bedsideOrder,
          disabled: !config.bedsideOrder.present,
        }),
        instructions: this.fb.control(instructions, [
          Validators.maxLength(5000),
        ]),
        patientType: config.patientType.present
          ? this.fb.control(value?.patientType, [Validators.required])
          : this.fb.control({ value: "", disabled: true }),
        additionalInformation: this.fb.control(additionalInformation, [
          Validators.maxLength(5000),
        ]),
        default: this.fb.control({
          value: defaultField,
          disabled: !config.default.present,
        }),
        protocols: config.default.present
          ? protocols
          : this.fb.control({ value: "", disabled: true }),
        startTime: config.startTime.present
          ? this.fb.group({
              date: this.fb.control(
                { value: startTime.date, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              hour: this.fb.control(
                { value: startTime.hour, disabled: urgency == "STAT" },
                [Validators.required]
              ),
              minute: this.fb.control(
                { value: startTime.minute, disabled: urgency == "STAT" },
                [Validators.required]
              ),
            })
          : this.fb.control({ value: "", disabled: true }),
        endTime: config.endTime.present
          ? this.fb.group(
              {
                date: this.fb.control(endTime.date),
                hour: this.fb.control(endTime.hour),
                minute: this.fb.control(endTime.minute),
              },
              {
                validators: this.mutualDateTimeValidator(
                  "date",
                  "hour",
                  "minute"
                ),
              }
            )
          : this.fb.control({ value: "", disabled: true }),
        protocol: this.fb.control(protocol),
        sos: this.fb.control(sos),
        frequency: this.fb.group(
          {
            fType: this.fb.control(fType, [Validators.required]),
            hours: this.fb.control(hours, this.frequencyHourValidator),
            mins: this.fb.control(mins, this.frequencyMinuteValidator),
            days: this.fb.control(days, this.frequencyDaysValidator),
            timeOfDay: this.fb.control(timeOfDay),
          },
          { validators: frequencyValidator }
        ),
        sosReason: this.fb.control(sosReason, [Validators.maxLength(5000)]),
        everyFreRadio: this.fb.control(
          hours ? `${hours}` : fType == "once" ? "once" : null
        ),
        numberOfDoses: this.fb.control(numberOfDoses),
        urgency: this.fb.control(urgency),
        scheduleSelector: this.fb.control(scheduleSelector),
        timePeriodDetail: this.fb.group({
          period: this.fb.control(period),
          periodOption: this.fb.control(periodOption),
        }),
        startNow: this.fb.control({
          value: this.getStartNow(startNow, urgency),
          disabled: urgency == "STAT",
        }),
        skipSchedule: config.startTime.present
          ? skipScheduleFormArray
          : this.getSkipFormArray(null),
        displayAsShortcut: this.fb.control({
          value: displayAsShortcut,
          disabled: !config.displayAsShortcut.present,
        }),
      },
      { validators: [this.endTimeValidator] }
    );
  }

  /**
   * Creates a comb med form.
   * Fields are pre filled if any values are passed.
   *
   * @param {string} [name=''] - Name of med
   * @param {string|number} [quantity = ''] - Quantity of med
   * @param {string} [unit=''] - Unit of med
   * @returns {FormGroup}
   */
  public newCombinationFormGroup(
    name: string = "",
    quantity: string | number = "",
    unit: string = "",
    concentration?: MedConcentration
  ): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        name: new UntypedFormControl(name, [
          Validators.required,
          // Validators.maxLength(100),
        ]),
        quantity: new UntypedFormControl(quantity, [
          Validators.required,
          rangeValidators(0),
        ]),
        unit: new UntypedFormControl(unit, [Validators.required]),
        concentration: new UntypedFormGroup({
          value: new UntypedFormControl(concentration?.value),
          unit: new UntypedFormControl(concentration?.unit),
        }),
      },
      {
        validators: [orderConcentrationValidator(true)],
      }
    );
  }

  /**
   * In few order types there is a field which is same as orderableName.
   * These fields cannot be editted, so this method is used to return that field with default name.
   * Used when a form resets.
   *
   * @param {string} type
   * @param {string} value
   * @returns {Object}
   */
  public getFieldObjectRelatedToOrderableName(type: string, value: string) {
    if (type === "blood") {
      return { title: value };
    } else if (type === "comm") {
      return { title: value };
    } else if (type === "diet") {
      return { name: value };
    } else if (type === "lab") {
      return { investigation: value };
    } else if (type === "med") {
      return { name: value };
    } else if (type === "procedure") {
      return { pType: value };
    }
  }

  public getFieldRelatedToOrderableName(type: string): string {
    if (type === "blood") {
      return "title";
    } else if (type === "comm") {
      return "title";
    } else if (type === "diet") {
      return "name";
    } else if (type === "lab") {
      return "investigation";
    } else if (type === "med") {
      return "name";
    } else if (type === "procedure") {
      return "pType";
    }
  }

  /**
   * Combines date and time into one dateTime value.
   */
  public transformTime(date: Date, hour: string, minute: string): Date {
    const transformedDateTz = this._tz.transformIntoTimezoneObj(date);
    transformedDateTz.hours(+hour);
    transformedDateTz.minutes(+minute);

    return transformedDateTz.toDate();
  }

  /**
   * Calculate order based on role and form value.
   *
   * @param {string} role
   * @param {BloodOrderFE | CommunicationOrderFE | DietOrderFE | LabOrderFE | MedOrderFE | ProcedureOrderFE} value
   */
  public getOrderCategory(role: string, value: any): string {
    if (value.bedsideOrder) {
      return "active";
    } else if (value.pta) {
      return "pta";
    } else if (
      [
        this.NURSE.toLocaleLowerCase(),
        this.PHARMACOLOGIST.toLocaleLowerCase(),
        this.DIETITIAN.toLocaleLowerCase(),
        this.CCA.toLowerCase(),
        this.CCN.toLocaleLowerCase(),
        this.PA.toLocaleLowerCase(),
      ].includes(role?.toLowerCase())
    ) {
      return "pending";
    }

    return "active";
  }

  /**
   * Get Scheduled checkboxes
   * Note: Reason for passing it manually is because on edit schedule checkboxes would be disabled and form don't send disabled values
   * @param {FormGroup} orderForm
   *
   */
  public getScheduleChekboxes(orderForm: UntypedFormGroup): {
    startNow: boolean;
    skipSchedule: { skip: boolean; timeStamp: string; _id: string }[];
  } {
    if (!orderForm && !(orderForm instanceof UntypedFormGroup)) {
      console.warn("Pass param as Form Group for getScheduleChekbox function");
      return {
        startNow: null,
        skipSchedule: null,
      };
    }
    const startNow = orderForm?.get("startNow");
    const skipSchedule = orderForm?.get("skipSchedule");
    return {
      startNow: startNow?.value,
      skipSchedule: skipSchedule?.value,
    };
  }

  /**
   * Get order signed value.
   *
   * @param {string} name
   * @param {string} title
   * @returns {string}
   */
  getOrderSigned(name: string, title: string): string {
    if (title) {
      return `${title} ${name}`;
    } else {
      return `${name}`;
    }
  }

  /**
   * Calculate form submit button text
   *
   * @param role
   * @returns {string}
   */
  getFormSubmitText(role: string): string {
    if (
      [
        this.NURSE.toLocaleLowerCase(),
        this.PHARMACOLOGIST.toLocaleLowerCase(),
        this.DIETITIAN.toLocaleLowerCase(),
        this.CCA.toLocaleLowerCase(),
        this.CCN.toLocaleLowerCase(),
        this.PA.toLocaleLowerCase(),
      ].includes(role?.toLocaleLowerCase())
    ) {
      return "Pend";
    }

    return "Sign";
  }

  generateMedSchedule(time: any, frequency: any, dose: string | number): any {
    const medSchedule = [];

    if (!time) return medSchedule;
    const date = this.transformTime(time.date, time.hour, time.minute);
    const dateMs = date.getTime();

    medSchedule.push(new Date(dateMs));

    if (dose == 1) {
      return medSchedule;
    }

    const frequencyMs = frequency.hours * 3600000 + frequency.mins * 60000;

    medSchedule.push(new Date(dateMs + frequencyMs));

    if (dose == 2) {
      return medSchedule;
    }

    medSchedule.push(new Date(dateMs + 2 * frequencyMs));

    return medSchedule;
  }

  validEveryFrequency(frequency): boolean {
    return (
      (frequency.hours || frequency.mins) &&
      ((frequency.mins && this.numberRegex.test(frequency.mins)) ||
        frequency.mins === "" ||
        !frequency.mins) &&
      ((frequency.hours && this.numberRegex.test(frequency.hours)) ||
        frequency.hours === "" ||
        !frequency.hours)
    );
  }

  validStartTime(startTime): boolean {
    return (
      typeof startTime?.date === "object" &&
      typeof startTime?.hour === "string" &&
      typeof startTime?.minute === "string"
    );
  }

  private getStartDate(
    startNowDate: Date,
    urgency: "STAT" | "ASAP",
    scheduleSelector = null
  ): { date: Date; hour: number; minute: number } {
    if (startNowDate) return this.getTimeObj(startNowDate);
    const isStartNow = urgency == "STAT";
    let returnDate = null;
    if (isStartNow) {
      returnDate = this._tz.getCurrentTimestampStr();
    }
    if (scheduleSelector && !isStartNow) {
      returnDate = this.generateTimeStampList({
        scheduleSelector,
      })[0];
    }
    return this.getTimeObj(returnDate);
  }

  private getStartNow(startNowCheck, urgency: "STAT" | "ASAP"): boolean {
    if (startNowCheck || urgency != "STAT") return startNowCheck;

    return startNowCheck || true;
  }

  private getTimeObj(dateTime: Date): {
    date: Date;
    hour: number;
    minute: number;
  } {
    const dayObj = { date: null, hour: null, minute: null };
    if (!dateTime) return dayObj;
    const tzDate = this._tz.transformIntoTimezoneObj(dateTime);
    dayObj.date = tzDate;
    dayObj.hour = padStart(tzDate.hours(), 2, 0);
    dayObj.minute = padStart(tzDate.minutes(), 2, 0);
    return dayObj;
  }

  public getSkipScheduleValue(value): UntypedFormArray {
    if (!value) return this.getSkipFormArray();
    const { scheduleSelector, skipSchedule, urgency } = value;
    if (value.skipSchedule?.length) return this.getSkipFormArray(skipSchedule);
    const timestampList = this.generateTimeStampList({
      scheduleSelector,
    });
    const tranformedList = timestampList.reduce(
      (finalObj, timeStamp) => [...finalObj, { timeStamp, skip: false }],
      []
    );
    return this.getSkipFormArray(tranformedList);
  }

  private getSkipFormArray(skipSchedule = null) {
    const skipScheduleFormArray = this.fb.array([]);
    if (skipSchedule?.length) {
      skipSchedule.forEach(({ timeStamp, skip }) => {
        skipScheduleFormArray.push(
          this.fb.group({
            timeStamp: this.fb.control(timeStamp),
            skip: this.fb.control(skip),
          })
        );
      });
    }
    return skipScheduleFormArray;
  }

  public generateTimeStampList({
    scheduleSelector,
    startTime = this._tz.getCurrentTimeObj(),
    formType = "new",
    doseLimit = 3,
  }) {
    let timeStampList = [];
    let availableTimeSlots = this.getAvailableTimeSlots(scheduleSelector);
    const currentTimeObj = this._tz.getCurrentTimeObj();
    if (!availableTimeSlots?.length) return [];

    let day = 0;
    while (availableTimeSlots.length && day < 5) {
      availableTimeSlots?.forEach((t) => {
        timeStampList.push(
          moment(startTime > currentTimeObj ? startTime : currentTimeObj)
            .startOf("d")
            .add(day, "day")
            .set({ hour: t, m: 0, s: 0 })
        );
      });

      day += 1;
    }

    timeStampList = timeStampList
      .map((s) => moment(s, "DD/MM/YYYY HH:mm"))
      .filter((m) => {
        if (startTime) {
          return m.isSameOrAfter(currentTimeObj, "m");
        } else {
          return m.isSameOrAfter(this._tz.getCurrentTimeObj(), "m");
        }
      })
      .sort((a, b) =>
        moment(a, "DD/MM/YYYY HH:mm").diff(moment(b, "DD/MM/YYYY HH:mm"))
      );

    if (
      startTime &&
      formType !== "edit" &&
      !currentTimeObj.isSame(timeStampList[0], "m")
    ) {
      timeStampList.unshift(currentTimeObj);
    }

    const comparisonValues = timeStampList.map((v) => v.valueOf());
    timeStampList = timeStampList.filter(
      (v, i) => comparisonValues.indexOf(v.valueOf()) == i
    );

    const limit = doseLimit < 3 && doseLimit > 0 ? doseLimit : 3;
    return timeStampList.slice(0, limit);
  }

  private getAvailableTimeSlots(scheduleSelected) {
    let availableTimeSlots = [];
    if (!scheduleSelected) return;

    scheduleSelected?.split("-").forEach((time) => {
      const hour = +time.slice(0, 2);
      availableTimeSlots.push(hour);
    });

    return sortBy(availableTimeSlots);
  }

  /*
   * NAME: getPresetForm
   * PURPOSE: Generates and returns a preset form based on type.
   * DESCRIPTION: Creates a form based on type.
   *     Config is used to control the form behaviour(fields which are present, etc.)
   * PARAMS:
   *   preset        - predefined preset value which is passes to form if available
   *   presetType: string  - type of form to fetch (blood, lab, etc)
   *   config        - config which is passed to form
   * RETURNS: FormGroup - new form created
   * USED BY: RADAR
   * CREATED DATE: 3/10/2019
   * AUTHOR: Gunjit
   */
  getPresetForm(
    preset = null,
    presetType: string,
    config: any
  ): UntypedFormGroup {
    if (presetType === "blood") {
      return this.newBloodForm(preset, config);
    } else if (presetType === "comm") {
      return this.newCommForm(preset, config);
    } else if (presetType === "diet") {
      return this.newDietForm(preset, config);
    } else if (presetType === "lab") {
      return this.newLabForm(preset, config);
    } else if (presetType === "med") {
      return this.newMedForm(preset, config);
    } else if (presetType === "procedure") {
      return this.newProcedureForm(preset, config);
    }
  }

  /*
   * NAME: calculateEndTime
   * PURPOSE: calculates endTime for endTime field is med form
   * DESCRIPTION: update depends upon frequency, startTime & numberOfDoses
   *   - if frequency is 'once', then endtime is exactly 1 day greater than startTime
   *   - if frequency is 'every', then endTime is the time at which final dose should occur
   *   -  eg. if hours = 24. mins = 0, doses = 2, then endTime is 2 days greater than startTime
   *   - if frequency is continuous and days is present then days is added to start time.
   * PARAMS:
   *   - frequency:OrderFrequency - med frequency (contains fType, hours, mins, timeofDay)
   *   - startTime:OrderTime - med startTime (contains date and time)
   *   - doses:number - number of doses that is required
   * RETURNS: Date | null
   * USED BY: med-form.component.ts - setEndTime() method
   * CREATED DATE: 17 October 2019
   * AUTHOR: Gunjit Agrawal
   */
  calculateEndTime(
    frequency: OrderFrequency,
    startTime: OrderTime,
    doses: number,
    days: number = null
  ): Moment | null {
    if (!frequency?.fType || !startTime) {
      return null;
    } else if (
      frequency?.fType === "every" &&
      !doses &&
      (!frequency.hours || !frequency.mins)
    ) {
      return null;
    }

    const startTimeDate = this.transformTime(
      startTime.date,
      startTime.hour,
      startTime.minute
    );

    if (frequency?.fType === "once") {
      //edited by jitesh on 19th oct 2019 ,causes wrong time in io and breaks summary
      // startTimeDate.setDate(startTimeDate.getDate() + 1);

      // return startTimeDate;
      return null;
    } else if (frequency?.fType === "every" && doses) {
      const startTimeInMS = startTimeDate.getTime();
      doses -= 1;
      const frequencyInMS =
        (+frequency.hours * 60 + +frequency.mins) * 60 * 1000 * +doses;

      const endDte = new Date(startTimeInMS + frequencyInMS);
      return this._tz.transformIntoTimezoneObj(endDte);
    } else if (frequency?.fType === "continuous" && days) {
      const startTimeInMS = startTimeDate.getTime();
      const daysInMs = days * 24 * 60 * 60 * 1000;
      const endDte = new Date(startTimeInMS + daysInMs);
      return this._tz.transformIntoTimezoneObj(endDte);
    }

    return null;
  }

  hourHack(hours) {
    if (hours === "NULL") {
      return null;
    }

    return hours;
  }

  getOrderItems(): Observable<OrderItemInterface> {
    if (this.orderItemsData) return of(this.orderItemsData);
    return this.http
      .get<ApiResponse>(`${this.apiUrl}api/v1/radar/config`)
      .map((data: ApiResponse) => {
        this.orderItemsData = data.data;
        return data.data;
      });
  }

  // Get EndTime validator
  public get endTimeValidator() {
    return this.utilService.endTimeValidator(this._tz.patientTimeZone());
  }
}
