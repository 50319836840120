import { createAction, props } from "@ngrx/store";
import { User } from "src/app/models/user";
import { Unit } from "src/app/models/hospital";

export const getPatients = createAction(
  "[Patient List] get patients",
  props<{ email: String }>()
);

export const setPatients = createAction(
  "[Patient List] set patients",
  props<{ patients: any; referrals?: any[] }>()
);

export const setManyPatients = createAction(
  "[Patient List] set many patients",
  props<{ patients: any }>()
);

export const setManyReferral = createAction(
  "[Patient List] set many refferal patients",
  props<{ referrals: any[] }>()
);

export const setReferalIds = createAction(
  "[Patient List] set referalIds",
  props<{ ids: string[] }>()
);

export const setOnePatient = createAction(
  "[Patient List] set one patient",
  props<{ patient: any }>()
);

export const removeOnePatient = createAction(
  "[Patient List] remove one patient",
  props<{ patient: any }>()
);

export const resetpatients = createAction("[Patient List] reset patients");

export const setDrafts = createAction(
  "[Patient List] set drafts",
  props<{ draftArray: any; patients: any }>()
);

/**
Future enchancement: assign shift nurse and doc both call a different api.
The api logic is same for both it can be merged to form a single api. As of now mobile depends on both.
 */

// Assign shift
export const assignShift = createAction(
  "[Shift assignment] assign a unit to user",
  props<{
    shift?: { unit?: any; currentUser?: any; timestamp?: any };
  }>()
);

// Assign all shift to doctor/nurse
export const assignAllShift = createAction(
  "[Shift assignment] assign all units to a user",
  props<{ hospitals?: string[]; role: string; name: string }>()
);

// Assign all workspace
export const assignAllWorkspace = createAction(
  "[Shift assignment] assign workspace to a user",
  props<{
    workspace?: { workspaceId: string; isAssign: boolean };
  }>()
);

export const assignShiftSuccess = createAction(
  "[Shift assignment] success in assigning a unit to user",
  props<{ unitId: string }>()
);

export const assignAllShiftSuccess = createAction(
  "[Shift assignment] success in assigning all units to user",
  props<{ name?: string }>()
);

export const assignAllWorkspaceSuccess = createAction(
  "[Shift assignment] success in assigning a workspace to user",
  props<{ workspaceId: string }>()
);
