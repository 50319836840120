import { createReducer, on, Action } from "@ngrx/store";

import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  readReciepts: any;
  chatStatus: {
    chatSubmitStart: boolean;
    chatSubmitSuccess: boolean;
  };
  docImageKey: any;
  showNotification: boolean;
  chatTabOpen: boolean;
  minimised: boolean;
  draftText: string;
}

const initialState: State = {
  readReciepts: [],
  chatStatus: {
    chatSubmitStart: false,
    chatSubmitSuccess: false,
  },
  docImageKey: null,
  showNotification: false,
  chatTabOpen: false,
  minimised: true,
  draftText: "",
};

const chatViewReducer = createReducer(
  initialState,
  on(chatActions.submitChatStart, (state) => {
    return {
      ...state,
      chatStatus: {
        chatSubmitStart: true,
        chatSubmitSuccess: false,
      },
    };
  }),
  on(chatActions.submitChatSuccess, (state) => {
    return {
      ...state,
      chatStatus: {
        chatSubmitStart: false,
        chatSubmitSuccess: true,
      },
    };
  }),
  on(chatActions.resetForm, (state) => initialState),
  on(chatActions.addToDoc, (state, { imageData }) => {
    return {
      ...state,
      docImageKey: imageData,
    };
  }),
  on(chatActions.clearDocFile, (state) => {
    return {
      ...state,
      docImageKey: {},
    };
  }),
  on(chatActions.setLastSeen, (state, { user, lastChat }) => {
    let readArray = state.readReciepts;

    if (lastChat) {
      let foundUserIndex = readArray.findIndex((rUser) => {
        return rUser["email"] == user.email;
      });

      if (foundUserIndex >= 0) {
        readArray[foundUserIndex].lastRead = lastChat.timestamp;
        readArray[foundUserIndex].name = lastChat.name;
      } else {
        readArray.push({
          name: user.name,
          email: user.email,
          lastRead: lastChat.timestamp,
        });
      }
    }

    return {
      ...state,
      readReciepts: [...readArray],
    };
  }),
  on(chatActions.setReadReciepts, (state, { readReciepts }) => {
    return {
      ...state,
      readReciepts: [...readReciepts],
    };
  }),
  on(chatActions.updateReadReciepts, (state, { readReciept }) => {
    let foundRecieptIndex = state.readReciepts.findIndex((reciept) => {
      return readReciept.email == reciept.email;
    });

    if (foundRecieptIndex >= 0) {
      state.readReciepts[foundRecieptIndex] = { ...readReciept };
    } else {
      state.readReciepts.push(readReciept);
    }

    return state;
  }),
  on(chatActions.showNotification, (state) => ({
    ...state,
    showNotification: true,
  })),
  on(chatActions.hideNotification, (state) => ({
    ...state,
    showNotification: false,
  })),
  on(chatActions.saveDraftChat, (state, { message }) => ({
    ...state,
    draftText: message,
  })),
  on(chatActions.setChatMinimised, (state) => {
    let status = state.minimised;
    return { ...state, minimised: !status };
  }),
  on(chatActions.openChatTab, (state) => {
    return { ...state, chatTabOpen: true };
  }),
  on(chatActions.closeChatTab, (state) => {
    return { ...state, chatTabOpen: false };
  }),
  on(rootActions.logout, (state) => initialState),
  on(
    rootActions.patientChartReset,
    chatActions.resetChat,
    (state) => initialState
  )
);

export function reducer(state: State | undefined, action: Action) {
  return chatViewReducer(state, action);
}
