<div
  class="cp-mat-search-dropdown-container"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
>
  <mat-select
    [formControl]="multiSelectInput"
    panelClass="cp-mat-search-dropdown"
    [class]="inputClass"
    #matSelect
    [disableOptionCentering]="true"
    [placeholder]="placeholder"
    (selectionChange)="handleInput($event)"
    [ngClass]="matSelectClass"
    [panelClass]="panelClass"
    (openedChange)="openDropdown($event)"
    [multiple]="multiple"
    [matTooltip]="isOther"
  >
    <div class="search-input">
      <input
        type="text"
        #search
        [formControl]="searchInput"
        [placeholder]="searchPlaceholder"
        matInput
        (keydown)="$event.stopImmediatePropagation()"
      />
    </div>
    <div class="no-results cp-text-overflow-ellipses" *ngIf="isNoResults">
      <span>No results for "{{ searchInput?.value }}"</span>
    </div>
    <mat-select-trigger *ngIf="isMatSelectTrigger">
      <ng-content select="[mat-select-trigger]"></ng-content>
    </mat-select-trigger>
    <ng-container *ngIf="!searchInput.value">
      <mat-option *ngFor="let data of topList" [value]="data?.value">
        <ng-container
          [ngTemplateOutlet]="topOptionTemplate"
          [ngTemplateOutletContext]="{ $implicit: data }"
        ></ng-container>
      </mat-option>
      <mat-divider *ngIf="topList?.length"></mat-divider>
    </ng-container>
    <ng-container *ngFor="let selectData of data; let i = index">
      <mat-option
        *ngIf="selectData"
        [value]="selectData?.value || selectData"
        [hidden]="isOptionHidden(selectData)"
        [disabled]="previousSelectedValues?.includes(selectData.value)"
      >
        <ng-container *ngIf="selectData?.label">
          {{ selectData?.label }}
        </ng-container>
        <ng-container *ngIf="isOptionString(selectData)">
          {{ selectData }}
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="optionTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: selectData }"
        ></ng-container>
      </mat-option>
    </ng-container>
    <ng-content select="[ncp]"></ng-content>
  </mat-select>
</div>
