import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { GROWTH_TABLE_COLUMNS } from "@shared-modules/features/trends/constants/growth-trends.data";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormsService } from "src/app/admit-form/services/forms.service";
import {
  CreatedByEditedBy,
  Patient,
  PatientWeightHistory,
} from "src/app/models/patient";
import { numberWithDotValidator } from "src/app/shared/form-validators";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromPatientFormActions from "src/app/store/actions/patient-chart/patient-header/patient-form.actions";
import { AlertService } from "src/app/iris-components/service/alert.service";
import * as fromUserReducer from "src/app/store/reducers/user";
import { User } from "src/app/models/user";

@Component({
  selector: "app-growth-table",
  templateUrl: "./growth-table.component.html",
  styleUrls: ["./growth-table.component.scss"],
  host: {
    class: "display-flex flex-column w-100",
  },
})
export class GrowthTableComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  public patientHeader$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  public getUser$ = this._store.pipe(
    select(fromUserReducer.getCurrUser),
    takeUntil(this.unsubscribe$)
  );

  currPatient: Patient;
  currentUser: User;
  public tabularTableColumns = GROWTH_TABLE_COLUMNS;
  tableData: any;
  tooltipStore: any;

  get isDirty() {
    if (!this.tableData?.length) return false;
    return this.tableData?.some((wHObj) => wHObj?.formGroup?.dirty);
  }

  get isInvalid() {
    return this.tableData?.some((wHObj) => wHObj?.formGroup?.invalid);
  }

  constructor(
    private _store: Store<any>,
    private _fb: FormBuilder,
    private _formService: FormsService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.patientHeader$.subscribe((data) => {
      this.currPatient = data;
      this.transformWeightHistory(this.currPatient?.weightHistory);
    });

    this.getUser$.subscribe((user) => {
      this.currentUser = user;
    });
  }

  transformWeightHistory(wHistory: PatientWeightHistory[]) {
    if (!wHistory?.length) {
      this.tableData = [];
      return;
    }

    this.initiateTableDataWithInputForm(wHistory);
  }

  initiateTableDataWithInputForm(wHistory) {
    this.tableData = wHistory.map((wHObj) => {
      const formGroup = this.initInputForm(wHObj);

      return {
        ...wHObj,
        formGroup,
        age: Math.floor(
          moment
            .duration(
              moment(wHObj["weightMeasuredTime"]).diff(
                moment(this.currPatient?.dob)
              )
            )
            .asDays()
        ),
      };
    });
  }

  initInputForm(wHObj: PatientWeightHistory) {
    return this._fb.group({
      weight: [
        (wHObj?.weightKg && wHObj?.weightKg * 1000) ?? null,
        Validators.compose([
          Validators.min(1),
          Validators.max(999000),
          numberWithDotValidator,
        ]),
      ],
      length: [
        wHObj?.length ?? null,
        Validators.compose([
          Validators.min(1),
          Validators.max(300),
          numberWithDotValidator,
        ]),
      ],
      headCircumference: [
        wHObj?.headCircumference ?? null,
        Validators.compose([
          Validators.min(1),
          Validators.max(100),
          numberWithDotValidator,
        ]),
      ],
    });
  }

  updateWeightHistory() {
    const updatedWHistory: PatientWeightHistory[] = this.tableData?.map(
      (wHObj) => {
        const isCurrFormDirty = wHObj?.formGroup?.dirty;
        const formGroupValue = isCurrFormDirty ? wHObj?.formGroup?.value : null;
        const updatedBy: CreatedByEditedBy = isCurrFormDirty && {
          at: moment().toISOString(),
          email: this.currentUser?.email,
          name: this.currentUser?.name,
        };
        return {
          weightKg:
            (formGroupValue?.weight && +formGroupValue?.weight / 1000) ??
            wHObj?.weightKg,
          length:
            (formGroupValue?.length && +formGroupValue?.length) ??
            wHObj?.length,
          headCircumference:
            (formGroupValue?.headCircumference &&
              +formGroupValue?.headCircumference) ??
            wHObj?.headCircumference,
          weightMeasuredTime: wHObj?.weightMeasuredTime,
          weightUnit: wHObj?.weightUnit,
          createdBy: wHObj?.createdBy,
          updatedBy: updatedBy ?? wHObj?.updatedBy,
        } as PatientWeightHistory;
      }
    );
    const transformedProperties = this._formService.transformUpdateData({
      weightHistory: updatedWHistory,
    });
    const payload = {
      CPMRN: this.currPatient.CPMRN,
      encounters: this.currPatient.encounters,
      patient: transformedProperties,
    };

    this._store.dispatch(
      fromPatientFormActions.updatePatient({
        payload,
        nextPage: 0,
        isFormComplete: false,
      })
    );

    this._alertService.showNotification({
      type: "Success",
      message: "Edited successfully",
    });
  }

  resetForm() {
    this.selectedRowsForEdit = [];
    this.initiateTableDataWithInputForm(this.currPatient?.weightHistory);
  }

  selectedRowsForEdit: number[] = [];
  tableRowClick(index) {
    this.selectedRowsForEdit = this.selectedRowsForEdit?.includes(index)
      ? this.selectedRowsForEdit
      : [...this.selectedRowsForEdit, index];
  }

  get anyInputIsEmptyFromRow() {
    if (!this.selectedRowsForEdit?.length) return true;

    return this.selectedRowsForEdit?.some((rowIndex) => {
      const tableRow = this.tableData[rowIndex];

      return !Object.values(tableRow?.formGroup?.value)?.some((value) => value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
