import { EMPTY, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DocumentService } from 'src/app/services/document.service';
import * as documentsActions from '../actions/patient-chart/documents/documents.actions';
import { select, Store } from '@ngrx/store';
import * as fromFile from '../reducers/file';

@Injectable()
export class DocumentsEffects {
  constructor(
    private actions$: Actions,
    private documentsService: DocumentService,
    public store: Store<{}>
  ) {
  }

  getDocuments$ = createEffect(() => this.actions$.pipe(
    ofType(documentsActions.getDocuments),
    mergeMap(({ CPMRN, encounters }) => this.documentsService.getDocuments(CPMRN, encounters).pipe(
      map(documents => {
        return documentsActions.setDocuments({ docs: documents['documents'] });
      }),
      catchError(() => EMPTY)
    ))
  ));

  addDocument$ = createEffect(() => this.actions$.pipe(
    ofType(documentsActions.addDocToDb),
    mergeMap(({ doc }) => this.documentsService.addDocument(doc['CPMRN'], doc['encounters'], doc['doc']).pipe(
      map(doc => {
        return documentsActions.submitSuccess();
      }),
      catchError((error) => of({ type: documentsActions.submitFail.type, payload: error.error.message }))
    ))
  ));

  removeDocument$ = createEffect(() => this.actions$.pipe(
    ofType(documentsActions.removeDocument),
    mergeMap(({ doc }) => this.documentsService.removeDocument(doc['CPMRN'], doc['encounters'], doc['doc']).pipe(
      map(doc => {
        // console.log(doc);
        return documentsActions.submitSuccess();
      }),
      catchError((error) => of({ type: documentsActions.submitFail.type, payload: error.error.message }))
    ))
  ));

  sendVisionTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.sendVisionTask),
      switchMap(({ data, fileIds }) =>
        this.store.pipe(
          select(fromFile.getUploadedFileKeysForVisionTask, fileIds),
          take(1),
          switchMap((keys) => of(
            documentsActions.createTask({ task: { ...data, input: keys } }),
            documentsActions.sendTaskThroughSocket({ task: { ...data, input: [ ...keys, ...data.input ], clientId: data.id } })
            )
          )
        )
      )
    )
  );
}
