import { Store } from '@ngrx/store';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import * as fromAlert from '../reducers';
import { RAlertMessageListPageActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class RalertMessageListResolverService implements Resolve<any> {
  constructor(private _store: Store<fromAlert.State>) {
  }

  resolve(): void {
    this._store.dispatch(RAlertMessageListPageActions.loadRAlertMessages());
  }
}
