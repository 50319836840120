import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { SnappFormService } from "../services/snapp-form.service";
import {
  APGAR_SCORE_OPTION,
  BIRTH_WEIGHT_OPTION,
  GESTATION_AGE_TO_3rd_PERCENTILE_B_WEIGHT_MAP,
  LOWEST_SERUM_OPTIONS,
  LOWEST_TEMPERATURE_OPTIONS,
  MEAN_BLOOD_PRESSURE_OPTIONS,
  MULTIPLE_SEIZURES_OPTIONS,
  PO2_FIO2_OPTIONS,
  SMALL_FOR_GESTATION_AGE_OPTION,
  SNAPP_II_EXPECTED_DEATH_MAP_FOR_LESS_1500_BIRTH_WEIGHT,
  SNAPP_II_EXPECTED_DEATH_MAP_FOR_MORE_THAN_1500_BIRTH_WEIGHT,
  URINE_OUTPUT_OPTION,
} from "../constants";
import { takeUntil } from "rxjs/operators";
import { Vitals } from "src/app/vitals/models/Vital";
import { Store, select } from "@ngrx/store";
import * as fromVitalReducer from "../../../../store/reducers";
import * as vitalActions from "../../../../store/actions/vitals.actions";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { calculateWeight } from "src/app/support-functions/calculateWeight";
import { CALCULATE_WEIGHT } from "src/app/support-functions/calculateWeight";
import { findDropdownValueFrom3rdValue } from "src/app/vitals/support/vitals.support";
import { valExists } from "src/app/support-functions/util";

@Component({
  selector: "app-snapp-score",
  templateUrl: "./snapp-score.component.html",
  styleUrls: ["./snapp-score.component.scss"],
})
export class SnappScoreComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();

  @Input() set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;

      // set form if it's a new to get data of latest gestation/weight/gender/temperature/baseExcess values
      if (this.isFormToBeFilledFromCurrentPatient) this.setInitialsForSNAPP();
    }
  }

  @Input() set clickedDay(day: Vitals | null) {
    if (day) {
      this.isFormToBeFilledFromCurrentPatient = false;
      this.setFormValues(day);
    } else {
      this.isFormToBeFilledFromCurrentPatient = true;
    }
  }

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  public vitalData$ = this.store.pipe(
    select(fromVitalReducer.getVitaldays),
    takeUntil(this.unsubscribe$)
  );

  @Input() minDate;
  @Input() maxDate;

  vitals: Vitals[] = [];
  currentPatient;
  isFormToBeFilledFromCurrentPatient: boolean = false;
  snappForm = this._snappFormService.initSnappForm();
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  get snap2Score() {
    return this.snappForm.get("snap2Score")?.value;
  }

  get snappe2Score() {
    return this.snappForm.get("snappe2Score")?.value;
  }

  get expectedDeath() {
    return this.snappForm.get("expectedDeathPercentage")?.value;
  }

  get gestationAgeControl() {
    return this.snappForm.get("gestationalAge") as FormControl;
  }

  constructor(
    private _snappFormService: SnappFormService,
    private store: Store<{}>,
    private _alertService: AlertService
  ) {
    this.vitalData$.subscribe((data) => {
      this.vitals = data;
    });
    this.initListeners();
  }

  get meanBloodPressureList() {
    return MEAN_BLOOD_PRESSURE_OPTIONS;
  }

  get lowestTempList() {
    return LOWEST_TEMPERATURE_OPTIONS;
  }

  get po2Fio2List() {
    return PO2_FIO2_OPTIONS;
  }

  get lowestSerumList() {
    return LOWEST_SERUM_OPTIONS;
  }

  get multipleSeizuresList() {
    return MULTIPLE_SEIZURES_OPTIONS;
  }

  get urineOutputList() {
    return URINE_OUTPUT_OPTION;
  }

  get apgarScoreList() {
    return APGAR_SCORE_OPTION;
  }

  get birthWeightList() {
    return BIRTH_WEIGHT_OPTION;
  }

  get smallForGestationAgeList() {
    return SMALL_FOR_GESTATION_AGE_OPTION;
  }

  get gestationTo3rdPercentileMap() {
    return GESTATION_AGE_TO_3rd_PERCENTILE_B_WEIGHT_MAP;
  }

  get gesationAgeList() {
    return Object.keys(this.gestationTo3rdPercentileMap).map((val) =>
      parseInt(val)
    );
  }

  get birthWeight() {
    const weightPayload: CALCULATE_WEIGHT = {
      weightObj: this.currentPatient?.birthWeightObj,
      patientType: this.currentPatient?.patientType,
      weightInGrams: true,
      addUnits: false,
    };
    return calculateWeight(weightPayload) || null;
  }

  ngOnInit(): void {
    // this.initListeners();
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "SNAPPE II score updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });
    this.resetVitalTimestamp();
  }

  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.snappForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initListeners() {
    this.snappForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!Object.values(data).some((val) => valExists(val))) return;

        const snap2Score =
          data?.meanBP +
          data?.lowestTemp +
          data?.po2Fio2Ratio +
          data?.lowestSerumPh +
          data?.multipleSeizures +
          data?.urineOutput;

        const snappe2Score =
          snap2Score +
          data?.apgarScore +
          data?.birthWeight +
          data?.smallForGestationalAge;

        this.snappForm.patchValue(
          { snap2Score, snappe2Score },
          { emitEvent: false }
        );
        this.calculateAndUpdateExpectedDeath();
      });

    this.gestationAgeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data) return;

        this.populateSmallForGestationalAge(data);
      });
  }

  setInitialsForSNAPP() {
    const snappGestation = Math.ceil(
      this.currentPatient?.gestationAge?.weeks +
        this.currentPatient?.gestationAge?.days / 7
    );
    const snappBWeight = this.birthWeight;
    const snappAPGAR = this.findLatestAPGAR();

    const bWeightDropdownValue = findDropdownValueFrom3rdValue(
      BIRTH_WEIGHT_OPTION,
      snappBWeight
    );
    const apgarDropdownValue = findDropdownValueFrom3rdValue(
      APGAR_SCORE_OPTION,
      snappAPGAR
    );

    this.snappForm.patchValue({
      apgarScore: (apgarDropdownValue && apgarDropdownValue.value) ?? null,
      birthWeight: (bWeightDropdownValue && bWeightDropdownValue.value) ?? null,
      gestationalAge: snappGestation,
    });
  }

  findLatestAPGAR() {
    const latestAPGAR = this.vitals?.find((vit) => vit.apgar);
    return latestAPGAR ? latestAPGAR?.apgar?.apgarScore : null;
  }

  populateSmallForGestationalAge(gAge) {
    const _3rdPercentileBWeight =
      GESTATION_AGE_TO_3rd_PERCENTILE_B_WEIGHT_MAP[gAge];
    const dropdownValue =
      this.birthWeight >= (_3rdPercentileBWeight ?? 0)
        ? SMALL_FOR_GESTATION_AGE_OPTION[0]
        : SMALL_FOR_GESTATION_AGE_OPTION[1];
    this.snappForm.patchValue({
      smallForGestationalAge: dropdownValue.value,
    });
  }

  calculateAndUpdateExpectedDeath() {
    if (this.snappe2Score == null) return;
    const isBirthWLess = +this.birthWeight < 1500;
    const deathObject = findDropdownValueFrom3rdValue(
      isBirthWLess
        ? SNAPP_II_EXPECTED_DEATH_MAP_FOR_LESS_1500_BIRTH_WEIGHT
        : SNAPP_II_EXPECTED_DEATH_MAP_FOR_MORE_THAN_1500_BIRTH_WEIGHT,
      this.snappe2Score
    );
    const expectedDeath = deathObject ? deathObject?.value : null;
    this.snappForm.patchValue(
      {
        expectedDeathPercentage: expectedDeath,
      },
      { emitEvent: false }
    );
  }

  setFormValues(vitalData: Vitals | null) {
    const data = vitalData?.snap2;
    this.snappForm.patchValue(data);
    this.snappForm.get("timestamp").setValue(vitalData.timestamp);
  }

  submitSnapp(formValue) {
    this.store.dispatch(vitalActions.submitForm({ loadType: "snappe-2" }));
    const snapp2Props = {
      timestamp: new Date(formValue.timestamp),
      snap2: {
        meanBP: formValue?.meanBP,
        lowestTemp: formValue?.lowestTemp,
        po2Fio2Ratio: formValue?.po2Fio2Ratio,
        lowestSerumPh: formValue?.lowestSerumPh,
        multipleSeizures: formValue?.multipleSeizures,
        urineOutput: formValue?.urineOutput,
        snap2Score: formValue?.snap2Score,
        apgarScore: formValue?.apgarScore,
        birthWeight: formValue?.birthWeight,
        gestationalAge: formValue?.gestationalAge,
        smallForGestationalAge: formValue?.smallForGestationalAge,
        expectedDeathPercentage: formValue?.expectedDeathPercentage,
        snappe2Score: formValue?.snappe2Score,
      },
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: snapp2Props },
      })
    );
  }

  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.snappForm.reset({ emitEvent: false });

    this.formState.error = null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
