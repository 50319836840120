import * as d3 from 'd3';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BarChartConfig, BarChartData, Margin, Title, XAxis, YAxis } from './bar-chart.model';

@Component({
  selector: 'app-bar-chart',
  template: '<div #canvas class="canvas"></div>'
})
export class BarChartComponent implements OnInit, AfterViewInit {
  // Initialize flag to check if the graph is drawn if so, just update the values else draw it on the canvas
  private isDrawn = false;
  private defaultColor = 'black';

  @ViewChild('canvas', { static: true })
  public _canvas: ElementRef;

  private _canvasWidth = 600;
  set canvasWidth(canvasWidth) {
    this._canvasWidth = canvasWidth;
  };
  get canvasWidth() {
    return this._canvasWidth;
  };

  private _canvasHeight = 600;
  set canvasHeight(canvasHeight) {
    this._canvasHeight = canvasHeight;
  };
  get canvasHeight() {
    return this._canvasHeight;
  };

  private _margin: Margin = {
    top: 10,
    right: 10,
    left: 100,
    bottom: 50
  };
  set margin(marginSize: Margin) {
    if (marginSize && marginSize.top != null && marginSize.top != undefined)
      this._margin.top = marginSize.top;
    if (marginSize && marginSize.bottom != null && marginSize.bottom != undefined)
      this._margin.bottom = marginSize.bottom;
    if (marginSize && marginSize.left != null && marginSize.left != undefined)
      this._margin.left = marginSize.left;
    if (marginSize && marginSize.right != null && marginSize.right != undefined)
      this._margin.right = marginSize.right;
  };
  get margin() {
    return this._margin;
  };

  private _color: string = this.defaultColor;
  set color(color: string) {
    if (color != null && color != undefined)
      this._color = color;
  };
  get color() {
    return this._color;
  };

  private _xAxisConfig: XAxis = {
    range: [0, 500],
    paddingInner: 0.5,
    paddingOuter: 0.5,
    text: {
      fontColor: this.defaultColor
    },
    title: {
      x: 0,
      y: 0,
      fontSize: `${0}px`,
      fontWeight: 0,
      titleAndGraphSpacing: 0,
      fontColor: this.defaultColor
    }
  };
  set xAxisConfig(axis: XAxis) {
    if (axis && axis.range && axis.range.length == 2) {
      if (axis.range[0] != null && axis.range[0] != undefined &&
        axis.range[1] != null && axis.range[1] != undefined) {
        this._xAxisConfig.range = axis.range;
      }
    }

    if (axis && axis.paddingInner != null && axis.paddingInner != undefined)
      this._xAxisConfig.paddingInner = axis.paddingInner;

    if (axis && axis.paddingOuter != null && axis.paddingOuter != undefined)
      this._xAxisConfig.paddingOuter = axis.paddingOuter;

    if (axis && axis.text != null && axis.text != undefined) {
      if (axis.text.name != null && axis.text.name != undefined)
        this._xAxisConfig.text.name = axis.text.name;
      if (axis.text.fontColor != null && axis.text.fontColor != undefined)
        this._xAxisConfig.text.fontColor = axis.text.fontColor;
      if (axis.text.fontWeight != null && axis.text.fontWeight != undefined)
        this._xAxisConfig.text.fontWeight = axis.text.fontWeight;
      if (axis.text.fontSize != null && axis.text.fontSize != undefined)
        this._xAxisConfig.text.fontSize = axis.text.fontSize;
    }

    if (axis && axis.title) {
      if (axis.title.name != null && axis.title.name != undefined) {
        this._xAxisConfig.title.name = axis.title.name;
        this._xAxisConfig.title.x = this.canvasWidth / 2;
        this._xAxisConfig.title.y = 30;
        this._xAxisConfig.title.fontSize = `${25}px`;
        this._xAxisConfig.title.titleAndGraphSpacing = 10;
        this._xAxisConfig.title.fontColor = this.defaultColor;
      }
      if (axis.title.x != null && axis.title.x != undefined)
        this._xAxisConfig.title.x = axis.title.x;
      if (axis.title.y != null && axis.title.y != undefined)
        this._xAxisConfig.title.y = axis.title.y;
      if (axis.title.fontSize != null && axis.title.fontSize != undefined)
        this._xAxisConfig.title.fontSize = `${axis.title.fontSize}px`;
      if (axis.title.fontWeight != null && axis.title.fontWeight != undefined)
        this._xAxisConfig.title.fontWeight = axis.title.fontWeight;
      if (axis.title.titleAndGraphSpacing != null && axis.title.titleAndGraphSpacing != undefined)
        this._xAxisConfig.title.titleAndGraphSpacing = axis.title.titleAndGraphSpacing;
      if (axis.title.fontColor != null && axis.title.fontColor != undefined)
        this._xAxisConfig.title.fontColor = axis.title.fontColor;
    }
  };
  get xAxisConfig() {
    return this._xAxisConfig;
  };

  private _yAxisConfig: YAxis = {
    ticks: 5,
    text: {
      fontColor: this.defaultColor
    }
  };
  set yAxisConfig(axis: YAxis) {
    if (axis && axis.ticks != null && axis.ticks != undefined && axis.ticks != 0)
      this._yAxisConfig.ticks = axis.ticks;
    if (axis && axis.text != null && axis.text != undefined) {
      if (axis.text.name != null && axis.text.name != undefined)
        this._yAxisConfig.text.name = axis.text.name;
      if (axis.text.fontColor != null && axis.text.fontColor != undefined)
        this._yAxisConfig.text.fontColor = axis.text.fontColor;
      if (axis.text.fontWeight != null && axis.text.fontWeight != undefined)
        this._yAxisConfig.text.fontWeight = axis.text.fontWeight;
      if (axis.text.fontSize != null && axis.text.fontSize != undefined)
        this._yAxisConfig.text.fontSize = axis.text.fontSize;
    }
  };
  get yAxisConfig() {
    return this._yAxisConfig;
  };

  private _transition = { duration: 500 };
  set transition(transition: { duration: number }) {
    if (transition != null && transition != undefined)
      this._transition.duration = transition.duration;
  };
  get transition() {
    return this._transition;
  };

  private _title: Title = {
    x: 0,
    y: 0,
    fontSize: `${10}px`,
    fontWeight: 0,
    titleAndGraphSpacing: 0,
    fontColor: this.defaultColor,
  };
  set title(title: Title) {
    if (title) {
      if (title.name != null && title.name != undefined) {
        this._title.name = title.name;
        this._title.x = 0;
        this._title.y = 30;
        this._title.fontSize = `${25}px`;
        this._title.titleAndGraphSpacing = 10;
        this._title.fontColor = this.defaultColor;
      }
      if (title.x != null && title.x != undefined)
        this._title.x = title.x;
      if (title.y != null && title.y != undefined)
        this._title.y = title.y;
      if (title.fontSize != null && title.fontSize != undefined)
        this._title.fontSize = `${title.fontSize}px`;
      if (title.fontWeight != null && title.fontWeight != undefined)
        this._title.fontWeight = title.fontWeight;
      if (title.titleAndGraphSpacing != null && title.titleAndGraphSpacing != undefined)
        this._title.titleAndGraphSpacing = title.titleAndGraphSpacing;
      if (title.fontColor != null && title.fontColor != undefined)
        this._title.fontColor = title.fontColor;
    }
  };
  get title() {
    return this._title;
  };

  private _config;
  @Input()
  set config(config: BarChartConfig) {
    if (config.canvasWidth != null && config.canvasWidth != undefined)
      this.canvasWidth = config.canvasWidth;
    if (config.canvasHeight != null && config.canvasHeight != undefined)
      this.canvasHeight = config.canvasHeight;
    this.margin = config.margin;
    this.xAxisConfig = config.xAxis;
    this.yAxisConfig = config.yAxis;
    this.transition = config.transition;
    this.color = config.color;
    this.title = config.title;
    this._config = config;
  };
  get config() {
    return this._config;
  };

  // dummy data
  // private _data: BarChartData[] = [
  //   { value: 100, name: "Veg Soup" },
  //   { value: 900, name: "Veg Stew" },
  //   { value: 501, name: "Veg Burger" },
  //   { value: 100, name: "Veg Special" }
  // ];

  private _data: BarChartData[] = [];
  @Input()
  set data(data: BarChartData[]) {
    if (data.length) {
      this._data = data;
      if (this.isDrawn) {
        this.update(data);
      } else {
        this.draw();
        this.update(data);
      }
    }
  };
  get data() {
    return this._data;
  };

  // reference
  svg;
  graph;

  graphWidth = 0;
  graphHeight = 0;

  x; // => is to define scale, range, padding inner and outer of the bands
  y; // => is to define scale and range for the bands
  xAxis; // =>  is to define alignment ,tick and tickformate of each data
  yAxis; // =>  is to define alignment ,tick and tickformate of each data
  xAxisGroup; // groups both x and xAxis 
  yAxisGroup; // groups both y and yAxis 
  xAxisTitleGroup;

  constructor() { };

  ngOnInit() {
    this.draw();
  };

  draw() {
    if (this.isDrawn === false) {
      this.margin.top = d3.max([this.margin.top, this.title.y]);
      this.margin.bottom = d3.max([this.margin.bottom, this.xAxisConfig && this.xAxisConfig.title && this.xAxisConfig.title.y]);

      this.svg = d3.select(this._canvas.nativeElement)
        .append('svg')
        .attr('width', this.canvasWidth)
        .attr('height', this.canvasHeight);

      this.graphWidth = this.canvasWidth - this.margin.right - this.margin.left;
      this.graphHeight = this.canvasHeight - this.margin.top - this.margin.bottom - this.title.titleAndGraphSpacing - this.xAxisConfig.title.titleAndGraphSpacing;

      this.graph = this.svg.append('g')
        .attr('width', this.graphWidth)
        .attr('height', this.graphHeight)
        .attr('transform', `translate(${this.margin.left}, ${this.margin.top + this.title.titleAndGraphSpacing})`);

      this.svg.append('g')
        .append("text")
        // .attr("transform", "translate(100,0)")
        .attr("x", this.title.x)
        .attr("y", this.title.y)
        .attr("font-size", this.title.fontSize)
        .attr("font-weight", this.title.fontWeight)
        .text(this.title.name)
        .attr('fill', this.title.fontColor)

      if (this.xAxisConfig && this.xAxisConfig.title && this.xAxisConfig.title.name) {
        this.xAxisTitleGroup =  this.svg.append('g')
          .append("text")
          .attr("x", this.canvasWidth / 2)
          .attr("y", this.canvasHeight - (this.margin.bottom / 2))
          // .attr("x", (this.xAxisConfig.title.x)? this.xAxisConfig.title.x : (this.canvasWidth / 2))
          // .attr("y", (this.xAxisConfig.title.y)? this.xAxisConfig.title.y : (this.canvasHeight - (this.margin.bottom/2)))
          .attr("font-size", this.xAxisConfig.title.fontSize)
          .attr("font-weight", this.xAxisConfig.title.fontWeight)
          .text(this.xAxisConfig.title.name)
          .attr('fill', this.xAxisConfig.title.fontColor)
      }

      this.xAxisGroup = this.graph.append('g')
        .attr('transform', `translate(0,${this.graphHeight})`);
      this.yAxisGroup = this.graph.append('g');

      this.y = d3.scaleLinear()
        .range([this.graphHeight, 0]);
      this.x = d3.scaleBand()
        .range(this._xAxisConfig.range)
        .paddingInner(this._xAxisConfig.paddingInner)
        .paddingOuter(this._xAxisConfig.paddingOuter);

      this.xAxis = d3.axisBottom(this.x);
      this.yAxis = d3.axisLeft(this.y)
        .ticks(this.yAxisConfig.ticks)
      // .tickFormat(tickName => tickName + ' orders');
      this.isDrawn = true;
    }
  };

  // for animation
  widthTween = (data) => {
    // Interpolate: need to define startind and ending position of the interpolate's width
    // this will return a ticking function where the values range from 0-1
    const i = d3.interpolate(0, this.x.bandwidth());

    // return a funtion with return's a time ticker 't'
    // this function will be called recursivly during the tansition
    return function (t) {
      // return the interpolated time ticker value
      return i(t);
    }
  };

  // each time the data changes. UI need to re-render the bars
  update = (data: BarChartData[]) => {
    let max = d3.max(data, d => d.value);
    if(max <= 10) max = 10;

    this.y.domain([0, max]);
    this.x.domain(data.map(x => x.name));

    const rectangles = this.graph.selectAll('rect').data(data);

    rectangles.exit().remove();

    // tansition requires starting and ending states, that's why we set the height and y to starting position then to ending position.
    rectangles.attr('width', this.x.bandwidth)
      .attr('fill', this.color)
      .attr('x', d => this.x(d.name))

    // unlike above we need to define starind positions since the above tags are already in the dom we dont need it but for the 
    // newly created ones we do need that's why we set the height and y to starting position then to ending position.
    rectangles.enter()
      .append('rect')
      .attr('width', this.x.bandwidth)
      .attr('height', 0)
      .attr('fill', this.color)
      .attr('x', d => this.x(d.name))
      .attr('y', this.graphHeight)
      .merge(rectangles) // merges all the attributes below it to the tags which are already in the dom by their selector
      .transition().duration(this.transition.duration)
      .attrTween('width', this.widthTween)
      .attr('y', d => this.y(d.value))
      .attr('height', d => this.graphHeight - this.y(d.value));

    this.xAxisGroup.call(this.xAxis);
    this.yAxisGroup.call(this.yAxis);

    if (this.xAxisConfig && this.xAxisConfig.title && this.xAxisConfig.title.name) {
      this.xAxisTitleGroup.text(this.xAxisConfig.title.name);
    }

    this.xAxisGroup.selectAll('text')
      // .attr('transform', `rotate(-40)`)
      // .attr('text-anchor', 'end')
      .attr('fill', this.xAxisConfig.text.fontColor);

    this.yAxisGroup.selectAll('text')
      .attr('fill', this.yAxisConfig.text.fontColor);
  };

  ngAfterViewInit() {
    this.update(this.data);
  };
}
