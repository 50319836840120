<section class="assessment-view-section">
  <ng-container
    *ngIf="
      vitals | vitalDay: selectedDay | removeBlankEntries: assessmentKeys;
      let assessmentArray
    "
  >
    <ng-container
      *ngIf="
        currPatient.patientType === PatientType.Neonatal;
        else adultPedBlock
      "
    >
      <!-- CRIB II Table -->

      <table class="pain-view-table" *ngIf="hasCRIBIIScores(assessmentArray)">
        <thead>
          <tr>
            <th>CRIB II</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.CRIB)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.CRIB &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">CRIB II Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div class="vital_val verify_this">
                {{ vit?.daysCrib?.daysScore }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End CRIB II Table -->

      <!-- APGAR Table -->

      <table
        class="pain-view-table margin-top-25"
        *ngIf="hasAPGARScores(assessmentArray)"
      >
        <thead>
          <tr>
            <th>APGAR</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.APGAR)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.APGAR &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">APGAR Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div class="vital_val verify_this">
                {{ vit?.apgar?.apgarScore }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End APGAR Table -->

      <!-- SNAPP II Table -->

      <table
        class="pain-view-table margin-top-25"
        *ngIf="hasSNAPPEIIScores(assessmentArray)"
      >
        <thead>
          <tr>
            <th>SNAPPE II</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.SNAPP)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.SNAPP &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">SNAPPE II Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div class="vital_val verify_this">
                {{ vit?.snap2?.snappe2Score }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End SNAPP II Table -->

      <!-- THOMPSON Table -->

      <table
        class="pain-view-table margin-top-25"
        *ngIf="hasTHOMPSONScores(assessmentArray)"
      >
        <thead>
          <tr>
            <th>THOMPSON</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.THOMPSON)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.THOMPSON &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">THOMPSON Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning':
                    vit?.thompson?.thompsonScore >= 11 &&
                    vit?.thompson?.thompsonScore <= 14,
                  'table-danger': vit?.thompson?.thompsonScore >= 15
                }"
              >
                {{ vit?.thompson?.thompsonScore }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End THOMPSON Table -->
    </ng-container>
    <ng-template #adultPedBlock>
      <!-- Pain Table -->

      <table class="pain-view-table">
        <thead>
          <tr>
            <th>Pain</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.PAIN)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.PAIN &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">Visual Analog Pain Scale</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning': vit.daysPain >= 3 && vit.daysPain < 8,
                  'table-danger': vit.daysPain >= 8
                }"
              >
                {{ vit?.daysPain }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End Pain Table -->

      <!-- RASS Table -->

      <table class="pain-view-table margin-top-25">
        <thead>
          <tr>
            <th>RASS</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.RASS)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.RASS &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rassProp of rassAssessmentProps; index as j">
            <td>
              <div class="vital-value-heading">{{ rassProp?.show }}</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <ng-container
                *ngIf="rassProp.val == 'daysRassCAMICU'; else rassScoreBlock"
              >
                <div
                  class="vital_val verify_this"
                  [ngClass]="{
                    'table-danger':
                      rassProp.val === 'daysRassCAMICU' &&
                      vit[rassProp.val] == true
                  }"
                >
                  <span *ngIf="vit['daysRassCAMICU'] === true">Positive</span>
                  <span *ngIf="vit['daysRassCAMICU'] === false">Negative</span>
                </div>
              </ng-container>
              <ng-template #rassScoreBlock>
                <div class="vital_val verify_this">
                  {{ vit[rassProp.val] }}
                </div>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End RASS Table  -->

      <!-- Fall Table -->

      <table class="pain-view-table margin-top-25">
        <thead>
          <tr>
            <th>Fall</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.FALL)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.FALL &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning': vit.daysFalls > 25 && vit.daysFalls <= 45,
                  'table-danger': vit.daysFalls > 45
                }"
              >
                {{ vit?.daysFalls }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End Fall Table -->

      <!-- Braden Table -->

      <table class="pain-view-table margin-top-25">
        <thead>
          <tr>
            <th>Braden</th>
            <th
              *ngFor="let day of assessmentArray; index as i"
              [matTooltip]="
                canEditAssessment ? 'Click here to edit vitals' : ''
              "
              (click)="
                canEditAssessment
                  ? highlightAssessment(day, ActiveAssessment.BRADEN)
                  : null
              "
              [ngClass]="{
                'iAssessment-header': day?.vitalType === 'Initial',
                highlight:
                  activeAssessment === ActiveAssessment.BRADEN &&
                  day._id === (vitalClickedDay$ | async)?._id,
                'cp-cursor-pointer': canEditAssessment
              }"
            >
              <div class="time">
                {{ day.timestamp | timezone: "HH:mm" }}
                <br />
                <cp-timezone-label
                  *ngIf="currPatient?.hospitalInfo?.timeZoneDetail?.name"
                  [timeZoneDetail]="currPatient?.hospitalInfo?.timeZoneDetail"
                ></cp-timezone-label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="vital-value-heading">Score</div>
            </td>
            <td
              *ngFor="let vit of assessmentArray; index as i"
              [ngClass]="{
                'iAssessment-body': vit?.vitalType === 'Initial',
                row_highlight: vit.highlight,
                'not-verified': vit?.isVerified === false
              }"
            >
              <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning': vit.daysBraden >= 12 && vit.daysBraden <= 18,
                  'table-danger': vit.daysBraden < 12
                }"
              >
                {{ vit?.daysBraden }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- End Braden Table -->
    </ng-template>
  </ng-container>
</section>
