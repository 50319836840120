import { Action, createReducer, on } from "@ngrx/store";

import * as fromAuth from "../../actions/auth.actions";
import {
  AuthApiActions,
  AuthLoginPageActions,
  AuthOtpPageActions,
  AuthFamilyOtpPageActions,
} from "../../actions/auth";

export interface State {
  loading: boolean;
  successMsg: string | null;
  emailLoginError: string | null;
  mobileLoginError: string | null;
  otpLoginError: string | null;
  familyLoginError: string | null;
}

const initialState: State = {
  loading: false,
  successMsg: null,
  emailLoginError: null,
  mobileLoginError: null,
  otpLoginError: null,
  familyLoginError: null,
};

const authReducer = createReducer(
  initialState,
  on(
    AuthOtpPageActions.clickResendOtp,
    AuthOtpPageActions.clickVerifyOtp,
    (state) => ({
      ...state,
      loading: true,
      otpLoginError: null,
      successMsg: null,
    })
  ),
  on(AuthLoginPageActions.clickEmailLoginBtn, (state) => ({
    ...state,
    loading: true,
    emailLoginError: null,
  })),
  on(AuthLoginPageActions.clickMobileLoginBtn, (state) => ({
    ...state,
    loading: true,
    mobileLoginError: null,
  })),
  on(AuthApiActions.mobileAuthFailure, (state, { error }) => ({
    ...state,
    loading: false,
    mobileLoginError: error,
  })),
  on(
    fromAuth.loginSuccess,
    AuthApiActions.mobileAuthSuccess,
    AuthApiActions.basicAuthSuccess,
    AuthApiActions.verifyOtpSuccess,
    (state) => initialState
  ),
  on(fromAuth.loginFail, (state, { error }) => ({
    ...state,
    loading: false,
    emailLoginError: error,
    successMsg: null,
  })),
  on(
    AuthApiActions.verifyOtpFailure,
    AuthApiActions.resendOtpFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      otpLoginError: error,
      successMsg: null,
    })
  ),
  on(AuthApiActions.basicAuthFailure, (state, { error }) => ({
    ...state,
    loading: false,
    emailLoginError: error,
    successMsg: null,
  })),
  on(AuthApiActions.resendOtpSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    otpLoginError: null,
    successMsg: message,
  })),
  on(AuthFamilyOtpPageActions.clickFamilyLogin, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthApiActions.validateFamilyLoginFailure, (state, { error }) => ({
    ...state,
    familyLoginError: error,
    loading: false,
  })),
  on(AuthApiActions.validateFamilyLoginSuccess, (state) => ({
    ...state,
    loading: false,
    familyLoginError: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const getEmailAuthFormError = (state: State) => state.emailLoginError;
export const getMobileAuthFormError = (state: State) => state.mobileLoginError;
export const getOtpAuthFormError = (state: State) => state.otpLoginError;
export const getAuthFormLoading = (state: State) => state.loading;
export const getAuthFormSuccessMsg = (state: State) => state.successMsg;
