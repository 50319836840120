import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";

import * as fromOrder from "../../../store/reducers/order";
import { OrderType } from "../../../models/order/OrderType.model";
import { HistoryModalData } from "../../../models/order/OrderHistory.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { schedules } from "../../form.data";
@Component({
  selector: "app-order-details-modal",
  templateUrl: "./details-modal.component.html",
  styleUrls: ["./details-modal.component.scss", "./details-modal-common.scss"],
})
export class DetailsModalComponent implements OnInit, OnDestroy {
  private _unsubscribe$: Subject<any> = new Subject<any>();
  // complete order history for a given order
  public order: any;
  public orderType = OrderType;
  public scheduleSelected = null;

  constructor(
    public dialogRef: MatDialogRef<DetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HistoryModalData,
    private _store: Store<any>
  ) {}

  ngOnInit() {
    this._store
      .pipe(
        select(fromOrder.getOrderByCategoryAndType, this.data),
        takeUntil(this._unsubscribe$)
      )
      .subscribe((order) => {
        this.order = order;
        if (this.order.scheduleSelector) {
          const index = this.order.scheduleSelector.split("-").length - 1;

          const keys = Object.keys(schedules);
          this.scheduleSelected = schedules[keys[index]].find(
            (schedule) => schedule.value === this.order.scheduleSelector
          ).label;
        }
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
