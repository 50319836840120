import { ActionReducerMap } from "@ngrx/store";

import * as fromOrderable from "../index";
import * as fromOrderableRequestData from "./orderable-request-data.reducer";
import * as fromOrderableRequestForm from "./orderable-request-form.reducer";
import * as fromOrderableRequestStatus from "./orderable-request-status.reducer";

export interface OrderableRequestState {
  data: fromOrderableRequestData.State;
  status: fromOrderableRequestStatus.State;
  form: fromOrderableRequestForm.State;
}

export interface State extends fromOrderable.OrderableState {
  request: OrderableRequestState;
}

export const reducers: ActionReducerMap<OrderableRequestState, any> = {
  data: fromOrderableRequestData.reducer,
  status: fromOrderableRequestStatus.reducer,
  form: fromOrderableRequestForm.reducer,
};
