<div class="note-popup">
  <div
    class="note-popup__header display-flex justify-content-between align-items-center"
  >
    <span class="note-popup__header__title">Handover draft</span>
    <span class="note-popup__header__actions">
      <button mat-icon-button aria-label="More icon" [matMenuTriggerFor]="menu">
        <mat-icon class="cp-icon">more_horiz</mat-icon>
      </button>
      <mat-menu #menu>
        <button
          mat-menu-item
          [disabled]="!handOverId || !note.value || note.invalid"
          (click)="moveToHandover()"
        >
          <span>Move to handover summary</span>
        </button>
        <button mat-menu-item (click)="clearNotes(true)">
          <span>Clear all</span>
        </button>
      </mat-menu>
      <button mat-icon-button (click)="closePopup()" aria-label="Close icon">
        <mat-icon class="cp-icon">close</mat-icon>
      </button>
    </span>
  </div>

  <div class="note-popup__body" [formGroup]="handOverControl">
    <cp-text-editor
      formControlName="note"
      [height]="24"
      [cpmrn]="CPMRN"
      [encounters]="encounters"
      [toolBarFixed]="true"
      [handOverId]="handOverId"
      (createdId)="handOverId = $event"
    ></cp-text-editor>
  </div>
  <div>
    <span *ngIf="this.note?.hasError('maxlength')" class="error-text">{{
      validationText
    }}</span>
  </div>
  <div>
    <span *ngIf="isErrorMessage" class="error-text">Something went wrong!</span>
  </div>
  <span class="mt note-popup__text">
    <ng-container *ngIf="handoverNotes?.length">
      Last handover note added by {{ latestHandover?.user_detail?.name }}:
      {{
        latestHandover?.updatedAt || latestHandover?.createdAt
          | timezone: "DD/MM/YYYY, HH:mm"
      }}
      <cp-timezone-label></cp-timezone-label>
      <span
        class="note-popup__text__link cursor-pointer"
        (click)="openHandoverView()"
        >Click to view<ng-container *ngIf="isEditView">/edit</ng-container>
      </span>
    </ng-container>
  </span>
</div>
