import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { PatientMonitor } from '../../models/patientMonitor/patientMonitor.model';
import { PatientMonitorVitalData } from '../../models/patientMonitor/patientMonitorVital.model';

export const addAllPatientMonitors = createAction(
    '[Patient Monitor Api] Add All Patient Monitor',
    props<{ monitors: PatientMonitor[] }>()
);

export const addPatientMonitor = createAction(
    '[Patient Monitor Api] Add Patient Monitor',
    props<{ monitor: PatientMonitor }>()
);

export const updatePatientMonitor = createAction(
    '[Patient Monitor Api] Update Patient Monitor Device',
    props<{ monitor: Update<PatientMonitor> }>()
);

export const deletePatientMonitor = createAction(
    '[Patient Monitor Api] Delete Patient Monitor Device',
    props<{ id: string }>()
);

export const setLatestPatientMonitorVitals = createAction(
    '[Patient Monitor Api] Set Latest Patient Monitor Vitals',
    props<{ data: PatientMonitorVitalData }>()
);
