import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";

import { GetHandoverNotes } from "src/app/handover/shared/models/getHandoverNotes.interface";
import { quillConfig } from "src/app/handover/shared/utils/quill-config";
import * as fromRoot from "src/app/reducers/index";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs-compat";
import { State as User } from "src/app/store/reducers/user/user.reducers";
import { HandoverNotesService } from "src/app/handover/shared/services/handover-notes.service";
import { UpdateHandoverParams } from "src/app/handover/shared/models/updateHandoverParams.interface";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { QuillEditorComponent } from "ngx-quill";
import {
  maxCharectors,
  validationText,
} from "../../../shared/utils/validation";

@Component({
  selector: "app-handover-view",
  templateUrl: "./handover-view.component.html",
  styleUrls: ["./handover-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HandoverViewComponent implements OnInit, OnDestroy {
  isUpdateButton: boolean = false;
  updateNoteLoader: boolean;
  get user(): User | undefined {
    return this.noteData.user;
  }
  get notes(): GetHandoverNotes[] | undefined {
    return this.noteData.handoverNotes;
  }
  constructor(
    public dialogRef: MatDialogRef<HandoverViewComponent>,
    private _fb: FormBuilder,
    public store: Store<fromRoot.AppState>,
    private _handoverNotes: HandoverNotesService,
    private _alertService: AlertService,
    private _cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public noteData: { handoverNotes: GetHandoverNotes[]; user: User }
  ) {}

  quillConfig = quillConfig;

  public noteMap = new Map();

  public selectedInput: number | undefined;

  @ViewChildren("quillEditor") quillEditor: QueryList<QuillEditorComponent>;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public validationText: string = validationText;

  public form = new FormGroup({
    notes: this._fb.array([]),
  });

  ngOnInit(): void {
    this.initializeValues();
    this.initializeForm();
  }

  initializeValues() {
    this.isUpdateButton =
      this.notes.find(
        (note) => note.user_detail.email == this.user.currentUser.email
      ) != undefined;
  }

  get notesForm() {
    return this.form.controls["notes"] as FormArray;
  }

  get isInvalid(): boolean {
    return (
      this.notesForm.controls.find((form) => {
        return form.invalid;
      }) != undefined
    );
  }

  get isDirty(): boolean {
    return (
      this.notesForm.controls.find(
        (form) => !this.isSameNote(form.value.noteId, form.value.note)
      ) != undefined
    );
  }

  updateNotes() {
    this.updateNoteLoader = true;
    const finalNote: UpdateHandoverParams[] = this.notesForm.value.reduce(
      (finalArr, noteData) => {
        const sameNote = this.isSameNote(noteData?.noteId, noteData?.note);
        if (
          this.user.currentUser.email !=
            this.noteMap.get(noteData?.noteId).email ||
          sameNote
        )
          return [...finalArr];

        return [
          ...finalArr,
          {
            ...noteData,
            handoverStatus: true,
          },
        ];
      },
      []
    );
    this._handoverNotes
      .updateHandoverNotes(finalNote)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.updateNoteLoader = false;
          this._alertService.showNotification({
            type: "Success",
            message: "Handover note updated successfully",
          });
          this.dialogRef.close(true);
        },
        (_) => {
          this._alertService.showNotification({
            type: "Error",
            message: "Something went wrong!",
          });
          this.updateNoteLoader = false;
          this._cdr.detectChanges();
        }
      );
  }

  public isSameNote(noteId: string, note: string): boolean {
    return this.noteMap.get(noteId).note == note;
  }

  public isCurrentUser(id: string): boolean {
    return this.noteMap.get(id).email == this.user.currentUser.email;
  }

  initializeForm() {
    this.notes.forEach((note) => {
      this.noteMap.set(note._id, {
        email: note.user_detail.email,
        name: note.user_detail.name,
        updatedAt: note.updatedAt,
        note: note.note,
      });
      const noteForm = this._fb.group({
        note: [
          note.note,
          [Validators.required, Validators.maxLength(maxCharectors)],
        ],
        noteId: [note._id],
      });
      this.notesForm.push(noteForm);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
