/**
 * @description Checks if the passed value is undefined or null
 * @param {any} val
 * @returns boolean
 * @author Suraj Shenoy
 * @date Jul 16 2021
 */
export const valExists = (val: any): boolean => {
  if (typeof val === "undefined" || val === null) {
    return false;
  }
  return true;
};
