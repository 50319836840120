import { Action, createReducer, on } from '@ngrx/store';

import { PatientMonitorApiActions } from '../actions';
import * as rootActions from '../../store/actions/root.actions';
import * as vitalActions from '../../store/actions/patient-chart/vitals/vitals.actions';
import { PatientMonitorVitalData } from '../../models/patientMonitor/patientMonitorVital.model';

export interface State {
    latestData: PatientMonitorVitalData | null;
}

export const initialState: State = {
    latestData: null
}

const patientMonitorVitalReducer = createReducer(
    initialState,
    on(
        PatientMonitorApiActions.setLatestPatientMonitorVitals,
        (state, { data }) => ({ ...state, latestData: data })
    ),
    on(
        vitalActions.resetPatientMonitorVital,
        rootActions.patientChartReset,
        rootActions.logout,
        (state) => ({ ...state, latestData: null })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return patientMonitorVitalReducer(state, action);
}
