<section class="vitals-section">
  <app-day-selector
    (setSelectedDay)="getSelectedDate($event)"
    [days]="days"
    [vitals]="vitals"
    [selectedDay]="selectedDay"
    [selectedDate]="selectedDate"
  ></app-day-selector>
  <div class="vital-view-entry assessment-view-entry">
    <app-assessment-view
      *ngIf="vitals?.length; else noDataFound"
      [selectedDay]="selectedDay"
      [currPatient]="currPatient"
      [vitals]="vitals"
    ></app-assessment-view>

    <ng-container *ngxPermissionsOnly="['create_assessment']">
      <ng-container *ngIf="!isDischargeTimeElapsed">
        <app-vital-input
          [entryType]="'assessment'"
          [currPatient]="currPatient"
        ></app-vital-input>
      </ng-container>
    </ng-container>
  </div>
</section>

<ng-template #noDataFound>
  <div class="no_data_found_block">No assessment found</div>
</ng-template>
