import { AbstractControl, ValidatorFn } from "@angular/forms";
const re = new RegExp("^[<>]?[ ]*[+-]?[0-9]+(.[0-9]+)?$");

export class FormValidators {
  static outOfRangeValidations({
    max = null,
    min = null,
  }: {
    max: number;
    min: number;
  }): ValidatorFn {
    return (
      control: AbstractControl
    ): { [key: string]: boolean | string } | null => {
      if (control?.value != "") {
        if (!control?.value?.toString().match(re) && (max || min)) {
          return {
            outOfRange: `Only numeric values are allowed`,
          };
        }
        const number = parseFloat(
          control?.value?.toString().match(/-?[\d\.]+/)
        );
        if (
          (control?.value?.toString().includes("<") &&
            min &&
            number <= Number(min)) ||
          (control?.value?.toString().includes(">") &&
            max &&
            number >= Number(max))
        ) {
          return {
            outOfRange: `Value must be between ${min} and ${max}`,
          };
        }
        if ((number > Number(max) && max) || (min && number < Number(min))) {
          return {
            outOfRange: `Value must be between ${min} and ${max}`,
          };
        }
      }
      return null;
    };
  }

  static errorRange(
    value,
    {
      max = null,
      min = null,
    }: {
      max: number | string;
      min: number | string;
    }
  ): boolean | null {
    if (max || min) {
      const number = parseFloat(value?.toString().match(/-?[\d\.]+/));
      if (
        (value?.toString().includes("<") && min && number <= Number(min)) ||
        (value?.toString().includes(">") && max && number >= Number(max))
      ) {
        return true;
      }
      if ((max || Number(max) == 0) && (min || Number(min) == 0)) {
        if (number > Number(max) || number < Number(min)) {
          return true;
        } else return false;
      }
    } else return false;
  }
}
