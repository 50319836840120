import { Component, OnInit, Input } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SbarService } from "src/app/services/sbar.service";
import { sbar } from "src/app/models/sbar";
import { Observable, Subject } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { PatientService } from "src/app/services/patient.service";
import { MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "src/app/iris-components/service/alert.service";

@Component({
  selector: "app-sbar-form",
  templateUrl: "./sbar-form.component.html",
  styleUrls: ["./sbar-form.component.scss"],
})
export class SbarFormComponent implements OnInit {
  sbarForm: UntypedFormGroup;

  public actions = [
    "Reviewed and admission note written",
    "Reviewed and daily progress note written",
    "Reviewed and issue noted",
    "Spoken to bedside RN",
    "Spoken to bedside physician",
    "Recommendations documented in note",
    "Recommendations documented please communicate to the bedside",
    "See notes / Chat thread",
    "Reviewed no further intervention at this time",
    "Follow up required",
    "Other",
  ];

  public invalidCPMRN = false;
  public invalidAction = false;
  public urgencies = ["New Admission", "Low", "Medium", "High"];
  types = [
    { key: "Bedside Initiated", value: true },
    { key: "CP CC Initiated", value: false },
  ];
  public CPMRN;
  public encounters;
  public invalidModule = false;
  public modules = [
    { name: "Vitals", value: "vitals" },
    { name: "Orders", value: "orders" },
    { name: "Notes", value: "notes" },
    { name: "I/O", value: "intake-output" },
    { name: "MAR", value: "mar" },
    { name: "Labs / Scans", value: "documents" },
    { name: "Summary", value: "summary" },
  ];
  public disableBtn = null;
  public restError = false;
  public formLoading: boolean = false;

  public ngDestroyed$ = new Subject();

  @Input() patientList;
  @Input() currentUser;
  @Input() sbarType;
  @Input() editedIssue;
  constructor(
    private activeModal: MatDialogRef<SbarFormComponent>,
    private fb: UntypedFormBuilder,
    private _sbarServices: SbarService,
    private _patientService: PatientService,
    private _alertService: AlertService
  ) {
    this.sbarForm = this.fb.group({
      cpmrn: [null, Validators.required],
      patientName: [{ value: null, disabled: true }, Validators.required],
      location: [{ value: null, disabled: true }, Validators.required],
      urgency: [
        "Select Urgency",
        Validators.pattern(/New Admission|Low|Medium|High/),
      ],
      bedsidePhysician: [{ value: null, disabled: true }],
      issues: [null, [Validators.required, Validators.pattern(/^(?!\s*$).+/)]],
      action: ["Select Action"],
      comments: [null],
      module: [
        "Select Module",
        Validators.pattern(
          /vitals|orders|notes|mar|intake-output|documents|summary/
        ),
      ],
      type: [false],
      bedSidePhone: [null],
    });
  }

  ngOnInit() {
    if (this.sbarType === "Physician") {
      this.setValues();
      this.sbarForm.controls["cpmrn"].disable();
      this.sbarForm.controls["urgency"].disable();
      this.sbarForm.controls["issues"].disable();
      this.sbarForm.controls["module"].disable();
      this.sbarForm.controls["type"].disable();
    } else if (this.sbarType === "NonPhysician") {
      if (this.editedIssue) this.sbarForm.controls["cpmrn"].disable();
      this.setValues();
    }

    this.sbarForm
      .get("cpmrn")
      .valueChanges.pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data) => {
        if (typeof data === "string") {
          if (this.sbarForm.get("patientName").value !== null) {
            this.sbarForm.get("patientName").setValue(null);
            this.sbarForm.get("location").setValue(null);
            this.sbarForm.get("bedsidePhysician").setValue(null);
            this.sbarForm.get("urgency").setValue(null);
            this.sbarForm.get("issues").setValue(null);
            this.sbarForm.get("action").setValue(null);
            this.sbarForm.get("comments").setValue(null);
            this.sbarForm.get("module").setValue(null);
            this.sbarForm.get("type").setValue(false);
          }
          this.CPMRN = data;
          this.encounters = null;
          this.ngCPMRNSelect(data);
        } else if (data.CPMRN !== null) {
          this.CPMRN = data.CPMRN;
          this.encounters = data.encounters;
          this.ngCPMRNSelect(data.CPMRN);
        }
      });
  }

  setValues() {
    this.sbarForm.get("cpmrn").setValue(this.editedIssue.CPMRN);
    this.sbarForm.get("patientName").setValue(this.editedIssue.name);
    this.sbarForm.get("location").setValue(this.editedIssue.location);
    this.sbarForm
      .get("bedsidePhysician")
      .setValue(this.editedIssue.bedsidePhysician);
    this.sbarForm.get("urgency").setValue(this.editedIssue.urgency);
    this.sbarForm.get("issues").setValue(this.editedIssue.issues);
    this.sbarForm.get("action").setValue(this.editedIssue.action);
    // call the change action
    this.onActionChange();

    this.sbarForm.get("comments").setValue(this.editedIssue.comments);
    this.sbarForm.get("module").setValue(this.editedIssue.module);
    const typeKey =
      this.editedIssue.type === "Bedside Initiated" ? true : false;
    this.sbarForm.get("type").setValue(typeKey);
    this.CPMRN = this.editedIssue.CPMRN;
    this.encounters = this.editedIssue.encounters;
  }

  onClose() {
    this.activeModal.close();
  }

  onSBSubmit() {
    Object.keys(this.sbarForm.controls).forEach((key) => {
      this.sbarForm.get(key).markAsTouched();
    });

    if (this.sbarType === "Physician") {
      if (this.sbarForm.get("action").value !== null) {
        this.takeActionSbar(
          this.editedIssue._id,
          this.editedIssue.createDateTime,
          new Date()
        );
      } else {
        this.invalidAction = true;
      }
    } else {
      if (this.sbarForm.valid && !this.invalidCPMRN) {
        if (this.editedIssue?._id) {
          this.updateSbar(this.editedIssue._id, new Date(), null);
        } else {
          this.createSbar(null, new Date(), null);
        }
      } else {
        this.sbarForm.get("issues").markAsTouched();
        this.sbarForm.get("cpmrn").markAsTouched();
        this.sbarForm.get("urgency").markAsTouched();
        this.sbarForm.get("module").markAsTouched();
      }
    }
  }

  setEncounters(encounter: number): void {
    this.encounters = encounter;
  }

  /**
   * @description This is set the sbar object
   * @param {string} id - sbar id
   * @param {Date} createdTime - Time
   * @param {Date} reviewedtime - Time
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  setSbarObject(id, createdTime, reviewedtime): sbar {
    let action;
    if (this.sbarForm.get("action").value === "Select Action") action = null;
    else action = this.sbarForm.get("action").value;

    let sbar: sbar = {
      id,
      createDateTime: createdTime,
      taskCreator: this.user(),
      CPMRN: this.CPMRN,
      patientName: this.sbarForm.get("patientName").value,
      urgency: this.sbarForm.get("urgency").value,
      bedsidePhysician: this.sbarForm.get("bedsidePhysician").value,
      issues: this.sbarForm.get("issues").value,
      action: action,
      comments: this.sbarForm.get("comments").value,
      module: this.sbarForm.get("module").value,
      timeReviewed: reviewedtime,
      bedSidePhone: this.sbarForm.get("bedSidePhone").value,
      encounters: this.encounters,
      type: this.sbarForm.get("type").value
        ? "Bedside Initiated"
        : "CP CC Initiated",
    };

    return sbar;
  }

  /**
   * @description To Add n sbar
   * @param {string} id - sbar id
   * @param {Date} createdTime - Time
   * @param {Date} reviewedtime - Time
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  createSbar(id, createdTime, reviewedtime) {
    this.formLoading = true;

    let sbar = this.setSbarObject(id, createdTime, reviewedtime);

    this._sbarServices.createSbar(sbar).subscribe(
      (data) => {
        this._sbarServices.getPatientInfo(data);
        this.invalidCPMRN = false;
        this.activeModal.close();
        this.restError = false;
        this.formLoading = false;
        this._alertService.showNotification({
          type: "Success",
          message: "SBAR details added successfully",
        });
      },
      (error) => {
        console.log("Error", error);
        this.restError = true;
        this.formLoading = false;
        this._alertService.showNotification({
          type: "Error",
          message: "Server error!",
        });
      }
    );
  }

  /**
   * @description To Update n sbar
   * @param {string} id - sbar id
   * @param {Date} createdTime - Time
   * @param {Date} reviewedtime - Time
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  updateSbar(id, createdTime, reviewedtime) {
    this.formLoading = true;

    let sbar = this.setSbarObject(id, createdTime, reviewedtime);

    this._sbarServices.updateSbar(sbar).subscribe(
      (data) => {
        this._sbarServices.getPatientInfo(data);
        this.invalidCPMRN = false;
        this.activeModal.close();
        this.restError = false;
        this.formLoading = false;
        this._alertService.showNotification({
          type: "Success",
          message: "SBAR details added successfully",
        });
      },
      (error) => {
        console.log("Error", error);
        this.restError = true;
        this.formLoading = false;
        this._alertService.showNotification({
          type: "Error",
          message: "Server error!",
        });
      }
    );
  }

  /**
   * @description To take action on a sbar
   * @param {string} id - sbar id
   * @param {Date} createdTime - Time
   * @param {Date} reviewedtime - Time
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  takeActionSbar(id, createdTime, reviewedtime) {
    this.formLoading = true;

    let sbar = this.setSbarObject(id, createdTime, reviewedtime);

    this._sbarServices.takeActionSbar(sbar).subscribe(
      (data) => {
        this._sbarServices.getPatientInfo(data);
        this.invalidCPMRN = false;
        this.activeModal.close();
        this.restError = false;
        this.formLoading = false;
      },
      (error) => {
        console.log("Error", error);
        this.restError = true;
        this.formLoading = false;
      }
    );
  }

  user() {
    if (this.currentUser.role === "Physician")
      return this.editedIssue.taskCreator;
    else return this.currentUser.name;
  }

  ngCPMRNSelect(cpmrn) {
    this.invalidCPMRN = true;
    this.sbarForm.get("urgency").setValue("Select Urgency");
    this.sbarForm.get("urgency").markAsUntouched();
    this.sbarForm.get("issues").setValue(null);
    this.sbarForm.get("issues").markAsUntouched();
    this.sbarForm.get("module").setValue("Select Module");
    this.sbarForm.get("module").markAsUntouched();

    this.patientList.forEach((patient) => {
      if (patient.CPMRN === cpmrn) {
        this.sbarForm
          .get("patientName")
          .setValue(this._patientService.displayName(patient, false));
        this.sbarForm.get("location").setValue(patient.hospitalName);
        this.sbarForm.get("bedsidePhysician").setValue(patient.PCP);
        this.setEncounters(patient.encounters);
        this.invalidCPMRN = false;
        return;
      }
    });
  }

  /**
   * @description To show the mandatory action on the comment field
   */
  isActionOther: boolean = false;
  /**
   * @description This is called when the action changes in the form, if others is selected, the comments becomes mandatory field
   * @author Suraj Shenoy
   * @date Mar 8, 2022
   */
  onActionChange() {
    let action = this.sbarForm.get("action").value;

    if (action !== null) this.invalidAction = false;

    if (action === "Other") {
      this.isActionOther = true;
      this.sbarForm.controls["comments"].setValidators(Validators.required);
      this.sbarForm.controls["comments"].updateValueAndValidity();
    } else {
      this.isActionOther = false;
      this.sbarForm.controls["comments"].clearValidators();
      this.sbarForm.controls["comments"].updateValueAndValidity();
    }
  }

  // typeahead function changed
  public CPMRN_list = [];
  get typeAhead() {
    this.sbarForm
      .get("cpmrn")
      .valueChanges.pipe(takeUntil(this.ngDestroyed$))
      .subscribe((searchTerm) => {
        if (searchTerm) {
          this.CPMRN_list = this.patientList.filter((patient) => {
            return patient.CPMRN.toLowerCase().includes(
              searchTerm.toLowerCase()
            );
          });
        }
      });
    return this.CPMRN_list;
  }

  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     map((term) =>
  //       term == ""
  //         ? []
  //         : this.patientList
  //             .filter((v) =>
  //               v.CPMRN
  //                 ? v.CPMRN.toLowerCase().indexOf(term.toLowerCase()) > -1
  //                 : ""
  //             )
  //             .slice(0, 10)
  //     )
  //   );

  formatter = (x: { CPMRN: string }) => x.CPMRN;

  sbarUrgency(urgency) {
    if (urgency === "New Admission") return "new";
    else if (urgency === "Low") return "low";
    else if (urgency === "Medium") return "med";
    else if (urgency === "High") return "high";
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
