import { Injectable } from "@angular/core";
import { catchError, retry, timeout } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class AiraApiService {
  env = environment;

  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  /*
   * NAME: get
   * PURPOSE: calls aira api to get aira data
   * DESCRIPTION:
   * PARAMS:
   *   - CPMRN:string - of patient
   *   - encounters:string - of patient
   * RETURNS: Observable:any - contains the aira result
   * USED BY: aira-btn.component.ts
   * CREATED DATE: 05/06/20
   * AUTHOR: Gunjit Agrawal
   * MODIFIED:Rakesh
   * DESCRIPTION:Increase the timeout of the response
   */
  get(CPMRN: string, encounters: string, note: {}): Observable<any> {
    const timeoutValue = 30 * 60 * 1000; // 30 minutes in milliseconds
    const retryCount = 3; // number of times to retry the request

    return this.http
      .post<any[]>(
        this.env.apiUrl + "notes/prepopulate/" + CPMRN + "/" + encounters,
        note
      )
      .pipe(
        timeout(timeoutValue),
        retry(retryCount),
        catchError(this.errorHandler)
      );
  }
}
