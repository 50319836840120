import { Component, Input } from "@angular/core";
import { patientTypeLabels } from "src/app/data/patientType-data";

@Component({
  selector: "age-group-view",
  template: `
    <div class="mb">
      <cp-choice-list
        [options]="ageGroups"
        [value]="ageGroup"
        label="Patient type:"
        [disabled]="true"
      ></cp-choice-list>
    </div>
  `,
})
export class AgeGroupViewComponent {
  @Input() ageGroup;
  @Input() id: string;
  ageGroups: string[] = patientTypeLabels;
}
