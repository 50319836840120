import { createAction, props } from "@ngrx/store";
import { Mar } from "@mar/models/mar.model";

export const setMarDiet = createAction(
  "[MAR] Set MAR - Diet medication",
  props<{ mar: Mar[] }>()
);

export const upsertMarDiet = createAction(
  "[MAR] Upsert MAR - Diet medication",
  props<{ mar: Mar[] }>()
);

export const resetMarDiet = createAction(
  "[MAR] Reset to initial state - Diet medication"
);
