import { AuthService } from "src/app/services/auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-token-expired-error",
  templateUrl: "./token-expired-error.component.html",
  styleUrls: ["./token-expired-error.component.scss"],
})
export class TokenExpiredErrorComponent implements OnInit {
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {}

  refreshPage(): void {
    location.reload();
  }
}
