import { Injectable } from "@angular/core";
import { AlertService } from "@iris/service/alert.service";
import { Store } from "@ngrx/store";
import {
  DraftNotesAPIResponse,
  FinalNotes,
  FinalNotesAPIResponse,
} from "@notes-view/model/notes-model";
import { CTAType } from "@notes/models/notes.model";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import * as fromNoteStore from "@notes/store";
import * as fromViewNoteStore from "@notes-view/store";
import * as fromloaderStateReducer from "@shared-modules/store";
import * as fromNoteTypeActions from "../actions";
import { NoteTypelabels } from "@notes-view/note-view/note-sections/note-table.config";

type HandlerType = {
  dispatchNoteAction: (
    response: FinalNotesAPIResponse | DraftNotesAPIResponse
  ) => void;
};

@Injectable({
  providedIn: "root",
})
export class NoteEffectsHandler {
  constructor(
    private _alertService: AlertService,
    private _noteViewStore: Store<fromViewNoteStore.NoteFormState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>
  ) {}

  handleNoteEffect(
    serviceCall: () => Observable<FinalNotes>,
    successMessage: string,
    ctaType: CTAType,
    loaderType?: string,
    noteType: NoteTypelabels = null,
    shouldResetContent: boolean = true
  ): Observable<FinalNotesAPIResponse> {
    const handler = this.getNoteHandler(noteType);
    return serviceCall().pipe(
      switchMap((response) =>
        this.handleSuccess(
          response,
          successMessage,
          ctaType,
          handler,
          loaderType,
          shouldResetContent
        )
      ),
      catchError((error) => this.handleError(error))
    );
  }

  private getNoteHandler(noteType: NoteTypelabels) {
    switch (noteType) {
      case NoteTypelabels.Draft:
        return this.createDraftNoteHandler();
      default:
        return this.createFinalNoteHandler();
    }
  }

  private createDraftNoteHandler() {
    return {
      dispatchNoteAction: (response: DraftNotesAPIResponse) => {
        this._noteViewStore.dispatch(this.addDraftNoteAction(response));
      },
    };
  }

  private createFinalNoteHandler() {
    return {
      dispatchNoteAction: (response: FinalNotesAPIResponse) => {
        this._noteViewStore.dispatch(this.addFinalNoteAction(response));
      },
    };
  }

  private handleSuccess(
    response: any,
    successMessage: string,
    ctaType: CTAType,
    handler: HandlerType,
    loaderType?: string,
    shouldResetContent: boolean = true
  ): Observable<any> {
    handler.dispatchNoteAction(response);
    this._loaderStore.dispatch(this.setLoadingSpinnerAction(loaderType, false));
    if (shouldResetContent) {
      this._noteStore.dispatch(fromNoteStore.setCTA({ cta: ctaType }));
      this._alertService.showNotification({
        type: "Success",
        message: successMessage,
      });
      this._noteStore.dispatch(fromNoteStore.resetNoteContent());
    }
    return of(fromNoteTypeActions.noteSubmissionSuccess());
  }

  private handleError(error: any): Observable<any> {
    this._alertService.showNotification({
      type: "Error",
      message: error.error.message,
    });
    return of(fromNoteTypeActions.noteSubmissionFail());
  }

  private addFinalNoteAction(response: FinalNotesAPIResponse) {
    const noteContents: FinalNotesAPIResponse = response;
    return fromViewNoteStore.addFinalNote({
      note: {
        refId: noteContents.notes.finalNotes["_id"],
        isDeleteEnabled: noteContents.notes.finalNotes.isDeleteEnabled,
        createdTimestamp: noteContents.notes.finalNotes.createdTimestamp,
        ...noteContents.notes.finalNotes["content"][0],
      },
    });
  }

  private addDraftNoteAction(response: DraftNotesAPIResponse) {
    const noteContents = response;
    return fromViewNoteStore.updateDraftNote({
      note: noteContents.notes.draftNotes,
    });
  }

  private setLoadingSpinnerAction(loaderType: string, status: boolean) {
    return fromloaderStateReducer.setLoadingSpinner({
      loader: loaderType,
      status: status,
    });
  }
}
