<div class="details-modal">
  <div class="details-modal__header">
    <span
      class="details-modal__header__name name"
      [matTooltip]="data.orderName?.length > 80 ? data.orderName : ''"
      matTooltipClass="cp-mat-tooltip"
      >{{ data.orderName }}</span
    >
    <span class="details-modal__header__cross" (click)="closeModal()">
      <!-- <img src="assets/icons/close.svg" /> -->
    </span>
  </div>

  <div class="details-modal__body" *ngIf="order">
    <div class="details-modal__preset" *ngIf="order.preset && !order.brandName">
      <div class="details-modal__text">
        <div class="details-modal__text__title">Preset:</div>
        <div
          class="details-modal__text__detail name"
          [matTooltip]="order.preset?.length > 45 ? order.preset : ''"
          matTooltipClass="cp-mat-tooltip"
        >
          {{ order.preset }}
        </div>
      </div>
    </div>

    <div class="details-modal__preset" *ngIf="order.brandName && !order.preset">
      <div class="details-modal__text">
        <div class="details-modal__text__title">Brand:</div>
        <div
          class="details-modal__text__detail name"
          [matTooltip]="order.brandName?.length > 45 ? order.brandName : ''"
          matTooltipClass="cp-mat-tooltip"
        >
          {{ order.brandName | titlecase }}
        </div>
      </div>
    </div>

    <div
      class="route-form-cont details-modal__preset"
      *ngIf="order.brandName && order.preset"
    >
      <div class="details-modal__text" style="padding-left: 15px">
        <span class="details-modal__text__title">Preset:</span
        ><span
          class="details-modal__text__detail name"
          [matTooltip]="order.preset?.length > 30 ? order.preset : ''"
          matTooltipClass="cp-mat-tooltip"
          >{{ order.preset }}
        </span>
      </div>
      <div class="details-modal__text" style="padding-left: 15px">
        <span class="details-modal__text__title">Brand:</span>
        <span
          class="details-modal__text__detail name"
          [matTooltip]="order.brandName?.length > 30 ? order.brandName : ''"
          matTooltipClass="cp-mat-tooltip"
          >{{ order.brandName }}</span
        >
      </div>
    </div>

    <div class="details-modal__box">
      <div class="details-modal__box__heading">Details</div>
      <div class="details-modal__box__body">
        <div class="details-modal__text" *ngIf="order?.completedBy">
          <div class="details-modal__text__title">Completed by:</div>
          <div class="details-modal__text__detail">
            {{ order.completedBy }} <cp-timezone-label></cp-timezone-label>
          </div>
        </div>

        <div class="details-modal__text" *ngIf="order?.discontinueBy">
          <div class="details-modal__text__title">Discontinued by:</div>
          <div class="details-modal__text__detail">
            {{ order.discontinueBy }} <cp-timezone-label></cp-timezone-label>
          </div>
        </div>

        <div class="details-modal__text" *ngIf="order.createdBy">
          <div class="details-modal__text__title">Created by:</div>
          <div class="details-modal__text__detail">
            {{ order?.createdBy }}
            <ng-container *ngIf="order.createdAt">
              on
              {{ order.createdAt | timezone : "DD/MM/YY" }} at
              {{ order.createdAt | timezone : "HH:mm" }}
            </ng-container>
            <cp-timezone-label></cp-timezone-label>
          </div>
        </div>

        <div
          class="details-modal__text"
          *ngIf="
            order.signed &&
            order.category != 'pta' &&
            order.category != 'pending'
          "
        >
          <div class="details-modal__text__title">
            {{ order.category | orderDetailsSignedTitle }}
          </div>
          <div class="details-modal__text__detail">
            {{ order.signed }}
            <ng-container *ngIf="order.signedAt">
              on {{ order.signedAt | timezone : "DD/MM/YY" }} at
              {{ order.signedAt | timezone : "HH:mm" }}
            </ng-container>
            <cp-timezone-label></cp-timezone-label>
          </div>
        </div>

        <div [ngSwitch]="order.type">
          <app-med-detail-view *ngSwitchCase="orderType.Med" [order]="order">
          </app-med-detail-view>
          <app-vent-detail-view *ngSwitchCase="orderType.Vent" [order]="order">
          </app-vent-detail-view>
        </div>

        <div class="details-modal__text">
          <div class="details-modal__text__title">Time Period:</div>
          <div class="details-modal__text__detail">
            {{
              order?.timePeriodDetail?.period
                ? order.timePeriodDetail?.period +
                  " " +
                  order.timePeriodDetail?.periodOption
                : "-"
            }}
          </div>
        </div>

        <div class="details-modal__text">
          <div class="details-modal__text__title">Schedule:</div>
          <div class="details-modal__text__detail">
            {{ scheduleSelected || "-" }}
          </div>
        </div>

        <div class="details-modal__text">
          <div class="details-modal__text__title">Instructions:</div>
          <div class="details-modal__text__detail">
            <pre>{{ order.instructions || "-" }}</pre>
          </div>
        </div>

        <div class="details-modal__text">
          <div class="details-modal__text__title">Information:</div>
          <div class="details-modal__text__detail">
            <pre>{{ order.additionalInformation || "-" }}</pre>
          </div>
        </div>
        <div
          class="details-modal__text flex-column"
          *ngIf="order?.discontinueReasons?.length > 0"
        >
          <div class="details-modal__text__title">
            Reason for discontinuation:
          </div>
          <div class="details-modal__text__detail">
            <div>
              Reasons:
              {{ order.discontinueReasons | formatDiscontinueReasons }}.
            </div>
            <span>Info: {{ order.discontinueReasonStatement }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="details-modal__box details-modal__history"
      *ngIf="order?.history?.length > 0"
    >
      <div class="details-modal__box__heading">History</div>

      <div *ngFor="let history of order.history">
        <div class="details-modal__history__change-time">
          <span>Changed on:</span>
          <span
            >{{ history.createdAt | timezone : "DD/MM/YY" }} at
            {{ history.createdAt | timezone : "HH:mm" }}</span
          >
          <cp-timezone-label></cp-timezone-label>
          <span class="font-weight-bold ml-3 mr-1">Changed by:</span>
          <span>{{ history.user }}</span>
        </div>

        <div class="details-modal__box__body">
          <div [ngSwitch]="order.type">
            <app-blood-history-view
              *ngSwitchCase="orderType.Blood"
              [order]="history.order"
            >
            </app-blood-history-view>
            <app-comm-history-view
              *ngSwitchCase="orderType.Comm"
              [order]="history.order"
            >
            </app-comm-history-view>
            <app-diet-history-view
              *ngSwitchCase="orderType.Diet"
              [order]="history.order"
            >
            </app-diet-history-view>
            <app-lab-history-view
              *ngSwitchCase="orderType.Lab"
              [order]="history.order"
            >
            </app-lab-history-view>
            <app-med-history-view
              *ngSwitchCase="orderType.Med"
              [order]="history.order"
            >
            </app-med-history-view>
            <app-procedure-history-view
              *ngSwitchCase="orderType.Procedure"
              [order]="history.order"
            >
            </app-procedure-history-view>
            <app-vent-history-view
              *ngSwitchCase="orderType.Vent"
              [order]="history.order"
            >
            </app-vent-history-view>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="details-modal__footer">
    <button mat-flat-button class="button-secondary" mat-dialog-close>
      Close
    </button>
  </div>
</div>
