import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { DietPreset } from "../../../models/preset/DietPreset.model";

@Component({
  selector: "app-protocol-diet-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="diet.patientType"
        [ageGroup]="diet.patientType"
        [id]="diet._id"
      ></age-group-view>
      <div class="name"><strong>Name: </strong> {{ diet.name }}</div>

      <div class="" *ngIf="diet.numberOfDoses">
        <strong>Instances: </strong>{{ diet.numberOfDoses }}
      </div>
      <div class="" *ngIf="diet.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{ orderService.getFrequencyDisplayName(diet.frequency) }}</span>
      </div>
      <div class="" *ngIf="diet.rate && diet.rate.value">
        <strong>Rate: </strong>{{ diet.rate.value }} {{ diet.rate.unit }}
      </div>
      <div class="" *ngIf="diet.urgency">
        <strong>Urgency: </strong>{{ diet.urgency }}
      </div>

      <div class=" details" *ngIf="diet.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ diet.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="diet.instructions">
        <strong>Instructions</strong>:
        <em>{{ diet.instructions }}</em>
      </div>

      <div class=" details" *ngIf="diet.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ diet.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolDietViewComponent {
  @Input() diet: DietPreset;

  constructor(public orderService: OrderService) {}
}
