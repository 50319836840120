import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { UntypedFormGroup, FormGroupDirective } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { Vitals } from "src/app/vitals/models/Vital";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { RassFormService } from "./rass-form.service";
import { takeUntil } from "rxjs/operators";
import { VitalInputService } from "../vital-input.service";
@Component({
  selector: "app-rass",
  templateUrl: "./rass.component.html",
  styleUrls: ["./rass.component.scss"],
})
export class RassComponent implements OnInit {
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description rass form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  public rRassForm: UntypedFormGroup = this._rassForm.initRassForm();

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Variables to calculate rass
   */
  public isAcuteCollapsed = true;
  public isInattentionCollapsed = true;
  public isAlteredDisorganizedCollapsed = true;
  public acuteVal = null;
  public fluctVal = null;
  public inattentionVal = null;
  public disorganizedVal = null;

  public isDelirium = null;
  public rassScore;
  public rassDelirium = null;

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.rRassForm.get("timestamp");
  }

  constructor(
    private store: Store<{}>,
    private _rassForm: RassFormService,
    private _alertService: AlertService
  ) {}

  ngOnInit() {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Rass updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.rRassForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    this.rassScore = vitalData.daysRass;
    this.isDelirium = vitalData.daysRassCAMICU;

    this.rRassForm.get("rassScore").setValue(vitalData.daysRass);
    if (vitalData.daysRass >= -3) {
      this.isAcuteCollapsed = false;
    } else {
      this.isAcuteCollapsed = true;
    }
    this.rRassForm
      .get("rassAssessDeliriumYN")
      .setValue(vitalData.daysRassAssessDeliriumYN);
    this.rRassForm.get("acuteYN").setValue(vitalData.daysRassAcuteYN);
    this.rRassForm.get("fluctYN").setValue(vitalData.daysRassFluctYN);
    if (
      vitalData.daysRassAcuteYN === "Yes" ||
      vitalData.daysRassFluctYN === "Yes"
    ) {
      this.isInattentionCollapsed = false;
    } else {
      this.isInattentionCollapsed = true;
    }
    this.rRassForm
      .get("inattentionYN")
      .setValue(vitalData.daysRassInattentionYN);
    if (vitalData.daysRassInattentionYN === "Yes") {
      this.isAlteredDisorganizedCollapsed = false;
    } else {
      this.isAlteredDisorganizedCollapsed = true;
    }
    this.rRassForm
      .get("disorganizedYN")
      .setValue(vitalData.daysRassDisorganizedYN);

    this.rRassForm.get("timestamp").setValue(vitalData.timestamp);
  }

  /**
   * @description Triggers when rass score is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  rassSelected(event) {
    this.rassScore = event.value;
    this.rassDelirium = null;
    this.isDelirium = null;
    this.isAcuteCollapsed = true;
    this.acuteVal = null;
    this.rRassForm.controls["acuteYN"].setValue(this.acuteVal);
    this.fluctVal = null;
    this.rRassForm.controls["fluctYN"].setValue(this.fluctVal);

    this.isInattentionCollapsed = true;
    this.inattentionVal = null;
    this.rRassForm.controls["inattentionYN"].setValue(this.inattentionVal);

    this.isAlteredDisorganizedCollapsed = true;
    this.disorganizedVal = null;
    this.rRassForm.controls["disorganizedYN"].setValue(this.disorganizedVal);

    if (this.rassScore >= -3) {
      this.isAcuteCollapsed = false;
    }
  }

  /**
   * @description Triggers when acute is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  acuteChanged(event) {
    this.acuteVal = event.value;
    this.inattentionCollapse();
    this.computeDelirium();
  }

  /**
   * @description Triggers when fluct is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  fluctChanged(event) {
    this.fluctVal = event.value;
    this.inattentionCollapse();
    this.computeDelirium();
  }

  /**
   * @description Triggers when inattention is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  inattentionChanged(event) {
    this.inattentionVal = event.value;
    if (this.inattentionVal === "Yes") {
      this.isAlteredDisorganizedCollapsed = false;
    } else {
      this.isAlteredDisorganizedCollapsed = true;
      this.disorganizedVal = null;
      this.rRassForm.get("disorganizedYN").setValue(this.disorganizedVal);
    }
    this.computeDelirium();
  }

  /**
   * @description Triggers when inattention is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  inattentionCollapse() {
    if (this.acuteVal === "Yes" || this.fluctVal === "Yes") {
      this.isInattentionCollapsed = false;
    } else {
      this.isInattentionCollapsed = true;
      this.inattentionVal = null;
      this.rRassForm.get("inattentionYN").setValue(this.inattentionVal);

      this.isAlteredDisorganizedCollapsed = true;
      this.disorganizedVal = null;
      this.rRassForm.get("disorganizedYN").setValue(this.disorganizedVal);
    }
  }

  /**
   * @description Triggers when disorganized is changed
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  disorganizedChanged(event) {
    this.disorganizedVal = event.value;
    this.computeDelirium();
  }

  /**
   * @description To compute Delirium
   * @param {Object} event
   * @author Dhruv Sud
   * @date June 28 2021 (modified: Suraj Shenoy)
   */
  computeDelirium() {
    this.isDelirium = null;
    // Basic check
    if (this.rassScore < -3) {
      return;
    }
    // Feature 1
    if (this.acuteVal === "No" && this.fluctVal === "No") {
      this.isDelirium = false;
      return;
    }
    // Feature 2
    if (this.inattentionVal === "No") {
      this.isDelirium = false;
      return;
    }
    // Feature 3
    if (this.inattentionVal === "Yes" && parseInt(this.rassScore, 10) !== 0) {
      this.isDelirium = true;
      return;
    }
    // Feature 4
    if (this.disorganizedVal === "Yes") {
      this.isDelirium = true;
      return;
    }
    if (this.disorganizedVal === "No") {
      this.isDelirium = false;
      return;
    }
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.rassScore = null;
    this.rassDelirium = null;
    this.isDelirium = null;
    this.isAcuteCollapsed = true;
    this.isInattentionCollapsed = true;
    this.isAlteredDisorganizedCollapsed = true;
    // this.rRassForm.reset();
    this.formDirective.resetForm();
    this.formState.error = null;
  }

  /**
   * @description To submit the rass values
   * @param {Object} patDataFromForm
   * @author Suraj Shenoy
   * @date June 28 2021
   */
  submitRass(patDataFromForm) {
    this.store.dispatch(vitalActions.submitForm({ loadType: "rass" }));
    const rassProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      daysRass: this.rassScore,
      daysRassAssessDeliriumYN: patDataFromForm.rassAssessDeliriumYN,
      daysRassCAMICU: this.isDelirium,
      daysRassAcuteYN: patDataFromForm.acuteYN,
      daysRassFluctYN: patDataFromForm.fluctYN,
      daysRassInattentionYN: patDataFromForm.inattentionYN,
      daysRassDisorganizedYN: patDataFromForm.disorganizedYN,
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: rassProps },
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
