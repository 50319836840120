import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { OrdersModule } from "../orders/orders.module";
import { VentFormComponent } from "./components/vent-form/vent-form.component";
// import { VitalTimeComponent } from "./components/vital-time/vital-time.component";
import { VitalPresetViewComponent } from "./components/vital-preset-view/vital-preset-view.component";
import { IrisComponentsModule } from "../iris-components/iris-components.module";

@NgModule({
  declarations: [
    VentFormComponent,
    // VitalTimeComponent,
    VitalPresetViewComponent,
  ],
  exports: [VentFormComponent, VitalPresetViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OrdersModule,
    FontAwesomeModule,
    IrisComponentsModule,
  ],
})
export class VitalModule {}
