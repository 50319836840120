import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextHighlightDirective } from "./text-highlight.directive";

@NgModule({
  declarations: [TextHighlightDirective],
  imports: [CommonModule],
  exports: [TextHighlightDirective],
})
export class TextHightlightModule {}
