import { Injectable } from '@angular/core';
import { PatientMonitorVital } from '../../models/patientMonitor/patientMonitorVital.model';

@Injectable({ providedIn: 'root' })
export class PatientMonitorVitalService {

  /*
  * NAME: checkIfVitalsIsEmpty
  * PURPOSE: Check if vital is empty.
  * DESCRIPTION:
  * PARAMS: vital:PatientMonitorVital - vital data
  * RETURNS: boolean - true if vitals is empty
  * USED BY: vital-form.component.ts
  * CREATED DATE: 23/07/20
  * AUTHOR: Gunjit Agrawal
  */
  checkIfVitalsIsEmpty(vital: PatientMonitorVital): boolean {
    return vital.bp1 === "0" &&
      vital.bp2 === "0" &&
      vital.MAP === "0" &&
      vital.hr === "0" &&
      vital.rr === "0" &&
      vital.spo2 === "0" &&
      vital.temp1 === "0.0";
  }
}
