import { createAction, props } from "@ngrx/store";
import { TvPatStoreType } from "src/app/models/tv-unit-view.model";

export const GET_UNIT_TV_INFO = "[TV Unit] Get unit TV info";
export const GET_UNIT_TV_INFO_FAILURE = "[TV Unit] Get unit TV info failure";
export const GET_UNIT_TV_INFO_SUCCESS = "[TV Unit] Get unit TV info success";
export const ADD_ONE_PATIENT = "[TV Unit] Add one patient";
export const SET_ONE_PATIENT = "[TV Unit] Set one patient";
export const REMOVE_ONE_PATIENT = "[TV Unit] Remove one patient";
export const UNIT_TV_CLEAR_STORE = "[TV Unit] Clear TV info";

export const getUnitTvInfoAction = createAction(
  GET_UNIT_TV_INFO,
  props<{ hospital: string; unit: string }>()
);

export const getUnitTvInfoFailureAction = createAction(
  GET_UNIT_TV_INFO_FAILURE,
  props<{ error: any }>()
);

export const getUnitTvInfoSuccessAction = createAction(
  GET_UNIT_TV_INFO_SUCCESS,
  props<{ data: any }>()
);

export const addOnePatientAction = createAction(
  ADD_ONE_PATIENT,
  props<{ patient: TvPatStoreType }>()
);

export const setOnePatientAction = createAction(
  SET_ONE_PATIENT,
  props<{ patient: TvPatStoreType }>()
);

export const removeOnePatientAction = createAction(
  REMOVE_ONE_PATIENT,
  props<{ patient: TvPatStoreType }>()
);

export const unitTvClearStore = createAction(UNIT_TV_CLEAR_STORE);
