import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RAlertDevice } from '../../../models/RAlert/device.model';

@Component({
  selector: 'app-ralert-device-list',
  templateUrl: './ralert-device-list.component.html',
  styleUrls: [ './ralert-device-list.component.scss' ]
})
export class RalertDeviceListComponent implements OnInit {
  @Input() devices: RAlertDevice[];
  @Output() editClick: EventEmitter<RAlertDevice> = new EventEmitter<RAlertDevice>();
  @Output() deleteClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  onEditClick(device: RAlertDevice) {
    this.editClick.emit(device);
  }

  onDeleteClick(device: RAlertDevice) {
    this.deleteClick.emit(device._id);
  }
}
