import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-med-detail-view',
  template: `
	  <div class="details-modal__text" *ngIf="order?.concentration?.value">
		  <div class="details-modal__text__title">
			  Concentration:
		  </div>
		  <div class="details-modal__text__detail">
			  {{ order.concentration.value }} {{ order.concentration.unit }}
		  </div>
	  </div>

	  <div class="details-modal__text" *ngIf="order?.concentration?.value">
		  <div class="details-modal__text__title">
			  Rate:
		  </div>

		  <div class="details-modal__text__detail">
          <span *ngIf="(order | medRate)?.rate as rate">
            <span class="mr-1">{{ rate.min }} </span>
            <span *ngIf="rate.max" class="mr-1"> - {{ rate.max }}</span>
            <span>(ml/hr)</span>
        </span>
		  </div>
	  </div>
  `,
  styleUrls: [ '../details-modal-common.scss' ]
})
export class MedDetailViewComponent {
  @Input() order;
}
