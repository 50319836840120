import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, Subject } from "rxjs";
import { CodeblueSenderComponent } from "../codeblue-sender/codeblue-sender.component";
import { DataService } from "../service/data.service";
import { AISocketService } from "src/app/notification-module/services/socket.service";
import { Topic } from "src/app/notification-module/enums/topic.enum";
import * as fromUnitIndex from "src/app/store/reducers/units/index";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-codeblue",
  templateUrl: "./codeblue.component.html",
  styleUrls: ["./codeblue.component.scss"],
})
export class CodeblueComponent implements OnInit, OnDestroy {
  // variable to indicate socket connection state
  public hasConnection: boolean = false;
  public runnigCodeBlue: any = [];
  public runnigEcall: any;
  public codeblueButtonText: string = "CodeBlue";
  public eCallButtonText: string = "eCall";
  public activeToasters: any;
  private subscription: Subscription;
  private assignedUnits: any;
  private availableUnits: any;
  private availableHospitals: any;
  private currentUser: any;
  private unsubscribe$: Subject<any> = new Subject<any>();

  public units$ = this.store.pipe(
    select(fromUnitIndex.getUnits),
    takeUntil(this.unsubscribe$)
  );

  constructor(
    private store: Store<{}>,
    private socketService: AISocketService,
    private dataService: DataService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // first get user details
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const userData = {
      role: this.currentUser["role"],
      email: this.currentUser["email"],
    };
    // all data gethering services from ai server are initiated in code blue component
    this.subscription = this.socketService.readyState.subscribe((state) => {
      this.hasConnection = state;
      // ensure socket is live before sending message to server
      if (this.hasConnection && this.currentUser) {
        // get assigned units after geting user details
        this.socketService.send(Topic.getAssignedUnits, userData);
      }
    });

    // incase of change in unit assignment, get new units details
    this.units$.subscribe((units) => {
      if (this.hasConnection && this.currentUser) {
        // get assigned units after geting user details and connection establishment
        this.socketService.send(Topic.getAssignedUnits, userData);
      }
    });

    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.setAssignedUnits)
        .subscribe((message) => {
          if (message) {
            this.assignedUnits = message["assigned_units"];
            this.availableUnits = message["available_units"];
            this.availableHospitals = message["available_hospitals"];
            // after getting assigned units get running code blue for user
            this.socketService.send(Topic.getRalert, userData);
          }
        })
    );

    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.setRalert)
        .subscribe((message) => {
          if (message) {
            // after setting up assigned units listion for code blue
            this.runnigCodeBlue = []; // clean up
            this.runnigEcall = [];
            message["r_alerts"].forEach((alert) => {
              if (alert["is_codeblue"]) {
                this.runnigCodeBlue.push(alert);
              } else {
                this.runnigEcall.push(alert);
              }
            });
            // set button text
            if (this.runnigCodeBlue.length > 0) {
              this.codeblueButtonText =
                "" + this.runnigCodeBlue.length + " CodeBlue running";
            } else {
              this.codeblueButtonText = "CodeBlue";
            }
            if (this.runnigEcall.length > 0) {
              this.eCallButtonText =
                "" + this.runnigEcall.length + " eCall running";
            } else {
              this.eCallButtonText = "eCall";
            }
            // on recieving new alert data update the data in dialogbox
            const newCodeblueData = {
              assignedUnits: this.assignedUnits,
              availableUnits: this.availableUnits,
              availableHospitals: this.availableHospitals,
              runningAlerts: this.runnigCodeBlue,
            };
            this.dataService.setCodeBlueData(newCodeblueData);
            const newEcallData = {
              assignedUnits: this.assignedUnits,
              availableUnits: this.availableUnits,
              availableHospitals: this.availableHospitals,
              runningAlerts: this.runnigEcall,
            };
            this.dataService.setEcallData(newEcallData);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // emit a value to complete all observables using takeUntil
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  raiseCodeBlue() {
    this.dialog.open(CodeblueSenderComponent, {
      width: "932px",
      data: {
        contentType: "CodeBlue",
        assignedUnits: this.assignedUnits,
        availableUnits: this.availableUnits,
        availableHospitals: this.availableHospitals,
        runningAlerts: this.runnigCodeBlue,
      },
      autoFocus: false, // Disable autofocus
    });
  }

  raiseEcall() {
    this.dialog.open(CodeblueSenderComponent, {
      width: "932px",
      data: {
        contentType: "eCall",
        assignedUnits: this.assignedUnits,
        availableUnits: this.availableUnits,
        availableHospitals: this.availableHospitals,
        runningAlerts: this.runnigEcall,
      },
      autoFocus: false, // Disable autofocus
    });
  }
}
