import { Patient, birthStatusList } from "src/app/models/patient";
import * as GROWTH_LINE_COORDS from "../constants/growth-line-coords.data";
import * as PRETERM_GROWTH_COORDS from "../constants/growth-preterm-coords.data";

import {
  GROWTH_CHART_CONFIG,
  GROWTH_CHART_OPTIONS,
  GROWTH_LINE_COLORS,
  GROWTH_LINE_LABELS_CONFIG,
  GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP,
  GROWTH_OPTION_TO_DEFAULT_LINE_MAP,
  GROWTH_OPTION_W_HISTORY_KEY_MAP,
} from "../constants/growth-trends.data";
import {
  GROWTH_WEIGHT_TYPE,
  GrowthChartConfigConstantInterface,
  GrowthDataPoints,
  GrowthTrendsConfig,
} from "../models/growth-trends.model";
import moment from "moment-timezone";
import { valExists } from "src/app/support-functions/util";
import { TrendType } from "../constants/trends.data";
import { XAxisConfig, YAxisConfig } from "../models";
import { getProperUnitName } from "src/app/support-functions/calculateAge";
import {
  CALCULATE_WEIGHT,
  calculateWeight,
} from "src/app/support-functions/calculateWeight";
import { TimezoneService } from "src/app/services/timezone.service";
import { Injectable, Injector } from "@angular/core";

@Injectable()
export class GrowthChartSupportService {
  constructor(private _tz: TimezoneService) {}

  /**
   * @description used to filter selected graph names from ENUM GROWTH_CHART_OPTIONS
   * @param selectedGraphs
   * @returns array of array [key, value] -> filtered GROWTH_CHART_OPTIONS
   * @author Rajat Saini
   * @date Jun 26, 2023
   */
  filterSelectedGraphNamesFromEnum = (selectedGraphs: string[]) => {
    if (!selectedGraphs?.length) return [];

    return Object.entries(GROWTH_CHART_OPTIONS)?.filter(([key, value]) =>
      selectedGraphs?.includes(value)
    );
  };

  /**
   * @description return birthStatus if it is not saved
   * @param patientInfo
   * @returns patient's birth status
   * @author Rajat Saini
   * @date Jun 28, 2023
   */
  getBirthStatus = (patientInfo: Patient) => {
    if (patientInfo?.birthStatus) return patientInfo?.birthStatus;

    const gestAgeWeeks = patientInfo?.gestationAge?.weeks;
    return gestAgeWeeks < 37 ? birthStatusList.Preterm : birthStatusList.Mature;
  };

  /**
   * @description: used to make graph data and config which we will be pushing into store
   * @author Rajat Saini
   * @date Jun 15, 2023
   */
  makeGraphDataAndConfigToBePushed = (
    graphKey: GROWTH_CHART_OPTIONS,
    patientInfo: Patient
  ): GrowthTrendsConfig => {
    patientInfo.birthStatus = this.getBirthStatus(patientInfo);
    const graphConfig: GrowthChartConfigConstantInterface =
      GROWTH_CHART_CONFIG[GROWTH_CHART_OPTIONS[graphKey]][
        patientInfo?.birthStatus
      ];
    const graphData = this.tranformGrowthDataForTrends(graphKey, patientInfo);
    const returnObj: GrowthTrendsConfig = {
      graphName: GROWTH_CHART_OPTIONS[graphKey],
      graphConfig: {
        xAxisConfig: {
          labelName: graphConfig.xLabelName,
          domainRange: graphConfig.xDomainRange,
          ticks: graphConfig.xTicks,
          tickValues: graphConfig?.xTickValues,
          niceDisable: graphConfig?.xNiceDisable || false,
        },
        yAxisConfig: {
          labelName: graphConfig.yLabelName,
          domainRange: graphConfig.yDomainRange,
          ticks: graphConfig.yTicks,
        },
      },
      graphData: graphData,
    };
    return returnObj;
  };

  /**
   * @description used to transform patient weight history into graph-data-points based on graphKey
   * @param graphKey
   * @param wHistory
   * @author Rajat Saini
   * @date Jun 15, 2023
   */
  tranformGrowthDataForTrends = (
    graphKey: GROWTH_CHART_OPTIONS,
    patientInfo: Patient
  ) => {
    if (!patientInfo?.weightHistory?.length)
      return this.insertBirthAdmissionDataPoints(
        undefined,
        patientInfo,
        graphKey
      );

    const growthToWHistoryKeyMap =
      GROWTH_OPTION_W_HISTORY_KEY_MAP[GROWTH_CHART_OPTIONS[graphKey]];
    let dataPoints = patientInfo?.weightHistory?.reduce((returnArr, wObj) => {
      if (!valExists(wObj[growthToWHistoryKeyMap.yLabel])) return returnArr;

      let xValue = this.transformXValue(
        patientInfo?.birthStatus,
        wObj.weightMeasuredTime,
        patientInfo?.dob,
        patientInfo?.gestationAge
      );

      if (!valExists(xValue)) return returnArr;

      const tranformedObj: GrowthDataPoints = {
        xValue,
        yValue: wObj[growthToWHistoryKeyMap.yLabel],
        timeStamp: wObj.weightMeasuredTime,
        createdBy: wObj?.createdBy,
        updatedBy: wObj?.updatedBy,
        graphName: GROWTH_CHART_OPTIONS[graphKey],
      };
      return [...returnArr, tranformedObj];
    }, []);

    // adding birth-weight and admission weight into dataPoints
    return this.insertBirthAdmissionDataPoints(
      dataPoints,
      patientInfo,
      graphKey
    );
  };

  /**
   * @param existingDataPoints
   * @param patientInfo
   * @param graphKey
   * @description used to insert admission / birth weight data points into existing graph data points only if weight graph is selected
   * @author Rajat Saini
   * @date Aug 25, 2023
   * @returns
   */
  insertBirthAdmissionDataPoints = (
    existingDataPoints: GrowthDataPoints[] = [],
    patientInfo,
    graphKey
  ) => {
    if (GROWTH_CHART_OPTIONS[graphKey] !== GROWTH_CHART_OPTIONS.weightForAge)
      return existingDataPoints;

    const birthAndAdmissionWeightDataPoints: GrowthDataPoints[] =
      this.makeBirthAndAdmissionWeightDataPoints(patientInfo);

    return birthAndAdmissionWeightDataPoints?.length
      ? [...existingDataPoints, ...birthAndAdmissionWeightDataPoints]
      : existingDataPoints;
  };

  transformXValue = (
    birthStatus: birthStatusList,
    wMeasuredTime: string,
    pDOB,
    gestationAge
  ) => {
    switch (birthStatus) {
      case birthStatusList.Mature:
        const ageInDays_WRT_24months = +(
          moment
            .duration(
              this._tz
                .transformIntoTimezoneObj(wMeasuredTime)
                .diff(this._tz.transformIntoTimezoneObj(pDOB))
            )
            .asDays() *
          (24 / 730)
        ).toFixed(2);
        return ageInDays_WRT_24months >= 0 && ageInDays_WRT_24months <= 730
          ? ageInDays_WRT_24months
          : null;
      case birthStatusList.Preterm:
        let { weeks: gestAgeW, days: gestAgeD } = gestationAge;
        let patientAgeInDaysAtMeasuredTime = Math.floor(
          moment
            .duration(
              this._tz
                .transformIntoTimezoneObj(wMeasuredTime)
                .diff(this._tz.transformIntoTimezoneObj(pDOB))
            )
            .asDays()
        );
        const newDaysInGestationAge = gestAgeD + patientAgeInDaysAtMeasuredTime;
        gestAgeW += Math.floor(newDaysInGestationAge / 7);
        gestAgeD = Math.floor(newDaysInGestationAge % 7);
        const corrGestAge = +(gestAgeW + gestAgeD / 7).toFixed(2);
        return corrGestAge >= 23 && corrGestAge <= 49 ? corrGestAge : null;
    }
  };

  /**
   * @description used to make x domain data for new trends
   * @returns [start, end]
   * @author Rajat Saini
   * @date Jun 16, 2023
   */
  makeXDomainData = (trendType: TrendType, configX: XAxisConfig) => {
    switch (trendType) {
      case TrendType.growthChart:
        return configX.domainRange;
      case TrendType.labTrendsChart:
        return configX.domainRange;
      default:
        break;
    }
  };

  /**
   * @description used to make y domain data for new trends
   * @param trendType
   * @param configY
   * @param yDataPoints
   * @returns []
   * @author Rajat Saini
   * @date Jun 16, 2023
   */
  makeYDomainData = (
    trendType: TrendType,
    configY: YAxisConfig,
    yDataPoints: number[]
  ) => {
    let minY, maxY, min, max;
    switch (trendType) {
      case TrendType.growthChart:
        [minY, maxY] = configY.domainRange;
        [min, max] = [Math.min(...yDataPoints), Math.max(...yDataPoints)];
        [minY, maxY] = [min < minY ? min : minY, max > maxY ? max : maxY];
        return [minY, maxY];
      case TrendType.labTrendsChart:
        [minY, maxY] = configY.domainRange;
        [min, max] = [Math.min(...yDataPoints), Math.max(...yDataPoints)];
        [minY, maxY] = [min < minY ? min : minY, max > maxY ? max : maxY];
        return [minY, maxY];
    }
  };

  makeHTMLDataForPopover = (
    trendType: TrendType,
    eventData: GrowthDataPoints,
    patientInfo: Patient
  ) => {
    switch (trendType) {
      case TrendType.growthChart:
        return this.makeHTMLDATAforPopover_Growth_Chart(eventData, patientInfo);
      case TrendType.labTrendsChart:
        return this.makeHTMLDATAforPopover_Lab_Chart(eventData);
      default:
        return "";
    }
  };

  /**
   * @description used to make popover html data for graphs
   * @param eventData
   * @returns
   * @author Rajat Saini
   * @date Jun 18, 2023
   */
  makeHTMLDATAforPopover_Growth_Chart = (
    eventData: GrowthDataPoints,
    patientInfo: Patient
  ) => {
    const htmlLabelConfig =
      GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP[eventData.graphName][
        patientInfo?.birthStatus
      ];
    const firstLineVal = getProperUnitName(
      eventData?.yValue,
      htmlLabelConfig.yUnit,
      false,
      false
    );
    const secLineVal = this.calculateGrowthChartXValueUnitNames(
      eventData?.xValue,
      htmlLabelConfig.xUnit,
      patientInfo
    );
    let thirdLineVal = this._tz
      .transformIntoTimezoneObj(eventData?.timeStamp)
      .format("MMM DD, HH:mm");
    thirdLineVal += ` ( ${this._tz.timeZoneDetails?.abbreviation_fullForm} )`;

    let str = `<div class="tooltip-cont"><span class="labels">${
      eventData?.weightType ? eventData?.weightType : htmlLabelConfig.yLabel
    }:</span> <span class="values">${firstLineVal}</span> <br/>`;
    if (secLineVal && parseInt(secLineVal?.split(" ")[0])) {
      str += ` <span class="labels">${htmlLabelConfig.xLabel}:</span> <span style="font-weight: 700; font-size: 12px">${secLineVal}</span> <br/>`;
    }
    return (str += ` <span class="labels">Date & time:</span> <span style="font-weight: 500; font-size: 12px">${thirdLineVal}</span></div>`);
  };

  makeHTMLDATAforPopover_Lab_Chart = (eventData: GrowthDataPoints) => {
    return eventData.yValue;
  };

  calculateGrowthChartXValueUnitNames = (
    xValue,
    unitName,
    patientInfo: Patient
  ) => {
    let [monthValue, daysValue] = xValue.toString().split(".");
    let str = +monthValue ? `${getProperUnitName(+monthValue, unitName)} ` : "";

    // at the time of xValue calculation 0.90 is converted to 0.9
    // thus leading to unwanted bugs
    // converting [0.9]'s 9 to 90 [0.90]
    daysValue = daysValue?.length === 1 ? +daysValue * 10 : daysValue;
    const properDays = +((daysValue / 100) * 30).toFixed(2) || 0;

    switch (patientInfo?.birthStatus) {
      case birthStatusList.Mature:
        return (str +=
          +properDays >= 1
            ? `${getProperUnitName(Math.floor((+daysValue / 100) * 30), "day")}`
            : `${getProperUnitName(Math.round(properDays * 24), "hour")}`);
      case birthStatusList.Preterm:
        return (str += +daysValue
          ? `${getProperUnitName(Math.round((+daysValue / 100) * 7), "day")}`
          : "");
    }
  };

  /**
   * @description finds max, min data points from graph data points
   * @param dataPoints
   * @returns
   * @author Rajat Saini
   * @date Jun 16, 2023
   */
  tranformMaxMinData = (dataPoints: GrowthDataPoints[]) => {
    return dataPoints?.reduce((arr, currObj) => {
      if (!arr?.length) return [currObj];

      let firstEle = arr[0];
      if (arr?.length == 1) {
        firstEle.yValue > currObj.yValue
          ? arr.push(currObj)
          : arr.unshift(currObj);
        return arr;
      }
      let secondEle = arr[1];
      arr[0] = currObj.yValue > firstEle.yValue ? currObj : firstEle;
      arr[1] = currObj.yValue < secondEle.yValue ? currObj : secondEle;
      return arr;
    }, []);
  };

  /**
   * @description used to align elements on graph view so that they won't go outside of graph
   * @param axis
   * @param value
   * @param minVal
   * @param defaultVal
   * @param graphWidth
   * @param graphHeight
   * @returns
   */
  alignElements = (
    axis: string,
    value,
    minVal,
    defaultVal,
    graphWidth,
    graphHeight,
    valueToSubtractFromYCoordValue = 0
  ) => {
    switch (axis) {
      case "x-axis":
        return value < minVal
          ? defaultVal
          : value > graphWidth - minVal
          ? graphWidth - defaultVal
          : value;
      case "y-axis":
        return value < minVal
          ? defaultVal
          : value > graphHeight - minVal
          ? graphHeight - defaultVal
          : value - valueToSubtractFromYCoordValue;
      default:
        return;
    }
  };

  /**
   * @description makes an array of line co-ordinates
   * @param trendType
   * @param patientBStatus
   * @param patientSex
   * @returns an array of line's co-ordinates
   */
  makeGraphDefaultLines = (
    trendType: TrendType,
    activeGraphName: string,
    patientInfo: Patient
  ) => {
    switch (trendType) {
      case TrendType.growthChart:
        return this.lineForGrowthChart(
          activeGraphName,
          patientInfo?.birthStatus,
          patientInfo?.sex
        );
      default:
        return [];
    }
  };

  lineForGrowthChart = (graphName, pBStatus: birthStatusList, pSex) => {
    const growthLineConfig = GROWTH_OPTION_TO_DEFAULT_LINE_MAP[graphName];
    let lineArray;
    switch (pBStatus) {
      case birthStatusList.Mature:
        lineArray =
          pSex === "M"
            ? growthLineConfig[birthStatusList.Mature].boysLineName
            : growthLineConfig[birthStatusList.Mature].girlsLineName;
        return GROWTH_LINE_COORDS[lineArray].reduce((parArray, currObj) => {
          const age = +((currObj.age * 24) / 730).toFixed(2);
          const lineObjs = this.makeLineObject(currObj, age, 7);

          lineObjs.forEach((lineObj, i) => {
            if (!parArray[i]) {
              parArray[i] = [];
            }
            parArray[i].push(lineObj);
          });
          return parArray;
        }, []);
      case birthStatusList.Preterm:
        lineArray =
          pSex === "M"
            ? growthLineConfig[birthStatusList.Preterm].boysLineName
            : growthLineConfig[birthStatusList.Preterm].girlsLineName;

        const newLines = [
          PRETERM_GROWTH_COORDS[lineArray + "_1"],
          PRETERM_GROWTH_COORDS[lineArray + "_2"],
          PRETERM_GROWTH_COORDS[lineArray + "_3"],
          PRETERM_GROWTH_COORDS[lineArray + "_4"],
          PRETERM_GROWTH_COORDS[lineArray + "_5"],
        ];
        return newLines;
      // return GROWTH_LINE_COORDS[lineArray].reduce((parArray, currObj) => {
      //   const age = currObj.age;
      //   const lineObjs = makeLineObject(currObj, age, 5);

      //   lineObjs.forEach((lineObj, i) => {
      //     if (!parArray[i]) {
      //       parArray[i] = [];
      //     }
      //     parArray[i].push(lineObj);
      //   });
      //   return parArray;
      // }, []);
      default:
        break;
    }
  };

  makeLineObject = (obj, xAge, arrLength) => {
    return Array.from({ length: arrLength }, (_, i) => ({
      xValue: xAge,
      yValue: obj[`line${i + 1}`],
    }));
  };

  makeGraphDefaultLineColor = (trendType: TrendType, patientInfo: Patient) => {
    switch (trendType) {
      case TrendType.growthChart:
        return GROWTH_LINE_COLORS[patientInfo?.birthStatus];
      default:
        return;
    }
  };

  makeBirthAndAdmissionWeightDataPoints = (
    patientInfo: Patient
  ): GrowthDataPoints[] => {
    let birthAdmissionWeightDataPoints = [];
    const admissionWPayload: CALCULATE_WEIGHT = {
      weightObj: patientInfo?.weightObj,
      patientType: patientInfo.patientType,
      weight: patientInfo?.weight,
      addUnits: false,
    };
    const admissionWeight = calculateWeight(admissionWPayload);
    const admissionW_xValue = this.transformXValue(
      patientInfo?.birthStatus,
      this._tz.transformIntoTimezoneStr(patientInfo?.ICUAdmitDate),
      patientInfo?.dob,
      patientInfo?.gestationAge
    );

    valExists(admissionW_xValue) &&
      birthAdmissionWeightDataPoints.push({
        xValue: admissionW_xValue,
        yValue: admissionWeight,
        timeStamp: this._tz.transformIntoTimezoneStr(patientInfo?.ICUAdmitDate),
        createdBy: null,
        updatedBy: null,
        graphName: GROWTH_CHART_OPTIONS.weightForAge,
        pointColor: "#3271DA",
        weightType: GROWTH_WEIGHT_TYPE.Admission,
      } as GrowthDataPoints);

    const birthWPayload: CALCULATE_WEIGHT = {
      weightObj: patientInfo?.birthWeightObj,
      patientType: patientInfo.patientType,
      weight: patientInfo?.birthWeight,
      addUnits: false,
    };
    const birthWeight = calculateWeight(birthWPayload);
    const birthW_xValue = this.transformXValue(
      patientInfo?.birthStatus,
      this._tz.transformIntoTimezoneStr(patientInfo?.dob),
      patientInfo?.dob,
      patientInfo?.gestationAge
    );

    valExists(birthW_xValue) &&
      birthAdmissionWeightDataPoints.push({
        xValue: birthW_xValue,
        yValue: birthWeight,
        timeStamp: this._tz.transformIntoTimezoneStr(patientInfo?.dob),
        createdBy: null,
        updatedBy: null,
        graphName: GROWTH_CHART_OPTIONS.weightForAge,
        pointColor: "#FF7F00",
        weightType: GROWTH_WEIGHT_TYPE.Birth,
      } as GrowthDataPoints);

    return birthAdmissionWeightDataPoints;
  };

  growthChartLineLabelsConfig = (
    trendType: TrendType,
    patientInfo: Patient
  ) => {
    switch (trendType) {
      case TrendType.growthChart:
        return GROWTH_LINE_LABELS_CONFIG[patientInfo?.birthStatus];
      default:
        return;
    }
  };

  /**
   * @description to get trend reference label name
   * @param trendType
   * @param patientInfo
   * @returns
   */
  trendYaxisReferenceLabel = (trendType: TrendType, patientInfo: Patient) => {
    switch (trendType) {
      case TrendType.growthChart:
        return patientInfo.birthStatus === birthStatusList.Mature
          ? "z-scores"
          : "Reference percentiles";
      default:
        return "";
    }
  };

  getTrendReferenceLabelCoords = (
    trendType: TrendType,
    birthStatus: birthStatusList,
    lineCoords: any[]
  ) => {
    if (!lineCoords?.length) return null;
    let firstLineLastCoord;
    let lastLineLastCoord;
    switch (trendType) {
      case TrendType.growthChart:
        switch (birthStatus) {
          case birthStatusList.Mature:
            firstLineLastCoord = lineCoords[0][lineCoords[0]?.length - 1];
            lastLineLastCoord = lineCoords[6][lineCoords[6]?.length - 1];
            return (
              (firstLineLastCoord["yValue"] + lastLineLastCoord["yValue"]) / 2
            );
          case birthStatusList.Preterm:
            firstLineLastCoord = lineCoords[0][lineCoords[0]?.length - 1];
            lastLineLastCoord = lineCoords[4][lineCoords[4]?.length - 1];
            return (
              (firstLineLastCoord["yValue"] + lastLineLastCoord["yValue"]) / 2
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  getTrendReferenceLabelCoordsDisplacements = (
    trendType: TrendType,
    birthStatus: birthStatusList
  ) => {
    switch (trendType) {
      case TrendType.growthChart:
        switch (birthStatus) {
          case birthStatusList.Mature:
            return -20;
          case birthStatusList.Preterm:
            return -50;
          default:
            return 0;
        }
      default:
        return 0;
    }
  };
}
