import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-codeblue-cancel",
  templateUrl: "./codeblue-cancel.component.html",
  styleUrls: ["./codeblue-cancel.component.scss"],
})
export class CodeblueCancelComponent implements OnInit {
  public cancelReasonsForm: FormGroup;
  public cancelFormControl: FormControl;
  public filteredCancelOptions: Observable<string[]>;
  public cancelReasons: string[] = [
    "Patient's condition improved",
    "Clicked by mistake",
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public componentData: any,
    private dialogRef: MatDialogRef<CodeblueCancelComponent>
  ) {}

  ngOnInit(): void {
    this.cancelFormControl = new FormControl();
    this.cancelReasonsForm = new FormGroup({
      reason: this.cancelFormControl,
    });

    this.filteredCancelOptions = this.cancelFormControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cancelReasons.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onSubmit(): void {
    const selectedValue = this.cancelFormControl.value;
    if (selectedValue !== "") {
      this.componentData.callback(selectedValue);
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
