<section
  class="pain-container input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form pain-form"
    autocomplete="off"
    [formGroup]="rPainForm"
    (ngSubmit)="submitPain(rPainForm.value)"
  >
    <!--Pain card-->
    <ng-conatiner *ngIf="!admitFormIsParent; else smallHeading">
      <div class="sub-tittle">Wong Baker Face Scale:</div>
    </ng-conatiner>
    <ng-template #smallHeading>
      <p class="pain-sub-header">Wong Baker Face Scale</p>
    </ng-template>
    <hr *ngIf="!admitFormIsParent" />
    <div class="input-form-header" *ngIf="!admitFormIsParent">
      <div class="score">
        <div
          [ngClass]="{
            'background-success': painScore <= 2 && painScore > 0,
            'background-warning': painScore > 2 && painScore < 8,
            'background-danger': painScore <= 10 && painScore >= 8
          }"
        >
          {{ painScore != null ? painScore : "NA" }}
        </div>
      </div>
      <div class="info">
        <div class="emoji-section">
          <div class="text-success">
            0-2 Mild&nbsp;<mat-icon inline="true">sentiment_satisfied</mat-icon>
          </div>
          <div class="text-warning ml-1">
            3-7 Moderate&nbsp;<mat-icon inline="true"
              >sentiment_neutral</mat-icon
            >
          </div>
          <div class="text-danger">
            8-10 Strong&nbsp;<mat-icon inline="true"
              >sentiment_dissatisfied</mat-icon
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-fields">
      <!-- Pain scale -->
      <!-- <div class="iris-form-row">
        <label for="painScore">Pain level </label>
        <mat-button-toggle-group name="painScore" formControlName="painScore">
          <mat-button-toggle
            *ngFor="let val of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            [value]="val"
            >{{ val }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div> -->

      <div class="iris-form-row">
        <div class="emojis">
          <label for="ej-1">
            <!-- emoji -->
            <ng-container *ngIf="painScore == 0; else default1">
              <img
                class="text-success"
                for="ej-1"
                src="../../../../../assets/icons/smile_2.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default1>
              <img
                class="text-success"
                for="ej-1"
                src="../../../../../assets/icons/default_smile_2.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-1"
              hidden
              type="radio"
              [value]="0"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              0<br />
              <div class="emoji-caption">
                No<br />
                Hurt
              </div>
            </div>
          </label>
          <label for="ej-2">
            <ng-container *ngIf="painScore == 2; else default2">
              <img
                for="ej-2"
                src="../../../../../assets/icons/smile_1.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default2>
              <img
                for="ej-2"
                src="../../../../../assets/icons/default_smile_1.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-2"
              hidden
              type="radio"
              [value]="2"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              2<br />
              <div class="emoji-caption">
                Hurts<br />
                Little Bit
              </div>
            </div>
          </label>
          <label for="ej-3">
            <ng-container *ngIf="painScore == 4; else default3">
              <img
                for="ej-3"
                src="../../../../../assets/icons/meh.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default3>
              <img
                for="ej-3"
                src="../../../../../assets/icons/default_meh.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-3"
              hidden
              type="radio"
              [value]="4"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              4<br />
              <div class="emoji-caption">
                Hurts<br />
                Little More
              </div>
            </div>
          </label>
          <label for="ej-4">
            <ng-container *ngIf="painScore == 6; else default4">
              <img
                for="ej-4"
                src="../../../../../assets/icons/sad.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default4>
              <img
                for="ej-4"
                src="../../../../../assets/icons/default_sad.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-4"
              hidden
              type="radio"
              [value]="6"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              6<br />
              <div class="emoji-caption">
                Hurts<br />
                Even More
              </div>
            </div>
          </label>
          <label for="ej-5">
            <ng-container *ngIf="painScore == 8; else default5">
              <img
                for="ej-5"
                src="../../../../../assets/icons/pain.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default5>
              <img
                for="ej-5"
                src="../../../../../assets/icons/default_pain.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-5"
              hidden
              type="radio"
              [value]="8"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              8<br />
              <div class="emoji-caption">
                Hurts<br />
                Whole Lot
              </div>
            </div>
          </label>
          <label for="ej-6">
            <ng-container *ngIf="painScore == 10; else default6">
              <img
                for="ej-6"
                src="../../../../../assets/icons/crying.svg"
                alt=""
              />
            </ng-container>
            <ng-template #default6>
              <img
                for="ej-6"
                src="../../../../../assets/icons/default_crying.svg"
                alt=""
              />
            </ng-template>
            <input
              id="ej-6"
              hidden
              type="radio"
              [value]="10"
              formControlName="painScore"
            /><br />
            <div class="emoji-text">
              10<br />
              <div class="emoji-caption">
                Hurts<br />
                Worst
              </div>
            </div>
          </label>
        </div>
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row" *ngIf="!admitFormIsParent">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!rPainForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'pain'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
