<div class="notification-tab">
  <div class="notification-text-header">
    <div>RECENT</div>
    <div class="mark-all-read" (click)="markAllRead()">Mark all as read</div>
  </div>
  <ng-container *ngFor="let elem of recentNotifications">
    <app-notification-element
      [notificationContent]="elem"
      (notificationClicked)="handleNotificationClicked(elem)"
    >
    </app-notification-element>
  </ng-container>
  <div class="notification-text-header">OLDER</div>
  <ng-container *ngFor="let elem of olderNotifications">
    <app-notification-element
      [notificationContent]="elem"
      (notificationClicked)="handleNotificationClicked(elem)"
    >
    </app-notification-element>
  </ng-container>
</div>
