import { CreatedByEditedBy } from "src/app/models/patient";
import { QUESTION_TYPE } from "../constants";

export interface InfectionBundle {
  _id: string;
  timestamp: string;
  comment: string;
  createdBy: CreatedByEditedBy;
  updatedBy: CreatedByEditedBy;
  type: INFECTION_BUNDLE_TYPES;
  dayNum: number;
  questions: InfectionBundleQuestions[];
}

export interface NewInfectionBundleChecklist extends InfectionBundle {
  isNewChecklist: boolean;
}

export enum INFECTION_BUNDLE_TYPES {
  VAP = "Ventilator Associated Pneumonia (VAP)",
  CAUTI = "Demo",
  CLABSI = "DEMO",
  SSIP = "DEMO",
}

export const INFECTION_BUNDLES_CONFIG = {
  [INFECTION_BUNDLE_TYPES.VAP]: {
    keyName: "VAP",
    value: "Ventilator Associated Pneumonia (VAP)",
  },
};

export interface InfectionBundleQuestions {
  question: string;
  type: QUESTION_TYPE;
  value: any;
  header?: string;
}

export interface CreateInfBundChecklistPayload {
  CPMRN: string;
  encounters: number;
  checklist: Omit<InfectionBundle, "createdBy" | "updatedBy" | "dayNum">;
}

export interface DefaultQuestionInterface {
  name: string;
  type: QUESTION_TYPE;
  header?: string;
}
