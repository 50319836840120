export interface BedUnitInfoInterface {
  deviceId: string;
  bedNo: string;
  hospitalName: string;
  unitName: string;
}

export type headerColor = headerColors.DEFAULT | headerColors.BLUE;

export enum headerColors {
  DEFAULT = "default",
  BLUE = "blue",
}
