import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { LabsScansService } from "../../services/labs-scans.service";
import * as labDocumentActions from "../actions/lab-request.actions";

@Injectable()
export class LabRequestEffects {
  constructor(
    private actions$: Actions,
    private _labsScanService: LabsScansService
  ) {}

  loadLabRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labDocumentActions.LOAD_LAB_REQUEST),
      map((action: labDocumentActions.LoadLabRequestAction) => action.payload),
      switchMap(({ request }) => {
        return this._labsScanService.submitLabRequest(request).pipe(
          map(
            (code: []) => new labDocumentActions.UploadLabRequestSuccess(code)
          ),
          catchError((error) =>
            of(new labDocumentActions.UploadLabRequestFail(error))
          )
        );
      })
    )
  );

  updateLabRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labDocumentActions.UPDATE_LAB_REQUEST),
      map((action: labDocumentActions.UpdateLabRequest) => action.payload),
      switchMap(({ request, id }) => {
        return this._labsScanService.updateLabRequest(request, id).pipe(
          map(
            (code: []) => new labDocumentActions.UpdateLabRequestSuccess(code)
          ),
          catchError((error) =>
            of(new labDocumentActions.UpdateLabRequestFail(error))
          )
        );
      })
    )
  );

  uploadLabRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labDocumentActions.UPLOAD_LAB_REQUEST),
      map((action: labDocumentActions.LoadLabRequestAction) => action.payload),
      switchMap(() => {
        return this._labsScanService.getLabRequest().pipe(
          map(
            (code: []) => new labDocumentActions.UploadLabRequestSuccess(code)
          ),
          catchError((error) =>
            of(new labDocumentActions.UploadLabRequestFail(error))
          )
        );
      })
    )
  );
}
