import { createAction, props } from "@ngrx/store";

export const sendMessage = createAction(
  "[Patient Chat] send message",
  props<{ CPMRN: string; encounters: number; message: any }>()
);

export const unitSendMessage = createAction(
  "[Unit Chat] send message",
  props<{ unit: any; message: any }>()
);

export const addChat = createAction(
  "[Patient Chat] add message",
  props<{ message: any }>()
);

export const addMultiChat = createAction(
  "[Patient Chat] add multi message",
  props<{ messages: any }>()
);

export const updateChat = createAction(
  "[Patient Chat] update message",
  props<{ message: any }>()
);

export const setChat = createAction(
  "[Patient Chat] update chat",
  props<{ messages: any }>()
);

export const submitChatStart = createAction("[Patient Chat] submit chat start");

export const submitChatSuccess = createAction(
  "[Patient Chat] submit chat success"
);

export const resetForm = createAction("[Patient Chat] submit chat reset");

export const addToDoc = createAction(
  "[Patient Chat] export to documents",
  props<{ imageData: any }>()
);

export const clearDocFile = createAction(
  "[Patient Chat] clear doc file from chat"
);

export const setLastSeen = createAction(
  "[Patient Chat] set last seen",
  props<{ user: any; lastChat: any }>()
);

export const setReadReciepts = createAction(
  "[Patient Chat] set read reciepts",
  props<{ readReciepts: any }>()
);

export const updateReadReciepts = createAction(
  "[Patient Chat] update read reciepts",
  props<{ readReciept: any }>()
);

export const updateLastSeen = createAction(
  "[Patient Chat] update last seen",
  props<{ CPMRN: string; encounters: number; readReciept: any }>()
);

export const showNotification = createAction(
  "[Patient Chat] show notification"
);

export const hideNotification = createAction(
  "[Patient Chat] hide notification"
);

export const resetChat = createAction("[Unit Chat] reset chat");

export const getUnitChat = createAction(
  "[Unit Chat] get unit chat",
  props<{ hospName: string; unitName: string }>()
);

export const unitListUpdateLastSeen = createAction(
  "[Unit Chat] update last seen",
  props<{ unit: any; readReciept: any }>()
);

export const saveDraftChat = createAction(
  "[Patient Chat] save draft chat",
  props<{ message: string }>()
);

export const setChatMinimised = createAction(
  "[Patient Chat] set minimised status"
);

export const openChatTab = createAction("[Patient Chat] open chat tab");
export const closeChatTab = createAction("[Patient Chat] close chat tab");
