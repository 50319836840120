import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { SharedModule } from "../shared/shared.module";
import { RalertRoutingModule } from "./ralert-routing.module";
import { RalertDeviceEffects } from "./effects/ralert-device.effects";
import { RalertMessagesEffects } from "./effects/ralert-messages.effects";
import { RalertDeviceFormService } from "./services/ralert-device-form.service";
import { RalertDeviceListComponent } from "./components/ralert-device-list/ralert-device-list.component";
import { RalertDeviceFormComponent } from "./components/ralert-device-form/ralert-device-form.component";
import { RalertMessageListComponent } from "./components/ralert-message-list/ralert-message-list.component";
import { RalertDeviceListPageComponent } from "./containers/ralert-device-list-page/ralert-device-list-page.component";
import { RalertMessageListPageComponent } from "./containers/ralert-message-list-page/ralert-message-list-page.component";
import { IrisComponentsModule } from "../iris-components/iris-components.module";

@NgModule({
  declarations: [
    RalertMessageListPageComponent,
    RalertMessageListComponent,
    RalertDeviceListPageComponent,
    RalertDeviceListComponent,
    RalertDeviceFormComponent,
  ],
  imports: [
    CommonModule,
    RalertRoutingModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    IrisComponentsModule,
    EffectsModule.forFeature([RalertMessagesEffects, RalertDeviceEffects]),
  ],
  exports: [RalertDeviceListPageComponent],
  providers: [RalertDeviceFormService],
})
export class RalertModule {}
