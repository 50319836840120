<div class="search">
  <div style="display: flex">
    <div class="icon-pos">
      <img src="assets/icons/search.svg" (click)="showSearchBar()" />
    </div>
    <input
      *ngIf="showSearch"
      type="text"
      class="form-control"
      id="inputValidation"
      [placeholder]="'Search for admitted patients...'"
      [(ngModel)]="searchText"
      (keyup)="searchPatients(searchText)"
      autocomplete="off"
      aria-describedby="search-icon"
    />
  </div>

  <div *ngIf="dropDown" class="search__list">
    <div id="dropdown">
      <table class="table">
        <tbody>
          <tr
            tabindex="0"
            class="search__list__item align-items-baseline"
            (click)="setPatient(patient, patient)"
            (keydown.enter)="setPatient(patient, patient)"
            *ngFor="let patient of typeaheadList; index as i"
            [ngClass]="
              patient.class && patient.class == 'selected'
                ? 'search__list__item--selected'
                : ''
            "
            (mouseenter)="setSelected(i)"
          >
            <td
              style="max-width: 8rem"
              [innerHtml]="patient.CPMRN | highlightText: typeTerms"
            ></td>
            <td
              style="max-width: 8rem"
              [innerHtml]="patient.MRN | highlightText: typeTerms"
            ></td>
            <td
              style="max-width: 8rem"
              [innerHtml]="
                patient.name +
                  ' ' +
                  patient.lastName +
                  ' ' +
                  '(' +
                  getPatientAge(patient) +
                  ', ' +
                  patient.sex +
                  ')' | highlightText: typeTerms
              "
            ></td>
            <td
              [innerHtml]="
                patient.hospitalName +
                  ' ' +
                  '(' +
                  patient.unitName +
                  ', ' +
                  patient.bedNo +
                  ')' | highlightText: typeTerms
              "
            ></td>
          </tr>
        </tbody>
      </table>
      <li
        class="search__list__item d-flex align-items-baseline error ml-1"
        *ngIf="noDataMsg"
      >
        No Data Found
      </li>
    </div>
  </div>
</div>
