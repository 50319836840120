import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, mergeMap, catchError, switchMap, tap } from "rxjs/operators";
import { of, EMPTY } from "rxjs";

import * as chatActions from "../actions/chat.actions";
import { ChatService } from "src/app/patient/chat/services/chat.service";

@Injectable()
export class ChatEffects {
  constructor(private actions$: Actions, private chatService: ChatService) {}

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.sendMessage),
      switchMap(({ CPMRN, encounters, message }) =>
        this.chatService.sendMessage(CPMRN, encounters, message).pipe(
          switchMap((data) => {
            const message = (data as any).message;
            const user = {
              name: message.senderName,
              email: message.senderEmail,
            };

            const lastChat = {
              name: message.senderName,
              timestamp: message.timestamp,
            };
            // switchmap since it returns the result out of the observable
            return [
              // chatActions.hideNotification(),
              chatActions.submitChatSuccess(),
              chatActions.setLastSeen({
                user,
                lastChat,
              }),
            ];
          }),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          })
        )
      )
    )
  );

  unitSendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.unitSendMessage),
      switchMap(({ unit, message }) =>
        this.chatService.sendMessageUnitList(unit, message).pipe(
          switchMap((data) => {
            // switchmap since it returns the result out of the observable
            return of(
              // chatActions.hideNotification(),
              chatActions.submitChatSuccess()
            );
          }),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          })
        )
      )
    )
  );

  updateLastSeen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chatActions.updateLastSeen),
        switchMap(({ CPMRN, encounters, readReciept }) =>
          this.chatService.updateLastSeen(CPMRN, encounters, readReciept).pipe(
            tap((data) => EMPTY),
            catchError((err) => EMPTY)
          )
        )
      ),
    { dispatch: false }
  );

  getUnitMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatActions.getUnitChat),
      switchMap(({ hospName, unitName }) =>
        this.chatService.getUnitChatMessages(hospName, unitName).pipe(
          switchMap((data) => {
            let chatMessages = [];
            let readReciepts = [];

            if (data["chatMessages"]["messages"]) {
              chatMessages = data["chatMessages"]["messages"];
            }
            if (data["chatMessages"]["readReciepts"]) {
              readReciepts = data["chatMessages"]["readReciepts"];
            }

            return of(
              chatActions.setChat({ messages: chatMessages }),
              chatActions.setReadReciepts({ readReciepts: readReciepts })
            );
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  unitListUpdateLastSeen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chatActions.unitListUpdateLastSeen),
        switchMap(({ unit, readReciept }) =>
          this.chatService.updateLastSeenUnitList(unit, readReciept).pipe(
            tap((data) => EMPTY),
            catchError((err) => EMPTY)
          )
        )
      ),
    { dispatch: false }
  );
}
