import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "addTimeToDate"
})
export class AddTimeToDatePipe implements PipeTransform {
  transform(date: Date, args?: string): Date {
    const split = args.split(":");
    const hours = parseInt(split[0], 10);
    const mins = parseInt(split[1], 10);
    date.setHours(hours);
    date.setMinutes(mins);
    return date;
  }
}
