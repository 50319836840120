import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromRoot from "../../reducers";
import * as fromProtocol from "./protocol.reducer";
import * as fromProtocolFile from "./protocol-file.reducer";
import * as fromProtocolView from "./protocol-view.reducer";
import * as fromProtocolForm from "./protocol-form.reducer";

export interface ProtocolState {
  data: fromProtocol.State;
  form: fromProtocolForm.State;
  view: fromProtocolView.State;
  file: fromProtocolFile.State;
}

export interface State extends fromRoot.AppState {
  protocols: ProtocolState;
}

export const reducers: ActionReducerMap<ProtocolState, any> = {
  data: fromProtocol.reducer,
  form: fromProtocolForm.reducer,
  view: fromProtocolView.reducer,
  file: fromProtocolFile.reducer,
};

export const getProtocolState =
  createFeatureSelector<ProtocolState>("protocols");

/*
 * Protocol Data State selectors
 * */
export const getProtocolDataState = createSelector(
  getProtocolState,
  (state: ProtocolState) => state.data
);

export const getSelectedProtocolId = createSelector(
  getProtocolDataState,
  fromProtocol.getSelectedProtocolId
);

export const selectProtocolIds = createSelector(
  getProtocolDataState,
  fromProtocol.selectProtocolIds
);

export const selectProtocolEntities = createSelector(
  getProtocolDataState,
  fromProtocol.selectProtocolEntities
);

export const selectAllProtocols = createSelector(
  getProtocolDataState,
  fromProtocol.selectAllProtocols
);

export const selectProtocolTotal = createSelector(
  getProtocolDataState,
  fromProtocol.selectProtocolTotal
);

export const getSelectedProtocol = createSelector(
  selectProtocolEntities,
  getSelectedProtocolId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

/*
 * Protocol Form State selectors
 * */
export const getProtocolFormState = createSelector(
  getProtocolState,
  (state: ProtocolState) => state.form
);

export const getProtocolFormError = createSelector(
  getProtocolFormState,
  fromProtocolForm.getProtocolFormError
);

export const getProtocolFormLoading = createSelector(
  getProtocolFormState,
  fromProtocolForm.getProtocolFormLoading
);

export const getProtocolFormData = createSelector(
  getProtocolFormState,
  fromProtocolForm.getProtocolFormData
);

/*
 * Protocol View State selectors
 * */
export const getProtocolViewState = createSelector(
  getProtocolState,
  (state: ProtocolState) => state.view
);

export const getProtocolViewLoaded = createSelector(
  getProtocolViewState,
  fromProtocolView.getProtocolViewLoaded
);

export const getProtocolViewLoading = createSelector(
  getProtocolViewState,
  fromProtocolView.getProtocolViewLoading
);

/*
 * Protocol File State Selectors
 * */
export const getProtocolFileState = createSelector(
  getProtocolState,
  (state: ProtocolState) => state.file
);

export const selectProtocolFileIds = createSelector(
  getProtocolFileState,
  fromProtocolFile.selectProtocolFileIds
);

export const selectProtocolFileEntities = createSelector(
  getProtocolFileState,
  fromProtocolFile.selectProtocolFileEntities
);

export const selectAllProtocolFiles = createSelector(
  getProtocolFileState,
  fromProtocolFile.selectAllProtocolFiles
);

export const selectProtocolFileTotal = createSelector(
  getProtocolFileState,
  fromProtocolFile.selectProtocolFileTotal
);

export const getProtocolFileByName = createSelector(
  selectProtocolFileEntities,
  (files, name) => name && files[name]
);
