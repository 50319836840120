import { createAction, props } from "@ngrx/store";
import { Orderable } from "../../../models/Orderable.model";

export const addSearchedOrderable = createAction(
  "[Orderable Protocol Page] Add Searched Orderable",
  props<{ orderable: Orderable }>()
);

export const selectOrderable = createAction(
  "[Order Protocol Page] Select Orderable",
  props<{ id: string }>()
);

export const resetSearchedOrderable = createAction(
  "[Order Protocol Page] Reset Orderable"
);

export const clearSelectedOrderable = createAction(
  "[Order Protocol Page] Clear Selected Orderable"
);

export const getOrderable = createAction(
  "[Order Protocol Page] Get Orderable",
  props<{ id: string; brand?: string }>()
);
