import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "lastTag"
})
export class LastTagPipe implements PipeTransform {
  transform(tags: Array<String>, args?: any): any {
    let rTags = tags.filter(tag => tag != null).reverse();
    return rTags[0];
  }
}
