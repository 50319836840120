<app-loader *ngIf="ncpFetchLoader$ | async"></app-loader>
<section class="vital-view-section">
  <ng-container *ngIf="(displayedRows$ | async)?.length > 0">
    <mat-accordion displayMode="flat" class="mat-table">
      <section class="mat-elevation-z2 mat-header-row header-text">
        <span class="mat-header-cell">Author name</span>
        <span class="mat-header-cell">Date created</span>
        <span class="mat-header-cell">Last edited</span>
      </section>
      <ng-container *ngIf="displayedRows$ | async as displayedRows">
        <mat-expansion-panel
          class="trigger-panel"
          *ngFor="let ncp of displayedRows; let i = index"
          [expanded]="expandedPanelIndex === i"
          (opened)="expandedPanelIndex = i"
        >
          <mat-expansion-panel-header
            class="mat-row"
            [ngClass]="{ 'expanded-header': expandedPanelIndex === i }"
          >
            <span class="mat-cell">{{ ncp.createdBy }}</span>
            <span class="mat-cell"
              >{{
                ncp.createdAt
                  | timezone: "DD-MM-YYYY, HH:mm":timeZoneDetail?.name
              }}
              <cp-timezone-label
                *ngIf="timeZoneDetail?.name"
                [timeZoneDetail]="timeZoneDetail"
              ></cp-timezone-label
            ></span>
            <span class="mat-cell"
              >{{
                ncp.updatedAt
                  | timezone: "DD-MM-YYYY, HH:mm":timeZoneDetail?.name
              }}
              <cp-timezone-label
                *ngIf="timeZoneDetail?.name"
                [timeZoneDetail]="timeZoneDetail"
              ></cp-timezone-label
            ></span>
          </mat-expansion-panel-header>
          <div class="accordion-expansion-container iris-form">
            <div class="iris-v-row">
              <div class="row-left"></div>
              <div class="row-right">
                <div class="iris-form-row float">
                  <ng-container *ngxPermissionsOnly="['create_vital']">
                    <button
                      mat-icon-button
                      *ngIf="ncp.createdBy === (getUser$ | async).name"
                      class="cp-cursor-pointer edit"
                      (click)="edit(ncp.plans)"
                    >
                      <img src="assets/icons/edit.svg" />
                    </button>
                  </ng-container>
                  <span>
                    <!-- <mat-icon class="print-icon cp-cursor-pointer mr"
                      >print</mat-icon
                    > -->
                    <button
                      mat-icon-button
                      (click)="printJsPdf(ncp)"
                      [disabled]="disableBtn"
                    >
                      <mat-icon class="print-icon cp-cursor-pointer mr">
                        print
                      </mat-icon>
                      <app-loader
                        [buttonLoader]="true"
                        *ngIf="disableBtn === 'print'"
                      ></app-loader>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <mat-divider class="divider mb"></mat-divider>
            <div *ngFor="let plan of ncp.plans" class="params-body">
              <div class="iris-v-row">
                <div class="row-left">
                  <div class="iris-form-row diagnose-text-style mb">
                    {{ plan.diagnose }}
                  </div>
                </div>
              </div>

              <ng-container
                *ngFor="let param of plan.fields | keyvalueWithoutsorting"
              >
                <div class="iris-v-row param-text-style">
                  <div style="width: 20%">
                    <div class="row-left">
                      {{
                        param.key == "goalsandoutcomes"
                          ? "Goals and outcomes"
                          : (param.key | titlecase)
                      }}:
                    </div>
                  </div>
                  <div style="width: 80%">
                    <div class="row-right param-value">
                      {{ param.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
</section>
