import { DropdownLabelValueNumberInterface } from "src/app/models/dropdown.model";

export const MEAN_BLOOD_PRESSURE_OPTIONS: DropdownLabelValueNumberInterface[] =
  [
    {
      label: ">= 30",
      value: 0,
    },
    {
      label: "20 - 29",
      value: 9,
    },
    {
      label: "< 20",
      value: 19,
    },
  ];

export const LOWEST_TEMPERATURE_OPTIONS: DropdownLabelValueNumberInterface[] = [
  {
    label: "> 96",
    value: 0,
  },
  {
    label: "95 - 96",
    value: 8,
  },
  {
    label: "< 95",
    value: 15,
  },
];

export const PO2_FIO2_OPTIONS: DropdownLabelValueNumberInterface[] = [
  {
    label: ">= 2.5",
    value: 0,
  },
  {
    label: "1 - 2.49",
    value: 5,
  },
  {
    label: "0.3 - 0.99",
    value: 16,
  },
  {
    label: "< 0.3",
    value: 28,
  },
];

export const LOWEST_SERUM_OPTIONS: DropdownLabelValueNumberInterface[] = [
  {
    label: ">= 7.2",
    value: 0,
  },
  {
    label: "7.1 - 7.19",
    value: 7,
  },
  {
    label: "< 7.1",
    value: 16,
  },
];

export const MULTIPLE_SEIZURES_OPTIONS: DropdownLabelValueNumberInterface[] = [
  {
    label: "No",
    value: 0,
  },
  {
    label: "Yes",
    value: 19,
  },
];

export const URINE_OUTPUT_OPTION: DropdownLabelValueNumberInterface[] = [
  {
    label: ">= 1",
    value: 0,
  },
  {
    label: "0.1 - 0.9",
    value: 5,
  },
  {
    label: "< 0.1",
    value: 18,
  },
];

export const APGAR_SCORE_OPTION: DropdownLabelValueNumberInterface[] = [
  {
    label: ">= 7",
    value: 0,
  },
  {
    label: "< 7",
    value: 18,
  },
];

export const BIRTH_WEIGHT_OPTION: DropdownLabelValueNumberInterface[] = [
  {
    label: ">= 1000",
    value: 0,
  },
  {
    label: "750 - 999",
    value: 10,
  },
  {
    label: "< 750",
    value: 17,
  },
];

export const SMALL_FOR_GESTATION_AGE_OPTION: DropdownLabelValueNumberInterface[] =
  [
    {
      label: ">= 3rd %",
      value: 0,
    },
    {
      label: "< 3rd %",
      value: 12,
    },
  ];

// key: gestation_age
// value: 3rd percentile birth weight
export const GESTATION_AGE_TO_3rd_PERCENTILE_B_WEIGHT_MAP = {
  "22": 320,
  "23": 380,
  "24": 430,
  "25": 500,
  "26": 580,
  "27": 670,
  "28": 740,
  "29": 820,
  "30": 920,
  "31": 1030,
  "32": 1140,
  "33": 1280,
  "34": 1420,
  "35": 1580,
  "36": 1750,
  "37": 1920,
  "38": 2120,
  "39": 2350,
  "40": 2520,
  "41": 2660,
  "42": 2750,
};

export const SNAPP_II_EXPECTED_DEATH_MAP_FOR_LESS_1500_BIRTH_WEIGHT: {
  label: string;
  value: string;
}[] = [
  {
    label: "0 - 9",
    value: "0.5%",
  },
  {
    label: "10 - 19",
    value: "2.2%",
  },
  {
    label: "20 - 29",
    value: "4.9%",
  },
  {
    label: "30 - 39",
    value: "9.3%",
  },
  {
    label: "40 - 49",
    value: "15.7%",
  },
  {
    label: "50 - 59",
    value: "22.6%",
  },
  {
    label: "60 - 69",
    value: "32.3%",
  },
  {
    label: "70 - 79",
    value: "43.8%",
  },
  {
    label: ">= 80",
    value: "64.4%",
  },
];

export const SNAPP_II_EXPECTED_DEATH_MAP_FOR_MORE_THAN_1500_BIRTH_WEIGHT: {
  label: string;
  value: string;
}[] = [
  {
    label: "0 - 9",
    value: "0.3%",
  },
  {
    label: "10 - 19",
    value: "1.3%",
  },
  {
    label: "20 - 29",
    value: "2.8%",
  },
  {
    label: "30 - 39",
    value: "6.5%",
  },
  {
    label: "40 - 49",
    value: "13.0%",
  },
  {
    label: "50 - 59",
    value: "19.0%",
  },
  {
    label: "60 - 69",
    value: "32.0%",
  },
  {
    label: "70 - 79",
    value: "47.6%",
  },
  {
    label: ">= 80",
    value: "55.6%",
  },
];
