import { AuthService } from "src/app/services/auth.service";
// import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Subject } from "rxjs";
import { isEmpty } from "lodash-es";
import { select, Store } from "@ngrx/store";
import { filter, takeUntil } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ContextMenuComponent } from "ngx-contextmenu";

import * as fromUser from "./store/reducers/user";
import { CpContextMenuService } from "./services/cp-context-menu.serivce";
import { PermissionService } from "./shared/accessControl/permission.service";

import { socket, initiateSocketConnection } from "./config/socket";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SocketDisconnectComponent } from "./socket-disconnect/socket-disconnect.component";
import { TokenExpiredErrorComponent } from "./token-expired-error/token-expired-error.component";
import { AlertService } from "./iris-components/service/alert.service";
import { AlertInline } from "./iris-components/model/iris-component.model";
import { LocalStorageService } from "./services/local-storage.service";
import { User } from "./models/user";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("contextMenuComponent") contextMenu: ContextMenuComponent;

  public title = "app";
  // public noteBookNoteContent: any;
  // public notebookNoteType: string;
  public userEmail: string;
  public userFullName: string;
  public patient: any;
  // public systems$ = this._store.pipe(select(fromNotebook.getNotebookSystems));
  public todayPageId: string;
  public roundar$ = this._store.pipe(select(fromUser.getUserPreferenceRoundar));
  public highlightedText: string;
  loader = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  // public show_socket_message = false;

  constructor(
    private _store: Store<any>,
    private _cdr: ChangeDetectorRef,
    private cpContextMenuService: CpContextMenuService,
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService: PermissionService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertService
  ) {
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  public localStorageService: LocalStorageService = inject(LocalStorageService);

  ngOnInit(): void {
    const token = this.localStorageService.getItem("token");
    const currentUser: User = this.localStorageService.getObject("currentUser");
    if (token && currentUser && !currentUser.isFamilyUser) {
      if (!socket) {
        initiateSocketConnection();
      }
    }

    if (socket) {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.on("disconnect", (msg) => {
        console.log(msg, "socket disconnect");
        if (msg === "io server disconnect") {
          this.onSocketDisconnect();
        }
      });

      socket.io.on("reconnect", (attempt) => {
        console.log(attempt, "reconnect success");
        this.showReconnectMessage(
          {
            type: "Success",
            title: "Reconnected successfully",
            message: "",
          },
          2000
        );

        this.reconnectMessageShown = false;
      });

      socket.io.on("reconnect_attempt", (attempt) => {
        console.log(attempt, "reconnect attempt");
        if (!this.reconnectMessageShown) {
          this.showReconnectMessage(
            {
              type: "Info",
              title: "Session expired",
              message: "Trying to reconnect...",
            },
            15000
          );

          this.reconnectMessageShown = true;
        }
      });

      socket.io.on("reconnect_error", (error) => {
        console.log(error, "reconnect_error");
      });

      socket.io.on("reconnect_failed", () => {
        console.log("socket reconnect failed");
        this.onSocketDisconnect();
      });
    }

    this._store
      .pipe(
        select(fromUser.getCurrentUserEmail),
        filter((email) => email !== ""),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((email) => {
        this.userEmail = email;
      });

    this._store
      .pipe(
        select(fromUser.getCurrentUserFullName),
        filter((name) => name !== ""),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((name) => {
        this.userFullName = name;
      });

    this._store
      .pipe(
        select(fromUser.getCurrentUserRole),
        filter((role) => role !== ""),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((role) => {
        if (role) {
          if (isEmpty(this.ngxPermissionsService.getPermissions())) {
            this.ngxPermissionsService.loadPermissions(
              this.permissionService.getPermissions(role)
            );
          }
        }
      });

    this.onTokenExpiry();
  }

  checkRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      // Show a loader untill the admit form loads
      if (
        routerEvent.url.includes("admitPatient") ||
        routerEvent.url.includes("med-admin")
      ) {
        this.loader = true;
        this._cdr.detectChanges();
      }
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loader = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mouseup() {
    if (window.getSelection) {
      this.highlightedText = window.getSelection().toString();
    } else {
      this.highlightedText = "";
    }
  }

  public onContextMenu($event: MouseEvent): void {
    if (this.highlightedText !== "") {
      this.cpContextMenuService.onContextMenu($event, {
        content: this.highlightedText,
        type: "selection",
      });
    }
  }

  /**
   * @description This is to show the socket disconnection alert
   * @author Suraj Shenoy
   * @date 30th Nov 2021
   */
  onSocketDisconnect(): void {
    this.dialog.open(SocketDisconnectComponent, {
      autoFocus: true,
      disableClose: true,
    });
  }

  /**
   * @description This is to check the token expiry
   * @author Suraj Shenoy
   * @date 30th Nov 2021
   */
  onTokenExpiry(): void {
    this._authService.tokenExpiredAction.subscribe((data) => {
      if (data) {
        this.dialog.closeAll();

        this.dialog.open(TokenExpiredErrorComponent, {
          autoFocus: true,
          disableClose: true,
        });
      }
    });
  }

  // TODO: merge add with comment and diagnosis into one route

  performRefresh() {
    location.reload();
  }

  /**
   * @description To register whether the reconnect message is shown or not
   */
  reconnectMessageShown: boolean = false;

  /**
   * @description To show the reconnect message
   */
  showReconnectMessage(message: AlertInline, duration: number): void {
    this.alertService.showInlineNotification(
      message,
      "center",
      "bottom",
      duration
    );
  }
}
