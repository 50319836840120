// Config.ts
interface Template {
  templateDisplayName: string;
  height: string;
}

interface SubtypeConfig {
  notesubtype: string;
  templates: Template[];
}

interface NotetypeConfig {
  notetype: string;
  subtypes: SubtypeConfig[];
}

const sharedTemplates: { [key: string]: Template[] } = {
  commonTemplate: [
    { templateDisplayName: "Summary", height: "quill-small" },
    { templateDisplayName: "Diagnosis", height: "quill-small" },
    { templateDisplayName: "Assessment", height: "quill-large" },
    { templateDisplayName: "To-do's", height: "quill-small" },
    { templateDisplayName: "History", height: "quill-large" },
    { templateDisplayName: "Admission details", height: "quill-large" },
    { templateDisplayName: "Systemic examination", height: "quill-large" },
    { templateDisplayName: "Medication", height: "quill-small" },
    { templateDisplayName: "Reports", height: "quill-small" },
    { templateDisplayName: "Plan of treatment", height: "quill-large" },
    { templateDisplayName: "Procedures/Surgery", height: "quill-large" },
    { templateDisplayName: "Nutritional Care Plan", height: "quill-large" },
  ],
};

const config: NotetypeConfig[] = [
  {
    notetype: "Progress",
    subtypes: [
      {
        notesubtype: "NICU progress note L1",
        templates: [
          { templateDisplayName: "Summary", height: "quill-large" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
      {
        notesubtype: "NICU progress note L2",
        templates: [
          { templateDisplayName: "Summary", height: "quill-large" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
      {
        notesubtype: "NICU progress note L3",
        templates: [
          { templateDisplayName: "Summary", height: "quill-large" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
      {
        notesubtype: "HDU Post-Op",
        templates: [
          { templateDisplayName: "Summary", height: "quill-medium" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
      {
        notesubtype: "COVID-19",
        templates: [
          { templateDisplayName: "Summary", height: "quill-medium" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
    ],
  },
  {
    notetype: "Procedure",
    subtypes: [
      {
        notesubtype: "MICU Template",
        templates: [
          { templateDisplayName: "Summary", height: "quill-large" },
          { templateDisplayName: "Diagnosis", height: "quill-large" },
          { templateDisplayName: "Assessment", height: "quill-small" },
          ...sharedTemplates["commonTemplate"],
        ],
      },
    ],
  },
];

export function getTemplatesStyles(
  notetype: string,
  notesubtype: string
): Template[] {
  for (const typeConfig of config) {
    if (typeConfig.notetype === notetype) {
      for (const subtypeConfig of typeConfig.subtypes) {
        if (subtypeConfig.notesubtype === notesubtype) {
          return subtypeConfig.templates;
        }
      }
    }
  }
  // If no matching notetype and subtype found, return sharedTemplates
  return sharedTemplates["commonTemplate"];
}
