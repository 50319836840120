import { Action } from "@ngrx/store";

export enum ActionTypes {
  set = "[Patient] set"
}

export class SetPatient implements Action {
  readonly type = ActionTypes.set;
  constructor(public payload: any) {}
}

export type Actions = SetPatient;
