<ol class="cp-breadcrumb" *ngIf="breadcrumbs">
    <li class="cp-breadcrumb__item" *ngFor="let breadcrumb of breadcrumbs; index as i">
        <a routerLink="{{ breadcrumb.link }}" class="cp-breadcrumb__link"
           *ngIf="i + 1 < breadcrumbs.length; else elseBlock">
            {{ breadcrumb.name }}
        </a>

        <ng-template #elseBlock>{{ breadcrumb.name }}</ng-template>
    </li>
</ol>
