<div
  class="progress"
  (mousedown)="progressChange($event)"
  #progress
  [attr.data-buffer]="buffered"
>
  <span class="progress__buffer" [style.width]="buffered + '%'"></span>
  <span class="progress__bar" [style.width.%]="percentage">
    <span class="progress__bar__draggable" (mousedown)="onMouseDown()"></span>
  </span>
</div>
