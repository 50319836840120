import { Action, createReducer, on } from "@ngrx/store";
import { TrendCommonReducerInterface } from "../state";
import * as fromTrendCommonActions from "../actions/trend-common.actions";

const initialState: TrendCommonReducerInterface = {
  trendType: null,
};

const trendCommonReducer = createReducer(
  initialState,
  on(fromTrendCommonActions.setTrendCommonConfig, (state, { config }) => ({
    ...state,
    ...config,
  })),
  on(fromTrendCommonActions.resetTrendConfig, (state) => ({
    ...initialState,
  }))
);

export function reducer(state: TrendCommonReducerInterface, action: Action) {
  return trendCommonReducer(state, action);
}
