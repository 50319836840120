import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "allUnitsHidden",
})
export class AllUnitsHiddenPipe implements PipeTransform {
  transform(unitList: any[]): boolean {
    if (!Array.isArray(unitList)) return;
    return unitList.every((unit) => unit.isHidden);
  }
}
