import { createAction, props } from "@ngrx/store";
import { DraftNotes } from "@notes-view/model/notes-model";

export const LOAD_DRAFT_NOTE_DATAS = "[NOTE-PATIENT-DATA] load draft note data";
export const UPDATE_DRAFT_NOTE_DATA =
  "[NOTE-PATIENT-DATA] update draft note data";
export const DELETE_DRAFT_NOTE_DATA =
  "[NOTE-PATIENT-DATA] delete draft note data";
export const RESET_DRAFT_NOTE_STORE =
  "[RESET-DRAFT-NOTE-PATIENT-DATA] reset draft note data";

export const loadDraftNotes = createAction(
  LOAD_DRAFT_NOTE_DATAS,
  props<{ notes: DraftNotes[] }>()
);

export const updateDraftNote = createAction(
  UPDATE_DRAFT_NOTE_DATA,
  props<{ note: DraftNotes }>()
);

export const deleteDraftNote = createAction(
  DELETE_DRAFT_NOTE_DATA,
  props<{ refId: string }>()
);

export const resetDraftNoteContent = createAction(RESET_DRAFT_NOTE_STORE);
