import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { NcpList, NcpResponse, UpdateNcp } from "../type/ncp-data.interface";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NcpService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private _httpClient: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }
  getdiagnoses(): Observable<any> {
    return this._httpClient.get(`${this.apiUrl}nursing_care/diagnoses`);
  }

  createNcp(
    ncpList: NcpList[],
    cpmrn: string,
    encounter: number
  ): Observable<NcpResponse> {
    return this._httpClient
      .post<any>(
        `${this.apiUrl}nursing_care/${cpmrn}/${encounter}`,
        ncpList,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
  fetchNcp(cpmrn: string, encounter: number): Observable<any> {
    return this._httpClient.get(
      `${this.apiUrl}nursing_care/${cpmrn}/${encounter}`
    );
  }

  updateNcp(
    ncpList: UpdateNcp[],
    cpmrn: string,
    encounter: number
  ): Observable<NcpResponse> {
    return this._httpClient
      .patch<any>(
        `${this.apiUrl}nursing_care/${cpmrn}/${encounter}`,
        ncpList,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
}
