import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, mergeMap, catchError, switchMap } from "rxjs/operators";
import { of, EMPTY } from "rxjs";

import * as ioActions from "../actions/patient-chart/io/io.actions";
import { IoService } from "src/app/services/io.service";
import { GetOrderService } from "src/app/patient/intake-output/io-view/get-order-data";

@Injectable()
export class IoEffects {
  constructor(
    private actions$: Actions,
    private ioService: IoService,
    private _getOrderService: GetOrderService
  ) {}

  getIo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ioActions.getIo),
      switchMap(({ CPMRN, encounters }) =>
        this.ioService.getIoData({ cpmrn: CPMRN, encounters: encounters }).pipe(
          map((data) => {
            let ioObj = {};
            let intakeProps = {
              infusionProps: [],
              bolusProps: [],
            };
            // setup med props
            if (data["data"]["io"].medProps?.length) {
              data["data"]["io"].medProps.forEach((medProp) => {
                if (medProp.medType === "infusion") {
                  intakeProps.infusionProps.push(medProp.name);
                }
                if (medProp.medType === "bolus") {
                  intakeProps.bolusProps.push(medProp.name);
                }
              });
            }
            let outputProps: {};
            let proceduresConnected = {};

            ioObj = data["data"]["io"]["days"];
            outputProps = data["data"]["io"]["props"];

            let tempInfusionProps: any = [];
            let tempBolusProps: any = [];
            data["data"]["io"]["days"].forEach((day) => {
              day.hours.forEach((hr) => {
                // get infusion
                if (hr.intake && hr.intake.meds && hr.intake.meds.infusion) {
                  hr.intake.meds.infusion.forEach((inf) => {
                    if (tempInfusionProps.indexOf(inf.name) < 0) {
                      tempInfusionProps.push(inf.name);
                    }
                  });
                }

                // get bolus
                if (hr.intake && hr.intake.meds && hr.intake.meds.bolus) {
                  hr.intake.meds.bolus.forEach((bol) => {
                    if (tempBolusProps.indexOf(bol.name) < 0) {
                      tempBolusProps.push(bol.name);
                    }
                  });
                }
              });
            });

            // order has data
            if (Object.keys(data["data"]["orders"]).length > 0) {
              let dischargedDate = data["data"]["ICUDischargeDate"]
                ? data["data"]["ICUDischargeDate"]
                : null; // check if discharge date exists

              let tempRes = this._getOrderService.populateOrder(
                data["data"]["orders"],
                data["data"]["ICUAdmitDate"],
                ioObj,
                data["data"]["weightKg"],
                dischargedDate
              );
              proceduresConnected = tempRes.proceduresConnected;
            }

            return ioActions.setIo({
              ioObj,
              intakeProps,
              outputProps,
              proceduresConnected,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateIo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ioActions.updateIo),
      mergeMap(({ obj, CPMRN, ioType, encounters }) =>
        this.ioService.submitIoData(obj, CPMRN, ioType, encounters).pipe(
          map((io) => {
            return ioActions.submitSuccess();
          }),
          catchError((error) => {
            return of({ type: ioActions.submitFail.type, error: error });
          })
        )
      )
    )
  );
}
