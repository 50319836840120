<div class="order-date-time-picker-comp">
  <div [formGroup]="form">
    <div
      class="start-now"
      [ngClass]="startTimeStatus ? 'disable-div-startNow' : ''"
    >
      <mat-checkbox
        formControlName="startNow"
        [checked]="checked"
        (change)="onStartNow($event.checked)"
        >Start now</mat-checkbox
      >
    </div>

    <div
      class="form-date-group"
      formGroupName="startTime"
      [ngClass]="startTimeStatus ? 'disable-div' : ''"
      *ngIf="config.startTime.present"
    >
      <div class="date-picker-cont">
        <div class="date-label">
          <b> Start date* <cp-timezone-label></cp-timezone-label></b>
        </div>
        <div class="date-picker">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label></mat-label>
            <input
              matInput
              class="date-input"
              placeholder="yyyy/mm/dd"
              [min]="minTime"
              [max]="maxTime"
              [matDatepicker]="startPicker"
              [errorStateMatcher]="dateRangeError"
              (dateChange)="onStartDateChange($event)"
              formControlName="date"
            />
            <mat-datepicker-toggle matPrefix [for]="startPicker">
              <mat-icon aria-label="calender icon">date_range</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error
              *ngIf="
                startTime.get('date').hasError('matDatepickerMin') ||
                startTime.get('date').hasError('matDatepickerMax')
              "
              >Invalid date</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="time-picker-cont">
        <div class="time-label">
          <b> Start time* <cp-timezone-label></cp-timezone-label></b>
        </div>

        <div class="time-picker">
          <div class="hour-picker">
            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <mat-label></mat-label>
              <input
                type="text"
                pattern="\d*"
                maxlength="2"
                [errorStateMatcher]="dateRangeError"
                placeholder="HH"
                matInput
                class="order-input hour-input"
                formControlName="hour"
                (change)="
                  onStartTimeHourMinuteChange();
                  setPaddedValue($event.target.value, 'startTime', 'hour')
                "
              />
            </mat-form-field>
          </div>
          <div class="separator form-date-group_padding-bottom">:</div>
          <div class="minute-picker">
            <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
              <mat-label></mat-label>
              <input
                type="text"
                pattern="\d*"
                maxlength="2"
                matInput
                [errorStateMatcher]="dateRangeError"
                placeholder="MM"
                class="order-input minute-input"
                formControlName="minute"
                (change)="
                  onStartTimeHourMinuteChange();
                  setPaddedValue($event.target.value, 'startTime', 'minute')
                "
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div
      class="form-date-group"
      formGroupName="endTime"
      *ngIf="config.endTime.present"
      [ngClass]="frequency == 'once' ? 'disable-div-startNow' : ''"
    >
      <div class="date-picker-cont">
        <div class="date-label">
          <b>End date </b> <cp-timezone-label></cp-timezone-label>
        </div>
        <div class="date-picker">
          <mat-form-field floatLabel="always">
            <mat-label></mat-label>
            <input
              matInput
              class="date-input"
              placeholder="yyyy/mm/dd"
              [matDatepicker]="endPicker"
              [errorStateMatcher]="dateRangeError"
              formControlName="date"
            />
            <mat-datepicker-toggle matPrefix [for]="endPicker">
              <mat-icon aria-label="calender icon">date_range</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="form.hasError('endDateLesser')">
              Date/Time Range is Invalid
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="time-picker-cont">
        <div class="time-label">
          <b>End time </b> <cp-timezone-label></cp-timezone-label>
        </div>

        <div class="time-picker">
          <div class="hour-picker">
            <mat-form-field floatLabel="always">
              <mat-label></mat-label>
              <input
                type="text"
                pattern="\d*"
                maxlength="2"
                matInput
                placeholder="HH"
                class="order-input hour-input"
                [errorStateMatcher]="dateRangeError"
                formControlName="hour"
                (change)="
                  setPaddedValue($event.target.value, 'endTime', 'hour')
                "
              />
            </mat-form-field>
          </div>
          <div class="separator form-date-group_padding-bottom">:</div>
          <div class="minute-picker">
            <mat-form-field floatLabel="always">
              <mat-label></mat-label>
              <input
                type="text"
                pattern="\d*"
                maxlength="2"
                matInput
                placeholder="MM"
                class="order-input minute-input"
                [errorStateMatcher]="dateRangeError"
                formControlName="minute"
                (change)="
                  setPaddedValue($event.target.value, 'endTime', 'minute')
                "
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
