<div role="button"
     class="cp-btn cp-hvr-info-p text-decoration-none my-2"
     (click)="editedPatientMonitor = null; openModal(patientMonitorFormModal)">
	New Patient Monitor
</div>

<app-patient-monitor-list
		[monitors]="monitors$ | async"
		(deleteClick)="onDelete($event)"
		(editClick)="onEdit($event, patientMonitorFormModal)">
</app-patient-monitor-list>

<ng-template #patientMonitorFormModal let-modal>
	<div class="modal-header">
		<h6 class="modal-title">
			{{ editedPatientMonitor ? 'Update' : 'Create' }} Patient Monitor
		</h6>
		<div class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</div>
	</div>
	<div class="modal-body">
		<app-patient-monitor-form
				[hospitals]="hospitals"
				[data]="editedPatientMonitor"
				[loading]="false"
				(submitting)="onSubmit($event); modal.dismiss('Submitting')">
		</app-patient-monitor-form>
	</div>
</ng-template>
