import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth.guard';
import { PatientMonitorListPageComponent } from './containers/patient-monitor-list-page/patient-monitor-list-page.component';


const routes: Routes = [
    {
        path: 'hospital-admin/patient-monitor',
        component: PatientMonitorListPageComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PatientMonitorRoutingModule {
}
