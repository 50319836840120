import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientMonitor } from '../../../models/patientMonitor/patientMonitor.model';

@Component({
    selector: 'app-patient-monitor-list',
    templateUrl: './patient-monitor-list.component.html',
    styleUrls: ['./patient-monitor-list.component.scss']
})
export class PatientMonitorListComponent implements OnInit {
    @Input() monitors: PatientMonitor[];
    @Output() editClick: EventEmitter<PatientMonitor> = new EventEmitter<PatientMonitor>();
    @Output() deleteClick: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    /*
    * NAME: onEditClick
    * PURPOSE:
    * DESCRIPTION:
    * PARAMS: monitor:PatientMonitor
    * RETURNS: void
    * USED BY: template
    * CREATED DATE: 22/07/20
    * AUTHOR: Gunjit Agrawal
    */
    onEditClick(monitor: PatientMonitor): void {
        this.editClick.emit(monitor);
    }

    /*
    * NAME: onDeleteClick
    * PURPOSE:
    * DESCRIPTION:
    * PARAMS: monitor:PatientMonitor
    * RETURNS: void
    * USED BY: template
    * CREATED DATE: 22/07/20
    * AUTHOR: Gunjit Agrawal
    */
    onDeleteClick(monitor: PatientMonitor): void {
        this.deleteClick.emit(monitor._id);
    }
}
