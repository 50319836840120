import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lab-history-view',
  template: `
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Discipline:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.discipline">
            {{ order.discipline }}
        </span>
			  <span *ngIf="!order?.discipline">-</span>
		  </div>
	  </div>

    <div class="details-modal__text">
	    <span class="details-modal__text__title">Specimen Type:</span>
	    <div class="details-modal__text__content">
        <span *ngIf="order?.specimenType">
            {{ order.specimenType !== 'N/A' ? order.specimenType : 'N/A' }}
        </span>
		    <span *ngIf="!order?.specimenType">-</span>
	    </div>
    </div>

	  <app-history-common-view [data]="order"></app-history-common-view>
  `,
  styleUrls: ['../details-modal-common.scss']
})
export class LabHistoryViewComponent {
  @Input() order;
}

