import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { GoogleFileSigned } from '../../models/file/GoogleFileSigned.model';

@Injectable({ providedIn: 'root' })
export class GoogleStorageService {
  public env = environment;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server error');
  }

  getSignedUrl(file: GoogleFileSigned): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/google/getSignedUrl`, file, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  uploadFile(file: File, url: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': type,
      DoNotIntercept: 'true'
    });

    const req = new HttpRequest('PUT', url, file, { headers, reportProgress: true });

    return this.http.request(req).pipe(catchError(this.errorHandler));
  }
}
