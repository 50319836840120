import { createReducer, on, Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import * as fromHospitalListActions from "../../actions/hospitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { ProtocolResolverActions } from "../../../protocol/actions";
import { Hospital } from "src/app/models/hospital";

export interface State extends EntityState<Hospital> {}

export function selectHospitalId(hosp: Hospital): string {
  //In this case this would be optional since primary key is _id
  return hosp._id;
}

export function sortByName(a: Hospital, b: Hospital): number {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<Hospital> = createEntityAdapter<Hospital>({
  selectId: selectHospitalId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState();

const hospitalListReducer = createReducer(
  initialState,
  on(
    fromHospitalListActions.setHospitals,
    fromHospitalListActions.setPublicHospitals,
    (state, { hospitals }) => {
      return adapter.setAll(hospitals, state);
    }
  ),
  on(fromHospitalListActions.resetHospitals, (state) =>
    adapter.removeAll({ ...state })
  ),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state }))
);

const { selectAll } = adapter.getSelectors();

// select the array of hospitals
export const selectAllHospitals = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return hospitalListReducer(state, action);
}
