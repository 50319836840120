export class ioObj {
  dayDate: Date;
  dayNumber: Number;
  hours: [
    {
      hourName: number;
      intake: {
        feeds: {
          oral: number;
          tube: number;
          tpnPn: number;
          meals: number;
          others: {
            name: String;
            value: String;
          };
        };
        bloodProducts: {
          prbc: number;
          wholeBlood: number;
          ffp: number;
          cyro: number;
          platelets: number;
          pRPlasma: number;
        };
        meds: {
          infusion: String;
          bolus: String;
        };
        meals: String;
        others: {
          name: String;
          value: String;
        };
        note: String;
      };
      output: {
        urine: Number;
        drains: {
          type: String;
          site: String;
          latrality: String; //(l / r)
          ampunt: String;
        };
        procedure: {
          site: String;
          ampunt: String;
        };
        dialysis: String;
        stool: String;
        emesis: String;
        NG_aspirate: Number;
        others: String;
        note: String;
      };
    }
  ];
}

export class IntakeObj {
  category: String;
  subCategory: String;
  otherFeeds: String;
  medName: String;
  amount: Number;
  note: String;
  date: Date;
  time: String;
}

export class OutputObj {
  category: String;
  subCategory: String;
  otherFeeds: String;
  amount: Number;
  note: String;
  date: Date;
  time: String;
  drainType: String;
  procedureType: String;
  dialysisType: String;
  name: String;
  site: String;
  laterality: String;
}

export const bloodProds = [
  { name: "PRBC", prop: "prbc" },
  { name: "Whole blood", prop: "wholeBlood" },
  { name: "FFP", prop: "ffp" },
  { name: "Cryoprecipitate", prop: "cyro" },
  { name: "Single Donor Platelets", prop: "sdp" },
  { name: "Random Donor Platelets", prop: "rdp" },
  { name: "Platelet-rich Plasma", prop: "prp" },
];
