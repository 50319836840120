import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-comm-history-view',
  template: `
	  <div class="col details-modal__text">
		  <div class="details-modal__text__title">GCS:</div>
		  <div class="details-modal__text__content">{{ order.gcs === false ? 'false' : order.gcs ? 'true' : '-' }}</div>
	  </div>
	  <app-history-common-view [data]="order"></app-history-common-view>
  `,
  styleUrls: ['../details-modal-common.scss']
})
export class CommHistoryViewComponent {
  @Input() order;
}
