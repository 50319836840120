import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { of, EMPTY } from "rxjs";

import * as notesActions from '../actions/patient-chart/notes/notes.actions';
import { NotesService } from 'src/app/services/notes.service';

@Injectable()
export class NotesEffects {
    constructor(
        private actions$: Actions,
        private notesService: NotesService
    ) {}

    getNotes$ = createEffect( () => this.actions$.pipe(
        ofType(notesActions.getNotes),
        switchMap(({CPMRN, encounters, currUser}) => this.notesService.getNote(CPMRN, encounters, currUser).pipe(
            map(notes => {
                return notesActions.setNotes({notes});
            }),
            catchError(() => EMPTY)
        ))
    ));
}