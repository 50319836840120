import { createAction, props } from "@ngrx/store";

import {
  DisplayShortcutApi,
  Orderable,
  SetDefaultApi,
} from "../../../models/Orderable.model";
import { LabPreset } from "../../../models/preset/LabPreset.model";
import { MedPreset } from "../../../models/preset/MedPreset.model";
import { DietPreset } from "../../../models/preset/DietPreset.model";
import { BloodPreset } from "../../../models/preset/BloodPreset.model";
import { ProcedurePreset } from "../../../models/preset/ProcedurePreset.model";
import { CommunicationPreset } from "../../../models/preset/CommunicationPreset.model";

export const addOrderable = createAction(
  "[Orderable Page] Add Orderable",
  props<{ orderable: Orderable }>()
);

export const updateOrderable = createAction(
  "[Orderable Page] Update Orderable",
  props<{ id: string; orderable: Orderable }>()
);

export const deleteOrderable = createAction(
  "[Orderable Page] Delete Orderable",
  props<{ id: string }>()
);

export const setDefault = createAction(
  "[Orderable Page] Set Default Orderable",
  props<{ data: SetDefaultApi }>()
);

export const deletePreset = createAction(
  "[Orderable Page] Delete Preset",
  props<{ orderableId: string; presetId: string }>()
);

export const setDisplayShortcut = createAction(
  "[Orderable Page] Set Display Shortcut",
  props<{ data: DisplayShortcutApi }>()
);
