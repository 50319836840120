import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Notes } from "../models/notes";
import { Content } from "../models/notes-content";
import { environment } from "src/environments/environment";
import { CodeSheet } from "../models/code-sheet";
import { Store, select } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user/index";

@Injectable({
  providedIn: "root"
})
export class NotesService {
  env = environment;
  public user;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));

  constructor(
    private http: HttpClient, 
    private store: Store<{}>,
  ) {
    this.user$.subscribe(data => {
      if (data && data.email) {
        this.user = data
      }
    })
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  createNote(CPMRN, encounters, notes): Observable<Notes> {
    return this.http.post<Notes>(this.env.apiUrl + "notes/" + CPMRN + "/" + encounters, notes, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getNote(CPMRN, encounters, currentUser): Observable<Notes[]> {
    return this.http
      .get<Notes[]>(this.env.apiUrl + "notes/" + CPMRN + "/" + encounters + "/" + currentUser)
      .pipe(catchError(this.errorHandler));
  }

  getFinalNote(CPMRN, encounters, currentUser): Observable<Notes[]> {
    return this.http
      .get<Notes[]>(this.env.apiUrl + "notes/" + CPMRN + "/" + encounters + "/" + currentUser)
      .pipe(catchError(this.errorHandler));
  }

  updateNote(CPMRN, encounters, id, note): Observable<Content> {
    return this.http
      .put<Content>(this.env.apiUrl + "notes/" + CPMRN + "/" + encounters + "/" + id, note)
      .pipe(catchError(this.errorHandler));
  }

  lockNote(data): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/locknote/", data)
      .pipe(catchError(this.errorHandler));
  }

  deleteDraft(note): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/deletedraft/", note)
      .pipe(catchError(this.errorHandler));
  }
  
  updateCodesheet(CPMRN, encounters, codesheet): Observable<CodeSheet> {
    return this.http
      .put<CodeSheet>(this.env.apiUrl + "notes/codesheet/updateCodeSheet/" + CPMRN + "/" + encounters + "/", codesheet)
      .pipe(catchError(this.errorHandler));
  }

  getCodesheetDraft(CPMRN, encounters): Observable<any> {
    return this.http
      .get<CodeSheet>(this.env.apiUrl + "notes/codesheetdraft/" + CPMRN + "/" + encounters)
      .pipe(catchError(this.errorHandler));
  }

  deleteCodesheet(delInfo): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/deleteCodesheet/", delInfo)
      .pipe(catchError(this.errorHandler));
  }

  deletePendedNote(deletePend): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/deletepended/", {...deletePend}) 
      .pipe(catchError(this.errorHandler));
  }
  
  prepopulate(CPMRN, encounters): Observable<any> {
    return this.http
      .get<any[]>(this.env.apiUrl + "notes/prepopulate/" + CPMRN + "/" + encounters)
      .pipe(catchError(this.errorHandler));
  }

  public identification: any = null;
  private data;

  private notesGetUpdateSource = new BehaviorSubject(this.identification);
  notifyNotesGetUpdate = this.notesGetUpdateSource.asObservable();

  getNotesFound(value) {
    this.notesGetUpdateSource.next(value);
  }

  updateNotesForEdit(value, type) {
    this.data = { data: value, type: type };
    this.notesGetUpdateSource.next(this.data);
  }

  /**
   * Setting notes value so that it can be used in other component
   */
  private latestNotesSource = new BehaviorSubject(this.identification);
  notifyLatestNotes = this.latestNotesSource.asObservable();

  setLatestNotes(value) {
    this.latestNotesSource.next(value);
  }
  
  /**
   * Setting notes count
   */
  private latestNotesCountSource = new BehaviorSubject(this.identification);
  notifyLatestNotesCount = this.latestNotesCountSource.asObservable();

  setLatestNotesCount(value) {
    this.latestNotesCountSource.next(value);
  }

  /**
   * Confirmed to delete
   */
  // private deleteNoteConfirmedSource = new BehaviorSubject(this.identification);
  // notifyDeleteNoteConfirmed = this.deleteNoteConfirmedSource.asObservable();

  // deleteNoteConfirmed(value) {
  //   this.deleteNoteConfirmedSource.next(value);
  // }
  
}
