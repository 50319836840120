import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-input-side-chip",
  templateUrl: "./input-side-chip.component.html",
  styleUrls: ["./input-side-chip.component.scss"],
})
export class InputSideChipComponent implements OnInit {
  @Input() labelText: string = "";
  @Input() config: { width: string; height: string; skipRightBorder: boolean };

  constructor() {}

  ngOnInit(): void {}
}
