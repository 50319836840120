export interface RAlertDevice {
  _id: string;
  deviceId: string;
  comment: string;
  type: string;
  hospitalName: string;
  unitName: string;
  bedNo: string;
  hospitalLogo: string;
  wifi: RAlertDeviceWifi[];
  online: boolean;
}

export interface RAlertDeviceWifi {
  ssid: string;
  psk: string;
  dns?: number[];
  ip?: number[];
  subnet: number[];
  gateway: number[];
  advanced: boolean;
}

export interface RAlertDeviceForm {
  deviceId: string;
  comment: string;
  hospitalName: string;
  unitName: string;
  hospitalLogo: string;
  wifi: RAlertDeviceWifi[];
}

export interface RAlertDeviceStatus {
  deviceId: string;
  status?: string;
}

export interface RAlertHospital {
  _id: string;
  name: string;
  logo: string;
  units: RAlertHospitalUnit[];
}

export interface RAlertHospitalUnit {
  _id: string;
  name: string;
  ralertDeviceExists: boolean;
}
