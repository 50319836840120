import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { Alert } from "../model/iris-component.model";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Alert) {}

  ngOnInit() {}

  getAlertIcon() {
    switch (this.data.type) {
      case "Info":
        return "info";
      case "Warning":
        return "warning";
      case "Success":
        return "check_circle";
      case "Error":
        return "error";
    }
  }

  ngOnDestroy() {}
}
