import { Action } from "@ngrx/store";
import { CodeSheet } from "../../model/code-sheet.model";

// Create codesheet notes

export const CREATE_CODE_SHEET_NOTE =
  "[Patient Notes Module] Create Code-sheet Note";
export const CREATE_CODE_SHEET_NOTE_SUCCESS =
  "[Patient Notes Module] Create Code-sheet Note Success";
export const CREATE_CODE_SHEET_NOTE_FAIL =
  "[Patient Notes Module] Create Code-sheet Note Fail";
export const DELETE_CODE_SHEET_NOTE =
  "[Patient Notes Module] Delete Code-sheet Note";
export const CREATE_CODE_SHEET_DRAFT_NOTE =
  "[Patient Notes Module] Create Code-sheet Draft Note";
export const CREATE_CODE_SHEET_DRAFT_NOTE_SUCCESS =
  "[Patient Notes Module] Create Code-sheet Draft Note Success";
export const CREATE_CODE_SHEET_DRAFT_NOTE_FAIL =
  "[Patient Notes Module] Create Code-sheet Draft Note Fail";
export const LOAD_CODESHEET_NOTE_SUCCESS =
  "[Patient Notes Module] Load CodeSheet Notes Success";

export class CreateCodeSheet implements Action {
  readonly type = CREATE_CODE_SHEET_NOTE;
  constructor(
    public payload: { CPMRN: string; encounters: Number; data: CodeSheet }
  ) {}
}

export class CreateCodeSheetDraft implements Action {
  readonly type = CREATE_CODE_SHEET_DRAFT_NOTE;
  constructor(
    public payload: { CPMRN: string; encounters: Number; codeSheet: CodeSheet }
  ) {}
}

export class CreateCodeSheetDraftSuccess implements Action {
  readonly type = CREATE_CODE_SHEET_DRAFT_NOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateCodeSheetDraftFail implements Action {
  readonly type = CREATE_CODE_SHEET_DRAFT_NOTE_FAIL;
  constructor(public payload: any) {}
}

export class CreateCodeSheetSucess implements Action {
  readonly type = CREATE_CODE_SHEET_NOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadCodeSheetNote implements Action {
  readonly type = LOAD_CODESHEET_NOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateCodeSheetFail implements Action {
  readonly type = CREATE_CODE_SHEET_NOTE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteCodeSheet implements Action {
  readonly type = DELETE_CODE_SHEET_NOTE;
  constructor(
    public payload: { CPMRN: string; encounters: Number; refID: string }
  ) {}
}

// action types

export type CodeSheetAction =
  | CreateCodeSheet
  | CreateCodeSheetDraft
  | CreateCodeSheetDraftFail
  | CreateCodeSheetDraftSuccess
  | CreateCodeSheetSucess
  | DeleteCodeSheet
  | CreateCodeSheetFail;
