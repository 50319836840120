import { SharedState } from "./shared.state";
import { createFeatureSelector, createSelector } from "@ngrx/store";
export const SHARED_STATE_NAME = "shared";

const getSharedState = createFeatureSelector<SharedState>(SHARED_STATE_NAME);

export enum LoaderNames {
  DEFAULT_LOADER = "Default",
}

export const getLoaderNamespaceLoading = (
  loaderName: string = LoaderNames.DEFAULT_LOADER
) =>
  createSelector(
    getSharedState,
    (state) => state.loader[loaderName]?.showLoading || false
  );

export const getDefaultLoading = createSelector(
  getSharedState,
  (state) => state.loader[LoaderNames.DEFAULT_LOADER]?.showLoading || false
);

export const getErrorMessage = createSelector(getSharedState, (state) => {
  return state.errorMessage;
});
