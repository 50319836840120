import { createAction, props } from '@ngrx/store';
import { RAlertMessage } from '../../models/RAlert/message.model';

export const addRAlertMessage = createAction(
  '[RAlert Socket] Add One RAlert Message',
  props<{ message: RAlertMessage }>()
);

export const acknowledgeRAlertMessage = createAction(
  '[RAlert Socket] Acknowledge RAlert Message',
  props<{ id: string }>()
);
