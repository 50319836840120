import { createAction, props } from "@ngrx/store";
import { LabOrderBE } from "../../../models/Lab.model";
import { MedOrderBE } from "../../../models/Med.model";
import { DietOrderBE } from "../../../models/Diet.model";
import { BloodOrderBE } from "../../../models/Blood.model";
import { ProcedureOrderBE } from "../../../models/Procedure.model";
import { CommunicationOrderBE } from "../../../models/Communication.model";
import { PlaceProtocolForm } from "../../../models/protocol/PlaceProtocolForm.model";
import { OrderableRequest } from "../../../models/orderable/OrderableRequest.model";

export enum OrderPaneActionTypes {
  AddOrder = "[Order Pane] Create Order",
  UpdateOrder = "[Order Pane] Update Order",
}

/*
 * Actions related to orderables.
 * */
export const selectOrderable = createAction(
  "[Order Pane] Select Orderable",
  props<{ id: string }>()
);

export const getOrderable = createAction(
  "[Order Pane] Get Orderable",
  props<{ id: string; brand?: string }>()
);

export const getOrderables = createAction(
  "[Order Pane] Get Orderables",
  props<{ orderableType: string }>()
);

export const updateOrderables = createAction(
  "[Order Pane] Update Orderables",
  props<{ orderableType: string }>()
);

export const removeOrderables = createAction("[Order Pane] Remove Orderables");

export const clearSelectedOrderable = createAction(
  "[Order Pane] Clear Selected Orderable"
);

/*
 * Actions related to orders.
 * */
export const addOrder = createAction(
  "[Order Pane] Create Order",
  props<{
    order:
      | BloodOrderBE
      | CommunicationOrderBE
      | DietOrderBE
      | LabOrderBE
      | MedOrderBE
      | ProcedureOrderBE;
  }>()
);

export const updateOrder = createAction(
  "[Order Pane] Update Order",
  props<{
    order:
      | BloodOrderBE
      | CommunicationOrderBE
      | DietOrderBE
      | LabOrderBE
      | MedOrderBE
      | ProcedureOrderBE;
  }>()
);

export const submitOrderableRequest = createAction(
  "[Order Pane] Submit Orderable Request",
  props<{ request: OrderableRequest }>()
);

/*
 * Actions related to protocols.
 * */
export const selectProtocol = createAction(
  "[Order Pane] Select Protocol",
  props<{ id: string }>()
);

export const getProtocol = createAction(
  "[Order Pane] Get Protocol",
  props<{ id: string }>()
);

export const clearSelectedProtocol = createAction(
  "[Order Pane] Clear Selected Protocol"
);

export const placeProtocol = createAction(
  "[Order Pane] Place Protocol",
  props<{ value: PlaceProtocolForm }>()
);
