export const PRETERM_BOYS_WEIGHT_COORDS_LINE_1 = [
  {
    xValue: 22.87661214,
    yValue: 0.411428833,
  },
  {
    xValue: 23.54696358,
    yValue: 0.4342858887,
  },
  {
    xValue: 24.1657457,
    yValue: 0.4571429443,
  },
  {
    xValue: 24.78453058,
    yValue: 0.48,
  },
  {
    xValue: 25.40331546,
    yValue: 0.5028570557,
  },
  {
    xValue: 26.0736669,
    yValue: 0.5257141113,
  },
  {
    xValue: 26.69245178,
    yValue: 0.5485717773,
  },
  {
    xValue: 27.36280046,
    yValue: 0.571428833,
  },
  {
    xValue: 28.03314914,
    yValue: 0.6057141113,
  },
  {
    xValue: 28.65193402,
    yValue: 0.651428833,
  },
  {
    xValue: 29.2707189,
    yValue: 0.6971429443,
  },
  {
    xValue: 29.83793723,
    yValue: 0.7428570557,
  },
  {
    xValue: 30.45672211,
    yValue: 0.811428833,
  },
  {
    xValue: 30.97237663,
    yValue: 0.891428833,
  },
  {
    xValue: 31.59116151,
    yValue: 0.9828570557,
  },
  {
    xValue: 32.10681604,
    yValue: 1.074285889,
  },
  {
    xValue: 32.62247057,
    yValue: 1.165714111,
  },
  {
    xValue: 33.08655578,
    yValue: 1.268571777,
  },
  {
    xValue: 33.55064651,
    yValue: 1.36,
  },
  {
    xValue: 34.01473173,
    yValue: 1.462857056,
  },
  {
    xValue: 34.42725865,
    yValue: 1.554285889,
  },
  {
    xValue: 34.89134387,
    yValue: 1.657142944,
  },
  {
    xValue: 35.4069984,
    yValue: 1.782857056,
  },
  {
    xValue: 35.92265292,
    yValue: 1.908571777,
  },
  {
    xValue: 36.38674365,
    yValue: 2,
  },
  {
    xValue: 36.95396197,
    yValue: 2.125714722,
  },
  {
    xValue: 37.41804719,
    yValue: 2.228571777,
  },
  {
    xValue: 37.88213792,
    yValue: 2.32,
  },
  {
    xValue: 38.39779244,
    yValue: 2.422857056,
  },
  {
    xValue: 38.96501077,
    yValue: 2.525714722,
  },
  {
    xValue: 39.4806653,
    yValue: 2.64,
  },
  {
    xValue: 39.99631982,
    yValue: 2.72,
  },
  {
    xValue: 40.40884124,
    yValue: 2.811428833,
  },
  {
    xValue: 40.92449577,
    yValue: 2.902857056,
  },
  {
    xValue: 41.44015029,
    yValue: 3.005714722,
  },
  {
    xValue: 41.90423551,
    yValue: 3.097142944,
  },
  {
    xValue: 42.41989004,
    yValue: 3.2,
  },
  {
    xValue: 42.88398076,
    yValue: 3.291428833,
  },
  {
    xValue: 43.39962978,
    yValue: 3.382857056,
  },
  {
    xValue: 43.91528431,
    yValue: 3.485714722,
  },
  {
    xValue: 44.43093883,
    yValue: 3.577142944,
  },
  {
    xValue: 44.94659336,
    yValue: 3.68,
  },
  {
    xValue: 45.46224788,
    yValue: 3.771428833,
  },
  {
    xValue: 45.97790241,
    yValue: 3.874285889,
  },
  {
    xValue: 46.44198763,
    yValue: 3.965714722,
  },
  {
    xValue: 46.95764215,
    yValue: 4.068571777,
  },
  {
    xValue: 47.52486048,
    yValue: 4.171428833,
  },
  {
    xValue: 47.9889512,
    yValue: 4.251428833,
  },
  {
    xValue: 48.45304193,
    yValue: 4.342857666,
  },
  {
    xValue: 48.96869095,
    yValue: 4.434285889,
  },
  {
    xValue: 49.48434547,
    yValue: 4.525714722,
  },
  {
    xValue: 50,
    yValue: 4.617142944,
  },
];

export const PRETERM_BOYS_WEIGHT_COORDS_LINE_2 = [
  {
    xValue: 22.87661214,
    yValue: 0.4457141113,
  },
  {
    xValue: 23.39226667,
    yValue: 0.48,
  },
  {
    xValue: 23.9079212,
    yValue: 0.5028570557,
  },
  {
    xValue: 24.52670332,
    yValue: 0.5371429443,
  },
  {
    xValue: 25.1454882,
    yValue: 0.571428833,
  },
  {
    xValue: 25.81583964,
    yValue: 0.6057141113,
  },
  {
    xValue: 26.38305796,
    yValue: 0.651428833,
  },
  {
    xValue: 27.00184284,
    yValue: 0.6857141113,
  },
  {
    xValue: 27.62062772,
    yValue: 0.731428833,
  },
  {
    xValue: 28.2909764,
    yValue: 0.7885717773,
  },
  {
    xValue: 28.90976128,
    yValue: 0.8571429443,
  },
  {
    xValue: 29.47697961,
    yValue: 0.9142858887,
  },
  {
    xValue: 30.09576449,
    yValue: 0.9942858887,
  },
  {
    xValue: 30.61141902,
    yValue: 1.062857056,
  },
  {
    xValue: 31.17863734,
    yValue: 1.142857056,
  },
  {
    xValue: 31.69429187,
    yValue: 1.234285889,
  },
  {
    xValue: 32.20994364,
    yValue: 1.325714111,
  },
  {
    xValue: 32.72559817,
    yValue: 1.44,
  },
  {
    xValue: 33.3443858,
    yValue: 1.565714111,
  },
  {
    xValue: 33.91160413,
    yValue: 1.691428833,
  },
  {
    xValue: 34.47882245,
    yValue: 1.817142944,
  },
  {
    xValue: 34.94291318,
    yValue: 1.92,
  },
  {
    xValue: 35.3554346,
    yValue: 2.011428833,
  },
  {
    xValue: 35.76795601,
    yValue: 2.125714722,
  },
  {
    xValue: 36.28361054,
    yValue: 2.24,
  },
  {
    xValue: 36.85082887,
    yValue: 2.354285889,
  },
  {
    xValue: 37.31491959,
    yValue: 2.445714722,
  },
  {
    xValue: 37.83057412,
    yValue: 2.56,
  },
  {
    xValue: 38.34622314,
    yValue: 2.662857056,
  },
  {
    xValue: 38.86187766,
    yValue: 2.754285889,
  },
  {
    xValue: 39.32596839,
    yValue: 2.857142944,
  },
  {
    xValue: 39.89318671,
    yValue: 2.96,
  },
  {
    xValue: 40.40884124,
    yValue: 3.062857056,
  },
  {
    xValue: 40.82136266,
    yValue: 3.142857056,
  },
  {
    xValue: 41.28545338,
    yValue: 3.234285889,
  },
  {
    xValue: 41.7495386,
    yValue: 3.337142944,
  },
  {
    xValue: 42.21362933,
    yValue: 3.428571777,
  },
  {
    xValue: 42.62615074,
    yValue: 3.52,
  },
  {
    xValue: 43.09024147,
    yValue: 3.611428833,
  },
  {
    xValue: 43.50276289,
    yValue: 3.691428833,
  },
  {
    xValue: 43.96685361,
    yValue: 3.782857666,
  },
  {
    xValue: 44.43093883,
    yValue: 3.885714722,
  },
  {
    xValue: 44.89502956,
    yValue: 3.965714722,
  },
  {
    xValue: 45.25598717,
    yValue: 4.057142944,
  },
  {
    xValue: 45.72007239,
    yValue: 4.148571777,
  },
  {
    xValue: 46.18416312,
    yValue: 4.24,
  },
  {
    xValue: 46.64824834,
    yValue: 4.331428833,
  },
  {
    xValue: 47.06077526,
    yValue: 4.422857666,
  },
  {
    xValue: 47.52486048,
    yValue: 4.502857666,
  },
  {
    xValue: 47.9889512,
    yValue: 4.582857666,
  },
  {
    xValue: 48.40147262,
    yValue: 4.674285889,
  },
  {
    xValue: 48.81399955,
    yValue: 4.765714722,
  },
  {
    xValue: 49.38121787,
    yValue: 4.868571777,
  },
  {
    xValue: 49.9484362,
    yValue: 4.971428833,
  },
];

export const PRETERM_BOYS_WEIGHT_COORDS_LINE_3 = [
  {
    xValue: 22.72191523,
    yValue: 0.5371429443,
  },
  {
    xValue: 23.34070012,
    yValue: 0.5828570557,
  },
  {
    xValue: 23.85635464,
    yValue: 0.64,
  },
  {
    xValue: 24.42357297,
    yValue: 0.6857141113,
  },
  {
    xValue: 25.04235785,
    yValue: 0.731428833,
  },
  {
    xValue: 25.60957893,
    yValue: 0.7885717773,
  },
  {
    xValue: 26.17679725,
    yValue: 0.8571429443,
  },
  {
    xValue: 26.79558213,
    yValue: 0.9142858887,
  },
  {
    xValue: 27.31123391,
    yValue: 0.9828570557,
  },
  {
    xValue: 27.87845499,
    yValue: 1.051428833,
  },
  {
    xValue: 28.39410676,
    yValue: 1.12,
  },
  {
    xValue: 28.96132784,
    yValue: 1.211428833,
  },
  {
    xValue: 29.42541581,
    yValue: 1.28,
  },
  {
    xValue: 29.88950378,
    yValue: 1.36,
  },
  {
    xValue: 30.35359175,
    yValue: 1.451428833,
  },
  {
    xValue: 30.86924628,
    yValue: 1.542857056,
  },
  {
    xValue: 31.33333425,
    yValue: 1.634285889,
  },
  {
    xValue: 31.79742222,
    yValue: 1.725714111,
  },
  {
    xValue: 32.26151295,
    yValue: 1.828571777,
  },
  {
    xValue: 32.67403437,
    yValue: 1.931428833,
  },
  {
    xValue: 33.08655578,
    yValue: 2.034285889,
  },
  {
    xValue: 33.55064651,
    yValue: 2.137142944,
  },
  {
    xValue: 33.96316793,
    yValue: 2.24,
  },
  {
    xValue: 34.42725865,
    yValue: 2.342857056,
  },
  {
    xValue: 34.89134387,
    yValue: 2.445714722,
  },
  {
    xValue: 35.3038708,
    yValue: 2.56,
  },
  {
    xValue: 35.71639221,
    yValue: 2.662857056,
  },
  {
    xValue: 36.12891363,
    yValue: 2.754285889,
  },
  {
    xValue: 36.59300436,
    yValue: 2.845714722,
  },
  {
    xValue: 37.00552577,
    yValue: 2.948571777,
  },
  {
    xValue: 37.5211803,
    yValue: 3.04,
  },
  {
    xValue: 37.93370172,
    yValue: 3.142857056,
  },
  {
    xValue: 38.44935624,
    yValue: 3.245714722,
  },
  {
    xValue: 38.86187766,
    yValue: 3.337142944,
  },
  {
    xValue: 39.32596839,
    yValue: 3.417142944,
  },
  {
    xValue: 39.79005361,
    yValue: 3.52,
  },
  {
    xValue: 40.25414433,
    yValue: 3.622857056,
  },
  {
    xValue: 40.71823506,
    yValue: 3.714285889,
  },
  {
    xValue: 41.18232027,
    yValue: 3.817142944,
  },
  {
    xValue: 41.646411,
    yValue: 3.92,
  },
  {
    xValue: 42.05893242,
    yValue: 4.034285889,
  },
  {
    xValue: 42.52302314,
    yValue: 4.125714722,
  },
  {
    xValue: 42.98710836,
    yValue: 4.24,
  },
  {
    xValue: 43.39962978,
    yValue: 4.342857666,
  },
  {
    xValue: 43.8637205,
    yValue: 4.445714722,
  },
  {
    xValue: 44.32781123,
    yValue: 4.56,
  },
  {
    xValue: 44.74033265,
    yValue: 4.651428833,
  },
  {
    xValue: 45.15285407,
    yValue: 4.754285889,
  },
  {
    xValue: 45.56537548,
    yValue: 4.857142944,
  },
  {
    xValue: 46.02946621,
    yValue: 4.96,
  },
  {
    xValue: 46.44198763,
    yValue: 5.062857666,
  },
  {
    xValue: 46.90607835,
    yValue: 5.165714722,
  },
  {
    xValue: 47.31859977,
    yValue: 5.245714722,
  },
  {
    xValue: 47.7311267,
    yValue: 5.337142944,
  },
  {
    xValue: 48.14364811,
    yValue: 5.440000305,
  },
  {
    xValue: 48.60773333,
    yValue: 5.531428833,
  },
  {
    xValue: 49.07182406,
    yValue: 5.622857361,
  },
  {
    xValue: 49.48434547,
    yValue: 5.714285889,
  },
  {
    xValue: 49.9484362,
    yValue: 5.81714325,
  },
];

export const PRETERM_BOYS_WEIGHT_COORDS_LINE_4 = [
  {
    xValue: 22.46409073,
    yValue: 0.6285717773,
  },
  {
    xValue: 22.9281787,
    yValue: 0.6628570557,
  },
  {
    xValue: 23.44383047,
    yValue: 0.72,
  },
  {
    xValue: 24.01105155,
    yValue: 0.7885717773,
  },
  {
    xValue: 24.57826988,
    yValue: 0.8457141113,
  },
  {
    xValue: 25.0939244,
    yValue: 0.9142858887,
  },
  {
    xValue: 25.66114273,
    yValue: 0.9828570557,
  },
  {
    xValue: 26.17679725,
    yValue: 1.051428833,
  },
  {
    xValue: 26.69245178,
    yValue: 1.131428833,
  },
  {
    xValue: 27.20810355,
    yValue: 1.211428833,
  },
  {
    xValue: 27.67219152,
    yValue: 1.291428833,
  },
  {
    xValue: 28.2394126,
    yValue: 1.382857056,
  },
  {
    xValue: 28.80663093,
    yValue: 1.485714111,
  },
  {
    xValue: 29.32228546,
    yValue: 1.6,
  },
  {
    xValue: 29.83793723,
    yValue: 1.691428833,
  },
  {
    xValue: 30.35359175,
    yValue: 1.817142944,
  },
  {
    xValue: 30.81767973,
    yValue: 1.92,
  },
  {
    xValue: 31.2817677,
    yValue: 2.045714722,
  },
  {
    xValue: 31.74585842,
    yValue: 2.16,
  },
  {
    xValue: 32.10681604,
    yValue: 2.262857056,
  },
  {
    xValue: 32.57090126,
    yValue: 2.377142944,
  },
  {
    xValue: 33.03499198,
    yValue: 2.502857056,
  },
  {
    xValue: 33.49908271,
    yValue: 2.617142944,
  },
  {
    xValue: 33.80847102,
    yValue: 2.72,
  },
  {
    xValue: 34.22099794,
    yValue: 2.822857056,
  },
  {
    xValue: 34.68508316,
    yValue: 2.948571777,
  },
  {
    xValue: 35.14917389,
    yValue: 3.062857056,
  },
  {
    xValue: 35.56169531,
    yValue: 3.165714722,
  },
  {
    xValue: 36.02578603,
    yValue: 3.28,
  },
  {
    xValue: 36.43830745,
    yValue: 3.382857056,
  },
  {
    xValue: 36.90239267,
    yValue: 3.497142944,
  },
  {
    xValue: 37.41804719,
    yValue: 3.622857056,
  },
  {
    xValue: 37.98526552,
    yValue: 3.748571777,
  },
  {
    xValue: 38.60405315,
    yValue: 3.874285889,
  },
  {
    xValue: 39.01657457,
    yValue: 3.965714722,
  },
  {
    xValue: 39.4806653,
    yValue: 4.068571777,
  },
  {
    xValue: 39.99631982,
    yValue: 4.171428833,
  },
  {
    xValue: 40.40884124,
    yValue: 4.285714722,
  },
  {
    xValue: 40.82136266,
    yValue: 4.388571777,
  },
  {
    xValue: 41.23388407,
    yValue: 4.491428833,
  },
  {
    xValue: 41.646411,
    yValue: 4.582857666,
  },
  {
    xValue: 42.00736862,
    yValue: 4.697142944,
  },
  {
    xValue: 42.36832624,
    yValue: 4.788571777,
  },
  {
    xValue: 42.78084765,
    yValue: 4.891428833,
  },
  {
    xValue: 43.19336907,
    yValue: 5.005714722,
  },
  {
    xValue: 43.50276289,
    yValue: 5.12,
  },
  {
    xValue: 43.91528431,
    yValue: 5.222857666,
  },
  {
    xValue: 44.32781123,
    yValue: 5.337142944,
  },
  {
    xValue: 44.74033265,
    yValue: 5.451428833,
  },
  {
    xValue: 45.20441787,
    yValue: 5.588571777,
  },
  {
    xValue: 45.61694479,
    yValue: 5.702857361,
  },
  {
    xValue: 46.08103001,
    yValue: 5.81714325,
  },
  {
    xValue: 46.54512073,
    yValue: 5.931428833,
  },
  {
    xValue: 46.95764215,
    yValue: 6.034285889,
  },
  {
    xValue: 47.37016357,
    yValue: 6.148571777,
  },
  {
    xValue: 47.8342543,
    yValue: 6.262857361,
  },
  {
    xValue: 48.24677571,
    yValue: 6.365714722,
  },
  {
    xValue: 48.65930264,
    yValue: 6.45714325,
  },
  {
    xValue: 49.07182406,
    yValue: 6.560000305,
  },
  {
    xValue: 49.53591478,
    yValue: 6.662857361,
  },
  {
    xValue: 49.9484362,
    yValue: 6.754286194,
  },
];

export const PRETERM_BOYS_WEIGHT_COORDS_LINE_5 = [
  {
    xValue: 22.82504834,
    yValue: 0.7085717773,
  },
  {
    xValue: 23.49539702,
    yValue: 0.7771429443,
  },
  {
    xValue: 24.01105155,
    yValue: 0.8571429443,
  },
  {
    xValue: 24.57826988,
    yValue: 0.9142858887,
  },
  {
    xValue: 25.0939244,
    yValue: 0.9942858887,
  },
  {
    xValue: 25.60957893,
    yValue: 1.062857056,
  },
  {
    xValue: 26.1252307,
    yValue: 1.131428833,
  },
  {
    xValue: 26.58931867,
    yValue: 1.222857056,
  },
  {
    xValue: 27.1049732,
    yValue: 1.302857056,
  },
  {
    xValue: 27.56906117,
    yValue: 1.382857056,
  },
  {
    xValue: 28.03314914,
    yValue: 1.474285889,
  },
  {
    xValue: 28.49723987,
    yValue: 1.554285889,
  },
  {
    xValue: 28.96132784,
    yValue: 1.657142944,
  },
  {
    xValue: 29.42541581,
    yValue: 1.76,
  },
  {
    xValue: 29.88950378,
    yValue: 1.862857056,
  },
  {
    xValue: 30.30202795,
    yValue: 1.965714722,
  },
  {
    xValue: 30.76611593,
    yValue: 2.08,
  },
  {
    xValue: 31.12707354,
    yValue: 2.182857056,
  },
  {
    xValue: 31.53959496,
    yValue: 2.285714722,
  },
  {
    xValue: 31.90055258,
    yValue: 2.388571777,
  },
  {
    xValue: 32.26151295,
    yValue: 2.491428833,
  },
  {
    xValue: 32.62247057,
    yValue: 2.605714722,
  },
  {
    xValue: 33.03499198,
    yValue: 2.72,
  },
  {
    xValue: 33.3959496,
    yValue: 2.834285889,
  },
  {
    xValue: 33.80847102,
    yValue: 2.937142944,
  },
  {
    xValue: 34.22099794,
    yValue: 3.062857056,
  },
  {
    xValue: 34.63351936,
    yValue: 3.177142944,
  },
  {
    xValue: 35.04604078,
    yValue: 3.302857056,
  },
  {
    xValue: 35.4585622,
    yValue: 3.405714722,
  },
  {
    xValue: 35.81951981,
    yValue: 3.52,
  },
  {
    xValue: 36.23204674,
    yValue: 3.622857056,
  },
  {
    xValue: 36.69613196,
    yValue: 3.725714722,
  },
  {
    xValue: 37.10865888,
    yValue: 3.828571777,
  },
  {
    xValue: 37.5727441,
    yValue: 3.931428833,
  },
  {
    xValue: 38.03683483,
    yValue: 4.034285889,
  },
  {
    xValue: 38.50092004,
    yValue: 4.148571777,
  },
  {
    xValue: 38.96501077,
    yValue: 4.251428833,
  },
  {
    xValue: 39.37753219,
    yValue: 4.342857666,
  },
  {
    xValue: 39.84162291,
    yValue: 4.445714722,
  },
  {
    xValue: 40.25414433,
    yValue: 4.56,
  },
  {
    xValue: 40.66666575,
    yValue: 4.662857666,
  },
  {
    xValue: 41.07919267,
    yValue: 4.765714722,
  },
  {
    xValue: 41.49171409,
    yValue: 4.88,
  },
  {
    xValue: 41.90423551,
    yValue: 5.005714722,
  },
  {
    xValue: 42.36832624,
    yValue: 5.142857666,
  },
  {
    xValue: 42.78084765,
    yValue: 5.257142944,
  },
  {
    xValue: 43.14180527,
    yValue: 5.371428833,
  },
  {
    xValue: 43.50276289,
    yValue: 5.485714722,
  },
  {
    xValue: 43.91528431,
    yValue: 5.611428833,
  },
  {
    xValue: 44.27624192,
    yValue: 5.725714722,
  },
  {
    xValue: 44.68876885,
    yValue: 5.840000305,
  },
  {
    xValue: 45.10129027,
    yValue: 5.954285889,
  },
  {
    xValue: 45.51381168,
    yValue: 6.080000305,
  },
  {
    xValue: 45.8747693,
    yValue: 6.194286194,
  },
  {
    xValue: 46.28729072,
    yValue: 6.29714325,
  },
  {
    xValue: 46.64824834,
    yValue: 6.400000305,
  },
  {
    xValue: 47.06077526,
    yValue: 6.514286194,
  },
  {
    xValue: 47.42173288,
    yValue: 6.61714325,
  },
  {
    xValue: 47.8342543,
    yValue: 6.720000305,
  },
  {
    xValue: 48.29834502,
    yValue: 6.834286194,
  },
  {
    xValue: 48.71086644,
    yValue: 6.948571777,
  },
  {
    xValue: 49.12338786,
    yValue: 7.051428833,
  },
  {
    xValue: 49.53591478,
    yValue: 7.142857666,
  },
  {
    xValue: 49.9484362,
    yValue: 7.245714722,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE_1 = [
  {
    xValue: 23.39226667,
    yValue: 18.87342079,
  },
  {
    xValue: 23.9079212,
    yValue: 19.3291152,
  },
  {
    xValue: 24.42357297,
    yValue: 19.78480962,
  },
  {
    xValue: 25.04235785,
    yValue: 20.35443068,
  },
  {
    xValue: 25.60957893,
    yValue: 20.92405174,
  },
  {
    xValue: 26.22836105,
    yValue: 21.37974616,
  },
  {
    xValue: 26.79558213,
    yValue: 21.94936722,
  },
  {
    xValue: 27.41436701,
    yValue: 22.40506772,
  },
  {
    xValue: 28.03314914,
    yValue: 23.08860934,
  },
  {
    xValue: 28.65193402,
    yValue: 23.54430376,
  },
  {
    xValue: 29.2707189,
    yValue: 24.11392482,
  },
  {
    xValue: 29.83793723,
    yValue: 24.68354588,
  },
  {
    xValue: 30.45672211,
    yValue: 25.25316694,
  },
  {
    xValue: 31.07550699,
    yValue: 25.70886136,
  },
  {
    xValue: 31.69429187,
    yValue: 26.39240603,
  },
  {
    xValue: 32.26151295,
    yValue: 26.84810348,
  },
  {
    xValue: 32.77716747,
    yValue: 27.3037979,
  },
  {
    xValue: 33.3443858,
    yValue: 27.75949536,
  },
  {
    xValue: 33.96316793,
    yValue: 28.21519282,
  },
  {
    xValue: 34.53038625,
    yValue: 28.78481084,
  },
  {
    xValue: 35.14917389,
    yValue: 29.24050829,
  },
  {
    xValue: 35.81951981,
    yValue: 29.69620271,
  },
  {
    xValue: 36.38674365,
    yValue: 30.15190017,
  },
  {
    xValue: 37.00552577,
    yValue: 30.49367098,
  },
  {
    xValue: 37.6243079,
    yValue: 30.94936844,
  },
  {
    xValue: 38.24309554,
    yValue: 31.29113925,
  },
  {
    xValue: 38.86187766,
    yValue: 31.74683671,
  },
  {
    xValue: 39.4806653,
    yValue: 32.08861056,
  },
  {
    xValue: 40.15101122,
    yValue: 32.43038137,
  },
  {
    xValue: 40.76979886,
    yValue: 32.77215218,
  },
  {
    xValue: 41.38858098,
    yValue: 33.11392604,
  },
  {
    xValue: 42.00736862,
    yValue: 33.45569685,
  },
  {
    xValue: 42.62615074,
    yValue: 33.7974707,
  },
  {
    xValue: 43.24493838,
    yValue: 34.02531791,
  },
  {
    xValue: 43.8121567,
    yValue: 34.25316512,
  },
  {
    xValue: 44.53407194,
    yValue: 34.70886258,
  },
  {
    xValue: 45.10129027,
    yValue: 35.05063339,
  },
  {
    xValue: 45.72007239,
    yValue: 35.27848364,
  },
  {
    xValue: 46.33886003,
    yValue: 35.50633085,
  },
  {
    xValue: 46.95764215,
    yValue: 35.84810166,
  },
  {
    xValue: 47.62799359,
    yValue: 36.07595191,
  },
  {
    xValue: 48.19521191,
    yValue: 36.41772272,
  },
  {
    xValue: 48.76243024,
    yValue: 36.64556993,
  },
  {
    xValue: 49.32964857,
    yValue: 36.87342018,
  },
  {
    xValue: 50,
    yValue: 37.21519099,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE_2 = [
  {
    xValue: 23.59852738,
    yValue: 19.78480962,
  },
  {
    xValue: 24.26887881,
    yValue: 20.46835733,
  },
  {
    xValue: 24.83609714,
    yValue: 20.92405174,
  },
  {
    xValue: 25.35175166,
    yValue: 21.37974616,
  },
  {
    xValue: 25.91896999,
    yValue: 21.94936722,
  },
  {
    xValue: 26.53775487,
    yValue: 22.51898828,
  },
  {
    xValue: 27.20810355,
    yValue: 23.08860934,
  },
  {
    xValue: 27.77532463,
    yValue: 23.65823041,
  },
  {
    xValue: 28.2909764,
    yValue: 24.11392482,
  },
  {
    xValue: 28.90976128,
    yValue: 24.68354588,
  },
  {
    xValue: 29.47697961,
    yValue: 25.13924334,
  },
  {
    xValue: 29.99263414,
    yValue: 25.70886136,
  },
  {
    xValue: 30.61141902,
    yValue: 26.27848242,
  },
  {
    xValue: 31.2302039,
    yValue: 26.73417988,
  },
  {
    xValue: 31.84898878,
    yValue: 27.3037979,
  },
  {
    xValue: 32.41620986,
    yValue: 27.87341896,
  },
  {
    xValue: 32.98342818,
    yValue: 28.32911642,
  },
  {
    xValue: 33.60221031,
    yValue: 28.78481084,
  },
  {
    xValue: 34.22099794,
    yValue: 29.3544319,
  },
  {
    xValue: 34.78821627,
    yValue: 29.81012935,
  },
  {
    xValue: 35.3554346,
    yValue: 30.26582377,
  },
  {
    xValue: 35.97421672,
    yValue: 30.72152123,
  },
  {
    xValue: 36.59300436,
    yValue: 31.17721564,
  },
  {
    xValue: 37.16022268,
    yValue: 31.6329131,
  },
  {
    xValue: 37.72744101,
    yValue: 31.97468391,
  },
  {
    xValue: 38.34622314,
    yValue: 32.31645777,
  },
  {
    xValue: 38.91344697,
    yValue: 32.65822858,
  },
  {
    xValue: 39.5322291,
    yValue: 33.00000243,
  },
  {
    xValue: 40.15101122,
    yValue: 33.34177325,
  },
  {
    xValue: 40.87292646,
    yValue: 33.6835471,
  },
  {
    xValue: 41.44015029,
    yValue: 34.02531791,
  },
  {
    xValue: 42.00736862,
    yValue: 34.25316512,
  },
  {
    xValue: 42.67771454,
    yValue: 34.59493897,
  },
  {
    xValue: 43.39962978,
    yValue: 34.93670978,
  },
  {
    xValue: 44.12154501,
    yValue: 35.27848364,
  },
  {
    xValue: 44.74033265,
    yValue: 35.62025445,
  },
  {
    xValue: 45.35911477,
    yValue: 35.84810166,
  },
  {
    xValue: 46.02946621,
    yValue: 36.18987551,
  },
  {
    xValue: 46.64824834,
    yValue: 36.41772272,
  },
  {
    xValue: 47.31859977,
    yValue: 36.75949353,
  },
  {
    xValue: 47.9889512,
    yValue: 36.98734378,
  },
  {
    xValue: 48.55616953,
    yValue: 37.32911459,
  },
  {
    xValue: 49.12338786,
    yValue: 37.5569618,
  },
  {
    xValue: 49.8968724,
    yValue: 37.89873566,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE_3 = [
  {
    xValue: 23.54696358,
    yValue: 21.37974616,
  },
  {
    xValue: 24.21731226,
    yValue: 21.94936722,
  },
  {
    xValue: 24.68140023,
    yValue: 22.40506772,
  },
  {
    xValue: 25.19705476,
    yValue: 22.9746827,
  },
  {
    xValue: 25.76427308,
    yValue: 23.54430376,
  },
  {
    xValue: 26.33149416,
    yValue: 24.11392482,
  },
  {
    xValue: 26.84714593,
    yValue: 24.56962228,
  },
  {
    xValue: 27.36280046,
    yValue: 25.02531974,
  },
  {
    xValue: 27.87845499,
    yValue: 25.48101415,
  },
  {
    xValue: 28.39410676,
    yValue: 26.05063521,
  },
  {
    xValue: 28.90976128,
    yValue: 26.50632963,
  },
  {
    xValue: 29.42541581,
    yValue: 27.07595069,
  },
  {
    xValue: 29.99263414,
    yValue: 27.53164815,
  },
  {
    xValue: 30.61141902,
    yValue: 28.10126617,
  },
  {
    xValue: 31.17863734,
    yValue: 28.67088723,
  },
  {
    xValue: 31.74585842,
    yValue: 29.12658469,
  },
  {
    xValue: 32.31307675,
    yValue: 29.69620271,
  },
  {
    xValue: 32.82873127,
    yValue: 30.15190017,
  },
  {
    xValue: 33.3443858,
    yValue: 30.60759762,
  },
  {
    xValue: 33.86004033,
    yValue: 31.06329204,
  },
  {
    xValue: 34.42725865,
    yValue: 31.40506589,
  },
  {
    xValue: 34.99447698,
    yValue: 31.86076031,
  },
  {
    xValue: 35.56169531,
    yValue: 32.31645777,
  },
  {
    xValue: 36.07734983,
    yValue: 32.77215218,
  },
  {
    xValue: 36.64456816,
    yValue: 33.11392604,
  },
  {
    xValue: 37.21178648,
    yValue: 33.45569685,
  },
  {
    xValue: 37.77900481,
    yValue: 33.7974707,
  },
  {
    xValue: 38.34622314,
    yValue: 34.13924152,
  },
  {
    xValue: 38.91344697,
    yValue: 34.48101537,
  },
  {
    xValue: 39.4806653,
    yValue: 34.70886258,
  },
  {
    xValue: 40.04788362,
    yValue: 35.05063339,
  },
  {
    xValue: 40.56353815,
    yValue: 35.27848364,
  },
  {
    xValue: 41.07919267,
    yValue: 35.62025445,
  },
  {
    xValue: 41.646411,
    yValue: 35.84810166,
  },
  {
    xValue: 42.26519313,
    yValue: 36.07595191,
  },
  {
    xValue: 42.88398076,
    yValue: 36.41772272,
  },
  {
    xValue: 43.50276289,
    yValue: 36.75949353,
  },
  {
    xValue: 44.01841741,
    yValue: 36.87342018,
  },
  {
    xValue: 44.58563574,
    yValue: 37.21519099,
  },
  {
    xValue: 45.25598717,
    yValue: 37.5569618,
  },
  {
    xValue: 45.8747693,
    yValue: 37.78481205,
  },
  {
    xValue: 46.49355693,
    yValue: 38.01265926,
  },
  {
    xValue: 47.06077526,
    yValue: 38.24050647,
  },
  {
    xValue: 47.67955739,
    yValue: 38.58228032,
  },
  {
    xValue: 48.24677571,
    yValue: 38.81012753,
  },
  {
    xValue: 48.81399955,
    yValue: 39.03797474,
  },
  {
    xValue: 49.38121787,
    yValue: 39.26582499,
  },
  {
    xValue: 50,
    yValue: 39.4936722,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE_4 = [
  {
    xValue: 23.54696358,
    yValue: 22.9746827,
  },
  {
    xValue: 24.32044261,
    yValue: 23.65823041,
  },
  {
    xValue: 24.78453058,
    yValue: 24.22785147,
  },
  {
    xValue: 25.35175166,
    yValue: 24.79746949,
  },
  {
    xValue: 25.86740344,
    yValue: 25.25316694,
  },
  {
    xValue: 26.38305796,
    yValue: 25.82278801,
  },
  {
    xValue: 26.95027629,
    yValue: 26.27848242,
  },
  {
    xValue: 27.51749737,
    yValue: 26.96202709,
  },
  {
    xValue: 28.08471569,
    yValue: 27.41772455,
  },
  {
    xValue: 28.60037022,
    yValue: 27.98734257,
  },
  {
    xValue: 29.16758855,
    yValue: 28.55696363,
  },
  {
    xValue: 29.73480687,
    yValue: 29.12658469,
  },
  {
    xValue: 30.2504614,
    yValue: 29.5822791,
  },
  {
    xValue: 30.71454937,
    yValue: 30.03797656,
  },
  {
    xValue: 31.2302039,
    yValue: 30.49367098,
  },
  {
    xValue: 31.64272531,
    yValue: 30.94936844,
  },
  {
    xValue: 32.15837984,
    yValue: 31.40506589,
  },
  {
    xValue: 32.67403437,
    yValue: 31.86076031,
  },
  {
    xValue: 33.18968889,
    yValue: 32.31645777,
  },
  {
    xValue: 33.75690722,
    yValue: 32.77215218,
  },
  {
    xValue: 34.27256174,
    yValue: 33.34177325,
  },
  {
    xValue: 34.78821627,
    yValue: 33.6835471,
  },
  {
    xValue: 35.3554346,
    yValue: 34.13924152,
  },
  {
    xValue: 35.87108912,
    yValue: 34.48101537,
  },
  {
    xValue: 36.48987125,
    yValue: 34.82278618,
  },
  {
    xValue: 37.05708958,
    yValue: 35.27848364,
  },
  {
    xValue: 37.6243079,
    yValue: 35.50633085,
  },
  {
    xValue: 38.19153174,
    yValue: 35.96202526,
  },
  {
    xValue: 38.75875006,
    yValue: 36.18987551,
  },
  {
    xValue: 39.32596839,
    yValue: 36.53164632,
  },
  {
    xValue: 39.94475051,
    yValue: 36.75949353,
  },
  {
    xValue: 40.51196884,
    yValue: 37.10126739,
  },
  {
    xValue: 41.13075647,
    yValue: 37.32911459,
  },
  {
    xValue: 41.7495386,
    yValue: 37.67088845,
  },
  {
    xValue: 42.31675693,
    yValue: 37.89873566,
  },
  {
    xValue: 42.88398076,
    yValue: 38.24050647,
  },
  {
    xValue: 43.45119909,
    yValue: 38.46835672,
  },
  {
    xValue: 44.06998121,
    yValue: 38.69620393,
  },
  {
    xValue: 44.63719954,
    yValue: 38.92405113,
  },
  {
    xValue: 45.20441787,
    yValue: 39.15189834,
  },
  {
    xValue: 45.72007239,
    yValue: 39.37974859,
  },
  {
    xValue: 46.33886003,
    yValue: 39.6075958,
  },
  {
    xValue: 47.00920595,
    yValue: 39.94936661,
  },
  {
    xValue: 47.57642979,
    yValue: 40.06329326,
  },
  {
    xValue: 48.14364811,
    yValue: 40.29114047,
  },
  {
    xValue: 48.65930264,
    yValue: 40.51898767,
  },
  {
    xValue: 49.32964857,
    yValue: 40.74683488,
  },
  {
    xValue: 50,
    yValue: 40.97468513,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE_5 = [
  {
    xValue: 23.49539702,
    yValue: 23.54430376,
  },
  {
    xValue: 24.26887881,
    yValue: 24.34177507,
  },
  {
    xValue: 24.78453058,
    yValue: 24.91139309,
  },
  {
    xValue: 25.40331546,
    yValue: 25.59493776,
  },
  {
    xValue: 26.02210035,
    yValue: 26.16455882,
  },
  {
    xValue: 26.64088523,
    yValue: 26.84810348,
  },
  {
    xValue: 27.25967011,
    yValue: 27.53164815,
  },
  {
    xValue: 27.77532463,
    yValue: 27.98734257,
  },
  {
    xValue: 28.2909764,
    yValue: 28.55696363,
  },
  {
    xValue: 28.80663093,
    yValue: 29.01266109,
  },
  {
    xValue: 29.2707189,
    yValue: 29.5822791,
  },
  {
    xValue: 29.83793723,
    yValue: 30.03797656,
  },
  {
    xValue: 30.40515831,
    yValue: 30.60759762,
  },
  {
    xValue: 30.97237663,
    yValue: 31.17721564,
  },
  {
    xValue: 31.53959496,
    yValue: 31.74683671,
  },
  {
    xValue: 32.10681604,
    yValue: 32.20253416,
  },
  {
    xValue: 32.67403437,
    yValue: 32.77215218,
  },
  {
    xValue: 33.29281649,
    yValue: 33.22784964,
  },
  {
    xValue: 33.96316793,
    yValue: 33.7974707,
  },
  {
    xValue: 34.68508316,
    yValue: 34.48101537,
  },
  {
    xValue: 35.25230149,
    yValue: 34.93670978,
  },
  {
    xValue: 35.92265292,
    yValue: 35.39240724,
  },
  {
    xValue: 36.48987125,
    yValue: 35.73417805,
  },
  {
    xValue: 37.10865888,
    yValue: 36.07595191,
  },
  {
    xValue: 37.77900481,
    yValue: 36.53164632,
  },
  {
    xValue: 38.44935624,
    yValue: 36.87342018,
  },
  {
    xValue: 39.11970768,
    yValue: 37.21519099,
  },
  {
    xValue: 39.686926,
    yValue: 37.5569618,
  },
  {
    xValue: 40.25414433,
    yValue: 37.78481205,
  },
  {
    xValue: 40.87292646,
    yValue: 38.12658286,
  },
  {
    xValue: 41.44015029,
    yValue: 38.35443007,
  },
  {
    xValue: 42.00736862,
    yValue: 38.58228032,
  },
  {
    xValue: 42.57458694,
    yValue: 38.81012753,
  },
  {
    xValue: 43.19336907,
    yValue: 39.15189834,
  },
  {
    xValue: 43.7605874,
    yValue: 39.37974859,
  },
  {
    xValue: 44.58563574,
    yValue: 39.7215194,
  },
  {
    xValue: 45.15285407,
    yValue: 39.94936661,
  },
  {
    xValue: 45.7716417,
    yValue: 40.17721686,
  },
  {
    xValue: 46.39042383,
    yValue: 40.40506407,
  },
  {
    xValue: 47.00920595,
    yValue: 40.63291128,
  },
  {
    xValue: 47.62799359,
    yValue: 40.86076153,
  },
  {
    xValue: 48.19521191,
    yValue: 41.08860873,
  },
  {
    xValue: 48.76243024,
    yValue: 41.31645594,
  },
  {
    xValue: 49.38121787,
    yValue: 41.54430619,
  },
  {
    xValue: 49.9484362,
    yValue: 41.6582298,
  },
];

export const PRETERM_BOYS_LENGTH_COORDS_LINE_1 = [
  {
    xValue: 23.52462679,
    yValue: 26.72405577,
  },
  {
    xValue: 24.24210551,
    yValue: 27.51662322,
  },
  {
    xValue: 24.84000491,
    yValue: 28.13306828,
  },
  {
    xValue: 25.3581875,
    yValue: 28.74951031,
  },
  {
    xValue: 25.95608965,
    yValue: 29.36595233,
  },
  {
    xValue: 26.55398904,
    yValue: 30.07046011,
  },
  {
    xValue: 27.19174821,
    yValue: 30.86302755,
  },
  {
    xValue: 27.70992805,
    yValue: 31.56753533,
  },
  {
    xValue: 28.26796767,
    yValue: 32.18397735,
  },
  {
    xValue: 28.78614751,
    yValue: 32.88848209,
  },
  {
    xValue: 29.30433011,
    yValue: 33.50492715,
  },
  {
    xValue: 29.82250995,
    yValue: 34.20943189,
  },
  {
    xValue: 30.34068703,
    yValue: 34.91393662,
  },
  {
    xValue: 30.93858918,
    yValue: 35.70650711,
  },
  {
    xValue: 31.4966288,
    yValue: 36.4990776,
  },
  {
    xValue: 32.01480864,
    yValue: 37.11552267,
  },
  {
    xValue: 32.53299123,
    yValue: 37.8200274,
  },
  {
    xValue: 33.05117383,
    yValue: 38.61259485,
  },
  {
    xValue: 33.60921069,
    yValue: 39.40516534,
  },
  {
    xValue: 34.12739329,
    yValue: 40.10967007,
  },
  {
    xValue: 34.72528993,
    yValue: 40.90224056,
  },
  {
    xValue: 35.32319207,
    yValue: 41.6067453,
  },
  {
    xValue: 35.88122894,
    yValue: 42.39931578,
  },
  {
    xValue: 36.43927131,
    yValue: 43.10382052,
  },
  {
    xValue: 37.03717346,
    yValue: 43.72026558,
  },
  {
    xValue: 37.59521583,
    yValue: 44.42477032,
  },
  {
    xValue: 38.23297224,
    yValue: 45.04121234,
  },
  {
    xValue: 38.79101461,
    yValue: 45.65765437,
  },
  {
    xValue: 39.38891125,
    yValue: 46.27409639,
  },
  {
    xValue: 39.9868134,
    yValue: 46.89054146,
  },
  {
    xValue: 40.62457532,
    yValue: 47.50698348,
  },
  {
    xValue: 41.22247196,
    yValue: 48.03536279,
  },
  {
    xValue: 41.82037962,
    yValue: 48.65180786,
  },
  {
    xValue: 42.41827626,
    yValue: 49.18018717,
  },
  {
    xValue: 43.09589245,
    yValue: 49.79662919,
  },
  {
    xValue: 43.61407504,
    yValue: 50.3250085,
  },
  {
    xValue: 44.21197719,
    yValue: 50.85338629,
  },
  {
    xValue: 44.77001956,
    yValue: 51.29370441,
  },
  {
    xValue: 45.32805642,
    yValue: 51.82208373,
  },
  {
    xValue: 45.92595857,
    yValue: 52.35046304,
  },
  {
    xValue: 46.52385521,
    yValue: 52.79077812,
  },
  {
    xValue: 47.12175736,
    yValue: 53.23109624,
  },
  {
    xValue: 47.63993995,
    yValue: 53.67141132,
  },
  {
    xValue: 48.19798232,
    yValue: 54.11172792,
  },
  {
    xValue: 48.79587896,
    yValue: 54.64010723,
  },
  {
    xValue: 49.35392133,
    yValue: 55.08042383,
  },
  {
    xValue: 49.95182348,
    yValue: 55.4326762,
  },
];

export const PRETERM_BOYS_LENGTH_COORDS_LINE_2 = [
  {
    xValue: 23.49539702,
    yValue: 27.87341896,
  },
  {
    xValue: 24.01105155,
    yValue: 28.32911642,
  },
  {
    xValue: 24.47513952,
    yValue: 28.89873444,
  },
  {
    xValue: 24.99079405,
    yValue: 29.4683555,
  },
  {
    xValue: 25.50644582,
    yValue: 30.15190017,
  },
  {
    xValue: 26.0736669,
    yValue: 30.83544483,
  },
  {
    xValue: 26.58931867,
    yValue: 31.5189895,
  },
  {
    xValue: 27.25967011,
    yValue: 32.31645777,
  },
  {
    xValue: 27.93001879,
    yValue: 33.22784964,
  },
  {
    xValue: 28.49723987,
    yValue: 33.91139431,
  },
  {
    xValue: 29.11602199,
    yValue: 34.70886258,
  },
  {
    xValue: 29.68324307,
    yValue: 35.50633085,
  },
  {
    xValue: 30.2504614,
    yValue: 36.30379912,
  },
  {
    xValue: 30.86924628,
    yValue: 37.10126739,
  },
  {
    xValue: 31.38490081,
    yValue: 37.78481205,
  },
  {
    xValue: 31.90055258,
    yValue: 38.46835672,
  },
  {
    xValue: 32.51933746,
    yValue: 39.4936722,
  },
  {
    xValue: 33.08655578,
    yValue: 40.29114047,
  },
  {
    xValue: 33.60221031,
    yValue: 40.86076153,
  },
  {
    xValue: 34.16942864,
    yValue: 41.6582298,
  },
  {
    xValue: 34.78821627,
    yValue: 42.45569807,
  },
  {
    xValue: 35.3554346,
    yValue: 43.25316634,
  },
  {
    xValue: 35.92265292,
    yValue: 43.936711,
  },
  {
    xValue: 36.43830745,
    yValue: 44.50632902,
  },
  {
    xValue: 37.00552577,
    yValue: 45.07595008,
  },
  {
    xValue: 37.5211803,
    yValue: 45.64557115,
  },
  {
    xValue: 38.03683483,
    yValue: 46.21518916,
  },
  {
    xValue: 38.60405315,
    yValue: 46.89873383,
  },
  {
    xValue: 39.22283528,
    yValue: 47.35443129,
  },
  {
    xValue: 39.73848981,
    yValue: 48.03797595,
  },
  {
    xValue: 40.30570813,
    yValue: 48.60759397,
  },
  {
    xValue: 40.82136266,
    yValue: 49.06329143,
  },
  {
    xValue: 41.38858098,
    yValue: 49.63291249,
  },
  {
    xValue: 41.90423551,
    yValue: 50.08860843,
  },
  {
    xValue: 42.52302314,
    yValue: 50.54430437,
  },
  {
    xValue: 43.03867216,
    yValue: 51.11392391,
  },
  {
    xValue: 43.55432669,
    yValue: 51.56961984,
  },
  {
    xValue: 44.12154501,
    yValue: 52.0253173,
  },
  {
    xValue: 44.68876885,
    yValue: 52.48101324,
  },
  {
    xValue: 45.25598717,
    yValue: 52.93670918,
  },
  {
    xValue: 45.8232055,
    yValue: 53.39240511,
  },
  {
    xValue: 46.39042383,
    yValue: 53.84810105,
  },
  {
    xValue: 47.00920595,
    yValue: 54.41772211,
  },
  {
    xValue: 47.57642979,
    yValue: 54.87341805,
  },
  {
    xValue: 48.19521191,
    yValue: 55.32911399,
  },
  {
    xValue: 48.71086644,
    yValue: 55.78480992,
  },
  {
    xValue: 49.32964857,
    yValue: 56.24050586,
  },
  {
    xValue: 49.9484362,
    yValue: 56.6962018,
  },
];

export const PRETERM_BOYS_LENGTH_COORDS_LINE_3 = [
  {
    xValue: 23.49539702,
    yValue: 30.26582377,
  },
  {
    xValue: 24.1141819,
    yValue: 30.94936844,
  },
  {
    xValue: 24.52670332,
    yValue: 31.6329131,
  },
  {
    xValue: 24.88766094,
    yValue: 32.20253416,
  },
  {
    xValue: 25.30018511,
    yValue: 32.77215218,
  },
  {
    xValue: 25.76427308,
    yValue: 33.34177325,
  },
  {
    xValue: 26.27992761,
    yValue: 34.13924152,
  },
  {
    xValue: 26.79558213,
    yValue: 34.70886258,
  },
  {
    xValue: 27.25967011,
    yValue: 35.39240724,
  },
  {
    xValue: 27.67219152,
    yValue: 35.96202526,
  },
  {
    xValue: 28.08471569,
    yValue: 36.53164632,
  },
  {
    xValue: 28.49723987,
    yValue: 37.10126739,
  },
  {
    xValue: 28.90976128,
    yValue: 37.67088845,
  },
  {
    xValue: 29.37384926,
    yValue: 38.24050647,
  },
  {
    xValue: 29.78637343,
    yValue: 38.81012753,
  },
  {
    xValue: 30.2504614,
    yValue: 39.4936722,
  },
  {
    xValue: 30.71454937,
    yValue: 40.17721686,
  },
  {
    xValue: 31.17863734,
    yValue: 40.86076153,
  },
  {
    xValue: 31.64272531,
    yValue: 41.43037955,
  },
  {
    xValue: 32.10681604,
    yValue: 42.11392421,
  },
  {
    xValue: 32.57090126,
    yValue: 42.79746888,
  },
  {
    xValue: 33.03499198,
    yValue: 43.25316634,
  },
  {
    xValue: 33.4475134,
    yValue: 43.82278436,
  },
  {
    xValue: 33.86004033,
    yValue: 44.50632902,
  },
  {
    xValue: 34.37568934,
    yValue: 45.07595008,
  },
  {
    xValue: 34.78821627,
    yValue: 45.64557115,
  },
  {
    xValue: 35.20073769,
    yValue: 46.10126556,
  },
  {
    xValue: 35.61325911,
    yValue: 46.67088662,
  },
  {
    xValue: 36.12891363,
    yValue: 47.35443129,
  },
  {
    xValue: 36.64456816,
    yValue: 47.8101257,
  },
  {
    xValue: 37.21178648,
    yValue: 48.49367037,
  },
  {
    xValue: 37.72744101,
    yValue: 48.94936783,
  },
  {
    xValue: 38.13996243,
    yValue: 49.40506224,
  },
  {
    xValue: 38.60405315,
    yValue: 49.7468361,
  },
  {
    xValue: 39.06813837,
    yValue: 50.20253203,
  },
  {
    xValue: 39.5322291,
    yValue: 50.77215158,
  },
  {
    xValue: 40.04788362,
    yValue: 51.22784903,
  },
  {
    xValue: 40.56353815,
    yValue: 51.56961984,
  },
  {
    xValue: 41.13075647,
    yValue: 52.0253173,
  },
  {
    xValue: 41.59484169,
    yValue: 52.48101324,
  },
  {
    xValue: 42.16206553,
    yValue: 53.05063278,
  },
  {
    xValue: 42.62615074,
    yValue: 53.39240511,
  },
  {
    xValue: 43.19336907,
    yValue: 53.96202618,
  },
  {
    xValue: 43.7090236,
    yValue: 54.41772211,
  },
  {
    xValue: 44.17311432,
    yValue: 54.87341805,
  },
  {
    xValue: 44.74033265,
    yValue: 55.32911399,
  },
  {
    xValue: 45.30755097,
    yValue: 55.67088632,
  },
  {
    xValue: 45.8232055,
    yValue: 56.01265865,
  },
  {
    xValue: 46.33886003,
    yValue: 56.46835459,
  },
  {
    xValue: 46.80294524,
    yValue: 56.92405053,
  },
  {
    xValue: 47.31859977,
    yValue: 57.26582286,
  },
  {
    xValue: 47.8342543,
    yValue: 57.72151879,
  },
  {
    xValue: 48.34990882,
    yValue: 58.17721473,
  },
  {
    xValue: 48.86556335,
    yValue: 58.51898706,
  },
  {
    xValue: 49.38121787,
    yValue: 58.8607594,
  },
  {
    xValue: 49.9484362,
    yValue: 59.31645533,
  },
];

export const PRETERM_BOYS_LENGTH_COORDS_LINE_4 = [
  {
    xValue: 23.54696358,
    yValue: 32.77215218,
  },
  {
    xValue: 24.06261535,
    yValue: 33.45569685,
  },
  {
    xValue: 24.47513952,
    yValue: 34.13924152,
  },
  {
    xValue: 24.93922749,
    yValue: 34.93670978,
  },
  {
    xValue: 25.45488202,
    yValue: 35.73417805,
  },
  {
    xValue: 25.91896999,
    yValue: 36.41772272,
  },
  {
    xValue: 26.43462452,
    yValue: 37.10126739,
  },
  {
    xValue: 26.89871249,
    yValue: 37.89873566,
  },
  {
    xValue: 27.41436701,
    yValue: 38.58228032,
  },
  {
    xValue: 27.82688843,
    yValue: 39.26582499,
  },
  {
    xValue: 28.2909764,
    yValue: 39.83544301,
  },
  {
    xValue: 28.75506438,
    yValue: 40.51898767,
  },
  {
    xValue: 29.2191551,
    yValue: 41.20253234,
  },
  {
    xValue: 29.78637343,
    yValue: 42.00000061,
  },
  {
    xValue: 30.2504614,
    yValue: 42.68354527,
  },
  {
    xValue: 30.76611593,
    yValue: 43.36708994,
  },
  {
    xValue: 31.2817677,
    yValue: 44.16455821,
  },
  {
    xValue: 31.79742222,
    yValue: 44.84810288,
  },
  {
    xValue: 32.26151295,
    yValue: 45.53164754,
  },
  {
    xValue: 32.67403437,
    yValue: 46.21518916,
  },
  {
    xValue: 33.13812509,
    yValue: 46.67088662,
  },
  {
    xValue: 33.60221031,
    yValue: 47.35443129,
  },
  {
    xValue: 34.06630104,
    yValue: 47.92405235,
  },
  {
    xValue: 34.58195556,
    yValue: 48.60759397,
  },
  {
    xValue: 35.20073769,
    yValue: 49.40506224,
  },
  {
    xValue: 35.61325911,
    yValue: 49.8607597,
  },
  {
    xValue: 36.07734983,
    yValue: 50.43038076,
  },
  {
    xValue: 36.59300436,
    yValue: 50.8860767,
  },
  {
    xValue: 37.10865888,
    yValue: 51.34177264,
  },
  {
    xValue: 37.67587721,
    yValue: 51.91139218,
  },
  {
    xValue: 38.19153174,
    yValue: 52.36708811,
  },
  {
    xValue: 38.81031386,
    yValue: 52.93670918,
  },
  {
    xValue: 39.37753219,
    yValue: 53.39240511,
  },
  {
    xValue: 39.94475051,
    yValue: 53.96202618,
  },
  {
    xValue: 40.51196884,
    yValue: 54.41772211,
  },
  {
    xValue: 41.02762337,
    yValue: 54.87341805,
  },
  {
    xValue: 41.59484169,
    yValue: 55.32911399,
  },
  {
    xValue: 42.21362933,
    yValue: 55.89873353,
  },
  {
    xValue: 42.78084765,
    yValue: 56.35443098,
  },
  {
    xValue: 43.39962978,
    yValue: 56.81012692,
  },
  {
    xValue: 43.91528431,
    yValue: 57.26582286,
  },
  {
    xValue: 44.48250263,
    yValue: 57.72151879,
  },
  {
    xValue: 44.99815716,
    yValue: 58.06329113,
  },
  {
    xValue: 45.51381168,
    yValue: 58.51898706,
  },
  {
    xValue: 46.08103001,
    yValue: 58.974683,
  },
  {
    xValue: 46.64824834,
    yValue: 59.43037894,
  },
  {
    xValue: 47.16390286,
    yValue: 59.77215127,
  },
];

export const PRETERM_BOYS_LENGTH_COORDS_LINE_5 = [
  {
    xValue: 23.49539702,
    yValue: 33.7974707,
  },
  {
    xValue: 24.06261535,
    yValue: 34.70886258,
  },
  {
    xValue: 24.47513952,
    yValue: 35.39240724,
  },
  {
    xValue: 24.93922749,
    yValue: 36.18987551,
  },
  {
    xValue: 25.40331546,
    yValue: 36.98734378,
  },
  {
    xValue: 25.91896999,
    yValue: 37.67088845,
  },
  {
    xValue: 26.43462452,
    yValue: 38.46835672,
  },
  {
    xValue: 26.89871249,
    yValue: 39.26582499,
  },
  {
    xValue: 27.41436701,
    yValue: 39.94936661,
  },
  {
    xValue: 27.87845499,
    yValue: 40.74683488,
  },
  {
    xValue: 28.39410676,
    yValue: 41.54430619,
  },
  {
    xValue: 28.90976128,
    yValue: 42.22784782,
  },
  {
    xValue: 29.42541581,
    yValue: 43.02531609,
  },
  {
    xValue: 29.94107034,
    yValue: 43.70886075,
  },
  {
    xValue: 30.40515831,
    yValue: 44.50632902,
  },
  {
    xValue: 30.92081008,
    yValue: 45.18987369,
  },
  {
    xValue: 31.43646461,
    yValue: 45.98734196,
  },
  {
    xValue: 31.95211913,
    yValue: 46.67088662,
  },
  {
    xValue: 32.46777366,
    yValue: 47.35443129,
  },
  {
    xValue: 32.88029508,
    yValue: 48.03797595,
  },
  {
    xValue: 33.3443858,
    yValue: 48.60759397,
  },
  {
    xValue: 33.86004033,
    yValue: 49.29113864,
  },
  {
    xValue: 34.37568934,
    yValue: 49.8607597,
  },
  {
    xValue: 34.89134387,
    yValue: 50.43038076,
  },
  {
    xValue: 35.3554346,
    yValue: 51.0000003,
  },
  {
    xValue: 35.81951981,
    yValue: 51.56961984,
  },
  {
    xValue: 36.33517434,
    yValue: 52.13924091,
  },
  {
    xValue: 36.85082887,
    yValue: 52.59493684,
  },
  {
    xValue: 37.41804719,
    yValue: 53.05063278,
  },
  {
    xValue: 37.88213792,
    yValue: 53.50632872,
  },
  {
    xValue: 38.44935624,
    yValue: 54.07594978,
  },
  {
    xValue: 39.06813837,
    yValue: 54.53164572,
  },
  {
    xValue: 39.686926,
    yValue: 55.10126526,
  },
  {
    xValue: 40.20258053,
    yValue: 55.55696271,
  },
  {
    xValue: 40.76979886,
    yValue: 56.01265865,
  },
  {
    xValue: 41.23388407,
    yValue: 56.35443098,
  },
  {
    xValue: 41.85267171,
    yValue: 56.81012692,
  },
  {
    xValue: 42.36832624,
    yValue: 57.26582286,
  },
  {
    xValue: 42.88398076,
    yValue: 57.72151879,
  },
  {
    xValue: 43.39962978,
    yValue: 58.17721473,
  },
  {
    xValue: 43.91528431,
    yValue: 58.51898706,
  },
  {
    xValue: 44.53407194,
    yValue: 58.974683,
  },
  {
    xValue: 45.10129027,
    yValue: 59.43037894,
  },
  {
    xValue: 45.61694479,
    yValue: 59.8860764,
  },
];

// GIRLS

export const PRETERM_GIRLS_WEIGHT_COORDS_LINE_1 = [
  {
    xValue: 22.72191523,
    yValue: 0.3885717773,
  },
  {
    xValue: 23.54696358,
    yValue: 0.411428833,
  },
  {
    xValue: 24.1141819,
    yValue: 0.4342858887,
  },
  {
    xValue: 24.73296678,
    yValue: 0.4457141113,
  },
  {
    xValue: 25.35175166,
    yValue: 0.4685717773,
  },
  {
    xValue: 25.97053654,
    yValue: 0.48,
  },
  {
    xValue: 26.58931867,
    yValue: 0.5028570557,
  },
  {
    xValue: 27.25967011,
    yValue: 0.5142858887,
  },
  {
    xValue: 27.93001879,
    yValue: 0.5485717773,
  },
  {
    xValue: 28.60037022,
    yValue: 0.5828570557,
  },
  {
    xValue: 29.2191551,
    yValue: 0.6171429443,
  },
  {
    xValue: 29.78637343,
    yValue: 0.6742858887,
  },
  {
    xValue: 30.35359175,
    yValue: 0.731428833,
  },
  {
    xValue: 30.92081008,
    yValue: 0.8,
  },
  {
    xValue: 31.43646461,
    yValue: 0.88,
  },
  {
    xValue: 32.00368293,
    yValue: 0.971428833,
  },
  {
    xValue: 32.46777366,
    yValue: 1.062857056,
  },
  {
    xValue: 32.93185888,
    yValue: 1.154285889,
  },
  {
    xValue: 33.3443858,
    yValue: 1.245714111,
  },
  {
    xValue: 33.80847102,
    yValue: 1.337142944,
  },
  {
    xValue: 34.32412554,
    yValue: 1.44,
  },
  {
    xValue: 34.73664696,
    yValue: 1.542857056,
  },
  {
    xValue: 35.20073769,
    yValue: 1.645714111,
  },
  {
    xValue: 35.61325911,
    yValue: 1.76,
  },
  {
    xValue: 36.07734983,
    yValue: 1.851428833,
  },
  {
    xValue: 36.54143505,
    yValue: 1.954285889,
  },
  {
    xValue: 36.95396197,
    yValue: 2.045714722,
  },
  {
    xValue: 37.4696165,
    yValue: 2.137142944,
  },
  {
    xValue: 37.98526552,
    yValue: 2.24,
  },
  {
    xValue: 38.55248935,
    yValue: 2.331428833,
  },
  {
    xValue: 39.06813837,
    yValue: 2.422857056,
  },
  {
    xValue: 39.5837929,
    yValue: 2.502857056,
  },
  {
    xValue: 40.15101122,
    yValue: 2.582857056,
  },
  {
    xValue: 40.61510195,
    yValue: 2.662857056,
  },
  {
    xValue: 41.13075647,
    yValue: 2.742857056,
  },
  {
    xValue: 41.59484169,
    yValue: 2.811428833,
  },
  {
    xValue: 42.00736862,
    yValue: 2.891428833,
  },
  {
    xValue: 42.57458694,
    yValue: 2.982857056,
  },
  {
    xValue: 43.09024147,
    yValue: 3.074285889,
  },
  {
    xValue: 43.7605874,
    yValue: 3.177142944,
  },
  {
    xValue: 44.27624192,
    yValue: 3.268571777,
  },
  {
    xValue: 44.79189645,
    yValue: 3.348571777,
  },
  {
    xValue: 45.25598717,
    yValue: 3.417142944,
  },
  {
    xValue: 45.7716417,
    yValue: 3.508571777,
  },
  {
    xValue: 46.33886003,
    yValue: 3.6,
  },
  {
    xValue: 46.85451455,
    yValue: 3.691428833,
  },
  {
    xValue: 47.37016357,
    yValue: 3.782857666,
  },
  {
    xValue: 47.9373874,
    yValue: 3.862857666,
  },
  {
    xValue: 48.40147262,
    yValue: 3.942857666,
  },
  {
    xValue: 48.86556335,
    yValue: 4.011428833,
  },
  {
    xValue: 49.43278167,
    yValue: 4.102857666,
  },
  {
    xValue: 49.9484362,
    yValue: 4.194285889,
  },
];

export const PRETERM_GIRLS_WEIGHT_COORDS_LINE_2 = [
  {
    xValue: 22.48068758,
    yValue: 0.44,
  },
  {
    xValue: 23.08154499,
    yValue: 0.4533325195,
  },
  {
    xValue: 23.80257499,
    yValue: 0.48,
  },
  {
    xValue: 23.80257499,
    yValue: 0.48,
  },
  {
    xValue: 24.40343515,
    yValue: 0.5066662598,
  },
  {
    xValue: 25.00429532,
    yValue: 0.5466662598,
  },
  {
    xValue: 25.66523765,
    yValue: 0.5733325195,
  },
  {
    xValue: 26.26609781,
    yValue: 0.6133325195,
  },
  {
    xValue: 26.92704014,
    yValue: 0.64,
  },
  {
    xValue: 27.58798247,
    yValue: 0.68,
  },
  {
    xValue: 28.2489303,
    yValue: 0.7333331299,
  },
  {
    xValue: 28.90987263,
    yValue: 0.7866662598,
  },
  {
    xValue: 29.51073279,
    yValue: 0.8533331299,
  },
  {
    xValue: 30.17167512,
    yValue: 0.92,
  },
  {
    xValue: 30.77253528,
    yValue: 1,
  },
  {
    xValue: 31.37338994,
    yValue: 1.09333313,
  },
  {
    xValue: 31.91416244,
    yValue: 1.18666626,
  },
  {
    xValue: 32.5150226,
    yValue: 1.30666626,
  },
  {
    xValue: 32.99570743,
    yValue: 1.4,
  },
  {
    xValue: 33.53647993,
    yValue: 1.49333313,
  },
  {
    xValue: 34.07725243,
    yValue: 1.62666626,
  },
  {
    xValue: 34.61802492,
    yValue: 1.74666626,
  },
  {
    xValue: 35.09871526,
    yValue: 1.86666626,
  },
  {
    xValue: 35.63948775,
    yValue: 1.98666626,
  },
  {
    xValue: 36.24034242,
    yValue: 2.10666626,
  },
  {
    xValue: 36.72103275,
    yValue: 2.22666626,
  },
  {
    xValue: 37.26180524,
    yValue: 2.33333313,
  },
  {
    xValue: 37.80257774,
    yValue: 2.44,
  },
  {
    xValue: 38.4034324,
    yValue: 2.56,
  },
  {
    xValue: 38.9442049,
    yValue: 2.65333313,
  },
  {
    xValue: 39.54506506,
    yValue: 2.74666626,
  },
  {
    xValue: 40.08583756,
    yValue: 2.84,
  },
  {
    xValue: 40.62661006,
    yValue: 2.92,
  },
  {
    xValue: 41.16738255,
    yValue: 3.01333313,
  },
  {
    xValue: 41.70815505,
    yValue: 3.09333313,
  },
  {
    xValue: 42.24892755,
    yValue: 3.2,
  },
  {
    xValue: 42.78970004,
    yValue: 3.29333313,
  },
  {
    xValue: 43.33047254,
    yValue: 3.38666626,
  },
  {
    xValue: 43.81115737,
    yValue: 3.48,
  },
  {
    xValue: 44.35192987,
    yValue: 3.57333313,
  },
  {
    xValue: 44.89270236,
    yValue: 3.66666626,
  },
  {
    xValue: 45.43347486,
    yValue: 3.76,
  },
  {
    xValue: 45.97424736,
    yValue: 3.86666626,
  },
  {
    xValue: 46.51501985,
    yValue: 3.96,
  },
  {
    xValue: 47.05579235,
    yValue: 4.04,
  },
  {
    xValue: 47.65665251,
    yValue: 4.14666626,
  },
  {
    xValue: 48.19742501,
    yValue: 4.24,
  },
  {
    xValue: 48.73819751,
    yValue: 4.33333313,
  },
  {
    xValue: 49.33905767,
    yValue: 4.42666626,
  },
  {
    xValue: 49.93991233,
    yValue: 4.52,
  },
];

export const PRETERM_GIRLS_WEIGHT_COORDS_LINE_3 = [
  {
    xValue: 22.48068758,
    yValue: 0.4933325195,
  },
  {
    xValue: 22.96137516,
    yValue: 0.5333325195,
  },
  {
    xValue: 23.56223257,
    yValue: 0.5733325195,
  },
  {
    xValue: 24.10300782,
    yValue: 0.6133325195,
  },
  {
    xValue: 24.70386248,
    yValue: 0.6666662598,
  },
  {
    xValue: 25.30472265,
    yValue: 0.72,
  },
  {
    xValue: 25.90558281,
    yValue: 0.7866662598,
  },
  {
    xValue: 26.50643747,
    yValue: 0.84,
  },
  {
    xValue: 27.04720997,
    yValue: 0.9066662598,
  },
  {
    xValue: 27.64807013,
    yValue: 0.96,
  },
  {
    xValue: 28.18884263,
    yValue: 1.05333313,
  },
  {
    xValue: 28.78970279,
    yValue: 1.10666626,
  },
  {
    xValue: 29.27038762,
    yValue: 1.18666626,
  },
  {
    xValue: 29.81116012,
    yValue: 1.26666626,
  },
  {
    xValue: 30.35193262,
    yValue: 1.34666626,
  },
  {
    xValue: 30.83261745,
    yValue: 1.44,
  },
  {
    xValue: 31.31330778,
    yValue: 1.54666626,
  },
  {
    xValue: 31.79399261,
    yValue: 1.64,
  },
  {
    xValue: 32.27467744,
    yValue: 1.73333313,
  },
  {
    xValue: 32.6952801,
    yValue: 1.82666626,
  },
  {
    xValue: 33.17596493,
    yValue: 1.93333313,
  },
  {
    xValue: 33.65665526,
    yValue: 2.02666626,
  },
  {
    xValue: 34.07725243,
    yValue: 2.13333313,
  },
  {
    xValue: 34.49785509,
    yValue: 2.24,
  },
  {
    xValue: 34.97853992,
    yValue: 2.33333313,
  },
  {
    xValue: 35.33905492,
    yValue: 2.42666626,
  },
  {
    xValue: 35.69956992,
    yValue: 2.53333313,
  },
  {
    xValue: 36.06008492,
    yValue: 2.62666626,
  },
  {
    xValue: 36.48068758,
    yValue: 2.72,
  },
  {
    xValue: 36.90129025,
    yValue: 2.81333313,
  },
  {
    xValue: 37.32188741,
    yValue: 2.90666626,
  },
  {
    xValue: 37.74249007,
    yValue: 3,
  },
  {
    xValue: 38.2231749,
    yValue: 3.09333313,
  },
  {
    xValue: 38.70386523,
    yValue: 3.18666626,
  },
  {
    xValue: 39.18455006,
    yValue: 3.26666626,
  },
  {
    xValue: 39.66523489,
    yValue: 3.36,
  },
  {
    xValue: 40.14592523,
    yValue: 3.44,
  },
  {
    xValue: 40.62661006,
    yValue: 3.52,
  },
  {
    xValue: 41.10729489,
    yValue: 3.62666626,
  },
  {
    xValue: 41.64806738,
    yValue: 3.72,
  },
  {
    xValue: 42.12875771,
    yValue: 3.81333313,
  },
  {
    xValue: 42.66953021,
    yValue: 3.92,
  },
  {
    xValue: 43.15021504,
    yValue: 4.01333313,
  },
  {
    xValue: 43.63089987,
    yValue: 4.12,
  },
  {
    xValue: 44.17167237,
    yValue: 4.21333313,
  },
  {
    xValue: 44.6523627,
    yValue: 4.32,
  },
  {
    xValue: 45.13304753,
    yValue: 4.41333313,
  },
  {
    xValue: 45.55365019,
    yValue: 4.50666626,
  },
  {
    xValue: 46.03433502,
    yValue: 4.61333313,
  },
  {
    xValue: 46.57510752,
    yValue: 4.70666626,
  },
  {
    xValue: 46.99571018,
    yValue: 4.8,
  },
  {
    xValue: 47.47639502,
    yValue: 4.88,
  },
  {
    xValue: 47.89699768,
    yValue: 4.97333313,
  },
  {
    xValue: 48.37768251,
    yValue: 5.05333313,
  },
  {
    xValue: 48.85836734,
    yValue: 5.14666626,
  },
  {
    xValue: 49.4592275,
    yValue: 5.25333313,
  },
  {
    xValue: 49.93991233,
    yValue: 5.34666626,
  },
];

export const PRETERM_GIRLS_WEIGHT_COORDS_LINE_4 = [
  {
    xValue: 22.72103,
    yValue: 0.6266662598,
  },
  {
    xValue: 23.32189016,
    yValue: 0.68,
  },
  {
    xValue: 23.98283249,
    yValue: 0.7466662598,
  },
  {
    xValue: 24.70386248,
    yValue: 0.8133331299,
  },
  {
    xValue: 25.30472265,
    yValue: 0.8933331299,
  },
  {
    xValue: 25.84549514,
    yValue: 0.96,
  },
  {
    xValue: 26.44635531,
    yValue: 1.04,
  },
  {
    xValue: 26.9871278,
    yValue: 1.12,
  },
  {
    xValue: 27.5279003,
    yValue: 1.21333313,
  },
  {
    xValue: 28.0686728,
    yValue: 1.30666626,
  },
  {
    xValue: 28.66952746,
    yValue: 1.41333313,
  },
  {
    xValue: 29.27038762,
    yValue: 1.52,
  },
  {
    xValue: 29.87124779,
    yValue: 1.64,
  },
  {
    xValue: 30.29184495,
    yValue: 1.74666626,
  },
  {
    xValue: 30.71244762,
    yValue: 1.84,
  },
  {
    xValue: 31.19313245,
    yValue: 1.94666626,
  },
  {
    xValue: 31.67382278,
    yValue: 2.05333313,
  },
  {
    xValue: 32.09441994,
    yValue: 2.17333313,
  },
  {
    xValue: 32.57511027,
    yValue: 2.28,
  },
  {
    xValue: 32.99570743,
    yValue: 2.4,
  },
  {
    xValue: 33.47639777,
    yValue: 2.52,
  },
  {
    xValue: 33.9570826,
    yValue: 2.65333313,
  },
  {
    xValue: 34.43776743,
    yValue: 2.77333313,
  },
  {
    xValue: 34.85837009,
    yValue: 2.88,
  },
  {
    xValue: 35.27897275,
    yValue: 3,
  },
  {
    xValue: 35.63948775,
    yValue: 3.12,
  },
  {
    xValue: 36.12017258,
    yValue: 3.22666626,
  },
  {
    xValue: 36.48068758,
    yValue: 3.33333313,
  },
  {
    xValue: 36.90129025,
    yValue: 3.44,
  },
  {
    xValue: 37.32188741,
    yValue: 3.54666626,
  },
  {
    xValue: 37.74249007,
    yValue: 3.64,
  },
  {
    xValue: 38.2231749,
    yValue: 3.74666626,
  },
  {
    xValue: 38.70386523,
    yValue: 3.84,
  },
  {
    xValue: 39.1244624,
    yValue: 3.93333313,
  },
  {
    xValue: 39.66523489,
    yValue: 4.04,
  },
  {
    xValue: 40.08583756,
    yValue: 4.13333313,
  },
  {
    xValue: 40.62661006,
    yValue: 4.22666626,
  },
  {
    xValue: 41.10729489,
    yValue: 4.33333313,
  },
  {
    xValue: 41.58798522,
    yValue: 4.44,
  },
  {
    xValue: 42.06867005,
    yValue: 4.54666626,
  },
  {
    xValue: 42.60944254,
    yValue: 4.65333313,
  },
  {
    xValue: 43.09012737,
    yValue: 4.77333313,
  },
  {
    xValue: 43.57081771,
    yValue: 4.88,
  },
  {
    xValue: 43.99141487,
    yValue: 4.98666626,
  },
  {
    xValue: 44.4721052,
    yValue: 5.09333313,
  },
  {
    xValue: 44.89270236,
    yValue: 5.18666626,
  },
  {
    xValue: 45.37339269,
    yValue: 5.28,
  },
  {
    xValue: 45.73390769,
    yValue: 5.38666626,
  },
  {
    xValue: 46.21459252,
    yValue: 5.48,
  },
  {
    xValue: 46.63519519,
    yValue: 5.58666626,
  },
  {
    xValue: 47.05579235,
    yValue: 5.69333313,
  },
  {
    xValue: 47.53648268,
    yValue: 5.8,
  },
  {
    xValue: 48.01716751,
    yValue: 5.906666565,
  },
  {
    xValue: 48.49785234,
    yValue: 6,
  },
  {
    xValue: 48.97854267,
    yValue: 6.09333313,
  },
  {
    xValue: 49.4592275,
    yValue: 6.186666565,
  },
  {
    xValue: 49.93991233,
    yValue: 6.29333313,
  },
];

export const PRETERM_GIRLS_WEIGHT_COORDS_LINE_5 = [
  {
    xValue: 22.74677851,
    yValue: 0.6849698639,
  },
  {
    xValue: 23.313304,
    yValue: 0.7306839752,
  },
  {
    xValue: 23.87982674,
    yValue: 0.7878269196,
  },
  {
    xValue: 24.39484891,
    yValue: 0.8563980865,
  },
  {
    xValue: 25.01287497,
    yValue: 0.9363980865,
  },
  {
    xValue: 25.63090102,
    yValue: 1.016398087,
  },
  {
    xValue: 26.19742376,
    yValue: 1.10782692,
  },
  {
    xValue: 26.7639465,
    yValue: 1.210683975,
  },
  {
    xValue: 27.43347587,
    yValue: 1.313541031,
  },
  {
    xValue: 27.99999861,
    yValue: 1.416398087,
  },
  {
    xValue: 28.61802467,
    yValue: 1.542112808,
  },
  {
    xValue: 29.18454741,
    yValue: 1.656398087,
  },
  {
    xValue: 29.75107015,
    yValue: 1.782112808,
  },
  {
    xValue: 30.26609232,
    yValue: 1.90782692,
  },
  {
    xValue: 30.67811061,
    yValue: 2.022112808,
  },
  {
    xValue: 31.14162946,
    yValue: 2.124969864,
  },
  {
    xValue: 31.553645,
    yValue: 2.250683975,
  },
  {
    xValue: 32.01716385,
    yValue: 2.364969864,
  },
  {
    xValue: 32.48068546,
    yValue: 2.490683975,
  },
  {
    xValue: 32.89270099,
    yValue: 2.62782692,
  },
  {
    xValue: 33.3562226,
    yValue: 2.753541031,
  },
  {
    xValue: 33.76823814,
    yValue: 2.856398697,
  },
  {
    xValue: 34.12875311,
    yValue: 2.982112808,
  },
  {
    xValue: 34.54076864,
    yValue: 3.084969864,
  },
  {
    xValue: 34.95278968,
    yValue: 3.210683975,
  },
  {
    xValue: 35.46780635,
    yValue: 3.336398697,
  },
  {
    xValue: 35.93132796,
    yValue: 3.473541031,
  },
  {
    xValue: 36.34334349,
    yValue: 3.599255753,
  },
  {
    xValue: 36.75536453,
    yValue: 3.713541641,
  },
  {
    xValue: 37.16738007,
    yValue: 3.82782692,
  },
  {
    xValue: 37.57939561,
    yValue: 3.942112808,
  },
  {
    xValue: 38.04291721,
    yValue: 4.044969864,
  },
  {
    xValue: 38.45493275,
    yValue: 4.136398697,
  },
  {
    xValue: 38.91845435,
    yValue: 4.22782692,
  },
  {
    xValue: 39.33046989,
    yValue: 4.330683975,
  },
  {
    xValue: 39.79398599,
    yValue: 4.422112808,
  },
  {
    xValue: 40.30900817,
    yValue: 4.524969864,
  },
  {
    xValue: 40.7210292,
    yValue: 4.604969864,
  },
  {
    xValue: 41.13304474,
    yValue: 4.696398697,
  },
  {
    xValue: 41.54506028,
    yValue: 4.799255753,
  },
  {
    xValue: 41.95707581,
    yValue: 4.913541641,
  },
  {
    xValue: 42.42059742,
    yValue: 5.016398697,
  },
  {
    xValue: 42.83261296,
    yValue: 5.119255753,
  },
  {
    xValue: 43.29613456,
    yValue: 5.210683975,
  },
  {
    xValue: 43.65664953,
    yValue: 5.313541641,
  },
  {
    xValue: 44.06866507,
    yValue: 5.416398697,
  },
  {
    xValue: 44.42918004,
    yValue: 5.507827225,
  },
  {
    xValue: 44.84119558,
    yValue: 5.61068428,
  },
  {
    xValue: 45.30471718,
    yValue: 5.724969864,
  },
  {
    xValue: 45.71673272,
    yValue: 5.827827225,
  },
  {
    xValue: 46.18025432,
    yValue: 5.93068428,
  },
  {
    xValue: 46.59226986,
    yValue: 6.033541336,
  },
  {
    xValue: 47.0042854,
    yValue: 6.136398697,
  },
  {
    xValue: 47.41630644,
    yValue: 6.227827225,
  },
  {
    xValue: 47.87982254,
    yValue: 6.33068428,
  },
  {
    xValue: 48.24033751,
    yValue: 6.422112808,
  },
  {
    xValue: 48.65235855,
    yValue: 6.513541336,
  },
  {
    xValue: 49.11587465,
    yValue: 6.604970169,
  },
  {
    xValue: 49.52789569,
    yValue: 6.696398697,
  },
  {
    xValue: 49.93991123,
    yValue: 6.787827225,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE_1 = [
  {
    xValue: 23.46586959,
    yValue: 18.72620962,
  },
  {
    xValue: 24.00830499,
    yValue: 19.12595576,
  },
  {
    xValue: 24.55074039,
    yValue: 19.52570191,
  },
  {
    xValue: 25.13837874,
    yValue: 20.02538459,
  },
  {
    xValue: 25.72601708,
    yValue: 20.52506728,
  },
  {
    xValue: 26.31365543,
    yValue: 21.02474996,
  },
  {
    xValue: 26.90129378,
    yValue: 21.52443264,
  },
  {
    xValue: 27.53413507,
    yValue: 22.02411532,
  },
  {
    xValue: 28.07657047,
    yValue: 22.52379801,
  },
  {
    xValue: 28.61900587,
    yValue: 23.02348069,
  },
  {
    xValue: 29.20664421,
    yValue: 23.52316337,
  },
  {
    xValue: 29.79428256,
    yValue: 24.12278259,
  },
  {
    xValue: 30.42712386,
    yValue: 24.62246528,
  },
  {
    xValue: 31.05996516,
    yValue: 25.12214796,
  },
  {
    xValue: 31.6476035,
    yValue: 25.72176718,
  },
  {
    xValue: 32.23524185,
    yValue: 26.22144986,
  },
  {
    xValue: 32.8228802,
    yValue: 26.82106908,
  },
  {
    xValue: 33.41051854,
    yValue: 27.32075177,
  },
  {
    xValue: 33.99815689,
    yValue: 27.82043445,
  },
  {
    xValue: 34.54059229,
    yValue: 28.32011713,
  },
  {
    xValue: 35.08302769,
    yValue: 28.71986328,
  },
  {
    xValue: 35.67066603,
    yValue: 29.11960942,
  },
  {
    xValue: 36.21310143,
    yValue: 29.51935557,
  },
  {
    xValue: 36.80073978,
    yValue: 30.01903825,
  },
  {
    xValue: 37.38837812,
    yValue: 30.4187844,
  },
  {
    xValue: 37.97601647,
    yValue: 30.81853055,
  },
  {
    xValue: 38.56365482,
    yValue: 31.21827669,
  },
  {
    xValue: 39.19649611,
    yValue: 31.61802284,
  },
  {
    xValue: 39.78413446,
    yValue: 31.91783245,
  },
  {
    xValue: 40.37177281,
    yValue: 32.3175786,
  },
  {
    xValue: 41.00461411,
    yValue: 32.61738821,
  },
  {
    xValue: 41.5470495,
    yValue: 32.91719782,
  },
  {
    xValue: 42.0894849,
    yValue: 33.21700743,
  },
  {
    xValue: 42.67712325,
    yValue: 33.51681704,
  },
  {
    xValue: 43.26476159,
    yValue: 33.71669011,
  },
  {
    xValue: 43.85239994,
    yValue: 34.01649972,
  },
  {
    xValue: 44.48524124,
    yValue: 34.21637279,
  },
  {
    xValue: 45.11808254,
    yValue: 34.5161824,
  },
  {
    xValue: 45.70572088,
    yValue: 34.71605547,
  },
  {
    xValue: 46.33856218,
    yValue: 35.01586508,
  },
  {
    xValue: 47.01660643,
    yValue: 35.21573816,
  },
  {
    xValue: 47.60424477,
    yValue: 35.51554777,
  },
  {
    xValue: 48.14668017,
    yValue: 35.71542084,
  },
  {
    xValue: 48.73431852,
    yValue: 35.91529391,
  },
  {
    xValue: 49.36715981,
    yValue: 36.11516699,
  },
  {
    xValue: 50.04520406,
    yValue: 36.31504006,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE_2 = [
  {
    xValue: 23.46586959,
    yValue: 19.2258923,
  },
  {
    xValue: 23.91789909,
    yValue: 19.72557498,
  },
  {
    xValue: 24.50553744,
    yValue: 20.22525767,
  },
  {
    xValue: 25.09317579,
    yValue: 20.72494035,
  },
  {
    xValue: 25.77122003,
    yValue: 21.32455957,
  },
  {
    xValue: 26.35885838,
    yValue: 21.82424225,
  },
  {
    xValue: 26.99169968,
    yValue: 22.42386147,
  },
  {
    xValue: 27.66974392,
    yValue: 23.02348069,
  },
  {
    xValue: 28.34778817,
    yValue: 23.62309991,
  },
  {
    xValue: 29.02583242,
    yValue: 24.22271913,
  },
  {
    xValue: 29.74907961,
    yValue: 24.82233835,
  },
  {
    xValue: 30.38192091,
    yValue: 25.42195757,
  },
  {
    xValue: 30.96955926,
    yValue: 26.02157679,
  },
  {
    xValue: 31.5571976,
    yValue: 26.42132294,
  },
  {
    xValue: 32.1900389,
    yValue: 27.02094216,
  },
  {
    xValue: 32.77767725,
    yValue: 27.62056138,
  },
  {
    xValue: 33.36531559,
    yValue: 28.12024406,
  },
  {
    xValue: 33.86254804,
    yValue: 28.61992674,
  },
  {
    xValue: 34.45018639,
    yValue: 29.01967289,
  },
  {
    xValue: 35.12823064,
    yValue: 29.71922864,
  },
  {
    xValue: 35.71586898,
    yValue: 30.11897479,
  },
  {
    xValue: 36.25830438,
    yValue: 30.51872094,
  },
  {
    xValue: 36.80073978,
    yValue: 30.91846708,
  },
  {
    xValue: 37.38837812,
    yValue: 31.41814977,
  },
  {
    xValue: 38.02121942,
    yValue: 31.71795938,
  },
  {
    xValue: 38.60885777,
    yValue: 32.11770552,
  },
  {
    xValue: 39.19649611,
    yValue: 32.51745167,
  },
  {
    xValue: 39.91974331,
    yValue: 32.81726128,
  },
  {
    xValue: 40.50738166,
    yValue: 33.11707089,
  },
  {
    xValue: 41.14022295,
    yValue: 33.51681704,
  },
  {
    xValue: 41.77306425,
    yValue: 33.81662665,
  },
  {
    xValue: 42.49631145,
    yValue: 34.21637279,
  },
  {
    xValue: 43.12915274,
    yValue: 34.5161824,
  },
  {
    xValue: 43.80719699,
    yValue: 34.71605547,
  },
  {
    xValue: 44.39483534,
    yValue: 35.01586508,
  },
  {
    xValue: 45.11808254,
    yValue: 35.31567469,
  },
  {
    xValue: 45.75092383,
    yValue: 35.51554777,
  },
  {
    xValue: 46.47417103,
    yValue: 35.81535738,
  },
  {
    xValue: 47.19741822,
    yValue: 36.11516699,
  },
  {
    xValue: 47.87546247,
    yValue: 36.31504006,
  },
  {
    xValue: 48.64391262,
    yValue: 36.61484967,
  },
  {
    xValue: 49.36715981,
    yValue: 36.91465928,
  },
  {
    xValue: 50.04520406,
    yValue: 37.01459582,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE_3 = [
  {
    xValue: 23.69188434,
    yValue: 21.1246865,
  },
  {
    xValue: 24.32472564,
    yValue: 21.72430572,
  },
  {
    xValue: 24.95756694,
    yValue: 22.2239884,
  },
  {
    xValue: 25.63561118,
    yValue: 22.82360762,
  },
  {
    xValue: 26.31365543,
    yValue: 23.52316337,
  },
  {
    xValue: 26.99169968,
    yValue: 24.12278259,
  },
  {
    xValue: 27.66974392,
    yValue: 24.72240181,
  },
  {
    xValue: 28.25738227,
    yValue: 25.32202103,
  },
  {
    xValue: 28.84502062,
    yValue: 25.82170372,
  },
  {
    xValue: 29.43265896,
    yValue: 26.42132294,
  },
  {
    xValue: 30.02029731,
    yValue: 26.92100562,
  },
  {
    xValue: 30.60793566,
    yValue: 27.52062484,
  },
  {
    xValue: 31.24077695,
    yValue: 28.02030752,
  },
  {
    xValue: 31.8284153,
    yValue: 28.71986328,
  },
  {
    xValue: 32.41605365,
    yValue: 29.21954596,
  },
  {
    xValue: 33.04889495,
    yValue: 29.81916518,
  },
  {
    xValue: 33.68173624,
    yValue: 30.4187844,
  },
  {
    xValue: 34.31457754,
    yValue: 30.91846708,
  },
  {
    xValue: 34.94741884,
    yValue: 31.41814977,
  },
  {
    xValue: 35.53505718,
    yValue: 31.91783245,
  },
  {
    xValue: 36.16789848,
    yValue: 32.41751513,
  },
  {
    xValue: 36.75553683,
    yValue: 32.81726128,
  },
  {
    xValue: 37.43358107,
    yValue: 33.21700743,
  },
  {
    xValue: 38.06642237,
    yValue: 33.61675357,
  },
  {
    xValue: 38.65406072,
    yValue: 34.01649972,
  },
  {
    xValue: 39.28690201,
    yValue: 34.31630933,
  },
  {
    xValue: 39.87454036,
    yValue: 34.61611894,
  },
  {
    xValue: 40.50738166,
    yValue: 35.01586508,
  },
  {
    xValue: 41.14022295,
    yValue: 35.31567469,
  },
  {
    xValue: 41.8182672,
    yValue: 35.6154843,
  },
  {
    xValue: 42.40590555,
    yValue: 35.91529391,
  },
  {
    xValue: 43.03874685,
    yValue: 36.11516699,
  },
  {
    xValue: 43.67158814,
    yValue: 36.31504006,
  },
  {
    xValue: 44.30442944,
    yValue: 36.61484967,
  },
  {
    xValue: 44.89206779,
    yValue: 36.91465928,
  },
  {
    xValue: 45.57011203,
    yValue: 37.11453235,
  },
  {
    xValue: 46.20295333,
    yValue: 37.31440543,
  },
  {
    xValue: 46.83579463,
    yValue: 37.5142785,
  },
  {
    xValue: 47.46863592,
    yValue: 37.81408811,
  },
  {
    xValue: 48.10147722,
    yValue: 38.01396118,
  },
  {
    xValue: 48.73431852,
    yValue: 38.21383426,
  },
  {
    xValue: 49.45756571,
    yValue: 38.41370733,
  },
  {
    xValue: 50.04520406,
    yValue: 38.6135804,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE_4 = [
  {
    xValue: 23.54981767,
    yValue: 22.42386223,
  },
  {
    xValue: 24.27306481,
    yValue: 23.10913873,
  },
  {
    xValue: 24.84132963,
    yValue: 23.68020552,
  },
  {
    xValue: 25.46125614,
    yValue: 24.36548507,
  },
  {
    xValue: 26.13284159,
    yValue: 25.05076461,
  },
  {
    xValue: 26.75276811,
    yValue: 25.62182836,
  },
  {
    xValue: 27.42435632,
    yValue: 26.19289515,
  },
  {
    xValue: 28.04428007,
    yValue: 26.76396195,
  },
  {
    xValue: 28.61254765,
    yValue: 27.44924149,
  },
  {
    xValue: 29.18081247,
    yValue: 28.02030523,
  },
  {
    xValue: 29.85239792,
    yValue: 28.59137203,
  },
  {
    xValue: 30.4206655,
    yValue: 29.16243882,
  },
  {
    xValue: 30.98893033,
    yValue: 29.73350257,
  },
  {
    xValue: 31.55719515,
    yValue: 30.30456936,
  },
  {
    xValue: 32.12546273,
    yValue: 30.76142341,
  },
  {
    xValue: 32.64206862,
    yValue: 31.33248715,
  },
  {
    xValue: 33.15867451,
    yValue: 31.7893412,
  },
  {
    xValue: 33.67527488,
    yValue: 32.24619525,
  },
  {
    xValue: 34.29520415,
    yValue: 32.70304624,
  },
  {
    xValue: 34.96679236,
    yValue: 33.38832579,
  },
  {
    xValue: 35.53505718,
    yValue: 33.84517983,
  },
  {
    xValue: 36.15498094,
    yValue: 34.18781808,
  },
  {
    xValue: 36.72324576,
    yValue: 34.64467213,
  },
  {
    xValue: 37.39483397,
    yValue: 34.98731037,
  },
  {
    xValue: 38.01475772,
    yValue: 35.44416442,
  },
  {
    xValue: 38.68634593,
    yValue: 35.78680267,
  },
  {
    xValue: 39.35793414,
    yValue: 36.12944396,
  },
  {
    xValue: 39.92619896,
    yValue: 36.47208221,
  },
  {
    xValue: 40.54612272,
    yValue: 36.70050771,
  },
  {
    xValue: 41.16605199,
    yValue: 36.92893625,
  },
  {
    xValue: 41.73431681,
    yValue: 37.2715745,
  },
  {
    xValue: 42.35424057,
    yValue: 37.5,
  },
  {
    xValue: 42.97416984,
    yValue: 37.72842855,
  },
  {
    xValue: 43.64575805,
    yValue: 38.07106679,
  },
  {
    xValue: 44.26568181,
    yValue: 38.29949229,
  },
  {
    xValue: 44.88560556,
    yValue: 38.52792084,
  },
  {
    xValue: 45.50553484,
    yValue: 38.75634634,
  },
  {
    xValue: 46.12545859,
    yValue: 38.98477184,
  },
  {
    xValue: 46.84870573,
    yValue: 39.21319733,
  },
  {
    xValue: 47.46863501,
    yValue: 39.44162588,
  },
  {
    xValue: 48.08855876,
    yValue: 39.55583863,
  },
  {
    xValue: 48.76014697,
    yValue: 39.89847688,
  },
  {
    xValue: 49.38007073,
    yValue: 40.01268963,
  },
  {
    xValue: 50,
    yValue: 40.24111818,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE_5 = [
  {
    xValue: 23.46586959,
    yValue: 23.02348069,
  },
  {
    xValue: 23.87269614,
    yValue: 23.52316337,
  },
  {
    xValue: 24.41513154,
    yValue: 24.02284606,
  },
  {
    xValue: 24.95756694,
    yValue: 24.62246528,
  },
  {
    xValue: 25.54520528,
    yValue: 25.12214796,
  },
  {
    xValue: 26.04243773,
    yValue: 25.72176718,
  },
  {
    xValue: 26.63007608,
    yValue: 26.3213864,
  },
  {
    xValue: 27.17251148,
    yValue: 26.92100562,
  },
  {
    xValue: 27.76014982,
    yValue: 27.4206883,
  },
  {
    xValue: 28.30258522,
    yValue: 27.92037098,
  },
  {
    xValue: 28.89022357,
    yValue: 28.61992674,
  },
  {
    xValue: 29.47786191,
    yValue: 29.11960942,
  },
  {
    xValue: 30.02029731,
    yValue: 29.61929211,
  },
  {
    xValue: 30.56273271,
    yValue: 30.21891133,
  },
  {
    xValue: 31.10516811,
    yValue: 30.71859401,
  },
  {
    xValue: 31.69280645,
    yValue: 31.31821323,
  },
  {
    xValue: 32.2804448,
    yValue: 31.91783245,
  },
  {
    xValue: 32.86808315,
    yValue: 32.41751513,
  },
  {
    xValue: 33.41051854,
    yValue: 32.91719782,
  },
  {
    xValue: 34.04335984,
    yValue: 33.51681704,
  },
  {
    xValue: 34.67620114,
    yValue: 33.91656318,
  },
  {
    xValue: 35.21863653,
    yValue: 34.41624586,
  },
  {
    xValue: 35.80627488,
    yValue: 34.81599201,
  },
  {
    xValue: 36.34871028,
    yValue: 35.31567469,
  },
  {
    xValue: 36.93634863,
    yValue: 35.6154843,
  },
  {
    xValue: 37.61439287,
    yValue: 36.01523045,
  },
  {
    xValue: 38.24723417,
    yValue: 36.4149766,
  },
  {
    xValue: 38.92527842,
    yValue: 36.71478621,
  },
  {
    xValue: 39.51291676,
    yValue: 37.11453235,
  },
  {
    xValue: 40.14575806,
    yValue: 37.31440543,
  },
  {
    xValue: 40.77859936,
    yValue: 37.61421504,
  },
  {
    xValue: 41.32103475,
    yValue: 37.81408811,
  },
  {
    xValue: 41.999079,
    yValue: 38.21383426,
  },
  {
    xValue: 42.6319203,
    yValue: 38.41370733,
  },
  {
    xValue: 43.26476159,
    yValue: 38.71351694,
  },
  {
    xValue: 43.89760289,
    yValue: 39.01332655,
  },
  {
    xValue: 44.53044419,
    yValue: 39.11326309,
  },
  {
    xValue: 45.16328548,
    yValue: 39.31313616,
  },
  {
    xValue: 45.79612678,
    yValue: 39.61294577,
  },
  {
    xValue: 46.42896808,
    yValue: 39.81281884,
  },
  {
    xValue: 47.06180938,
    yValue: 40.01269192,
  },
  {
    xValue: 47.69465067,
    yValue: 40.21256499,
  },
  {
    xValue: 48.28228902,
    yValue: 40.41243806,
  },
  {
    xValue: 48.91513032,
    yValue: 40.61231113,
  },
  {
    xValue: 49.50276866,
    yValue: 40.81218421,
  },
  {
    xValue: 50.04520406,
    yValue: 41.01205728,
  },
];

export const PRETERM_GIRLS_LENGTH_COORDS_LINE_1 = [
  {
    xValue: 23.39483535,
    yValue: 25.96446965,
  },
  {
    xValue: 23.85977954,
    yValue: 26.5355334,
  },
  {
    xValue: 24.37638543,
    yValue: 27.22081294,
  },
  {
    xValue: 24.94465026,
    yValue: 27.67766699,
  },
  {
    xValue: 25.56457677,
    yValue: 28.47715928,
  },
  {
    xValue: 26.08118266,
    yValue: 29.04822607,
  },
  {
    xValue: 26.64944748,
    yValue: 29.61928982,
  },
  {
    xValue: 27.2177123,
    yValue: 30.30456936,
  },
  {
    xValue: 27.78597989,
    yValue: 30.98984891,
  },
  {
    xValue: 28.35424471,
    yValue: 31.5609157,
  },
  {
    xValue: 28.8708506,
    yValue: 32.24619525,
  },
  {
    xValue: 29.38745373,
    yValue: 32.81725899,
  },
  {
    xValue: 29.90405962,
    yValue: 33.50253854,
  },
  {
    xValue: 30.4206655,
    yValue: 34.07360533,
  },
  {
    xValue: 30.8856097,
    yValue: 34.75888487,
  },
  {
    xValue: 31.40221559,
    yValue: 35.44416442,
  },
  {
    xValue: 31.97048041,
    yValue: 36.24365671,
  },
  {
    xValue: 32.59040417,
    yValue: 37.043149,
  },
  {
    xValue: 33.10701006,
    yValue: 37.72842855,
  },
  {
    xValue: 33.67527488,
    yValue: 38.52792084,
  },
  {
    xValue: 34.19188077,
    yValue: 39.21319733,
  },
  {
    xValue: 34.70848666,
    yValue: 39.89847688,
  },
  {
    xValue: 35.22509255,
    yValue: 40.58375642,
  },
  {
    xValue: 35.79335737,
    yValue: 41.26903597,
  },
  {
    xValue: 36.30996326,
    yValue: 42.06852826,
  },
  {
    xValue: 36.87822808,
    yValue: 42.63959505,
  },
  {
    xValue: 37.4464929,
    yValue: 43.3248746,
  },
  {
    xValue: 38.01475772,
    yValue: 44.01015414,
  },
  {
    xValue: 38.634687,
    yValue: 44.69543064,
  },
  {
    xValue: 39.25461075,
    yValue: 45.38071018,
  },
  {
    xValue: 39.77121664,
    yValue: 45.83756423,
  },
  {
    xValue: 40.2361636,
    yValue: 46.40863102,
  },
  {
    xValue: 40.80442842,
    yValue: 46.86548202,
  },
  {
    xValue: 41.42435218,
    yValue: 47.55076156,
  },
  {
    xValue: 41.992617,
    yValue: 48.12182836,
  },
  {
    xValue: 42.56088734,
    yValue: 48.57867935,
  },
  {
    xValue: 43.12915216,
    yValue: 49.0355334,
  },
  {
    xValue: 43.74907592,
    yValue: 49.49238439,
  },
  {
    xValue: 44.21402287,
    yValue: 50.06345119,
  },
  {
    xValue: 44.7822877,
    yValue: 50.40609096,
  },
  {
    xValue: 45.35055252,
    yValue: 50.86294348,
  },
  {
    xValue: 45.91881734,
    yValue: 51.20558325,
  },
  {
    xValue: 46.53874661,
    yValue: 51.66243577,
  },
  {
    xValue: 47.10701144,
    yValue: 52.11928982,
  },
  {
    xValue: 47.72693519,
    yValue: 52.46192807,
  },
  {
    xValue: 48.29520001,
    yValue: 52.91878211,
  },
  {
    xValue: 48.86347036,
    yValue: 53.26142036,
  },
  {
    xValue: 49.43173518,
    yValue: 53.71827441,
  },
  {
    xValue: 50,
    yValue: 54.06091418,
  },
];

export const PRETERM_GIRLS_LENGTH_COORDS_LINE_2 = [
  {
    xValue: 23.49815597,
    yValue: 27.22081294,
  },
  {
    xValue: 24.0664208,
    yValue: 27.90609249,
  },
  {
    xValue: 24.58302669,
    yValue: 28.47715928,
  },
  {
    xValue: 25.2029532,
    yValue: 29.27665157,
  },
  {
    xValue: 25.87453865,
    yValue: 30.07614387,
  },
  {
    xValue: 26.44280623,
    yValue: 30.76142341,
  },
  {
    xValue: 27.11439168,
    yValue: 31.5609157,
  },
  {
    xValue: 27.73431819,
    yValue: 32.24619525,
  },
  {
    xValue: 28.40590364,
    yValue: 33.04568754,
  },
  {
    xValue: 29.02583016,
    yValue: 33.84517983,
  },
  {
    xValue: 29.54243605,
    yValue: 34.53045938,
  },
  {
    xValue: 30.05904193,
    yValue: 35.21573587,
  },
  {
    xValue: 30.62730676,
    yValue: 35.90101541,
  },
  {
    xValue: 31.14391265,
    yValue: 36.58629496,
  },
  {
    xValue: 31.66051578,
    yValue: 37.38578725,
  },
  {
    xValue: 32.2287806,
    yValue: 38.07106679,
  },
  {
    xValue: 32.74538649,
    yValue: 38.87055909,
  },
  {
    xValue: 33.26199238,
    yValue: 39.55583863,
  },
  {
    xValue: 33.8302572,
    yValue: 40.35533092,
  },
  {
    xValue: 34.39852202,
    yValue: 41.15482322,
  },
  {
    xValue: 34.86346898,
    yValue: 41.84010276,
  },
  {
    xValue: 35.4317338,
    yValue: 42.29695376,
  },
  {
    xValue: 35.89668075,
    yValue: 43.09644605,
  },
  {
    xValue: 36.46494558,
    yValue: 43.66751285,
  },
  {
    xValue: 37.0332104,
    yValue: 44.46700514,
  },
  {
    xValue: 37.65313415,
    yValue: 45.03807193,
  },
  {
    xValue: 38.16974004,
    yValue: 45.60913873,
  },
  {
    xValue: 38.84132825,
    yValue: 46.29441522,
  },
  {
    xValue: 39.46125201,
    yValue: 46.97969477,
  },
  {
    xValue: 40.02952235,
    yValue: 47.43654881,
  },
  {
    xValue: 40.54612272,
    yValue: 48.00761561,
  },
  {
    xValue: 41.06272861,
    yValue: 48.57867935,
  },
  {
    xValue: 41.63099343,
    yValue: 49.0355334,
  },
  {
    xValue: 42.30258164,
    yValue: 49.60660019,
  },
  {
    xValue: 42.81918753,
    yValue: 50.06345119,
  },
  {
    xValue: 43.43911128,
    yValue: 50.52030523,
  },
  {
    xValue: 44.05904055,
    yValue: 50.97715776,
  },
  {
    xValue: 44.67896431,
    yValue: 51.43401028,
  },
  {
    xValue: 45.29889358,
    yValue: 51.8908628,
  },
  {
    xValue: 45.91881734,
    yValue: 52.46192807,
  },
  {
    xValue: 46.43542323,
    yValue: 52.80456784,
  },
  {
    xValue: 47.00368805,
    yValue: 53.14720761,
  },
  {
    xValue: 47.57195287,
    yValue: 53.60406013,
  },
  {
    xValue: 48.08855876,
    yValue: 54.06091418,
  },
  {
    xValue: 48.65682359,
    yValue: 54.40355242,
  },
  {
    xValue: 49.22509393,
    yValue: 54.7461922,
  },
  {
    xValue: 49.89667662,
    yValue: 55.20304472,
  },
];

export const PRETERM_GIRLS_LENGTH_COORDS_LINE_3 = [
  {
    xValue: 23.75645892,
    yValue: 29.96193112,
  },
  {
    xValue: 24.27306481,
    yValue: 30.64721066,
  },
  {
    xValue: 24.78966794,
    yValue: 31.33248715,
  },
  {
    xValue: 25.30627383,
    yValue: 32.0177667,
  },
  {
    xValue: 25.87453865,
    yValue: 32.81725899,
  },
  {
    xValue: 26.39114454,
    yValue: 33.50253854,
  },
  {
    xValue: 26.90775043,
    yValue: 34.07360533,
  },
  {
    xValue: 27.32103293,
    yValue: 34.64467213,
  },
  {
    xValue: 27.73431819,
    yValue: 35.32995167,
  },
  {
    xValue: 28.25092408,
    yValue: 35.90101541,
  },
  {
    xValue: 28.8191889,
    yValue: 36.70050771,
  },
  {
    xValue: 29.33579479,
    yValue: 37.38578725,
  },
  {
    xValue: 29.85239792,
    yValue: 38.18527954,
  },
  {
    xValue: 30.36900381,
    yValue: 38.98477184,
  },
  {
    xValue: 30.8856097,
    yValue: 39.67005138,
  },
  {
    xValue: 31.45387452,
    yValue: 40.46954367,
  },
  {
    xValue: 31.97048041,
    yValue: 41.15482322,
  },
  {
    xValue: 32.53874523,
    yValue: 41.95431551,
  },
  {
    xValue: 33.10701006,
    yValue: 42.63959505,
  },
  {
    xValue: 33.72693933,
    yValue: 43.5533001,
  },
  {
    xValue: 34.39852202,
    yValue: 44.35279239,
  },
  {
    xValue: 35.01845129,
    yValue: 45.26649743,
  },
  {
    xValue: 35.58671612,
    yValue: 45.95177697,
  },
  {
    xValue: 36.25830432,
    yValue: 46.75126927,
  },
  {
    xValue: 36.87822808,
    yValue: 47.43654881,
  },
  {
    xValue: 37.4464929,
    yValue: 48.00761561,
  },
  {
    xValue: 38.06642218,
    yValue: 48.57867935,
  },
  {
    xValue: 38.634687,
    yValue: 49.2639589,
  },
  {
    xValue: 39.20295182,
    yValue: 49.72081294,
  },
  {
    xValue: 39.77121664,
    yValue: 50.29187821,
  },
  {
    xValue: 40.44280485,
    yValue: 50.74873073,
  },
  {
    xValue: 41.01106967,
    yValue: 51.20558325,
  },
  {
    xValue: 41.63099343,
    yValue: 51.77665005,
  },
  {
    xValue: 42.2509227,
    yValue: 52.34771532,
  },
  {
    xValue: 42.81918753,
    yValue: 52.80456784,
  },
  {
    xValue: 43.43911128,
    yValue: 53.26142036,
  },
  {
    xValue: 44.11069949,
    yValue: 53.71827441,
  },
  {
    xValue: 44.73062876,
    yValue: 54.17512693,
  },
  {
    xValue: 45.29889358,
    yValue: 54.63197945,
  },
  {
    xValue: 45.91881734,
    yValue: 55.08883197,
  },
  {
    xValue: 46.53874661,
    yValue: 55.54568449,
  },
  {
    xValue: 47.10701144,
    yValue: 55.88832426,
  },
  {
    xValue: 47.67527626,
    yValue: 56.34517678,
  },
  {
    xValue: 48.34686447,
    yValue: 56.8020293,
  },
  {
    xValue: 48.91512929,
    yValue: 57.14466908,
  },
  {
    xValue: 49.48339411,
    yValue: 57.6015216,
  },
  {
    xValue: 50.10331787,
    yValue: 57.94416137,
  },
];

export const PRETERM_GIRLS_LENGTH_COORDS_LINE_4 = [
  {
    xValue: 23.54981767,
    yValue: 31.90355395,
  },
  {
    xValue: 24.0664208,
    yValue: 32.70304624,
  },
  {
    xValue: 24.58302669,
    yValue: 33.50253854,
  },
  {
    xValue: 25.09963257,
    yValue: 34.30203083,
  },
  {
    xValue: 25.71955909,
    yValue: 35.10152312,
  },
  {
    xValue: 26.18450329,
    yValue: 36.01522816,
  },
  {
    xValue: 26.8044298,
    yValue: 36.81472046,
  },
  {
    xValue: 27.32103293,
    yValue: 37.61421275,
  },
  {
    xValue: 27.94095945,
    yValue: 38.64213359,
  },
  {
    xValue: 28.45756533,
    yValue: 39.44162588,
  },
  {
    xValue: 29.07749185,
    yValue: 40.35533092,
  },
  {
    xValue: 29.59409774,
    yValue: 41.15482322,
  },
  {
    xValue: 30.11070087,
    yValue: 41.84010276,
  },
  {
    xValue: 30.62730676,
    yValue: 42.63959505,
  },
  {
    xValue: 31.19557158,
    yValue: 43.3248746,
  },
  {
    xValue: 31.71217747,
    yValue: 44.23857964,
  },
  {
    xValue: 32.28044505,
    yValue: 45.03807193,
  },
  {
    xValue: 32.79705094,
    yValue: 45.72335148,
  },
  {
    xValue: 33.31365131,
    yValue: 46.40863102,
  },
  {
    xValue: 33.88192165,
    yValue: 47.20812331,
  },
  {
    xValue: 34.50184541,
    yValue: 47.89339981,
  },
  {
    xValue: 35.07011023,
    yValue: 48.6928921,
  },
  {
    xValue: 35.6900395,
    yValue: 49.37817164,
  },
  {
    xValue: 36.30996326,
    yValue: 49.94923844,
  },
  {
    xValue: 36.92988701,
    yValue: 50.63451798,
  },
  {
    xValue: 37.54981629,
    yValue: 51.20558325,
  },
  {
    xValue: 38.22140449,
    yValue: 51.8908628,
  },
  {
    xValue: 38.94465164,
    yValue: 52.34771532,
  },
  {
    xValue: 39.56457539,
    yValue: 52.91878211,
  },
  {
    xValue: 40.13284021,
    yValue: 53.37563463,
  },
  {
    xValue: 40.75276949,
    yValue: 53.83248715,
  },
  {
    xValue: 41.47601663,
    yValue: 54.5177667,
  },
  {
    xValue: 42.09594038,
    yValue: 54.97461922,
  },
  {
    xValue: 42.71586414,
    yValue: 55.43147174,
  },
  {
    xValue: 43.33579341,
    yValue: 55.88832426,
  },
  {
    xValue: 44.05904055,
    yValue: 56.45939106,
  },
  {
    xValue: 44.67896431,
    yValue: 56.8020293,
  },
  {
    xValue: 45.29889358,
    yValue: 57.25888335,
  },
  {
    xValue: 45.97047627,
    yValue: 57.71573587,
  },
  {
    xValue: 46.64206448,
    yValue: 58.17258839,
  },
  {
    xValue: 47.26199376,
    yValue: 58.62944091,
  },
  {
    xValue: 47.88191751,
    yValue: 59.08629343,
  },
  {
    xValue: 48.50184678,
    yValue: 59.42893321,
  },
  {
    xValue: 49.07011161,
    yValue: 59.88578573,
  },
];

export const PRETERM_GIRLS_LENGTH_COORDS_LINE_5 = [
  {
    xValue: 23.49815597,
    yValue: 33.04568754,
  },
  {
    xValue: 23.96310017,
    yValue: 33.73096708,
  },
  {
    xValue: 24.37638543,
    yValue: 34.30203083,
  },
  {
    xValue: 24.94465026,
    yValue: 35.21573587,
  },
  {
    xValue: 25.46125614,
    yValue: 36.01522816,
  },
  {
    xValue: 25.97786203,
    yValue: 36.92893625,
  },
  {
    xValue: 26.49446516,
    yValue: 37.72842855,
  },
  {
    xValue: 27.01107105,
    yValue: 38.52792084,
  },
  {
    xValue: 27.57933587,
    yValue: 39.44162588,
  },
  {
    xValue: 28.09594176,
    yValue: 40.24111818,
  },
  {
    xValue: 28.56088596,
    yValue: 41.04061047,
  },
  {
    xValue: 29.07749185,
    yValue: 41.84010276,
  },
  {
    xValue: 29.64575667,
    yValue: 42.63959505,
  },
  {
    xValue: 30.16236256,
    yValue: 43.43908735,
  },
  {
    xValue: 30.67896845,
    yValue: 44.23857964,
  },
  {
    xValue: 31.24723327,
    yValue: 45.15228468,
  },
  {
    xValue: 31.81549809,
    yValue: 45.95177697,
  },
  {
    xValue: 32.33210398,
    yValue: 46.63705652,
  },
  {
    xValue: 32.84870987,
    yValue: 47.43654881,
  },
  {
    xValue: 33.41697469,
    yValue: 48.12182836,
  },
  {
    xValue: 33.93358058,
    yValue: 48.8071079,
  },
  {
    xValue: 34.45018647,
    yValue: 49.49238439,
  },
  {
    xValue: 35.01845129,
    yValue: 50.17766546,
  },
  {
    xValue: 35.63837505,
    yValue: 50.86294348,
  },
  {
    xValue: 36.25830432,
    yValue: 51.43401028,
  },
  {
    xValue: 36.87822808,
    yValue: 52.23350257,
  },
  {
    xValue: 37.49815735,
    yValue: 52.69035509,
  },
  {
    xValue: 38.11808111,
    yValue: 53.26142036,
  },
  {
    xValue: 38.68634593,
    yValue: 53.71827441,
  },
  {
    xValue: 39.30627521,
    yValue: 54.17512693,
  },
  {
    xValue: 39.87454003,
    yValue: 54.63197945,
  },
  {
    xValue: 40.49446378,
    yValue: 55.08883197,
  },
  {
    xValue: 41.16605199,
    yValue: 55.54568449,
  },
  {
    xValue: 41.78597575,
    yValue: 56.00253701,
  },
  {
    xValue: 42.40590502,
    yValue: 56.45939106,
  },
  {
    xValue: 42.97416984,
    yValue: 56.91624358,
  },
  {
    xValue: 43.5940936,
    yValue: 57.25888335,
  },
  {
    xValue: 44.31734074,
    yValue: 57.82994862,
  },
  {
    xValue: 44.98892895,
    yValue: 58.28680114,
  },
  {
    xValue: 45.55719377,
    yValue: 58.62944091,
  },
  {
    xValue: 46.12545859,
    yValue: 59.08629343,
  },
  {
    xValue: 46.69372341,
    yValue: 59.42893321,
  },
  {
    xValue: 47.31365269,
    yValue: 59.88578573,
  },
];

// PRETERM Calculation Function

// let str = `47.31365269	59.88578573
// 46.69372341	59.42893321
// 46.12545859	59.08629343
// 45.55719377	58.62944091
// 44.98892895	58.28680114
// 44.31734074	57.82994862
// 43.5940936	57.25888335
// 42.97416984	56.91624358
// 42.40590502	56.45939106
// 41.78597575	56.00253701
// 41.16605199	55.54568449
// 40.49446378	55.08883197
// 39.87454003	54.63197945
// 39.30627521	54.17512693
// 38.68634593	53.71827441
// 38.11808111	53.26142036
// 37.49815735	52.69035509
// 36.87822808	52.23350257
// 36.25830432	51.43401028
// 35.63837505	50.86294348
// 35.01845129	50.17766546
// 34.45018647	49.49238439
// 33.93358058	48.8071079
// 33.41697469	48.12182836
// 32.84870987	47.43654881
// 32.33210398	46.63705652
// 31.81549809	45.95177697
// 31.24723327	45.15228468
// 30.67896845	44.23857964
// 30.16236256	43.43908735
// 29.64575667	42.63959505
// 29.07749185	41.84010276
// 28.56088596	41.04061047
// 28.09594176	40.24111818
// 27.57933587	39.44162588
// 27.01107105	38.52792084
// 26.49446516	37.72842855
// 25.97786203	36.92893625
// 25.46125614	36.01522816
// 24.94465026	35.21573587
// 24.37638543	34.30203083
// 23.96310017	33.73096708
// 23.49815597	33.04568754`;

// const sepLines = str.split('\n');
// let res = [];
// for (const sLine of sepLines) {
//   const line = sLine.split('\t');
//   res.push({
//     xValue: +line[0],
//     yValue: +line[1],
//   })
// }
// console.log(res.sort((a, b) => a.xValue - b.xValue));
