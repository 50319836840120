import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { validRateUnits } from "../../../orders/form.data";

export function orderConcentrationValidator(
  cancelWeightValidation = false
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const concentration = control.get("concentration")
      ? control.get("concentration").value
      : null;
    const unit = control.get("unit") ? control.get("unit").value : null;
    const bodyWeight = control.get("bodyWeight")
      ? control.get("bodyWeight").value
      : null;
    const frequency = control.get("frequency")
      ? control.get("frequency").value
      : null;

    if (
      (frequency && frequency.fType !== "continuous") ||
      validRateUnits.indexOf(unit) === -1
    ) {
      return null;
    }

    if (!concentration) {
      return { invalidConcentration: { message: "Concentration is required" } };
    }

    const concentrationValue = concentration.value;
    const concentrationUnit = concentration.unit;

    // if conc unit or conc value or unit is not present
    if (!concentrationUnit || !concentrationValue || !unit) {
      return {
        invalidConcentration: {
          message: "Concentration unit and value is required",
        },
      };
    }

    const concentrationUnitArray = concentrationUnit.split("/");
    const unitArray = unit.split("/");

    // if fType is not continuous
    if (frequency && frequency.fType !== "continuous") {
      return {
        invalidConcentration: { message: "Frequency needs to be continuous" },
      };
    }
    // If one of the unit is 'IU' then other should be 'IU' as well
    if (
      (unitArray[0] === "IU" && concentrationUnitArray[0] !== "IU") ||
      (unitArray[0] !== "IU" && concentrationUnitArray[0] === "IU")
    ) {
      return {
        invalidConcentration: {
          message: "Use the similar units to get the rate",
        },
      };
    }

    // If one of the unit is 'mEq' then other should be 'mEq' as well
    if (
      (unitArray[0] === "mEq" && concentrationUnitArray[0] !== "mEq") ||
      (unitArray[0] !== "mEq" && concentrationUnitArray[0] === "mEq")
    ) {
      return {
        invalidConcentration: {
          message: "Use the similar units to get the rate",
        },
      };
    }
    // it unit has weight but weight is not present
    if (
      !cancelWeightValidation &&
      unit &&
      unit.split("/").length === 3 &&
      !bodyWeight
    ) {
      return { invalidWeight: { message: "Weight is required" } };
    }

    // if unit is not of concentration type
    if (validRateUnits.indexOf(unit) === -1) {
      return {
        invalidConcentration: {
          message: "Provided unit cannot be used to calculate rate.",
        },
      };
    }

    return null;
  };
}
