import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fileType, FileTypes } from "src/app/models/file/File.model";
import { FileService } from "src/app/services/file.service";
import { ChatMessageInterface } from "../../models/chat.model";

@Component({
  selector: "cp-reply-message",
  templateUrl: "./reply-message.component.html",
  styleUrls: ["./reply-message.component.scss"],
})
export class ReplyMessageComponent implements OnDestroy, AfterViewInit {
  public fileTypes = FileTypes;
  public unsubscribe$ = new Subject();
  public link: any = "";

  @ViewChild("replyMessage") replyMessage: ElementRef;

  @Input("refMessage") refMessage: ChatMessageInterface | null = null;
  @Input("isClose") isClose: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  constructor(private _fileService: FileService) {}
  ngAfterViewInit(): void {
    this.loadFileOnView();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public get name(): string {
    return this.refMessage?.senderName;
  }

  public get message(): string {
    return this.refMessage?.message;
  }

  public get fileType(): fileType {
    return this._fileService.getFileType(
      this.refMessage?.attachmentLink?.fileType
    );
  }

  loadFileOnView() {
    if (!this.replyMessage?.nativeElement) return;
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.getFile();
          observer.unobserve(entry.target);
        }
      });
    });
    observer.observe(this.replyMessage?.nativeElement);
  }

  public getFile() {
    if (!this?.refMessage.attachmentLink?.key) return;
    this._fileService
      .getFile(this.refMessage.attachmentLink.key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          let fileType = this.refMessage.attachmentLink["fileType"];
          if (fileType == "application/pdf") return;
          if (data.status === "success") {
            this.link = data?.data;
          }
        },
        () => {
          console.error("Something went wrong!");
        }
      );
  }

  public closeReply() {
    this.close.emit();
  }
}
