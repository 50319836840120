import { createAction, props } from "@ngrx/store";
import { PhoneInputInterface } from "@shared-modules/components/phone-input";
import { LoginFormApi } from "src/app/models/auth/Login.model";

export const clickEmailLoginBtn = createAction(
  "[Login Page] Click Email login btn",
  props<LoginFormApi>()
);

export const clickMobileLoginBtn = createAction(
  "[Login Page] Click phone number login btn",
  props<PhoneInputInterface>()
);
