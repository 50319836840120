import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KeyvalueWithoutsortingPipe } from "./keyvalue-withoutsorting.pipe";

@NgModule({
  declarations: [KeyvalueWithoutsortingPipe],
  imports: [CommonModule],
  exports: [KeyvalueWithoutsortingPipe],
  providers: [KeyvalueWithoutsortingPipe],
})
export class KeyValueWithoutSortingModule {}
