import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { RalertApiService } from "../services/ralert-api.service";
import { RAlertApiActions, RAlertListPageActions } from "../actions";
import { displayErrorMessage } from "../../store/actions/root.actions";
import { RalertSocketService } from "../services/ralert-socket.service";

@Injectable()
export class RalertDeviceEffects {
  constructor(
    private _ralertApiService: RalertApiService,
    private _ralertSocketService: RalertSocketService,
    private actions$: Actions
  ) {}

  /**
   * Executes on loadRAlertMessages action.
   * Whenever ralert list page gets load the effect gets called.
   */
  loadRAlertMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertListPageActions.getRalertDevices),
      switchMap(() =>
        this._ralertApiService.getDevices().pipe(
          map((data) =>
            RAlertApiActions.addAllRalertDevices({ devices: data.data })
          ),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  /**
   * Executes on submitNewRalertDeviceForm action.
   */
  submitNewRalertDeviceForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertListPageActions.submitNewRalertDeviceForm),
      switchMap(({ data }) =>
        this._ralertApiService.newDevice(data).pipe(
          map((data) =>
            RAlertApiActions.addRalertDevice({ device: data.data })
          ),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  /**
   * Executes on submitNewRalertDeviceForm action.
   */
  submitEditRalertDeviceForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertListPageActions.submitEditRalertDeviceForm),
      switchMap(({ data, id }) =>
        this._ralertApiService.updateDevice(data, id).pipe(
          map((data) =>
            RAlertApiActions.updateRalertDevice({
              device: { id, changes: data.data },
            })
          ),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  /**
   * Executes on clickDeleteRAlertDevice action.
   */
  clickDeleteRAlertDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RAlertListPageActions.clickDeleteRAlertDevice),
      switchMap(({ id }) =>
        this._ralertApiService.deleteDevice(id).pipe(
          map((data) => RAlertApiActions.deleteRalertDevice({ id })),
          catchError((error: any) =>
            of(displayErrorMessage({ message: "Server Error" }))
          )
        )
      )
    )
  );

  /**
   * Executes on clickDeleteRAlertDevice action.
   */
  getAllRalertDeviceStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RAlertListPageActions.getAllRalertDeviceStatus),
        tap(({ devices }) =>
          this._ralertSocketService.emitAllDeviceStatusMessage(devices)
        )
      ),
    { dispatch: false }
  );
}
