export const bloodTitle = [
  "PRBC",
  "Whole blood",
  "FFP",
  "Cryoprecipitate",
  "Single Donor Platelets",
  "Random Donor Platelets",
];

export const frequency = [
  "once",
  "before",
  "after",
  "every",
  "continuous",
  "SOS",
];

export const diet = [
  "Oral-liquid",
  "Oral-semi solid",
  "Oral-soft",
  "Oral-normal",
];

export const otherDiet = [
  "Tube-NG",
  "Tube-OG",
  "Tube-PEG",
  "Tube-PEJ",
  "Parenteral-TPN",
  "Parenteral-PPN",
  "NPO",
];

export const procedureTypes = [
  "Foley",
  "PICC",
  "CVC",
  "HD",
  "Introducer",
  "Arterial",
  "NG/OG tube",
  "PIV",
  "SCD",
  "Stockings",
  "Other",
];

export const procedureSides = ["Left", "Right", "Bilateral", "NA"];

export const specimenTypes = [
  "blood",
  "stone/calculus",
  "faecal",
  "fluid",
  "urine",
  "semen",
  "sweat",
  "csf",
  "bone marrow",
  "swab",
  "pus",
  "hair",
  "sputum",
  "skin",
  "plasma",
  "NPA",
  "serum",
  "N/A",
];

export const routes = [
  "Epidural",
  "ID",
  "IM",
  "Inhalation",
  "Intradural",
  "Intraocular",
  "Intraspinal",
  "Intratracheal",
  "Intraventricular",
  "IO",
  "IV",
  "Jejunostomy",
  "Nasal Spray",
  "Nebulization",
  "NG",
  "OG",
  "Oral",
  "Patch",
  "PEG",
  "Peritoneal",
  "PO",
  "PR",
  "SC",
  "SL",
  "Topical",
  "Transdermal",
  "Vaginal",
];

export const units = [
  "Billion CFU",
  "Capsules",
  "drops",
  "g",
  "g/kg",
  "IU",
  "IU/hr",
  "IU/kg",
  "IU/kg/hr",
  "IU/kg/min",
  "IU/min",
  "kg",
  "KIT",
  "l",
  "l/min",
  "mcg",
  "mcg/hr",
  "mcg/kg",
  "mcg/kg/hr",
  "mcg/kg/min",
  "mcg/min",
  "mEq",
  "mEq/hr",
  "mEq/kg",
  "mEq/kg/hr",
  "mEq/kg/min",
  "mg",
  "mg/hr",
  "mg/kg",
  "mg/kg/hr",
  "mg/kg/min",
  "mg/min",
  "mg/msq",
  "micrograms",
  "milligrams",
  "Million Units",
  "ml",
  "ml/hr",
  "ml/kg",
  "ml/kg/hr",
  "ml/min",
  "mmol",
  "mmol/kg",
  "mmol/kg/hr",
  "MU",
  "ng",
  "ng/kg",
  "ng/kg/min",
  "ng/kg/hr",
  "ng/kg/day",
  "ng/kg/s",
  "puff",
  "Sachets",
  "spray",
  "Tablet",
  "Vial",
];

export const validContinuousFrequencyUnits = [
  "IU/hr",
  "IU/kg/hr",
  "IU/kg/min",
  "IU/min",
  "l/min",
  "mEq/hr",
  "mEq/hr",
  "mEq/kg/hr",
  "mEq/kg/hr",
  "mEq/kg/min",
  "mEq/kg/min",
  "mcg/hr",
  "mcg/kg/hr",
  "mcg/kg/min",
  "mcg/min",
  "mg/hr",
  "mg/kg/hr",
  "mg/kg/min",
  "mg/min",
  "ml/hr",
  "ml/kg/hr",
  "ml/min",
  "mmol/kg/hr",
  "ng/kg/min",
  "ng/kg/hr",
  "ng/kg/day",
  "ng/kg/s",
];

export const bloodQuantityUnits = ["ml", "ml/kg", "units", "units/kg"];

export const validRateUnits = [
  "IU/hr",
  "IU/kg/hr",
  "IU/kg/min",
  "IU/min",
  "mEq/hr",
  "mEq/hr",
  "mEq/kg/hr",
  "mEq/kg/min",
  "mcg/hr",
  "mcg/kg/hr",
  "mcg/kg/min",
  "mcg/min",
  "mg/hr",
  "mg/kg/hr",
  "mg/kg/min",
  "mg/min",
  "ng/kg/min",
  "ng/kg/hr",
  "ng/kg/day",
  "ng/kg/s",
];

export const schedules = {
  od: [
    { label: "  6 am", value: "06:00" },
    { label: "  8 am", value: "08:00" },
    { label: "  9 am", value: "09:00" },
    { label: "10 am", value: "10:00" },
    { label: "12 pm", value: "12:00" },
    { label: "  2 pm", value: "14:00" },
    { label: "  4 pm", value: "16:00" },
    { label: "  5 pm", value: "17:00" },
    { label: "  8 pm", value: "20:00" },
    { label: "  9 pm", value: "21:00" },
    { label: "10 pm", value: "22:00" },
  ],
  bid: [
    { label: "  6 am |   6 pm", value: "06:00-18:00" },
    { label: "  8 am |   4 pm", value: "08:00-16:00" },
    { label: "  8 am |   8 pm", value: "08:00-20:00" },
    { label: "10 am | 10 pm", value: "10:00-22:00" },
  ],

  tid: [
    { label: "  6 am |   2 pm | 10 pm", value: "06:00-14:00-22:00" },
    { label: "  8 am |   4 pm | 12 am", value: "08:00-16:00-00:00" },
    { label: "10 am |   6 pm |   2 am", value: "10:00-18:00-02:00" },
  ],
  qid: [
    {
      label: "  6 am | 12 pm |  6 pm | 12 am",
      value: "06:00-12:00-18:00-00:00",
    },
    {
      label: "  8 am |   2 pm |  8 pm |   2 am",
      value: "08:00-14:00-20:00-02:00",
    },
  ],
};

export const timePeriodOptions = [
  "Food / Meal",
  "Bedtime",
  "Insulin dose",
  "Hemodialysis",
];
