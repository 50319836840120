import { Injectable } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class PainFormService {
  constructor() {}

  initPainForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      painScore: new UntypedFormControl(null, Validators.required),
      timestamp: new UntypedFormControl(
        new Date(new Date().setSeconds(0, 0)),
        Validators.required
      ),
    });
  }
}
