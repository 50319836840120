import { createAction, props } from "@ngrx/store";
import { GROWTH_CHART_OPTIONS } from "../../constants/growth-trends.data";
import { GrowthTrendsConfig } from "../../models/growth-trends.model";

const SET_INITIAL_GROWTH_GRAPH_AND_DATA =
  "[GROWTH TRENDS] set initial graph and data";
const ADD_NEW_GROWTH_GRAPH = "[GROWTH TRENDS] append a new graph-data";
const UPDATE_MANY_GROWTH_GRAPHs = "[GROWTH TRENDS] update many graph-data";
const REMOVE_GROWTH_GRAPH = "[GROWTH TRENDS] remove a graph-data";
const REPLACE_GROWTH_GRAPH = "[GROWTH TRENDS] replace a new graph-data";
const RESET_GROWTH_GRAPH = "[GROWTH TRENDS] reset growth graph and data";

export const setInitialGraphAndData = createAction(
  SET_INITIAL_GROWTH_GRAPH_AND_DATA,
  props<{ graphConfigs: GrowthTrendsConfig }>()
);

export const addNewGrowthGraph = createAction(
  ADD_NEW_GROWTH_GRAPH,
  props<{ graphConfigs: GrowthTrendsConfig }>()
);

export const updateManyGrowthGraph = createAction(
  UPDATE_MANY_GROWTH_GRAPHs,
  props<{ graphConfigs: GrowthTrendsConfig[] }>()
);

export const removeGrowthGraph = createAction(
  REMOVE_GROWTH_GRAPH,
  props<{ graphName: GROWTH_CHART_OPTIONS }>()
);

export const replaceGrowthGraph = createAction(
  REPLACE_GROWTH_GRAPH,
  props<{ graphConfigs: GrowthTrendsConfig }>()
);

export const resetGrowthGraph = createAction(RESET_GROWTH_GRAPH);
