import { Inject, Injectable, inject } from "@angular/core";
import { Store, select } from "@ngrx/store";
import moment, { Moment } from "moment-timezone";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { TimeZoneDetails } from "../models/hospital";

@Injectable({
  providedIn: "root",
})
export class TimezoneService {
  private patientHospitalTimeZone$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatientHospitalTimeZone)
  );

  public timeZoneDetails: TimeZoneDetails;

  constructor(private _store: Store<any>) {
    this.patientHospitalTimeZone$.subscribe((data) => {
      if (!data) return;
      this.timeZoneDetails = data;
    });
  }

  patientTimeZone() {
    return this.timeZoneDetails?.name || "Asia/Kolkata";
  }

  public tzAbbreviation() {
    return this.timeZoneDetails?.abbreviation || "";
  }

  getCurrentTimeObj = (timeZone: string = this.patientTimeZone()) => {
    return moment().tz(timeZone);
  };

  getCurrentTimestampStr = (timeZone: string = this.patientTimeZone()) => {
    return moment().tz(timeZone).toISOString();
  };

  transformIntoTimezoneObj = (
    value: string | Date | Moment,
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone);
  };

  getPatDatetimeStrTz = (
    value: string | Date = new Date(),
    format = "MMM D y, HH:mm"
  ) => {
    const lstStr = format == "DD/MM/yy, H:mm" ? " hrs" : "";
    return `${
      moment(value).tz(this.patientTimeZone()).format(format) + lstStr
    } (${this.tzAbbreviation()})`;
  };

  endOfDay = (
    value: string | Date | Moment = new Date(),
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone).endOf("day");
  };

  endOfDayMilli = (
    value: string | Date | Moment = new Date(),
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone).endOf("day").valueOf();
  };

  startOfDay = (
    value: string | Date | Moment = new Date(),
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone).startOf("day");
  };

  startOfDayMilli = (
    value: string | Date | Moment = new Date(),
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone).startOf("day").valueOf();
  };

  transformIntoTimezoneStr = (
    value: string | Date,
    timeZone: string = this.patientTimeZone()
  ) => {
    return moment(value).tz(timeZone).toISOString();
  };
}
