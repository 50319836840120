import { createSelector } from "@ngrx/store";
import * as fromNotesState from "../reducers";
import { noteTypeAdaptor } from "../reducers/note-data-types.reducers";

export const getNoteDataType = createSelector(
  fromNotesState.getNoteState,
  (state) => state.noteDataType
);

export const { selectEntities: getNoteEntities } =
  noteTypeAdaptor.getSelectors(getNoteDataType);

export const { selectIds: getAllNoteTypes } =
  noteTypeAdaptor.getSelectors(getNoteDataType);

export const { selectAll: getAllNoteDatas } =
  noteTypeAdaptor.getSelectors(getNoteDataType);

export const selectNoteSubTypesDataByType = (noteType: string) =>
  createSelector(getAllNoteDatas, (notes) =>
    notes.find((note) => note.noteType === noteType)
  );

export const getCTA = createSelector(fromNotesState.getNoteState, (state) => ({
  cta: state.cta,
  timestamp: new Date().getTime(), // Include a timestamp or unique identifier
}));
