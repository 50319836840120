<section class="iris-modal-container cc-status-wrapper">
  <div mat-dialog-title class="title">
    <mat-icon>warning</mat-icon>
    Are you sure you want to replace the note ?
  </div>
  <mat-dialog-content class="content">
    <div class="modal-body">
      <ng-container
        *ngFor="let componentControl of finalNotes?.components; let i = index"
      >
        <ng-container *ngIf="checkifErrortemplate; else normalTemplate">
          <div
            *ngIf="componentControl.displayName === 'Error'"
            class="note-element-description--body ql-editor"
            [innerHtml]="componentControl?.value || '' | sanitizeHtml"
          ></div>
        </ng-container>
        <ng-template #normalTemplate>
          <div
            class="note-element-description--body ql-editor"
            [innerHtml]="componentControl?.value || '' | sanitizeHtml"
          ></div>
        </ng-template>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="button-secondary">
      Cancel
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      class="button-primary"
      [disabled]="checkifErrortemplate"
      (click)="submitUserInfo('yes')"
    >
      Yes, Submit
    </button>
  </mat-dialog-actions>
</section>
