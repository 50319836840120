import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { LabPreset } from "../../../models/preset/LabPreset.model";

@Component({
  selector: "app-protocol-lab-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="lab.patientType"
        [ageGroup]="lab.patientType"
        [id]="lab._id"
      ></age-group-view>
      <div class="">
        <strong>Investigation: </strong> {{ lab.investigation }}
      </div>

      <div class="" *ngIf="lab.discipline">
        <strong>Discipline: </strong> {{ lab.discipline }}
      </div>

      <div class="" *ngIf="lab.specimenType">
        <strong>Specimen Type: </strong> {{ lab.specimenType }}
      </div>

      <div class="" *ngIf="lab.numberOfDoses">
        <strong>Instances: </strong>{{ lab.numberOfDoses }}
      </div>
      <div class="" *ngIf="lab.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{ orderService.getFrequencyDisplayName(lab.frequency) }}</span>
      </div>
      <div class="" *ngIf="lab.urgency">
        <strong>Urgency: </strong>{{ lab.urgency }}
      </div>

      <div class=" details" *ngIf="lab.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ lab.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="lab.instructions">
        <strong>Instructions</strong>:
        <em>{{ lab.instructions }}</em>
      </div>

      <div class=" details" *ngIf="lab.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ lab.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolLabViewComponent {
  @Input() lab: LabPreset;

  constructor(public orderService: OrderService) {}
}
