import { createAction, props } from "@ngrx/store";
import { PatientType } from "src/app/models/patient";

const GET_ALL_QUESTIONS = "[INFECTION QUESTIONS] get all questions";
const SET_ALL_QUESTIONS = "[INFECTION QUESTIONS] set all questions";

export const getAllQuestions = createAction(
  GET_ALL_QUESTIONS,
  props<{ CPMRN: string; encounters: number; pType: PatientType }>()
);

export const setAllQuestions = createAction(
  SET_ALL_QUESTIONS,
  props<{ questionPayload: any }>()
);
