import { Action, createReducer, on, props } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Protocol } from "../../models/protocol/Protocol.model";
import { OrderApiActions, OrderPaneActions } from "../../store/actions/order";
import { ListProtocolPageActions, ProtocolApiActions } from "../actions";

export interface State extends EntityState<Protocol> {
  selectedProtocolId: string | null;
}

export const adapter: EntityAdapter<Protocol> = createEntityAdapter<Protocol>({
  selectId: (protocol: Protocol) => protocol._id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedProtocolId: null,
});

const protocolReducer = createReducer(
  initialState,
  on(
    ListProtocolPageActions.selectProtocol,
    OrderPaneActions.selectProtocol,
    (state, { id }) => ({ ...state, selectedProtocolId: id })
  ),
  on(
    ProtocolApiActions.createProtocolSuccess,
    ProtocolApiActions.getProtocolSuccess,
    (state, { protocol }) => adapter.addOne(protocol, state)
  ),
  on(
    ProtocolApiActions.loadProtocolsSuccess,
    ProtocolApiActions.searchProtocolsSuccess,
    (state, { protocols }) => adapter.setAll(protocols, state)
  ),
  on(ProtocolApiActions.deleteProtocolSuccess, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  on(
    OrderPaneActions.clearSelectedProtocol,
    ProtocolApiActions.placeProtocolSuccess,
    (state) => ({ ...state, selectedProtocolId: null })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return protocolReducer(state, action);
}

export const getSelectedProtocolId = (state: State) => state.selectedProtocolId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectProtocolIds = selectIds;

export const selectProtocolEntities = selectEntities;

export const selectAllProtocols = selectAll;

export const selectProtocolTotal = selectTotal;
