import * as fromCodeSheet from "../actions/codesheet.actions";

export interface CodeSheetState {
  codeSheet: [];
  codeSheetDraft: {};
  loading: boolean;
  loaded: boolean;
}

export const initialState: CodeSheetState = {
  codeSheet: [],
  codeSheetDraft: {},
  loading: false,
  loaded: false,
};

export function reducer(state = initialState, action): CodeSheetState {
  switch (action.type) {
    case fromCodeSheet.CREATE_CODE_SHEET_DRAFT_NOTE_SUCCESS: {
      state.codeSheetDraft = action.payload;
      return { ...state, loading: false, loaded: true };
    }

    case "[Root Actions] patient chart closed": {
      return initialState;
    }
    case fromCodeSheet.CREATE_CODE_SHEET_NOTE: {
      return { ...state, loading: true, loaded: false };
    }
    case fromCodeSheet.CREATE_CODE_SHEET_NOTE_SUCCESS: {
      return { ...state, loading: false, loaded: true };
    }
    case fromCodeSheet.CREATE_CODE_SHEET_NOTE_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
    case fromCodeSheet.LOAD_CODESHEET_NOTE_SUCCESS: {
      let notes = action.payload;
      return { ...state, ...notes };
    }

    case fromCodeSheet.CREATE_CODE_SHEET_DRAFT_NOTE_SUCCESS: {
      state.codeSheetDraft = action.payload;
      return { ...state, loading: false, loaded: true };
    }
    default:
      return { ...state };
  }
  return { ...state };
}

export const getCodeSheetNotes = (state: CodeSheetState) => {
  return state.codeSheet;
};

export const getCodeSheetDraftNotes = (state: CodeSheetState) => {
  return state.codeSheetDraft;
};

export const getCodeSheetloadingState = (state: CodeSheetState) => {
  return state.loading;
};

export const getCodeSheetloadedState = (state: CodeSheetState) => {
  return state.loaded;
};

export const getCodeSheetLoadedState = (state: CodeSheetState) => {
  return state.loaded;
};
