<section class="user_section">
  <table
    class="user_table"
    *ngIf="monitors?.length > 0; else elseMonitorsNotFound"
  >
    <thead>
      <tr>
        <th>Id</th>
        <th>Hospital</th>
        <th>Unit</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let monitor of monitors">
        <td>{{ monitor.deviceId }}</td>
        <td>{{ monitor.hospitalName }}</td>
        <td>{{ monitor.unitName }}</td>
        <td class="text-right">
          <fa-icon
            [icon]="['far', 'user']"
            *ngIf="monitor.patientAssigned"
            matTooltip="Patient Assigned"
          >
          </fa-icon>

          <fa-icon
            [icon]="['fas', 'dot-circle']"
            *ngIf="!monitor.online; else onlineElseIcon"
            class="ml-2 offline-icon"
            matTooltip="Offline"
          ></fa-icon>
          <ng-template #onlineElseIcon>
            <fa-icon
              [icon]="['fas', 'dot-circle']"
              class="ml-2 online-icon"
              matTooltip="Online"
            ></fa-icon>
          </ng-template>

          <fa-icon
            [icon]="['far', 'edit']"
            class="ml-2 edit-icon"
            (click)="onEditClick(monitor)"
            matTooltip="Edit"
          >
          </fa-icon>
          <fa-icon
            [icon]="['far', 'trash-alt']"
            class="ml-2 delete-icon"
            (click)="onDeleteClick(monitor)"
            matTooltip="Delete"
          >
          </fa-icon>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-template #elseMonitorsNotFound>
    <div>Patient Monitors Not Found</div>
  </ng-template>
</section>
