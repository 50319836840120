import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private codeBlueDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private eCallDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() {}

  setCodeBlueData(data: any): void {
    this.codeBlueDataSubject.next(data);
  }

  getCodeBlueData(): Observable<any> {
    return this.codeBlueDataSubject.asObservable();
  }

  setEcallData(data: any): void {
    this.eCallDataSubject.next(data);
  }

  getEcallData(): Observable<any> {
    return this.eCallDataSubject.asObservable();
  }
}
