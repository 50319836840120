import { Action, createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

import { Orderable } from "../../../models/Orderable.model";
import { OrderPaneActions } from "../../actions/order";
import {
  OrderableApiActions,
  OrderableEditPageActions,
  OrderableProtocolPageActions,
} from "../../actions/orderable";
import { ProtocolApiActions } from "src/app/protocol/actions";

export interface State extends EntityState<Orderable> {
  selectedOrderableId: string | null;
  loading: boolean;
}

export const adapter: EntityAdapter<Orderable> = createEntityAdapter<Orderable>(
  {
    selectId: (orderable: Orderable) => orderable._id,
    sortComparer: false,
  }
);

export const initialState: State = adapter.getInitialState({
  selectedOrderableId: null,
  loading: false,
});

const orderableReducer = createReducer(
  initialState,
  on(
    OrderPaneActions.selectOrderable,
    OrderableEditPageActions.selectOrderable,
    OrderableProtocolPageActions.selectOrderable,
    (state, { id }) => ({ ...state, selectedOrderableId: id })
  ),
  on(OrderPaneActions.getOrderables, (state) => {
    return { ...state, loading: true };
  }),
  on(OrderableApiActions.getOrderableSuccess, (state, { orderable }) =>
    adapter.upsertOne(orderable, { ...state, loading: false })
  ),
  on(OrderableApiActions.getOrderableFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(
    OrderPaneActions.getOrderable,
    OrderPaneActions.getProtocol,
    OrderableEditPageActions.getOrderable,
    OrderableProtocolPageActions.getOrderable,
    (state) => ({ ...state, loading: true })
  ),
  on(OrderableApiActions.getOrderablesSuccess, (state, { orderables }) =>
    adapter.setAll(orderables, { ...state, loading: false })
  ),
  on(ProtocolApiActions.getProtocolSuccess, (state) => {
    return { ...state, loading: false };
  }),
  on(OrderableApiActions.updateOrderablesSuccess, (state, { orderables }) =>
    adapter.upsertMany(orderables, state)
  ),
  on(OrderPaneActions.removeOrderables, (state) =>
    adapter.removeAll({ ...state, selectedOrderableId: null })
  ),
  on(
    OrderPaneActions.clearSelectedOrderable,
    OrderableEditPageActions.clearSelectedOrderable,
    OrderableProtocolPageActions.clearSelectedOrderable,
    (state) => ({
      ...state,
      selectedOrderableId: null,
    })
  ),
  on(OrderableEditPageActions.addSearchedOrderable, (state, { orderable }) =>
    adapter.addOne(orderable, state)
  ),
  on(OrderableEditPageActions.resetSearchedOrderable, () => initialState),
  on(OrderableApiActions.updateOrderableSuccess, (state, { orderable }) =>
    adapter.upsertOne(orderable, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return orderableReducer(state, action);
}

export const getSelectedOrderableId = (state: State) =>
  state.selectedOrderableId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectOrderableIds = selectIds;

export const selectOrderableEntities = selectEntities;

export const selectAllOrderables = selectAll;

export const selectOrderableTotal = selectTotal;

export const getOrderablesLoading = (state) => state.loading;
