import { Injectable, inject } from "@angular/core";
import moment from "moment-timezone";
import { TimezoneService } from "./timezone.service";
@Injectable({
  providedIn: "root",
})
export class DateChangeService {
  private _tz = inject(TimezoneService);

  constructor() {}

  /**
   * @description Finds the index of the element inside the array by date.
   * @param array array used to find element index.
   * @param findDate the date need to be find
   * @param key key of the element to be found out
   * @date 17 Aug 2019
   * @returns number
   */
  public findElementByDate(array, findDate: Date, key): number {
    if (!Array.isArray(array)) return -1;
    const findIndex = array.findIndex((element) => {
      return (
        this._tz.transformIntoTimezoneStr(element[key]) ==
        this._tz.transformIntoTimezoneStr(findDate)
      );
    });
    return findIndex;
  }

  /**
   * @description Finds the first element inside an array.
   * @param array Array used to find the element
   * @param findValue The element to be found out.
   * @param key key of the element to be found out
   * @date 17 Aug 2019
   */
  public findElementByKey(array, findValue, key) {
    if (!Array.isArray(array)) return;
    const findElement = array.filter((element) => {
      return element[key] == findValue;
    });
    return findElement[0];
  }

  /**
   * @description Filters the required date for date picker
   * @date 17 Aug 2019
   */
  public filterDate(array, findDate: Date, key): boolean {
    if (!Array.isArray(array) || !findDate) return false;
    const findIndex = array.findIndex((element) => {
      return (
        this._tz.transformIntoTimezoneStr(element[key]) ==
        this._tz.transformIntoTimezoneStr(findDate)
      );
    });
    return findIndex > -1;
  }
}
