import { Action, createReducer, on } from "@ngrx/store";
import { GrowthTrendsReducerInterface } from "../state";
import * as fromGrowthTrendsAction from "../actions/growth-trends.actions";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { GrowthTrendsConfig } from "../../models/growth-trends.model";

export const adapter: EntityAdapter<GrowthTrendsConfig> =
  createEntityAdapter<GrowthTrendsConfig>({
    selectId: selectGrowthDataGraphName,
    sortComparer: sortGrowthDataGraph,
  });

function selectGrowthDataGraphName(a: GrowthTrendsConfig) {
  return a.graphName;
}

function sortGrowthDataGraph(a: GrowthTrendsConfig, b: GrowthTrendsConfig) {
  return b.graphName.localeCompare(a.graphName);
}

const initialState: GrowthTrendsReducerInterface = adapter.getInitialState({});

const growthTrendsReducer = createReducer(
  initialState,
  on(fromGrowthTrendsAction.resetGrowthGraph, (state) =>
    adapter.removeAll(state)
  ),
  on(
    fromGrowthTrendsAction.setInitialGraphAndData,
    (state, { graphConfigs }) => {
      return adapter.upsertOne(graphConfigs, state);
    }
  ),
  on(fromGrowthTrendsAction.addNewGrowthGraph, (state, { graphConfigs }) => {
    return adapter.upsertOne(graphConfigs, state);
  }),
  on(
    fromGrowthTrendsAction.updateManyGrowthGraph,
    (state, { graphConfigs }) => {
      return adapter.upsertMany(graphConfigs, state);
    }
  ),
  on(fromGrowthTrendsAction.removeGrowthGraph, (state, { graphName }) => {
    return adapter.removeOne(graphName, state);
  }),
  on(fromGrowthTrendsAction.replaceGrowthGraph, (state, { graphConfigs }) => {
    return adapter.setAll([graphConfigs], state);
  })
);

export function reducer(state: GrowthTrendsReducerInterface, action: Action) {
  return growthTrendsReducer(state, action);
}
