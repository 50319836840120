import { createAction, props } from "@ngrx/store";
import {
  AuthFamilyOtp,
  AuthOtp,
  AuthResendOtpApi,
  AuthVerifyOtpApi,
} from "../../../models/auth/Otp.model";
import {
  FamilyLoginSuccess,
  LoginSuccessBE,
} from "../../../models/auth/Login.model";

export const showLoginAuthScreen = createAction(
  "[Auth Api] Show Login Auth Screen"
);

export const showOtpAuthScreen = createAction(
  "[Auth Api] Show Otp Auth Screen"
);

export const showForgotPasswordAuthScreen = createAction(
  "[Auth Api] Show Forgot Password Auth Screen"
);

export const saveOtpData = createAction(
  "[Auth Api] Save Otp Data",
  props<{ data: AuthOtp }>()
);

export const verifyOtpFailure = createAction(
  "[Auth Api] Verify OTP Failure",
  props<{ error: string }>()
);

export const verifyOtpSuccess = createAction("[Auth Api] Verify OTP Success");

export const basicAuthSuccess = createAction("[Auth Api] Basic Auth Success");

export const mobileAuthSuccess = createAction(
  "[Auth Api] mobile number Auth Success"
);

export const basicAuthFailure = createAction(
  "[Auth Api] Basic Auth Failure",
  props<{ error: string }>()
);

export const mobileAuthFailure = createAction(
  "[Auth Api] Mobile number Auth Failure",
  props<{ error: string }>()
);

export const login = createAction(
  "[Auth Api] Login",
  props<{ data: LoginSuccessBE }>()
);

export const resendOtpSuccess = createAction(
  "[Auth Api] Resend Otp Success",
  props<{ message: string }>()
);

export const resendOtpFailure = createAction(
  "[Auth Api] Resend Otp Failure",
  props<{ error: string }>()
);

export const forgotPasswordSendOtp = createAction(
  "[Auth Api] Forgot Password Send Otp",
  props<{ email: string }>()
);

export const forgotPasswordSendOtpSuccess = createAction(
  "[Auth Api] Forgot Password Send Otp Success"
);

export const forgotPasswordSendOtpFailure = createAction(
  "[Auth Api] Forgot Password Send Otp Failure",
  props<{ error: string }>()
);

export const forgotPasswordResendOtp = createAction(
  "[Auth Api] Forgot Password Resend Otp",
  props<{ data: AuthResendOtpApi }>()
);

export const forgotPasswordResendOtpSuccess = createAction(
  "[Auth Api] Forgot Password Resend Otp Success",
  props<{ message: string }>()
);

export const forgotPasswordResendOtpFailure = createAction(
  "[Auth Api] Forgot Password Resend Otp Failure",
  props<{ error: string }>()
);

export const forgotPasswordVerifyOtp = createAction(
  "[Auth Api] Forgot Password Verify Otp",
  props<{ data: AuthVerifyOtpApi }>()
);

export const forgotPasswordVerifyOtpSuccess = createAction(
  "[Auth Api] Forgot Password Verify Otp Success"
);

export const forgotPasswordVerifyOtpFailure = createAction(
  "[Auth Api] Forgot Password Verify Otp Failure",
  props<{ error: string }>()
);

export const forgotPassword = createAction(
  "[Auth Api] Forgot Password",
  props<{ email: string; uuid: string; password: string; otp: string }>()
);

export const resetPasswordFailure = createAction(
  "[Auth Api] Reset Password Failure",
  props<{ error: string }>()
);

export const resetPasswordSuccess = createAction(
  "[Auth Api] Reset Password Success"
);

export const generateFamilyOtp = createAction(
  "[Auth Api] Generate family otp",
  props<{ familyId: string }>()
);

export const generateFamilyOtpFailure = createAction(
  "[Auth Api] Generate family otp Failure",
  props<{ error: string }>()
);

export const generateFamilyOtpSuccess = createAction(
  "[Auth Api] Generate family otp Success",
  props<{ data: AuthFamilyOtp }>()
);

export const validateFamilyLoginFailure = createAction(
  "[Auth Api] Validate family login Failure",
  props<{ error: string }>()
);

export const validateFamilyLoginSuccess = createAction(
  "[Auth Api] Validate family login Success",
  props<{ data: FamilyLoginSuccess }>()
);
