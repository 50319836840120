import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { RAlertDevice } from '../../models/RAlert/device.model';
import { RAlertMessage } from '../../models/RAlert/message.model';

export const addAllRalertMessages = createAction(
  '[Ralert Api] Add All Ralert Messages',
  props<{ messages: RAlertMessage[] }>()
);

export const acknowledgeMessage = createAction(
  '[Ralert Api] Acknowledge Ralert Message',
  props<{ id: string }>()
);

export const addAllRalertDevices = createAction(
  '[Ralert Api] Add All Ralert Devices',
  props<{ devices: RAlertDevice[] }>()
);

export const addRalertDevice = createAction(
  '[Ralert Api] Add Ralert Device',
  props<{ device: RAlertDevice }>()
);

export const updateRalertDevice = createAction(
  '[Ralert Api] Update Ralert Device',
  props<{ device: Update<RAlertDevice> }>()
);

export const deleteRalertDevice = createAction(
  '[Ralert Api] Delete Ralert Device',
  props<{ id: string }>()
);
