import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { FallRiskService } from "./fall-risk.service";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { valExists } from "src/app/support-functions/util";
@Component({
  selector: "app-fall-risk",
  templateUrl: "./fall-risk.component.html",
  styleUrls: ["./fall-risk.component.scss"],
})
export class FallRiskComponent implements OnInit {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description fall risk form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  public rFallsForm: UntypedFormGroup =
    this._fallRiskService.initFallRiskForm();

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Variables to store the fall score and related values
   */
  public fallScore: number = 0;
  public fallHistory: number = 0;
  public secDiagnoses: number = 0;
  public ambAssist: number = 0;
  public ivTherapy: number = 0;
  public gait: number = 0;
  public mentalStatus: number = 0;

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }

  @Input() minDate;
  @Input() maxDate;

  // passing from initial-assessment form [ admit form ]
  @Input() admitFormIsParent: boolean = false;

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.rFallsForm.get("timestamp");
  }

  constructor(
    private store: Store<{}>,
    private _fallRiskService: FallRiskService,
    private _alertService: AlertService
  ) {}

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    this.fallScore = vitalData.daysFalls || 0;

    setFormVal(
      this.rFallsForm,
      "fallHistory",
      valExists(vitalData.daysFallsFallHistory)
        ? vitalData.daysFallsFallHistory
        : null
    );

    setFormVal(
      this.rFallsForm,
      "secDiagnoses",
      valExists(vitalData.daysFallsSecDiagnoses)
        ? vitalData.daysFallsSecDiagnoses
        : null
    );

    setFormVal(
      this.rFallsForm,
      "ambAssist",
      valExists(vitalData.daysFallsAmbAssist)
        ? vitalData.daysFallsAmbAssist
        : null
    );

    setFormVal(
      this.rFallsForm,
      "ivTherapy",
      valExists(vitalData.daysFallsivTherapy)
        ? vitalData.daysFallsivTherapy
        : null
    );

    setFormVal(
      this.rFallsForm,
      "gait",
      valExists(vitalData.daysFallsGait) ? vitalData.daysFallsGait : null
    );

    setFormVal(
      this.rFallsForm,
      "mentalStatus",
      valExists(vitalData.daysFallsMentalStatus)
        ? vitalData.daysFallsMentalStatus
        : null
    );

    if (vitalData.timestamp)
      setFormVal(this.rFallsForm, "timestamp", vitalData.timestamp);
  }

  /**
   * @description This calculates the fall score based on the changes
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  onFallInput(): void {
    this.fallScore =
      this.rFallsForm.value.fallHistory +
      this.rFallsForm.value.secDiagnoses +
      this.rFallsForm.value.ambAssist +
      this.rFallsForm.value.ivTherapy +
      this.rFallsForm.value.gait +
      this.rFallsForm.value.mentalStatus;
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset(): void {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.rFallsForm.reset();
    this.rFallsForm.get("timestamp").setValue(null);

    /** Set all values to 0 */
    this.fallScore = 0;
    this.fallHistory = 0;
    this.secDiagnoses = 0;
    this.ambAssist = 0;
    this.ivTherapy = 0;
    this.gait = 0;
    this.mentalStatus = 0;

    this.formState.error = null;
  }

  /**
   * @description To submit the pain score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitFalls(patDataFromForm): void {
    this.store.dispatch(vitalActions.submitForm({ loadType: "fall" }));

    const fallsProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      daysFalls: this.fallScore,
      daysFallsFallHistory: patDataFromForm.fallHistory,
      daysFallsSecDiagnoses: patDataFromForm.secDiagnoses,
      daysFallsAmbAssist: patDataFromForm.ambAssist,
      daysFallsivTherapy: patDataFromForm.ivTherapy,
      daysFallsGait: patDataFromForm.gait,
      daysFallsMentalStatus: patDataFromForm.mentalStatus,
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: fallsProps },
      })
    );
  }

  ngOnInit(): void {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Fall risk updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.rFallsForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
