import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  HostListener,
} from "@angular/core";
import {
  faSearchMinus,
  faSearchPlus,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-img-canvas",
  templateUrl: "./img-canvas.component.html",
  styleUrls: ["./img-canvas.component.scss"],
})
export class ImgCanvasComponent {
  constructor() {}

  public canvas: any;
  public context: any;
  @ViewChild("canvas") canvasRef: ElementRef;
  @Input() public image: HTMLImageElement;
  @Input() public canvasWidth: number;
  @Input() public canvasHeight: number;
  @Input() public imageArray?: Array<any> = [];

  public lastX;
  public lastY;
  public dragStart;
  public dragged;
  public scaleFactor = 1.1;
  public newWidth;
  public newHeight;
  public index = 1;
  newModifiedImageArray = [];

  /* ICONS */
  faSearchPlus = faSearchPlus;
  faSearchMinus = faSearchMinus;
  faAngleRight = faAngleRight;
  faAngleLeft = faAngleLeft;

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialize();
      this.newModifiedImageArray = this.imageArray.filter(
        (x) => x["fileType"] != "pdf"
      );
    }, 0);
  }

  initialize() {
    this.canvas = this.canvasRef.nativeElement;
    this.canvas.width = 1920;
    this.canvas.height = 1080;

    if (this.canvasWidth) {
      this.canvas.width = this.canvasWidth;
    }

    if (this.canvasHeight) {
      this.canvas.height = this.canvasHeight;
    }

    if (this.canvas.getContext) {
      this.context = this.canvas.getContext("2d");
      this.trackTransforms(this.context);
      this.lastX = this.canvas.width / 2;
      this.lastY = this.canvas.height / 2;

      // Formula to check the ratio
      let wrh = this.image.width / this.image.height;
      this.newWidth = this.canvas.width;
      this.newHeight = this.newWidth / wrh;
      if (this.newHeight > this.canvas.height) {
        this.newHeight = this.canvas.height;
        this.newWidth = this.newHeight * wrh;
      }

      let p1 = this.context.transformedPoint(0, 0);
      let p2 = this.context.transformedPoint(
        this.canvas.width,
        this.canvas.height
      );
      this.context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
      this.context.save();
      this.context.setTransform(1, 0, 0, 1, 0, 0);
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.restore();
      this.context.scale(
        Math.abs(this.newWidth / this.image.width),
        Math.abs(this.newHeight / this.image.height)
      );
      this.context.drawImage(this.image, 0, 0);
    }
  }

  trackTransforms(ctx) {
    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    let xform = svg.createSVGMatrix();
    ctx.getTransform = function () {
      return xform;
    };

    let savedTransforms = [];
    let save = ctx.save;
    ctx.save = function () {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(ctx);
    };

    let restore = ctx.restore;
    ctx.restore = function () {
      xform = savedTransforms.pop();
      return restore.call(ctx);
    };

    let scale = ctx.scale;
    ctx.scale = function (sx, sy) {
      xform = xform.scaleNonUniform(sx, sy);
      return scale.call(ctx, sx, sy);
    };

    let rotate = ctx.rotate;
    ctx.rotate = function (radians) {
      xform = xform.rotate((radians * 180) / Math.PI);
      return rotate.call(ctx, radians);
    };

    let translate = ctx.translate;
    ctx.translate = function (dx, dy) {
      xform = xform.translate(dx, dy);
      return translate.call(ctx, dx, dy);
    };

    let transform = ctx.transform;
    ctx.transform = function (a, b, c, d, e, f) {
      let m2 = svg.createSVGMatrix();
      m2.a = a;
      m2.b = b;
      m2.c = c;
      m2.d = d;
      m2.e = e;
      m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };

    let setTransform = ctx.setTransform;
    ctx.setTransform = function (a, b, c, d, e, f) {
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };

    let pt = svg.createSVGPoint();
    ctx.transformedPoint = function (x, y) {
      pt.x = x;
      pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };
  }

  redraw() {
    let p1 = this.context.transformedPoint(0, 0);
    let p2 = this.context.transformedPoint(
      this.canvas.width,
      this.canvas.height
    );
    this.context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    this.context.save();
    this.context.setTransform(1, 0, 0, 1, 0, 0);
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.restore();
    this.context.drawImage(this.image, 0, 0);
  }

  @HostListener("document:mousemove", ["$event"])
  MouseMove(event: MouseEvent) {
    // console.log('mouse move');
    // console.log(event.target['id']);
    if (event.target["id"] === "canvasID") {
      this.lastX = event.offsetX || event.pageX - this.canvas.offsetLeft;
      this.lastY = event.offsetY || event.pageY - this.canvas.offsetTop;
      this.dragged = true;
      if (this.dragStart) {
        let pt = this.context.transformedPoint(this.lastX, this.lastY);
        this.context.translate(
          pt.x - this.dragStart.x,
          pt.y - this.dragStart.y
        );
        this.redraw();
      }
    }
  }

  @HostListener("document:mousedown", ["$event"])
  MouseClickDown(event: MouseEvent) {
    // console.log('down');
    // console.log(event.target['id']);
    // document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect =  "none";
    if (event.target["id"] === "canvasID") {
      this.lastX = event.offsetX || event.pageX - this.canvas.offsetLeft;
      this.lastY = event.offsetY || event.pageY - this.canvas.offsetTop;
      this.dragStart = this.context.transformedPoint(this.lastX, this.lastY);
      this.dragged = false;
    }
  }

  @HostListener("document:mouseup", ["$event"])
  MouseClickUp(event: MouseEvent) {
    // console.log('up');
    // console.log(event.target['id']);
    if (event.target["id"] === "canvasID") {
      this.dragStart = null;
      if (!this.dragged) this.Zoom(event.shiftKey ? -1 : 1);
    }
  }

  @HostListener("document:mousewheel", ["$event"])
  MouseScroll(event: MouseEvent) {
    // console.log('scroll');
    // console.log(event.target['id']);
    if (event.target["id"] === "canvasID") {
      this.handleScroll(event);
    }
  }

  Zoom(clicks) {
    let pt = this.context.transformedPoint(this.lastX, this.lastY);
    this.context.translate(pt.x, pt.y);
    let factor = Math.pow(this.scaleFactor, clicks);
    this.context.scale(factor, factor);
    this.context.translate(-pt.x, -pt.y);
    this.redraw();
  }

  handleScroll(evt) {
    let delta = evt.wheelDelta
      ? evt.wheelDelta / 40
      : evt.detail
      ? -evt.detail
      : 0;
    if (delta) this.Zoom(delta);
    // return evt.preventDefault()?evt.preventDefault():"" && false;
  }

  zoomIn(event) {
    this.Zoom(3);
  }

  zoomOut(event) {
    this.Zoom(-3);
  }

  nextOrPrevImage(val) {
    // if image array contains pdf; ignore it

    const currentIndex = this.newModifiedImageArray
      .map((x) => x.imgData)
      .indexOf(this.image.src);
    const movableIndex = currentIndex + parseInt(val);

    // means file doesn't exist
    if (currentIndex < 0) {
      return;
    }

    if (movableIndex >= this.newModifiedImageArray.length) {
      this.image.src = this.newModifiedImageArray[0]["imgData"];
      this.initialize();
      return;
    }

    if (movableIndex <= -1) {
      this.image.src =
        this.newModifiedImageArray[this.newModifiedImageArray.length - 1][
          "imgData"
        ];
      this.initialize();
      return;
    }

    this.image.src =
      this.newModifiedImageArray[currentIndex + parseInt(val)]["imgData"];
    this.initialize();
  }
}
