<section class="ncp-form-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div formArrayName="entries">
      <div class="grid-container">
        <ng-container
          *ngFor="let entry of form.get('entries')['controls']; let i = index"
          [formGroupName]="i"
        >
          <div class="cp-grid-row mb">
            <div class="cp-grid-row diagnosis-style" ngDefaultControl>
              <span class="diagnosis-text">{{
                entry.get("diagnose").value
              }}</span>
            </div>
            <div [formGroupName]="'fields'">
              <ng-container>
                <div
                  *ngIf="entry.get('fields').controls.assessment"
                  class="cp-grid-row param-row"
                >
                  <div class="col-1-of-2 param-style">
                    <span class="param-text">Assessment</span>
                  </div>

                  <div class="col-1-of-2 input-style">
                    <textarea
                      formControlName="assessment"
                      class="input-text"
                    ></textarea>
                  </div>
                </div>

                <div
                  *ngIf="entry.get('fields').controls.goalsandoutcomes"
                  class="cp-grid-row param-row"
                >
                  <div class="col-1-of-2 param-style">
                    <span class="param-text">Goals and Outcomes</span>
                  </div>

                  <div class="col-1-of-2 input-style">
                    <textarea
                      formControlName="goalsandoutcomes"
                      class="input-text"
                    ></textarea>
                  </div>
                </div>

                <div
                  *ngIf="entry.get('fields').controls.interventions"
                  class="cp-grid-row param-row"
                >
                  <div class="col-1-of-2 param-style">
                    <span class="param-text">Interventions</span>
                  </div>

                  <div class="col-1-of-2 input-style">
                    <textarea
                      formControlName="interventions"
                      class="input-text"
                    ></textarea>
                  </div>
                </div>

                <div
                  *ngIf="entry.get('fields').controls.rationale"
                  class="cp-grid-row param-row"
                >
                  <div class="col-1-of-2 param-style">
                    <span class="param-text">Rationale</span>
                  </div>

                  <div class="col-1-of-2 input-style">
                    <textarea
                      formControlName="rationale"
                      class="input-text"
                    ></textarea>
                  </div>
                </div>

                <div
                  *ngIf="entry.get('fields').controls.evaluation"
                  class="cp-grid-row param-row"
                >
                  <div class="col-1-of-2 param-style">
                    <span class="param-text">Evaluation</span>
                  </div>

                  <div class="col-1-of-2 input-style">
                    <textarea
                      formControlName="evaluation"
                      class="input-text"
                    ></textarea>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <br />
        </ng-container>
      </div>
      <div class="date-time-row">
        <div class="content">
          <form [formGroup]="date">
            <app-date-time-picker
              formControlName="ncpDateAndTime"
              [showTime]="true"
              [showNowButton]="true"
            ></app-date-time-picker>
          </form>

          <div class="b-holder">
            <button
              (click)="clearAll()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Clear all
            </button>
            <button
              [disabled]="isButtonDisabled || !date.valid"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader [buttonLoader]="true" *ngIf="loader"></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
