import { Pipe, PipeTransform } from '@angular/core';
import { Vitals } from '../models/Vital';

@Pipe({
  name: 'uniqueDay',
})
export class UniqueDayPipe implements PipeTransform {
  transform(vitals: Vitals[]): number[] {
    let dayNumbers = [...new Set(vitals.map((vital) => vital.dayNum))];

    return dayNumbers;
  }
}
