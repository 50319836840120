<div
  class="display-flex align-items-center"
  [ngStyle]="{ width: width + 3 + 'rem' }"
  [ngClass]="{ 'bg-error': validate()?.maxError }"
  popoverTrigger="hover"
  cpPopover="{{
    validate()?.maxError ? 'Input cannot be more than ' + max : ''
  }}"
  position="top-center"
>
  <input
    [type]="inputType"
    matInput
    class="cp-edit-input"
    [ngClass]="{ focus: isEdit }"
    [readonly]="!isEdit"
    [formControl]="editInputControl"
    [ngStyle]="{ width: width + 'rem' }"
    (focusout)="onFocusOut()"
    #editInput
  />
  <mat-icon
    [style.visibility]="isEdit || readOnly ? 'hidden' : ''"
    *ngIf="!isEdit"
    svgIcon="cp-edit"
    class="cp-ml-1"
    (click)="isEdit = true; editInput.focus(); inputType = type"
  ></mat-icon>
  <mat-icon
    *ngIf="showTick && isEdit"
    class="primary cursor-pointer cp-ml-2"
    (click)="isEdit = false"
    >done</mat-icon
  >
</div>
