export const tests = new Map([
  // Common for RFT and ABG
  [ 'Na', [ { min: 135, max: 145, sex: null, minAge: null, maxAge: null } ] ],
  [ 'K', [ { min: 3.5, max: 5, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Creatinine', [ { min: 0.8, max: 1.3, sex: null, minAge: null, maxAge: null } ] ],

  // Electrolytes
  [ 'Ca', [ { min: 8.5, max: 10.5, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Phos', [ { min: 2.5, max: 4.5, sex: null, minAge: null, maxAge: null } ] ],

  // RFT
  [ 'Cl', [ { min: 97, max: 106, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Chloride', [ { min: 97, max: 106, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Urea', [ { min: 13, max: 43, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Glucose', [ { min: 70, max: 180, sex: null, minAge: null, maxAge: null } ] ],
  [ 'BUN', [ { min: 7, max: 20, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Uric Acid', [ { min: 4, max: 7.0, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Calcium', [ { min: 8.5, max: 10.5, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Magnesium', [ { min: 1.5, max: 2.5, sex: null, minAge: null, maxAge: null } ] ],

  // ABG
  [ 'Bicarb', [ { min: 22, max: 35, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Lactic', [ { min: null, max: 1.9, sex: null, minAge: null, maxAge: null } ] ],
  [ 'PaCO2', [ { min: 34, max: 46, sex: null, minAge: null, maxAge: null } ] ],
  [ 'pH', [ { min: 7.34, max: 7.46, sex: null, minAge: null, maxAge: null } ] ],
  [ 'PaO2', [ { min: 80, max: null, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Glu', [ { min: 70, max: 180, sex: null, minAge: null, maxAge: null } ] ],

  // CBC
  [ 'Atypical', [ { min: 0, max: 0, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Basophils', [ { min: 0, max: 2, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Eosinophil', [ { min: 1, max: 6, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Hb', [ { min: 12, max: 17, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Lymphocytes', [ { min: 20, max: 40, sex: null, minAge: null, maxAge: null } ] ],
  [ 'MCV', [ { min: 83, max: 101, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Neutrophils', [ { min: 40, max: 80, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Platelet', [ { min: 150000, max: 450000, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Total count', [ { min: 4000, max: 10000, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Monocyte', [ { min: 2, max: 10, sex: null, minAge: null, maxAge: null } ] ],
  [ 'MCH', [ { min: 27, max: 32, sex: null, minAge: null, maxAge: null } ] ],
  [ 'MCHC', [ { min: 31, max: 34.5, sex: null, minAge: null, maxAge: null } ] ],
  [ 'RDW-SD', [ { min: 40, max: 55, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Hematocrit', [ { min: 40, max: 50, sex: null, minAge: null, maxAge: null }, ] ],
  [ 'HCT', [ { min: 40, max: 50, sex: null, minAge: null, maxAge: null }, ] ],

  // LFT
  [ 'Albumin', [ { min: 3.6, max: 5.5, sex: null, minAge: null, maxAge: null } ] ],
  [ 'ALK phos', [ { min: 40, max: 129, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Bili(direct)', [ { min: 0, max: 0.2, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Bili(indirect)', [ { min: 0, max: 0.8, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Bili(Total)', [ { min: 0, max: 1.2, sex: null, minAge: null, maxAge: null } ] ],
  [ 'GGT', [ { min: 0, max: 60, sex: null, minAge: null, maxAge: null } ] ],
  [ 'SGOT(AST)', [ { min: 0, max: 40, sex: null, minAge: null, maxAge: null } ] ],
  [ 'SGPT(ALT)', [ { min: 0, max: 41, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Globulin', [ { min: 2, max: 4, sex: null, minAge: null, maxAge: null } ] ],
  [ 'Total Protein', [ { min: 6.2, max: 8.1, sex: null, minAge: null, maxAge: null } ] ],

  // Coagulation
  [ 'aPTT', [ { min: 23, max: 35, sex: null, minAge: null, maxAge: null } ] ],
  [ 'INR', [ { min: 1.1, max: null, sex: null, minAge: null, maxAge: null } ] ],
  [ 'PT', [ { min: 11, max: 14, sex: null, minAge: null, maxAge: null } ] ],
]);
