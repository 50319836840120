import { Pipe, PipeTransform } from "@angular/core";
import { Vitals } from "src/app/vitals/models/Vital";

@Pipe({
  name: "removeBlankEntries",
})
export class RemoveBlankEntriesPipe implements PipeTransform {
  transform(vitals: Vitals[], reqVitalKeys: string[]): unknown {
    if (!vitals?.length) return;

    let days = vitals.filter((vital) =>
      this.filterEmptyValues(vital, reqVitalKeys)
    );
    return days && days.length ? days : null;
  }

  filterEmptyValues = (vitalObj, requiredKeys) => {
    if (requiredKeys.some((key) => this.checkForNonEmptyValue(vitalObj, key))) {
      return true;
    }
    return false;
  };

  checkForNonEmptyValue = (vitalObj, key) => {
    return (
      key in vitalObj &&
      vitalObj[key] != null &&
      vitalObj[key] !== "" &&
      (typeof vitalObj[key] === "object"
        ? Object.keys(vitalObj[key])?.length
        : vitalObj[key] !== null)
    );
  };
}
