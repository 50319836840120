import { Pipe, PipeTransform } from "@angular/core";
import { ShiftSortParam } from "src/app/models/patient-list";
import { shiftAssignSort } from "../patients-list/patients-list.support";

@Pipe({
  name: "sortShiftAssign",
})
export class SortShiftAssignPipe implements PipeTransform {
  transform(
    value: any,
    sortParam: ShiftSortParam,
    tableWorkspaceName: string
  ): unknown {
    if (
      !sortParam ||
      !sortParam.type ||
      sortParam.direction === "" ||
      !sortParam.workspace ||
      tableWorkspaceName !== sortParam.workspace
    )
      return value;

    return shiftAssignSort(value, sortParam);
  }
}
