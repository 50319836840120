import { Injectable } from "@angular/core";
import { NoteForm } from "@notes/models/notes.model";
import { Observable, of } from "rxjs";
import { FormGroup } from "@angular/forms";
import * as fromNoteStore from "@notes/store";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class AutosaveService {
  constructor(private _noteStore: Store<fromNoteStore.NoteFormState>) {}

  private autoSave(formValues: any): Observable<NoteForm> {
    let draftedNote: NoteForm = {
      refId: formValues.refId,
      noteType: formValues.noteType,
      noteSubType: formValues.noteSubType,
      addendum: formValues.addendum,
      components: formValues.components,
      impactCase: formValues.impactCase,
      chargeable: formValues.chargeable,
      cosign: formValues.cosign,
    };
    return of(draftedNote);
  }

  public handleAutoSave(patientNotesForm: FormGroup): void {
    this.autoSave(patientNotesForm.value)
      .toPromise()
      .then((saveDraftData) => {
        const VALUE = patientNotesForm.value;
        if (VALUE) {
          this._noteStore.dispatch(
            fromNoteStore.submitDraftNote({
              draftNotes: saveDraftData,
            })
          );
        }
      });
  }
}
