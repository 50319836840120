import { INFECTION_BUNDLE_TYPES } from "../models";

export const EMPTRY_INF_CHECKLIST = {
  _id: null,
  comment: null,
  createdBy: null,
  updatedBy: null,
  dayNum: null,
  questions: null,
};

export const INF_DROPDOWN_LIST = [
  {
    label: "VAP",
    value: INFECTION_BUNDLE_TYPES.VAP,
  },
  // {
  //   label: "CAUTI",
  //   value: INFECTION_BUNDLE_TYPES.VAP,
  // },
  // {
  //   label: "CLABSI",
  //   value: INFECTION_BUNDLE_TYPES.VAP,
  // },
  // {
  //   label: "SSIP",
  //   value: INFECTION_BUNDLE_TYPES.VAP,
  // },
];

export enum QUESTION_TYPE {
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  TEXT = "text",
  DATETIME = "dateTime",
  HEADER = "header",
}
