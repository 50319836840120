import * as fromOrder from "./order";
import * as userReducer from "./user/index";
import * as authFormReducer from "./auth/index";
import * as patientReducer from "./patients/index";
import * as hospitalReducer from "./hospitals/index";
import * as orderReducer from "./order/orders.reducer";
import { RouterReducerState, routerReducer } from "@ngrx/router-store";

export interface AppState {
  auth: authFormReducer.AuthState;
  user: userReducer.State;
  patients: patientReducer.patientState;
  hospitals: hospitalReducer.hospitalState;
  order: fromOrder.OrderState;
  router: RouterReducerState;
}

export const reducers = {
  user: userReducer.reducer,
  order: orderReducer.reducer,
  router: routerReducer,
};
