import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "formatDiscontinueReasons" })
export class FormatDiscontinueReasonsPipe implements PipeTransform {
  /**
   * Reformat reasons array to a single string separated by ','.
   * Returns default reason if reasons is empty.
   *
   * @param {string[]} reasons - array of reasons
   * @returns string - returns reasons in a string appended by , or default reason if empty
   */

  private disContinueReasons = [
    "adverse effect",
    "contraindicated",
    "duplicate",
    "doctor review",
    "financial reasons",
    "in error",
    "other",
  ];
  transform(reasons: string[]): string {
    if (reasons && reasons.length > 0) {
      return reasons
        .sort(
          (a, b) =>
            this.disContinueReasons.indexOf(a) -
            this.disContinueReasons.indexOf(b)
        )
        .map((x) => this.capitalizeFirstLetter(x))
        .join(", ");
    }

    return "None of the reasons selected";
  }

  capitalizeFirstLetter(discontinueReason: string) {
    return (
      discontinueReason.charAt(0).toUpperCase() + discontinueReason.slice(1)
    );
  }
}
