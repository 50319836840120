import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-edit-input",
  templateUrl: "./edit-input.component.html",
  styleUrls: ["./edit-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EditInputComponent,
      multi: true,
    },
  ],
})
export class EditInputComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  onFocusOut() {
    if (this.showTick) return;
    this.isEdit = false;
  }
  onChange = (_: any) => {};
  onTouched = () => {};

  public isEdit: boolean = false;

  @Input("width") width: number = 3;
  @Input("readOnly") readOnly: boolean = false;
  @Input("value") value: number = 0;
  @Input("showTick") showTick: boolean = false;
  @Input("max") max: number | null = null;
  @Input("type") type: string | null = "number";
  @Input("isShowEmpty") isShowEmpty: boolean = false;

  public inputType: string = "number";

  public $unsubscribe = new Subject();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "cp-edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/edit.svg"
      )
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.setControl();
    }
    if (changes.max && !this.max) {
      this.editInputControl.clearValidators();
    }
  }
  setControl() {
    if (!this.value || !this.readOnly) return;
    this.setInputValue(this.value);
  }

  @Input("hasError") hasError: boolean = false;

  @Output("input") input = new EventEmitter<string>();

  validate() {
    if (this.max == null) return null;
    if (this.max < parseFloat(this.editInputControl.value))
      return { maxError: true };
  }

  editInputControl = new FormControl(null);

  writeValue(obj: string): void {
    if (this.readOnly && this.value) return;
    this.setInputValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnInit(): void {
    this.initializeValues();
    this.initializeListeners();
  }

  initializeValues() {
    this.inputType = this.type;
  }

  initializeListeners() {
    this.editInputControl.valueChanges
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((val) => {
        this.onChange(val);
        this.input.emit(val);
      });
  }

  public setInputValue(value: number | string | null) {
    if (this.type == "text") {
      this.editInputControl.setValue(value, { emitEvent: false });
      return;
    }
    if (value == null && this.isShowEmpty) {
      this.editInputControl.setValue("-", { emitEvent: false });
      this.inputType = "text";
    } else {
      let setValue = value;
      if (typeof setValue == "string") {
        setValue = parseFloat(setValue);
      }
      if (this.type == "number" && value) {
        setValue = parseFloat(parseFloat(value as string).toFixed(2));
      }
      this.editInputControl.setValue(setValue, { emitEvent: false });
      this.inputType = this.type;
    }
  }
}
