import { Action, createReducer, on } from "@ngrx/store";
import { OrderApiActions, OrderPaneActions } from "../../actions/order";
import {
  OrderableApiActions,
  OrderablePageActions,
} from "../../actions/orderable";

export interface State {
  error: any;
  loading: boolean;
  success: boolean;
}

export const initialState: State = {
  error: [],
  loading: false,
  success: false,
};

export const orderableFormReducer = createReducer(
  initialState,
  on(
    OrderablePageActions.addOrderable,
    OrderablePageActions.updateOrderable,
    OrderablePageActions.deleteOrderable,
    (state) => ({ loading: true, error: [], success: false })
  ),
  on(
    OrderableApiActions.addOrderableSuccess,
    OrderableApiActions.updateOrderableSuccess,
    OrderableApiActions.deleteOrderableSuccess,
    (state) => ({ loading: false, error: [], success: true })
  ),
  on(
    OrderableApiActions.addOrderableFailure,
    OrderableApiActions.updateOrderableFailure,
    OrderableApiActions.deleteOrderableFailure,
    (state, { error }) => ({ loading: false, error, success: false })
  ),
  on(OrderPaneActions.addOrder, OrderPaneActions.updateOrder, (state) => ({
    loading: true,
    error: [],
    success: false,
  })),
  on(
    OrderApiActions.addOrderSuccess,
    OrderApiActions.updateOrderSuccess,
    OrderApiActions.duplicateOrder,
    (state) => ({ loading: false, error: [], success: true })
  ),
  on(
    OrderApiActions.addOrderFailure,
    OrderApiActions.updateOrderFailure,
    (state, { error }) => ({ loading: false, error, success: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return orderableFormReducer(state, action);
}

export const getOrderableFormError = (state: State) => state.error;

export const getOrderableFormLoading = (state: State) => state.loading;

export const getOrderableFormSuccess = (state: State) => state.success;
