<div class="canvas-parent">
  <canvas
    #canvas
    style="max-width: 95vw; max-height: 65vh; display: block"
    id="canvasID"
  ></canvas>

  <div
    class="interactive_buttons px-5 pb-1 pt-3 text-center"
    style="align-self: center"
  >
    <fa-icon
      *ngIf="newModifiedImageArray?.length > 1"
      style="cursor: pointer; margin-right: 1rem"
      [icon]="faAngleLeft"
      (click)="nextOrPrevImage(-1)"
    ></fa-icon>

    <div
      title="Zoom in"
      class="cp-btn cp-hvr-info mr-3"
      style="font-size: 20px"
      (click)="zoomIn($event)"
    >
      <fa-icon [icon]="faSearchPlus" class="medium"></fa-icon>
    </div>
    <div title="Zoom out" class="cp-btn cp-hvr-info" (click)="zoomOut($event)">
      <fa-icon [icon]="faSearchMinus" class="medium"></fa-icon>
    </div>
    <fa-icon
      *ngIf="newModifiedImageArray?.length > 1"
      style="cursor: pointer; margin-left: 1rem"
      [icon]="faAngleRight"
      (click)="nextOrPrevImage(1)"
    ></fa-icon>
  </div>
</div>
