import { createSelector } from "@ngrx/store";
import { getNcpCreateState } from "../reducers/index";

export const getNcpListData = createSelector(getNcpCreateState, (state) => {
  return state.ncpAdd.data;
});

export const getNcpEditListData = createSelector(getNcpCreateState, (state) => {
  return state.ncpEdit.data;
});

export const getNcpIsEDitByAdd = createSelector(getNcpCreateState, (state) => {
  return state.ncpAdd.isEdit;
});
export const getNcpIsEDit = createSelector(getNcpCreateState, (state) => {
  return state.ncpEdit.isEdit;
});

export const getNcpCreateSuccessData = createSelector(
  getNcpCreateState,
  (state) => {
    return state.ncpCreate.data;
  }
);

export const getNcpFetchData = createSelector(getNcpCreateState, (state) => {
  return state.ncpFetch.data?.data;
});

export const getNcpFetchLoader = createSelector(getNcpCreateState, (state) => {
  return state.ncpFetch.loading;
});
