import { EntityState } from "@ngrx/entity";
import { InfectionBundle } from "../../models";

// export interface InfectionBundleViewReducerState {
//   loading: boolean;
// }
export interface InfectionQuestionReducerState {
  questions: any;
}

export interface InfectionBundleReducerState
  extends EntityState<InfectionBundle> {
  loading: boolean;
}

export interface InfectionBundleActionReducerState {
  question: InfectionQuestionReducerState;
  infectionBundle: InfectionBundleReducerState;
}
