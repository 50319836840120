<div class="row pt-5">
    <div class="col-md-5 mx-auto">
        <div class="card">
            <div class="card-header d-flex justify-content-center">
                <h3 class="mb-0">Register New User</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <form [formGroup]="rRegisterForm" (ngSubmit)="registerUser(rRegisterForm.value)">
                            <div class="row">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="title">Title
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="text" formControlName="title">
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="name">Name
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="text" formControlName="name">
                                    <div class="alertOnForm"
                                         *ngIf="!rRegisterForm.controls['name'].valid && rRegisterForm.controls['name'].touched">
                                        {{nameAlert}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="qualification">Qualification
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" id="qualification" type="text" formControlName="qualification">
                                    <div class="alertOnForm" *ngIf="!rRegisterForm.controls['qualification'].valid &&
                   rRegisterForm.controls['qualification'].touched">
                                        Max 50 characters
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="email">Email
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="text" formControlName="email">
                                    <div class="alertOnForm"
                                         *ngIf="!rRegisterForm.controls['email'].valid && rRegisterForm.controls['email'].touched">
                                        {{emailAlert}}
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="password">Password
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="password" formControlName="password">
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="password">Role
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <select class="cp-btn" formControlName="role">
                                        <option *ngFor="let author of authorType" [ngValue]="author"
                                                [disabled]="author === 'Select Author Type'">{{author}}</option>
                                    </select>
                                    <div class="alertOnForm"
                                         *ngIf="!rRegisterForm.controls['password'].valid && rRegisterForm.controls['password'].touched">
                                        <!-- {{roleAlert}} -->
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="code">Phone
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="text" formControlName="phone">
                                    <div class="alertOnForm"
                                         *ngIf="!rRegisterForm.controls['phone'].valid && rRegisterForm.controls['phone'].touched">
                                        Invalid contact number
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label for="code">Registration Code
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <input class="cp-input" type="text" formControlName="code">
                                    <div class="alertOnForm"
                                         *ngIf="!rRegisterForm.controls['code'].valid && rRegisterForm.controls['code'].touched">
                                        Registration code required
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <div class="col-md-5 d-flex justify-content-end">
                                    <label>Hospitals</label>
                                </div>
                                <div class="col-md-7">
                                    <select multiple formControlName="hospitals">
                                        <option selected disabled>Select Hospitals</option>
                                        <option *ngFor="let hospital of hospitals"
                                                [value]="hospital._id">{{ hospital.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div *ngIf="duplicateError" class="row d-flex justify-content-center mt-2 cp-error">
                                {{duplicateError}}
                            </div>
                            <div *ngIf="codeError" class="row d-flex justify-content-center mt-2 cp-error">
                                {{codeError}}
                            </div>
                            <div class="row d-flex justify-content-center mt-3">
                                <input type="submit" class="btn btn-secondary text-white" value="Register"
                                       [disabled]="!rRegisterForm.valid">
                            </div>
                        </form>
                    </div>
                    <div class="col-6">
                        <div style="margin-top: 79px;">

                            <div class="mt-2"
                                 [ngClass]="isPasswordValid('minlength') ? 'text-success' : 'text-danger'">
                                <fa-icon [icon]="isPasswordValid('minlength') ? ['fas', 'check-circle'] : ['fas', 'times-circle'] ">
                                </fa-icon>
                                Must be at least 8 characters
                            </div>

                            <div class="mt-2"
                                 [ngClass]="isPasswordValid('hasNumber') ? 'text-success' : 'text-danger'">
                                <fa-icon [icon]="isPasswordValid('hasNumber') ? ['fas', 'check-circle'] : ['fas', 'times-circle'] ">
                                </fa-icon>
                                Must contain at least 1 number
                            </div>

                            <div class="mt-2"
                                 [ngClass]="isPasswordValid('hasCapitalCase') ? 'text-success' : 'text-danger'">
                                <fa-icon [icon]="isPasswordValid('hasCapitalCase') ? ['fas', 'check-circle'] : ['fas', 'times-circle'] ">
                                </fa-icon>
                                Must contain at least 1 in uppercase
                            </div>

                            <div class="mt-2"
                                 [ngClass]="isPasswordValid('hasSmallCase') ? 'text-success' : 'text-danger'">
                                <fa-icon [icon]="isPasswordValid('hasSmallCase') ? ['fas', 'check-circle'] : ['fas', 'times-circle'] ">
                                </fa-icon>
                                Must contain at least 1 in lowercase
                            </div>

                            <div class="mt-2"
                                 [ngClass]="isPasswordValid('hasSpecialCharacters') ? 'text-success' : 'text-danger'">
                                <fa-icon [icon]="isPasswordValid('hasSpecialCharacters') ? ['fas', 'check-circle'] : ['fas', 'times-circle'] ">
                                </fa-icon>
                                Must contain at least 1 special character
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
