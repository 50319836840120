import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { environment as env } from "../../environments/environment";

interface apiResponse {
  success: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class WikiService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getLandingPages(): Observable<apiResponse> {
    return this.http
      .get<apiResponse>(`${env.apiUrl}wiki/getLandingPages`)
      .pipe(catchError(this.errorHandler));
  }
}
