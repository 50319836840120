import {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
} from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

// Define your custom router state interface if needed
export interface CustomRouterState {
  url: string;
  queryParams: { [key: string]: string };
  params: { [key: string]: string };
}

export class CustomRouterSerializer
  implements RouterStateSerializer<CustomRouterState>
{
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    const { url } = routerState;
    const queryParams = this.collectQueryParams(routerState.root);
    const params = this.collectRouteParams(routerState.root);

    // Return your custom router state object
    return { url, queryParams, params };
  }

  private collectQueryParams(route: ActivatedRouteSnapshot): Params {
    let params: Params = {};
    if (route.firstChild) {
      params = this.collectQueryParams(route.firstChild);
    }
    return { ...route.queryParams, ...params };
  }

  private collectRouteParams(route: ActivatedRouteSnapshot): Params {
    let params: Params = {};
    if (route.firstChild) {
      params = this.collectRouteParams(route.firstChild);
    }
    return { ...route.params, ...params };
  }
}
