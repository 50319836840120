import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { FileProgressBar } from '../../models/File.model';
import { Protocol } from '../../models/protocol/Protocol.model';
import { SignedRequest } from '../../models/s3/SignedRequest.model';
import { ProtocolFileData } from '../../models/protocol/ProtocolFileDate.model';

export enum ProtocolApiActionTypes {
  UpdateProtocol = '[Protocol Api] Update Protocol',
  UpdateProtocolSuccess = '[Protocol Api] Update Protocol Success',
  UpdateProtocolFailure = '[Protocol Api] Update Protocol Failure',
  LoadProtocolsSuccess = '[Protocol Api] Load Protocols Success',
  LoadProtocolsFailure = '[Protocol Api] Load Protocols Failure',
  SearchProtocolsSuccess = '[Protocol Api] Search Protocols Success',
  SearchProtocolsFailure = '[Protocol Api] Search Protocols Failure',
  CreateProtocol = '[Protocol Api] Create Protocol',
  CreateProtocolSuccess = '[Protocol Api] Create Protocol Success',
  CreateProtocolFailure = '[Protocol Api] Create Protocol Failure',
  DeleteProtocolSuccess = '[Protocol Api] Delete Protocol Success',
  DeleteProtocolFailure = '[Protocol Api] Delete Protocol Failure',
  GetProtocolSuccess = '[Protocol Api] Get Protocol Success',
  GetProtocolFailure = '[Protocol Api] Get Protocol Failure',
  PlaceProtocolSuccess = '[Protocol Api] Place Protocol Success',
  PlaceProtocolFailure = '[Protocol Api] Place Protocol Failure',
  DiscontinueProtocolSuccess = '[Protocol Api] Discontinue Protocol Success',
  DiscontinueProtocolFailure = '[Protocol Api] Discontinue Protocol Failure',
  SignProtocolSuccess = '[Protocol Api] Sign Protocol Success',
  SignProtocolFailure = '[Protocol Api] Sign Protocol Failure',
  GetProtocolFileSuccess = '[Protocol Api] Get Protocol File Success',
  GetProtocolFileFailure = '[Protocol Api] Get Protocol File Failure',

  GetSignedUrl = '[Protocol Api] Get Signed Url',
  GetSignedUrlSuccess = '[Protocol Api] Get Signed Url Success',
  GetSignedUrlFailure = '[Protocol Api] Get Signed Url Failure',
  SaveFormData = '[Protocol Api] Save Form Data',
  UploadFile = '[Protocol Api] Upload File',
  UploadFileSuccess = '[Protocol Api] Upload File Success',
  SaveFileProgress = '[Protocol Api] Save File Progress',
  DeleteFileProgress = '[Protocol Api] Delete File Progress'
}

/*
* Protocol Action
* */
export const updateProtocol = createAction(
  ProtocolApiActionTypes.UpdateProtocol,
  props<{ id: string, protocol: Protocol }>()
);

export const updateProtocolSuccess = createAction(
  ProtocolApiActionTypes.UpdateProtocolSuccess,
  props<{ protocol: Update<Protocol> }>()
);

export const updateProtocolFailure = createAction(
  ProtocolApiActionTypes.UpdateProtocolFailure,
  props<{ error: any }>()
);

export const loadProtocolsSuccess = createAction(
  ProtocolApiActionTypes.LoadProtocolsSuccess,
  props<{ protocols: Protocol[] }>()
);

export const loadProtocolsFailure = createAction(
  ProtocolApiActionTypes.LoadProtocolsFailure,
  props<{ error: any }>()
);

export const searchProtocolsSuccess = createAction(
  ProtocolApiActionTypes.SearchProtocolsSuccess,
  props<{ protocols: Protocol[] }>()
);

export const searchProtocolsFailure = createAction(
  ProtocolApiActionTypes.SearchProtocolsFailure,
  props<{ error: any }>()
);

export const saveFormData = createAction(
  ProtocolApiActionTypes.SaveFormData,
  props<{ form: Protocol, file: File | null, category: string, id?: string }>()
);

export const createProtocol = createAction(
  ProtocolApiActionTypes.CreateProtocol,
  props<{ protocol: Protocol }>()
);

export const createProtocolSuccess = createAction(
  ProtocolApiActionTypes.CreateProtocolSuccess,
  props<{ protocol: Protocol }>()
);

export const createProtocolFailure = createAction(
  ProtocolApiActionTypes.CreateProtocolFailure,
  props<{ error: any }>()
);

export const deleteProtocolSuccess = createAction(
  ProtocolApiActionTypes.DeleteProtocolSuccess,
  props<{ id: string }>()
);

export const deleteProtocolsFailure = createAction(
  ProtocolApiActionTypes.DeleteProtocolFailure,
  props<{ error: string }>()
);

export const getProtocolSuccess = createAction(
  ProtocolApiActionTypes.GetProtocolSuccess,
  props<{ protocol: Protocol }>()
);

export const getProtocolFailure = createAction(
  ProtocolApiActionTypes.GetProtocolFailure,
  props<{ error: string }>()
);

export const placeProtocolSuccess = createAction(
  ProtocolApiActionTypes.PlaceProtocolSuccess,
  props<{ message: string }>()
);

export const placeProtocolFailure = createAction(
  ProtocolApiActionTypes.PlaceProtocolFailure,
  props<{ error: any }>()
);

export const discontinueProtocolSuccess = createAction(
  ProtocolApiActionTypes.DiscontinueProtocolSuccess,
  props<{ message: string }>()
);

export const discontinueProtocolFailure = createAction(
  ProtocolApiActionTypes.DiscontinueProtocolFailure,
  props<{ error: any }>()
);

export const signProtocolSuccess = createAction(
  ProtocolApiActionTypes.SignProtocolSuccess,
  props<{ message: string }>()
);

export const signProtocolFailure = createAction(
  ProtocolApiActionTypes.SignProtocolFailure,
  props<{ error: any }>()
);

/*
* File Actions
* */
export const getSignedUrl = createAction(
  ProtocolApiActionTypes.GetSignedUrl
);

export const getSignedUrlSuccess = createAction(
  ProtocolApiActionTypes.GetSignedUrlSuccess,
  props<{ signedRequest: SignedRequest }>()
);

export const getSignedUrlFailure = createAction(
  ProtocolApiActionTypes.GetSignedUrlFailure,
  props<{ error: any }>()
);

export const uploadFile = createAction(
  ProtocolApiActionTypes.UploadFile,
  props<{ url: string, file: File }>()
);

export const uploadFileSuccess = createAction(
  ProtocolApiActionTypes.UploadFileSuccess,
  props<{ data: FileProgressBar }>()
);

export const saveFileProgress = createAction(
  ProtocolApiActionTypes.SaveFileProgress,
  props<{ data: FileProgressBar }>()
);

export const deleteFileProgress = createAction(
  ProtocolApiActionTypes.DeleteFileProgress,
  props<{ id: number }>()
);

export const getProtocolFileSuccess = createAction(
  ProtocolApiActionTypes.GetProtocolFileSuccess,
  props<{ data: ProtocolFileData }>()
);

export const getProtocolFileFailure = createAction(
  ProtocolApiActionTypes.GetProtocolFileFailure,
  props<{ error: string }>()
);
