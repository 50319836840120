import { createAction, props } from "@ngrx/store";
import { LabTrendsConfig } from "../../components/lab-chart/lab-utility/lab-trends.config";

const SET_INITIAL_LAB_TRENDS = "[LAB TRENDS] set initial graph and data";
const ADD_NEW_LAB_TREND_GRAPH = "[LAB TRENDS] append a new graph-data";
const UPDATE_MANY_LAB_TREND_GRAPHs = "[LAB TRENDS] update many graph-data";
const REMOVE_LAB_TREND_GRAPH = "[LAB TRENDS] remove a graph-data";
const RESET_LAB_TREND_GRAPH = "[LAB TRENDS] reset growth graph and data";

export const setInitialGraphAndData = createAction(
  SET_INITIAL_LAB_TRENDS,
  props<{ graphConfigs: LabTrendsConfig }>()
);

export const addNewGrowthGraph = createAction(
  ADD_NEW_LAB_TREND_GRAPH,
  props<{ graphConfigs: LabTrendsConfig }>()
);

export const updateManyGrowthGraph = createAction(
  UPDATE_MANY_LAB_TREND_GRAPHs,
  props<{ graphConfigs: LabTrendsConfig[] }>()
);

export const removeGrowthGraph = createAction(
  REMOVE_LAB_TREND_GRAPH,
  props<{ graphName: string }>()
);

export const resetGraph = createAction(RESET_LAB_TREND_GRAPH);
