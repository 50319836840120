<div class="vital-trends">
  <div class="header">
    <div class="header-tabs">
      <mat-tab-group
        *ngIf="isHeaderToggleVisible"
        class="iris-tab"
        [animationDuration]="0"
        [disablePagination]="true"
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab label="Trends"></mat-tab>
        <mat-tab label="Tabular View"></mat-tab>
      </mat-tab-group>
    </div>
    <div class="close-icon">
      <img matDialogClose="cancel" src="assets/icons/close.svg" />
    </div>
  </div>
  <hr [style.margin-top]="isHeaderToggleVisible ? '0' : '1rem'" />

  <div class="body" [hidden]="!isTrendActive">
    <div class="vital-header">
      <div class="left-vital">
        <div class="left-vital-info">
          <div class="vital-name" [innerHtml]="vitalNames?.show || ''"></div>
          <div
            class="vital-range"
            *ngIf="
              (vitalNormalRange && vitalNormalRange[2]) ||
              vitalNames?.val == 'daysBP'
            "
          >
            <ng-container *ngIf="vitalNames?.val == 'daysBP'; else noBPBlock">
              <span
                class="normal-range-text"
                *ngIf="vitalNormalRange && vitalNormalRange[2]"
                >Normal value:
              </span>
              <span>
                <span class="bp-systolic-box"></span>Systolic<ng-container
                  *ngIf="vitalNormalRange && vitalNormalRange[2]"
                  >:
                  {{ vitalNormalRange[2] }}
                  {{ vitalNames?.unitName }}</ng-container
                >
              </span>
              <br />
              <span
                class="normal-range-text"
                *ngIf="vitalNormalRange && vitalNormalRange[3]"
                >Normal value:
              </span>
              <span>
                <span class="bp-diastolic-box"></span>Diastolic<ng-container
                  *ngIf="vitalNormalRange && vitalNormalRange[3]"
                  >:
                  {{ vitalNormalRange ? vitalNormalRange[3] : "" }}
                  {{ vitalNames?.unitName }}</ng-container
                >
              </span>
            </ng-container>
            <ng-template #noBPBlock>
              <span class="normal-range-text">Normal range: </span>
              <span>
                {{ vitalNormalRange[2] }} {{ vitalNames?.unitName }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="day-dropdown" *ngIf="isShowDayDropdown">
          <mat-select
            [(value)]="selectedDay"
            (selectionChange)="shuffleVitalDay($event)"
          >
            <mat-option *ngFor="let day of days; index as i" [value]="+day">
              {{
                day == todayDayNum ? "Today (Day " + day + ")" : "Day " + day
              }}</mat-option
            >
          </mat-select>
        </div>
        <div class="nice-chart-header" *ngIf="isTrendType(TrendType.niceChart)">
          <div class="field-container">
            <div class="label">Gestation week:</div>
            <div class="value">{{ getPatientGestationAge() }}</div>
          </div>
          <div class="field-container">
            <div class="label">TOB:</div>
            <div class="value">
              {{ currPatient?.dob | timezone: "DD/MM/YYYY, HH:mm" }}
              <cp-timezone-label *ngIf="currPatient?.dob"></cp-timezone-label>
            </div>
          </div>
          <div class="field-container">
            <div class="label">Direct antiglobulin test:</div>
            <div class="value">{{ directAntiGlobulinValue || "-" }}</div>
          </div>
          <div class="field-container">
            <div class="label">Blood group:</div>
            <div class="value">{{ currPatient?.bloodGroup }}</div>
          </div>
          <div class="field-container">
            <div class="label">Mother’s blood group:</div>
            <div class="value">{{ currPatient?.motherInfo?.bloodGroup }}</div>
          </div>
        </div>
      </div>
      <div class="right-vital">
        <div class="recent">Recent</div>
        <div class="recent-value">
          <span class="normal-range-text">Value: </span>
          <span *ngIf="recentVitalData?.value"
            >{{ recentVitalData?.value }}
            <span *ngIf="recentVitalData?.value2"
              >/{{ recentVitalData?.value2 }}</span
            >
            {{ vitalNames["unitName"] }}</span
          >
        </div>
        <div class="recent-value">
          <span class="normal-range-text">Date & time: </span
          >{{ recentVitalData?.date | timezone: "MMM DD, H:mm" }}
          <cp-timezone-label *ngIf="recentVitalData?.date"></cp-timezone-label>
        </div>
      </div>
    </div>
    <div
      class="axis-text y"
      [ngStyle]="
        trendType === TrendType.weight ? { left: '32vh' } : { left: '28vh' }
      "
      *ngIf="yAxisConfig && yAxisConfig[2]"
    >
      {{ yAxisConfig[2] }}
    </div>

    <div class="axis-text x" *ngIf="xAxisConfig && xAxisConfig[2]">
      {{ xAxisConfig[2] }}
    </div>

    <div class="chart-body">
      <div #canvas></div>
    </div>
  </div>

  <!-- Tabular View -->
  <div class="tabular-view" *ngIf="!isTrendActive">
    <div class="table-holder">
      <table mat-table [dataSource]="vitalsToDisplay">
        <!-- age -->
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef>Age(days)</th>
          <td mat-cell *matCellDef="let row">
            {{ row.age }}
          </td>
        </ng-container>

        <!-- weight -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Weight</th>
          <td
            mat-cell
            *matCellDef="let row; let rowIndex = index"
            [ngClass]="{
              'input-row': inputWeightIndex == rowIndex
            }"
            (mouseenter)="showEditWeightIndex = rowIndex"
            (mouseleave)="showEditWeightIndex = null"
          >
            <div
              class="weight-cell-holder"
              *ngIf="inputWeightIndex != rowIndex; else weightInputBlock"
            >
              <div class="value">{{ row.value }} g</div>
              <div class="edit">
                <img
                  src="assets/icons/edit.svg"
                  alt=""
                  (click)="
                    inputWeightIndex = rowIndex;
                    inputWeigthTimestamp = row.date;
                    weightInput.setValue(row.value)
                  "
                  *ngIf="showEditWeightIndex == rowIndex"
                />
              </div>
            </div>
            <ng-template #weightInputBlock>
              <mat-form-field
                [floatLabel]="'always'"
                class="weight-cell-holder"
              >
                <input
                  matInput
                  type="number"
                  [formControl]="weightInput"
                  autocomplete="off"
                  class="iris-input"
                />
              </mat-form-field>
            </ng-template>
          </td>
        </ng-container>

        <!-- date -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date(dd-mm-yyyy)</th>
          <td mat-cell *matCellDef="let row">
            {{ row.date | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <!-- time -->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let row">
            {{ row.date | date: "HH:mm" }}
          </td>
        </ng-container>

        <!-- info -->
        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div
              class="icon-holder"
              popoverTrigger="hover"
              [cpPopover]="popoverItem"
              position="bottom-end"
              (mouseenter)="tooltipStore = row"
              (mouseleave)="tooltipStore = null"
            >
              <mat-icon inline="true">info</mat-icon>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="tabularTableColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: tabularTableColumns"></tr>
      </table>
    </div>

    <div class="tabular-footer" *ngIf="inputWeightIndex != null">
      <mat-dialog-actions class="dialog-actions">
        <button
          mat-flat-button
          class="button-secondary"
          color="primary"
          (click)="closeWeightInput()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="button-primary"
          color="warn"
          [disabled]="weightInput.invalid"
          (click)="updateWeigth()"
        >
          Save Changes
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>

<ng-template #popoverItem>
  <div class="popover-cont">
    <div class="tp-head">Entered by</div>
    <p class="tp-body">
      {{ tooltipStore?.createdBy?.name || "-" }} on
      {{ tooltipStore?.createdBy?.at | date: "HH:mm dd-MM-yyyy" }}
    </p>
    <ng-container *ngIf="tooltipStore?.updatedBy?.name">
      <div class="tp-head">Edited by</div>
      <p class="tp-body">
        {{ tooltipStore?.updatedBy?.name || "-" }} on
        {{ tooltipStore?.updatedBy?.at | date: "HH:mm dd-MM-yyyy" }}
      </p>
    </ng-container>
  </div>
</ng-template>

<button
  mat-button
  [matMenuTriggerFor]="menu"
  class="event-button"
  [ngStyle]="{
    'top.px': createIntensityYCoord,
    'left.px': createIntensityXCoord
  }"
></button>

<mat-menu #menu="matMenu">
  <button
    class="display-flex justify-content-between"
    mat-menu-item
    *ngFor="let intensity of photoTherapyIntensityMap"
    (click)="openConfirmationDialog(intensity.value)"
  >
    {{ intensity.label }}
    <img [src]="intensity.img" />
  </button>
</mat-menu>
