import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  RAlertDevice,
  RAlertDeviceWifi,
} from "../../models/RAlert/device.model";
import { Injectable } from "@angular/core";

@Injectable()
export class RalertDeviceFormService {
  public getDeviceForm(value: RAlertDevice): UntypedFormGroup {
    const deviceId = value && value.deviceId ? value.deviceId : null;
    const comment = value && value.comment ? value.comment : null;
    const hospitalName =
      value && value.hospitalName ? value.hospitalName : null;
    const unitName = value && value.unitName ? value.unitName : null;
    const bedNo = value && value.bedNo ? value.bedNo : null;
    const hospitalLogo =
      value && value.hospitalLogo ? value.hospitalLogo : null;
    let wifi = new UntypedFormArray([]);

    if (value && value.wifi && value.wifi.length > 0) {
      for (const wifiData of value.wifi) {
        wifi.push(this.getDeviceWifiForm(wifiData));
      }
    }

    if (
      !value ||
      (value && !value.wifi) ||
      (value && value.wifi && value.wifi.length === 0)
    ) {
      wifi.push(this.getDeviceWifiForm(null));
    }

    return new UntypedFormGroup({
      deviceId: new UntypedFormControl(deviceId, [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(/^(?!\s*$).+/),
      ]),
      comment: new UntypedFormControl(comment),
      hospitalName: new UntypedFormControl(hospitalName, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      unitName: new UntypedFormControl(unitName, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      bedNo: new UntypedFormControl(bedNo),
      hospitalLogo: new UntypedFormControl(hospitalLogo, [
        Validators.maxLength(200),
      ]),
      wifi,
    });
  }

  public getDeviceWifiForm(data: RAlertDeviceWifi): UntypedFormGroup {
    const ssid = data && data.ssid ? data.ssid : null;
    const psk = data && data.psk ? data.psk : null;
    const advanced = data && data.advanced ? data.advanced : false;
    let dns = new UntypedFormArray([]);
    let ip = new UntypedFormArray([]);
    let subnet = new UntypedFormArray([]);
    let gateway = new UntypedFormArray([]);

    for (let i = 0; i < 4; i++) {
      const dnsData = data && data.dns ? data.dns[i] : null;
      const ipData = data && data.ip ? data.ip[i] : null;
      const subnetData = data && data.subnet ? data.dns[i] : null;
      const gatewayData = data && data.gateway ? data.gateway[i] : null;

      dns.push(new UntypedFormControl(dnsData));
      ip.push(new UntypedFormControl(ipData));
      subnet.push(new UntypedFormControl(subnetData));
      gateway.push(new UntypedFormControl(gatewayData));
    }

    return new UntypedFormGroup({
      ssid: new UntypedFormControl(ssid, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      psk: new UntypedFormControl(psk, [
        Validators.required,
        Validators.maxLength(200),
      ]),
      dns,
      ip,
      subnet,
      gateway,
      advanced: new UntypedFormControl(advanced),
    });
  }
}
