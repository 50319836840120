import * as fromLabs from "../actions/lab-request.actions";

export interface LabRequestState {
  entities: any;
  loaded: boolean;
  loading: boolean;
  message: string;
}

export const initialState: LabRequestState = {
  entities: [],
  loaded: false,
  loading: false,
  message: null,
};

const getIndex = (entities, payload): number | null =>
  entities.findIndex((item) => item._id === payload._id);

export function reducer(state = initialState, action): LabRequestState {
  switch (action.type) {
    case fromLabs.LOAD_LAB_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_REQUEST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_REQUEST_SUCCESS: {
      const entities = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromLabs.UPLOAD_LAB_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.UPLOAD_LAB_REQUEST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromLabs.UPLOAD_LAB_REQUEST_SUCCESS: {
      const entities = action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromLabs.UPDATE_LAB_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.UPDATE_LAB_REQUEST_FAIL: {
      const entities = action.payload.error.message;
      return {
        ...state,
        loading: false,
        loaded: false,
        message: entities,
      };
    }

    case fromLabs.UPDATE_LAB_REQUEST_FAIL_MESSAGE_RESET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        message: null,
      };
    }

    case fromLabs.UPDATE_LAB_REQUEST_SUCCESS: {
      const payload = action.payload.data;
      let newState = state.entities.map((element) =>
        element._id === payload.id
          ? payload.status === "rejected"
            ? { ...element, status: payload.status }
            : delete element.id
          : element
      );
      state.entities = [...newState];
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case "[Root Actions] patient chart closed": {
      return initialState;
    }
  }

  return state;
}
export const getLabRequestEntities = (state: LabRequestState) =>
  state.entities?.sort(
    (a, b) =>
      new Date(b.updatedOn)?.getTime() - new Date(a.updatedOn)?.getTime()
  );
export const getLabRequestLoading = (state: LabRequestState) => state.loading;
export const getLabRequestLoaded = (state: LabRequestState) => state.loaded;
export const getLabRequestErrorMsg = (state: LabRequestState) => state.message;
