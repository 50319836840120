import { I } from "@angular/cdk/keycodes";
import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { from, merge, of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { Alert } from "src/app/iris-components/model/iris-component.model";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { CustomValidators } from "src/app/iris-components/validators/custom-validators";
import { Hospital, Unit } from "src/app/models/hospital";
import { AuditService } from "src/app/services/audit.service";
import { userRoles } from "src/app/shared/accessControl/roles";
import { ManagementService } from "../../services/management.service";
import { UserFormService } from "../../services/user-form.service";
import { ValidatorsService } from "../../services/validators.service";

const availableRoles = [...userRoles];
@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  /**
   * User form
   */
  userForm = new UntypedFormGroup(
    {
      title: new UntypedFormControl(""),
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^(?!\s*$).+/),
      ]),
      qualification: new UntypedFormControl(""),
      hospitals: new UntypedFormControl([]),
      units: new UntypedFormControl([]),
      role: new UntypedFormControl("", Validators.required),
      speciality: new UntypedFormControl(""),
      allowedRoles: new UntypedFormControl(""),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
      ]),
      phone: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(true, Validators.required),
      lockedStatus: new UntypedFormControl(null),
      remarks: new UntypedFormControl(""),
      registration_id: new UntypedFormControl(""),
      password: new UntypedFormControl(
        "",
        Validators.compose([
          // Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true,
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            }
          ),
          Validators.minLength(8),
        ])
      ),
    },
    { validators: CustomValidators.checkIfAdmin }
  );

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public validatorService: ValidatorsService,
    public _managementService: ManagementService,
    private auditorService: AuditService,
    private _alertService: AlertService,
    private _userFormService: UserFormService
  ) {}

  /** Holds the list of available roles
   * @type {array}
   */
  public roles: string[] = availableRoles;

  public specialtyOptions: string[];

  /** To indicate data is being processed
   * @type {boolean} */
  formLoading: boolean = false;

  /** To display the server error
   * @type {string} */
  serverError: string = "";

  /** To hold the list of selected hospitals
   * @type {array}
   */
  selHospitals: Hospital[] = [];

  /** To hold the list of selected units
   * @type {array}
   */
  selUnits: Unit[] = [];

  /** Check if password update is necessary
   * @type {boolean}
   */
  updatePassword: boolean = false;

  /** To set if add or edit user
   * @type {boolean}
   */
  addUserFlag: boolean = true;

  unitLabel: string = "Units";

  ngOnInit(): void {
    if (this.data?.userType === "System Administrator") {
      this.userForm.get("role").setValue("System Administrator");
    }

    if (this.data?.user?._id) {
      this.fillData();

      this.addUserFlag = false;
      this.isAllUnitsSelected = false;
    } else {
      this.updatePassword = true;
    }
    this.fetchSpecialtyList();
    this.formControlListeners();
  }

  fetchSpecialtyList() {
    this._managementService.getSpecialityList().subscribe((res: any) => {
      this.specialtyOptions = res?.data?.specialities?.length
        ? res?.data?.specialities
        : [];
    });
  }

  formControlListeners() {
    const roleValueChange = this.userForm.get("role").valueChanges;
    const allowedRolesValueChane =
      this.userForm.get("allowedRoles").valueChanges;
    merge(roleValueChange, allowedRolesValueChane).subscribe((data) => {
      const allRoles = [
        this.userForm.get("role").value,
        ...this.userForm.get("allowedRoles").value,
      ];
      if (!allRoles.includes("Physician"))
        this.userForm.patchValue({ speciality: null });
    });
  }

  /**
   * To see the status of password update toggle
   * @param event {event}
   */
  passwordToggle(event): void {
    this.updatePassword = event.checked;
  }

  /**
   * Fills the userform if its edit
   */
  fillData(): void {
    let user = this.data.user;
    this.userForm.get("title").setValue(user.title);
    this.userForm.get("name").setValue(user.name);
    this.userForm.get("qualification").setValue(user.qualification);
    this.userForm.get("email").setValue(user.email);
    this.userForm.get("phone").setValue({
      mobileNumber: user.phone,
      countryCode: user.countryCode,
    });
    this.userForm.get("active").setValue(user.active);
    this.userForm.get("role").setValue(user.role);
    this.userForm.get("speciality").setValue(user.speciality);
    this.userForm.get("remarks").setValue(user.remarks);
    this.userForm.get("registration_id").setValue(user.registration_id || "");

    if (user.allowedRoles) {
      this.userForm.get("allowedRoles").setValue(user.allowedRoles);
    }

    if (user.hospitals) {
      let hospIds = user.hospitals.map((hosp) => hosp._id);

      this.userForm.get("hospitals").setValue(hospIds);
      this.setHospital();

      if (user.units) {
        let unitValues = user.units.map(
          (unit) => `${unit.hospitalName}___${unit.name}___${unit._id}`
        );

        this.userForm.get("units").setValue(unitValues);
      }
    }

    if (this.isLocked) {
      this.userForm.get("lockedStatus").setValue("locked");
    }
  }

  /**
   * Does the server call to save the user in the db
   */
  addUser(): void {
    this.formLoading = true;

    const userInfo = this._userFormService.transformUserForm(
      this.userForm.value
    );

    // Send blank allowedRoles if not present
    if (!userInfo.allowedRoles) {
      userInfo.allowedRoles = [];
    }

    if (this.data?.commandCenter?._id) {
      userInfo.commandCenterID = this.data.commandCenter._id;
    }
    if (this.data?.user?._id) {
      userInfo._id = this.data.user._id;
    }

    if (userInfo.units?.length) {
      userInfo.units = userInfo.units
        .filter((unit) => unit !== "selectAll")
        .map((unit) => {
          let val = unit?.split("___");

          return {
            hospitalName: val[0],
            name: val[1],
            _id: val[2],
          };
        });
    }

    // Registering User in aws quicksight
    // AllowedRoles having 'Auditor' role.
    if (userInfo.allowedRoles.includes("Auditor")) {
      this.auditorService
        .registerNewUser(userInfo.email)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (data) => {
            const displayMessage: Alert = {
              type: "Success",
              message: "User successfully registered quicksight",
            };

            this._alertService.showNotification(displayMessage);
          },
          (error) => {
            const displayMessage: Alert = {
              type: "Error",
              message: "Something Went Wrong",
            };

            this._alertService.showNotification(displayMessage);
          }
        );
    }

    this.setTheApi(userInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.dialogRef.close(response.user);
        },
        (err) => {
          console.log(err);
          this.serverError = err.message;
          if (err.message === "Validation Error" && err.data?.length) {
            this.serverError = "";
            err.data.forEach((e, index) => {
              this.serverError += `${e.param}: ${e.msg}`;

              if (index < err.data.length - 1) {
                this.serverError += ", ";
              }
            });
          }
          this.formLoading = false;
        }
      );
  }

  /**
   * To set the api if its create or edit
   * @param userInfo {User}
   * @returns
   */
  setTheApi(userInfo): any {
    if (this.data?.user?._id) {
      return this._managementService.editUser(userInfo);
    } else {
      return this._managementService.addUser(userInfo);
    }
  }

  /**
   * Set the hospital based on the select so that respective units can be filled
   */
  setHospital(): any {
    let hospIds = this.userForm.get("hospitals").value;
    this.selHospitals = [];
    this.selUnits = [];

    this.selHospitals = this.data.hospitals.filter((hosp) =>
      hospIds.includes(hosp._id)
    );

    let isCommandCenter = false;
    this.selHospitals.forEach((hosp) => {
      if (hosp.isCommandCenter) isCommandCenter = true;

      let units = hosp.units.map((unit) => ({
        ...unit,
        hospitalName: hosp.name,
      }));
      this.selUnits = [...this.selUnits, ...units];

      if (!this.data?.user?._id) {
        this.selUnits = this.selUnits.filter((unit) => unit.active);
      } else {
        let userUnits = this.data.user.units;

        this.selUnits = this.selUnits.filter((unit) => {
          let unitIndex = userUnits.findIndex(
            (uUnit) => uUnit._id === unit._id
          );

          if (unitIndex < 0 && unit.active) {
            return true;
          } else if (unitIndex > -1) {
            return true;
          } else {
            return false;
          }
        });
      }
    });

    this.setUnitValidator(isCommandCenter);

    // preselect all the units
    if (!this.data?.user?._id) {
      this.setUnitsValue(true);
    }
  }

  setUnitValidator(isCommandCenter = false) {
    // if user type is System Administrator then we clear the unit's validators
    if (isCommandCenter || this.data?.userType === "System Administrator") {
      this.unitLabel = "Units";
      this.userForm.get("units").clearValidators();
    } else {
      this.unitLabel = "Units*";
      this.userForm.get("units").setValidators(Validators.required);
    }

    this.userForm.get("units").updateValueAndValidity();
  }

  setUnitsValue(setAll: boolean): void {
    if (setAll) {
      let newUnitVal = this.selUnits.map(
        (unit) => unit.hospitalName + "___" + unit.name + "___" + unit._id
      );
      this.units.setValue([...newUnitVal, "selectAll"]);
    } else {
      this.units.setValue([]);
    }
  }

  /**
   * To get if the user is admin or not
   */
  get isAdmin(): boolean {
    if (this.data.userType === "System Administrator") {
      return true;
    } else return false;
  }

  /**
   * To get if the user is locked
   */
  get isLocked(): boolean {
    if (this.data?.user?.lockout?.isLocked) {
      return true;
    }
    return false;
  }

  /**
   * Extract units value from user form
   */
  get units(): AbstractControl {
    return this.userForm.get("units");
  }

  /**
   * Extract password value from user form
   */
  get regPassword(): AbstractControl {
    return this.userForm.get("password");
  }

  isAllUnitsSelected: boolean = true;
  selectAllUnits(): void {
    if (!this.isAllUnitsSelected) {
      this.setUnitsValue(true);
    } else {
      this.setUnitsValue(false);
    }
    this.isAllUnitsSelected = !this.isAllUnitsSelected;
  }

  /** Subject to eliminate the subscription. */
  unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
