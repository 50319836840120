import { createAction, props } from "@ngrx/store";

export const loginSuccess = createAction(
  "[Login] Login Success",
  props<{ user: any }>()
);

export const loginFail = createAction(
  "[Login] Login Fail",
  props<{ error: any }>()
);

export const authError = createAction(
  "[Auth] Auth Error",
  props<{ status: number; message: string }>()
);

export const wikiSetJwt = createAction(
  "[Wiki] set jwt for the wiki user",
  props<{ jwt: string; roles: string[] }>()
);
