<div class="order-detail-comp" [formGroup]="form">
  <div class="instr-cont">
    <label>Instructions</label>
    <textarea matInput formControlName="instructions" rows="2"></textarea>
    <span
      class="error-text"
      *ngIf="
        utilService.hasError(form, 'instructions', 'maxlength') &&
        (instructions.touched || instructions.dirty)
      "
    >
      Max. characters is 5000
    </span>
  </div>
  <div class="addn-info-cont">
    <label>Additional information</label>
    <textarea
      matInput
      formControlName="additionalInformation"
      rows="2"
    ></textarea>
    <span
      class="error-text"
      *ngIf="
        utilService.hasError(form, 'additionalInformation', 'maxlength') &&
        (additionalInformation.touched || additionalInformation.dirty)
      "
    >
      Max. characters is 5000
    </span>
  </div>
</div>
