<div
  class="video-player"
  #videoContainer
  *ngIf="isSupportedVideo; else downloadLink"
  (mousemove)="setPlayerControls()"
  (click)="setPlayerControls()"
  (mouseover)="setPlayerControls()"
  (mouseleave)="hideControls()"
  [ngClass]="{ 'video-player--disable-video': videoOnLoad }"
>
  <button
    mat-icon-button
    aria-label="Play button"
    *ngIf="isShowPlay && !loading"
    class="video-player__play-button"
    (click)="togglePlay()"
  >
    <mat-icon>{{ playButtonIcon }}</mat-icon>
  </button>
  <app-zoom-pan-container
    #zoomContainer
    [ngStyle]="{ 'pointer-events': videoType == 'normal' ? 'initial' : 'none' }"
  >
    <video
      width="100%"
      height="100%"
      #videoPlayer
      (loadeddata)="loading = false; videoOnLoad = false"
      (progress)="buffered()"
      (waiting)="loading = true"
      (seeked)="loading = false"
      (ended)="onVideoEnd()"
      (timeupdate)="updateTime()"
      (dblclick)="fullscreenToggle()"
      (click)="togglePlay()"
      (loadeddata)="initializeDuration()"
    >
      <source [src]="src" [type]="fileType" />
      Your browser does not support the video tag.
    </video>
  </app-zoom-pan-container>
  <mat-spinner
    *ngIf="loading"
    class="video-player__loader"
    [diameter]="60"
  ></mat-spinner>
  <div
    *ngIf="isShowPlay && videoType == 'preview'"
    class="video-player__audio-button-top"
    (click)="toggleMute()"
  >
    <ng-container *ngIf="videoPlayer?.muted">
      <mat-icon>volume_off</mat-icon>
    </ng-container>
    <ng-container *ngIf="!videoPlayer?.muted">
      <mat-icon *ngIf="isVolumeUp(videoPlayer?.volume)">volume_up</mat-icon>
    </ng-container>
  </div>
  <div class="video-player__info" *ngIf="videoType == 'thumbnail'">
    <mat-icon>videocam</mat-icon> &nbsp; {{ duration }}
  </div>
  <div
    class="video-player__info"
    *ngIf="isShowPlay && videoType != 'thumbnail'"
    (mouseover)="overControls = true; clearTimer()"
    (mouseleave)="setPlayerControls(); overControls = false"
  >
    <div>{{ timestamp }}</div>
    <cp-progress-bar
      class="video-player__info__progress"
      [buffered]="bufferedValue"
      [value]="percentValue"
      (change)="valueChange($event)"
    ></cp-progress-bar>
    <div>{{ duration }}</div>
    <ng-container *ngIf="videoType == 'normal'">
      <div class="video-player__info__volume">
        <ng-container *ngIf="videoPlayer?.muted">
          <mat-icon (click)="toggleMute()">volume_off</mat-icon>
        </ng-container>
        <ng-container *ngIf="!videoPlayer?.muted">
          <div (click)="toggleMute()" class="video-player__info__volume__icons">
            <mat-icon *ngIf="videoPlayer?.volume == 0">volume_mute</mat-icon>
            <mat-icon *ngIf="isVolumeDown(videoPlayer?.volume)"
              >volume_down</mat-icon
            >
            <mat-icon *ngIf="isVolumeUp(videoPlayer?.volume)"
              >volume_up</mat-icon
            >
          </div>
        </ng-container>
        <mat-slider
          class="video-player__info__volume__control cp-mat-slider"
          [(ngModel)]="volume"
          (input)="volumeChange($event.value)"
        ></mat-slider>
      </div>
      <div></div>
      <button
        mat-icon-button
        aria-label="Play button"
        (click)="zoomContainer.zoomOutImage(); decreaseZoom()"
      >
        <mat-icon>zoom_out</mat-icon>
      </button>
      <span>{{ zoom }}x</span>
      <button
        mat-icon-button
        aria-label="Play button"
        (click)="zoomContainer.zoomInImage(); increaseZoom()"
      >
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button
        mat-icon-button
        aria-label="Play button"
        (click)="fullscreenToggle()"
      >
        <mat-icon>{{ fullscreenIcon }}</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #downloadLink>
  <div
    class="video-player-not-supported display-flex align-items-center justify-content-center flex-column"
  >
    <span>Sorry file type not supported</span>
    <button
      class="button-primary"
      *ngIf="fileType == 'video/quicktime'"
      (click)="openLink()"
    >
      Download video
    </button>
  </div>
</ng-template>
