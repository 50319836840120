import { createReducer, on } from "@ngrx/store";
import { InfectionQuestionReducerState } from "../state";
import * as fromInfectionQuestionActions from "../actions/infection-questions.actions";
const initialState: InfectionQuestionReducerState = {
  questions: null,
};

const infectionQuestionReducer = createReducer(
  initialState,
  on(
    fromInfectionQuestionActions.setAllQuestions,
    (state, { questionPayload }) => ({
      ...state,
      questions: questionPayload,
    })
  )
);

export function reducer(state: InfectionQuestionReducerState, action) {
  return infectionQuestionReducer(state, action);
}
