import { createReducer, on, Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import * as sbarAlertActions from "src/app/store/actions/sbar-alerts/sbar-alerts.actions";
import * as rootActions from "src/app/store/actions/root.actions";

interface SbarAlert {
  _id: string;
  urgency: string;
  patientName: string;
  issue: string;
  hospitalName: string;
  unitName: string;
  CPMRN: string;
  encounters: string;
  patientAssignmentMsg?: string;
}

export interface State extends EntityState<SbarAlert> {}

export function selectSbarId(a: SbarAlert): string {
  //In this case this would be optional since primary key is _id
  return a._id;
}

export const adapter: EntityAdapter<SbarAlert> = createEntityAdapter<SbarAlert>(
  {
    selectId: selectSbarId,
  }
);

export const initialState: State = adapter.getInitialState();

const sbarAlertReducer = createReducer(
  initialState,
  on(sbarAlertActions.addSbarAlert, (state, { sbar }) => {
    return adapter.upsertOne(sbar, state);
  }),
  on(sbarAlertActions.removeSbarAlert, (state, { sbar }) => {
    return adapter.removeOne(sbar["_id"], state);
  }),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state }))
);

const { selectAll } = adapter.getSelectors();

export const getAllSbarAlerts = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return sbarAlertReducer(state, action);
}
