import { AppState } from "src/app/reducers";

import * as fromUnits from "./unit.reducer";

import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface unitState {
  data: fromUnits.State;
}

export interface State extends AppState {
  units: unitState;
}

export const reducer: ActionReducerMap<unitState, any> = {
  data: fromUnits.reducer,
};

/** SELECTOR setup */
export const getUnitState = createFeatureSelector<unitState>("units");

export const getUnitsData = createSelector(
  getUnitState,
  (state: unitState) => state.data
);

export const getUnits = createSelector(getUnitsData, fromUnits.selectAllUnits);
