import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteService } from "@notes-view/services/note.service";
import { quillConfig } from "@notes/utils/quill.config";
import { Addendum, Cosign } from "@notes/models/notes.model";
import { AugnitoService } from "@notes/services/augnito.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-note-addendum",
  templateUrl: "./note-addendum.component.html",
  styleUrls: ["./note-addendum.component.scss"],
  host: {
    class: "cp-flex-container cp-h-100",
  },
})
export class NoteAddendumComponent implements OnInit {
  @Input() patientNotesForm: FormGroup;
  private unsubscribe$: Subject<any> = new Subject<any>();

  quillConfig = quillConfig;

  inDebounce: NodeJS.Timeout;
  constructor(
    private _noteService: NoteService,
    public augnitoService: AugnitoService
  ) {}

  ngOnInit(): void {
    // this.initSwara();
  }

  initSwara() {
    let latestIndex; // set the cursor index variable
    this.augnitoService.augnitoText$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          const selection =
            this.augnitoService.cursorIndex.event.editor.getSelection(true);
          this.augnitoService.cursorIndex.event.editor.insertText(
            selection.index,
            data
          );
          setTimeout(() => {
            latestIndex =
              this.augnitoService.cursorIndex.event.editor.getSelection(true);
            let form = this.augnitoService.cursorIndex.form;
            this.updateNoteInputFormsWithSwaraData(form);
            this.augnitoService.cursorIndex.event.editor.setSelection(
              latestIndex.index,
              0
            );
          }, 0);
        }
      });
  }
  updateNoteInputFormsWithSwaraData(form) {
    if (form == "addendum")
      this.addendumNote.setValue(this.augnitoService.contentChangeNotes);
  }

  addendumNote = new UntypedFormControl(null);

  get componentsFormArray() {
    return (this.patientNotesForm.get("components") as FormArray).controls;
  }

  get getMergeNotes(): Addendum[] | Cosign[] {
    const finalNotes = this.patientNotesForm.value as FinalNotes;
    return this._noteService.getAddendumAndCosignMerge(finalNotes);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
