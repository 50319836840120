import { Pipe, PipeTransform } from "@angular/core";
import { Hospital } from "src/app/models/hospital";

@Pipe({
  name: "hospFilter",
  pure: false,
})
export class HospFilterPipe implements PipeTransform {
  transform(hospList: Hospital[], ...filterArgs: any): Hospital[] {
    if (!hospList?.length) return [];
    let filterName = filterArgs[0];

    // sorting hospital-units according to selected or not
    hospList = hospList
      ?.slice()
      .sort((a, b) => +b["selected"] - +a["selected"]);
    if (filterName) {
      return hospList?.filter((hosp) =>
        this.getHospitalUnitString(hosp)
          .toLowerCase()
          .includes(filterName.toLowerCase())
      );
    }
    return hospList;
  }

  getHospitalUnitString(hospital) {
    return hospital?.hospName
      ? `${hospital["hospName"]} - ${hospital["unitName"]}`
      : `${hospital["name"]}`;
  }
}
