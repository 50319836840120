import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-socket-disconnect",
  templateUrl: "./socket-disconnect.component.html",
  styleUrls: ["./socket-disconnect.component.scss"],
})
export class SocketDisconnectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  refreshPage(): void {
    location.reload();
  }
}
