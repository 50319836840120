import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { Observable } from "rxjs";
import { filter, switchMap, take, withLatestFrom } from "rxjs/operators";
import * as finalNotesActions from "../actions";
import { Store } from "@ngrx/store";
import * as fromNoteStore from "@notes-view/store";
import * as fromInputNoteStore from "@notes/store";
import { CTAType } from "@notes/models/notes.model";
import { NoteService } from "@notes-view/services/note.service";

@Injectable()
export class LoadDraftNoteEffects {
  constructor(
    private actions$: Actions,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _noteService: NoteService
  ) {}

  @Effect()
  loadDraftNote$ = this.actions$.pipe(
    ofType(finalNotesActions.loadDraftNotes),
    withLatestFrom(this._noteStore.select(fromNoteStore.selectLatestNote)),
    take(1), // Ensure only the first emission is taken
    switchMap(([action, latestNote]) =>
      this._noteService.buttonClickTrigger$.pipe(
        filter((state) => state === true), // only if clicked
        take(1), // Ensure we wait for the button click only once
        switchMap(() => {
          if (latestNote) {
            setTimeout(() => {
              this._noteStore.dispatch(
                fromInputNoteStore.loadNoteContentSuccess({
                  noteContents: latestNote,
                })
              );
              this._noteStore.dispatch(
                fromInputNoteStore.setCTA({ cta: latestNote["noteAction"] })
              );
            }, 0);
          }
          return [];
        })
      )
    )
  );
}
