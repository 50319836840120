import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { CodeSheetService } from "../../services/code-sheet.service";

import * as codeSheetActions from "../actions/codesheet.actions";

@Injectable()
export class CodeSheetEffects {
  constructor(
    private actions$: Actions,
    private _alertService: AlertService,
    private _codeSheetService: CodeSheetService
  ) {}

  createCodeSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(codeSheetActions.CREATE_CODE_SHEET_NOTE),
      map((action: codeSheetActions.CodeSheetAction) => action.payload),
      switchMap(({ CPMRN, encounters, data }) => {
        return this._codeSheetService
          .updateCodesheet(CPMRN, encounters, data)
          .pipe(
            map((code: any) => {
              this._alertService.showNotification({
                type: "Success",
                message: "Codesheet submitted successfully!",
              });
              return new codeSheetActions.CreateCodeSheetSucess(code);
            }),
            catchError((error) => {
              this._alertService.showNotification({
                type: "Error",
                message: "Server error!",
              });
              return of(new codeSheetActions.CreateCodeSheetFail(error));
            })
          );
      })
    )
  );

  deleteCodeSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(codeSheetActions.DELETE_CODE_SHEET_NOTE),
      map((action: codeSheetActions.CodeSheetAction) => action.payload),
      switchMap((payload) => {
        return this._codeSheetService.deleteCodesheet(payload).pipe(
          map((code: any) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Codesheet deleted successfully!",
            });
            return new codeSheetActions.CreateCodeSheetSucess(code);
          }),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Server error!",
            });
            return of(new codeSheetActions.CreateCodeSheetFail(error));
          })
        );
      })
    )
  );

  draftCodeSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(codeSheetActions.CREATE_CODE_SHEET_DRAFT_NOTE),
      map((action: codeSheetActions.CodeSheetAction) => action.payload),
      switchMap(({ CPMRN, encounters, codeSheet }) => {
        return this._codeSheetService
          .saveCodesheetDraft(CPMRN, encounters, codeSheet)
          .pipe(
            map(
              (code: any) =>
                new codeSheetActions.CreateCodeSheetDraftSuccess(code.data)
            ),
            catchError((error) =>
              of(new codeSheetActions.CreateCodeSheetDraftFail(error))
            )
          );
      })
    )
  );
}
