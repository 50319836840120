import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Store, select } from "@ngrx/store";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromNcpSelector from "../nursing-care-plan/store/selector/ncp.selector";
import * as fromNcpAction from "../nursing-care-plan/store/action/ncp.action";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  ASSESSMENT_TABS,
  ASSESSMENT_TABS_PAYLOD,
  DEFAULT_ASSESSMENT_ACTIVE_TAB,
} from "src/app/vitals/constants";
import { ToggleDataInterface } from "src/app/iris-components/toggle-header/model";
import * as fromInfectionReducer from "src/app/vitals/assessment/infection-bundle/store";
import { INF_DROPDOWN_LIST } from "src/app/vitals/assessment/infection-bundle/constants";
import { socket } from "../../config/socket";

@Component({
  selector: "app-assessment-ncp-tab",
  templateUrl: "./assessment-ncp-tab.component.html",
  styleUrls: ["./assessment-ncp-tab.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AssessmentNcpTabComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();
  public currentPatient;

  infectionDropDownList = INF_DROPDOWN_LIST;

  get ncpFetchData$() {
    return this.store.pipe(select(fromNcpSelector.getNcpFetchData));
  }

  get patientHeader$() {
    return this.store.pipe(select(fromPatientHeaderReducers.getPatHeaderData));
  }
  constructor(
    private store: Store<any>,
    private _infStore: Store<fromInfectionReducer.InfectionBundleActionReducerState>
  ) {}

  activeInfBundle;
  assessmentTabs = ASSESSMENT_TABS;
  toggleHeaderPayload: ToggleDataInterface[] = ASSESSMENT_TABS_PAYLOD;
  activeTab: string = DEFAULT_ASSESSMENT_ACTIVE_TAB;
  isLoadDataAndSocketSetForInfBundle: boolean = false;

  ngOnInit(): void {
    this.patientHeader$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!data?.CPMRN) return;

      this.currentPatient = data;

      if (!this.isLoadDataAndSocketSetForInfBundle)
        this.loadInfDataAndSetSocketSockets();
    });
  }

  setActiveTab(event) {
    this.activeTab = event;

    if (this.activeTab === ASSESSMENT_TABS.NCP)
      this.store.dispatch(
        fromNcpAction.fetchNcpData({
          cpmrn: this.currentPatient.CPMRN,
          encounter: this.currentPatient.encounters,
        })
      );
  }

  loadInfDataAndSetSocketSockets() {
    this.isLoadDataAndSocketSetForInfBundle = true;
    // FETCHING INFECTION BUNDLE DATA
    this._infStore.dispatch(
      fromInfectionReducer.getAllQuestions({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        pType: this.currentPatient?.patientType,
      })
    );

    this._infStore.dispatch(
      fromInfectionReducer.getAllInfectionBundlesAction({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
      })
    );

    socket.on(
      this.currentPatient?.CPMRN + this.currentPatient?.encounters + "ifb",
      (data) => {
        this._infStore.dispatch(
          fromInfectionReducer.upsertInfectionBundle({ checklist: data })
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    socket.removeAllListeners();
    this.isLoadDataAndSocketSetForInfBundle = false;
  }
}
