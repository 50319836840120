import { createReducer, on, Action } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';

import * as chatActions from 'src/app/patient/chat/store/actions/chat.actions';
import * as rootActions from 'src/app/store/actions/root.actions';

interface ChatMessage {
  _id: string;
  message: string;
  attachmentLink: {
    name: string;
    key: string;
    size: number;
    fileType: string;
  };
  timestamp: Date;
  senderName: string;
  senderRole: string;
  senderEmail: string;
  device: string;
  priority: Number;
  isDeleted: Boolean;
  image: string;
  imageLoaded: Boolean;
}

export interface State extends EntityState<ChatMessage> {}

export function selectChatId(a: ChatMessage): string {
  //In this case this would be optional since primary key is _id
  return `${new Date(a.timestamp).getTime()}`;
}

export function sortByTimestamp(a: ChatMessage, b: ChatMessage): number {
  return `${new Date(a.timestamp).getTime()}`.localeCompare(
    `${new Date(b.timestamp).getTime()}`
  );
}

export const adapter: EntityAdapter<ChatMessage> =
  createEntityAdapter<ChatMessage>({
    selectId: selectChatId,
    sortComparer: sortByTimestamp,
  });

export const initialState: State = adapter.getInitialState();

const chatReducer = createReducer(
  initialState,
  on(chatActions.setChat, (state, { messages }) => {
    return adapter.setAll(messages, state);
  }),
  on(chatActions.addChat, (state, { message }) => {
    return adapter.upsertOne(message, state);
  }),
  on(chatActions.addMultiChat, (state, { messages }) => {
    return adapter.addMany(messages, state);
  }),
  on(chatActions.updateChat, (state, { message }) => {
    const update = {
      id: `${new Date(message.timestamp).getTime()}`,
      changes: message,
    };

    return adapter.updateOne(update, state);
  }),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state })),
  on(
    rootActions.patientChartReset,
    chatActions.resetChat,
    (state) => initialState
  )
);

// Get selectors
const { selectAll } = adapter.getSelectors();

// select the array of messages
export const selectAllMessages = selectAll;

// Export the reducer
export function reducer(state: State | undefined, action: Action) {
  return chatReducer(state, action);
}
