import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Notification } from 'src/app/models/notifications/notifications.model';
import { Store, select } from "@ngrx/store";
import * as fromNotification from 'src/app/store/reducers/notifications';
import { tap, filter, take, switchMap, catchError } from "rxjs/operators";
import * as notificationActions from 'src/app/store/actions/notifications/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationResolver implements Resolve<Notification[]> {

    constructor(
        private store: Store<{}>,
    ) { }

    getFromStore(): Observable<any> {
        return this.store
            .pipe(
                select(fromNotification.getNotificationArray),
                tap((data: any) => {
                    if (!data.length) {
                        this.store.dispatch(notificationActions.getNotification());
                    }
                }),
                take(1))
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<Notification[]> | Promise<Notification[]> | Notification[] {
        return this.getFromStore();
    }
}