import { Component, OnInit, inject } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject, combineLatest, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";

import * as vitalReducers from "../store/reducers";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromUserReducer from "src/app/store/reducers/user";
import { User } from "src/app/models/user";
import { Patient } from "src/app/models/patient";
import { PatientService } from "src/app/services/patient.service";
import { Vitals } from "../models/Vital";
import { UniqueDayPipe } from "../pipes/unique-day.pipe";
import { setClickedDay, getVitals } from "../store/actions/vitals.actions";
import { ActivatedRoute } from "@angular/router";
import { TimezoneService } from "src/app/services/timezone.service";

@Component({
  selector: "app-vitals",
  templateUrl: "./vitals.component.html",
  styleUrls: ["./vitals.component.scss"],
  providers: [UniqueDayPipe],
})
export class VitalsComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();
  public vitalsData$ = this.store.pipe(
    select(vitalReducers.getVitaldays),
    takeUntil(this.unsubscribe$)
  );
  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );
  /**
   * @description To observe the clicked day
   * @type {Observable}
   */
  public vitalClickedDay$ = this.store.pipe(
    select(vitalReducers.getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  selectedDate: Date;
  collapseEmptyRows = false;
  hideVitalInput: boolean = false;

  private _tz = inject(TimezoneService);

  constructor(
    private _patientService: PatientService,
    private store: Store<any>,
    private _uniqueDayPipe: UniqueDayPipe, // private datechange: DateChangeService,
    private _route: ActivatedRoute
  ) {}

  public currUser: User;
  public currPatient: Patient;
  public isDischargeTimeElapsed: boolean = false;
  public days: number[] = [];

  /**
   * @description To store the vitals
   * @type {Array}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  vitals: Vitals[] = [];

  ngOnInit(): void {
    this.users$.subscribe((data) => {
      this.currUser = data.currentUser;
    });

    this._route.data.subscribe((data) => {
      this.hideVitalInput = data.hideVitalInput || false;
    });

    // get patient
    this.patientHeader$.subscribe((patient) => {
      this.currPatient = patient;
      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(this.currPatient);
    });

    // get vitals and the clicked day
    combineLatest([this.vitalsData$, this.vitalClickedDay$]).subscribe(
      ([vitalsData, clickedDay]) => {
        this.vitals = vitalsData;

        if (this.vitals?.length) {
          if (!this.selectedDay) {
            this.selectedDay = this.vitals[0].dayNum;
            this.selectedDate = this.vitals[0].timestamp;
          }
          this.days = this._uniqueDayPipe.transform(this.vitals);

          if (clickedDay) {
            this.selectedDay = clickedDay.dayNum;
            this.selectedDate = clickedDay.timestamp;
          }
        }
      }
    );

    this.callGetVitalApiWithTimer();
  }

  /**
   * @description - set timer to call get vital api every 10 mins
   * @author Rajat Saini
   * @date March 28, 2024
   */
  callGetVitalApiWithTimer() {
    const now = this._tz.getCurrentTimeObj();
    const startDelay = 10 - (now.minute() % 10);
    timer(startDelay * 60000, 600000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(
          getVitals({
            CPMRN: this.currPatient?.CPMRN,
            encounters: this.currPatient?.encounters,
          })
        );
      });
  }
  /**
   * @description To store the current selected day
   * @type {number}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  selectedDay: number = 0;

  getSelectedDate(event): void {
    this.selectedDay = event;
  }

  getCollapseStatus(event: boolean) {
    this.collapseEmptyRows = event;
  }

  ngOnDestroy() {
    this.store.dispatch(setClickedDay({ day: null, activeAssessment: null }));
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
