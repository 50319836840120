import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Addendum, Component, Cosign } from "@notes/models/notes.model";
import { PatternValidator } from "src/app/services/pattern-validation/pattern-validators";
import { BehaviorSubject } from "rxjs";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteService } from "@notes/services/note.service";
import { ImpactNotes } from "@notes/utils/permission.data";
import { RadarGlobalConfig } from "src/app/shared/global.variables";

@Injectable({
  providedIn: "root",
})
export class NoteFormsService {
  readonly defaultNoteType = "Select Note Type";

  private popUpCloseTriggerSource = new BehaviorSubject<boolean>(false);
  public popUpCloseTrigger$ = this.popUpCloseTriggerSource.asObservable();

  constructor(
    private _fb: FormBuilder,
    private patternValidator: PatternValidator,
    private _noteService: NoteService
  ) {}

  createPatientNotesForm(data: FinalNotes = null) {
    return this._fb.group({
      refId: data?.refId || "",
      noteType: [
        data?.noteType || this.defaultNoteType,
        [Validators.required],
        [this.patternValidator.validate("noteType")],
      ],
      noteSubType: [
        data?.noteSubType || "",
        [Validators.required],
        [this.patternValidator.validate("noteSubType")],
      ],
      components: this.createFormArray(
        data?.components,
        this.buildComponentGroup
      ),
      impactCase: [data?.impactCase || null],
      chargeable: [data?.chargeable || null],
      addendum: this.createFormArray(data?.addendum, this.buildAddendumGroup),
      pendOrSigned: [data?.pendOrSigned || ""],
      isDeleteEnabled: [data?.isDeleteEnabled || ""],
      cosign: this.buildCosignGroup(data?.cosign),
    });
  }

  popUpCloseTrigger(state: boolean) {
    this.popUpCloseTriggerSource.next(state);
  }

  private createFormArray(
    data: any[] | undefined,
    groupBuilder: (item: any) => FormGroup
  ): FormArray {
    if (!data || data.length === 0) {
      return this._fb.array([]);
    }
    return this._fb.array(data.map(groupBuilder));
  }

  private buildComponentGroup = (component: Component): FormGroup => {
    return this._fb.group({
      id: [component.id],
      displayName: [component.displayName, [Validators.required]],
      type: [component.type, [Validators.required]],
      value: component.value,
    });
  };

  buildCosignGroup = (component: Cosign): FormGroup => {
    if (!component) return null;
    return this._fb.group({
      physicianMessage: [component.physicianMessage],
      requestedBy: [component.requestedBy, [Validators.required]],
      requestedTo: [component.requestedTo],
      status: [component.status, [Validators.required]],
      attestedOn: [component.attestedOn],
    });
  };

  buildAddendumGroup = (addendum: Addendum): FormGroup => {
    return this._fb.group({
      name: [addendum.name || this._noteService.author, [Validators.required]],
      note: [addendum.note, [Validators.required]],
      timestamp: [addendum.timestamp || new Date(), [Validators.required]],
      uniqueId: [addendum.uniqueID || null],
    });
  };

  showImpactCases(patientNotesForm: FormGroup) {
    return (
      Object.values(ImpactNotes).includes(
        patientNotesForm.get("noteType").value
      ) && RadarGlobalConfig.isImpactNoteHospital(this._noteService.user)
    );
  }
}
