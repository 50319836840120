import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromNotificationReducer from "./notifications.reducer";
import * as fromRoot from "../index";

export interface notificationState {
  data: fromNotificationReducer.State;
}

export interface State {
  notifications: notificationState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromNotificationReducer.reducer,
};

export const getNotificationState =
  createFeatureSelector<notificationState>("notifications");

export const getNotificationData = createSelector(
  getNotificationState,
  (state) => state.data
);

export const getNotification = createSelector(
  getNotificationData,
  fromNotificationReducer.selectAllNotification
);

// export const getNotificationFiltered = createSelector(
//     getNotification,
//     (notification) => { }
// )

export const getNotificationArray = createSelector(
  getNotificationData,
  fromNotificationReducer.selectAllNotification
);

export const getNotificationEntites = createSelector(
  getNotificationData,
  fromNotificationReducer.selectNotificationEntities
);

export const getNotificationById = createSelector(
  getNotificationEntites,
  (entites, id: string) => entites[id]
);
