<section class="page-not-found-container">
  <div class="content">
    <div class="c-emoji">
      <img src="../../assets/icons/sad_face_error_page.svg" alt="Sad Face" />
    </div>
    <div class="c-body">
      <h2>Oops, something went wrong.</h2>
      <p>
        We can’t find the page you’re looking for. <br />
        Please head back to home.
      </p>

      <button
        mat-flat-button
        class="button-primary"
        type="button"
        (click)="goBack()"
      >
        Go back
      </button>
    </div>
  </div>
</section>
