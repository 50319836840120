import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { VitalsService } from "src/app/services/vitals.service";
import { Vitals } from "../../models/Vital";
import {
  setClickedDay,
  canvasStatus,
} from "../../store/actions/vitals.actions";
import { getVitalClickedDay } from "../../store/reducers";
import { valExists } from "src/app/support-functions/util";
import * as fromVitalReducer from "../../store/reducers";
import EventEmitter from "events";
import { NgxPermissionsService } from "ngx-permissions";
import { MatDialog } from "@angular/material/dialog";
import { VitalDayPipe } from "../../pipes/vital-day.pipe";
import { getNormalRange } from "../vitalsrange";
import { vitalrange } from "src/app/data/vitalsrange.data";
import { TimeZoneDetails } from "src/app/models/hospital";
import { TrendsComponent } from "src/app/shared-modules/features/trends/components/old-trends/trends.component";
import { TrendType } from "src/app/shared-modules/features/trends/constants/trends.data";
import { TimezoneService } from "src/app/services/timezone.service";
import {
  VITAL_PROPS_ADULTS,
  VITAL_PROPS_NEONATAL,
} from "../../constants/vital-view.constant";
import { PrintService } from "@shared-modules/services/print.service";
import { PatientService } from "src/app/services/patient.service";
import { setPatientNameInCamelCase } from "src/app/support-functions/patientNameInCamelCase";
import { displayAge } from "src/app/support-functions/calculateAge";
import { RemoveBlankEntriesPipe } from "@shared-modules/pipes/remove-blank-entries/remove-blank-entries.pipe";
import { PatientType } from "src/app/models/patient";
const jsPDF = require("jspdf");
require("jspdf-autotable");

const temperatureVitalName = "daysTemperature";
const temperatureUnitVitalName = "daysTemperatureUnit";
const bpVitalName = "daysBP";
@Component({
  selector: "app-vital-view",
  templateUrl: "./vital-view.component.html",
  styleUrls: ["./vital-view.component.scss"],
})
export class VitalViewComponent implements OnInit {
  public vitalrange = vitalrange;
  public timeZoneDetail: TimeZoneDetails;
  public currentPatient;
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.timeZoneDetail =
        this.currentPatient?.timeZoneDetail ||
        this.currentPatient?.hospitalInfo?.timeZoneDetail;
    }
  }
  public vital = { min: "", max: "", priority: "" };
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  @HostBinding("class.hideoverflow") showImageCanvas: boolean = false;

  /**
   * @description To observe the clicked day
   */
  public vitalClickedDay$ = this.store.pipe(
    select(getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  @Input() collapseEmptyRows: boolean;

  /**
   * @description To store the current selected day
   * @type {number}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  @Input() selectedDay: number = 0;

  @Input() selectedDate: any;

  /**
   * @description Determines if date is shown on top of table column
   * @type {boolean}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  @Input() showDateOnCol: boolean = false;

  /**
   * @description To store the vitals
   * @type {Array}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  @Input() vitals: Vitals[] = [];

  /**
   * @description to show or hide vital-trends
   */
  @Input() showVitalTrends: boolean = false;

  /**
   * @description To display this in front end
   * @type {Array}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  public vitalPropsAdult: { val: string; show: string }[] = VITAL_PROPS_ADULTS;
  public vitalPropsNeonatal: { val: string; show: string }[] =
    VITAL_PROPS_NEONATAL;

  public vitalKeysAdult = this.vitalPropsAdult.reduce(
    (arr, prop) => (prop.val.length ? [...arr, prop.val] : arr),
    []
  );
  public vitalKeysNeonatal = this.vitalPropsNeonatal.reduce(
    (arr, prop) => (prop.val.length ? [...arr, prop.val] : arr),
    []
  );

  /**
   * @description includes vitals to which vital-trends enabled
   * @author Rajat Saini
   * @Date Aug 29, 2022
   */
  public vitalsToTrendsMap = [
    "daysTemperature",
    "daysBP",
    "daysHR",
    "daysRR",
    "daysMAP",
    "daysCVP",
    "daysSpO2",
  ];

  public vitalsToTrendsMapPedNeo = [
    "daysTemperature",
    "daysBP",
    "daysHR",
    "daysRR",
    "daysMAP",
    "daysSpO2",
  ];

  vitalToUnitNameMap: any = {
    daysTemperature: "F",
    daysBP: "mmHg",
    daysHR: "BPM",
    daysRR: "per minute",
    daysMAP: "mmHg",
    daysSpO2: "%",
  };

  // [['domain'], 'ticks']
  vitalYAxisRanges: any = {
    daysTemperature: [[90, 110], 9],
    daysBP: [[0, 300], 10],
    daysHR: [[0, 260], 10],
    daysRR: [[0, 90], 10],
    daysMAP: [[0, 260], 10],
    daysCVP: [[0, 40], 9],
    daysSpO2: [[0, 100], 9],
  };

  private _tz = inject(TimezoneService);
  mathCeil = Math.ceil;

  constructor(
    private store: Store<any>,
    private _vitalService: VitalsService,
    private ngxPermissionsService: NgxPermissionsService,
    private _dialog: MatDialog,
    private _printService: PrintService,
    private _patientService: PatientService,
    private _removeBlankEntriesPipe: RemoveBlankEntriesPipe,
    private _vitalDayPipe: VitalDayPipe
  ) {}

  ngOnInit(): void {
    this.queryCurrentDayVitals();
    this.vitalReviewListener();
    this.formStateInit();
    this.vitalImageListener();

    this.getVitalEntryPermission();
  }

  currentDayVitals: any;
  queryCurrentDayVitals() {
    this.currentDayVitals = this._removeBlankEntriesPipe.transform(
      this._vitalDayPipe.transform(this.vitals, this.selectedDay),
      this.currentPatient?.patientType === PatientType.Neonatal
        ? this.vitalKeysNeonatal
        : this.vitalKeysAdult
    );
  }

  vitalReviewListener() {
    this._printService.isTriggerPrint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.triggerJSPDF();
      });
  }

  triggerJSPDF() {
    let doc = new jsPDF({
      orientation: "l",
    });
    doc.setFontSize(10);

    const tempMonth = new Date().getMonth() + 1;

    doc.setProperties({
      title:
        this._patientService.displayName(this.currentPatient, false) +
        " " +
        this.currentPatient["MRN"] +
        " " +
        new Date().getDate() +
        "-" +
        tempMonth +
        "-" +
        new Date().getFullYear(),
      author: "Cloudphysician",
      creator: "Cloudphysician",
    });

    let topY = doc.lastAutoTable?.finalY || 0 + 8;
    doc = this.setPersonalInfoToPdf(doc);

    for (const i of Array(
      Math.ceil(this.currentDayVitals?.length / 24)
    ).keys()) {
      doc.autoTable({
        startY: doc?.lastAutoTable?.finalY + 4,
        headStyles: {
          fontSize: 8,
          fillColor: "#E2E8F4",
          lineWidth: 0.5,
          cellWidth: 11.5,
          lineColor: "#E2E8F4",
          textColor: [0, 0, 0],
          halign: "center",
        },
        columnStyles: {
          0: {
            fontSize: 8,
            cellWidth: null,
            fillColor: "#EEEFF4",
            lineWidth: 0.5,
            lineColor: "#E2E8F4",
          },
          1: {
            fontSize: 8,
            lineWidth: 0.5,
            lineColor: "#E2E8F4",
          },
        },
        bodyStyles: {
          fontSize: 8,
          halign: "center",
          lineWidth: 0.5,
          lineColor: "#E2E8F4",
        },
        html: `#vitalTable-${i}`,
        margin: { top: 25, bottom: 10, left: 5, right: 5 },
        styles: { overflow: "linebreak", fontSize: 8, halign: "center" },
      });
    }

    doc = this.setPdfHeaderOnEveryPage(doc);

    // open the pdf in new window
    let pdfWindow = window.open("", "", "width=700,height=900");
    pdfWindow.document.write(
      "<iframe style='width: 100%; height: 70rem' src='" +
        doc.output("datauristring") +
        "' frameborder='0'></iframe>"
    );
    pdfWindow.document.close();
  }

  setPdfHeaderOnEveryPage(doc) {
    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      if (i > 1) doc = this.setPersonalInfoToPdf(doc);
    }
    return doc;
  }

  setPersonalInfoToPdf(doc) {
    doc.autoTable({
      styles: { fontSize: 10 },
      startY: 8,
      columnStyles: {
        0: { cellWidth: 33 },
        1: { cellWidth: 58 },
        2: { cellWidth: 33 },
        3: { cellWidth: 58 },
      },
      body: [
        [
          "Name: ",
          setPatientNameInCamelCase(
            this.currentPatient["name"],
            this.currentPatient["lastName"]
          ) || "-",
          "MRN: ",
          `${this.currentPatient["MRN"] || "-"}`,
          "Date: ",
          `${this._tz
            .transformIntoTimezoneObj(this.selectedDate)
            .format("YYYY-MM-DD")}`,
        ],
        [
          "Age / Gender: ",
          `${
            this.getPatientAge() + " / " + (this.currentPatient["sex"] || "-")
          }`,
          "Bed no: ",
          `${this.currentPatient["bedNo"] || "-"}`,
        ],
      ],
      margin: { top: 0, bottom: 0, left: 5, right: 5 },
    });
    return doc;
  }

  /**
   * @description To listen the click on show image from vital form
   * @author Suraj Shenoy
   * @date Nov 12 2021
   */
  vitalImageListener(): void {
    this._vitalService.netraImage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((day) => {
        this.verifyColumn(day);
      });
  }

  /**
   * @description To listen to the vital update, if update is true hide the netra image
   * @author Suraj Shenoy
   * @date Jul 16 2021
   */
  formStateInit(): void {
    this.vitalForm$.subscribe((data) => {
      if (data.showSuccess) {
        this.appearCanvas = false;
        this.store.dispatch(canvasStatus({ val: this.appearCanvas }));
      }
    });
  }

  /**
   * @description This is to know whether the user can edit vitals or not
   * @author Suraj Shenoy
   * @date Dc 14 2021
   */
  canEditVitals: boolean = false;

  /**
   * @description This is to get the permission for create_vitals
   * @author Suraj Shenoy
   * @date Dc 14 2021
   */
  getVitalEntryPermission(): void {
    if (this.ngxPermissionsService.getPermission("create_vital")) {
      this.canEditVitals = true;
    } else {
      this.canEditVitals = false;
    }
  }

  /**
   * @description used to highlight the button in highlighted column
   * @type {number}
   * @author Suraj Shenoy
   * @date June 9th 2021
   */
  hoveredColumnIndex: number = -1;

  /**
   * @description Sets the hoveredColumnIndex with the index of the column
   * @param {number} index index of the hovered column
   * @author Suraj Shenoy
   * @date June 9th 2021
   */
  highlightButton(index: number): void {
    this.hoveredColumnIndex = index;
  }

  /**
   * @description To trigger the loader when the image loads
   * @type {boolean}
   */
  imageLoading: boolean = false;

  /**
   * @description To hide / show the image canvas popup
   * @type {boolean}
   */
  appearCanvas: boolean = false;

  /**
   * @description To hold the image dom element
   * @type {HTMLImageElement}
   */
  public zoomedImage: HTMLImageElement = new Image();

  /**
   * @description This gets the key from the parameter object and gets the image data
   * @param {Vitals} day
   * @author Suraj Shenoy B
   * @date Jul 19 2021
   */
  verifyColumn(day: Vitals): void {
    this.imageLoading = true;

    if (day.image?.attachmentLink?.key) {
      this._vitalService
        .getVitalImage(
          day.image.attachmentLink.key,
          day.image.attachmentLink.origin
        )
        .toPromise()
        .then((data: any) => {
          const imageUrl = "data:image/jpeg;base64," + data.image;

          let imageData = new Image();
          imageData.onload = () => {
            this.imageLoading = false;
            this.showImageCanvas = true;
            this.zoomedImage.src = imageUrl;

            setTimeout(() => {
              this.appearCanvas = true;
              this.store.dispatch(canvasStatus({ val: this.appearCanvas }));
            }, 100);
          };
          imageData.src = imageUrl;
        })
        .catch((err) => console.log(err));
    }
  }

  /**
   * @description This is triggered when the image canvas is closed
   * @author Suraj Shenoy B
   * @date Jul 19 2021
   */
  closeCanvas(): void {
    this.showImageCanvas = false;
    this.appearCanvas = false;
    this.store.dispatch(canvasStatus({ val: this.appearCanvas }));
  }

  /**
   * @description Used to set the day info in the store to populate the input section
   * @author Suraj Shenoy
   * @date June 23rd 2021
   */
  highlightDay(day: Vitals): void {
    this.store.dispatch(setClickedDay({ day }));
  }

  openVitalTrends({ ...vitalProp }) {
    if (!this.showVitalTrends) return;

    // check if trends is enabled for a vital
    const isTrendsVisible =
      !this.currentPatient.patientType ||
      this.currentPatient?.patientType === "adult"
        ? this.vitalsToTrendsMap.includes(vitalProp?.val)
        : this.vitalsToTrendsMapPedNeo.includes(vitalProp?.val);
    if (!isTrendsVisible) return;

    if (vitalProp?.show === "Temp") {
      vitalProp.show = "Temperature";
    } else if (vitalProp?.val === "daysSpO2") {
      vitalProp.show = "SpO2";
    }
    const { val: vitalName, show: vitalDisplayName } = vitalProp;
    vitalProp["unitName"] = this.getUnitName(vitalName);

    const currentVital = this.vitals.reduce((obj, vital) => {
      if (!vital?.[vitalName] && vital[vitalName] !== 0) return obj;

      obj[vital.dayNum] =
        vital?.dayNum in obj
          ? [
              ...obj[vital.dayNum],
              {
                value: this.getVitalValue(vital, vitalName),
                value2:
                  vitalName === bpVitalName
                    ? this.getNormaliseBPValues(vital[vitalName])
                    : null,
                date: this._tz.transformIntoTimezoneStr(vital.timestamp),
              },
            ]
          : [
              {
                value: this.getVitalValue(vital, vitalName),
                value2:
                  vitalName === bpVitalName
                    ? this.getNormaliseBPValues(vital[vitalName])
                    : null,
                date: this._tz.transformIntoTimezoneStr(vital.timestamp),
              },
            ];
      return obj;
    }, {});

    if (!currentVital[this.selectedDay]) return;

    const dialogRef = this._dialog.open(TrendsComponent, {
      width: "1320px",
      height: "705px",
      autoFocus: false,
      disableClose: true,
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.isShowDayDropdown = true;
    componentInstance.currPatient = this.currentPatient;
    componentInstance.selectedDay = this.selectedDay;
    componentInstance.currentVitalData = currentVital;
    componentInstance.vitalNames = vitalProp;
    componentInstance.trendType = TrendType.vital;
    componentInstance.yAxisConfig =
      vitalProp.val in this.vitalYAxisRanges
        ? this.vitalYAxisRanges[vitalProp.val]
        : [0, 100];
    componentInstance.vitalNormalRange = getNormalRange(
      this.currentPatient?.patientType,
      vitalName,
      this.currentPatient.age,
      this.currentPatient.weight
    );
  }

  getVitalValue(vitalObj, vitalName) {
    if (vitalName === bpVitalName) {
      return this.getNormaliseBPValues(vitalObj[vitalName], true);
    } else if (
      vitalName === temperatureVitalName &&
      vitalObj[temperatureUnitVitalName] === "C"
    ) {
      return this.convertCToFTemp(vitalObj[vitalName]);
    }
    return vitalObj[vitalName];
  }

  getUnitName(vitalName) {
    return vitalName in this.vitalToUnitNameMap
      ? this.vitalToUnitNameMap[vitalName]
      : "";
  }

  getNormaliseBPValues(bpValue, isValue1 = false) {
    if (!bpValue) return null;
    return isValue1 ? bpValue.split("/")[0] : bpValue.split("/")[1];
  }

  convertCToFTemp(cValue: number) {
    return Math.round(cValue * (9 / 5) + 32);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  valExists = valExists;
  //for getting priorities for vital ranges(color highlights)
  getVitalPriorityCase1(vitalVal, value, tempUnit?) {
    if (tempUnit === "C") {
      value = value * (9 / 5) + 32;
    }
    let patientType = this.currentPatient?.patientType;
    this.vital = { min: "", max: "", priority: "" };

    if (patientType == "adult" || patientType == undefined) {
      this.methodForAdult(vitalVal, value);
    }
    if (patientType == "neonatal") {
      if (vitalVal == "daysBP") {
        let input = value.split("/");
        if (
          this.currentPatient.age.hour <= 24 &&
          this.currentPatient.weight <= 1 &&
          this.currentPatient.age.day == 0
        ) {
          this.methodForNeonatal(
            patientType,
            vitalVal,
            value,
            input,
            "24H,1KG"
          );
        } else if (
          this.currentPatient.age.hour <= 24 &&
          this.currentPatient.weight > 1 &&
          this.currentPatient.weight <= 3 &&
          this.currentPatient.age.day == 0
        ) {
          this.methodForNeonatal(
            patientType,
            vitalVal,
            value,
            input,
            "24H,3KG"
          );
        } else if (
          this.currentPatient.age.hour <= 24 &&
          this.currentPatient.weight > 3 &&
          this.currentPatient.age.day == 0
        ) {
          this.methodForNeonatal(
            patientType,
            vitalVal,
            value,
            input,
            "24H,default"
          );
        } else if (
          (this.currentPatient.age.day >= 1 &&
            this.currentPatient.age.day < 28) ||
          (this.currentPatient.age.day == 28 &&
            this.currentPatient.age.hour == 0)
        ) {
          this.methodForNeonatal(patientType, vitalVal, value, input, "28D");
        } else if (
          this.currentPatient.age.day > 28 ||
          (this.currentPatient.age.day == 28 &&
            this.currentPatient.age.hour > 0)
        ) {
          this.methodForNeonatal(
            patientType,
            vitalVal,
            value,
            input,
            "default"
          );
        }
      } else if (vitalrange[patientType].default[vitalVal] !== undefined) {
        this.vital = vitalrange[patientType]["default"][
          vitalVal
        ].priorities.find((element) => {
          if (value && element.min === null && value < element.max) {
            return element;
          } else if (value && element.max === null && value > element.min) {
            return element;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    }
    if (patientType == "pediatric") {
      if (vitalVal == "daysBP") {
        let input = value.split("/");
        if (
          this.currentPatient.age.year == 0 &&
          this.currentPatient.age.month <= 6
        ) {
          if (vitalrange.pediatric["6M"][vitalVal] !== undefined) {
            this.vital = vitalrange.pediatric["6M"][vitalVal].priorities.find(
              (element) => {
                if (
                  value &&
                  element.min === null &&
                  (Number(input[0]) < element.max.split("/")[0] ||
                    Number(input[1]) < element.max.split("/")[1])
                ) {
                  return element;
                } else if (
                  value &&
                  element.max === null &&
                  (Number(input[0]) > element.min.split("/")[0] ||
                    Number(input[1]) > element.min.split("/")[1])
                ) {
                  return element;
                } else {
                  return false;
                }
              }
            );
          }
        } else if (
          (this.currentPatient.age.year == 2 &&
            this.currentPatient.age.month == 0) ||
          (this.currentPatient.age.year < 2 &&
            this.currentPatient.age.year >= 1) ||
          (this.currentPatient.age.month > 6 &&
            this.currentPatient.age.year == 0)
        ) {
          if (vitalrange.pediatric["2Y"][vitalVal] !== undefined) {
            this.vital = vitalrange.pediatric["2Y"][vitalVal].priorities.find(
              (element) => {
                if (
                  value &&
                  element.min === null &&
                  (Number(input[0]) < element.max.split("/")[0] ||
                    Number(input[1]) < element.max.split("/")[1])
                ) {
                  return element;
                } else if (
                  value &&
                  element.max === null &&
                  (Number(input[0]) > element.min.split("/")[0] ||
                    Number(input[1]) > element.min.split("/")[1])
                ) {
                  return element;
                } else {
                  return false;
                }
              }
            );
          }
        } else if (
          (this.currentPatient.age.year > 2 &&
            this.currentPatient.age.year < 7) ||
          (this.currentPatient.age.year == 2 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 7 &&
            this.currentPatient.age.month == 0)
        ) {
          if (vitalrange.pediatric["7Y"][vitalVal] !== undefined) {
            this.vital = vitalrange.pediatric["7Y"][vitalVal].priorities.find(
              (element) => {
                if (
                  value &&
                  element.min === null &&
                  (Number(input[0]) < element.max.split("/")[0] ||
                    Number(input[1]) < element.max.split("/")[1])
                ) {
                  return element;
                } else if (
                  value &&
                  element.max === null &&
                  (Number(input[0]) > element.min.split("/")[0] ||
                    Number(input[1]) > element.min.split("/")[1])
                ) {
                  return element;
                } else {
                  return false;
                }
              }
            );
          }
        } else if (
          (this.currentPatient.age.year > 7 &&
            this.currentPatient.age.year <= 18) ||
          (this.currentPatient.age.year == 7 &&
            this.currentPatient.age.month > 0)
        ) {
          if (vitalrange.pediatric["18Y"][vitalVal] !== undefined) {
            this.vital = vitalrange.pediatric["18Y"][vitalVal].priorities.find(
              (element) => {
                if (
                  value &&
                  element.min === null &&
                  (Number(input[0]) < element.max.split("/")[0] ||
                    Number(input[1]) < element.max.split("/")[1])
                ) {
                  return element;
                } else if (
                  value &&
                  element.max === null &&
                  (Number(input[0]) > element.min.split("/")[0] ||
                    Number(input[1]) > element.min.split("/")[1])
                ) {
                  return element;
                } else {
                  return false;
                }
              }
            );
          }
        } else {
          return false;
        }
      } else if (vitalVal !== "daysBP") {
        if (
          this.currentPatient.age.year == 0 &&
          this.currentPatient.age.month <= 6
        ) {
          this.methodForPediatric(vitalVal, value, "6M");
        } else if (
          (this.currentPatient.age.year == 0 &&
            this.currentPatient.age.month > 6 &&
            this.currentPatient.age.month <= 12) ||
          (this.currentPatient.age.year == 1 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "12M");
        } else if (
          (this.currentPatient.age.year > 0 &&
            this.currentPatient.age.year < 2) ||
          (this.currentPatient.age.year == 2 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "2Y");
        } else if (
          (this.currentPatient.age.year > 2 &&
            this.currentPatient.age.year < 4) ||
          (this.currentPatient.age.year == 2 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 4 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "4Y");
        } else if (
          (this.currentPatient.age.year > 4 &&
            this.currentPatient.age.year < 6) ||
          (this.currentPatient.age.year == 4 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 6 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "6Y");
        } else if (
          (this.currentPatient.age.year > 6 &&
            this.currentPatient.age.year < 8) ||
          (this.currentPatient.age.year == 6 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 8 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "8Y");
        } else if (
          (this.currentPatient.age.year > 8 &&
            this.currentPatient.age.year < 10) ||
          (this.currentPatient.age.year == 8 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 10 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "10Y");
        } else if (
          (this.currentPatient.age.year > 10 &&
            this.currentPatient.age.year < 12) ||
          (this.currentPatient.age.year == 10 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 12 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "12Y");
        } else if (
          (this.currentPatient.age.year > 12 &&
            this.currentPatient.age.year < 14) ||
          (this.currentPatient.age.year == 12 &&
            this.currentPatient.age.month > 0) ||
          (this.currentPatient.age.year == 14 &&
            this.currentPatient.age.month == 0)
        ) {
          this.methodForPediatric(vitalVal, value, "14Y");
        } else if (
          (this.currentPatient.age.year > 14 &&
            this.currentPatient.age.year < 18) ||
          (this.currentPatient.age.year == 14 &&
            this.currentPatient.age.month > 0) ||
          this.currentPatient.age.year >= 18
        ) {
          this.methodForPediatric(vitalVal, value, "18Y");
        }
      }
    }

    if (this.vital === undefined) {
      return "";
    } else if (this.vital.priority === "medium") {
      return "table-warning";
    } else if (this.vital.priority === "high") {
      return "table-danger";
    } else {
      return "";
    }
  }
  methodForAdult(vitalVal, value) {
    return (this.vital = vitalrange.others.default[vitalVal].priorities.find(
      (element) => {
        if (value && element.min === null && value <= element.max) {
          return element;
        } else if (
          value &&
          element.min &&
          element.max &&
          value > element.min &&
          value <= element.max
        ) {
          return element;
        } else if (value && element.max === null && value > element.min) {
          return element;
        } else {
          return false;
        }
      }
    ));
  }
  methodForPediatric(vitalVal, value, age) {
    if (vitalrange.pediatric[age][vitalVal] !== undefined) {
      this.vital = vitalrange.pediatric[age][vitalVal].priorities.find(
        (element) => {
          if (value && element.min === null && value < element.max) {
            return element;
          } else if (value && element.max === null && value > element.min) {
            return element;
          } else {
            return false;
          }
        }
      );
    } else if (vitalrange.pediatric["default"][vitalVal] != undefined) {
      this.vital = vitalrange.pediatric["default"][vitalVal].priorities.find(
        (element) => {
          if (value && element.min === null && value < element.max) {
            return element;
          } else if (value && element.max === null && value > element.min) {
            return element;
          } else {
            return false;
          }
        }
      );
    } else {
      return false;
    }
  }
  methodForNeonatal(patientType, vitalVal, value, input, age) {
    this.vital = vitalrange[patientType][age][vitalVal].priorities.find(
      (element) => {
        if (
          value &&
          element.min === null &&
          (Number(input[0]) < element.max.split("/")[0] ||
            Number(input[1]) < element.max.split("/")[1])
        ) {
          return element;
        } else if (
          value &&
          element.max === null &&
          (Number(input[0]) > element.min.split("/")[0] ||
            Number(input[1]) > element.min.split("/")[1])
        ) {
          return element;
        } else {
          return false;
        }
      }
    );
  }

  getPatientAge() {
    return displayAge(
      this.currentPatient?.age,
      true,
      this.currentPatient.patientType,
      this.currentPatient.dob
    );
  }
}
