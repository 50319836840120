import { Action, createReducer, on } from "@ngrx/store";
import * as rootActions from "src/app/store/actions/root.actions";
import { patientChartReset } from "src/app/store/actions/root.actions";
import {
  MarSOSActions,
  MarDietActions,
  MarNormalActions,
  MarStatusActions,
  MarInfusionActions,
} from "@mar/store/actions/index";

export interface State {
  loaded?: Boolean;
  loading: Boolean;
}

export const initialState: State = {
  loaded: false,
  loading: true,
};

const marStatusReducer = createReducer(
  initialState,
  on(
    MarStatusActions.setLoader,
    (state, { loading }) => ({ loading }) // loaded: true, loading: false
  ),
  on(
    MarNormalActions.setMarNormal,
    MarNormalActions.upsertMarNormal,
    MarNormalActions.resetMarNormal,
    MarDietActions.setMarDiet,
    MarDietActions.upsertMarDiet,
    MarDietActions.resetMarDiet,
    MarSOSActions.setMarSOS,
    MarSOSActions.upsertMarSOS,
    MarSOSActions.resetMarSOS,
    MarInfusionActions.setMarInfusion,
    MarInfusionActions.upsertMarInfusion,
    MarInfusionActions.resetMarInfusion,
    (state) => ({ loaded: true, loading: false })
  ),
  on(patientChartReset, (state) => initialState),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State, action: Action) {
  return marStatusReducer(state, action);
}

export const getMarStatusLoading = (state: State) => state.loading;
