import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { OrderService } from "../../services/order.service";
import { OrderApiActions, OrderPaneActions } from "../actions/order";
import { OrderApiActionTypes } from "../actions/order/order-api.actions";
import * as orderActions from "src/app/store/actions/order/order-main.actions";
import { displayErrorMessage } from "../actions/root.actions";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Store } from "@ngrx/store";
import {
  State as AppState,
  getPatientCPMRNEncounters,
} from "src/app/store/reducers/patient-chart/patient-header";

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private alertService: AlertService,
    private store: Store<AppState>
  ) {}

  addOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.addOrder),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ order }, patientData]) =>
        this.orderService.placeOrder(order, patientData).pipe(
          take(1),
          map((data: any) =>
            OrderApiActions.addOrderSuccess({ order: data.data })
          ),
          catchError((error: any) => {
            if (error?.status == 409) {
              return of(OrderApiActions.duplicateOrder());
            }

            return of(
              OrderApiActions.addOrderFailure({ error: error?.error?.message })
            );
          })
        )
      )
    )
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.updateOrder),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ order }, patientData]) =>
        this.orderService.editOrder(order, patientData).pipe(
          take(1),
          map((data: any) =>
            OrderApiActions.updateOrderSuccess({ order: data.data })
          ),
          catchError((error: any) =>
            of(
              OrderApiActions.updateOrderFailure({
                error: error?.error?.message,
              })
            )
          )
        )
      )
    )
  );

  updateOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderApiActions.updateOrderSuccess),
      map((_) => new orderActions.CloseInputTab())
    )
  );

  orderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderApiActions.addOrderSuccess,
        OrderApiActions.updateOrderSuccess
      ),
      map(({ order, type }) => {
        let message = "Successfully Created";

        if (type === OrderApiActionTypes.UpdateOrderSuccess) {
          message = "Successfully Updated";
        }

        this.alertService.showNotification({ type: "Success", message });

        return new orderActions.CloseInputTab();
      })
    )
  );

  orderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrderApiActions.addOrderFailure,
          OrderApiActions.updateOrderFailure
        ),
        tap(({ error }) => {
          const message = error ? error : "Error Occurred. Try Again";

          this.alertService.showNotification({
            type: "Error",
            message,
          });
        })
      ),
    { dispatch: false }
  );

  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.ActionTypes.listOrders),
      mergeMap((action: orderActions.ListOrders) =>
        this.orderService
          .getPatientOrders(
            action.payload.type,
            action.payload.CPMRN,
            action.payload.encounters
          )
          .pipe(
            map((response) => ({
              type: orderActions.ActionTypes.ordersListed,
              payload: response["data"],
            })),
            catchError(() => of({ type: orderActions.ActionTypes.listOrders }))
          )
      )
    )
  );

  orderUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.ActionTypes.callUpdate),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ payload }, patientData]) =>
        this.orderService.editOrder(payload, patientData).pipe(
          map((response) => new orderActions.CloseInputTab()),
          catchError(() => of(displayErrorMessage({ message: "Server Error" })))
        )
      )
    )
  );

  /*  @Effect()
    orderNew$ = this.actions$.pipe(
      ofType(orderActions.ActionTypes.callNewOrder),
      switchMap((action: orderActions.CallNewOrder) =>
        this.orderService.placeOrder(action.payload).pipe(
          map(response => new orderActions.CloseInputTab()),
          catchError(() => of({ type: orderActions.ActionTypes.callNewOrder }))
        )
      )
    );*/

  orderDiscontinue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.ActionTypes.discontinue),
        withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
        switchMap(([{ payload }, patientData]) =>
          this.orderService.disContinueOrder(payload, patientData).pipe(
            // map((response) => console.log(response)),
            catchError(() => of({ type: orderActions.ActionTypes.discontinue }))
          )
        )
      ),
    { dispatch: false }
  );

  orderSign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.ActionTypes.sign),
        withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
        switchMap(([{ payload }, patientData]) =>
          this.orderService.signOrder(payload, patientData).pipe(
            // map((response) => console.log(response)),
            catchError(() => of({ type: orderActions.ActionTypes.sign }))
          )
        )
      ),
    { dispatch: false }
  );

  orderMove$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.ActionTypes.move),
        withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
        switchMap(([{ payload }, patientData]) =>
          this.orderService.moveOrder(payload, patientData).pipe(
            // map((response) => console.log(response)),
            catchError(() => of({ type: orderActions.ActionTypes.move }))
          )
        )
      ),
    { dispatch: false }
  );

  orderCommunicate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.ActionTypes.communicate),
        withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
        switchMap(([{ payload }, patientData]) =>
          this.orderService.communicateAction(payload, patientData).pipe(
            // map((response) => console.log(response)),
            catchError(() => of({ type: orderActions.ActionTypes.communicate }))
          )
        )
      ),
    { dispatch: false }
  );
}
