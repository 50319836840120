import { createReducer, on, Action } from "@ngrx/store";

import * as documentActions from "src/app/store/actions/patient-chart/documents/documents.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  documents: any[];
}

const initialState: State = {
  documents: [],
};

const documentDataReducer = createReducer(
  initialState,
  on(documentActions.setDocuments, (state, { docs }) => ({
    ...state,
    documents: docs,
  })),
  on(documentActions.editDocument, (state, { doc }) => {
    const subId = doc["subId"];
    const attributes = doc["attributes"];

    state.documents.map((doc) => {
      if (doc._id == subId) {
        doc.attributes = attributes;
      }
    });

    return state;
  }),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return documentDataReducer(state, action);
}

/** Helper functions */
function updateDocument(docs: Array<any>, props) {
  const subId = props.subId;
  const attributes = props.attributes;

  return docs.map((doc) => {
    if (doc._id == subId) {
      doc.attributes = attributes;
    }
  });
}

function editDocument(docs: Array<any>, props) {
  const subId = props.subId;
  const document = props.doc;

  let changedDocs = docs.map((doc) => {
    if (doc._id == subId) {
      doc = document;
    }
    return doc;
  });
  return changedDocs;
}
