import {
  Component,
  OnInit,
  ɵConsole,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DischargeFormComponent } from "./discharge-form/discharge-form.component";
import { Unit } from "src/app/models/hospital";
import { Patient, PatientType } from "src/app/models/patient";
import { Store, select } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { setUrl } from "src/app/support-functions/setUrl";
import { ManagePatientComponent } from "src/app/manage-patient/components/manage-patient/manage-patient.component";
import { SbarFormComponent } from "src/app/patient-list/sbar/sbar-form/sbar-form.component";
import { Title } from "@angular/platform-browser";
import { socket } from "../../config/socket";
import * as patHeaderActions from "src/app/store/actions/patient-chart/patient-header/patient-data.actions";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { PatientService } from "src/app/services/patient.service";
import * as fromNotification from "../../store/reducers/notifications";
import * as notificationActions from "src/app/store/actions/notifications/notifications";
import { Notification } from "src/app/models/notifications/notifications.model";
import { setRoundarPreference } from "../../store/actions/user.actions";
import * as fromUser from "../../store/reducers/user";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as fromUserReducer from "src/app/store/reducers/user";

import {
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment-timezone";

@Component({
  selector: "app-patient-header",
  templateUrl: "./patient-header.component.html",
  styleUrls: ["./patient-header.component.scss"],
})
export class PatientHeaderComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();

  isHovered: boolean;

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );
  public roundar$ = this.store.pipe(
    select(fromUser.getUserPreferenceRoundar),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  public patient = new Patient();
  public selUnit: Unit;

  public CPMRN = "";
  public encounters;
  public errorMsg;
  public googleMeetLink;

  public MEWSclasses = {};
  public codeClasses = {};

  public currentUser;
  public primaryCamera;
  public bedRemark = "";
  severity = "";
  public isDischargeTimeElapsed = false;

  public notifications = [];

  public severityList = ["Fair", "Watcher", "Unstable", "Comfort Care"];

  public encounter = null;

  public showAdditionalInfo = false;

  public cameraTableColumns = ["slNo", "cameraName", "remark"];

  public rox: any = {};
  public di: any = {};
  //public deltaDI: any ={};
  public patientType: string = "";
  faArrowAltCircleUp = faArrowAltCircleUp;
  faArrowAltCircleDown = faArrowAltCircleDown;

  public pressorMedInfo: any = [];

  public cameraBedMap: any = {};
  public cameraStore: any = {};

  env = environment;

  currTime = moment();

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private store: Store<any>,
    private _actions$: Actions,
    private pageTitle: Title,
    public _patientService: PatientService
  ) {}

  /* Open patient edit form in modal */
  openEditModal() {
    // const modalRef = this.modalService.open(SearchPatientComponent, this.ngbModalOptions);
    // modalRef.componentInstance.dataToUpdate = this.patient;
    this.router.navigate([`admitPatient/${this.CPMRN}/${this.encounters}`]);
  }

  getPatientData() {
    this.patientHeader$.subscribe(
      (res) => {
        // clone it, to avoid modifying the store indirectly
        let data = JSON.parse(JSON.stringify(res));

        // google meet link
        let root = this;
        if (data && data.CPMRN && data.hospitalInfo["units"]) {
          // added units validation in if condition
          data.hospitalInfo["units"].filter(function (unit) {
            if (unit.name == data.unitName && unit.meetLink) {
              if (!unit.meetLink.includes("https://")) {
                unit.meetLink = "https://" + unit.meetLink.trim();
              }
              root.googleMeetLink = unit.meetLink;
            }
          });
        }

        if (!data.allergies) {
        } else if (typeof data.allergies == "object") {
          // @ts-ignore
          data.allergies = data.allergies.join(", ");
        } else if (typeof data.allergies == "string") {
          // data.allergies = "abc,def";
          // @ts-ignore
          data.allergies = data.allergies.replace(/,/gi, ", ");
        }

        if (!data.diagnoses) {
        } else if (typeof data.diagnoses == "object") {
          // data.diagnoses = ["abc", "def"];
          // @ts-ignore
          data.diagnoses = data.diagnoses.join(", ");
        } else if (typeof data.diagnoses == "string") {
          // data.diagnoses = "abc,def";
          // @ts-ignore
          data.diagnoses = data.diagnoses.replace(/,/gi, ", ");
        }

        if (data && data.CPMRN) {
          this.CPMRN = data.CPMRN;
          this.encounters = data.encounters;

          this.setPatientData(data);
        }

        this.isDischargeTimeElapsed =
          this._patientService.checkIfDischargeTimeElapsed(data);

        // get pressor med info
        if (data["marInfo"]) {
          this.pressorMedInfo = data.marInfo.map((marMed) => {
            let dosageStatus: string = "-";
            if ((marMed.schedules.length = 2)) {
              if (
                marMed.schedules[1] &&
                marMed.schedules[1].status === "in progress"
              ) {
                let dose1 = marMed.schedules[1].infusionRate
                  ? marMed.schedules[1].infusionRate
                  : 0;
                let dose0 = marMed.schedules[0].infusionRate
                  ? marMed.schedules[0].infusionRate
                  : 0;

                if (dose1 > dose0) {
                  dosageStatus = "increased";
                } else if (dose1 < dose0) {
                  dosageStatus = "decreased";
                }
              }
            }

            let infusionRate = "-";
            let infusionUnit = "";
            if (
              marMed.schedules[1] &&
              marMed.schedules[1].status === "in progress" &&
              marMed.schedules[1].infusionRate
            ) {
              infusionRate = marMed.schedules[1].infusionRate;
              infusionUnit = marMed.schedules[1].infusionRateUnit;
            } else if (
              marMed.schedules[0] &&
              marMed.schedules[0].status === "in progress" &&
              marMed.schedules[0].infusionRate
            ) {
              infusionRate = marMed.schedules[0].infusionRate;
              infusionUnit = marMed.schedules[0].infusionRateUnit;
            }

            return {
              name: marMed.name,
              dosageStatus,
              infusionRate,
              infusionUnit,
            };
          });
        }
      },
      (error) => {
        this.errorMsg = error;
        // if (this.errorMsg.status === 404) {
        //   this.router.navigate(["patientlist"]);
        // }
      }
    );
  }

  setSockets() {
    // Setting inital start time based on the current date for discharged patient search
    socket?.on("Deterioration index", (data) => {
      if (this.patient?.patientType != PatientType.Adult) return;
      if (data.DI && this.patient.CPMRN && this.patient.CPMRN == data.CPMRN) {
        this.patient.DI = data?.DI;
      }
    });
  }

  roundValue(value: number): number {
    return Math.round(value);
  }

  setPatientData(data) {
    this.patient = data;

    // set the patient type - discharged or not discharged
    if (this.patient.isCurrentlyAdmitted) {
      this.patientType =
        this.patient.encounters > 1 ? "re-admitted" : "admitted";
    } else {
      this.patientType = "discharged";
    }

    this.MEWSclasses = {
      badge: true,
      "badge-danger": this.patient.MEWS === 3,
      "badge-warning": this.patient.MEWS > 0 && this.patient.MEWS < 3,
      "badge-success": this.patient.MEWS === 0,
    };

    //this is required only to handle previous data. For the new patient we are setting severity to null during admission.
    if (!this.patient.severity) this.patient.severity = null;
    if (!this.patient.covid) this.patient.covid = null;

    if (
      this.patient.hospitalInfo.units &&
      this.patient.hospitalInfo.units.length
    ) {
      this.selUnit = this.patient.hospitalInfo.units.find((unit) => {
        return unit["name"] == this.patient.unitName;
      });

      if (this.selUnit && this.selUnit.name) {
        let cameras = [];
        // set primary camera
        this.selUnit.cameras.forEach((cam) => {
          cameras = cam.name ? [...cameras, cam.name] : cameras;
          if (cam["ip"] == this.patient.camera) {
            this.primaryCamera = setUrl(cam);
          }
        });

        if (cameras.length) {
          this.cameraStore = this.selUnit.cameras[0];
          this.cameraBedMap = this.selUnit.beds.reduce((mapObj, bed) => {
            return bed.number && bed.camera && cameras.includes(bed.camera)
              ? {
                  ...mapObj,
                  [bed.camera]:
                    mapObj[bed.camera] && mapObj[bed.camera].length
                      ? [...mapObj[bed.camera], bed.number]
                      : [bed.number],
                }
              : mapObj;
          }, {});
        }

        // get bed remark
        this.selUnit.beds.forEach((bed) => {
          if (bed["number"] == this.patient.bedNo) {
            this.bedRemark = bed["remark"];
          }
        });
      }
    }

    // set rox
    if (this.patient.rox) {
      this.rox = this.patient.rox;
    }

    // set Deterioration index and delta of deterioration index
    // if (this.patient.patientType == 'adult' && this.patient.DI && this.patient.DI[0] && this.patient)
    if (this.patient?.DI && this.patient?.DI[0]) {
      this.patient.DI = this.patient.DI[0];
    }
  }

  /* Open patient discharge form in modal */
  openDischargeModal() {
    // this.modalService.open(DischargeFormComponent, this.ngbModalOptions);

    const dialogRef = this.dialog.open(DischargeFormComponent, {
      autoFocus: false,
      restoreFocus: false,
    });
  }

  ngOnInit() {
    setInterval(() => {
      this.currTime = moment();
    }, 1000);

    this.users$.subscribe((data) => (this.currentUser = data.currentUser));

    // fetch the notifications again  in case of refresh
    this.store.dispatch(notificationActions.getNotification());
    this.store
      .pipe(select(fromNotification.getNotificationArray))
      .subscribe((data) => {
        this.notifications = data;
      });

    this.getPatientData(); // load patient data at chart open
    this.setSockets();
  }

  severityChangeFn(encounters) {
    let patient = {
      CPMRN: this.CPMRN,
      severity: this.patient.severity,
      encounters: encounters,
    };

    this.store.dispatch(patHeaderActions.updateSeverity({ patient }));
  }

  severityColor(severity) {
    if (severity === "Fair") return "fair";
    else if (severity === "Watcher") return "watcher";
    else if (severity === "Unstable") return "unstable";
    else if (severity === "Comfort Care") return "comfort";
    else return "none";
  }

  getCovidClass(covid: string): string {
    if (covid === "suspected") return "cp-select--covid__suspected";
    else if (covid === "positive") return "cp-select--covid__positive";
    else return "";
  }

  public dialogRef;
  openSbarModal(data, type) {
    let sbarData = {
      CPMRN: data.CPMRN,
      name: data.name + " " + (data.lastName ? data.lastName : ""),
      location: data.hospitalName,
      urgency: "Select Urgency",
      bedsidePhysician: data.PCP,
      issues: null,
      action: null,
      comments: null,
      encounters: data.encounters,
      module: "Select Module",
    };

    this.dialogRef = this.dialog.open(SbarFormComponent, {
      data: sbarData,
      width: "650px",
      autoFocus: false,
      disableClose: true,
    });

    this.dialogRef.componentInstance.editedIssue = sbarData;
    this.dialogRef.componentInstance.currentUser = this.currentUser;
    this.dialogRef.componentInstance.sbarType = type;
  }

  openCamModal(content) {
    this.dialog.open(content, { width: "700px", disableClose: true });
  }

  ngOnDestroy() {
    // set the page title
    this.pageTitle.setTitle("Cloudphysician RADAR");
    socket?.removeAllListeners("Deterioration index");

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // close modal on destroy
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  showCamBroadcast() {
    if (!this.cameraStore || !this.cameraStore["ip"]) return;
    this.selUnit["cameras"].forEach((cam) => {
      if (cam["ip"] === this.cameraStore["ip"]) {
        let url = setUrl(cam);
        window.open(url, "_blank");
      }
    });
  }

  selectEncounter(encounter) {
    this.encounter = encounter;
    this.router.navigate(["patient", this.CPMRN, encounter]);
    setTimeout(() => {
      location.reload();
    }, 0);
  }

  @Output() clickedNotificationUser = new EventEmitter<{
    cpmrn: string;
    encounter: string;
  }>();
  OnNotificationClick(notification: Notification) {
    this.clickedNotificationUser.emit({
      cpmrn: notification.cpmrn,
      encounter: notification.encounter,
    });

    //   this.CPMRN = notification.cpmrn;
    //   this.encounters = notification.encounter;
    // setCurrentNotificationUser
    //   this.setPatientDate(data);
    // }
  }

  /*
   * NAME: onRoundarChange
   * PURPOSE: sets user roundar preference
   * DESCRIPTION:
   * PARAMS: data:boolean - true if checked
   * RETURNS: void
   * USED BY: patient-header.component.html
   * CREATED DATE: 9 January 2020
   * AUTHOR: Gunjit Agrawal
   */
  onRoundarChange(checked: boolean): void {
    this.store.dispatch(setRoundarPreference({ data: checked }));
  }

  getNumOfICUDays() {
    // no of days admitted
    return ` (${Math.ceil(
      (new Date().getTime() -
        new Date(this.patient["ICUAdmitDate"]).getTime()) /
        8.64e7
    )} days)`;
  }
}
