import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderDetailsSignedTitle' })
export class OrderDetailSignedTitlePipe implements PipeTransform {
  /**
   * Returns the label basis on the category of the order
   *
   * @param {string} category - category of the order (active, pending, etc.)
   * @returns {string} - Label which will be used in template
   */
  transform(category: string): string {
    if (category === 'pending' || category === 'pta') {
      return 'Created by:';
    }

    return 'Signed by:';
  }
}
