import { PatientType, PatientWeightHistory } from "../models/patient";

export interface CalculateLength {
  growthHistory: PatientWeightHistory[];
  patientType: string;
  addUnits?: boolean;
}

export const calculateCurrentLength = ({
  growthHistory,
  patientType,
  addUnits = true,
}: CalculateLength) => {
  if (!growthHistory?.length || patientType != PatientType.Neonatal)
    return null;

  const recentL = growthHistory?.find((wH) => wH.length);
  if (!recentL) return null;

  return addUnits
    ? [+recentL?.length + " cm", recentL]
    : [+recentL?.length, recentL];
};

export const calculateCurrentHeadCircumference = ({
  growthHistory,
  patientType,
  addUnits = true,
}: CalculateLength) => {
  if (!growthHistory?.length || patientType != PatientType.Neonatal)
    return null;

  const recentL = growthHistory?.find((wH) => wH.headCircumference);
  if (!recentL) return null;

  return addUnits
    ? [+recentL?.headCircumference + " cm", recentL]
    : [+recentL?.headCircumference, recentL];
};
