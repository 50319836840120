import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EffectsModule } from "@ngrx/effects";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PatientMonitorEffects } from "./effects/patient-monitor.effects";
import { PatientMonitorRoutingModule } from "./patient-monitor-routing.module";
import { PatientMonitorFormService } from "./services/patient-monitor-form.service";
import { PatientMonitorFormComponent } from "./components/patient-monitor-form/patient-monitor-form.component";
import { PatientMonitorListComponent } from "./components/patient-monitor-list/patient-monitor-list.component";
import { PatientMonitorListPageComponent } from "./containers/patient-monitor-list-page/patient-monitor-list-page.component";
import { IrisComponentsModule } from "../iris-components/iris-components.module";

@NgModule({
  declarations: [
    PatientMonitorListPageComponent,
    PatientMonitorFormComponent,
    PatientMonitorListComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    IrisComponentsModule,
    PatientMonitorRoutingModule,
    EffectsModule.forFeature([PatientMonitorEffects]),
  ],
  exports: [PatientMonitorListPageComponent],
  providers: [PatientMonitorFormService],
})
export class PatientMonitorModule {}
