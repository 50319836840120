import { createAction, props } from "@ngrx/store";
import { Unit } from "../../models/hospital";

export const setUnits = createAction(
  "[Unit List] set units",
  props<{ units: Unit[] }>()
);

export const updateUnitInfo = createAction(
  "[Unit List] set one unit info",
  props<{ unit: any }>()
);

export const updateBulkUnits = createAction(
  "[Unit List] set many unit info",
  props<{ units: any }>()
);

export const resetUnits = createAction("[Unit List] reset units");
