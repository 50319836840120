<section class="user_section">
  <table class="user_table" *ngIf="devices?.length > 0">
    <thead>
      <tr>
        <th>Id</th>
        <th>Hospital</th>
        <th>Unit</th>
        <th>Comments</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let device of devices">
        <td>{{ device.deviceId }}</td>
        <td>{{ device.hospitalName }}</td>
        <td>{{ device.unitName }}</td>
        <td>
          {{ device.comment }}
        </td>
        <td class="text-right">
          <fa-icon
            [icon]="['fas', 'dot-circle']"
            *ngIf="!device.online; else onlineElseIcon"
            class="ml-2 offline-icon"
            matTooltip="Device Offline"
          ></fa-icon>
          <ng-template #onlineElseIcon>
            <fa-icon
              [icon]="['fas', 'dot-circle']"
              class="ml-2 online-icon"
              matTooltip="Device Online"
            ></fa-icon>
          </ng-template>
          <fa-icon
            [icon]="['fas', 'exclamation-triangle']"
            class="wifi-warning"
            *ngIf="device?.wifi?.length === 0"
            matTooltip="Wifi Settings Missing"
          >
          </fa-icon>
          <fa-icon
            [icon]="['far', 'edit']"
            class="ml-2 edit-icon"
            (click)="onEditClick(device)"
            matTooltip="Edit"
          >
          </fa-icon>
          <fa-icon
            [icon]="['far', 'trash-alt']"
            class="ml-2 delete-icon"
            (click)="onDeleteClick(device)"
            placement="top"
            matTooltip="Delete"
          >
          </fa-icon>
        </td>
      </tr>
    </tbody>
  </table>
</section>
