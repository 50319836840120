import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { ApiResponse } from "../../models/api-response.model";
import { environment } from "../../../environments/environment";
import { RAlertDeviceForm } from "../../models/RAlert/device.model";
import { AcknowledgeMessageApi } from "../../models/RAlert/message.model";

@Injectable({ providedIn: "root" })
export class RalertApiService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getMessages(): Observable<any> {
    return this.http.get<ApiResponse>(
      `${this.apiUrl}iot/ralert/messages`,
      this.httpOptions
    );
  }

  acknowledgeMessage(id: string, data: AcknowledgeMessageApi): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}iot/ralert/message/${id}/acknowledge`,
      data,
      this.httpOptions
    );
  }

  getDevices(): Observable<any> {
    return this.http.get<ApiResponse>(
      `${this.apiUrl}iot/ralert/devices`,
      this.httpOptions
    );
  }

  newDevice(data: RAlertDeviceForm): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}iot/ralert/devices`,
      data,
      this.httpOptions
    );
  }

  updateDevice(data: RAlertDeviceForm, id: string): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}iot/ralert/devices/${id}`,
      data,
      this.httpOptions
    );
  }

  deleteDevice(id: string): Observable<any> {
    return this.http.delete<ApiResponse>(
      `${this.apiUrl}iot/ralert/devices/${id}`,
      this.httpOptions
    );
  }

  getBedNo(hospId: string, unitId: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}iot/ralert/${hospId}/${unitId}/beds`,
      this.httpOptions
    );
  }
}
