import { CreatedByEditedBy } from "src/app/models/patient";
import { XAxisConfig, YAxisConfig } from ".";
import { GROWTH_CHART_OPTIONS } from "../constants/growth-trends.data";

export interface GrowthTrendsConfig {
  graphName: GROWTH_CHART_OPTIONS;
  graphData: GrowthDataPoints[];
  graphConfig: GrowthGraphConfig;
}

export interface GrowthDataPoints {
  yValue: number;
  xValue: number;
  timeStamp: string;
  createdBy?: CreatedByEditedBy;
  updatedBy?: CreatedByEditedBy;
  graphName?: GROWTH_CHART_OPTIONS;
  pointColor?: string;
  weightType?: GROWTH_WEIGHT_TYPE;
}

export interface GrowthGraphConfig {
  xAxisConfig: XAxisConfig;
  yAxisConfig: YAxisConfig;
}

export interface GrowthChartConfigConstantInterface {
  xLabelName: string;
  xDomainRange?: number[];
  xTicks?: number;
  xTickValues?: number[];
  xNiceDisable?: boolean;
  yLabelName: string;
  yDomainRange: number[];
  yTicks: number;
}

export enum GROWTH_WEIGHT_TYPE {
  Birth = "Birth weight",
  Admission = "Admission weight",
}
