import { createAction, props } from '@ngrx/store';
import { Protocol } from '../../models/protocol/Protocol.model';

export const submitEditProtocolForm = createAction(
  '[Edit Protocol Page] Submit Edit Protocol Form',
  props<{ id: string, form: Protocol, file: File | null, category: string }>()
);

export const deleteProtocol = createAction(
  '[Edit Protocol Page] Delete Protocol',
  props<{ id: string }>()
);
