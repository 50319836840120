import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AISocketService } from "../../services/socket.service";
import { Topic } from "../../enums/topic.enum";
import { NotificationType } from "../../enums/notificationtype.enum";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: "app-notification-toaster",
  templateUrl: "./notification-toaster.component.html",
  styleUrls: ["./notification-toaster.component.scss"],
})
export class NotificationToasterComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private currentUser: any;
  public activeToasters: any = [];
  public notifications = NotificationType; // expose the enum to the template

  constructor(
    private socketService: AISocketService,
    private toasterService: ToasterService
  ) {
    // clear audio stored in local storage after refresh
    localStorage.removeItem("playedAudio");
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.subscription = this.socketService
      .subscribeToTopic(Topic.setAlertToaster)
      .subscribe((message) => {
        if (message) {
          // reset active toasters
          this.toasterService.resetAllToasters();
          this.activeToasters = message["open_toasters"];
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeToasterEvent(toasterData: any) {
    // local cleanup of toasters
    const newActiveToasters = [];
    this.activeToasters.forEach((toaster) => {
      if (toaster["_id"] !== toasterData["_id"]) {
        newActiveToasters.push(toaster);
      }
    });
    this.activeToasters = newActiveToasters;
    // send to server if connection is open
    toasterData["role"] = this.currentUser["role"];
    toasterData["email"] = this.currentUser["email"];
    this.socketService.send(Topic.closeAlertToaster, toasterData);
  }

  endCodeBlueEvent(toasterData: any): void {
    toasterData["completed_by_role"] = this.currentUser["role"];
    toasterData["completed_by_email"] = this.currentUser["email"];
    toasterData["completed_by_name"] = this.currentUser["name"];
    this.socketService.send(Topic.completeRalert, toasterData);
  }
}
