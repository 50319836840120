/*
  * NAME: NGX vent blank structural directive
  * PURPOSE: Filters vent table in summary
  * DESCRIPTION: Filters out the blank rows from ventilation settings
  * PARAMS: { name(name of the vent), values(vital values) }
  * RETURNS: boolean
  * USED BY: summary.component.html
  * CREATED DATE: 20/02/2020
  * AUTHOR: Suraj Shenoy
  */

import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[appNgxVentBlank]'
})
export class NgxVentBlankDirective {
  public visible = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set appNgxVentBlank(obj) {
    let ventName = obj.name.name;
    let ventValues = obj.values;

    let notEmpty = false;
    for (let i = 0; i < ventValues.length; i++) {
      const vent = ventValues[i];
      
      if( vent.min.vitals[ventName] ) {
        notEmpty = true;
        break;
      }
    }

    if (notEmpty && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      this.visible = true;
    }else if (!notEmpty && this.visible) {
      this.viewContainer.clear();

      this.visible = false;
    }
  }
}
