<section class="iris-modal-container socket-disconnect-wrapper">
  <div mat-dialog-title class="title">
    <mat-icon>warning</mat-icon>
    Session expired
  </div>

  <mat-dialog-content class="content">
    Your current session has expired due to inactivity. Please refresh the page.
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <small class="message">* This will not affect your data.</small>
    <button mat-flat-button class="button-primary" (click)="refreshPage()">
      Refresh
    </button>
  </mat-dialog-actions>
</section>
