import { uniqBy, forIn, findIndex } from "lodash-es";
import { Order } from "src/app/models/orders";
import {
  Actions,
  ActionTypes,
} from "src/app/store/actions/order/order-main.actions";
import { OrderApiActionTypes } from "../../actions/order/order-api.actions";
import { OrderPaneActionTypes } from "../../actions/order/order-pane.actions";
import { Order as OrderModel } from "../../../models/Order.model";

export interface State {
  inputTabActive: boolean;
  orders: {
    activeTotal: object;
    active: object;
    inactive: object;
    pta: object;
    pending: object;
    completed: object;
    activeD: object;
  };
  activeProtocols: any;
  form: {
    error: any;
    loading: boolean;
  };
}

const initialState = {
  inputTabActive: false,
  orders: {
    activeTotal: new Order(),
    active: new Order(),
    inactive: new Order(),
    pta: new Order(),
    pending: new Order(),
    completed: new Order(),
    activeD: new Order(),
  },
  activeProtocols: [],
  form: {
    error: null,
    loading: false,
  },
};

export function reducer(
  state = initialState,
  action: Actions | OrderApiActionTypes | OrderPaneActionTypes | any
) {
  let order;
  let orderArr;
  let category;

  switch (action.type) {
    case ActionTypes.openInputTab:
      return { ...state, inputTabActive: true };

    case ActionTypes.closeInputTab:
      return { ...state, inputTabActive: false };

    case OrderPaneActionTypes.AddOrder:
    case OrderPaneActionTypes.UpdateOrder:
      return { ...state, form: { error: null, loading: true } };

    case OrderApiActionTypes.AddOrderSuccess:
    case OrderApiActionTypes.AddOrderFailure:
    case OrderApiActionTypes.UpdateOrderSuccess:
    case OrderApiActionTypes.UpdateOrderFailure:
      return { ...state, form: { error: null, loading: false } };

    case ActionTypes.AddAllOrders:
      let addAllOrders: any = {};

      for (let key in action.orders) {
        const orders = action.orders[key];
        const category = key;
        const result = processAllOrders(category, orders);

        addAllOrders = { ...addAllOrders, ...result };
      }

      return { ...state, orders: addAllOrders };

    case ActionTypes.ordersListed:
      category = action.payload.category;
      let activeProtocols = [];
      let newOrders = { ...state.orders };
      const orders = action.payload.orders;

      if (orders) {
        for (const key in orders) {
          if (orders.hasOwnProperty(key) && orders[key].length > 0) {
            for (const value of orders[key]) {
              if (value.protocol) {
                activeProtocols.push(value.protocol);
              }
            }
          }
        }
      }

      activeProtocols = [...new Set(activeProtocols)];

      if (category !== "active") {
        newOrders[category] = { ...action.payload.orders, activeProtocols };
      } else {
        newOrders["activeTotal"] = {
          ...action.payload.orders,
          activeProtocols,
        };
        newOrders = {
          ...newOrders,
          ...resetActiveOrders(action.payload.orders),
        };
      }

      return { ...state, orders: newOrders };

    case ActionTypes.update:
      order = action.payload;
      category = order.category !== "active" ? order.category : "activeTotal";
      const oldCategory =
        order.oldCategory !== "active" ? order.oldCategory : "activeTotal";
      const orderType = order.type;
      let updateOrder = { ...state.orders };

      if (!oldCategory || category === oldCategory) {
        orderArr = state.orders[category][orderType];
        orderArr = editOrder(orderArr, order);
      } else {
        orderArr = state.orders[category][orderType];
        orderArr.push(order);
        const oldCategoryOrderArr = state.orders[oldCategory][orderType].filter(
          (doc) => doc._id !== order._id
        );
        updateOrder[oldCategory][orderType] = oldCategoryOrderArr;
        updateOrder[oldCategory]["count"] -= 1;
        updateOrder[category]["count"] += 1;
      }

      updateOrder[category][orderType] = orderArr;

      if (category === "activeTotal") {
        updateOrder = {
          ...updateOrder,
          ...resetActiveOrders(state.orders[category]),
        };
      }

      return { ...state, orders: updateOrder };

    case ActionTypes.UpdateOrders:
      // @ts-ignore
      const updateOrders = action.payload;
      let updatedOrders = { ...state.orders };
      category =
        updateOrders[0].category !== "active"
          ? updateOrders[0].category
          : "activeTotal";

      for (const localOrder of updateOrders) {
        orderArr = state.orders[category][localOrder.type];
        updatedOrders[category][localOrder.type] = editOrder(
          orderArr,
          localOrder
        );
      }

      if (category === "activeTotal") {
        updatedOrders = {
          ...updatedOrders,
          ...resetActiveOrders(state.orders[category]),
        };
      }

      return { ...state, orders: updatedOrders };

    case ActionTypes.newOrder:
      order = action.payload;
      category = order.category !== "active" ? order.category : "activeTotal";
      let newOrder = { ...state.orders };
      orderArr = state.orders[category][order.type];

      if (!orderArr) {
        orderArr = [];
      }

      if (findIndex(orderArr, { _id: order._id }) > -1) {
        return { ...state };
      }

      orderArr.push(order);
      newOrder[category]["count"] += 1;
      newOrder[category][order.type] = orderArr;

      if (category === "activeTotal") {
        newOrder = {
          ...newOrder,
          ...resetActiveOrders(state.orders[category]),
        };
      }

      if (order.protocol && order.protocol !== "null") {
        newOrder[category]["activeProtocols"] = [
          ...new Set([
            ...newOrder[category]["activeProtocols"],
            order.protocol,
          ]),
        ];
      }

      return { ...state, orders: newOrder };

    case ActionTypes.AddOrders:
      // @ts-ignore
      const ordersData = action.payload;
      const stateOrders = Object.assign({}, state.orders);

      stateOrders["active"]["count"] += ordersData.count.active;
      stateOrders["activeTotal"]["count"] += ordersData.count.active;
      stateOrders["pending"]["count"] += ordersData.count.pending;

      forIn(ordersData.active, (value, key) => {
        if (value && value.length > 0) {
          stateOrders["active"][key].push(...value);
          stateOrders["activeTotal"][key].push(...value);
        }
      });

      forIn(ordersData.pending, (value, key) => {
        if (value && value.length > 0) {
          stateOrders["pending"][key].push(...value);
        }
      });

      if (
        ordersData &&
        ordersData.protocolName &&
        ordersData.count &&
        ordersData.count.active > 0
      ) {
        stateOrders["activeTotal"]["activeProtocols"] = [
          ...new Set([
            ...stateOrders["activeTotal"]["activeProtocols"],
            ordersData.protocolName,
          ]),
        ];
      }

      if (
        ordersData &&
        ordersData.protocolName &&
        ordersData.count &&
        ordersData.count.pending > 0
      ) {
        stateOrders["pending"]["activeProtocols"] = [
          ...new Set([
            ...stateOrders["pending"]["activeProtocols"],
            ordersData.protocolName,
          ]),
        ];
      }

      return { ...state, orders: stateOrders };

    case ActionTypes.AddRemoveOrders:
      const data = action.payload;
      let aroStateOrders = Object.assign({}, state.orders);
      const aroAddCategory =
        data.addCategory === "active" ? "activeTotal" : data.addCategory;
      const aroRemoveCategory =
        data.removeCategory === "active" ? "activeTotal" : data.removeCategory;

      aroStateOrders[aroAddCategory]["count"] += data.count;
      aroStateOrders[aroRemoveCategory]["count"] -= data.count;
      aroStateOrders[aroRemoveCategory]["activeProtocols"] = aroStateOrders[
        aroRemoveCategory
      ]["activeProtocols"].filter((proto) => proto !== data.protocolName);
      aroStateOrders[aroAddCategory]["activeProtocols"] = [
        ...new Set([
          ...aroStateOrders[aroAddCategory]["activeProtocols"],
          data.protocolName,
        ]),
      ];

      for (const localOrder of data.orders) {
        orderArr = aroStateOrders[aroRemoveCategory][localOrder.type];
        aroStateOrders[aroRemoveCategory][localOrder.type] = filterOrder(
          orderArr,
          localOrder._id
        );
        aroStateOrders[aroAddCategory][localOrder.type].push(localOrder);
      }

      if (
        aroAddCategory === "activeTotal" ||
        aroRemoveCategory === "activeTotal"
      ) {
        aroStateOrders = {
          ...aroStateOrders,
          ...resetActiveOrders(aroStateOrders["activeTotal"]),
        };
      }

      return { ...state, orders: aroStateOrders };

    case ActionTypes.remove:
      //order has type category and subId
      order = action.payload;
      category = order.category !== "active" ? order.category : "activeTotal";
      let removeOrders = { ...state.orders };
      orderArr = removeOrders[category][order.type];
      removeOrders[category][order.type] = filterOrder(orderArr, order.subId);
      removeOrders[category]["count"] -= 1;
      let removeOrdersActiveProtocols = [];

      if (category === "activeTotal") {
        removeOrders = {
          ...removeOrders,
          ...resetActiveOrders(removeOrders["activeTotal"]),
        };
      }

      for (let key in removeOrders[category]) {
        if (
          removeOrders[category].hasOwnProperty(key) &&
          key !== "count" &&
          key !== "activeProtocols"
        ) {
          for (const localOrder of removeOrders[category][key]) {
            if (localOrder.protocol) {
              removeOrdersActiveProtocols.push(localOrder.protocol);
            }
          }
        }
      }

      removeOrders[category]["activeProtocols"] = [
        ...new Set(removeOrdersActiveProtocols),
      ];

      return { ...state, orders: removeOrders };

    case ActionTypes.RemoveOrders:
      // @ts-ignore
      const removeOrdersData = action.payload;
      category = removeOrdersData[0].category;
      let removeProtocols = [];

      if (category === "active") {
        category = "activeTotal";
      }

      const removeActiveProtocols = state.orders[category]["activeProtocols"];

      for (const localOrder of removeOrdersData) {
        orderArr = state.orders[category][localOrder.type];
        state.orders[category][localOrder.type] = filterOrder(
          orderArr,
          localOrder._id
        );
        state.orders[category]["count"] -= 1;
        removeProtocols.push(localOrder.protocol);
      }

      const differenceProtocols = removeActiveProtocols
        .filter((x) => !removeProtocols.includes(x))
        .concat(
          removeProtocols.filter((x) => !removeActiveProtocols.includes(x))
        );

      state.orders[category]["activeProtocols"] = differenceProtocols;

      if (category === "activeTotal") {
        state.orders = {
          ...state.orders,
          ...resetActiveOrders(state.orders["activeTotal"]),
        };
      } else {
        state.orders = { ...state.orders };
      }

      return { ...state };

    case ActionTypes.resetOrders:
      state.orders["active"] = new Order();
      state.orders["pending"] = new Order();
      state.orders["inactive"] = new Order();
      state.orders["pta"] = new Order();
      state.orders["completed"] = new Order();
      state.orders["activeD"] = new Order();
      state.inputTabActive = false;
      return state;

    case ActionTypes.UpdateMultipleOrders:
      let prevOrders = { ...state.orders },
        currOrders = action.payload;

      for (const category in currOrders) {
        for (const type in currOrders[category]) {
          orderArr = state.orders[category][type];

          currOrders[category][type].forEach((order) => {
            prevOrders[category][type] = editOrder(orderArr, order);
          });
        }
      }

      return { ...state, orders: prevOrders };

    case ActionTypes.SetActiveProtocols:
      return { ...state, activeProtocols: action.protocols };

    case ActionTypes.AddActiveProtocol:
      const existingProtocols = state.activeProtocols
        ? state.activeProtocols
        : [];
      // @ts-ignore
      const newActiveProtocol = action.payload;

      return {
        ...state,
        activeProtocols: uniqBy(
          [...existingProtocols, newActiveProtocol],
          "name"
        ),
      };

    default:
      return state;
  }
}

function editOrder(orderArray: Array<any>, obj: any): any {
  const index = orderArray.findIndex((element) => {
    return element._id === obj._id;
  });
  orderArray[index] = obj;

  return [...orderArray];
}

function filterOrder(orderArray: Array<any>, subId: string) {
  return orderArray.filter((item) => {
    return item._id != subId;
  });
}

function resetActiveOrders(orders) {
  let result = {};
  let keys = Object.keys(orders);
  result["active"] = new Order();
  result["activeD"] = new Order();
  keys = keys.filter((elem) => elem !== "count" && elem !== "activeProtocols");

  keys.map((key) => {
    let orderArr: Array<Object> = orders[key];
    orderArr.map((order) => {
      const type = key;
      if (order["toBeDiscarded"]) {
        result["activeD"][type].push(order);
        result["activeD"]["count"] += 1;
      } else {
        result["active"][type].push(order);
        result["active"]["count"] += 1;
      }
    });
  });

  return result;
  /*  let orders = state.orders['activeTotal'];
    let keys = Object.keys(orders);
    state.orders['active'] = new Order();
    state.orders['activeD'] = new Order();
    keys = keys.filter(elem => elem !== 'count' && elem !== 'activeProtocols');
    keys.map(key => {
      let orderArr: Array<Object> = orders[key];
      orderArr.map(order => {
        let type = key;
        if (order['toBeDiscarded']) {
          state.orders['activeD'][type].push(order);
          state.orders['activeD']['count'] += 1;
        } else {
          state.orders['active'][type].push(order);
          state.orders['active']['count'] += 1;
        }
      });
    });
    return state;*/
}

export const getInputTabActive = (state) => state.inputTabActive;

export const getOrders = (state) => state.orders;

export const getActiveProtocols = (state) => state.activeProtocols;

export const getOrderForm = (state) => state.form;

function processAllOrders(category: string, orders: any): any {
  let activeProtocols = [];
  let count =
    orders["bloods"].length +
    orders["communications"].length +
    orders["diets"].length +
    orders["labs"].length +
    orders["medications"].length +
    orders["procedures"].length;

  if (orders["vents"]) {
    count += orders["vents"].length;
  }

  let newOrders = {};

  if (orders) {
    for (const key in orders) {
      if (orders.hasOwnProperty(key) && orders[key].length > 0) {
        for (const value of orders[key]) {
          if (value.protocol) {
            activeProtocols.push(value.protocol);
          }
        }
      }
    }
  }

  activeProtocols = [...new Set(activeProtocols)];

  if (category !== "active") {
    newOrders[category] = { ...orders, activeProtocols };
    newOrders[category]["count"] = count;
  } else {
    newOrders["activeTotal"] = { ...orders, activeProtocols };
    newOrders = { ...newOrders, ...resetActiveOrders(orders) };
    newOrders["activeTotal"]["count"] = count;
  }

  return newOrders;
}

function getActiveProtocolFromOrders(orders: OrderModel[]): string[] {
  if (!Array.isArray(orders)) {
    return [];
  }

  return orders.reduce((res, order) => {
    if (order.protocol) {
      res.push(order.protocol);
    }

    return res;
  }, []);
}
