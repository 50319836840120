<mat-icon
  class="cp-icon"
  svgIcon="cp-team"
  aria-hidden="false"
  aria-label="team icon"
  position="left-center"
  [cpPopover]="tooltip"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
></mat-icon>

<ng-template #tooltip>
  <ng-container *ngIf="!isError; else error">
    <ng-container *ngIf="!loading; else loader">
      <div
        class="assigned-users display-flex align-items-center"
        *ngIf="allAssignedUsers?.length; else noUsers"
      >
        <ng-container *ngFor="let user of allAssignedUsers">
          <img
            [src]="gethospitalLogo(user)"
            class="assigned-users__img"
            alt="avatar logo"
            onerror="this.onerror = null; this.src='assets/icons/hospital_logo.svg'"
          />
          <span
            class="ml cp-color-normal cp-text-bold assigned-users__label cp-text-overflow"
            >{{ user.name }}</span
          >
          <span class="cp-color-light cp-text-light ml">{{ user.role }}</span>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loader>
  <mat-spinner [diameter]="30"></mat-spinner>
</ng-template>

<ng-template #error>
  <span class="error-text">Something went wrong!</span>
</ng-template>

<ng-template #noUsers>
  <span>No users assigned.</span>
</ng-template>
