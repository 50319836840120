import { Injectable } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class RassFormService {
  constructor() {}

  initRassForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      rassScore: new UntypedFormControl(null, Validators.required),
      rassAssessDeliriumYN: new UntypedFormControl(null),
      camicuScore: new UntypedFormControl(null),
      acuteYN: new UntypedFormControl(null),
      fluctYN: new UntypedFormControl(null),
      inattentionYN: new UntypedFormControl(null),
      disorganizedYN: new UntypedFormControl(null),
      timestamp: new UntypedFormControl(
        new Date(new Date().setSeconds(0, 0)),
        Validators.required
      ),
    });
  }
}
