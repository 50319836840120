<div id="notesInput">
  <form class="note" [formGroup]="patientNotesForm">
    <div class="note-main">
      <div formGroupName="noteTypeForm" class="note-main__left-sec">
        <div class="note-main__left-sec--type">
          <mat-form-field [floatLabel]="'always'">
            <mat-select class="select-input" formControlName="formNoteType">
              <mat-option
                (click)="onSelect('type')"
                *ngFor="let formNoteType of noteType"
                [value]="formNoteType"
                [disabled]="formNoteType === 'Select Note Type'"
                >{{ formNoteType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="note-main__left-sec--subtype">
          <mat-form-field
            *ngIf="
              patientNotesForm.controls['noteTypeForm'].get('formNoteType')
                .value != 'Select Note Type'
            "
            [floatLabel]="'always'"
          >
            <mat-select class="select-input" formControlName="formNoteSubType">
              <mat-option
                (click)="onSelect('subType')"
                *ngFor="let formNoteSubType of noteSubType"
                [value]="formNoteSubType"
                >{{ formNoteSubType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="note-main__right-sec">
        <div class="display-flex">
          <app-aira-btn
            class="note-main__right-sec--item"
            *ngIf="!displayForAttestion"
            [patient]="{
              cpmrn: CPMRN,
              encounters: encounters,
              note: {
                noteType:
                  patientNotesForm.controls['noteTypeForm'].get('formNoteType')
                    .value,
                noteSubType:
                  patientNotesForm.controls['noteTypeForm'].get(
                    'formNoteSubType'
                  ).value
              }
            }"
          >
          </app-aira-btn>
          <div *ngIf="editor && !displayForAttestion">
            <button
              mat-flat-button
              (click)="disclaimer()"
              class="button-secondary note-main__right-sec--item"
            >
              Disclaimer
            </button>
          </div>
          <div class="note-main__right-sec--item" *ngIf="!displayForAttestion">
            <button
              [disabled]="!newDraftData"
              (click)="newNote()"
              mat-flat-button
              class="button-secondary"
            >
              New Note
            </button>
          </div>
          <div
            *ngIf="editor && cursorIndex"
            title="Press Ctrl+Alt to turn on mic"
          >
            <button
              [ngClass]="{
                'button-secondary white': !!augnitoBtnActive,
                'button-primary': !augnitoBtnActive
              }"
              appAugnitoButton
              (micOffDialog)="micStatus($event)"
              (augnitoText)="augnitoText($event)"
              (partialText)="partialTextAugnitochk($event)"
              mat-flat-button
            >
              <img
                [src]="
                  !augnitoBtnActive
                    ? '../../../../assets/icons/swara.svg'
                    : '../../../../assets/images/google-mic-seeklogo.com.svg'
                "
                alt="mic"
              />swaᴙa
            </button>
          </div>
        </div>
        <div class="note-main__right-sec--item align-self-end">
          <mat-checkbox
            class="mr-3"
            *ngIf="!!showImpactCases"
            formControlName="impactCase"
            >Impact case</mat-checkbox
          ><ng-template [ngxPermissionsOnly]="['chargeble_note']"
            ><mat-checkbox
              *ngIf="checkProgressOrEventNote && isHospitalNoteChargeable"
              formControlName="chargeable"
              >Chargeable</mat-checkbox
            ></ng-template
          >
        </div>
      </div>
      <div class="note-main--hyper-text">
        <div *ngIf="hyperText.show" id="hyperText" class="col-sm-12">
          {{ hyperText.val }}
        </div>
      </div>
      <div *ngIf="editor && cursorIndex" class="note-main__branding">
        swaᴙa<mat-divider vertical></mat-divider>Powered by Augnito.
      </div>
    </div>
    <ng-container *ngIf="!(showAddenum$ | async) && !displayForAttestion">
      <div class="note-edit" [style.visibility]="editor ? 'visible' : 'hidden'">
        <div class="editor" tabindex="1" *ngIf="templateData?.summaryTemplate">
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 0
              }"
            ></ng-template>
          </div>
          <div
            id="quillSummary"
            [ngClass]="{
              'one-quill': editorClass
            }"
            class="editor__quill"
          >
            <div>
              <quill-editor
                formControlName="formNoteSummary"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteSummary')"
                [modules]="quillConfig"
              ></quill-editor>
            </div>
          </div>
        </div>
        <div
          class="editor"
          tabindex="2"
          *ngIf="templateData?.diagnosisTemplate"
        >
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 1
              }"
            ></ng-template>
            <!-- <div>Copy</div> -->
          </div>
          <div id="quillDiagnosis" class="editor__quill">
            <div>
              <quill-editor
                formControlName="formNoteDiagnosis"
                [modules]="quillConfig"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteDiagnosis')"
              ></quill-editor>
            </div>
          </div>
        </div>
        <div
          class="editor"
          tabindex="3"
          *ngIf="templateData?.secondaryTemplate"
        >
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 2
              }"
            ></ng-template>
            <!-- <div>Copy</div> -->
          </div>
          <div id="quillAction" class="editor__quill">
            <div>
              <quill-editor
                formControlName="formNoteActionList"
                [modules]="quillConfig"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteActionList')"
              ></quill-editor>
            </div>
          </div>
        </div>
        <div
          class="editor"
          tabindex="4"
          *ngIf="templateData?.medicationTemplate"
        >
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 3
              }"
            ></ng-template>
            <!-- <div>Copy</div> -->
          </div>
          <div id="quillDiagnosis" class="editor__quill">
            <div>
              <quill-editor
                formControlName="formNoteMedication"
                [modules]="quillConfig"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteMedication')"
              ></quill-editor>
            </div>
          </div>
        </div>
        <div class="editor" tabindex="5" *ngIf="templateData?.reportTemplate">
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 4
              }"
            ></ng-template>
            <!-- <div>Copy</div> -->
          </div>
          <div id="quillDiagnosis" class="editor__quill">
            <div>
              <quill-editor
                formControlName="formNoteReports"
                [modules]="quillConfig"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteReports')"
              ></quill-editor>
            </div>
          </div>
        </div>
        <div class="editor" tabindex="7" *ngIf="templateData?.tertiaryTemplate">
          <div class="editor__heading">
            <ng-template
              [ngTemplateOutlet]="editorHeading"
              [ngTemplateOutletContext]="{
                $implicit: patientNotesForm.get('noteTypeForm').value,
                idx: 5
              }"
            ></ng-template>
            <!-- <div>Copy</div> -->
          </div>
          <div id="quillToDo" class="editor__quill">
            <div>
              <quill-editor
                formControlName="formNoteToDoList"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, 'formNoteToDoList')"
                [modules]="quillConfig"
              ></quill-editor>
            </div>
          </div>
        </div>
        <ng-template [ngTemplateOutlet]="addendumArray"></ng-template>
      </div>
    </ng-container>
    <ng-container
      *ngIf="!!(showAddenum$ | async) || !!displayForAttestion"
      [ngTemplateOutlet]="addendum"
    >
    </ng-container>
    <ng-container *ngIf="!!displayForAttestion && !(showAddenum$ | async)">
      <div class="note-edit">
        <ng-container [ngTemplateOutlet]="addendumArray"></ng-container>
      </div>
      <div class="note-edit">
        <div class="editor__heading">
          <div>Physician note</div>
          <!-- <div>Copy</div> -->
        </div>
        <div class="editor">
          <div id="quillAddendum" class="editor__quill">
            <quill-editor
              [formControl]="attestationStatusMsg"
              (onContentChanged)="contentchanged($event)"
              (onSelectionChanged)="onFocus($event, 'attestationStatusMsg')"
              [modules]="quillConfig"
            ></quill-editor>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showAddenum$ | async">
      <div class="note-edit">
        <div class="editor__heading">
          <div>Addendum</div>
          <!-- <div>Copy</div> -->
        </div>
        <div class="editor" formGroupName="formNoteAddendum">
          <div id="quillAddendum" class="editor__quill">
            <quill-editor
              (onContentChanged)="contentchanged($event)"
              (onSelectionChanged)="onFocus($event, 'formNoteAddendum')"
              formControlName="note"
              [modules]="quillConfig"
            ></quill-editor>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="note-footer" *ngIf="editor">
      <div class="note-footer--error-section">
        <div class="display-flex justify-content-between">
          <h6>
            <i *ngIf="lastUpdatedAt"
              >Last saved at:
              {{ lastUpdatedAt | timezone : "DD/MM/yyyy, HH:mm:ss  " }}
              <cp-timezone-label
                *ngIf="hospitatalTimzoneDetails"
                [timeZoneDetail]="hospitatalTimzoneDetails"
              ></cp-timezone-label
            ></i>
          </h6>
        </div>
        <div *ngIf="!!disableBtn()" class="error-text">{{ ERROR_MSG }}</div>
      </div>
      <div *ngIf="!displayForAttestion" class="note-footer--buttons">
        <div class="note-footer--buttons__delete">
          <button
            [disabled]="loading$ | async"
            mat-flat-button
            class="button-tertiary"
            (click)="onDelete()"
          >
            Delete
          </button>
        </div>
        <div class="note-footer--buttons__item">
          <button
            mat-flat-button
            [disabled]="disableBtn() || (loading$ | async)"
            class="button-secondary"
            (click)="onSubmit('pend')"
          >
            Pend<app-loader
              [buttonLoader]="true"
              *ngIf="(loading$ | async) && loaderActive === 'pend'"
            ></app-loader>
          </button>
        </div>
        <div
          *ngxPermissionsOnly="['attest_notes']"
          class="note-footer--buttons__item"
        >
          <button
            *ngIf="
              attestRequired
                .get(currentUser.role)
                .includes(
                  patientNotesForm.controls.noteTypeForm.get('formNoteType')
                    .value
                )
            "
            mat-flat-button
            [disabled]="disableBtn() || (loading$ | async)"
            class="button-secondary"
            (click)="requestAttest()"
            popoverTrigger="hover"
            cpPopover="You automatically sign the note when you request for an attestation"
            position="top-center"
          >
            Request attest
          </button>
        </div>
        <div class="note-footer--buttons__item">
          <button
            *ngIf="
              signPermission
                .get(currentUser.role)
                .includes(
                  patientNotesForm.controls.noteTypeForm.get('formNoteType')
                    .value
                )
            "
            mat-flat-button
            [disabled]="disableBtn() || (loading$ | async)"
            class="button-primary"
            (click)="onSubmit('sign')"
          >
            Sign<app-loader
              [buttonLoader]="true"
              *ngIf="(loading$ | async) && loaderActive === 'sign'"
            ></app-loader>
          </button>
        </div>
      </div>
      <div *ngIf="!!displayForAttestion" class="note-footer--buttons">
        <div class="note-footer--buttons__delete">
          <button
            [disabled]="loading$ | async"
            mat-flat-button
            class="button-tertiary"
            (click)="onDelete()"
          >
            Delete
          </button>
        </div>
        <div class="note-footer--buttons__item">
          <button
            [disabled]="
              (loading$ | async) ||
              !(attestationStatusMsg.value | htmlTagRemoveRegex) ||
              attestionStatus === 'accepted'
            "
            mat-flat-button
            class="button-primary reject mr-1"
            (click)="submitAttest('rejected')"
          >
            Reject<app-loader
              [buttonLoader]="true"
              *ngIf="(loading$ | async) && loaderActive === 'reject'"
            ></app-loader>
          </button>
        </div>
        <div class="note-footer--buttons__item">
          <button
            mat-flat-button
            [disabled]="disableBtn() || (loading$ | async)"
            class="button-primary"
            (click)="submitAttest('accepted')"
          >
            Attest<app-loader
              [buttonLoader]="true"
              *ngIf="(loading$ | async) && loaderActive === 'accept'"
            ></app-loader>
          </button>
        </div>
      </div>
      <div style="padding: 2rem">
        <!-- the place holder for quill toolbar -->
      </div>
    </div>
  </form>
</div>

<ng-template #addendum>
  <div
    class="note-edit-addendum"
    [ngClass]="
      (showAddenum$ | async) || !!displayForAttestion
        ? 'note-edit-addendum add'
        : ''
    "
  >
    <div [innerHtml]="patientNotesForm.get('formNoteSummary').value"></div>
    <div [innerHtml]="patientNotesForm.get('formNoteDiagnosis').value"></div>
    <div [innerHtml]="patientNotesForm.get('formNoteActionList').value"></div>
    <div [innerHtml]="patientNotesForm.get('formNoteMedication').value"></div>
    <div [innerHtml]="patientNotesForm.get('formNoteReports').value"></div>
    <div [innerHtml]="patientNotesForm.get('formNoteToDoList').value"></div>
    <ng-template [ngIf]="!!(showAddenum$ | async)">
      <div
        *ngIf="attestationStatusMsg?.value"
        class="note-edit-addendum__section"
      >
        <mat-divider></mat-divider>
        <div class="note-edit-addendum__section__heading">
          <div>Physician note</div>
        </div>
        <div [innerHtml]="attestationStatusMsg.value"></div>
      </div>
    </ng-template>
    <ng-template [ngIf]="!!(showAddenum$ | async)">
      <div
        class="note-edit-addendum__section"
        *ngFor="let addendumnNote of addendumArrays"
      >
        <mat-divider></mat-divider>
        <div class="note-edit-addendum__section__heading">
          <div>{{ addendumnNote.value.name }}</div>
        </div>
        <div [innerHtml]="addendumnNote.value.note"></div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #addendumArray>
  <form class="note" [formGroup]="patientNotesForm">
    <div class="editor" tabindex="8" *ngIf="addendumArrays.length > 0">
      <div class="editor__heading">
        <div>Addendum notes</div>
        <!-- <div>Copy</div> -->
      </div>
      <div id="quillAddendum" class="editor__quill">
        <div formArrayName="formNoteAddendumArray">
          <ng-container
            *ngFor="let addendumnNote of addendumArrays; let i = index"
          >
            <div [formGroupName]="i">
              <quill-editor
                [disable]="true"
                (onContentChanged)="contentchanged($event)"
                (onSelectionChanged)="onFocus($event, i)"
                formControlName="note"
                [modules]="quillConfig"
              ></quill-editor>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #editorHeading let-noteTypeForm let-position="idx">
  <div>
    {{
      header(noteTypeForm.formNoteType)(noteTypeForm.formNoteSubType)[position]
    }}
  </div>
</ng-template>
