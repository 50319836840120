import { SafeResourceUrl } from "@angular/platform-browser";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import * as moment from "moment";
import { LabOrderFE } from "../../../../models/Lab.model";
import { MedOrderFE } from "../../../../models/Med.model";
import { DietOrderFE } from "../../../../models/Diet.model";
import { BloodOrderFE } from "../../../../models/Blood.model";
import { FileService } from "../../../../services/file.service";
import { OrderService } from "../../../../services/order.service";
import { Protocol } from "../../../../models/protocol/Protocol.model";
import { ProcedureOrderFE } from "../../../../models/Procedure.model";
import { OrderFormService } from "../../../../services/order-form.service";
import { CommunicationOrderFE } from "../../../../models/Communication.model";
import { PlaceProtocolForm } from "../../../../models/protocol/PlaceProtocolForm.model";
import {
  bloodConfig,
  commConfig,
  dietConfig,
  labConfig,
  medConfig,
  procedureConfig,
  ventConfig,
} from "../../protocol-form.config";
import { VentFormService } from "../../../../vital/services/vent-form.service";
import { select, Store } from "@ngrx/store";
import * as fromPatientHeader from "../../../../store/reducers/patient-chart/patient-header";
import * as fromHospitals from "../../../../store/reducers/hospitals";
import { combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { padStart } from "lodash-es";
import { MatExpansionPanel } from "@angular/material/expansion";
import { TimezoneService } from "src/app/services/timezone.service";

@Component({
  selector: "app-protocol-form",
  templateUrl: "./protocol-form.component.html",
  styleUrls: ["./protocol-form.component.scss"],
})
export class ProtocolFormComponent implements OnInit, OnChanges {
  @Input() protocol: Protocol | null;
  @Input() user: any;
  @Input() currentPatient?: any;
  @Input() loading?: boolean;
  @Input() prescribedOrders: any;

  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitted: EventEmitter<PlaceProtocolForm> =
    new EventEmitter<PlaceProtocolForm>();
  showBedsideForm = false;
  @ViewChild("accordionForm") public accordionForm: any;
  //for getting expansionpanel
  @ViewChildren("expPanels")
  expPanels: QueryList<MatExpansionPanel>;
  public patientHospitalUnitName$ = this._store.pipe(
    select(fromPatientHeader.getPatientHospitalAndUnitName)
  );
  public hospitals$ = this._store.pipe(select(fromHospitals.getHospitals));

  public canPlaceBedsideOrder$ = this._store.pipe(
    select(fromPatientHeader.canPlaceBedsideOrder)
  );

  public form: UntypedFormGroup;

  public selectAllPreset = false;

  // presetsMap contains all presets
  public presetsMap = new Map([]);
  public medSchduleArr: any = [];
  public selectedPresets = new Map([]);
  presetArray = [];
  confirmedPresets = new Map([]);
  private _tz = inject(TimezoneService);
  constructor(
    private orderFormService: OrderFormService,
    private dialog: MatDialog,
    private orderService: OrderService,
    private fileService: FileService,
    private _store: Store<any>,
    private _ventFormService: VentFormService
  ) {}

  ngOnInit() {
    this.initializeValues();
    // same code present in order-form.component.ts, refactor to one function
    if (this.patientHospitalUnitName$ && this.hospitals$) {
      combineLatest(this.patientHospitalUnitName$, this.hospitals$)
        .pipe(take(1))
        .subscribe((data) => {
          const patientData = data[0];
          const hospitals = data[1];

          const hospital = hospitals.filter(
            (hosp) => hosp.name === patientData.hospitalName
          ) as any;

          if (
            hospital.length > 0 &&
            hospital[0].units &&
            hospital[0].units.length > 0
          ) {
            const unit = hospital[0].units.filter(
              (unitData) => unitData.name === patientData.unitName
            );
            this.showBedsideForm =
              unit[0] && unit[0].cpManaged ? !unit[0].cpManaged : true;
          }
        });
    }
  }

  public initializeValues() {
    this.form = this.initForm(this.protocol);
    this.medSchduleArr = this.getMedSchedule();
  }

  getMedSchedule() {
    return this.form.value?.presets.map((medSchedule) => {
      const { numberOfDoses, frequency, startTime } = medSchedule;
      return this.generateMedSchedule(startTime, frequency, numberOfDoses);
    });
  }

  private generateMedSchedule(startTime, frequency, dose): void {
    let result = null;
    if (
      frequency?.fType === "every" &&
      this.orderFormService.validStartTime(startTime) &&
      this.orderFormService.validEveryFrequency(frequency)
    ) {
      result = this.orderFormService.generateMedSchedule(
        startTime,
        frequency,
        dose
      );
    } else if (
      frequency?.fType === "once" &&
      this.orderFormService.validStartTime(startTime)
    ) {
      result = this.orderFormService.generateMedSchedule(startTime, null, 1);
    }
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.protocol && !changes.protocol.firstChange) {
      this.initializeValues();
    }
  }

  /*
   * NAME: presets
   * PURPOSE: accessor for presets input value
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: FormArray
   * USED BY: protocol-form.component.html
   * CREATED DATE: 3/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  get presets(): UntypedFormArray {
    return this.form.get("presets") as UntypedFormArray;
  }

  /*
   * NAME: initForm
   * PURPOSE: initializes the form
   * DESCRIPTION: creates a form group with protocol presets.
   * PARAMS:
   *   protocol:Protocol - protocol which contains preset.
   * RETURNS:
   *   FormArray - Newly create form array with presets in it.
   * USED BY: RADAR
   * CREATED DATE: 3/10/2019
   * AUTHOR: Gunjit
   */
  private initForm(protocol: Protocol): UntypedFormGroup {
    const presets = new UntypedFormArray([]);
    this.presetsMap = new Map([]);
    this.selectedPresets = new Map([]);

    if (protocol && protocol.presets && protocol.presets.length > 0) {
      for (const preset of this.protocol.presets) {
        if (!preset.type) {
          continue;
        }

        if (preset.required) {
          this.selectedPresets.set(preset._preset, {});
          this.selectedPresets.set(
            "required" + preset._preset,
            preset.required
          );
        }

        if (!preset.required && preset.preSelect) {
          this.selectedPresets.set(preset._preset, {});
        }

        const config = this.getConfig(preset.type);

        let formGroup;

        if (preset.type !== "vents") {
          formGroup = this.orderFormService.getPresetForm(
            preset.view,
            preset.type,
            config
          );
        } else {
          formGroup = this._ventFormService.initForm(config, preset.view);
        }

        const presetName =
          preset.view && preset.view.presetName ? preset.view.presetName : null;
        // const brandName=preset.view && preset.view.brandName ? preset.view.brandName : null;
        //  formGroup.addControl("brandName",new FormControl(brandName))
        formGroup.addControl("type", new UntypedFormControl(preset.type));
        formGroup.addControl(
          "_orderable",
          new UntypedFormControl(preset._orderable)
        );
        formGroup.addControl("preset", new UntypedFormControl(presetName));

        const today = this._tz.getCurrentTimeObj();
        const hour = padStart(today.hours(), 2, 0);
        const minute = padStart(today.minutes(), 2, 0);

        formGroup.addControl(
          "atcClass",
          new UntypedFormControl(preset["atcClass"])
        );
        formGroup.patchValue({
          startTime: { date: today, hour, minute },
        });

        if (
          formGroup.get("startTime")?.value &&
          formGroup.get("noOfDays")?.value &&
          formGroup.get("numberOfDoses")?.value
        ) {
          let endTime = this.orderFormService.calculateEndTime(
            formGroup.get("frequency").value,
            formGroup.get("startTime").value,
            formGroup.get("numberOfDoses").value,
            formGroup.get("noOfDays").value
          );

          if (endTime) {
            formGroup.patchValue({
              endTime: {
                date: endTime?.toDate(),
                hour: padStart(endTime?.hours(), 2, 0),
                minute: padStart(endTime?.minutes(), 2, 0),
              },
            });
          }
        }

        this.presetsMap.set(preset._preset, {
          ...preset,
          view: null,
          presetName: preset.view.presetName,
        });

        presets.push(formGroup);
      }
    }
    return new UntypedFormGroup({ presets });
  }

  /*
   * NAME: getConfig
   * PURPOSE: Returns form config
   * DESCRIPTION: Returns a form config based on type
   * PARAMS:
   *   presetType:string - type of preset by which config is fetched
   * RETURNS: loaded config
   * USED BY: RADAR
   * CREATED DATE: 3/10/2019
   * AUTHOR: Gunjit
   */
  private getConfig(presetType: string): object {
    const configMap = new Map([
      ["blood", { config: bloodConfig }],
      ["comm", { config: commConfig }],
      ["diet", { config: dietConfig }],
      ["lab", { config: labConfig }],
      ["med", { config: medConfig }],
      ["procedure", { config: procedureConfig }],
      ["vents", { config: ventConfig }],
    ]);

    return configMap.get(presetType).config;
  }

  /*
   * NAME: attachValues
   * PURPOSE: attaches values to each preset in protocol form
   * DESCRIPTION: attaches these values:
   *   - endTime & startTime - transform theses values using transformTime
   *   - type - type of preset (med, blood)
   *   - signed - name of the current user (doc. name, nurse name)
   *   - state - red (used for communicating orders)
   *   - category - pending, active, etc.
   *   - protocol - name of protocol which ther are from
   * PARAMS: value:object - med, blood, etc.
   * RETURNS: updated protocol form value
   * USED BY: onSubmit()
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  private attachValues(
    value:
      | BloodOrderFE
      | CommunicationOrderFE
      | DietOrderFE
      | LabOrderFE
      | MedOrderFE
      | ProcedureOrderFE
      | any
  ): any {
    let endTime = null;
    const startTime = this.orderFormService.transformTime(
      value.startTime.date,
      value.startTime.hour,
      value.startTime.minute
    );
    if (value.endTime && value.endTime.date) {
      endTime = this.orderFormService.transformTime(
        value.endTime.date,
        value.endTime.hour,
        value.endTime.minute
      );
    }

    const defaultValues = {
      type: this.orderService.convertNewTypeToOldType(value.type),
      signed: this.orderFormService.getOrderSigned(
        this.user.name,
        this.user.title
      ),
      state: "red",
      category: this.orderFormService.getOrderCategory(
        this.user.role,
        this.form.value
      ),
      protocol: this.protocol.name,
    };

    return { ...value, startTime, endTime, ...defaultValues };
  }

  /*
   * NAME: detachValues
   * PURPOSE: detachValues from presets in protocol form.
   * DESCRIPTION: removal happens with two keys:
   *   - conditionalKeys:  these keys are only remove if they are empty
   *   - removalKeys:  these keys are always removed, whether it is empty or not
   * PARAMS: value:object - protocol form value
   * RETURNS: updated protocol form value
   * USED BY: onSubmit()
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  private detachValues(value): any {
    const conditionalKeys = ["endTime"];
    const removalKeys = ["everyFreRadio"];

    const newValue = { ...value };
    Object.keys(newValue).forEach((key) => {
      if (conditionalKeys.includes(key) && newValue[key] == null) {
        delete newValue[key];
      }

      if (removalKeys.includes(key)) {
        delete newValue[key];
      }
    });

    return newValue;
  }

  /*
   * NAME: checkFormInvalid
   * PURPOSE: returns true if selected forms are invalid
   * DESCRIPTION:
   * RETURNS: boolean(true/false)
   * USED BY: protocol-form.component.html, checkFormInvalid()
   * CREATED DATE: 31/01/2022
   * AUTHOR: Gokul Pratheep
   */
  public checkFormInvalid(): boolean {
    if (this.selectedPresets.size == 0) return;
    const presetForms = this.form.get("presets") as UntypedFormArray;
    if (!presetForms) return;
    const invalidForms = presetForms?.controls.filter(
      (preset) =>
        this.checkIfPresetIsSelected(preset?.value?._id) && preset?.invalid
    );
    return invalidForms.length > 0;
  }

  /*
   * NAME: getPreset
   * PURPOSE: returns preset from presetMap by id
   * DESCRIPTION:
   * PARAMS: id:string - preste _id
   * RETURNS: preset data - (med, blood, lab, etc.)
   * USED BY: protocol-form.component.html, getPresetType()
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  getPreset(id: string): any {
    return this.presetsMap.get(id);
  }

  /*
   * NAME: getPrescribed
   * PURPOSE: returns prescribed data
   * DESCRIPTION:
   * PARAMS: id:string
   * RETURNS: prescribed data
   * CREATED DATE: 19/01/2023
   * AUTHOR: Gokul Pratheep
   */
  getPrescribed(ordername: string): any {
    if (!this.prescribedOrders) return null;
    return this.prescribedOrders[ordername];
  }

  /*
   * NAME: getPresetType
   * PURPOSE: return preset type (med, blood, etc.)
   * DESCRIPTION: fetches the preset from presetMap using id,
   *   then returns the type. If type is not present return unknown
   * PARAMS: id:string - preset _id
   * RETURNS: string - preset type
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  getPresetType(id: string): string {
    const preset = this.getPreset(id);

    return preset && preset.type ? preset.type : "unknown";
  }

  /*
   * NAME: removePreset
   * PURPOSE: remove preset from protocol
   * DESCRIPTION: removes a preset according to index and
   *   also removes the preset from presetMap
   * PARAMS:
   *   - id:string     - preset id
   *   - index:number  - index of preset in form
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  removePreset(id: string, index: number): void {
    this.presets.removeAt(index);
    this.presetsMap.delete(id);
  }

  /*
   * NAME: open
   * PURPOSE: Opens the modal
   * DESCRIPTION:
   * PARAMS: content:string - name of modal (template variable eg. #modal)
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  open(content): void {
    if (this.accordionForm) {
      this.accordionForm.collapseAll();
    }
    this.dialog.open(content, {
      width: "600px",
      autoFocus: false,
      disableClose: true,
    });
  }

  /*
   * NAME: getIconClass
   * PURPOSE: Returns the class for icon for view
   * DESCRIPTION: Used for displaying icon colors.
   * PARAMS: type:string - type of order (med, lab, blood)
   * RETURNS: string - css class
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  getIconClass(type: string): string {
    return (
      this.orderService.getIconClassForOrder(type) +
      " protocol-accordion__order-icon"
    );
  }

  getIcon(type: string): any {
    return `assets/icons/${this.orderService.getIconForOrder(type)}.svg`;
  }

  /*
   * NAME: onSubmit
   * PURPOSE: Submits the protocol form with data
   * DESCRIPTION: Before firing event, data is modified by:
   *   - attaching few default values (signed by, category, protocol, etc.)
   *   - also detaches few values (end time, everyFreRadio)
   * PARAMS: void
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  onSubmit(): void {
    const presets = this.form
      .getRawValue()
      .presets.filter((preset) => this.checkIfConfirmSelected(preset._id))
      .map((preset) => {
        if (preset.type === "vents") {
          preset = this._ventFormService.transformVentFormToVentOrder(preset);
          preset = { ...preset, type: "vents" };
        }

        if (preset.startNow) {
          let startTime = this.orderFormService.transformTime(
            preset.startTime.date,
            preset.startTime.hour,
            preset.startTime.minute
          );

          const secounds = this._tz
            .getCurrentTimeObj()
            .startOf("m")
            .diff(
              this._tz.transformIntoTimezoneObj(startTime).startOf("m"),
              "s",
              true
            );
          const now = this._tz.getCurrentTimeObj();

          if (preset?.skipSchedule?.length) {
            preset.skipSchedule[0].timeStamp = now.toDate();
          }

          preset.startTime = {
            date: now.toDate(),
            hour: padStart(now.hours(), 2, 0),
            minute: padStart(now.minutes(), 2, 0),
          };

          if (
            preset?.endTime?.date ||
            preset?.endTime?.hour ||
            preset?.endTime?.minute
          ) {
            let endTime = this.orderFormService.transformTime(
              preset.endTime.date,
              preset.endTime.hour,
              preset.endTime.minute
            );

            endTime = this._tz
              .transformIntoTimezoneObj(endTime)
              .add(secounds, "s")
              .toDate();

            preset.endTime = {
              date: endTime,
              hour: padStart(endTime.getHours(), 2, 0),
              minute: padStart(endTime.getMinutes(), 2, 0),
            };
          }
        }

        const afterAttached = this.attachValues(preset);
        return this.detachValues(afterAttached);
      });

    console.log(presets);
    this.submitted.emit({ presets });
  }

  /*
   * NAME: onCancel
   * PURPOSE: Emits cancel event
   * DESCRIPTION: Emits cancel event on click
   * PARAMS: type:string - name of modal
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 11/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  onCancel(type: string): void {
    this.cancel.emit(type);
  }

  /*
   * NAME: openFileModal
   * PURPOSE: opens up modal
   * DESCRIPTION:
   * PARAMS: modal:any - template reference variable mentioned in html
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  openFileModal(modal: any, data): void {
    let url: SafeResourceUrl;
    if (this.protocol?.file?.data)
      url = this.getFileData(this.protocol.file.data);

    // open the pdf in new window
    window.open(data, "", "left=500, top=100, width=1000, height=800");

    // this.dialog.open(modal, {
    //   width: "1050px",
    //   disableClose: true,
    // });
  }

  /*
   * NAME: getFileData
   * PURPOSE: calls fileService getFileData to get file data
   * DESCRIPTION:
   * PARAMS: data:string - file data encoded in base64
   * RETURNS: SafeResourceUrl - bypassed security check url
   * USED BY: protocol-form.component.html
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getFileData(data: string): SafeResourceUrl {
    return this.fileService.getFileData(data);
  }

  /*
   * NAME: checkIfPresetIsSelected
   * PURPOSE: checks if given preset is selected
   * DESCRIPTION:
   * PARAMS: presetId:string - id of the preset
   * RETURNS: boolean - true if it is selected
   * USED BY: protocol-form-component.html. onSubmit()
   * CREATED DATE: 05/02/20
   * AUTHOR: Gunjit Agrawal
   */
  checkIfPresetIsSelected(presetId: string): boolean {
    return this.selectedPresets.has(presetId);
  }

  /*
   * NAME: checkIfConfirmSelected
   * PURPOSE: checks if given preset is selected to confirm
   * DESCRIPTION:
   * PARAMS: presetId:string - id of the preset
   * RETURNS: boolean - true if it is selected
   * USED BY: protocol-form-component.html. onSubmit()
   * CREATED DATE: 05/12/22
   * AUTHOR: Gokul Pratheep
   */
  checkIfConfirmSelected(presetId: string): boolean {
    return this.confirmedPresets.has(presetId);
  }

  /*
   * NAME: toggleSelectedPreset
   * PURPOSE: adds preset to preset map if it is selected
   * DESCRIPTION: also updates selectAllPresets.
   * PARAMS:
   *   - checked:boolean - true if it is checked
   *   - presetId:string - id of the preset
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 05/02/20
   * AUTHOR: Gunjit Agrawal
   */
  toggleSelectedPreset(checked: boolean, presetId: string, i): void {
    if (checked) {
      this.expPanels.toArray()[i].expanded = true;
      this.selectedPresets.set(presetId, {});
    } else {
      this.expPanels.toArray()[i].expanded = false;
      this.selectedPresets.delete(presetId);
    }

    this.selectAllPreset = this.presetsMap.size === this.selectedPresets.size;
  }

  toggleConfirmed(checked: boolean, presetId: string): void {
    if (checked) {
      this.confirmedPresets.set(presetId, {});
    } else {
      this.confirmedPresets.delete(presetId);
    }
  }

  /*
   * NAME: selectAllPresets
   * PURPOSE: selects/deselects all presets
   * DESCRIPTION:
   * PARAMS: checked:boolean - true if all presets is selected
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 22/04/20
   * AUTHOR: Gunjit Agrawal
   */
  selectAllPresets(): void {
    for (const val of this.presetsMap.values()) {
      // val is partial ProtocolPreset
      const preset = val as any;

      if (preset.required) {
        continue;
      }

      if (this.selectAllPreset && !this.selectedPresets.has(preset._preset)) {
        this.selectedPresets.set(preset._preset, {});

        continue;
      }

      if (!this.selectAllPreset && this.selectedPresets.has(preset._preset)) {
        this.selectedPresets.delete(preset._preset);
      }
    }
  }
  selectedItems() {
    this.presetArray = [];
    this.confirmedPresets.clear();
    for (let key of this.selectedPresets.keys()) {
      this.form.value.presets.forEach((element) => {
        if (key == element._id) {
          this.presetArray.push(element);
          this.confirmedPresets.set(element._id, {});
        }
      });
    }
  }
}
