import { createAction, props } from "@ngrx/store";
import { Mar, Schedule } from "@mar/models/mar.model";

export const setMarInfusion = createAction(
  "[MAR] Set MAR - Infusion",
  props<{ mar: Mar[] }>()
);

export const upsertMarInfusion = createAction(
  "[MAR] Upsert MAR - Infusion medication",
  props<{ mar: Mar[] }>()
);

export const resetMarInfusion = createAction(
  "[MAR] Reset to initial state - Infusion"
);

export const updateMarInfusionSchedule = createAction(
  "[MAR API] Update MAR Infusion schedule",
  props<{ marId: string; schedule: Schedule }>()
);

export const checkForInfusionCompletion = createAction(
  "[MAR API] check if infusion schedule is completed",
  props<{ cpmrn: string; encounter: number }>()
);
