import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from "rxjs/operators";

import * as fromProtocol from "../reducers";
import { ProtocolApiActions } from "../actions";
import * as fromFile from "../../store/reducers/file";
import { FileApiActions } from "../../store/actions/file";
import { FileService } from "../../services/file.service";
import { S3Service } from "../../services/file/s3.service";
import { ProtocolService } from "../services/protocol.service";
import { GoogleStorageService } from "../../services/file/google-storage.service";
import { ProtocolForm } from "../components/protocol-form/protocol-form.component";
import * as documentsActions from "../../store/actions/patient-chart/documents/documents.actions";

@Injectable()
export class FileEffects {
  constructor(
    private store: Store<fromProtocol.State>,
    private fileStore: Store<fromFile.State>,
    private protocolService: ProtocolService,
    private actions$: Actions,
    private googleStorageService: GoogleStorageService,
    private s3Service: S3Service,
    private fileService: FileService
  ) {}

  /*
   * Old effects used in protocol. Needs to be changed
   * */
  getSignedUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolApiActions.getSignedUrl),
      switchMap(() =>
        this.protocolService.getSignedUrl().pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.getSignedUrlSuccess({ signedRequest: data.data })
          ),
          catchError((error: any) =>
            of(ProtocolApiActions.getSignedUrlFailure({ error: error.message }))
          )
        )
      )
    )
  );

  getSignedUrlSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolApiActions.getSignedUrlSuccess),
      switchMap(({ signedRequest }) =>
        this.store.pipe(select(fromProtocol.getProtocolFormData)).pipe(
          take(1),
          switchMap((data: ProtocolForm) => {
            const file = { ...data.form.file, key: signedRequest.key };
            const protocol = { ...data.form, file };
            const category = data.category;

            if (category === "new") {
              return of(
                ProtocolApiActions.createProtocol({ protocol }),
                ProtocolApiActions.uploadFile({
                  url: signedRequest.url,
                  file: data.file,
                })
              );
            } else if (category === "edit") {
              return of(
                ProtocolApiActions.updateProtocol({ id: data.id, protocol }),
                ProtocolApiActions.uploadFile({
                  url: signedRequest.url,
                  file: data.file,
                })
              );
            }
          })
        )
      )
    )
  );

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolApiActions.uploadFile),
      switchMap(({ url, file }) => {
        const id = Date.now();

        return this.protocolService.uploadFile(url, file).pipe(
          map((progressData: any) => {
            const completed = Math.floor(
              (progressData.loaded / progressData.total) * 100
            );
            const data = {
              id,
              name: file.name,
              loaded: progressData.loaded,
              total: progressData.total,
              completed,
            };

            if (progressData.type !== 4) {
              return ProtocolApiActions.saveFileProgress({ data });
            } else {
              return ProtocolApiActions.uploadFileSuccess({ data });
            }
          })
        );
      })
    )
  );

  // ----------------------------------

  getSignedUrlForFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentsActions.uploadDocument),
      mergeMap(({ file, fileData }) =>
        this.fileService.getSignedUrl(file).pipe(
          take(1),
          switchMap((data: any) => {
            return of(
              FileApiActions.saveFileToStore({ id: file.id, file: fileData }),
              FileApiActions.getSignedUrlSuccess({ file: data.data })
            );
          })
        )
      )
    )
  );

  getSignedUrlForFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileApiActions.getSignedUrlSuccess),
      mergeMap(({ file }) =>
        this.fileStore
          .pipe(select(fromFile.getFileDateEntityById, file.id))
          .pipe(
            take(1),
            switchMap((data: any) =>
              of(
                FileApiActions.uploadFile({
                  url: file.url,
                  file: data.file,
                  id: file.id,
                }),
                FileApiActions.updateFile({
                  id: file.id,
                  data: { key: file.key, name: file.name },
                })
              )
            )
          )
      )
    )
  );

  uploadSignedFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileApiActions.uploadFile),
      mergeMap(({ url, file, id }) => {
        const fileProgressId = Date.now();

        return this.fileService.uploadFile(file, url, file.type).pipe(
          switchMap((progressData: any) => {
            const completed = Math.floor(
              (progressData.loaded / progressData.total) * 100
            );
            const data = {
              id: fileProgressId,
              name: file.name,
              loaded: progressData.loaded,
              total: progressData.total,
              completed,
            };

            if (progressData.type !== 4) {
              return of(FileApiActions.saveFileProgress({ data }));
            } else {
              return of(
                FileApiActions.uploadFileSuccess({ data }),
                FileApiActions.updateFilesUploadedState({ id })
              );
            }
          })
        );
      })
    )
  );

  uploadFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProtocolApiActions.uploadFileSuccess,
        FileApiActions.uploadFileSuccess
      ),
      map(({ data }) => FileApiActions.deleteFileProgress({ id: data.id }))
    )
  );
}
