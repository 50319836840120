import { Injectable } from "@angular/core";
import { usersCount } from "../models/user";
import { Patient, ShiftsEnum } from "../models/patient";
import { Unit } from "../models/hospital";
import { findIndex as lodashFindIndex } from "lodash-es";
import { PatientService } from "src/app/services/patient.service";
import { BehaviorSubject } from "rxjs";
import { OTHER_WORKSPACE } from "../patient-list/patients-list/patients-list.support";
import { WorkSpace } from "../models/workspace.model";

const HOSPITAL_DUMMY_DATA = {
  name: "dummy",
  units: [],
};
@Injectable({
  providedIn: "root",
})
export class PatientListService {
  constructor(private _patientService: PatientService) {}

  private _shiftBehaviourSubject = new BehaviorSubject<ShiftsEnum>(
    ShiftsEnum.ALL_DAY
  );
  public shift$ = this._shiftBehaviourSubject.asObservable();

  setShift(shift: ShiftsEnum) {
    this._shiftBehaviourSubject.next(shift);
  }

  public hasShift(value: WorkSpace[]): boolean {
    return value
      .filter((workspace) => workspace.name !== OTHER_WORKSPACE)
      .every((workspace) => workspace.shift);
  }

  findAssignedNurseAndDocs(units: Unit[]): Promise<{
    rn?: usersCount[];
    docs?: usersCount[];
    np: usersCount[];
    ccn: usersCount[];
    specialist: usersCount[];
    ccaDoc: usersCount[];
  }> {
    return new Promise((resolve, reject) => {
      if (units?.length) {
        let totalAssignedDoctors = [];
        let totalAssignedNurses = [];
        let totalAssignedNP = [];
        let totalAssignedCCN = [];
        let totalAssignedSpeciality = [];
        let totalAssignedCCADoc = [];
        let docDictionary = {};
        let nurseDictionary = {};
        let npDictionary = {};
        let ccnDictionary = {};
        let specialistDictionary = {};
        let ccaDocDictionary = {};

        let count = units.reduce(
          (result, unit: Unit) => {
            totalAssignedDoctors.push(unit.docAssigned);
            totalAssignedNurses.push(unit.rnAssigned);
            totalAssignedNP.push(unit.npAssigned);
            totalAssignedCCN.push(unit.ccrnAssigned);
            totalAssignedSpeciality.push(unit.specialistAssigned);
            totalAssignedCCADoc.push(unit.ccaPhysicianAssigned);

            totalAssignedDoctors.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );
            totalAssignedNurses.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );
            totalAssignedNP.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );
            totalAssignedCCN.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );
            totalAssignedSpeciality.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );
            totalAssignedCCADoc.sort(
              (a, b) =>
                new Date(a.inTime).getTime() - new Date(b.inTime).getTime()
            );

            // capture updated names
            for (let index = 0; index < totalAssignedDoctors.length; index++) {
              docDictionary[totalAssignedDoctors[index]?.email] =
                totalAssignedDoctors[index]?.name;
            }

            for (let index = 0; index < totalAssignedNurses.length; index++) {
              nurseDictionary[totalAssignedNurses[index]?.email] =
                totalAssignedNurses[index]?.name;
            }

            for (let index = 0; index < totalAssignedNP.length; index++) {
              npDictionary[totalAssignedNP[index]?.email] =
                totalAssignedNP[index]?.name;
            }

            for (let index = 0; index < totalAssignedCCN.length; index++) {
              ccnDictionary[totalAssignedCCN[index]?.email] =
                totalAssignedCCN[index]?.name;
            }

            for (
              let index = 0;
              index < totalAssignedSpeciality.length;
              index++
            ) {
              specialistDictionary[totalAssignedSpeciality[index]?.email] =
                totalAssignedSpeciality[index]?.name;
            }

            for (let index = 0; index < totalAssignedCCADoc.length; index++) {
              ccaDocDictionary[totalAssignedCCADoc[index]?.email] =
                totalAssignedCCADoc[index]?.name;
            }

            let nurseLength = unit.rnAssigned;
            if (nurseLength?.email) {
              // check weather nurse is assigned, else add user
              // as different users with same name were breaking, identifying users with thier email
              const index = lodashFindIndex(result.rn, [
                "email",
                unit.rnAssigned.email,
              ]);
              if (index !== -1) {
                // check weather hospital exist,  else add hospital
                const doesHospitalExist = lodashFindIndex(
                  result.rn[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  // if hospital exist, just add the units
                  result.rn[index].hospitals[doesHospitalExist].units.push(
                    unit.name
                  );
                } else {
                  result.rn[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.rn.push({
                  email: unit.rnAssigned.email,
                  count: 0,
                  phone: unit.rnAssigned.phone || "",
                  name: nurseDictionary[unit.rnAssigned.email],
                  // name: unit.ccrnAssigned[unit.ccrnAssigned.length - 1].name, // fetch latest nurse name, after admin changing the name of the nurse.
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            let docLength = unit.docAssigned;
            if (docLength?.email) {
              const index = lodashFindIndex(result.docs, [
                "email",
                unit.docAssigned.email,
              ]);
              if (index !== -1) {
                const doesHospitalExist = lodashFindIndex(
                  result.docs[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  result.docs[index].hospitals[doesHospitalExist].units.push(
                    unit.name
                  );
                } else {
                  result.docs[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.docs.push({
                  email: unit.docAssigned.email,
                  count: 0,
                  name: docDictionary[unit.docAssigned.email],
                  phone: unit.docAssigned.phone || "",
                  // name: unit.docAssigned[unit.docAssigned.length - 1].name, // fetch latest doc name, after admin changing the name of the doc.
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            let npLength = unit.npAssigned;
            if (npLength?.email) {
              const index = lodashFindIndex(result.np, [
                "email",
                unit.npAssigned.email,
              ]);
              if (index !== -1) {
                const doesHospitalExist = lodashFindIndex(
                  result.np[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  result.np[index].hospitals[doesHospitalExist].units.push(
                    unit.name
                  );
                } else {
                  result.np[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.np.push({
                  email: unit.npAssigned.email,
                  count: 0,
                  name: npDictionary[unit.npAssigned.email],
                  phone: unit.npAssigned.phone || "",
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            let ccnLength = unit.ccrnAssigned;
            if (ccnLength?.email) {
              const index = lodashFindIndex(result.ccn, [
                "email",
                unit.ccrnAssigned.email,
              ]);
              if (index !== -1) {
                const doesHospitalExist = lodashFindIndex(
                  result.ccn[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  result.ccn[index].hospitals[doesHospitalExist].units.push(
                    unit.name
                  );
                } else {
                  result.ccn[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.ccn.push({
                  email: unit.ccrnAssigned.email,
                  count: 0,
                  name: ccnDictionary[unit.ccrnAssigned.email],
                  phone: unit.ccrnAssigned.phone || "",
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            let specialistLength = unit.specialistAssigned;
            if (specialistLength?.email) {
              const index = lodashFindIndex(result.specialist, [
                "email",
                unit.specialistAssigned.email,
              ]);
              if (index !== -1) {
                const doesHospitalExist = lodashFindIndex(
                  result.specialist[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  result.specialist[index].hospitals[
                    doesHospitalExist
                  ].units.push(unit.name);
                } else {
                  result.specialist[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.specialist.push({
                  email: unit.specialistAssigned.email,
                  count: 0,
                  name: specialistDictionary[unit.specialistAssigned.email],
                  phone: unit.specialistAssigned.phone || "",
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            let ccaDocLength = unit.ccaPhysicianAssigned;
            if (ccaDocLength?.email) {
              const index = lodashFindIndex(result.ccaDoc, [
                "email",
                unit.ccaPhysicianAssigned.email,
              ]);
              if (index !== -1) {
                const doesHospitalExist = lodashFindIndex(
                  result.ccaDoc[index].hospitals,
                  ["name", unit?.hospitalInfo?.name]
                );
                if (doesHospitalExist !== -1) {
                  result.ccaDoc[index].hospitals[doesHospitalExist].units.push(
                    unit.name
                  );
                } else {
                  result.ccaDoc[index].hospitals.push({
                    name: unit?.hospitalInfo?.name,
                    units: [unit.name],
                  });
                }
              } else {
                result.ccaDoc.push({
                  email: unit.ccaPhysicianAssigned.email,
                  count: 0,
                  name: ccaDocDictionary[unit.ccaPhysicianAssigned.email],
                  phone: unit.ccaPhysicianAssigned.phone || "",
                  hospitals: [
                    {
                      name: unit?.hospitalInfo?.name,
                      units: [unit.name],
                    },
                  ],
                });
              }
            }

            return result;
          },
          { rn: [], docs: [], np: [], ccn: [], specialist: [], ccaDoc: [] }
        );

        resolve(count);
      } else {
        resolve({
          rn: [],
          docs: [],
          np: [],
          ccn: [],
          specialist: [],
          ccaDoc: [],
        });
      }
    });
  }

  ROLE_TO_USER_MAP = {
    Physician: "docs",
    Specialist: "specialist",
  };

  getPatientCount(
    patients: Patient[] | any,
    users: {
      rn?: usersCount[];
      docs?: usersCount[];
      np: usersCount[];
      ccn: usersCount[];
      specialist: usersCount[];
      ccaDoc: usersCount[];
    }
  ): Promise<{
    rn?: usersCount[];
    docs?: usersCount[];
    np: usersCount[];
    ccn: usersCount[];
    specialist: usersCount[];
    ccaDoc: usersCount[];
  }> {
    return new Promise((resolve, reject) => {
      let clone = JSON.parse(JSON.stringify(users));
      for (let index = 0; index < patients.length; index++) {
        const patient = patients[index];

        // users that have been assigned to patients by patient-level assignement will not come from previous calculation
        // Thus, to insert them into list when we iterate patient -> we extract it's patient-level assigned users and check
        // whether we already have this user in our list or not.
        // IF not we append this users with dummy hospital data
        const assignedUsers = patient?.assignedUsers?.length
          ? patient?.assignedUsers
          : null;
        if (assignedUsers) {
          assignedUsers.forEach((assigned) => {
            const { name, email, role } = assigned;
            const isAssignedUserPresentInOurList = clone[
              this.ROLE_TO_USER_MAP[role]
            ]?.find((ourListUser) => ourListUser.email === email);
            if (isAssignedUserPresentInOurList) return;

            const newRoleUser = {
              name,
              email,
              count: 0,
              hospitals: [HOSPITAL_DUMMY_DATA],
            };
            clone[this.ROLE_TO_USER_MAP[role]] = [
              ...clone[this.ROLE_TO_USER_MAP[role]],
              newRoleUser,
            ];
          });
        }
        for (let index = 0; index < clone.rn.length; index++) {
          const nurse = clone.rn[index];
          nurse.hospitals.forEach((hospital) => {
            if (
              hospital.name == patient.hospitalName &&
              hospital.units.includes(patient.unitName)
            )
              nurse.count += 1;
          });
        }

        // checks if patient is assigned to user's unit or assigned via paitnet-level assignment
        for (let index = 0; index < clone.docs.length; index++) {
          const doc = clone.docs[index];
          doc.hospitals.forEach((hospital) => {
            if (
              (hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName) &&
                (!patient?.assignedUsers?.length ||
                  findMyNameInUserAssigned(patient?.assignedUsers, doc))) ||
              findMyNameInUserAssigned(patient?.assignedUsers, doc)
            )
              doc.count += 1;
          });
        }

        for (let index = 0; index < clone.np.length; index++) {
          const np = clone.np[index];
          np.hospitals.forEach((hospital) => {
            if (
              hospital.name == patient.hospitalName &&
              hospital.units.includes(patient.unitName)
            )
              np.count += 1;
          });
        }

        for (let index = 0; index < clone.ccn.length; index++) {
          const ccn = clone.ccn[index];
          ccn.hospitals.forEach((hospital) => {
            if (
              hospital.name == patient.hospitalName &&
              hospital.units.includes(patient.unitName)
            )
              ccn.count += 1;
          });
        }

        // checks if patient is assigned to user's unit or assigned via paitnet-level assignment
        for (let index = 0; index < clone.specialist.length; index++) {
          const specialist = clone.specialist[index];
          specialist.hospitals?.forEach((hospital) => {
            if (
              (hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName) &&
                (!patient?.assignedUsers?.length ||
                  findMyNameInUserAssigned(
                    patient?.assignedUsers,
                    specialist
                  ))) ||
              findMyNameInUserAssigned(patient?.assignedUsers, specialist)
            )
              specialist.count += 1;
          });
        }

        for (let index = 0; index < clone.ccaDoc.length; index++) {
          const ccaDoc = clone.ccaDoc[index];
          ccaDoc.hospitals.forEach((hospital) => {
            if (
              hospital.name == patient.hospitalName &&
              hospital.units.includes(patient.unitName)
            )
              ccaDoc.count += 1;
          });
        }
      }

      clone.rn = clone.rn
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      clone.docs = clone.docs
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      clone.np = clone.np
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      clone.ccn = clone.ccn
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      clone.specialist = clone.specialist
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      clone.ccaDoc = clone.ccaDoc
        .filter((x) => x.count !== 0)
        .sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      return resolve(clone);
    });
  }

  /* ==============================
  NAME: getDataFromHospitals
  PURPOSE: To extract necessary info from the hospital list
  RETURNS: List of nurse / doc users
  USED BY: Radar web - patient list
  MODIFIED DATE: 22/07/2020
  AUTHOR: Suraj Shenoy
  ================================= */
  getDataFromUnits(units: Unit[]): Promise<{
    rn?: usersCount[];
    docs?: usersCount[];
    np?: usersCount[];
    ccrn?: usersCount[];
    dietitian?: usersCount[];
    specialist?: usersCount[];
    labTechnician?: usersCount[];
    pharmacologist?: usersCount[];
    documentationSpecialist?: usersCount[];
    physicalTherapist?: usersCount[];
    physicianAssociate?: usersCount[];
    occupationalTherapist?: usersCount[];
    respiratoryTherapist?: usersCount[];
    dataEntryAssociate?: usersCount[];
    registrar?: usersCount[];
    counsultantWardUnits?: any;
  }> {
    return new Promise((resolve, reject) => {
      let unitDocs = [];
      let unitRn = [];
      let unitNp = [];
      let unitCcrn = [],
        unitDietitian = [],
        unitSpecialist = [],
        unitRegistrar = [],
        unitLabTechnician = [],
        unitPharmacologist = [],
        unitDocumentationSpecialist = [],
        unitPhysicalTherapist = [],
        unitPhysicianAssociate = [],
        unitOccupationalTherapist = [],
        unitRespiratoryTherapist = [],
        unitDataEntryAssociate = [];

      if (!units.length) {
        resolve({
          rn: unitRn,
          docs: unitDocs,
          np: unitNp,
          ccrn: unitCcrn,
          dietitian: unitDietitian,
          specialist: unitSpecialist,
          registrar: unitRegistrar,
          labTechnician: unitLabTechnician,
          pharmacologist: unitPharmacologist,
          documentationSpecialist: unitDocumentationSpecialist,
          physicalTherapist: unitPhysicalTherapist,
          physicianAssociate: unitPhysicianAssociate,
          occupationalTherapist: unitOccupationalTherapist,
          respiratoryTherapist: unitRespiratoryTherapist,
          dataEntryAssociate: unitDataEntryAssociate,
          counsultantWardUnits: new Map(),
        });
        return;
      }

      units.forEach((unit) => {
        if (unit.active) {
          let unitUsed = { ...unit };

          if (unit.docAssigned && unit.docAssigned.name) {
            unitDocs = getUserDetails(unitUsed, "docAssigned", unitDocs);
          }

          if (unit.rnAssigned && unit.rnAssigned.name) {
            unitRn = getUserDetails(unitUsed, "rnAssigned", unitRn);
          }

          if (unit.ccrnAssigned && unit.ccrnAssigned.name) {
            unitCcrn = getUserDetails(unitUsed, "ccrnAssigned", unitCcrn);
          }

          if (unit.npAssigned && unit.npAssigned.name) {
            unitNp = getUserDetails(unitUsed, "npAssigned", unitNp);
          }

          if (unit.dietitianAssigned && unit.dietitianAssigned.name) {
            unitDietitian = getUserDetails(
              unitUsed,
              "dietitianAssigned",
              unitDietitian
            );
          }

          if (unit.specialistAssigned && unit.specialistAssigned.name) {
            unitSpecialist = getUserDetails(
              unitUsed,
              "specialistAssigned",
              unitSpecialist
            );
          }

          if (unit.ccaPhysicianAssigned && unit.ccaPhysicianAssigned.name) {
            unitRegistrar = getUserDetails(
              unitUsed,
              "ccaPhysicianAssigned",
              unitRegistrar
            );
          }

          if (unit.labTechnicianAssigned && unit.labTechnicianAssigned.name) {
            unitLabTechnician = getUserDetails(
              unitUsed,
              "labTechnicianAssigned",
              unitLabTechnician
            );
          }

          if (unit.pharmacologistAssigned && unit.pharmacologistAssigned.name) {
            unitPharmacologist = getUserDetails(
              unitUsed,
              "pharmacologistAssigned",
              unitPharmacologist
            );
          }

          if (
            unit.documentationSpecialistAssigned &&
            unit.documentationSpecialistAssigned.name
          ) {
            unitDocumentationSpecialist = getUserDetails(
              unitUsed,
              "documentationSpecialistAssigned",
              unitDocumentationSpecialist
            );
          }
          if (
            unit.physicalTherapistAssigned &&
            unit.physicalTherapistAssigned.name
          ) {
            unitPhysicalTherapist = getUserDetails(
              unitUsed,
              "physicalTherapistAssigned",
              unitPhysicalTherapist
            );
          }

          if (
            unit.physicianAssociateAssigned &&
            unit.physicianAssociateAssigned.name
          ) {
            unitPhysicianAssociate = getUserDetails(
              unitUsed,
              "physicianAssociateAssigned",
              unitPhysicianAssociate
            );
          }

          if (
            unit.occupationalTherapistAssigned &&
            unit.occupationalTherapistAssigned.name
          ) {
            unitOccupationalTherapist = getUserDetails(
              unitUsed,
              "occupationalTherapistAssigned",
              unitOccupationalTherapist
            );
          }

          if (
            unit.respiratoryTherapistAssigned &&
            unit.respiratoryTherapistAssigned.name
          ) {
            unitRespiratoryTherapist = getUserDetails(
              unitUsed,
              "respiratoryTherapistAssigned",
              unitRespiratoryTherapist
            );
          }

          if (
            unit.dataEntryAssociateAssigned &&
            unit.dataEntryAssociateAssigned.name
          ) {
            unitDataEntryAssociate = getUserDetails(
              unitUsed,
              "dataEntryAssociateAssigned",
              unitDataEntryAssociate
            );
          }
        }
      });

      resolve({
        rn: unitRn,
        docs: unitDocs,
        np: unitNp,
        ccrn: unitCcrn,
        dietitian: unitDietitian,
        specialist: unitSpecialist,
        registrar: unitRegistrar,
        labTechnician: unitLabTechnician,
        pharmacologist: unitPharmacologist,
        documentationSpecialist: unitDocumentationSpecialist,
        physicalTherapist: unitPhysicalTherapist,
        physicianAssociate: unitPhysicianAssociate,
        occupationalTherapist: unitOccupationalTherapist,
        respiratoryTherapist: unitRespiratoryTherapist,
        dataEntryAssociate: unitDataEntryAssociate,
        counsultantWardUnits: this.getConsultantWard(units),
      });
    });
  }

  public getConsultantWard(units: Unit[]) {
    return units
      .filter((unit) => unit.unitType === "Consultant-Ward")
      .reduce((hospitalMap, unit) => {
        const hospitalName = unit.hospitalInfo.name;
        const unitList = hospitalMap.get(unit.hospitalInfo.name) || [];
        return hospitalMap.set(hospitalName, [...unitList, unit.name]);
      }, new Map());
  }

  /* ==============================
  NAME: getDataFromPatients
  PURPOSE: To extract necessary info from the patient list
  RETURNS: count of patients of nurse / doc users, hospital names which has patients, hospital filters
  USED BY: Radar web - patient list
  MODIFIED DATE: 2/11/2020
  AUTHOR: Suraj Shenoy
  Updates:
    * added a flag which will specify certain tasks not to be exce if its discharged
  ================================= */
  getDataFromPatients(
    patients: Patient[] | any,
    data: {
      rn?: usersCount[];
      docs?: usersCount[];
      np?: usersCount[];
      ccrn?: usersCount[];
      dietitian?: usersCount[];
      specialist?: usersCount[];
      registrar?: usersCount[];
      labTechnician?: usersCount[];
      pharmacologist?: usersCount[];
      documentationSpecialist?: usersCount[];
      physicalTherapist?: usersCount[];
      physicianAssociate?: usersCount[];
      occupationalTherapist?: usersCount[];
      respiratoryTherapist?: usersCount[];
      dataEntryAssociate?: usersCount[];
      counsultantWardUnits: any;
      referalPatients: any;
    },
    discharged: boolean = false
  ): Promise<{
    rn?: usersCount[];
    docs?: usersCount[];
    np?: usersCount[];
    ccrn?: usersCount[];
    dietitian?: usersCount[];
    specialist?: usersCount[];
    registrar?: usersCount[];
    labTechnician?: usersCount[];
    pharmacologist?: usersCount[];
    documentationSpecialist?: usersCount[];
    physicalTherapist?: usersCount[];
    physicianAssociate?: usersCount[];
    occupationalTherapist?: usersCount[];
    respiratoryTherapist?: usersCount[];
    dataEntryAssociate?: usersCount[];
    sbarData?: any;
    hospitalNames?: any;
    hospitalFilters?: any;
  }> {
    return new Promise((resolve, reject) => {
      let clone = JSON.parse(JSON.stringify(data));

      let hospitalNames = [];
      let hospitalFilters = {};

      for (let index = 0; index < patients?.length; index++) {
        const patient = { ...patients[index] };

        if (!discharged) {
          // get count
          for (let index = 0; index < clone.rn.length; index++) {
            const rn = clone.rn[index];
            rn.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                rn.count += 1;
            });
          }
          for (let index = 0; index < clone.docs.length; index++) {
            const doc = clone.docs[index];
            doc.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                doc.count += 1;
            });
          }
          for (let index = 0; index < clone.np.length; index++) {
            const np = clone.np[index];
            np.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                np.count += 1;
            });
          }
          for (let index = 0; index < clone.ccrn.length; index++) {
            const ccrn = clone.ccrn[index];
            ccrn.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                ccrn.count += 1;
            });
          }

          for (let index = 0; index < clone.dietitian.length; index++) {
            const dietitian = clone.dietitian[index];
            dietitian.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                dietitian.count += 1;
            });
          }

          for (let index = 0; index < clone.specialist.length; index++) {
            const specialist = clone.specialist[index];
            specialist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                specialist.count += 1;
            });
          }

          for (let index = 0; index < clone.registrar.length; index++) {
            const registrar = clone.registrar[index];
            registrar.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                registrar.count += 1;
            });
          }

          for (let index = 0; index < clone.labTechnician.length; index++) {
            const labTechnician = clone.labTechnician[index];
            labTechnician.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                labTechnician.count += 1;
            });
          }

          for (let index = 0; index < clone.pharmacologist.length; index++) {
            const pharmacologist = clone.pharmacologist[index];
            pharmacologist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                pharmacologist.count += 1;
            });
          }

          for (
            let index = 0;
            index < clone.documentationSpecialist.length;
            index++
          ) {
            const documentationSpecialist =
              clone.documentationSpecialist[index];
            documentationSpecialist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                documentationSpecialist.count += 1;
            });
          }

          for (let index = 0; index < clone.physicalTherapist.length; index++) {
            const physicalTherapist = clone.physicalTherapist[index];
            physicalTherapist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                physicalTherapist.count += 1;
            });
          }

          for (
            let index = 0;
            index < clone.physicianAssociate.length;
            index++
          ) {
            const physicianAssociate = clone.physicianAssociate[index];
            physicianAssociate.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                physicianAssociate.count += 1;
            });
          }

          for (
            let index = 0;
            index < clone.occupationalTherapist.length;
            index++
          ) {
            const occupationalTherapist = clone.occupationalTherapist[index];
            occupationalTherapist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                occupationalTherapist.count += 1;
            });
          }

          for (
            let index = 0;
            index < clone.respiratoryTherapist.length;
            index++
          ) {
            const respiratoryTherapist = clone.respiratoryTherapist[index];
            respiratoryTherapist.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                respiratoryTherapist.count += 1;
            });
          }

          for (
            let index = 0;
            index < clone.dataEntryAssociate.length;
            index++
          ) {
            const dataEntryAssociate = clone.dataEntryAssociate[index];
            dataEntryAssociate.hospitals.forEach((hospital) => {
              if (
                hospital.name == patient.hospitalName &&
                hospital.units.includes(patient.unitName)
              )
                dataEntryAssociate.count += 1;
            });
          }
        }

        // get hospitalNames
        hospitalNames.push(patient.hospitalName);

        // get hospitalFilters
        if (hospitalFilters[patient.hospitalName]) {
          let hF = hospitalFilters[patient.hospitalName];
          hF.count += 1;
          if (hF.units[patient.unitName]) {
            let uF = hF.units[patient.unitName];
            uF.count += 1;
          } else
            [
              (hF.units[patient.unitName] = {
                value: discharged ? true : false,
                count: 1,
              }),
            ];
        } else {
          hospitalFilters[patient.hospitalName] = {
            value: discharged ? true : false,
            count: 1,
            units: {
              [patient.unitName]: {
                value: discharged ? true : false,
                count: 1,
              },
            },
          };
        }
      }

      this.setConsultantsCount(patients, hospitalFilters, data);

      if (!discharged) {
        clone.rn = clone.rn.sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        clone.docs = clone.docs.sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        clone.np = clone.np.sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        clone.ccrn = clone.ccrn.sort(function (a, b) {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      }

      hospitalNames = [...new Set(hospitalNames)];

      if (!discharged) {
        return resolve({
          rn: clone.rn,
          ccrn: clone.ccrn,
          docs: clone.docs,
          np: clone.np,
          dietitian: clone.dietitian,
          specialist: clone.specialist,
          registrar: clone.registrar,
          labTechnician: clone.labTechnician,
          pharmacologist: clone.pharmacologist,
          documentationSpecialist: clone.documentationSpecialist,
          physicalTherapist: clone.physicalTherapist,
          physicianAssociate: clone.physicianAssociate,
          occupationalTherapist: clone.occupationalTherapist,
          respiratoryTherapist: clone.respiratoryTherapist,
          dataEntryAssociate: clone.dataEntryAssociate,
          hospitalNames,
          hospitalFilters,
        });
      } else {
        return resolve({
          hospitalNames,
          hospitalFilters,
        });
      }
    });
  }

  setConsultantsCount(
    patients: any,
    hospitalFilters: {},
    { referalPatients, counsultantWardUnits }
  ) {
    if (!patients?.length || !referalPatients.size) return;
    const consultantMap = patients
      ?.filter((patient) => referalPatients.has(patient._id))
      .reduce((final, el) => {
        const count = final.get(el.hospitalName) || 0;
        return final.set(el.hospitalName, count + 1);
      }, new Map());

    consultantMap &&
      counsultantWardUnits.forEach((value, key) => {
        value.forEach((consultantWard) => {
          const count = hospitalFilters?.[key]?.[consultantWard] || 0;
          if (!hospitalFilters[key]) return;
          hospitalFilters[key].units[consultantWard] = {
            count: count + consultantMap.get(key),
            value: false,
          };
        });
      });
  }

  public getUnitToWorkspaceMap(workSpaceInfo: WorkSpace[]) {
    return workSpaceInfo.reduce((accumlator, workObj) => {
      let unitArray = workObj.unitIds;
      let workSpaceName = workObj.name;

      unitArray.forEach((id) => {
        if (!accumlator[id]) {
          accumlator[id] = {
            day: null,
            night: null, // No specific night shift
            all_day: null,
          };
        }
        accumlator[id][workObj.shift || "all_day"] = workSpaceName;
      }, {});
      return accumlator;
    }, {});
  }

  public getUnitIdMapping(units: Unit[]) {
    return units.reduce((acc, el) => {
      acc[el._id] = el;
      return acc;
    }, {});
  }
}

/* ====={Support functions}===== */

/* ==============================
  NAME: getUserDetails
  PURPOSE: Gets the list of hospitals and units assigned to a user (doc / nurse)
  RETURNS: Gets the list of hospitals and units assigned to a user (doc / nurse)
  USED BY: Radar web - patient list
  MODIFIED DATE: 22/07/2020
  AUTHOR: Suraj Shenoy
  ================================= */
const getUserDetails = (unit, key, userArr) => {
  let uDoc = unit[key];
  let docIndex = userArr.findIndex((doc) => doc.email == uDoc.email);

  if (docIndex > -1) {
    let hospIndex = userArr[docIndex]["hospitals"].findIndex(
      (hosp) => hosp.name === unit.hospitalInfo.name
    );
    if (hospIndex > -1) {
      let unitIndex = userArr[docIndex]["hospitals"][hospIndex].units.findIndex(
        (unt) => unt === unit.name
      );
      if (unitIndex < 0)
        userArr[docIndex]["hospitals"][hospIndex].units.push(unit.name);
    } else {
      userArr[docIndex]["hospitals"].push({
        name: unit.hospitalInfo.name,
        units: [unit.name],
      });
    }
  } else {
    userArr.push({
      name: uDoc.name,
      count: 0,
      email: uDoc.email,
      phone: uDoc.phone ? uDoc.phone : "",
      hospitals: [
        {
          name: unit.hospitalInfo.name,
          units: [unit.name],
        },
      ],
    });
  }

  return userArr;
};

const findMyNameInUserAssigned = (assignedUsers, user) => {
  return (
    assignedUsers?.length &&
    assignedUsers?.find((aUser) => aUser.email == user.email)
  );
};
