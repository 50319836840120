import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
  PatientMonitor,
  PatientMonitorForm,
  PatientMonitorHospital,
  PatientMonitorHospitalUnit,
} from "../../../models/patientMonitor/patientMonitor.model";
import { UtilService } from "../../../services/util.service";
import { PatientMonitorApiService } from "../../services/patient-monitor-api.service";
import { PatientMonitorFormService } from "../../services/patient-monitor-form.service";

@Component({
  selector: "app-patient-monitor-form",
  templateUrl: "./patient-monitor-form.component.html",
  styleUrls: ["./patient-monitor-form.component.scss"],
})
export class PatientMonitorFormComponent implements OnInit {
  @Input() hospitals: PatientMonitorHospital[];
  @Input() data: PatientMonitor;
  @Input() loading: boolean;
  @Output() submitting: EventEmitter<PatientMonitorForm> =
    new EventEmitter<PatientMonitorForm>();

  public form: UntypedFormGroup;
  public units: PatientMonitorHospitalUnit[] = [];

  constructor(
    public utilService: UtilService,
    private _patientMonitorFormService: PatientMonitorFormService,
    private _patientMonitorApiService: PatientMonitorApiService
  ) {}

  ngOnInit() {
    // Initializes the form.
    this.form = this._patientMonitorFormService.getPatientMonitorForm(
      this.data
    );

    if (this.data && this.data.hospitalName) {
      this.onHospitalChange(this.data.hospitalName);
    }
  }

  get deviceId(): AbstractControl {
    return this.form.get("deviceId") as AbstractControl;
  }

  get unitName(): AbstractControl {
    return this.form.get("unitName") as AbstractControl;
  }

  /*
   * NAME: onHospitalChange
   * PURPOSE: basis on hospital name, units is selected.
   * DESCRIPTION:
   *   fetches all unit name basis on the hospital name.
   *   if form is of editing unit name assigned is pre selected.
   *   if form is new then first unit is selected.
   * PARAMS: hospitalName:string - name of the hospital
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  onHospitalChange(hospitalName: string): void {
    if (!this.hospitals) {
      return;
    }

    const hospital = this.hospitals.filter(
      (hosp) => hosp.name === hospitalName
    );

    if (hospital && hospital.length === 1) {
      this.units = hospital[0] && hospital[0].units ? hospital[0].units : [];

      this.unitName.setValue(null);

      for (const unit of this.units) {
        // set unit if device is being updated.
        if (this.data && unit.name === this.data.unitName) {
          this.unitName.setValue(unit.name);
          break;
        }

        // set first available unit in device form.
        if (
          !this.data ||
          (this.data && hospitalName !== this.data.hospitalName)
        ) {
          this.unitName.setValue(unit.name);
          break;
        }
      }
    }
  }

  /*
   * NAME: onSubmit
   * PURPOSE: submits the form with patient monitor data
   * DESCRIPTION:
   *   if form is of editing oldPatientMonitorId is attached before sending.
   *   This is done to make sure on editing, attached patientMonitorId in patient object gets updated.
   *   checkIfUnique service is called to make sure deviceId is unique.
   *   if everything is correct then 'submitting' event is fired with the data.
   * PARAMS: value:PatientMonitorForm - form value
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  onSubmit(value: PatientMonitorForm): void {
    const id = this.data ? this.data._id : null;
    let formValue = value;

    if (this.data) {
      formValue = { ...value, oldDeviceId: this.data.deviceId };
    }

    this._patientMonitorApiService
      .checkIfUnique(value.deviceId, id)
      .subscribe((res) => {
        if (res.data) {
          this.deviceId.setErrors({ unique: true });
        } else {
          this.submitting.emit(formValue);
        }
      });
  }
}
