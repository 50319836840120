import { Action, createAction, props } from "@ngrx/store";
import { Order } from "../../../models/Order.model";
import { ActiveProtocol } from "../../../models/protocol/Protocol.model";

export enum ActionTypes {
  openInputTab = "[Order Component] openTab",
  closeInputTab = "[Order Component] closeTab",
  listOrders = "[Order] list",
  ordersListed = "[Order] listed",
  newOrder = "[Order] new",
  callNewOrder = "[Order] callNew",
  callUpdate = "[Order] callUpdate",
  update = "[Order] update",
  move = "[Order] move",
  remove = "[Order] remove",
  sign = "[Order] sign",
  discontinue = "[Order] discontinue",
  communicate = "[Order] communicate",
  resetOrders = "[Order] reset",
  AddOrders = "[Order Socket] Add Orders",
  RemoveOrders = "[Order Socket] Remove Orders",
  UpdateOrders = "[Order Socket] Update Orders",
  AddRemoveOrders = "[Order Socket] Add Remove Orders",
  UpdateMultipleOrders = "[Order Socket] Update Multiple Orders",
  AddAllOrders = "[Order Api] Add All Orders",
  SetActiveProtocols = "[Order View] Set Active Protocols",

  AddActiveProtocol = "[Order Socket] Add Active Protocol",
}

export class OpenInputTab implements Action {
  readonly type = ActionTypes.openInputTab;
}

export class CloseInputTab implements Action {
  readonly type = ActionTypes.closeInputTab;
}

export class ListOrders implements Action {
  readonly type = ActionTypes.listOrders;

  constructor(public payload: any) {}
}

export class MoveOrder implements Action {
  readonly type = ActionTypes.move;

  constructor(public payload: any) {}
}

export class OrdersListed implements Action {
  readonly type = ActionTypes.ordersListed;

  constructor(public payload: any) {}
}

export class OrderCallUpdate implements Action {
  readonly type = ActionTypes.callUpdate;

  constructor(public payload: any) {}
}

export class OrderUpdated implements Action {
  readonly type = ActionTypes.update;

  constructor(public payload: any) {}
}

export class OrderNew implements Action {
  readonly type = ActionTypes.newOrder;

  constructor(public payload: any) {}
}

export class CallNewOrder implements Action {
  readonly type = ActionTypes.callNewOrder;

  constructor(public payload: any) {}
}

export class DiscontinueOrder implements Action {
  readonly type = ActionTypes.discontinue;

  constructor(public payload: any) {}
}

export class RemoveOrder implements Action {
  readonly type = ActionTypes.remove;

  constructor(public payload: any) {}
}

export class SignOrder implements Action {
  readonly type = ActionTypes.sign;

  constructor(public payload: any) {}
}

export class CommunicateOrder implements Action {
  readonly type = ActionTypes.communicate;

  constructor(public payload: any) {}
}

export class ResetOrder implements Action {
  readonly type = ActionTypes.resetOrders;
}

export class AddOrders implements Action {
  readonly type = ActionTypes.AddOrders;

  constructor(public orders: Order[]) {}
}

export class RemoveOrders implements Action {
  readonly type = ActionTypes.RemoveOrders;

  constructor(public orders: Order[]) {}
}

export class UpdateOrders implements Action {
  readonly type = ActionTypes.UpdateOrders;

  constructor(public orders: Order[]) {}
}

export class UpdateMultipleOrders implements Action {
  readonly type = ActionTypes.UpdateMultipleOrders;

  constructor(public orders: Order[]) {}
}

export class AddAllOrders implements Action {
  readonly type = ActionTypes.AddAllOrders;

  constructor(public orders: any) {}
}

export class SetActiveProtocols implements Action {
  readonly type = ActionTypes.SetActiveProtocols;

  constructor(public protocols: any) {}
}

export class AddActiveProtocol implements Action {
  readonly type = ActionTypes.AddActiveProtocol;

  constructor(public activeProtocol: ActiveProtocol) {}
}

export type Actions =
  | OpenInputTab
  | CloseInputTab
  | ListOrders
  | OrdersListed
  | OrderUpdated
  | OrderCallUpdate
  | CallNewOrder
  | OrderNew
  | DiscontinueOrder
  | RemoveOrder
  | ResetOrder
  | MoveOrder
  | AddOrders
  | RemoveOrders
  | UpdateOrders
  | AddAllOrders
  | SetActiveProtocols
  | AddActiveProtocol;
