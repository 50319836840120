import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as fromNoteStore from "@notes/store";
import { Store, select } from "@ngrx/store";
import {
  CTAType,
  CosignStatusType,
  Intensivist,
  SubmitTypes,
} from "@notes/models/notes.model";
import { AsyncPipe } from "@angular/common";
import * as fromloaderStateReducer from "@shared-modules/store";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { LoaderNames } from "@notes/utils/loader_name";
import { NoteActionsService } from "@notes/services/note-actions.service";
import { NoteFormsService } from "@notes/services/note-forms.service";
import { RequestAttestComponent } from "../request-attest/request-attest.component";
import { attestRequired, signPermission } from "@notes/utils/permission.data";
import { User } from "src/app/models/user";
import { NoteService } from "@notes/services/note.service";

@Component({
  selector: "cp-note-action",
  templateUrl: "./note-action.component.html",
  styleUrls: ["./note-action.component.scss"],
  providers: [AsyncPipe],
  host: {
    class: "cp-w-100",
  },
})
export class NoteActionComponent implements OnInit {
  constructor(
    private _dialog: MatDialog,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _noteActionServices: NoteActionsService,
    private _noteFormService: NoteFormsService,
    private _notesService: NoteService,
    public dialog: MatDialog
  ) {}

  @ViewChild("deleteConfirmationTemplate")
  deleteConfirmationTemplate: TemplateRef<HTMLElement>;
  lastUpdatedAt = false;
  SubmitTypes = SubmitTypes;
  CosignTypes = CosignStatusType;

  attestRequired = attestRequired;
  signPermission = signPermission;

  public currentUser: User = this._notesService.user;

  @Input() patientNotesForm: FormGroup;
  @Input() addendumNote: FormControl;
  @Input() findAttestationUserCriteria: {
    hospitalID: string;
    commandCenterID: string;
  };

  @Input() disableActionButtons = false;

  @Input() attestationStatusMsg: FormControl;

  public noteCTA$ = this._noteStore.pipe(
    select(fromNoteStore.getCTA),
    map((data) => data.cta)
  );

  CTAType = CTAType;
  LoaderNames = LoaderNames;

  ngOnInit(): void {}

  onSubmit(submitType: SubmitTypes) {
    this.addAddendumNoteIfAvailable();
    if (this.attestationStatusMsg?.value) {
      this.patientNotesForm.controls.cosign.patchValue({
        physicianMessage: this.attestationStatusMsg.value,
      });
    }
    this._noteActionServices.onSubmit(submitType, this.patientNotesForm);
  }

  onAttest(attestType: CosignStatusType) {
    this.attestNoteIfAvailable(attestType);
    this.patientNotesForm.controls.cosign.patchValue({
      status: attestType,
    });
    this._noteActionServices.onAttest(this.patientNotesForm, attestType);
  }

  private addAddendumNoteIfAvailable() {
    const addendumValue = this.addendumNote?.value;
    if (addendumValue) {
      this.resetCosignRejectedCase();
      const addendumFormGroup: FormGroup =
        this._noteFormService.buildAddendumGroup({ note: addendumValue });
      const addendumFormArray = this.patientNotesForm.get(
        "addendum"
      ) as FormArray;
      addendumFormArray.push(addendumFormGroup);
    }
  }

  private attestNoteIfAvailable(attestType: CosignStatusType) {
    const attestationStatusMsgValue = this.attestationStatusMsg?.value;
    if (attestationStatusMsgValue) {
      this.patientNotesForm.controls.cosign.patchValue({
        physicianMessage: attestationStatusMsgValue,
        status: attestType,
      });
    }
  }

  private resetCosignRejectedCase() {
    const cosign = this.patientNotesForm.get("cosign") as FormGroup;
    if (cosign?.value && cosign?.value?.status) {
      const status: CosignStatusType = cosign.value.status;
      if (status === CosignStatusType.Rejected) {
        this.handleCancelAction();
      }
    }
  }

  public get isNewNote(): boolean {
    return this.patientNotesForm.get("refId").value === "new";
  }

  public getLoaderState$(loaderName: LoaderNames = null): Observable<boolean> {
    return this._loaderStore.pipe(
      select(fromloaderStateReducer.getLoaderNamespaceLoading(loaderName))
    );
  }

  onDeleteClick() {
    const dialogRef: MatDialogRef<HTMLElement> = this._dialog.open(
      this.deleteConfirmationTemplate
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result === true) {
          const { refId } = this.patientNotesForm.value;
          this._noteStore.dispatch(fromNoteStore.deleteDraftNote({ refId }));
        }
      });
  }

  onRequestAttest(): void {
    const dialogRef = this.openAttestDialog();
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: Intensivist) => {
        if (result) {
          this.addAddendumNoteIfAvailable();
          const requestedtoInfo = this.extractRequestedToInfo(result);
          this.submitAttestationNote(requestedtoInfo, this.patientNotesForm);
        } else {
          this.handleCancelAction();
        }
      });
  }

  handleCancelAction() {
    const cosignGroup = this.patientNotesForm.get("cosign") as FormGroup;
    if (cosignGroup) {
      cosignGroup.removeControl("requestedTo");
      cosignGroup.get("status").setValue(CosignStatusType.Optional);
    }
  }

  private openAttestDialog(): MatDialogRef<RequestAttestComponent> {
    return this.dialog.open(RequestAttestComponent, {
      height: "53vh",
      width: "36vw",
    });
  }

  private extractRequestedToInfo(result: Intensivist): any {
    return {
      author: result.author,
      uniqueID: {
        name: result.name,
        email: result.email,
      },
    };
  }

  private submitAttestationNote(
    requestedtoInfo: any,
    patientNotesForm: FormGroup
  ): void {
    this._noteActionServices.onRequestAttestation(
      requestedtoInfo,
      patientNotesForm
    );
  }
}
