import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { Vitals } from "../models/Vital";

@Directive({
  selector: "[vitalCollapseRow]",
})
export class CollapseRowDirective {
  @Input("collapseButtonState") collapseButtonState: boolean;
  @Input("vitals") vitals: Vitals[];
  @Input("showVitals") showVitals: { val: string; show: string };

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    if (this.collapseButtonState) {
      return this.hideRowsWithNullValues();
    }
    this.showRows();
  }

  private hideRowsWithNullValues() {
    const { val } = this.showVitals;
    const allVitalsMatchCondition = this.vitals.every((vital) => {
      let value = vital[val];
      if (typeof value === "string") {
        value = value.trim(); // Trim white spaces
      }
      return (
        value === null ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        typeof value === "undefined" ||
        (typeof value === "object" &&
          value !== null &&
          Object.values(value).every((prop) => prop === null))
      );
    });

    if (allVitalsMatchCondition) {
      this.renderer.setStyle(this.elementRef.nativeElement, "display", "none");
    }
  }

  private showRows() {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      "display",
      "table-row"
    );
  }
}
