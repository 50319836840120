import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { schedules, timePeriodOptions } from "../../../form.data";
@Component({
  selector: "app-meta-frequency",
  styleUrls: ["./meta-frequency.component.scss"],
  templateUrl: "./meta-frequency.component.html",
})
export class MetaFrequencyComponent implements OnInit {
  @Input() config;
  @Input() formType;
  @Input() freqHourSelected;
  @Input() frequencyType: string;

  @Output() daysChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() doseChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() scheduleChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() urgencyChange: EventEmitter<string> = new EventEmitter<string>();

  public frequency: string[];
  public schedules = schedules;
  public periodOptions = JSON.parse(JSON.stringify(timePeriodOptions));
  prevSelectedUrgency;
  prevSelectedTimePeriod;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.prevSelectedUrgency = this.urgency.value;
    this.prevSelectedTimePeriod = this.period.value;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      !simpleChanges.freqHourSelected?.firstChange &&
      simpleChanges.freqHourSelected?.currentValue !=
        simpleChanges.freqHourSelected?.previousValue &&
      this.form.dirty
    ) {
      this.scheduleSelector.reset();
      this.scheduleChange.emit(null);
      (this.form.get("skipSchedule") as UntypedFormArray).clear();
    }

    if (
      simpleChanges.frequencyType &&
      simpleChanges.frequencyType?.currentValue == "continuous"
    ) {
      this.onTimePeriodChange(null);
    }
  }

  get form() {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for urgency field.
   *
   * @returns {AbstractControl}
   */
  get urgency(): AbstractControl {
    return this.form.get("urgency");
  }

  /**
   * Get accessor for numberOfDoses field.
   *
   * @returns {AbstractControl}
   */
  get numberOfDoses(): AbstractControl {
    return this.form.get("numberOfDoses");
  }

  /**
   * Get accessor for numberOfDoses field.
   *
   * @returns {AbstractControl}
   */
  get noOfDays(): AbstractControl {
    return this.form.get("noOfDays");
  }

  get period(): AbstractControl {
    return this.form.get("timePeriodDetail.period");
  }

  get periodOption(): AbstractControl {
    return this.form.get("timePeriodDetail.periodOption");
  }

  get scheduleSelector(): AbstractControl {
    return this.form.get("scheduleSelector");
  }
  /**
   * Clicking on same urgency twice unselects it.
   *
   * @param {string} urgency
   * @returns null
   */
  selectUrgency(event: MatButtonToggleChange): void {
    const value = this.prevSelectedUrgency === event.value ? null : event.value;
    this.urgency.setValue(value);
    this.prevSelectedUrgency = value;
    this.urgencyChange.emit(value);
  }

  /*
   * NAME: onDaysChange
   * PURPOSE: emits new days through daysChange emitter
   * DESCRIPTION:
   * PARAMS: days:number
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDaysChange(days: number): void {
    this.daysChange.emit(+days);
  }

  /*
   * NAME: onDoseChange
   * PURPOSE: emits new dose through daysChdoseChangeange emitter
   * DESCRIPTION:
   * PARAMS: dose:number
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDoseChange(dose: number): void {
    this.doseChange.emit(+dose);
  }

  onScheduleChange(event) {
    this.scheduleChange.emit(event.value);
  }

  onTimePeriodChange(selectedPeriod) {
    const period =
      this.prevSelectedTimePeriod === selectedPeriod ? null : selectedPeriod;
    this.period.setValue(period);
    this.periodOption.setValue(null);
    this.prevSelectedTimePeriod = period;

    if (period) {
      this.periodOption.setValidators(Validators.required);
    } else {
      this.periodOption.clearValidators();
    }
    this.periodOption.updateValueAndValidity();
  }
}
