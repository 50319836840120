import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, Validators } from "@angular/forms";

import * as formData from "../../../form.data";
import { Orderable } from "../../../../models/Orderable.model";
import { UtilService } from "../../../../services/util.service";
import { DietPresetBE } from "../../../../models/preset/DietPreset.model";
import { rangeValidators } from "src/app/shared/validators/range.validators";

@Component({
  selector: "app-diet-form",
  templateUrl: "./diet-form.component.html",
  styleUrls: ["./diet-form.component.scss"],
})
export class DietFormComponent implements OnInit {
  @Input() value?: DietPresetBE;
  @Input() config: any;
  @Input() orderables?: Orderable[] | null;
  @Input() formType: string;
  @Input() showBedsideForm = true;

  public diet = formData.diet;
  public otherDiet = formData.otherDiet;

  formControlNames = {
    start: "startTime",
    end: "endTime",
  };

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {
    if (
      this.value &&
      this.value.frequency &&
      this.value.frequency.fType === "continuous"
    ) {
      this.rateValue.setValidators([Validators.required, rangeValidators(0)]);
      this.rateValue.updateValueAndValidity();
    }
  }

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for presetName field.
   *
   * @returns {AbstractControl}
   */
  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  /**
   * Get accessor for name field.
   *
   * @returns {AbstractControl}
   */
  get name(): AbstractControl {
    return this.form.get("name");
  }

  /**
   * Get accessor for rate.value field.
   *
   * @returns {AbstractControl}
   */
  get rateValue(): AbstractControl {
    return this.form.get("rate.value");
  }

  /**
   * Get accessor for fType field.
   *
   * @returns {AbstractControl}
   */
  get fType(): AbstractControl {
    return this.form.get("frequency.fType");
  }

  /*
   * NAME: rateValueHasError
   * PURPOSE: checks whether rate value has required error
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: boolean - true if it has error
   * USED BY: diet-from.component.html
   * CREATED DATE: 7 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  rateValueHasError(error): boolean {
    return this.rateValue.hasError(error);
  }

  /*
   * NAME: onFrequencyChange
   * PURPOSE: set validators to rate.value if frequency is continous, otherwise resets it.
   * DESCRIPTION:
   * PARAMS: frequency:string - fType value of frequency
   * RETURNS: void
   * USED BY: diet-form.component.html
   * CREATED DATE: 7 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  onFrequencyChange(frequency: string): void {
    if (frequency === "continuous") {
      this.rateValue.setValidators([Validators.required, rangeValidators(0)]);
    } else {
      this.rateValue.clearValidators();
    }

    this.rateValue.updateValueAndValidity();
  }

  onDateChange(date: Date, type: string) {
    // const control: string =
    //   type === 'start' ? 'startTime.date' : 'endTime.date';
    // this.form.get(control).setValue(date);
  }

  onTimeChange(time: string, type: string) {
    // const control: string =
    //   type === 'start' ? 'startTime.time' : 'endTime.time';
    // this.form.get(control).setValue(time);
  }

  /**
   * If bedside is checked then resets home med checkbox.
   */
  onBedsideChange() {
    this.form
      .get("bedsideOrder")
      .patchValue(!!!this.form.get("bedsideOrder").value);
  }
}
