import { createAction, props } from "@ngrx/store";
import { LabOrderBE } from "../../../models/Lab.model";
import { MedOrderBE } from "../../../models/Med.model";
import { DietOrderBE } from "../../../models/Diet.model";
import { BloodOrderBE } from "../../../models/Blood.model";
import { ProcedureOrderBE } from "../../../models/Procedure.model";
import { CommunicationOrderBE } from "../../../models/Communication.model";

export enum OrderApiActionTypes {
  AddOrderSuccess = "[Order Api] Add Order Success",
  AddOrderFailure = "[Order Api] Add Order Failure",
  DuplicateOrder = "[Order Api] Duplicate add Order",
  UpdateOrderSuccess = "[Order Api] Update Order Success",
  UpdateOrderFailure = "[Order Api] Update Order Failure",
}

export const addOrderSuccess = createAction(
  OrderApiActionTypes.AddOrderSuccess,
  props<{
    order:
      | BloodOrderBE
      | CommunicationOrderBE
      | DietOrderBE
      | LabOrderBE
      | MedOrderBE
      | ProcedureOrderBE;
  }>()
);

export const addOrderFailure = createAction(
  OrderApiActionTypes.AddOrderFailure,
  props<{ error: string }>()
);

export const duplicateOrder = createAction(OrderApiActionTypes.DuplicateOrder);

export const updateOrderSuccess = createAction(
  OrderApiActionTypes.UpdateOrderSuccess,
  props<{
    order:
      | BloodOrderBE
      | CommunicationOrderBE
      | DietOrderBE
      | LabOrderBE
      | MedOrderBE
      | ProcedureOrderBE;
  }>()
);

export const updateOrderFailure = createAction(
  OrderApiActionTypes.UpdateOrderFailure,
  props<{ error: string }>()
);
