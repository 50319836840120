import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextEditorComponent } from "./components/text-editor/text-editor.component";
import { QuillModule } from "ngx-quill";
import { ReactiveFormsModule } from "@angular/forms";
import { SanitizeHtmlModule } from "src/app/shared-modules/pipes/sanitize-html/sanitize-html.module";

@NgModule({
  declarations: [TextEditorComponent],
  imports: [CommonModule, QuillModule, ReactiveFormsModule, SanitizeHtmlModule],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
