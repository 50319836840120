import { Component, OnInit } from '@angular/core';
import { SplashScreenService } from "src/app/services/splash-screen.service";
import { Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  public loading = true;
  public splashScreenSubs: Subscription;

  constructor(private SplashScreenService: SplashScreenService) { }

  ngOnInit() {
      this.splashScreenSubs = this.SplashScreenService.loadingStatus
      .subscribe((value) => {

        this.loading = value['val'];
      });
  }

  ngOnDestroy() {
    this.splashScreenSubs.unsubscribe();
  }

}
