export class Order {
  medications: Array<Object>;
  labs: Array<Object>;
  diets: Array<Object>;
  communications: Array<Object>;
  bloods: Array<Object>;
  procedures: Array<Object>;
  vents: any;
  count: number;
  activeProtocols: string[];

  constructor() {
    this.medications = [];
    this.labs = [];
    this.diets = [];
    this.communications = [];
    this.bloods = [];
    this.procedures = [];
    this.vents = [];
    this.count = 0;
    this.activeProtocols = [];
  }
}
