import { Subscription } from "rxjs";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { padStart } from "lodash-es";
import { UtilService } from "../../../services/util.service";
import { airways, intubatedAirways, tubes } from "../../data";
import { VentForm } from "../../../models/vital/vent/Vent.model";
import { VentFormTemplateService } from "../../services/vent-form-template.service";

@Component({
  selector: "app-vent-form",
  styleUrls: ["./vent-form.component.scss"],
  templateUrl: "./vent-form.component.html",
})
export class VentFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentPatient?;
  @Input() minDate?;
  @Input() maxDate?;
  @Input() value?: any;
  @Input() config: any;
  @Input() formType: string;
  @Input() intubated = false;
  @Output() submitting: EventEmitter<any> = new EventEmitter<any>();
  @Output() timeChange: EventEmitter<any> = new EventEmitter<any>();

  public airwayOptions = airways;
  public tubeOptions = tubes;

  /*
   * Template Variables
   *
   * Controls which part of can be displayed
   * */
  public canViewTube = false;
  public canViewMechVent = false;
  public canViewNivVent = false;
  public canViewTubeSize = false;
  public canViewIntType = false;
  public modeSelectValues = [];
  public PEEPLabel = "PEEP/EPAP";
  public canViewFlowRate = false;
  public canViewBasicForm = false;
  public viewTidalFirst = false;
  public canViewFio2 = false;
  public intTypeRequired = false;
  public canViewEttTieLevel = false;
  public startTime;

  /*
   * Subscription Variables
   *
   * Form value subscriptions
   * */
  private airway$: Subscription;
  private intType$: Subscription;
  private mode$: Subscription;
  private ventType$: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private ventFormTemplateService: VentFormTemplateService,
    public controlContainer: ControlContainer,
    public utilService: UtilService
  ) {}

  ngOnInit(): void {
    if (!this.checkIfSubscribedToForm()) {
      this.subscribeFields();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      const value = changes.value.currentValue;

      /*if (!this.checkIfSubscribedToForm()) {
        this.subscribeFields();
      }*/
      this.subscribeFields();

      this.setFormValue(value);
    }

    if (changes.intubated) {
      this.updateAirways(changes.intubated.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeFields();
  }

  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  get airway(): AbstractControl {
    return this.form.get("patVentAirway");
  }

  get intType(): AbstractControl {
    return this.form.get("patIntType");
  }

  get mode(): AbstractControl {
    return this.form.get("patVentMode");
  }

  get ventType(): AbstractControl {
    return this.form.get("patVentType");
  }

  get date(): AbstractControl {
    return this.form.get("patVentDate");
  }

  get time(): AbstractControl {
    return this.form.get("patVentTime");
  }

  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  /*
   * NAME: subscribeFields
   * PURPOSE: subscribes to the form value subscriptions
   * DESCRIPTION:
   *   - this function controls which part of template can be displayed
   *   - basis on the value from the subscription it updates the template level variables
   * PARAMS: void
   * RETURNS: void
   * USED BY: ngOnInit()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  private subscribeFields(): void {
    this.airway$ = this.airway.valueChanges.subscribe((airway) => {
      const intType = this.intType.value;
      const ventType = this.ventType.value;

      this.canViewTube = this.ventFormTemplateService.canViewTube(airway);
      this.canViewEttTieLevel =
        this.ventFormTemplateService.canViewEttTieLevel(airway);
      this.canViewMechVent = this.ventFormTemplateService.canViewVentType(
        airway,
        "Mech"
      );
      this.canViewNivVent = this.ventFormTemplateService.canViewVentType(
        airway,
        "NIV"
      );

      if (!this.canViewMechVent || !this.canViewNivVent) {
        this.ventType.patchValue(null);
      }

      this.canViewTubeSize = this.ventFormTemplateService.canViewTubeSize(
        airway,
        intType
      );
      this.canViewIntType = this.ventFormTemplateService.canViewIntType(airway);
      this.intTypeRequired =
        this.ventFormTemplateService.isIntTypeRequired(airway);
      this.setIntTypeValidation(this.intTypeRequired);
      this.onVentTypeChange(ventType, airway);

      if (airway === "High Flow") {
        this.ventType.setValue("Heated High Flow");
      }
    });

    this.intType$ = this.intType.valueChanges.subscribe((intType) => {
      const airway = this.airway.value;

      this.canViewTubeSize = this.ventFormTemplateService.canViewTubeSize(
        airway,
        intType
      );
    });

    this.mode$ = this.mode.valueChanges.subscribe((mode) => {
      this.modeSelectValues =
        this.ventFormTemplateService.getModeSelectValues(mode);
      this.viewTidalFirst = this.ventFormTemplateService.viewTidalFirst(mode);
    });

    this.ventType$ = this.ventType.valueChanges.subscribe((ventType) => {
      this.onVentTypeChange(ventType);
    });
  }

  /*
   * NAME: unSubscribeFields
   * PURPOSE: unsubscribe the form value subscriptions
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: void
   * USED BY: ngOnDestroy()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  private unSubscribeFields(): void {
    if (this.airway$) {
      this.airway$.unsubscribe();
    }

    if (this.intType$) {
      this.intType$.unsubscribe();
    }

    if (this.mode$) {
      this.mode$.unsubscribe();
    }

    if (this.ventType$) {
      this.ventType$.unsubscribe();
    }
  }

  /*
   * NAME: checkIfSubscribedToForm
   * PURPOSE: check if form values are subscribed to
   * DESCRIPTION:
   * PARAMS:
   * RETURNS: boolean - true it they are subscribed
   * USED BY: ngOnChanges()
   * CREATED DATE: 18/02/20
   * AUTHOR: Gunjit Agrawal
   */
  private checkIfSubscribedToForm(): boolean {
    return !!(this.airway$ && this.intType$ && this.mode$ && this.ventType$);
  }

  /*
   * NAME: setFormValue
   * PURPOSE: updates the form values to existing vent data
   * DESCRIPTION:
   * PARAMS: value:object - vent data to be updated
   * RETURNS: void
   * USED BY: ngOnChanges()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  private setFormValue(value): void {
    if (!value) {
      return null;
    }

    if (value.presetName && this.form.get("presetName")) {
      this.form.get("presetName").setValue(value.presetName);
    }

    if (value.displayAsShortcut && this.form.get("displayAsShortcut")) {
      this.form.get("displayAsShortcut").setValue(value.displayAsShortcut);
    }

    if (
      value.protocols &&
      this.form.get("protocols") &&
      this.form.get("protocols").status === "VALID"
    ) {
      const protocolsFormArray = this.form.get("protocols") as UntypedFormArray;

      for (const id of value.protocols) {
        protocolsFormArray.push(new UntypedFormControl(id));
      }
    }

    if (value.protocol) {
      this.form.get("protocol").setValue(value.protocol);
    }

    let airway = value.patVentAirway;

    if (
      this.intubated &&
      airway &&
      !airway.includes("Trach") &&
      !airway.includes("ETT") &&
      airway !== "LMA"
    ) {
      airway = "";
    }

    this.airway.setValue(airway);
    this.form.get("patVentTube").setValue(value.patVentTube);
    this.form.get("patVentOralAirway").setValue(value.patVentOralAirway);
    this.form.get("instructions").setValue(value.instructions);
    this.form
      .get("additionalInformation")
      .setValue(value.additionalInformation);
    this.ventType.setValue(value.patVentType);
    this.intType.setValue(value.patIntType);
    this.mode.setValue(value.patVentMode);
    this.form.get("patVentSubMode").setValue(value.patVentSubMode);
    this.form.get("patVentI").setValue(value.patVentI);
    this.form.get("patVentE").setValue(value.patVentE);
    this.form.get("patVentMV").setValue(value.patVentMV);
    this.form.get("patVentTubeSize").setValue(value.patVentTubeSize);
    this.form.get("patVentFiO2").setValue(value.patVentFiO2);
    this.form.get("patVentVt").setValue(value.patVentVt);
    this.form.get("patVentRRTot").setValue(value.patVentRRTot);
    this.form.get("patVentRRset").setValue(value.patVentRRset);
    this.form.get("patVentPplat").setValue(value.patVentPplat);
    this.form.get("patVentPpeak").setValue(value.patVentPpeak);
    this.form.get("patVentMAP").setValue(value.patVentMAP);
    this.form.get("patVentPinsp").setValue(value.patVentPinsp);
    this.form.get("patVentPsupport").setValue(value.patVentPsupport);
    this.form.get("patVentPEEP").setValue(value.patVentPEEP);
    this.form.get("patFlowRate").setValue(value.patFlowRate);
    this.form.get("patEttTieLevel").setValue(value.patEttTieLevel);
    this.form.get("patientType").setValue(value.patientType);

    this.form.get("startNow").setValue(value?.startNow);

    const today = value.startTime ? new Date(value.startTime) : new Date();
    this.startTime = today;
    this.form.patchValue({
      startTime: {
        date: today,
        hour: padStart(today.getHours(), 2, 0),
        minute: padStart(today.getMinutes(), 2, 0),
      },
    });

    if (value.patVentDate) {
      this.date.setValue(value.patVentDate);
    }

    if (value.patVentTime) {
      this.time.setValue(value.patVentTime);
    }
  }

  /*
   * NAME: onVentTypeChange
   * PURPOSE: updates the template variables basis on ventType and airway
   * DESCRIPTION:
   *   controls which part of form can be displayed through ventType and airway
   * PARAMS:
   *   - ventType:string
   *   - airway:string|null
   * RETURNS: void
   * USED BY: subscribeFields()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  onVentTypeChange(ventType: string, airway: string | null = null): void {
    this.PEEPLabel = this.ventFormTemplateService.getPeepLabel(ventType);
    this.canViewFlowRate =
      this.ventFormTemplateService.canViewFlowRate(ventType);
    this.canViewBasicForm = this.ventFormTemplateService.canViewBasicForm(
      ventType,
      airway
    );
    this.canViewFio2 = this.ventFormTemplateService.canViewFio2(ventType);
  }

  /*
   * NAME: onTimeDateChanged
   * PURPOSE: emits timeChange event with date and time
   * DESCRIPTION: fires whenever date or time is changed
   * PARAMS: void
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  onTimeDateChanged(): void {
    this.timeChange.emit({
      date: this.date.value,
      time: this.time.value,
    });
  }

  /*
   * NAME: setIntTypeValidation
   * PURPOSE: updates intubation type validation
   * DESCRIPTION:
   *   - if required is true then intubation is required
   *   - calls angular's updateValueAndValidity() to update validation rules for the field
   * PARAMS: required:boolean
   * RETURNS: void
   * USED BY: subscribeFields()
   * CREATED DATE: 14/02/20
   * AUTHOR: Gunjit Agrawal
   */
  setIntTypeValidation(required: boolean): void {
    if (required) {
      this.intType.setValidators([Validators.required]);
    } else {
      this.intType.clearValidators();
    }

    this.intType.updateValueAndValidity();
  }

  /*
   * NAME: updateAirways
   * PURPOSE: sets airway options basis on intubated
   * DESCRIPTION:
   * PARAMS: intubated:boolean
   * RETURNS:
   * USED BY:
   * CREATED DATE: 26/03/20
   * AUTHOR: Gunjit Agrawal
   */
  updateAirways(intubated: boolean): void {
    if (intubated) {
      this.airwayOptions = intubatedAirways;
    } else {
      this.airwayOptions = airways;
    }
  }

  startNowChangeHandler(checked) {
    if (checked) this.startTime = new Date();
  }
}
