<div class="ioContainer">
  <div
    class="component-view"
    [ngClass]="{
      'component-view-full': !checkUserPermission() || isDischargeTimeElapsed
    }"
  >
    <!-- Table for daily vitals -->
    <app-io-view
      [currUser]="currUser"
      [currPatient]="currPatient"
    ></app-io-view>
  </div>

  <ng-template [ngxPermissionsOnly]="['update_io']">
    <div class="component-input ml" *ngIf="isDischargeTimeElapsed == false">
      <!-- Sidebar to take input -->
      <app-io-input [currPatient]="currPatient"></app-io-input>
    </div>
  </ng-template>
</div>
