import * as d3 from 'd3';
import { legendColor } from 'd3-svg-legend';
import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Dimension, Legends, PieCenter, PieChartConfigData, Title, PieChartConfig } from './pie-chart.model';

@Component({
  selector: 'app-pie-chart',
  template: '<div #canvas class="canvas"></div>',
})
export class PieChartComponent implements OnInit, AfterViewInit {
  // Initialize flag to check if the graph is drawn if so, just update the values else draw it on the canvas
  private isDrawn = false;
  private defaultColor = 'black';

  @ViewChild('canvas', { static: true })
  public _canvas: ElementRef;

  private _dimensions: Dimension = {
    height: 300,
    width: 300,
    radius: 150
  };
  set dimensions(dimension: Dimension) {
    if (dimension != undefined && dimension != null) {
      if (dimension.height != undefined && dimension.height != null)
        this._dimensions.height = dimension.height;
      if (dimension.width != undefined && dimension.width != null)
        this._dimensions.width = dimension.width;
      if (dimension.radius != undefined && dimension.radius != null)
        this._dimensions.radius = dimension.radius;

    }
  }
  get dimensions() {
    return this._dimensions;
  };

  private _legendConfig: Legends = {
    legendNeeded: true,
    width: 150,
    height: 150,
    x: this.dimensions.width + 40,
    y: 20,
    shape: 'circle',
    shapePadding: 10,
    text: {
      fontColor: this.defaultColor,
      fontSize: 10,
      fontWeight: 0,
    }
  }
  set legendConfig(legendConfig) {
    if (legendConfig != undefined && legendConfig != null) {
      if (legendConfig.legendNeeded != undefined && legendConfig.legendNeeded != null)
        this._legendConfig.legendNeeded = legendConfig.legendNeeded;

      if (legendConfig.width != undefined && legendConfig.width != null
        && legendConfig.height != undefined && legendConfig.height != null) {
        this._legendConfig.height = legendConfig.height;
        this._legendConfig.width = legendConfig.width;
        this._legendConfig.x = this.dimensions.width + 40;
        this._legendConfig.y = 20;
      }
      if (legendConfig.width != undefined && legendConfig.width != null)
        this._legendConfig.width = legendConfig.width;
      if (legendConfig.height != undefined && legendConfig.height != null)
        this._legendConfig.height = legendConfig.height;
      if (legendConfig.x != undefined && legendConfig.x != null)
        this._legendConfig.x = legendConfig.x;
      if (legendConfig.y != undefined && legendConfig.y != null)
        this._legendConfig.y = legendConfig.y;
      if (legendConfig.shape != undefined && legendConfig.shape != null)
        this._legendConfig.shape = legendConfig.shape;
      if (legendConfig.shapePadding != undefined && legendConfig.shapePadding != null)
        this._legendConfig.shapePadding = legendConfig.shapePadding;
      if (legendConfig.text != null && legendConfig.text != undefined) {
        if (legendConfig.text.name != null && legendConfig.text.name != undefined)
          this._legendConfig.text.name = legendConfig.text.name;
        if (legendConfig.text.fontColor != null && legendConfig.text.fontColor != undefined)
          this._legendConfig.text.fontColor = legendConfig.text.fontColor;
        if (legendConfig.text.fontWeight != null && legendConfig.text.fontWeight != undefined)
          this._legendConfig.text.fontWeight = legendConfig.text.fontWeight;
        if (legendConfig.text.fontSize != null && legendConfig.text.fontSize != undefined)
          this._legendConfig.text.fontSize = legendConfig.text.fontSize;
      }
    }
  };
  get legendConfig() {
    return this._legendConfig;
  };

  private _title: Title = {
    x: 0,
    y: 0,
    fontSize: `${10}px`,
    fontWeight: 0,
    titleAndGraphSpacing: 0,
    fontColor: this.defaultColor,
  };
  set title(title: Title) {
    if (title) {
      if (title.name != null && title.name != undefined) {
        this._title.name = title.name;
        this._title.x = 0;
        this._title.y = 30;
        this._title.fontSize = `${25}px`;
        this._title.titleAndGraphSpacing = 10;
        this._title.fontColor = this.defaultColor;
      }
      if (title.x != null && title.x != undefined)
        this._title.x = title.x;
      if (title.y != null && title.y != undefined)
        this._title.y = title.y;
      if (title.fontSize != null && title.fontSize != undefined)
        this._title.fontSize = `${title.fontSize}px`;
      if (title.fontWeight != null && title.fontWeight != undefined)
        this._title.fontWeight = title.fontWeight;
      if (title.titleAndGraphSpacing != null && title.titleAndGraphSpacing != undefined)
        this._title.titleAndGraphSpacing = title.titleAndGraphSpacing;
      if (title.fontColor != null && title.fontColor != undefined)
        this._title.fontColor = title.fontColor;
    }
  };
  get title() {
    return this._title;
  };

  private _center: PieCenter = {
    x: (this.dimensions.width / 2),
    y: (this.dimensions.height / 2)
  };
  set center(center: PieCenter) {
    if (center != undefined && center != null) {
      if (center.x != undefined && center.x != null)
        this._center.x = center.x;
      if (center.y != undefined && center.y != null)
        this._center.y = center.y;
    }
  };
  get center() {
    return this._center;
  };

  private _config: PieChartConfig = {
    stroke: '#fff',
    strokeWidth: 3,
  };
  @Input()
  set config(config: PieChartConfig) {
    if (config != undefined && config != null) {
      this.dimensions = config.dimensions;
      this.legendConfig = config.legendConfig;
      this.title = config.title;
      this.center = config.center;

      if (config.stroke != undefined && config.stroke != null)
        this._config.stroke = config.stroke;
      if (config.strokeWidth != undefined && config.strokeWidth != null)
        this._config.strokeWidth = config.strokeWidth;
      if (config.colors != undefined && config.colors != null && config.colors.length != 0)
        this.color = d3.scaleOrdinal(config.colors);
      
      // If chart config changes - remove already rendered charts on DOM - draw a new one
      if (this.isDrawn) {
        this.svg.remove();
        this.isDrawn = false;
        this.draw();
      }
    }
  };
  get config() {
    return this._config;
  };

  // private _data: PieChartConfigData[] = [
  //   { value: 140, name: "Electric Bill" },
  //   { value: 100, name: "Biryani" },
  //   { value: 200, name: "Fuel" },
  //   { value: 340, name: "Juice" },
  //   { value: 120, name: "Burger" },
  //   { value: 500, name: "Rent" },
  //   { value: 370, name: "Shoe" }
  // ];
  private _data: PieChartConfigData[] = [];
  @Input()
  set data(data: PieChartConfigData[]) {
    if (data && data.length) {
      this._data = data;
      if (this.isDrawn) {
        this.update(data);
      } else {
        this.draw();
        this.update(data);
      }
    }
  };
  get data() {
    return this._data;
  };

  // global reference
  svg;
  graph;
  pie;
  legend;
  arcPath;
  legendGroup;
  _currentValue;

  // We can set the value manually by passing a array of colors
  // const color = d3.scaleOrdinal(['red', 'purple', 'orange', 'pink']);
  // Or we can use one of the scheme set's
  color = d3.scaleOrdinal(d3['schemeSet3']);

  constructor() { }
  ngOnInit() {
    this.draw();
  }

  draw() {
    if (this.isDrawn === false) {

      this.center = {
        x: (this.center.x) ? this.center.x : (this.dimensions.width / 2),
        y: (this.center.y) ? this.center.y : (this.dimensions.height / 2)
      };

      this.svg = d3.select(this._canvas.nativeElement)
        .append('svg')
        .attr('width', this.dimensions.width + this.legendConfig.width)
        .attr('height', this.dimensions.height + this.legendConfig.height);

      this.graph = this.svg.append('g')
        .attr('transform', `translate(${this.center.x}, ${this.center.y})`);
      // we need to translate it to the center of the svg else it will consider top left as center
      // This pie function will spit out path data to draw pie
      this.pie = d3.pie()
        .sort(null)
        .value((d: any) => d.value); // value by which angles are made
      // This function is used to draw arcs on our pie
      this.arcPath = d3.arc()
        .outerRadius(this.dimensions.radius)
        .innerRadius(this.dimensions.radius / 2);

      // Ordinal Scale: we can use ordinal to set color's to each element 
      // Domain = array of unique names of our element
      // Range = array of color's OR we can use scheme set

      this.svg.append('g')
        .append("text")
        // .attr("transform", "translate(100,0)")
        .attr("x", this.title.x)
        .attr("y", this.title.y)
        .attr("font-size", this.title.fontSize)
        .attr("font-weight", this.title.fontWeight)
        .text(this.title.name)
        .attr('fill', this.title.fontColor)

      if (this.legendConfig.legendNeeded) {
        // legend setup
        this.legendGroup = this.svg.append('g')
          .attr('transform', `translate(${this.legendConfig.x}, ${this.legendConfig.y})`);
        this.legend = legendColor()
          .shape(this.legendConfig.shape)
          .shapePadding(this.legendConfig.shapePadding)
          .scale(this.color);
      }
      this.isDrawn = true;
    }
  };

  update(data: PieChartConfigData[]) {
    // set ordinal scale domain
    this.color.domain(data.map(item => item.name));

    // join enhanced(pie()) data to path element
    const paths = this.graph.selectAll('path')
      .data(this.pie(data));

    // exit elements which are in the dom 
    paths.exit()
      .remove();

    // update all the elements which are already in the DOM
    paths.attr('d', this.arcPath)

    paths.enter()
      .append('path')
      .attr('class', 'arc')
      .attr('d', this.arcPath)
      .attr('stroke', this.config.stroke)
      .attr('stroke-width', this.config.strokeWidth)
      .attr('fill', (innerData: any) => {
        // our data doesn't exist directly, since we pass our data to pie() for enhancement
        return this.color(innerData.data.name);
      })

    if (this.legendConfig.legendNeeded) {
      // call/update legend 
      this.legendGroup.call(this.legend);
      this.legendGroup.selectAll('text')
        .attr('fill', this.legendConfig.text.fontColor);
    }
  };

  ngAfterViewInit() {
    this.update(this.data);
  };
}
