import { createSelector } from "@ngrx/store";
import { initLabClassificationArray } from "../../labs-scans/labs-scan-view-new/utility";
import { labelType } from "../../labs-scans/models/labs-scans";
import * as fromLabScanDocuments from "../reducers";
import {
  DocumentsState,
  documentAdaptor,
} from "../reducers/lab-scan-documents.reducer";

export const getLabDocumentData = createSelector(
  fromLabScanDocuments.getLabState,
  (state) => state.labScanDocuments
);

export const selectSpotlightRecords = createSelector(
  getLabDocumentData,
  (state: DocumentsState) =>
    state.spotLightRecords.map((record: string) => record.trim())
);

export const { selectEntities: getDocumentEntities } =
  documentAdaptor.getSelectors(getLabDocumentData);

export const { selectIds: getDocumentIds } =
  documentAdaptor.getSelectors(getLabDocumentData);

export const { selectAll: getAllDocument } =
  documentAdaptor.getSelectors(getLabDocumentData);

export const getDocumentsEntity = createSelector(
  getDocumentEntities,
  (documents: fromLabScanDocuments.LabState) => documents,
  (document) => document
);

type category = "labs" | "scans" | "documents";

export const getClassificationAndLabels = (category: category) =>
  createSelector(
    getDocumentIds,
    getDocumentsEntity,
    (labId, document): ReadonlyMap<string, labelType[]> => {
      const labClassificationMap = new Map<string, labelType[]>();
      labId?.forEach((id: string | number) => {
        if (document[id].category === category)
          return initLabClassificationArray(document, id, labClassificationMap);
      });
      return labClassificationMap;
    }
  );

export const getCountByCategory = (categoryType: category) =>
  createSelector(getAllDocument, (documents) =>
    documents.filter((doc) => doc.category === categoryType)
  );

export const getFilterByClassification = (classificationType: string) =>
  createSelector(getAllDocument, (documents) =>
    documents.filter((doc) => doc.classifications?.includes(classificationType))
  );

export const getFilterByLabels = (labelType: string[]) =>
  createSelector(getAllDocument, (documents) =>
    documents.filter((doc) => labelType.includes(doc.label))
  );

export const getLabById = (id: string) =>
  createSelector(getAllDocument, (documents) =>
    documents.find((doc) => doc._id === id)
  );
