<div class="main-container">
  <ng-container *ngFor="let param of avatarParams; index as i">
    <div
      *ngIf="i < maximumAvatars"
      class="avatar{{ i }} {{ param.size }} avatar"
    >
      <div
        class="initials {{ param.size }}"
        [ngClass]="[!param.imageUrl && param.name ? 'primary-bg-light' : '']"
      >
        <img *ngIf="param.imageUrl" class="image" [src]="param.imageUrl" />

        <div *ngIf="param.name && !param.imageUrl">
          {{ param.initials }}
        </div>

        <img
          *ngIf="!param.name && !param.imageUrl"
          class="avatar-icon"
          src="../../assets/icons/avatar_group.svg"
        />

        <div *ngIf="param.status" class="status-overlay">
          <div class="{{ param.status }}"></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="i == maximumAvatars"
      class="avatar{{ i }} {{ param.size }} avatar"
    >
      <div class="extras">
        <div class="text">+{{ avatarParams.length - maximumAvatars }}</div>
      </div>
    </div>
  </ng-container>
</div>
