<section class="iris-modal-container form-container add-user">
  <div class="form-title">
    {{ data?.user?._id ? "Edit" : "Register" }} {{ data.userType | lowercase }}
  </div>
  <div class="form-body">
    <form
      class="iris-form"
      [formGroup]="userForm"
      (ngSubmit)="addUser()"
      autocomplete="off"
    >
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Title</mat-label>
              <input
                class="iris-input"
                formControlName="title"
                matInput
                placeholder="Enter the title"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Name*</mat-label>
              <input
                class="iris-input"
                formControlName="name"
                matInput
                placeholder="Enter the name"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Email*</mat-label>
              <input
                class="iris-input"
                formControlName="email"
                matInput
                placeholder="Enter the email"
              />
              <mat-error>Enter a valid email id</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field
              class="form-field"
              [floatLabel]="'always'"
              [hideRequiredMarker]="true"
            >
              <mat-label>Phone number*</mat-label>
              <cp-phone-input
                formControlName="phone"
                placeholder="Enter the phone no."
              >
              </cp-phone-input>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Registration ID</mat-label>
              <input
                class="iris-input"
                formControlName="registration_id"
                matInput
                placeholder="Enter the registration id"
              />
              <mat-error>Enter a valid registration id</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row" *ngIf="isAdmin">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Qualification</mat-label>
              <input
                class="iris-input"
                formControlName="qualification"
                matInput
                placeholder="Enter the qualification"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdmin" class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Role</mat-label>
              <mat-select
                placeholder="Set the role"
                class="iris-input"
                formControlName="role"
              >
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Allowed roles</mat-label>
              <mat-select
                multiple
                placeholder="Set the allowed role"
                class="iris-input"
                formControlName="allowedRoles"
              >
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row" *ngIf="!isAdmin">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Qualification</mat-label>
              <input
                class="iris-input"
                formControlName="qualification"
                matInput
                placeholder="Enter the qualification"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Specialty</mat-label>
              <mat-select
                placeholder="Set the specialty"
                class="iris-input"
                formControlName="speciality"
                [disabled]="
                  userForm?.get('role')?.value != 'Physician' &&
                  !userForm?.get('allowedRoles')?.value?.includes('Physician')
                "
              >
                <mat-option
                  *ngFor="let spOption of specialtyOptions"
                  [value]="spOption"
                  >{{ spOption }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdmin && data?.hospitals?.length" class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Hospitals*</mat-label>
              <mat-select
                (selectionChange)="setHospital()"
                placeholder="Select the hospital"
                class="iris-input"
                formControlName="hospitals"
                multiple
              >
                <mat-option
                  *ngFor="let hospital of data.hospitals"
                  [value]="hospital._id"
                  >{{ hospital.name }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
              <div
                *ngIf="
                  userForm.controls['hospitals'].touched &&
                  userForm.errors?.hospitals
                "
                class="iris-error"
              >
                Note: Add ateast one hospital!
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row" *ngIf="selHospitals?.length">
            <mat-form-field
              *ngIf="selUnits?.length; else noUnitsAdded"
              [floatLabel]="'always'"
              hideRequiredMarker="true"
            >
              <mat-label>{{ unitLabel }}</mat-label>
              <mat-select
                placeholder="Select the units"
                class="iris-input"
                formControlName="units"
                multiple
              >
                <mat-option
                  #selectAll
                  checked
                  value="selectAll"
                  (click)="selectAllUnits()"
                  >Select All</mat-option
                >
                <mat-option
                  *ngFor="let unit of selUnits"
                  [value]="
                    unit.hospitalName + '___' + unit.name + '___' + unit._id
                  "
                  >{{ unit.hospitalName }} - {{ unit.name }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Remarks</mat-label>
              <input
                class="iris-input"
                formControlName="remarks"
                matInput
                placeholder="Enter the remarks"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>User status</mat-label>
              <mat-select
                placeholder="Set user status"
                class="iris-input"
                formControlName="active"
              >
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div *ngIf="isLocked" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Lockout status</mat-label>
              <mat-select
                placeholder="User lockout status"
                class="iris-input"
                formControlName="lockedStatus"
              >
                <mat-option [value]="'locked'">Locked</mat-option>
                <mat-option [value]="'unlock'">Unlock</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right"></div>
      </div>
      <div class="iris-v-row password-section">
        <div class="row-left">
          <div *ngIf="!addUserFlag" class="iris-form-row">
            <mat-slide-toggle
              (change)="passwordToggle($event)"
              [checked]="false"
              >Update password?</mat-slide-toggle
            >
          </div>
          <div *ngIf="updatePassword" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Password</mat-label>
              <input
                type="password"
                class="iris-input"
                formControlName="password"
                matInput
                placeholder="Type in the password"
              />
              <mat-hint>
                <mat-icon inline="true">warning</mat-icon>
                This will reset the user's password
              </mat-hint>
              <mat-error>Enter a valid password</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="updatePassword" class="row-right">
          <div class="iris-form-row password-error-row">
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'minlength'
                )
              "
              >Must be at least 8 characters long</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasNumber'
                )
              "
              >Must contain at least 1 number</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasCapitalCase'
                )
              "
              >Must contain at least 1 uppercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasSmallCase'
                )
              "
              >Must contain at least 1 lowercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasSpecialCharacters'
                )
              "
              >Must contain at least 1 special character</mat-checkbox
            >
          </div>
        </div>
      </div>

      <mat-dialog-actions align="end">
        <div *ngIf="serverError" class="iris-error mr">
          {{ serverError }}
        </div>
        <button
          class="button-secondary"
          type="button"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
        <button
          [disabled]="!userForm.valid || formLoading"
          type="submit"
          class="button-primary"
          mat-flat-button
        >
          Submit
          <app-loader [buttonLoader]="true" *ngIf="formLoading"></app-loader>
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</section>

<ng-template #noUnitsAdded>
  <div class="form-info">No units added</div>
</ng-template>
