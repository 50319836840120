<app-loader *ngIf="infectionLoading$ | async"></app-loader>
<section class="vitals-section">
  <div class="day-select-row">
    <app-day-selector
      (setSelectedDay)="getSelectedDay($event)"
      (setSelectedDate)="getSelectedDate($event)"
      [days]="days"
      [vitals]="infectionsArray"
      [selectedDay]="selectedDay"
      [selectedDate]="selectedDate"
      [displayGraphButtons]="false"
    ></app-day-selector>
    <button
      class="button-primary"
      [disabled]="
        selectedDate !== currDate ||
        (infectionsArray | vitalDay: selectedDay)?.length >= 4 ||
        editCheckListIndex ||
        editCheckListIndex === 0
      "
      *ngxPermissionsOnly="['create_infections']"
      (click)="createNewCheckList(0, false)"
    >
      Add entry
    </button>
  </div>
  <div class="vital-view-entry">
    <div class="question-sets">
      <table class="question-table cp-table-2">
        <thead>
          <tr
            class="cp-table-2__header-width table-header cp-table-2__main-header"
          >
            <th>Ventilator Associated Pneumonia (VAP)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            [ngClass]="{ isHeader: infQ?.type === questionTypes.HEADER }"
            *ngFor="let infQ of defaultQuestions"
          >
            <td>
              {{ infQ?.name }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
          </tr>
        </tfoot>
      </table>

      <!-- multiple infections -->
      <ng-container
        *ngIf="infectionsArray | vitalDay: selectedDay; let infArray"
      >
        <ng-container
          *ngFor="let infColumnData of infArray; index as recordIndex"
        >
          <app-inf-table
            #infectionTables
            [defaultQuestions]="defaultQuestions"
            [infectionBundle]="infColumnData"
            [isEditTable]="editCheckListIndex === recordIndex"
            [editCheckListStore]="editCheckListStore"
            (enableEdit)="editCheckList(infColumnData, recordIndex)"
            (submitTable)="submitCheckList()"
            (submitComment)="saveInputComment($event)"
          ></app-inf-table>
        </ng-container>

        <!-- <ng-container
          *ngIf="
            selectedDate === currDate &&
            infArray?.length <= 3 &&
            !(infArray?.length === 1 && infArray[0]?.isNewChecklist) &&
            !editCheckListIndex
          "
        >
          <mat-icon
            class="cp-icon cursor-pointer"
            *ngxPermissionsOnly="['create_infections']"
            (click)="createNewCheckList(infArray?.length, false)"
            >add</mat-icon
          >
        </ng-container> -->
      </ng-container>
    </div>
  </div>
</section>
