import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "cp-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit, OnChanges {
  isDown: boolean = false;
  offset = [];

  @Input() value: number = 0;
  @Output() change = new EventEmitter<number>();
  @Input() buffered: number = 0;

  @ViewChild("progress", { static: false })
  progress!: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      this.updatePercentage(this.value);
    }
  }

  public percentage = 0;

  ngOnInit(): void {
    this.updatePercentage(this.value);
  }

  public updatePercentage(percentValue: number) {
    if (percentValue < 0 || percentValue > 100) return;
    this.percentage = percentValue;
  }

  progressChange(event: any) {
    this.percentage = this.progressPer(event.clientX);
    this.change.emit(this.percentage);
    this.onMouseDown();
  }

  onMouseDown(): void {
    this.isDown = true;
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e: any) {
    if (this.isDown) {
      const percentageValue = this.progressPer(e.clientX);
      this.updatePercentage(percentageValue);
      this.change.emit(percentageValue);
    }
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUp() {
    this.isDown = false;
  }

  progressPer(xValue: any): number {
    const mouseMoved =
      xValue - this.progress.nativeElement.getBoundingClientRect().left;
    return (mouseMoved / this.progress.nativeElement.offsetWidth) * 100;
  }
}
