import { Component, Input } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { presetType } from "src/app/models/Orderable.model";

@Component({
  selector: "preset-fields",
  templateUrl: "./preset.component.html",
  styleUrls: ["./preset.component.scss"],
})
export class PresetComponent {
  constructor(public controlContainer: ControlContainer) {}

  @Input() config;

  public formTypes = presetType;

  get form() {
    return this.controlContainer.control;
  }

  get presetName() {
    return this.form.get("presetName");
  }
}
