import { Action, createReducer, on } from "@ngrx/store";
import * as FromTypesActions from "../actions/note-input-form.actions";
import { NoteContent } from "@notes/models/notes.model";
import { FinalNotes } from "@notes-view/model/notes-model";
export const INITIAL_REFID = "new";

export const initialState: FinalNotes = {
  refId: INITIAL_REFID,
  noteType: "",
  noteSubType: "",
  components: [],
  addendum: [],
};

const _noteContentReducer = createReducer(
  initialState,
  on(FromTypesActions.loadNoteContentSuccess, (state, { noteContents }) => ({
    ...state,
    ...noteContents,
  })),
  on(FromTypesActions.resetNoteContent, () => initialState)
);

export function reducer(state: NoteContent, action: Action) {
  return _noteContentReducer(state, action);
}
