import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-request-search-input",
  templateUrl: "./request-search-input.component.html",
  styleUrls: ["./request-search-input.component.scss"],
})
export class RequestSearchInputComponent implements OnInit, OnDestroy {
  filterSearch: UntypedFormControl;
  ngDestroyed$ = new Subject<void>();
  @Output() filterSearh = new EventEmitter<string>();
  constructor() {
    this.filterSearch = new UntypedFormControl("");
  }

  ngOnInit(): void {
    this.filterSearch.valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((val) => {
        this.filterSearh.emit(val);
      });
  }

  isInputEmpty() {
    return !this.filterSearch.value;
  }

  clearInput() {
    this.filterSearch.setValue("");
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
