import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { ProcedurePreset } from "../../../models/preset/ProcedurePreset.model";

@Component({
  selector: "app-protocol-procedure-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="procedure.patientType"
        [ageGroup]="procedure.patientType"
        [id]="procedure._id"
      ></age-group-view>
      <div class="name"><strong>Type: </strong> {{ procedure.pType }}</div>
      <div class="" *ngIf="procedure.name">
        <strong>Name: </strong> {{ procedure.name }}
      </div>

      <div class=""><strong>Site: </strong> {{ procedure.site }}</div>
      <div class="" *ngIf="procedure.laterality">
        <strong>Laterality: </strong> {{ procedure.laterality }}
      </div>

      <div class="" *ngIf="procedure.numberOfDoses">
        <strong>Instances: </strong>{{ procedure.numberOfDoses }}
      </div>
      <div class="" *ngIf="procedure.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{
          orderService.getFrequencyDisplayName(procedure.frequency)
        }}</span>
      </div>
      <div class="" *ngIf="procedure.urgency">
        <strong>Urgency: </strong>{{ procedure.urgency }}
      </div>

      <div class=" details" *ngIf="procedure.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ procedure.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="procedure.instructions">
        <strong>Instructions</strong>:
        <em>{{ procedure.instructions }}</em>
      </div>

      <div class=" details" *ngIf="procedure.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ procedure.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolProcedureViewComponent {
  @Input() procedure: ProcedurePreset;

  constructor(public orderService: OrderService) {}
}
