<section
  class="fall-risk-container input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form fall-risk-form"
    autocomplete="off"
    [formGroup]="apgarForm"
    (ngSubmit)="submitFalls(apgarForm.value)"
  >
    <div class="sub-tittle" *ngIf="!admitFormIsParent">APGAR Score:</div>
    <hr *ngIf="!admitFormIsParent" />
    <div class="input-form-header">
      <div class="score">
        <div>
          {{ apgarScore }}
        </div>
      </div>
    </div>
    <div class="form_fields">
      <div class="iris-form-row">
        <label for="activity"
          >Activity
          <small>&nbsp;(Muscle tone):</small>
        </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="activity"
          formControlName="activity"
        >
          <mat-button-toggle [value]="0">Limp</mat-button-toggle>
          <mat-button-toggle [value]="1">Some flexion</mat-button-toggle>
          <mat-button-toggle [value]="2">Active motion</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="pulse">Pulse </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="pulse"
          formControlName="pulse"
        >
          <mat-button-toggle [value]="0">Absent</mat-button-toggle>
          <mat-button-toggle [value]="1"> &lt;100 / min</mat-button-toggle>
          <mat-button-toggle [value]="2"> &gt;100 / min</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="grimace"
          >Grimace
          <small>&nbsp;(Reflex irritability):</small>
        </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="grimace"
          formControlName="grimace"
        >
          <mat-button-toggle [value]="0">No response</mat-button-toggle>
          <mat-button-toggle [value]="1">Grimace</mat-button-toggle>
          <mat-button-toggle [value]="2"
            >Cry / Active withdrawal</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="appearance"
          >Appearance <small>&nbsp;(Skin color):</small></label
        >
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="appearance"
          formControlName="appearance"
        >
          <mat-button-toggle [value]="0">Blue; Pale</mat-button-toggle>
          <mat-button-toggle [value]="1">Acrocyanotic</mat-button-toggle>
          <mat-button-toggle [value]="2">Completely pink</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="iris-form-row">
        <label for="respiration">Respiration:</label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="respiration"
          formControlName="respiration"
        >
          <mat-button-toggle [value]="0">Absent</mat-button-toggle>
          <mat-button-toggle [value]="1"
            >Weak cry; Hypoventilation</mat-button-toggle
          >
          <mat-button-toggle [value]="2">Good crying</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row" *ngIf="!admitFormIsParent">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!apgarForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'apgar'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
