import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { PlayerComponent } from "./components/player/player.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { IrisComponentsModule } from "../iris-components.module";

@NgModule({
  declarations: [PlayerComponent, ProgressBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    IrisComponentsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
  ],
  exports: [PlayerComponent],
})
export class PlayerModule {}
