import { Action } from "@ngrx/store";
import { File, FileSigned } from "../../labs-scans/models/File.model";

// load lab values

export const UPLOAD_FILE = "[LAB] Upload File";
export const UPLOAD_FILE_FAIL = "[LAB] Upload File Fail";
export const UPLOAD_FILE_GGP = "[LAB] Upload File Gcp";
export const UPLOAD_FILE_SUCCESS = "[LAB] Upload File Success";
export const UPLOAD_FILE_RESET = "[LAB] Upload File Reset";
export const UPLOAD_GCP_SUCCESS = "[LAB] Upload Gcp Success";

export const VISION_TASK_INIT = "[LAB] Upload Vision Task";
export const VISION_TASK_INIT_SUCCESS = "[LAB] Upload Vision Task Success";
export const VISION_TASK_INIT_FAIL = "[LAB] Upload Vision Task Fail";

export class UploadFile implements Action {
  readonly type = UPLOAD_FILE;
  constructor(
    public payload: {
      file: FileSigned;
      fileData: File;
    }
  ) {}
}

export class UploadFileFail implements Action {
  readonly type = UPLOAD_FILE_FAIL;
  constructor(public payload: any) {}
}

export class UploadFileSuccess implements Action {
  readonly type = UPLOAD_FILE_SUCCESS;
  constructor(public payload) {}
}

export class UploadFileGcp implements Action {
  readonly type = UPLOAD_FILE_GGP;
  constructor(public payload: any) {}
}

export class UploadFileGcpSucsess implements Action {
  readonly type = UPLOAD_GCP_SUCCESS;
  constructor(public payload: any) {}
}

export class UploadFileReset implements Action {
  readonly type = UPLOAD_FILE_RESET;
  constructor(public payload) {}
}

export class sendVisionTask implements Action {
  readonly type = VISION_TASK_INIT;
  constructor(public payload: any) {}
}

export class sendVisionTaskSuccess implements Action {
  readonly type = VISION_TASK_INIT_SUCCESS;
  constructor(public payload: any) {}
}

export class sendVisionTaskFail implements Action {
  readonly type = VISION_TASK_INIT_FAIL;
  constructor(public payload: any) {}
}

export type UploadFileAction =
  | UploadFile
  | UploadFileFail
  | UploadFileSuccess
  | UploadFileGcp
  | UploadFileReset
  | sendVisionTask
  | sendVisionTaskSuccess
  | sendVisionTaskFail
  | UploadFileGcpSucsess;
