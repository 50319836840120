import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { BradenFormService } from "./braden-form.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Vitals } from "src/app/vitals/models/Vital";
import { setFormVal } from "src/app/vitals/support/vitals.support";

@Component({
  selector: "app-braden",
  templateUrl: "./braden.component.html",
  styleUrls: ["./braden.component.scss"],
})
export class BradenComponent implements OnInit {
  @Output() getFormVal: EventEmitter<any> = new EventEmitter();

  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description braden form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  public rBradenForm: UntypedFormGroup = this._bradenForm.initFallRiskForm();

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Variables to store the braden score and related values
   */
  public bradenScore = 0;
  public sensory = 0;
  public moisture = 0;
  public activity = 0;
  public mobility = 0;
  public nutrition = 0;
  public friction = 0;

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }

  @Input() minDate;
  @Input() maxDate;

  // passing from initial-assessment form [ admit form ]
  @Input() admitFormIsParent: boolean = false;

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.rBradenForm.get("timestamp");
  }

  constructor(
    private store: Store<{}>,
    private _bradenForm: BradenFormService,
    private _alertService: AlertService
  ) {}

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    this.bradenScore = vitalData.daysBraden || 0;

    setFormVal(
      this.rBradenForm,
      "sensory",
      vitalData.daysBradenSensory ? vitalData.daysBradenSensory : null
    );
    setFormVal(
      this.rBradenForm,
      "moisture",
      vitalData.daysBradenMoisture ? vitalData.daysBradenMoisture : null
    );
    setFormVal(
      this.rBradenForm,
      "activity",
      vitalData.daysBradenActivity ? vitalData.daysBradenActivity : null
    );
    setFormVal(
      this.rBradenForm,
      "mobility",
      vitalData.daysBradenMobility ? vitalData.daysBradenMobility : null
    );
    setFormVal(
      this.rBradenForm,
      "nutrition",
      vitalData.daysBradenNutrition ? vitalData.daysBradenNutrition : null
    );
    setFormVal(
      this.rBradenForm,
      "friction",
      vitalData.daysBradenFriction ? vitalData.daysBradenFriction : null
    );
    if (vitalData.timestamp)
      setFormVal(this.rBradenForm, "timestamp", vitalData.timestamp);
  }

  /**
   * @description This calculates the braden score based on the changes
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  onBradenInput() {
    this.bradenScore =
      this.rBradenForm.value.sensory +
      this.rBradenForm.value.moisture +
      this.rBradenForm.value.activity +
      this.rBradenForm.value.mobility +
      this.rBradenForm.value.nutrition +
      this.rBradenForm.value.friction;
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.rBradenForm.reset();
    this.rBradenForm.get("timestamp").setValue(null);

    /** Set all values to 0 */
    this.bradenScore = 0;
    this.sensory = 0;
    this.moisture = 0;
    this.activity = 0;
    this.mobility = 0;
    this.nutrition = 0;
    this.friction = 0;

    this.formState.error = null;
  }

  /**
   * @description To submit the braden score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitBraden(patDataFromForm) {
    this.store.dispatch(vitalActions.submitForm({ loadType: "braden" }));

    const bradenProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      daysBraden: this.bradenScore,
      daysBradenSensory: patDataFromForm.sensory,
      daysBradenMoisture: patDataFromForm.moisture,
      daysBradenActivity: patDataFromForm.activity,
      daysBradenMobility: patDataFromForm.mobility,
      daysBradenNutrition: patDataFromForm.nutrition,
      daysBradenFriction: patDataFromForm.friction,
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: bradenProps },
      })
    );
  }

  ngOnInit() {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Braden Scale updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.rBradenForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
