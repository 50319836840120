<section class="rass-container input-form">
  <form
    class="iris-form rass-form"
    autocomplete="off"
    [formGroup]="rRassForm"
    (ngSubmit)="submitRass(rRassForm.value)"
  >
    <!-- <div class="form_head position-sticky fixed-top top-forty">
      <h4 class="p-2 cp-clr-primary">
        <div>CAM-ICU
          <span *ngIf="isDelirium == null">Cannot be Determined</span>
          <span *ngIf="isDelirium == true" [ngClass]="['badge badge-danger']">Positive: Delirium present</span>
          <span *ngIf="isDelirium == false" [ngClass]="['badge badge-success']">Negative: No Delirium</span>
        </div>
        <div>
          RASS Score
          <b *ngIf="rassScore" [ngClass]="['badge badge-info']">
            <span *ngIf="rassScore > 0">+</span>{{rassScore}}
          </b>
          <b *ngIf="!rassScore">NA</b>
        </div>
      </h4>
    </div> -->
    <div class="sub-tittle">RASS:</div>
    <hr />
    <div class="input-form-header">
      <div class="score">
        <div>
          {{ rassScore > 0 ? "+ " : "" }} {{ rassScore ? +rassScore : "NA" }}
        </div>
      </div>
    </div>
    <div class="form_fields">
      <!-- <div class="form-row mt-2">
        <div class="card mt-2">
          <div class="card-header">
            <label for="rassScore"
              >Richmond Agitation-Sedation Scale (RASS)*</label
            >
          </div>
          <div class="card-body">
            <select
              class="col-md-12 cp-select"
              formControlName="rassScore"
              (change)="rassSelected($event)"
            >
              <option value="4">
                +4: Combative - Overtly combative/violent; immediate danger to
                staff
              </option>
              <option value="3">
                +3: Very agitated - Pulls to remove tubes/catheters or
                aggressive towards staff
              </option>
              <option value="2">
                +2: Agitated - Frequent non-purposeful
                movement/patient-ventilator dyssynchrony
              </option>
              <option value="1">
                +1: Restless - Anxious, apprehensive but movements not
                aggressive/vigorous
              </option>
              <option value="0">
                &nbsp;0: Alert and calm - Spontaneously pays attention to
                caregiver
              </option>
              <option value="-1">
                -1: Drowsy - Not fully alert, has sustained awakening (more than
                10 sec), with eye contact, to voice
              </option>
              <option value="-2">
                -2: Light sedation - Briefly (less than 10 seconds) awakens with
                eye contact to voice
              </option>
              <option value="-3">
                -3: Moderate sedation - Any movement (but no eye contact) to
                voice
              </option>
              <option value="-4">
                -4: Deep sedation - No response to voice, but any movement to
                physical stimulation
              </option>
              <option value="-5">
                -5: Unarousable - No response to voice or physical stimulation
              </option>
            </select>
          </div>
        </div>
      </div> -->

      <div class="iris-form-row margin--0">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Richmond agitation-sedation scale (RASS)*</mat-label>
          <mat-select
            (selectionChange)="rassSelected($event)"
            formControlName="rassScore"
          >
            <mat-option [value]="4"
              >+4: Combative - Overtly combative/violent; immediate danger to
              staff</mat-option
            >
            <mat-option [value]="3"
              >+3: Very agitated - Pulls to remove tubes/catheters or aggressive
              towards staff</mat-option
            >
            <mat-option [value]="2"
              >+2: Agitated - Frequent non-purposeful
              movement/patient-ventilator dyssynchrony</mat-option
            >
            <mat-option [value]="1"
              >+1: Restless - Anxious, apprehensive but movements not
              aggressive/vigorous</mat-option
            >
            <mat-option [value]="0"
              >&nbsp;0: Alert and calm - Spontaneously pays attention to
              caregiver</mat-option
            >
            <mat-option [value]="-1"
              >-1: Drowsy - Not fully alert, has sustained awakening (more than
              10 sec), with eye contact, to voice</mat-option
            >
            <mat-option [value]="-2"
              >-2: Light sedation - Briefly (less than 10 seconds) awakens with
              eye contact to voice</mat-option
            >
            <mat-option [value]="-3"
              >-3: Moderate sedation - Any movement (but no eye contact) to
              voice</mat-option
            >
            <mat-option [value]="-4"
              >-4: Deep sedation - No response to voice, but any movement to
              physical stimulation</mat-option
            >
            <mat-option [value]="-5"
              >-5: Unarousable - No response to voice or physical
              stimulation</mat-option
            >
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </div>

      <div class="iris-form-row">
        <label for="rassAssessDeliriumYN"> Assess delirium: </label>
        <br />
        <mat-button-toggle-group
          name="rassAssessDeliriumYN"
          formControlName="rassAssessDeliriumYN"
        >
          <mat-button-toggle [value]="'true'">Yes</mat-button-toggle>
          <mat-button-toggle [value]="'no'">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="rass-worksheet" *ngIf="!isAcuteCollapsed">
        <div class="sub-tittle">CAM-ICU Worksheet</div>
        <hr />

        <div class="info">
          <div>
            <span *ngIf="isDelirium === null" class="background-light"
              >Cannot be determined</span
            >
            <span *ngIf="isDelirium === true" class="background-danger"
              >Positive: Delirium present</span
            >
            <span *ngIf="isDelirium === false" class="background-success"
              >Negative: No Delirium</span
            >
          </div>
        </div>
        <div class="feature-row" *ngIf="!isAcuteCollapsed">
          <b class="row-header">Feature 1: Acute Onset or Fluctuating Course</b>

          <div class="iris-form-row">
            <label for="acuteYN"
              >Is the patient's mental status different than
              baseline/pre-hospital mental status?
            </label>
            <mat-button-toggle-group
              (change)="acuteChanged($event)"
              name="acuteYN"
              formControlName="acuteYN"
            >
              <mat-button-toggle [value]="'Yes'">Yes</mat-button-toggle>
              <mat-button-toggle [value]="'No'">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-divider></mat-divider>

          <div class="iris-form-row">
            <label for="fluctYN"
              >Has the patient had any fluctuation in mental status in the past
              24 hours as evidenced by fluctuation on a sedation/level of
              consciousness scale (i.e., RASS/SAS), GCS, or previous delirium
              assessment?
            </label>
            <mat-button-toggle-group
              (change)="fluctChanged($event)"
              name="fluctYN"
              formControlName="fluctYN"
            >
              <mat-button-toggle [value]="'Yes'">Yes</mat-button-toggle>
              <mat-button-toggle [value]="'No'">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="feature-row" *ngIf="!isInattentionCollapsed">
          <b class="row-header">Feature 2: Inattention</b>

          <div class="iris-form-row">
            <div class="instructions">
              Letters attention test: Count the number of errors.
              <br />
              <small
                >Directions: Say to the patient, "I am going to read you a
                series of 10 letters. Whenever you hear the letter 'A', indicate
                by squeezing my hand." Read letters from the following letter
                list in a normal tone 3 seconds apart.<br />S-A-V-E-H-A-A-R-T or
                C-A-S-A-B-L-A-N-C-A or A-B-A-D-B-A-D-A-A-Y.<br />Errors are
                counted when patient fails to squeeze on the letter 'A' and when
                the patient squeezes on any letter other than 'A'</small
              >
            </div>
            <label for="inattentionYN"
              >Did the patient make 3 or more errors?
            </label>
            <br />
            <mat-button-toggle-group
              (change)="inattentionChanged($event)"
              name="inattentionYN"
              formControlName="inattentionYN"
            >
              <mat-button-toggle [value]="'Yes'">Yes</mat-button-toggle>
              <mat-button-toggle [value]="'No'">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="feature-row" *ngIf="!isAlteredDisorganizedCollapsed">
          <b class="row-header">Feature 3: Altered Level of Consciousness</b>

          <div class="iris-form-row">
            <div class="instructions">
              <span *ngIf="rassScore != 0">
                RASS score for the patient is {{ rassScore }}. As this score is
                NOT equal to 0 (alert and calm), "Feature 4" below has no effect
                on the CAM-ICU computation.
              </span>
              <span *ngIf="rassScore == 0">
                Please proceed to Feature 4 below since the RASS score is 0
                (alert and calm).
              </span>
            </div>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="feature-row" *ngIf="!isAlteredDisorganizedCollapsed">
          <b class="row-header">Feature 4: Disorganized thinking</b>

          <div class="iris-form-row">
            <div class="instructions">
              Follow the directions below: Count the number of errors.
              <br />
              <div>
                <small>
                  <strong>Yes/No Questions</strong><br />
                  <ol>
                    <li>Will a stone float on water?</li>
                    <li>Are there fish in the sea?</li>
                    <li>Does one kilogram weigh more than two kilograms?</li>
                    <li>Can scissors be used to cut paper?</li>
                  </ol></small
                >
              </div>
              <div>
                <small>
                  <strong>Command</strong><br />
                  Say to patient: “Hold up this many fingers” (Hold 2 fingers in
                  front of patient) “Now do the same thing with the other hand”
                  (Do not repeat number of fingers). If the patient is unable to
                  move both arms, for 2nd part of command ask patient to “Add
                  one more finger”</small
                >
              </div>
            </div>
            <label for="disorganizedYN"
              >Did the patient make 2 or more errors?
            </label>
            <br />
            <mat-button-toggle-group
              (change)="disorganizedChanged($event)"
              name="disorganizedYN"
              formControlName="disorganizedYN"
            >
              <mat-button-toggle [value]="'Yes'">Yes</mat-button-toggle>
              <mat-button-toggle [value]="'No'">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <mat-divider></mat-divider>
        </div>

        <!-- <div class="form-row mt-2">
          <div id="acuteCard" class="mt-2" [ngbCollapse]="isAcuteCollapsed">
            <div class="card mt-2">
              <div
                class="card-header cp-clr-info-s d-flex justify-content-center"
              >
                <h4>CAM-ICU Worksheet</h4>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <label for="acuteCard"
                  >Feature 1: Acute Onset or Fluctuating Course</label
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-9">
                    Is the patient's mental status different than
                    baseline/pre-hospital mental status?
                  </div>
                  <div class="col-lg-3">
                    <div>
                      <span
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        formControlName="acuteYN"
                        (change)="acuteChanged($event)"
                      >
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'No'" />No
                        </label>
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'Yes'" />Yes
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-lg-9">
                    Has the patient had any fluctuation in mental status in the
                    past 24 hours as evidenced by fluctuation on a
                    sedation/level of consciousness scale (i.e., RASS/SAS), GCS,
                    or previous delirium assessment?
                  </div>
                  <div class="col-lg-3">
                    <div>
                      <span
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        formControlName="fluctYN"
                        (change)="fluctChanged($event)"
                      >
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'No'" />No
                        </label>
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'Yes'" />Yes
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="form-row mt-2">
          <div
            id="inattentionCard"
            class="mt-2"
            [ngbCollapse]="isInattentionCollapsed"
          >
            <div class="card">
              <div class="card-header">
                <label for="inattentionCard">Feature 2: Inattention</label>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-9">
                    Letters attention test: Count the number of errors.
                    <div class="small text-muted">
                      Directions: Say to the patient, "I am going to read you a
                      series of 10 letters. Whenever you hear the letter 'A',
                      indicate by squeezing my hand." Read letters from the
                      following letter list in a normal tone 3 seconds apart.<br />S-A-V-E-H-A-A-R-T
                      or C-A-S-A-B-L-A-N-C-A or A-B-A-D-B-A-D-A-A-Y.<br />Errors
                      are counted when patient fails to squeeze on the letter
                      'A' and when the patient squeezes on any letter other than
                      'A'
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div>
                      <div class="mb-2">
                        Did the patient make 3 or more errors?
                      </div>
                      <span
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        formControlName="inattentionYN"
                        (change)="inattentionChanged($event)"
                      >
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'No'" />No
                        </label>
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'Yes'" />Yes
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="form-row mt-2">
          <div
            id="alteredCard"
            class="mt-2"
            [ngbCollapse]="isAlteredDisorganizedCollapsed"
          >
            <div class="card">
              <div class="card-header">
                <label for="alteredCard"
                  >Feature 3: Altered Level of Consciousness</label
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <span *ngIf="rassScore != 0">
                      RASS score for the patient is {{ rassScore }}. As this
                      score is NOT equal to 0 (alert and calm), "Feature 4"
                      below has no effect on the CAM-ICU computation.
                    </span>
                    <span *ngIf="rassScore == 0">
                      Please proceed to Feature 4 below since the RASS score is
                      0 (alert and calm).
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="form-row mt-2">
          <div
            id="disorganizedCard"
            class="mt-2"
            [ngbCollapse]="isAlteredDisorganizedCollapsed"
          >
            <div class="card">
              <div class="card-header">
                <label for="disorganizedCard"
                  >Feature 4: Disorganized thinking</label
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-9">
                    Follow the directions below: Count the number of errors.
                    <div class="text-muted small">
                      <strong>Yes/No Questions</strong><br />
                      1. Will a stone float on water?<br />
                      2. Are there fish in the sea?<br />
                      3. Does one kilogram weigh more than two kilograms?<br />
                      4. Can scissors be used to cut paper?<br />
                      <div>
                        <strong>Command</strong><br />
                        Say to patient: “Hold up this many fingers” (Hold 2
                        fingers in front of patient) “Now do the same thing with
                        the other hand” (Do not repeat number of fingers). If
                        the patient is unable to move both arms, for 2nd part of
                        command ask patient to “Add one more finger”
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div>
                      <div class="mb-2">
                        Did the patient make 2 or more errors?
                      </div>
                      <span
                        class="btn-group btn-group-toggle"
                        ngbRadioGroup
                        formControlName="disorganizedYN"
                        (change)="disorganizedChanged($event)"
                      >
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'No'" />No
                        </label>
                        <label ngbButtonLabel class="cp-btn cp-outline-misc-p">
                          <input ngbButton type="radio" [value]="'Yes'" />Yes
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!rRassForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'rass'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
