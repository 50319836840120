export interface SharedState {
  loader: { [loaderName: string]: LoaderState };
  errorMessage: string;
}

export interface LoaderState {
  showLoading: boolean;
}

export const initialState: SharedState = {
  loader: {},
  errorMessage: "",
};
