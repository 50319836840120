<button
  mat-flat-button
  class="button-primary"
  *ngxPermissionsOnly="['aira']"
  (click)="get()"
  [disabled]="disableBtn"
  [ngClass]="{ 'cp-disabled': disableBtn }"
>
  <app-loader [buttonLoader]="true" *ngIf="enableLoader"></app-loader>
  aiᴙa
</button>
