import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ShiftService {
  env = environment;

  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  getUnits(params = null): Observable<any> {
    const options = {
      params: params ? new HttpParams().set("active", params.active) : null,
    };

    return this.http
      .get<any>(`${this.env.apiUrl}units/`, options)
      .pipe(catchError(this.errorHandler));
  }

  addUnits(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/", data)
      .pipe(catchError(this.errorHandler));
  }

  assignShifts(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/shifts/", data)
      .pipe(catchError(this.errorHandler));
  }

  assignShiftsDoc(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/shifts_doc/", data)
      .pipe(catchError(this.errorHandler));
  }

  assignShiftsNp(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/shifts_np/", data)
      .pipe(catchError(this.errorHandler));
  }

  assignAllShifts(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/shifts/assignAll", data)
      .pipe(catchError(this.errorHandler));
  }

  assignAllWorkspace(data): Observable<any> {
    return this.http
      .patch<any>(this.env.apiUrl + "workspace/assign", data)
      .pipe(catchError(this.errorHandler));
  }

  editUnits(data): Observable<any> {
    return this.http
      .post<any>(this.env.apiUrl + "units/edit", data)
      .pipe(catchError(this.errorHandler));
  }
}
