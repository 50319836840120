<div
  class="notification-bar"
  (click)="openNotification()"
  [ngClass]="{ 'notification-unread': isUnread }"
>
  <ng-container [ngSwitch]="notificationType">
    <!-- Netra missed notification  -->
    <ng-container *ngSwitchCase="notifications.netraMissedVital">
      <img src="assets/icons/notification-icons/netra_notification.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">Netra attempt unsuccessful</div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="message">
          {{
            "Couldn't pick vitals for " +
              notificationContent.notification_data.length +
              " patient" +
              (notificationContent.notification_data.length > 1 ? "s." : ".")
          }}
        </div>
      </div>
    </ng-container>

    <!-- Netra not Verified notification  -->
    <ng-container *ngSwitchCase="notifications.netraNotVerified">
      <img src="assets/icons/notification-icons/netra_notification.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">Netra not verified</div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="message">
          {{
            "Netra verification is pending for " +
              notificationContent.notification_data.length +
              " patient" +
              (notificationContent.notification_data.length > 1 ? "s." : ".")
          }}
        </div>
      </div>
    </ng-container>

    <!-- Abnormalvital notification  -->
    <ng-container *ngSwitchCase="notifications.AbnormalVital">
      <img
        src="assets/icons/notification-icons/error_outline.svg"
        (click)="openVitalPage()"
      />
      <div class="notification-text" (click)="openVitalPage()">
        <div class="notification-title">
          <div class="header">
            {{ "Abnormal Vitals detected for " + notificationContent.name }}
            <!-- <img
              [src]="
                notificationContent.is_resolved
                  ? 'assets/icons/notification-icons/resolved.svg'
                  : 'assets/icons/notification-icons/unresolved.svg'
              "
              style="margin-left: 8px"
              (click)="toggleResolve()"
              [cpPopover]="popoverElement"
            />
            <ng-template #popoverElement>
              <div class="popover-element">
                {{
                  "Mark as " +
                    (notificationContent.is_resolved
                      ? "unresolved"
                      : "resolved")
                }}
              </div>
            </ng-template> -->
          </div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="message">
          <p>
            Abnormal value in <b>{{ abnormalVitals }} </b>
            is detected for patient with
            <span class="primary-text">
              CPMRN:{{ notificationContent.cpmrn }}, bed no
              {{ notificationContent.bed_number }}
            </span>
            at
            <span class="primary-text">
              {{ notificationContent.hospital }}/{{ notificationContent.unit }}.
            </span>
          </p>
        </div>
      </div>
    </ng-container>

    <!-- Codeblue Raised notification  -->
    <ng-container *ngSwitchCase="notifications.codeblueRaised">
      <img src="assets/icons/code_blue.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">
            {{
              "CodeBlue raised " +
                (notificationContent.name === "-"
                  ? ""
                  : "for " + notificationContent.name)
            }}
          </div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="notification-content">
          <p>
            {{
              "Code Blue alert has been triggered " +
                (notificationContent.cpmrn === "-" ? "" : "for patient with ")
            }}
            <span
              class="primary-text"
              *ngIf="notificationContent.cpmrn !== '-'"
            >
              CPMRN:{{ notificationContent.cpmrn }}, bed no
              {{ notificationContent.bed_number }}
            </span>
            at
            <span class="primary-text">
              {{ notificationContent.hospital }}/{{ notificationContent.unit }}.
            </span>
          </p>
          <div>Raised by: {{ notificationContent.raised_by_name }}</div>
          <div *ngIf="notificationContent.is_running">
            Time Passed: {{ timePassed }}
          </div>
          <a
            *ngIf="notificationContent.is_running"
            [href]="convertToHTTPS(notificationContent.google_meet_link)"
            target="_blank"
          >
            <div class="google-meet-link">
              <img src="/assets/icons/notification-icons/google_meet.svg" />
              <div class="google-meet-link-text">Join with Google Meet</div>
            </div>
          </a>
          <div class="google-meet-link" *ngIf="notificationContent.is_running">
            <img src="/assets/icons/notification-icons/end_call.svg" />
            <div class="google-meet-link-text" (click)="endAlertEvent()">
              End Codeblue call
            </div>
          </div>
          <div *ngIf="!notificationContent.is_running">
            Call Duration: {{ calculateTimeDuration() }}
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Codeblue Cancelled notification  -->
    <ng-container *ngSwitchCase="notifications.codeblueCancelled">
      <img src="assets/icons/code_blue.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">
            {{
              "CodeBlue cancelled " +
                (notificationContent.name === "-"
                  ? ""
                  : "for " + notificationContent.name)
            }}
          </div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="notification-content">
          <p>
            {{
              "Code Blue alert has been cancelled " +
                (notificationContent.cpmrn === "-" ? "" : "for patient with ")
            }}
            <span
              class="primary-text"
              *ngIf="notificationContent.cpmrn !== '-'"
            >
              CPMRN:{{ notificationContent.cpmrn }}, bed no
              {{ notificationContent.bed_number }}
            </span>
            at
            <span class="primary-text">
              {{ notificationContent.hospital }}/{{ notificationContent.unit }}.
            </span>
          </p>
          <div>Reason: {{ notificationContent.cancelled_reason }}</div>
          <div>Cancelled by: {{ notificationContent.cancelled_by_name }}</div>
          <div>Raised by: {{ notificationContent.raised_by_name }}</div>
          <div>Call Duration: {{ calculateTimeDuration() }}</div>
        </div>
      </div>
    </ng-container>

    <!-- eCall Raised notification  -->
    <ng-container *ngSwitchCase="notifications.eCallRaised">
      <img src="assets/icons/notification-icons/ecall_grey.svg" />
      <div class="notification-text">
        <div class="notification-title">
          <div class="header">
            {{
              "eCall raised " +
                (notificationContent.name === "-"
                  ? ""
                  : "for " + notificationContent.name)
            }}
          </div>
          <div class="timestamp">{{ notificationTimestamp }}</div>
        </div>
        <div class="notification-content">
          <p>
            {{
              "An eCall has been initiated " +
                (notificationContent.cpmrn === "-" ? "" : "for patient with ")
            }}
            <span
              class="primary-text"
              *ngIf="notificationContent.cpmrn !== '-'"
            >
              CPMRN:{{ notificationContent.cpmrn }}, bed no
              {{ notificationContent.bed_number }}
            </span>
            at
            <span class="primary-text">
              {{ notificationContent.hospital }}/{{ notificationContent.unit }}.
            </span>
          </p>
          <div>Raised by: {{ notificationContent.raised_by_name }}</div>
          <div *ngIf="notificationContent.is_running">
            Time Passed: {{ timePassed }}
          </div>
          <a
            *ngIf="notificationContent.is_running"
            [href]="notificationContent.google_meet_link"
            target="_blank"
          >
            <div class="google-meet-link">
              <img src="/assets/icons/notification-icons/google_meet.svg" />
              <div class="google-meet-link-text">Join with Google Meet</div>
            </div>
          </a>
          <div class="google-meet-link" *ngIf="notificationContent.is_running">
            <img src="/assets/icons/notification-icons/end_call.svg" />
            <div class="google-meet-link-text" (click)="endAlertEvent()">
              End eCall
            </div>
          </div>
          <div *ngIf="!notificationContent.is_running">
            Call Duration: {{ calculateTimeDuration() }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
