import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AudioService {
  private codeBlueAlertAudio = "assets/audio/code_blue_alert_1.wav";
  private callAlertAudio = "assets/audio/call_alert_1.wav";
  private sbarAlertAudio = "assets/audio/sbar-default.wav";
  private sbarHighAlertAudio = "assets/audio/sbar-alert-high.wav";

  public audio = new Audio();
  private defaultPriority = 10;
  private currentlyPlaying = this.defaultPriority;

  constructor() {}

  private playAudio(src: string, priority: number) {
    if (priority <= this.currentlyPlaying) {
      this.currentlyPlaying = priority;

      this.audio.currentTime = 0;
      this.audio.src = src;
      this.audio.load();
      this.audio.play();

      this.audio.addEventListener(
        "ended",
        () => {
          this.currentlyPlaying = this.defaultPriority;
        },
        {
          once: true,
        }
      );
    }
  }

  public playCodeBlueAlert() {
    this.playAudio(this.codeBlueAlertAudio, 1);
  }

  public playCallAlert() {
    this.playAudio(this.callAlertAudio, 2);
  }

  public playSbarAlert() {
    this.playAudio(this.sbarAlertAudio, 2);
  }

  public playSbarAlertHigh() {
    this.playAudio(this.sbarHighAlertAudio, 1);
  }

  playRalertAlertAudio(message: string) {
    if (message === "code_blue") {
      this.playCodeBlueAlert();
    } else if (message === "call") {
      this.playCallAlert();
    }
  }

  stopRalertAlertAudio(message: string) {
    if (
      this.currentlyPlaying === this.getRAlertMessagePriority(message) &&
      this.audio
    ) {
      this.currentlyPlaying = this.defaultPriority;
      this.audio.src = "";
    }
  }

  private getRAlertMessagePriority(message: string): number {
    if (message === "code_blue") {
      return 1;
    }

    return 2;
  }
}
