import { createAction, props } from "@ngrx/store";
import {
  InitialAssessment,
  PatientPayloadInterface,
} from "src/app/models/patient";

export const submitForm = createAction(
  "[Patient Form] patient form fetch/submit",
  props<{ loadType: string }>()
);

export const submitSuccess = createAction(
  "[Patient Form] patient form success"
);

export const submitFail = createAction(
  "[Patient Form] patient form fail",
  props<{ error: any }>()
);

export const formClose = createAction("[Patient Form] patient form close");

/** Action to set create the patient details, this will cause a side effect */
export const createPatient = createAction(
  "[Patient Form] create selected patient",
  props<{ patient: any }>()
);

export const updatePatient = createAction(
  "[Patient Form] update selected patient",
  props<{
    payload: PatientPayloadInterface;
    nextPage: number;
    isFormComplete: boolean;
  }>()
);

export const updateIninitialAssessment = createAction(
  "[Patient Form] update initial assessment",
  props<{
    payload: PatientPayloadInterface;
    nextPage: number;
    isFormComplete: boolean;
  }>()
);

export const updateLoaderStatus = createAction(
  "[Patient Form] update the status of the loader",
  props<{ loading: boolean; loadType?: string }>()
);

export const updateAdmitStatus = createAction(
  "[Patient Form] update admint status",
  props<{ isNewPatient: boolean }>()
);

export const showSuccessScreenOne = createAction(
  "[Patient Form] show success screen one",
  props<{ status: boolean }>()
);

export const showSuccessScreenTwo = createAction(
  "[Patient Form] show success screen two",
  props<{ status: boolean }>()
);

export const updateCurrentPage = createAction(
  "[Patient Form] update current form status",
  props<{ currentPage?: number }>()
);

export const updateCovidStatus = createAction(
  "[Patient Form] update covid status",
  props<{ covidStatus?: any }>()
);

export const returnToPatientDetails = createAction(
  "[Patient Form] return to patient details/list",
  props<{ CPMRN: string; encounters: number }>()
);

export const updateUrlParams = createAction(
  "[Patient Form] update url params after admit",
  props<{ CPMRN: string; encounters: number }>()
);
