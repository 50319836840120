import { birthStatusList } from "src/app/models/patient";
import { GrowthChartConfigConstantInterface } from "../models/growth-trends.model";

export enum GROWTH_CHART_OPTIONS {
  weightForAge = "Weight for age",
  lengthForAge = "Length for age",
  headCircumferenceForAge = "Head circumference for age",
}

export const GROWTH_CHART_CONFIG = {
  [GROWTH_CHART_OPTIONS.weightForAge]: {
    [birthStatusList.Mature]: {
      xLabelName: "Age (Months)",
      xDomainRange: [0, 24],
      xTicks: 9,
      xTickValues: [0, 3, 6, 9, 12, 15, 18, 21, 24],
      yLabelName: "Weight",
      yDomainRange: [0, 24],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
    [birthStatusList.Preterm]: {
      xLabelName: "Gestation age (Weeks)",
      xDomainRange: [22, 50],
      xTickValues: [22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50],
      xNiceDisable: true,
      xTicks: 9,
      yLabelName: "Weight",
      yDomainRange: [0, 8],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
  },
  [GROWTH_CHART_OPTIONS.lengthForAge]: {
    [birthStatusList.Mature]: {
      xLabelName: "Age (Months)",
      xDomainRange: [0, 24],
      xTicks: 9,
      xTickValues: [0, 3, 6, 9, 12, 15, 18, 21, 24],
      yLabelName: "Length",
      yDomainRange: [30, 100],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
    [birthStatusList.Preterm]: {
      xLabelName: "Gestation age (Weeks)",
      xDomainRange: [22, 50],
      xTickValues: [22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50],
      xTicks: 9,
      xNiceDisable: true,
      yLabelName: "Length",
      yDomainRange: [20, 65],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
  },
  [GROWTH_CHART_OPTIONS.headCircumferenceForAge]: {
    [birthStatusList.Mature]: {
      xLabelName: "Age (Months)",
      xDomainRange: [0, 24],
      xTicks: 9,
      xTickValues: [0, 3, 6, 9, 12, 15, 18, 21, 24],
      yLabelName: "Head circumference",
      yDomainRange: [30, 54],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
    [birthStatusList.Preterm]: {
      xLabelName: "Gestation age (Weeks)",
      xDomainRange: [22, 50],
      xTickValues: [22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50],
      xTicks: 9,
      xNiceDisable: true,
      yLabelName: "Head circumference",
      yDomainRange: [16, 45],
      yTicks: 10,
    } as GrowthChartConfigConstantInterface,
  },
};

export const GROWTH_OPTION_W_HISTORY_KEY_MAP = {
  [GROWTH_CHART_OPTIONS.headCircumferenceForAge]: {
    yLabel: "headCircumference",
  },
  [GROWTH_CHART_OPTIONS.lengthForAge]: {
    yLabel: "length",
  },
  [GROWTH_CHART_OPTIONS.weightForAge]: {
    yLabel: "weightKg",
  },
};

export const GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP = {
  [GROWTH_CHART_OPTIONS.headCircumferenceForAge]: {
    [birthStatusList.Mature]: {
      yLabel: "Head circumference",
      yUnit: "cm",
      xLabel: "Age",
      xUnit: "month",
    },
    [birthStatusList.Preterm]: {
      yLabel: "Head circumference",
      yUnit: "cm",
      xLabel: "Age",
      xUnit: "week",
    },
  },
  [GROWTH_CHART_OPTIONS.lengthForAge]: {
    [birthStatusList.Mature]: {
      yLabel: "Length",
      yUnit: "cm",
      xLabel: "Age",
      xUnit: "month",
    },
    [birthStatusList.Preterm]: {
      yLabel: "Length",
      yUnit: "cm",
      xLabel: "Age",
      xUnit: "week",
    },
  },
  [GROWTH_CHART_OPTIONS.weightForAge]: {
    [birthStatusList.Mature]: {
      yLabel: "Weight",
      yUnit: "kg",
      xLabel: "Age",
      xUnit: "month",
    },
    [birthStatusList.Preterm]: {
      yLabel: "Weight",
      yUnit: "kg",
      xLabel: "Age",
      xUnit: "week",
    },
  },
};

export const GROWTH_OPTION_TO_DEFAULT_LINE_MAP = {
  [GROWTH_CHART_OPTIONS.headCircumferenceForAge]: {
    [birthStatusList.Mature]: {
      boysLineName: "MATURE_BOYS_HEADCIRCUMFERENCE_LINE_COORDS",
      girlsLineName: "MATURE_GIRLS_HEADCIRCUMFERENCE_LINE_COORDS",
    },
    [birthStatusList.Preterm]: {
      boysLineName: "PRETERM_BOYS_HEADCIRCUMFERENCE_COORDS_LINE",
      girlsLineName: "PRETERM_GIRLS_HEADCIRCUMFERENCE_COORDS_LINE",
    },
  },
  [GROWTH_CHART_OPTIONS.lengthForAge]: {
    [birthStatusList.Mature]: {
      boysLineName: "MATURE_BOYS_LENGTH_LINE_COORDS",
      girlsLineName: "MATURE_GIRLS_LENGTH_LINE_COORDS",
    },
    [birthStatusList.Preterm]: {
      boysLineName: "PRETERM_BOYS_LENGTH_COORDS_LINE",
      girlsLineName: "PRETERM_GIRLS_LENGTH_COORDS_LINE",
    },
  },
  [GROWTH_CHART_OPTIONS.weightForAge]: {
    [birthStatusList.Mature]: {
      boysLineName: "MATURE_BOYS_WEIGHT_LINE_COORDS",
      girlsLineName: "MATURE_GIRLS_WEIGHT_LINE_COORDS",
    },
    [birthStatusList.Preterm]: {
      boysLineName: "PRETERM_BOYS_WEIGHT_COORDS_LINE",
      girlsLineName: "PRETERM_GIRLS_WEIGHT_COORDS_LINE",
    },
  },
};

export const GROWTH_LINE_COLORS = {
  [birthStatusList.Mature]: {
    line1: "#B5D2FE",
    line2: "#E0EDFF",
    line3: "#E0EDFF",
    line4: "#B5D2FE",
    line5: "#E0EDFF",
    line6: "#E0EDFF",
    line7: "#B5D2FE",
  },
  [birthStatusList.Preterm]: {
    line1: "#B5D2FE",
    line2: "#E0EDFF",
    line3: "#B5D2FE",
    line4: "#E0EDFF",
    line5: "#B5D2FE",
  },
};

export const GROWTH_LINE_LABELS_CONFIG = {
  [birthStatusList.Mature]: {
    line1: {
      labelName: "-3",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line2: {
      labelName: "-2",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line3: {
      labelName: "-1",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line4: {
      labelName: "0",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line5: {
      labelName: "1",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line6: {
      labelName: "2",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
    line7: {
      labelName: "3",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
    },
  },
  [birthStatusList.Preterm]: {
    line1: {
      labelName: "3",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 10,
      transformX: "0",
      transformY: "0",
    },
    line2: {
      labelName: "10",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 0,
      transformX: "0",
      transformY: "0",
    },
    line3: {
      labelName: "50",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 0,
      transformX: "0",
      transformY: "0",
    },
    line4: {
      labelName: "90",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
      isHideFor: [
        `${birthStatusList.Preterm}-M-${GROWTH_CHART_OPTIONS.lengthForAge}`,
        `${birthStatusList.Preterm}-F-${GROWTH_CHART_OPTIONS.lengthForAge}`,
      ],
    },
    line5: {
      labelName: "97",
      transformAngle: "0",
      displacementX: 5,
      displacementY: 5,
      transformX: "0",
      transformY: "0",
      isHideFor: [
        `${birthStatusList.Preterm}-M-${GROWTH_CHART_OPTIONS.lengthForAge}`,
        `${birthStatusList.Preterm}-F-${GROWTH_CHART_OPTIONS.lengthForAge}`,
      ],
    },
  },
};

export const GROWTH_TABLE_COLUMNS: string[] = [
  "age",
  "weight",
  "length",
  "headCircumference",
  "date",
  "time",
  "info",
];
