import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { AuthService } from "./services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _auth: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this._authService.isLoggedIn()) {
      /**
       * Logic has to be changed to check anyone of the tab is active
       */
      // this.idleWatch();
      if (!this._authService.checkIfAccessibleByRole(route.routeConfig.path)) {
        this._router.navigate(["/login"]);
        return false;
      }

      return true;
    } else {
      this._router.navigate(["/login"]);
      return false;
    }
  }

  canActivateChild(): boolean {
    if (this._authService.isLoggedIn()) {
      /**
       * Logic has to be changed to check anyone of the tab is active
       */
      // this.idleWatch();
      return true;
    } else {
      this._router.navigate(["/login"]);
      return false;
    }
  }

  // idleWatch() {
  //   //Start watching for user inactivity.
  //   this.userIdle.startWatching();
  //   // Start watching when user idle is starting.
  //   this.userIdle.onTimerStart().subscribe(count => {
  //     this.userIdle.stopTimer();
  //     this.userIdle.stopWatching();
  //     this._auth.setSessionExpirationInfo('Session expired due to inactivity, please login again');
  //     this._auth.logoutUser();
  //   });
  // }
}
