import { Subscription } from "rxjs";
import { select, Store } from "@ngrx/store";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { OrderPaneActions } from "../../../store/actions/order";
import * as fromOrderable from "../../../store/reducers/orderable";
import { OrderableRequestType } from "../../../models/orderable/OrderableRequest.model";

@Component({
  selector: "app-orderable-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.scss"],
})
export class RequestComponent implements OnInit, OnDestroy {
  @Input() userName: string;
  public orderableRequestFormLoading$ = this._store.pipe(
    select(fromOrderable.getOrderableRequestFormLoading)
  );
  public orderableRequestFormError$ = this._store.pipe(
    select(fromOrderable.getOrderableRequestFormError)
  );
  public orderableRequestFormCompleted$ = this._store.pipe(
    select(fromOrderable.getOrderableRequestCompleted)
  );
  public orderableRequestFormCompletedSubscription: Subscription;

  public form: UntypedFormGroup;
  public requestType = OrderableRequestType;

  constructor(private _store: Store<fromOrderable.State>) {}

  ngOnInit() {
    this._initForm();

    this.orderableRequestFormCompletedSubscription =
      this.orderableRequestFormCompleted$.subscribe((completed) => {
        if (completed && this.form) {
          this.form.reset();
        }
      });
  }

  ngOnDestroy() {
    if (this.orderableRequestFormCompletedSubscription) {
      this.orderableRequestFormCompletedSubscription.unsubscribe();
    }
  }

  private _initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      brand: new UntypedFormControl(null, [Validators.maxLength(100)]),
      type: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
  }

  onSubmit() {
    this._store.dispatch(
      OrderPaneActions.submitOrderableRequest({
        request: {
          ...this.form.value,
          creatorName: this.userName,
        },
      })
    );
  }
}
