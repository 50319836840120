import { AbstractControl, ValidatorFn } from "@angular/forms";
import { ValidationError } from "aws-sdk/clients/datapipeline";

export function rangeValidators(startRange, endRange?): ValidatorFn {
  //for checking between ranges or only <= a number @sheethalkhan  18-06-2023
  return (control: AbstractControl) => {
    const value = control.value;

    if (!value && value !== 0) {
      return null;
    }
    if (+value <= startRange) {
      return { negativeError: true };
    } else if (endRange && +value > endRange) {
      return { positiveError: true };
    } else {
      return null;
    }
  };
}
