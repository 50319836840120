import { createAction, props } from "@ngrx/store";
import { FinalNotes } from "@notes-view/model/notes-model";

export const LOAD_FINAL_NOTE_DATAS = "[NOTE-PATIENT-DATA] load final note data";
export const UPDATE_FINAL_NOTE_DATA =
  "[NOTE-PATIENT-DATA] update final note data";
export const DELETE_FINAL_NOTE_DATA =
  "[NOTE-PATIENT-DATA] delete final note data";
export const RESET_FINAL_NOTE_STORE =
  "[RESET-NOTE-FINAL-PATIENT-DATA] reset final note data";

export const loadFinalNote = createAction(
  LOAD_FINAL_NOTE_DATAS,
  props<{ notes: FinalNotes[] }>()
);

export const addFinalNote = createAction(
  UPDATE_FINAL_NOTE_DATA,
  props<{ note: FinalNotes }>()
);

export const updateFinalNote = createAction(
  UPDATE_FINAL_NOTE_DATA,
  props<{ note: FinalNotes }>()
);

export const deleteFinalNote = createAction(
  DELETE_FINAL_NOTE_DATA,
  props<{ refId: string }>()
);

export const resetFinalNoteContent = createAction(RESET_FINAL_NOTE_STORE);
