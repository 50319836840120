import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { FileApiActions } from '../../actions/file';
import { FileProgressBar } from '../../../models/File.model';
import { ProtocolApiActions } from '../../../protocol/actions';


export interface State extends EntityState<FileProgressBar> {
}

export const adapter: EntityAdapter<FileProgressBar> = createEntityAdapter<FileProgressBar>({
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

const fileReducer = createReducer(
  initialState,
  on(
    ProtocolApiActions.saveFileProgress,
    FileApiActions.saveFileProgress,
    (state, { data }) => {
      if (!data.completed) {
        return { ...state };
      }

      return adapter.upsertOne(data, state);
    }
  ),
  on(
    ProtocolApiActions.deleteFileProgress,
    FileApiActions.deleteFileProgress,
    (state, { id }) => adapter.removeOne(id, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return fileReducer(state, action);
}

const { selectIds, selectEntities, selectTotal, selectAll } = adapter.getSelectors();

export const selectFileProgressBarIds = selectIds;

export const selectFileProgressBarEntities = selectEntities;

export const selectFileProgressBarTotal = selectTotal;

export const selectAllFileProgressBars = selectAll;
