import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { ZoomTransformService } from "src/app/iris-components/service/zoom-transform.service";
import { supportedVideoTypes } from "src/app/models/file/File.model";

@Component({
  selector: "cp-player",
  templateUrl: "./player.component.html",
  styleUrls: ["./player.component.scss"],
  providers: [ZoomTransformService],
})
export class PlayerComponent implements OnChanges {
  bufferedValue: number;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && this.video) {
      this.onVideoEnd();
      this.video?.load();
    }
  }

  @Input() videoType: "normal" | "preview" | "thumbnail" = "normal";
  @Input() src: string = "";
  @Input() fileType: string = "";
  @Output() thumbnail: EventEmitter<any> = new EventEmitter();
  public isShowPlay: boolean = true;
  public zoom: number = 1;
  playButtonTimer: any = null;
  previousVolume: number = 0;
  timestamp: string = "00:00";
  duration: string = "00:00";

  public volume: number = 100;

  public overControls: boolean = false;

  percentage: number = 0;

  initializeDuration(): void {
    this.duration = moment.utc(this.video.duration * 1000).format("mm:ss");
  }

  public videoOnLoad: boolean = true;

  playButtonIcon: "play_arrow" | "pause" = "play_arrow";

  progressWidth = 0;

  volumeButtonIcon: "volume_down" | "volume_up" | "volume_off" = "volume_up";

  fullscreenIcon: "fullscreen" | "fullscreen_exit" = "fullscreen";

  hours: string = "25";

  minutes: string = "10";

  loading: boolean = true;

  percentValue: number = 0;

  @ViewChild("videoPlayer", { static: false })
  videoplayer!: ElementRef;

  @ViewChild("videoContainer", { static: false })
  videoContainer!: ElementRef;

  @ViewChild("progress", { static: false })
  progress!: ElementRef;

  get video(): HTMLMediaElement {
    return this.videoplayer?.nativeElement;
  }

  progressChange(event: any): void {
    this.pauseVideo();
    this.changeTime(event);
    this.playVideo();
  }

  changeTime(event: any): void {
    if (!event) return;
    this.video.currentTime =
      this.progressPer(event.offsetX) * this.video.duration;
  }

  progressPer(xValue: any): number {
    return xValue / this.progress.nativeElement.offsetWidth;
  }

  updateTime(): void {
    this.timestamp = moment.utc(this.video.currentTime * 1000).format("mm:ss");
    const duration = this.video?.duration;
    if (!duration) {
      this.percentValue = 0;
      return;
    }
    this.percentValue = (this.video.currentTime / duration) * 100;
  }

  valueChange(percentage: number): void {
    this.percentValue = percentage;
    this.video.currentTime = (percentage * this.video.duration) / 100;
  }

  public toggleMute(): void {
    this.video.muted = !this.video.muted;
    if (this.video.muted) {
      this.previousVolume = this.volume;
      this.volume = 0;
    } else {
      this.volume = this.previousVolume;
    }
  }

  public volumeChange(event: any) {
    this.video.volume = event / 100;
  }

  public isVolumeDown(volume: number | undefined): boolean {
    if (!volume) return false;
    return volume <= 0.5 && volume != 0;
  }

  public isVolumeUp(volume: number | undefined): boolean {
    if (!volume) return false;
    return 0.5 < volume;
  }

  togglePlay(): void {
    if (this.videoType == "thumbnail") {
      this.thumbnail.emit();
      return;
    }
    if (this.playButtonIcon == "play_arrow") {
      this.playVideo();
    } else {
      this.pauseVideo();
    }
  }

  public pauseVideo() {
    this.video.pause();
    this.playButtonIcon = "play_arrow";
  }

  public playVideo(): void {
    this.playButtonIcon = "pause";
    this.video.play().catch((err) => console.log(err));
  }

  volumeToggle(): void {
    this.video.muted = !this.video.muted;
    if (this.video.muted) {
      this.volumeButtonIcon = "volume_off";
    } else {
      this.volumeButtonIcon = "volume_up";
    }
  }

  fullscreenToggle(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => console.log(err));
    } else {
      this.videoContainer.nativeElement.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    }
  }

  buffered(): number {
    if (!this.video?.buffered?.length) return 0;
    const buffered = this.video.buffered.end(0);
    const duration = this.video.duration;
    this.bufferedValue = (buffered / duration) * 100;
  }

  @HostListener("document:fullscreenchange", ["$event"])
  changeListener(event: any) {
    if (document.fullscreenElement) {
      this.fullscreenIcon = "fullscreen_exit";
    } else {
      //not in full screen
      this.fullscreenIcon = "fullscreen";
    }
  }

  setPlayerControls(): void {
    if (this.overControls) return;
    this.clearTimer();
    if (this.playButtonIcon == "play_arrow") {
      this.isShowPlay = true;
    }
    if (this.playButtonIcon == "pause") {
      this.isShowPlay = true;
      this.playButtonTimer = setTimeout(() => {
        this.isShowPlay = false;
      }, 2000);
    }
  }

  hideControls(): void {
    if (this.playButtonIcon == "play_arrow") return;
    this.isShowPlay = false;
  }

  onVideoEnd(): void {
    this.video.currentTime = 0;
    this.pauseVideo();
  }

  public clearTimer(): void {
    if (!this.playButtonTimer) return;
    clearTimeout(this.playButtonTimer);
    this.playButtonTimer = null;
  }

  public increaseZoom(): void {
    if (this.zoom > 9) return;
    this.zoom++;
  }

  public decreaseZoom(): void {
    if (this.zoom < 2) return;
    this.zoom--;
  }

  public get isSupportedVideo(): boolean {
    if (!this.fileType) return true;
    return supportedVideoTypes.includes(this.fileType);
  }

  public openLink(): void {
    window.open(this.src, "_blank");
  }
}
