<mat-dialog-content
  class="iris-alert"
  [ngClass]="{
    'alert-success': data.type === 'Success',
    'alert-info': data.type === 'Info',
    'alert-error': data.type === 'Error',
    'alert-warning': data.type === 'Warning'
  }"
>
  <div class="iris-alert__content">
    <div class="iris-alert__title">
      <mat-icon inline="true" class="icon cp-mat-icon-overflow-initial">{{
        getAlertIcon()
      }}</mat-icon>
      <span class="title"
        ><strong>{{ data.title }}.</strong></span
      >
    </div>
    <div class="iris-alert__message">
      {{ data.message }}
    </div>
  </div>

  <div matRipple (click)="close()" class="iris-alert__close cp-cursor-pointer">
    <mat-icon>close</mat-icon>
  </div>
</mat-dialog-content>
