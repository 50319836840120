import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteService } from "@notes-view/services/note.service";
import { quillConfig } from "@notes/utils/quill.config";
import { Addendum, Cosign } from "@notes/models/notes.model";

@Component({
  selector: "cp-note-attest",
  templateUrl: "./note-attest.component.html",
  styleUrls: ["./note-attest.component.scss"],
})
export class NoteAttestComponent implements OnInit {
  @Input() patientNotesForm: FormGroup;
  private unsubscribe$: Subject<any> = new Subject<any>();

  quillConfig = quillConfig;
  inDebounce: NodeJS.Timeout;
  constructor(private _noteService: NoteService) {}

  ngOnInit(): void {}

  attestationStatusMsg = new UntypedFormControl(null);

  get componentsFormArray() {
    return (this.patientNotesForm.get("components") as FormArray).controls;
  }

  get getMergeNotes(): any[] {
    const finalNotes = this.patientNotesForm.value as FinalNotes;
    return this._noteService.getAddendumAndCosignMerge(finalNotes);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
