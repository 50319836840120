import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timePeriodOptions",
})
export class TimePeriodOptionsPipe implements PipeTransform {
  transform(items: string[], filterBy: string): string[] {
    if (!items || !filterBy || filterBy == "Before") {
      return items;
    }

    return items.filter((item) => item !== "Bedtime");
  }
}
