import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ToggleDataInterface } from "../../model";

@Component({
  selector: "cp-toggle-header",
  templateUrl: "./toggle-header.component.html",
  styleUrls: ["./toggle-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleHeaderComponent implements OnInit {
  @Input() tabs: ToggleDataInterface[] = [];
  @Input() activeTab: string = "";
  @Input() customTabValue: string = "";
  @Output() emitActiveTab: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  changeTab(tabValue) {
    this.emitActiveTab.emit(tabValue);
  }
}
