import { createReducer, on, Action } from "@ngrx/store";

import * as hospitalListActions from "../../actions/hospitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import * as patientListActions from "src/app/store/actions/patients/patient-list.action";

export interface State {
  hospitalFilters: {};
  unitAssignLoaderList: string[];
  workspaceAssignLoaderList: string[];
  assignAllUnitsLoading: boolean;
}

export const initialState: State = {
  hospitalFilters: {},
  unitAssignLoaderList: [],
  workspaceAssignLoaderList: [],
  assignAllUnitsLoading: false,
};

const hospitalViewReducer = createReducer(
  initialState,
  on(hospitalListActions.setHospitalFilters, (state, { hospitalFilter }) => {
    return { ...state, hospitalFilters: hospitalFilter };
  }),
  on(rootActions.logout, (state) => initialState),
  on(patientListActions.assignShift, (state, { shift: { unit } }) => {
    return {
      ...state,
      unitAssignLoaderList: [...state.unitAssignLoaderList, unit],
    };
  }),
  on(patientListActions.assignShiftSuccess, (state, { unitId }) => {
    const unitAssignLoaderList = state.unitAssignLoaderList?.filter(
      (id) => id !== unitId
    );
    return { ...state, unitAssignLoaderList };
  }),
  on(patientListActions.assignAllShift, (state) => {
    return { ...state, assignAllUnitsLoading: true };
  }),
  on(patientListActions.assignAllShiftSuccess, (state) => {
    return { ...state, assignAllUnitsLoading: false };
  }),
  on(
    patientListActions.assignAllWorkspace,
    (state, { workspace: { workspaceId } }) => {
      return {
        ...state,
        workspaceAssignLoaderList: [
          ...state.workspaceAssignLoaderList,
          workspaceId,
        ],
      };
    }
  ),
  on(patientListActions.assignAllWorkspaceSuccess, (state, { workspaceId }) => {
    const workspaceAssignLoaderList = state.workspaceAssignLoaderList?.filter(
      (id) => id !== workspaceId
    );
    return { ...state, workspaceAssignLoaderList };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return hospitalViewReducer(state, action);
}
