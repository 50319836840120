<div class="stage" *ngIf="!buttonLoader">
  <div class="loading-dots"></div>
</div>

<div
  class="button-element"
  *ngIf="buttonLoader"
  #buttonElem
  (click)="onButtonClick($event)"
>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
