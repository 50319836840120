<div class="growth-chart-container">
  <div class="trends-header">
    <mat-tab-group
      class="iris-tab"
      [animationDuration]="0"
      [disablePagination]="true"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="Chart view"> </mat-tab>
      <mat-tab label="Table View"></mat-tab>
    </mat-tab-group>
    <div class="close-icon">
      <img matDialogClose="cancel" src="assets/icons/close.svg" />
    </div>
  </div>

  <hr class="header-divider" />

  <div class="trends-container" [hidden]="!isTrendActive">
    <app-trends-left-panel></app-trends-left-panel>
    <div class="graphs-container">
      <!-- ngFor to be added here  -->
      <div
        class="body"
        *ngFor="let gConfig of growthTrendsConfig$ | async; index as i"
      >
        <div class="chart-title-container">
          <div class="title-and-unit">
            <div class="graph-title">{{ gConfig?.graphName }}</div>
          </div>
          <div class="recent-values">
            <div class="header">Recent:</div>
            <div class="recent-items">
              <div class="label">Value:</div>
              <div class="value" *ngIf="gConfig?.graphData?.length">
                {{
                  getProperUnitName(
                    gConfig?.graphData[0]["yValue"],
                    GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP[
                      gConfig?.graphName
                    ][patientInfo?.birthStatus]["yUnit"]
                  ) || "-"
                }}
              </div>
            </div>
            <mat-divider [vertical]="true" class="divider"></mat-divider>
            <div class="recent-items">
              <div class="label">Date & time:</div>
              <div class="value" *ngIf="gConfig?.graphData?.length">
                {{
                  gConfig?.graphData[0]["timeStamp"]
                    | timezone: "MMM DD, H:mm" || "-"
                }}
                <cp-timezone-label></cp-timezone-label>
              </div>
            </div>
          </div>
        </div>
        <div class="d3-graph">
          <div id="{{ gConfig?.graphName }}" #canvas></div>
        </div>
      </div>
    </div>
  </div>

  <div class="tabular-container" *ngIf="!isTrendActive">
    <app-growth-table></app-growth-table>
  </div>
</div>
