import { createAction, props } from '@ngrx/store';
import { Notification } from '../../../models/notifications/notifications.model';

export const setNotification = createAction(
    '[Notification] Set Notification',
    props<{ notification: Notification[] }>()
);

export const getNotification = createAction(
    '[Notification] Get Notification'
);

export const updateNotification = createAction(
    '[Notification] Update Notification',
    props<{ id: string, notification: Notification }>()
);

export const deleteNotification = createAction(
    '[Notification] Delete Notification',
    props<{ notification: Notification }>()
);

export const addNotification = createAction(
    '[Notification] Add Notification',
    props<{ notification: Notification }>()
);
