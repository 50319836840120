import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { TrendsBaseComponent } from "../trends-base/trends-base.component";
import { Store, select } from "@ngrx/store";
import * as fromTrendsStore from "../../store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GrowthTrendsConfig } from "../../models/growth-trends.model";
import {
  GROWTH_CHART_OPTIONS,
  GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP,
} from "../../constants/growth-trends.data";
import { getProperUnitName } from "src/app/support-functions/calculateAge";
import { MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-growth-chart",
  templateUrl: "./growth-chart.component.html",
  styleUrls: ["./growth-chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GrowthChartComponent
  extends TrendsBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren("canvas") public _canvasList: QueryList<any>;

  growthTrendsConfig$ = this._trendStore.pipe(
    select(fromTrendsStore.getAllGrowthTrendsData),
    takeUntil(this.unsubscribe$)
  );

  trendCommonConfig$ = this._trendStore.pipe(
    select(fromTrendsStore.getTrendCommonConfig),
    takeUntil(this.unsubscribe$)
  );

  selectedGraphsNames$ = this._trendStore.pipe(
    select(fromTrendsStore.getAllGrowthTrendsNames),
    takeUntil(this.unsubscribe$)
  );

  growthChartsConfigs: GrowthTrendsConfig[];
  selectedGraph: string[] = [];
  svgContainer: [] = [];
  isTrendActive: boolean = true;

  GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP =
    GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP;

  constructor(
    private _trendStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    protected _store: Store<any>,
    private _cdrf: ChangeDetectorRef,
    private _dialogRef: MatDialogRef<GrowthChartComponent>
  ) {
    super(_store);
  }

  ngOnInit(): void {
    /* Get Patient info */
    this.patientHeader$.skip(1).subscribe((patient) => {
      this.updateStoreWithNewData();
    });

    this.trendCommonConfig$.subscribe((data) => {
      this.trendType = data.trendType;
    });

    this.selectedGraphsNames$.subscribe((data: string[]) => {
      this.selectedGraph = data;
    });

    // Resetting the store so that on re-open we can re-calculate the new data
    this._dialogRef.afterClosed().subscribe((_) => {
      this._trendStore.dispatch(fromTrendsStore.resetTrendConfig());
      this._trendStore.dispatch(fromTrendsStore.resetGrowthGraph());
    });
  }

  /**
   * @description updating the whole graph with new data
   * @returns
   * @author Rajat Saini
   * @date Jun 26, 2023
   */
  updateStoreWithNewData() {
    if (!this.selectedGraph?.length) return;

    const filteredGrowthChartNamesEnum =
      this._gcs.filterSelectedGraphNamesFromEnum(this.selectedGraph);
    let growthTrendsConfigPayload: GrowthTrendsConfig[] =
      filteredGrowthChartNamesEnum.map(([graphKey, graphName]) => {
        return this._gcs.makeGraphDataAndConfigToBePushed(
          graphKey as GROWTH_CHART_OPTIONS,
          this.patientInfo
        );
      });
    this._trendStore.dispatch(
      fromTrendsStore.updateManyGrowthGraph({
        graphConfigs: growthTrendsConfigPayload,
      })
    );
  }

  ngAfterViewInit(): void {
    this.growthTrendsConfig$.subscribe((data: GrowthTrendsConfig[]) => {
      this.growthChartsConfigs = data;
      // Manually running change detection
      // so that all graph div are rendered
      // in order to access them and append svg
      this._cdrf.detectChanges();
      this.clearAllSVGElements();
      this.generateGraphs();
    });
  }

  generateGraphs() {
    this.growthChartsConfigs?.forEach((graphConfig, graphIndex) => {
      const yDataPoints = graphConfig?.graphData?.map(
        (dataObj) => dataObj?.yValue
      );

      const currNativeEle = this._canvasList.find((ele, index) => {
        return ele.nativeElement.id === graphConfig?.graphName;
      });
      this.setGraphHeightWidht(this.trendType);
      const svg = this.attachSVGElement(currNativeEle.nativeElement);
      if (graphIndex == 0) this.makeTooltip(currNativeEle.nativeElement);
      this.attachXAxis(svg, graphConfig?.graphConfig?.xAxisConfig);
      this.attachYAxis(svg, graphConfig?.graphConfig?.yAxisConfig, yDataPoints);
      this.plotALine(svg, graphConfig.graphName);
      this.drawLineAndPath(svg, graphConfig?.graphData);
      this.scatterDots(svg, graphConfig?.graphData);
      this.makeMaxMinText(svg, graphConfig?.graphData);
    });
  }

  getProperUnitName(value, unitName) {
    return getProperUnitName(value, unitName, false, false);
  }

  tabChanged(event) {
    this.isTrendActive = event.index ? false : true;
  }
}
