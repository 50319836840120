import { ElementRef, Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  isTriggerPrint$: Subject<any> = new Subject();

  constructor() {}

  triggerPrint() {
    this.isTriggerPrint$.next();
  }

  print(): void {
    window.print();
  }
}
