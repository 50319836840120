import { Injectable } from "@angular/core";
import * as fromInfectionBundleActions from "../actions";
import { InfectionBundleService } from "../../services/infection-bundle.service";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, switchMap, tap } from "rxjs/operators";
import { Alert } from "src/app/iris-components/model/iris-component.model";

@Injectable()
export class InfectionBundleEffect {
  constructor(
    private actions$: Actions,
    private _infectionBundleService: InfectionBundleService,
    private _alertService: AlertService
  ) {}

  getAllInfectionBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInfectionBundleActions.getAllInfectionBundlesAction),
      switchMap(({ CPMRN, encounters }) => {
        return this._infectionBundleService
          .getAllInfectionBundles(CPMRN, encounters)
          .pipe(
            mergeMap((infectionBundles) => {
              return [
                fromInfectionBundleActions.getAllInfectionBundlesActionSuccess({
                  infectionBundles,
                }),
              ];
            }),
            catchError((response) => {
              return [
                fromInfectionBundleActions.showError({
                  msg: response?.error?.message || "error",
                }),
              ];
            })
          );
      })
    )
  );

  createInfectionBundleChecklist = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInfectionBundleActions.createInfBundChecklist),
      switchMap(({ payload }) => {
        return this._infectionBundleService
          .createInfectionBundleChecklist(payload)
          .pipe(
            mergeMap((infection) => {
              return [
                fromInfectionBundleActions.createInfBundChecklistSuccess({
                  checklist: infection,
                }),
                fromInfectionBundleActions.showSucc({
                  msg: "VAP checklist created successfully!",
                }),
              ];
            }),
            catchError((response) => {
              return [
                fromInfectionBundleActions.showError({
                  msg: response?.error?.message || "error",
                }),
              ];
            })
          );
      })
    )
  );

  updateInfectionBundleChecklist = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInfectionBundleActions.updateInfBundChecklist),
      switchMap(({ payload }) => {
        return this._infectionBundleService
          .updateInfectionBundleChecklist(payload)
          .pipe(
            mergeMap((infection) => {
              return [
                fromInfectionBundleActions.updateInfBundChecklistSuccess({
                  updatedChecklist: infection,
                }),
                fromInfectionBundleActions.showSucc({
                  msg: "VAP checklist updated successfully!",
                }),
              ];
            }),
            catchError((response) => {
              return [
                fromInfectionBundleActions.showError({
                  msg: response?.error?.message || "error",
                }),
              ];
            })
          );
      })
    )
  );

  showSucc$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInfectionBundleActions.showSucc),
        tap(({ msg }) => {
          const errorObj: Alert = {
            type: "Success",
            message: msg,
          };
          this._alertService.showNotification(errorObj);
        })
      ),
    { dispatch: false }
  );

  showErr$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInfectionBundleActions.showError),
        tap(({ msg }) => {
          const errorObj: Alert = {
            type: "Error",
            message: msg,
          };
          this._alertService.showNotification(errorObj);
        })
      ),
    { dispatch: false }
  );
}
