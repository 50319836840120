import * as _moment from "moment-timezone";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment-timezone";

const moment = _rollupMoment || _moment;
import { Pipe, PipeTransform } from "@angular/core";

interface DaysPassed {
  day: number;
  hr?: number;
  min?: number;
}

@Pipe({ name: "daysPassed" })
export class DaysPassedPipe implements PipeTransform {
  transform(
    order: any,
    type: string,
    onlyDays: boolean = true,
    dischargeDate: Date | null
  ): DaysPassed | null {
    let endDate = moment();

    if (type === "active" && dischargeDate) {
      endDate = moment(dischargeDate);
    } else if (type === "pending") {
      if (dischargeDate) {
        endDate = moment(dischargeDate);
      } else {
        endDate = moment(order.updatedAt);
      }
    } else if (type === "completed") {
      endDate = moment(order.completedAt);
    } else if (type === "inactive") {
      endDate = moment(order.discontinueAt);
    }

    if (onlyDays) {
      const a = moment([
        moment(order.startTime).get("year"),
        moment(order.startTime).get("month"),
        moment(order.startTime).get("date"),
      ]);
      const b = moment([
        moment(endDate).get("year"),
        moment(endDate).get("month"),
        moment(endDate).get("date"),
      ]);

      return {
        day: b.diff(a, "days") + 1,
        hr: 0,
        min: 0,
      };
    } else {
      const duration = moment.duration(
        moment(endDate).diff(moment(order.startTime))
      );

      return {
        day: duration.days(),
        hr: duration.hours(),
        min: duration.minutes(),
      };
    }
  }
}
