export const controlVariable: string[] = ["VC", "PC", "TC"];
export const breathSeq_vc: string[] = ["CMV", "IMV"];
export const breathSeq_pc_tc: string[] = ["CMV", "CSV", "IMV"];
export const neonatalMode: string[] = [
  "HFO V",
  "SIMV VG",
  "SIPPV VG",
  "Ventilator CPAP",
  "SIMV PC",
  "Other",
];
export const adultMode: string[] = ["Other"];
