import {
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromRoot from "../../../reducers/index";
import * as fromOrderable from "./orderable.reducer";
import * as fromOrderableForm from "./orderable-form.reducer";
import * as fromOrderableRequest from "./orderable-request";
import * as fromOrderableRequestForm from "./orderable-request/orderable-request-form.reducer";
import * as fromOrderableRequestData from "./orderable-request/orderable-request-data.reducer";
import * as fromOrderableRequestStatus from "./orderable-request/orderable-request-status.reducer";

export interface OrderableState {
  form: fromOrderableForm.State;
  data: fromOrderable.State;
  orderableRequest: fromOrderableRequest.OrderableRequestState;
}

export interface State extends fromRoot.AppState {
  orderable: OrderableState;
}

export const reducers: ActionReducerMap<OrderableState, any> = {
  form: fromOrderableForm.reducer,
  data: fromOrderable.reducer,
  orderableRequest: combineReducers(fromOrderableRequest.reducers),
};

export const getOrderableState =
  createFeatureSelector<OrderableState>("orderable");

/*
 * Orderable Form State selectors
 * */
export const getOrderableFormState = createSelector(
  getOrderableState,
  (state: OrderableState) => state.form
);

export const getOrderableFormError = createSelector(
  getOrderableFormState,
  fromOrderableForm.getOrderableFormError
);

export const getOrderableFormLoading = createSelector(
  getOrderableFormState,
  fromOrderableForm.getOrderableFormLoading
);

export const getOrderableFormSuccess = createSelector(
  getOrderableFormState,
  fromOrderableForm.getOrderableFormSuccess
);

/*
 * Orderable Data State selectors
 * */
export const getOrderableDataState = createSelector(
  getOrderableState,
  (state: OrderableState) => state.data
);

export const getSelectedOrderableId = createSelector(
  getOrderableDataState,
  fromOrderable.getSelectedOrderableId
);

export const selectOrderableIds = createSelector(
  getOrderableDataState,
  fromOrderable.selectOrderableIds
);

export const selectOrderableEntities = createSelector(
  getOrderableDataState,
  fromOrderable.selectOrderableEntities
);

export const selectAllOrderables = createSelector(
  getOrderableDataState,
  fromOrderable.selectAllOrderables
);

export const selectOrderableTotal = createSelector(
  getOrderableDataState,
  fromOrderable.selectOrderableTotal
);

export const getSelectedOrderable = createSelector(
  selectOrderableEntities,
  getSelectedOrderableId,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getOrderableByType = createSelector(
  selectAllOrderables,
  (orderables, props) => {
    if (props.type) {
      return orderables.filter((orderable) => orderable.type === props.type);
    } else {
      return orderables;
    }
  }
);

export const getOrderablesLoading = createSelector(
  getOrderableDataState,
  fromOrderable.getOrderablesLoading
);

/*
 * Orderable Request Selectors
 * */
export const getOrderableRequestState = createSelector(
  getOrderableState,
  (state: OrderableState) => state.orderableRequest
);

/*
 * Orderable Request Data selectors
 * */
export const getOrderableRequestDataState = createSelector(
  getOrderableRequestState,
  (state) => state.data
);

export const getOrderableRequestDataPendingState = createSelector(
  getOrderableRequestDataState,
  fromOrderableRequestData.getPendingRequest
);

export const getOrderableRequestDataRejectedState = createSelector(
  getOrderableRequestDataState,
  fromOrderableRequestData.getRejectedRequest
);

export const getOrderablePendingRequestCount = createSelector(
  getOrderableRequestDataPendingState,
  (state) => state.length
);

export const getOrderableRejectedRequestCount = createSelector(
  getOrderableRequestDataRejectedState,
  (state) => state.length
);

export const getTotalOrderableRequestCount = createSelector(
  getOrderablePendingRequestCount,
  getOrderableRejectedRequestCount,
  (pendingCount, rejectedCount) => pendingCount + rejectedCount
);

/*
 * Orderable Request Status selectors
 * */
export const getOrderableRequestStatusState = createSelector(
  getOrderableRequestState,
  (state) => state.status
);

export const getOrderableRequestStatusError = createSelector(
  getOrderableRequestStatusState,
  fromOrderableRequestStatus.getOrderableRequestError
);

export const getOrderableRequestStatusLoading = createSelector(
  getOrderableRequestStatusState,
  fromOrderableRequestStatus.getOrderableRequestLoading
);

/*
 * Orderable Request Form Selectors
 * */
export const getOrderableRequestFormState = createSelector(
  getOrderableRequestState,
  (state) => state.form
);

export const getOrderableRequestFormError = createSelector(
  getOrderableRequestFormState,
  fromOrderableRequestForm.getOrderableRequestError
);

export const getOrderableRequestFormLoading = createSelector(
  getOrderableRequestFormState,
  fromOrderableRequestForm.getOrderableRequestLoading
);

export const getOrderableRequestCompleted = createSelector(
  getOrderableRequestFormState,
  fromOrderableRequestForm.getOrderableRequestCompleted
);
