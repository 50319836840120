import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  private isOpenSubject = new BehaviorSubject<boolean>(true); // Default state is open
  isOpen$ = this.isOpenSubject.asObservable();

  constructor() {}

  closeAllToasters() {
    this.isOpenSubject.next(false);
  }

  resetAllToasters() {
    this.isOpenSubject.next(true);
  }
}
