import { Injectable } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { bradenFormValidator } from "../custom-validators";
@Injectable({
  providedIn: "root",
})
export class BradenFormService {
  constructor() {}

  initFallRiskForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        sensory: new UntypedFormControl(null),
        moisture: new UntypedFormControl(null),
        activity: new UntypedFormControl(null),
        mobility: new UntypedFormControl(null),
        nutrition: new UntypedFormControl(null),
        friction: new UntypedFormControl(null),
        timestamp: new UntypedFormControl(
          new Date(new Date().setSeconds(0, 0)),
          Validators.required
        ),
      },
      {
        validators: bradenFormValidator,
      }
    );
  }
}
