import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import * as fromInfectionReducer from "../../store";
import { Store, select } from "@ngrx/store";
import { EMPTRY_INF_CHECKLIST, QUESTION_TYPE } from "../../constants";
import {
  CreateInfBundChecklistPayload,
  DefaultQuestionInterface,
  INFECTION_BUNDLES_CONFIG,
  INFECTION_BUNDLE_TYPES,
  InfectionBundle,
  InfectionBundleQuestions,
  NewInfectionBundleChecklist,
} from "../../models";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { FindObjectInArrayWithKeyPipe } from "@shared-modules/pipes/find-object-in-array-with-key/pipe/find-object-in-array-with-key.pipe";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import * as fromUserReducer from "src/app/store/reducers/user";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { Patient, PatientType } from "src/app/models/patient";
import { User } from "src/app/models/user";
import moment from "moment-timezone";
import { IcuDaysPipe } from "@shared-modules/pipes/icu-days-pipe/icu-days.pipe";
import { VitalDayPipe } from "src/app/vitals/pipes/vital-day.pipe";
import { InfectionBundleService } from "../../services/infection-bundle.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: "app-infection-bundle",
  templateUrl: "./infection-bundle.component.html",
  styleUrls: ["./infection-bundle.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InfectionBundleComponent implements OnInit, OnDestroy {
  @ViewChildren("infectionTables") infectionRecordsTables: QueryList<any>;

  unsubscribe$ = new Subject<any>();

  public patientHeader$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this._store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );
  public infectionQuestions$ = this._infStore.pipe(
    select(fromInfectionReducer.getAllQuestionSelector),
    takeUntil(this.unsubscribe$)
  );
  public infections$ = this._infStore.pipe(
    select(fromInfectionReducer.getAllInfectionBundlesData),
    takeUntil(this.unsubscribe$)
  );

  public infectionLoading$ = this._infStore.pipe(
    select(fromInfectionReducer.getLoading),
    takeUntil(this.unsubscribe$)
  );

  currDate = moment().startOf("day").toISOString();

  public currUser: User;
  public currPatient: Patient;
  days: number[] = [];
  selectedDay: number = 0;
  selectedDate: string;
  activeInfBundle: INFECTION_BUNDLE_TYPES = INFECTION_BUNDLE_TYPES.VAP;
  defaultQuestions: DefaultQuestionInterface[] = [];
  infectionsArray: InfectionBundle[] = [];
  editCheckListIndex: number = null;
  editCheckListStore: any = null;
  tooltipStore: any;

  commentColumnDataStore: InfectionBundle | null = null;
  commentFormControl = new FormControl("", Validators.required);

  questionTypes = QUESTION_TYPE;
  constructor(
    private _store: Store<any>,
    private _infStore: Store<fromInfectionReducer.InfectionBundleReducerState>,
    private _fb: FormBuilder,
    private _findObjectInArrayWithKeyPipe: FindObjectInArrayWithKeyPipe,
    private _icuDayPipe: IcuDaysPipe,
    private _vitalDayPipe: VitalDayPipe,
    private _matIconRegistry: MatIconRegistry,
    private _domSantizer: DomSanitizer,
    private _cdrf: ChangeDetectorRef,
    private _ngxPermissionsService: NgxPermissionsService
  ) {
    this._matIconRegistry.addSvgIcon(
      "cp-upload_circle_grey",
      this._domSantizer.bypassSecurityTrustResourceUrl(
        "assets/icons/upload_circle_grey.svg"
      )
    );

    this._matIconRegistry.addSvgIcon(
      "cp-comment_grey",
      this._domSantizer.bypassSecurityTrustResourceUrl("assets/icons/chat.svg")
    );
  }

  ngOnInit(): void {
    this.users$.subscribe((data) => {
      this.currUser = data.currentUser;
    });

    this.patientHeader$.subscribe((patient) => {
      if (!patient?.CPMRN) return;
      this.currPatient = patient;
    });

    this.infectionQuestions$.subscribe((data) => {
      if (!data || !Object.keys(data)?.length) return;
      this.defaultQuestions =
        data[INFECTION_BUNDLES_CONFIG[this.activeInfBundle].keyName];
    });

    this.infections$.subscribe((data) => {
      if (!this.currPatient?.CPMRN) return;

      this.resetEditCheckList();

      this.infectionsArray = data?.filter(
        (inf) => inf.type === this.activeInfBundle
      );

      const currDate = moment().startOf("day").toISOString();
      const currDay = +this._icuDayPipe
        .transform(this.currPatient?.ICUAdmitDate)
        .split(" ")[0];

      if (!this.selectedDay) {
        // setting current date and day
        [this.selectedDate, this.selectedDay] = [currDate, currDay];
      }

      if (!this._ngxPermissionsService.getPermission("create_infections"))
        return;

      const currentInfectionRecord = this._vitalDayPipe.transform(
        this.infectionsArray as any,
        currDay
      );

      if (!currentInfectionRecord?.length) {
        this.createNewCheckList(
          0,
          true,
          this.selectedDay === currDay ? true : false
        );
      } else {
        this.newCheckListData = null;
      }
    });
  }

  @HostListener("document:click", ["$event.target"])
  onClick(eventTarget) {
    if (
      this.editCheckListStore &&
      this.editCheckListStore._id &&
      !this.infectionRecordsTables?.some((ele) =>
        ele?.infectionTable?.nativeElement?.contains(eventTarget)
      )
    ) {
      this.resetEditCheckList();
      return;
    }
  }

  resetEditCheckList() {
    this.editCheckListStore = null;
    this.editCheckListIndex = null;

    this.commentColumnDataStore = null;
    this.commentFormControl.reset();
  }

  editCheckList(infectionRecord: InfectionBundle, recordIndex) {
    if (!infectionRecord) return;

    this.editCheckListStore = {
      ...infectionRecord,
      questions: this.initFormArrayForQuestions(infectionRecord?.questions),
    };
    this.editCheckListIndex = recordIndex;
  }

  initFormArrayForQuestions(questionsRecord = []) {
    return this._fb.array(
      this.defaultQuestions?.map((question) => {
        const isQuestionChecked =
          question.type !== QUESTION_TYPE.HEADER
            ? this._findObjectInArrayWithKeyPipe.transform(
                questionsRecord,
                "question",
                question?.name
              )
            : null;
        return this._fb.group({
          question: [question?.name],
          value: [isQuestionChecked && isQuestionChecked["value"]],
          type: [question?.type],
          header: question?.header,
        });
      })
    );
  }

  newCheckListData: NewInfectionBundleChecklist;
  createNewCheckList(
    recordIndex,
    isNewInTheList: boolean = true,
    isEditChecklist: boolean = true
  ) {
    this.newCheckListData = {
      ...EMPTRY_INF_CHECKLIST,
      timestamp: moment().toISOString(),
      isNewChecklist: true,
      dayNum: +this._icuDayPipe
        .transform(this.currPatient?.ICUAdmitDate)
        .split(" ")[0],
      type: this.activeInfBundle,
      questions: this.defaultQuestions.map((quesSet) => ({
        question: quesSet?.name,
        type: quesSet?.type,
        value: null,
        header: quesSet?.header || null,
      })),
    };
    this.infectionsArray = [this.newCheckListData, ...this.infectionsArray];
    // this.infectionsArray = isNewInTheList
    //   ? [this.newCheckListData, ...this.infectionsArray]
    //   : [...this.infectionsArray, this.newCheckListData];

    if (!isEditChecklist) return;

    setTimeout(() => {
      this.editCheckList(
        this.newCheckListData,
        isNewInTheList ? 0 : recordIndex
      );
    });
  }

  getSelectedDay(event) {
    this.selectedDay = event;
    this.commonDateChangeFunc();
  }

  getSelectedDate(event) {
    this.selectedDate = moment(event).startOf("day").toISOString();
    this.commonDateChangeFunc();
  }

  /**
   * @description: trigger when date/day change happen
   * check if selected date is curr date then edited-checklist should show
   * else default data - checklist should show
   * @author Rajat Saini
   * @date Aug 21, 2023
   */
  commonDateChangeFunc() {
    if (this.selectedDate != this.currDate) {
      this.resetEditCheckList();
      return;
    }

    this.editCheckList(this.newCheckListData, 0);
  }

  submitCheckList() {
    const checkListPayload: CreateInfBundChecklistPayload = {
      CPMRN: this.currPatient?.CPMRN,
      encounters: this.currPatient?.encounters,
      checklist: {
        _id: this.editCheckListStore?._id,
        timestamp: this.editCheckListStore?.isNewChecklist
          ? moment().toISOString()
          : this.editCheckListStore?.timestamp || moment().toISOString(),
        comment: this.editCheckListStore?.value,
        type: this.editCheckListStore?.type,
        questions: this.removeHeaderQuestion(
          this.editCheckListStore?.questions?.value
        ),
      },
    };

    this.editCheckListStore.isNewChecklist
      ? this.saveNewChecklist(checkListPayload)
      : this.updateChecklist(checkListPayload);
  }

  saveNewChecklist(checkListPayload: CreateInfBundChecklistPayload) {
    delete checkListPayload.checklist?._id;

    this._infStore.dispatch(
      fromInfectionReducer.createInfBundChecklist({
        payload: checkListPayload,
      })
    );
  }

  updateChecklist(checkListPayload) {
    this._infStore.dispatch(
      fromInfectionReducer.updateInfBundChecklist({
        payload: checkListPayload,
      })
    );
  }

  removeHeaderQuestion(questions: InfectionBundleQuestions[]) {
    return questions?.filter((quesObj) => quesObj.type != QUESTION_TYPE.HEADER);
  }

  saveInputComment(event) {
    const updateChecklistPayload: CreateInfBundChecklistPayload = {
      CPMRN: this.currPatient?.CPMRN,
      encounters: this.currPatient?.encounters,
      checklist: event,
    };

    this.updateChecklist(updateChecklistPayload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
