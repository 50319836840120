import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import * as fromFinalNotesType from "./final-notes.reducers";
import * as fromDraftNotesType from "./draft-notes.reducer";
export const NOTE_VIEW_STATE = "notes-view-overhaul";
export interface NoteFormState {
  finalNotes: fromFinalNotesType.FinalNoteState;
  draftNotes: fromDraftNotesType.DraftNoteState;
}

export const reducer: ActionReducerMap<NoteFormState> = {
  finalNotes: fromFinalNotesType.reducer,
  draftNotes: fromDraftNotesType.reducer,
};

export const getNoteState =
  createFeatureSelector<NoteFormState>(NOTE_VIEW_STATE);
