import { select, Store } from "@ngrx/store";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  faComments,
  faFlask,
  faPrescriptionBottleAlt,
  faProcedures,
  faSearch,
  faTint,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

import { Orderable } from "../../../models/Orderable.model";
import { RequestComponent } from "../request/request.component";
import { SearchService } from "../../../services/order-search.service";
import * as fromPatientHeader from "../../../store/reducers/patient-chart/patient-header";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { of, pipe, BehaviorSubject, Observable, Subject } from "rxjs";
import {
  timeout,
  switchMap,
  catchError,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { SiblingService } from "src/app/services/sibling.service";

export interface SearchOrder {
  type: string;
  value: Orderable;
}

@Component({
  selector: "app-order-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() placeholder?: string;
  @Input() type?: string;
  @Input() enableRequest = false;
  @Input() userName?: string;
  @Output() search: EventEmitter<SearchOrder> = new EventEmitter<SearchOrder>();

  public typeaheadList: any = [];
  public runnNo = -1;
  public pervSearchTerm = "";
  public ordersList: any;
  public searchText: any;
  public errorMsg: string = null;
  private focusIn = false;
  private noData = false;
  public requestBtnVisible = false;

  private patientHospitalName = "";

  /* ICONS */
  faSearch = faSearch;
  faTint = faTint;
  faComments = faComments;
  faUtensils = faUtensils;
  faFlask = faFlask;
  faPrescriptionBottleAlt = faPrescriptionBottleAlt;
  faProcedures = faProcedures;
  faProductHunt = faProductHunt;
  //for filter and brand name
  selectedBrand: any = "";
  filterInput = [];
  orderTypes: UntypedFormGroup;
  ordertype: any;
  menuButton = false;
  loader = false;
  @HostListener("click")
  clickInside() {
    this.focusIn = true;
  }

  @HostListener("document:click")
  clickOut() {
    if (!this.focusIn && !this.menuButton) {
      this.resetOrderSearch();
    }
    this.focusIn = false;
  }

  searchAPIEvent$ = new Subject<any>();
  searchResult$ = this.searchAPIEvent$.pipe(
    debounceTime(400),
    distinctUntilChanged(),
    pipe(
      switchMap((ele) => {
        return this.searchService.search(
          ele?.term,
          ele?.type,
          null,
          ele?.patientHospitalName,
          ele?.filterInput
        );
      }),
      // timeout(30_000),
      catchError((error) => {
        console.log("error", error);
        return of({
          protocol: [],
        });
      })
    )
  );

  constructor(
    private searchService: SearchService,
    private dialog: MatDialog,
    private store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    private _siblingService: SiblingService,
    private cdr: ChangeDetectorRef
  ) {
    this._siblingService.getResetFlag().subscribe((data) => {
      if (data == "true") {
        this.resetOrderSearch();
      }
    });
  }

  ngOnInit() {
    //for filter initializing array starts
    this.orderTypes = this.formBuilder.group({
      ordertype: this.formBuilder.array([]),
    });
    this.ordertype = [
      "Medication",
      "Diet",
      "Transfusion",
      "Procedure",
      "Vents",
      "Labs",
      "Protocol",
    ];
    //for filter initializing array  ends

    this.resetOrderSearch();
    if (!this.placeholder) {
      this.placeholder = "Search For Orders";
    }

    if (!this.type) {
      this.type = "order";
    }

    this.store
      .pipe(select(fromPatientHeader.getPatientHospitalName))
      .subscribe((hospitalName) => (this.patientHospitalName = hospitalName));

    this.searchResult$.subscribe((data: any) => {
      this.requestBtnVisible = true;

      if (data && this.searchText) {
        this.errorMsg = null;
        this.noData = false;
        this.ordersList = this.setOrderTypePriority(data);
        this.typeaheadList = data["lab"]
          ? [
              ...data["protocol"],
              ...data["med"],
              ...data["lab"],
              ...data["procedure"],
              ...data["vents"],
              ...data["comm"],
              ...data["blood"],
              ...data["diet"],
            ]
          : [...data["protocol"]];
        this.loader = false;
        this.typeaheadList.forEach((element) => {
          if (element.patientType != undefined && element.patientType != null) {
            element.patientType.forEach((type, i) => {
              if (type == "adult") {
                element.patientType.splice(i, 1);
              }
            });
          }
          element.selectedBrand = null;
        });

        if (
          data &&
          data["protocol"] &&
          data["protocol"]?.length === 0 &&
          data["lab"] == undefined &&
          data["blood"] == undefined &&
          data["comm"] == undefined &&
          data["diet"] == undefined &&
          data["med"] == undefined &&
          data["procedure"] == undefined &&
          data["vents"] == undefined
        ) {
          this.loader = false;
          this.noData = true;
          this.errorMsg = "No orders found";
        }
      } else {
        this.loader = false;
        this.noData = true;
        this.errorMsg = "No orders found";
      }
    });
  }

  ngOnDestroy(): void {
    this.searchAPIEvent$.unsubscribe();
  }

  searchOrders(term: string, event) {
    this.requestBtnVisible = false;

    if (this.typeaheadList[this.runnNo]) {
      this.typeaheadList[this.runnNo]["class"] = "";
    }

    const pressedKey = event.keyCode;

    if (pressedKey === 38) {
      if (this.runnNo > 0) {
        this.runnNo--;
      }
      if (this.typeaheadList.length) {
        this.typeaheadList[this.runnNo]["class"] = "selected";
      }
      this.requestBtnVisible = true;
    } else if (pressedKey === 40) {
      if (this.runnNo < this.typeaheadList.length - 1) {
        this.runnNo++;
      }
      if (this.typeaheadList.length) {
        this.typeaheadList[this.runnNo]["class"] = "selected";
      }
      this.requestBtnVisible = true;
    }

    // if escape
    if (pressedKey === 27) {
      this.typeaheadList = [];
      this.pervSearchTerm = "";
      this.runnNo = -1;
    } else if (term?.length > 2) {
      this.pervSearchTerm = term;
      this.ordersList = {};

      this.loader = true;

      this.searchAPIEvent$.next({
        term,
        type: this.type,
        patientHospitalName: this.patientHospitalName,
        filterInput: this.filterInput,
      });
    } else {
      this.filterInput = [];
      this.ordersList = {};
      this.typeaheadList = [];
      this.pervSearchTerm = "";
      this.runnNo = -1;
      this.resetOrderSearch();
    }
  }

  setOrder(type: string, value: Orderable, brand?): void {
    let canDelete = true;
    this.menuButton = false;
    if (brand !== undefined) value.selectedBrand = brand;
    if (value.presets && value.presets.length > 0) {
      // @ts-ignore
      value.presets = value.presets.map((preset) => {
        if (preset.protocols && preset.protocols.length > 0) {
          canDelete = false;
        }

        return preset;
      });
    }

    this.search.emit({ type, value: { ...value, canDelete } });
    this.resetOrderSearch();
  }

  setSelected(val) {
    if (this.runnNo >= 0) {
      if (this.typeaheadList[this.runnNo]) {
        this.typeaheadList[this.runnNo]["class"] = "";
      }
    }
    this.runnNo = val;
    if (this.typeaheadList[this.runnNo]) {
      this.typeaheadList[this.runnNo]["class"] = "selected";
    }
  }

  resetOrderSearch() {
    this.ordersList = {};
    this.typeaheadList = [];
    this.searchText = "";
    this.requestBtnVisible = false;
    this.errorMsg = null;
    this.loader = false;
  }

  filterOrders(type: string, term: string) {
    return this.ordersList[type].filter(
      (orderValue) =>
        orderValue.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );
  }

  /*
   * NAME: setOrderTypePriority
   * PURPOSE: sets the order of orderable types to appear in search result
   * DESCRIPTION:
   * PARAMS: data - data return from backend on search
   * RETURNS:
   * USED BY:
   * CREATED DATE: 20/04/20
   * AUTHOR: Gunjit Agrawal
   */
  setOrderTypePriority(data) {
    return {
      protocol: data["protocol"],
      med: data["med"],
      lab: data["lab"],
      procedure: data["procedure"],
      vent: data["vents"],
      comm: data["comm"],
      blood: data["blood"],
      diet: data["diet"],
    };
  }

  /**
   * Opens request modal to allow users to submit orderable request.
   */
  makeRequest() {
    this.errorMsg = null;
    this.requestBtnVisible = false;
    this.resetOrderSearch();

    const dialogRef = this.dialog.open(RequestComponent, { width: "700" });
    dialogRef.componentInstance.userName = this.userName;
  }
  //for filter starts
  onChange(event) {
    if (event.source.value == "Medication") event.source.value = "med";
    if (event.source.value == "Diet") event.source.value = "diet";
    if (event.source.value == "Transfusion") event.source.value = "blood";
    if (event.source.value == "Procedure") event.source.value = "procedure";
    if (event.source.value == "Vents") event.source.value = "vents";
    if (event.source.value == "Labs") event.source.value = "lab";
    if (event.source.value == "Protocol") event.source.value = "protocol";

    if (event.checked) {
      this.filterInput.push(event.source.value);
    } else {
      const i = this.filterInput.findIndex((x) => x === event.source.value);
      this.filterInput.splice(i, 1);
    }

    if (this.searchText?.length > 2) {
      this.pervSearchTerm = this.searchText;
      this.ordersList = {};

      this.loader = true;

      this.searchAPIEvent$.next({
        term: this.searchText,
        type: this.type,
        patientHospitalName: this.patientHospitalName,
        filterInput: this.filterInput,
      });
    } else {
      this.filterInput = [];
      this.ordersList = {};
      this.typeaheadList = [];
      this.pervSearchTerm = "";
      this.runnNo = -1;
    }
  }

  //for filter ends
  loaderActive(searchText) {
    if (searchText.length >= 3 && this.loader) {
      return false;
    } else {
      return true;
    }
  }
  isDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
}
