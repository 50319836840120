import { Injectable } from "@angular/core";
import { jsPDFDocument } from "jspdf-autotable";
import { FormGroup, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import moment from "moment";
import { Sort } from "@angular/material/sort";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  hasError(group: any, controlName: string, errorName: string): boolean {
    if (!!controlName) return group.controls[controlName].hasError(errorName);
    // for nested controlller inside group
    else {
      return group.errors && group.errors[errorName]; // for group errors
    }
  }

  endTimeValidator = (timeZone) => {
    return (group): ValidationErrors | null => {
      const getDateTime = (dateTimeGroup: UntypedFormGroup): number => {
        if (!dateTimeGroup?.value) return null;
        const { date, minute, hour } = dateTimeGroup.value,
          dateTimeObj = moment(date);
        return dateTimeObj
          .tz(timeZone)
          .set({ hour: hour, minute: minute ?? 0, second: 0, millisecond: 0 })
          .valueOf();
      };
      const startDateTime = getDateTime(group.get("startTime"));
      const endDateTime = getDateTime(group.get("endTime"));
      if (endDateTime < startDateTime) {
        return { endDateLesser: true };
      }

      return null;
    };
  };

  // Validation to make sure that end date & time is always 5 mins ahead of start date & time

  getDataUri(url, callback) {
    let image = new Image();
    image.src = `${url}?r=${Math.floor(Math.random() * 100000)}`;
    image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);
      try {
        callback(canvas.toDataURL("image/jpeg"));
      } catch (e) {
        console.log(e);
        callback(null);
      }
    };
  }

  setImageHeader(doc: jsPDFDocument, imgData) {
    if (!imgData) return;
    const imgProps = doc.getImageProperties(imgData);
    const containerHeight = 18;
    const containerWidth = 50;
    let imgHeight = containerHeight;
    let imgWidth = (imgProps.width * imgHeight) / imgProps.height;
    let xPos = 15;
    let yPos = 9.6;
    if (imgWidth > containerWidth) {
      imgWidth = containerWidth;
      imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      yPos += (containerHeight - imgHeight) / 2;
    }

    doc.addImage(imgData, "JPEG", xPos, yPos, imgWidth, imgHeight);
  }

  // This is to check if the control is invalid and not the parent form.
  public isFormControlInvalid(form: FormGroup): boolean {
    return Object.keys(form.controls).some((key) => form.get(key).invalid);
  }

  isValidHttpUrl(str: string) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
