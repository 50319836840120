import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription, interval } from "rxjs";
import * as moment from "moment";
import { AudioService } from "src/app/shared/audio/audio.service";
import { NotificationType } from "../../enums/notificationtype.enum";
import { ToasterService } from "../../services/toaster.service";

@Component({
  selector: "app-codeblue-toaster",
  templateUrl: "./codeblue-toaster.component.html",
  styleUrls: ["./codeblue-toaster.component.scss"],
})
export class CodeblueToasterComponent implements OnInit, OnDestroy {
  @Input() toasterData: any;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() endCallEvent = new EventEmitter<any>();

  private timerSubscription: Subscription;
  private subscription: Subscription;
  private timeoutId: any;
  public isCodeBlue: boolean = false;
  public isRunning: boolean = false;
  public patientName: string;
  public alertHeader: string;
  public alertText: string;
  public runningFor: string;

  constructor(
    private audioService: AudioService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.isCodeBlue = this.toasterData["is_codeblue"];
    this.isRunning = this.toasterData["is_running"];
    this.patientName = this.toasterData["name"];
    let cpmrn = this.toasterData["patient_info"]["cpmrn"];
    // generate alert header text
    this.alertHeader = "";
    if (this.toasterData["type"] === NotificationType.codeblueRaised) {
      this.alertHeader = "CodeBlue raised ";
    } else if (this.toasterData["type"] === NotificationType.eCallRaised) {
      this.alertHeader = "eCall raised ";
    }
    // if patient name is defined, show it on the header
    if (cpmrn) {
      this.alertHeader = this.alertHeader + "for " + this.patientName;
    }
    // generate alert body text
    if (this.isCodeBlue) {
      this.alertText = "A CodeBlue has been started for your ";
    } else {
      this.alertText = "An eCall has been started for your ";
    }
    if (cpmrn) {
      this.alertText =
        this.alertText + "patient with CPMRN:" + cpmrn + ", bed no ";
      this.alertText = this.alertText + this.toasterData["bed_number"] + " at ";
    } else {
      this.alertText = this.alertText + "assigned unit ";
    }
    this.alertText =
      this.alertText +
      this.toasterData["hospital"] +
      "/" +
      this.toasterData["unit"] +
      ".";

    // subscribe to toaster service for logout event
    this.subscription = this.toasterService.isOpen$.subscribe((isOpen) => {
      if (!isOpen) {
        this.closeDialog("logout");
      }
    });
    // update alert's running for time every second
    this.timerSubscription = interval(1000).subscribe(() => {
      this.runningFor = this.calculateTime(this.toasterData["raised_at"]);
    });
    // eCall toaster should be closed after 15 seconds
    if (!this.isCodeBlue) {
      this.timeoutId = setTimeout(() => {
        this.closeDialog("timeout");
      }, 15000); // 15000 milliseconds = 15 seconds
    }
    // play audio for toaster
    this.playAudio();
  }

  playAudio(): void {
    let playedAudio: any = [];
    const playedAudioString = localStorage.getItem("playedAudio");
    if (playedAudioString !== null) {
      playedAudio = JSON.parse(playedAudioString);
    }
    const toasterID = this.toasterData["_id"];
    if (this.isCodeBlue) {
      // play code blue tone once on raise event
      if (this.toasterData["type"] === NotificationType.codeblueRaised) {
        if (!playedAudio.includes(toasterID)) {
          this.audioService.playCodeBlueAlert();
          playedAudio.push(toasterID);
          const playedAudioString = JSON.stringify(playedAudio);
          // after playing tone set up into local storage
          localStorage.setItem("playedAudio", playedAudioString);
        }
      }
    } else {
      // play ecall tone once on raise even
      if (this.toasterData["type"] === NotificationType.eCallRaised) {
        if (!playedAudio.includes(toasterID)) {
          this.audioService.playCallAlert();
          playedAudio.push(toasterID);
          const playedAudioString = JSON.stringify(playedAudio);
          // after playing tone set up into local storage
          localStorage.setItem("playedAudio", playedAudioString);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.timerSubscription.unsubscribe();
    clearTimeout(this.timeoutId);
  }

  calculateTime(timestamp: string): string {
    const now = moment();
    const targetTime = moment(timestamp, "YYYY-MM-DD HH:mm:ss");
    const diff = now.diff(targetTime, "seconds");
    const minutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} mins`;
  }

  calculateTimeDuration(): string {
    let endTimestamp = this.toasterData.completed_at;
    if (endTimestamp === "") {
      // if alert is not completed then it's canceled
      endTimestamp = this.toasterData.cancelled_at;
    }
    const startTime = moment(this.toasterData.raised_at, "YYYY-MM-DD HH:mm:ss");
    const endTime = moment(endTimestamp, "YYYY-MM-DD HH:mm:ss");
    const minutesAgo = endTime.diff(startTime, "minutes");
    const duration = minutesAgo + " min" + (minutesAgo > 1 ? "s" : "");
    return duration;
  }

  closeDialog(interaction: string): void {
    this.toasterData["interaction"] = interaction;
    this.closeEvent.emit(this.toasterData);
  }

  endAlertEvent(): void {
    this.endCallEvent.emit(this.toasterData);
    this.closeDialog("end codeblue button clicked");
  }

  convertToHTTPS(link: string): string {
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return link;
    }
    return `https://${link}`;
  }
}
