import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromSbarAlerts from "./sbar-alerts.reducer";
import * as fromRoot from "../index";

export interface SbarAlertState {
  data: fromSbarAlerts.State;
}

export interface State {
  sbarAlert: SbarAlertState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromSbarAlerts.reducer,
};

/** SELECTOR setup */
export const getSbarAlertState =
  createFeatureSelector<SbarAlertState>("sbarAlert");

export const getSbarAlertData = createSelector(
  getSbarAlertState,
  (state) => state.data
);

export const getSbarAlerts = createSelector(
  getSbarAlertData,
  fromSbarAlerts.getAllSbarAlerts
);
