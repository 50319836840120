import { Action, createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import * as _moment from "moment-timezone";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment-timezone";

import { RAlertMessage } from "../../models/RAlert/message.model";
import { RAlertApiActions, RAlertSocketActions } from "../actions";

const moment = _rollupMoment || _moment;

export interface State extends EntityState<RAlertMessage> {}

export function sortByTime(a: RAlertMessage, b: RAlertMessage): number {
  if (a._id === b._id) {
    return 0;
  } else if (a._id > b._id) {
    return -1;
  } else {
    return 1;
  }
}

export const adapter: EntityAdapter<RAlertMessage> =
  createEntityAdapter<RAlertMessage>({
    selectId: (message: RAlertMessage) => message._id,
    sortComparer: sortByTime,
  });

export const initialState: State = adapter.getInitialState();

const rAlertMessageReducer = createReducer(
  initialState,
  on(RAlertSocketActions.addRAlertMessage, (state, { message }) =>
    adapter.addOne(message, state)
  ),
  on(RAlertApiActions.addAllRalertMessages, (state, { messages }) =>
    adapter.setAll(messages, state)
  ),
  on(
    RAlertApiActions.acknowledgeMessage,
    RAlertSocketActions.acknowledgeRAlertMessage,
    (state, { id }) => adapter.removeOne(id, state)
  )
);

export function reducer(state: State | undefined, action: Action) {
  return rAlertMessageReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectMessageIds = selectIds;

export const selectMessageEntities = selectEntities;

export const selectAllMessages = selectAll;

export const selectMessageTotal = selectTotal;
