import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ProtocolApiActions } from '../actions';
import { ProtocolFileData } from '../../models/protocol/ProtocolFileDate.model';

export interface State extends EntityState<ProtocolFileData> {
}

export const adapter: EntityAdapter<ProtocolFileData> = createEntityAdapter<ProtocolFileData>({
  selectId: (file: ProtocolFileData) => file.name,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

const protocolFileReducer = createReducer(
  initialState,
  on(
    ProtocolApiActions.getProtocolFileSuccess,
    (state, { data }) => data ? adapter.addOne(data, state) : { ...state }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return protocolFileReducer(state, action);
}

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();

export const selectProtocolFileIds = selectIds;

export const selectProtocolFileEntities = selectEntities;

export const selectAllProtocolFiles = selectAll;

export const selectProtocolFileTotal = selectTotal;
