import { Component, OnInit, OnDestroy } from "@angular/core";
import { OrderService } from "src/app/services/order.service";
import { Store, select } from "@ngrx/store";
import { ActionTypes } from "src/app/store/actions/order/index";

import * as fromPatientHeaderReducers from 'src/app/store/reducers/patient-chart/patient-header/index';
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"]
})
export class OrdersComponent implements OnInit, OnDestroy {
  public patientHeader$ = this.store.pipe(select(fromPatientHeaderReducers.getPatHeaderData));
  public currPatient;
  public CPMRN;
  public encounters;
  
  constructor(private _orderService: OrderService, private store: Store<{}>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.patientHeader$.subscribe(data => {
      if (data && data.CPMRN) {
        this.currPatient = data;
      }
    });
  }

  ngOnDestroy() {
    // this._orderService.stopListening();
    // this.store.dispatch({ type: ActionTypes.resetOrders });
  }
}
