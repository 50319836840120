import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromRoot from "../index";
import * as fromFileData from "./file-data.reducer";
import * as fromFileStatus from "./file-status.reducer";
import * as fromFileProgressBar from "./file-progress-bar.reducer";

export interface FileState {
  progressBar: fromFileProgressBar.State;
  data: fromFileData.State;
  status: fromFileStatus.State;
}

export interface State extends fromRoot.AppState {
  file: FileState;
}

export const reducers: ActionReducerMap<FileState, any> = {
  progressBar: fromFileProgressBar.reducer,
  data: fromFileData.reducer,
  status: fromFileStatus.reducer,
};

export const getFileState = createFeatureSelector<FileState>("file");

/*
 * File Progress Bar selectors
 * */
export const getFileProgressBarState = createSelector(
  getFileState,
  (state: FileState) => state.progressBar
);

export const selectFileProgressBarIds = createSelector(
  getFileProgressBarState,
  fromFileProgressBar.selectFileProgressBarIds
);

export const selectFileProgressBarEntities = createSelector(
  getFileProgressBarState,
  fromFileProgressBar.selectFileProgressBarEntities
);

export const selectFileProgressBarTotal = createSelector(
  getFileProgressBarState,
  fromFileProgressBar.selectFileProgressBarTotal
);

export const selectAllFileProgressBars = createSelector(
  getFileProgressBarState,
  fromFileProgressBar.selectAllFileProgressBars
);

/*
 * File Data Selectors
 * */
export const getFileDataState = createSelector(
  getFileState,
  (state: FileState) => state.data
);

export const getFileDataEntities = createSelector(
  getFileDataState,
  fromFileData.getFileDataEntities
);

export const getFileDateEntityById = createSelector(
  getFileDataEntities,
  (state, id) => state.filter((file) => file.id === id)[0]
);

export const getFileDateEntityByName = createSelector(
  getFileDataEntities,
  (state, name) => state.filter((file) => file.name === name)[0]
);

export const getUploadedFileKeysForVisionTask = createSelector(
  getFileDataEntities,
  (fileEntities, uploadedIds) => {
    const keys = [];

    for (const file of fileEntities) {
      if (uploadedIds.indexOf(file.id) > -1) {
        keys.push(file.key);
      }
    }

    return keys;
  }
);

/*
 * File State reducers
 * */
export const getFileStatusState = createSelector(
  getFileState,
  (state: FileState) => state.status
);

export const getFileStateFilesToBeUploaded = createSelector(
  getFileStatusState,
  fromFileStatus.getFileStateFilesToBeUploaded
);

export const getFileStateFilesUploaded = createSelector(
  getFileStatusState,
  fromFileStatus.getFileStateFilesUploaded
);

export const areAllFilesUploaded = createSelector(
  getFileStateFilesToBeUploaded,
  getFileStateFilesUploaded,
  (toUpload, uploaded, filesToBeChecked) => {
    if (filesToBeChecked.length === 0 || uploaded.length === 0) {
      return false;
    }

    for (let i = 0; i < filesToBeChecked.length; i++) {
      if (uploaded.indexOf(filesToBeChecked[i]) === -1) {
        return false;
      }
    }

    return true;
  }
);
