import * as fromAuditLineChart from "./auditlineCharts.reducers";
import * as fromRoot from "../../reducers";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface AuditLineChartState {
  data: fromAuditLineChart.State;
}

export interface State extends fromRoot.AppState {
  auditLineChart: AuditLineChartState;
}

export const reducers: ActionReducerMap<AuditLineChartState, any> = {
  data: fromAuditLineChart.reducer,
};

export const getAuditLineChartState =
  createFeatureSelector<AuditLineChartState>("auditLineChart");

export const getAuditLineChartData = createSelector(
  getAuditLineChartState,
  (state) => state.data
);

export const getAuditLineChartDataData = createSelector(
  getAuditLineChartData,
  fromAuditLineChart.getData
);
