export const VITAL_PROPS_ADULTS: { val: string; show: string }[] = [
  // vitals
  { val: "daysTemperature", show: "Temp" },
  { val: "daysHR", show: "HR" },
  { val: "daysRR", show: "RR" },
  { val: "daysBP", show: "BP" },
  { val: "daysMAP", show: "MAP" },
  { val: "daysCVP", show: "CVP" },
  {
    val: "daysSpO2",
    show: "SpO<sub>2</sub><small>(FiO<sub>2</sub>)</small>",
  },
  { val: "daysAVPU", show: "AVPU" },
  { val: "daysPatPosition", show: "Patient Position" },
  { val: "daysGCS", show: "GCS" },
  { val: "daysGCSeyes", show: "Eye" },
  { val: "daysGCSverbal", show: "Verbal" },
  { val: "daysGCSmotor", show: "Motor" },
  { val: "", show: "Pupillary scale" },
  { val: "daysLeftPupillarySize", show: "Size: Left" },
  { val: "daysLeftPupilaryCondition", show: "Condition: Left" },
  { val: "daysRightPupillarySize", show: "Size: Right" },
  { val: "daysRightPupilaryCondition", show: "Condition: Right" },

  // scores
  // { val: "daysFalls", show: "Fall" },
  // { val: "daysBraden", show: "Braden" },
  // { val: "daysPain", show: "Pain" },
  // { val: "daysRass", show: "RASS" },
  // { val: "daysRassCAMICU", show: "CAM-ICU" },

  // oxygen therapy
  { val: "daysTherapyDevice", show: "Oxygen Therapy" },
  { val: "daysOxygenFlow", show: "Oxygen Flow" },
  { val: "daysVentMode", show: "Control Variable" },
  { val: "daysVentBreathSequence", show: "Breath Sequence" },
  { val: "daysInvasiveMode", show: "Mode" },
  { val: "daysFiO2", show: "FiO<sub>2</sub>" },

  { val: "daysVentVt", show: "V<sub>t</sub>" },
  { val: "daysVentPEEP", show: "PEEP/EPAP" },
  { val: "daysRR", show: "RR<sub>total</sub>" },
  { val: "daysVentRRset", show: "RR<sub>set</sub>" },
  { val: "daysVentPinsp", show: "P<sub>insp</sub>/IPAP" },
  { val: "daysVentPsupport", show: "PS" },
  { val: "daysVentPpeak", show: "P<sub>peak</sub>" },
  { val: "daysVentPplat", show: "P<sub>plat</sub>" },
  { val: "daysVentPmean", show: "P<sub>mean</sub>" },
  { val: "daysVentMV", show: "MV" },

  { val: "daysIE", show: "I:E" },

  // airway
  { val: "daysVentAirway", show: "Airway" },
  { val: "nasopharyngealAirway", show: "Nasopharyngeal airway" },
  { val: "oralAirway", show: "Oral airway" },
  { val: "biteBlock", show: "Bite Block" },
  { val: "supraglotticAirway", show: "Supraglottic airway" },
  { val: "cooksAirway", show: "Cooks airway" },
  {
    val: "oralEndotrachealTube",
    show: "Oral endotracheal <br> tube with cuff",
  },
  {
    val: "nasalEndotrachealTube",
    show: "Nasal endotracheal <br> tube with cuff",
  },
  {
    val: "tracheostomyTube",
    show: "Tracheostomy <br> Tube",
  },
];

export const VITAL_PROPS_NEONATAL: { val: string; show: string }[] = [
  // vitals
  { val: "daysTemperature", show: "Temp" },
  { val: "daysHR", show: "HR" },
  { val: "daysRR", show: "RR" },
  { val: "daysBP", show: "BP" },
  { val: "daysMAP", show: "MAP" },
  { val: "daysCVP", show: "CVP" },
  {
    val: "daysSpO2",
    show: "SpO<sub>2</sub><small>(FiO<sub>2</sub>)</small>",
  },
  { val: "daysAVPU", show: "AVPU" },
  { val: "daysPatPosition", show: "Patient Position" },
  { val: "daysGCS", show: "GCS" },
  { val: "daysGCSeyes", show: "Eye" },
  { val: "daysGCSverbal", show: "Verbal" },
  { val: "daysGCSmotor", show: "Motor" },
  { val: "", show: "Pupillary scale" },
  { val: "daysLeftPupillarySize", show: "Size: Left" },
  { val: "daysLeftPupilaryCondition", show: "Condition: Left" },
  { val: "daysRightPupillarySize", show: "Size: Right" },
  { val: "daysRightPupilaryCondition", show: "Condition: Right" },

  // scores
  // { val: "daysFalls", show: "Fall" },
  // { val: "daysBraden", show: "Braden" },
  // { val: "daysPain", show: "Pain" },
  // { val: "daysRass", show: "RASS" },
  // { val: "daysRassCAMICU", show: "CAM-ICU" },

  // oxygen therapy
  { val: "daysTherapyDevice", show: "Oxygen Therapy" },
  { val: "daysOxygenFlow", show: "Oxygen Flow" },
  { val: "daysVentMode", show: "Control Variable" },
  { val: "daysVentBreathSequence", show: "Breath Sequence" },
  { val: "daysInvasiveMode", show: "Mode" },
  { val: "daysFiO2", show: "FiO<sub>2</sub>" },

  { val: "daysVentVt", show: "V<sub>t</sub>" },
  { val: "daysVentPEEP", show: "PEEP/EPAP" },
  { val: "daysRR", show: "RR<sub>total</sub>" },
  { val: "daysVentRRset", show: "RR<sub>set</sub>" },
  { val: "daysVentPinsp", show: "P<sub>insp</sub>/IPAP" },
  { val: "daysVentPsupport", show: "PS" },
  { val: "daysVentPpeak", show: "P<sub>peak</sub>" },
  { val: "daysVentPplat", show: "P<sub>plat</sub>" },
  { val: "daysVentPmean", show: "P<sub>mean</sub>" },
  { val: "daysVentMV", show: "MV" },

  { val: "daysIE", show: "I:E" },

  //invasive new fields @sheethalkhan  24-04-2023
  // vent settings

  { val: "daysVentTi", show: "Inspiratory time (Ti)" },
  { val: "daysVentPip", show: "PIP" },
  { val: "daysVentIFR", show: "IFR" },
  { val: "daysVentTidalVolume", show: "Tidal Volume" },
  { val: "daysVentOI", show: "OI" },
  { val: "daysVentOSI", show: "OSI" },
  { val: "daysVentMeanMAP", show: "Mean(MAP)" },
  { val: "daysVentFrequency", show: "Freaquency" },
  { val: "daysVentDeltaP", show: "Delta P(Ampitude)" },

  // airway
  { val: "daysVentAirway", show: "Airway" },
  { val: "nasopharyngealAirway", show: "Nasopharyngeal airway" },
  { val: "oralAirway", show: "Oral airway" },
  { val: "biteBlock", show: "Bite Block" },
  { val: "supraglotticAirway", show: "Supraglottic airway" },
  { val: "cooksAirway", show: "Cooks airway" },
  {
    val: "oralEndotrachealTube",
    show: "Oral endotracheal <br> tube with cuff",
  },
  {
    val: "nasalEndotrachealTube",
    show: "Nasal endotracheal <br> tube with cuff",
  },
  {
    val: "tracheostomyTube",
    show: "Tracheostomy <br> Tube",
  },
];
