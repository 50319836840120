import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ECallPopupComponent } from "./e-call-popup.component";
import { BedUnitInfoModule } from "../bed-unit-info/bed-unit-info.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [ECallPopupComponent],
  imports: [CommonModule, BedUnitInfoModule, MatDialogModule],
  exports: [ECallPopupComponent],
})
export class ECallPopupModule {}
