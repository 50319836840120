import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PatientCardComponent } from "./patient-card/patient-card.component";
import { MatDividerModule } from "@angular/material/divider";
import { AvatarModule } from "src/app/iris-components/avatar/avatar.module";
import { FlagIconsModule } from "../flag-icons/flag-icons.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TimezoneLabelModule } from "../timezone-label/timezone-label.module";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";
import { IcuDaysPipeModule } from "@shared-modules/pipes/icu-days-pipe/icu-days-pipe.module";
import { PopoverDirective } from "src/app/iris-components/directives/popover.directive";

@NgModule({
  declarations: [PatientCardComponent],
  imports: [
    CommonModule,
    AvatarModule,
    MatDividerModule,
    FlagIconsModule,
    MatTooltipModule,
    TimezoneLabelModule,
    TimezonePipeModule,
    IcuDaysPipeModule,
  ],
  exports: [PatientCardComponent],
})
export class PatientCardModule {}
