import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { ApiResponse } from "../../models/api-response.model";
import { environment } from "../../../environments/environment";
import {
  PatientMonitorForm,
  PatientMonitorGetParams,
} from "../../models/patientMonitor/patientMonitor.model";

@Injectable({ providedIn: "root" })
export class PatientMonitorApiService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getPatientMonitors(params: PatientMonitorGetParams | null): Observable<any> {
    let options;

    if (params) {
      options = {
        ...this.httpOptions,
        params: new HttpParams()
          .set("hospital", params.hospital)
          .set("unit", params.unit)
          .set("status", params.status),
      };
    } else {
      options = this.httpOptions;
    }

    return this.http.get<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor/${params.commandCenterID}`,
      options
    );
  }

  newPatientMonitor(data: PatientMonitorForm): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor`,
      data,
      this.httpOptions
    );
  }

  checkIfUnique(deviceId: string, id: string | null): Observable<any> {
    let options;

    if (id) {
      options = {
        ...this.httpOptions,
        params: new HttpParams().set("deviceId", deviceId).set("id", id),
      };
    } else {
      options = {
        ...this.httpOptions,
        params: new HttpParams().set("deviceId", deviceId),
      };
    }

    return this.http.get<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor/check-if-exist`,
      options
    );
  }

  updatePatientMonitor(data: PatientMonitorForm, id: string): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor/${id}`,
      data,
      this.httpOptions
    );
  }

  deletePatientMonitor(id: string): Observable<any> {
    return this.http.delete<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor/${id}`,
      this.httpOptions
    );
  }

  getPatientMonitorLatestData(deviceId: string): Observable<any> {
    return this.http.get<ApiResponse>(
      `${this.apiUrl}iot/patient-monitor/latest-data/${deviceId}`,
      this.httpOptions
    );
  }
}
