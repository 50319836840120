// mature line co - ordinates

export const MATURE_BOYS_HEADCIRCUMFERENCE_LINE_COORDS = [
  {
    age: 0,
    line1: 30.651,
    line2: 31.921,
    line3: 33.192,
    line4: 34.462,
    line5: 35.732,
    line6: 37.002,
    line7: 38.273,
  },
  {
    age: 1,
    line1: 30.771,
    line2: 32.035,
    line3: 33.298,
    line4: 34.562,
    line5: 35.826,
    line6: 37.089,
    line7: 38.353,
  },
  {
    age: 2,
    line1: 30.893,
    line2: 32.149,
    line3: 33.406,
    line4: 34.662,
    line5: 35.919,
    line6: 37.175,
    line7: 38.432,
  },
  {
    age: 3,
    line1: 31.013,
    line2: 32.263,
    line3: 33.513,
    line4: 34.762,
    line5: 36.012,
    line6: 37.262,
    line7: 38.512,
  },
  {
    age: 4,
    line1: 31.135,
    line2: 32.378,
    line3: 33.62,
    line4: 34.863,
    line5: 36.105,
    line6: 37.348,
    line7: 38.59,
  },
  {
    age: 5,
    line1: 31.257,
    line2: 32.492,
    line3: 33.728,
    line4: 34.963,
    line5: 36.198,
    line6: 37.433,
    line7: 38.669,
  },
  {
    age: 6,
    line1: 31.378,
    line2: 32.607,
    line3: 33.835,
    line4: 35.063,
    line5: 36.291,
    line6: 37.52,
    line7: 38.748,
  },
  {
    age: 7,
    line1: 31.501,
    line2: 32.722,
    line3: 33.943,
    line4: 35.163,
    line5: 36.384,
    line6: 37.605,
    line7: 38.826,
  },
  {
    age: 8,
    line1: 31.623,
    line2: 32.837,
    line3: 34.05,
    line4: 35.264,
    line5: 36.477,
    line6: 37.69,
    line7: 38.904,
  },
  {
    age: 9,
    line1: 31.745,
    line2: 32.951,
    line3: 34.158,
    line4: 35.364,
    line5: 36.57,
    line6: 37.776,
    line7: 38.983,
  },
  {
    age: 10,
    line1: 31.868,
    line2: 33.067,
    line3: 34.265,
    line4: 35.464,
    line5: 36.663,
    line6: 37.861,
    line7: 39.06,
  },
  {
    age: 11,
    line1: 31.99,
    line2: 33.181,
    line3: 34.373,
    line4: 35.564,
    line5: 36.756,
    line6: 37.947,
    line7: 39.139,
  },
  {
    age: 12,
    line1: 32.113,
    line2: 33.297,
    line3: 34.481,
    line4: 35.664,
    line5: 36.848,
    line6: 38.032,
    line7: 39.216,
  },
  {
    age: 13,
    line1: 32.237,
    line2: 33.413,
    line3: 34.589,
    line4: 35.765,
    line5: 36.941,
    line6: 38.117,
    line7: 39.293,
  },
  {
    age: 14,
    line1: 32.359,
    line2: 33.528,
    line3: 34.696,
    line4: 35.865,
    line5: 37.033,
    line6: 38.202,
    line7: 39.37,
  },
  {
    age: 15,
    line1: 32.461,
    line2: 33.629,
    line3: 34.797,
    line4: 35.965,
    line5: 37.133,
    line6: 38.301,
    line7: 39.47,
  },
  {
    age: 16,
    line1: 32.559,
    line2: 33.727,
    line3: 34.895,
    line4: 36.063,
    line5: 37.231,
    line6: 38.399,
    line7: 39.567,
  },
  {
    age: 17,
    line1: 32.655,
    line2: 33.823,
    line3: 34.991,
    line4: 36.159,
    line5: 37.327,
    line6: 38.495,
    line7: 39.663,
  },
  {
    age: 18,
    line1: 32.75,
    line2: 33.917,
    line3: 35.085,
    line4: 36.253,
    line5: 37.42,
    line6: 38.588,
    line7: 39.756,
  },
  {
    age: 19,
    line1: 32.841,
    line2: 34.009,
    line3: 35.176,
    line4: 36.344,
    line5: 37.512,
    line6: 38.68,
    line7: 39.847,
  },
  {
    age: 20,
    line1: 32.931,
    line2: 34.098,
    line3: 35.266,
    line4: 36.434,
    line5: 37.602,
    line6: 38.769,
    line7: 39.937,
  },
  {
    age: 21,
    line1: 33.019,
    line2: 34.186,
    line3: 35.354,
    line4: 36.522,
    line5: 37.689,
    line6: 38.857,
    line7: 40.024,
  },
  {
    age: 22,
    line1: 33.106,
    line2: 34.273,
    line3: 35.44,
    line4: 36.608,
    line5: 37.775,
    line6: 38.943,
    line7: 40.11,
  },
  {
    age: 23,
    line1: 33.19,
    line2: 34.357,
    line3: 35.525,
    line4: 36.692,
    line5: 37.86,
    line6: 39.027,
    line7: 40.195,
  },
  {
    age: 24,
    line1: 33.272,
    line2: 34.44,
    line3: 35.607,
    line4: 36.775,
    line5: 37.943,
    line6: 39.11,
    line7: 40.278,
  },
  {
    age: 25,
    line1: 33.354,
    line2: 34.521,
    line3: 35.689,
    line4: 36.857,
    line5: 38.024,
    line6: 39.192,
    line7: 40.359,
  },
  {
    age: 26,
    line1: 33.434,
    line2: 34.601,
    line3: 35.769,
    line4: 36.937,
    line5: 38.104,
    line6: 39.272,
    line7: 40.439,
  },
  {
    age: 27,
    line1: 33.513,
    line2: 34.68,
    line3: 35.848,
    line4: 37.015,
    line5: 38.183,
    line6: 39.35,
    line7: 40.518,
  },
  {
    age: 28,
    line1: 33.59,
    line2: 34.757,
    line3: 35.925,
    line4: 37.093,
    line5: 38.26,
    line6: 39.428,
    line7: 40.596,
  },
  {
    age: 29,
    line1: 33.666,
    line2: 34.834,
    line3: 36.001,
    line4: 37.169,
    line5: 38.336,
    line6: 39.504,
    line7: 40.671,
  },
  {
    age: 30,
    line1: 33.741,
    line2: 34.908,
    line3: 36.076,
    line4: 37.244,
    line5: 38.411,
    line6: 39.579,
    line7: 40.746,
  },
  {
    age: 31,
    line1: 33.814,
    line2: 34.982,
    line3: 36.15,
    line4: 37.317,
    line5: 38.485,
    line6: 39.653,
    line7: 40.82,
  },
  {
    age: 32,
    line1: 33.887,
    line2: 35.054,
    line3: 36.222,
    line4: 37.39,
    line5: 38.557,
    line6: 39.725,
    line7: 40.893,
  },
  {
    age: 33,
    line1: 33.957,
    line2: 35.125,
    line3: 36.293,
    line4: 37.461,
    line5: 38.629,
    line6: 39.797,
    line7: 40.965,
  },
  {
    age: 34,
    line1: 34.028,
    line2: 35.196,
    line3: 36.364,
    line4: 37.532,
    line5: 38.7,
    line6: 39.868,
    line7: 41.036,
  },
  {
    age: 35,
    line1: 34.096,
    line2: 35.264,
    line3: 36.433,
    line4: 37.601,
    line5: 38.769,
    line6: 39.938,
    line7: 41.106,
  },
  {
    age: 36,
    line1: 34.165,
    line2: 35.333,
    line3: 36.501,
    line4: 37.669,
    line5: 38.838,
    line6: 40.006,
    line7: 41.174,
  },
  {
    age: 37,
    line1: 34.232,
    line2: 35.4,
    line3: 36.568,
    line4: 37.737,
    line5: 38.905,
    line6: 40.073,
    line7: 41.242,
  },
  {
    age: 38,
    line1: 34.298,
    line2: 35.466,
    line3: 36.635,
    line4: 37.803,
    line5: 38.972,
    line6: 40.14,
    line7: 41.309,
  },
  {
    age: 39,
    line1: 34.363,
    line2: 35.532,
    line3: 36.7,
    line4: 37.869,
    line5: 39.038,
    line6: 40.206,
    line7: 41.375,
  },
  {
    age: 40,
    line1: 34.428,
    line2: 35.596,
    line3: 36.765,
    line4: 37.934,
    line5: 39.103,
    line6: 40.271,
    line7: 41.44,
  },
  {
    age: 41,
    line1: 34.491,
    line2: 35.66,
    line3: 36.829,
    line4: 37.998,
    line5: 39.167,
    line6: 40.335,
    line7: 41.504,
  },
  {
    age: 42,
    line1: 34.553,
    line2: 35.722,
    line3: 36.892,
    line4: 38.061,
    line5: 39.23,
    line6: 40.399,
    line7: 41.569,
  },
  {
    age: 43,
    line1: 34.616,
    line2: 35.785,
    line3: 36.954,
    line4: 38.123,
    line5: 39.293,
    line6: 40.462,
    line7: 41.631,
  },
  {
    age: 44,
    line1: 34.677,
    line2: 35.847,
    line3: 37.016,
    line4: 38.185,
    line5: 39.354,
    line6: 40.523,
    line7: 41.693,
  },
  {
    age: 45,
    line1: 34.737,
    line2: 35.907,
    line3: 37.076,
    line4: 38.246,
    line5: 39.415,
    line6: 40.585,
    line7: 41.755,
  },
  {
    age: 46,
    line1: 34.796,
    line2: 35.966,
    line3: 37.136,
    line4: 38.306,
    line5: 39.476,
    line6: 40.646,
    line7: 41.816,
  },
  {
    age: 47,
    line1: 34.856,
    line2: 36.026,
    line3: 37.196,
    line4: 38.366,
    line5: 39.535,
    line6: 40.705,
    line7: 41.875,
  },
  {
    age: 48,
    line1: 34.914,
    line2: 36.084,
    line3: 37.254,
    line4: 38.424,
    line5: 39.594,
    line6: 40.764,
    line7: 41.934,
  },
  {
    age: 49,
    line1: 34.972,
    line2: 36.142,
    line3: 37.312,
    line4: 38.482,
    line5: 39.653,
    line6: 40.823,
    line7: 41.993,
  },
  {
    age: 50,
    line1: 35.029,
    line2: 36.199,
    line3: 37.369,
    line4: 38.54,
    line5: 39.71,
    line6: 40.881,
    line7: 42.051,
  },
  {
    age: 51,
    line1: 35.085,
    line2: 36.256,
    line3: 37.426,
    line4: 38.597,
    line5: 39.767,
    line6: 40.938,
    line7: 42.109,
  },
  {
    age: 52,
    line1: 35.141,
    line2: 36.311,
    line3: 37.482,
    line4: 38.653,
    line5: 39.824,
    line6: 40.995,
    line7: 42.165,
  },
  {
    age: 53,
    line1: 35.196,
    line2: 36.367,
    line3: 37.538,
    line4: 38.709,
    line5: 39.88,
    line6: 41.051,
    line7: 42.222,
  },
  {
    age: 54,
    line1: 35.251,
    line2: 36.422,
    line3: 37.593,
    line4: 38.764,
    line5: 39.935,
    line6: 41.106,
    line7: 42.277,
  },
  {
    age: 55,
    line1: 35.304,
    line2: 36.475,
    line3: 37.647,
    line4: 38.818,
    line5: 39.99,
    line6: 41.161,
    line7: 42.333,
  },
  {
    age: 56,
    line1: 35.358,
    line2: 36.529,
    line3: 37.701,
    line4: 38.872,
    line5: 40.044,
    line6: 41.216,
    line7: 42.387,
  },
  {
    age: 57,
    line1: 35.411,
    line2: 36.582,
    line3: 37.754,
    line4: 38.926,
    line5: 40.097,
    line6: 41.269,
    line7: 42.441,
  },
  {
    age: 58,
    line1: 35.463,
    line2: 36.635,
    line3: 37.807,
    line4: 38.979,
    line5: 40.151,
    line6: 41.323,
    line7: 42.495,
  },
  {
    age: 59,
    line1: 35.515,
    line2: 36.687,
    line3: 37.859,
    line4: 39.031,
    line5: 40.203,
    line6: 41.376,
    line7: 42.548,
  },
  {
    age: 60,
    line1: 35.566,
    line2: 36.738,
    line3: 37.911,
    line4: 39.083,
    line5: 40.256,
    line6: 41.428,
    line7: 42.601,
  },
  {
    age: 61,
    line1: 35.616,
    line2: 36.789,
    line3: 37.962,
    line4: 39.135,
    line5: 40.308,
    line6: 41.481,
    line7: 42.654,
  },
  {
    age: 62,
    line1: 35.668,
    line2: 36.84,
    line3: 38.013,
    line4: 39.186,
    line5: 40.359,
    line6: 41.532,
    line7: 42.705,
  },
  {
    age: 63,
    line1: 35.717,
    line2: 36.89,
    line3: 38.064,
    line4: 39.237,
    line5: 40.41,
    line6: 41.583,
    line7: 42.756,
  },
  {
    age: 64,
    line1: 35.767,
    line2: 36.94,
    line3: 38.114,
    line4: 39.287,
    line5: 40.461,
    line6: 41.634,
    line7: 42.808,
  },
  {
    age: 65,
    line1: 35.815,
    line2: 36.989,
    line3: 38.163,
    line4: 39.337,
    line5: 40.511,
    line6: 41.685,
    line7: 42.858,
  },
  {
    age: 66,
    line1: 35.864,
    line2: 37.038,
    line3: 38.212,
    line4: 39.386,
    line5: 40.56,
    line6: 41.735,
    line7: 42.909,
  },
  {
    age: 67,
    line1: 35.912,
    line2: 37.087,
    line3: 38.261,
    line4: 39.435,
    line5: 40.61,
    line6: 41.784,
    line7: 42.958,
  },
  {
    age: 68,
    line1: 35.96,
    line2: 37.135,
    line3: 38.309,
    line4: 39.484,
    line5: 40.658,
    line6: 41.833,
    line7: 43.008,
  },
  {
    age: 69,
    line1: 36.007,
    line2: 37.182,
    line3: 38.357,
    line4: 39.532,
    line5: 40.707,
    line6: 41.882,
    line7: 43.057,
  },
  {
    age: 70,
    line1: 36.054,
    line2: 37.229,
    line3: 38.405,
    line4: 39.58,
    line5: 40.755,
    line6: 41.93,
    line7: 43.105,
  },
  {
    age: 71,
    line1: 36.101,
    line2: 37.276,
    line3: 38.452,
    line4: 39.627,
    line5: 40.802,
    line6: 41.978,
    line7: 43.153,
  },
  {
    age: 72,
    line1: 36.147,
    line2: 37.323,
    line3: 38.498,
    line4: 39.674,
    line5: 40.85,
    line6: 42.025,
    line7: 43.201,
  },
  {
    age: 73,
    line1: 36.192,
    line2: 37.368,
    line3: 38.544,
    line4: 39.721,
    line5: 40.897,
    line6: 42.073,
    line7: 43.249,
  },
  {
    age: 74,
    line1: 36.238,
    line2: 37.414,
    line3: 38.59,
    line4: 39.767,
    line5: 40.943,
    line6: 42.119,
    line7: 43.296,
  },
  {
    age: 75,
    line1: 36.283,
    line2: 37.46,
    line3: 38.636,
    line4: 39.813,
    line5: 40.989,
    line6: 42.166,
    line7: 43.342,
  },
  {
    age: 76,
    line1: 36.327,
    line2: 37.504,
    line3: 38.681,
    line4: 39.858,
    line5: 41.035,
    line6: 42.212,
    line7: 43.389,
  },
  {
    age: 77,
    line1: 36.372,
    line2: 37.549,
    line3: 38.726,
    line4: 39.903,
    line5: 41.08,
    line6: 42.258,
    line7: 43.435,
  },
  {
    age: 78,
    line1: 36.415,
    line2: 37.593,
    line3: 38.77,
    line4: 39.948,
    line5: 41.126,
    line6: 42.303,
    line7: 43.481,
  },
  {
    age: 79,
    line1: 36.459,
    line2: 37.637,
    line3: 38.815,
    line4: 39.992,
    line5: 41.17,
    line6: 42.348,
    line7: 43.526,
  },
  {
    age: 80,
    line1: 36.502,
    line2: 37.68,
    line3: 38.858,
    line4: 40.036,
    line5: 41.215,
    line6: 42.393,
    line7: 43.571,
  },
  {
    age: 81,
    line1: 36.545,
    line2: 37.724,
    line3: 38.902,
    line4: 40.08,
    line5: 41.259,
    line6: 42.437,
    line7: 43.615,
  },
  {
    age: 82,
    line1: 36.587,
    line2: 37.766,
    line3: 38.945,
    line4: 40.124,
    line5: 41.303,
    line6: 42.481,
    line7: 43.66,
  },
  {
    age: 83,
    line1: 36.629,
    line2: 37.808,
    line3: 38.988,
    line4: 40.167,
    line5: 41.346,
    line6: 42.525,
    line7: 43.705,
  },
  {
    age: 84,
    line1: 36.672,
    line2: 37.851,
    line3: 39.03,
    line4: 40.21,
    line5: 41.389,
    line6: 42.568,
    line7: 43.748,
  },
  {
    age: 85,
    line1: 36.713,
    line2: 37.893,
    line3: 39.072,
    line4: 40.252,
    line5: 41.432,
    line6: 42.612,
    line7: 43.791,
  },
  {
    age: 86,
    line1: 36.754,
    line2: 37.934,
    line3: 39.114,
    line4: 40.294,
    line5: 41.475,
    line6: 42.655,
    line7: 43.835,
  },
  {
    age: 87,
    line1: 36.794,
    line2: 37.975,
    line3: 39.156,
    line4: 40.336,
    line5: 41.517,
    line6: 42.697,
    line7: 43.878,
  },
  {
    age: 88,
    line1: 36.835,
    line2: 38.016,
    line3: 39.197,
    line4: 40.378,
    line5: 41.559,
    line6: 42.74,
    line7: 43.921,
  },
  {
    age: 89,
    line1: 36.876,
    line2: 38.057,
    line3: 39.238,
    line4: 40.419,
    line5: 41.6,
    line6: 42.781,
    line7: 43.962,
  },
  {
    age: 90,
    line1: 36.916,
    line2: 38.097,
    line3: 39.279,
    line4: 40.46,
    line5: 41.642,
    line6: 42.823,
    line7: 44.004,
  },
  {
    age: 91,
    line1: 36.955,
    line2: 38.137,
    line3: 39.319,
    line4: 40.501,
    line5: 41.683,
    line6: 42.864,
    line7: 44.046,
  },
  {
    age: 92,
    line1: 36.995,
    line2: 38.177,
    line3: 39.359,
    line4: 40.541,
    line5: 41.723,
    line6: 42.906,
    line7: 44.088,
  },
  {
    age: 93,
    line1: 37.034,
    line2: 38.216,
    line3: 39.399,
    line4: 40.582,
    line5: 41.764,
    line6: 42.947,
    line7: 44.129,
  },
  {
    age: 94,
    line1: 37.073,
    line2: 38.256,
    line3: 39.439,
    line4: 40.621,
    line5: 41.804,
    line6: 42.987,
    line7: 44.17,
  },
  {
    age: 95,
    line1: 37.111,
    line2: 38.295,
    line3: 39.478,
    line4: 40.661,
    line5: 41.844,
    line6: 43.028,
    line7: 44.211,
  },
  {
    age: 96,
    line1: 37.15,
    line2: 38.333,
    line3: 39.517,
    line4: 40.7,
    line5: 41.884,
    line6: 43.068,
    line7: 44.251,
  },
  {
    age: 97,
    line1: 37.187,
    line2: 38.371,
    line3: 39.555,
    line4: 40.74,
    line5: 41.924,
    line6: 43.108,
    line7: 44.293,
  },
  {
    age: 98,
    line1: 37.225,
    line2: 38.409,
    line3: 39.594,
    line4: 40.778,
    line5: 41.963,
    line6: 43.148,
    line7: 44.332,
  },
  {
    age: 99,
    line1: 37.262,
    line2: 38.447,
    line3: 39.632,
    line4: 40.817,
    line5: 42.002,
    line6: 43.187,
    line7: 44.372,
  },
  {
    age: 100,
    line1: 37.3,
    line2: 38.485,
    line3: 39.67,
    line4: 40.856,
    line5: 42.041,
    line6: 43.226,
    line7: 44.411,
  },
  {
    age: 101,
    line1: 37.337,
    line2: 38.523,
    line3: 39.708,
    line4: 40.894,
    line5: 42.079,
    line6: 43.265,
    line7: 44.45,
  },
  {
    age: 102,
    line1: 37.373,
    line2: 38.559,
    line3: 39.745,
    line4: 40.932,
    line5: 42.118,
    line6: 43.304,
    line7: 44.49,
  },
  {
    age: 103,
    line1: 37.41,
    line2: 38.596,
    line3: 39.783,
    line4: 40.969,
    line5: 42.156,
    line6: 43.342,
    line7: 44.528,
  },
  {
    age: 104,
    line1: 37.446,
    line2: 38.633,
    line3: 39.82,
    line4: 41.006,
    line5: 42.193,
    line6: 43.38,
    line7: 44.567,
  },
  {
    age: 105,
    line1: 37.481,
    line2: 38.669,
    line3: 39.856,
    line4: 41.044,
    line5: 42.231,
    line6: 43.418,
    line7: 44.606,
  },
  {
    age: 106,
    line1: 37.518,
    line2: 38.705,
    line3: 39.893,
    line4: 41.08,
    line5: 42.268,
    line6: 43.456,
    line7: 44.643,
  },
  {
    age: 107,
    line1: 37.554,
    line2: 38.741,
    line3: 39.929,
    line4: 41.117,
    line5: 42.305,
    line6: 43.493,
    line7: 44.681,
  },
  {
    age: 108,
    line1: 37.588,
    line2: 38.777,
    line3: 39.965,
    line4: 41.154,
    line5: 42.342,
    line6: 43.531,
    line7: 44.719,
  },
  {
    age: 109,
    line1: 37.624,
    line2: 38.812,
    line3: 40.001,
    line4: 41.19,
    line5: 42.379,
    line6: 43.567,
    line7: 44.756,
  },
  {
    age: 110,
    line1: 37.658,
    line2: 38.847,
    line3: 40.036,
    line4: 41.226,
    line5: 42.415,
    line6: 43.604,
    line7: 44.794,
  },
  {
    age: 111,
    line1: 37.693,
    line2: 38.882,
    line3: 40.072,
    line4: 41.262,
    line5: 42.451,
    line6: 43.641,
    line7: 44.83,
  },
  {
    age: 112,
    line1: 37.726,
    line2: 38.917,
    line3: 40.107,
    line4: 41.297,
    line5: 42.487,
    line6: 43.677,
    line7: 44.868,
  },
  {
    age: 113,
    line1: 37.761,
    line2: 38.952,
    line3: 40.142,
    line4: 41.332,
    line5: 42.523,
    line6: 43.713,
    line7: 44.903,
  },
  {
    age: 114,
    line1: 37.794,
    line2: 38.985,
    line3: 40.176,
    line4: 41.367,
    line5: 42.558,
    line6: 43.749,
    line7: 44.94,
  },
  {
    age: 115,
    line1: 37.829,
    line2: 39.02,
    line3: 40.211,
    line4: 41.402,
    line5: 42.593,
    line6: 43.784,
    line7: 44.976,
  },
  {
    age: 116,
    line1: 37.862,
    line2: 39.053,
    line3: 40.245,
    line4: 41.437,
    line5: 42.629,
    line6: 43.82,
    line7: 45.012,
  },
  {
    age: 117,
    line1: 37.894,
    line2: 39.087,
    line3: 40.279,
    line4: 41.471,
    line5: 42.663,
    line6: 43.856,
    line7: 45.048,
  },
  {
    age: 118,
    line1: 37.928,
    line2: 39.12,
    line3: 40.313,
    line4: 41.505,
    line5: 42.698,
    line6: 43.89,
    line7: 45.083,
  },
  {
    age: 119,
    line1: 37.96,
    line2: 39.153,
    line3: 40.346,
    line4: 41.539,
    line5: 42.732,
    line6: 43.925,
    line7: 45.118,
  },
  {
    age: 120,
    line1: 37.992,
    line2: 39.186,
    line3: 40.38,
    line4: 41.573,
    line5: 42.767,
    line6: 43.96,
    line7: 45.154,
  },
  {
    age: 121,
    line1: 38.026,
    line2: 39.219,
    line3: 40.413,
    line4: 41.607,
    line5: 42.8,
    line6: 43.994,
    line7: 45.188,
  },
  {
    age: 122,
    line1: 38.057,
    line2: 39.252,
    line3: 40.446,
    line4: 41.64,
    line5: 42.834,
    line6: 44.029,
    line7: 45.223,
  },
  {
    age: 123,
    line1: 38.089,
    line2: 39.284,
    line3: 40.478,
    line4: 41.673,
    line5: 42.868,
    line6: 44.063,
    line7: 45.258,
  },
  {
    age: 124,
    line1: 38.122,
    line2: 39.316,
    line3: 40.511,
    line4: 41.706,
    line5: 42.901,
    line6: 44.096,
    line7: 45.291,
  },
  {
    age: 125,
    line1: 38.153,
    line2: 39.348,
    line3: 40.543,
    line4: 41.739,
    line5: 42.934,
    line6: 44.13,
    line7: 45.325,
  },
  {
    age: 126,
    line1: 38.184,
    line2: 39.38,
    line3: 40.576,
    line4: 41.772,
    line5: 42.967,
    line6: 44.163,
    line7: 45.359,
  },
  {
    age: 127,
    line1: 38.215,
    line2: 39.411,
    line3: 40.607,
    line4: 41.804,
    line5: 43,
    line6: 44.197,
    line7: 45.393,
  },
  {
    age: 128,
    line1: 38.245,
    line2: 39.442,
    line3: 40.639,
    line4: 41.836,
    line5: 43.033,
    line6: 44.23,
    line7: 45.427,
  },
  {
    age: 129,
    line1: 38.277,
    line2: 39.474,
    line3: 40.671,
    line4: 41.868,
    line5: 43.065,
    line6: 44.262,
    line7: 45.459,
  },
  {
    age: 130,
    line1: 38.307,
    line2: 39.505,
    line3: 40.702,
    line4: 41.9,
    line5: 43.097,
    line6: 44.295,
    line7: 45.492,
  },
  {
    age: 131,
    line1: 38.337,
    line2: 39.535,
    line3: 40.733,
    line4: 41.931,
    line5: 43.129,
    line6: 44.327,
    line7: 45.525,
  },
  {
    age: 132,
    line1: 38.367,
    line2: 39.566,
    line3: 40.764,
    line4: 41.963,
    line5: 43.161,
    line6: 44.36,
    line7: 45.558,
  },
  {
    age: 133,
    line1: 38.397,
    line2: 39.596,
    line3: 40.795,
    line4: 41.994,
    line5: 43.193,
    line6: 44.392,
    line7: 45.591,
  },
  {
    age: 134,
    line1: 38.427,
    line2: 39.626,
    line3: 40.826,
    line4: 42.025,
    line5: 43.224,
    line6: 44.424,
    line7: 45.623,
  },
  {
    age: 135,
    line1: 38.456,
    line2: 39.656,
    line3: 40.856,
    line4: 42.056,
    line5: 43.256,
    line6: 44.455,
    line7: 45.655,
  },
  {
    age: 136,
    line1: 38.485,
    line2: 39.686,
    line3: 40.886,
    line4: 42.086,
    line5: 43.287,
    line6: 44.487,
    line7: 45.687,
  },
  {
    age: 137,
    line1: 38.515,
    line2: 39.715,
    line3: 40.916,
    line4: 42.117,
    line5: 43.318,
    line6: 44.518,
    line7: 45.719,
  },
  {
    age: 138,
    line1: 38.544,
    line2: 39.745,
    line3: 40.946,
    line4: 42.147,
    line5: 43.348,
    line6: 44.549,
    line7: 45.751,
  },
  {
    age: 139,
    line1: 38.572,
    line2: 39.774,
    line3: 40.976,
    line4: 42.177,
    line5: 43.379,
    line6: 44.58,
    line7: 45.782,
  },
  {
    age: 140,
    line1: 38.601,
    line2: 39.803,
    line3: 41.005,
    line4: 42.207,
    line5: 43.409,
    line6: 44.611,
    line7: 45.813,
  },
  {
    age: 141,
    line1: 38.629,
    line2: 39.832,
    line3: 41.034,
    line4: 42.237,
    line5: 43.439,
    line6: 44.642,
    line7: 45.844,
  },
  {
    age: 142,
    line1: 38.658,
    line2: 39.861,
    line3: 41.063,
    line4: 42.266,
    line5: 43.469,
    line6: 44.672,
    line7: 45.875,
  },
  {
    age: 143,
    line1: 38.686,
    line2: 39.889,
    line3: 41.092,
    line4: 42.296,
    line5: 43.499,
    line6: 44.702,
    line7: 45.906,
  },
  {
    age: 144,
    line1: 38.714,
    line2: 39.917,
    line3: 41.121,
    line4: 42.325,
    line5: 43.529,
    line6: 44.732,
    line7: 45.936,
  },
  {
    age: 145,
    line1: 38.742,
    line2: 39.946,
    line3: 41.15,
    line4: 42.354,
    line5: 43.558,
    line6: 44.762,
    line7: 45.966,
  },
  {
    age: 146,
    line1: 38.769,
    line2: 39.974,
    line3: 41.178,
    line4: 42.383,
    line5: 43.587,
    line6: 44.792,
    line7: 45.996,
  },
  {
    age: 147,
    line1: 38.797,
    line2: 40.002,
    line3: 41.207,
    line4: 42.412,
    line5: 43.616,
    line6: 44.821,
    line7: 46.026,
  },
  {
    age: 148,
    line1: 38.824,
    line2: 40.029,
    line3: 41.235,
    line4: 42.44,
    line5: 43.645,
    line6: 44.851,
    line7: 46.056,
  },
  {
    age: 149,
    line1: 38.851,
    line2: 40.057,
    line3: 41.263,
    line4: 42.468,
    line5: 43.674,
    line6: 44.88,
    line7: 46.085,
  },
  {
    age: 150,
    line1: 38.877,
    line2: 40.084,
    line3: 41.29,
    line4: 42.496,
    line5: 43.703,
    line6: 44.909,
    line7: 46.116,
  },
  {
    age: 151,
    line1: 38.904,
    line2: 40.111,
    line3: 41.318,
    line4: 42.525,
    line5: 43.731,
    line6: 44.938,
    line7: 46.145,
  },
  {
    age: 152,
    line1: 38.931,
    line2: 40.138,
    line3: 41.345,
    line4: 42.552,
    line5: 43.76,
    line6: 44.967,
    line7: 46.174,
  },
  {
    age: 153,
    line1: 38.957,
    line2: 40.165,
    line3: 41.373,
    line4: 42.58,
    line5: 43.788,
    line6: 44.995,
    line7: 46.203,
  },
  {
    age: 154,
    line1: 38.984,
    line2: 40.192,
    line3: 41.4,
    line4: 42.608,
    line5: 43.816,
    line6: 45.023,
    line7: 46.231,
  },
  {
    age: 155,
    line1: 39.009,
    line2: 40.218,
    line3: 41.426,
    line4: 42.635,
    line5: 43.844,
    line6: 45.052,
    line7: 46.261,
  },
  {
    age: 156,
    line1: 39.035,
    line2: 40.244,
    line3: 41.453,
    line4: 42.662,
    line5: 43.871,
    line6: 45.08,
    line7: 46.289,
  },
  {
    age: 157,
    line1: 39.061,
    line2: 40.27,
    line3: 41.48,
    line4: 42.689,
    line5: 43.899,
    line6: 45.108,
    line7: 46.317,
  },
  {
    age: 158,
    line1: 39.087,
    line2: 40.297,
    line3: 41.506,
    line4: 42.716,
    line5: 43.926,
    line6: 45.135,
    line7: 46.345,
  },
  {
    age: 159,
    line1: 39.111,
    line2: 40.322,
    line3: 41.532,
    line4: 42.743,
    line5: 43.953,
    line6: 45.164,
    line7: 46.374,
  },
  {
    age: 160,
    line1: 39.137,
    line2: 40.348,
    line3: 41.559,
    line4: 42.769,
    line5: 43.98,
    line6: 45.191,
    line7: 46.402,
  },
  {
    age: 161,
    line1: 39.162,
    line2: 40.373,
    line3: 41.585,
    line4: 42.796,
    line5: 44.007,
    line6: 45.218,
    line7: 46.429,
  },
  {
    age: 162,
    line1: 39.188,
    line2: 40.399,
    line3: 41.61,
    line4: 42.822,
    line5: 44.033,
    line6: 45.245,
    line7: 46.456,
  },
  {
    age: 163,
    line1: 39.211,
    line2: 40.424,
    line3: 41.636,
    line4: 42.848,
    line5: 44.06,
    line6: 45.272,
    line7: 46.485,
  },
  {
    age: 164,
    line1: 39.237,
    line2: 40.449,
    line3: 41.662,
    line4: 42.874,
    line5: 44.086,
    line6: 45.299,
    line7: 46.511,
  },
  {
    age: 165,
    line1: 39.261,
    line2: 40.474,
    line3: 41.687,
    line4: 42.9,
    line5: 44.112,
    line6: 45.325,
    line7: 46.538,
  },
  {
    age: 166,
    line1: 39.285,
    line2: 40.498,
    line3: 41.712,
    line4: 42.925,
    line5: 44.139,
    line6: 45.352,
    line7: 46.566,
  },
  {
    age: 167,
    line1: 39.31,
    line2: 40.523,
    line3: 41.737,
    line4: 42.951,
    line5: 44.165,
    line6: 45.378,
    line7: 46.592,
  },
  {
    age: 168,
    line1: 39.334,
    line2: 40.548,
    line3: 41.762,
    line4: 42.976,
    line5: 44.19,
    line6: 45.404,
    line7: 46.618,
  },
  {
    age: 169,
    line1: 39.357,
    line2: 40.572,
    line3: 41.787,
    line4: 43.001,
    line5: 44.216,
    line6: 45.431,
    line7: 46.646,
  },
  {
    age: 170,
    line1: 39.381,
    line2: 40.596,
    line3: 41.811,
    line4: 43.026,
    line5: 44.241,
    line6: 45.457,
    line7: 46.672,
  },
  {
    age: 171,
    line1: 39.405,
    line2: 40.621,
    line3: 41.836,
    line4: 43.051,
    line5: 44.267,
    line6: 45.482,
    line7: 46.697,
  },
  {
    age: 172,
    line1: 39.428,
    line2: 40.644,
    line3: 41.86,
    line4: 43.076,
    line5: 44.292,
    line6: 45.508,
    line7: 46.724,
  },
  {
    age: 173,
    line1: 39.452,
    line2: 40.668,
    line3: 41.884,
    line4: 43.101,
    line5: 44.317,
    line6: 45.533,
    line7: 46.75,
  },
  {
    age: 174,
    line1: 39.474,
    line2: 40.691,
    line3: 41.908,
    line4: 43.125,
    line5: 44.342,
    line6: 45.559,
    line7: 46.776,
  },
  {
    age: 175,
    line1: 39.498,
    line2: 40.715,
    line3: 41.932,
    line4: 43.15,
    line5: 44.367,
    line6: 45.584,
    line7: 46.801,
  },
  {
    age: 176,
    line1: 39.521,
    line2: 40.739,
    line3: 41.956,
    line4: 43.174,
    line5: 44.391,
    line6: 45.609,
    line7: 46.826,
  },
  {
    age: 177,
    line1: 39.543,
    line2: 40.761,
    line3: 41.98,
    line4: 43.198,
    line5: 44.416,
    line6: 45.634,
    line7: 46.852,
  },
  {
    age: 178,
    line1: 39.566,
    line2: 40.785,
    line3: 42.003,
    line4: 43.222,
    line5: 44.44,
    line6: 45.659,
    line7: 46.877,
  },
  {
    age: 179,
    line1: 39.588,
    line2: 40.807,
    line3: 42.026,
    line4: 43.246,
    line5: 44.465,
    line6: 45.684,
    line7: 46.903,
  },
  {
    age: 180,
    line1: 39.611,
    line2: 40.83,
    line3: 42.05,
    line4: 43.269,
    line5: 44.488,
    line6: 45.708,
    line7: 46.927,
  },
  {
    age: 181,
    line1: 39.633,
    line2: 40.853,
    line3: 42.073,
    line4: 43.293,
    line5: 44.513,
    line6: 45.733,
    line7: 46.953,
  },
  {
    age: 182,
    line1: 39.655,
    line2: 40.876,
    line3: 42.096,
    line4: 43.316,
    line5: 44.536,
    line6: 45.756,
    line7: 46.977,
  },
  {
    age: 183,
    line1: 39.677,
    line2: 40.898,
    line3: 42.118,
    line4: 43.339,
    line5: 44.56,
    line6: 45.781,
    line7: 47.002,
  },
  {
    age: 184,
    line1: 39.699,
    line2: 40.92,
    line3: 42.141,
    line4: 43.362,
    line5: 44.583,
    line6: 45.805,
    line7: 47.026,
  },
  {
    age: 185,
    line1: 39.72,
    line2: 40.942,
    line3: 42.164,
    line4: 43.385,
    line5: 44.607,
    line6: 45.829,
    line7: 47.051,
  },
  {
    age: 186,
    line1: 39.742,
    line2: 40.964,
    line3: 42.186,
    line4: 43.408,
    line5: 44.63,
    line6: 45.852,
    line7: 47.074,
  },
  {
    age: 187,
    line1: 39.763,
    line2: 40.986,
    line3: 42.208,
    line4: 43.431,
    line5: 44.654,
    line6: 45.876,
    line7: 47.099,
  },
  {
    age: 188,
    line1: 39.785,
    line2: 41.008,
    line3: 42.231,
    line4: 43.454,
    line5: 44.676,
    line6: 45.899,
    line7: 47.122,
  },
  {
    age: 189,
    line1: 39.806,
    line2: 41.029,
    line3: 42.253,
    line4: 43.476,
    line5: 44.7,
    line6: 45.923,
    line7: 47.146,
  },
  {
    age: 190,
    line1: 39.828,
    line2: 41.051,
    line3: 42.275,
    line4: 43.498,
    line5: 44.722,
    line6: 45.946,
    line7: 47.169,
  },
  {
    age: 191,
    line1: 39.848,
    line2: 41.072,
    line3: 42.296,
    line4: 43.521,
    line5: 44.745,
    line6: 45.969,
    line7: 47.193,
  },
  {
    age: 192,
    line1: 39.869,
    line2: 41.094,
    line3: 42.318,
    line4: 43.543,
    line5: 44.767,
    line6: 45.992,
    line7: 47.216,
  },
  {
    age: 193,
    line1: 39.89,
    line2: 41.115,
    line3: 42.34,
    line4: 43.565,
    line5: 44.79,
    line6: 46.015,
    line7: 47.24,
  },
  {
    age: 194,
    line1: 39.911,
    line2: 41.136,
    line3: 42.361,
    line4: 43.586,
    line5: 44.812,
    line6: 46.037,
    line7: 47.262,
  },
  {
    age: 195,
    line1: 39.931,
    line2: 41.157,
    line3: 42.382,
    line4: 43.608,
    line5: 44.834,
    line6: 46.06,
    line7: 47.286,
  },
  {
    age: 196,
    line1: 39.952,
    line2: 41.178,
    line3: 42.404,
    line4: 43.63,
    line5: 44.856,
    line6: 46.082,
    line7: 47.308,
  },
  {
    age: 197,
    line1: 39.971,
    line2: 41.198,
    line3: 42.425,
    line4: 43.651,
    line5: 44.878,
    line6: 46.105,
    line7: 47.331,
  },
  {
    age: 198,
    line1: 39.991,
    line2: 41.218,
    line3: 42.445,
    line4: 43.673,
    line5: 44.9,
    line6: 46.127,
    line7: 47.354,
  },
  {
    age: 199,
    line1: 40.012,
    line2: 41.239,
    line3: 42.467,
    line4: 43.694,
    line5: 44.921,
    line6: 46.149,
    line7: 47.376,
  },
  {
    age: 200,
    line1: 40.031,
    line2: 41.259,
    line3: 42.487,
    line4: 43.715,
    line5: 44.943,
    line6: 46.171,
    line7: 47.399,
  },
  {
    age: 201,
    line1: 40.052,
    line2: 41.28,
    line3: 42.508,
    line4: 43.736,
    line5: 44.964,
    line6: 46.192,
    line7: 47.42,
  },
  {
    age: 202,
    line1: 40.071,
    line2: 41.3,
    line3: 42.528,
    line4: 43.757,
    line5: 44.986,
    line6: 46.214,
    line7: 47.443,
  },
  {
    age: 203,
    line1: 40.09,
    line2: 41.319,
    line3: 42.548,
    line4: 43.778,
    line5: 45.007,
    line6: 46.236,
    line7: 47.466,
  },
  {
    age: 204,
    line1: 40.11,
    line2: 41.339,
    line3: 42.569,
    line4: 43.798,
    line5: 45.028,
    line6: 46.257,
    line7: 47.487,
  },
  {
    age: 205,
    line1: 40.129,
    line2: 41.359,
    line3: 42.589,
    line4: 43.819,
    line5: 45.049,
    line6: 46.279,
    line7: 47.509,
  },
  {
    age: 206,
    line1: 40.148,
    line2: 41.378,
    line3: 42.609,
    line4: 43.839,
    line5: 45.07,
    line6: 46.3,
    line7: 47.531,
  },
  {
    age: 207,
    line1: 40.167,
    line2: 41.398,
    line3: 42.629,
    line4: 43.86,
    line5: 45.09,
    line6: 46.321,
    line7: 47.552,
  },
  {
    age: 208,
    line1: 40.186,
    line2: 41.417,
    line3: 42.649,
    line4: 43.88,
    line5: 45.111,
    line6: 46.342,
    line7: 47.574,
  },
  {
    age: 209,
    line1: 40.204,
    line2: 41.436,
    line3: 42.668,
    line4: 43.9,
    line5: 45.132,
    line6: 46.363,
    line7: 47.595,
  },
  {
    age: 210,
    line1: 40.224,
    line2: 41.456,
    line3: 42.688,
    line4: 43.92,
    line5: 45.152,
    line6: 46.384,
    line7: 47.616,
  },
  {
    age: 211,
    line1: 40.242,
    line2: 41.475,
    line3: 42.707,
    line4: 43.94,
    line5: 45.172,
    line6: 46.405,
    line7: 47.637,
  },
  {
    age: 212,
    line1: 40.26,
    line2: 41.493,
    line3: 42.726,
    line4: 43.959,
    line5: 45.192,
    line6: 46.426,
    line7: 47.659,
  },
  {
    age: 213,
    line1: 40.28,
    line2: 41.513,
    line3: 42.746,
    line4: 43.979,
    line5: 45.212,
    line6: 46.445,
    line7: 47.679,
  },
  {
    age: 214,
    line1: 40.297,
    line2: 41.531,
    line3: 42.765,
    line4: 43.999,
    line5: 45.232,
    line6: 46.466,
    line7: 47.7,
  },
  {
    age: 215,
    line1: 40.315,
    line2: 41.55,
    line3: 42.784,
    line4: 44.018,
    line5: 45.252,
    line6: 46.487,
    line7: 47.721,
  },
  {
    age: 216,
    line1: 40.334,
    line2: 41.569,
    line3: 42.803,
    line4: 44.037,
    line5: 45.272,
    line6: 46.506,
    line7: 47.741,
  },
  {
    age: 217,
    line1: 40.352,
    line2: 41.587,
    line3: 42.822,
    line4: 44.057,
    line5: 45.292,
    line6: 46.526,
    line7: 47.761,
  },
  {
    age: 218,
    line1: 40.369,
    line2: 41.605,
    line3: 42.84,
    line4: 44.076,
    line5: 45.311,
    line6: 46.547,
    line7: 47.782,
  },
  {
    age: 219,
    line1: 40.388,
    line2: 41.624,
    line3: 42.859,
    line4: 44.095,
    line5: 45.33,
    line6: 46.566,
    line7: 47.801,
  },
  {
    age: 220,
    line1: 40.405,
    line2: 41.641,
    line3: 42.878,
    line4: 44.114,
    line5: 45.35,
    line6: 46.586,
    line7: 47.822,
  },
  {
    age: 221,
    line1: 40.423,
    line2: 41.659,
    line3: 42.896,
    line4: 44.132,
    line5: 45.369,
    line6: 46.606,
    line7: 47.842,
  },
  {
    age: 222,
    line1: 40.441,
    line2: 41.678,
    line3: 42.915,
    line4: 44.151,
    line5: 45.388,
    line6: 46.625,
    line7: 47.861,
  },
  {
    age: 223,
    line1: 40.458,
    line2: 41.695,
    line3: 42.933,
    line4: 44.17,
    line5: 45.407,
    line6: 46.644,
    line7: 47.881,
  },
  {
    age: 224,
    line1: 40.475,
    line2: 41.713,
    line3: 42.951,
    line4: 44.188,
    line5: 45.426,
    line6: 46.664,
    line7: 47.901,
  },
  {
    age: 225,
    line1: 40.492,
    line2: 41.73,
    line3: 42.968,
    line4: 44.207,
    line5: 45.445,
    line6: 46.683,
    line7: 47.921,
  },
  {
    age: 226,
    line1: 40.51,
    line2: 41.748,
    line3: 42.987,
    line4: 44.225,
    line5: 45.463,
    line6: 46.702,
    line7: 47.94,
  },
  {
    age: 227,
    line1: 40.527,
    line2: 41.766,
    line3: 43.004,
    line4: 44.243,
    line5: 45.482,
    line6: 46.721,
    line7: 47.96,
  },
  {
    age: 228,
    line1: 40.543,
    line2: 41.783,
    line3: 43.022,
    line4: 44.261,
    line5: 45.501,
    line6: 46.74,
    line7: 47.979,
  },
  {
    age: 229,
    line1: 40.56,
    line2: 41.8,
    line3: 43.039,
    line4: 44.279,
    line5: 45.519,
    line6: 46.759,
    line7: 47.999,
  },
  {
    age: 230,
    line1: 40.578,
    line2: 41.817,
    line3: 43.057,
    line4: 44.297,
    line5: 45.537,
    line6: 46.777,
    line7: 48.017,
  },
  {
    age: 231,
    line1: 40.594,
    line2: 41.834,
    line3: 43.075,
    line4: 44.315,
    line5: 45.555,
    line6: 46.796,
    line7: 48.036,
  },
  {
    age: 232,
    line1: 40.61,
    line2: 41.851,
    line3: 43.092,
    line4: 44.333,
    line5: 45.574,
    line6: 46.815,
    line7: 48.055,
  },
  {
    age: 233,
    line1: 40.626,
    line2: 41.868,
    line3: 43.109,
    line4: 44.35,
    line5: 45.592,
    line6: 46.833,
    line7: 48.075,
  },
  {
    age: 234,
    line1: 40.644,
    line2: 41.885,
    line3: 43.126,
    line4: 44.368,
    line5: 45.609,
    line6: 46.851,
    line7: 48.092,
  },
  {
    age: 235,
    line1: 40.66,
    line2: 41.902,
    line3: 43.143,
    line4: 44.385,
    line5: 45.627,
    line6: 46.869,
    line7: 48.111,
  },
  {
    age: 236,
    line1: 40.676,
    line2: 41.918,
    line3: 43.16,
    line4: 44.403,
    line5: 45.645,
    line6: 46.887,
    line7: 48.13,
  },
  {
    age: 237,
    line1: 40.691,
    line2: 41.934,
    line3: 43.177,
    line4: 44.42,
    line5: 45.663,
    line6: 46.906,
    line7: 48.149,
  },
  {
    age: 238,
    line1: 40.707,
    line2: 41.95,
    line3: 43.194,
    line4: 44.437,
    line5: 45.681,
    line6: 46.924,
    line7: 48.167,
  },
  {
    age: 239,
    line1: 40.724,
    line2: 41.968,
    line3: 43.211,
    line4: 44.454,
    line5: 45.698,
    line6: 46.941,
    line7: 48.184,
  },
  {
    age: 240,
    line1: 40.74,
    line2: 41.984,
    line3: 43.227,
    line4: 44.471,
    line5: 45.715,
    line6: 46.959,
    line7: 48.203,
  },
  {
    age: 241,
    line1: 40.755,
    line2: 42,
    line3: 43.244,
    line4: 44.488,
    line5: 45.733,
    line6: 46.977,
    line7: 48.221,
  },
  {
    age: 242,
    line1: 40.771,
    line2: 42.015,
    line3: 43.26,
    line4: 44.505,
    line5: 45.75,
    line6: 46.995,
    line7: 48.239,
  },
  {
    age: 243,
    line1: 40.786,
    line2: 42.031,
    line3: 43.276,
    line4: 44.522,
    line5: 45.767,
    line6: 47.012,
    line7: 48.258,
  },
  {
    age: 244,
    line1: 40.803,
    line2: 42.048,
    line3: 43.293,
    line4: 44.538,
    line5: 45.784,
    line6: 47.029,
    line7: 48.274,
  },
  {
    age: 245,
    line1: 40.818,
    line2: 42.063,
    line3: 43.309,
    line4: 44.555,
    line5: 45.801,
    line6: 47.046,
    line7: 48.292,
  },
  {
    age: 246,
    line1: 40.833,
    line2: 42.079,
    line3: 43.325,
    line4: 44.571,
    line5: 45.818,
    line6: 47.064,
    line7: 48.31,
  },
  {
    age: 247,
    line1: 40.848,
    line2: 42.094,
    line3: 43.341,
    line4: 44.588,
    line5: 45.834,
    line6: 47.081,
    line7: 48.328,
  },
  {
    age: 248,
    line1: 40.863,
    line2: 42.11,
    line3: 43.357,
    line4: 44.604,
    line5: 45.851,
    line6: 47.098,
    line7: 48.345,
  },
  {
    age: 249,
    line1: 40.879,
    line2: 42.126,
    line3: 43.373,
    line4: 44.62,
    line5: 45.867,
    line6: 47.115,
    line7: 48.362,
  },
  {
    age: 250,
    line1: 40.894,
    line2: 42.141,
    line3: 43.389,
    line4: 44.636,
    line5: 45.884,
    line6: 47.132,
    line7: 48.379,
  },
  {
    age: 251,
    line1: 40.908,
    line2: 42.156,
    line3: 43.404,
    line4: 44.652,
    line5: 45.901,
    line6: 47.149,
    line7: 48.397,
  },
  {
    age: 252,
    line1: 40.923,
    line2: 42.172,
    line3: 43.42,
    line4: 44.668,
    line5: 45.917,
    line6: 47.165,
    line7: 48.414,
  },
  {
    age: 253,
    line1: 40.938,
    line2: 42.187,
    line3: 43.435,
    line4: 44.684,
    line5: 45.933,
    line6: 47.182,
    line7: 48.431,
  },
  {
    age: 254,
    line1: 40.952,
    line2: 42.201,
    line3: 43.451,
    line4: 44.7,
    line5: 45.95,
    line6: 47.199,
    line7: 48.448,
  },
  {
    age: 255,
    line1: 40.968,
    line2: 42.217,
    line3: 43.467,
    line4: 44.716,
    line5: 45.965,
    line6: 47.215,
    line7: 48.464,
  },
  {
    age: 256,
    line1: 40.982,
    line2: 42.232,
    line3: 43.482,
    line4: 44.732,
    line5: 45.981,
    line6: 47.231,
    line7: 48.481,
  },
  {
    age: 257,
    line1: 40.996,
    line2: 42.247,
    line3: 43.497,
    line4: 44.747,
    line5: 45.997,
    line6: 47.248,
    line7: 48.498,
  },
  {
    age: 258,
    line1: 41.011,
    line2: 42.261,
    line3: 43.512,
    line4: 44.763,
    line5: 46.013,
    line6: 47.264,
    line7: 48.515,
  },
  {
    age: 259,
    line1: 41.025,
    line2: 42.276,
    line3: 43.527,
    line4: 44.778,
    line5: 46.029,
    line6: 47.28,
    line7: 48.531,
  },
  {
    age: 260,
    line1: 41.039,
    line2: 42.29,
    line3: 43.542,
    line4: 44.794,
    line5: 46.045,
    line6: 47.297,
    line7: 48.548,
  },
  {
    age: 261,
    line1: 41.053,
    line2: 42.305,
    line3: 43.557,
    line4: 44.809,
    line5: 46.061,
    line6: 47.313,
    line7: 48.565,
  },
  {
    age: 262,
    line1: 41.068,
    line2: 42.32,
    line3: 43.572,
    line4: 44.824,
    line5: 46.076,
    line6: 47.328,
    line7: 48.58,
  },
  {
    age: 263,
    line1: 41.082,
    line2: 42.334,
    line3: 43.587,
    line4: 44.839,
    line5: 46.091,
    line6: 47.344,
    line7: 48.596,
  },
  {
    age: 264,
    line1: 41.096,
    line2: 42.349,
    line3: 43.601,
    line4: 44.854,
    line5: 46.107,
    line6: 47.36,
    line7: 48.613,
  },
  {
    age: 265,
    line1: 41.11,
    line2: 42.363,
    line3: 43.616,
    line4: 44.869,
    line5: 46.122,
    line6: 47.375,
    line7: 48.629,
  },
  {
    age: 266,
    line1: 41.123,
    line2: 42.377,
    line3: 43.63,
    line4: 44.884,
    line5: 46.138,
    line6: 47.391,
    line7: 48.645,
  },
  {
    age: 267,
    line1: 41.137,
    line2: 42.391,
    line3: 43.645,
    line4: 44.899,
    line5: 46.153,
    line6: 47.407,
    line7: 48.661,
  },
  {
    age: 268,
    line1: 41.15,
    line2: 42.405,
    line3: 43.659,
    line4: 44.914,
    line5: 46.168,
    line6: 47.422,
    line7: 48.677,
  },
  {
    age: 269,
    line1: 41.164,
    line2: 42.419,
    line3: 43.673,
    line4: 44.928,
    line5: 46.183,
    line6: 47.438,
    line7: 48.693,
  },
  {
    age: 270,
    line1: 41.178,
    line2: 42.433,
    line3: 43.688,
    line4: 44.943,
    line5: 46.198,
    line6: 47.453,
    line7: 48.707,
  },
  {
    age: 271,
    line1: 41.192,
    line2: 42.447,
    line3: 43.702,
    line4: 44.958,
    line5: 46.213,
    line6: 47.468,
    line7: 48.723,
  },
  {
    age: 272,
    line1: 41.205,
    line2: 42.461,
    line3: 43.716,
    line4: 44.972,
    line5: 46.228,
    line6: 47.483,
    line7: 48.739,
  },
  {
    age: 273,
    line1: 41.218,
    line2: 42.474,
    line3: 43.73,
    line4: 44.986,
    line5: 46.242,
    line6: 47.498,
    line7: 48.754,
  },
  {
    age: 274,
    line1: 41.231,
    line2: 42.488,
    line3: 43.744,
    line4: 45.001,
    line5: 46.257,
    line6: 47.514,
    line7: 48.77,
  },
  {
    age: 275,
    line1: 41.245,
    line2: 42.501,
    line3: 43.758,
    line4: 45.015,
    line5: 46.272,
    line6: 47.529,
    line7: 48.785,
  },
  {
    age: 276,
    line1: 41.258,
    line2: 42.515,
    line3: 43.772,
    line4: 45.029,
    line5: 46.286,
    line6: 47.544,
    line7: 48.801,
  },
  {
    age: 277,
    line1: 41.27,
    line2: 42.528,
    line3: 43.786,
    line4: 45.043,
    line5: 46.301,
    line6: 47.559,
    line7: 48.816,
  },
  {
    age: 278,
    line1: 41.283,
    line2: 42.541,
    line3: 43.799,
    line4: 45.057,
    line5: 46.315,
    line6: 47.573,
    line7: 48.831,
  },
  {
    age: 279,
    line1: 41.297,
    line2: 42.555,
    line3: 43.813,
    line4: 45.071,
    line5: 46.329,
    line6: 47.587,
    line7: 48.845,
  },
  {
    age: 280,
    line1: 41.31,
    line2: 42.569,
    line3: 43.827,
    line4: 45.085,
    line5: 46.344,
    line6: 47.602,
    line7: 48.86,
  },
  {
    age: 281,
    line1: 41.323,
    line2: 42.582,
    line3: 43.84,
    line4: 45.099,
    line5: 46.358,
    line6: 47.617,
    line7: 48.875,
  },
  {
    age: 282,
    line1: 41.336,
    line2: 42.595,
    line3: 43.854,
    line4: 45.113,
    line5: 46.372,
    line6: 47.631,
    line7: 48.89,
  },
  {
    age: 283,
    line1: 41.348,
    line2: 42.608,
    line3: 43.867,
    line4: 45.127,
    line5: 46.386,
    line6: 47.646,
    line7: 48.905,
  },
  {
    age: 284,
    line1: 41.361,
    line2: 42.621,
    line3: 43.88,
    line4: 45.14,
    line5: 46.4,
    line6: 47.66,
    line7: 48.92,
  },
  {
    age: 285,
    line1: 41.373,
    line2: 42.633,
    line3: 43.894,
    line4: 45.154,
    line5: 46.414,
    line6: 47.674,
    line7: 48.935,
  },
  {
    age: 286,
    line1: 41.386,
    line2: 42.646,
    line3: 43.907,
    line4: 45.167,
    line5: 46.428,
    line6: 47.689,
    line7: 48.949,
  },
  {
    age: 287,
    line1: 41.398,
    line2: 42.659,
    line3: 43.92,
    line4: 45.181,
    line5: 46.442,
    line6: 47.703,
    line7: 48.964,
  },
  {
    age: 288,
    line1: 41.41,
    line2: 42.672,
    line3: 43.933,
    line4: 45.194,
    line5: 46.456,
    line6: 47.717,
    line7: 48.978,
  },
  {
    age: 289,
    line1: 41.422,
    line2: 42.684,
    line3: 43.946,
    line4: 45.208,
    line5: 46.469,
    line6: 47.731,
    line7: 48.993,
  },
  {
    age: 290,
    line1: 41.435,
    line2: 42.697,
    line3: 43.959,
    line4: 45.221,
    line5: 46.483,
    line6: 47.745,
    line7: 49.007,
  },
  {
    age: 291,
    line1: 41.448,
    line2: 42.71,
    line3: 43.972,
    line4: 45.234,
    line5: 46.496,
    line6: 47.758,
    line7: 49.02,
  },
  {
    age: 292,
    line1: 41.46,
    line2: 42.723,
    line3: 43.985,
    line4: 45.247,
    line5: 46.51,
    line6: 47.772,
    line7: 49.034,
  },
  {
    age: 293,
    line1: 41.472,
    line2: 42.735,
    line3: 43.998,
    line4: 45.26,
    line5: 46.523,
    line6: 47.786,
    line7: 49.049,
  },
  {
    age: 294,
    line1: 41.484,
    line2: 42.747,
    line3: 44.01,
    line4: 45.273,
    line5: 46.537,
    line6: 47.8,
    line7: 49.063,
  },
  {
    age: 295,
    line1: 41.496,
    line2: 42.759,
    line3: 44.023,
    line4: 45.286,
    line5: 46.55,
    line6: 47.813,
    line7: 49.077,
  },
  {
    age: 296,
    line1: 41.508,
    line2: 42.772,
    line3: 44.035,
    line4: 45.299,
    line5: 46.563,
    line6: 47.827,
    line7: 49.091,
  },
  {
    age: 297,
    line1: 41.519,
    line2: 42.784,
    line3: 44.048,
    line4: 45.312,
    line5: 46.576,
    line6: 47.841,
    line7: 49.105,
  },
  {
    age: 298,
    line1: 41.531,
    line2: 42.796,
    line3: 44.06,
    line4: 45.325,
    line5: 46.589,
    line6: 47.854,
    line7: 49.119,
  },
  {
    age: 299,
    line1: 41.543,
    line2: 42.808,
    line3: 44.073,
    line4: 45.338,
    line5: 46.603,
    line6: 47.868,
    line7: 49.132,
  },
  {
    age: 300,
    line1: 41.554,
    line2: 42.82,
    line3: 44.085,
    line4: 45.35,
    line5: 46.616,
    line6: 47.881,
    line7: 49.146,
  },
  {
    age: 301,
    line1: 41.566,
    line2: 42.832,
    line3: 44.097,
    line4: 45.363,
    line5: 46.629,
    line6: 47.894,
    line7: 49.16,
  },
  {
    age: 302,
    line1: 41.578,
    line2: 42.844,
    line3: 44.11,
    line4: 45.376,
    line5: 46.641,
    line6: 47.907,
    line7: 49.173,
  },
  {
    age: 303,
    line1: 41.589,
    line2: 42.855,
    line3: 44.122,
    line4: 45.388,
    line5: 46.654,
    line6: 47.921,
    line7: 49.187,
  },
  {
    age: 304,
    line1: 41.6,
    line2: 42.867,
    line3: 44.134,
    line4: 45.4,
    line5: 46.667,
    line6: 47.934,
    line7: 49.2,
  },
  {
    age: 305,
    line1: 41.612,
    line2: 42.879,
    line3: 44.146,
    line4: 45.413,
    line5: 46.68,
    line6: 47.947,
    line7: 49.214,
  },
  {
    age: 306,
    line1: 41.623,
    line2: 42.89,
    line3: 44.158,
    line4: 45.425,
    line5: 46.692,
    line6: 47.96,
    line7: 49.227,
  },
  {
    age: 307,
    line1: 41.636,
    line2: 42.903,
    line3: 44.17,
    line4: 45.437,
    line5: 46.705,
    line6: 47.972,
    line7: 49.239,
  },
  {
    age: 308,
    line1: 41.647,
    line2: 42.914,
    line3: 44.182,
    line4: 45.45,
    line5: 46.717,
    line6: 47.985,
    line7: 49.252,
  },
  {
    age: 309,
    line1: 41.658,
    line2: 42.926,
    line3: 44.194,
    line4: 45.462,
    line5: 46.73,
    line6: 47.998,
    line7: 49.266,
  },
  {
    age: 310,
    line1: 41.669,
    line2: 42.937,
    line3: 44.206,
    line4: 45.474,
    line5: 46.742,
    line6: 48.01,
    line7: 49.279,
  },
  {
    age: 311,
    line1: 41.68,
    line2: 42.949,
    line3: 44.217,
    line4: 45.486,
    line5: 46.755,
    line6: 48.023,
    line7: 49.292,
  },
  {
    age: 312,
    line1: 41.691,
    line2: 42.96,
    line3: 44.229,
    line4: 45.498,
    line5: 46.767,
    line6: 48.036,
    line7: 49.305,
  },
  {
    age: 313,
    line1: 41.702,
    line2: 42.971,
    line3: 44.241,
    line4: 45.51,
    line5: 46.779,
    line6: 48.048,
    line7: 49.318,
  },
  {
    age: 314,
    line1: 41.713,
    line2: 42.982,
    line3: 44.252,
    line4: 45.522,
    line5: 46.791,
    line6: 48.061,
    line7: 49.331,
  },
  {
    age: 315,
    line1: 41.724,
    line2: 42.994,
    line3: 44.264,
    line4: 45.534,
    line5: 46.804,
    line6: 48.073,
    line7: 49.343,
  },
  {
    age: 316,
    line1: 41.735,
    line2: 43.005,
    line3: 44.275,
    line4: 45.545,
    line5: 46.816,
    line6: 48.086,
    line7: 49.356,
  },
  {
    age: 317,
    line1: 41.745,
    line2: 43.016,
    line3: 44.287,
    line4: 45.557,
    line5: 46.828,
    line6: 48.098,
    line7: 49.369,
  },
  {
    age: 318,
    line1: 41.756,
    line2: 43.027,
    line3: 44.298,
    line4: 45.569,
    line5: 46.84,
    line6: 48.111,
    line7: 49.381,
  },
  {
    age: 319,
    line1: 41.767,
    line2: 43.038,
    line3: 44.309,
    line4: 45.58,
    line5: 46.852,
    line6: 48.123,
    line7: 49.394,
  },
  {
    age: 320,
    line1: 41.777,
    line2: 43.049,
    line3: 44.32,
    line4: 45.592,
    line5: 46.863,
    line6: 48.135,
    line7: 49.407,
  },
  {
    age: 321,
    line1: 41.788,
    line2: 43.06,
    line3: 44.332,
    line4: 45.603,
    line5: 46.875,
    line6: 48.147,
    line7: 49.419,
  },
  {
    age: 322,
    line1: 41.798,
    line2: 43.071,
    line3: 44.343,
    line4: 45.615,
    line5: 46.887,
    line6: 48.159,
    line7: 49.431,
  },
  {
    age: 323,
    line1: 41.809,
    line2: 43.081,
    line3: 44.354,
    line4: 45.626,
    line5: 46.899,
    line6: 48.171,
    line7: 49.444,
  },
  {
    age: 324,
    line1: 41.819,
    line2: 43.092,
    line3: 44.365,
    line4: 45.638,
    line5: 46.91,
    line6: 48.183,
    line7: 49.456,
  },
  {
    age: 325,
    line1: 41.829,
    line2: 43.103,
    line3: 44.376,
    line4: 45.649,
    line5: 46.922,
    line6: 48.195,
    line7: 49.468,
  },
  {
    age: 326,
    line1: 41.84,
    line2: 43.113,
    line3: 44.387,
    line4: 45.66,
    line5: 46.934,
    line6: 48.207,
    line7: 49.481,
  },
  {
    age: 327,
    line1: 41.85,
    line2: 43.124,
    line3: 44.398,
    line4: 45.671,
    line5: 46.945,
    line6: 48.219,
    line7: 49.493,
  },
  {
    age: 328,
    line1: 41.86,
    line2: 43.134,
    line3: 44.409,
    line4: 45.683,
    line5: 46.957,
    line6: 48.231,
    line7: 49.505,
  },
  {
    age: 329,
    line1: 41.871,
    line2: 43.145,
    line3: 44.419,
    line4: 45.694,
    line5: 46.968,
    line6: 48.242,
    line7: 49.517,
  },
  {
    age: 330,
    line1: 41.881,
    line2: 43.155,
    line3: 44.43,
    line4: 45.705,
    line5: 46.979,
    line6: 48.254,
    line7: 49.529,
  },
  {
    age: 331,
    line1: 41.891,
    line2: 43.166,
    line3: 44.441,
    line4: 45.716,
    line5: 46.991,
    line6: 48.266,
    line7: 49.541,
  },
  {
    age: 332,
    line1: 41.901,
    line2: 43.176,
    line3: 44.451,
    line4: 45.727,
    line5: 47.002,
    line6: 48.277,
    line7: 49.553,
  },
  {
    age: 333,
    line1: 41.911,
    line2: 43.186,
    line3: 44.462,
    line4: 45.738,
    line5: 47.013,
    line6: 48.289,
    line7: 49.564,
  },
  {
    age: 334,
    line1: 41.921,
    line2: 43.197,
    line3: 44.473,
    line4: 45.748,
    line5: 47.024,
    line6: 48.3,
    line7: 49.576,
  },
  {
    age: 335,
    line1: 41.931,
    line2: 43.207,
    line3: 44.483,
    line4: 45.759,
    line5: 47.036,
    line6: 48.312,
    line7: 49.588,
  },
  {
    age: 336,
    line1: 41.941,
    line2: 43.217,
    line3: 44.494,
    line4: 45.77,
    line5: 47.047,
    line6: 48.323,
    line7: 49.6,
  },
  {
    age: 337,
    line1: 41.95,
    line2: 43.227,
    line3: 44.504,
    line4: 45.781,
    line5: 47.058,
    line6: 48.334,
    line7: 49.611,
  },
  {
    age: 338,
    line1: 41.96,
    line2: 43.237,
    line3: 44.514,
    line4: 45.792,
    line5: 47.069,
    line6: 48.346,
    line7: 49.623,
  },
  {
    age: 339,
    line1: 41.97,
    line2: 43.247,
    line3: 44.525,
    line4: 45.802,
    line5: 47.08,
    line6: 48.357,
    line7: 49.634,
  },
  {
    age: 340,
    line1: 41.98,
    line2: 43.257,
    line3: 44.535,
    line4: 45.813,
    line5: 47.091,
    line6: 48.368,
    line7: 49.646,
  },
  {
    age: 341,
    line1: 41.991,
    line2: 43.268,
    line3: 44.546,
    line4: 45.823,
    line5: 47.101,
    line6: 48.378,
    line7: 49.656,
  },
  {
    age: 342,
    line1: 42,
    line2: 43.278,
    line3: 44.556,
    line4: 45.834,
    line5: 47.112,
    line6: 48.389,
    line7: 49.667,
  },
  {
    age: 343,
    line1: 42.01,
    line2: 43.288,
    line3: 44.566,
    line4: 45.844,
    line5: 47.122,
    line6: 48.401,
    line7: 49.679,
  },
  {
    age: 344,
    line1: 42.019,
    line2: 43.298,
    line3: 44.576,
    line4: 45.855,
    line5: 47.133,
    line6: 48.412,
    line7: 49.69,
  },
  {
    age: 345,
    line1: 42.029,
    line2: 43.308,
    line3: 44.586,
    line4: 45.865,
    line5: 47.144,
    line6: 48.423,
    line7: 49.701,
  },
  {
    age: 346,
    line1: 42.038,
    line2: 43.317,
    line3: 44.596,
    line4: 45.875,
    line5: 47.154,
    line6: 48.433,
    line7: 49.712,
  },
  {
    age: 347,
    line1: 42.048,
    line2: 43.327,
    line3: 44.606,
    line4: 45.886,
    line5: 47.165,
    line6: 48.444,
    line7: 49.724,
  },
  {
    age: 348,
    line1: 42.057,
    line2: 43.337,
    line3: 44.616,
    line4: 45.896,
    line5: 47.175,
    line6: 48.455,
    line7: 49.735,
  },
  {
    age: 349,
    line1: 42.067,
    line2: 43.346,
    line3: 44.626,
    line4: 45.906,
    line5: 47.186,
    line6: 48.466,
    line7: 49.746,
  },
  {
    age: 350,
    line1: 42.076,
    line2: 43.356,
    line3: 44.636,
    line4: 45.916,
    line5: 47.196,
    line6: 48.477,
    line7: 49.757,
  },
  {
    age: 351,
    line1: 42.085,
    line2: 43.366,
    line3: 44.646,
    line4: 45.926,
    line5: 47.207,
    line6: 48.487,
    line7: 49.768,
  },
  {
    age: 352,
    line1: 42.094,
    line2: 43.375,
    line3: 44.656,
    line4: 45.936,
    line5: 47.217,
    line6: 48.498,
    line7: 49.779,
  },
  {
    age: 353,
    line1: 42.104,
    line2: 43.385,
    line3: 44.666,
    line4: 45.946,
    line5: 47.227,
    line6: 48.508,
    line7: 49.789,
  },
  {
    age: 354,
    line1: 42.113,
    line2: 43.394,
    line3: 44.675,
    line4: 45.956,
    line5: 47.238,
    line6: 48.519,
    line7: 49.8,
  },
  {
    age: 355,
    line1: 42.122,
    line2: 43.403,
    line3: 44.685,
    line4: 45.966,
    line5: 47.248,
    line6: 48.529,
    line7: 49.811,
  },
  {
    age: 356,
    line1: 42.131,
    line2: 43.413,
    line3: 44.694,
    line4: 45.976,
    line5: 47.258,
    line6: 48.54,
    line7: 49.822,
  },
  {
    age: 357,
    line1: 42.14,
    line2: 43.422,
    line3: 44.704,
    line4: 45.986,
    line5: 47.268,
    line6: 48.55,
    line7: 49.832,
  },
  {
    age: 358,
    line1: 42.149,
    line2: 43.431,
    line3: 44.714,
    line4: 45.996,
    line5: 47.278,
    line6: 48.561,
    line7: 49.843,
  },
  {
    age: 359,
    line1: 42.158,
    line2: 43.441,
    line3: 44.723,
    line4: 46.006,
    line5: 47.288,
    line6: 48.571,
    line7: 49.854,
  },
  {
    age: 360,
    line1: 42.167,
    line2: 43.45,
    line3: 44.733,
    line4: 46.016,
    line5: 47.298,
    line6: 48.581,
    line7: 49.864,
  },
  {
    age: 361,
    line1: 42.176,
    line2: 43.459,
    line3: 44.742,
    line4: 46.025,
    line5: 47.308,
    line6: 48.592,
    line7: 49.875,
  },
  {
    age: 362,
    line1: 42.183,
    line2: 43.467,
    line3: 44.751,
    line4: 46.035,
    line5: 47.319,
    line6: 48.603,
    line7: 49.887,
  },
  {
    age: 363,
    line1: 42.192,
    line2: 43.476,
    line3: 44.76,
    line4: 46.044,
    line5: 47.329,
    line6: 48.613,
    line7: 49.897,
  },
  {
    age: 364,
    line1: 42.201,
    line2: 43.485,
    line3: 44.77,
    line4: 46.054,
    line5: 47.339,
    line6: 48.623,
    line7: 49.907,
  },
  {
    age: 365,
    line1: 42.21,
    line2: 43.494,
    line3: 44.779,
    line4: 46.064,
    line5: 47.348,
    line6: 48.633,
    line7: 49.918,
  },
  {
    age: 366,
    line1: 42.218,
    line2: 43.503,
    line3: 44.788,
    line4: 46.073,
    line5: 47.358,
    line6: 48.643,
    line7: 49.928,
  },
  {
    age: 367,
    line1: 42.227,
    line2: 43.512,
    line3: 44.797,
    line4: 46.083,
    line5: 47.368,
    line6: 48.653,
    line7: 49.938,
  },
  {
    age: 368,
    line1: 42.236,
    line2: 43.521,
    line3: 44.807,
    line4: 46.092,
    line5: 47.378,
    line6: 48.663,
    line7: 49.949,
  },
  {
    age: 369,
    line1: 42.244,
    line2: 43.53,
    line3: 44.816,
    line4: 46.102,
    line5: 47.387,
    line6: 48.673,
    line7: 49.959,
  },
  {
    age: 370,
    line1: 42.253,
    line2: 43.539,
    line3: 44.825,
    line4: 46.111,
    line5: 47.397,
    line6: 48.683,
    line7: 49.969,
  },
  {
    age: 371,
    line1: 42.261,
    line2: 43.548,
    line3: 44.834,
    line4: 46.12,
    line5: 47.406,
    line6: 48.693,
    line7: 49.979,
  },
  {
    age: 372,
    line1: 42.27,
    line2: 43.556,
    line3: 44.843,
    line4: 46.13,
    line5: 47.416,
    line6: 48.703,
    line7: 49.989,
  },
  {
    age: 373,
    line1: 42.278,
    line2: 43.565,
    line3: 44.852,
    line4: 46.139,
    line5: 47.426,
    line6: 48.712,
    line7: 49.999,
  },
  {
    age: 374,
    line1: 42.287,
    line2: 43.574,
    line3: 44.861,
    line4: 46.148,
    line5: 47.435,
    line6: 48.722,
    line7: 50.009,
  },
  {
    age: 375,
    line1: 42.295,
    line2: 43.583,
    line3: 44.87,
    line4: 46.157,
    line5: 47.445,
    line6: 48.732,
    line7: 50.019,
  },
  {
    age: 376,
    line1: 42.304,
    line2: 43.591,
    line3: 44.879,
    line4: 46.166,
    line5: 47.454,
    line6: 48.741,
    line7: 50.029,
  },
  {
    age: 377,
    line1: 42.312,
    line2: 43.6,
    line3: 44.888,
    line4: 46.175,
    line5: 47.463,
    line6: 48.751,
    line7: 50.039,
  },
  {
    age: 378,
    line1: 42.32,
    line2: 43.608,
    line3: 44.896,
    line4: 46.184,
    line5: 47.473,
    line6: 48.761,
    line7: 50.049,
  },
  {
    age: 379,
    line1: 42.328,
    line2: 43.617,
    line3: 44.905,
    line4: 46.194,
    line5: 47.482,
    line6: 48.77,
    line7: 50.059,
  },
  {
    age: 380,
    line1: 42.337,
    line2: 43.625,
    line3: 44.914,
    line4: 46.202,
    line5: 47.491,
    line6: 48.78,
    line7: 50.068,
  },
  {
    age: 381,
    line1: 42.345,
    line2: 43.634,
    line3: 44.923,
    line4: 46.212,
    line5: 47.5,
    line6: 48.789,
    line7: 50.078,
  },
  {
    age: 382,
    line1: 42.353,
    line2: 43.642,
    line3: 44.931,
    line4: 46.22,
    line5: 47.509,
    line6: 48.799,
    line7: 50.088,
  },
  {
    age: 383,
    line1: 42.361,
    line2: 43.651,
    line3: 44.94,
    line4: 46.229,
    line5: 47.519,
    line6: 48.808,
    line7: 50.097,
  },
  {
    age: 384,
    line1: 42.369,
    line2: 43.659,
    line3: 44.949,
    line4: 46.238,
    line5: 47.528,
    line6: 48.817,
    line7: 50.107,
  },
  {
    age: 385,
    line1: 42.378,
    line2: 43.667,
    line3: 44.957,
    line4: 46.247,
    line5: 47.537,
    line6: 48.827,
    line7: 50.117,
  },
  {
    age: 386,
    line1: 42.386,
    line2: 43.676,
    line3: 44.966,
    line4: 46.256,
    line5: 47.546,
    line6: 48.836,
    line7: 50.126,
  },
  {
    age: 387,
    line1: 42.394,
    line2: 43.684,
    line3: 44.974,
    line4: 46.265,
    line5: 47.555,
    line6: 48.845,
    line7: 50.136,
  },
  {
    age: 388,
    line1: 42.402,
    line2: 43.692,
    line3: 44.983,
    line4: 46.273,
    line5: 47.564,
    line6: 48.855,
    line7: 50.145,
  },
  {
    age: 389,
    line1: 42.41,
    line2: 43.7,
    line3: 44.991,
    line4: 46.282,
    line5: 47.573,
    line6: 48.864,
    line7: 50.155,
  },
  {
    age: 390,
    line1: 42.418,
    line2: 43.709,
    line3: 45,
    line4: 46.291,
    line5: 47.582,
    line6: 48.873,
    line7: 50.164,
  },
  {
    age: 391,
    line1: 42.426,
    line2: 43.717,
    line3: 45.008,
    line4: 46.299,
    line5: 47.591,
    line6: 48.882,
    line7: 50.173,
  },
  {
    age: 392,
    line1: 42.433,
    line2: 43.725,
    line3: 45.016,
    line4: 46.308,
    line5: 47.6,
    line6: 48.891,
    line7: 50.183,
  },
  {
    age: 393,
    line1: 42.441,
    line2: 43.733,
    line3: 45.025,
    line4: 46.317,
    line5: 47.608,
    line6: 48.9,
    line7: 50.192,
  },
  {
    age: 394,
    line1: 42.449,
    line2: 43.741,
    line3: 45.033,
    line4: 46.325,
    line5: 47.617,
    line6: 48.909,
    line7: 50.201,
  },
  {
    age: 395,
    line1: 42.457,
    line2: 43.749,
    line3: 45.041,
    line4: 46.334,
    line5: 47.626,
    line6: 48.918,
    line7: 50.21,
  },
  {
    age: 396,
    line1: 42.465,
    line2: 43.757,
    line3: 45.05,
    line4: 46.342,
    line5: 47.635,
    line6: 48.927,
    line7: 50.22,
  },
  {
    age: 397,
    line1: 42.472,
    line2: 43.765,
    line3: 45.058,
    line4: 46.351,
    line5: 47.643,
    line6: 48.936,
    line7: 50.229,
  },
  {
    age: 398,
    line1: 42.48,
    line2: 43.773,
    line3: 45.066,
    line4: 46.359,
    line5: 47.652,
    line6: 48.945,
    line7: 50.238,
  },
  {
    age: 399,
    line1: 42.488,
    line2: 43.781,
    line3: 45.074,
    line4: 46.367,
    line5: 47.661,
    line6: 48.954,
    line7: 50.247,
  },
  {
    age: 400,
    line1: 42.496,
    line2: 43.789,
    line3: 45.082,
    line4: 46.376,
    line5: 47.669,
    line6: 48.963,
    line7: 50.256,
  },
  {
    age: 401,
    line1: 42.503,
    line2: 43.797,
    line3: 45.09,
    line4: 46.384,
    line5: 47.678,
    line6: 48.971,
    line7: 50.265,
  },
  {
    age: 402,
    line1: 42.509,
    line2: 43.804,
    line3: 45.098,
    line4: 46.392,
    line5: 47.687,
    line6: 48.981,
    line7: 50.275,
  },
  {
    age: 403,
    line1: 42.517,
    line2: 43.812,
    line3: 45.106,
    line4: 46.401,
    line5: 47.695,
    line6: 48.99,
    line7: 50.284,
  },
  {
    age: 404,
    line1: 42.525,
    line2: 43.819,
    line3: 45.114,
    line4: 46.409,
    line5: 47.704,
    line6: 48.999,
    line7: 50.293,
  },
  {
    age: 405,
    line1: 42.532,
    line2: 43.827,
    line3: 45.122,
    line4: 46.417,
    line5: 47.712,
    line6: 49.007,
    line7: 50.302,
  },
  {
    age: 406,
    line1: 42.54,
    line2: 43.835,
    line3: 45.13,
    line4: 46.425,
    line5: 47.721,
    line6: 49.016,
    line7: 50.311,
  },
  {
    age: 407,
    line1: 42.547,
    line2: 43.843,
    line3: 45.138,
    line4: 46.434,
    line5: 47.729,
    line6: 49.024,
    line7: 50.32,
  },
  {
    age: 408,
    line1: 42.555,
    line2: 43.85,
    line3: 45.146,
    line4: 46.442,
    line5: 47.737,
    line6: 49.033,
    line7: 50.329,
  },
  {
    age: 409,
    line1: 42.562,
    line2: 43.858,
    line3: 45.154,
    line4: 46.45,
    line5: 47.746,
    line6: 49.042,
    line7: 50.338,
  },
  {
    age: 410,
    line1: 42.569,
    line2: 43.865,
    line3: 45.162,
    line4: 46.458,
    line5: 47.754,
    line6: 49.05,
    line7: 50.346,
  },
  {
    age: 411,
    line1: 42.577,
    line2: 43.873,
    line3: 45.17,
    line4: 46.466,
    line5: 47.762,
    line6: 49.059,
    line7: 50.355,
  },
  {
    age: 412,
    line1: 42.584,
    line2: 43.881,
    line3: 45.177,
    line4: 46.474,
    line5: 47.771,
    line6: 49.067,
    line7: 50.364,
  },
  {
    age: 413,
    line1: 42.591,
    line2: 43.888,
    line3: 45.185,
    line4: 46.482,
    line5: 47.779,
    line6: 49.076,
    line7: 50.372,
  },
  {
    age: 414,
    line1: 42.599,
    line2: 43.896,
    line3: 45.193,
    line4: 46.49,
    line5: 47.787,
    line6: 49.084,
    line7: 50.381,
  },
  {
    age: 415,
    line1: 42.606,
    line2: 43.903,
    line3: 45.201,
    line4: 46.498,
    line5: 47.795,
    line6: 49.092,
    line7: 50.39,
  },
  {
    age: 416,
    line1: 42.613,
    line2: 43.911,
    line3: 45.208,
    line4: 46.506,
    line5: 47.803,
    line6: 49.101,
    line7: 50.398,
  },
  {
    age: 417,
    line1: 42.62,
    line2: 43.918,
    line3: 45.216,
    line4: 46.514,
    line5: 47.811,
    line6: 49.109,
    line7: 50.407,
  },
  {
    age: 418,
    line1: 42.628,
    line2: 43.926,
    line3: 45.224,
    line4: 46.522,
    line5: 47.819,
    line6: 49.117,
    line7: 50.415,
  },
  {
    age: 419,
    line1: 42.635,
    line2: 43.933,
    line3: 45.231,
    line4: 46.529,
    line5: 47.827,
    line6: 49.126,
    line7: 50.424,
  },
  {
    age: 420,
    line1: 42.642,
    line2: 43.94,
    line3: 45.239,
    line4: 46.537,
    line5: 47.835,
    line6: 49.134,
    line7: 50.432,
  },
  {
    age: 421,
    line1: 42.649,
    line2: 43.948,
    line3: 45.246,
    line4: 46.545,
    line5: 47.844,
    line6: 49.142,
    line7: 50.441,
  },
  {
    age: 422,
    line1: 42.656,
    line2: 43.955,
    line3: 45.254,
    line4: 46.553,
    line5: 47.852,
    line6: 49.15,
    line7: 50.449,
  },
  {
    age: 423,
    line1: 42.663,
    line2: 43.962,
    line3: 45.261,
    line4: 46.56,
    line5: 47.859,
    line6: 49.158,
    line7: 50.458,
  },
  {
    age: 424,
    line1: 42.67,
    line2: 43.97,
    line3: 45.269,
    line4: 46.568,
    line5: 47.867,
    line6: 49.167,
    line7: 50.466,
  },
  {
    age: 425,
    line1: 42.676,
    line2: 43.976,
    line3: 45.276,
    line4: 46.576,
    line5: 47.876,
    line6: 49.176,
    line7: 50.476,
  },
  {
    age: 426,
    line1: 42.683,
    line2: 43.983,
    line3: 45.283,
    line4: 46.583,
    line5: 47.884,
    line6: 49.184,
    line7: 50.484,
  },
  {
    age: 427,
    line1: 42.69,
    line2: 43.99,
    line3: 45.291,
    line4: 46.591,
    line5: 47.891,
    line6: 49.192,
    line7: 50.492,
  },
  {
    age: 428,
    line1: 42.697,
    line2: 43.998,
    line3: 45.298,
    line4: 46.599,
    line5: 47.899,
    line6: 49.2,
    line7: 50.5,
  },
  {
    age: 429,
    line1: 42.704,
    line2: 44.005,
    line3: 45.305,
    line4: 46.606,
    line5: 47.907,
    line6: 49.208,
    line7: 50.509,
  },
  {
    age: 430,
    line1: 42.711,
    line2: 44.012,
    line3: 45.313,
    line4: 46.614,
    line5: 47.915,
    line6: 49.216,
    line7: 50.517,
  },
  {
    age: 431,
    line1: 42.718,
    line2: 44.019,
    line3: 45.32,
    line4: 46.621,
    line5: 47.923,
    line6: 49.224,
    line7: 50.525,
  },
  {
    age: 432,
    line1: 42.725,
    line2: 44.026,
    line3: 45.327,
    line4: 46.629,
    line5: 47.93,
    line6: 49.232,
    line7: 50.533,
  },
  {
    age: 433,
    line1: 42.731,
    line2: 44.033,
    line3: 45.335,
    line4: 46.636,
    line5: 47.938,
    line6: 49.24,
    line7: 50.541,
  },
  {
    age: 434,
    line1: 42.738,
    line2: 44.04,
    line3: 45.342,
    line4: 46.644,
    line5: 47.946,
    line6: 49.247,
    line7: 50.549,
  },
  {
    age: 435,
    line1: 42.745,
    line2: 44.047,
    line3: 45.349,
    line4: 46.651,
    line5: 47.953,
    line6: 49.255,
    line7: 50.557,
  },
  {
    age: 436,
    line1: 42.752,
    line2: 44.054,
    line3: 45.356,
    line4: 46.659,
    line5: 47.961,
    line6: 49.263,
    line7: 50.565,
  },
  {
    age: 437,
    line1: 42.759,
    line2: 44.061,
    line3: 45.364,
    line4: 46.666,
    line5: 47.968,
    line6: 49.271,
    line7: 50.573,
  },
  {
    age: 438,
    line1: 42.765,
    line2: 44.068,
    line3: 45.371,
    line4: 46.673,
    line5: 47.976,
    line6: 49.279,
    line7: 50.581,
  },
  {
    age: 439,
    line1: 42.772,
    line2: 44.075,
    line3: 45.378,
    line4: 46.681,
    line5: 47.984,
    line6: 49.286,
    line7: 50.589,
  },
  {
    age: 440,
    line1: 42.779,
    line2: 44.082,
    line3: 45.385,
    line4: 46.688,
    line5: 47.991,
    line6: 49.294,
    line7: 50.597,
  },
  {
    age: 441,
    line1: 42.786,
    line2: 44.089,
    line3: 45.392,
    line4: 46.695,
    line5: 47.999,
    line6: 49.302,
    line7: 50.605,
  },
  {
    age: 442,
    line1: 42.792,
    line2: 44.096,
    line3: 45.399,
    line4: 46.703,
    line5: 48.006,
    line6: 49.31,
    line7: 50.613,
  },
  {
    age: 443,
    line1: 42.797,
    line2: 44.102,
    line3: 45.406,
    line4: 46.71,
    line5: 48.014,
    line6: 49.318,
    line7: 50.622,
  },
  {
    age: 444,
    line1: 42.804,
    line2: 44.108,
    line3: 45.413,
    line4: 46.717,
    line5: 48.021,
    line6: 49.326,
    line7: 50.63,
  },
  {
    age: 445,
    line1: 42.811,
    line2: 44.115,
    line3: 45.42,
    line4: 46.724,
    line5: 48.029,
    line6: 49.333,
    line7: 50.638,
  },
  {
    age: 446,
    line1: 42.817,
    line2: 44.122,
    line3: 45.427,
    line4: 46.731,
    line5: 48.036,
    line6: 49.341,
    line7: 50.646,
  },
  {
    age: 447,
    line1: 42.824,
    line2: 44.129,
    line3: 45.434,
    line4: 46.739,
    line5: 48.044,
    line6: 49.348,
    line7: 50.653,
  },
  {
    age: 448,
    line1: 42.83,
    line2: 44.135,
    line3: 45.441,
    line4: 46.746,
    line5: 48.051,
    line6: 49.356,
    line7: 50.661,
  },
  {
    age: 449,
    line1: 42.837,
    line2: 44.142,
    line3: 45.448,
    line4: 46.753,
    line5: 48.058,
    line6: 49.364,
    line7: 50.669,
  },
  {
    age: 450,
    line1: 42.843,
    line2: 44.149,
    line3: 45.454,
    line4: 46.76,
    line5: 48.066,
    line6: 49.371,
    line7: 50.677,
  },
  {
    age: 451,
    line1: 42.85,
    line2: 44.156,
    line3: 45.461,
    line4: 46.767,
    line5: 48.073,
    line6: 49.378,
    line7: 50.684,
  },
  {
    age: 452,
    line1: 42.856,
    line2: 44.162,
    line3: 45.468,
    line4: 46.774,
    line5: 48.08,
    line6: 49.386,
    line7: 50.692,
  },
  {
    age: 453,
    line1: 42.863,
    line2: 44.169,
    line3: 45.475,
    line4: 46.781,
    line5: 48.087,
    line6: 49.393,
    line7: 50.699,
  },
  {
    age: 454,
    line1: 42.869,
    line2: 44.175,
    line3: 45.482,
    line4: 46.788,
    line5: 48.094,
    line6: 49.401,
    line7: 50.707,
  },
  {
    age: 455,
    line1: 42.876,
    line2: 44.182,
    line3: 45.489,
    line4: 46.795,
    line5: 48.102,
    line6: 49.408,
    line7: 50.715,
  },
  {
    age: 456,
    line1: 42.882,
    line2: 44.189,
    line3: 45.495,
    line4: 46.802,
    line5: 48.109,
    line6: 49.416,
    line7: 50.722,
  },
  {
    age: 457,
    line1: 42.888,
    line2: 44.195,
    line3: 45.502,
    line4: 46.809,
    line5: 48.116,
    line6: 49.423,
    line7: 50.73,
  },
  {
    age: 458,
    line1: 42.895,
    line2: 44.202,
    line3: 45.509,
    line4: 46.816,
    line5: 48.123,
    line6: 49.43,
    line7: 50.737,
  },
  {
    age: 459,
    line1: 42.9,
    line2: 44.207,
    line3: 45.515,
    line4: 46.823,
    line5: 48.131,
    line6: 49.438,
    line7: 50.746,
  },
  {
    age: 460,
    line1: 42.906,
    line2: 44.214,
    line3: 45.522,
    line4: 46.83,
    line5: 48.138,
    line6: 49.446,
    line7: 50.754,
  },
  {
    age: 461,
    line1: 42.912,
    line2: 44.22,
    line3: 45.528,
    line4: 46.837,
    line5: 48.145,
    line6: 49.453,
    line7: 50.761,
  },
  {
    age: 462,
    line1: 42.918,
    line2: 44.227,
    line3: 45.535,
    line4: 46.844,
    line5: 48.152,
    line6: 49.46,
    line7: 50.769,
  },
  {
    age: 463,
    line1: 42.925,
    line2: 44.233,
    line3: 45.542,
    line4: 46.85,
    line5: 48.159,
    line6: 49.467,
    line7: 50.776,
  },
  {
    age: 464,
    line1: 42.931,
    line2: 44.24,
    line3: 45.548,
    line4: 46.857,
    line5: 48.166,
    line6: 49.475,
    line7: 50.783,
  },
  {
    age: 465,
    line1: 42.937,
    line2: 44.246,
    line3: 45.555,
    line4: 46.864,
    line5: 48.173,
    line6: 49.482,
    line7: 50.791,
  },
  {
    age: 466,
    line1: 42.943,
    line2: 44.253,
    line3: 45.562,
    line4: 46.871,
    line5: 48.18,
    line6: 49.489,
    line7: 50.798,
  },
  {
    age: 467,
    line1: 42.95,
    line2: 44.259,
    line3: 45.568,
    line4: 46.878,
    line5: 48.187,
    line6: 49.496,
    line7: 50.805,
  },
  {
    age: 468,
    line1: 42.956,
    line2: 44.265,
    line3: 45.575,
    line4: 46.884,
    line5: 48.194,
    line6: 49.503,
    line7: 50.813,
  },
  {
    age: 469,
    line1: 42.962,
    line2: 44.272,
    line3: 45.581,
    line4: 46.891,
    line5: 48.201,
    line6: 49.51,
    line7: 50.82,
  },
  {
    age: 470,
    line1: 42.968,
    line2: 44.278,
    line3: 45.588,
    line4: 46.898,
    line5: 48.207,
    line6: 49.517,
    line7: 50.827,
  },
  {
    age: 471,
    line1: 42.974,
    line2: 44.284,
    line3: 45.594,
    line4: 46.904,
    line5: 48.214,
    line6: 49.524,
    line7: 50.834,
  },
  {
    age: 472,
    line1: 42.98,
    line2: 44.291,
    line3: 45.601,
    line4: 46.911,
    line5: 48.221,
    line6: 49.531,
    line7: 50.842,
  },
  {
    age: 473,
    line1: 42.985,
    line2: 44.296,
    line3: 45.607,
    line4: 46.918,
    line5: 48.228,
    line6: 49.539,
    line7: 50.85,
  },
  {
    age: 474,
    line1: 42.991,
    line2: 44.302,
    line3: 45.613,
    line4: 46.924,
    line5: 48.235,
    line6: 49.546,
    line7: 50.857,
  },
  {
    age: 475,
    line1: 42.997,
    line2: 44.308,
    line3: 45.62,
    line4: 46.931,
    line5: 48.242,
    line6: 49.553,
    line7: 50.865,
  },
  {
    age: 476,
    line1: 43.003,
    line2: 44.315,
    line3: 45.626,
    line4: 46.937,
    line5: 48.249,
    line6: 49.56,
    line7: 50.872,
  },
  {
    age: 477,
    line1: 43.009,
    line2: 44.321,
    line3: 45.632,
    line4: 46.944,
    line5: 48.256,
    line6: 49.567,
    line7: 50.879,
  },
  {
    age: 478,
    line1: 43.015,
    line2: 44.327,
    line3: 45.639,
    line4: 46.95,
    line5: 48.262,
    line6: 49.574,
    line7: 50.886,
  },
  {
    age: 479,
    line1: 43.021,
    line2: 44.333,
    line3: 45.645,
    line4: 46.957,
    line5: 48.269,
    line6: 49.581,
    line7: 50.893,
  },
  {
    age: 480,
    line1: 43.027,
    line2: 44.339,
    line3: 45.651,
    line4: 46.964,
    line5: 48.276,
    line6: 49.588,
    line7: 50.9,
  },
  {
    age: 481,
    line1: 43.033,
    line2: 44.345,
    line3: 45.658,
    line4: 46.97,
    line5: 48.282,
    line6: 49.595,
    line7: 50.907,
  },
  {
    age: 482,
    line1: 43.039,
    line2: 44.352,
    line3: 45.664,
    line4: 46.977,
    line5: 48.289,
    line6: 49.602,
    line7: 50.914,
  },
  {
    age: 483,
    line1: 43.045,
    line2: 44.358,
    line3: 45.67,
    line4: 46.983,
    line5: 48.296,
    line6: 49.609,
    line7: 50.921,
  },
  {
    age: 484,
    line1: 43.051,
    line2: 44.364,
    line3: 45.677,
    line4: 46.99,
    line5: 48.302,
    line6: 49.615,
    line7: 50.928,
  },
  {
    age: 485,
    line1: 43.057,
    line2: 44.37,
    line3: 45.683,
    line4: 46.996,
    line5: 48.309,
    line6: 49.622,
    line7: 50.935,
  },
  {
    age: 486,
    line1: 43.061,
    line2: 44.375,
    line3: 45.689,
    line4: 47.002,
    line5: 48.316,
    line6: 49.63,
    line7: 50.944,
  },
  {
    age: 487,
    line1: 43.067,
    line2: 44.381,
    line3: 45.695,
    line4: 47.009,
    line5: 48.323,
    line6: 49.637,
    line7: 50.95,
  },
  {
    age: 488,
    line1: 43.073,
    line2: 44.387,
    line3: 45.701,
    line4: 47.015,
    line5: 48.329,
    line6: 49.643,
    line7: 50.957,
  },
  {
    age: 489,
    line1: 43.079,
    line2: 44.393,
    line3: 45.707,
    line4: 47.022,
    line5: 48.336,
    line6: 49.65,
    line7: 50.964,
  },
  {
    age: 490,
    line1: 43.085,
    line2: 44.399,
    line3: 45.713,
    line4: 47.028,
    line5: 48.342,
    line6: 49.657,
    line7: 50.971,
  },
  {
    age: 491,
    line1: 43.09,
    line2: 44.405,
    line3: 45.72,
    line4: 47.034,
    line5: 48.349,
    line6: 49.663,
    line7: 50.978,
  },
  {
    age: 492,
    line1: 43.096,
    line2: 44.411,
    line3: 45.726,
    line4: 47.04,
    line5: 48.355,
    line6: 49.67,
    line7: 50.985,
  },
  {
    age: 493,
    line1: 43.102,
    line2: 44.417,
    line3: 45.732,
    line4: 47.047,
    line5: 48.362,
    line6: 49.677,
    line7: 50.992,
  },
  {
    age: 494,
    line1: 43.108,
    line2: 44.423,
    line3: 45.738,
    line4: 47.053,
    line5: 48.368,
    line6: 49.683,
    line7: 50.999,
  },
  {
    age: 495,
    line1: 43.113,
    line2: 44.429,
    line3: 45.744,
    line4: 47.059,
    line5: 48.375,
    line6: 49.69,
    line7: 51.005,
  },
  {
    age: 496,
    line1: 43.119,
    line2: 44.435,
    line3: 45.75,
    line4: 47.066,
    line5: 48.381,
    line6: 49.697,
    line7: 51.012,
  },
  {
    age: 497,
    line1: 43.125,
    line2: 44.441,
    line3: 45.756,
    line4: 47.072,
    line5: 48.388,
    line6: 49.703,
    line7: 51.019,
  },
  {
    age: 498,
    line1: 43.131,
    line2: 44.446,
    line3: 45.762,
    line4: 47.078,
    line5: 48.394,
    line6: 49.71,
    line7: 51.026,
  },
  {
    age: 499,
    line1: 43.135,
    line2: 44.451,
    line3: 45.768,
    line4: 47.084,
    line5: 48.401,
    line6: 49.717,
    line7: 51.034,
  },
  {
    age: 500,
    line1: 43.141,
    line2: 44.457,
    line3: 45.774,
    line4: 47.09,
    line5: 48.407,
    line6: 49.724,
    line7: 51.04,
  },
  {
    age: 501,
    line1: 43.146,
    line2: 44.463,
    line3: 45.78,
    line4: 47.097,
    line5: 48.414,
    line6: 49.73,
    line7: 51.047,
  },
  {
    age: 502,
    line1: 43.152,
    line2: 44.469,
    line3: 45.786,
    line4: 47.103,
    line5: 48.42,
    line6: 49.737,
    line7: 51.054,
  },
  {
    age: 503,
    line1: 43.157,
    line2: 44.475,
    line3: 45.792,
    line4: 47.109,
    line5: 48.426,
    line6: 49.743,
    line7: 51.061,
  },
  {
    age: 504,
    line1: 43.163,
    line2: 44.481,
    line3: 45.798,
    line4: 47.115,
    line5: 48.433,
    line6: 49.75,
    line7: 51.067,
  },
  {
    age: 505,
    line1: 43.169,
    line2: 44.486,
    line3: 45.804,
    line4: 47.121,
    line5: 48.439,
    line6: 49.756,
    line7: 51.074,
  },
  {
    age: 506,
    line1: 43.174,
    line2: 44.492,
    line3: 45.81,
    line4: 47.127,
    line5: 48.445,
    line6: 49.763,
    line7: 51.08,
  },
  {
    age: 507,
    line1: 43.18,
    line2: 44.498,
    line3: 45.816,
    line4: 47.134,
    line5: 48.451,
    line6: 49.769,
    line7: 51.087,
  },
  {
    age: 508,
    line1: 43.186,
    line2: 44.504,
    line3: 45.822,
    line4: 47.14,
    line5: 48.458,
    line6: 49.776,
    line7: 51.094,
  },
  {
    age: 509,
    line1: 43.191,
    line2: 44.509,
    line3: 45.827,
    line4: 47.146,
    line5: 48.464,
    line6: 49.782,
    line7: 51.1,
  },
  {
    age: 510,
    line1: 43.197,
    line2: 44.515,
    line3: 45.833,
    line4: 47.152,
    line5: 48.47,
    line6: 49.788,
    line7: 51.107,
  },
  {
    age: 511,
    line1: 43.201,
    line2: 44.52,
    line3: 45.839,
    line4: 47.158,
    line5: 48.477,
    line6: 49.796,
    line7: 51.115,
  },
  {
    age: 512,
    line1: 43.206,
    line2: 44.525,
    line3: 45.845,
    line4: 47.164,
    line5: 48.483,
    line6: 49.802,
    line7: 51.121,
  },
  {
    age: 513,
    line1: 43.212,
    line2: 44.531,
    line3: 45.85,
    line4: 47.17,
    line5: 48.489,
    line6: 49.808,
    line7: 51.128,
  },
  {
    age: 514,
    line1: 43.217,
    line2: 44.537,
    line3: 45.856,
    line4: 47.176,
    line5: 48.495,
    line6: 49.815,
    line7: 51.134,
  },
  {
    age: 515,
    line1: 43.223,
    line2: 44.542,
    line3: 45.862,
    line4: 47.182,
    line5: 48.501,
    line6: 49.821,
    line7: 51.141,
  },
  {
    age: 516,
    line1: 43.228,
    line2: 44.548,
    line3: 45.868,
    line4: 47.188,
    line5: 48.508,
    line6: 49.827,
    line7: 51.147,
  },
  {
    age: 517,
    line1: 43.234,
    line2: 44.554,
    line3: 45.874,
    line4: 47.194,
    line5: 48.514,
    line6: 49.834,
    line7: 51.154,
  },
  {
    age: 518,
    line1: 43.239,
    line2: 44.559,
    line3: 45.879,
    line4: 47.2,
    line5: 48.52,
    line6: 49.84,
    line7: 51.16,
  },
  {
    age: 519,
    line1: 43.244,
    line2: 44.565,
    line3: 45.885,
    line4: 47.206,
    line5: 48.526,
    line6: 49.846,
    line7: 51.167,
  },
  {
    age: 520,
    line1: 43.25,
    line2: 44.57,
    line3: 45.891,
    line4: 47.211,
    line5: 48.532,
    line6: 49.852,
    line7: 51.173,
  },
  {
    age: 521,
    line1: 43.255,
    line2: 44.576,
    line3: 45.897,
    line4: 47.217,
    line5: 48.538,
    line6: 49.859,
    line7: 51.179,
  },
  {
    age: 522,
    line1: 43.261,
    line2: 44.582,
    line3: 45.902,
    line4: 47.223,
    line5: 48.544,
    line6: 49.865,
    line7: 51.186,
  },
  {
    age: 523,
    line1: 43.265,
    line2: 44.586,
    line3: 45.908,
    line4: 47.229,
    line5: 48.55,
    line6: 49.872,
    line7: 51.193,
  },
  {
    age: 524,
    line1: 43.27,
    line2: 44.592,
    line3: 45.913,
    line4: 47.235,
    line5: 48.557,
    line6: 49.878,
    line7: 51.2,
  },
  {
    age: 525,
    line1: 43.275,
    line2: 44.597,
    line3: 45.919,
    line4: 47.241,
    line5: 48.562,
    line6: 49.884,
    line7: 51.206,
  },
  {
    age: 526,
    line1: 43.281,
    line2: 44.603,
    line3: 45.925,
    line4: 47.247,
    line5: 48.569,
    line6: 49.891,
    line7: 51.212,
  },
  {
    age: 527,
    line1: 43.286,
    line2: 44.608,
    line3: 45.93,
    line4: 47.252,
    line5: 48.575,
    line6: 49.897,
    line7: 51.219,
  },
  {
    age: 528,
    line1: 43.291,
    line2: 44.614,
    line3: 45.936,
    line4: 47.258,
    line5: 48.58,
    line6: 49.903,
    line7: 51.225,
  },
  {
    age: 529,
    line1: 43.297,
    line2: 44.619,
    line3: 45.942,
    line4: 47.264,
    line5: 48.586,
    line6: 49.909,
    line7: 51.231,
  },
  {
    age: 530,
    line1: 43.302,
    line2: 44.625,
    line3: 45.947,
    line4: 47.27,
    line5: 48.592,
    line6: 49.915,
    line7: 51.238,
  },
  {
    age: 531,
    line1: 43.307,
    line2: 44.63,
    line3: 45.953,
    line4: 47.276,
    line5: 48.598,
    line6: 49.921,
    line7: 51.244,
  },
  {
    age: 532,
    line1: 43.313,
    line2: 44.635,
    line3: 45.958,
    line4: 47.281,
    line5: 48.604,
    line6: 49.927,
    line7: 51.25,
  },
  {
    age: 533,
    line1: 43.318,
    line2: 44.641,
    line3: 45.964,
    line4: 47.287,
    line5: 48.61,
    line6: 49.933,
    line7: 51.256,
  },
  {
    age: 534,
    line1: 43.322,
    line2: 44.645,
    line3: 45.969,
    line4: 47.293,
    line5: 48.617,
    line6: 49.94,
    line7: 51.264,
  },
  {
    age: 535,
    line1: 43.327,
    line2: 44.651,
    line3: 45.975,
    line4: 47.298,
    line5: 48.622,
    line6: 49.946,
    line7: 51.27,
  },
  {
    age: 536,
    line1: 43.332,
    line2: 44.656,
    line3: 45.98,
    line4: 47.304,
    line5: 48.628,
    line6: 49.952,
    line7: 51.276,
  },
  {
    age: 537,
    line1: 43.337,
    line2: 44.661,
    line3: 45.986,
    line4: 47.31,
    line5: 48.634,
    line6: 49.958,
    line7: 51.283,
  },
  {
    age: 538,
    line1: 43.343,
    line2: 44.667,
    line3: 45.991,
    line4: 47.316,
    line5: 48.64,
    line6: 49.964,
    line7: 51.289,
  },
  {
    age: 539,
    line1: 43.348,
    line2: 44.672,
    line3: 45.997,
    line4: 47.321,
    line5: 48.646,
    line6: 49.97,
    line7: 51.295,
  },
  {
    age: 540,
    line1: 43.353,
    line2: 44.678,
    line3: 46.002,
    line4: 47.327,
    line5: 48.652,
    line6: 49.976,
    line7: 51.301,
  },
  {
    age: 541,
    line1: 43.358,
    line2: 44.683,
    line3: 46.008,
    line4: 47.333,
    line5: 48.657,
    line6: 49.982,
    line7: 51.307,
  },
  {
    age: 542,
    line1: 43.363,
    line2: 44.688,
    line3: 46.013,
    line4: 47.338,
    line5: 48.663,
    line6: 49.988,
    line7: 51.313,
  },
  {
    age: 543,
    line1: 43.368,
    line2: 44.693,
    line3: 46.019,
    line4: 47.344,
    line5: 48.669,
    line6: 49.994,
    line7: 51.319,
  },
  {
    age: 544,
    line1: 43.372,
    line2: 44.698,
    line3: 46.024,
    line4: 47.349,
    line5: 48.675,
    line6: 50.001,
    line7: 51.327,
  },
  {
    age: 545,
    line1: 43.377,
    line2: 44.703,
    line3: 46.029,
    line4: 47.355,
    line5: 48.681,
    line6: 50.007,
    line7: 51.333,
  },
  {
    age: 546,
    line1: 43.382,
    line2: 44.708,
    line3: 46.035,
    line4: 47.361,
    line5: 48.687,
    line6: 50.013,
    line7: 51.339,
  },
  {
    age: 547,
    line1: 43.387,
    line2: 44.714,
    line3: 46.04,
    line4: 47.366,
    line5: 48.692,
    line6: 50.019,
    line7: 51.345,
  },
  {
    age: 548,
    line1: 43.393,
    line2: 44.719,
    line3: 46.045,
    line4: 47.372,
    line5: 48.698,
    line6: 50.025,
    line7: 51.351,
  },
  {
    age: 549,
    line1: 43.398,
    line2: 44.724,
    line3: 46.051,
    line4: 47.377,
    line5: 48.704,
    line6: 50.031,
    line7: 51.357,
  },
  {
    age: 550,
    line1: 43.403,
    line2: 44.729,
    line3: 46.056,
    line4: 47.383,
    line5: 48.71,
    line6: 50.036,
    line7: 51.363,
  },
  {
    age: 551,
    line1: 43.408,
    line2: 44.735,
    line3: 46.062,
    line4: 47.388,
    line5: 48.715,
    line6: 50.042,
    line7: 51.369,
  },
  {
    age: 552,
    line1: 43.413,
    line2: 44.74,
    line3: 46.067,
    line4: 47.394,
    line5: 48.721,
    line6: 50.048,
    line7: 51.375,
  },
  {
    age: 553,
    line1: 43.418,
    line2: 44.745,
    line3: 46.072,
    line4: 47.4,
    line5: 48.727,
    line6: 50.054,
    line7: 51.381,
  },
  {
    age: 554,
    line1: 43.423,
    line2: 44.75,
    line3: 46.078,
    line4: 47.405,
    line5: 48.732,
    line6: 50.06,
    line7: 51.387,
  },
  {
    age: 555,
    line1: 43.427,
    line2: 44.755,
    line3: 46.083,
    line4: 47.41,
    line5: 48.738,
    line6: 50.066,
    line7: 51.394,
  },
  {
    age: 556,
    line1: 43.432,
    line2: 44.76,
    line3: 46.088,
    line4: 47.416,
    line5: 48.744,
    line6: 50.072,
    line7: 51.4,
  },
  {
    age: 557,
    line1: 43.437,
    line2: 44.765,
    line3: 46.093,
    line4: 47.422,
    line5: 48.75,
    line6: 50.078,
    line7: 51.406,
  },
  {
    age: 558,
    line1: 43.442,
    line2: 44.77,
    line3: 46.098,
    line4: 47.427,
    line5: 48.755,
    line6: 50.084,
    line7: 51.412,
  },
  {
    age: 559,
    line1: 43.447,
    line2: 44.775,
    line3: 46.104,
    line4: 47.432,
    line5: 48.761,
    line6: 50.09,
    line7: 51.418,
  },
  {
    age: 560,
    line1: 43.452,
    line2: 44.78,
    line3: 46.109,
    line4: 47.438,
    line5: 48.767,
    line6: 50.095,
    line7: 51.424,
  },
  {
    age: 561,
    line1: 43.457,
    line2: 44.785,
    line3: 46.114,
    line4: 47.443,
    line5: 48.772,
    line6: 50.101,
    line7: 51.43,
  },
  {
    age: 562,
    line1: 43.462,
    line2: 44.791,
    line3: 46.12,
    line4: 47.449,
    line5: 48.778,
    line6: 50.107,
    line7: 51.436,
  },
  {
    age: 563,
    line1: 43.467,
    line2: 44.796,
    line3: 46.125,
    line4: 47.454,
    line5: 48.783,
    line6: 50.112,
    line7: 51.442,
  },
  {
    age: 564,
    line1: 43.471,
    line2: 44.801,
    line3: 46.13,
    line4: 47.46,
    line5: 48.789,
    line6: 50.118,
    line7: 51.448,
  },
  {
    age: 565,
    line1: 43.475,
    line2: 44.805,
    line3: 46.135,
    line4: 47.465,
    line5: 48.795,
    line6: 50.125,
    line7: 51.455,
  },
  {
    age: 566,
    line1: 43.48,
    line2: 44.81,
    line3: 46.14,
    line4: 47.47,
    line5: 48.8,
    line6: 50.131,
    line7: 51.461,
  },
  {
    age: 567,
    line1: 43.485,
    line2: 44.815,
    line3: 46.145,
    line4: 47.476,
    line5: 48.806,
    line6: 50.136,
    line7: 51.466,
  },
  {
    age: 568,
    line1: 43.49,
    line2: 44.82,
    line3: 46.151,
    line4: 47.481,
    line5: 48.811,
    line6: 50.142,
    line7: 51.472,
  },
  {
    age: 569,
    line1: 43.495,
    line2: 44.825,
    line3: 46.156,
    line4: 47.486,
    line5: 48.817,
    line6: 50.147,
    line7: 51.478,
  },
  {
    age: 570,
    line1: 43.5,
    line2: 44.83,
    line3: 46.161,
    line4: 47.492,
    line5: 48.822,
    line6: 50.153,
    line7: 51.484,
  },
  {
    age: 571,
    line1: 43.504,
    line2: 44.835,
    line3: 46.166,
    line4: 47.497,
    line5: 48.828,
    line6: 50.159,
    line7: 51.49,
  },
  {
    age: 572,
    line1: 43.509,
    line2: 44.84,
    line3: 46.171,
    line4: 47.502,
    line5: 48.833,
    line6: 50.164,
    line7: 51.495,
  },
  {
    age: 573,
    line1: 43.514,
    line2: 44.845,
    line3: 46.177,
    line4: 47.508,
    line5: 48.839,
    line6: 50.17,
    line7: 51.501,
  },
  {
    age: 574,
    line1: 43.519,
    line2: 44.85,
    line3: 46.182,
    line4: 47.513,
    line5: 48.844,
    line6: 50.176,
    line7: 51.507,
  },
  {
    age: 575,
    line1: 43.522,
    line2: 44.854,
    line3: 46.186,
    line4: 47.518,
    line5: 48.85,
    line6: 50.182,
    line7: 51.514,
  },
  {
    age: 576,
    line1: 43.527,
    line2: 44.859,
    line3: 46.192,
    line4: 47.524,
    line5: 48.856,
    line6: 50.188,
    line7: 51.52,
  },
  {
    age: 577,
    line1: 43.532,
    line2: 44.864,
    line3: 46.197,
    line4: 47.529,
    line5: 48.861,
    line6: 50.193,
    line7: 51.525,
  },
  {
    age: 578,
    line1: 43.537,
    line2: 44.869,
    line3: 46.202,
    line4: 47.534,
    line5: 48.866,
    line6: 50.199,
    line7: 51.531,
  },
  {
    age: 579,
    line1: 43.542,
    line2: 44.874,
    line3: 46.207,
    line4: 47.539,
    line5: 48.872,
    line6: 50.204,
    line7: 51.537,
  },
  {
    age: 580,
    line1: 43.547,
    line2: 44.879,
    line3: 46.212,
    line4: 47.545,
    line5: 48.877,
    line6: 50.21,
    line7: 51.543,
  },
  {
    age: 581,
    line1: 43.551,
    line2: 44.884,
    line3: 46.217,
    line4: 47.55,
    line5: 48.883,
    line6: 50.215,
    line7: 51.548,
  },
  {
    age: 582,
    line1: 43.556,
    line2: 44.889,
    line3: 46.222,
    line4: 47.555,
    line5: 48.888,
    line6: 50.221,
    line7: 51.554,
  },
  {
    age: 583,
    line1: 43.561,
    line2: 44.894,
    line3: 46.227,
    line4: 47.56,
    line5: 48.893,
    line6: 50.227,
    line7: 51.56,
  },
  {
    age: 584,
    line1: 43.564,
    line2: 44.898,
    line3: 46.232,
    line4: 47.566,
    line5: 48.899,
    line6: 50.233,
    line7: 51.567,
  },
  {
    age: 585,
    line1: 43.569,
    line2: 44.903,
    line3: 46.237,
    line4: 47.571,
    line5: 48.905,
    line6: 50.238,
    line7: 51.572,
  },
  {
    age: 586,
    line1: 43.574,
    line2: 44.908,
    line3: 46.242,
    line4: 47.576,
    line5: 48.91,
    line6: 50.244,
    line7: 51.578,
  },
  {
    age: 587,
    line1: 43.579,
    line2: 44.913,
    line3: 46.247,
    line4: 47.581,
    line5: 48.915,
    line6: 50.249,
    line7: 51.584,
  },
  {
    age: 588,
    line1: 43.583,
    line2: 44.918,
    line3: 46.252,
    line4: 47.586,
    line5: 48.921,
    line6: 50.255,
    line7: 51.589,
  },
  {
    age: 589,
    line1: 43.588,
    line2: 44.923,
    line3: 46.257,
    line4: 47.592,
    line5: 48.926,
    line6: 50.26,
    line7: 51.595,
  },
  {
    age: 590,
    line1: 43.593,
    line2: 44.927,
    line3: 46.262,
    line4: 47.597,
    line5: 48.931,
    line6: 50.266,
    line7: 51.6,
  },
  {
    age: 591,
    line1: 43.598,
    line2: 44.932,
    line3: 46.267,
    line4: 47.602,
    line5: 48.937,
    line6: 50.271,
    line7: 51.606,
  },
  {
    age: 592,
    line1: 43.602,
    line2: 44.937,
    line3: 46.272,
    line4: 47.607,
    line5: 48.942,
    line6: 50.277,
    line7: 51.612,
  },
  {
    age: 593,
    line1: 43.605,
    line2: 44.941,
    line3: 46.276,
    line4: 47.612,
    line5: 48.948,
    line6: 50.283,
    line7: 51.619,
  },
  {
    age: 594,
    line1: 43.61,
    line2: 44.946,
    line3: 46.282,
    line4: 47.617,
    line5: 48.953,
    line6: 50.289,
    line7: 51.624,
  },
  {
    age: 595,
    line1: 43.615,
    line2: 44.951,
    line3: 46.286,
    line4: 47.622,
    line5: 48.958,
    line6: 50.294,
    line7: 51.63,
  },
  {
    age: 596,
    line1: 43.62,
    line2: 44.956,
    line3: 46.291,
    line4: 47.627,
    line5: 48.963,
    line6: 50.299,
    line7: 51.635,
  },
  {
    age: 597,
    line1: 43.624,
    line2: 44.96,
    line3: 46.296,
    line4: 47.632,
    line5: 48.969,
    line6: 50.305,
    line7: 51.641,
  },
  {
    age: 598,
    line1: 43.629,
    line2: 44.965,
    line3: 46.301,
    line4: 47.638,
    line5: 48.974,
    line6: 50.31,
    line7: 51.646,
  },
  {
    age: 599,
    line1: 43.634,
    line2: 44.97,
    line3: 46.306,
    line4: 47.643,
    line5: 48.979,
    line6: 50.315,
    line7: 51.652,
  },
  {
    age: 600,
    line1: 43.638,
    line2: 44.975,
    line3: 46.311,
    line4: 47.648,
    line5: 48.984,
    line6: 50.321,
    line7: 51.657,
  },
  {
    age: 601,
    line1: 43.643,
    line2: 44.979,
    line3: 46.316,
    line4: 47.653,
    line5: 48.989,
    line6: 50.326,
    line7: 51.663,
  },
  {
    age: 602,
    line1: 43.647,
    line2: 44.984,
    line3: 46.321,
    line4: 47.658,
    line5: 48.995,
    line6: 50.332,
    line7: 51.668,
  },
  {
    age: 603,
    line1: 43.651,
    line2: 44.988,
    line3: 46.326,
    line4: 47.663,
    line5: 49,
    line6: 50.338,
    line7: 51.675,
  },
  {
    age: 604,
    line1: 43.655,
    line2: 44.993,
    line3: 46.33,
    line4: 47.668,
    line5: 49.006,
    line6: 50.343,
    line7: 51.681,
  },
  {
    age: 605,
    line1: 43.66,
    line2: 44.998,
    line3: 46.335,
    line4: 47.673,
    line5: 49.011,
    line6: 50.348,
    line7: 51.686,
  },
  {
    age: 606,
    line1: 43.665,
    line2: 45.002,
    line3: 46.34,
    line4: 47.678,
    line5: 49.016,
    line6: 50.354,
    line7: 51.692,
  },
  {
    age: 607,
    line1: 43.669,
    line2: 45.007,
    line3: 46.345,
    line4: 47.683,
    line5: 49.021,
    line6: 50.359,
    line7: 51.697,
  },
  {
    age: 608,
    line1: 43.674,
    line2: 45.012,
    line3: 46.35,
    line4: 47.688,
    line5: 49.026,
    line6: 50.364,
    line7: 51.702,
  },
  {
    age: 609,
    line1: 43.678,
    line2: 45.017,
    line3: 46.355,
    line4: 47.693,
    line5: 49.031,
    line6: 50.37,
    line7: 51.708,
  },
  {
    age: 610,
    line1: 43.683,
    line2: 45.021,
    line3: 46.36,
    line4: 47.698,
    line5: 49.037,
    line6: 50.375,
    line7: 51.713,
  },
  {
    age: 611,
    line1: 43.687,
    line2: 45.026,
    line3: 46.365,
    line4: 47.703,
    line5: 49.042,
    line6: 50.38,
    line7: 51.719,
  },
  {
    age: 612,
    line1: 43.691,
    line2: 45.03,
    line3: 46.369,
    line4: 47.708,
    line5: 49.047,
    line6: 50.386,
    line7: 51.726,
  },
  {
    age: 613,
    line1: 43.695,
    line2: 45.034,
    line3: 46.374,
    line4: 47.713,
    line5: 49.052,
    line6: 50.392,
    line7: 51.731,
  },
  {
    age: 614,
    line1: 43.7,
    line2: 45.039,
    line3: 46.379,
    line4: 47.718,
    line5: 49.057,
    line6: 50.397,
    line7: 51.736,
  },
  {
    age: 615,
    line1: 43.704,
    line2: 45.044,
    line3: 46.383,
    line4: 47.723,
    line5: 49.063,
    line6: 50.402,
    line7: 51.742,
  },
  {
    age: 616,
    line1: 43.709,
    line2: 45.049,
    line3: 46.388,
    line4: 47.728,
    line5: 49.068,
    line6: 50.407,
    line7: 51.747,
  },
  {
    age: 617,
    line1: 43.713,
    line2: 45.053,
    line3: 46.393,
    line4: 47.733,
    line5: 49.073,
    line6: 50.413,
    line7: 51.752,
  },
  {
    age: 618,
    line1: 43.718,
    line2: 45.058,
    line3: 46.398,
    line4: 47.738,
    line5: 49.078,
    line6: 50.418,
    line7: 51.758,
  },
  {
    age: 619,
    line1: 43.722,
    line2: 45.063,
    line3: 46.403,
    line4: 47.743,
    line5: 49.083,
    line6: 50.423,
    line7: 51.763,
  },
  {
    age: 620,
    line1: 43.725,
    line2: 45.066,
    line3: 46.407,
    line4: 47.748,
    line5: 49.088,
    line6: 50.429,
    line7: 51.77,
  },
  {
    age: 621,
    line1: 43.73,
    line2: 45.071,
    line3: 46.412,
    line4: 47.753,
    line5: 49.093,
    line6: 50.434,
    line7: 51.775,
  },
  {
    age: 622,
    line1: 43.734,
    line2: 45.075,
    line3: 46.416,
    line4: 47.758,
    line5: 49.099,
    line6: 50.44,
    line7: 51.781,
  },
  {
    age: 623,
    line1: 43.739,
    line2: 45.08,
    line3: 46.421,
    line4: 47.762,
    line5: 49.104,
    line6: 50.445,
    line7: 51.786,
  },
  {
    age: 624,
    line1: 43.743,
    line2: 45.085,
    line3: 46.426,
    line4: 47.767,
    line5: 49.109,
    line6: 50.45,
    line7: 51.791,
  },
  {
    age: 625,
    line1: 43.748,
    line2: 45.089,
    line3: 46.431,
    line4: 47.772,
    line5: 49.114,
    line6: 50.455,
    line7: 51.797,
  },
  {
    age: 626,
    line1: 43.752,
    line2: 45.094,
    line3: 46.436,
    line4: 47.777,
    line5: 49.119,
    line6: 50.46,
    line7: 51.802,
  },
  {
    age: 627,
    line1: 43.757,
    line2: 45.099,
    line3: 46.44,
    line4: 47.782,
    line5: 49.124,
    line6: 50.465,
    line7: 51.807,
  },
  {
    age: 628,
    line1: 43.761,
    line2: 45.103,
    line3: 46.445,
    line4: 47.787,
    line5: 49.129,
    line6: 50.471,
    line7: 51.812,
  },
  {
    age: 629,
    line1: 43.764,
    line2: 45.107,
    line3: 46.449,
    line4: 47.792,
    line5: 49.134,
    line6: 50.477,
    line7: 51.819,
  },
  {
    age: 630,
    line1: 43.769,
    line2: 45.111,
    line3: 46.454,
    line4: 47.796,
    line5: 49.139,
    line6: 50.482,
    line7: 51.824,
  },
  {
    age: 631,
    line1: 43.773,
    line2: 45.116,
    line3: 46.459,
    line4: 47.801,
    line5: 49.144,
    line6: 50.487,
    line7: 51.83,
  },
  {
    age: 632,
    line1: 43.778,
    line2: 45.12,
    line3: 46.463,
    line4: 47.806,
    line5: 49.149,
    line6: 50.492,
    line7: 51.835,
  },
  {
    age: 633,
    line1: 43.782,
    line2: 45.125,
    line3: 46.468,
    line4: 47.811,
    line5: 49.154,
    line6: 50.497,
    line7: 51.84,
  },
  {
    age: 634,
    line1: 43.786,
    line2: 45.13,
    line3: 46.473,
    line4: 47.816,
    line5: 49.159,
    line6: 50.502,
    line7: 51.845,
  },
  {
    age: 635,
    line1: 43.791,
    line2: 45.134,
    line3: 46.477,
    line4: 47.821,
    line5: 49.164,
    line6: 50.507,
    line7: 51.851,
  },
  {
    age: 636,
    line1: 43.795,
    line2: 45.139,
    line3: 46.482,
    line4: 47.826,
    line5: 49.169,
    line6: 50.512,
    line7: 51.856,
  },
  {
    age: 637,
    line1: 43.798,
    line2: 45.142,
    line3: 46.486,
    line4: 47.83,
    line5: 49.174,
    line6: 50.518,
    line7: 51.862,
  },
  {
    age: 638,
    line1: 43.803,
    line2: 45.147,
    line3: 46.491,
    line4: 47.835,
    line5: 49.179,
    line6: 50.523,
    line7: 51.868,
  },
  {
    age: 639,
    line1: 43.807,
    line2: 45.151,
    line3: 46.496,
    line4: 47.84,
    line5: 49.184,
    line6: 50.529,
    line7: 51.873,
  },
  {
    age: 640,
    line1: 43.811,
    line2: 45.156,
    line3: 46.5,
    line4: 47.845,
    line5: 49.189,
    line6: 50.533,
    line7: 51.878,
  },
  {
    age: 641,
    line1: 43.816,
    line2: 45.16,
    line3: 46.505,
    line4: 47.849,
    line5: 49.194,
    line6: 50.539,
    line7: 51.883,
  },
  {
    age: 642,
    line1: 43.82,
    line2: 45.165,
    line3: 46.509,
    line4: 47.854,
    line5: 49.199,
    line6: 50.544,
    line7: 51.888,
  },
  {
    age: 643,
    line1: 43.824,
    line2: 45.169,
    line3: 46.514,
    line4: 47.859,
    line5: 49.204,
    line6: 50.549,
    line7: 51.894,
  },
  {
    age: 644,
    line1: 43.829,
    line2: 45.174,
    line3: 46.519,
    line4: 47.864,
    line5: 49.209,
    line6: 50.554,
    line7: 51.899,
  },
  {
    age: 645,
    line1: 43.833,
    line2: 45.178,
    line3: 46.523,
    line4: 47.868,
    line5: 49.214,
    line6: 50.559,
    line7: 51.904,
  },
  {
    age: 646,
    line1: 43.836,
    line2: 45.182,
    line3: 46.527,
    line4: 47.873,
    line5: 49.219,
    line6: 50.565,
    line7: 51.91,
  },
  {
    age: 647,
    line1: 43.84,
    line2: 45.186,
    line3: 46.532,
    line4: 47.878,
    line5: 49.224,
    line6: 50.57,
    line7: 51.915,
  },
  {
    age: 648,
    line1: 43.845,
    line2: 45.191,
    line3: 46.537,
    line4: 47.883,
    line5: 49.229,
    line6: 50.575,
    line7: 51.921,
  },
  {
    age: 649,
    line1: 43.849,
    line2: 45.195,
    line3: 46.541,
    line4: 47.887,
    line5: 49.234,
    line6: 50.58,
    line7: 51.926,
  },
  {
    age: 650,
    line1: 43.853,
    line2: 45.2,
    line3: 46.546,
    line4: 47.892,
    line5: 49.238,
    line6: 50.585,
    line7: 51.931,
  },
  {
    age: 651,
    line1: 43.858,
    line2: 45.204,
    line3: 46.55,
    line4: 47.897,
    line5: 49.243,
    line6: 50.59,
    line7: 51.936,
  },
  {
    age: 652,
    line1: 43.862,
    line2: 45.208,
    line3: 46.555,
    line4: 47.902,
    line5: 49.248,
    line6: 50.595,
    line7: 51.941,
  },
  {
    age: 653,
    line1: 43.866,
    line2: 45.213,
    line3: 46.56,
    line4: 47.906,
    line5: 49.253,
    line6: 50.599,
    line7: 51.946,
  },
  {
    age: 654,
    line1: 43.869,
    line2: 45.216,
    line3: 46.564,
    line4: 47.911,
    line5: 49.258,
    line6: 50.605,
    line7: 51.953,
  },
  {
    age: 655,
    line1: 43.873,
    line2: 45.221,
    line3: 46.568,
    line4: 47.916,
    line5: 49.263,
    line6: 50.61,
    line7: 51.958,
  },
  {
    age: 656,
    line1: 43.878,
    line2: 45.225,
    line3: 46.573,
    line4: 47.92,
    line5: 49.268,
    line6: 50.615,
    line7: 51.963,
  },
  {
    age: 657,
    line1: 43.882,
    line2: 45.23,
    line3: 46.577,
    line4: 47.925,
    line5: 49.273,
    line6: 50.62,
    line7: 51.968,
  },
  {
    age: 658,
    line1: 43.886,
    line2: 45.234,
    line3: 46.582,
    line4: 47.93,
    line5: 49.277,
    line6: 50.625,
    line7: 51.973,
  },
  {
    age: 659,
    line1: 43.89,
    line2: 45.238,
    line3: 46.586,
    line4: 47.934,
    line5: 49.282,
    line6: 50.63,
    line7: 51.978,
  },
  {
    age: 660,
    line1: 43.895,
    line2: 45.243,
    line3: 46.591,
    line4: 47.939,
    line5: 49.287,
    line6: 50.635,
    line7: 51.983,
  },
  {
    age: 661,
    line1: 43.899,
    line2: 45.247,
    line3: 46.595,
    line4: 47.944,
    line5: 49.292,
    line6: 50.64,
    line7: 51.988,
  },
  {
    age: 662,
    line1: 43.902,
    line2: 45.251,
    line3: 46.599,
    line4: 47.948,
    line5: 49.297,
    line6: 50.646,
    line7: 51.995,
  },
  {
    age: 663,
    line1: 43.906,
    line2: 45.255,
    line3: 46.604,
    line4: 47.953,
    line5: 49.302,
    line6: 50.651,
    line7: 52,
  },
  {
    age: 664,
    line1: 43.91,
    line2: 45.259,
    line3: 46.608,
    line4: 47.957,
    line5: 49.306,
    line6: 50.655,
    line7: 52.005,
  },
  {
    age: 665,
    line1: 43.914,
    line2: 45.264,
    line3: 46.613,
    line4: 47.962,
    line5: 49.311,
    line6: 50.66,
    line7: 52.01,
  },
  {
    age: 666,
    line1: 43.919,
    line2: 45.268,
    line3: 46.617,
    line4: 47.967,
    line5: 49.316,
    line6: 50.665,
    line7: 52.015,
  },
  {
    age: 667,
    line1: 43.923,
    line2: 45.272,
    line3: 46.622,
    line4: 47.971,
    line5: 49.321,
    line6: 50.67,
    line7: 52.02,
  },
  {
    age: 668,
    line1: 43.927,
    line2: 45.277,
    line3: 46.626,
    line4: 47.976,
    line5: 49.325,
    line6: 50.675,
    line7: 52.025,
  },
  {
    age: 669,
    line1: 43.931,
    line2: 45.281,
    line3: 46.631,
    line4: 47.98,
    line5: 49.33,
    line6: 50.68,
    line7: 52.029,
  },
  {
    age: 670,
    line1: 43.934,
    line2: 45.284,
    line3: 46.635,
    line4: 47.985,
    line5: 49.335,
    line6: 50.686,
    line7: 52.036,
  },
  {
    age: 671,
    line1: 43.938,
    line2: 45.289,
    line3: 46.639,
    line4: 47.99,
    line5: 49.34,
    line6: 50.69,
    line7: 52.041,
  },
  {
    age: 672,
    line1: 43.943,
    line2: 45.293,
    line3: 46.644,
    line4: 47.994,
    line5: 49.345,
    line6: 50.695,
    line7: 52.046,
  },
  {
    age: 673,
    line1: 43.947,
    line2: 45.297,
    line3: 46.648,
    line4: 47.999,
    line5: 49.349,
    line6: 50.7,
    line7: 52.051,
  },
  {
    age: 674,
    line1: 43.951,
    line2: 45.302,
    line3: 46.652,
    line4: 48.003,
    line5: 49.354,
    line6: 50.705,
    line7: 52.056,
  },
  {
    age: 675,
    line1: 43.955,
    line2: 45.306,
    line3: 46.657,
    line4: 48.008,
    line5: 49.359,
    line6: 50.71,
    line7: 52.061,
  },
  {
    age: 676,
    line1: 43.959,
    line2: 45.31,
    line3: 46.661,
    line4: 48.012,
    line5: 49.363,
    line6: 50.715,
    line7: 52.066,
  },
  {
    age: 677,
    line1: 43.963,
    line2: 45.315,
    line3: 46.666,
    line4: 48.017,
    line5: 49.368,
    line6: 50.719,
    line7: 52.071,
  },
  {
    age: 678,
    line1: 43.966,
    line2: 45.318,
    line3: 46.67,
    line4: 48.022,
    line5: 49.373,
    line6: 50.725,
    line7: 52.077,
  },
  {
    age: 679,
    line1: 43.97,
    line2: 45.322,
    line3: 46.674,
    line4: 48.026,
    line5: 49.378,
    line6: 50.73,
    line7: 52.082,
  },
  {
    age: 680,
    line1: 43.974,
    line2: 45.326,
    line3: 46.679,
    line4: 48.031,
    line5: 49.383,
    line6: 50.735,
    line7: 52.087,
  },
  {
    age: 681,
    line1: 43.979,
    line2: 45.331,
    line3: 46.683,
    line4: 48.035,
    line5: 49.387,
    line6: 50.739,
    line7: 52.092,
  },
  {
    age: 682,
    line1: 43.983,
    line2: 45.335,
    line3: 46.687,
    line4: 48.04,
    line5: 49.392,
    line6: 50.744,
    line7: 52.097,
  },
  {
    age: 683,
    line1: 43.987,
    line2: 45.339,
    line3: 46.692,
    line4: 48.044,
    line5: 49.397,
    line6: 50.749,
    line7: 52.101,
  },
  {
    age: 684,
    line1: 43.991,
    line2: 45.343,
    line3: 46.696,
    line4: 48.049,
    line5: 49.401,
    line6: 50.754,
    line7: 52.106,
  },
  {
    age: 685,
    line1: 43.995,
    line2: 45.348,
    line3: 46.7,
    line4: 48.053,
    line5: 49.406,
    line6: 50.758,
    line7: 52.111,
  },
  {
    age: 686,
    line1: 43.998,
    line2: 45.351,
    line3: 46.704,
    line4: 48.058,
    line5: 49.411,
    line6: 50.764,
    line7: 52.118,
  },
  {
    age: 687,
    line1: 44.002,
    line2: 45.355,
    line3: 46.709,
    line4: 48.062,
    line5: 49.416,
    line6: 50.769,
    line7: 52.122,
  },
  {
    age: 688,
    line1: 44.006,
    line2: 45.359,
    line3: 46.713,
    line4: 48.067,
    line5: 49.42,
    line6: 50.774,
    line7: 52.127,
  },
  {
    age: 689,
    line1: 44.01,
    line2: 45.364,
    line3: 46.717,
    line4: 48.071,
    line5: 49.425,
    line6: 50.778,
    line7: 52.132,
  },
  {
    age: 690,
    line1: 44.014,
    line2: 45.368,
    line3: 46.722,
    line4: 48.076,
    line5: 49.429,
    line6: 50.783,
    line7: 52.137,
  },
  {
    age: 691,
    line1: 44.018,
    line2: 45.372,
    line3: 46.726,
    line4: 48.08,
    line5: 49.434,
    line6: 50.788,
    line7: 52.142,
  },
  {
    age: 692,
    line1: 44.022,
    line2: 45.376,
    line3: 46.73,
    line4: 48.084,
    line5: 49.438,
    line6: 50.793,
    line7: 52.147,
  },
  {
    age: 693,
    line1: 44.026,
    line2: 45.381,
    line3: 46.735,
    line4: 48.089,
    line5: 49.443,
    line6: 50.797,
    line7: 52.151,
  },
  {
    age: 694,
    line1: 44.029,
    line2: 45.384,
    line3: 46.739,
    line4: 48.093,
    line5: 49.448,
    line6: 50.803,
    line7: 52.158,
  },
  {
    age: 695,
    line1: 44.033,
    line2: 45.388,
    line3: 46.743,
    line4: 48.098,
    line5: 49.453,
    line6: 50.808,
    line7: 52.162,
  },
  {
    age: 696,
    line1: 44.037,
    line2: 45.392,
    line3: 46.747,
    line4: 48.102,
    line5: 49.457,
    line6: 50.812,
    line7: 52.167,
  },
  {
    age: 697,
    line1: 44.041,
    line2: 45.396,
    line3: 46.751,
    line4: 48.107,
    line5: 49.462,
    line6: 50.817,
    line7: 52.172,
  },
  {
    age: 698,
    line1: 44.045,
    line2: 45.4,
    line3: 46.756,
    line4: 48.111,
    line5: 49.466,
    line6: 50.822,
    line7: 52.177,
  },
  {
    age: 699,
    line1: 44.049,
    line2: 45.405,
    line3: 46.76,
    line4: 48.115,
    line5: 49.471,
    line6: 50.826,
    line7: 52.182,
  },
  {
    age: 700,
    line1: 44.053,
    line2: 45.409,
    line3: 46.764,
    line4: 48.12,
    line5: 49.475,
    line6: 50.831,
    line7: 52.186,
  },
  {
    age: 701,
    line1: 44.057,
    line2: 45.413,
    line3: 46.769,
    line4: 48.124,
    line5: 49.48,
    line6: 50.836,
    line7: 52.191,
  },
  {
    age: 702,
    line1: 44.06,
    line2: 45.416,
    line3: 46.772,
    line4: 48.129,
    line5: 49.485,
    line6: 50.841,
    line7: 52.197,
  },
  {
    age: 703,
    line1: 44.064,
    line2: 45.42,
    line3: 46.777,
    line4: 48.133,
    line5: 49.489,
    line6: 50.846,
    line7: 52.202,
  },
  {
    age: 704,
    line1: 44.068,
    line2: 45.424,
    line3: 46.781,
    line4: 48.137,
    line5: 49.494,
    line6: 50.85,
    line7: 52.207,
  },
  {
    age: 705,
    line1: 44.072,
    line2: 45.429,
    line3: 46.785,
    line4: 48.142,
    line5: 49.498,
    line6: 50.855,
    line7: 52.212,
  },
  {
    age: 706,
    line1: 44.076,
    line2: 45.433,
    line3: 46.789,
    line4: 48.146,
    line5: 49.503,
    line6: 50.86,
    line7: 52.216,
  },
  {
    age: 707,
    line1: 44.08,
    line2: 45.437,
    line3: 46.794,
    line4: 48.15,
    line5: 49.507,
    line6: 50.864,
    line7: 52.221,
  },
  {
    age: 708,
    line1: 44.084,
    line2: 45.441,
    line3: 46.798,
    line4: 48.155,
    line5: 49.512,
    line6: 50.869,
    line7: 52.226,
  },
  {
    age: 709,
    line1: 44.086,
    line2: 45.444,
    line3: 46.802,
    line4: 48.159,
    line5: 49.517,
    line6: 50.874,
    line7: 52.232,
  },
  {
    age: 710,
    line1: 44.09,
    line2: 45.448,
    line3: 46.806,
    line4: 48.164,
    line5: 49.521,
    line6: 50.879,
    line7: 52.237,
  },
  {
    age: 711,
    line1: 44.094,
    line2: 45.452,
    line3: 46.81,
    line4: 48.168,
    line5: 49.526,
    line6: 50.884,
    line7: 52.241,
  },
  {
    age: 712,
    line1: 44.098,
    line2: 45.456,
    line3: 46.814,
    line4: 48.172,
    line5: 49.53,
    line6: 50.888,
    line7: 52.246,
  },
  {
    age: 713,
    line1: 44.102,
    line2: 45.46,
    line3: 46.819,
    line4: 48.177,
    line5: 49.535,
    line6: 50.893,
    line7: 52.251,
  },
  {
    age: 714,
    line1: 44.106,
    line2: 45.464,
    line3: 46.823,
    line4: 48.181,
    line5: 49.539,
    line6: 50.897,
    line7: 52.256,
  },
  {
    age: 715,
    line1: 44.11,
    line2: 45.469,
    line3: 46.827,
    line4: 48.185,
    line5: 49.544,
    line6: 50.902,
    line7: 52.26,
  },
  {
    age: 716,
    line1: 44.114,
    line2: 45.473,
    line3: 46.831,
    line4: 48.19,
    line5: 49.548,
    line6: 50.907,
    line7: 52.265,
  },
  {
    age: 717,
    line1: 44.117,
    line2: 45.476,
    line3: 46.835,
    line4: 48.194,
    line5: 49.553,
    line6: 50.912,
    line7: 52.271,
  },
  {
    age: 718,
    line1: 44.121,
    line2: 45.48,
    line3: 46.839,
    line4: 48.198,
    line5: 49.557,
    line6: 50.917,
    line7: 52.276,
  },
  {
    age: 719,
    line1: 44.125,
    line2: 45.484,
    line3: 46.843,
    line4: 48.202,
    line5: 49.562,
    line6: 50.921,
    line7: 52.28,
  },
  {
    age: 720,
    line1: 44.129,
    line2: 45.488,
    line3: 46.847,
    line4: 48.207,
    line5: 49.566,
    line6: 50.926,
    line7: 52.285,
  },
  {
    age: 721,
    line1: 44.132,
    line2: 45.492,
    line3: 46.852,
    line4: 48.211,
    line5: 49.571,
    line6: 50.93,
    line7: 52.29,
  },
  {
    age: 722,
    line1: 44.136,
    line2: 45.496,
    line3: 46.856,
    line4: 48.215,
    line5: 49.575,
    line6: 50.935,
    line7: 52.294,
  },
  {
    age: 723,
    line1: 44.14,
    line2: 45.5,
    line3: 46.86,
    line4: 48.22,
    line5: 49.579,
    line6: 50.939,
    line7: 52.299,
  },
  {
    age: 724,
    line1: 44.144,
    line2: 45.504,
    line3: 46.864,
    line4: 48.224,
    line5: 49.584,
    line6: 50.944,
    line7: 52.304,
  },
  {
    age: 725,
    line1: 44.147,
    line2: 45.507,
    line3: 46.868,
    line4: 48.228,
    line5: 49.589,
    line6: 50.949,
    line7: 52.31,
  },
  {
    age: 726,
    line1: 44.15,
    line2: 45.511,
    line3: 46.872,
    line4: 48.232,
    line5: 49.593,
    line6: 50.954,
    line7: 52.314,
  },
  {
    age: 727,
    line1: 44.154,
    line2: 45.515,
    line3: 46.876,
    line4: 48.237,
    line5: 49.597,
    line6: 50.958,
    line7: 52.319,
  },
  {
    age: 728,
    line1: 44.158,
    line2: 45.519,
    line3: 46.88,
    line4: 48.241,
    line5: 49.602,
    line6: 50.963,
    line7: 52.324,
  },
  {
    age: 729,
    line1: 44.162,
    line2: 45.523,
    line3: 46.884,
    line4: 48.245,
    line5: 49.606,
    line6: 50.967,
    line7: 52.328,
  },
  {
    age: 730,
    line1: 44.166,
    line2: 45.527,
    line3: 46.888,
    line4: 48.249,
    line5: 49.611,
    line6: 50.972,
    line7: 52.333,
  },
];

export const MATURE_GIRLS_HEADCIRCUMFERENCE_LINE_COORDS = [
  {
    age: 0,
    line1: 30.326,
    line2: 31.51,
    line3: 32.694,
    line4: 33.879,
    line5: 35.063,
    line6: 36.247,
    line7: 37.432,
  },
  {
    age: 1,
    line1: 30.429,
    line2: 31.611,
    line3: 32.793,
    line4: 33.975,
    line5: 35.157,
    line6: 36.339,
    line7: 37.521,
  },
  {
    age: 2,
    line1: 30.534,
    line2: 31.713,
    line3: 32.892,
    line4: 34.071,
    line5: 35.251,
    line6: 36.43,
    line7: 37.609,
  },
  {
    age: 3,
    line1: 30.637,
    line2: 31.814,
    line3: 32.991,
    line4: 34.168,
    line5: 35.344,
    line6: 36.521,
    line7: 37.698,
  },
  {
    age: 4,
    line1: 30.742,
    line2: 31.916,
    line3: 33.09,
    line4: 34.264,
    line5: 35.438,
    line6: 36.612,
    line7: 37.786,
  },
  {
    age: 5,
    line1: 30.846,
    line2: 32.018,
    line3: 33.189,
    line4: 34.36,
    line5: 35.532,
    line6: 36.703,
    line7: 37.874,
  },
  {
    age: 6,
    line1: 30.951,
    line2: 32.12,
    line3: 33.288,
    line4: 34.457,
    line5: 35.625,
    line6: 36.793,
    line7: 37.962,
  },
  {
    age: 7,
    line1: 31.055,
    line2: 32.221,
    line3: 33.387,
    line4: 34.553,
    line5: 35.719,
    line6: 36.885,
    line7: 38.05,
  },
  {
    age: 8,
    line1: 31.161,
    line2: 32.324,
    line3: 33.486,
    line4: 34.649,
    line5: 35.812,
    line6: 36.975,
    line7: 38.138,
  },
  {
    age: 9,
    line1: 31.265,
    line2: 32.425,
    line3: 33.585,
    line4: 34.746,
    line5: 35.906,
    line6: 37.066,
    line7: 38.226,
  },
  {
    age: 10,
    line1: 31.371,
    line2: 32.528,
    line3: 33.685,
    line4: 34.842,
    line5: 35.999,
    line6: 37.156,
    line7: 38.313,
  },
  {
    age: 11,
    line1: 31.475,
    line2: 32.629,
    line3: 33.784,
    line4: 34.938,
    line5: 36.093,
    line6: 37.247,
    line7: 38.401,
  },
  {
    age: 12,
    line1: 31.581,
    line2: 32.732,
    line3: 33.883,
    line4: 35.034,
    line5: 36.186,
    line6: 37.337,
    line7: 38.488,
  },
  {
    age: 13,
    line1: 31.686,
    line2: 32.834,
    line3: 33.982,
    line4: 35.131,
    line5: 36.279,
    line6: 37.428,
    line7: 38.576,
  },
  {
    age: 14,
    line1: 31.791,
    line2: 32.937,
    line3: 34.082,
    line4: 35.227,
    line5: 36.372,
    line6: 37.518,
    line7: 38.663,
  },
  {
    age: 15,
    line1: 31.879,
    line2: 33.027,
    line3: 34.174,
    line4: 35.321,
    line5: 36.468,
    line6: 37.616,
    line7: 38.763,
  },
  {
    age: 16,
    line1: 31.966,
    line2: 33.115,
    line3: 34.264,
    line4: 35.413,
    line5: 36.562,
    line6: 37.711,
    line7: 38.86,
  },
  {
    age: 17,
    line1: 32.05,
    line2: 33.201,
    line3: 34.352,
    line4: 35.503,
    line5: 36.654,
    line6: 37.805,
    line7: 38.956,
  },
  {
    age: 18,
    line1: 32.132,
    line2: 33.285,
    line3: 34.438,
    line4: 35.591,
    line5: 36.743,
    line6: 37.896,
    line7: 39.049,
  },
  {
    age: 19,
    line1: 32.213,
    line2: 33.368,
    line3: 34.522,
    line4: 35.677,
    line5: 36.831,
    line6: 37.986,
    line7: 39.14,
  },
  {
    age: 20,
    line1: 32.292,
    line2: 33.448,
    line3: 34.605,
    line4: 35.761,
    line5: 36.917,
    line6: 38.073,
    line7: 39.229,
  },
  {
    age: 21,
    line1: 32.369,
    line2: 33.527,
    line3: 34.685,
    line4: 35.843,
    line5: 37.001,
    line6: 38.159,
    line7: 39.317,
  },
  {
    age: 22,
    line1: 32.445,
    line2: 33.604,
    line3: 34.764,
    line4: 35.924,
    line5: 37.083,
    line6: 38.243,
    line7: 39.403,
  },
  {
    age: 23,
    line1: 32.518,
    line2: 33.68,
    line3: 34.841,
    line4: 36.003,
    line5: 37.164,
    line6: 38.326,
    line7: 39.487,
  },
  {
    age: 24,
    line1: 32.592,
    line2: 33.755,
    line3: 34.917,
    line4: 36.08,
    line5: 37.243,
    line6: 38.406,
    line7: 39.569,
  },
  {
    age: 25,
    line1: 32.663,
    line2: 33.827,
    line3: 34.992,
    line4: 36.156,
    line5: 37.321,
    line6: 38.485,
    line7: 39.65,
  },
  {
    age: 26,
    line1: 32.732,
    line2: 33.898,
    line3: 35.065,
    line4: 36.231,
    line5: 37.397,
    line6: 38.563,
    line7: 39.73,
  },
  {
    age: 27,
    line1: 32.8,
    line2: 33.968,
    line3: 35.136,
    line4: 36.304,
    line5: 37.472,
    line6: 38.64,
    line7: 39.808,
  },
  {
    age: 28,
    line1: 32.868,
    line2: 34.037,
    line3: 35.207,
    line4: 36.376,
    line5: 37.546,
    line6: 38.715,
    line7: 39.885,
  },
  {
    age: 29,
    line1: 32.934,
    line2: 34.105,
    line3: 35.276,
    line4: 36.447,
    line5: 37.618,
    line6: 38.789,
    line7: 39.96,
  },
  {
    age: 30,
    line1: 32.999,
    line2: 34.171,
    line3: 35.344,
    line4: 36.516,
    line5: 37.689,
    line6: 38.861,
    line7: 40.034,
  },
  {
    age: 31,
    line1: 33.063,
    line2: 34.237,
    line3: 35.411,
    line4: 36.585,
    line5: 37.759,
    line6: 38.933,
    line7: 40.107,
  },
  {
    age: 32,
    line1: 33.126,
    line2: 34.301,
    line3: 35.476,
    line4: 36.652,
    line5: 37.827,
    line6: 39.003,
    line7: 40.178,
  },
  {
    age: 33,
    line1: 33.186,
    line2: 34.364,
    line3: 35.541,
    line4: 36.718,
    line5: 37.895,
    line6: 39.072,
    line7: 40.25,
  },
  {
    age: 34,
    line1: 33.248,
    line2: 34.426,
    line3: 35.605,
    line4: 36.783,
    line5: 37.962,
    line6: 39.14,
    line7: 40.319,
  },
  {
    age: 35,
    line1: 33.308,
    line2: 34.488,
    line3: 35.667,
    line4: 36.847,
    line5: 38.027,
    line6: 39.207,
    line7: 40.387,
  },
  {
    age: 36,
    line1: 33.367,
    line2: 34.548,
    line3: 35.729,
    line4: 36.91,
    line5: 38.092,
    line6: 39.273,
    line7: 40.454,
  },
  {
    age: 37,
    line1: 33.424,
    line2: 34.607,
    line3: 35.79,
    line4: 36.973,
    line5: 38.155,
    line6: 39.338,
    line7: 40.521,
  },
  {
    age: 38,
    line1: 33.482,
    line2: 34.666,
    line3: 35.85,
    line4: 37.034,
    line5: 38.218,
    line6: 39.402,
    line7: 40.586,
  },
  {
    age: 39,
    line1: 33.538,
    line2: 34.723,
    line3: 35.909,
    line4: 37.094,
    line5: 38.28,
    line6: 39.466,
    line7: 40.651,
  },
  {
    age: 40,
    line1: 33.594,
    line2: 34.781,
    line3: 35.967,
    line4: 37.154,
    line5: 38.341,
    line6: 39.528,
    line7: 40.714,
  },
  {
    age: 41,
    line1: 33.648,
    line2: 34.837,
    line3: 36.025,
    line4: 37.213,
    line5: 38.401,
    line6: 39.589,
    line7: 40.778,
  },
  {
    age: 42,
    line1: 33.703,
    line2: 34.892,
    line3: 36.082,
    line4: 37.271,
    line5: 38.46,
    line6: 39.65,
    line7: 40.839,
  },
  {
    age: 43,
    line1: 33.756,
    line2: 34.947,
    line3: 36.138,
    line4: 37.328,
    line5: 38.519,
    line6: 39.71,
    line7: 40.901,
  },
  {
    age: 44,
    line1: 33.81,
    line2: 35.001,
    line3: 36.193,
    line4: 37.385,
    line5: 38.577,
    line6: 39.769,
    line7: 40.961,
  },
  {
    age: 45,
    line1: 33.861,
    line2: 35.055,
    line3: 36.248,
    line4: 37.441,
    line5: 38.634,
    line6: 39.828,
    line7: 41.021,
  },
  {
    age: 46,
    line1: 33.912,
    line2: 35.107,
    line3: 36.302,
    line4: 37.496,
    line5: 38.691,
    line6: 39.886,
    line7: 41.08,
  },
  {
    age: 47,
    line1: 33.964,
    line2: 35.16,
    line3: 36.355,
    line4: 37.551,
    line5: 38.747,
    line6: 39.942,
    line7: 41.138,
  },
  {
    age: 48,
    line1: 34.014,
    line2: 35.211,
    line3: 36.408,
    line4: 37.605,
    line5: 38.802,
    line6: 39.999,
    line7: 41.196,
  },
  {
    age: 49,
    line1: 34.064,
    line2: 35.262,
    line3: 36.46,
    line4: 37.658,
    line5: 38.857,
    line6: 40.055,
    line7: 41.253,
  },
  {
    age: 50,
    line1: 34.114,
    line2: 35.313,
    line3: 36.512,
    line4: 37.711,
    line5: 38.91,
    line6: 40.11,
    line7: 41.309,
  },
  {
    age: 51,
    line1: 34.162,
    line2: 35.362,
    line3: 36.563,
    line4: 37.764,
    line5: 38.964,
    line6: 40.165,
    line7: 41.365,
  },
  {
    age: 52,
    line1: 34.21,
    line2: 35.412,
    line3: 36.613,
    line4: 37.815,
    line5: 39.017,
    line6: 40.219,
    line7: 41.421,
  },
  {
    age: 53,
    line1: 34.257,
    line2: 35.46,
    line3: 36.663,
    line4: 37.866,
    line5: 39.069,
    line6: 40.272,
    line7: 41.475,
  },
  {
    age: 54,
    line1: 34.304,
    line2: 35.508,
    line3: 36.713,
    line4: 37.917,
    line5: 39.121,
    line6: 40.325,
    line7: 41.53,
  },
  {
    age: 55,
    line1: 34.352,
    line2: 35.557,
    line3: 36.762,
    line4: 37.967,
    line5: 39.172,
    line6: 40.377,
    line7: 41.582,
  },
  {
    age: 56,
    line1: 34.398,
    line2: 35.604,
    line3: 36.81,
    line4: 38.017,
    line5: 39.223,
    line6: 40.429,
    line7: 41.636,
  },
  {
    age: 57,
    line1: 34.443,
    line2: 35.651,
    line3: 36.858,
    line4: 38.066,
    line5: 39.273,
    line6: 40.481,
    line7: 41.688,
  },
  {
    age: 58,
    line1: 34.489,
    line2: 35.697,
    line3: 36.906,
    line4: 38.114,
    line5: 39.323,
    line6: 40.532,
    line7: 41.74,
  },
  {
    age: 59,
    line1: 34.534,
    line2: 35.743,
    line3: 36.953,
    line4: 38.163,
    line5: 39.373,
    line6: 40.582,
    line7: 41.792,
  },
  {
    age: 60,
    line1: 34.578,
    line2: 35.789,
    line3: 37,
    line4: 38.211,
    line5: 39.421,
    line6: 40.632,
    line7: 41.843,
  },
  {
    age: 61,
    line1: 34.622,
    line2: 35.834,
    line3: 37.046,
    line4: 38.258,
    line5: 39.47,
    line6: 40.682,
    line7: 41.894,
  },
  {
    age: 62,
    line1: 34.666,
    line2: 35.879,
    line3: 37.092,
    line4: 38.305,
    line5: 39.518,
    line6: 40.731,
    line7: 41.944,
  },
  {
    age: 63,
    line1: 34.709,
    line2: 35.923,
    line3: 37.137,
    line4: 38.352,
    line5: 39.566,
    line6: 40.78,
    line7: 41.994,
  },
  {
    age: 64,
    line1: 34.753,
    line2: 35.968,
    line3: 37.183,
    line4: 38.398,
    line5: 39.613,
    line6: 40.828,
    line7: 42.043,
  },
  {
    age: 65,
    line1: 34.796,
    line2: 36.012,
    line3: 37.228,
    line4: 38.444,
    line5: 39.66,
    line6: 40.876,
    line7: 42.092,
  },
  {
    age: 66,
    line1: 34.838,
    line2: 36.055,
    line3: 37.272,
    line4: 38.489,
    line5: 39.706,
    line6: 40.923,
    line7: 42.14,
  },
  {
    age: 67,
    line1: 34.88,
    line2: 36.098,
    line3: 37.316,
    line4: 38.534,
    line5: 39.752,
    line6: 40.97,
    line7: 42.188,
  },
  {
    age: 68,
    line1: 34.922,
    line2: 36.141,
    line3: 37.36,
    line4: 38.579,
    line5: 39.798,
    line6: 41.017,
    line7: 42.236,
  },
  {
    age: 69,
    line1: 34.963,
    line2: 36.183,
    line3: 37.403,
    line4: 38.623,
    line5: 39.843,
    line6: 41.064,
    line7: 42.284,
  },
  {
    age: 70,
    line1: 35.004,
    line2: 36.225,
    line3: 37.446,
    line4: 38.667,
    line5: 39.888,
    line6: 41.11,
    line7: 42.331,
  },
  {
    age: 71,
    line1: 35.044,
    line2: 36.266,
    line3: 37.489,
    line4: 38.711,
    line5: 39.933,
    line6: 41.156,
    line7: 42.378,
  },
  {
    age: 72,
    line1: 35.084,
    line2: 36.307,
    line3: 37.531,
    line4: 38.754,
    line5: 39.978,
    line6: 41.201,
    line7: 42.425,
  },
  {
    age: 73,
    line1: 35.124,
    line2: 36.348,
    line3: 37.573,
    line4: 38.797,
    line5: 40.022,
    line6: 41.246,
    line7: 42.471,
  },
  {
    age: 74,
    line1: 35.164,
    line2: 36.389,
    line3: 37.615,
    line4: 38.84,
    line5: 40.065,
    line6: 41.291,
    line7: 42.516,
  },
  {
    age: 75,
    line1: 35.203,
    line2: 36.43,
    line3: 37.656,
    line4: 38.882,
    line5: 40.109,
    line6: 41.335,
    line7: 42.561,
  },
  {
    age: 76,
    line1: 35.243,
    line2: 36.47,
    line3: 37.697,
    line4: 38.924,
    line5: 40.152,
    line6: 41.379,
    line7: 42.606,
  },
  {
    age: 77,
    line1: 35.281,
    line2: 36.51,
    line3: 37.738,
    line4: 38.966,
    line5: 40.194,
    line6: 41.423,
    line7: 42.651,
  },
  {
    age: 78,
    line1: 35.32,
    line2: 36.549,
    line3: 37.778,
    line4: 39.008,
    line5: 40.237,
    line6: 41.466,
    line7: 42.695,
  },
  {
    age: 79,
    line1: 35.359,
    line2: 36.589,
    line3: 37.819,
    line4: 39.049,
    line5: 40.279,
    line6: 41.509,
    line7: 42.739,
  },
  {
    age: 80,
    line1: 35.397,
    line2: 36.628,
    line3: 37.859,
    line4: 39.09,
    line5: 40.32,
    line6: 41.551,
    line7: 42.782,
  },
  {
    age: 81,
    line1: 35.433,
    line2: 36.666,
    line3: 37.898,
    line4: 39.13,
    line5: 40.362,
    line6: 41.595,
    line7: 42.827,
  },
  {
    age: 82,
    line1: 35.471,
    line2: 36.704,
    line3: 37.937,
    line4: 39.17,
    line5: 40.403,
    line6: 41.637,
    line7: 42.87,
  },
  {
    age: 83,
    line1: 35.509,
    line2: 36.742,
    line3: 37.976,
    line4: 39.21,
    line5: 40.444,
    line6: 41.678,
    line7: 42.912,
  },
  {
    age: 84,
    line1: 35.546,
    line2: 36.78,
    line3: 38.015,
    line4: 39.25,
    line5: 40.485,
    line6: 41.72,
    line7: 42.955,
  },
  {
    age: 85,
    line1: 35.583,
    line2: 36.818,
    line3: 38.054,
    line4: 39.29,
    line5: 40.525,
    line6: 41.761,
    line7: 42.997,
  },
  {
    age: 86,
    line1: 35.619,
    line2: 36.856,
    line3: 38.092,
    line4: 39.329,
    line5: 40.565,
    line6: 41.802,
    line7: 43.038,
  },
  {
    age: 87,
    line1: 35.655,
    line2: 36.892,
    line3: 38.13,
    line4: 39.368,
    line5: 40.605,
    line6: 41.843,
    line7: 43.081,
  },
  {
    age: 88,
    line1: 35.691,
    line2: 36.929,
    line3: 38.168,
    line4: 39.406,
    line5: 40.645,
    line6: 41.883,
    line7: 43.122,
  },
  {
    age: 89,
    line1: 35.727,
    line2: 36.966,
    line3: 38.205,
    line4: 39.445,
    line5: 40.684,
    line6: 41.924,
    line7: 43.163,
  },
  {
    age: 90,
    line1: 35.763,
    line2: 37.003,
    line3: 38.243,
    line4: 39.483,
    line5: 40.723,
    line6: 41.963,
    line7: 43.203,
  },
  {
    age: 91,
    line1: 35.798,
    line2: 37.039,
    line3: 38.28,
    line4: 39.521,
    line5: 40.762,
    line6: 42.003,
    line7: 43.244,
  },
  {
    age: 92,
    line1: 35.832,
    line2: 37.074,
    line3: 38.317,
    line4: 39.559,
    line5: 40.801,
    line6: 42.043,
    line7: 43.285,
  },
  {
    age: 93,
    line1: 35.867,
    line2: 37.11,
    line3: 38.353,
    line4: 39.596,
    line5: 40.839,
    line6: 42.082,
    line7: 43.325,
  },
  {
    age: 94,
    line1: 35.902,
    line2: 37.146,
    line3: 38.39,
    line4: 39.634,
    line5: 40.877,
    line6: 42.121,
    line7: 43.365,
  },
  {
    age: 95,
    line1: 35.937,
    line2: 37.182,
    line3: 38.426,
    line4: 39.67,
    line5: 40.915,
    line6: 42.159,
    line7: 43.404,
  },
  {
    age: 96,
    line1: 35.97,
    line2: 37.216,
    line3: 38.462,
    line4: 39.707,
    line5: 40.953,
    line6: 42.199,
    line7: 43.444,
  },
  {
    age: 97,
    line1: 36.005,
    line2: 37.251,
    line3: 38.497,
    line4: 39.744,
    line5: 40.99,
    line6: 42.237,
    line7: 43.483,
  },
  {
    age: 98,
    line1: 36.039,
    line2: 37.286,
    line3: 38.533,
    line4: 39.78,
    line5: 41.027,
    line6: 42.274,
    line7: 43.522,
  },
  {
    age: 99,
    line1: 36.073,
    line2: 37.321,
    line3: 38.568,
    line4: 39.816,
    line5: 41.064,
    line6: 42.312,
    line7: 43.56,
  },
  {
    age: 100,
    line1: 36.105,
    line2: 37.354,
    line3: 38.603,
    line4: 39.852,
    line5: 41.101,
    line6: 42.35,
    line7: 43.599,
  },
  {
    age: 101,
    line1: 36.139,
    line2: 37.389,
    line3: 38.638,
    line4: 39.888,
    line5: 41.138,
    line6: 42.387,
    line7: 43.637,
  },
  {
    age: 102,
    line1: 36.172,
    line2: 37.423,
    line3: 38.673,
    line4: 39.923,
    line5: 41.174,
    line6: 42.424,
    line7: 43.674,
  },
  {
    age: 103,
    line1: 36.205,
    line2: 37.456,
    line3: 38.707,
    line4: 39.959,
    line5: 41.21,
    line6: 42.461,
    line7: 43.712,
  },
  {
    age: 104,
    line1: 36.237,
    line2: 37.489,
    line3: 38.741,
    line4: 39.994,
    line5: 41.246,
    line6: 42.498,
    line7: 43.75,
  },
  {
    age: 105,
    line1: 36.27,
    line2: 37.523,
    line3: 38.776,
    line4: 40.028,
    line5: 41.281,
    line6: 42.534,
    line7: 43.787,
  },
  {
    age: 106,
    line1: 36.302,
    line2: 37.556,
    line3: 38.809,
    line4: 40.063,
    line5: 41.317,
    line6: 42.57,
    line7: 43.824,
  },
  {
    age: 107,
    line1: 36.333,
    line2: 37.588,
    line3: 38.843,
    line4: 40.097,
    line5: 41.352,
    line6: 42.607,
    line7: 43.861,
  },
  {
    age: 108,
    line1: 36.366,
    line2: 37.621,
    line3: 38.876,
    line4: 40.132,
    line5: 41.387,
    line6: 42.642,
    line7: 43.898,
  },
  {
    age: 109,
    line1: 36.398,
    line2: 37.654,
    line3: 38.91,
    line4: 40.166,
    line5: 41.422,
    line6: 42.678,
    line7: 43.934,
  },
  {
    age: 110,
    line1: 36.428,
    line2: 37.685,
    line3: 38.942,
    line4: 40.199,
    line5: 41.456,
    line6: 42.713,
    line7: 43.971,
  },
  {
    age: 111,
    line1: 36.46,
    line2: 37.718,
    line3: 38.975,
    line4: 40.233,
    line5: 41.491,
    line6: 42.748,
    line7: 44.006,
  },
  {
    age: 112,
    line1: 36.491,
    line2: 37.75,
    line3: 39.008,
    line4: 40.266,
    line5: 41.525,
    line6: 42.783,
    line7: 44.041,
  },
  {
    age: 113,
    line1: 36.521,
    line2: 37.781,
    line3: 39.04,
    line4: 40.3,
    line5: 41.559,
    line6: 42.818,
    line7: 44.078,
  },
  {
    age: 114,
    line1: 36.553,
    line2: 37.813,
    line3: 39.073,
    line4: 40.332,
    line5: 41.592,
    line6: 42.852,
    line7: 44.112,
  },
  {
    age: 115,
    line1: 36.583,
    line2: 37.844,
    line3: 39.105,
    line4: 40.365,
    line5: 41.626,
    line6: 42.887,
    line7: 44.147,
  },
  {
    age: 116,
    line1: 36.613,
    line2: 37.875,
    line3: 39.136,
    line4: 40.398,
    line5: 41.66,
    line6: 42.921,
    line7: 44.183,
  },
  {
    age: 117,
    line1: 36.644,
    line2: 37.906,
    line3: 39.168,
    line4: 40.43,
    line5: 41.693,
    line6: 42.955,
    line7: 44.217,
  },
  {
    age: 118,
    line1: 36.674,
    line2: 37.937,
    line3: 39.2,
    line4: 40.462,
    line5: 41.725,
    line6: 42.988,
    line7: 44.251,
  },
  {
    age: 119,
    line1: 36.703,
    line2: 37.967,
    line3: 39.231,
    line4: 40.495,
    line5: 41.758,
    line6: 43.022,
    line7: 44.286,
  },
  {
    age: 120,
    line1: 36.733,
    line2: 37.998,
    line3: 39.262,
    line4: 40.526,
    line5: 41.791,
    line6: 43.055,
    line7: 44.32,
  },
  {
    age: 121,
    line1: 36.762,
    line2: 38.027,
    line3: 39.293,
    line4: 40.558,
    line5: 41.824,
    line6: 43.089,
    line7: 44.354,
  },
  {
    age: 122,
    line1: 36.792,
    line2: 38.058,
    line3: 39.324,
    line4: 40.59,
    line5: 41.855,
    line6: 43.121,
    line7: 44.387,
  },
  {
    age: 123,
    line1: 36.821,
    line2: 38.088,
    line3: 39.354,
    line4: 40.621,
    line5: 41.887,
    line6: 43.154,
    line7: 44.42,
  },
  {
    age: 124,
    line1: 36.849,
    line2: 38.117,
    line3: 39.384,
    line4: 40.652,
    line5: 41.919,
    line6: 43.187,
    line7: 44.454,
  },
  {
    age: 125,
    line1: 36.879,
    line2: 38.147,
    line3: 39.415,
    line4: 40.683,
    line5: 41.951,
    line6: 43.219,
    line7: 44.487,
  },
  {
    age: 126,
    line1: 36.906,
    line2: 38.176,
    line3: 39.445,
    line4: 40.714,
    line5: 41.983,
    line6: 43.252,
    line7: 44.521,
  },
  {
    age: 127,
    line1: 36.935,
    line2: 38.205,
    line3: 39.475,
    line4: 40.744,
    line5: 42.014,
    line6: 43.283,
    line7: 44.553,
  },
  {
    age: 128,
    line1: 36.964,
    line2: 38.234,
    line3: 39.504,
    line4: 40.775,
    line5: 42.045,
    line6: 43.315,
    line7: 44.585,
  },
  {
    age: 129,
    line1: 36.992,
    line2: 38.263,
    line3: 39.534,
    line4: 40.805,
    line5: 42.076,
    line6: 43.347,
    line7: 44.618,
  },
  {
    age: 130,
    line1: 37.02,
    line2: 38.292,
    line3: 39.563,
    line4: 40.835,
    line5: 42.106,
    line6: 43.378,
    line7: 44.65,
  },
  {
    age: 131,
    line1: 37.047,
    line2: 38.32,
    line3: 39.592,
    line4: 40.865,
    line5: 42.137,
    line6: 43.41,
    line7: 44.682,
  },
  {
    age: 132,
    line1: 37.075,
    line2: 38.348,
    line3: 39.621,
    line4: 40.894,
    line5: 42.167,
    line6: 43.44,
    line7: 44.714,
  },
  {
    age: 133,
    line1: 37.103,
    line2: 38.377,
    line3: 39.65,
    line4: 40.924,
    line5: 42.197,
    line6: 43.471,
    line7: 44.745,
  },
  {
    age: 134,
    line1: 37.13,
    line2: 38.404,
    line3: 39.679,
    line4: 40.953,
    line5: 42.228,
    line6: 43.502,
    line7: 44.777,
  },
  {
    age: 135,
    line1: 37.158,
    line2: 38.432,
    line3: 39.707,
    line4: 40.982,
    line5: 42.257,
    line6: 43.532,
    line7: 44.807,
  },
  {
    age: 136,
    line1: 37.184,
    line2: 38.46,
    line3: 39.736,
    line4: 41.012,
    line5: 42.287,
    line6: 43.563,
    line7: 44.839,
  },
  {
    age: 137,
    line1: 37.211,
    line2: 38.488,
    line3: 39.764,
    line4: 41.04,
    line5: 42.317,
    line6: 43.593,
    line7: 44.869,
  },
  {
    age: 138,
    line1: 37.237,
    line2: 38.515,
    line3: 39.792,
    line4: 41.069,
    line5: 42.346,
    line6: 43.623,
    line7: 44.901,
  },
  {
    age: 139,
    line1: 37.264,
    line2: 38.542,
    line3: 39.82,
    line4: 41.098,
    line5: 42.375,
    line6: 43.653,
    line7: 44.931,
  },
  {
    age: 140,
    line1: 37.291,
    line2: 38.57,
    line3: 39.848,
    line4: 41.126,
    line5: 42.404,
    line6: 43.682,
    line7: 44.96,
  },
  {
    age: 141,
    line1: 37.317,
    line2: 38.596,
    line3: 39.875,
    line4: 41.154,
    line5: 42.433,
    line6: 43.712,
    line7: 44.991,
  },
  {
    age: 142,
    line1: 37.344,
    line2: 38.623,
    line3: 39.903,
    line4: 41.182,
    line5: 42.462,
    line6: 43.741,
    line7: 45.021,
  },
  {
    age: 143,
    line1: 37.369,
    line2: 38.649,
    line3: 39.93,
    line4: 41.21,
    line5: 42.49,
    line6: 43.771,
    line7: 45.051,
  },
  {
    age: 144,
    line1: 37.395,
    line2: 38.676,
    line3: 39.957,
    line4: 41.238,
    line5: 42.519,
    line6: 43.799,
    line7: 45.08,
  },
  {
    age: 145,
    line1: 37.42,
    line2: 38.702,
    line3: 39.984,
    line4: 41.265,
    line5: 42.547,
    line6: 43.829,
    line7: 45.11,
  },
  {
    age: 146,
    line1: 37.446,
    line2: 38.728,
    line3: 40.011,
    line4: 41.293,
    line5: 42.575,
    line6: 43.857,
    line7: 45.139,
  },
  {
    age: 147,
    line1: 37.471,
    line2: 38.754,
    line3: 40.037,
    line4: 41.32,
    line5: 42.603,
    line6: 43.886,
    line7: 45.169,
  },
  {
    age: 148,
    line1: 37.497,
    line2: 38.78,
    line3: 40.064,
    line4: 41.347,
    line5: 42.631,
    line6: 43.914,
    line7: 45.197,
  },
  {
    age: 149,
    line1: 37.521,
    line2: 38.806,
    line3: 40.09,
    line4: 41.374,
    line5: 42.658,
    line6: 43.943,
    line7: 45.227,
  },
  {
    age: 150,
    line1: 37.547,
    line2: 38.832,
    line3: 40.116,
    line4: 41.401,
    line5: 42.686,
    line6: 43.97,
    line7: 45.255,
  },
  {
    age: 151,
    line1: 37.571,
    line2: 38.857,
    line3: 40.142,
    line4: 41.428,
    line5: 42.713,
    line6: 43.998,
    line7: 45.284,
  },
  {
    age: 152,
    line1: 37.596,
    line2: 38.882,
    line3: 40.168,
    line4: 41.454,
    line5: 42.74,
    line6: 44.026,
    line7: 45.312,
  },
  {
    age: 153,
    line1: 37.62,
    line2: 38.907,
    line3: 40.194,
    line4: 41.48,
    line5: 42.767,
    line6: 44.054,
    line7: 45.341,
  },
  {
    age: 154,
    line1: 37.645,
    line2: 38.932,
    line3: 40.219,
    line4: 41.507,
    line5: 42.794,
    line6: 44.081,
    line7: 45.368,
  },
  {
    age: 155,
    line1: 37.669,
    line2: 38.957,
    line3: 40.245,
    line4: 41.533,
    line5: 42.821,
    line6: 44.109,
    line7: 45.396,
  },
  {
    age: 156,
    line1: 37.694,
    line2: 38.982,
    line3: 40.27,
    line4: 41.559,
    line5: 42.847,
    line6: 44.135,
    line7: 45.424,
  },
  {
    age: 157,
    line1: 37.718,
    line2: 39.007,
    line3: 40.296,
    line4: 41.584,
    line5: 42.873,
    line6: 44.162,
    line7: 45.451,
  },
  {
    age: 158,
    line1: 37.742,
    line2: 39.031,
    line3: 40.321,
    line4: 41.61,
    line5: 42.899,
    line6: 44.189,
    line7: 45.478,
  },
  {
    age: 159,
    line1: 37.766,
    line2: 39.056,
    line3: 40.346,
    line4: 41.636,
    line5: 42.925,
    line6: 44.215,
    line7: 45.505,
  },
  {
    age: 160,
    line1: 37.789,
    line2: 39.08,
    line3: 40.37,
    line4: 41.661,
    line5: 42.952,
    line6: 44.242,
    line7: 45.533,
  },
  {
    age: 161,
    line1: 37.812,
    line2: 39.103,
    line3: 40.395,
    line4: 41.686,
    line5: 42.978,
    line6: 44.269,
    line7: 45.56,
  },
  {
    age: 162,
    line1: 37.836,
    line2: 39.128,
    line3: 40.419,
    line4: 41.711,
    line5: 43.003,
    line6: 44.295,
    line7: 45.587,
  },
  {
    age: 163,
    line1: 37.858,
    line2: 39.151,
    line3: 40.444,
    line4: 41.736,
    line5: 43.029,
    line6: 44.321,
    line7: 45.614,
  },
  {
    age: 164,
    line1: 37.882,
    line2: 39.175,
    line3: 40.468,
    line4: 41.761,
    line5: 43.054,
    line6: 44.347,
    line7: 45.64,
  },
  {
    age: 165,
    line1: 37.905,
    line2: 39.198,
    line3: 40.492,
    line4: 41.786,
    line5: 43.079,
    line6: 44.373,
    line7: 45.667,
  },
  {
    age: 166,
    line1: 37.928,
    line2: 39.222,
    line3: 40.516,
    line4: 41.81,
    line5: 43.104,
    line6: 44.398,
    line7: 45.692,
  },
  {
    age: 167,
    line1: 37.95,
    line2: 39.245,
    line3: 40.54,
    line4: 41.835,
    line5: 43.129,
    line6: 44.424,
    line7: 45.719,
  },
  {
    age: 168,
    line1: 37.974,
    line2: 39.269,
    line3: 40.564,
    line4: 41.859,
    line5: 43.154,
    line6: 44.449,
    line7: 45.744,
  },
  {
    age: 169,
    line1: 37.996,
    line2: 39.291,
    line3: 40.587,
    line4: 41.883,
    line5: 43.179,
    line6: 44.475,
    line7: 45.771,
  },
  {
    age: 170,
    line1: 38.019,
    line2: 39.315,
    line3: 40.611,
    line4: 41.907,
    line5: 43.203,
    line6: 44.499,
    line7: 45.796,
  },
  {
    age: 171,
    line1: 38.04,
    line2: 39.337,
    line3: 40.634,
    line4: 41.931,
    line5: 43.228,
    line6: 44.525,
    line7: 45.822,
  },
  {
    age: 172,
    line1: 38.063,
    line2: 39.36,
    line3: 40.658,
    line4: 41.955,
    line5: 43.252,
    line6: 44.549,
    line7: 45.847,
  },
  {
    age: 173,
    line1: 38.084,
    line2: 39.382,
    line3: 40.68,
    line4: 41.978,
    line5: 43.276,
    line6: 44.574,
    line7: 45.872,
  },
  {
    age: 174,
    line1: 38.107,
    line2: 39.405,
    line3: 40.704,
    line4: 42.002,
    line5: 43.3,
    line6: 44.598,
    line7: 45.897,
  },
  {
    age: 175,
    line1: 38.128,
    line2: 39.427,
    line3: 40.726,
    line4: 42.025,
    line5: 43.324,
    line6: 44.623,
    line7: 45.922,
  },
  {
    age: 176,
    line1: 38.151,
    line2: 39.45,
    line3: 40.749,
    line4: 42.048,
    line5: 43.348,
    line6: 44.647,
    line7: 45.946,
  },
  {
    age: 177,
    line1: 38.172,
    line2: 39.472,
    line3: 40.772,
    line4: 42.072,
    line5: 43.372,
    line6: 44.672,
    line7: 45.972,
  },
  {
    age: 178,
    line1: 38.194,
    line2: 39.494,
    line3: 40.794,
    line4: 42.095,
    line5: 43.395,
    line6: 44.695,
    line7: 45.996,
  },
  {
    age: 179,
    line1: 38.215,
    line2: 39.516,
    line3: 40.817,
    line4: 42.118,
    line5: 43.419,
    line6: 44.72,
    line7: 46.021,
  },
  {
    age: 180,
    line1: 38.235,
    line2: 39.537,
    line3: 40.839,
    line4: 42.14,
    line5: 43.442,
    line6: 44.744,
    line7: 46.045,
  },
  {
    age: 181,
    line1: 38.257,
    line2: 39.559,
    line3: 40.861,
    line4: 42.163,
    line5: 43.465,
    line6: 44.767,
    line7: 46.069,
  },
  {
    age: 182,
    line1: 38.277,
    line2: 39.58,
    line3: 40.883,
    line4: 42.186,
    line5: 43.488,
    line6: 44.791,
    line7: 46.094,
  },
  {
    age: 183,
    line1: 38.299,
    line2: 39.602,
    line3: 40.905,
    line4: 42.208,
    line5: 43.511,
    line6: 44.814,
    line7: 46.117,
  },
  {
    age: 184,
    line1: 38.319,
    line2: 39.623,
    line3: 40.927,
    line4: 42.23,
    line5: 43.534,
    line6: 44.837,
    line7: 46.141,
  },
  {
    age: 185,
    line1: 38.341,
    line2: 39.644,
    line3: 40.948,
    line4: 42.252,
    line5: 43.556,
    line6: 44.86,
    line7: 46.164,
  },
  {
    age: 186,
    line1: 38.361,
    line2: 39.665,
    line3: 40.97,
    line4: 42.274,
    line5: 43.579,
    line6: 44.884,
    line7: 46.188,
  },
  {
    age: 187,
    line1: 38.382,
    line2: 39.687,
    line3: 40.991,
    line4: 42.296,
    line5: 43.601,
    line6: 44.906,
    line7: 46.211,
  },
  {
    age: 188,
    line1: 38.402,
    line2: 39.707,
    line3: 41.013,
    line4: 42.318,
    line5: 43.624,
    line6: 44.929,
    line7: 46.235,
  },
  {
    age: 189,
    line1: 38.421,
    line2: 39.727,
    line3: 41.034,
    line4: 42.34,
    line5: 43.646,
    line6: 44.952,
    line7: 46.258,
  },
  {
    age: 190,
    line1: 38.442,
    line2: 39.749,
    line3: 41.055,
    line4: 42.361,
    line5: 43.668,
    line6: 44.974,
    line7: 46.281,
  },
  {
    age: 191,
    line1: 38.462,
    line2: 39.769,
    line3: 41.076,
    line4: 42.383,
    line5: 43.69,
    line6: 44.997,
    line7: 46.304,
  },
  {
    age: 192,
    line1: 38.482,
    line2: 39.79,
    line3: 41.097,
    line4: 42.404,
    line5: 43.712,
    line6: 45.019,
    line7: 46.326,
  },
  {
    age: 193,
    line1: 38.502,
    line2: 39.81,
    line3: 41.118,
    line4: 42.426,
    line5: 43.733,
    line6: 45.041,
    line7: 46.349,
  },
  {
    age: 194,
    line1: 38.522,
    line2: 39.83,
    line3: 41.138,
    line4: 42.447,
    line5: 43.755,
    line6: 45.063,
    line7: 46.371,
  },
  {
    age: 195,
    line1: 38.541,
    line2: 39.85,
    line3: 41.159,
    line4: 42.468,
    line5: 43.776,
    line6: 45.085,
    line7: 46.394,
  },
  {
    age: 196,
    line1: 38.56,
    line2: 39.87,
    line3: 41.179,
    line4: 42.488,
    line5: 43.798,
    line6: 45.107,
    line7: 46.417,
  },
  {
    age: 197,
    line1: 38.58,
    line2: 39.89,
    line3: 41.2,
    line4: 42.509,
    line5: 43.819,
    line6: 45.129,
    line7: 46.438,
  },
  {
    age: 198,
    line1: 38.599,
    line2: 39.909,
    line3: 41.22,
    line4: 42.53,
    line5: 43.84,
    line6: 45.151,
    line7: 46.461,
  },
  {
    age: 199,
    line1: 38.619,
    line2: 39.929,
    line3: 41.24,
    line4: 42.551,
    line5: 43.861,
    line6: 45.172,
    line7: 46.482,
  },
  {
    age: 200,
    line1: 38.638,
    line2: 39.949,
    line3: 41.26,
    line4: 42.571,
    line5: 43.882,
    line6: 45.193,
    line7: 46.505,
  },
  {
    age: 201,
    line1: 38.657,
    line2: 39.969,
    line3: 41.28,
    line4: 42.592,
    line5: 43.903,
    line6: 45.214,
    line7: 46.526,
  },
  {
    age: 202,
    line1: 38.676,
    line2: 39.988,
    line3: 41.3,
    line4: 42.612,
    line5: 43.924,
    line6: 45.236,
    line7: 46.548,
  },
  {
    age: 203,
    line1: 38.694,
    line2: 40.007,
    line3: 41.319,
    line4: 42.632,
    line5: 43.945,
    line6: 45.257,
    line7: 46.57,
  },
  {
    age: 204,
    line1: 38.713,
    line2: 40.026,
    line3: 41.339,
    line4: 42.652,
    line5: 43.965,
    line6: 45.278,
    line7: 46.59,
  },
  {
    age: 205,
    line1: 38.732,
    line2: 40.045,
    line3: 41.358,
    line4: 42.672,
    line5: 43.985,
    line6: 45.299,
    line7: 46.612,
  },
  {
    age: 206,
    line1: 38.751,
    line2: 40.064,
    line3: 41.378,
    line4: 42.692,
    line5: 44.005,
    line6: 45.319,
    line7: 46.633,
  },
  {
    age: 207,
    line1: 38.769,
    line2: 40.083,
    line3: 41.397,
    line4: 42.712,
    line5: 44.026,
    line6: 45.34,
    line7: 46.654,
  },
  {
    age: 208,
    line1: 38.787,
    line2: 40.101,
    line3: 41.416,
    line4: 42.731,
    line5: 44.046,
    line6: 45.361,
    line7: 46.676,
  },
  {
    age: 209,
    line1: 38.806,
    line2: 40.121,
    line3: 41.436,
    line4: 42.751,
    line5: 44.066,
    line6: 45.381,
    line7: 46.696,
  },
  {
    age: 210,
    line1: 38.823,
    line2: 40.139,
    line3: 41.454,
    line4: 42.77,
    line5: 44.086,
    line6: 45.401,
    line7: 46.717,
  },
  {
    age: 211,
    line1: 38.842,
    line2: 40.158,
    line3: 41.474,
    line4: 42.789,
    line5: 44.105,
    line6: 45.421,
    line7: 46.737,
  },
  {
    age: 212,
    line1: 38.86,
    line2: 40.176,
    line3: 41.492,
    line4: 42.809,
    line5: 44.125,
    line6: 45.441,
    line7: 46.758,
  },
  {
    age: 213,
    line1: 38.877,
    line2: 40.194,
    line3: 41.511,
    line4: 42.828,
    line5: 44.145,
    line6: 45.462,
    line7: 46.779,
  },
  {
    age: 214,
    line1: 38.896,
    line2: 40.213,
    line3: 41.53,
    line4: 42.847,
    line5: 44.164,
    line6: 45.481,
    line7: 46.798,
  },
  {
    age: 215,
    line1: 38.913,
    line2: 40.23,
    line3: 41.548,
    line4: 42.866,
    line5: 44.183,
    line6: 45.501,
    line7: 46.819,
  },
  {
    age: 216,
    line1: 38.931,
    line2: 40.249,
    line3: 41.567,
    line4: 42.885,
    line5: 44.202,
    line6: 45.52,
    line7: 46.838,
  },
  {
    age: 217,
    line1: 38.948,
    line2: 40.267,
    line3: 41.585,
    line4: 42.903,
    line5: 44.222,
    line6: 45.54,
    line7: 46.859,
  },
  {
    age: 218,
    line1: 38.965,
    line2: 40.284,
    line3: 41.603,
    line4: 42.922,
    line5: 44.241,
    line6: 45.56,
    line7: 46.879,
  },
  {
    age: 219,
    line1: 38.983,
    line2: 40.302,
    line3: 41.621,
    line4: 42.941,
    line5: 44.26,
    line6: 45.579,
    line7: 46.898,
  },
  {
    age: 220,
    line1: 39,
    line2: 40.32,
    line3: 41.639,
    line4: 42.959,
    line5: 44.279,
    line6: 45.599,
    line7: 46.918,
  },
  {
    age: 221,
    line1: 39.017,
    line2: 40.337,
    line3: 41.657,
    line4: 42.977,
    line5: 44.298,
    line6: 45.618,
    line7: 46.938,
  },
  {
    age: 222,
    line1: 39.035,
    line2: 40.355,
    line3: 41.675,
    line4: 42.996,
    line5: 44.316,
    line6: 45.636,
    line7: 46.957,
  },
  {
    age: 223,
    line1: 39.051,
    line2: 40.372,
    line3: 41.693,
    line4: 43.014,
    line5: 44.335,
    line6: 45.656,
    line7: 46.977,
  },
  {
    age: 224,
    line1: 39.069,
    line2: 40.39,
    line3: 41.711,
    line4: 43.032,
    line5: 44.353,
    line6: 45.674,
    line7: 46.995,
  },
  {
    age: 225,
    line1: 39.085,
    line2: 40.407,
    line3: 41.728,
    line4: 43.05,
    line5: 44.372,
    line6: 45.693,
    line7: 47.015,
  },
  {
    age: 226,
    line1: 39.101,
    line2: 40.424,
    line3: 41.746,
    line4: 43.068,
    line5: 44.39,
    line6: 45.712,
    line7: 47.034,
  },
  {
    age: 227,
    line1: 39.119,
    line2: 40.441,
    line3: 41.763,
    line4: 43.086,
    line5: 44.408,
    line6: 45.73,
    line7: 47.053,
  },
  {
    age: 228,
    line1: 39.135,
    line2: 40.458,
    line3: 41.781,
    line4: 43.103,
    line5: 44.426,
    line6: 45.749,
    line7: 47.072,
  },
  {
    age: 229,
    line1: 39.151,
    line2: 40.474,
    line3: 41.798,
    line4: 43.121,
    line5: 44.444,
    line6: 45.768,
    line7: 47.091,
  },
  {
    age: 230,
    line1: 39.168,
    line2: 40.492,
    line3: 41.815,
    line4: 43.139,
    line5: 44.462,
    line6: 45.786,
    line7: 47.109,
  },
  {
    age: 231,
    line1: 39.184,
    line2: 40.508,
    line3: 41.832,
    line4: 43.156,
    line5: 44.48,
    line6: 45.804,
    line7: 47.128,
  },
  {
    age: 232,
    line1: 39.201,
    line2: 40.525,
    line3: 41.849,
    line4: 43.173,
    line5: 44.498,
    line6: 45.822,
    line7: 47.146,
  },
  {
    age: 233,
    line1: 39.217,
    line2: 40.541,
    line3: 41.866,
    line4: 43.191,
    line5: 44.515,
    line6: 45.84,
    line7: 47.165,
  },
  {
    age: 234,
    line1: 39.232,
    line2: 40.558,
    line3: 41.883,
    line4: 43.208,
    line5: 44.533,
    line6: 45.858,
    line7: 47.183,
  },
  {
    age: 235,
    line1: 39.249,
    line2: 40.574,
    line3: 41.9,
    line4: 43.225,
    line5: 44.55,
    line6: 45.876,
    line7: 47.201,
  },
  {
    age: 236,
    line1: 39.265,
    line2: 40.59,
    line3: 41.916,
    line4: 43.242,
    line5: 44.568,
    line6: 45.894,
    line7: 47.22,
  },
  {
    age: 237,
    line1: 39.28,
    line2: 40.606,
    line3: 41.933,
    line4: 43.259,
    line5: 44.585,
    line6: 45.912,
    line7: 47.238,
  },
  {
    age: 238,
    line1: 39.297,
    line2: 40.623,
    line3: 41.949,
    line4: 43.276,
    line5: 44.602,
    line6: 45.929,
    line7: 47.255,
  },
  {
    age: 239,
    line1: 39.312,
    line2: 40.639,
    line3: 41.966,
    line4: 43.293,
    line5: 44.62,
    line6: 45.946,
    line7: 47.273,
  },
  {
    age: 240,
    line1: 39.327,
    line2: 40.654,
    line3: 41.982,
    line4: 43.309,
    line5: 44.637,
    line6: 45.964,
    line7: 47.292,
  },
  {
    age: 241,
    line1: 39.343,
    line2: 40.671,
    line3: 41.998,
    line4: 43.326,
    line5: 44.653,
    line6: 45.981,
    line7: 47.308,
  },
  {
    age: 242,
    line1: 39.358,
    line2: 40.686,
    line3: 42.014,
    line4: 43.342,
    line5: 44.67,
    line6: 45.998,
    line7: 47.326,
  },
  {
    age: 243,
    line1: 39.375,
    line2: 40.703,
    line3: 42.031,
    line4: 43.359,
    line5: 44.687,
    line6: 46.015,
    line7: 47.343,
  },
  {
    age: 244,
    line1: 39.39,
    line2: 40.718,
    line3: 42.047,
    line4: 43.375,
    line5: 44.704,
    line6: 46.032,
    line7: 47.361,
  },
  {
    age: 245,
    line1: 39.404,
    line2: 40.733,
    line3: 42.062,
    line4: 43.392,
    line5: 44.721,
    line6: 46.05,
    line7: 47.379,
  },
  {
    age: 246,
    line1: 39.42,
    line2: 40.749,
    line3: 42.079,
    line4: 43.408,
    line5: 44.737,
    line6: 46.066,
    line7: 47.395,
  },
  {
    age: 247,
    line1: 39.435,
    line2: 40.765,
    line3: 42.094,
    line4: 43.424,
    line5: 44.754,
    line6: 46.083,
    line7: 47.413,
  },
  {
    age: 248,
    line1: 39.45,
    line2: 40.78,
    line3: 42.11,
    line4: 43.44,
    line5: 44.77,
    line6: 46.1,
    line7: 47.43,
  },
  {
    age: 249,
    line1: 39.465,
    line2: 40.796,
    line3: 42.126,
    line4: 43.456,
    line5: 44.786,
    line6: 46.116,
    line7: 47.446,
  },
  {
    age: 250,
    line1: 39.48,
    line2: 40.81,
    line3: 42.141,
    line4: 43.472,
    line5: 44.802,
    line6: 46.133,
    line7: 47.464,
  },
  {
    age: 251,
    line1: 39.494,
    line2: 40.825,
    line3: 42.156,
    line4: 43.488,
    line5: 44.819,
    line6: 46.15,
    line7: 47.481,
  },
  {
    age: 252,
    line1: 39.51,
    line2: 40.841,
    line3: 42.172,
    line4: 43.503,
    line5: 44.835,
    line6: 46.166,
    line7: 47.497,
  },
  {
    age: 253,
    line1: 39.524,
    line2: 40.856,
    line3: 42.187,
    line4: 43.519,
    line5: 44.851,
    line6: 46.182,
    line7: 47.514,
  },
  {
    age: 254,
    line1: 39.538,
    line2: 40.87,
    line3: 42.202,
    line4: 43.534,
    line5: 44.867,
    line6: 46.199,
    line7: 47.531,
  },
  {
    age: 255,
    line1: 39.553,
    line2: 40.886,
    line3: 42.218,
    line4: 43.55,
    line5: 44.882,
    line6: 46.214,
    line7: 47.547,
  },
  {
    age: 256,
    line1: 39.567,
    line2: 40.9,
    line3: 42.233,
    line4: 43.565,
    line5: 44.898,
    line6: 46.231,
    line7: 47.563,
  },
  {
    age: 257,
    line1: 39.581,
    line2: 40.915,
    line3: 42.248,
    line4: 43.581,
    line5: 44.914,
    line6: 46.247,
    line7: 47.58,
  },
  {
    age: 258,
    line1: 39.597,
    line2: 40.93,
    line3: 42.263,
    line4: 43.596,
    line5: 44.929,
    line6: 46.262,
    line7: 47.596,
  },
  {
    age: 259,
    line1: 39.61,
    line2: 40.944,
    line3: 42.278,
    line4: 43.611,
    line5: 44.945,
    line6: 46.279,
    line7: 47.612,
  },
  {
    age: 260,
    line1: 39.624,
    line2: 40.958,
    line3: 42.292,
    line4: 43.626,
    line5: 44.96,
    line6: 46.295,
    line7: 47.629,
  },
  {
    age: 261,
    line1: 39.639,
    line2: 40.973,
    line3: 42.307,
    line4: 43.642,
    line5: 44.976,
    line6: 46.31,
    line7: 47.644,
  },
  {
    age: 262,
    line1: 39.653,
    line2: 40.987,
    line3: 42.322,
    line4: 43.656,
    line5: 44.991,
    line6: 46.326,
    line7: 47.66,
  },
  {
    age: 263,
    line1: 39.666,
    line2: 41.001,
    line3: 42.336,
    line4: 43.671,
    line5: 45.006,
    line6: 46.341,
    line7: 47.677,
  },
  {
    age: 264,
    line1: 39.681,
    line2: 41.016,
    line3: 42.351,
    line4: 43.686,
    line5: 45.021,
    line6: 46.356,
    line7: 47.691,
  },
  {
    age: 265,
    line1: 39.694,
    line2: 41.03,
    line3: 42.365,
    line4: 43.701,
    line5: 45.037,
    line6: 46.372,
    line7: 47.708,
  },
  {
    age: 266,
    line1: 39.708,
    line2: 41.044,
    line3: 42.38,
    line4: 43.716,
    line5: 45.052,
    line6: 46.388,
    line7: 47.724,
  },
  {
    age: 267,
    line1: 39.722,
    line2: 41.058,
    line3: 42.394,
    line4: 43.73,
    line5: 45.066,
    line6: 46.402,
    line7: 47.738,
  },
  {
    age: 268,
    line1: 39.736,
    line2: 41.072,
    line3: 42.408,
    line4: 43.745,
    line5: 45.081,
    line6: 46.418,
    line7: 47.754,
  },
  {
    age: 269,
    line1: 39.749,
    line2: 41.086,
    line3: 42.423,
    line4: 43.759,
    line5: 45.096,
    line6: 46.433,
    line7: 47.77,
  },
  {
    age: 270,
    line1: 39.763,
    line2: 41.1,
    line3: 42.437,
    line4: 43.774,
    line5: 45.111,
    line6: 46.448,
    line7: 47.784,
  },
  {
    age: 271,
    line1: 39.776,
    line2: 41.114,
    line3: 42.451,
    line4: 43.788,
    line5: 45.125,
    line6: 46.463,
    line7: 47.8,
  },
  {
    age: 272,
    line1: 39.789,
    line2: 41.127,
    line3: 42.465,
    line4: 43.802,
    line5: 45.14,
    line6: 46.478,
    line7: 47.816,
  },
  {
    age: 273,
    line1: 39.804,
    line2: 41.141,
    line3: 42.479,
    line4: 43.817,
    line5: 45.154,
    line6: 46.492,
    line7: 47.83,
  },
  {
    age: 274,
    line1: 39.816,
    line2: 41.155,
    line3: 42.493,
    line4: 43.831,
    line5: 45.169,
    line6: 46.507,
    line7: 47.845,
  },
  {
    age: 275,
    line1: 39.829,
    line2: 41.168,
    line3: 42.506,
    line4: 43.845,
    line5: 45.184,
    line6: 46.522,
    line7: 47.861,
  },
  {
    age: 276,
    line1: 39.843,
    line2: 41.182,
    line3: 42.52,
    line4: 43.859,
    line5: 45.198,
    line6: 46.536,
    line7: 47.875,
  },
  {
    age: 277,
    line1: 39.856,
    line2: 41.195,
    line3: 42.534,
    line4: 43.873,
    line5: 45.212,
    line6: 46.551,
    line7: 47.89,
  },
  {
    age: 278,
    line1: 39.869,
    line2: 41.208,
    line3: 42.547,
    line4: 43.887,
    line5: 45.226,
    line6: 46.566,
    line7: 47.905,
  },
  {
    age: 279,
    line1: 39.882,
    line2: 41.222,
    line3: 42.561,
    line4: 43.901,
    line5: 45.24,
    line6: 46.58,
    line7: 47.919,
  },
  {
    age: 280,
    line1: 39.895,
    line2: 41.235,
    line3: 42.575,
    line4: 43.914,
    line5: 45.254,
    line6: 46.594,
    line7: 47.934,
  },
  {
    age: 281,
    line1: 39.907,
    line2: 41.248,
    line3: 42.588,
    line4: 43.928,
    line5: 45.268,
    line6: 46.609,
    line7: 47.949,
  },
  {
    age: 282,
    line1: 39.921,
    line2: 41.261,
    line3: 42.602,
    line4: 43.942,
    line5: 45.282,
    line6: 46.622,
    line7: 47.963,
  },
  {
    age: 283,
    line1: 39.934,
    line2: 41.274,
    line3: 42.615,
    line4: 43.956,
    line5: 45.296,
    line6: 46.637,
    line7: 47.977,
  },
  {
    age: 284,
    line1: 39.946,
    line2: 41.287,
    line3: 42.628,
    line4: 43.969,
    line5: 45.31,
    line6: 46.651,
    line7: 47.992,
  },
  {
    age: 285,
    line1: 39.959,
    line2: 41.3,
    line3: 42.641,
    line4: 43.982,
    line5: 45.323,
    line6: 46.664,
    line7: 48.005,
  },
  {
    age: 286,
    line1: 39.972,
    line2: 41.313,
    line3: 42.654,
    line4: 43.996,
    line5: 45.337,
    line6: 46.679,
    line7: 48.02,
  },
  {
    age: 287,
    line1: 39.984,
    line2: 41.326,
    line3: 42.667,
    line4: 44.009,
    line5: 45.351,
    line6: 46.693,
    line7: 48.035,
  },
  {
    age: 288,
    line1: 39.996,
    line2: 41.338,
    line3: 42.68,
    line4: 44.022,
    line5: 45.365,
    line6: 46.707,
    line7: 48.049,
  },
  {
    age: 289,
    line1: 40.009,
    line2: 41.351,
    line3: 42.693,
    line4: 44.036,
    line5: 45.378,
    line6: 46.72,
    line7: 48.062,
  },
  {
    age: 290,
    line1: 40.021,
    line2: 41.364,
    line3: 42.706,
    line4: 44.049,
    line5: 45.392,
    line6: 46.734,
    line7: 48.077,
  },
  {
    age: 291,
    line1: 40.033,
    line2: 41.376,
    line3: 42.719,
    line4: 44.062,
    line5: 45.405,
    line6: 46.748,
    line7: 48.091,
  },
  {
    age: 292,
    line1: 40.046,
    line2: 41.389,
    line3: 42.732,
    line4: 44.075,
    line5: 45.418,
    line6: 46.761,
    line7: 48.104,
  },
  {
    age: 293,
    line1: 40.058,
    line2: 41.401,
    line3: 42.745,
    line4: 44.088,
    line5: 45.431,
    line6: 46.775,
    line7: 48.118,
  },
  {
    age: 294,
    line1: 40.07,
    line2: 41.413,
    line3: 42.757,
    line4: 44.101,
    line5: 45.445,
    line6: 46.789,
    line7: 48.132,
  },
  {
    age: 295,
    line1: 40.083,
    line2: 41.426,
    line3: 42.77,
    line4: 44.114,
    line5: 45.458,
    line6: 46.801,
    line7: 48.145,
  },
  {
    age: 296,
    line1: 40.094,
    line2: 41.439,
    line3: 42.783,
    line4: 44.127,
    line5: 45.471,
    line6: 46.815,
    line7: 48.159,
  },
  {
    age: 297,
    line1: 40.106,
    line2: 41.451,
    line3: 42.795,
    line4: 44.14,
    line5: 45.484,
    line6: 46.828,
    line7: 48.173,
  },
  {
    age: 298,
    line1: 40.119,
    line2: 41.463,
    line3: 42.808,
    line4: 44.152,
    line5: 45.497,
    line6: 46.841,
    line7: 48.186,
  },
  {
    age: 299,
    line1: 40.13,
    line2: 41.475,
    line3: 42.82,
    line4: 44.165,
    line5: 45.51,
    line6: 46.854,
    line7: 48.199,
  },
  {
    age: 300,
    line1: 40.142,
    line2: 41.487,
    line3: 42.832,
    line4: 44.177,
    line5: 45.523,
    line6: 46.868,
    line7: 48.213,
  },
  {
    age: 301,
    line1: 40.153,
    line2: 41.499,
    line3: 42.844,
    line4: 44.19,
    line5: 45.536,
    line6: 46.881,
    line7: 48.227,
  },
  {
    age: 302,
    line1: 40.166,
    line2: 41.511,
    line3: 42.857,
    line4: 44.202,
    line5: 45.548,
    line6: 46.894,
    line7: 48.239,
  },
  {
    age: 303,
    line1: 40.177,
    line2: 41.523,
    line3: 42.869,
    line4: 44.215,
    line5: 45.561,
    line6: 46.907,
    line7: 48.253,
  },
  {
    age: 304,
    line1: 40.188,
    line2: 41.535,
    line3: 42.881,
    line4: 44.227,
    line5: 45.574,
    line6: 46.92,
    line7: 48.266,
  },
  {
    age: 305,
    line1: 40.201,
    line2: 41.547,
    line3: 42.893,
    line4: 44.24,
    line5: 45.586,
    line6: 46.932,
    line7: 48.278,
  },
  {
    age: 306,
    line1: 40.212,
    line2: 41.559,
    line3: 42.905,
    line4: 44.252,
    line5: 45.598,
    line6: 46.945,
    line7: 48.292,
  },
  {
    age: 307,
    line1: 40.223,
    line2: 41.57,
    line3: 42.917,
    line4: 44.264,
    line5: 45.611,
    line6: 46.958,
    line7: 48.305,
  },
  {
    age: 308,
    line1: 40.234,
    line2: 41.582,
    line3: 42.929,
    line4: 44.276,
    line5: 45.624,
    line6: 46.971,
    line7: 48.318,
  },
  {
    age: 309,
    line1: 40.247,
    line2: 41.594,
    line3: 42.941,
    line4: 44.288,
    line5: 45.636,
    line6: 46.983,
    line7: 48.33,
  },
  {
    age: 310,
    line1: 40.258,
    line2: 41.605,
    line3: 42.953,
    line4: 44.3,
    line5: 45.648,
    line6: 46.996,
    line7: 48.343,
  },
  {
    age: 311,
    line1: 40.269,
    line2: 41.617,
    line3: 42.965,
    line4: 44.312,
    line5: 45.66,
    line6: 47.008,
    line7: 48.356,
  },
  {
    age: 312,
    line1: 40.281,
    line2: 41.629,
    line3: 42.977,
    line4: 44.324,
    line5: 45.672,
    line6: 47.02,
    line7: 48.368,
  },
  {
    age: 313,
    line1: 40.292,
    line2: 41.64,
    line3: 42.988,
    line4: 44.336,
    line5: 45.685,
    line6: 47.033,
    line7: 48.381,
  },
  {
    age: 314,
    line1: 40.302,
    line2: 41.651,
    line3: 43,
    line4: 44.348,
    line5: 45.697,
    line6: 47.046,
    line7: 48.394,
  },
  {
    age: 315,
    line1: 40.314,
    line2: 41.663,
    line3: 43.012,
    line4: 44.36,
    line5: 45.709,
    line6: 47.057,
    line7: 48.406,
  },
  {
    age: 316,
    line1: 40.325,
    line2: 41.674,
    line3: 43.023,
    line4: 44.372,
    line5: 45.721,
    line6: 47.07,
    line7: 48.419,
  },
  {
    age: 317,
    line1: 40.336,
    line2: 41.685,
    line3: 43.034,
    line4: 44.384,
    line5: 45.733,
    line6: 47.082,
    line7: 48.431,
  },
  {
    age: 318,
    line1: 40.346,
    line2: 41.696,
    line3: 43.046,
    line4: 44.395,
    line5: 45.745,
    line6: 47.094,
    line7: 48.444,
  },
  {
    age: 319,
    line1: 40.358,
    line2: 41.708,
    line3: 43.057,
    line4: 44.407,
    line5: 45.756,
    line6: 47.106,
    line7: 48.455,
  },
  {
    age: 320,
    line1: 40.369,
    line2: 41.719,
    line3: 43.069,
    line4: 44.418,
    line5: 45.768,
    line6: 47.118,
    line7: 48.468,
  },
  {
    age: 321,
    line1: 40.379,
    line2: 41.73,
    line3: 43.08,
    line4: 44.43,
    line5: 45.78,
    line6: 47.13,
    line7: 48.481,
  },
  {
    age: 322,
    line1: 40.391,
    line2: 41.741,
    line3: 43.091,
    line4: 44.441,
    line5: 45.792,
    line6: 47.142,
    line7: 48.492,
  },
  {
    age: 323,
    line1: 40.401,
    line2: 41.752,
    line3: 43.102,
    line4: 44.453,
    line5: 45.803,
    line6: 47.154,
    line7: 48.504,
  },
  {
    age: 324,
    line1: 40.412,
    line2: 41.763,
    line3: 43.113,
    line4: 44.464,
    line5: 45.815,
    line6: 47.166,
    line7: 48.517,
  },
  {
    age: 325,
    line1: 40.422,
    line2: 41.773,
    line3: 43.124,
    line4: 44.476,
    line5: 45.827,
    line6: 47.178,
    line7: 48.529,
  },
  {
    age: 326,
    line1: 40.434,
    line2: 41.785,
    line3: 43.136,
    line4: 44.487,
    line5: 45.838,
    line6: 47.189,
    line7: 48.54,
  },
  {
    age: 327,
    line1: 40.444,
    line2: 41.795,
    line3: 43.147,
    line4: 44.498,
    line5: 45.85,
    line6: 47.201,
    line7: 48.552,
  },
  {
    age: 328,
    line1: 40.454,
    line2: 41.806,
    line3: 43.158,
    line4: 44.509,
    line5: 45.861,
    line6: 47.213,
    line7: 48.565,
  },
  {
    age: 329,
    line1: 40.464,
    line2: 41.816,
    line3: 43.168,
    line4: 44.52,
    line5: 45.873,
    line6: 47.225,
    line7: 48.577,
  },
  {
    age: 330,
    line1: 40.476,
    line2: 41.828,
    line3: 43.18,
    line4: 44.532,
    line5: 45.884,
    line6: 47.236,
    line7: 48.588,
  },
  {
    age: 331,
    line1: 40.486,
    line2: 41.838,
    line3: 43.19,
    line4: 44.543,
    line5: 45.895,
    line6: 47.247,
    line7: 48.6,
  },
  {
    age: 332,
    line1: 40.496,
    line2: 41.848,
    line3: 43.201,
    line4: 44.554,
    line5: 45.906,
    line6: 47.259,
    line7: 48.612,
  },
  {
    age: 333,
    line1: 40.507,
    line2: 41.86,
    line3: 43.212,
    line4: 44.565,
    line5: 45.917,
    line6: 47.27,
    line7: 48.622,
  },
  {
    age: 334,
    line1: 40.517,
    line2: 41.87,
    line3: 43.223,
    line4: 44.576,
    line5: 45.928,
    line6: 47.281,
    line7: 48.634,
  },
  {
    age: 335,
    line1: 40.527,
    line2: 41.88,
    line3: 43.233,
    line4: 44.586,
    line5: 45.94,
    line6: 47.293,
    line7: 48.646,
  },
  {
    age: 336,
    line1: 40.537,
    line2: 41.89,
    line3: 43.244,
    line4: 44.597,
    line5: 45.951,
    line6: 47.304,
    line7: 48.658,
  },
  {
    age: 337,
    line1: 40.548,
    line2: 41.901,
    line3: 43.255,
    line4: 44.608,
    line5: 45.962,
    line6: 47.315,
    line7: 48.668,
  },
  {
    age: 338,
    line1: 40.558,
    line2: 41.911,
    line3: 43.265,
    line4: 44.619,
    line5: 45.973,
    line6: 47.326,
    line7: 48.68,
  },
  {
    age: 339,
    line1: 40.567,
    line2: 41.921,
    line3: 43.276,
    line4: 44.63,
    line5: 45.984,
    line6: 47.338,
    line7: 48.692,
  },
  {
    age: 340,
    line1: 40.577,
    line2: 41.931,
    line3: 43.286,
    line4: 44.64,
    line5: 45.995,
    line6: 47.349,
    line7: 48.703,
  },
  {
    age: 341,
    line1: 40.588,
    line2: 41.942,
    line3: 43.297,
    line4: 44.651,
    line5: 46.005,
    line6: 47.359,
    line7: 48.714,
  },
  {
    age: 342,
    line1: 40.598,
    line2: 41.952,
    line3: 43.307,
    line4: 44.662,
    line5: 46.016,
    line6: 47.371,
    line7: 48.725,
  },
  {
    age: 343,
    line1: 40.607,
    line2: 41.962,
    line3: 43.317,
    line4: 44.672,
    line5: 46.027,
    line6: 47.382,
    line7: 48.737,
  },
  {
    age: 344,
    line1: 40.618,
    line2: 41.973,
    line3: 43.328,
    line4: 44.682,
    line5: 46.037,
    line6: 47.392,
    line7: 48.747,
  },
  {
    age: 345,
    line1: 40.628,
    line2: 41.983,
    line3: 43.338,
    line4: 44.693,
    line5: 46.048,
    line6: 47.403,
    line7: 48.758,
  },
  {
    age: 346,
    line1: 40.637,
    line2: 41.993,
    line3: 43.348,
    line4: 44.703,
    line5: 46.059,
    line6: 47.414,
    line7: 48.77,
  },
  {
    age: 347,
    line1: 40.647,
    line2: 42.002,
    line3: 43.358,
    line4: 44.714,
    line5: 46.07,
    line6: 47.425,
    line7: 48.781,
  },
  {
    age: 348,
    line1: 40.657,
    line2: 42.013,
    line3: 43.369,
    line4: 44.724,
    line5: 46.08,
    line6: 47.435,
    line7: 48.791,
  },
  {
    age: 349,
    line1: 40.667,
    line2: 42.023,
    line3: 43.379,
    line4: 44.734,
    line5: 46.09,
    line6: 47.446,
    line7: 48.802,
  },
  {
    age: 350,
    line1: 40.676,
    line2: 42.032,
    line3: 43.388,
    line4: 44.745,
    line5: 46.101,
    line6: 47.457,
    line7: 48.813,
  },
  {
    age: 351,
    line1: 40.685,
    line2: 42.042,
    line3: 43.398,
    line4: 44.755,
    line5: 46.111,
    line6: 47.468,
    line7: 48.824,
  },
  {
    age: 352,
    line1: 40.696,
    line2: 42.052,
    line3: 43.409,
    line4: 44.765,
    line5: 46.121,
    line6: 47.478,
    line7: 48.834,
  },
  {
    age: 353,
    line1: 40.705,
    line2: 42.062,
    line3: 43.419,
    line4: 44.775,
    line5: 46.132,
    line6: 47.489,
    line7: 48.845,
  },
  {
    age: 354,
    line1: 40.714,
    line2: 42.071,
    line3: 43.428,
    line4: 44.785,
    line5: 46.142,
    line6: 47.499,
    line7: 48.856,
  },
  {
    age: 355,
    line1: 40.723,
    line2: 42.081,
    line3: 43.438,
    line4: 44.795,
    line5: 46.153,
    line6: 47.51,
    line7: 48.867,
  },
  {
    age: 356,
    line1: 40.734,
    line2: 42.091,
    line3: 43.448,
    line4: 44.805,
    line5: 46.163,
    line6: 47.52,
    line7: 48.877,
  },
  {
    age: 357,
    line1: 40.743,
    line2: 42.1,
    line3: 43.458,
    line4: 44.815,
    line5: 46.173,
    line6: 47.53,
    line7: 48.888,
  },
  {
    age: 358,
    line1: 40.752,
    line2: 42.11,
    line3: 43.468,
    line4: 44.825,
    line5: 46.183,
    line6: 47.541,
    line7: 48.899,
  },
  {
    age: 359,
    line1: 40.762,
    line2: 42.12,
    line3: 43.478,
    line4: 44.835,
    line5: 46.193,
    line6: 47.551,
    line7: 48.908,
  },
  {
    age: 360,
    line1: 40.771,
    line2: 42.129,
    line3: 43.487,
    line4: 44.845,
    line5: 46.203,
    line6: 47.561,
    line7: 48.919,
  },
  {
    age: 361,
    line1: 40.78,
    line2: 42.139,
    line3: 43.497,
    line4: 44.855,
    line5: 46.213,
    line6: 47.571,
    line7: 48.93,
  },
  {
    age: 362,
    line1: 40.789,
    line2: 42.148,
    line3: 43.506,
    line4: 44.865,
    line5: 46.223,
    line6: 47.582,
    line7: 48.94,
  },
  {
    age: 363,
    line1: 40.8,
    line2: 42.158,
    line3: 43.516,
    line4: 44.875,
    line5: 46.233,
    line6: 47.591,
    line7: 48.95,
  },
  {
    age: 364,
    line1: 40.808,
    line2: 42.167,
    line3: 43.526,
    line4: 44.884,
    line5: 46.243,
    line6: 47.602,
    line7: 48.96,
  },
  {
    age: 365,
    line1: 40.817,
    line2: 42.176,
    line3: 43.535,
    line4: 44.894,
    line5: 46.253,
    line6: 47.612,
    line7: 48.971,
  },
  {
    age: 366,
    line1: 40.826,
    line2: 42.185,
    line3: 43.544,
    line4: 44.904,
    line5: 46.263,
    line6: 47.622,
    line7: 48.981,
  },
  {
    age: 367,
    line1: 40.836,
    line2: 42.195,
    line3: 43.554,
    line4: 44.913,
    line5: 46.272,
    line6: 47.631,
    line7: 48.991,
  },
  {
    age: 368,
    line1: 40.845,
    line2: 42.204,
    line3: 43.564,
    line4: 44.923,
    line5: 46.282,
    line6: 47.642,
    line7: 49.001,
  },
  {
    age: 369,
    line1: 40.854,
    line2: 42.213,
    line3: 43.573,
    line4: 44.932,
    line5: 46.292,
    line6: 47.652,
    line7: 49.011,
  },
  {
    age: 370,
    line1: 40.862,
    line2: 42.222,
    line3: 43.582,
    line4: 44.942,
    line5: 46.302,
    line6: 47.662,
    line7: 49.022,
  },
  {
    age: 371,
    line1: 40.872,
    line2: 42.232,
    line3: 43.592,
    line4: 44.952,
    line5: 46.311,
    line6: 47.671,
    line7: 49.031,
  },
  {
    age: 372,
    line1: 40.881,
    line2: 42.241,
    line3: 43.601,
    line4: 44.961,
    line5: 46.321,
    line6: 47.681,
    line7: 49.041,
  },
  {
    age: 373,
    line1: 40.889,
    line2: 42.25,
    line3: 43.61,
    line4: 44.97,
    line5: 46.331,
    line6: 47.691,
    line7: 49.051,
  },
  {
    age: 374,
    line1: 40.898,
    line2: 42.258,
    line3: 43.619,
    line4: 44.98,
    line5: 46.34,
    line6: 47.701,
    line7: 49.062,
  },
  {
    age: 375,
    line1: 40.908,
    line2: 42.268,
    line3: 43.629,
    line4: 44.989,
    line5: 46.35,
    line6: 47.71,
    line7: 49.071,
  },
  {
    age: 376,
    line1: 40.916,
    line2: 42.277,
    line3: 43.638,
    line4: 44.998,
    line5: 46.359,
    line6: 47.72,
    line7: 49.081,
  },
  {
    age: 377,
    line1: 40.925,
    line2: 42.286,
    line3: 43.647,
    line4: 45.008,
    line5: 46.369,
    line6: 47.73,
    line7: 49.091,
  },
  {
    age: 378,
    line1: 40.933,
    line2: 42.294,
    line3: 43.656,
    line4: 45.017,
    line5: 46.378,
    line6: 47.74,
    line7: 49.101,
  },
  {
    age: 379,
    line1: 40.943,
    line2: 42.304,
    line3: 43.665,
    line4: 45.026,
    line5: 46.387,
    line6: 47.748,
    line7: 49.11,
  },
  {
    age: 380,
    line1: 40.951,
    line2: 42.312,
    line3: 43.674,
    line4: 45.035,
    line5: 46.397,
    line6: 47.758,
    line7: 49.12,
  },
  {
    age: 381,
    line1: 40.959,
    line2: 42.321,
    line3: 43.683,
    line4: 45.044,
    line5: 46.406,
    line6: 47.768,
    line7: 49.13,
  },
  {
    age: 382,
    line1: 40.968,
    line2: 42.33,
    line3: 43.692,
    line4: 45.054,
    line5: 46.416,
    line6: 47.778,
    line7: 49.14,
  },
  {
    age: 383,
    line1: 40.977,
    line2: 42.339,
    line3: 43.701,
    line4: 45.063,
    line5: 46.424,
    line6: 47.786,
    line7: 49.148,
  },
  {
    age: 384,
    line1: 40.985,
    line2: 42.348,
    line3: 43.71,
    line4: 45.072,
    line5: 46.434,
    line6: 47.796,
    line7: 49.158,
  },
  {
    age: 385,
    line1: 40.994,
    line2: 42.356,
    line3: 43.718,
    line4: 45.081,
    line5: 46.443,
    line6: 47.805,
    line7: 49.168,
  },
  {
    age: 386,
    line1: 41.002,
    line2: 42.364,
    line3: 43.727,
    line4: 45.09,
    line5: 46.452,
    line6: 47.815,
    line7: 49.178,
  },
  {
    age: 387,
    line1: 41.011,
    line2: 42.374,
    line3: 43.736,
    line4: 45.099,
    line5: 46.461,
    line6: 47.824,
    line7: 49.186,
  },
  {
    age: 388,
    line1: 41.019,
    line2: 42.382,
    line3: 43.745,
    line4: 45.108,
    line5: 46.47,
    line6: 47.833,
    line7: 49.196,
  },
  {
    age: 389,
    line1: 41.028,
    line2: 42.391,
    line3: 43.754,
    line4: 45.116,
    line5: 46.479,
    line6: 47.842,
    line7: 49.205,
  },
  {
    age: 390,
    line1: 41.036,
    line2: 42.399,
    line3: 43.762,
    line4: 45.125,
    line5: 46.489,
    line6: 47.852,
    line7: 49.215,
  },
  {
    age: 391,
    line1: 41.045,
    line2: 42.408,
    line3: 43.771,
    line4: 45.134,
    line5: 46.497,
    line6: 47.86,
    line7: 49.223,
  },
  {
    age: 392,
    line1: 41.053,
    line2: 42.416,
    line3: 43.78,
    line4: 45.143,
    line5: 46.506,
    line6: 47.87,
    line7: 49.233,
  },
  {
    age: 393,
    line1: 41.061,
    line2: 42.425,
    line3: 43.788,
    line4: 45.152,
    line5: 46.515,
    line6: 47.879,
    line7: 49.243,
  },
  {
    age: 394,
    line1: 41.069,
    line2: 42.433,
    line3: 43.797,
    line4: 45.16,
    line5: 46.524,
    line6: 47.888,
    line7: 49.252,
  },
  {
    age: 395,
    line1: 41.078,
    line2: 42.442,
    line3: 43.806,
    line4: 45.169,
    line5: 46.533,
    line6: 47.897,
    line7: 49.26,
  },
  {
    age: 396,
    line1: 41.086,
    line2: 42.45,
    line3: 43.814,
    line4: 45.178,
    line5: 46.542,
    line6: 47.906,
    line7: 49.27,
  },
  {
    age: 397,
    line1: 41.094,
    line2: 42.458,
    line3: 43.822,
    line4: 45.187,
    line5: 46.551,
    line6: 47.915,
    line7: 49.279,
  },
  {
    age: 398,
    line1: 41.102,
    line2: 42.466,
    line3: 43.831,
    line4: 45.195,
    line5: 46.56,
    line6: 47.924,
    line7: 49.289,
  },
  {
    age: 399,
    line1: 41.11,
    line2: 42.474,
    line3: 43.839,
    line4: 45.204,
    line5: 46.569,
    line6: 47.933,
    line7: 49.298,
  },
  {
    age: 400,
    line1: 41.119,
    line2: 42.483,
    line3: 43.848,
    line4: 45.212,
    line5: 46.577,
    line6: 47.941,
    line7: 49.306,
  },
  {
    age: 401,
    line1: 41.127,
    line2: 42.491,
    line3: 43.856,
    line4: 45.221,
    line5: 46.586,
    line6: 47.95,
    line7: 49.315,
  },
  {
    age: 402,
    line1: 41.134,
    line2: 42.499,
    line3: 43.864,
    line4: 45.229,
    line5: 46.594,
    line6: 47.959,
    line7: 49.324,
  },
  {
    age: 403,
    line1: 41.142,
    line2: 42.507,
    line3: 43.873,
    line4: 45.238,
    line5: 46.603,
    line6: 47.968,
    line7: 49.334,
  },
  {
    age: 404,
    line1: 41.151,
    line2: 42.516,
    line3: 43.881,
    line4: 45.246,
    line5: 46.611,
    line6: 47.976,
    line7: 49.342,
  },
  {
    age: 405,
    line1: 41.159,
    line2: 42.524,
    line3: 43.889,
    line4: 45.255,
    line5: 46.62,
    line6: 47.985,
    line7: 49.351,
  },
  {
    age: 406,
    line1: 41.166,
    line2: 42.532,
    line3: 43.898,
    line4: 45.263,
    line5: 46.629,
    line6: 47.994,
    line7: 49.36,
  },
  {
    age: 407,
    line1: 41.174,
    line2: 42.54,
    line3: 43.906,
    line4: 45.272,
    line5: 46.637,
    line6: 48.003,
    line7: 49.369,
  },
  {
    age: 408,
    line1: 41.183,
    line2: 42.549,
    line3: 43.914,
    line4: 45.28,
    line5: 46.646,
    line6: 48.011,
    line7: 49.377,
  },
  {
    age: 409,
    line1: 41.191,
    line2: 42.556,
    line3: 43.922,
    line4: 45.288,
    line5: 46.654,
    line6: 48.02,
    line7: 49.386,
  },
  {
    age: 410,
    line1: 41.198,
    line2: 42.564,
    line3: 43.93,
    line4: 45.296,
    line5: 46.663,
    line6: 48.029,
    line7: 49.395,
  },
  {
    age: 411,
    line1: 41.206,
    line2: 42.572,
    line3: 43.938,
    line4: 45.305,
    line5: 46.671,
    line6: 48.037,
    line7: 49.404,
  },
  {
    age: 412,
    line1: 41.214,
    line2: 42.581,
    line3: 43.947,
    line4: 45.313,
    line5: 46.679,
    line6: 48.045,
    line7: 49.412,
  },
  {
    age: 413,
    line1: 41.222,
    line2: 42.588,
    line3: 43.955,
    line4: 45.321,
    line5: 46.688,
    line6: 48.054,
    line7: 49.421,
  },
  {
    age: 414,
    line1: 41.229,
    line2: 42.596,
    line3: 43.963,
    line4: 45.329,
    line5: 46.696,
    line6: 48.063,
    line7: 49.429,
  },
  {
    age: 415,
    line1: 41.237,
    line2: 42.604,
    line3: 43.971,
    line4: 45.338,
    line5: 46.704,
    line6: 48.071,
    line7: 49.438,
  },
  {
    age: 416,
    line1: 41.244,
    line2: 42.611,
    line3: 43.978,
    line4: 45.346,
    line5: 46.713,
    line6: 48.08,
    line7: 49.447,
  },
  {
    age: 417,
    line1: 41.253,
    line2: 42.62,
    line3: 43.987,
    line4: 45.354,
    line5: 46.721,
    line6: 48.088,
    line7: 49.455,
  },
  {
    age: 418,
    line1: 41.26,
    line2: 42.627,
    line3: 43.995,
    line4: 45.362,
    line5: 46.729,
    line6: 48.096,
    line7: 49.463,
  },
  {
    age: 419,
    line1: 41.268,
    line2: 42.635,
    line3: 44.002,
    line4: 45.37,
    line5: 46.737,
    line6: 48.105,
    line7: 49.472,
  },
  {
    age: 420,
    line1: 41.275,
    line2: 42.643,
    line3: 44.01,
    line4: 45.378,
    line5: 46.746,
    line6: 48.113,
    line7: 49.481,
  },
  {
    age: 421,
    line1: 41.283,
    line2: 42.651,
    line3: 44.018,
    line4: 45.386,
    line5: 46.753,
    line6: 48.121,
    line7: 49.488,
  },
  {
    age: 422,
    line1: 41.291,
    line2: 42.658,
    line3: 44.026,
    line4: 45.394,
    line5: 46.762,
    line6: 48.129,
    line7: 49.497,
  },
  {
    age: 423,
    line1: 41.298,
    line2: 42.666,
    line3: 44.034,
    line4: 45.402,
    line5: 46.77,
    line6: 48.138,
    line7: 49.506,
  },
  {
    age: 424,
    line1: 41.305,
    line2: 42.673,
    line3: 44.042,
    line4: 45.41,
    line5: 46.778,
    line6: 48.146,
    line7: 49.514,
  },
  {
    age: 425,
    line1: 41.312,
    line2: 42.681,
    line3: 44.049,
    line4: 45.418,
    line5: 46.786,
    line6: 48.154,
    line7: 49.523,
  },
  {
    age: 426,
    line1: 41.321,
    line2: 42.689,
    line3: 44.057,
    line4: 45.426,
    line5: 46.794,
    line6: 48.162,
    line7: 49.53,
  },
  {
    age: 427,
    line1: 41.328,
    line2: 42.696,
    line3: 44.065,
    line4: 45.433,
    line5: 46.802,
    line6: 48.17,
    line7: 49.539,
  },
  {
    age: 428,
    line1: 41.335,
    line2: 42.704,
    line3: 44.073,
    line4: 45.441,
    line5: 46.81,
    line6: 48.179,
    line7: 49.547,
  },
  {
    age: 429,
    line1: 41.342,
    line2: 42.711,
    line3: 44.08,
    line4: 45.449,
    line5: 46.818,
    line6: 48.187,
    line7: 49.556,
  },
  {
    age: 430,
    line1: 41.351,
    line2: 42.719,
    line3: 44.088,
    line4: 45.457,
    line5: 46.826,
    line6: 48.194,
    line7: 49.563,
  },
  {
    age: 431,
    line1: 41.358,
    line2: 42.727,
    line3: 44.096,
    line4: 45.464,
    line5: 46.833,
    line6: 48.202,
    line7: 49.571,
  },
  {
    age: 432,
    line1: 41.365,
    line2: 42.734,
    line3: 44.103,
    line4: 45.472,
    line5: 46.841,
    line6: 48.211,
    line7: 49.58,
  },
  {
    age: 433,
    line1: 41.372,
    line2: 42.741,
    line3: 44.111,
    line4: 45.48,
    line5: 46.849,
    line6: 48.219,
    line7: 49.588,
  },
  {
    age: 434,
    line1: 41.38,
    line2: 42.749,
    line3: 44.118,
    line4: 45.488,
    line5: 46.857,
    line6: 48.226,
    line7: 49.595,
  },
  {
    age: 435,
    line1: 41.387,
    line2: 42.756,
    line3: 44.126,
    line4: 45.495,
    line5: 46.865,
    line6: 48.234,
    line7: 49.604,
  },
  {
    age: 436,
    line1: 41.394,
    line2: 42.764,
    line3: 44.133,
    line4: 45.503,
    line5: 46.873,
    line6: 48.242,
    line7: 49.612,
  },
  {
    age: 437,
    line1: 41.401,
    line2: 42.771,
    line3: 44.141,
    line4: 45.51,
    line5: 46.88,
    line6: 48.25,
    line7: 49.62,
  },
  {
    age: 438,
    line1: 41.408,
    line2: 42.778,
    line3: 44.148,
    line4: 45.518,
    line5: 46.888,
    line6: 48.258,
    line7: 49.628,
  },
  {
    age: 439,
    line1: 41.416,
    line2: 42.786,
    line3: 44.156,
    line4: 45.526,
    line5: 46.896,
    line6: 48.265,
    line7: 49.635,
  },
  {
    age: 440,
    line1: 41.423,
    line2: 42.793,
    line3: 44.163,
    line4: 45.533,
    line5: 46.903,
    line6: 48.273,
    line7: 49.643,
  },
  {
    age: 441,
    line1: 41.43,
    line2: 42.8,
    line3: 44.17,
    line4: 45.541,
    line5: 46.911,
    line6: 48.281,
    line7: 49.652,
  },
  {
    age: 442,
    line1: 41.437,
    line2: 42.807,
    line3: 44.178,
    line4: 45.548,
    line5: 46.919,
    line6: 48.289,
    line7: 49.66,
  },
  {
    age: 443,
    line1: 41.445,
    line2: 42.815,
    line3: 44.185,
    line4: 45.556,
    line5: 46.926,
    line6: 48.296,
    line7: 49.667,
  },
  {
    age: 444,
    line1: 41.452,
    line2: 42.822,
    line3: 44.193,
    line4: 45.563,
    line5: 46.934,
    line6: 48.304,
    line7: 49.675,
  },
  {
    age: 445,
    line1: 41.458,
    line2: 42.829,
    line3: 44.2,
    line4: 45.571,
    line5: 46.941,
    line6: 48.312,
    line7: 49.683,
  },
  {
    age: 446,
    line1: 41.465,
    line2: 42.836,
    line3: 44.207,
    line4: 45.578,
    line5: 46.949,
    line6: 48.32,
    line7: 49.691,
  },
  {
    age: 447,
    line1: 41.472,
    line2: 42.843,
    line3: 44.214,
    line4: 45.585,
    line5: 46.957,
    line6: 48.328,
    line7: 49.699,
  },
  {
    age: 448,
    line1: 41.48,
    line2: 42.851,
    line3: 44.222,
    line4: 45.593,
    line5: 46.964,
    line6: 48.335,
    line7: 49.706,
  },
  {
    age: 449,
    line1: 41.487,
    line2: 42.858,
    line3: 44.229,
    line4: 45.6,
    line5: 46.971,
    line6: 48.343,
    line7: 49.714,
  },
  {
    age: 450,
    line1: 41.493,
    line2: 42.865,
    line3: 44.236,
    line4: 45.608,
    line5: 46.979,
    line6: 48.35,
    line7: 49.722,
  },
  {
    age: 451,
    line1: 41.5,
    line2: 42.872,
    line3: 44.243,
    line4: 45.615,
    line5: 46.986,
    line6: 48.358,
    line7: 49.73,
  },
  {
    age: 452,
    line1: 41.507,
    line2: 42.878,
    line3: 44.25,
    line4: 45.622,
    line5: 46.994,
    line6: 48.366,
    line7: 49.738,
  },
  {
    age: 453,
    line1: 41.514,
    line2: 42.886,
    line3: 44.258,
    line4: 45.629,
    line5: 47.001,
    line6: 48.373,
    line7: 49.744,
  },
  {
    age: 454,
    line1: 41.521,
    line2: 42.893,
    line3: 44.265,
    line4: 45.637,
    line5: 47.008,
    line6: 48.38,
    line7: 49.752,
  },
  {
    age: 455,
    line1: 41.528,
    line2: 42.9,
    line3: 44.272,
    line4: 45.644,
    line5: 47.016,
    line6: 48.388,
    line7: 49.76,
  },
  {
    age: 456,
    line1: 41.534,
    line2: 42.906,
    line3: 44.279,
    line4: 45.651,
    line5: 47.023,
    line6: 48.396,
    line7: 49.768,
  },
  {
    age: 457,
    line1: 41.542,
    line2: 42.914,
    line3: 44.286,
    line4: 45.658,
    line5: 47.03,
    line6: 48.402,
    line7: 49.774,
  },
  {
    age: 458,
    line1: 41.549,
    line2: 42.921,
    line3: 44.293,
    line4: 45.665,
    line5: 47.038,
    line6: 48.41,
    line7: 49.782,
  },
  {
    age: 459,
    line1: 41.555,
    line2: 42.928,
    line3: 44.3,
    line4: 45.672,
    line5: 47.045,
    line6: 48.417,
    line7: 49.79,
  },
  {
    age: 460,
    line1: 41.562,
    line2: 42.934,
    line3: 44.307,
    line4: 45.68,
    line5: 47.052,
    line6: 48.425,
    line7: 49.798,
  },
  {
    age: 461,
    line1: 41.568,
    line2: 42.941,
    line3: 44.314,
    line4: 45.687,
    line5: 47.06,
    line6: 48.432,
    line7: 49.805,
  },
  {
    age: 462,
    line1: 41.576,
    line2: 42.949,
    line3: 44.321,
    line4: 45.694,
    line5: 47.066,
    line6: 48.439,
    line7: 49.812,
  },
  {
    age: 463,
    line1: 41.582,
    line2: 42.955,
    line3: 44.328,
    line4: 45.701,
    line5: 47.074,
    line6: 48.447,
    line7: 49.819,
  },
  {
    age: 464,
    line1: 41.589,
    line2: 42.962,
    line3: 44.335,
    line4: 45.708,
    line5: 47.081,
    line6: 48.454,
    line7: 49.827,
  },
  {
    age: 465,
    line1: 41.595,
    line2: 42.968,
    line3: 44.342,
    line4: 45.715,
    line5: 47.088,
    line6: 48.462,
    line7: 49.835,
  },
  {
    age: 466,
    line1: 41.602,
    line2: 42.975,
    line3: 44.349,
    line4: 45.722,
    line5: 47.095,
    line6: 48.469,
    line7: 49.842,
  },
  {
    age: 467,
    line1: 41.609,
    line2: 42.983,
    line3: 44.356,
    line4: 45.729,
    line5: 47.102,
    line6: 48.475,
    line7: 49.849,
  },
  {
    age: 468,
    line1: 41.616,
    line2: 42.989,
    line3: 44.362,
    line4: 45.736,
    line5: 47.109,
    line6: 48.483,
    line7: 49.856,
  },
  {
    age: 469,
    line1: 41.622,
    line2: 42.996,
    line3: 44.369,
    line4: 45.743,
    line5: 47.117,
    line6: 48.49,
    line7: 49.864,
  },
  {
    age: 470,
    line1: 41.628,
    line2: 43.002,
    line3: 44.376,
    line4: 45.75,
    line5: 47.124,
    line6: 48.498,
    line7: 49.871,
  },
  {
    age: 471,
    line1: 41.634,
    line2: 43.009,
    line3: 44.383,
    line4: 45.757,
    line5: 47.131,
    line6: 48.505,
    line7: 49.879,
  },
  {
    age: 472,
    line1: 41.642,
    line2: 43.016,
    line3: 44.39,
    line4: 45.764,
    line5: 47.137,
    line6: 48.511,
    line7: 49.885,
  },
  {
    age: 473,
    line1: 41.648,
    line2: 43.022,
    line3: 44.396,
    line4: 45.77,
    line5: 47.145,
    line6: 48.519,
    line7: 49.893,
  },
  {
    age: 474,
    line1: 41.655,
    line2: 43.029,
    line3: 44.403,
    line4: 45.777,
    line5: 47.152,
    line6: 48.526,
    line7: 49.9,
  },
  {
    age: 475,
    line1: 41.661,
    line2: 43.035,
    line3: 44.41,
    line4: 45.784,
    line5: 47.159,
    line6: 48.533,
    line7: 49.908,
  },
  {
    age: 476,
    line1: 41.668,
    line2: 43.043,
    line3: 44.417,
    line4: 45.791,
    line5: 47.165,
    line6: 48.539,
    line7: 49.914,
  },
  {
    age: 477,
    line1: 41.675,
    line2: 43.049,
    line3: 44.423,
    line4: 45.798,
    line5: 47.172,
    line6: 48.547,
    line7: 49.921,
  },
  {
    age: 478,
    line1: 41.681,
    line2: 43.055,
    line3: 44.43,
    line4: 45.805,
    line5: 47.179,
    line6: 48.554,
    line7: 49.928,
  },
  {
    age: 479,
    line1: 41.687,
    line2: 43.062,
    line3: 44.437,
    line4: 45.811,
    line5: 47.186,
    line6: 48.561,
    line7: 49.936,
  },
  {
    age: 480,
    line1: 41.693,
    line2: 43.068,
    line3: 44.443,
    line4: 45.818,
    line5: 47.193,
    line6: 48.568,
    line7: 49.943,
  },
  {
    age: 481,
    line1: 41.701,
    line2: 43.075,
    line3: 44.45,
    line4: 45.825,
    line5: 47.2,
    line6: 48.574,
    line7: 49.949,
  },
  {
    age: 482,
    line1: 41.707,
    line2: 43.082,
    line3: 44.457,
    line4: 45.832,
    line5: 47.207,
    line6: 48.581,
    line7: 49.956,
  },
  {
    age: 483,
    line1: 41.713,
    line2: 43.088,
    line3: 44.463,
    line4: 45.838,
    line5: 47.213,
    line6: 48.589,
    line7: 49.964,
  },
  {
    age: 484,
    line1: 41.719,
    line2: 43.094,
    line3: 44.47,
    line4: 45.845,
    line5: 47.22,
    line6: 48.596,
    line7: 49.971,
  },
  {
    age: 485,
    line1: 41.725,
    line2: 43.101,
    line3: 44.476,
    line4: 45.852,
    line5: 47.227,
    line6: 48.603,
    line7: 49.978,
  },
  {
    age: 486,
    line1: 41.732,
    line2: 43.108,
    line3: 44.483,
    line4: 45.858,
    line5: 47.234,
    line6: 48.609,
    line7: 49.984,
  },
  {
    age: 487,
    line1: 41.739,
    line2: 43.114,
    line3: 44.49,
    line4: 45.865,
    line5: 47.24,
    line6: 48.616,
    line7: 49.991,
  },
  {
    age: 488,
    line1: 41.745,
    line2: 43.12,
    line3: 44.496,
    line4: 45.872,
    line5: 47.247,
    line6: 48.623,
    line7: 49.999,
  },
  {
    age: 489,
    line1: 41.751,
    line2: 43.126,
    line3: 44.502,
    line4: 45.878,
    line5: 47.254,
    line6: 48.63,
    line7: 50.006,
  },
  {
    age: 490,
    line1: 41.757,
    line2: 43.133,
    line3: 44.509,
    line4: 45.885,
    line5: 47.261,
    line6: 48.637,
    line7: 50.013,
  },
  {
    age: 491,
    line1: 41.764,
    line2: 43.14,
    line3: 44.516,
    line4: 45.891,
    line5: 47.267,
    line6: 48.643,
    line7: 50.019,
  },
  {
    age: 492,
    line1: 41.77,
    line2: 43.146,
    line3: 44.522,
    line4: 45.898,
    line5: 47.274,
    line6: 48.65,
    line7: 50.026,
  },
  {
    age: 493,
    line1: 41.776,
    line2: 43.152,
    line3: 44.528,
    line4: 45.904,
    line5: 47.281,
    line6: 48.657,
    line7: 50.033,
  },
  {
    age: 494,
    line1: 41.782,
    line2: 43.158,
    line3: 44.535,
    line4: 45.911,
    line5: 47.287,
    line6: 48.664,
    line7: 50.04,
  },
  {
    age: 495,
    line1: 41.788,
    line2: 43.164,
    line3: 44.541,
    line4: 45.918,
    line5: 47.294,
    line6: 48.671,
    line7: 50.047,
  },
  {
    age: 496,
    line1: 41.795,
    line2: 43.171,
    line3: 44.548,
    line4: 45.924,
    line5: 47.3,
    line6: 48.677,
    line7: 50.053,
  },
  {
    age: 497,
    line1: 41.801,
    line2: 43.177,
    line3: 44.554,
    line4: 45.93,
    line5: 47.307,
    line6: 48.684,
    line7: 50.06,
  },
  {
    age: 498,
    line1: 41.807,
    line2: 43.184,
    line3: 44.56,
    line4: 45.937,
    line5: 47.314,
    line6: 48.69,
    line7: 50.067,
  },
  {
    age: 499,
    line1: 41.813,
    line2: 43.19,
    line3: 44.566,
    line4: 45.943,
    line5: 47.32,
    line6: 48.697,
    line7: 50.074,
  },
  {
    age: 500,
    line1: 41.819,
    line2: 43.196,
    line3: 44.573,
    line4: 45.95,
    line5: 47.327,
    line6: 48.704,
    line7: 50.081,
  },
  {
    age: 501,
    line1: 41.826,
    line2: 43.203,
    line3: 44.579,
    line4: 45.956,
    line5: 47.333,
    line6: 48.71,
    line7: 50.087,
  },
  {
    age: 502,
    line1: 41.832,
    line2: 43.209,
    line3: 44.586,
    line4: 45.963,
    line5: 47.34,
    line6: 48.717,
    line7: 50.094,
  },
  {
    age: 503,
    line1: 41.837,
    line2: 43.215,
    line3: 44.592,
    line4: 45.969,
    line5: 47.346,
    line6: 48.724,
    line7: 50.101,
  },
  {
    age: 504,
    line1: 41.843,
    line2: 43.221,
    line3: 44.598,
    line4: 45.976,
    line5: 47.353,
    line6: 48.73,
    line7: 50.108,
  },
  {
    age: 505,
    line1: 41.849,
    line2: 43.227,
    line3: 44.604,
    line4: 45.982,
    line5: 47.359,
    line6: 48.737,
    line7: 50.115,
  },
  {
    age: 506,
    line1: 41.856,
    line2: 43.234,
    line3: 44.611,
    line4: 45.988,
    line5: 47.366,
    line6: 48.743,
    line7: 50.12,
  },
  {
    age: 507,
    line1: 41.862,
    line2: 43.239,
    line3: 44.617,
    line4: 45.994,
    line5: 47.372,
    line6: 48.75,
    line7: 50.127,
  },
  {
    age: 508,
    line1: 41.868,
    line2: 43.245,
    line3: 44.623,
    line4: 46.001,
    line5: 47.379,
    line6: 48.756,
    line7: 50.134,
  },
  {
    age: 509,
    line1: 41.873,
    line2: 43.251,
    line3: 44.629,
    line4: 46.007,
    line5: 47.385,
    line6: 48.763,
    line7: 50.141,
  },
  {
    age: 510,
    line1: 41.879,
    line2: 43.257,
    line3: 44.635,
    line4: 46.013,
    line5: 47.392,
    line6: 48.77,
    line7: 50.148,
  },
  {
    age: 511,
    line1: 41.886,
    line2: 43.264,
    line3: 44.642,
    line4: 46.02,
    line5: 47.398,
    line6: 48.775,
    line7: 50.153,
  },
  {
    age: 512,
    line1: 41.892,
    line2: 43.27,
    line3: 44.648,
    line4: 46.026,
    line5: 47.404,
    line6: 48.782,
    line7: 50.16,
  },
  {
    age: 513,
    line1: 41.898,
    line2: 43.276,
    line3: 44.654,
    line4: 46.032,
    line5: 47.41,
    line6: 48.789,
    line7: 50.167,
  },
  {
    age: 514,
    line1: 41.903,
    line2: 43.282,
    line3: 44.66,
    line4: 46.038,
    line5: 47.417,
    line6: 48.795,
    line7: 50.174,
  },
  {
    age: 515,
    line1: 41.909,
    line2: 43.288,
    line3: 44.666,
    line4: 46.045,
    line5: 47.423,
    line6: 48.802,
    line7: 50.18,
  },
  {
    age: 516,
    line1: 41.916,
    line2: 43.294,
    line3: 44.673,
    line4: 46.051,
    line5: 47.429,
    line6: 48.808,
    line7: 50.186,
  },
  {
    age: 517,
    line1: 41.922,
    line2: 43.3,
    line3: 44.679,
    line4: 46.057,
    line5: 47.436,
    line6: 48.814,
    line7: 50.193,
  },
  {
    age: 518,
    line1: 41.927,
    line2: 43.306,
    line3: 44.685,
    line4: 46.063,
    line5: 47.442,
    line6: 48.821,
    line7: 50.199,
  },
  {
    age: 519,
    line1: 41.933,
    line2: 43.312,
    line3: 44.691,
    line4: 46.069,
    line5: 47.448,
    line6: 48.827,
    line7: 50.206,
  },
  {
    age: 520,
    line1: 41.938,
    line2: 43.318,
    line3: 44.697,
    line4: 46.076,
    line5: 47.455,
    line6: 48.834,
    line7: 50.213,
  },
  {
    age: 521,
    line1: 41.945,
    line2: 43.324,
    line3: 44.703,
    line4: 46.082,
    line5: 47.461,
    line6: 48.839,
    line7: 50.218,
  },
  {
    age: 522,
    line1: 41.951,
    line2: 43.33,
    line3: 44.709,
    line4: 46.088,
    line5: 47.467,
    line6: 48.846,
    line7: 50.225,
  },
  {
    age: 523,
    line1: 41.957,
    line2: 43.336,
    line3: 44.715,
    line4: 46.094,
    line5: 47.473,
    line6: 48.852,
    line7: 50.231,
  },
  {
    age: 524,
    line1: 41.962,
    line2: 43.341,
    line3: 44.721,
    line4: 46.1,
    line5: 47.479,
    line6: 48.859,
    line7: 50.238,
  },
  {
    age: 525,
    line1: 41.968,
    line2: 43.347,
    line3: 44.727,
    line4: 46.106,
    line5: 47.486,
    line6: 48.865,
    line7: 50.245,
  },
  {
    age: 526,
    line1: 41.973,
    line2: 43.353,
    line3: 44.733,
    line4: 46.112,
    line5: 47.492,
    line6: 48.872,
    line7: 50.251,
  },
  {
    age: 527,
    line1: 41.98,
    line2: 43.36,
    line3: 44.739,
    line4: 46.118,
    line5: 47.498,
    line6: 48.877,
    line7: 50.257,
  },
  {
    age: 528,
    line1: 41.986,
    line2: 43.365,
    line3: 44.745,
    line4: 46.124,
    line5: 47.504,
    line6: 48.884,
    line7: 50.263,
  },
  {
    age: 529,
    line1: 41.991,
    line2: 43.371,
    line3: 44.751,
    line4: 46.13,
    line5: 47.51,
    line6: 48.89,
    line7: 50.27,
  },
  {
    age: 530,
    line1: 41.997,
    line2: 43.377,
    line3: 44.757,
    line4: 46.136,
    line5: 47.516,
    line6: 48.896,
    line7: 50.276,
  },
  {
    age: 531,
    line1: 42.002,
    line2: 43.382,
    line3: 44.762,
    line4: 46.142,
    line5: 47.523,
    line6: 48.903,
    line7: 50.283,
  },
  {
    age: 532,
    line1: 42.009,
    line2: 43.389,
    line3: 44.769,
    line4: 46.148,
    line5: 47.528,
    line6: 48.908,
    line7: 50.288,
  },
  {
    age: 533,
    line1: 42.014,
    line2: 43.394,
    line3: 44.774,
    line4: 46.154,
    line5: 47.535,
    line6: 48.915,
    line7: 50.295,
  },
  {
    age: 534,
    line1: 42.02,
    line2: 43.4,
    line3: 44.78,
    line4: 46.16,
    line5: 47.541,
    line6: 48.921,
    line7: 50.301,
  },
  {
    age: 535,
    line1: 42.025,
    line2: 43.406,
    line3: 44.786,
    line4: 46.166,
    line5: 47.547,
    line6: 48.927,
    line7: 50.308,
  },
  {
    age: 536,
    line1: 42.031,
    line2: 43.411,
    line3: 44.792,
    line4: 46.172,
    line5: 47.553,
    line6: 48.934,
    line7: 50.314,
  },
  {
    age: 537,
    line1: 42.038,
    line2: 43.418,
    line3: 44.798,
    line4: 46.178,
    line5: 47.559,
    line6: 48.939,
    line7: 50.319,
  },
  {
    age: 538,
    line1: 42.043,
    line2: 43.423,
    line3: 44.804,
    line4: 46.184,
    line5: 47.565,
    line6: 48.945,
    line7: 50.326,
  },
  {
    age: 539,
    line1: 42.048,
    line2: 43.429,
    line3: 44.81,
    line4: 46.19,
    line5: 47.571,
    line6: 48.952,
    line7: 50.332,
  },
  {
    age: 540,
    line1: 42.054,
    line2: 43.435,
    line3: 44.815,
    line4: 46.196,
    line5: 47.577,
    line6: 48.958,
    line7: 50.339,
  },
  {
    age: 541,
    line1: 42.059,
    line2: 43.44,
    line3: 44.821,
    line4: 46.202,
    line5: 47.583,
    line6: 48.964,
    line7: 50.345,
  },
  {
    age: 542,
    line1: 42.065,
    line2: 43.446,
    line3: 44.827,
    line4: 46.208,
    line5: 47.589,
    line6: 48.97,
    line7: 50.351,
  },
  {
    age: 543,
    line1: 42.071,
    line2: 43.452,
    line3: 44.833,
    line4: 46.214,
    line5: 47.595,
    line6: 48.976,
    line7: 50.357,
  },
  {
    age: 544,
    line1: 42.077,
    line2: 43.458,
    line3: 44.839,
    line4: 46.22,
    line5: 47.601,
    line6: 48.982,
    line7: 50.363,
  },
  {
    age: 545,
    line1: 42.082,
    line2: 43.463,
    line3: 44.844,
    line4: 46.226,
    line5: 47.607,
    line6: 48.988,
    line7: 50.369,
  },
  {
    age: 546,
    line1: 42.087,
    line2: 43.469,
    line3: 44.85,
    line4: 46.232,
    line5: 47.613,
    line6: 48.994,
    line7: 50.376,
  },
  {
    age: 547,
    line1: 42.093,
    line2: 43.474,
    line3: 44.856,
    line4: 46.237,
    line5: 47.619,
    line6: 49,
    line7: 50.382,
  },
  {
    age: 548,
    line1: 42.099,
    line2: 43.481,
    line3: 44.862,
    line4: 46.243,
    line5: 47.624,
    line6: 49.006,
    line7: 50.387,
  },
  {
    age: 549,
    line1: 42.105,
    line2: 43.486,
    line3: 44.868,
    line4: 46.249,
    line5: 47.63,
    line6: 49.012,
    line7: 50.393,
  },
  {
    age: 550,
    line1: 42.11,
    line2: 43.492,
    line3: 44.873,
    line4: 46.255,
    line5: 47.636,
    line6: 49.018,
    line7: 50.4,
  },
  {
    age: 551,
    line1: 42.115,
    line2: 43.497,
    line3: 44.879,
    line4: 46.261,
    line5: 47.642,
    line6: 49.024,
    line7: 50.406,
  },
  {
    age: 552,
    line1: 42.12,
    line2: 43.502,
    line3: 44.884,
    line4: 46.266,
    line5: 47.648,
    line6: 49.03,
    line7: 50.412,
  },
  {
    age: 553,
    line1: 42.127,
    line2: 43.509,
    line3: 44.89,
    line4: 46.272,
    line5: 47.654,
    line6: 49.035,
    line7: 50.417,
  },
  {
    age: 554,
    line1: 42.132,
    line2: 43.514,
    line3: 44.896,
    line4: 46.278,
    line5: 47.66,
    line6: 49.042,
    line7: 50.423,
  },
  {
    age: 555,
    line1: 42.138,
    line2: 43.52,
    line3: 44.902,
    line4: 46.284,
    line5: 47.666,
    line6: 49.048,
    line7: 50.43,
  },
  {
    age: 556,
    line1: 42.143,
    line2: 43.525,
    line3: 44.907,
    line4: 46.289,
    line5: 47.672,
    line6: 49.054,
    line7: 50.436,
  },
  {
    age: 557,
    line1: 42.148,
    line2: 43.53,
    line3: 44.913,
    line4: 46.295,
    line5: 47.677,
    line6: 49.06,
    line7: 50.442,
  },
  {
    age: 558,
    line1: 42.153,
    line2: 43.536,
    line3: 44.918,
    line4: 46.301,
    line5: 47.683,
    line6: 49.066,
    line7: 50.449,
  },
  {
    age: 559,
    line1: 42.16,
    line2: 43.542,
    line3: 44.924,
    line4: 46.307,
    line5: 47.689,
    line6: 49.071,
    line7: 50.453,
  },
  {
    age: 560,
    line1: 42.165,
    line2: 43.547,
    line3: 44.93,
    line4: 46.312,
    line5: 47.695,
    line6: 49.077,
    line7: 50.46,
  },
  {
    age: 561,
    line1: 42.17,
    line2: 43.553,
    line3: 44.935,
    line4: 46.318,
    line5: 47.701,
    line6: 49.083,
    line7: 50.466,
  },
  {
    age: 562,
    line1: 42.175,
    line2: 43.558,
    line3: 44.941,
    line4: 46.324,
    line5: 47.706,
    line6: 49.089,
    line7: 50.472,
  },
  {
    age: 563,
    line1: 42.181,
    line2: 43.564,
    line3: 44.946,
    line4: 46.329,
    line5: 47.712,
    line6: 49.095,
    line7: 50.478,
  },
  {
    age: 564,
    line1: 42.187,
    line2: 43.57,
    line3: 44.952,
    line4: 46.335,
    line5: 47.718,
    line6: 49.1,
    line7: 50.483,
  },
  {
    age: 565,
    line1: 42.192,
    line2: 43.575,
    line3: 44.958,
    line4: 46.341,
    line5: 47.724,
    line6: 49.106,
    line7: 50.489,
  },
  {
    age: 566,
    line1: 42.197,
    line2: 43.58,
    line3: 44.963,
    line4: 46.346,
    line5: 47.729,
    line6: 49.112,
    line7: 50.495,
  },
  {
    age: 567,
    line1: 42.203,
    line2: 43.586,
    line3: 44.969,
    line4: 46.352,
    line5: 47.735,
    line6: 49.118,
    line7: 50.501,
  },
  {
    age: 568,
    line1: 42.208,
    line2: 43.591,
    line3: 44.974,
    line4: 46.358,
    line5: 47.741,
    line6: 49.124,
    line7: 50.508,
  },
  {
    age: 569,
    line1: 42.213,
    line2: 43.596,
    line3: 44.98,
    line4: 46.363,
    line5: 47.747,
    line6: 49.13,
    line7: 50.514,
  },
  {
    age: 570,
    line1: 42.219,
    line2: 43.603,
    line3: 44.986,
    line4: 46.369,
    line5: 47.752,
    line6: 49.135,
    line7: 50.518,
  },
  {
    age: 571,
    line1: 42.224,
    line2: 43.608,
    line3: 44.991,
    line4: 46.374,
    line5: 47.758,
    line6: 49.141,
    line7: 50.525,
  },
  {
    age: 572,
    line1: 42.23,
    line2: 43.613,
    line3: 44.997,
    line4: 46.38,
    line5: 47.764,
    line6: 49.147,
    line7: 50.531,
  },
  {
    age: 573,
    line1: 42.235,
    line2: 43.618,
    line3: 45.002,
    line4: 46.386,
    line5: 47.769,
    line6: 49.153,
    line7: 50.537,
  },
  {
    age: 574,
    line1: 42.24,
    line2: 43.624,
    line3: 45.007,
    line4: 46.391,
    line5: 47.775,
    line6: 49.159,
    line7: 50.543,
  },
  {
    age: 575,
    line1: 42.245,
    line2: 43.629,
    line3: 45.013,
    line4: 46.397,
    line5: 47.781,
    line6: 49.165,
    line7: 50.549,
  },
  {
    age: 576,
    line1: 42.251,
    line2: 43.635,
    line3: 45.019,
    line4: 46.402,
    line5: 47.786,
    line6: 49.17,
    line7: 50.554,
  },
  {
    age: 577,
    line1: 42.256,
    line2: 43.64,
    line3: 45.024,
    line4: 46.408,
    line5: 47.792,
    line6: 49.176,
    line7: 50.56,
  },
  {
    age: 578,
    line1: 42.261,
    line2: 43.645,
    line3: 45.029,
    line4: 46.414,
    line5: 47.798,
    line6: 49.182,
    line7: 50.566,
  },
  {
    age: 579,
    line1: 42.266,
    line2: 43.651,
    line3: 45.035,
    line4: 46.419,
    line5: 47.803,
    line6: 49.187,
    line7: 50.572,
  },
  {
    age: 580,
    line1: 42.271,
    line2: 43.656,
    line3: 45.04,
    line4: 46.424,
    line5: 47.809,
    line6: 49.193,
    line7: 50.578,
  },
  {
    age: 581,
    line1: 42.278,
    line2: 43.662,
    line3: 45.046,
    line4: 46.43,
    line5: 47.814,
    line6: 49.198,
    line7: 50.582,
  },
  {
    age: 582,
    line1: 42.283,
    line2: 43.667,
    line3: 45.051,
    line4: 46.436,
    line5: 47.82,
    line6: 49.204,
    line7: 50.588,
  },
  {
    age: 583,
    line1: 42.288,
    line2: 43.672,
    line3: 45.057,
    line4: 46.441,
    line5: 47.826,
    line6: 49.21,
    line7: 50.594,
  },
  {
    age: 584,
    line1: 42.293,
    line2: 43.677,
    line3: 45.062,
    line4: 46.447,
    line5: 47.831,
    line6: 49.216,
    line7: 50.6,
  },
  {
    age: 585,
    line1: 42.298,
    line2: 43.683,
    line3: 45.067,
    line4: 46.452,
    line5: 47.837,
    line6: 49.222,
    line7: 50.606,
  },
  {
    age: 586,
    line1: 42.303,
    line2: 43.688,
    line3: 45.073,
    line4: 46.458,
    line5: 47.842,
    line6: 49.227,
    line7: 50.612,
  },
  {
    age: 587,
    line1: 42.309,
    line2: 43.694,
    line3: 45.078,
    line4: 46.463,
    line5: 47.848,
    line6: 49.232,
    line7: 50.617,
  },
  {
    age: 588,
    line1: 42.314,
    line2: 43.699,
    line3: 45.084,
    line4: 46.468,
    line5: 47.853,
    line6: 49.238,
    line7: 50.623,
  },
  {
    age: 589,
    line1: 42.319,
    line2: 43.704,
    line3: 45.089,
    line4: 46.474,
    line5: 47.859,
    line6: 49.244,
    line7: 50.629,
  },
  {
    age: 590,
    line1: 42.324,
    line2: 43.709,
    line3: 45.094,
    line4: 46.479,
    line5: 47.864,
    line6: 49.25,
    line7: 50.635,
  },
  {
    age: 591,
    line1: 42.329,
    line2: 43.714,
    line3: 45.1,
    line4: 46.485,
    line5: 47.87,
    line6: 49.255,
    line7: 50.641,
  },
  {
    age: 592,
    line1: 42.334,
    line2: 43.719,
    line3: 45.105,
    line4: 46.49,
    line5: 47.876,
    line6: 49.261,
    line7: 50.646,
  },
  {
    age: 593,
    line1: 42.34,
    line2: 43.725,
    line3: 45.111,
    line4: 46.496,
    line5: 47.881,
    line6: 49.266,
    line7: 50.651,
  },
  {
    age: 594,
    line1: 42.345,
    line2: 43.731,
    line3: 45.116,
    line4: 46.501,
    line5: 47.886,
    line6: 49.272,
    line7: 50.657,
  },
  {
    age: 595,
    line1: 42.35,
    line2: 43.736,
    line3: 45.121,
    line4: 46.506,
    line5: 47.892,
    line6: 49.277,
    line7: 50.663,
  },
  {
    age: 596,
    line1: 42.355,
    line2: 43.741,
    line3: 45.126,
    line4: 46.512,
    line5: 47.897,
    line6: 49.283,
    line7: 50.669,
  },
  {
    age: 597,
    line1: 42.36,
    line2: 43.746,
    line3: 45.132,
    line4: 46.517,
    line5: 47.903,
    line6: 49.289,
    line7: 50.675,
  },
  {
    age: 598,
    line1: 42.366,
    line2: 43.752,
    line3: 45.137,
    line4: 46.523,
    line5: 47.908,
    line6: 49.294,
    line7: 50.679,
  },
  {
    age: 599,
    line1: 42.371,
    line2: 43.757,
    line3: 45.142,
    line4: 46.528,
    line5: 47.914,
    line6: 49.299,
    line7: 50.685,
  },
  {
    age: 600,
    line1: 42.376,
    line2: 43.762,
    line3: 45.148,
    line4: 46.533,
    line5: 47.919,
    line6: 49.305,
    line7: 50.691,
  },
  {
    age: 601,
    line1: 42.381,
    line2: 43.767,
    line3: 45.153,
    line4: 46.539,
    line5: 47.925,
    line6: 49.311,
    line7: 50.697,
  },
  {
    age: 602,
    line1: 42.386,
    line2: 43.772,
    line3: 45.158,
    line4: 46.544,
    line5: 47.93,
    line6: 49.316,
    line7: 50.702,
  },
  {
    age: 603,
    line1: 42.391,
    line2: 43.777,
    line3: 45.163,
    line4: 46.55,
    line5: 47.936,
    line6: 49.322,
    line7: 50.708,
  },
  {
    age: 604,
    line1: 42.397,
    line2: 43.783,
    line3: 45.169,
    line4: 46.555,
    line5: 47.941,
    line6: 49.327,
    line7: 50.713,
  },
  {
    age: 605,
    line1: 42.402,
    line2: 43.788,
    line3: 45.174,
    line4: 46.56,
    line5: 47.946,
    line6: 49.332,
    line7: 50.718,
  },
  {
    age: 606,
    line1: 42.407,
    line2: 43.793,
    line3: 45.179,
    line4: 46.566,
    line5: 47.952,
    line6: 49.338,
    line7: 50.724,
  },
  {
    age: 607,
    line1: 42.412,
    line2: 43.798,
    line3: 45.184,
    line4: 46.571,
    line5: 47.957,
    line6: 49.344,
    line7: 50.73,
  },
  {
    age: 608,
    line1: 42.416,
    line2: 43.803,
    line3: 45.19,
    line4: 46.576,
    line5: 47.963,
    line6: 49.349,
    line7: 50.736,
  },
  {
    age: 609,
    line1: 42.421,
    line2: 43.808,
    line3: 45.195,
    line4: 46.581,
    line5: 47.968,
    line6: 49.355,
    line7: 50.742,
  },
  {
    age: 610,
    line1: 42.427,
    line2: 43.814,
    line3: 45.2,
    line4: 46.587,
    line5: 47.973,
    line6: 49.36,
    line7: 50.746,
  },
  {
    age: 611,
    line1: 42.432,
    line2: 43.819,
    line3: 45.205,
    line4: 46.592,
    line5: 47.979,
    line6: 49.365,
    line7: 50.752,
  },
  {
    age: 612,
    line1: 42.437,
    line2: 43.824,
    line3: 45.211,
    line4: 46.597,
    line5: 47.984,
    line6: 49.371,
    line7: 50.758,
  },
  {
    age: 613,
    line1: 42.442,
    line2: 43.829,
    line3: 45.216,
    line4: 46.603,
    line5: 47.989,
    line6: 49.376,
    line7: 50.763,
  },
  {
    age: 614,
    line1: 42.447,
    line2: 43.834,
    line3: 45.221,
    line4: 46.608,
    line5: 47.995,
    line6: 49.382,
    line7: 50.769,
  },
  {
    age: 615,
    line1: 42.451,
    line2: 43.839,
    line3: 45.226,
    line4: 46.613,
    line5: 48,
    line6: 49.388,
    line7: 50.775,
  },
  {
    age: 616,
    line1: 42.458,
    line2: 43.845,
    line3: 45.231,
    line4: 46.618,
    line5: 48.005,
    line6: 49.392,
    line7: 50.779,
  },
  {
    age: 617,
    line1: 42.462,
    line2: 43.849,
    line3: 45.237,
    line4: 46.624,
    line5: 48.011,
    line6: 49.398,
    line7: 50.785,
  },
  {
    age: 618,
    line1: 42.467,
    line2: 43.854,
    line3: 45.242,
    line4: 46.629,
    line5: 48.016,
    line6: 49.403,
    line7: 50.79,
  },
  {
    age: 619,
    line1: 42.472,
    line2: 43.859,
    line3: 45.247,
    line4: 46.634,
    line5: 48.021,
    line6: 49.409,
    line7: 50.796,
  },
  {
    age: 620,
    line1: 42.477,
    line2: 43.864,
    line3: 45.252,
    line4: 46.639,
    line5: 48.027,
    line6: 49.414,
    line7: 50.802,
  },
  {
    age: 621,
    line1: 42.481,
    line2: 43.869,
    line3: 45.257,
    line4: 46.644,
    line5: 48.032,
    line6: 49.42,
    line7: 50.808,
  },
  {
    age: 622,
    line1: 42.488,
    line2: 43.875,
    line3: 45.262,
    line4: 46.65,
    line5: 48.037,
    line6: 49.424,
    line7: 50.812,
  },
  {
    age: 623,
    line1: 42.492,
    line2: 43.88,
    line3: 45.267,
    line4: 46.655,
    line5: 48.043,
    line6: 49.43,
    line7: 50.818,
  },
  {
    age: 624,
    line1: 42.497,
    line2: 43.885,
    line3: 45.273,
    line4: 46.66,
    line5: 48.048,
    line6: 49.436,
    line7: 50.823,
  },
  {
    age: 625,
    line1: 42.502,
    line2: 43.89,
    line3: 45.278,
    line4: 46.665,
    line5: 48.053,
    line6: 49.441,
    line7: 50.829,
  },
  {
    age: 626,
    line1: 42.507,
    line2: 43.895,
    line3: 45.283,
    line4: 46.671,
    line5: 48.059,
    line6: 49.447,
    line7: 50.835,
  },
  {
    age: 627,
    line1: 42.511,
    line2: 43.899,
    line3: 45.288,
    line4: 46.676,
    line5: 48.064,
    line6: 49.452,
    line7: 50.84,
  },
  {
    age: 628,
    line1: 42.517,
    line2: 43.905,
    line3: 45.293,
    line4: 46.681,
    line5: 48.069,
    line6: 49.457,
    line7: 50.844,
  },
  {
    age: 629,
    line1: 42.522,
    line2: 43.91,
    line3: 45.298,
    line4: 46.686,
    line5: 48.074,
    line6: 49.462,
    line7: 50.85,
  },
  {
    age: 630,
    line1: 42.527,
    line2: 43.915,
    line3: 45.303,
    line4: 46.691,
    line5: 48.079,
    line6: 49.468,
    line7: 50.856,
  },
  {
    age: 631,
    line1: 42.532,
    line2: 43.92,
    line3: 45.308,
    line4: 46.696,
    line5: 48.085,
    line6: 49.473,
    line7: 50.861,
  },
  {
    age: 632,
    line1: 42.536,
    line2: 43.925,
    line3: 45.313,
    line4: 46.702,
    line5: 48.09,
    line6: 49.478,
    line7: 50.867,
  },
  {
    age: 633,
    line1: 42.541,
    line2: 43.93,
    line3: 45.318,
    line4: 46.707,
    line5: 48.095,
    line6: 49.484,
    line7: 50.872,
  },
  {
    age: 634,
    line1: 42.547,
    line2: 43.935,
    line3: 45.324,
    line4: 46.712,
    line5: 48.1,
    line6: 49.488,
    line7: 50.877,
  },
  {
    age: 635,
    line1: 42.552,
    line2: 43.94,
    line3: 45.329,
    line4: 46.717,
    line5: 48.105,
    line6: 49.494,
    line7: 50.882,
  },
  {
    age: 636,
    line1: 42.556,
    line2: 43.945,
    line3: 45.334,
    line4: 46.722,
    line5: 48.111,
    line6: 49.499,
    line7: 50.888,
  },
  {
    age: 637,
    line1: 42.561,
    line2: 43.95,
    line3: 45.339,
    line4: 46.727,
    line5: 48.116,
    line6: 49.505,
    line7: 50.894,
  },
  {
    age: 638,
    line1: 42.566,
    line2: 43.955,
    line3: 45.344,
    line4: 46.732,
    line5: 48.121,
    line6: 49.51,
    line7: 50.899,
  },
  {
    age: 639,
    line1: 42.57,
    line2: 43.959,
    line3: 45.348,
    line4: 46.738,
    line5: 48.127,
    line6: 49.516,
    line7: 50.905,
  },
  {
    age: 640,
    line1: 42.576,
    line2: 43.965,
    line3: 45.354,
    line4: 46.743,
    line5: 48.131,
    line6: 49.52,
    line7: 50.909,
  },
  {
    age: 641,
    line1: 42.581,
    line2: 43.97,
    line3: 45.359,
    line4: 46.748,
    line5: 48.137,
    line6: 49.525,
    line7: 50.914,
  },
  {
    age: 642,
    line1: 42.586,
    line2: 43.975,
    line3: 45.364,
    line4: 46.753,
    line5: 48.142,
    line6: 49.531,
    line7: 50.92,
  },
  {
    age: 643,
    line1: 42.59,
    line2: 43.98,
    line3: 45.369,
    line4: 46.758,
    line5: 48.147,
    line6: 49.536,
    line7: 50.925,
  },
  {
    age: 644,
    line1: 42.595,
    line2: 43.984,
    line3: 45.374,
    line4: 46.763,
    line5: 48.152,
    line6: 49.542,
    line7: 50.931,
  },
  {
    age: 645,
    line1: 42.6,
    line2: 43.989,
    line3: 45.379,
    line4: 46.768,
    line5: 48.157,
    line6: 49.547,
    line7: 50.936,
  },
  {
    age: 646,
    line1: 42.606,
    line2: 43.995,
    line3: 45.384,
    line4: 46.773,
    line5: 48.162,
    line6: 49.551,
    line7: 50.941,
  },
  {
    age: 647,
    line1: 42.61,
    line2: 44,
    line3: 45.389,
    line4: 46.778,
    line5: 48.168,
    line6: 49.557,
    line7: 50.946,
  },
  {
    age: 648,
    line1: 42.615,
    line2: 44.004,
    line3: 45.394,
    line4: 46.783,
    line5: 48.173,
    line6: 49.562,
    line7: 50.952,
  },
  {
    age: 649,
    line1: 42.619,
    line2: 44.009,
    line3: 45.399,
    line4: 46.788,
    line5: 48.178,
    line6: 49.568,
    line7: 50.957,
  },
  {
    age: 650,
    line1: 42.624,
    line2: 44.014,
    line3: 45.404,
    line4: 46.793,
    line5: 48.183,
    line6: 49.573,
    line7: 50.963,
  },
  {
    age: 651,
    line1: 42.629,
    line2: 44.019,
    line3: 45.408,
    line4: 46.798,
    line5: 48.188,
    line6: 49.578,
    line7: 50.968,
  },
  {
    age: 652,
    line1: 42.633,
    line2: 44.023,
    line3: 45.413,
    line4: 46.803,
    line5: 48.193,
    line6: 49.584,
    line7: 50.974,
  },
  {
    age: 653,
    line1: 42.639,
    line2: 44.029,
    line3: 45.419,
    line4: 46.808,
    line5: 48.198,
    line6: 49.588,
    line7: 50.978,
  },
  {
    age: 654,
    line1: 42.644,
    line2: 44.034,
    line3: 45.424,
    line4: 46.814,
    line5: 48.203,
    line6: 49.593,
    line7: 50.983,
  },
  {
    age: 655,
    line1: 42.648,
    line2: 44.038,
    line3: 45.428,
    line4: 46.818,
    line5: 48.209,
    line6: 49.599,
    line7: 50.989,
  },
  {
    age: 656,
    line1: 42.653,
    line2: 44.043,
    line3: 45.433,
    line4: 46.824,
    line5: 48.214,
    line6: 49.604,
    line7: 50.994,
  },
  {
    age: 657,
    line1: 42.657,
    line2: 44.048,
    line3: 45.438,
    line4: 46.828,
    line5: 48.219,
    line6: 49.609,
    line7: 51,
  },
  {
    age: 658,
    line1: 42.662,
    line2: 44.053,
    line3: 45.443,
    line4: 46.834,
    line5: 48.224,
    line6: 49.614,
    line7: 51.005,
  },
  {
    age: 659,
    line1: 42.668,
    line2: 44.058,
    line3: 45.448,
    line4: 46.838,
    line5: 48.229,
    line6: 49.619,
    line7: 51.009,
  },
  {
    age: 660,
    line1: 42.673,
    line2: 44.063,
    line3: 45.453,
    line4: 46.844,
    line5: 48.234,
    line6: 49.624,
    line7: 51.014,
  },
  {
    age: 661,
    line1: 42.677,
    line2: 44.068,
    line3: 45.458,
    line4: 46.848,
    line5: 48.239,
    line6: 49.629,
    line7: 51.02,
  },
  {
    age: 662,
    line1: 42.682,
    line2: 44.072,
    line3: 45.463,
    line4: 46.854,
    line5: 48.244,
    line6: 49.635,
    line7: 51.025,
  },
  {
    age: 663,
    line1: 42.686,
    line2: 44.077,
    line3: 45.468,
    line4: 46.858,
    line5: 48.249,
    line6: 49.64,
    line7: 51.031,
  },
  {
    age: 664,
    line1: 42.691,
    line2: 44.082,
    line3: 45.472,
    line4: 46.863,
    line5: 48.254,
    line6: 49.645,
    line7: 51.036,
  },
  {
    age: 665,
    line1: 42.697,
    line2: 44.087,
    line3: 45.478,
    line4: 46.868,
    line5: 48.259,
    line6: 49.65,
    line7: 51.04,
  },
  {
    age: 666,
    line1: 42.701,
    line2: 44.092,
    line3: 45.483,
    line4: 46.873,
    line5: 48.264,
    line6: 49.655,
    line7: 51.045,
  },
  {
    age: 667,
    line1: 42.706,
    line2: 44.097,
    line3: 45.487,
    line4: 46.878,
    line5: 48.269,
    line6: 49.66,
    line7: 51.051,
  },
  {
    age: 668,
    line1: 42.71,
    line2: 44.101,
    line3: 45.492,
    line4: 46.883,
    line5: 48.274,
    line6: 49.665,
    line7: 51.056,
  },
  {
    age: 669,
    line1: 42.715,
    line2: 44.106,
    line3: 45.497,
    line4: 46.888,
    line5: 48.279,
    line6: 49.671,
    line7: 51.062,
  },
  {
    age: 670,
    line1: 42.719,
    line2: 44.11,
    line3: 45.502,
    line4: 46.893,
    line5: 48.284,
    line6: 49.676,
    line7: 51.067,
  },
  {
    age: 671,
    line1: 42.725,
    line2: 44.116,
    line3: 45.507,
    line4: 46.898,
    line5: 48.289,
    line6: 49.68,
    line7: 51.071,
  },
  {
    age: 672,
    line1: 42.73,
    line2: 44.121,
    line3: 45.512,
    line4: 46.903,
    line5: 48.294,
    line6: 49.685,
    line7: 51.076,
  },
  {
    age: 673,
    line1: 42.734,
    line2: 44.125,
    line3: 45.517,
    line4: 46.908,
    line5: 48.299,
    line6: 49.69,
    line7: 51.082,
  },
  {
    age: 674,
    line1: 42.738,
    line2: 44.13,
    line3: 45.521,
    line4: 46.913,
    line5: 48.304,
    line6: 49.696,
    line7: 51.087,
  },
  {
    age: 675,
    line1: 42.743,
    line2: 44.135,
    line3: 45.526,
    line4: 46.918,
    line5: 48.309,
    line6: 49.701,
    line7: 51.092,
  },
  {
    age: 676,
    line1: 42.748,
    line2: 44.139,
    line3: 45.531,
    line4: 46.923,
    line5: 48.314,
    line6: 49.706,
    line7: 51.098,
  },
  {
    age: 677,
    line1: 42.752,
    line2: 44.144,
    line3: 45.536,
    line4: 46.928,
    line5: 48.319,
    line6: 49.711,
    line7: 51.103,
  },
  {
    age: 678,
    line1: 42.758,
    line2: 44.149,
    line3: 45.541,
    line4: 46.932,
    line5: 48.324,
    line6: 49.716,
    line7: 51.107,
  },
  {
    age: 679,
    line1: 42.762,
    line2: 44.154,
    line3: 45.546,
    line4: 46.937,
    line5: 48.329,
    line6: 49.721,
    line7: 51.112,
  },
  {
    age: 680,
    line1: 42.767,
    line2: 44.159,
    line3: 45.55,
    line4: 46.942,
    line5: 48.334,
    line6: 49.726,
    line7: 51.118,
  },
  {
    age: 681,
    line1: 42.771,
    line2: 44.163,
    line3: 45.555,
    line4: 46.947,
    line5: 48.339,
    line6: 49.731,
    line7: 51.123,
  },
  {
    age: 682,
    line1: 42.776,
    line2: 44.168,
    line3: 45.56,
    line4: 46.952,
    line5: 48.344,
    line6: 49.736,
    line7: 51.128,
  },
  {
    age: 683,
    line1: 42.78,
    line2: 44.172,
    line3: 45.565,
    line4: 46.957,
    line5: 48.349,
    line6: 49.741,
    line7: 51.134,
  },
  {
    age: 684,
    line1: 42.786,
    line2: 44.178,
    line3: 45.57,
    line4: 46.962,
    line5: 48.354,
    line6: 49.746,
    line7: 51.138,
  },
  {
    age: 685,
    line1: 42.79,
    line2: 44.182,
    line3: 45.575,
    line4: 46.967,
    line5: 48.359,
    line6: 49.751,
    line7: 51.143,
  },
  {
    age: 686,
    line1: 42.795,
    line2: 44.187,
    line3: 45.579,
    line4: 46.971,
    line5: 48.364,
    line6: 49.756,
    line7: 51.148,
  },
  {
    age: 687,
    line1: 42.799,
    line2: 44.192,
    line3: 45.584,
    line4: 46.976,
    line5: 48.369,
    line6: 49.761,
    line7: 51.153,
  },
  {
    age: 688,
    line1: 42.804,
    line2: 44.196,
    line3: 45.589,
    line4: 46.981,
    line5: 48.374,
    line6: 49.766,
    line7: 51.159,
  },
  {
    age: 689,
    line1: 42.808,
    line2: 44.201,
    line3: 45.593,
    line4: 46.986,
    line5: 48.379,
    line6: 49.771,
    line7: 51.164,
  },
  {
    age: 690,
    line1: 42.812,
    line2: 44.205,
    line3: 45.598,
    line4: 46.991,
    line5: 48.384,
    line6: 49.776,
    line7: 51.169,
  },
  {
    age: 691,
    line1: 42.818,
    line2: 44.211,
    line3: 45.603,
    line4: 46.996,
    line5: 48.388,
    line6: 49.781,
    line7: 51.173,
  },
  {
    age: 692,
    line1: 42.823,
    line2: 44.215,
    line3: 45.608,
    line4: 47,
    line5: 48.393,
    line6: 49.786,
    line7: 51.178,
  },
  {
    age: 693,
    line1: 42.827,
    line2: 44.22,
    line3: 45.613,
    line4: 47.005,
    line5: 48.398,
    line6: 49.791,
    line7: 51.184,
  },
  {
    age: 694,
    line1: 42.831,
    line2: 44.224,
    line3: 45.617,
    line4: 47.01,
    line5: 48.403,
    line6: 49.796,
    line7: 51.189,
  },
  {
    age: 695,
    line1: 42.836,
    line2: 44.229,
    line3: 45.622,
    line4: 47.015,
    line5: 48.408,
    line6: 49.801,
    line7: 51.194,
  },
  {
    age: 696,
    line1: 42.84,
    line2: 44.233,
    line3: 45.627,
    line4: 47.02,
    line5: 48.413,
    line6: 49.806,
    line7: 51.199,
  },
  {
    age: 697,
    line1: 42.846,
    line2: 44.239,
    line3: 45.632,
    line4: 47.024,
    line5: 48.417,
    line6: 49.81,
    line7: 51.203,
  },
  {
    age: 698,
    line1: 42.85,
    line2: 44.243,
    line3: 45.636,
    line4: 47.029,
    line5: 48.422,
    line6: 49.815,
    line7: 51.208,
  },
  {
    age: 699,
    line1: 42.855,
    line2: 44.248,
    line3: 45.641,
    line4: 47.034,
    line5: 48.427,
    line6: 49.82,
    line7: 51.214,
  },
  {
    age: 700,
    line1: 42.859,
    line2: 44.252,
    line3: 45.646,
    line4: 47.039,
    line5: 48.432,
    line6: 49.825,
    line7: 51.219,
  },
  {
    age: 701,
    line1: 42.863,
    line2: 44.257,
    line3: 45.65,
    line4: 47.044,
    line5: 48.437,
    line6: 49.83,
    line7: 51.224,
  },
  {
    age: 702,
    line1: 42.868,
    line2: 44.261,
    line3: 45.655,
    line4: 47.048,
    line5: 48.442,
    line6: 49.836,
    line7: 51.229,
  },
  {
    age: 703,
    line1: 42.872,
    line2: 44.266,
    line3: 45.659,
    line4: 47.053,
    line5: 48.447,
    line6: 49.841,
    line7: 51.234,
  },
  {
    age: 704,
    line1: 42.878,
    line2: 44.271,
    line3: 45.665,
    line4: 47.058,
    line5: 48.451,
    line6: 49.845,
    line7: 51.238,
  },
  {
    age: 705,
    line1: 42.882,
    line2: 44.276,
    line3: 45.669,
    line4: 47.063,
    line5: 48.456,
    line6: 49.85,
    line7: 51.243,
  },
  {
    age: 706,
    line1: 42.886,
    line2: 44.28,
    line3: 45.674,
    line4: 47.067,
    line5: 48.461,
    line6: 49.855,
    line7: 51.248,
  },
  {
    age: 707,
    line1: 42.891,
    line2: 44.285,
    line3: 45.678,
    line4: 47.072,
    line5: 48.466,
    line6: 49.86,
    line7: 51.254,
  },
  {
    age: 708,
    line1: 42.895,
    line2: 44.289,
    line3: 45.683,
    line4: 47.077,
    line5: 48.471,
    line6: 49.865,
    line7: 51.259,
  },
  {
    age: 709,
    line1: 42.899,
    line2: 44.293,
    line3: 45.688,
    line4: 47.082,
    line5: 48.476,
    line6: 49.87,
    line7: 51.264,
  },
  {
    age: 710,
    line1: 42.904,
    line2: 44.298,
    line3: 45.692,
    line4: 47.086,
    line5: 48.481,
    line6: 49.875,
    line7: 51.269,
  },
  {
    age: 711,
    line1: 42.909,
    line2: 44.303,
    line3: 45.697,
    line4: 47.091,
    line5: 48.485,
    line6: 49.879,
    line7: 51.273,
  },
  {
    age: 712,
    line1: 42.914,
    line2: 44.308,
    line3: 45.702,
    line4: 47.096,
    line5: 48.49,
    line6: 49.884,
    line7: 51.278,
  },
  {
    age: 713,
    line1: 42.918,
    line2: 44.312,
    line3: 45.706,
    line4: 47.1,
    line5: 48.495,
    line6: 49.889,
    line7: 51.283,
  },
  {
    age: 714,
    line1: 42.922,
    line2: 44.317,
    line3: 45.711,
    line4: 47.105,
    line5: 48.5,
    line6: 49.894,
    line7: 51.288,
  },
  {
    age: 715,
    line1: 42.927,
    line2: 44.321,
    line3: 45.715,
    line4: 47.11,
    line5: 48.504,
    line6: 49.899,
    line7: 51.293,
  },
  {
    age: 716,
    line1: 42.931,
    line2: 44.325,
    line3: 45.72,
    line4: 47.115,
    line5: 48.509,
    line6: 49.904,
    line7: 51.298,
  },
  {
    age: 717,
    line1: 42.937,
    line2: 44.331,
    line3: 45.725,
    line4: 47.119,
    line5: 48.514,
    line6: 49.908,
    line7: 51.302,
  },
  {
    age: 718,
    line1: 42.941,
    line2: 44.335,
    line3: 45.73,
    line4: 47.124,
    line5: 48.518,
    line6: 49.913,
    line7: 51.307,
  },
  {
    age: 719,
    line1: 42.945,
    line2: 44.34,
    line3: 45.734,
    line4: 47.129,
    line5: 48.523,
    line6: 49.918,
    line7: 51.312,
  },
  {
    age: 720,
    line1: 42.949,
    line2: 44.344,
    line3: 45.739,
    line4: 47.133,
    line5: 48.528,
    line6: 49.923,
    line7: 51.317,
  },
  {
    age: 721,
    line1: 42.954,
    line2: 44.348,
    line3: 45.743,
    line4: 47.138,
    line5: 48.533,
    line6: 49.928,
    line7: 51.322,
  },
  {
    age: 722,
    line1: 42.958,
    line2: 44.353,
    line3: 45.748,
    line4: 47.143,
    line5: 48.538,
    line6: 49.933,
    line7: 51.328,
  },
  {
    age: 723,
    line1: 42.962,
    line2: 44.357,
    line3: 45.752,
    line4: 47.147,
    line5: 48.542,
    line6: 49.938,
    line7: 51.333,
  },
  {
    age: 724,
    line1: 42.968,
    line2: 44.362,
    line3: 45.757,
    line4: 47.152,
    line5: 48.547,
    line6: 49.942,
    line7: 51.336,
  },
  {
    age: 725,
    line1: 42.972,
    line2: 44.367,
    line3: 45.762,
    line4: 47.157,
    line5: 48.552,
    line6: 49.946,
    line7: 51.341,
  },
  {
    age: 726,
    line1: 42.976,
    line2: 44.371,
    line3: 45.766,
    line4: 47.161,
    line5: 48.556,
    line6: 49.951,
    line7: 51.346,
  },
  {
    age: 727,
    line1: 42.98,
    line2: 44.376,
    line3: 45.771,
    line4: 47.166,
    line5: 48.561,
    line6: 49.956,
    line7: 51.352,
  },
  {
    age: 728,
    line1: 42.985,
    line2: 44.38,
    line3: 45.775,
    line4: 47.171,
    line5: 48.566,
    line6: 49.961,
    line7: 51.357,
  },
  {
    age: 729,
    line1: 42.989,
    line2: 44.384,
    line3: 45.78,
    line4: 47.175,
    line5: 48.571,
    line6: 49.966,
    line7: 51.362,
  },
  {
    age: 730,
    line1: 42.993,
    line2: 44.389,
    line3: 45.784,
    line4: 47.18,
    line5: 48.575,
    line6: 49.971,
    line7: 51.367,
  },
];

export const MATURE_BOYS_WEIGHT_LINE_COORDS = [
  {
    age: 0,
    line1: 2.08,
    line2: 2.459,
    line3: 2.881,
    line4: 3.346,
    line5: 3.859,
    line6: 4.419,
    line7: 5.031,
  },
  {
    age: 1,
    line1: 2.065,
    line2: 2.437,
    line3: 2.854,
    line4: 3.317,
    line5: 3.83,
    line6: 4.394,
    line7: 5.013,
  },
  {
    age: 2,
    line1: 2.08,
    line2: 2.454,
    line3: 2.872,
    line4: 3.337,
    line5: 3.852,
    line6: 4.421,
    line7: 5.045,
  },
  {
    age: 3,
    line1: 2.1,
    line2: 2.475,
    line3: 2.895,
    line4: 3.363,
    line5: 3.881,
    line6: 4.453,
    line7: 5.083,
  },
  {
    age: 4,
    line1: 2.122,
    line2: 2.499,
    line3: 2.921,
    line4: 3.392,
    line5: 3.913,
    line6: 4.49,
    line7: 5.124,
  },
  {
    age: 5,
    line1: 2.146,
    line2: 2.525,
    line3: 2.949,
    line4: 3.422,
    line5: 3.947,
    line6: 4.528,
    line7: 5.167,
  },
  {
    age: 6,
    line1: 2.17,
    line2: 2.551,
    line3: 2.978,
    line4: 3.454,
    line5: 3.983,
    line6: 4.568,
    line7: 5.211,
  },
  {
    age: 7,
    line1: 2.195,
    line2: 2.579,
    line3: 3.009,
    line4: 3.488,
    line5: 4.02,
    line6: 4.609,
    line7: 5.257,
  },
  {
    age: 8,
    line1: 2.221,
    line2: 2.607,
    line3: 3.04,
    line4: 3.522,
    line5: 4.058,
    line6: 4.65,
    line7: 5.303,
  },
  {
    age: 9,
    line1: 2.248,
    line2: 2.637,
    line3: 3.072,
    line4: 3.558,
    line5: 4.097,
    line6: 4.693,
    line7: 5.351,
  },
  {
    age: 10,
    line1: 2.276,
    line2: 2.667,
    line3: 3.105,
    line4: 3.594,
    line5: 4.137,
    line6: 4.738,
    line7: 5.4,
  },
  {
    age: 11,
    line1: 2.304,
    line2: 2.698,
    line3: 3.14,
    line4: 3.632,
    line5: 4.179,
    line6: 4.784,
    line7: 5.451,
  },
  {
    age: 12,
    line1: 2.333,
    line2: 2.73,
    line3: 3.175,
    line4: 3.671,
    line5: 4.222,
    line6: 4.831,
    line7: 5.503,
  },
  {
    age: 13,
    line1: 2.363,
    line2: 2.764,
    line3: 3.212,
    line4: 3.711,
    line5: 4.266,
    line6: 4.88,
    line7: 5.558,
  },
  {
    age: 14,
    line1: 2.395,
    line2: 2.798,
    line3: 3.249,
    line4: 3.753,
    line5: 4.312,
    line6: 4.931,
    line7: 5.613,
  },
  {
    age: 15,
    line1: 2.426,
    line2: 2.833,
    line3: 3.288,
    line4: 3.796,
    line5: 4.359,
    line6: 4.983,
    line7: 5.671,
  },
  {
    age: 16,
    line1: 2.459,
    line2: 2.869,
    line3: 3.327,
    line4: 3.839,
    line5: 4.407,
    line6: 5.035,
    line7: 5.729,
  },
  {
    age: 17,
    line1: 2.491,
    line2: 2.905,
    line3: 3.367,
    line4: 3.883,
    line5: 4.455,
    line6: 5.089,
    line7: 5.787,
  },
  {
    age: 18,
    line1: 2.524,
    line2: 2.941,
    line3: 3.407,
    line4: 3.927,
    line5: 4.504,
    line6: 5.143,
    line7: 5.847,
  },
  {
    age: 19,
    line1: 2.557,
    line2: 2.977,
    line3: 3.448,
    line4: 3.971,
    line5: 4.553,
    line6: 5.196,
    line7: 5.906,
  },
  {
    age: 20,
    line1: 2.59,
    line2: 3.014,
    line3: 3.488,
    line4: 4.016,
    line5: 4.602,
    line6: 5.25,
    line7: 5.965,
  },
  {
    age: 21,
    line1: 2.624,
    line2: 3.051,
    line3: 3.528,
    line4: 4.06,
    line5: 4.651,
    line6: 5.304,
    line7: 6.024,
  },
  {
    age: 22,
    line1: 2.657,
    line2: 3.087,
    line3: 3.569,
    line4: 4.105,
    line5: 4.7,
    line6: 5.358,
    line7: 6.083,
  },
  {
    age: 23,
    line1: 2.69,
    line2: 3.124,
    line3: 3.609,
    line4: 4.149,
    line5: 4.748,
    line6: 5.411,
    line7: 6.142,
  },
  {
    age: 24,
    line1: 2.723,
    line2: 3.16,
    line3: 3.649,
    line4: 4.193,
    line5: 4.797,
    line6: 5.464,
    line7: 6.2,
  },
  {
    age: 25,
    line1: 2.756,
    line2: 3.197,
    line3: 3.689,
    line4: 4.237,
    line5: 4.845,
    line6: 5.517,
    line7: 6.259,
  },
  {
    age: 26,
    line1: 2.789,
    line2: 3.233,
    line3: 3.729,
    line4: 4.281,
    line5: 4.893,
    line6: 5.57,
    line7: 6.316,
  },
  {
    age: 27,
    line1: 2.822,
    line2: 3.269,
    line3: 3.768,
    line4: 4.324,
    line5: 4.94,
    line6: 5.622,
    line7: 6.373,
  },
  {
    age: 28,
    line1: 2.854,
    line2: 3.305,
    line3: 3.807,
    line4: 4.367,
    line5: 4.988,
    line6: 5.674,
    line7: 6.43,
  },
  {
    age: 29,
    line1: 2.887,
    line2: 3.34,
    line3: 3.847,
    line4: 4.41,
    line5: 5.035,
    line6: 5.725,
    line7: 6.487,
  },
  {
    age: 30,
    line1: 2.919,
    line2: 3.376,
    line3: 3.885,
    line4: 4.452,
    line5: 5.081,
    line6: 5.776,
    line7: 6.542,
  },
  {
    age: 31,
    line1: 2.951,
    line2: 3.411,
    line3: 3.924,
    line4: 4.495,
    line5: 5.127,
    line6: 5.827,
    line7: 6.597,
  },
  {
    age: 32,
    line1: 2.982,
    line2: 3.445,
    line3: 3.962,
    line4: 4.536,
    line5: 5.173,
    line6: 5.877,
    line7: 6.652,
  },
  {
    age: 33,
    line1: 3.014,
    line2: 3.48,
    line3: 4,
    line4: 4.578,
    line5: 5.218,
    line6: 5.926,
    line7: 6.706,
  },
  {
    age: 34,
    line1: 3.045,
    line2: 3.514,
    line3: 4.037,
    line4: 4.618,
    line5: 5.263,
    line6: 5.975,
    line7: 6.759,
  },
  {
    age: 35,
    line1: 3.076,
    line2: 3.548,
    line3: 4.074,
    line4: 4.659,
    line5: 5.307,
    line6: 6.023,
    line7: 6.812,
  },
  {
    age: 36,
    line1: 3.107,
    line2: 3.581,
    line3: 4.111,
    line4: 4.699,
    line5: 5.351,
    line6: 6.071,
    line7: 6.864,
  },
  {
    age: 37,
    line1: 3.137,
    line2: 3.615,
    line3: 4.147,
    line4: 4.739,
    line5: 5.394,
    line6: 6.118,
    line7: 6.915,
  },
  {
    age: 38,
    line1: 3.167,
    line2: 3.648,
    line3: 4.183,
    line4: 4.778,
    line5: 5.437,
    line6: 6.164,
    line7: 6.965,
  },
  {
    age: 39,
    line1: 3.197,
    line2: 3.68,
    line3: 4.219,
    line4: 4.817,
    line5: 5.479,
    line6: 6.21,
    line7: 7.016,
  },
  {
    age: 40,
    line1: 3.227,
    line2: 3.712,
    line3: 4.254,
    line4: 4.855,
    line5: 5.521,
    line6: 6.256,
    line7: 7.065,
  },
  {
    age: 41,
    line1: 3.256,
    line2: 3.744,
    line3: 4.289,
    line4: 4.893,
    line5: 5.562,
    line6: 6.3,
    line7: 7.114,
  },
  {
    age: 42,
    line1: 3.285,
    line2: 3.776,
    line3: 4.323,
    line4: 4.93,
    line5: 5.603,
    line6: 6.345,
    line7: 7.162,
  },
  {
    age: 43,
    line1: 3.314,
    line2: 3.807,
    line3: 4.357,
    line4: 4.967,
    line5: 5.643,
    line6: 6.388,
    line7: 7.209,
  },
  {
    age: 44,
    line1: 3.342,
    line2: 3.838,
    line3: 4.391,
    line4: 5.004,
    line5: 5.683,
    line6: 6.432,
    line7: 7.256,
  },
  {
    age: 45,
    line1: 3.37,
    line2: 3.869,
    line3: 4.424,
    line4: 5.04,
    line5: 5.722,
    line6: 6.475,
    line7: 7.303,
  },
  {
    age: 46,
    line1: 3.398,
    line2: 3.9,
    line3: 4.457,
    line4: 5.076,
    line5: 5.761,
    line6: 6.517,
    line7: 7.349,
  },
  {
    age: 47,
    line1: 3.426,
    line2: 3.93,
    line3: 4.49,
    line4: 5.112,
    line5: 5.8,
    line6: 6.558,
    line7: 7.394,
  },
  {
    age: 48,
    line1: 3.454,
    line2: 3.96,
    line3: 4.522,
    line4: 5.147,
    line5: 5.838,
    line6: 6.6,
    line7: 7.438,
  },
  {
    age: 49,
    line1: 3.481,
    line2: 3.989,
    line3: 4.555,
    line4: 5.182,
    line5: 5.875,
    line6: 6.641,
    line7: 7.483,
  },
  {
    age: 50,
    line1: 3.508,
    line2: 4.018,
    line3: 4.586,
    line4: 5.216,
    line5: 5.913,
    line6: 6.681,
    line7: 7.526,
  },
  {
    age: 51,
    line1: 3.534,
    line2: 4.047,
    line3: 4.618,
    line4: 5.25,
    line5: 5.949,
    line6: 6.721,
    line7: 7.569,
  },
  {
    age: 52,
    line1: 3.561,
    line2: 4.076,
    line3: 4.649,
    line4: 5.284,
    line5: 5.986,
    line6: 6.76,
    line7: 7.612,
  },
  {
    age: 53,
    line1: 3.587,
    line2: 4.104,
    line3: 4.68,
    line4: 5.317,
    line5: 6.022,
    line6: 6.799,
    line7: 7.654,
  },
  {
    age: 54,
    line1: 3.613,
    line2: 4.133,
    line3: 4.71,
    line4: 5.35,
    line5: 6.057,
    line6: 6.837,
    line7: 7.696,
  },
  {
    age: 55,
    line1: 3.639,
    line2: 4.16,
    line3: 4.74,
    line4: 5.383,
    line5: 6.093,
    line6: 6.876,
    line7: 7.737,
  },
  {
    age: 56,
    line1: 3.664,
    line2: 4.188,
    line3: 4.77,
    line4: 5.415,
    line5: 6.128,
    line6: 6.913,
    line7: 7.777,
  },
  {
    age: 57,
    line1: 3.689,
    line2: 4.215,
    line3: 4.8,
    line4: 5.447,
    line5: 6.162,
    line6: 6.95,
    line7: 7.817,
  },
  {
    age: 58,
    line1: 3.714,
    line2: 4.242,
    line3: 4.829,
    line4: 5.478,
    line5: 6.196,
    line6: 6.987,
    line7: 7.857,
  },
  {
    age: 59,
    line1: 3.739,
    line2: 4.269,
    line3: 4.858,
    line4: 5.51,
    line5: 6.23,
    line6: 7.024,
    line7: 7.896,
  },
  {
    age: 60,
    line1: 3.764,
    line2: 4.296,
    line3: 4.887,
    line4: 5.541,
    line5: 6.263,
    line6: 7.06,
    line7: 7.935,
  },
  {
    age: 61,
    line1: 3.788,
    line2: 4.322,
    line3: 4.915,
    line4: 5.571,
    line5: 6.296,
    line6: 7.095,
    line7: 7.974,
  },
  {
    age: 62,
    line1: 3.812,
    line2: 4.348,
    line3: 4.943,
    line4: 5.602,
    line5: 6.329,
    line6: 7.131,
    line7: 8.012,
  },
  {
    age: 63,
    line1: 3.836,
    line2: 4.374,
    line3: 4.971,
    line4: 5.632,
    line5: 6.362,
    line6: 7.166,
    line7: 8.049,
  },
  {
    age: 64,
    line1: 3.86,
    line2: 4.4,
    line3: 4.999,
    line4: 5.662,
    line5: 6.394,
    line6: 7.2,
    line7: 8.087,
  },
  {
    age: 65,
    line1: 3.884,
    line2: 4.425,
    line3: 5.026,
    line4: 5.691,
    line5: 6.426,
    line6: 7.234,
    line7: 8.123,
  },
  {
    age: 66,
    line1: 3.907,
    line2: 4.45,
    line3: 5.053,
    line4: 5.72,
    line5: 6.457,
    line6: 7.268,
    line7: 8.16,
  },
  {
    age: 67,
    line1: 3.93,
    line2: 4.475,
    line3: 5.08,
    line4: 5.749,
    line5: 6.488,
    line6: 7.302,
    line7: 8.196,
  },
  {
    age: 68,
    line1: 3.953,
    line2: 4.5,
    line3: 5.107,
    line4: 5.778,
    line5: 6.519,
    line6: 7.335,
    line7: 8.232,
  },
  {
    age: 69,
    line1: 3.976,
    line2: 4.525,
    line3: 5.133,
    line4: 5.806,
    line5: 6.55,
    line6: 7.368,
    line7: 8.267,
  },
  {
    age: 70,
    line1: 3.998,
    line2: 4.549,
    line3: 5.159,
    line4: 5.835,
    line5: 6.58,
    line6: 7.4,
    line7: 8.302,
  },
  {
    age: 71,
    line1: 4.021,
    line2: 4.573,
    line3: 5.185,
    line4: 5.862,
    line5: 6.61,
    line6: 7.433,
    line7: 8.337,
  },
  {
    age: 72,
    line1: 4.043,
    line2: 4.597,
    line3: 5.211,
    line4: 5.89,
    line5: 6.639,
    line6: 7.464,
    line7: 8.371,
  },
  {
    age: 73,
    line1: 4.065,
    line2: 4.62,
    line3: 5.236,
    line4: 5.917,
    line5: 6.669,
    line6: 7.496,
    line7: 8.405,
  },
  {
    age: 74,
    line1: 4.087,
    line2: 4.644,
    line3: 5.262,
    line4: 5.944,
    line5: 6.698,
    line6: 7.527,
    line7: 8.438,
  },
  {
    age: 75,
    line1: 4.108,
    line2: 4.667,
    line3: 5.287,
    line4: 5.971,
    line5: 6.727,
    line6: 7.558,
    line7: 8.471,
  },
  {
    age: 76,
    line1: 4.13,
    line2: 4.69,
    line3: 5.311,
    line4: 5.998,
    line5: 6.755,
    line6: 7.589,
    line7: 8.504,
  },
  {
    age: 77,
    line1: 4.151,
    line2: 4.713,
    line3: 5.336,
    line4: 6.024,
    line5: 6.783,
    line6: 7.619,
    line7: 8.537,
  },
  {
    age: 78,
    line1: 4.172,
    line2: 4.736,
    line3: 5.36,
    line4: 6.05,
    line5: 6.811,
    line6: 7.649,
    line7: 8.569,
  },
  {
    age: 79,
    line1: 4.193,
    line2: 4.758,
    line3: 5.384,
    line4: 6.076,
    line5: 6.839,
    line6: 7.679,
    line7: 8.601,
  },
  {
    age: 80,
    line1: 4.213,
    line2: 4.78,
    line3: 5.408,
    line4: 6.102,
    line5: 6.867,
    line6: 7.709,
    line7: 8.633,
  },
  {
    age: 81,
    line1: 4.234,
    line2: 4.802,
    line3: 5.432,
    line4: 6.127,
    line5: 6.894,
    line6: 7.738,
    line7: 8.664,
  },
  {
    age: 82,
    line1: 4.254,
    line2: 4.824,
    line3: 5.455,
    line4: 6.152,
    line5: 6.921,
    line6: 7.767,
    line7: 8.695,
  },
  {
    age: 83,
    line1: 4.274,
    line2: 4.846,
    line3: 5.478,
    line4: 6.177,
    line5: 6.948,
    line6: 7.795,
    line7: 8.726,
  },
  {
    age: 84,
    line1: 4.294,
    line2: 4.867,
    line3: 5.501,
    line4: 6.202,
    line5: 6.974,
    line6: 7.824,
    line7: 8.756,
  },
  {
    age: 85,
    line1: 4.314,
    line2: 4.888,
    line3: 5.524,
    line4: 6.226,
    line5: 7,
    line6: 7.852,
    line7: 8.787,
  },
  {
    age: 86,
    line1: 4.334,
    line2: 4.909,
    line3: 5.547,
    line4: 6.251,
    line5: 7.027,
    line6: 7.88,
    line7: 8.817,
  },
  {
    age: 87,
    line1: 4.353,
    line2: 4.93,
    line3: 5.569,
    line4: 6.275,
    line5: 7.052,
    line6: 7.907,
    line7: 8.846,
  },
  {
    age: 88,
    line1: 4.372,
    line2: 4.951,
    line3: 5.591,
    line4: 6.299,
    line5: 7.078,
    line6: 7.935,
    line7: 8.876,
  },
  {
    age: 89,
    line1: 4.392,
    line2: 4.972,
    line3: 5.614,
    line4: 6.322,
    line5: 7.103,
    line6: 7.962,
    line7: 8.905,
  },
  {
    age: 90,
    line1: 4.41,
    line2: 4.992,
    line3: 5.635,
    line4: 6.346,
    line5: 7.128,
    line6: 7.989,
    line7: 8.934,
  },
  {
    age: 91,
    line1: 4.429,
    line2: 5.012,
    line3: 5.657,
    line4: 6.369,
    line5: 7.153,
    line6: 8.016,
    line7: 8.962,
  },
  {
    age: 92,
    line1: 4.448,
    line2: 5.032,
    line3: 5.679,
    line4: 6.392,
    line5: 7.178,
    line6: 8.042,
    line7: 8.991,
  },
  {
    age: 93,
    line1: 4.466,
    line2: 5.052,
    line3: 5.7,
    line4: 6.415,
    line5: 7.203,
    line6: 8.069,
    line7: 9.019,
  },
  {
    age: 94,
    line1: 4.485,
    line2: 5.072,
    line3: 5.721,
    line4: 6.438,
    line5: 7.227,
    line6: 8.095,
    line7: 9.047,
  },
  {
    age: 95,
    line1: 4.503,
    line2: 5.091,
    line3: 5.742,
    line4: 6.46,
    line5: 7.251,
    line6: 8.121,
    line7: 9.075,
  },
  {
    age: 96,
    line1: 4.521,
    line2: 5.111,
    line3: 5.763,
    line4: 6.482,
    line5: 7.275,
    line6: 8.146,
    line7: 9.102,
  },
  {
    age: 97,
    line1: 4.539,
    line2: 5.13,
    line3: 5.783,
    line4: 6.505,
    line5: 7.299,
    line6: 8.172,
    line7: 9.13,
  },
  {
    age: 98,
    line1: 4.557,
    line2: 5.149,
    line3: 5.804,
    line4: 6.526,
    line5: 7.322,
    line6: 8.197,
    line7: 9.157,
  },
  {
    age: 99,
    line1: 4.574,
    line2: 5.168,
    line3: 5.824,
    line4: 6.548,
    line5: 7.346,
    line6: 8.222,
    line7: 9.184,
  },
  {
    age: 100,
    line1: 4.592,
    line2: 5.187,
    line3: 5.844,
    line4: 6.57,
    line5: 7.369,
    line6: 8.247,
    line7: 9.211,
  },
  {
    age: 101,
    line1: 4.609,
    line2: 5.205,
    line3: 5.864,
    line4: 6.591,
    line5: 7.392,
    line6: 8.272,
    line7: 9.237,
  },
  {
    age: 102,
    line1: 4.626,
    line2: 5.224,
    line3: 5.884,
    line4: 6.613,
    line5: 7.415,
    line6: 8.296,
    line7: 9.263,
  },
  {
    age: 103,
    line1: 4.644,
    line2: 5.242,
    line3: 5.904,
    line4: 6.634,
    line5: 7.437,
    line6: 8.321,
    line7: 9.29,
  },
  {
    age: 104,
    line1: 4.66,
    line2: 5.26,
    line3: 5.923,
    line4: 6.655,
    line5: 7.46,
    line6: 8.345,
    line7: 9.315,
  },
  {
    age: 105,
    line1: 4.677,
    line2: 5.278,
    line3: 5.943,
    line4: 6.676,
    line5: 7.482,
    line6: 8.369,
    line7: 9.341,
  },
  {
    age: 106,
    line1: 4.694,
    line2: 5.296,
    line3: 5.962,
    line4: 6.696,
    line5: 7.504,
    line6: 8.392,
    line7: 9.367,
  },
  {
    age: 107,
    line1: 4.711,
    line2: 5.314,
    line3: 5.981,
    line4: 6.717,
    line5: 7.526,
    line6: 8.416,
    line7: 9.392,
  },
  {
    age: 108,
    line1: 4.727,
    line2: 5.332,
    line3: 6,
    line4: 6.737,
    line5: 7.548,
    line6: 8.44,
    line7: 9.418,
  },
  {
    age: 109,
    line1: 4.743,
    line2: 5.349,
    line3: 6.019,
    line4: 6.757,
    line5: 7.57,
    line6: 8.463,
    line7: 9.443,
  },
  {
    age: 110,
    line1: 4.759,
    line2: 5.367,
    line3: 6.037,
    line4: 6.777,
    line5: 7.591,
    line6: 8.486,
    line7: 9.467,
  },
  {
    age: 111,
    line1: 4.775,
    line2: 5.384,
    line3: 6.056,
    line4: 6.797,
    line5: 7.613,
    line6: 8.509,
    line7: 9.492,
  },
  {
    age: 112,
    line1: 4.791,
    line2: 5.401,
    line3: 6.074,
    line4: 6.817,
    line5: 7.634,
    line6: 8.532,
    line7: 9.516,
  },
  {
    age: 113,
    line1: 4.807,
    line2: 5.418,
    line3: 6.093,
    line4: 6.836,
    line5: 7.655,
    line6: 8.555,
    line7: 9.541,
  },
  {
    age: 114,
    line1: 4.823,
    line2: 5.435,
    line3: 6.111,
    line4: 6.856,
    line5: 7.676,
    line6: 8.577,
    line7: 9.565,
  },
  {
    age: 115,
    line1: 4.838,
    line2: 5.452,
    line3: 6.129,
    line4: 6.875,
    line5: 7.697,
    line6: 8.6,
    line7: 9.59,
  },
  {
    age: 116,
    line1: 4.854,
    line2: 5.468,
    line3: 6.147,
    line4: 6.894,
    line5: 7.717,
    line6: 8.622,
    line7: 9.613,
  },
  {
    age: 117,
    line1: 4.869,
    line2: 5.485,
    line3: 6.164,
    line4: 6.914,
    line5: 7.738,
    line6: 8.644,
    line7: 9.637,
  },
  {
    age: 118,
    line1: 4.884,
    line2: 5.501,
    line3: 6.182,
    line4: 6.932,
    line5: 7.758,
    line6: 8.666,
    line7: 9.661,
  },
  {
    age: 119,
    line1: 4.9,
    line2: 5.517,
    line3: 6.199,
    line4: 6.951,
    line5: 7.779,
    line6: 8.688,
    line7: 9.684,
  },
  {
    age: 120,
    line1: 4.915,
    line2: 5.533,
    line3: 6.217,
    line4: 6.97,
    line5: 7.799,
    line6: 8.709,
    line7: 9.707,
  },
  {
    age: 121,
    line1: 4.929,
    line2: 5.549,
    line3: 6.234,
    line4: 6.988,
    line5: 7.819,
    line6: 8.731,
    line7: 9.731,
  },
  {
    age: 122,
    line1: 4.944,
    line2: 5.565,
    line3: 6.251,
    line4: 7.007,
    line5: 7.838,
    line6: 8.752,
    line7: 9.754,
  },
  {
    age: 123,
    line1: 4.959,
    line2: 5.581,
    line3: 6.268,
    line4: 7.025,
    line5: 7.858,
    line6: 8.773,
    line7: 9.777,
  },
  {
    age: 124,
    line1: 4.974,
    line2: 5.597,
    line3: 6.285,
    line4: 7.043,
    line5: 7.878,
    line6: 8.794,
    line7: 9.8,
  },
  {
    age: 125,
    line1: 4.988,
    line2: 5.613,
    line3: 6.302,
    line4: 7.062,
    line5: 7.897,
    line6: 8.815,
    line7: 9.822,
  },
  {
    age: 126,
    line1: 5.002,
    line2: 5.628,
    line3: 6.318,
    line4: 7.079,
    line5: 7.917,
    line6: 8.836,
    line7: 9.845,
  },
  {
    age: 127,
    line1: 5.017,
    line2: 5.643,
    line3: 6.335,
    line4: 7.097,
    line5: 7.936,
    line6: 8.857,
    line7: 9.867,
  },
  {
    age: 128,
    line1: 5.031,
    line2: 5.658,
    line3: 6.351,
    line4: 7.115,
    line5: 7.955,
    line6: 8.878,
    line7: 9.889,
  },
  {
    age: 129,
    line1: 5.045,
    line2: 5.674,
    line3: 6.368,
    line4: 7.132,
    line5: 7.974,
    line6: 8.898,
    line7: 9.912,
  },
  {
    age: 130,
    line1: 5.059,
    line2: 5.689,
    line3: 6.384,
    line4: 7.15,
    line5: 7.993,
    line6: 8.919,
    line7: 9.934,
  },
  {
    age: 131,
    line1: 5.073,
    line2: 5.704,
    line3: 6.4,
    line4: 7.167,
    line5: 8.012,
    line6: 8.939,
    line7: 9.956,
  },
  {
    age: 132,
    line1: 5.086,
    line2: 5.718,
    line3: 6.416,
    line4: 7.185,
    line5: 8.03,
    line6: 8.959,
    line7: 9.978,
  },
  {
    age: 133,
    line1: 5.1,
    line2: 5.733,
    line3: 6.432,
    line4: 7.202,
    line5: 8.049,
    line6: 8.979,
    line7: 9.999,
  },
  {
    age: 134,
    line1: 5.113,
    line2: 5.748,
    line3: 6.448,
    line4: 7.219,
    line5: 8.067,
    line6: 8.999,
    line7: 10.021,
  },
  {
    age: 135,
    line1: 5.127,
    line2: 5.762,
    line3: 6.463,
    line4: 7.236,
    line5: 8.085,
    line6: 9.019,
    line7: 10.042,
  },
  {
    age: 136,
    line1: 5.14,
    line2: 5.777,
    line3: 6.479,
    line4: 7.252,
    line5: 8.104,
    line6: 9.038,
    line7: 10.064,
  },
  {
    age: 137,
    line1: 5.154,
    line2: 5.791,
    line3: 6.494,
    line4: 7.269,
    line5: 8.122,
    line6: 9.058,
    line7: 10.085,
  },
  {
    age: 138,
    line1: 5.167,
    line2: 5.805,
    line3: 6.51,
    line4: 7.286,
    line5: 8.14,
    line6: 9.077,
    line7: 10.106,
  },
  {
    age: 139,
    line1: 5.18,
    line2: 5.819,
    line3: 6.525,
    line4: 7.302,
    line5: 8.158,
    line6: 9.097,
    line7: 10.127,
  },
  {
    age: 140,
    line1: 5.193,
    line2: 5.833,
    line3: 6.54,
    line4: 7.319,
    line5: 8.175,
    line6: 9.116,
    line7: 10.148,
  },
  {
    age: 141,
    line1: 5.206,
    line2: 5.847,
    line3: 6.555,
    line4: 7.335,
    line5: 8.193,
    line6: 9.135,
    line7: 10.168,
  },
  {
    age: 142,
    line1: 5.219,
    line2: 5.861,
    line3: 6.57,
    line4: 7.351,
    line5: 8.21,
    line6: 9.154,
    line7: 10.189,
  },
  {
    age: 143,
    line1: 5.231,
    line2: 5.875,
    line3: 6.585,
    line4: 7.367,
    line5: 8.228,
    line6: 9.173,
    line7: 10.21,
  },
  {
    age: 144,
    line1: 5.244,
    line2: 5.888,
    line3: 6.6,
    line4: 7.383,
    line5: 8.245,
    line6: 9.192,
    line7: 10.23,
  },
  {
    age: 145,
    line1: 5.256,
    line2: 5.902,
    line3: 6.614,
    line4: 7.399,
    line5: 8.262,
    line6: 9.211,
    line7: 10.251,
  },
  {
    age: 146,
    line1: 5.269,
    line2: 5.915,
    line3: 6.629,
    line4: 7.415,
    line5: 8.28,
    line6: 9.229,
    line7: 10.271,
  },
  {
    age: 147,
    line1: 5.281,
    line2: 5.929,
    line3: 6.643,
    line4: 7.431,
    line5: 8.297,
    line6: 9.248,
    line7: 10.291,
  },
  {
    age: 148,
    line1: 5.294,
    line2: 5.942,
    line3: 6.658,
    line4: 7.446,
    line5: 8.314,
    line6: 9.266,
    line7: 10.311,
  },
  {
    age: 149,
    line1: 5.306,
    line2: 5.955,
    line3: 6.672,
    line4: 7.462,
    line5: 8.33,
    line6: 9.285,
    line7: 10.331,
  },
  {
    age: 150,
    line1: 5.318,
    line2: 5.968,
    line3: 6.686,
    line4: 7.477,
    line5: 8.347,
    line6: 9.303,
    line7: 10.351,
  },
  {
    age: 151,
    line1: 5.33,
    line2: 5.981,
    line3: 6.7,
    line4: 7.492,
    line5: 8.364,
    line6: 9.321,
    line7: 10.371,
  },
  {
    age: 152,
    line1: 5.342,
    line2: 5.994,
    line3: 6.714,
    line4: 7.508,
    line5: 8.38,
    line6: 9.339,
    line7: 10.39,
  },
  {
    age: 153,
    line1: 5.354,
    line2: 6.007,
    line3: 6.728,
    line4: 7.523,
    line5: 8.397,
    line6: 9.357,
    line7: 10.41,
  },
  {
    age: 154,
    line1: 5.365,
    line2: 6.02,
    line3: 6.742,
    line4: 7.538,
    line5: 8.413,
    line6: 9.375,
    line7: 10.429,
  },
  {
    age: 155,
    line1: 5.377,
    line2: 6.033,
    line3: 6.756,
    line4: 7.553,
    line5: 8.429,
    line6: 9.392,
    line7: 10.449,
  },
  {
    age: 156,
    line1: 5.389,
    line2: 6.045,
    line3: 6.77,
    line4: 7.568,
    line5: 8.446,
    line6: 9.41,
    line7: 10.468,
  },
  {
    age: 157,
    line1: 5.4,
    line2: 6.058,
    line3: 6.783,
    line4: 7.582,
    line5: 8.462,
    line6: 9.427,
    line7: 10.487,
  },
  {
    age: 158,
    line1: 5.412,
    line2: 6.07,
    line3: 6.797,
    line4: 7.597,
    line5: 8.478,
    line6: 9.445,
    line7: 10.506,
  },
  {
    age: 159,
    line1: 5.423,
    line2: 6.082,
    line3: 6.81,
    line4: 7.612,
    line5: 8.494,
    line6: 9.462,
    line7: 10.525,
  },
  {
    age: 160,
    line1: 5.434,
    line2: 6.095,
    line3: 6.823,
    line4: 7.626,
    line5: 8.509,
    line6: 9.48,
    line7: 10.544,
  },
  {
    age: 161,
    line1: 5.445,
    line2: 6.107,
    line3: 6.837,
    line4: 7.641,
    line5: 8.525,
    line6: 9.497,
    line7: 10.563,
  },
  {
    age: 162,
    line1: 5.457,
    line2: 6.119,
    line3: 6.85,
    line4: 7.655,
    line5: 8.541,
    line6: 9.514,
    line7: 10.582,
  },
  {
    age: 163,
    line1: 5.468,
    line2: 6.131,
    line3: 6.863,
    line4: 7.669,
    line5: 8.556,
    line6: 9.531,
    line7: 10.6,
  },
  {
    age: 164,
    line1: 5.479,
    line2: 6.143,
    line3: 6.876,
    line4: 7.683,
    line5: 8.572,
    line6: 9.548,
    line7: 10.619,
  },
  {
    age: 165,
    line1: 5.49,
    line2: 6.155,
    line3: 6.889,
    line4: 7.698,
    line5: 8.587,
    line6: 9.565,
    line7: 10.637,
  },
  {
    age: 166,
    line1: 5.5,
    line2: 6.167,
    line3: 6.902,
    line4: 7.712,
    line5: 8.602,
    line6: 9.581,
    line7: 10.655,
  },
  {
    age: 167,
    line1: 5.511,
    line2: 6.178,
    line3: 6.915,
    line4: 7.726,
    line5: 8.618,
    line6: 9.598,
    line7: 10.674,
  },
  {
    age: 168,
    line1: 5.522,
    line2: 6.19,
    line3: 6.927,
    line4: 7.739,
    line5: 8.633,
    line6: 9.615,
    line7: 10.692,
  },
  {
    age: 169,
    line1: 5.533,
    line2: 6.202,
    line3: 6.94,
    line4: 7.753,
    line5: 8.648,
    line6: 9.631,
    line7: 10.71,
  },
  {
    age: 170,
    line1: 5.543,
    line2: 6.213,
    line3: 6.952,
    line4: 7.767,
    line5: 8.663,
    line6: 9.648,
    line7: 10.728,
  },
  {
    age: 171,
    line1: 5.554,
    line2: 6.225,
    line3: 6.965,
    line4: 7.78,
    line5: 8.678,
    line6: 9.664,
    line7: 10.746,
  },
  {
    age: 172,
    line1: 5.564,
    line2: 6.236,
    line3: 6.977,
    line4: 7.794,
    line5: 8.693,
    line6: 9.68,
    line7: 10.764,
  },
  {
    age: 173,
    line1: 5.575,
    line2: 6.247,
    line3: 6.99,
    line4: 7.808,
    line5: 8.708,
    line6: 9.696,
    line7: 10.782,
  },
  {
    age: 174,
    line1: 5.585,
    line2: 6.259,
    line3: 7.002,
    line4: 7.821,
    line5: 8.722,
    line6: 9.713,
    line7: 10.799,
  },
  {
    age: 175,
    line1: 5.595,
    line2: 6.27,
    line3: 7.014,
    line4: 7.834,
    line5: 8.737,
    line6: 9.729,
    line7: 10.817,
  },
  {
    age: 176,
    line1: 5.605,
    line2: 6.281,
    line3: 7.026,
    line4: 7.848,
    line5: 8.751,
    line6: 9.745,
    line7: 10.835,
  },
  {
    age: 177,
    line1: 5.616,
    line2: 6.292,
    line3: 7.038,
    line4: 7.861,
    line5: 8.766,
    line6: 9.761,
    line7: 10.852,
  },
  {
    age: 178,
    line1: 5.626,
    line2: 6.303,
    line3: 7.05,
    line4: 7.874,
    line5: 8.78,
    line6: 9.776,
    line7: 10.87,
  },
  {
    age: 179,
    line1: 5.636,
    line2: 6.314,
    line3: 7.062,
    line4: 7.887,
    line5: 8.795,
    line6: 9.792,
    line7: 10.887,
  },
  {
    age: 180,
    line1: 5.646,
    line2: 6.325,
    line3: 7.074,
    line4: 7.9,
    line5: 8.809,
    line6: 9.808,
    line7: 10.904,
  },
  {
    age: 181,
    line1: 5.656,
    line2: 6.336,
    line3: 7.086,
    line4: 7.913,
    line5: 8.823,
    line6: 9.823,
    line7: 10.921,
  },
  {
    age: 182,
    line1: 5.665,
    line2: 6.346,
    line3: 7.098,
    line4: 7.926,
    line5: 8.837,
    line6: 9.839,
    line7: 10.939,
  },
  {
    age: 183,
    line1: 5.675,
    line2: 6.357,
    line3: 7.11,
    line4: 7.939,
    line5: 8.852,
    line6: 9.855,
    line7: 10.956,
  },
  {
    age: 184,
    line1: 5.685,
    line2: 6.368,
    line3: 7.121,
    line4: 7.952,
    line5: 8.866,
    line6: 9.87,
    line7: 10.973,
  },
  {
    age: 185,
    line1: 5.695,
    line2: 6.378,
    line3: 7.133,
    line4: 7.964,
    line5: 8.879,
    line6: 9.885,
    line7: 10.99,
  },
  {
    age: 186,
    line1: 5.704,
    line2: 6.389,
    line3: 7.144,
    line4: 7.977,
    line5: 8.893,
    line6: 9.901,
    line7: 11.007,
  },
  {
    age: 187,
    line1: 5.714,
    line2: 6.399,
    line3: 7.156,
    line4: 7.99,
    line5: 8.907,
    line6: 9.916,
    line7: 11.023,
  },
  {
    age: 188,
    line1: 5.723,
    line2: 6.41,
    line3: 7.167,
    line4: 8.002,
    line5: 8.921,
    line6: 9.931,
    line7: 11.04,
  },
  {
    age: 189,
    line1: 5.733,
    line2: 6.42,
    line3: 7.178,
    line4: 8.014,
    line5: 8.935,
    line6: 9.946,
    line7: 11.057,
  },
  {
    age: 190,
    line1: 5.742,
    line2: 6.43,
    line3: 7.19,
    line4: 8.027,
    line5: 8.948,
    line6: 9.961,
    line7: 11.074,
  },
  {
    age: 191,
    line1: 5.751,
    line2: 6.441,
    line3: 7.201,
    line4: 8.039,
    line5: 8.962,
    line6: 9.976,
    line7: 11.09,
  },
  {
    age: 192,
    line1: 5.761,
    line2: 6.451,
    line3: 7.212,
    line4: 8.052,
    line5: 8.975,
    line6: 9.991,
    line7: 11.106,
  },
  {
    age: 193,
    line1: 5.77,
    line2: 6.461,
    line3: 7.223,
    line4: 8.064,
    line5: 8.989,
    line6: 10.006,
    line7: 11.123,
  },
  {
    age: 194,
    line1: 5.779,
    line2: 6.471,
    line3: 7.234,
    line4: 8.076,
    line5: 9.002,
    line6: 10.021,
    line7: 11.139,
  },
  {
    age: 195,
    line1: 5.788,
    line2: 6.481,
    line3: 7.245,
    line4: 8.088,
    line5: 9.015,
    line6: 10.035,
    line7: 11.155,
  },
  {
    age: 196,
    line1: 5.797,
    line2: 6.491,
    line3: 7.256,
    line4: 8.1,
    line5: 9.029,
    line6: 10.05,
    line7: 11.172,
  },
  {
    age: 197,
    line1: 5.807,
    line2: 6.501,
    line3: 7.267,
    line4: 8.112,
    line5: 9.042,
    line6: 10.065,
    line7: 11.188,
  },
  {
    age: 198,
    line1: 5.815,
    line2: 6.511,
    line3: 7.278,
    line4: 8.124,
    line5: 9.055,
    line6: 10.079,
    line7: 11.204,
  },
  {
    age: 199,
    line1: 5.824,
    line2: 6.52,
    line3: 7.289,
    line4: 8.136,
    line5: 9.068,
    line6: 10.094,
    line7: 11.22,
  },
  {
    age: 200,
    line1: 5.833,
    line2: 6.53,
    line3: 7.299,
    line4: 8.148,
    line5: 9.081,
    line6: 10.108,
    line7: 11.236,
  },
  {
    age: 201,
    line1: 5.842,
    line2: 6.54,
    line3: 7.31,
    line4: 8.159,
    line5: 9.094,
    line6: 10.123,
    line7: 11.252,
  },
  {
    age: 202,
    line1: 5.851,
    line2: 6.55,
    line3: 7.321,
    line4: 8.171,
    line5: 9.107,
    line6: 10.137,
    line7: 11.268,
  },
  {
    age: 203,
    line1: 5.86,
    line2: 6.559,
    line3: 7.331,
    line4: 8.183,
    line5: 9.12,
    line6: 10.151,
    line7: 11.283,
  },
  {
    age: 204,
    line1: 5.868,
    line2: 6.569,
    line3: 7.342,
    line4: 8.194,
    line5: 9.133,
    line6: 10.165,
    line7: 11.299,
  },
  {
    age: 205,
    line1: 5.877,
    line2: 6.578,
    line3: 7.352,
    line4: 8.206,
    line5: 9.146,
    line6: 10.179,
    line7: 11.315,
  },
  {
    age: 206,
    line1: 5.886,
    line2: 6.588,
    line3: 7.363,
    line4: 8.217,
    line5: 9.158,
    line6: 10.193,
    line7: 11.331,
  },
  {
    age: 207,
    line1: 5.894,
    line2: 6.597,
    line3: 7.373,
    line4: 8.229,
    line5: 9.171,
    line6: 10.207,
    line7: 11.346,
  },
  {
    age: 208,
    line1: 5.903,
    line2: 6.607,
    line3: 7.383,
    line4: 8.24,
    line5: 9.184,
    line6: 10.221,
    line7: 11.361,
  },
  {
    age: 209,
    line1: 5.911,
    line2: 6.616,
    line3: 7.394,
    line4: 8.251,
    line5: 9.196,
    line6: 10.235,
    line7: 11.377,
  },
  {
    age: 210,
    line1: 5.92,
    line2: 6.625,
    line3: 7.404,
    line4: 8.263,
    line5: 9.209,
    line6: 10.249,
    line7: 11.392,
  },
  {
    age: 211,
    line1: 5.928,
    line2: 6.634,
    line3: 7.414,
    line4: 8.274,
    line5: 9.221,
    line6: 10.263,
    line7: 11.408,
  },
  {
    age: 212,
    line1: 5.937,
    line2: 6.644,
    line3: 7.424,
    line4: 8.285,
    line5: 9.233,
    line6: 10.276,
    line7: 11.423,
  },
  {
    age: 213,
    line1: 5.945,
    line2: 6.653,
    line3: 7.434,
    line4: 8.296,
    line5: 9.246,
    line6: 10.29,
    line7: 11.438,
  },
  {
    age: 214,
    line1: 5.953,
    line2: 6.662,
    line3: 7.444,
    line4: 8.307,
    line5: 9.258,
    line6: 10.304,
    line7: 11.453,
  },
  {
    age: 215,
    line1: 5.961,
    line2: 6.671,
    line3: 7.454,
    line4: 8.318,
    line5: 9.27,
    line6: 10.317,
    line7: 11.468,
  },
  {
    age: 216,
    line1: 5.97,
    line2: 6.68,
    line3: 7.464,
    line4: 8.329,
    line5: 9.282,
    line6: 10.331,
    line7: 11.483,
  },
  {
    age: 217,
    line1: 5.978,
    line2: 6.689,
    line3: 7.474,
    line4: 8.34,
    line5: 9.295,
    line6: 10.345,
    line7: 11.499,
  },
  {
    age: 218,
    line1: 5.986,
    line2: 6.698,
    line3: 7.484,
    line4: 8.351,
    line5: 9.307,
    line6: 10.358,
    line7: 11.513,
  },
  {
    age: 219,
    line1: 5.994,
    line2: 6.707,
    line3: 7.494,
    line4: 8.362,
    line5: 9.319,
    line6: 10.371,
    line7: 11.528,
  },
  {
    age: 220,
    line1: 6.002,
    line2: 6.716,
    line3: 7.504,
    line4: 8.373,
    line5: 9.331,
    line6: 10.384,
    line7: 11.543,
  },
  {
    age: 221,
    line1: 6.01,
    line2: 6.724,
    line3: 7.513,
    line4: 8.384,
    line5: 9.343,
    line6: 10.398,
    line7: 11.558,
  },
  {
    age: 222,
    line1: 6.018,
    line2: 6.733,
    line3: 7.523,
    line4: 8.394,
    line5: 9.355,
    line6: 10.411,
    line7: 11.573,
  },
  {
    age: 223,
    line1: 6.026,
    line2: 6.742,
    line3: 7.533,
    line4: 8.405,
    line5: 9.366,
    line6: 10.424,
    line7: 11.587,
  },
  {
    age: 224,
    line1: 6.034,
    line2: 6.751,
    line3: 7.542,
    line4: 8.416,
    line5: 9.378,
    line6: 10.437,
    line7: 11.602,
  },
  {
    age: 225,
    line1: 6.042,
    line2: 6.759,
    line3: 7.552,
    line4: 8.426,
    line5: 9.39,
    line6: 10.45,
    line7: 11.616,
  },
  {
    age: 226,
    line1: 6.049,
    line2: 6.768,
    line3: 7.561,
    line4: 8.437,
    line5: 9.402,
    line6: 10.464,
    line7: 11.631,
  },
  {
    age: 227,
    line1: 6.057,
    line2: 6.776,
    line3: 7.571,
    line4: 8.447,
    line5: 9.413,
    line6: 10.476,
    line7: 11.646,
  },
  {
    age: 228,
    line1: 6.065,
    line2: 6.785,
    line3: 7.58,
    line4: 8.458,
    line5: 9.425,
    line6: 10.489,
    line7: 11.66,
  },
  {
    age: 229,
    line1: 6.073,
    line2: 6.793,
    line3: 7.59,
    line4: 8.468,
    line5: 9.437,
    line6: 10.502,
    line7: 11.674,
  },
  {
    age: 230,
    line1: 6.08,
    line2: 6.802,
    line3: 7.599,
    line4: 8.479,
    line5: 9.448,
    line6: 10.515,
    line7: 11.689,
  },
  {
    age: 231,
    line1: 6.088,
    line2: 6.81,
    line3: 7.608,
    line4: 8.489,
    line5: 9.459,
    line6: 10.528,
    line7: 11.703,
  },
  {
    age: 232,
    line1: 6.096,
    line2: 6.819,
    line3: 7.618,
    line4: 8.499,
    line5: 9.471,
    line6: 10.541,
    line7: 11.717,
  },
  {
    age: 233,
    line1: 6.103,
    line2: 6.827,
    line3: 7.627,
    line4: 8.51,
    line5: 9.482,
    line6: 10.553,
    line7: 11.731,
  },
  {
    age: 234,
    line1: 6.111,
    line2: 6.836,
    line3: 7.636,
    line4: 8.52,
    line5: 9.494,
    line6: 10.566,
    line7: 11.746,
  },
  {
    age: 235,
    line1: 6.118,
    line2: 6.844,
    line3: 7.645,
    line4: 8.53,
    line5: 9.505,
    line6: 10.579,
    line7: 11.759,
  },
  {
    age: 236,
    line1: 6.126,
    line2: 6.852,
    line3: 7.655,
    line4: 8.54,
    line5: 9.516,
    line6: 10.591,
    line7: 11.774,
  },
  {
    age: 237,
    line1: 6.133,
    line2: 6.86,
    line3: 7.664,
    line4: 8.55,
    line5: 9.527,
    line6: 10.604,
    line7: 11.787,
  },
  {
    age: 238,
    line1: 6.141,
    line2: 6.869,
    line3: 7.673,
    line4: 8.56,
    line5: 9.539,
    line6: 10.616,
    line7: 11.802,
  },
  {
    age: 239,
    line1: 6.148,
    line2: 6.877,
    line3: 7.682,
    line4: 8.57,
    line5: 9.55,
    line6: 10.629,
    line7: 11.816,
  },
  {
    age: 240,
    line1: 6.156,
    line2: 6.885,
    line3: 7.691,
    line4: 8.58,
    line5: 9.561,
    line6: 10.641,
    line7: 11.829,
  },
  {
    age: 241,
    line1: 6.163,
    line2: 6.893,
    line3: 7.7,
    line4: 8.59,
    line5: 9.572,
    line6: 10.654,
    line7: 11.843,
  },
  {
    age: 242,
    line1: 6.17,
    line2: 6.901,
    line3: 7.709,
    line4: 8.6,
    line5: 9.583,
    line6: 10.666,
    line7: 11.857,
  },
  {
    age: 243,
    line1: 6.177,
    line2: 6.909,
    line3: 7.718,
    line4: 8.61,
    line5: 9.594,
    line6: 10.678,
    line7: 11.871,
  },
  {
    age: 244,
    line1: 6.185,
    line2: 6.917,
    line3: 7.727,
    line4: 8.62,
    line5: 9.605,
    line6: 10.69,
    line7: 11.884,
  },
  {
    age: 245,
    line1: 6.192,
    line2: 6.925,
    line3: 7.735,
    line4: 8.63,
    line5: 9.616,
    line6: 10.703,
    line7: 11.898,
  },
  {
    age: 246,
    line1: 6.199,
    line2: 6.933,
    line3: 7.744,
    line4: 8.64,
    line5: 9.627,
    line6: 10.715,
    line7: 11.912,
  },
  {
    age: 247,
    line1: 6.206,
    line2: 6.941,
    line3: 7.753,
    line4: 8.649,
    line5: 9.638,
    line6: 10.727,
    line7: 11.925,
  },
  {
    age: 248,
    line1: 6.213,
    line2: 6.949,
    line3: 7.762,
    line4: 8.659,
    line5: 9.649,
    line6: 10.739,
    line7: 11.939,
  },
  {
    age: 249,
    line1: 6.221,
    line2: 6.957,
    line3: 7.771,
    line4: 8.669,
    line5: 9.66,
    line6: 10.751,
    line7: 11.953,
  },
  {
    age: 250,
    line1: 6.228,
    line2: 6.965,
    line3: 7.779,
    line4: 8.678,
    line5: 9.67,
    line6: 10.763,
    line7: 11.966,
  },
  {
    age: 251,
    line1: 6.235,
    line2: 6.973,
    line3: 7.788,
    line4: 8.688,
    line5: 9.681,
    line6: 10.775,
    line7: 11.979,
  },
  {
    age: 252,
    line1: 6.242,
    line2: 6.98,
    line3: 7.797,
    line4: 8.698,
    line5: 9.692,
    line6: 10.787,
    line7: 11.993,
  },
  {
    age: 253,
    line1: 6.249,
    line2: 6.988,
    line3: 7.805,
    line4: 8.707,
    line5: 9.702,
    line6: 10.799,
    line7: 12.006,
  },
  {
    age: 254,
    line1: 6.256,
    line2: 6.996,
    line3: 7.814,
    line4: 8.717,
    line5: 9.713,
    line6: 10.811,
    line7: 12.02,
  },
  {
    age: 255,
    line1: 6.263,
    line2: 7.003,
    line3: 7.822,
    line4: 8.726,
    line5: 9.724,
    line6: 10.823,
    line7: 12.033,
  },
  {
    age: 256,
    line1: 6.27,
    line2: 7.011,
    line3: 7.831,
    line4: 8.736,
    line5: 9.734,
    line6: 10.835,
    line7: 12.046,
  },
  {
    age: 257,
    line1: 6.277,
    line2: 7.019,
    line3: 7.839,
    line4: 8.745,
    line5: 9.745,
    line6: 10.847,
    line7: 12.059,
  },
  {
    age: 258,
    line1: 6.283,
    line2: 7.026,
    line3: 7.848,
    line4: 8.755,
    line5: 9.756,
    line6: 10.858,
    line7: 12.073,
  },
  {
    age: 259,
    line1: 6.29,
    line2: 7.034,
    line3: 7.856,
    line4: 8.764,
    line5: 9.766,
    line6: 10.87,
    line7: 12.086,
  },
  {
    age: 260,
    line1: 6.297,
    line2: 7.042,
    line3: 7.865,
    line4: 8.774,
    line5: 9.776,
    line6: 10.882,
    line7: 12.099,
  },
  {
    age: 261,
    line1: 6.304,
    line2: 7.049,
    line3: 7.873,
    line4: 8.783,
    line5: 9.787,
    line6: 10.893,
    line7: 12.112,
  },
  {
    age: 262,
    line1: 6.311,
    line2: 7.057,
    line3: 7.881,
    line4: 8.792,
    line5: 9.797,
    line6: 10.905,
    line7: 12.125,
  },
  {
    age: 263,
    line1: 6.318,
    line2: 7.064,
    line3: 7.89,
    line4: 8.802,
    line5: 9.808,
    line6: 10.917,
    line7: 12.138,
  },
  {
    age: 264,
    line1: 6.324,
    line2: 7.072,
    line3: 7.898,
    line4: 8.811,
    line5: 9.818,
    line6: 10.928,
    line7: 12.151,
  },
  {
    age: 265,
    line1: 6.331,
    line2: 7.079,
    line3: 7.906,
    line4: 8.82,
    line5: 9.828,
    line6: 10.94,
    line7: 12.164,
  },
  {
    age: 266,
    line1: 6.338,
    line2: 7.087,
    line3: 7.915,
    line4: 8.829,
    line5: 9.839,
    line6: 10.951,
    line7: 12.177,
  },
  {
    age: 267,
    line1: 6.345,
    line2: 7.094,
    line3: 7.923,
    line4: 8.838,
    line5: 9.849,
    line6: 10.963,
    line7: 12.19,
  },
  {
    age: 268,
    line1: 6.351,
    line2: 7.101,
    line3: 7.931,
    line4: 8.848,
    line5: 9.859,
    line6: 10.974,
    line7: 12.203,
  },
  {
    age: 269,
    line1: 6.358,
    line2: 7.109,
    line3: 7.939,
    line4: 8.857,
    line5: 9.869,
    line6: 10.986,
    line7: 12.215,
  },
  {
    age: 270,
    line1: 6.365,
    line2: 7.116,
    line3: 7.947,
    line4: 8.866,
    line5: 9.879,
    line6: 10.997,
    line7: 12.228,
  },
  {
    age: 271,
    line1: 6.371,
    line2: 7.123,
    line3: 7.955,
    line4: 8.875,
    line5: 9.889,
    line6: 11.008,
    line7: 12.241,
  },
  {
    age: 272,
    line1: 6.378,
    line2: 7.131,
    line3: 7.964,
    line4: 8.884,
    line5: 9.9,
    line6: 11.02,
    line7: 12.254,
  },
  {
    age: 273,
    line1: 6.384,
    line2: 7.138,
    line3: 7.972,
    line4: 8.893,
    line5: 9.91,
    line6: 11.031,
    line7: 12.267,
  },
  {
    age: 274,
    line1: 6.391,
    line2: 7.145,
    line3: 7.98,
    line4: 8.902,
    line5: 9.92,
    line6: 11.042,
    line7: 12.279,
  },
  {
    age: 275,
    line1: 6.397,
    line2: 7.153,
    line3: 7.988,
    line4: 8.911,
    line5: 9.93,
    line6: 11.054,
    line7: 12.292,
  },
  {
    age: 276,
    line1: 6.404,
    line2: 7.16,
    line3: 7.996,
    line4: 8.92,
    line5: 9.94,
    line6: 11.065,
    line7: 12.304,
  },
  {
    age: 277,
    line1: 6.41,
    line2: 7.167,
    line3: 8.004,
    line4: 8.929,
    line5: 9.95,
    line6: 11.076,
    line7: 12.317,
  },
  {
    age: 278,
    line1: 6.417,
    line2: 7.174,
    line3: 8.012,
    line4: 8.938,
    line5: 9.96,
    line6: 11.087,
    line7: 12.33,
  },
  {
    age: 279,
    line1: 6.423,
    line2: 7.181,
    line3: 8.02,
    line4: 8.947,
    line5: 9.97,
    line6: 11.098,
    line7: 12.342,
  },
  {
    age: 280,
    line1: 6.43,
    line2: 7.188,
    line3: 8.028,
    line4: 8.956,
    line5: 9.98,
    line6: 11.11,
    line7: 12.355,
  },
  {
    age: 281,
    line1: 6.436,
    line2: 7.196,
    line3: 8.036,
    line4: 8.964,
    line5: 9.99,
    line6: 11.121,
    line7: 12.367,
  },
  {
    age: 282,
    line1: 6.442,
    line2: 7.203,
    line3: 8.044,
    line4: 8.973,
    line5: 10,
    line6: 11.132,
    line7: 12.38,
  },
  {
    age: 283,
    line1: 6.449,
    line2: 7.21,
    line3: 8.051,
    line4: 8.982,
    line5: 10.009,
    line6: 11.143,
    line7: 12.392,
  },
  {
    age: 284,
    line1: 6.455,
    line2: 7.217,
    line3: 8.059,
    line4: 8.991,
    line5: 10.019,
    line6: 11.154,
    line7: 12.404,
  },
  {
    age: 285,
    line1: 6.461,
    line2: 7.224,
    line3: 8.067,
    line4: 9,
    line5: 10.029,
    line6: 11.165,
    line7: 12.417,
  },
  {
    age: 286,
    line1: 6.468,
    line2: 7.231,
    line3: 8.075,
    line4: 9.008,
    line5: 10.039,
    line6: 11.176,
    line7: 12.429,
  },
  {
    age: 287,
    line1: 6.474,
    line2: 7.238,
    line3: 8.083,
    line4: 9.017,
    line5: 10.049,
    line6: 11.187,
    line7: 12.442,
  },
  {
    age: 288,
    line1: 6.481,
    line2: 7.245,
    line3: 8.091,
    line4: 9.026,
    line5: 10.058,
    line6: 11.198,
    line7: 12.454,
  },
  {
    age: 289,
    line1: 6.487,
    line2: 7.252,
    line3: 8.098,
    line4: 9.034,
    line5: 10.068,
    line6: 11.209,
    line7: 12.466,
  },
  {
    age: 290,
    line1: 6.493,
    line2: 7.259,
    line3: 8.106,
    line4: 9.043,
    line5: 10.078,
    line6: 11.22,
    line7: 12.478,
  },
  {
    age: 291,
    line1: 6.499,
    line2: 7.266,
    line3: 8.114,
    line4: 9.052,
    line5: 10.087,
    line6: 11.23,
    line7: 12.491,
  },
  {
    age: 292,
    line1: 6.505,
    line2: 7.272,
    line3: 8.121,
    line4: 9.06,
    line5: 10.097,
    line6: 11.241,
    line7: 12.503,
  },
  {
    age: 293,
    line1: 6.512,
    line2: 7.279,
    line3: 8.129,
    line4: 9.069,
    line5: 10.107,
    line6: 11.252,
    line7: 12.515,
  },
  {
    age: 294,
    line1: 6.518,
    line2: 7.286,
    line3: 8.137,
    line4: 9.077,
    line5: 10.116,
    line6: 11.263,
    line7: 12.527,
  },
  {
    age: 295,
    line1: 6.524,
    line2: 7.293,
    line3: 8.144,
    line4: 9.086,
    line5: 10.126,
    line6: 11.274,
    line7: 12.539,
  },
  {
    age: 296,
    line1: 6.53,
    line2: 7.3,
    line3: 8.152,
    line4: 9.094,
    line5: 10.135,
    line6: 11.284,
    line7: 12.551,
  },
  {
    age: 297,
    line1: 6.536,
    line2: 7.307,
    line3: 8.16,
    line4: 9.103,
    line5: 10.145,
    line6: 11.295,
    line7: 12.564,
  },
  {
    age: 298,
    line1: 6.542,
    line2: 7.314,
    line3: 8.167,
    line4: 9.111,
    line5: 10.154,
    line6: 11.306,
    line7: 12.576,
  },
  {
    age: 299,
    line1: 6.549,
    line2: 7.321,
    line3: 8.175,
    line4: 9.12,
    line5: 10.164,
    line6: 11.316,
    line7: 12.587,
  },
  {
    age: 300,
    line1: 6.555,
    line2: 7.327,
    line3: 8.182,
    line4: 9.128,
    line5: 10.173,
    line6: 11.327,
    line7: 12.6,
  },
  {
    age: 301,
    line1: 6.561,
    line2: 7.334,
    line3: 8.19,
    line4: 9.137,
    line5: 10.183,
    line6: 11.338,
    line7: 12.611,
  },
  {
    age: 302,
    line1: 6.567,
    line2: 7.341,
    line3: 8.197,
    line4: 9.145,
    line5: 10.192,
    line6: 11.348,
    line7: 12.624,
  },
  {
    age: 303,
    line1: 6.573,
    line2: 7.347,
    line3: 8.205,
    line4: 9.153,
    line5: 10.202,
    line6: 11.359,
    line7: 12.635,
  },
  {
    age: 304,
    line1: 6.579,
    line2: 7.354,
    line3: 8.212,
    line4: 9.162,
    line5: 10.211,
    line6: 11.369,
    line7: 12.647,
  },
  {
    age: 305,
    line1: 6.585,
    line2: 7.361,
    line3: 8.22,
    line4: 9.17,
    line5: 10.22,
    line6: 11.38,
    line7: 12.659,
  },
  {
    age: 306,
    line1: 6.591,
    line2: 7.368,
    line3: 8.227,
    line4: 9.178,
    line5: 10.23,
    line6: 11.39,
    line7: 12.671,
  },
  {
    age: 307,
    line1: 6.597,
    line2: 7.374,
    line3: 8.235,
    line4: 9.187,
    line5: 10.239,
    line6: 11.401,
    line7: 12.683,
  },
  {
    age: 308,
    line1: 6.603,
    line2: 7.381,
    line3: 8.242,
    line4: 9.195,
    line5: 10.248,
    line6: 11.411,
    line7: 12.695,
  },
  {
    age: 309,
    line1: 6.609,
    line2: 7.387,
    line3: 8.25,
    line4: 9.203,
    line5: 10.258,
    line6: 11.422,
    line7: 12.707,
  },
  {
    age: 310,
    line1: 6.615,
    line2: 7.394,
    line3: 8.257,
    line4: 9.212,
    line5: 10.267,
    line6: 11.432,
    line7: 12.718,
  },
  {
    age: 311,
    line1: 6.621,
    line2: 7.401,
    line3: 8.264,
    line4: 9.22,
    line5: 10.276,
    line6: 11.443,
    line7: 12.73,
  },
  {
    age: 312,
    line1: 6.627,
    line2: 7.407,
    line3: 8.272,
    line4: 9.228,
    line5: 10.285,
    line6: 11.453,
    line7: 12.742,
  },
  {
    age: 313,
    line1: 6.633,
    line2: 7.414,
    line3: 8.279,
    line4: 9.236,
    line5: 10.295,
    line6: 11.464,
    line7: 12.753,
  },
  {
    age: 314,
    line1: 6.639,
    line2: 7.42,
    line3: 8.286,
    line4: 9.245,
    line5: 10.304,
    line6: 11.474,
    line7: 12.765,
  },
  {
    age: 315,
    line1: 6.645,
    line2: 7.427,
    line3: 8.294,
    line4: 9.253,
    line5: 10.313,
    line6: 11.484,
    line7: 12.777,
  },
  {
    age: 316,
    line1: 6.65,
    line2: 7.434,
    line3: 8.301,
    line4: 9.261,
    line5: 10.322,
    line6: 11.495,
    line7: 12.789,
  },
  {
    age: 317,
    line1: 6.656,
    line2: 7.44,
    line3: 8.308,
    line4: 9.269,
    line5: 10.331,
    line6: 11.505,
    line7: 12.8,
  },
  {
    age: 318,
    line1: 6.662,
    line2: 7.447,
    line3: 8.316,
    line4: 9.277,
    line5: 10.341,
    line6: 11.515,
    line7: 12.812,
  },
  {
    age: 319,
    line1: 6.668,
    line2: 7.453,
    line3: 8.323,
    line4: 9.285,
    line5: 10.35,
    line6: 11.525,
    line7: 12.823,
  },
  {
    age: 320,
    line1: 6.674,
    line2: 7.46,
    line3: 8.33,
    line4: 9.294,
    line5: 10.359,
    line6: 11.536,
    line7: 12.835,
  },
  {
    age: 321,
    line1: 6.68,
    line2: 7.466,
    line3: 8.337,
    line4: 9.302,
    line5: 10.368,
    line6: 11.546,
    line7: 12.847,
  },
  {
    age: 322,
    line1: 6.685,
    line2: 7.473,
    line3: 8.345,
    line4: 9.31,
    line5: 10.377,
    line6: 11.556,
    line7: 12.858,
  },
  {
    age: 323,
    line1: 6.691,
    line2: 7.479,
    line3: 8.352,
    line4: 9.318,
    line5: 10.386,
    line6: 11.566,
    line7: 12.87,
  },
  {
    age: 324,
    line1: 6.697,
    line2: 7.485,
    line3: 8.359,
    line4: 9.326,
    line5: 10.395,
    line6: 11.577,
    line7: 12.881,
  },
  {
    age: 325,
    line1: 6.703,
    line2: 7.492,
    line3: 8.366,
    line4: 9.334,
    line5: 10.404,
    line6: 11.587,
    line7: 12.893,
  },
  {
    age: 326,
    line1: 6.708,
    line2: 7.498,
    line3: 8.373,
    line4: 9.342,
    line5: 10.413,
    line6: 11.597,
    line7: 12.904,
  },
  {
    age: 327,
    line1: 6.714,
    line2: 7.505,
    line3: 8.38,
    line4: 9.35,
    line5: 10.422,
    line6: 11.607,
    line7: 12.916,
  },
  {
    age: 328,
    line1: 6.72,
    line2: 7.511,
    line3: 8.388,
    line4: 9.358,
    line5: 10.431,
    line6: 11.617,
    line7: 12.927,
  },
  {
    age: 329,
    line1: 6.726,
    line2: 7.517,
    line3: 8.395,
    line4: 9.366,
    line5: 10.44,
    line6: 11.628,
    line7: 12.939,
  },
  {
    age: 330,
    line1: 6.731,
    line2: 7.524,
    line3: 8.402,
    line4: 9.374,
    line5: 10.449,
    line6: 11.638,
    line7: 12.95,
  },
  {
    age: 331,
    line1: 6.737,
    line2: 7.53,
    line3: 8.409,
    line4: 9.382,
    line5: 10.458,
    line6: 11.648,
    line7: 12.962,
  },
  {
    age: 332,
    line1: 6.743,
    line2: 7.537,
    line3: 8.416,
    line4: 9.39,
    line5: 10.467,
    line6: 11.658,
    line7: 12.973,
  },
  {
    age: 333,
    line1: 6.749,
    line2: 7.543,
    line3: 8.423,
    line4: 9.398,
    line5: 10.476,
    line6: 11.668,
    line7: 12.984,
  },
  {
    age: 334,
    line1: 6.754,
    line2: 7.549,
    line3: 8.43,
    line4: 9.406,
    line5: 10.485,
    line6: 11.678,
    line7: 12.996,
  },
  {
    age: 335,
    line1: 6.76,
    line2: 7.556,
    line3: 8.437,
    line4: 9.414,
    line5: 10.494,
    line6: 11.688,
    line7: 13.007,
  },
  {
    age: 336,
    line1: 6.765,
    line2: 7.562,
    line3: 8.444,
    line4: 9.422,
    line5: 10.503,
    line6: 11.698,
    line7: 13.019,
  },
  {
    age: 337,
    line1: 6.771,
    line2: 7.568,
    line3: 8.451,
    line4: 9.429,
    line5: 10.512,
    line6: 11.708,
    line7: 13.03,
  },
  {
    age: 338,
    line1: 6.777,
    line2: 7.574,
    line3: 8.458,
    line4: 9.437,
    line5: 10.52,
    line6: 11.718,
    line7: 13.041,
  },
  {
    age: 339,
    line1: 6.783,
    line2: 7.581,
    line3: 8.465,
    line4: 9.445,
    line5: 10.529,
    line6: 11.728,
    line7: 13.052,
  },
  {
    age: 340,
    line1: 6.788,
    line2: 7.587,
    line3: 8.472,
    line4: 9.453,
    line5: 10.538,
    line6: 11.738,
    line7: 13.064,
  },
  {
    age: 341,
    line1: 6.794,
    line2: 7.593,
    line3: 8.479,
    line4: 9.461,
    line5: 10.547,
    line6: 11.748,
    line7: 13.075,
  },
  {
    age: 342,
    line1: 6.799,
    line2: 7.6,
    line3: 8.486,
    line4: 9.469,
    line5: 10.556,
    line6: 11.758,
    line7: 13.086,
  },
  {
    age: 343,
    line1: 6.805,
    line2: 7.606,
    line3: 8.493,
    line4: 9.476,
    line5: 10.565,
    line6: 11.768,
    line7: 13.097,
  },
  {
    age: 344,
    line1: 6.811,
    line2: 7.612,
    line3: 8.5,
    line4: 9.484,
    line5: 10.573,
    line6: 11.778,
    line7: 13.109,
  },
  {
    age: 345,
    line1: 6.816,
    line2: 7.618,
    line3: 8.507,
    line4: 9.492,
    line5: 10.582,
    line6: 11.788,
    line7: 13.12,
  },
  {
    age: 346,
    line1: 6.822,
    line2: 7.624,
    line3: 8.514,
    line4: 9.5,
    line5: 10.591,
    line6: 11.798,
    line7: 13.131,
  },
  {
    age: 347,
    line1: 6.827,
    line2: 7.631,
    line3: 8.521,
    line4: 9.508,
    line5: 10.6,
    line6: 11.807,
    line7: 13.142,
  },
  {
    age: 348,
    line1: 6.833,
    line2: 7.637,
    line3: 8.528,
    line4: 9.516,
    line5: 10.608,
    line6: 11.817,
    line7: 13.154,
  },
  {
    age: 349,
    line1: 6.838,
    line2: 7.643,
    line3: 8.535,
    line4: 9.523,
    line5: 10.617,
    line6: 11.827,
    line7: 13.165,
  },
  {
    age: 350,
    line1: 6.844,
    line2: 7.649,
    line3: 8.542,
    line4: 9.531,
    line5: 10.626,
    line6: 11.837,
    line7: 13.176,
  },
  {
    age: 351,
    line1: 6.849,
    line2: 7.655,
    line3: 8.549,
    line4: 9.539,
    line5: 10.635,
    line6: 11.847,
    line7: 13.187,
  },
  {
    age: 352,
    line1: 6.855,
    line2: 7.661,
    line3: 8.556,
    line4: 9.546,
    line5: 10.643,
    line6: 11.857,
    line7: 13.198,
  },
  {
    age: 353,
    line1: 6.86,
    line2: 7.668,
    line3: 8.563,
    line4: 9.554,
    line5: 10.652,
    line6: 11.867,
    line7: 13.209,
  },
  {
    age: 354,
    line1: 6.866,
    line2: 7.674,
    line3: 8.569,
    line4: 9.562,
    line5: 10.661,
    line6: 11.876,
    line7: 13.22,
  },
  {
    age: 355,
    line1: 6.872,
    line2: 7.68,
    line3: 8.576,
    line4: 9.57,
    line5: 10.669,
    line6: 11.886,
    line7: 13.231,
  },
  {
    age: 356,
    line1: 6.877,
    line2: 7.686,
    line3: 8.583,
    line4: 9.577,
    line5: 10.678,
    line6: 11.896,
    line7: 13.242,
  },
  {
    age: 357,
    line1: 6.882,
    line2: 7.692,
    line3: 8.59,
    line4: 9.585,
    line5: 10.687,
    line6: 11.906,
    line7: 13.253,
  },
  {
    age: 358,
    line1: 6.888,
    line2: 7.698,
    line3: 8.597,
    line4: 9.593,
    line5: 10.695,
    line6: 11.915,
    line7: 13.264,
  },
  {
    age: 359,
    line1: 6.893,
    line2: 7.704,
    line3: 8.604,
    line4: 9.6,
    line5: 10.704,
    line6: 11.925,
    line7: 13.275,
  },
  {
    age: 360,
    line1: 6.899,
    line2: 7.71,
    line3: 8.61,
    line4: 9.608,
    line5: 10.713,
    line6: 11.935,
    line7: 13.287,
  },
  {
    age: 361,
    line1: 6.904,
    line2: 7.716,
    line3: 8.617,
    line4: 9.616,
    line5: 10.721,
    line6: 11.944,
    line7: 13.297,
  },
  {
    age: 362,
    line1: 6.91,
    line2: 7.722,
    line3: 8.624,
    line4: 9.623,
    line5: 10.73,
    line6: 11.954,
    line7: 13.308,
  },
  {
    age: 363,
    line1: 6.915,
    line2: 7.729,
    line3: 8.631,
    line4: 9.631,
    line5: 10.738,
    line6: 11.964,
    line7: 13.32,
  },
  {
    age: 364,
    line1: 6.92,
    line2: 7.735,
    line3: 8.638,
    line4: 9.638,
    line5: 10.747,
    line6: 11.974,
    line7: 13.331,
  },
  {
    age: 365,
    line1: 6.926,
    line2: 7.741,
    line3: 8.644,
    line4: 9.646,
    line5: 10.755,
    line6: 11.983,
    line7: 13.341,
  },
  {
    age: 366,
    line1: 6.931,
    line2: 7.747,
    line3: 8.651,
    line4: 9.654,
    line5: 10.764,
    line6: 11.993,
    line7: 13.352,
  },
  {
    age: 367,
    line1: 6.937,
    line2: 7.753,
    line3: 8.658,
    line4: 9.661,
    line5: 10.773,
    line6: 12.003,
    line7: 13.363,
  },
  {
    age: 368,
    line1: 6.942,
    line2: 7.759,
    line3: 8.665,
    line4: 9.669,
    line5: 10.781,
    line6: 12.012,
    line7: 13.374,
  },
  {
    age: 369,
    line1: 6.948,
    line2: 7.765,
    line3: 8.671,
    line4: 9.676,
    line5: 10.79,
    line6: 12.022,
    line7: 13.385,
  },
  {
    age: 370,
    line1: 6.953,
    line2: 7.771,
    line3: 8.678,
    line4: 9.684,
    line5: 10.798,
    line6: 12.032,
    line7: 13.396,
  },
  {
    age: 371,
    line1: 6.958,
    line2: 7.777,
    line3: 8.685,
    line4: 9.691,
    line5: 10.807,
    line6: 12.041,
    line7: 13.407,
  },
  {
    age: 372,
    line1: 6.964,
    line2: 7.783,
    line3: 8.691,
    line4: 9.699,
    line5: 10.815,
    line6: 12.051,
    line7: 13.418,
  },
  {
    age: 373,
    line1: 6.969,
    line2: 7.789,
    line3: 8.698,
    line4: 9.706,
    line5: 10.824,
    line6: 12.06,
    line7: 13.429,
  },
  {
    age: 374,
    line1: 6.974,
    line2: 7.795,
    line3: 8.705,
    line4: 9.714,
    line5: 10.832,
    line6: 12.07,
    line7: 13.44,
  },
  {
    age: 375,
    line1: 6.979,
    line2: 7.8,
    line3: 8.711,
    line4: 9.721,
    line5: 10.841,
    line6: 12.08,
    line7: 13.451,
  },
  {
    age: 376,
    line1: 6.985,
    line2: 7.807,
    line3: 8.718,
    line4: 9.729,
    line5: 10.849,
    line6: 12.089,
    line7: 13.461,
  },
  {
    age: 377,
    line1: 6.99,
    line2: 7.812,
    line3: 8.725,
    line4: 9.736,
    line5: 10.857,
    line6: 12.099,
    line7: 13.472,
  },
  {
    age: 378,
    line1: 6.995,
    line2: 7.818,
    line3: 8.731,
    line4: 9.744,
    line5: 10.866,
    line6: 12.108,
    line7: 13.483,
  },
  {
    age: 379,
    line1: 7.001,
    line2: 7.824,
    line3: 8.738,
    line4: 9.751,
    line5: 10.874,
    line6: 12.118,
    line7: 13.494,
  },
  {
    age: 380,
    line1: 7.006,
    line2: 7.83,
    line3: 8.745,
    line4: 9.759,
    line5: 10.883,
    line6: 12.127,
    line7: 13.505,
  },
  {
    age: 381,
    line1: 7.011,
    line2: 7.836,
    line3: 8.751,
    line4: 9.766,
    line5: 10.891,
    line6: 12.137,
    line7: 13.516,
  },
  {
    age: 382,
    line1: 7.017,
    line2: 7.842,
    line3: 8.758,
    line4: 9.774,
    line5: 10.9,
    line6: 12.147,
    line7: 13.527,
  },
  {
    age: 383,
    line1: 7.022,
    line2: 7.848,
    line3: 8.765,
    line4: 9.781,
    line5: 10.908,
    line6: 12.156,
    line7: 13.537,
  },
  {
    age: 384,
    line1: 7.027,
    line2: 7.854,
    line3: 8.771,
    line4: 9.789,
    line5: 10.916,
    line6: 12.165,
    line7: 13.548,
  },
  {
    age: 385,
    line1: 7.033,
    line2: 7.86,
    line3: 8.778,
    line4: 9.796,
    line5: 10.925,
    line6: 12.175,
    line7: 13.559,
  },
  {
    age: 386,
    line1: 7.038,
    line2: 7.866,
    line3: 8.784,
    line4: 9.804,
    line5: 10.933,
    line6: 12.184,
    line7: 13.57,
  },
  {
    age: 387,
    line1: 7.043,
    line2: 7.871,
    line3: 8.791,
    line4: 9.811,
    line5: 10.942,
    line6: 12.194,
    line7: 13.581,
  },
  {
    age: 388,
    line1: 7.048,
    line2: 7.877,
    line3: 8.797,
    line4: 9.818,
    line5: 10.95,
    line6: 12.204,
    line7: 13.591,
  },
  {
    age: 389,
    line1: 7.054,
    line2: 7.883,
    line3: 8.804,
    line4: 9.826,
    line5: 10.958,
    line6: 12.213,
    line7: 13.602,
  },
  {
    age: 390,
    line1: 7.059,
    line2: 7.889,
    line3: 8.811,
    line4: 9.833,
    line5: 10.966,
    line6: 12.222,
    line7: 13.612,
  },
  {
    age: 391,
    line1: 7.064,
    line2: 7.895,
    line3: 8.817,
    line4: 9.84,
    line5: 10.975,
    line6: 12.232,
    line7: 13.623,
  },
  {
    age: 392,
    line1: 7.069,
    line2: 7.901,
    line3: 8.824,
    line4: 9.848,
    line5: 10.983,
    line6: 12.241,
    line7: 13.634,
  },
  {
    age: 393,
    line1: 7.074,
    line2: 7.906,
    line3: 8.83,
    line4: 9.855,
    line5: 10.991,
    line6: 12.251,
    line7: 13.645,
  },
  {
    age: 394,
    line1: 7.079,
    line2: 7.912,
    line3: 8.837,
    line4: 9.862,
    line5: 11,
    line6: 12.26,
    line7: 13.656,
  },
  {
    age: 395,
    line1: 7.085,
    line2: 7.918,
    line3: 8.843,
    line4: 9.87,
    line5: 11.008,
    line6: 12.269,
    line7: 13.666,
  },
  {
    age: 396,
    line1: 7.09,
    line2: 7.924,
    line3: 8.85,
    line4: 9.877,
    line5: 11.016,
    line6: 12.279,
    line7: 13.677,
  },
  {
    age: 397,
    line1: 7.095,
    line2: 7.93,
    line3: 8.856,
    line4: 9.884,
    line5: 11.025,
    line6: 12.288,
    line7: 13.687,
  },
  {
    age: 398,
    line1: 7.1,
    line2: 7.935,
    line3: 8.863,
    line4: 9.892,
    line5: 11.033,
    line6: 12.298,
    line7: 13.698,
  },
  {
    age: 399,
    line1: 7.105,
    line2: 7.941,
    line3: 8.869,
    line4: 9.899,
    line5: 11.041,
    line6: 12.307,
    line7: 13.709,
  },
  {
    age: 400,
    line1: 7.111,
    line2: 7.947,
    line3: 8.876,
    line4: 9.906,
    line5: 11.05,
    line6: 12.316,
    line7: 13.72,
  },
  {
    age: 401,
    line1: 7.116,
    line2: 7.953,
    line3: 8.882,
    line4: 9.914,
    line5: 11.058,
    line6: 12.326,
    line7: 13.73,
  },
  {
    age: 402,
    line1: 7.121,
    line2: 7.959,
    line3: 8.889,
    line4: 9.921,
    line5: 11.066,
    line6: 12.335,
    line7: 13.741,
  },
  {
    age: 403,
    line1: 7.126,
    line2: 7.964,
    line3: 8.895,
    line4: 9.928,
    line5: 11.074,
    line6: 12.344,
    line7: 13.751,
  },
  {
    age: 404,
    line1: 7.131,
    line2: 7.97,
    line3: 8.902,
    line4: 9.936,
    line5: 11.083,
    line6: 12.354,
    line7: 13.762,
  },
  {
    age: 405,
    line1: 7.136,
    line2: 7.976,
    line3: 8.908,
    line4: 9.943,
    line5: 11.091,
    line6: 12.363,
    line7: 13.773,
  },
  {
    age: 406,
    line1: 7.141,
    line2: 7.982,
    line3: 8.915,
    line4: 9.95,
    line5: 11.099,
    line6: 12.372,
    line7: 13.783,
  },
  {
    age: 407,
    line1: 7.147,
    line2: 7.987,
    line3: 8.921,
    line4: 9.958,
    line5: 11.107,
    line6: 12.382,
    line7: 13.794,
  },
  {
    age: 408,
    line1: 7.152,
    line2: 7.993,
    line3: 8.927,
    line4: 9.965,
    line5: 11.115,
    line6: 12.391,
    line7: 13.805,
  },
  {
    age: 409,
    line1: 7.157,
    line2: 7.999,
    line3: 8.934,
    line4: 9.972,
    line5: 11.124,
    line6: 12.401,
    line7: 13.815,
  },
  {
    age: 410,
    line1: 7.162,
    line2: 8.005,
    line3: 8.94,
    line4: 9.979,
    line5: 11.132,
    line6: 12.41,
    line7: 13.826,
  },
  {
    age: 411,
    line1: 7.167,
    line2: 8.01,
    line3: 8.947,
    line4: 9.986,
    line5: 11.14,
    line6: 12.419,
    line7: 13.836,
  },
  {
    age: 412,
    line1: 7.172,
    line2: 8.016,
    line3: 8.953,
    line4: 9.994,
    line5: 11.148,
    line6: 12.428,
    line7: 13.847,
  },
  {
    age: 413,
    line1: 7.177,
    line2: 8.022,
    line3: 8.96,
    line4: 10.001,
    line5: 11.156,
    line6: 12.438,
    line7: 13.857,
  },
  {
    age: 414,
    line1: 7.182,
    line2: 8.027,
    line3: 8.966,
    line4: 10.008,
    line5: 11.165,
    line6: 12.447,
    line7: 13.868,
  },
  {
    age: 415,
    line1: 7.187,
    line2: 8.033,
    line3: 8.972,
    line4: 10.015,
    line5: 11.173,
    line6: 12.456,
    line7: 13.879,
  },
  {
    age: 416,
    line1: 7.192,
    line2: 8.039,
    line3: 8.979,
    line4: 10.023,
    line5: 11.181,
    line6: 12.465,
    line7: 13.889,
  },
  {
    age: 417,
    line1: 7.198,
    line2: 8.044,
    line3: 8.985,
    line4: 10.03,
    line5: 11.189,
    line6: 12.475,
    line7: 13.9,
  },
  {
    age: 418,
    line1: 7.203,
    line2: 8.05,
    line3: 8.992,
    line4: 10.037,
    line5: 11.197,
    line6: 12.484,
    line7: 13.91,
  },
  {
    age: 419,
    line1: 7.208,
    line2: 8.056,
    line3: 8.998,
    line4: 10.044,
    line5: 11.205,
    line6: 12.493,
    line7: 13.921,
  },
  {
    age: 420,
    line1: 7.213,
    line2: 8.061,
    line3: 9.004,
    line4: 10.051,
    line5: 11.214,
    line6: 12.503,
    line7: 13.932,
  },
  {
    age: 421,
    line1: 7.218,
    line2: 8.067,
    line3: 9.011,
    line4: 10.059,
    line5: 11.222,
    line6: 12.512,
    line7: 13.942,
  },
  {
    age: 422,
    line1: 7.223,
    line2: 8.073,
    line3: 9.017,
    line4: 10.066,
    line5: 11.23,
    line6: 12.521,
    line7: 13.952,
  },
  {
    age: 423,
    line1: 7.228,
    line2: 8.078,
    line3: 9.023,
    line4: 10.073,
    line5: 11.238,
    line6: 12.53,
    line7: 13.963,
  },
  {
    age: 424,
    line1: 7.233,
    line2: 8.084,
    line3: 9.03,
    line4: 10.08,
    line5: 11.246,
    line6: 12.539,
    line7: 13.973,
  },
  {
    age: 425,
    line1: 7.238,
    line2: 8.09,
    line3: 9.036,
    line4: 10.087,
    line5: 11.254,
    line6: 12.548,
    line7: 13.984,
  },
  {
    age: 426,
    line1: 7.243,
    line2: 8.095,
    line3: 9.042,
    line4: 10.094,
    line5: 11.262,
    line6: 12.558,
    line7: 13.994,
  },
  {
    age: 427,
    line1: 7.248,
    line2: 8.101,
    line3: 9.049,
    line4: 10.102,
    line5: 11.27,
    line6: 12.567,
    line7: 14.005,
  },
  {
    age: 428,
    line1: 7.253,
    line2: 8.107,
    line3: 9.055,
    line4: 10.109,
    line5: 11.278,
    line6: 12.576,
    line7: 14.015,
  },
  {
    age: 429,
    line1: 7.258,
    line2: 8.112,
    line3: 9.061,
    line4: 10.116,
    line5: 11.286,
    line6: 12.585,
    line7: 14.026,
  },
  {
    age: 430,
    line1: 7.263,
    line2: 8.118,
    line3: 9.068,
    line4: 10.123,
    line5: 11.295,
    line6: 12.595,
    line7: 14.036,
  },
  {
    age: 431,
    line1: 7.268,
    line2: 8.123,
    line3: 9.074,
    line4: 10.13,
    line5: 11.303,
    line6: 12.604,
    line7: 14.047,
  },
  {
    age: 432,
    line1: 7.273,
    line2: 8.129,
    line3: 9.08,
    line4: 10.137,
    line5: 11.311,
    line6: 12.613,
    line7: 14.057,
  },
  {
    age: 433,
    line1: 7.278,
    line2: 8.134,
    line3: 9.087,
    line4: 10.144,
    line5: 11.319,
    line6: 12.622,
    line7: 14.068,
  },
  {
    age: 434,
    line1: 7.283,
    line2: 8.14,
    line3: 9.093,
    line4: 10.152,
    line5: 11.327,
    line6: 12.631,
    line7: 14.078,
  },
  {
    age: 435,
    line1: 7.288,
    line2: 8.146,
    line3: 9.099,
    line4: 10.159,
    line5: 11.335,
    line6: 12.641,
    line7: 14.089,
  },
  {
    age: 436,
    line1: 7.293,
    line2: 8.151,
    line3: 9.106,
    line4: 10.166,
    line5: 11.343,
    line6: 12.65,
    line7: 14.099,
  },
  {
    age: 437,
    line1: 7.298,
    line2: 8.157,
    line3: 9.112,
    line4: 10.173,
    line5: 11.351,
    line6: 12.659,
    line7: 14.11,
  },
  {
    age: 438,
    line1: 7.303,
    line2: 8.162,
    line3: 9.118,
    line4: 10.18,
    line5: 11.359,
    line6: 12.668,
    line7: 14.12,
  },
  {
    age: 439,
    line1: 7.308,
    line2: 8.168,
    line3: 9.124,
    line4: 10.187,
    line5: 11.367,
    line6: 12.677,
    line7: 14.13,
  },
  {
    age: 440,
    line1: 7.313,
    line2: 8.174,
    line3: 9.131,
    line4: 10.194,
    line5: 11.375,
    line6: 12.686,
    line7: 14.141,
  },
  {
    age: 441,
    line1: 7.318,
    line2: 8.179,
    line3: 9.137,
    line4: 10.201,
    line5: 11.383,
    line6: 12.695,
    line7: 14.151,
  },
  {
    age: 442,
    line1: 7.323,
    line2: 8.185,
    line3: 9.143,
    line4: 10.208,
    line5: 11.391,
    line6: 12.704,
    line7: 14.162,
  },
  {
    age: 443,
    line1: 7.328,
    line2: 8.19,
    line3: 9.149,
    line4: 10.215,
    line5: 11.399,
    line6: 12.714,
    line7: 14.172,
  },
  {
    age: 444,
    line1: 7.333,
    line2: 8.196,
    line3: 9.156,
    line4: 10.222,
    line5: 11.407,
    line6: 12.723,
    line7: 14.183,
  },
  {
    age: 445,
    line1: 7.337,
    line2: 8.201,
    line3: 9.162,
    line4: 10.229,
    line5: 11.415,
    line6: 12.732,
    line7: 14.193,
  },
  {
    age: 446,
    line1: 7.342,
    line2: 8.207,
    line3: 9.168,
    line4: 10.236,
    line5: 11.423,
    line6: 12.741,
    line7: 14.203,
  },
  {
    age: 447,
    line1: 7.347,
    line2: 8.212,
    line3: 9.174,
    line4: 10.244,
    line5: 11.431,
    line6: 12.75,
    line7: 14.214,
  },
  {
    age: 448,
    line1: 7.352,
    line2: 8.218,
    line3: 9.181,
    line4: 10.251,
    line5: 11.439,
    line6: 12.759,
    line7: 14.224,
  },
  {
    age: 449,
    line1: 7.357,
    line2: 8.223,
    line3: 9.187,
    line4: 10.258,
    line5: 11.447,
    line6: 12.768,
    line7: 14.234,
  },
  {
    age: 450,
    line1: 7.362,
    line2: 8.229,
    line3: 9.193,
    line4: 10.265,
    line5: 11.455,
    line6: 12.777,
    line7: 14.245,
  },
  {
    age: 451,
    line1: 7.367,
    line2: 8.235,
    line3: 9.199,
    line4: 10.272,
    line5: 11.463,
    line6: 12.786,
    line7: 14.255,
  },
  {
    age: 452,
    line1: 7.372,
    line2: 8.24,
    line3: 9.206,
    line4: 10.279,
    line5: 11.471,
    line6: 12.796,
    line7: 14.266,
  },
  {
    age: 453,
    line1: 7.377,
    line2: 8.246,
    line3: 9.212,
    line4: 10.286,
    line5: 11.479,
    line6: 12.805,
    line7: 14.276,
  },
  {
    age: 454,
    line1: 7.382,
    line2: 8.251,
    line3: 9.218,
    line4: 10.293,
    line5: 11.487,
    line6: 12.814,
    line7: 14.287,
  },
  {
    age: 455,
    line1: 7.386,
    line2: 8.256,
    line3: 9.224,
    line4: 10.3,
    line5: 11.495,
    line6: 12.823,
    line7: 14.297,
  },
  {
    age: 456,
    line1: 7.391,
    line2: 8.262,
    line3: 9.23,
    line4: 10.307,
    line5: 11.503,
    line6: 12.832,
    line7: 14.308,
  },
  {
    age: 457,
    line1: 7.396,
    line2: 8.267,
    line3: 9.236,
    line4: 10.314,
    line5: 11.511,
    line6: 12.841,
    line7: 14.318,
  },
  {
    age: 458,
    line1: 7.401,
    line2: 8.273,
    line3: 9.243,
    line4: 10.321,
    line5: 11.519,
    line6: 12.85,
    line7: 14.328,
  },
  {
    age: 459,
    line1: 7.406,
    line2: 8.278,
    line3: 9.249,
    line4: 10.328,
    line5: 11.527,
    line6: 12.859,
    line7: 14.339,
  },
  {
    age: 460,
    line1: 7.411,
    line2: 8.284,
    line3: 9.255,
    line4: 10.335,
    line5: 11.535,
    line6: 12.868,
    line7: 14.349,
  },
  {
    age: 461,
    line1: 7.416,
    line2: 8.289,
    line3: 9.261,
    line4: 10.342,
    line5: 11.543,
    line6: 12.877,
    line7: 14.359,
  },
  {
    age: 462,
    line1: 7.421,
    line2: 8.295,
    line3: 9.267,
    line4: 10.349,
    line5: 11.551,
    line6: 12.886,
    line7: 14.369,
  },
  {
    age: 463,
    line1: 7.426,
    line2: 8.3,
    line3: 9.274,
    line4: 10.356,
    line5: 11.559,
    line6: 12.895,
    line7: 14.38,
  },
  {
    age: 464,
    line1: 7.431,
    line2: 8.306,
    line3: 9.28,
    line4: 10.363,
    line5: 11.567,
    line6: 12.905,
    line7: 14.39,
  },
  {
    age: 465,
    line1: 7.435,
    line2: 8.311,
    line3: 9.286,
    line4: 10.37,
    line5: 11.575,
    line6: 12.914,
    line7: 14.4,
  },
  {
    age: 466,
    line1: 7.44,
    line2: 8.317,
    line3: 9.292,
    line4: 10.377,
    line5: 11.583,
    line6: 12.923,
    line7: 14.411,
  },
  {
    age: 467,
    line1: 7.445,
    line2: 8.322,
    line3: 9.298,
    line4: 10.384,
    line5: 11.591,
    line6: 12.932,
    line7: 14.421,
  },
  {
    age: 468,
    line1: 7.45,
    line2: 8.328,
    line3: 9.304,
    line4: 10.391,
    line5: 11.599,
    line6: 12.941,
    line7: 14.432,
  },
  {
    age: 469,
    line1: 7.455,
    line2: 8.333,
    line3: 9.311,
    line4: 10.398,
    line5: 11.607,
    line6: 12.95,
    line7: 14.442,
  },
  {
    age: 470,
    line1: 7.46,
    line2: 8.339,
    line3: 9.317,
    line4: 10.405,
    line5: 11.615,
    line6: 12.959,
    line7: 14.452,
  },
  {
    age: 471,
    line1: 7.465,
    line2: 8.344,
    line3: 9.323,
    line4: 10.412,
    line5: 11.622,
    line6: 12.968,
    line7: 14.463,
  },
  {
    age: 472,
    line1: 7.469,
    line2: 8.349,
    line3: 9.329,
    line4: 10.419,
    line5: 11.63,
    line6: 12.977,
    line7: 14.473,
  },
  {
    age: 473,
    line1: 7.474,
    line2: 8.355,
    line3: 9.335,
    line4: 10.426,
    line5: 11.638,
    line6: 12.986,
    line7: 14.483,
  },
  {
    age: 474,
    line1: 7.479,
    line2: 8.36,
    line3: 9.341,
    line4: 10.433,
    line5: 11.646,
    line6: 12.995,
    line7: 14.493,
  },
  {
    age: 475,
    line1: 7.484,
    line2: 8.366,
    line3: 9.348,
    line4: 10.44,
    line5: 11.654,
    line6: 13.004,
    line7: 14.504,
  },
  {
    age: 476,
    line1: 7.488,
    line2: 8.371,
    line3: 9.354,
    line4: 10.446,
    line5: 11.662,
    line6: 13.013,
    line7: 14.514,
  },
  {
    age: 477,
    line1: 7.493,
    line2: 8.377,
    line3: 9.36,
    line4: 10.454,
    line5: 11.67,
    line6: 13.022,
    line7: 14.525,
  },
  {
    age: 478,
    line1: 7.498,
    line2: 8.382,
    line3: 9.366,
    line4: 10.46,
    line5: 11.678,
    line6: 13.031,
    line7: 14.535,
  },
  {
    age: 479,
    line1: 7.503,
    line2: 8.387,
    line3: 9.372,
    line4: 10.467,
    line5: 11.686,
    line6: 13.04,
    line7: 14.545,
  },
  {
    age: 480,
    line1: 7.508,
    line2: 8.393,
    line3: 9.378,
    line4: 10.474,
    line5: 11.694,
    line6: 13.049,
    line7: 14.555,
  },
  {
    age: 481,
    line1: 7.513,
    line2: 8.398,
    line3: 9.384,
    line4: 10.481,
    line5: 11.701,
    line6: 13.058,
    line7: 14.566,
  },
  {
    age: 482,
    line1: 7.518,
    line2: 8.404,
    line3: 9.39,
    line4: 10.488,
    line5: 11.709,
    line6: 13.067,
    line7: 14.576,
  },
  {
    age: 483,
    line1: 7.522,
    line2: 8.409,
    line3: 9.396,
    line4: 10.495,
    line5: 11.717,
    line6: 13.076,
    line7: 14.587,
  },
  {
    age: 484,
    line1: 7.527,
    line2: 8.414,
    line3: 9.402,
    line4: 10.502,
    line5: 11.725,
    line6: 13.085,
    line7: 14.597,
  },
  {
    age: 485,
    line1: 7.532,
    line2: 8.42,
    line3: 9.409,
    line4: 10.509,
    line5: 11.733,
    line6: 13.094,
    line7: 14.607,
  },
  {
    age: 486,
    line1: 7.537,
    line2: 8.425,
    line3: 9.415,
    line4: 10.516,
    line5: 11.741,
    line6: 13.103,
    line7: 14.617,
  },
  {
    age: 487,
    line1: 7.541,
    line2: 8.431,
    line3: 9.421,
    line4: 10.523,
    line5: 11.749,
    line6: 13.112,
    line7: 14.628,
  },
  {
    age: 488,
    line1: 7.546,
    line2: 8.436,
    line3: 9.427,
    line4: 10.53,
    line5: 11.757,
    line6: 13.121,
    line7: 14.638,
  },
  {
    age: 489,
    line1: 7.551,
    line2: 8.441,
    line3: 9.433,
    line4: 10.537,
    line5: 11.765,
    line6: 13.13,
    line7: 14.648,
  },
  {
    age: 490,
    line1: 7.556,
    line2: 8.447,
    line3: 9.439,
    line4: 10.544,
    line5: 11.772,
    line6: 13.139,
    line7: 14.659,
  },
  {
    age: 491,
    line1: 7.561,
    line2: 8.452,
    line3: 9.445,
    line4: 10.55,
    line5: 11.78,
    line6: 13.148,
    line7: 14.669,
  },
  {
    age: 492,
    line1: 7.565,
    line2: 8.458,
    line3: 9.451,
    line4: 10.557,
    line5: 11.788,
    line6: 13.157,
    line7: 14.679,
  },
  {
    age: 493,
    line1: 7.57,
    line2: 8.463,
    line3: 9.457,
    line4: 10.564,
    line5: 11.796,
    line6: 13.166,
    line7: 14.689,
  },
  {
    age: 494,
    line1: 7.575,
    line2: 8.468,
    line3: 9.463,
    line4: 10.571,
    line5: 11.804,
    line6: 13.175,
    line7: 14.7,
  },
  {
    age: 495,
    line1: 7.58,
    line2: 8.474,
    line3: 9.469,
    line4: 10.578,
    line5: 11.812,
    line6: 13.184,
    line7: 14.71,
  },
  {
    age: 496,
    line1: 7.584,
    line2: 8.479,
    line3: 9.476,
    line4: 10.585,
    line5: 11.82,
    line6: 13.193,
    line7: 14.72,
  },
  {
    age: 497,
    line1: 7.589,
    line2: 8.484,
    line3: 9.482,
    line4: 10.592,
    line5: 11.827,
    line6: 13.202,
    line7: 14.731,
  },
  {
    age: 498,
    line1: 7.594,
    line2: 8.49,
    line3: 9.488,
    line4: 10.599,
    line5: 11.835,
    line6: 13.211,
    line7: 14.741,
  },
  {
    age: 499,
    line1: 7.599,
    line2: 8.495,
    line3: 9.494,
    line4: 10.606,
    line5: 11.843,
    line6: 13.22,
    line7: 14.751,
  },
  {
    age: 500,
    line1: 7.603,
    line2: 8.5,
    line3: 9.5,
    line4: 10.612,
    line5: 11.851,
    line6: 13.229,
    line7: 14.762,
  },
  {
    age: 501,
    line1: 7.608,
    line2: 8.506,
    line3: 9.506,
    line4: 10.619,
    line5: 11.859,
    line6: 13.238,
    line7: 14.772,
  },
  {
    age: 502,
    line1: 7.613,
    line2: 8.511,
    line3: 9.512,
    line4: 10.626,
    line5: 11.867,
    line6: 13.247,
    line7: 14.782,
  },
  {
    age: 503,
    line1: 7.618,
    line2: 8.517,
    line3: 9.518,
    line4: 10.633,
    line5: 11.874,
    line6: 13.256,
    line7: 14.792,
  },
  {
    age: 504,
    line1: 7.622,
    line2: 8.522,
    line3: 9.524,
    line4: 10.64,
    line5: 11.882,
    line6: 13.265,
    line7: 14.803,
  },
  {
    age: 505,
    line1: 7.627,
    line2: 8.527,
    line3: 9.53,
    line4: 10.647,
    line5: 11.89,
    line6: 13.274,
    line7: 14.813,
  },
  {
    age: 506,
    line1: 7.632,
    line2: 8.533,
    line3: 9.536,
    line4: 10.654,
    line5: 11.898,
    line6: 13.283,
    line7: 14.823,
  },
  {
    age: 507,
    line1: 7.637,
    line2: 8.538,
    line3: 9.542,
    line4: 10.66,
    line5: 11.906,
    line6: 13.291,
    line7: 14.833,
  },
  {
    age: 508,
    line1: 7.641,
    line2: 8.543,
    line3: 9.548,
    line4: 10.667,
    line5: 11.914,
    line6: 13.301,
    line7: 14.844,
  },
  {
    age: 509,
    line1: 7.646,
    line2: 8.548,
    line3: 9.554,
    line4: 10.674,
    line5: 11.921,
    line6: 13.309,
    line7: 14.854,
  },
  {
    age: 510,
    line1: 7.651,
    line2: 8.554,
    line3: 9.56,
    line4: 10.681,
    line5: 11.929,
    line6: 13.318,
    line7: 14.864,
  },
  {
    age: 511,
    line1: 7.655,
    line2: 8.559,
    line3: 9.566,
    line4: 10.688,
    line5: 11.937,
    line6: 13.327,
    line7: 14.874,
  },
  {
    age: 512,
    line1: 7.66,
    line2: 8.564,
    line3: 9.572,
    line4: 10.695,
    line5: 11.945,
    line6: 13.336,
    line7: 14.885,
  },
  {
    age: 513,
    line1: 7.665,
    line2: 8.57,
    line3: 9.578,
    line4: 10.702,
    line5: 11.953,
    line6: 13.345,
    line7: 14.895,
  },
  {
    age: 514,
    line1: 7.669,
    line2: 8.575,
    line3: 9.584,
    line4: 10.708,
    line5: 11.96,
    line6: 13.354,
    line7: 14.905,
  },
  {
    age: 515,
    line1: 7.674,
    line2: 8.58,
    line3: 9.59,
    line4: 10.715,
    line5: 11.968,
    line6: 13.363,
    line7: 14.915,
  },
  {
    age: 516,
    line1: 7.679,
    line2: 8.586,
    line3: 9.596,
    line4: 10.722,
    line5: 11.976,
    line6: 13.372,
    line7: 14.926,
  },
  {
    age: 517,
    line1: 7.683,
    line2: 8.591,
    line3: 9.602,
    line4: 10.729,
    line5: 11.984,
    line6: 13.381,
    line7: 14.936,
  },
  {
    age: 518,
    line1: 7.688,
    line2: 8.596,
    line3: 9.608,
    line4: 10.736,
    line5: 11.991,
    line6: 13.39,
    line7: 14.946,
  },
  {
    age: 519,
    line1: 7.693,
    line2: 8.601,
    line3: 9.614,
    line4: 10.743,
    line5: 11.999,
    line6: 13.399,
    line7: 14.957,
  },
  {
    age: 520,
    line1: 7.697,
    line2: 8.607,
    line3: 9.62,
    line4: 10.749,
    line5: 12.007,
    line6: 13.408,
    line7: 14.967,
  },
  {
    age: 521,
    line1: 7.702,
    line2: 8.612,
    line3: 9.626,
    line4: 10.756,
    line5: 12.015,
    line6: 13.417,
    line7: 14.977,
  },
  {
    age: 522,
    line1: 7.707,
    line2: 8.617,
    line3: 9.632,
    line4: 10.763,
    line5: 12.023,
    line6: 13.425,
    line7: 14.987,
  },
  {
    age: 523,
    line1: 7.711,
    line2: 8.623,
    line3: 9.638,
    line4: 10.77,
    line5: 12.03,
    line6: 13.434,
    line7: 14.997,
  },
  {
    age: 524,
    line1: 7.716,
    line2: 8.628,
    line3: 9.644,
    line4: 10.777,
    line5: 12.038,
    line6: 13.443,
    line7: 15.008,
  },
  {
    age: 525,
    line1: 7.721,
    line2: 8.633,
    line3: 9.65,
    line4: 10.784,
    line5: 12.046,
    line6: 13.452,
    line7: 15.018,
  },
  {
    age: 526,
    line1: 7.725,
    line2: 8.638,
    line3: 9.656,
    line4: 10.79,
    line5: 12.054,
    line6: 13.461,
    line7: 15.028,
  },
  {
    age: 527,
    line1: 7.73,
    line2: 8.644,
    line3: 9.662,
    line4: 10.797,
    line5: 12.062,
    line6: 13.47,
    line7: 15.038,
  },
  {
    age: 528,
    line1: 7.735,
    line2: 8.649,
    line3: 9.668,
    line4: 10.804,
    line5: 12.069,
    line6: 13.479,
    line7: 15.048,
  },
  {
    age: 529,
    line1: 7.739,
    line2: 8.654,
    line3: 9.674,
    line4: 10.811,
    line5: 12.077,
    line6: 13.488,
    line7: 15.059,
  },
  {
    age: 530,
    line1: 7.744,
    line2: 8.659,
    line3: 9.68,
    line4: 10.817,
    line5: 12.085,
    line6: 13.497,
    line7: 15.069,
  },
  {
    age: 531,
    line1: 7.749,
    line2: 8.665,
    line3: 9.686,
    line4: 10.824,
    line5: 12.093,
    line6: 13.506,
    line7: 15.079,
  },
  {
    age: 532,
    line1: 7.753,
    line2: 8.67,
    line3: 9.692,
    line4: 10.831,
    line5: 12.1,
    line6: 13.515,
    line7: 15.09,
  },
  {
    age: 533,
    line1: 7.758,
    line2: 8.675,
    line3: 9.698,
    line4: 10.838,
    line5: 12.108,
    line6: 13.523,
    line7: 15.1,
  },
  {
    age: 534,
    line1: 7.763,
    line2: 8.68,
    line3: 9.704,
    line4: 10.845,
    line5: 12.116,
    line6: 13.532,
    line7: 15.11,
  },
  {
    age: 535,
    line1: 7.767,
    line2: 8.686,
    line3: 9.71,
    line4: 10.851,
    line5: 12.124,
    line6: 13.541,
    line7: 15.12,
  },
  {
    age: 536,
    line1: 7.772,
    line2: 8.691,
    line3: 9.716,
    line4: 10.858,
    line5: 12.131,
    line6: 13.55,
    line7: 15.13,
  },
  {
    age: 537,
    line1: 7.777,
    line2: 8.696,
    line3: 9.722,
    line4: 10.865,
    line5: 12.139,
    line6: 13.559,
    line7: 15.14,
  },
  {
    age: 538,
    line1: 7.781,
    line2: 8.701,
    line3: 9.728,
    line4: 10.872,
    line5: 12.147,
    line6: 13.568,
    line7: 15.151,
  },
  {
    age: 539,
    line1: 7.786,
    line2: 8.707,
    line3: 9.734,
    line4: 10.878,
    line5: 12.155,
    line6: 13.577,
    line7: 15.161,
  },
  {
    age: 540,
    line1: 7.79,
    line2: 8.712,
    line3: 9.739,
    line4: 10.885,
    line5: 12.162,
    line6: 13.586,
    line7: 15.171,
  },
  {
    age: 541,
    line1: 7.795,
    line2: 8.717,
    line3: 9.745,
    line4: 10.892,
    line5: 12.17,
    line6: 13.595,
    line7: 15.182,
  },
  {
    age: 542,
    line1: 7.8,
    line2: 8.722,
    line3: 9.751,
    line4: 10.899,
    line5: 12.178,
    line6: 13.603,
    line7: 15.192,
  },
  {
    age: 543,
    line1: 7.804,
    line2: 8.727,
    line3: 9.757,
    line4: 10.906,
    line5: 12.186,
    line6: 13.612,
    line7: 15.202,
  },
  {
    age: 544,
    line1: 7.809,
    line2: 8.733,
    line3: 9.763,
    line4: 10.912,
    line5: 12.193,
    line6: 13.621,
    line7: 15.212,
  },
  {
    age: 545,
    line1: 7.813,
    line2: 8.738,
    line3: 9.769,
    line4: 10.919,
    line5: 12.201,
    line6: 13.63,
    line7: 15.222,
  },
  {
    age: 546,
    line1: 7.818,
    line2: 8.743,
    line3: 9.775,
    line4: 10.926,
    line5: 12.209,
    line6: 13.639,
    line7: 15.233,
  },
  {
    age: 547,
    line1: 7.823,
    line2: 8.748,
    line3: 9.781,
    line4: 10.933,
    line5: 12.217,
    line6: 13.648,
    line7: 15.243,
  },
  {
    age: 548,
    line1: 7.827,
    line2: 8.753,
    line3: 9.787,
    line4: 10.939,
    line5: 12.224,
    line6: 13.657,
    line7: 15.253,
  },
  {
    age: 549,
    line1: 7.832,
    line2: 8.759,
    line3: 9.793,
    line4: 10.946,
    line5: 12.232,
    line6: 13.666,
    line7: 15.263,
  },
  {
    age: 550,
    line1: 7.836,
    line2: 8.764,
    line3: 9.799,
    line4: 10.953,
    line5: 12.24,
    line6: 13.674,
    line7: 15.273,
  },
  {
    age: 551,
    line1: 7.841,
    line2: 8.769,
    line3: 9.805,
    line4: 10.96,
    line5: 12.248,
    line6: 13.684,
    line7: 15.284,
  },
  {
    age: 552,
    line1: 7.846,
    line2: 8.774,
    line3: 9.81,
    line4: 10.966,
    line5: 12.255,
    line6: 13.692,
    line7: 15.294,
  },
  {
    age: 553,
    line1: 7.85,
    line2: 8.779,
    line3: 9.816,
    line4: 10.973,
    line5: 12.263,
    line6: 13.701,
    line7: 15.304,
  },
  {
    age: 554,
    line1: 7.855,
    line2: 8.785,
    line3: 9.822,
    line4: 10.98,
    line5: 12.271,
    line6: 13.71,
    line7: 15.314,
  },
  {
    age: 555,
    line1: 7.859,
    line2: 8.79,
    line3: 9.828,
    line4: 10.986,
    line5: 12.278,
    line6: 13.719,
    line7: 15.324,
  },
  {
    age: 556,
    line1: 7.864,
    line2: 8.795,
    line3: 9.834,
    line4: 10.993,
    line5: 12.286,
    line6: 13.728,
    line7: 15.335,
  },
  {
    age: 557,
    line1: 7.868,
    line2: 8.8,
    line3: 9.84,
    line4: 11,
    line5: 12.294,
    line6: 13.737,
    line7: 15.345,
  },
  {
    age: 558,
    line1: 7.873,
    line2: 8.805,
    line3: 9.846,
    line4: 11.007,
    line5: 12.302,
    line6: 13.746,
    line7: 15.356,
  },
  {
    age: 559,
    line1: 7.878,
    line2: 8.811,
    line3: 9.852,
    line4: 11.013,
    line5: 12.309,
    line6: 13.754,
    line7: 15.365,
  },
  {
    age: 560,
    line1: 7.882,
    line2: 8.816,
    line3: 9.858,
    line4: 11.02,
    line5: 12.317,
    line6: 13.763,
    line7: 15.376,
  },
  {
    age: 561,
    line1: 7.887,
    line2: 8.821,
    line3: 9.864,
    line4: 11.027,
    line5: 12.325,
    line6: 13.772,
    line7: 15.386,
  },
  {
    age: 562,
    line1: 7.891,
    line2: 8.826,
    line3: 9.869,
    line4: 11.034,
    line5: 12.332,
    line6: 13.781,
    line7: 15.396,
  },
  {
    age: 563,
    line1: 7.896,
    line2: 8.831,
    line3: 9.875,
    line4: 11.04,
    line5: 12.34,
    line6: 13.79,
    line7: 15.406,
  },
  {
    age: 564,
    line1: 7.9,
    line2: 8.836,
    line3: 9.881,
    line4: 11.047,
    line5: 12.348,
    line6: 13.799,
    line7: 15.416,
  },
  {
    age: 565,
    line1: 7.905,
    line2: 8.841,
    line3: 9.887,
    line4: 11.054,
    line5: 12.355,
    line6: 13.807,
    line7: 15.427,
  },
  {
    age: 566,
    line1: 7.909,
    line2: 8.847,
    line3: 9.893,
    line4: 11.06,
    line5: 12.363,
    line6: 13.816,
    line7: 15.437,
  },
  {
    age: 567,
    line1: 7.914,
    line2: 8.852,
    line3: 9.899,
    line4: 11.067,
    line5: 12.371,
    line6: 13.825,
    line7: 15.447,
  },
  {
    age: 568,
    line1: 7.919,
    line2: 8.857,
    line3: 9.905,
    line4: 11.074,
    line5: 12.379,
    line6: 13.834,
    line7: 15.457,
  },
  {
    age: 569,
    line1: 7.923,
    line2: 8.862,
    line3: 9.91,
    line4: 11.081,
    line5: 12.386,
    line6: 13.843,
    line7: 15.468,
  },
  {
    age: 570,
    line1: 7.928,
    line2: 8.867,
    line3: 9.916,
    line4: 11.087,
    line5: 12.394,
    line6: 13.852,
    line7: 15.478,
  },
  {
    age: 571,
    line1: 7.932,
    line2: 8.872,
    line3: 9.922,
    line4: 11.094,
    line5: 12.402,
    line6: 13.861,
    line7: 15.488,
  },
  {
    age: 572,
    line1: 7.937,
    line2: 8.878,
    line3: 9.928,
    line4: 11.101,
    line5: 12.409,
    line6: 13.869,
    line7: 15.498,
  },
  {
    age: 573,
    line1: 7.941,
    line2: 8.883,
    line3: 9.934,
    line4: 11.107,
    line5: 12.417,
    line6: 13.878,
    line7: 15.509,
  },
  {
    age: 574,
    line1: 7.946,
    line2: 8.888,
    line3: 9.94,
    line4: 11.114,
    line5: 12.425,
    line6: 13.887,
    line7: 15.519,
  },
  {
    age: 575,
    line1: 7.95,
    line2: 8.893,
    line3: 9.946,
    line4: 11.121,
    line5: 12.432,
    line6: 13.896,
    line7: 15.529,
  },
  {
    age: 576,
    line1: 7.955,
    line2: 8.898,
    line3: 9.952,
    line4: 11.128,
    line5: 12.44,
    line6: 13.905,
    line7: 15.539,
  },
  {
    age: 577,
    line1: 7.959,
    line2: 8.903,
    line3: 9.957,
    line4: 11.134,
    line5: 12.448,
    line6: 13.914,
    line7: 15.55,
  },
  {
    age: 578,
    line1: 7.964,
    line2: 8.908,
    line3: 9.963,
    line4: 11.141,
    line5: 12.455,
    line6: 13.923,
    line7: 15.56,
  },
  {
    age: 579,
    line1: 7.968,
    line2: 8.913,
    line3: 9.969,
    line4: 11.148,
    line5: 12.463,
    line6: 13.932,
    line7: 15.57,
  },
  {
    age: 580,
    line1: 7.973,
    line2: 8.919,
    line3: 9.975,
    line4: 11.154,
    line5: 12.471,
    line6: 13.94,
    line7: 15.58,
  },
  {
    age: 581,
    line1: 7.977,
    line2: 8.924,
    line3: 9.981,
    line4: 11.161,
    line5: 12.479,
    line6: 13.949,
    line7: 15.591,
  },
  {
    age: 582,
    line1: 7.982,
    line2: 8.929,
    line3: 9.987,
    line4: 11.168,
    line5: 12.486,
    line6: 13.958,
    line7: 15.601,
  },
  {
    age: 583,
    line1: 7.986,
    line2: 8.934,
    line3: 9.992,
    line4: 11.174,
    line5: 12.494,
    line6: 13.967,
    line7: 15.611,
  },
  {
    age: 584,
    line1: 7.991,
    line2: 8.939,
    line3: 9.998,
    line4: 11.181,
    line5: 12.502,
    line6: 13.976,
    line7: 15.621,
  },
  {
    age: 585,
    line1: 7.995,
    line2: 8.944,
    line3: 10.004,
    line4: 11.188,
    line5: 12.509,
    line6: 13.985,
    line7: 15.631,
  },
  {
    age: 586,
    line1: 8,
    line2: 8.949,
    line3: 10.01,
    line4: 11.194,
    line5: 12.517,
    line6: 13.993,
    line7: 15.641,
  },
  {
    age: 587,
    line1: 8.004,
    line2: 8.954,
    line3: 10.016,
    line4: 11.201,
    line5: 12.525,
    line6: 14.002,
    line7: 15.652,
  },
  {
    age: 588,
    line1: 8.009,
    line2: 8.96,
    line3: 10.022,
    line4: 11.208,
    line5: 12.532,
    line6: 14.011,
    line7: 15.662,
  },
  {
    age: 589,
    line1: 8.013,
    line2: 8.965,
    line3: 10.027,
    line4: 11.214,
    line5: 12.54,
    line6: 14.02,
    line7: 15.672,
  },
  {
    age: 590,
    line1: 8.018,
    line2: 8.97,
    line3: 10.033,
    line4: 11.221,
    line5: 12.548,
    line6: 14.029,
    line7: 15.683,
  },
  {
    age: 591,
    line1: 8.022,
    line2: 8.975,
    line3: 10.039,
    line4: 11.228,
    line5: 12.555,
    line6: 14.038,
    line7: 15.693,
  },
  {
    age: 592,
    line1: 8.027,
    line2: 8.98,
    line3: 10.045,
    line4: 11.234,
    line5: 12.563,
    line6: 14.047,
    line7: 15.703,
  },
  {
    age: 593,
    line1: 8.031,
    line2: 8.985,
    line3: 10.051,
    line4: 11.241,
    line5: 12.571,
    line6: 14.055,
    line7: 15.713,
  },
  {
    age: 594,
    line1: 8.036,
    line2: 8.99,
    line3: 10.057,
    line4: 11.248,
    line5: 12.578,
    line6: 14.064,
    line7: 15.724,
  },
  {
    age: 595,
    line1: 8.04,
    line2: 8.995,
    line3: 10.062,
    line4: 11.254,
    line5: 12.586,
    line6: 14.073,
    line7: 15.734,
  },
  {
    age: 596,
    line1: 8.045,
    line2: 9,
    line3: 10.068,
    line4: 11.261,
    line5: 12.594,
    line6: 14.082,
    line7: 15.744,
  },
  {
    age: 597,
    line1: 8.049,
    line2: 9.005,
    line3: 10.074,
    line4: 11.268,
    line5: 12.601,
    line6: 14.091,
    line7: 15.754,
  },
  {
    age: 598,
    line1: 8.054,
    line2: 9.01,
    line3: 10.08,
    line4: 11.274,
    line5: 12.609,
    line6: 14.1,
    line7: 15.764,
  },
  {
    age: 599,
    line1: 8.058,
    line2: 9.016,
    line3: 10.086,
    line4: 11.281,
    line5: 12.617,
    line6: 14.109,
    line7: 15.775,
  },
  {
    age: 600,
    line1: 8.062,
    line2: 9.021,
    line3: 10.091,
    line4: 11.288,
    line5: 12.624,
    line6: 14.117,
    line7: 15.785,
  },
  {
    age: 601,
    line1: 8.067,
    line2: 9.026,
    line3: 10.097,
    line4: 11.294,
    line5: 12.632,
    line6: 14.126,
    line7: 15.795,
  },
  {
    age: 602,
    line1: 8.071,
    line2: 9.031,
    line3: 10.103,
    line4: 11.301,
    line5: 12.64,
    line6: 14.135,
    line7: 15.805,
  },
  {
    age: 603,
    line1: 8.076,
    line2: 9.036,
    line3: 10.109,
    line4: 11.308,
    line5: 12.647,
    line6: 14.144,
    line7: 15.816,
  },
  {
    age: 604,
    line1: 8.08,
    line2: 9.041,
    line3: 10.115,
    line4: 11.314,
    line5: 12.655,
    line6: 14.153,
    line7: 15.826,
  },
  {
    age: 605,
    line1: 8.085,
    line2: 9.046,
    line3: 10.121,
    line4: 11.321,
    line5: 12.663,
    line6: 14.162,
    line7: 15.836,
  },
  {
    age: 606,
    line1: 8.089,
    line2: 9.051,
    line3: 10.126,
    line4: 11.328,
    line5: 12.67,
    line6: 14.171,
    line7: 15.846,
  },
  {
    age: 607,
    line1: 8.094,
    line2: 9.056,
    line3: 10.132,
    line4: 11.334,
    line5: 12.678,
    line6: 14.179,
    line7: 15.856,
  },
  {
    age: 608,
    line1: 8.098,
    line2: 9.061,
    line3: 10.138,
    line4: 11.341,
    line5: 12.686,
    line6: 14.188,
    line7: 15.867,
  },
  {
    age: 609,
    line1: 8.102,
    line2: 9.066,
    line3: 10.144,
    line4: 11.348,
    line5: 12.693,
    line6: 14.197,
    line7: 15.877,
  },
  {
    age: 610,
    line1: 8.107,
    line2: 9.072,
    line3: 10.15,
    line4: 11.354,
    line5: 12.701,
    line6: 14.206,
    line7: 15.887,
  },
  {
    age: 611,
    line1: 8.112,
    line2: 9.077,
    line3: 10.155,
    line4: 11.361,
    line5: 12.709,
    line6: 14.215,
    line7: 15.898,
  },
  {
    age: 612,
    line1: 8.116,
    line2: 9.082,
    line3: 10.161,
    line4: 11.368,
    line5: 12.716,
    line6: 14.224,
    line7: 15.908,
  },
  {
    age: 613,
    line1: 8.12,
    line2: 9.087,
    line3: 10.167,
    line4: 11.374,
    line5: 12.724,
    line6: 14.233,
    line7: 15.918,
  },
  {
    age: 614,
    line1: 8.125,
    line2: 9.092,
    line3: 10.173,
    line4: 11.381,
    line5: 12.732,
    line6: 14.242,
    line7: 15.929,
  },
  {
    age: 615,
    line1: 8.129,
    line2: 9.097,
    line3: 10.179,
    line4: 11.388,
    line5: 12.739,
    line6: 14.25,
    line7: 15.939,
  },
  {
    age: 616,
    line1: 8.134,
    line2: 9.102,
    line3: 10.184,
    line4: 11.394,
    line5: 12.747,
    line6: 14.259,
    line7: 15.949,
  },
  {
    age: 617,
    line1: 8.138,
    line2: 9.107,
    line3: 10.19,
    line4: 11.401,
    line5: 12.755,
    line6: 14.268,
    line7: 15.959,
  },
  {
    age: 618,
    line1: 8.143,
    line2: 9.112,
    line3: 10.196,
    line4: 11.408,
    line5: 12.763,
    line6: 14.277,
    line7: 15.97,
  },
  {
    age: 619,
    line1: 8.147,
    line2: 9.117,
    line3: 10.202,
    line4: 11.414,
    line5: 12.77,
    line6: 14.286,
    line7: 15.98,
  },
  {
    age: 620,
    line1: 8.152,
    line2: 9.122,
    line3: 10.208,
    line4: 11.421,
    line5: 12.778,
    line6: 14.295,
    line7: 15.99,
  },
  {
    age: 621,
    line1: 8.156,
    line2: 9.127,
    line3: 10.213,
    line4: 11.428,
    line5: 12.785,
    line6: 14.303,
    line7: 16,
  },
  {
    age: 622,
    line1: 8.16,
    line2: 9.132,
    line3: 10.219,
    line4: 11.434,
    line5: 12.793,
    line6: 14.312,
    line7: 16.011,
  },
  {
    age: 623,
    line1: 8.165,
    line2: 9.137,
    line3: 10.225,
    line4: 11.441,
    line5: 12.801,
    line6: 14.321,
    line7: 16.021,
  },
  {
    age: 624,
    line1: 8.169,
    line2: 9.142,
    line3: 10.231,
    line4: 11.448,
    line5: 12.809,
    line6: 14.33,
    line7: 16.031,
  },
  {
    age: 625,
    line1: 8.174,
    line2: 9.147,
    line3: 10.236,
    line4: 11.454,
    line5: 12.816,
    line6: 14.339,
    line7: 16.042,
  },
  {
    age: 626,
    line1: 8.178,
    line2: 9.153,
    line3: 10.242,
    line4: 11.461,
    line5: 12.824,
    line6: 14.348,
    line7: 16.052,
  },
  {
    age: 627,
    line1: 8.183,
    line2: 9.158,
    line3: 10.248,
    line4: 11.468,
    line5: 12.831,
    line6: 14.357,
    line7: 16.062,
  },
  {
    age: 628,
    line1: 8.187,
    line2: 9.163,
    line3: 10.254,
    line4: 11.474,
    line5: 12.839,
    line6: 14.366,
    line7: 16.073,
  },
  {
    age: 629,
    line1: 8.191,
    line2: 9.168,
    line3: 10.26,
    line4: 11.481,
    line5: 12.847,
    line6: 14.374,
    line7: 16.083,
  },
  {
    age: 630,
    line1: 8.196,
    line2: 9.173,
    line3: 10.265,
    line4: 11.488,
    line5: 12.855,
    line6: 14.383,
    line7: 16.093,
  },
  {
    age: 631,
    line1: 8.2,
    line2: 9.178,
    line3: 10.271,
    line4: 11.494,
    line5: 12.862,
    line6: 14.392,
    line7: 16.103,
  },
  {
    age: 632,
    line1: 8.205,
    line2: 9.183,
    line3: 10.277,
    line4: 11.501,
    line5: 12.87,
    line6: 14.401,
    line7: 16.113,
  },
  {
    age: 633,
    line1: 8.209,
    line2: 9.188,
    line3: 10.283,
    line4: 11.508,
    line5: 12.878,
    line6: 14.41,
    line7: 16.124,
  },
  {
    age: 634,
    line1: 8.213,
    line2: 9.193,
    line3: 10.288,
    line4: 11.514,
    line5: 12.885,
    line6: 14.419,
    line7: 16.134,
  },
  {
    age: 635,
    line1: 8.218,
    line2: 9.198,
    line3: 10.294,
    line4: 11.521,
    line5: 12.893,
    line6: 14.428,
    line7: 16.144,
  },
  {
    age: 636,
    line1: 8.222,
    line2: 9.203,
    line3: 10.3,
    line4: 11.527,
    line5: 12.9,
    line6: 14.436,
    line7: 16.155,
  },
  {
    age: 637,
    line1: 8.227,
    line2: 9.208,
    line3: 10.306,
    line4: 11.534,
    line5: 12.908,
    line6: 14.446,
    line7: 16.165,
  },
  {
    age: 638,
    line1: 8.231,
    line2: 9.213,
    line3: 10.312,
    line4: 11.541,
    line5: 12.916,
    line6: 14.454,
    line7: 16.175,
  },
  {
    age: 639,
    line1: 8.236,
    line2: 9.218,
    line3: 10.317,
    line4: 11.547,
    line5: 12.924,
    line6: 14.463,
    line7: 16.186,
  },
  {
    age: 640,
    line1: 8.24,
    line2: 9.223,
    line3: 10.323,
    line4: 11.554,
    line5: 12.931,
    line6: 14.472,
    line7: 16.196,
  },
  {
    age: 641,
    line1: 8.244,
    line2: 9.228,
    line3: 10.329,
    line4: 11.561,
    line5: 12.939,
    line6: 14.481,
    line7: 16.206,
  },
  {
    age: 642,
    line1: 8.249,
    line2: 9.233,
    line3: 10.335,
    line4: 11.567,
    line5: 12.947,
    line6: 14.49,
    line7: 16.216,
  },
  {
    age: 643,
    line1: 8.253,
    line2: 9.238,
    line3: 10.34,
    line4: 11.574,
    line5: 12.954,
    line6: 14.499,
    line7: 16.227,
  },
  {
    age: 644,
    line1: 8.257,
    line2: 9.243,
    line3: 10.346,
    line4: 11.581,
    line5: 12.962,
    line6: 14.508,
    line7: 16.237,
  },
  {
    age: 645,
    line1: 8.262,
    line2: 9.248,
    line3: 10.352,
    line4: 11.587,
    line5: 12.969,
    line6: 14.516,
    line7: 16.247,
  },
  {
    age: 646,
    line1: 8.266,
    line2: 9.253,
    line3: 10.358,
    line4: 11.594,
    line5: 12.977,
    line6: 14.525,
    line7: 16.258,
  },
  {
    age: 647,
    line1: 8.271,
    line2: 9.258,
    line3: 10.363,
    line4: 11.6,
    line5: 12.985,
    line6: 14.534,
    line7: 16.268,
  },
  {
    age: 648,
    line1: 8.275,
    line2: 9.263,
    line3: 10.369,
    line4: 11.607,
    line5: 12.992,
    line6: 14.543,
    line7: 16.278,
  },
  {
    age: 649,
    line1: 8.28,
    line2: 9.268,
    line3: 10.375,
    line4: 11.614,
    line5: 13,
    line6: 14.552,
    line7: 16.289,
  },
  {
    age: 650,
    line1: 8.284,
    line2: 9.273,
    line3: 10.381,
    line4: 11.62,
    line5: 13.008,
    line6: 14.561,
    line7: 16.299,
  },
  {
    age: 651,
    line1: 8.288,
    line2: 9.278,
    line3: 10.386,
    line4: 11.627,
    line5: 13.016,
    line6: 14.57,
    line7: 16.31,
  },
  {
    age: 652,
    line1: 8.293,
    line2: 9.283,
    line3: 10.392,
    line4: 11.634,
    line5: 13.023,
    line6: 14.578,
    line7: 16.319,
  },
  {
    age: 653,
    line1: 8.297,
    line2: 9.288,
    line3: 10.398,
    line4: 11.64,
    line5: 13.031,
    line6: 14.588,
    line7: 16.33,
  },
  {
    age: 654,
    line1: 8.301,
    line2: 9.293,
    line3: 10.404,
    line4: 11.647,
    line5: 13.039,
    line6: 14.596,
    line7: 16.34,
  },
  {
    age: 655,
    line1: 8.306,
    line2: 9.298,
    line3: 10.409,
    line4: 11.654,
    line5: 13.046,
    line6: 14.605,
    line7: 16.351,
  },
  {
    age: 656,
    line1: 8.31,
    line2: 9.303,
    line3: 10.415,
    line4: 11.66,
    line5: 13.054,
    line6: 14.614,
    line7: 16.361,
  },
  {
    age: 657,
    line1: 8.315,
    line2: 9.308,
    line3: 10.421,
    line4: 11.667,
    line5: 13.062,
    line6: 14.623,
    line7: 16.371,
  },
  {
    age: 658,
    line1: 8.319,
    line2: 9.313,
    line3: 10.427,
    line4: 11.673,
    line5: 13.069,
    line6: 14.632,
    line7: 16.382,
  },
  {
    age: 659,
    line1: 8.323,
    line2: 9.318,
    line3: 10.432,
    line4: 11.68,
    line5: 13.077,
    line6: 14.641,
    line7: 16.392,
  },
  {
    age: 660,
    line1: 8.328,
    line2: 9.323,
    line3: 10.438,
    line4: 11.687,
    line5: 13.084,
    line6: 14.65,
    line7: 16.402,
  },
  {
    age: 661,
    line1: 8.332,
    line2: 9.328,
    line3: 10.444,
    line4: 11.693,
    line5: 13.092,
    line6: 14.659,
    line7: 16.413,
  },
  {
    age: 662,
    line1: 8.336,
    line2: 9.333,
    line3: 10.45,
    line4: 11.7,
    line5: 13.1,
    line6: 14.668,
    line7: 16.423,
  },
  {
    age: 663,
    line1: 8.341,
    line2: 9.338,
    line3: 10.455,
    line4: 11.706,
    line5: 13.107,
    line6: 14.676,
    line7: 16.434,
  },
  {
    age: 664,
    line1: 8.345,
    line2: 9.343,
    line3: 10.461,
    line4: 11.713,
    line5: 13.115,
    line6: 14.685,
    line7: 16.444,
  },
  {
    age: 665,
    line1: 8.35,
    line2: 9.348,
    line3: 10.467,
    line4: 11.72,
    line5: 13.123,
    line6: 14.694,
    line7: 16.454,
  },
  {
    age: 666,
    line1: 8.354,
    line2: 9.353,
    line3: 10.473,
    line4: 11.726,
    line5: 13.13,
    line6: 14.703,
    line7: 16.464,
  },
  {
    age: 667,
    line1: 8.358,
    line2: 9.358,
    line3: 10.478,
    line4: 11.733,
    line5: 13.138,
    line6: 14.712,
    line7: 16.475,
  },
  {
    age: 668,
    line1: 8.363,
    line2: 9.363,
    line3: 10.484,
    line4: 11.74,
    line5: 13.146,
    line6: 14.721,
    line7: 16.485,
  },
  {
    age: 669,
    line1: 8.367,
    line2: 9.368,
    line3: 10.49,
    line4: 11.746,
    line5: 13.153,
    line6: 14.73,
    line7: 16.495,
  },
  {
    age: 670,
    line1: 8.371,
    line2: 9.373,
    line3: 10.496,
    line4: 11.753,
    line5: 13.161,
    line6: 14.739,
    line7: 16.506,
  },
  {
    age: 671,
    line1: 8.376,
    line2: 9.378,
    line3: 10.501,
    line4: 11.76,
    line5: 13.169,
    line6: 14.748,
    line7: 16.516,
  },
  {
    age: 672,
    line1: 8.38,
    line2: 9.383,
    line3: 10.507,
    line4: 11.766,
    line5: 13.176,
    line6: 14.756,
    line7: 16.526,
  },
  {
    age: 673,
    line1: 8.384,
    line2: 9.388,
    line3: 10.513,
    line4: 11.773,
    line5: 13.184,
    line6: 14.765,
    line7: 16.537,
  },
  {
    age: 674,
    line1: 8.389,
    line2: 9.393,
    line3: 10.519,
    line4: 11.779,
    line5: 13.192,
    line6: 14.774,
    line7: 16.547,
  },
  {
    age: 675,
    line1: 8.393,
    line2: 9.398,
    line3: 10.524,
    line4: 11.786,
    line5: 13.199,
    line6: 14.783,
    line7: 16.558,
  },
  {
    age: 676,
    line1: 8.397,
    line2: 9.403,
    line3: 10.53,
    line4: 11.792,
    line5: 13.207,
    line6: 14.792,
    line7: 16.568,
  },
  {
    age: 677,
    line1: 8.402,
    line2: 9.408,
    line3: 10.536,
    line4: 11.799,
    line5: 13.215,
    line6: 14.801,
    line7: 16.578,
  },
  {
    age: 678,
    line1: 8.406,
    line2: 9.413,
    line3: 10.542,
    line4: 11.806,
    line5: 13.222,
    line6: 14.81,
    line7: 16.588,
  },
  {
    age: 679,
    line1: 8.41,
    line2: 9.418,
    line3: 10.547,
    line4: 11.812,
    line5: 13.23,
    line6: 14.819,
    line7: 16.599,
  },
  {
    age: 680,
    line1: 8.415,
    line2: 9.423,
    line3: 10.553,
    line4: 11.819,
    line5: 13.238,
    line6: 14.828,
    line7: 16.609,
  },
  {
    age: 681,
    line1: 8.419,
    line2: 9.428,
    line3: 10.559,
    line4: 11.826,
    line5: 13.245,
    line6: 14.837,
    line7: 16.62,
  },
  {
    age: 682,
    line1: 8.424,
    line2: 9.433,
    line3: 10.564,
    line4: 11.832,
    line5: 13.253,
    line6: 14.845,
    line7: 16.63,
  },
  {
    age: 683,
    line1: 8.428,
    line2: 9.438,
    line3: 10.57,
    line4: 11.839,
    line5: 13.261,
    line6: 14.854,
    line7: 16.64,
  },
  {
    age: 684,
    line1: 8.432,
    line2: 9.443,
    line3: 10.576,
    line4: 11.845,
    line5: 13.268,
    line6: 14.863,
    line7: 16.651,
  },
  {
    age: 685,
    line1: 8.436,
    line2: 9.448,
    line3: 10.582,
    line4: 11.852,
    line5: 13.276,
    line6: 14.872,
    line7: 16.661,
  },
  {
    age: 686,
    line1: 8.441,
    line2: 9.453,
    line3: 10.587,
    line4: 11.859,
    line5: 13.284,
    line6: 14.881,
    line7: 16.672,
  },
  {
    age: 687,
    line1: 8.445,
    line2: 9.458,
    line3: 10.593,
    line4: 11.865,
    line5: 13.291,
    line6: 14.89,
    line7: 16.682,
  },
  {
    age: 688,
    line1: 8.45,
    line2: 9.463,
    line3: 10.599,
    line4: 11.872,
    line5: 13.299,
    line6: 14.899,
    line7: 16.692,
  },
  {
    age: 689,
    line1: 8.454,
    line2: 9.468,
    line3: 10.604,
    line4: 11.878,
    line5: 13.306,
    line6: 14.907,
    line7: 16.702,
  },
  {
    age: 690,
    line1: 8.458,
    line2: 9.473,
    line3: 10.61,
    line4: 11.885,
    line5: 13.314,
    line6: 14.916,
    line7: 16.713,
  },
  {
    age: 691,
    line1: 8.462,
    line2: 9.478,
    line3: 10.616,
    line4: 11.892,
    line5: 13.322,
    line6: 14.925,
    line7: 16.723,
  },
  {
    age: 692,
    line1: 8.467,
    line2: 9.483,
    line3: 10.622,
    line4: 11.898,
    line5: 13.329,
    line6: 14.934,
    line7: 16.733,
  },
  {
    age: 693,
    line1: 8.471,
    line2: 9.488,
    line3: 10.627,
    line4: 11.905,
    line5: 13.337,
    line6: 14.943,
    line7: 16.744,
  },
  {
    age: 694,
    line1: 8.475,
    line2: 9.493,
    line3: 10.633,
    line4: 11.911,
    line5: 13.345,
    line6: 14.952,
    line7: 16.754,
  },
  {
    age: 695,
    line1: 8.48,
    line2: 9.498,
    line3: 10.639,
    line4: 11.918,
    line5: 13.352,
    line6: 14.961,
    line7: 16.765,
  },
  {
    age: 696,
    line1: 8.484,
    line2: 9.503,
    line3: 10.644,
    line4: 11.925,
    line5: 13.36,
    line6: 14.97,
    line7: 16.775,
  },
  {
    age: 697,
    line1: 8.488,
    line2: 9.507,
    line3: 10.65,
    line4: 11.931,
    line5: 13.368,
    line6: 14.979,
    line7: 16.786,
  },
  {
    age: 698,
    line1: 8.493,
    line2: 9.513,
    line3: 10.656,
    line4: 11.938,
    line5: 13.375,
    line6: 14.987,
    line7: 16.796,
  },
  {
    age: 699,
    line1: 8.497,
    line2: 9.517,
    line3: 10.662,
    line4: 11.944,
    line5: 13.383,
    line6: 14.996,
    line7: 16.806,
  },
  {
    age: 700,
    line1: 8.501,
    line2: 9.522,
    line3: 10.667,
    line4: 11.951,
    line5: 13.391,
    line6: 15.005,
    line7: 16.817,
  },
  {
    age: 701,
    line1: 8.506,
    line2: 9.527,
    line3: 10.673,
    line4: 11.958,
    line5: 13.398,
    line6: 15.014,
    line7: 16.827,
  },
  {
    age: 702,
    line1: 8.51,
    line2: 9.532,
    line3: 10.679,
    line4: 11.964,
    line5: 13.406,
    line6: 15.023,
    line7: 16.838,
  },
  {
    age: 703,
    line1: 8.514,
    line2: 9.537,
    line3: 10.684,
    line4: 11.971,
    line5: 13.414,
    line6: 15.032,
    line7: 16.848,
  },
  {
    age: 704,
    line1: 8.519,
    line2: 9.542,
    line3: 10.69,
    line4: 11.977,
    line5: 13.421,
    line6: 15.041,
    line7: 16.858,
  },
  {
    age: 705,
    line1: 8.523,
    line2: 9.547,
    line3: 10.696,
    line4: 11.984,
    line5: 13.429,
    line6: 15.05,
    line7: 16.868,
  },
  {
    age: 706,
    line1: 8.527,
    line2: 9.552,
    line3: 10.701,
    line4: 11.99,
    line5: 13.437,
    line6: 15.059,
    line7: 16.879,
  },
  {
    age: 707,
    line1: 8.531,
    line2: 9.557,
    line3: 10.707,
    line4: 11.997,
    line5: 13.444,
    line6: 15.068,
    line7: 16.889,
  },
  {
    age: 708,
    line1: 8.536,
    line2: 9.562,
    line3: 10.713,
    line4: 12.004,
    line5: 13.452,
    line6: 15.077,
    line7: 16.9,
  },
  {
    age: 709,
    line1: 8.54,
    line2: 9.567,
    line3: 10.718,
    line4: 12.01,
    line5: 13.46,
    line6: 15.086,
    line7: 16.91,
  },
  {
    age: 710,
    line1: 8.544,
    line2: 9.572,
    line3: 10.724,
    line4: 12.017,
    line5: 13.467,
    line6: 15.094,
    line7: 16.921,
  },
  {
    age: 711,
    line1: 8.549,
    line2: 9.577,
    line3: 10.73,
    line4: 12.023,
    line5: 13.475,
    line6: 15.103,
    line7: 16.931,
  },
  {
    age: 712,
    line1: 8.553,
    line2: 9.582,
    line3: 10.736,
    line4: 12.03,
    line5: 13.482,
    line6: 15.112,
    line7: 16.941,
  },
  {
    age: 713,
    line1: 8.557,
    line2: 9.587,
    line3: 10.741,
    line4: 12.037,
    line5: 13.49,
    line6: 15.121,
    line7: 16.952,
  },
  {
    age: 714,
    line1: 8.561,
    line2: 9.591,
    line3: 10.747,
    line4: 12.043,
    line5: 13.498,
    line6: 15.13,
    line7: 16.962,
  },
  {
    age: 715,
    line1: 8.566,
    line2: 9.596,
    line3: 10.753,
    line4: 12.05,
    line5: 13.505,
    line6: 15.139,
    line7: 16.972,
  },
  {
    age: 716,
    line1: 8.57,
    line2: 9.601,
    line3: 10.758,
    line4: 12.056,
    line5: 13.513,
    line6: 15.148,
    line7: 16.983,
  },
  {
    age: 717,
    line1: 8.574,
    line2: 9.606,
    line3: 10.764,
    line4: 12.063,
    line5: 13.521,
    line6: 15.157,
    line7: 16.993,
  },
  {
    age: 718,
    line1: 8.578,
    line2: 9.611,
    line3: 10.769,
    line4: 12.069,
    line5: 13.528,
    line6: 15.166,
    line7: 17.004,
  },
  {
    age: 719,
    line1: 8.583,
    line2: 9.616,
    line3: 10.775,
    line4: 12.076,
    line5: 13.536,
    line6: 15.174,
    line7: 17.014,
  },
  {
    age: 720,
    line1: 8.587,
    line2: 9.621,
    line3: 10.781,
    line4: 12.083,
    line5: 13.543,
    line6: 15.183,
    line7: 17.024,
  },
  {
    age: 721,
    line1: 8.591,
    line2: 9.626,
    line3: 10.787,
    line4: 12.089,
    line5: 13.551,
    line6: 15.192,
    line7: 17.035,
  },
  {
    age: 722,
    line1: 8.596,
    line2: 9.631,
    line3: 10.792,
    line4: 12.096,
    line5: 13.559,
    line6: 15.201,
    line7: 17.045,
  },
  {
    age: 723,
    line1: 8.6,
    line2: 9.636,
    line3: 10.798,
    line4: 12.102,
    line5: 13.566,
    line6: 15.21,
    line7: 17.056,
  },
  {
    age: 724,
    line1: 8.604,
    line2: 9.641,
    line3: 10.804,
    line4: 12.109,
    line5: 13.574,
    line6: 15.219,
    line7: 17.066,
  },
  {
    age: 725,
    line1: 8.608,
    line2: 9.645,
    line3: 10.809,
    line4: 12.115,
    line5: 13.582,
    line6: 15.228,
    line7: 17.077,
  },
  {
    age: 726,
    line1: 8.613,
    line2: 9.65,
    line3: 10.815,
    line4: 12.122,
    line5: 13.589,
    line6: 15.237,
    line7: 17.087,
  },
  {
    age: 727,
    line1: 8.617,
    line2: 9.655,
    line3: 10.821,
    line4: 12.128,
    line5: 13.597,
    line6: 15.246,
    line7: 17.097,
  },
  {
    age: 728,
    line1: 8.621,
    line2: 9.66,
    line3: 10.826,
    line4: 12.135,
    line5: 13.604,
    line6: 15.254,
    line7: 17.107,
  },
  {
    age: 729,
    line1: 8.626,
    line2: 9.665,
    line3: 10.832,
    line4: 12.142,
    line5: 13.612,
    line6: 15.263,
    line7: 17.118,
  },
  {
    age: 730,
    line1: 8.63,
    line2: 9.67,
    line3: 10.838,
    line4: 12.148,
    line5: 13.62,
    line6: 15.272,
    line7: 17.128,
  },
];

export const MATURE_GIRLS_WEIGHT_LINE_COORDS = [
  {
    age: 0,
    line1: 2.033,
    line2: 2.395,
    line3: 2.794,
    line4: 3.232,
    line5: 3.711,
    line6: 4.23,
    line7: 4.793,
  },
  {
    age: 1,
    line1: 1.994,
    line2: 2.352,
    line3: 2.752,
    line4: 3.196,
    line5: 3.685,
    line6: 4.222,
    line7: 4.81,
  },
  {
    age: 2,
    line1: 2.002,
    line2: 2.362,
    line3: 2.764,
    line4: 3.21,
    line5: 3.704,
    line6: 4.249,
    line7: 4.846,
  },
  {
    age: 3,
    line1: 2.017,
    line2: 2.378,
    line3: 2.782,
    line4: 3.232,
    line5: 3.73,
    line6: 4.28,
    line7: 4.885,
  },
  {
    age: 4,
    line1: 2.034,
    line2: 2.397,
    line3: 2.803,
    line4: 3.256,
    line5: 3.758,
    line6: 4.314,
    line7: 4.925,
  },
  {
    age: 5,
    line1: 2.053,
    line2: 2.418,
    line3: 2.826,
    line4: 3.282,
    line5: 3.789,
    line6: 4.349,
    line7: 4.967,
  },
  {
    age: 6,
    line1: 2.074,
    line2: 2.44,
    line3: 2.851,
    line4: 3.31,
    line5: 3.82,
    line6: 4.386,
    line7: 5.009,
  },
  {
    age: 7,
    line1: 2.096,
    line2: 2.464,
    line3: 2.877,
    line4: 3.339,
    line5: 3.853,
    line6: 4.423,
    line7: 5.052,
  },
  {
    age: 8,
    line1: 2.118,
    line2: 2.488,
    line3: 2.904,
    line4: 3.369,
    line5: 3.887,
    line6: 4.461,
    line7: 5.096,
  },
  {
    age: 9,
    line1: 2.141,
    line2: 2.513,
    line3: 2.932,
    line4: 3.4,
    line5: 3.921,
    line6: 4.5,
    line7: 5.14,
  },
  {
    age: 10,
    line1: 2.165,
    line2: 2.54,
    line3: 2.96,
    line4: 3.431,
    line5: 3.957,
    line6: 4.54,
    line7: 5.186,
  },
  {
    age: 11,
    line1: 2.19,
    line2: 2.567,
    line3: 2.99,
    line4: 3.464,
    line5: 3.993,
    line6: 4.581,
    line7: 5.232,
  },
  {
    age: 12,
    line1: 2.216,
    line2: 2.595,
    line3: 3.021,
    line4: 3.498,
    line5: 4.031,
    line6: 4.623,
    line7: 5.28,
  },
  {
    age: 13,
    line1: 2.242,
    line2: 2.623,
    line3: 3.052,
    line4: 3.533,
    line5: 4.07,
    line6: 4.667,
    line7: 5.329,
  },
  {
    age: 14,
    line1: 2.269,
    line2: 2.653,
    line3: 3.085,
    line4: 3.569,
    line5: 4.11,
    line6: 4.712,
    line7: 5.379,
  },
  {
    age: 15,
    line1: 2.297,
    line2: 2.683,
    line3: 3.118,
    line4: 3.606,
    line5: 4.151,
    line6: 4.758,
    line7: 5.431,
  },
  {
    age: 16,
    line1: 2.325,
    line2: 2.714,
    line3: 3.152,
    line4: 3.644,
    line5: 4.193,
    line6: 4.804,
    line7: 5.483,
  },
  {
    age: 17,
    line1: 2.353,
    line2: 2.745,
    line3: 3.187,
    line4: 3.682,
    line5: 4.235,
    line6: 4.852,
    line7: 5.536,
  },
  {
    age: 18,
    line1: 2.382,
    line2: 2.777,
    line3: 3.221,
    line4: 3.72,
    line5: 4.278,
    line6: 4.899,
    line7: 5.589,
  },
  {
    age: 19,
    line1: 2.41,
    line2: 2.808,
    line3: 3.256,
    line4: 3.758,
    line5: 4.32,
    line6: 4.946,
    line7: 5.642,
  },
  {
    age: 20,
    line1: 2.439,
    line2: 2.84,
    line3: 3.291,
    line4: 3.797,
    line5: 4.363,
    line6: 4.994,
    line7: 5.695,
  },
  {
    age: 21,
    line1: 2.468,
    line2: 2.871,
    line3: 3.325,
    line4: 3.835,
    line5: 4.406,
    line6: 5.042,
    line7: 5.749,
  },
  {
    age: 22,
    line1: 2.496,
    line2: 2.903,
    line3: 3.36,
    line4: 3.874,
    line5: 4.448,
    line6: 5.089,
    line7: 5.802,
  },
  {
    age: 23,
    line1: 2.525,
    line2: 2.934,
    line3: 3.395,
    line4: 3.912,
    line5: 4.49,
    line6: 5.136,
    line7: 5.854,
  },
  {
    age: 24,
    line1: 2.553,
    line2: 2.965,
    line3: 3.429,
    line4: 3.95,
    line5: 4.532,
    line6: 5.183,
    line7: 5.906,
  },
  {
    age: 25,
    line1: 2.582,
    line2: 2.996,
    line3: 3.463,
    line4: 3.987,
    line5: 4.574,
    line6: 5.229,
    line7: 5.958,
  },
  {
    age: 26,
    line1: 2.61,
    line2: 3.027,
    line3: 3.497,
    line4: 4.025,
    line5: 4.616,
    line6: 5.275,
    line7: 6.01,
  },
  {
    age: 27,
    line1: 2.638,
    line2: 3.058,
    line3: 3.531,
    line4: 4.062,
    line5: 4.657,
    line6: 5.321,
    line7: 6.061,
  },
  {
    age: 28,
    line1: 2.665,
    line2: 3.088,
    line3: 3.564,
    line4: 4.099,
    line5: 4.698,
    line6: 5.366,
    line7: 6.112,
  },
  {
    age: 29,
    line1: 2.693,
    line2: 3.118,
    line3: 3.597,
    line4: 4.135,
    line5: 4.738,
    line6: 5.411,
    line7: 6.162,
  },
  {
    age: 30,
    line1: 2.72,
    line2: 3.148,
    line3: 3.63,
    line4: 4.172,
    line5: 4.778,
    line6: 5.456,
    line7: 6.212,
  },
  {
    age: 31,
    line1: 2.747,
    line2: 3.178,
    line3: 3.663,
    line4: 4.208,
    line5: 4.818,
    line6: 5.5,
    line7: 6.261,
  },
  {
    age: 32,
    line1: 2.774,
    line2: 3.207,
    line3: 3.695,
    line4: 4.243,
    line5: 4.857,
    line6: 5.544,
    line7: 6.31,
  },
  {
    age: 33,
    line1: 2.801,
    line2: 3.236,
    line3: 3.727,
    line4: 4.278,
    line5: 4.896,
    line6: 5.587,
    line7: 6.358,
  },
  {
    age: 34,
    line1: 2.827,
    line2: 3.265,
    line3: 3.758,
    line4: 4.313,
    line5: 4.935,
    line6: 5.63,
    line7: 6.406,
  },
  {
    age: 35,
    line1: 2.853,
    line2: 3.294,
    line3: 3.79,
    line4: 4.348,
    line5: 4.973,
    line6: 5.672,
    line7: 6.453,
  },
  {
    age: 36,
    line1: 2.879,
    line2: 3.322,
    line3: 3.821,
    line4: 4.382,
    line5: 5.011,
    line6: 5.714,
    line7: 6.5,
  },
  {
    age: 37,
    line1: 2.904,
    line2: 3.35,
    line3: 3.851,
    line4: 4.416,
    line5: 5.048,
    line6: 5.756,
    line7: 6.546,
  },
  {
    age: 38,
    line1: 2.93,
    line2: 3.377,
    line3: 3.882,
    line4: 4.449,
    line5: 5.085,
    line6: 5.797,
    line7: 6.592,
  },
  {
    age: 39,
    line1: 2.955,
    line2: 3.405,
    line3: 3.912,
    line4: 4.482,
    line5: 5.122,
    line6: 5.837,
    line7: 6.637,
  },
  {
    age: 40,
    line1: 2.98,
    line2: 3.432,
    line3: 3.942,
    line4: 4.515,
    line5: 5.158,
    line6: 5.878,
    line7: 6.682,
  },
  {
    age: 41,
    line1: 3.004,
    line2: 3.459,
    line3: 3.971,
    line4: 4.547,
    line5: 5.194,
    line6: 5.917,
    line7: 6.726,
  },
  {
    age: 42,
    line1: 3.029,
    line2: 3.485,
    line3: 4,
    line4: 4.579,
    line5: 5.229,
    line6: 5.957,
    line7: 6.77,
  },
  {
    age: 43,
    line1: 3.053,
    line2: 3.512,
    line3: 4.029,
    line4: 4.611,
    line5: 5.264,
    line6: 5.995,
    line7: 6.813,
  },
  {
    age: 44,
    line1: 3.077,
    line2: 3.538,
    line3: 4.058,
    line4: 4.642,
    line5: 5.299,
    line6: 6.034,
    line7: 6.856,
  },
  {
    age: 45,
    line1: 3.1,
    line2: 3.564,
    line3: 4.086,
    line4: 4.674,
    line5: 5.333,
    line6: 6.072,
    line7: 6.898,
  },
  {
    age: 46,
    line1: 3.124,
    line2: 3.589,
    line3: 4.114,
    line4: 4.704,
    line5: 5.367,
    line6: 6.11,
    line7: 6.941,
  },
  {
    age: 47,
    line1: 3.147,
    line2: 3.614,
    line3: 4.142,
    line4: 4.735,
    line5: 5.401,
    line6: 6.147,
    line7: 6.982,
  },
  {
    age: 48,
    line1: 3.17,
    line2: 3.639,
    line3: 4.169,
    line4: 4.765,
    line5: 5.434,
    line6: 6.184,
    line7: 7.024,
  },
  {
    age: 49,
    line1: 3.192,
    line2: 3.664,
    line3: 4.196,
    line4: 4.795,
    line5: 5.467,
    line6: 6.221,
    line7: 7.064,
  },
  {
    age: 50,
    line1: 3.215,
    line2: 3.689,
    line3: 4.223,
    line4: 4.824,
    line5: 5.5,
    line6: 6.257,
    line7: 7.105,
  },
  {
    age: 51,
    line1: 3.237,
    line2: 3.713,
    line3: 4.25,
    line4: 4.854,
    line5: 5.532,
    line6: 6.293,
    line7: 7.145,
  },
  {
    age: 52,
    line1: 3.259,
    line2: 3.737,
    line3: 4.276,
    line4: 4.883,
    line5: 5.564,
    line6: 6.329,
    line7: 7.185,
  },
  {
    age: 53,
    line1: 3.281,
    line2: 3.761,
    line3: 4.302,
    line4: 4.912,
    line5: 5.596,
    line6: 6.364,
    line7: 7.224,
  },
  {
    age: 54,
    line1: 3.303,
    line2: 3.785,
    line3: 4.328,
    line4: 4.94,
    line5: 5.627,
    line6: 6.399,
    line7: 7.263,
  },
  {
    age: 55,
    line1: 3.324,
    line2: 3.808,
    line3: 4.354,
    line4: 4.968,
    line5: 5.658,
    line6: 6.433,
    line7: 7.301,
  },
  {
    age: 56,
    line1: 3.345,
    line2: 3.831,
    line3: 4.379,
    line4: 4.996,
    line5: 5.689,
    line6: 6.467,
    line7: 7.339,
  },
  {
    age: 57,
    line1: 3.366,
    line2: 3.854,
    line3: 4.404,
    line4: 5.024,
    line5: 5.72,
    line6: 6.501,
    line7: 7.377,
  },
  {
    age: 58,
    line1: 3.387,
    line2: 3.877,
    line3: 4.429,
    line4: 5.051,
    line5: 5.75,
    line6: 6.535,
    line7: 7.414,
  },
  {
    age: 59,
    line1: 3.408,
    line2: 3.899,
    line3: 4.454,
    line4: 5.078,
    line5: 5.78,
    line6: 6.568,
    line7: 7.452,
  },
  {
    age: 60,
    line1: 3.428,
    line2: 3.922,
    line3: 4.478,
    line4: 5.105,
    line5: 5.81,
    line6: 6.601,
    line7: 7.488,
  },
  {
    age: 61,
    line1: 3.449,
    line2: 3.944,
    line3: 4.502,
    line4: 5.132,
    line5: 5.839,
    line6: 6.634,
    line7: 7.525,
  },
  {
    age: 62,
    line1: 3.469,
    line2: 3.966,
    line3: 4.526,
    line4: 5.158,
    line5: 5.868,
    line6: 6.666,
    line7: 7.561,
  },
  {
    age: 63,
    line1: 3.489,
    line2: 3.987,
    line3: 4.55,
    line4: 5.184,
    line5: 5.897,
    line6: 6.699,
    line7: 7.597,
  },
  {
    age: 64,
    line1: 3.508,
    line2: 4.009,
    line3: 4.574,
    line4: 5.21,
    line5: 5.926,
    line6: 6.731,
    line7: 7.633,
  },
  {
    age: 65,
    line1: 3.528,
    line2: 4.031,
    line3: 4.597,
    line4: 5.236,
    line5: 5.955,
    line6: 6.762,
    line7: 7.669,
  },
  {
    age: 66,
    line1: 3.548,
    line2: 4.052,
    line3: 4.621,
    line4: 5.262,
    line5: 5.983,
    line6: 6.794,
    line7: 7.704,
  },
  {
    age: 67,
    line1: 3.567,
    line2: 4.073,
    line3: 4.644,
    line4: 5.287,
    line5: 6.011,
    line6: 6.825,
    line7: 7.739,
  },
  {
    age: 68,
    line1: 3.586,
    line2: 4.094,
    line3: 4.667,
    line4: 5.312,
    line5: 6.039,
    line6: 6.856,
    line7: 7.773,
  },
  {
    age: 69,
    line1: 3.605,
    line2: 4.114,
    line3: 4.689,
    line4: 5.337,
    line5: 6.066,
    line6: 6.886,
    line7: 7.807,
  },
  {
    age: 70,
    line1: 3.624,
    line2: 4.135,
    line3: 4.712,
    line4: 5.362,
    line5: 6.094,
    line6: 6.917,
    line7: 7.842,
  },
  {
    age: 71,
    line1: 3.643,
    line2: 4.155,
    line3: 4.734,
    line4: 5.386,
    line5: 6.121,
    line6: 6.947,
    line7: 7.875,
  },
  {
    age: 72,
    line1: 3.661,
    line2: 4.175,
    line3: 4.756,
    line4: 5.411,
    line5: 6.148,
    line6: 6.977,
    line7: 7.909,
  },
  {
    age: 73,
    line1: 3.679,
    line2: 4.195,
    line3: 4.778,
    line4: 5.435,
    line5: 6.175,
    line6: 7.007,
    line7: 7.942,
  },
  {
    age: 74,
    line1: 3.698,
    line2: 4.215,
    line3: 4.8,
    line4: 5.459,
    line5: 6.201,
    line6: 7.036,
    line7: 7.975,
  },
  {
    age: 75,
    line1: 3.716,
    line2: 4.235,
    line3: 4.821,
    line4: 5.482,
    line5: 6.227,
    line6: 7.066,
    line7: 8.008,
  },
  {
    age: 76,
    line1: 3.734,
    line2: 4.255,
    line3: 4.843,
    line4: 5.506,
    line5: 6.254,
    line6: 7.095,
    line7: 8.041,
  },
  {
    age: 77,
    line1: 3.752,
    line2: 4.274,
    line3: 4.864,
    line4: 5.53,
    line5: 6.279,
    line6: 7.124,
    line7: 8.073,
  },
  {
    age: 78,
    line1: 3.769,
    line2: 4.293,
    line3: 4.885,
    line4: 5.553,
    line5: 6.305,
    line6: 7.152,
    line7: 8.105,
  },
  {
    age: 79,
    line1: 3.787,
    line2: 4.312,
    line3: 4.906,
    line4: 5.576,
    line5: 6.331,
    line6: 7.181,
    line7: 8.137,
  },
  {
    age: 80,
    line1: 3.804,
    line2: 4.331,
    line3: 4.927,
    line4: 5.599,
    line5: 6.356,
    line6: 7.209,
    line7: 8.169,
  },
  {
    age: 81,
    line1: 3.821,
    line2: 4.35,
    line3: 4.947,
    line4: 5.621,
    line5: 6.381,
    line6: 7.237,
    line7: 8.2,
  },
  {
    age: 82,
    line1: 3.838,
    line2: 4.369,
    line3: 4.968,
    line4: 5.644,
    line5: 6.406,
    line6: 7.265,
    line7: 8.231,
  },
  {
    age: 83,
    line1: 3.855,
    line2: 4.387,
    line3: 4.988,
    line4: 5.666,
    line5: 6.431,
    line6: 7.292,
    line7: 8.262,
  },
  {
    age: 84,
    line1: 3.872,
    line2: 4.406,
    line3: 5.008,
    line4: 5.688,
    line5: 6.455,
    line6: 7.32,
    line7: 8.293,
  },
  {
    age: 85,
    line1: 3.889,
    line2: 4.424,
    line3: 5.028,
    line4: 5.71,
    line5: 6.48,
    line6: 7.347,
    line7: 8.324,
  },
  {
    age: 86,
    line1: 3.905,
    line2: 4.442,
    line3: 5.048,
    line4: 5.732,
    line5: 6.504,
    line6: 7.374,
    line7: 8.354,
  },
  {
    age: 87,
    line1: 3.922,
    line2: 4.46,
    line3: 5.068,
    line4: 5.754,
    line5: 6.528,
    line6: 7.401,
    line7: 8.384,
  },
  {
    age: 88,
    line1: 3.938,
    line2: 4.478,
    line3: 5.087,
    line4: 5.776,
    line5: 6.552,
    line6: 7.428,
    line7: 8.414,
  },
  {
    age: 89,
    line1: 3.955,
    line2: 4.496,
    line3: 5.107,
    line4: 5.797,
    line5: 6.576,
    line6: 7.454,
    line7: 8.444,
  },
  {
    age: 90,
    line1: 3.971,
    line2: 4.513,
    line3: 5.126,
    line4: 5.818,
    line5: 6.599,
    line6: 7.48,
    line7: 8.473,
  },
  {
    age: 91,
    line1: 3.987,
    line2: 4.531,
    line3: 5.145,
    line4: 5.839,
    line5: 6.623,
    line6: 7.506,
    line7: 8.503,
  },
  {
    age: 92,
    line1: 4.003,
    line2: 4.548,
    line3: 5.164,
    line4: 5.86,
    line5: 6.646,
    line6: 7.532,
    line7: 8.532,
  },
  {
    age: 93,
    line1: 4.018,
    line2: 4.565,
    line3: 5.183,
    line4: 5.881,
    line5: 6.669,
    line6: 7.558,
    line7: 8.561,
  },
  {
    age: 94,
    line1: 4.034,
    line2: 4.582,
    line3: 5.202,
    line4: 5.902,
    line5: 6.692,
    line6: 7.584,
    line7: 8.59,
  },
  {
    age: 95,
    line1: 4.05,
    line2: 4.599,
    line3: 5.221,
    line4: 5.922,
    line5: 6.715,
    line6: 7.609,
    line7: 8.618,
  },
  {
    age: 96,
    line1: 4.065,
    line2: 4.616,
    line3: 5.239,
    line4: 5.943,
    line5: 6.737,
    line6: 7.634,
    line7: 8.647,
  },
  {
    age: 97,
    line1: 4.081,
    line2: 4.633,
    line3: 5.257,
    line4: 5.963,
    line5: 6.76,
    line6: 7.66,
    line7: 8.675,
  },
  {
    age: 98,
    line1: 4.096,
    line2: 4.65,
    line3: 5.276,
    line4: 5.983,
    line5: 6.782,
    line6: 7.685,
    line7: 8.703,
  },
  {
    age: 99,
    line1: 4.111,
    line2: 4.666,
    line3: 5.294,
    line4: 6.003,
    line5: 6.805,
    line6: 7.71,
    line7: 8.731,
  },
  {
    age: 100,
    line1: 4.126,
    line2: 4.683,
    line3: 5.312,
    line4: 6.023,
    line5: 6.826,
    line6: 7.734,
    line7: 8.759,
  },
  {
    age: 101,
    line1: 4.141,
    line2: 4.699,
    line3: 5.33,
    line4: 6.043,
    line5: 6.848,
    line6: 7.759,
    line7: 8.786,
  },
  {
    age: 102,
    line1: 4.156,
    line2: 4.715,
    line3: 5.347,
    line4: 6.062,
    line5: 6.87,
    line6: 7.783,
    line7: 8.814,
  },
  {
    age: 103,
    line1: 4.171,
    line2: 4.731,
    line3: 5.365,
    line4: 6.082,
    line5: 6.892,
    line6: 7.807,
    line7: 8.841,
  },
  {
    age: 104,
    line1: 4.185,
    line2: 4.747,
    line3: 5.383,
    line4: 6.101,
    line5: 6.913,
    line6: 7.831,
    line7: 8.868,
  },
  {
    age: 105,
    line1: 4.2,
    line2: 4.763,
    line3: 5.4,
    line4: 6.12,
    line5: 6.935,
    line6: 7.855,
    line7: 8.895,
  },
  {
    age: 106,
    line1: 4.214,
    line2: 4.779,
    line3: 5.417,
    line4: 6.139,
    line5: 6.956,
    line6: 7.879,
    line7: 8.922,
  },
  {
    age: 107,
    line1: 4.229,
    line2: 4.794,
    line3: 5.434,
    line4: 6.158,
    line5: 6.977,
    line6: 7.902,
    line7: 8.948,
  },
  {
    age: 108,
    line1: 4.243,
    line2: 4.81,
    line3: 5.451,
    line4: 6.177,
    line5: 6.998,
    line6: 7.926,
    line7: 8.975,
  },
  {
    age: 109,
    line1: 4.257,
    line2: 4.825,
    line3: 5.468,
    line4: 6.196,
    line5: 7.019,
    line6: 7.949,
    line7: 9.001,
  },
  {
    age: 110,
    line1: 4.271,
    line2: 4.841,
    line3: 5.485,
    line4: 6.214,
    line5: 7.039,
    line6: 7.972,
    line7: 9.027,
  },
  {
    age: 111,
    line1: 4.285,
    line2: 4.856,
    line3: 5.502,
    line4: 6.233,
    line5: 7.06,
    line6: 7.995,
    line7: 9.053,
  },
  {
    age: 112,
    line1: 4.299,
    line2: 4.871,
    line3: 5.518,
    line4: 6.251,
    line5: 7.08,
    line6: 8.018,
    line7: 9.079,
  },
  {
    age: 113,
    line1: 4.313,
    line2: 4.886,
    line3: 5.535,
    line4: 6.269,
    line5: 7.1,
    line6: 8.041,
    line7: 9.104,
  },
  {
    age: 114,
    line1: 4.326,
    line2: 4.901,
    line3: 5.551,
    line4: 6.287,
    line5: 7.12,
    line6: 8.063,
    line7: 9.13,
  },
  {
    age: 115,
    line1: 4.34,
    line2: 4.916,
    line3: 5.568,
    line4: 6.305,
    line5: 7.141,
    line6: 8.086,
    line7: 9.155,
  },
  {
    age: 116,
    line1: 4.353,
    line2: 4.93,
    line3: 5.584,
    line4: 6.323,
    line5: 7.16,
    line6: 8.108,
    line7: 9.18,
  },
  {
    age: 117,
    line1: 4.367,
    line2: 4.945,
    line3: 5.6,
    line4: 6.341,
    line5: 7.18,
    line6: 8.13,
    line7: 9.206,
  },
  {
    age: 118,
    line1: 4.38,
    line2: 4.96,
    line3: 5.616,
    line4: 6.359,
    line5: 7.2,
    line6: 8.152,
    line7: 9.23,
  },
  {
    age: 119,
    line1: 4.394,
    line2: 4.974,
    line3: 5.632,
    line4: 6.376,
    line5: 7.219,
    line6: 8.174,
    line7: 9.255,
  },
  {
    age: 120,
    line1: 4.407,
    line2: 4.988,
    line3: 5.647,
    line4: 6.394,
    line5: 7.239,
    line6: 8.196,
    line7: 9.28,
  },
  {
    age: 121,
    line1: 4.42,
    line2: 5.003,
    line3: 5.663,
    line4: 6.411,
    line5: 7.258,
    line6: 8.217,
    line7: 9.304,
  },
  {
    age: 122,
    line1: 4.433,
    line2: 5.017,
    line3: 5.679,
    line4: 6.428,
    line5: 7.277,
    line6: 8.239,
    line7: 9.328,
  },
  {
    age: 123,
    line1: 4.446,
    line2: 5.031,
    line3: 5.694,
    line4: 6.445,
    line5: 7.296,
    line6: 8.26,
    line7: 9.352,
  },
  {
    age: 124,
    line1: 4.458,
    line2: 5.045,
    line3: 5.709,
    line4: 6.462,
    line5: 7.315,
    line6: 8.281,
    line7: 9.376,
  },
  {
    age: 125,
    line1: 4.471,
    line2: 5.059,
    line3: 5.725,
    line4: 6.479,
    line5: 7.334,
    line6: 8.302,
    line7: 9.4,
  },
  {
    age: 126,
    line1: 4.484,
    line2: 5.073,
    line3: 5.74,
    line4: 6.496,
    line5: 7.352,
    line6: 8.323,
    line7: 9.424,
  },
  {
    age: 127,
    line1: 4.496,
    line2: 5.086,
    line3: 5.755,
    line4: 6.512,
    line5: 7.371,
    line6: 8.344,
    line7: 9.448,
  },
  {
    age: 128,
    line1: 4.509,
    line2: 5.1,
    line3: 5.77,
    line4: 6.529,
    line5: 7.389,
    line6: 8.365,
    line7: 9.471,
  },
  {
    age: 129,
    line1: 4.521,
    line2: 5.113,
    line3: 5.785,
    line4: 6.545,
    line5: 7.408,
    line6: 8.385,
    line7: 9.494,
  },
  {
    age: 130,
    line1: 4.534,
    line2: 5.127,
    line3: 5.799,
    line4: 6.562,
    line5: 7.426,
    line6: 8.406,
    line7: 9.517,
  },
  {
    age: 131,
    line1: 4.546,
    line2: 5.14,
    line3: 5.814,
    line4: 6.578,
    line5: 7.444,
    line6: 8.426,
    line7: 9.54,
  },
  {
    age: 132,
    line1: 4.558,
    line2: 5.154,
    line3: 5.829,
    line4: 6.594,
    line5: 7.462,
    line6: 8.446,
    line7: 9.563,
  },
  {
    age: 133,
    line1: 4.57,
    line2: 5.167,
    line3: 5.843,
    line4: 6.61,
    line5: 7.48,
    line6: 8.466,
    line7: 9.586,
  },
  {
    age: 134,
    line1: 4.582,
    line2: 5.18,
    line3: 5.857,
    line4: 6.626,
    line5: 7.497,
    line6: 8.486,
    line7: 9.609,
  },
  {
    age: 135,
    line1: 4.594,
    line2: 5.193,
    line3: 5.872,
    line4: 6.642,
    line5: 7.515,
    line6: 8.506,
    line7: 9.631,
  },
  {
    age: 136,
    line1: 4.606,
    line2: 5.206,
    line3: 5.886,
    line4: 6.657,
    line5: 7.532,
    line6: 8.526,
    line7: 9.654,
  },
  {
    age: 137,
    line1: 4.618,
    line2: 5.219,
    line3: 5.9,
    line4: 6.673,
    line5: 7.55,
    line6: 8.545,
    line7: 9.676,
  },
  {
    age: 138,
    line1: 4.629,
    line2: 5.231,
    line3: 5.914,
    line4: 6.688,
    line5: 7.567,
    line6: 8.565,
    line7: 9.698,
  },
  {
    age: 139,
    line1: 4.641,
    line2: 5.244,
    line3: 5.928,
    line4: 6.704,
    line5: 7.585,
    line6: 8.584,
    line7: 9.72,
  },
  {
    age: 140,
    line1: 4.652,
    line2: 5.257,
    line3: 5.942,
    line4: 6.719,
    line5: 7.602,
    line6: 8.604,
    line7: 9.742,
  },
  {
    age: 141,
    line1: 4.664,
    line2: 5.269,
    line3: 5.956,
    line4: 6.734,
    line5: 7.619,
    line6: 8.623,
    line7: 9.764,
  },
  {
    age: 142,
    line1: 4.675,
    line2: 5.282,
    line3: 5.969,
    line4: 6.75,
    line5: 7.635,
    line6: 8.642,
    line7: 9.785,
  },
  {
    age: 143,
    line1: 4.687,
    line2: 5.294,
    line3: 5.983,
    line4: 6.765,
    line5: 7.652,
    line6: 8.661,
    line7: 9.807,
  },
  {
    age: 144,
    line1: 4.698,
    line2: 5.306,
    line3: 5.996,
    line4: 6.78,
    line5: 7.669,
    line6: 8.679,
    line7: 9.828,
  },
  {
    age: 145,
    line1: 4.709,
    line2: 5.318,
    line3: 6.01,
    line4: 6.794,
    line5: 7.686,
    line6: 8.698,
    line7: 9.849,
  },
  {
    age: 146,
    line1: 4.72,
    line2: 5.331,
    line3: 6.023,
    line4: 6.809,
    line5: 7.702,
    line6: 8.717,
    line7: 9.87,
  },
  {
    age: 147,
    line1: 4.731,
    line2: 5.343,
    line3: 6.036,
    line4: 6.824,
    line5: 7.718,
    line6: 8.735,
    line7: 9.892,
  },
  {
    age: 148,
    line1: 4.742,
    line2: 5.355,
    line3: 6.049,
    line4: 6.838,
    line5: 7.735,
    line6: 8.753,
    line7: 9.912,
  },
  {
    age: 149,
    line1: 4.753,
    line2: 5.366,
    line3: 6.063,
    line4: 6.853,
    line5: 7.751,
    line6: 8.772,
    line7: 9.933,
  },
  {
    age: 150,
    line1: 4.764,
    line2: 5.378,
    line3: 6.076,
    line4: 6.867,
    line5: 7.767,
    line6: 8.79,
    line7: 9.954,
  },
  {
    age: 151,
    line1: 4.775,
    line2: 5.39,
    line3: 6.088,
    line4: 6.882,
    line5: 7.783,
    line6: 8.808,
    line7: 9.974,
  },
  {
    age: 152,
    line1: 4.785,
    line2: 5.402,
    line3: 6.101,
    line4: 6.896,
    line5: 7.799,
    line6: 8.826,
    line7: 9.995,
  },
  {
    age: 153,
    line1: 4.796,
    line2: 5.413,
    line3: 6.114,
    line4: 6.91,
    line5: 7.815,
    line6: 8.844,
    line7: 10.015,
  },
  {
    age: 154,
    line1: 4.807,
    line2: 5.425,
    line3: 6.127,
    line4: 6.924,
    line5: 7.831,
    line6: 8.862,
    line7: 10.036,
  },
  {
    age: 155,
    line1: 4.817,
    line2: 5.436,
    line3: 6.139,
    line4: 6.938,
    line5: 7.846,
    line6: 8.879,
    line7: 10.056,
  },
  {
    age: 156,
    line1: 4.828,
    line2: 5.448,
    line3: 6.152,
    line4: 6.952,
    line5: 7.862,
    line6: 8.897,
    line7: 10.076,
  },
  {
    age: 157,
    line1: 4.838,
    line2: 5.459,
    line3: 6.164,
    line4: 6.966,
    line5: 7.877,
    line6: 8.914,
    line7: 10.096,
  },
  {
    age: 158,
    line1: 4.848,
    line2: 5.47,
    line3: 6.177,
    line4: 6.98,
    line5: 7.893,
    line6: 8.932,
    line7: 10.116,
  },
  {
    age: 159,
    line1: 4.859,
    line2: 5.482,
    line3: 6.189,
    line4: 6.993,
    line5: 7.908,
    line6: 8.949,
    line7: 10.136,
  },
  {
    age: 160,
    line1: 4.869,
    line2: 5.493,
    line3: 6.201,
    line4: 7.007,
    line5: 7.923,
    line6: 8.967,
    line7: 10.155,
  },
  {
    age: 161,
    line1: 4.879,
    line2: 5.504,
    line3: 6.214,
    line4: 7.02,
    line5: 7.938,
    line6: 8.984,
    line7: 10.175,
  },
  {
    age: 162,
    line1: 4.889,
    line2: 5.515,
    line3: 6.226,
    line4: 7.034,
    line5: 7.954,
    line6: 9.001,
    line7: 10.194,
  },
  {
    age: 163,
    line1: 4.899,
    line2: 5.526,
    line3: 6.238,
    line4: 7.047,
    line5: 7.969,
    line6: 9.018,
    line7: 10.214,
  },
  {
    age: 164,
    line1: 4.909,
    line2: 5.537,
    line3: 6.25,
    line4: 7.061,
    line5: 7.984,
    line6: 9.035,
    line7: 10.233,
  },
  {
    age: 165,
    line1: 4.919,
    line2: 5.548,
    line3: 6.262,
    line4: 7.074,
    line5: 7.998,
    line6: 9.051,
    line7: 10.252,
  },
  {
    age: 166,
    line1: 4.929,
    line2: 5.558,
    line3: 6.274,
    line4: 7.087,
    line5: 8.013,
    line6: 9.068,
    line7: 10.271,
  },
  {
    age: 167,
    line1: 4.938,
    line2: 5.569,
    line3: 6.285,
    line4: 7.1,
    line5: 8.028,
    line6: 9.085,
    line7: 10.29,
  },
  {
    age: 168,
    line1: 4.948,
    line2: 5.58,
    line3: 6.297,
    line4: 7.113,
    line5: 8.042,
    line6: 9.101,
    line7: 10.309,
  },
  {
    age: 169,
    line1: 4.958,
    line2: 5.59,
    line3: 6.309,
    line4: 7.126,
    line5: 8.057,
    line6: 9.118,
    line7: 10.328,
  },
  {
    age: 170,
    line1: 4.968,
    line2: 5.601,
    line3: 6.321,
    line4: 7.139,
    line5: 8.072,
    line6: 9.134,
    line7: 10.347,
  },
  {
    age: 171,
    line1: 4.977,
    line2: 5.611,
    line3: 6.332,
    line4: 7.152,
    line5: 8.086,
    line6: 9.151,
    line7: 10.366,
  },
  {
    age: 172,
    line1: 4.987,
    line2: 5.622,
    line3: 6.344,
    line4: 7.165,
    line5: 8.1,
    line6: 9.167,
    line7: 10.384,
  },
  {
    age: 173,
    line1: 4.996,
    line2: 5.632,
    line3: 6.355,
    line4: 7.178,
    line5: 8.115,
    line6: 9.183,
    line7: 10.403,
  },
  {
    age: 174,
    line1: 5.006,
    line2: 5.642,
    line3: 6.366,
    line4: 7.19,
    line5: 8.129,
    line6: 9.199,
    line7: 10.421,
  },
  {
    age: 175,
    line1: 5.015,
    line2: 5.653,
    line3: 6.378,
    line4: 7.203,
    line5: 8.143,
    line6: 9.215,
    line7: 10.44,
  },
  {
    age: 176,
    line1: 5.024,
    line2: 5.663,
    line3: 6.389,
    line4: 7.215,
    line5: 8.157,
    line6: 9.231,
    line7: 10.458,
  },
  {
    age: 177,
    line1: 5.034,
    line2: 5.673,
    line3: 6.4,
    line4: 7.228,
    line5: 8.171,
    line6: 9.247,
    line7: 10.477,
  },
  {
    age: 178,
    line1: 5.043,
    line2: 5.683,
    line3: 6.411,
    line4: 7.24,
    line5: 8.185,
    line6: 9.263,
    line7: 10.495,
  },
  {
    age: 179,
    line1: 5.052,
    line2: 5.693,
    line3: 6.422,
    line4: 7.253,
    line5: 8.199,
    line6: 9.279,
    line7: 10.513,
  },
  {
    age: 180,
    line1: 5.061,
    line2: 5.703,
    line3: 6.434,
    line4: 7.265,
    line5: 8.213,
    line6: 9.295,
    line7: 10.531,
  },
  {
    age: 181,
    line1: 5.07,
    line2: 5.713,
    line3: 6.444,
    line4: 7.277,
    line5: 8.227,
    line6: 9.31,
    line7: 10.549,
  },
  {
    age: 182,
    line1: 5.079,
    line2: 5.723,
    line3: 6.455,
    line4: 7.289,
    line5: 8.24,
    line6: 9.326,
    line7: 10.566,
  },
  {
    age: 183,
    line1: 5.088,
    line2: 5.733,
    line3: 6.466,
    line4: 7.302,
    line5: 8.254,
    line6: 9.341,
    line7: 10.585,
  },
  {
    age: 184,
    line1: 5.097,
    line2: 5.743,
    line3: 6.477,
    line4: 7.314,
    line5: 8.267,
    line6: 9.357,
    line7: 10.602,
  },
  {
    age: 185,
    line1: 5.106,
    line2: 5.753,
    line3: 6.488,
    line4: 7.326,
    line5: 8.281,
    line6: 9.372,
    line7: 10.62,
  },
  {
    age: 186,
    line1: 5.115,
    line2: 5.762,
    line3: 6.499,
    line4: 7.338,
    line5: 8.295,
    line6: 9.388,
    line7: 10.638,
  },
  {
    age: 187,
    line1: 5.124,
    line2: 5.772,
    line3: 6.509,
    line4: 7.35,
    line5: 8.308,
    line6: 9.403,
    line7: 10.655,
  },
  {
    age: 188,
    line1: 5.133,
    line2: 5.782,
    line3: 6.52,
    line4: 7.361,
    line5: 8.321,
    line6: 9.418,
    line7: 10.673,
  },
  {
    age: 189,
    line1: 5.141,
    line2: 5.791,
    line3: 6.531,
    line4: 7.373,
    line5: 8.335,
    line6: 9.433,
    line7: 10.69,
  },
  {
    age: 190,
    line1: 5.15,
    line2: 5.801,
    line3: 6.541,
    line4: 7.385,
    line5: 8.348,
    line6: 9.448,
    line7: 10.707,
  },
  {
    age: 191,
    line1: 5.159,
    line2: 5.81,
    line3: 6.552,
    line4: 7.397,
    line5: 8.361,
    line6: 9.463,
    line7: 10.725,
  },
  {
    age: 192,
    line1: 5.167,
    line2: 5.82,
    line3: 6.562,
    line4: 7.408,
    line5: 8.374,
    line6: 9.478,
    line7: 10.742,
  },
  {
    age: 193,
    line1: 5.176,
    line2: 5.829,
    line3: 6.572,
    line4: 7.42,
    line5: 8.387,
    line6: 9.493,
    line7: 10.759,
  },
  {
    age: 194,
    line1: 5.185,
    line2: 5.838,
    line3: 6.583,
    line4: 7.431,
    line5: 8.4,
    line6: 9.508,
    line7: 10.776,
  },
  {
    age: 195,
    line1: 5.193,
    line2: 5.848,
    line3: 6.593,
    line4: 7.443,
    line5: 8.413,
    line6: 9.523,
    line7: 10.793,
  },
  {
    age: 196,
    line1: 5.201,
    line2: 5.857,
    line3: 6.603,
    line4: 7.454,
    line5: 8.426,
    line6: 9.537,
    line7: 10.81,
  },
  {
    age: 197,
    line1: 5.21,
    line2: 5.866,
    line3: 6.613,
    line4: 7.466,
    line5: 8.439,
    line6: 9.552,
    line7: 10.827,
  },
  {
    age: 198,
    line1: 5.218,
    line2: 5.875,
    line3: 6.624,
    line4: 7.477,
    line5: 8.452,
    line6: 9.567,
    line7: 10.844,
  },
  {
    age: 199,
    line1: 5.226,
    line2: 5.884,
    line3: 6.634,
    line4: 7.488,
    line5: 8.465,
    line6: 9.581,
    line7: 10.861,
  },
  {
    age: 200,
    line1: 5.235,
    line2: 5.893,
    line3: 6.644,
    line4: 7.5,
    line5: 8.477,
    line6: 9.596,
    line7: 10.877,
  },
  {
    age: 201,
    line1: 5.243,
    line2: 5.902,
    line3: 6.654,
    line4: 7.511,
    line5: 8.49,
    line6: 9.61,
    line7: 10.894,
  },
  {
    age: 202,
    line1: 5.251,
    line2: 5.911,
    line3: 6.664,
    line4: 7.522,
    line5: 8.503,
    line6: 9.625,
    line7: 10.911,
  },
  {
    age: 203,
    line1: 5.259,
    line2: 5.92,
    line3: 6.674,
    line4: 7.533,
    line5: 8.515,
    line6: 9.639,
    line7: 10.927,
  },
  {
    age: 204,
    line1: 5.267,
    line2: 5.929,
    line3: 6.683,
    line4: 7.544,
    line5: 8.527,
    line6: 9.653,
    line7: 10.944,
  },
  {
    age: 205,
    line1: 5.276,
    line2: 5.938,
    line3: 6.693,
    line4: 7.555,
    line5: 8.54,
    line6: 9.668,
    line7: 10.961,
  },
  {
    age: 206,
    line1: 5.284,
    line2: 5.947,
    line3: 6.703,
    line4: 7.566,
    line5: 8.552,
    line6: 9.682,
    line7: 10.977,
  },
  {
    age: 207,
    line1: 5.292,
    line2: 5.956,
    line3: 6.713,
    line4: 7.577,
    line5: 8.565,
    line6: 9.696,
    line7: 10.993,
  },
  {
    age: 208,
    line1: 5.3,
    line2: 5.965,
    line3: 6.723,
    line4: 7.588,
    line5: 8.577,
    line6: 9.71,
    line7: 11.009,
  },
  {
    age: 209,
    line1: 5.307,
    line2: 5.973,
    line3: 6.732,
    line4: 7.599,
    line5: 8.589,
    line6: 9.724,
    line7: 11.026,
  },
  {
    age: 210,
    line1: 5.315,
    line2: 5.982,
    line3: 6.742,
    line4: 7.609,
    line5: 8.601,
    line6: 9.738,
    line7: 11.042,
  },
  {
    age: 211,
    line1: 5.323,
    line2: 5.991,
    line3: 6.751,
    line4: 7.62,
    line5: 8.614,
    line6: 9.752,
    line7: 11.058,
  },
  {
    age: 212,
    line1: 5.331,
    line2: 5.999,
    line3: 6.761,
    line4: 7.631,
    line5: 8.626,
    line6: 9.766,
    line7: 11.074,
  },
  {
    age: 213,
    line1: 5.339,
    line2: 6.008,
    line3: 6.771,
    line4: 7.642,
    line5: 8.638,
    line6: 9.78,
    line7: 11.09,
  },
  {
    age: 214,
    line1: 5.347,
    line2: 6.016,
    line3: 6.78,
    line4: 7.652,
    line5: 8.65,
    line6: 9.793,
    line7: 11.106,
  },
  {
    age: 215,
    line1: 5.354,
    line2: 6.025,
    line3: 6.789,
    line4: 7.663,
    line5: 8.662,
    line6: 9.807,
    line7: 11.122,
  },
  {
    age: 216,
    line1: 5.362,
    line2: 6.033,
    line3: 6.799,
    line4: 7.673,
    line5: 8.674,
    line6: 9.821,
    line7: 11.138,
  },
  {
    age: 217,
    line1: 5.37,
    line2: 6.042,
    line3: 6.808,
    line4: 7.684,
    line5: 8.686,
    line6: 9.834,
    line7: 11.154,
  },
  {
    age: 218,
    line1: 5.377,
    line2: 6.05,
    line3: 6.818,
    line4: 7.694,
    line5: 8.698,
    line6: 9.848,
    line7: 11.17,
  },
  {
    age: 219,
    line1: 5.385,
    line2: 6.059,
    line3: 6.827,
    line4: 7.705,
    line5: 8.71,
    line6: 9.862,
    line7: 11.185,
  },
  {
    age: 220,
    line1: 5.393,
    line2: 6.067,
    line3: 6.836,
    line4: 7.715,
    line5: 8.721,
    line6: 9.875,
    line7: 11.201,
  },
  {
    age: 221,
    line1: 5.4,
    line2: 6.075,
    line3: 6.845,
    line4: 7.725,
    line5: 8.733,
    line6: 9.889,
    line7: 11.217,
  },
  {
    age: 222,
    line1: 5.407,
    line2: 6.083,
    line3: 6.854,
    line4: 7.736,
    line5: 8.745,
    line6: 9.902,
    line7: 11.232,
  },
  {
    age: 223,
    line1: 5.415,
    line2: 6.092,
    line3: 6.864,
    line4: 7.746,
    line5: 8.756,
    line6: 9.915,
    line7: 11.248,
  },
  {
    age: 224,
    line1: 5.423,
    line2: 6.1,
    line3: 6.873,
    line4: 7.756,
    line5: 8.768,
    line6: 9.929,
    line7: 11.263,
  },
  {
    age: 225,
    line1: 5.43,
    line2: 6.108,
    line3: 6.882,
    line4: 7.766,
    line5: 8.78,
    line6: 9.942,
    line7: 11.279,
  },
  {
    age: 226,
    line1: 5.437,
    line2: 6.116,
    line3: 6.891,
    line4: 7.777,
    line5: 8.791,
    line6: 9.955,
    line7: 11.294,
  },
  {
    age: 227,
    line1: 5.445,
    line2: 6.124,
    line3: 6.9,
    line4: 7.787,
    line5: 8.803,
    line6: 9.969,
    line7: 11.31,
  },
  {
    age: 228,
    line1: 5.452,
    line2: 6.132,
    line3: 6.909,
    line4: 7.797,
    line5: 8.814,
    line6: 9.982,
    line7: 11.325,
  },
  {
    age: 229,
    line1: 5.459,
    line2: 6.14,
    line3: 6.918,
    line4: 7.807,
    line5: 8.825,
    line6: 9.995,
    line7: 11.34,
  },
  {
    age: 230,
    line1: 5.467,
    line2: 6.148,
    line3: 6.927,
    line4: 7.817,
    line5: 8.837,
    line6: 10.008,
    line7: 11.356,
  },
  {
    age: 231,
    line1: 5.474,
    line2: 6.156,
    line3: 6.936,
    line4: 7.827,
    line5: 8.848,
    line6: 10.021,
    line7: 11.371,
  },
  {
    age: 232,
    line1: 5.481,
    line2: 6.164,
    line3: 6.944,
    line4: 7.837,
    line5: 8.86,
    line6: 10.034,
    line7: 11.386,
  },
  {
    age: 233,
    line1: 5.488,
    line2: 6.172,
    line3: 6.953,
    line4: 7.847,
    line5: 8.871,
    line6: 10.047,
    line7: 11.401,
  },
  {
    age: 234,
    line1: 5.495,
    line2: 6.18,
    line3: 6.962,
    line4: 7.857,
    line5: 8.882,
    line6: 10.06,
    line7: 11.416,
  },
  {
    age: 235,
    line1: 5.502,
    line2: 6.188,
    line3: 6.971,
    line4: 7.866,
    line5: 8.893,
    line6: 10.073,
    line7: 11.431,
  },
  {
    age: 236,
    line1: 5.509,
    line2: 6.196,
    line3: 6.979,
    line4: 7.876,
    line5: 8.904,
    line6: 10.086,
    line7: 11.446,
  },
  {
    age: 237,
    line1: 5.517,
    line2: 6.204,
    line3: 6.988,
    line4: 7.886,
    line5: 8.916,
    line6: 10.099,
    line7: 11.461,
  },
  {
    age: 238,
    line1: 5.524,
    line2: 6.211,
    line3: 6.997,
    line4: 7.896,
    line5: 8.927,
    line6: 10.111,
    line7: 11.476,
  },
  {
    age: 239,
    line1: 5.531,
    line2: 6.219,
    line3: 7.005,
    line4: 7.905,
    line5: 8.938,
    line6: 10.124,
    line7: 11.491,
  },
  {
    age: 240,
    line1: 5.538,
    line2: 6.227,
    line3: 7.014,
    line4: 7.915,
    line5: 8.949,
    line6: 10.137,
    line7: 11.506,
  },
  {
    age: 241,
    line1: 5.544,
    line2: 6.234,
    line3: 7.022,
    line4: 7.925,
    line5: 8.96,
    line6: 10.15,
    line7: 11.52,
  },
  {
    age: 242,
    line1: 5.551,
    line2: 6.242,
    line3: 7.031,
    line4: 7.934,
    line5: 8.971,
    line6: 10.162,
    line7: 11.535,
  },
  {
    age: 243,
    line1: 5.558,
    line2: 6.25,
    line3: 7.04,
    line4: 7.944,
    line5: 8.981,
    line6: 10.175,
    line7: 11.55,
  },
  {
    age: 244,
    line1: 5.565,
    line2: 6.257,
    line3: 7.048,
    line4: 7.953,
    line5: 8.992,
    line6: 10.187,
    line7: 11.564,
  },
  {
    age: 245,
    line1: 5.572,
    line2: 6.265,
    line3: 7.056,
    line4: 7.963,
    line5: 9.003,
    line6: 10.2,
    line7: 11.579,
  },
  {
    age: 246,
    line1: 5.579,
    line2: 6.272,
    line3: 7.065,
    line4: 7.972,
    line5: 9.014,
    line6: 10.212,
    line7: 11.594,
  },
  {
    age: 247,
    line1: 5.586,
    line2: 6.28,
    line3: 7.073,
    line4: 7.982,
    line5: 9.025,
    line6: 10.225,
    line7: 11.608,
  },
  {
    age: 248,
    line1: 5.593,
    line2: 6.287,
    line3: 7.082,
    line4: 7.991,
    line5: 9.036,
    line6: 10.237,
    line7: 11.622,
  },
  {
    age: 249,
    line1: 5.599,
    line2: 6.295,
    line3: 7.09,
    line4: 8.001,
    line5: 9.046,
    line6: 10.249,
    line7: 11.637,
  },
  {
    age: 250,
    line1: 5.606,
    line2: 6.302,
    line3: 7.098,
    line4: 8.01,
    line5: 9.057,
    line6: 10.262,
    line7: 11.651,
  },
  {
    age: 251,
    line1: 5.613,
    line2: 6.31,
    line3: 7.106,
    line4: 8.019,
    line5: 9.068,
    line6: 10.274,
    line7: 11.666,
  },
  {
    age: 252,
    line1: 5.619,
    line2: 6.317,
    line3: 7.115,
    line4: 8.029,
    line5: 9.078,
    line6: 10.286,
    line7: 11.68,
  },
  {
    age: 253,
    line1: 5.626,
    line2: 6.324,
    line3: 7.123,
    line4: 8.038,
    line5: 9.089,
    line6: 10.298,
    line7: 11.694,
  },
  {
    age: 254,
    line1: 5.633,
    line2: 6.332,
    line3: 7.131,
    line4: 8.047,
    line5: 9.099,
    line6: 10.311,
    line7: 11.709,
  },
  {
    age: 255,
    line1: 5.639,
    line2: 6.339,
    line3: 7.139,
    line4: 8.056,
    line5: 9.11,
    line6: 10.323,
    line7: 11.723,
  },
  {
    age: 256,
    line1: 5.646,
    line2: 6.346,
    line3: 7.147,
    line4: 8.066,
    line5: 9.12,
    line6: 10.335,
    line7: 11.737,
  },
  {
    age: 257,
    line1: 5.652,
    line2: 6.354,
    line3: 7.155,
    line4: 8.075,
    line5: 9.131,
    line6: 10.347,
    line7: 11.751,
  },
  {
    age: 258,
    line1: 5.659,
    line2: 6.361,
    line3: 7.163,
    line4: 8.084,
    line5: 9.141,
    line6: 10.359,
    line7: 11.765,
  },
  {
    age: 259,
    line1: 5.665,
    line2: 6.368,
    line3: 7.172,
    line4: 8.093,
    line5: 9.152,
    line6: 10.371,
    line7: 11.779,
  },
  {
    age: 260,
    line1: 5.672,
    line2: 6.375,
    line3: 7.18,
    line4: 8.102,
    line5: 9.162,
    line6: 10.383,
    line7: 11.793,
  },
  {
    age: 261,
    line1: 5.678,
    line2: 6.382,
    line3: 7.188,
    line4: 8.111,
    line5: 9.172,
    line6: 10.395,
    line7: 11.807,
  },
  {
    age: 262,
    line1: 5.685,
    line2: 6.389,
    line3: 7.196,
    line4: 8.12,
    line5: 9.182,
    line6: 10.407,
    line7: 11.821,
  },
  {
    age: 263,
    line1: 5.691,
    line2: 6.397,
    line3: 7.203,
    line4: 8.129,
    line5: 9.193,
    line6: 10.419,
    line7: 11.835,
  },
  {
    age: 264,
    line1: 5.698,
    line2: 6.404,
    line3: 7.211,
    line4: 8.138,
    line5: 9.203,
    line6: 10.431,
    line7: 11.849,
  },
  {
    age: 265,
    line1: 5.704,
    line2: 6.411,
    line3: 7.219,
    line4: 8.147,
    line5: 9.213,
    line6: 10.442,
    line7: 11.863,
  },
  {
    age: 266,
    line1: 5.711,
    line2: 6.418,
    line3: 7.227,
    line4: 8.156,
    line5: 9.223,
    line6: 10.454,
    line7: 11.877,
  },
  {
    age: 267,
    line1: 5.717,
    line2: 6.425,
    line3: 7.235,
    line4: 8.164,
    line5: 9.233,
    line6: 10.466,
    line7: 11.891,
  },
  {
    age: 268,
    line1: 5.723,
    line2: 6.432,
    line3: 7.243,
    line4: 8.173,
    line5: 9.244,
    line6: 10.478,
    line7: 11.904,
  },
  {
    age: 269,
    line1: 5.73,
    line2: 6.439,
    line3: 7.251,
    line4: 8.182,
    line5: 9.254,
    line6: 10.489,
    line7: 11.918,
  },
  {
    age: 270,
    line1: 5.736,
    line2: 6.446,
    line3: 7.258,
    line4: 8.191,
    line5: 9.264,
    line6: 10.501,
    line7: 11.932,
  },
  {
    age: 271,
    line1: 5.742,
    line2: 6.453,
    line3: 7.266,
    line4: 8.2,
    line5: 9.274,
    line6: 10.513,
    line7: 11.945,
  },
  {
    age: 272,
    line1: 5.748,
    line2: 6.46,
    line3: 7.274,
    line4: 8.208,
    line5: 9.284,
    line6: 10.524,
    line7: 11.959,
  },
  {
    age: 273,
    line1: 5.755,
    line2: 6.466,
    line3: 7.282,
    line4: 8.217,
    line5: 9.294,
    line6: 10.536,
    line7: 11.972,
  },
  {
    age: 274,
    line1: 5.761,
    line2: 6.473,
    line3: 7.289,
    line4: 8.226,
    line5: 9.304,
    line6: 10.547,
    line7: 11.986,
  },
  {
    age: 275,
    line1: 5.767,
    line2: 6.48,
    line3: 7.297,
    line4: 8.235,
    line5: 9.314,
    line6: 10.559,
    line7: 11.999,
  },
  {
    age: 276,
    line1: 5.773,
    line2: 6.487,
    line3: 7.305,
    line4: 8.243,
    line5: 9.323,
    line6: 10.57,
    line7: 12.013,
  },
  {
    age: 277,
    line1: 5.779,
    line2: 6.494,
    line3: 7.312,
    line4: 8.252,
    line5: 9.333,
    line6: 10.582,
    line7: 12.026,
  },
  {
    age: 278,
    line1: 5.785,
    line2: 6.501,
    line3: 7.32,
    line4: 8.26,
    line5: 9.343,
    line6: 10.593,
    line7: 12.04,
  },
  {
    age: 279,
    line1: 5.792,
    line2: 6.507,
    line3: 7.327,
    line4: 8.269,
    line5: 9.353,
    line6: 10.604,
    line7: 12.053,
  },
  {
    age: 280,
    line1: 5.798,
    line2: 6.514,
    line3: 7.335,
    line4: 8.278,
    line5: 9.363,
    line6: 10.616,
    line7: 12.066,
  },
  {
    age: 281,
    line1: 5.804,
    line2: 6.521,
    line3: 7.343,
    line4: 8.286,
    line5: 9.373,
    line6: 10.627,
    line7: 12.08,
  },
  {
    age: 282,
    line1: 5.81,
    line2: 6.528,
    line3: 7.35,
    line4: 8.295,
    line5: 9.382,
    line6: 10.638,
    line7: 12.093,
  },
  {
    age: 283,
    line1: 5.816,
    line2: 6.534,
    line3: 7.358,
    line4: 8.303,
    line5: 9.392,
    line6: 10.65,
    line7: 12.106,
  },
  {
    age: 284,
    line1: 5.822,
    line2: 6.541,
    line3: 7.365,
    line4: 8.312,
    line5: 9.402,
    line6: 10.661,
    line7: 12.119,
  },
  {
    age: 285,
    line1: 5.828,
    line2: 6.548,
    line3: 7.373,
    line4: 8.32,
    line5: 9.412,
    line6: 10.672,
    line7: 12.133,
  },
  {
    age: 286,
    line1: 5.834,
    line2: 6.554,
    line3: 7.38,
    line4: 8.328,
    line5: 9.421,
    line6: 10.683,
    line7: 12.146,
  },
  {
    age: 287,
    line1: 5.84,
    line2: 6.561,
    line3: 7.387,
    line4: 8.337,
    line5: 9.431,
    line6: 10.695,
    line7: 12.159,
  },
  {
    age: 288,
    line1: 5.846,
    line2: 6.568,
    line3: 7.395,
    line4: 8.345,
    line5: 9.44,
    line6: 10.706,
    line7: 12.172,
  },
  {
    age: 289,
    line1: 5.852,
    line2: 6.574,
    line3: 7.402,
    line4: 8.354,
    line5: 9.45,
    line6: 10.717,
    line7: 12.185,
  },
  {
    age: 290,
    line1: 5.858,
    line2: 6.581,
    line3: 7.41,
    line4: 8.362,
    line5: 9.46,
    line6: 10.728,
    line7: 12.198,
  },
  {
    age: 291,
    line1: 5.864,
    line2: 6.587,
    line3: 7.417,
    line4: 8.37,
    line5: 9.469,
    line6: 10.739,
    line7: 12.211,
  },
  {
    age: 292,
    line1: 5.87,
    line2: 6.594,
    line3: 7.424,
    line4: 8.379,
    line5: 9.479,
    line6: 10.75,
    line7: 12.224,
  },
  {
    age: 293,
    line1: 5.876,
    line2: 6.601,
    line3: 7.432,
    line4: 8.387,
    line5: 9.488,
    line6: 10.761,
    line7: 12.237,
  },
  {
    age: 294,
    line1: 5.881,
    line2: 6.607,
    line3: 7.439,
    line4: 8.395,
    line5: 9.498,
    line6: 10.772,
    line7: 12.25,
  },
  {
    age: 295,
    line1: 5.887,
    line2: 6.614,
    line3: 7.446,
    line4: 8.404,
    line5: 9.507,
    line6: 10.783,
    line7: 12.263,
  },
  {
    age: 296,
    line1: 5.893,
    line2: 6.62,
    line3: 7.453,
    line4: 8.412,
    line5: 9.517,
    line6: 10.794,
    line7: 12.276,
  },
  {
    age: 297,
    line1: 5.899,
    line2: 6.626,
    line3: 7.461,
    line4: 8.42,
    line5: 9.526,
    line6: 10.805,
    line7: 12.289,
  },
  {
    age: 298,
    line1: 5.905,
    line2: 6.633,
    line3: 7.468,
    line4: 8.428,
    line5: 9.535,
    line6: 10.816,
    line7: 12.302,
  },
  {
    age: 299,
    line1: 5.911,
    line2: 6.639,
    line3: 7.475,
    line4: 8.436,
    line5: 9.545,
    line6: 10.827,
    line7: 12.314,
  },
  {
    age: 300,
    line1: 5.917,
    line2: 6.646,
    line3: 7.482,
    line4: 8.444,
    line5: 9.554,
    line6: 10.838,
    line7: 12.327,
  },
  {
    age: 301,
    line1: 5.922,
    line2: 6.652,
    line3: 7.49,
    line4: 8.453,
    line5: 9.563,
    line6: 10.849,
    line7: 12.34,
  },
  {
    age: 302,
    line1: 5.928,
    line2: 6.659,
    line3: 7.497,
    line4: 8.461,
    line5: 9.573,
    line6: 10.859,
    line7: 12.353,
  },
  {
    age: 303,
    line1: 5.934,
    line2: 6.665,
    line3: 7.504,
    line4: 8.469,
    line5: 9.582,
    line6: 10.87,
    line7: 12.366,
  },
  {
    age: 304,
    line1: 5.94,
    line2: 6.671,
    line3: 7.511,
    line4: 8.477,
    line5: 9.591,
    line6: 10.881,
    line7: 12.378,
  },
  {
    age: 305,
    line1: 5.945,
    line2: 6.678,
    line3: 7.518,
    line4: 8.485,
    line5: 9.601,
    line6: 10.892,
    line7: 12.391,
  },
  {
    age: 306,
    line1: 5.951,
    line2: 6.684,
    line3: 7.525,
    line4: 8.493,
    line5: 9.61,
    line6: 10.902,
    line7: 12.403,
  },
  {
    age: 307,
    line1: 5.957,
    line2: 6.69,
    line3: 7.532,
    line4: 8.501,
    line5: 9.619,
    line6: 10.913,
    line7: 12.416,
  },
  {
    age: 308,
    line1: 5.962,
    line2: 6.697,
    line3: 7.54,
    line4: 8.509,
    line5: 9.628,
    line6: 10.924,
    line7: 12.429,
  },
  {
    age: 309,
    line1: 5.968,
    line2: 6.703,
    line3: 7.547,
    line4: 8.517,
    line5: 9.638,
    line6: 10.935,
    line7: 12.442,
  },
  {
    age: 310,
    line1: 5.974,
    line2: 6.71,
    line3: 7.554,
    line4: 8.525,
    line5: 9.647,
    line6: 10.945,
    line7: 12.454,
  },
  {
    age: 311,
    line1: 5.98,
    line2: 6.716,
    line3: 7.561,
    line4: 8.533,
    line5: 9.656,
    line6: 10.956,
    line7: 12.466,
  },
  {
    age: 312,
    line1: 5.985,
    line2: 6.722,
    line3: 7.568,
    line4: 8.541,
    line5: 9.665,
    line6: 10.966,
    line7: 12.479,
  },
  {
    age: 313,
    line1: 5.991,
    line2: 6.728,
    line3: 7.575,
    line4: 8.549,
    line5: 9.674,
    line6: 10.977,
    line7: 12.491,
  },
  {
    age: 314,
    line1: 5.996,
    line2: 6.734,
    line3: 7.582,
    line4: 8.557,
    line5: 9.683,
    line6: 10.988,
    line7: 12.504,
  },
  {
    age: 315,
    line1: 6.002,
    line2: 6.741,
    line3: 7.589,
    line4: 8.565,
    line5: 9.692,
    line6: 10.998,
    line7: 12.516,
  },
  {
    age: 316,
    line1: 6.008,
    line2: 6.747,
    line3: 7.596,
    line4: 8.573,
    line5: 9.701,
    line6: 11.009,
    line7: 12.529,
  },
  {
    age: 317,
    line1: 6.013,
    line2: 6.753,
    line3: 7.603,
    line4: 8.581,
    line5: 9.71,
    line6: 11.019,
    line7: 12.541,
  },
  {
    age: 318,
    line1: 6.019,
    line2: 6.759,
    line3: 7.61,
    line4: 8.589,
    line5: 9.72,
    line6: 11.03,
    line7: 12.554,
  },
  {
    age: 319,
    line1: 6.024,
    line2: 6.766,
    line3: 7.617,
    line4: 8.596,
    line5: 9.729,
    line6: 11.04,
    line7: 12.566,
  },
  {
    age: 320,
    line1: 6.03,
    line2: 6.772,
    line3: 7.624,
    line4: 8.604,
    line5: 9.737,
    line6: 11.051,
    line7: 12.578,
  },
  {
    age: 321,
    line1: 6.036,
    line2: 6.778,
    line3: 7.63,
    line4: 8.612,
    line5: 9.746,
    line6: 11.061,
    line7: 12.59,
  },
  {
    age: 322,
    line1: 6.041,
    line2: 6.784,
    line3: 7.637,
    line4: 8.62,
    line5: 9.756,
    line6: 11.072,
    line7: 12.603,
  },
  {
    age: 323,
    line1: 6.047,
    line2: 6.79,
    line3: 7.644,
    line4: 8.628,
    line5: 9.765,
    line6: 11.082,
    line7: 12.615,
  },
  {
    age: 324,
    line1: 6.052,
    line2: 6.796,
    line3: 7.651,
    line4: 8.636,
    line5: 9.773,
    line6: 11.093,
    line7: 12.627,
  },
  {
    age: 325,
    line1: 6.058,
    line2: 6.803,
    line3: 7.658,
    line4: 8.644,
    line5: 9.782,
    line6: 11.103,
    line7: 12.639,
  },
  {
    age: 326,
    line1: 6.063,
    line2: 6.809,
    line3: 7.665,
    line4: 8.651,
    line5: 9.791,
    line6: 11.113,
    line7: 12.651,
  },
  {
    age: 327,
    line1: 6.069,
    line2: 6.815,
    line3: 7.672,
    line4: 8.659,
    line5: 9.8,
    line6: 11.124,
    line7: 12.664,
  },
  {
    age: 328,
    line1: 6.074,
    line2: 6.821,
    line3: 7.678,
    line4: 8.667,
    line5: 9.809,
    line6: 11.134,
    line7: 12.676,
  },
  {
    age: 329,
    line1: 6.08,
    line2: 6.827,
    line3: 7.685,
    line4: 8.674,
    line5: 9.818,
    line6: 11.144,
    line7: 12.688,
  },
  {
    age: 330,
    line1: 6.085,
    line2: 6.833,
    line3: 7.692,
    line4: 8.682,
    line5: 9.827,
    line6: 11.154,
    line7: 12.7,
  },
  {
    age: 331,
    line1: 6.091,
    line2: 6.839,
    line3: 7.699,
    line4: 8.69,
    line5: 9.836,
    line6: 11.165,
    line7: 12.712,
  },
  {
    age: 332,
    line1: 6.096,
    line2: 6.845,
    line3: 7.706,
    line4: 8.698,
    line5: 9.844,
    line6: 11.175,
    line7: 12.724,
  },
  {
    age: 333,
    line1: 6.102,
    line2: 6.851,
    line3: 7.713,
    line4: 8.705,
    line5: 9.853,
    line6: 11.185,
    line7: 12.737,
  },
  {
    age: 334,
    line1: 6.107,
    line2: 6.857,
    line3: 7.719,
    line4: 8.713,
    line5: 9.862,
    line6: 11.196,
    line7: 12.748,
  },
  {
    age: 335,
    line1: 6.113,
    line2: 6.863,
    line3: 7.726,
    line4: 8.721,
    line5: 9.871,
    line6: 11.206,
    line7: 12.761,
  },
  {
    age: 336,
    line1: 6.118,
    line2: 6.869,
    line3: 7.733,
    line4: 8.728,
    line5: 9.88,
    line6: 11.216,
    line7: 12.773,
  },
  {
    age: 337,
    line1: 6.124,
    line2: 6.875,
    line3: 7.74,
    line4: 8.736,
    line5: 9.889,
    line6: 11.226,
    line7: 12.785,
  },
  {
    age: 338,
    line1: 6.129,
    line2: 6.882,
    line3: 7.746,
    line4: 8.744,
    line5: 9.897,
    line6: 11.236,
    line7: 12.796,
  },
  {
    age: 339,
    line1: 6.134,
    line2: 6.887,
    line3: 7.753,
    line4: 8.751,
    line5: 9.906,
    line6: 11.247,
    line7: 12.808,
  },
  {
    age: 340,
    line1: 6.14,
    line2: 6.893,
    line3: 7.76,
    line4: 8.759,
    line5: 9.915,
    line6: 11.257,
    line7: 12.82,
  },
  {
    age: 341,
    line1: 6.145,
    line2: 6.899,
    line3: 7.766,
    line4: 8.766,
    line5: 9.923,
    line6: 11.267,
    line7: 12.833,
  },
  {
    age: 342,
    line1: 6.151,
    line2: 6.905,
    line3: 7.773,
    line4: 8.774,
    line5: 9.932,
    line6: 11.277,
    line7: 12.844,
  },
  {
    age: 343,
    line1: 6.156,
    line2: 6.911,
    line3: 7.78,
    line4: 8.782,
    line5: 9.941,
    line6: 11.287,
    line7: 12.856,
  },
  {
    age: 344,
    line1: 6.161,
    line2: 6.917,
    line3: 7.787,
    line4: 8.789,
    line5: 9.95,
    line6: 11.297,
    line7: 12.868,
  },
  {
    age: 345,
    line1: 6.167,
    line2: 6.923,
    line3: 7.793,
    line4: 8.797,
    line5: 9.958,
    line6: 11.307,
    line7: 12.88,
  },
  {
    age: 346,
    line1: 6.172,
    line2: 6.929,
    line3: 7.8,
    line4: 8.804,
    line5: 9.967,
    line6: 11.317,
    line7: 12.892,
  },
  {
    age: 347,
    line1: 6.177,
    line2: 6.935,
    line3: 7.807,
    line4: 8.812,
    line5: 9.976,
    line6: 11.327,
    line7: 12.904,
  },
  {
    age: 348,
    line1: 6.183,
    line2: 6.941,
    line3: 7.813,
    line4: 8.819,
    line5: 9.984,
    line6: 11.337,
    line7: 12.915,
  },
  {
    age: 349,
    line1: 6.188,
    line2: 6.947,
    line3: 7.82,
    line4: 8.827,
    line5: 9.993,
    line6: 11.347,
    line7: 12.927,
  },
  {
    age: 350,
    line1: 6.193,
    line2: 6.953,
    line3: 7.826,
    line4: 8.834,
    line5: 10.001,
    line6: 11.357,
    line7: 12.939,
  },
  {
    age: 351,
    line1: 6.199,
    line2: 6.959,
    line3: 7.833,
    line4: 8.842,
    line5: 10.01,
    line6: 11.368,
    line7: 12.951,
  },
  {
    age: 352,
    line1: 6.204,
    line2: 6.965,
    line3: 7.84,
    line4: 8.85,
    line5: 10.019,
    line6: 11.377,
    line7: 12.962,
  },
  {
    age: 353,
    line1: 6.209,
    line2: 6.971,
    line3: 7.846,
    line4: 8.857,
    line5: 10.027,
    line6: 11.387,
    line7: 12.974,
  },
  {
    age: 354,
    line1: 6.215,
    line2: 6.977,
    line3: 7.853,
    line4: 8.864,
    line5: 10.036,
    line6: 11.397,
    line7: 12.986,
  },
  {
    age: 355,
    line1: 6.22,
    line2: 6.983,
    line3: 7.86,
    line4: 8.872,
    line5: 10.044,
    line6: 11.407,
    line7: 12.997,
  },
  {
    age: 356,
    line1: 6.225,
    line2: 6.988,
    line3: 7.866,
    line4: 8.879,
    line5: 10.053,
    line6: 11.417,
    line7: 13.009,
  },
  {
    age: 357,
    line1: 6.231,
    line2: 6.994,
    line3: 7.873,
    line4: 8.887,
    line5: 10.061,
    line6: 11.427,
    line7: 13.021,
  },
  {
    age: 358,
    line1: 6.236,
    line2: 7,
    line3: 7.879,
    line4: 8.894,
    line5: 10.07,
    line6: 11.437,
    line7: 13.033,
  },
  {
    age: 359,
    line1: 6.241,
    line2: 7.006,
    line3: 7.886,
    line4: 8.902,
    line5: 10.079,
    line6: 11.447,
    line7: 13.044,
  },
  {
    age: 360,
    line1: 6.247,
    line2: 7.012,
    line3: 7.893,
    line4: 8.909,
    line5: 10.087,
    line6: 11.457,
    line7: 13.056,
  },
  {
    age: 361,
    line1: 6.252,
    line2: 7.018,
    line3: 7.899,
    line4: 8.917,
    line5: 10.096,
    line6: 11.467,
    line7: 13.068,
  },
  {
    age: 362,
    line1: 6.257,
    line2: 7.024,
    line3: 7.906,
    line4: 8.924,
    line5: 10.104,
    line6: 11.477,
    line7: 13.079,
  },
  {
    age: 363,
    line1: 6.263,
    line2: 7.03,
    line3: 7.912,
    line4: 8.931,
    line5: 10.113,
    line6: 11.486,
    line7: 13.091,
  },
  {
    age: 364,
    line1: 6.268,
    line2: 7.035,
    line3: 7.919,
    line4: 8.939,
    line5: 10.121,
    line6: 11.496,
    line7: 13.102,
  },
  {
    age: 365,
    line1: 6.273,
    line2: 7.041,
    line3: 7.925,
    line4: 8.946,
    line5: 10.129,
    line6: 11.506,
    line7: 13.114,
  },
  {
    age: 366,
    line1: 6.278,
    line2: 7.047,
    line3: 7.932,
    line4: 8.954,
    line5: 10.138,
    line6: 11.516,
    line7: 13.125,
  },
  {
    age: 367,
    line1: 6.283,
    line2: 7.053,
    line3: 7.938,
    line4: 8.961,
    line5: 10.146,
    line6: 11.526,
    line7: 13.137,
  },
  {
    age: 368,
    line1: 6.289,
    line2: 7.059,
    line3: 7.945,
    line4: 8.968,
    line5: 10.155,
    line6: 11.535,
    line7: 13.148,
  },
  {
    age: 369,
    line1: 6.294,
    line2: 7.064,
    line3: 7.951,
    line4: 8.976,
    line5: 10.163,
    line6: 11.545,
    line7: 13.16,
  },
  {
    age: 370,
    line1: 6.299,
    line2: 7.07,
    line3: 7.958,
    line4: 8.983,
    line5: 10.172,
    line6: 11.555,
    line7: 13.171,
  },
  {
    age: 371,
    line1: 6.304,
    line2: 7.076,
    line3: 7.964,
    line4: 8.99,
    line5: 10.18,
    line6: 11.565,
    line7: 13.183,
  },
  {
    age: 372,
    line1: 6.31,
    line2: 7.082,
    line3: 7.971,
    line4: 8.998,
    line5: 10.189,
    line6: 11.575,
    line7: 13.195,
  },
  {
    age: 373,
    line1: 6.315,
    line2: 7.088,
    line3: 7.977,
    line4: 9.005,
    line5: 10.197,
    line6: 11.584,
    line7: 13.206,
  },
  {
    age: 374,
    line1: 6.32,
    line2: 7.093,
    line3: 7.984,
    line4: 9.012,
    line5: 10.205,
    line6: 11.594,
    line7: 13.218,
  },
  {
    age: 375,
    line1: 6.325,
    line2: 7.099,
    line3: 7.99,
    line4: 9.02,
    line5: 10.214,
    line6: 11.604,
    line7: 13.229,
  },
  {
    age: 376,
    line1: 6.331,
    line2: 7.105,
    line3: 7.997,
    line4: 9.027,
    line5: 10.222,
    line6: 11.614,
    line7: 13.24,
  },
  {
    age: 377,
    line1: 6.336,
    line2: 7.111,
    line3: 8.003,
    line4: 9.034,
    line5: 10.23,
    line6: 11.623,
    line7: 13.251,
  },
  {
    age: 378,
    line1: 6.341,
    line2: 7.116,
    line3: 8.01,
    line4: 9.042,
    line5: 10.239,
    line6: 11.633,
    line7: 13.263,
  },
  {
    age: 379,
    line1: 6.346,
    line2: 7.122,
    line3: 8.016,
    line4: 9.049,
    line5: 10.247,
    line6: 11.643,
    line7: 13.274,
  },
  {
    age: 380,
    line1: 6.351,
    line2: 7.128,
    line3: 8.022,
    line4: 9.056,
    line5: 10.256,
    line6: 11.652,
    line7: 13.286,
  },
  {
    age: 381,
    line1: 6.357,
    line2: 7.134,
    line3: 8.029,
    line4: 9.064,
    line5: 10.264,
    line6: 11.662,
    line7: 13.297,
  },
  {
    age: 382,
    line1: 6.362,
    line2: 7.14,
    line3: 8.035,
    line4: 9.071,
    line5: 10.272,
    line6: 11.672,
    line7: 13.309,
  },
  {
    age: 383,
    line1: 6.367,
    line2: 7.145,
    line3: 8.042,
    line4: 9.078,
    line5: 10.281,
    line6: 11.681,
    line7: 13.32,
  },
  {
    age: 384,
    line1: 6.372,
    line2: 7.151,
    line3: 8.048,
    line4: 9.085,
    line5: 10.289,
    line6: 11.691,
    line7: 13.331,
  },
  {
    age: 385,
    line1: 6.377,
    line2: 7.157,
    line3: 8.055,
    line4: 9.093,
    line5: 10.297,
    line6: 11.701,
    line7: 13.342,
  },
  {
    age: 386,
    line1: 6.382,
    line2: 7.162,
    line3: 8.061,
    line4: 9.1,
    line5: 10.305,
    line6: 11.71,
    line7: 13.354,
  },
  {
    age: 387,
    line1: 6.388,
    line2: 7.168,
    line3: 8.068,
    line4: 9.107,
    line5: 10.314,
    line6: 11.72,
    line7: 13.365,
  },
  {
    age: 388,
    line1: 6.393,
    line2: 7.174,
    line3: 8.074,
    line4: 9.114,
    line5: 10.322,
    line6: 11.729,
    line7: 13.376,
  },
  {
    age: 389,
    line1: 6.398,
    line2: 7.18,
    line3: 8.08,
    line4: 9.122,
    line5: 10.33,
    line6: 11.739,
    line7: 13.387,
  },
  {
    age: 390,
    line1: 6.403,
    line2: 7.185,
    line3: 8.087,
    line4: 9.129,
    line5: 10.339,
    line6: 11.749,
    line7: 13.399,
  },
  {
    age: 391,
    line1: 6.408,
    line2: 7.191,
    line3: 8.093,
    line4: 9.136,
    line5: 10.347,
    line6: 11.758,
    line7: 13.41,
  },
  {
    age: 392,
    line1: 6.413,
    line2: 7.197,
    line3: 8.099,
    line4: 9.143,
    line5: 10.355,
    line6: 11.768,
    line7: 13.421,
  },
  {
    age: 393,
    line1: 6.419,
    line2: 7.203,
    line3: 8.106,
    line4: 9.151,
    line5: 10.363,
    line6: 11.777,
    line7: 13.432,
  },
  {
    age: 394,
    line1: 6.424,
    line2: 7.208,
    line3: 8.112,
    line4: 9.158,
    line5: 10.372,
    line6: 11.787,
    line7: 13.443,
  },
  {
    age: 395,
    line1: 6.429,
    line2: 7.214,
    line3: 8.119,
    line4: 9.165,
    line5: 10.38,
    line6: 11.796,
    line7: 13.455,
  },
  {
    age: 396,
    line1: 6.434,
    line2: 7.22,
    line3: 8.125,
    line4: 9.172,
    line5: 10.388,
    line6: 11.806,
    line7: 13.466,
  },
  {
    age: 397,
    line1: 6.439,
    line2: 7.225,
    line3: 8.131,
    line4: 9.179,
    line5: 10.396,
    line6: 11.816,
    line7: 13.477,
  },
  {
    age: 398,
    line1: 6.444,
    line2: 7.231,
    line3: 8.138,
    line4: 9.187,
    line5: 10.405,
    line6: 11.825,
    line7: 13.488,
  },
  {
    age: 399,
    line1: 6.449,
    line2: 7.237,
    line3: 8.144,
    line4: 9.194,
    line5: 10.413,
    line6: 11.835,
    line7: 13.5,
  },
  {
    age: 400,
    line1: 6.455,
    line2: 7.242,
    line3: 8.15,
    line4: 9.201,
    line5: 10.421,
    line6: 11.844,
    line7: 13.511,
  },
  {
    age: 401,
    line1: 6.46,
    line2: 7.248,
    line3: 8.157,
    line4: 9.208,
    line5: 10.429,
    line6: 11.853,
    line7: 13.522,
  },
  {
    age: 402,
    line1: 6.465,
    line2: 7.254,
    line3: 8.163,
    line4: 9.215,
    line5: 10.437,
    line6: 11.863,
    line7: 13.533,
  },
  {
    age: 403,
    line1: 6.47,
    line2: 7.259,
    line3: 8.17,
    line4: 9.222,
    line5: 10.446,
    line6: 11.872,
    line7: 13.544,
  },
  {
    age: 404,
    line1: 6.475,
    line2: 7.265,
    line3: 8.176,
    line4: 9.23,
    line5: 10.454,
    line6: 11.882,
    line7: 13.555,
  },
  {
    age: 405,
    line1: 6.48,
    line2: 7.271,
    line3: 8.182,
    line4: 9.237,
    line5: 10.462,
    line6: 11.891,
    line7: 13.566,
  },
  {
    age: 406,
    line1: 6.485,
    line2: 7.276,
    line3: 8.188,
    line4: 9.244,
    line5: 10.47,
    line6: 11.901,
    line7: 13.577,
  },
  {
    age: 407,
    line1: 6.49,
    line2: 7.282,
    line3: 8.195,
    line4: 9.251,
    line5: 10.478,
    line6: 11.91,
    line7: 13.588,
  },
  {
    age: 408,
    line1: 6.495,
    line2: 7.288,
    line3: 8.201,
    line4: 9.258,
    line5: 10.486,
    line6: 11.92,
    line7: 13.599,
  },
  {
    age: 409,
    line1: 6.501,
    line2: 7.293,
    line3: 8.207,
    line4: 9.265,
    line5: 10.495,
    line6: 11.929,
    line7: 13.61,
  },
  {
    age: 410,
    line1: 6.506,
    line2: 7.299,
    line3: 8.214,
    line4: 9.272,
    line5: 10.503,
    line6: 11.939,
    line7: 13.622,
  },
  {
    age: 411,
    line1: 6.511,
    line2: 7.305,
    line3: 8.22,
    line4: 9.28,
    line5: 10.511,
    line6: 11.948,
    line7: 13.632,
  },
  {
    age: 412,
    line1: 6.516,
    line2: 7.31,
    line3: 8.226,
    line4: 9.287,
    line5: 10.519,
    line6: 11.957,
    line7: 13.643,
  },
  {
    age: 413,
    line1: 6.521,
    line2: 7.316,
    line3: 8.233,
    line4: 9.294,
    line5: 10.527,
    line6: 11.967,
    line7: 13.655,
  },
  {
    age: 414,
    line1: 6.526,
    line2: 7.322,
    line3: 8.239,
    line4: 9.301,
    line5: 10.535,
    line6: 11.976,
    line7: 13.666,
  },
  {
    age: 415,
    line1: 6.531,
    line2: 7.327,
    line3: 8.245,
    line4: 9.308,
    line5: 10.543,
    line6: 11.986,
    line7: 13.676,
  },
  {
    age: 416,
    line1: 6.536,
    line2: 7.333,
    line3: 8.252,
    line4: 9.315,
    line5: 10.552,
    line6: 11.995,
    line7: 13.688,
  },
  {
    age: 417,
    line1: 6.541,
    line2: 7.338,
    line3: 8.258,
    line4: 9.322,
    line5: 10.56,
    line6: 12.004,
    line7: 13.698,
  },
  {
    age: 418,
    line1: 6.546,
    line2: 7.344,
    line3: 8.264,
    line4: 9.329,
    line5: 10.568,
    line6: 12.014,
    line7: 13.709,
  },
  {
    age: 419,
    line1: 6.551,
    line2: 7.35,
    line3: 8.27,
    line4: 9.336,
    line5: 10.576,
    line6: 12.023,
    line7: 13.721,
  },
  {
    age: 420,
    line1: 6.557,
    line2: 7.355,
    line3: 8.277,
    line4: 9.344,
    line5: 10.584,
    line6: 12.032,
    line7: 13.731,
  },
  {
    age: 421,
    line1: 6.562,
    line2: 7.361,
    line3: 8.283,
    line4: 9.351,
    line5: 10.592,
    line6: 12.042,
    line7: 13.742,
  },
  {
    age: 422,
    line1: 6.567,
    line2: 7.367,
    line3: 8.289,
    line4: 9.358,
    line5: 10.6,
    line6: 12.051,
    line7: 13.753,
  },
  {
    age: 423,
    line1: 6.572,
    line2: 7.372,
    line3: 8.296,
    line4: 9.365,
    line5: 10.608,
    line6: 12.061,
    line7: 13.764,
  },
  {
    age: 424,
    line1: 6.577,
    line2: 7.378,
    line3: 8.302,
    line4: 9.372,
    line5: 10.616,
    line6: 12.07,
    line7: 13.775,
  },
  {
    age: 425,
    line1: 6.582,
    line2: 7.383,
    line3: 8.308,
    line4: 9.379,
    line5: 10.624,
    line6: 12.079,
    line7: 13.786,
  },
  {
    age: 426,
    line1: 6.587,
    line2: 7.389,
    line3: 8.314,
    line4: 9.386,
    line5: 10.633,
    line6: 12.089,
    line7: 13.797,
  },
  {
    age: 427,
    line1: 6.592,
    line2: 7.395,
    line3: 8.321,
    line4: 9.393,
    line5: 10.641,
    line6: 12.098,
    line7: 13.808,
  },
  {
    age: 428,
    line1: 6.597,
    line2: 7.4,
    line3: 8.327,
    line4: 9.4,
    line5: 10.649,
    line6: 12.107,
    line7: 13.819,
  },
  {
    age: 429,
    line1: 6.602,
    line2: 7.406,
    line3: 8.333,
    line4: 9.407,
    line5: 10.657,
    line6: 12.116,
    line7: 13.829,
  },
  {
    age: 430,
    line1: 6.607,
    line2: 7.411,
    line3: 8.339,
    line4: 9.414,
    line5: 10.665,
    line6: 12.126,
    line7: 13.841,
  },
  {
    age: 431,
    line1: 6.612,
    line2: 7.417,
    line3: 8.346,
    line4: 9.421,
    line5: 10.673,
    line6: 12.135,
    line7: 13.852,
  },
  {
    age: 432,
    line1: 6.617,
    line2: 7.423,
    line3: 8.352,
    line4: 9.428,
    line5: 10.681,
    line6: 12.144,
    line7: 13.862,
  },
  {
    age: 433,
    line1: 6.622,
    line2: 7.428,
    line3: 8.358,
    line4: 9.436,
    line5: 10.689,
    line6: 12.153,
    line7: 13.873,
  },
  {
    age: 434,
    line1: 6.628,
    line2: 7.434,
    line3: 8.364,
    line4: 9.443,
    line5: 10.697,
    line6: 12.163,
    line7: 13.884,
  },
  {
    age: 435,
    line1: 6.632,
    line2: 7.439,
    line3: 8.371,
    line4: 9.45,
    line5: 10.705,
    line6: 12.172,
    line7: 13.895,
  },
  {
    age: 436,
    line1: 6.638,
    line2: 7.445,
    line3: 8.377,
    line4: 9.457,
    line5: 10.713,
    line6: 12.181,
    line7: 13.906,
  },
  {
    age: 437,
    line1: 6.643,
    line2: 7.451,
    line3: 8.383,
    line4: 9.464,
    line5: 10.721,
    line6: 12.191,
    line7: 13.916,
  },
  {
    age: 438,
    line1: 6.648,
    line2: 7.456,
    line3: 8.389,
    line4: 9.471,
    line5: 10.729,
    line6: 12.2,
    line7: 13.927,
  },
  {
    age: 439,
    line1: 6.653,
    line2: 7.462,
    line3: 8.396,
    line4: 9.478,
    line5: 10.737,
    line6: 12.209,
    line7: 13.938,
  },
  {
    age: 440,
    line1: 6.658,
    line2: 7.467,
    line3: 8.402,
    line4: 9.485,
    line5: 10.745,
    line6: 12.219,
    line7: 13.949,
  },
  {
    age: 441,
    line1: 6.663,
    line2: 7.473,
    line3: 8.408,
    line4: 9.492,
    line5: 10.753,
    line6: 12.228,
    line7: 13.96,
  },
  {
    age: 442,
    line1: 6.668,
    line2: 7.478,
    line3: 8.414,
    line4: 9.499,
    line5: 10.761,
    line6: 12.237,
    line7: 13.97,
  },
  {
    age: 443,
    line1: 6.673,
    line2: 7.484,
    line3: 8.42,
    line4: 9.506,
    line5: 10.769,
    line6: 12.246,
    line7: 13.981,
  },
  {
    age: 444,
    line1: 6.678,
    line2: 7.49,
    line3: 8.427,
    line4: 9.513,
    line5: 10.777,
    line6: 12.255,
    line7: 13.992,
  },
  {
    age: 445,
    line1: 6.683,
    line2: 7.495,
    line3: 8.433,
    line4: 9.52,
    line5: 10.785,
    line6: 12.265,
    line7: 14.003,
  },
  {
    age: 446,
    line1: 6.688,
    line2: 7.501,
    line3: 8.439,
    line4: 9.527,
    line5: 10.793,
    line6: 12.274,
    line7: 14.014,
  },
  {
    age: 447,
    line1: 6.693,
    line2: 7.506,
    line3: 8.445,
    line4: 9.534,
    line5: 10.801,
    line6: 12.283,
    line7: 14.024,
  },
  {
    age: 448,
    line1: 6.698,
    line2: 7.512,
    line3: 8.452,
    line4: 9.541,
    line5: 10.809,
    line6: 12.292,
    line7: 14.035,
  },
  {
    age: 449,
    line1: 6.703,
    line2: 7.517,
    line3: 8.458,
    line4: 9.548,
    line5: 10.817,
    line6: 12.301,
    line7: 14.046,
  },
  {
    age: 450,
    line1: 6.708,
    line2: 7.523,
    line3: 8.464,
    line4: 9.555,
    line5: 10.825,
    line6: 12.31,
    line7: 14.056,
  },
  {
    age: 451,
    line1: 6.713,
    line2: 7.529,
    line3: 8.47,
    line4: 9.562,
    line5: 10.833,
    line6: 12.32,
    line7: 14.067,
  },
  {
    age: 452,
    line1: 6.718,
    line2: 7.534,
    line3: 8.476,
    line4: 9.569,
    line5: 10.841,
    line6: 12.329,
    line7: 14.078,
  },
  {
    age: 453,
    line1: 6.723,
    line2: 7.54,
    line3: 8.483,
    line4: 9.576,
    line5: 10.849,
    line6: 12.338,
    line7: 14.089,
  },
  {
    age: 454,
    line1: 6.728,
    line2: 7.545,
    line3: 8.489,
    line4: 9.583,
    line5: 10.857,
    line6: 12.347,
    line7: 14.099,
  },
  {
    age: 455,
    line1: 6.733,
    line2: 7.551,
    line3: 8.495,
    line4: 9.59,
    line5: 10.865,
    line6: 12.356,
    line7: 14.11,
  },
  {
    age: 456,
    line1: 6.738,
    line2: 7.556,
    line3: 8.501,
    line4: 9.597,
    line5: 10.873,
    line6: 12.366,
    line7: 14.121,
  },
  {
    age: 457,
    line1: 6.743,
    line2: 7.562,
    line3: 8.507,
    line4: 9.604,
    line5: 10.881,
    line6: 12.375,
    line7: 14.131,
  },
  {
    age: 458,
    line1: 6.748,
    line2: 7.567,
    line3: 8.514,
    line4: 9.611,
    line5: 10.889,
    line6: 12.384,
    line7: 14.142,
  },
  {
    age: 459,
    line1: 6.753,
    line2: 7.573,
    line3: 8.52,
    line4: 9.618,
    line5: 10.897,
    line6: 12.393,
    line7: 14.153,
  },
  {
    age: 460,
    line1: 6.758,
    line2: 7.578,
    line3: 8.526,
    line4: 9.625,
    line5: 10.905,
    line6: 12.402,
    line7: 14.163,
  },
  {
    age: 461,
    line1: 6.763,
    line2: 7.584,
    line3: 8.532,
    line4: 9.632,
    line5: 10.913,
    line6: 12.411,
    line7: 14.174,
  },
  {
    age: 462,
    line1: 6.768,
    line2: 7.59,
    line3: 8.538,
    line4: 9.639,
    line5: 10.92,
    line6: 12.421,
    line7: 14.185,
  },
  {
    age: 463,
    line1: 6.773,
    line2: 7.595,
    line3: 8.545,
    line4: 9.646,
    line5: 10.928,
    line6: 12.43,
    line7: 14.195,
  },
  {
    age: 464,
    line1: 6.778,
    line2: 7.601,
    line3: 8.551,
    line4: 9.653,
    line5: 10.936,
    line6: 12.439,
    line7: 14.206,
  },
  {
    age: 465,
    line1: 6.783,
    line2: 7.606,
    line3: 8.557,
    line4: 9.66,
    line5: 10.944,
    line6: 12.448,
    line7: 14.217,
  },
  {
    age: 466,
    line1: 6.788,
    line2: 7.612,
    line3: 8.563,
    line4: 9.666,
    line5: 10.952,
    line6: 12.457,
    line7: 14.228,
  },
  {
    age: 467,
    line1: 6.793,
    line2: 7.617,
    line3: 8.569,
    line4: 9.674,
    line5: 10.96,
    line6: 12.466,
    line7: 14.238,
  },
  {
    age: 468,
    line1: 6.798,
    line2: 7.623,
    line3: 8.575,
    line4: 9.68,
    line5: 10.968,
    line6: 12.475,
    line7: 14.249,
  },
  {
    age: 469,
    line1: 6.803,
    line2: 7.628,
    line3: 8.582,
    line4: 9.687,
    line5: 10.976,
    line6: 12.484,
    line7: 14.259,
  },
  {
    age: 470,
    line1: 6.808,
    line2: 7.634,
    line3: 8.588,
    line4: 9.694,
    line5: 10.984,
    line6: 12.494,
    line7: 14.27,
  },
  {
    age: 471,
    line1: 6.813,
    line2: 7.639,
    line3: 8.594,
    line4: 9.701,
    line5: 10.992,
    line6: 12.503,
    line7: 14.281,
  },
  {
    age: 472,
    line1: 6.818,
    line2: 7.645,
    line3: 8.6,
    line4: 9.708,
    line5: 11,
    line6: 12.512,
    line7: 14.291,
  },
  {
    age: 473,
    line1: 6.823,
    line2: 7.65,
    line3: 8.606,
    line4: 9.715,
    line5: 11.008,
    line6: 12.521,
    line7: 14.302,
  },
  {
    age: 474,
    line1: 6.828,
    line2: 7.656,
    line3: 8.612,
    line4: 9.722,
    line5: 11.016,
    line6: 12.53,
    line7: 14.312,
  },
  {
    age: 475,
    line1: 6.833,
    line2: 7.661,
    line3: 8.619,
    line4: 9.729,
    line5: 11.023,
    line6: 12.539,
    line7: 14.323,
  },
  {
    age: 476,
    line1: 6.838,
    line2: 7.667,
    line3: 8.625,
    line4: 9.736,
    line5: 11.031,
    line6: 12.548,
    line7: 14.334,
  },
  {
    age: 477,
    line1: 6.843,
    line2: 7.672,
    line3: 8.631,
    line4: 9.743,
    line5: 11.039,
    line6: 12.557,
    line7: 14.344,
  },
  {
    age: 478,
    line1: 6.848,
    line2: 7.678,
    line3: 8.637,
    line4: 9.75,
    line5: 11.047,
    line6: 12.567,
    line7: 14.355,
  },
  {
    age: 479,
    line1: 6.853,
    line2: 7.684,
    line3: 8.643,
    line4: 9.757,
    line5: 11.055,
    line6: 12.576,
    line7: 14.366,
  },
  {
    age: 480,
    line1: 6.858,
    line2: 7.689,
    line3: 8.649,
    line4: 9.764,
    line5: 11.063,
    line6: 12.585,
    line7: 14.376,
  },
  {
    age: 481,
    line1: 6.863,
    line2: 7.695,
    line3: 8.656,
    line4: 9.771,
    line5: 11.071,
    line6: 12.594,
    line7: 14.387,
  },
  {
    age: 482,
    line1: 6.868,
    line2: 7.7,
    line3: 8.662,
    line4: 9.778,
    line5: 11.079,
    line6: 12.603,
    line7: 14.397,
  },
  {
    age: 483,
    line1: 6.873,
    line2: 7.706,
    line3: 8.668,
    line4: 9.785,
    line5: 11.087,
    line6: 12.612,
    line7: 14.408,
  },
  {
    age: 484,
    line1: 6.878,
    line2: 7.711,
    line3: 8.674,
    line4: 9.792,
    line5: 11.095,
    line6: 12.621,
    line7: 14.418,
  },
  {
    age: 485,
    line1: 6.883,
    line2: 7.717,
    line3: 8.68,
    line4: 9.798,
    line5: 11.102,
    line6: 12.63,
    line7: 14.429,
  },
  {
    age: 486,
    line1: 6.888,
    line2: 7.722,
    line3: 8.686,
    line4: 9.805,
    line5: 11.11,
    line6: 12.639,
    line7: 14.44,
  },
  {
    age: 487,
    line1: 6.893,
    line2: 7.728,
    line3: 8.692,
    line4: 9.812,
    line5: 11.118,
    line6: 12.648,
    line7: 14.45,
  },
  {
    age: 488,
    line1: 6.898,
    line2: 7.733,
    line3: 8.699,
    line4: 9.819,
    line5: 11.126,
    line6: 12.657,
    line7: 14.461,
  },
  {
    age: 489,
    line1: 6.903,
    line2: 7.739,
    line3: 8.705,
    line4: 9.826,
    line5: 11.134,
    line6: 12.666,
    line7: 14.471,
  },
  {
    age: 490,
    line1: 6.908,
    line2: 7.744,
    line3: 8.711,
    line4: 9.833,
    line5: 11.142,
    line6: 12.675,
    line7: 14.482,
  },
  {
    age: 491,
    line1: 6.913,
    line2: 7.75,
    line3: 8.717,
    line4: 9.84,
    line5: 11.15,
    line6: 12.685,
    line7: 14.493,
  },
  {
    age: 492,
    line1: 6.918,
    line2: 7.755,
    line3: 8.723,
    line4: 9.847,
    line5: 11.158,
    line6: 12.694,
    line7: 14.503,
  },
  {
    age: 493,
    line1: 6.923,
    line2: 7.761,
    line3: 8.729,
    line4: 9.854,
    line5: 11.165,
    line6: 12.703,
    line7: 14.514,
  },
  {
    age: 494,
    line1: 6.928,
    line2: 7.766,
    line3: 8.735,
    line4: 9.861,
    line5: 11.173,
    line6: 12.712,
    line7: 14.524,
  },
  {
    age: 495,
    line1: 6.933,
    line2: 7.772,
    line3: 8.742,
    line4: 9.868,
    line5: 11.181,
    line6: 12.721,
    line7: 14.534,
  },
  {
    age: 496,
    line1: 6.937,
    line2: 7.777,
    line3: 8.748,
    line4: 9.875,
    line5: 11.189,
    line6: 12.73,
    line7: 14.545,
  },
  {
    age: 497,
    line1: 6.942,
    line2: 7.783,
    line3: 8.754,
    line4: 9.882,
    line5: 11.197,
    line6: 12.739,
    line7: 14.556,
  },
  {
    age: 498,
    line1: 6.947,
    line2: 7.788,
    line3: 8.76,
    line4: 9.888,
    line5: 11.205,
    line6: 12.748,
    line7: 14.567,
  },
  {
    age: 499,
    line1: 6.952,
    line2: 7.794,
    line3: 8.766,
    line4: 9.895,
    line5: 11.213,
    line6: 12.757,
    line7: 14.577,
  },
  {
    age: 500,
    line1: 6.957,
    line2: 7.799,
    line3: 8.772,
    line4: 9.902,
    line5: 11.221,
    line6: 12.766,
    line7: 14.587,
  },
  {
    age: 501,
    line1: 6.962,
    line2: 7.805,
    line3: 8.778,
    line4: 9.909,
    line5: 11.228,
    line6: 12.775,
    line7: 14.598,
  },
  {
    age: 502,
    line1: 6.967,
    line2: 7.81,
    line3: 8.785,
    line4: 9.916,
    line5: 11.236,
    line6: 12.784,
    line7: 14.608,
  },
  {
    age: 503,
    line1: 6.972,
    line2: 7.816,
    line3: 8.791,
    line4: 9.923,
    line5: 11.244,
    line6: 12.793,
    line7: 14.619,
  },
  {
    age: 504,
    line1: 6.977,
    line2: 7.821,
    line3: 8.797,
    line4: 9.93,
    line5: 11.252,
    line6: 12.802,
    line7: 14.629,
  },
  {
    age: 505,
    line1: 6.982,
    line2: 7.827,
    line3: 8.803,
    line4: 9.937,
    line5: 11.26,
    line6: 12.811,
    line7: 14.64,
  },
  {
    age: 506,
    line1: 6.987,
    line2: 7.832,
    line3: 8.809,
    line4: 9.944,
    line5: 11.268,
    line6: 12.82,
    line7: 14.65,
  },
  {
    age: 507,
    line1: 6.992,
    line2: 7.837,
    line3: 8.815,
    line4: 9.951,
    line5: 11.276,
    line6: 12.829,
    line7: 14.661,
  },
  {
    age: 508,
    line1: 6.997,
    line2: 7.843,
    line3: 8.821,
    line4: 9.958,
    line5: 11.283,
    line6: 12.838,
    line7: 14.672,
  },
  {
    age: 509,
    line1: 7.002,
    line2: 7.848,
    line3: 8.827,
    line4: 9.964,
    line5: 11.291,
    line6: 12.847,
    line7: 14.682,
  },
  {
    age: 510,
    line1: 7.007,
    line2: 7.854,
    line3: 8.833,
    line4: 9.971,
    line5: 11.299,
    line6: 12.856,
    line7: 14.693,
  },
  {
    age: 511,
    line1: 7.012,
    line2: 7.859,
    line3: 8.84,
    line4: 9.978,
    line5: 11.307,
    line6: 12.865,
    line7: 14.703,
  },
  {
    age: 512,
    line1: 7.017,
    line2: 7.865,
    line3: 8.846,
    line4: 9.985,
    line5: 11.315,
    line6: 12.874,
    line7: 14.714,
  },
  {
    age: 513,
    line1: 7.022,
    line2: 7.87,
    line3: 8.852,
    line4: 9.992,
    line5: 11.323,
    line6: 12.884,
    line7: 14.724,
  },
  {
    age: 514,
    line1: 7.027,
    line2: 7.876,
    line3: 8.858,
    line4: 9.999,
    line5: 11.331,
    line6: 12.893,
    line7: 14.735,
  },
  {
    age: 515,
    line1: 7.032,
    line2: 7.881,
    line3: 8.864,
    line4: 10.006,
    line5: 11.338,
    line6: 12.902,
    line7: 14.745,
  },
  {
    age: 516,
    line1: 7.036,
    line2: 7.887,
    line3: 8.87,
    line4: 10.013,
    line5: 11.346,
    line6: 12.911,
    line7: 14.755,
  },
  {
    age: 517,
    line1: 7.041,
    line2: 7.892,
    line3: 8.876,
    line4: 10.02,
    line5: 11.354,
    line6: 12.92,
    line7: 14.766,
  },
  {
    age: 518,
    line1: 7.046,
    line2: 7.898,
    line3: 8.882,
    line4: 10.026,
    line5: 11.362,
    line6: 12.929,
    line7: 14.777,
  },
  {
    age: 519,
    line1: 7.051,
    line2: 7.903,
    line3: 8.889,
    line4: 10.033,
    line5: 11.37,
    line6: 12.938,
    line7: 14.787,
  },
  {
    age: 520,
    line1: 7.056,
    line2: 7.909,
    line3: 8.895,
    line4: 10.04,
    line5: 11.378,
    line6: 12.947,
    line7: 14.798,
  },
  {
    age: 521,
    line1: 7.061,
    line2: 7.914,
    line3: 8.901,
    line4: 10.047,
    line5: 11.385,
    line6: 12.956,
    line7: 14.808,
  },
  {
    age: 522,
    line1: 7.066,
    line2: 7.92,
    line3: 8.907,
    line4: 10.054,
    line5: 11.393,
    line6: 12.965,
    line7: 14.819,
  },
  {
    age: 523,
    line1: 7.071,
    line2: 7.925,
    line3: 8.913,
    line4: 10.061,
    line5: 11.401,
    line6: 12.974,
    line7: 14.829,
  },
  {
    age: 524,
    line1: 7.076,
    line2: 7.931,
    line3: 8.919,
    line4: 10.068,
    line5: 11.409,
    line6: 12.983,
    line7: 14.84,
  },
  {
    age: 525,
    line1: 7.081,
    line2: 7.936,
    line3: 8.925,
    line4: 10.075,
    line5: 11.417,
    line6: 12.992,
    line7: 14.85,
  },
  {
    age: 526,
    line1: 7.086,
    line2: 7.941,
    line3: 8.931,
    line4: 10.082,
    line5: 11.425,
    line6: 13.001,
    line7: 14.86,
  },
  {
    age: 527,
    line1: 7.091,
    line2: 7.947,
    line3: 8.937,
    line4: 10.088,
    line5: 11.432,
    line6: 13.01,
    line7: 14.871,
  },
  {
    age: 528,
    line1: 7.095,
    line2: 7.952,
    line3: 8.943,
    line4: 10.095,
    line5: 11.44,
    line6: 13.019,
    line7: 14.882,
  },
  {
    age: 529,
    line1: 7.1,
    line2: 7.958,
    line3: 8.949,
    line4: 10.102,
    line5: 11.448,
    line6: 13.028,
    line7: 14.892,
  },
  {
    age: 530,
    line1: 7.105,
    line2: 7.963,
    line3: 8.956,
    line4: 10.109,
    line5: 11.456,
    line6: 13.037,
    line7: 14.903,
  },
  {
    age: 531,
    line1: 7.11,
    line2: 7.969,
    line3: 8.962,
    line4: 10.116,
    line5: 11.464,
    line6: 13.046,
    line7: 14.913,
  },
  {
    age: 532,
    line1: 7.115,
    line2: 7.974,
    line3: 8.968,
    line4: 10.123,
    line5: 11.471,
    line6: 13.055,
    line7: 14.923,
  },
  {
    age: 533,
    line1: 7.12,
    line2: 7.98,
    line3: 8.974,
    line4: 10.13,
    line5: 11.479,
    line6: 13.064,
    line7: 14.934,
  },
  {
    age: 534,
    line1: 7.125,
    line2: 7.985,
    line3: 8.98,
    line4: 10.136,
    line5: 11.487,
    line6: 13.073,
    line7: 14.944,
  },
  {
    age: 535,
    line1: 7.13,
    line2: 7.99,
    line3: 8.986,
    line4: 10.143,
    line5: 11.495,
    line6: 13.082,
    line7: 14.955,
  },
  {
    age: 536,
    line1: 7.135,
    line2: 7.996,
    line3: 8.992,
    line4: 10.15,
    line5: 11.503,
    line6: 13.091,
    line7: 14.966,
  },
  {
    age: 537,
    line1: 7.14,
    line2: 8.001,
    line3: 8.998,
    line4: 10.157,
    line5: 11.511,
    line6: 13.1,
    line7: 14.976,
  },
  {
    age: 538,
    line1: 7.145,
    line2: 8.007,
    line3: 9.004,
    line4: 10.164,
    line5: 11.518,
    line6: 13.109,
    line7: 14.986,
  },
  {
    age: 539,
    line1: 7.149,
    line2: 8.012,
    line3: 9.01,
    line4: 10.171,
    line5: 11.526,
    line6: 13.118,
    line7: 14.997,
  },
  {
    age: 540,
    line1: 7.154,
    line2: 8.018,
    line3: 9.016,
    line4: 10.178,
    line5: 11.534,
    line6: 13.127,
    line7: 15.007,
  },
  {
    age: 541,
    line1: 7.159,
    line2: 8.023,
    line3: 9.023,
    line4: 10.184,
    line5: 11.542,
    line6: 13.136,
    line7: 15.018,
  },
  {
    age: 542,
    line1: 7.164,
    line2: 8.028,
    line3: 9.029,
    line4: 10.191,
    line5: 11.55,
    line6: 13.145,
    line7: 15.028,
  },
  {
    age: 543,
    line1: 7.169,
    line2: 8.034,
    line3: 9.035,
    line4: 10.198,
    line5: 11.557,
    line6: 13.154,
    line7: 15.039,
  },
  {
    age: 544,
    line1: 7.174,
    line2: 8.039,
    line3: 9.041,
    line4: 10.205,
    line5: 11.565,
    line6: 13.163,
    line7: 15.049,
  },
  {
    age: 545,
    line1: 7.179,
    line2: 8.045,
    line3: 9.047,
    line4: 10.212,
    line5: 11.573,
    line6: 13.172,
    line7: 15.059,
  },
  {
    age: 546,
    line1: 7.184,
    line2: 8.05,
    line3: 9.053,
    line4: 10.219,
    line5: 11.581,
    line6: 13.18,
    line7: 15.07,
  },
  {
    age: 547,
    line1: 7.189,
    line2: 8.056,
    line3: 9.059,
    line4: 10.226,
    line5: 11.589,
    line6: 13.189,
    line7: 15.08,
  },
  {
    age: 548,
    line1: 7.194,
    line2: 8.061,
    line3: 9.065,
    line4: 10.232,
    line5: 11.596,
    line6: 13.198,
    line7: 15.091,
  },
  {
    age: 549,
    line1: 7.198,
    line2: 8.066,
    line3: 9.071,
    line4: 10.239,
    line5: 11.604,
    line6: 13.208,
    line7: 15.101,
  },
  {
    age: 550,
    line1: 7.203,
    line2: 8.072,
    line3: 9.077,
    line4: 10.246,
    line5: 11.612,
    line6: 13.217,
    line7: 15.112,
  },
  {
    age: 551,
    line1: 7.208,
    line2: 8.077,
    line3: 9.083,
    line4: 10.253,
    line5: 11.62,
    line6: 13.225,
    line7: 15.122,
  },
  {
    age: 552,
    line1: 7.213,
    line2: 8.083,
    line3: 9.089,
    line4: 10.26,
    line5: 11.628,
    line6: 13.234,
    line7: 15.132,
  },
  {
    age: 553,
    line1: 7.218,
    line2: 8.088,
    line3: 9.095,
    line4: 10.267,
    line5: 11.635,
    line6: 13.243,
    line7: 15.143,
  },
  {
    age: 554,
    line1: 7.223,
    line2: 8.093,
    line3: 9.101,
    line4: 10.273,
    line5: 11.643,
    line6: 13.252,
    line7: 15.153,
  },
  {
    age: 555,
    line1: 7.227,
    line2: 8.099,
    line3: 9.107,
    line4: 10.28,
    line5: 11.651,
    line6: 13.261,
    line7: 15.164,
  },
  {
    age: 556,
    line1: 7.232,
    line2: 8.104,
    line3: 9.113,
    line4: 10.287,
    line5: 11.659,
    line6: 13.27,
    line7: 15.174,
  },
  {
    age: 557,
    line1: 7.237,
    line2: 8.11,
    line3: 9.119,
    line4: 10.294,
    line5: 11.666,
    line6: 13.279,
    line7: 15.185,
  },
  {
    age: 558,
    line1: 7.242,
    line2: 8.115,
    line3: 9.126,
    line4: 10.301,
    line5: 11.674,
    line6: 13.288,
    line7: 15.195,
  },
  {
    age: 559,
    line1: 7.247,
    line2: 8.121,
    line3: 9.132,
    line4: 10.308,
    line5: 11.682,
    line6: 13.297,
    line7: 15.205,
  },
  {
    age: 560,
    line1: 7.252,
    line2: 8.126,
    line3: 9.138,
    line4: 10.314,
    line5: 11.69,
    line6: 13.306,
    line7: 15.216,
  },
  {
    age: 561,
    line1: 7.257,
    line2: 8.131,
    line3: 9.144,
    line4: 10.321,
    line5: 11.698,
    line6: 13.315,
    line7: 15.227,
  },
  {
    age: 562,
    line1: 7.262,
    line2: 8.137,
    line3: 9.15,
    line4: 10.328,
    line5: 11.705,
    line6: 13.324,
    line7: 15.237,
  },
  {
    age: 563,
    line1: 7.266,
    line2: 8.142,
    line3: 9.156,
    line4: 10.335,
    line5: 11.713,
    line6: 13.333,
    line7: 15.247,
  },
  {
    age: 564,
    line1: 7.271,
    line2: 8.147,
    line3: 9.162,
    line4: 10.342,
    line5: 11.721,
    line6: 13.342,
    line7: 15.258,
  },
  {
    age: 565,
    line1: 7.276,
    line2: 8.153,
    line3: 9.168,
    line4: 10.349,
    line5: 11.729,
    line6: 13.351,
    line7: 15.268,
  },
  {
    age: 566,
    line1: 7.281,
    line2: 8.158,
    line3: 9.174,
    line4: 10.355,
    line5: 11.737,
    line6: 13.36,
    line7: 15.279,
  },
  {
    age: 567,
    line1: 7.286,
    line2: 8.164,
    line3: 9.18,
    line4: 10.362,
    line5: 11.744,
    line6: 13.369,
    line7: 15.289,
  },
  {
    age: 568,
    line1: 7.291,
    line2: 8.169,
    line3: 9.186,
    line4: 10.369,
    line5: 11.752,
    line6: 13.378,
    line7: 15.299,
  },
  {
    age: 569,
    line1: 7.296,
    line2: 8.175,
    line3: 9.192,
    line4: 10.376,
    line5: 11.76,
    line6: 13.387,
    line7: 15.31,
  },
  {
    age: 570,
    line1: 7.301,
    line2: 8.18,
    line3: 9.198,
    line4: 10.383,
    line5: 11.768,
    line6: 13.396,
    line7: 15.32,
  },
  {
    age: 571,
    line1: 7.305,
    line2: 8.185,
    line3: 9.204,
    line4: 10.39,
    line5: 11.775,
    line6: 13.405,
    line7: 15.331,
  },
  {
    age: 572,
    line1: 7.31,
    line2: 8.191,
    line3: 9.21,
    line4: 10.396,
    line5: 11.783,
    line6: 13.414,
    line7: 15.341,
  },
  {
    age: 573,
    line1: 7.315,
    line2: 8.196,
    line3: 9.216,
    line4: 10.403,
    line5: 11.791,
    line6: 13.422,
    line7: 15.351,
  },
  {
    age: 574,
    line1: 7.32,
    line2: 8.201,
    line3: 9.222,
    line4: 10.41,
    line5: 11.799,
    line6: 13.431,
    line7: 15.362,
  },
  {
    age: 575,
    line1: 7.325,
    line2: 8.207,
    line3: 9.228,
    line4: 10.417,
    line5: 11.807,
    line6: 13.44,
    line7: 15.372,
  },
  {
    age: 576,
    line1: 7.329,
    line2: 8.212,
    line3: 9.234,
    line4: 10.424,
    line5: 11.814,
    line6: 13.449,
    line7: 15.383,
  },
  {
    age: 577,
    line1: 7.334,
    line2: 8.218,
    line3: 9.24,
    line4: 10.43,
    line5: 11.822,
    line6: 13.458,
    line7: 15.393,
  },
  {
    age: 578,
    line1: 7.339,
    line2: 8.223,
    line3: 9.246,
    line4: 10.437,
    line5: 11.83,
    line6: 13.467,
    line7: 15.403,
  },
  {
    age: 579,
    line1: 7.344,
    line2: 8.228,
    line3: 9.252,
    line4: 10.444,
    line5: 11.838,
    line6: 13.476,
    line7: 15.414,
  },
  {
    age: 580,
    line1: 7.349,
    line2: 8.234,
    line3: 9.258,
    line4: 10.451,
    line5: 11.845,
    line6: 13.485,
    line7: 15.425,
  },
  {
    age: 581,
    line1: 7.354,
    line2: 8.239,
    line3: 9.264,
    line4: 10.458,
    line5: 11.853,
    line6: 13.494,
    line7: 15.435,
  },
  {
    age: 582,
    line1: 7.359,
    line2: 8.244,
    line3: 9.271,
    line4: 10.464,
    line5: 11.861,
    line6: 13.503,
    line7: 15.445,
  },
  {
    age: 583,
    line1: 7.363,
    line2: 8.25,
    line3: 9.277,
    line4: 10.471,
    line5: 11.869,
    line6: 13.512,
    line7: 15.456,
  },
  {
    age: 584,
    line1: 7.368,
    line2: 8.255,
    line3: 9.283,
    line4: 10.478,
    line5: 11.876,
    line6: 13.521,
    line7: 15.466,
  },
  {
    age: 585,
    line1: 7.373,
    line2: 8.261,
    line3: 9.289,
    line4: 10.485,
    line5: 11.884,
    line6: 13.53,
    line7: 15.477,
  },
  {
    age: 586,
    line1: 7.378,
    line2: 8.266,
    line3: 9.295,
    line4: 10.492,
    line5: 11.892,
    line6: 13.539,
    line7: 15.487,
  },
  {
    age: 587,
    line1: 7.383,
    line2: 8.271,
    line3: 9.301,
    line4: 10.498,
    line5: 11.9,
    line6: 13.548,
    line7: 15.497,
  },
  {
    age: 588,
    line1: 7.388,
    line2: 8.277,
    line3: 9.307,
    line4: 10.505,
    line5: 11.907,
    line6: 13.557,
    line7: 15.508,
  },
  {
    age: 589,
    line1: 7.392,
    line2: 8.282,
    line3: 9.313,
    line4: 10.512,
    line5: 11.915,
    line6: 13.566,
    line7: 15.518,
  },
  {
    age: 590,
    line1: 7.397,
    line2: 8.287,
    line3: 9.319,
    line4: 10.519,
    line5: 11.923,
    line6: 13.575,
    line7: 15.529,
  },
  {
    age: 591,
    line1: 7.402,
    line2: 8.293,
    line3: 9.325,
    line4: 10.526,
    line5: 11.931,
    line6: 13.583,
    line7: 15.539,
  },
  {
    age: 592,
    line1: 7.407,
    line2: 8.298,
    line3: 9.331,
    line4: 10.532,
    line5: 11.939,
    line6: 13.593,
    line7: 15.55,
  },
  {
    age: 593,
    line1: 7.411,
    line2: 8.303,
    line3: 9.337,
    line4: 10.539,
    line5: 11.946,
    line6: 13.601,
    line7: 15.56,
  },
  {
    age: 594,
    line1: 7.416,
    line2: 8.309,
    line3: 9.343,
    line4: 10.546,
    line5: 11.954,
    line6: 13.61,
    line7: 15.57,
  },
  {
    age: 595,
    line1: 7.421,
    line2: 8.314,
    line3: 9.349,
    line4: 10.553,
    line5: 11.962,
    line6: 13.619,
    line7: 15.58,
  },
  {
    age: 596,
    line1: 7.426,
    line2: 8.319,
    line3: 9.355,
    line4: 10.56,
    line5: 11.97,
    line6: 13.628,
    line7: 15.591,
  },
  {
    age: 597,
    line1: 7.431,
    line2: 8.325,
    line3: 9.361,
    line4: 10.566,
    line5: 11.977,
    line6: 13.637,
    line7: 15.602,
  },
  {
    age: 598,
    line1: 7.436,
    line2: 8.33,
    line3: 9.367,
    line4: 10.573,
    line5: 11.985,
    line6: 13.646,
    line7: 15.612,
  },
  {
    age: 599,
    line1: 7.441,
    line2: 8.336,
    line3: 9.373,
    line4: 10.58,
    line5: 11.993,
    line6: 13.655,
    line7: 15.622,
  },
  {
    age: 600,
    line1: 7.445,
    line2: 8.341,
    line3: 9.379,
    line4: 10.587,
    line5: 12.001,
    line6: 13.664,
    line7: 15.633,
  },
  {
    age: 601,
    line1: 7.45,
    line2: 8.346,
    line3: 9.385,
    line4: 10.594,
    line5: 12.008,
    line6: 13.673,
    line7: 15.643,
  },
  {
    age: 602,
    line1: 7.455,
    line2: 8.352,
    line3: 9.391,
    line4: 10.6,
    line5: 12.016,
    line6: 13.682,
    line7: 15.654,
  },
  {
    age: 603,
    line1: 7.46,
    line2: 8.357,
    line3: 9.397,
    line4: 10.607,
    line5: 12.024,
    line6: 13.691,
    line7: 15.664,
  },
  {
    age: 604,
    line1: 7.464,
    line2: 8.362,
    line3: 9.403,
    line4: 10.614,
    line5: 12.032,
    line6: 13.7,
    line7: 15.674,
  },
  {
    age: 605,
    line1: 7.469,
    line2: 8.368,
    line3: 9.409,
    line4: 10.621,
    line5: 12.039,
    line6: 13.709,
    line7: 15.685,
  },
  {
    age: 606,
    line1: 7.474,
    line2: 8.373,
    line3: 9.415,
    line4: 10.628,
    line5: 12.047,
    line6: 13.718,
    line7: 15.696,
  },
  {
    age: 607,
    line1: 7.479,
    line2: 8.378,
    line3: 9.421,
    line4: 10.634,
    line5: 12.055,
    line6: 13.727,
    line7: 15.706,
  },
  {
    age: 608,
    line1: 7.484,
    line2: 8.384,
    line3: 9.427,
    line4: 10.641,
    line5: 12.063,
    line6: 13.736,
    line7: 15.716,
  },
  {
    age: 609,
    line1: 7.488,
    line2: 8.389,
    line3: 9.433,
    line4: 10.648,
    line5: 12.071,
    line6: 13.745,
    line7: 15.727,
  },
  {
    age: 610,
    line1: 7.493,
    line2: 8.394,
    line3: 9.439,
    line4: 10.655,
    line5: 12.078,
    line6: 13.754,
    line7: 15.737,
  },
  {
    age: 611,
    line1: 7.498,
    line2: 8.4,
    line3: 9.445,
    line4: 10.662,
    line5: 12.086,
    line6: 13.762,
    line7: 15.747,
  },
  {
    age: 612,
    line1: 7.503,
    line2: 8.405,
    line3: 9.451,
    line4: 10.668,
    line5: 12.094,
    line6: 13.772,
    line7: 15.758,
  },
  {
    age: 613,
    line1: 7.508,
    line2: 8.411,
    line3: 9.457,
    line4: 10.675,
    line5: 12.102,
    line6: 13.78,
    line7: 15.769,
  },
  {
    age: 614,
    line1: 7.512,
    line2: 8.416,
    line3: 9.463,
    line4: 10.682,
    line5: 12.109,
    line6: 13.789,
    line7: 15.779,
  },
  {
    age: 615,
    line1: 7.517,
    line2: 8.421,
    line3: 9.469,
    line4: 10.689,
    line5: 12.117,
    line6: 13.798,
    line7: 15.79,
  },
  {
    age: 616,
    line1: 7.522,
    line2: 8.427,
    line3: 9.475,
    line4: 10.696,
    line5: 12.125,
    line6: 13.807,
    line7: 15.8,
  },
  {
    age: 617,
    line1: 7.527,
    line2: 8.432,
    line3: 9.481,
    line4: 10.702,
    line5: 12.133,
    line6: 13.816,
    line7: 15.81,
  },
  {
    age: 618,
    line1: 7.532,
    line2: 8.437,
    line3: 9.487,
    line4: 10.709,
    line5: 12.14,
    line6: 13.825,
    line7: 15.821,
  },
  {
    age: 619,
    line1: 7.536,
    line2: 8.443,
    line3: 9.493,
    line4: 10.716,
    line5: 12.148,
    line6: 13.834,
    line7: 15.831,
  },
  {
    age: 620,
    line1: 7.541,
    line2: 8.448,
    line3: 9.499,
    line4: 10.723,
    line5: 12.156,
    line6: 13.843,
    line7: 15.841,
  },
  {
    age: 621,
    line1: 7.546,
    line2: 8.453,
    line3: 9.505,
    line4: 10.73,
    line5: 12.164,
    line6: 13.852,
    line7: 15.852,
  },
  {
    age: 622,
    line1: 7.551,
    line2: 8.459,
    line3: 9.511,
    line4: 10.736,
    line5: 12.171,
    line6: 13.861,
    line7: 15.863,
  },
  {
    age: 623,
    line1: 7.556,
    line2: 8.464,
    line3: 9.517,
    line4: 10.743,
    line5: 12.179,
    line6: 13.87,
    line7: 15.873,
  },
  {
    age: 624,
    line1: 7.56,
    line2: 8.469,
    line3: 9.523,
    line4: 10.75,
    line5: 12.187,
    line6: 13.879,
    line7: 15.884,
  },
  {
    age: 625,
    line1: 7.565,
    line2: 8.475,
    line3: 9.529,
    line4: 10.757,
    line5: 12.195,
    line6: 13.888,
    line7: 15.894,
  },
  {
    age: 626,
    line1: 7.57,
    line2: 8.48,
    line3: 9.535,
    line4: 10.764,
    line5: 12.203,
    line6: 13.897,
    line7: 15.905,
  },
  {
    age: 627,
    line1: 7.575,
    line2: 8.485,
    line3: 9.541,
    line4: 10.77,
    line5: 12.21,
    line6: 13.906,
    line7: 15.915,
  },
  {
    age: 628,
    line1: 7.579,
    line2: 8.491,
    line3: 9.547,
    line4: 10.777,
    line5: 12.218,
    line6: 13.915,
    line7: 15.925,
  },
  {
    age: 629,
    line1: 7.584,
    line2: 8.496,
    line3: 9.553,
    line4: 10.784,
    line5: 12.226,
    line6: 13.924,
    line7: 15.936,
  },
  {
    age: 630,
    line1: 7.589,
    line2: 8.501,
    line3: 9.559,
    line4: 10.791,
    line5: 12.234,
    line6: 13.933,
    line7: 15.946,
  },
  {
    age: 631,
    line1: 7.594,
    line2: 8.507,
    line3: 9.565,
    line4: 10.798,
    line5: 12.242,
    line6: 13.942,
    line7: 15.957,
  },
  {
    age: 632,
    line1: 7.599,
    line2: 8.512,
    line3: 9.571,
    line4: 10.804,
    line5: 12.249,
    line6: 13.951,
    line7: 15.967,
  },
  {
    age: 633,
    line1: 7.603,
    line2: 8.517,
    line3: 9.577,
    line4: 10.811,
    line5: 12.257,
    line6: 13.96,
    line7: 15.977,
  },
  {
    age: 634,
    line1: 7.608,
    line2: 8.523,
    line3: 9.583,
    line4: 10.818,
    line5: 12.265,
    line6: 13.969,
    line7: 15.988,
  },
  {
    age: 635,
    line1: 7.613,
    line2: 8.528,
    line3: 9.589,
    line4: 10.825,
    line5: 12.273,
    line6: 13.978,
    line7: 15.999,
  },
  {
    age: 636,
    line1: 7.618,
    line2: 8.533,
    line3: 9.595,
    line4: 10.832,
    line5: 12.28,
    line6: 13.987,
    line7: 16.009,
  },
  {
    age: 637,
    line1: 7.622,
    line2: 8.539,
    line3: 9.601,
    line4: 10.838,
    line5: 12.288,
    line6: 13.996,
    line7: 16.02,
  },
  {
    age: 638,
    line1: 7.627,
    line2: 8.544,
    line3: 9.607,
    line4: 10.845,
    line5: 12.296,
    line6: 14.005,
    line7: 16.03,
  },
  {
    age: 639,
    line1: 7.632,
    line2: 8.549,
    line3: 9.613,
    line4: 10.852,
    line5: 12.304,
    line6: 14.014,
    line7: 16.041,
  },
  {
    age: 640,
    line1: 7.637,
    line2: 8.555,
    line3: 9.619,
    line4: 10.859,
    line5: 12.312,
    line6: 14.023,
    line7: 16.052,
  },
  {
    age: 641,
    line1: 7.641,
    line2: 8.56,
    line3: 9.625,
    line4: 10.866,
    line5: 12.319,
    line6: 14.032,
    line7: 16.062,
  },
  {
    age: 642,
    line1: 7.646,
    line2: 8.565,
    line3: 9.631,
    line4: 10.872,
    line5: 12.327,
    line6: 14.041,
    line7: 16.072,
  },
  {
    age: 643,
    line1: 7.651,
    line2: 8.571,
    line3: 9.637,
    line4: 10.879,
    line5: 12.335,
    line6: 14.05,
    line7: 16.083,
  },
  {
    age: 644,
    line1: 7.656,
    line2: 8.576,
    line3: 9.643,
    line4: 10.886,
    line5: 12.343,
    line6: 14.059,
    line7: 16.093,
  },
  {
    age: 645,
    line1: 7.66,
    line2: 8.581,
    line3: 9.649,
    line4: 10.893,
    line5: 12.35,
    line6: 14.068,
    line7: 16.104,
  },
  {
    age: 646,
    line1: 7.665,
    line2: 8.587,
    line3: 9.655,
    line4: 10.9,
    line5: 12.358,
    line6: 14.077,
    line7: 16.114,
  },
  {
    age: 647,
    line1: 7.67,
    line2: 8.592,
    line3: 9.661,
    line4: 10.906,
    line5: 12.366,
    line6: 14.086,
    line7: 16.125,
  },
  {
    age: 648,
    line1: 7.675,
    line2: 8.597,
    line3: 9.667,
    line4: 10.913,
    line5: 12.374,
    line6: 14.095,
    line7: 16.135,
  },
  {
    age: 649,
    line1: 7.679,
    line2: 8.602,
    line3: 9.673,
    line4: 10.92,
    line5: 12.382,
    line6: 14.104,
    line7: 16.146,
  },
  {
    age: 650,
    line1: 7.684,
    line2: 8.608,
    line3: 9.679,
    line4: 10.927,
    line5: 12.389,
    line6: 14.113,
    line7: 16.156,
  },
  {
    age: 651,
    line1: 7.689,
    line2: 8.613,
    line3: 9.685,
    line4: 10.934,
    line5: 12.397,
    line6: 14.122,
    line7: 16.167,
  },
  {
    age: 652,
    line1: 7.694,
    line2: 8.619,
    line3: 9.691,
    line4: 10.941,
    line5: 12.405,
    line6: 14.131,
    line7: 16.177,
  },
  {
    age: 653,
    line1: 7.699,
    line2: 8.624,
    line3: 9.697,
    line4: 10.947,
    line5: 12.413,
    line6: 14.14,
    line7: 16.188,
  },
  {
    age: 654,
    line1: 7.703,
    line2: 8.629,
    line3: 9.703,
    line4: 10.954,
    line5: 12.421,
    line6: 14.149,
    line7: 16.198,
  },
  {
    age: 655,
    line1: 7.708,
    line2: 8.634,
    line3: 9.709,
    line4: 10.961,
    line5: 12.428,
    line6: 14.158,
    line7: 16.209,
  },
  {
    age: 656,
    line1: 7.713,
    line2: 8.64,
    line3: 9.715,
    line4: 10.968,
    line5: 12.436,
    line6: 14.167,
    line7: 16.22,
  },
  {
    age: 657,
    line1: 7.718,
    line2: 8.645,
    line3: 9.721,
    line4: 10.975,
    line5: 12.444,
    line6: 14.176,
    line7: 16.231,
  },
  {
    age: 658,
    line1: 7.722,
    line2: 8.65,
    line3: 9.727,
    line4: 10.982,
    line5: 12.452,
    line6: 14.185,
    line7: 16.241,
  },
  {
    age: 659,
    line1: 7.727,
    line2: 8.656,
    line3: 9.733,
    line4: 10.988,
    line5: 12.46,
    line6: 14.194,
    line7: 16.252,
  },
  {
    age: 660,
    line1: 7.732,
    line2: 8.661,
    line3: 9.739,
    line4: 10.995,
    line5: 12.467,
    line6: 14.203,
    line7: 16.262,
  },
  {
    age: 661,
    line1: 7.737,
    line2: 8.666,
    line3: 9.745,
    line4: 11.002,
    line5: 12.475,
    line6: 14.212,
    line7: 16.273,
  },
  {
    age: 662,
    line1: 7.741,
    line2: 8.672,
    line3: 9.751,
    line4: 11.009,
    line5: 12.483,
    line6: 14.221,
    line7: 16.283,
  },
  {
    age: 663,
    line1: 7.746,
    line2: 8.677,
    line3: 9.757,
    line4: 11.016,
    line5: 12.491,
    line6: 14.23,
    line7: 16.294,
  },
  {
    age: 664,
    line1: 7.751,
    line2: 8.682,
    line3: 9.763,
    line4: 11.022,
    line5: 12.499,
    line6: 14.239,
    line7: 16.304,
  },
  {
    age: 665,
    line1: 7.756,
    line2: 8.688,
    line3: 9.769,
    line4: 11.029,
    line5: 12.507,
    line6: 14.248,
    line7: 16.315,
  },
  {
    age: 666,
    line1: 7.76,
    line2: 8.693,
    line3: 9.775,
    line4: 11.036,
    line5: 12.514,
    line6: 14.257,
    line7: 16.325,
  },
  {
    age: 667,
    line1: 7.765,
    line2: 8.698,
    line3: 9.781,
    line4: 11.043,
    line5: 12.522,
    line6: 14.267,
    line7: 16.336,
  },
  {
    age: 668,
    line1: 7.77,
    line2: 8.704,
    line3: 9.787,
    line4: 11.05,
    line5: 12.53,
    line6: 14.276,
    line7: 16.347,
  },
  {
    age: 669,
    line1: 7.775,
    line2: 8.709,
    line3: 9.793,
    line4: 11.056,
    line5: 12.538,
    line6: 14.285,
    line7: 16.357,
  },
  {
    age: 670,
    line1: 7.779,
    line2: 8.714,
    line3: 9.799,
    line4: 11.063,
    line5: 12.546,
    line6: 14.294,
    line7: 16.368,
  },
  {
    age: 671,
    line1: 7.784,
    line2: 8.72,
    line3: 9.805,
    line4: 11.07,
    line5: 12.554,
    line6: 14.303,
    line7: 16.378,
  },
  {
    age: 672,
    line1: 7.789,
    line2: 8.725,
    line3: 9.811,
    line4: 11.077,
    line5: 12.561,
    line6: 14.312,
    line7: 16.389,
  },
  {
    age: 673,
    line1: 7.794,
    line2: 8.73,
    line3: 9.817,
    line4: 11.084,
    line5: 12.569,
    line6: 14.321,
    line7: 16.399,
  },
  {
    age: 674,
    line1: 7.798,
    line2: 8.736,
    line3: 9.823,
    line4: 11.091,
    line5: 12.577,
    line6: 14.33,
    line7: 16.41,
  },
  {
    age: 675,
    line1: 7.803,
    line2: 8.741,
    line3: 9.829,
    line4: 11.098,
    line5: 12.585,
    line6: 14.339,
    line7: 16.42,
  },
  {
    age: 676,
    line1: 7.808,
    line2: 8.746,
    line3: 9.835,
    line4: 11.104,
    line5: 12.593,
    line6: 14.348,
    line7: 16.431,
  },
  {
    age: 677,
    line1: 7.813,
    line2: 8.751,
    line3: 9.841,
    line4: 11.111,
    line5: 12.601,
    line6: 14.357,
    line7: 16.442,
  },
  {
    age: 678,
    line1: 7.817,
    line2: 8.757,
    line3: 9.847,
    line4: 11.118,
    line5: 12.608,
    line6: 14.366,
    line7: 16.453,
  },
  {
    age: 679,
    line1: 7.822,
    line2: 8.762,
    line3: 9.853,
    line4: 11.125,
    line5: 12.616,
    line6: 14.375,
    line7: 16.463,
  },
  {
    age: 680,
    line1: 7.827,
    line2: 8.768,
    line3: 9.859,
    line4: 11.132,
    line5: 12.624,
    line6: 14.384,
    line7: 16.474,
  },
  {
    age: 681,
    line1: 7.832,
    line2: 8.773,
    line3: 9.865,
    line4: 11.138,
    line5: 12.632,
    line6: 14.393,
    line7: 16.484,
  },
  {
    age: 682,
    line1: 7.836,
    line2: 8.778,
    line3: 9.871,
    line4: 11.145,
    line5: 12.64,
    line6: 14.403,
    line7: 16.495,
  },
  {
    age: 683,
    line1: 7.841,
    line2: 8.783,
    line3: 9.877,
    line4: 11.152,
    line5: 12.648,
    line6: 14.412,
    line7: 16.506,
  },
  {
    age: 684,
    line1: 7.846,
    line2: 8.789,
    line3: 9.883,
    line4: 11.159,
    line5: 12.655,
    line6: 14.421,
    line7: 16.516,
  },
  {
    age: 685,
    line1: 7.851,
    line2: 8.794,
    line3: 9.889,
    line4: 11.166,
    line5: 12.663,
    line6: 14.43,
    line7: 16.527,
  },
  {
    age: 686,
    line1: 7.855,
    line2: 8.799,
    line3: 9.895,
    line4: 11.173,
    line5: 12.671,
    line6: 14.439,
    line7: 16.538,
  },
  {
    age: 687,
    line1: 7.86,
    line2: 8.805,
    line3: 9.901,
    line4: 11.18,
    line5: 12.679,
    line6: 14.448,
    line7: 16.548,
  },
  {
    age: 688,
    line1: 7.865,
    line2: 8.81,
    line3: 9.907,
    line4: 11.186,
    line5: 12.687,
    line6: 14.457,
    line7: 16.559,
  },
  {
    age: 689,
    line1: 7.869,
    line2: 8.815,
    line3: 9.913,
    line4: 11.193,
    line5: 12.695,
    line6: 14.466,
    line7: 16.57,
  },
  {
    age: 690,
    line1: 7.874,
    line2: 8.821,
    line3: 9.919,
    line4: 11.2,
    line5: 12.702,
    line6: 14.475,
    line7: 16.58,
  },
  {
    age: 691,
    line1: 7.879,
    line2: 8.826,
    line3: 9.925,
    line4: 11.207,
    line5: 12.71,
    line6: 14.484,
    line7: 16.591,
  },
  {
    age: 692,
    line1: 7.884,
    line2: 8.831,
    line3: 9.931,
    line4: 11.214,
    line5: 12.718,
    line6: 14.494,
    line7: 16.602,
  },
  {
    age: 693,
    line1: 7.889,
    line2: 8.837,
    line3: 9.937,
    line4: 11.22,
    line5: 12.726,
    line6: 14.502,
    line7: 16.612,
  },
  {
    age: 694,
    line1: 7.893,
    line2: 8.842,
    line3: 9.943,
    line4: 11.227,
    line5: 12.734,
    line6: 14.512,
    line7: 16.623,
  },
  {
    age: 695,
    line1: 7.898,
    line2: 8.847,
    line3: 9.949,
    line4: 11.234,
    line5: 12.742,
    line6: 14.521,
    line7: 16.634,
  },
  {
    age: 696,
    line1: 7.903,
    line2: 8.853,
    line3: 9.955,
    line4: 11.241,
    line5: 12.75,
    line6: 14.53,
    line7: 16.644,
  },
  {
    age: 697,
    line1: 7.907,
    line2: 8.858,
    line3: 9.961,
    line4: 11.248,
    line5: 12.758,
    line6: 14.539,
    line7: 16.655,
  },
  {
    age: 698,
    line1: 7.912,
    line2: 8.863,
    line3: 9.967,
    line4: 11.255,
    line5: 12.765,
    line6: 14.548,
    line7: 16.665,
  },
  {
    age: 699,
    line1: 7.917,
    line2: 8.869,
    line3: 9.973,
    line4: 11.262,
    line5: 12.773,
    line6: 14.557,
    line7: 16.676,
  },
  {
    age: 700,
    line1: 7.922,
    line2: 8.874,
    line3: 9.979,
    line4: 11.268,
    line5: 12.781,
    line6: 14.566,
    line7: 16.687,
  },
  {
    age: 701,
    line1: 7.927,
    line2: 8.879,
    line3: 9.985,
    line4: 11.275,
    line5: 12.789,
    line6: 14.575,
    line7: 16.697,
  },
  {
    age: 702,
    line1: 7.931,
    line2: 8.884,
    line3: 9.991,
    line4: 11.282,
    line5: 12.797,
    line6: 14.585,
    line7: 16.708,
  },
  {
    age: 703,
    line1: 7.936,
    line2: 8.89,
    line3: 9.997,
    line4: 11.289,
    line5: 12.805,
    line6: 14.594,
    line7: 16.719,
  },
  {
    age: 704,
    line1: 7.941,
    line2: 8.895,
    line3: 10.003,
    line4: 11.296,
    line5: 12.813,
    line6: 14.603,
    line7: 16.729,
  },
  {
    age: 705,
    line1: 7.945,
    line2: 8.9,
    line3: 10.009,
    line4: 11.303,
    line5: 12.82,
    line6: 14.612,
    line7: 16.74,
  },
  {
    age: 706,
    line1: 7.95,
    line2: 8.906,
    line3: 10.015,
    line4: 11.31,
    line5: 12.828,
    line6: 14.621,
    line7: 16.751,
  },
  {
    age: 707,
    line1: 7.955,
    line2: 8.911,
    line3: 10.021,
    line4: 11.316,
    line5: 12.836,
    line6: 14.63,
    line7: 16.761,
  },
  {
    age: 708,
    line1: 7.96,
    line2: 8.916,
    line3: 10.027,
    line4: 11.323,
    line5: 12.844,
    line6: 14.639,
    line7: 16.772,
  },
  {
    age: 709,
    line1: 7.964,
    line2: 8.922,
    line3: 10.033,
    line4: 11.33,
    line5: 12.852,
    line6: 14.648,
    line7: 16.783,
  },
  {
    age: 710,
    line1: 7.969,
    line2: 8.927,
    line3: 10.039,
    line4: 11.337,
    line5: 12.86,
    line6: 14.657,
    line7: 16.793,
  },
  {
    age: 711,
    line1: 7.974,
    line2: 8.932,
    line3: 10.045,
    line4: 11.344,
    line5: 12.868,
    line6: 14.667,
    line7: 16.804,
  },
  {
    age: 712,
    line1: 7.978,
    line2: 8.938,
    line3: 10.051,
    line4: 11.351,
    line5: 12.876,
    line6: 14.676,
    line7: 16.815,
  },
  {
    age: 713,
    line1: 7.983,
    line2: 8.943,
    line3: 10.057,
    line4: 11.358,
    line5: 12.883,
    line6: 14.685,
    line7: 16.826,
  },
  {
    age: 714,
    line1: 7.988,
    line2: 8.948,
    line3: 10.063,
    line4: 11.364,
    line5: 12.891,
    line6: 14.694,
    line7: 16.836,
  },
  {
    age: 715,
    line1: 7.993,
    line2: 8.954,
    line3: 10.069,
    line4: 11.371,
    line5: 12.899,
    line6: 14.703,
    line7: 16.847,
  },
  {
    age: 716,
    line1: 7.997,
    line2: 8.959,
    line3: 10.075,
    line4: 11.378,
    line5: 12.907,
    line6: 14.712,
    line7: 16.858,
  },
  {
    age: 717,
    line1: 8.002,
    line2: 8.964,
    line3: 10.081,
    line4: 11.385,
    line5: 12.915,
    line6: 14.722,
    line7: 16.869,
  },
  {
    age: 718,
    line1: 8.007,
    line2: 8.969,
    line3: 10.087,
    line4: 11.392,
    line5: 12.923,
    line6: 14.731,
    line7: 16.879,
  },
  {
    age: 719,
    line1: 8.011,
    line2: 8.975,
    line3: 10.093,
    line4: 11.399,
    line5: 12.931,
    line6: 14.74,
    line7: 16.89,
  },
  {
    age: 720,
    line1: 8.016,
    line2: 8.98,
    line3: 10.099,
    line4: 11.406,
    line5: 12.939,
    line6: 14.749,
    line7: 16.901,
  },
  {
    age: 721,
    line1: 8.021,
    line2: 8.985,
    line3: 10.105,
    line4: 11.412,
    line5: 12.946,
    line6: 14.758,
    line7: 16.912,
  },
  {
    age: 722,
    line1: 8.026,
    line2: 8.991,
    line3: 10.111,
    line4: 11.419,
    line5: 12.954,
    line6: 14.767,
    line7: 16.922,
  },
  {
    age: 723,
    line1: 8.031,
    line2: 8.996,
    line3: 10.117,
    line4: 11.426,
    line5: 12.962,
    line6: 14.776,
    line7: 16.933,
  },
  {
    age: 724,
    line1: 8.035,
    line2: 9.001,
    line3: 10.123,
    line4: 11.433,
    line5: 12.97,
    line6: 14.786,
    line7: 16.944,
  },
  {
    age: 725,
    line1: 8.04,
    line2: 9.007,
    line3: 10.129,
    line4: 11.44,
    line5: 12.978,
    line6: 14.795,
    line7: 16.954,
  },
  {
    age: 726,
    line1: 8.045,
    line2: 9.012,
    line3: 10.135,
    line4: 11.447,
    line5: 12.986,
    line6: 14.804,
    line7: 16.965,
  },
  {
    age: 727,
    line1: 8.049,
    line2: 9.017,
    line3: 10.141,
    line4: 11.454,
    line5: 12.994,
    line6: 14.813,
    line7: 16.976,
  },
  {
    age: 728,
    line1: 8.054,
    line2: 9.023,
    line3: 10.147,
    line4: 11.46,
    line5: 13.002,
    line6: 14.822,
    line7: 16.987,
  },
  {
    age: 729,
    line1: 8.059,
    line2: 9.028,
    line3: 10.153,
    line4: 11.467,
    line5: 13.01,
    line6: 14.832,
    line7: 16.998,
  },
  {
    age: 730,
    line1: 8.064,
    line2: 9.033,
    line3: 10.159,
    line4: 11.474,
    line5: 13.018,
    line6: 14.841,
    line7: 17.008,
  },
];

export const MATURE_BOYS_LENGTH_LINE_COORDS = [
  {
    age: 0,
    line1: 44.205,
    line2: 46.098,
    line3: 47.991,
    line4: 49.884,
    line5: 51.777,
    line6: 53.67,
    line7: 55.564,
  },
  {
    age: 1,
    line1: 44.376,
    line2: 46.271,
    line3: 48.165,
    line4: 50.06,
    line5: 51.955,
    line6: 53.85,
    line7: 55.744,
  },
  {
    age: 2,
    line1: 44.547,
    line2: 46.443,
    line3: 48.339,
    line4: 50.236,
    line5: 52.132,
    line6: 54.029,
    line7: 55.925,
  },
  {
    age: 3,
    line1: 44.719,
    line2: 46.617,
    line3: 48.514,
    line4: 50.412,
    line5: 52.309,
    line6: 54.207,
    line7: 56.104,
  },
  {
    age: 4,
    line1: 44.89,
    line2: 46.789,
    line3: 48.689,
    line4: 50.588,
    line5: 52.487,
    line6: 54.386,
    line7: 56.285,
  },
  {
    age: 5,
    line1: 45.062,
    line2: 46.962,
    line3: 48.863,
    line4: 50.764,
    line5: 52.664,
    line6: 54.565,
    line7: 56.465,
  },
  {
    age: 6,
    line1: 45.233,
    line2: 47.135,
    line3: 49.037,
    line4: 50.939,
    line5: 52.841,
    line6: 54.743,
    line7: 56.646,
  },
  {
    age: 7,
    line1: 45.406,
    line2: 47.309,
    line3: 49.212,
    line4: 51.115,
    line5: 53.018,
    line6: 54.921,
    line7: 56.824,
  },
  {
    age: 8,
    line1: 45.578,
    line2: 47.482,
    line3: 49.387,
    line4: 51.291,
    line5: 53.195,
    line6: 55.1,
    line7: 57.004,
  },
  {
    age: 9,
    line1: 45.749,
    line2: 47.655,
    line3: 49.561,
    line4: 51.467,
    line5: 53.373,
    line6: 55.279,
    line7: 57.184,
  },
  {
    age: 10,
    line1: 45.921,
    line2: 47.828,
    line3: 49.736,
    line4: 51.643,
    line5: 53.55,
    line6: 55.457,
    line7: 57.364,
  },
  {
    age: 11,
    line1: 46.095,
    line2: 48.003,
    line3: 49.911,
    line4: 51.819,
    line5: 53.727,
    line6: 55.635,
    line7: 57.542,
  },
  {
    age: 12,
    line1: 46.267,
    line2: 48.176,
    line3: 50.085,
    line4: 51.994,
    line5: 53.904,
    line6: 55.813,
    line7: 57.722,
  },
  {
    age: 13,
    line1: 46.439,
    line2: 48.349,
    line3: 50.26,
    line4: 52.17,
    line5: 54.081,
    line6: 55.991,
    line7: 57.902,
  },
  {
    age: 14,
    line1: 46.611,
    line2: 48.523,
    line3: 50.434,
    line4: 52.346,
    line5: 54.258,
    line6: 56.169,
    line7: 58.081,
  },
  {
    age: 15,
    line1: 46.757,
    line2: 48.671,
    line3: 50.584,
    line4: 52.498,
    line5: 54.411,
    line6: 56.325,
    line7: 58.238,
  },
  {
    age: 16,
    line1: 46.901,
    line2: 48.817,
    line3: 50.733,
    line4: 52.649,
    line5: 54.565,
    line6: 56.481,
    line7: 58.396,
  },
  {
    age: 17,
    line1: 47.044,
    line2: 48.963,
    line3: 50.881,
    line4: 52.799,
    line5: 54.717,
    line6: 56.635,
    line7: 58.554,
  },
  {
    age: 18,
    line1: 47.187,
    line2: 49.107,
    line3: 51.028,
    line4: 52.948,
    line5: 54.869,
    line6: 56.789,
    line7: 58.71,
  },
  {
    age: 19,
    line1: 47.329,
    line2: 49.251,
    line3: 51.174,
    line4: 53.097,
    line5: 55.019,
    line6: 56.942,
    line7: 58.865,
  },
  {
    age: 20,
    line1: 47.47,
    line2: 49.395,
    line3: 51.319,
    line4: 53.244,
    line5: 55.169,
    line6: 57.094,
    line7: 59.018,
  },
  {
    age: 21,
    line1: 47.61,
    line2: 49.537,
    line3: 51.464,
    line4: 53.39,
    line5: 55.317,
    line6: 57.244,
    line7: 59.171,
  },
  {
    age: 22,
    line1: 47.749,
    line2: 49.678,
    line3: 51.607,
    line4: 53.536,
    line5: 55.465,
    line6: 57.394,
    line7: 59.323,
  },
  {
    age: 23,
    line1: 47.888,
    line2: 49.819,
    line3: 51.75,
    line4: 53.68,
    line5: 55.611,
    line6: 57.542,
    line7: 59.473,
  },
  {
    age: 24,
    line1: 48.024,
    line2: 49.957,
    line3: 51.891,
    line4: 53.824,
    line5: 55.757,
    line6: 57.691,
    line7: 59.624,
  },
  {
    age: 25,
    line1: 48.161,
    line2: 50.096,
    line3: 52.031,
    line4: 53.966,
    line5: 55.902,
    line6: 57.837,
    line7: 59.772,
  },
  {
    age: 26,
    line1: 48.295,
    line2: 50.233,
    line3: 52.17,
    line4: 54.108,
    line5: 56.046,
    line6: 57.983,
    line7: 59.921,
  },
  {
    age: 27,
    line1: 48.43,
    line2: 50.37,
    line3: 52.309,
    line4: 54.248,
    line5: 56.188,
    line6: 58.127,
    line7: 60.067,
  },
  {
    age: 28,
    line1: 48.563,
    line2: 50.505,
    line3: 52.446,
    line4: 54.388,
    line5: 56.33,
    line6: 58.271,
    line7: 60.213,
  },
  {
    age: 29,
    line1: 48.695,
    line2: 50.639,
    line3: 52.583,
    line4: 54.527,
    line5: 56.471,
    line6: 58.415,
    line7: 60.358,
  },
  {
    age: 30,
    line1: 48.828,
    line2: 50.773,
    line3: 52.719,
    line4: 54.664,
    line5: 56.61,
    line6: 58.556,
    line7: 60.501,
  },
  {
    age: 31,
    line1: 48.958,
    line2: 50.906,
    line3: 52.854,
    line4: 54.801,
    line5: 56.749,
    line6: 58.696,
    line7: 60.644,
  },
  {
    age: 32,
    line1: 49.088,
    line2: 51.037,
    line3: 52.987,
    line4: 54.937,
    line5: 56.887,
    line6: 58.836,
    line7: 60.786,
  },
  {
    age: 33,
    line1: 49.216,
    line2: 51.168,
    line3: 53.12,
    line4: 55.071,
    line5: 57.023,
    line6: 58.975,
    line7: 60.927,
  },
  {
    age: 34,
    line1: 49.344,
    line2: 51.297,
    line3: 53.251,
    line4: 55.205,
    line5: 57.159,
    line6: 59.112,
    line7: 61.066,
  },
  {
    age: 35,
    line1: 49.471,
    line2: 51.426,
    line3: 53.382,
    line4: 55.337,
    line5: 57.293,
    line6: 59.249,
    line7: 61.204,
  },
  {
    age: 36,
    line1: 49.596,
    line2: 51.554,
    line3: 53.511,
    line4: 55.469,
    line5: 57.426,
    line6: 59.384,
    line7: 61.341,
  },
  {
    age: 37,
    line1: 49.721,
    line2: 51.681,
    line3: 53.64,
    line4: 55.599,
    line5: 57.559,
    line6: 59.518,
    line7: 61.477,
  },
  {
    age: 38,
    line1: 49.844,
    line2: 51.805,
    line3: 53.767,
    line4: 55.728,
    line5: 57.69,
    line6: 59.652,
    line7: 61.613,
  },
  {
    age: 39,
    line1: 49.967,
    line2: 51.93,
    line3: 53.893,
    line4: 55.857,
    line5: 57.82,
    line6: 59.784,
    line7: 61.747,
  },
  {
    age: 40,
    line1: 50.089,
    line2: 52.054,
    line3: 54.019,
    line4: 55.984,
    line5: 57.949,
    line6: 59.914,
    line7: 61.879,
  },
  {
    age: 41,
    line1: 50.209,
    line2: 52.176,
    line3: 54.143,
    line4: 56.11,
    line5: 58.078,
    line6: 60.045,
    line7: 62.012,
  },
  {
    age: 42,
    line1: 50.329,
    line2: 52.298,
    line3: 54.267,
    line4: 56.236,
    line5: 58.205,
    line6: 60.173,
    line7: 62.142,
  },
  {
    age: 43,
    line1: 50.449,
    line2: 52.419,
    line3: 54.39,
    line4: 56.36,
    line5: 58.33,
    line6: 60.301,
    line7: 62.271,
  },
  {
    age: 44,
    line1: 50.566,
    line2: 52.539,
    line3: 54.511,
    line4: 56.483,
    line5: 58.456,
    line6: 60.428,
    line7: 62.4,
  },
  {
    age: 45,
    line1: 50.682,
    line2: 52.657,
    line3: 54.631,
    line4: 56.606,
    line5: 58.58,
    line6: 60.554,
    line7: 62.529,
  },
  {
    age: 46,
    line1: 50.8,
    line2: 52.775,
    line3: 54.751,
    line4: 56.727,
    line5: 58.703,
    line6: 60.678,
    line7: 62.654,
  },
  {
    age: 47,
    line1: 50.914,
    line2: 52.892,
    line3: 54.869,
    line4: 56.847,
    line5: 58.825,
    line6: 60.803,
    line7: 62.78,
  },
  {
    age: 48,
    line1: 51.028,
    line2: 53.007,
    line3: 54.987,
    line4: 56.967,
    line5: 58.946,
    line6: 60.926,
    line7: 62.905,
  },
  {
    age: 49,
    line1: 51.143,
    line2: 53.123,
    line3: 55.104,
    line4: 57.085,
    line5: 59.066,
    line6: 61.047,
    line7: 63.028,
  },
  {
    age: 50,
    line1: 51.255,
    line2: 53.237,
    line3: 55.22,
    line4: 57.203,
    line5: 59.185,
    line6: 61.168,
    line7: 63.151,
  },
  {
    age: 51,
    line1: 51.366,
    line2: 53.35,
    line3: 55.335,
    line4: 57.319,
    line5: 59.304,
    line6: 61.288,
    line7: 63.272,
  },
  {
    age: 52,
    line1: 51.477,
    line2: 53.463,
    line3: 55.449,
    line4: 57.435,
    line5: 59.421,
    line6: 61.407,
    line7: 63.393,
  },
  {
    age: 53,
    line1: 51.586,
    line2: 53.574,
    line3: 55.562,
    line4: 57.55,
    line5: 59.537,
    line6: 61.525,
    line7: 63.513,
  },
  {
    age: 54,
    line1: 51.696,
    line2: 53.685,
    line3: 55.674,
    line4: 57.664,
    line5: 59.653,
    line6: 61.642,
    line7: 63.632,
  },
  {
    age: 55,
    line1: 51.804,
    line2: 53.795,
    line3: 55.786,
    line4: 57.777,
    line5: 59.768,
    line6: 61.759,
    line7: 63.75,
  },
  {
    age: 56,
    line1: 51.911,
    line2: 53.904,
    line3: 55.896,
    line4: 57.889,
    line5: 59.881,
    line6: 61.874,
    line7: 63.867,
  },
  {
    age: 57,
    line1: 52.018,
    line2: 54.012,
    line3: 56.006,
    line4: 58,
    line5: 59.994,
    line6: 61.988,
    line7: 63.982,
  },
  {
    age: 58,
    line1: 52.124,
    line2: 54.12,
    line3: 56.115,
    line4: 58.111,
    line5: 60.106,
    line6: 62.102,
    line7: 64.097,
  },
  {
    age: 59,
    line1: 52.228,
    line2: 54.226,
    line3: 56.223,
    line4: 58.221,
    line5: 60.218,
    line6: 62.216,
    line7: 64.213,
  },
  {
    age: 60,
    line1: 52.333,
    line2: 54.332,
    line3: 56.331,
    line4: 58.33,
    line5: 60.329,
    line6: 62.328,
    line7: 64.327,
  },
  {
    age: 61,
    line1: 52.437,
    line2: 54.438,
    line3: 56.438,
    line4: 58.438,
    line5: 60.439,
    line6: 62.439,
    line7: 64.439,
  },
  {
    age: 62,
    line1: 52.539,
    line2: 54.542,
    line3: 56.544,
    line4: 58.546,
    line5: 60.549,
    line6: 62.551,
    line7: 64.553,
  },
  {
    age: 63,
    line1: 52.643,
    line2: 54.646,
    line3: 56.65,
    line4: 58.654,
    line5: 60.657,
    line6: 62.661,
    line7: 64.664,
  },
  {
    age: 64,
    line1: 52.746,
    line2: 54.75,
    line3: 56.755,
    line4: 58.76,
    line5: 60.765,
    line6: 62.77,
    line7: 64.775,
  },
  {
    age: 65,
    line1: 52.846,
    line2: 54.853,
    line3: 56.86,
    line4: 58.866,
    line5: 60.873,
    line6: 62.88,
    line7: 64.887,
  },
  {
    age: 66,
    line1: 52.948,
    line2: 54.956,
    line3: 56.964,
    line4: 58.972,
    line5: 60.98,
    line6: 62.988,
    line7: 64.996,
  },
  {
    age: 67,
    line1: 53.047,
    line2: 55.057,
    line3: 57.067,
    line4: 59.077,
    line5: 61.086,
    line6: 63.096,
    line7: 65.106,
  },
  {
    age: 68,
    line1: 53.148,
    line2: 55.159,
    line3: 57.17,
    line4: 59.181,
    line5: 61.192,
    line6: 63.203,
    line7: 65.214,
  },
  {
    age: 69,
    line1: 53.246,
    line2: 55.259,
    line3: 57.272,
    line4: 59.284,
    line5: 61.297,
    line6: 63.31,
    line7: 65.322,
  },
  {
    age: 70,
    line1: 53.344,
    line2: 55.358,
    line3: 57.373,
    line4: 59.387,
    line5: 61.402,
    line6: 63.416,
    line7: 65.43,
  },
  {
    age: 71,
    line1: 53.443,
    line2: 55.458,
    line3: 57.474,
    line4: 59.489,
    line5: 61.505,
    line6: 63.52,
    line7: 65.536,
  },
  {
    age: 72,
    line1: 53.54,
    line2: 55.557,
    line3: 57.574,
    line4: 59.591,
    line5: 61.608,
    line6: 63.625,
    line7: 65.642,
  },
  {
    age: 73,
    line1: 53.636,
    line2: 55.654,
    line3: 57.673,
    line4: 59.692,
    line5: 61.711,
    line6: 63.73,
    line7: 65.748,
  },
  {
    age: 74,
    line1: 53.731,
    line2: 55.752,
    line3: 57.772,
    line4: 59.792,
    line5: 61.813,
    line6: 63.833,
    line7: 65.853,
  },
  {
    age: 75,
    line1: 53.828,
    line2: 55.849,
    line3: 57.871,
    line4: 59.892,
    line5: 61.913,
    line6: 63.935,
    line7: 65.956,
  },
  {
    age: 76,
    line1: 53.922,
    line2: 55.945,
    line3: 57.968,
    line4: 59.991,
    line5: 62.014,
    line6: 64.037,
    line7: 66.06,
  },
  {
    age: 77,
    line1: 54.016,
    line2: 56.041,
    line3: 58.065,
    line4: 60.089,
    line5: 62.114,
    line6: 64.138,
    line7: 66.163,
  },
  {
    age: 78,
    line1: 54.109,
    line2: 56.135,
    line3: 58.161,
    line4: 60.187,
    line5: 62.213,
    line6: 64.239,
    line7: 66.265,
  },
  {
    age: 79,
    line1: 54.202,
    line2: 56.23,
    line3: 58.257,
    line4: 60.284,
    line5: 62.312,
    line6: 64.339,
    line7: 66.366,
  },
  {
    age: 80,
    line1: 54.294,
    line2: 56.323,
    line3: 58.352,
    line4: 60.381,
    line5: 62.41,
    line6: 64.438,
    line7: 66.467,
  },
  {
    age: 81,
    line1: 54.386,
    line2: 56.416,
    line3: 58.446,
    line4: 60.477,
    line5: 62.507,
    line6: 64.537,
    line7: 66.567,
  },
  {
    age: 82,
    line1: 54.477,
    line2: 56.509,
    line3: 58.54,
    line4: 60.572,
    line5: 62.603,
    line6: 64.635,
    line7: 66.667,
  },
  {
    age: 83,
    line1: 54.568,
    line2: 56.601,
    line3: 58.634,
    line4: 60.666,
    line5: 62.699,
    line6: 64.732,
    line7: 66.765,
  },
  {
    age: 84,
    line1: 54.658,
    line2: 56.692,
    line3: 58.726,
    line4: 60.76,
    line5: 62.795,
    line6: 64.829,
    line7: 66.863,
  },
  {
    age: 85,
    line1: 54.747,
    line2: 56.783,
    line3: 58.818,
    line4: 60.854,
    line5: 62.889,
    line6: 64.925,
    line7: 66.961,
  },
  {
    age: 86,
    line1: 54.836,
    line2: 56.873,
    line3: 58.91,
    line4: 60.947,
    line5: 62.983,
    line6: 65.02,
    line7: 67.057,
  },
  {
    age: 87,
    line1: 54.923,
    line2: 56.961,
    line3: 59,
    line4: 61.039,
    line5: 63.077,
    line6: 65.116,
    line7: 67.155,
  },
  {
    age: 88,
    line1: 55.011,
    line2: 57.05,
    line3: 59.09,
    line4: 61.13,
    line5: 63.17,
    line6: 65.21,
    line7: 67.25,
  },
  {
    age: 89,
    line1: 55.098,
    line2: 57.139,
    line3: 59.18,
    line4: 61.221,
    line5: 63.262,
    line6: 65.303,
    line7: 67.345,
  },
  {
    age: 90,
    line1: 55.185,
    line2: 57.227,
    line3: 59.269,
    line4: 61.312,
    line5: 63.354,
    line6: 65.396,
    line7: 67.438,
  },
  {
    age: 91,
    line1: 55.269,
    line2: 57.313,
    line3: 59.357,
    line4: 61.401,
    line5: 63.445,
    line6: 65.489,
    line7: 67.533,
  },
  {
    age: 92,
    line1: 55.355,
    line2: 57.4,
    line3: 59.445,
    line4: 61.49,
    line5: 63.536,
    line6: 65.581,
    line7: 67.626,
  },
  {
    age: 93,
    line1: 55.44,
    line2: 57.486,
    line3: 59.533,
    line4: 61.579,
    line5: 63.625,
    line6: 65.672,
    line7: 67.718,
  },
  {
    age: 94,
    line1: 55.523,
    line2: 57.571,
    line3: 59.619,
    line4: 61.667,
    line5: 63.715,
    line6: 65.763,
    line7: 67.811,
  },
  {
    age: 95,
    line1: 55.607,
    line2: 57.656,
    line3: 59.705,
    line4: 61.754,
    line5: 63.803,
    line6: 65.852,
    line7: 67.901,
  },
  {
    age: 96,
    line1: 55.689,
    line2: 57.74,
    line3: 59.79,
    line4: 61.841,
    line5: 63.892,
    line6: 65.942,
    line7: 67.993,
  },
  {
    age: 97,
    line1: 55.772,
    line2: 57.824,
    line3: 59.876,
    line4: 61.927,
    line5: 63.979,
    line6: 66.031,
    line7: 68.082,
  },
  {
    age: 98,
    line1: 55.853,
    line2: 57.906,
    line3: 59.96,
    line4: 62.013,
    line5: 64.066,
    line6: 66.12,
    line7: 68.173,
  },
  {
    age: 99,
    line1: 55.935,
    line2: 57.99,
    line3: 60.044,
    line4: 62.098,
    line5: 64.152,
    line6: 66.207,
    line7: 68.261,
  },
  {
    age: 100,
    line1: 56.015,
    line2: 58.071,
    line3: 60.127,
    line4: 62.183,
    line5: 64.238,
    line6: 66.294,
    line7: 68.35,
  },
  {
    age: 101,
    line1: 56.097,
    line2: 58.153,
    line3: 60.21,
    line4: 62.266,
    line5: 64.323,
    line6: 66.38,
    line7: 68.436,
  },
  {
    age: 102,
    line1: 56.175,
    line2: 58.234,
    line3: 60.292,
    line4: 62.35,
    line5: 64.408,
    line6: 66.466,
    line7: 68.524,
  },
  {
    age: 103,
    line1: 56.256,
    line2: 58.315,
    line3: 60.374,
    line4: 62.433,
    line5: 64.492,
    line6: 66.551,
    line7: 68.61,
  },
  {
    age: 104,
    line1: 56.333,
    line2: 58.394,
    line3: 60.454,
    line4: 62.515,
    line5: 64.575,
    line6: 66.636,
    line7: 68.696,
  },
  {
    age: 105,
    line1: 56.411,
    line2: 58.473,
    line3: 60.535,
    line4: 62.597,
    line5: 64.659,
    line6: 66.72,
    line7: 68.782,
  },
  {
    age: 106,
    line1: 56.49,
    line2: 58.552,
    line3: 60.615,
    line4: 62.678,
    line5: 64.741,
    line6: 66.803,
    line7: 68.866,
  },
  {
    age: 107,
    line1: 56.566,
    line2: 58.63,
    line3: 60.694,
    line4: 62.758,
    line5: 64.823,
    line6: 66.887,
    line7: 68.951,
  },
  {
    age: 108,
    line1: 56.642,
    line2: 58.707,
    line3: 60.773,
    line4: 62.838,
    line5: 64.904,
    line6: 66.969,
    line7: 69.035,
  },
  {
    age: 109,
    line1: 56.719,
    line2: 58.786,
    line3: 60.852,
    line4: 62.918,
    line5: 64.984,
    line6: 67.05,
    line7: 69.117,
  },
  {
    age: 110,
    line1: 56.794,
    line2: 58.862,
    line3: 60.929,
    line4: 62.997,
    line5: 65.064,
    line6: 67.132,
    line7: 69.2,
  },
  {
    age: 111,
    line1: 56.869,
    line2: 58.938,
    line3: 61.007,
    line4: 63.075,
    line5: 65.144,
    line6: 67.213,
    line7: 69.282,
  },
  {
    age: 112,
    line1: 56.943,
    line2: 59.013,
    line3: 61.083,
    line4: 63.153,
    line5: 65.223,
    line6: 67.294,
    line7: 69.364,
  },
  {
    age: 113,
    line1: 57.016,
    line2: 59.088,
    line3: 61.159,
    line4: 63.231,
    line5: 65.302,
    line6: 67.374,
    line7: 69.445,
  },
  {
    age: 114,
    line1: 57.091,
    line2: 59.163,
    line3: 61.236,
    line4: 63.308,
    line5: 65.38,
    line6: 67.452,
    line7: 69.524,
  },
  {
    age: 115,
    line1: 57.164,
    line2: 59.237,
    line3: 61.311,
    line4: 63.384,
    line5: 65.457,
    line6: 67.53,
    line7: 69.604,
  },
  {
    age: 116,
    line1: 57.236,
    line2: 59.311,
    line3: 61.385,
    line4: 63.46,
    line5: 65.534,
    line6: 67.609,
    line7: 69.683,
  },
  {
    age: 117,
    line1: 57.308,
    line2: 59.384,
    line3: 61.459,
    line4: 63.535,
    line5: 65.611,
    line6: 67.686,
    line7: 69.762,
  },
  {
    age: 118,
    line1: 57.379,
    line2: 59.456,
    line3: 61.533,
    line4: 63.61,
    line5: 65.687,
    line6: 67.764,
    line7: 69.84,
  },
  {
    age: 119,
    line1: 57.45,
    line2: 59.528,
    line3: 61.606,
    line4: 63.684,
    line5: 65.762,
    line6: 67.84,
    line7: 69.918,
  },
  {
    age: 120,
    line1: 57.521,
    line2: 59.6,
    line3: 61.679,
    line4: 63.758,
    line5: 65.837,
    line6: 67.916,
    line7: 69.995,
  },
  {
    age: 121,
    line1: 57.591,
    line2: 59.671,
    line3: 61.751,
    line4: 63.831,
    line5: 65.912,
    line6: 67.992,
    line7: 70.072,
  },
  {
    age: 122,
    line1: 57.66,
    line2: 59.741,
    line3: 61.823,
    line4: 63.904,
    line5: 65.985,
    line6: 68.067,
    line7: 70.148,
  },
  {
    age: 123,
    line1: 57.729,
    line2: 59.812,
    line3: 61.894,
    line4: 63.976,
    line5: 66.059,
    line6: 68.141,
    line7: 70.224,
  },
  {
    age: 124,
    line1: 57.798,
    line2: 59.881,
    line3: 61.965,
    line4: 64.048,
    line5: 66.132,
    line6: 68.215,
    line7: 70.299,
  },
  {
    age: 125,
    line1: 57.866,
    line2: 59.951,
    line3: 62.035,
    line4: 64.12,
    line5: 66.204,
    line6: 68.289,
    line7: 70.373,
  },
  {
    age: 126,
    line1: 57.934,
    line2: 60.019,
    line3: 62.105,
    line4: 64.191,
    line5: 66.276,
    line6: 68.362,
    line7: 70.447,
  },
  {
    age: 127,
    line1: 58.001,
    line2: 60.088,
    line3: 62.174,
    line4: 64.261,
    line5: 66.348,
    line6: 68.434,
    line7: 70.521,
  },
  {
    age: 128,
    line1: 58.068,
    line2: 60.156,
    line3: 62.243,
    line4: 64.331,
    line5: 66.419,
    line6: 68.506,
    line7: 70.594,
  },
  {
    age: 129,
    line1: 58.135,
    line2: 60.224,
    line3: 62.312,
    line4: 64.401,
    line5: 66.489,
    line6: 68.578,
    line7: 70.666,
  },
  {
    age: 130,
    line1: 58.201,
    line2: 60.291,
    line3: 62.38,
    line4: 64.47,
    line5: 66.559,
    line6: 68.649,
    line7: 70.738,
  },
  {
    age: 131,
    line1: 58.267,
    line2: 60.358,
    line3: 62.448,
    line4: 64.538,
    line5: 66.629,
    line6: 68.719,
    line7: 70.809,
  },
  {
    age: 132,
    line1: 58.331,
    line2: 60.423,
    line3: 62.515,
    line4: 64.607,
    line5: 66.699,
    line6: 68.791,
    line7: 70.882,
  },
  {
    age: 133,
    line1: 58.396,
    line2: 60.489,
    line3: 62.582,
    line4: 64.674,
    line5: 66.767,
    line6: 68.86,
    line7: 70.953,
  },
  {
    age: 134,
    line1: 58.461,
    line2: 60.554,
    line3: 62.648,
    line4: 64.742,
    line5: 66.836,
    line6: 68.929,
    line7: 71.023,
  },
  {
    age: 135,
    line1: 58.525,
    line2: 60.62,
    line3: 62.714,
    line4: 64.809,
    line5: 66.903,
    line6: 68.998,
    line7: 71.093,
  },
  {
    age: 136,
    line1: 58.589,
    line2: 60.685,
    line3: 62.78,
    line4: 64.876,
    line5: 66.971,
    line6: 69.066,
    line7: 71.162,
  },
  {
    age: 137,
    line1: 58.651,
    line2: 60.748,
    line3: 62.845,
    line4: 64.942,
    line5: 67.039,
    line6: 69.136,
    line7: 71.233,
  },
  {
    age: 138,
    line1: 58.714,
    line2: 60.812,
    line3: 62.91,
    line4: 65.007,
    line5: 67.105,
    line6: 69.203,
    line7: 71.301,
  },
  {
    age: 139,
    line1: 58.777,
    line2: 60.876,
    line3: 62.974,
    line4: 65.073,
    line5: 67.172,
    line6: 69.27,
    line7: 71.369,
  },
  {
    age: 140,
    line1: 58.84,
    line2: 60.939,
    line3: 63.039,
    line4: 65.138,
    line5: 67.237,
    line6: 69.337,
    line7: 71.436,
  },
  {
    age: 141,
    line1: 58.9,
    line2: 61.001,
    line3: 63.102,
    line4: 65.203,
    line5: 67.304,
    line6: 69.404,
    line7: 71.505,
  },
  {
    age: 142,
    line1: 58.962,
    line2: 61.064,
    line3: 63.165,
    line4: 65.267,
    line5: 67.369,
    line6: 69.47,
    line7: 71.572,
  },
  {
    age: 143,
    line1: 59.024,
    line2: 61.126,
    line3: 63.229,
    line4: 65.331,
    line5: 67.433,
    line6: 69.536,
    line7: 71.638,
  },
  {
    age: 144,
    line1: 59.083,
    line2: 61.187,
    line3: 63.291,
    line4: 65.395,
    line5: 67.498,
    line6: 69.602,
    line7: 71.706,
  },
  {
    age: 145,
    line1: 59.144,
    line2: 61.249,
    line3: 63.353,
    line4: 65.458,
    line5: 67.562,
    line6: 69.667,
    line7: 71.771,
  },
  {
    age: 146,
    line1: 59.203,
    line2: 61.309,
    line3: 63.415,
    line4: 65.521,
    line5: 67.627,
    line6: 69.732,
    line7: 71.838,
  },
  {
    age: 147,
    line1: 59.264,
    line2: 61.37,
    line3: 63.477,
    line4: 65.583,
    line5: 67.69,
    line6: 69.796,
    line7: 71.903,
  },
  {
    age: 148,
    line1: 59.324,
    line2: 61.431,
    line3: 63.538,
    line4: 65.646,
    line5: 67.753,
    line6: 69.86,
    line7: 71.967,
  },
  {
    age: 149,
    line1: 59.382,
    line2: 61.49,
    line3: 63.599,
    line4: 65.708,
    line5: 67.816,
    line6: 69.925,
    line7: 72.033,
  },
  {
    age: 150,
    line1: 59.441,
    line2: 61.551,
    line3: 63.66,
    line4: 65.769,
    line5: 67.878,
    line6: 69.987,
    line7: 72.097,
  },
  {
    age: 151,
    line1: 59.499,
    line2: 61.609,
    line3: 63.72,
    line4: 65.83,
    line5: 67.941,
    line6: 70.051,
    line7: 72.162,
  },
  {
    age: 152,
    line1: 59.558,
    line2: 61.669,
    line3: 63.78,
    line4: 65.891,
    line5: 68.002,
    line6: 70.114,
    line7: 72.225,
  },
  {
    age: 153,
    line1: 59.614,
    line2: 61.727,
    line3: 63.839,
    line4: 65.952,
    line5: 68.064,
    line6: 70.177,
    line7: 72.289,
  },
  {
    age: 154,
    line1: 59.673,
    line2: 61.786,
    line3: 63.899,
    line4: 66.012,
    line5: 68.125,
    line6: 70.238,
    line7: 72.351,
  },
  {
    age: 155,
    line1: 59.729,
    line2: 61.843,
    line3: 63.958,
    line4: 66.072,
    line5: 68.186,
    line6: 70.301,
    line7: 72.415,
  },
  {
    age: 156,
    line1: 59.787,
    line2: 61.902,
    line3: 64.017,
    line4: 66.132,
    line5: 68.247,
    line6: 70.361,
    line7: 72.476,
  },
  {
    age: 157,
    line1: 59.843,
    line2: 61.959,
    line3: 64.075,
    line4: 66.191,
    line5: 68.307,
    line6: 70.423,
    line7: 72.539,
  },
  {
    age: 158,
    line1: 59.898,
    line2: 62.015,
    line3: 64.133,
    line4: 66.25,
    line5: 68.368,
    line6: 70.485,
    line7: 72.602,
  },
  {
    age: 159,
    line1: 59.955,
    line2: 62.073,
    line3: 64.191,
    line4: 66.309,
    line5: 68.427,
    line6: 70.545,
    line7: 72.663,
  },
  {
    age: 160,
    line1: 60.01,
    line2: 62.129,
    line3: 64.248,
    line4: 66.367,
    line5: 68.487,
    line6: 70.606,
    line7: 72.725,
  },
  {
    age: 161,
    line1: 60.067,
    line2: 62.186,
    line3: 64.306,
    line4: 66.426,
    line5: 68.545,
    line6: 70.665,
    line7: 72.785,
  },
  {
    age: 162,
    line1: 60.121,
    line2: 62.242,
    line3: 64.363,
    line4: 66.484,
    line5: 68.604,
    line6: 70.725,
    line7: 72.846,
  },
  {
    age: 163,
    line1: 60.175,
    line2: 62.297,
    line3: 64.419,
    line4: 66.541,
    line5: 68.663,
    line6: 70.785,
    line7: 72.907,
  },
  {
    age: 164,
    line1: 60.231,
    line2: 62.354,
    line3: 64.476,
    line4: 66.598,
    line5: 68.721,
    line6: 70.843,
    line7: 72.966,
  },
  {
    age: 165,
    line1: 60.285,
    line2: 62.408,
    line3: 64.532,
    line4: 66.656,
    line5: 68.779,
    line6: 70.903,
    line7: 73.027,
  },
  {
    age: 166,
    line1: 60.338,
    line2: 62.463,
    line3: 64.588,
    line4: 66.712,
    line5: 68.837,
    line6: 70.962,
    line7: 73.087,
  },
  {
    age: 167,
    line1: 60.393,
    line2: 62.519,
    line3: 64.644,
    line4: 66.769,
    line5: 68.894,
    line6: 71.02,
    line7: 73.145,
  },
  {
    age: 168,
    line1: 60.446,
    line2: 62.573,
    line3: 64.699,
    line4: 66.825,
    line5: 68.952,
    line6: 71.078,
    line7: 73.205,
  },
  {
    age: 169,
    line1: 60.499,
    line2: 62.626,
    line3: 64.754,
    line4: 66.882,
    line5: 69.009,
    line6: 71.137,
    line7: 73.264,
  },
  {
    age: 170,
    line1: 60.551,
    line2: 62.68,
    line3: 64.809,
    line4: 66.937,
    line5: 69.066,
    line6: 71.195,
    line7: 73.323,
  },
  {
    age: 171,
    line1: 60.604,
    line2: 62.734,
    line3: 64.863,
    line4: 66.993,
    line5: 69.123,
    line6: 71.252,
    line7: 73.382,
  },
  {
    age: 172,
    line1: 60.658,
    line2: 62.788,
    line3: 64.918,
    line4: 67.048,
    line5: 69.178,
    line6: 71.309,
    line7: 73.439,
  },
  {
    age: 173,
    line1: 60.71,
    line2: 62.841,
    line3: 64.972,
    line4: 67.104,
    line5: 69.235,
    line6: 71.366,
    line7: 73.497,
  },
  {
    age: 174,
    line1: 60.762,
    line2: 62.894,
    line3: 65.026,
    line4: 67.158,
    line5: 69.291,
    line6: 71.423,
    line7: 73.555,
  },
  {
    age: 175,
    line1: 60.813,
    line2: 62.947,
    line3: 65.08,
    line4: 67.213,
    line5: 69.347,
    line6: 71.48,
    line7: 73.613,
  },
  {
    age: 176,
    line1: 60.864,
    line2: 62.999,
    line3: 65.133,
    line4: 67.268,
    line5: 69.402,
    line6: 71.537,
    line7: 73.671,
  },
  {
    age: 177,
    line1: 60.918,
    line2: 63.052,
    line3: 65.187,
    line4: 67.322,
    line5: 69.457,
    line6: 71.591,
    line7: 73.726,
  },
  {
    age: 178,
    line1: 60.969,
    line2: 63.104,
    line3: 65.24,
    line4: 67.376,
    line5: 69.512,
    line6: 71.648,
    line7: 73.783,
  },
  {
    age: 179,
    line1: 61.019,
    line2: 63.156,
    line3: 65.293,
    line4: 67.43,
    line5: 69.567,
    line6: 71.704,
    line7: 73.84,
  },
  {
    age: 180,
    line1: 61.07,
    line2: 63.208,
    line3: 65.346,
    line4: 67.484,
    line5: 69.621,
    line6: 71.759,
    line7: 73.897,
  },
  {
    age: 181,
    line1: 61.12,
    line2: 63.259,
    line3: 65.398,
    line4: 67.537,
    line5: 69.676,
    line6: 71.815,
    line7: 73.954,
  },
  {
    age: 182,
    line1: 61.171,
    line2: 63.311,
    line3: 65.45,
    line4: 67.59,
    line5: 69.73,
    line6: 71.87,
    line7: 74.01,
  },
  {
    age: 183,
    line1: 61.221,
    line2: 63.362,
    line3: 65.503,
    line4: 67.644,
    line5: 69.784,
    line6: 71.925,
    line7: 74.066,
  },
  {
    age: 184,
    line1: 61.271,
    line2: 63.413,
    line3: 65.554,
    line4: 67.696,
    line5: 69.838,
    line6: 71.98,
    line7: 74.122,
  },
  {
    age: 185,
    line1: 61.32,
    line2: 63.463,
    line3: 65.606,
    line4: 67.749,
    line5: 69.892,
    line6: 72.035,
    line7: 74.178,
  },
  {
    age: 186,
    line1: 61.37,
    line2: 63.514,
    line3: 65.658,
    line4: 67.802,
    line5: 69.946,
    line6: 72.089,
    line7: 74.233,
  },
  {
    age: 187,
    line1: 61.419,
    line2: 63.564,
    line3: 65.709,
    line4: 67.854,
    line5: 69.999,
    line6: 72.144,
    line7: 74.289,
  },
  {
    age: 188,
    line1: 61.469,
    line2: 63.615,
    line3: 65.76,
    line4: 67.906,
    line5: 70.052,
    line6: 72.198,
    line7: 74.344,
  },
  {
    age: 189,
    line1: 61.518,
    line2: 63.665,
    line3: 65.811,
    line4: 67.958,
    line5: 70.105,
    line6: 72.252,
    line7: 74.399,
  },
  {
    age: 190,
    line1: 61.567,
    line2: 63.715,
    line3: 65.862,
    line4: 68.01,
    line5: 70.158,
    line6: 72.306,
    line7: 74.453,
  },
  {
    age: 191,
    line1: 61.616,
    line2: 63.764,
    line3: 65.913,
    line4: 68.062,
    line5: 70.211,
    line6: 72.359,
    line7: 74.508,
  },
  {
    age: 192,
    line1: 61.664,
    line2: 63.814,
    line3: 65.964,
    line4: 68.113,
    line5: 70.263,
    line6: 72.413,
    line7: 74.562,
  },
  {
    age: 193,
    line1: 61.713,
    line2: 63.864,
    line3: 66.014,
    line4: 68.165,
    line5: 70.315,
    line6: 72.466,
    line7: 74.616,
  },
  {
    age: 194,
    line1: 61.761,
    line2: 63.913,
    line3: 66.064,
    line4: 68.216,
    line5: 70.367,
    line6: 72.519,
    line7: 74.67,
  },
  {
    age: 195,
    line1: 61.81,
    line2: 63.962,
    line3: 66.114,
    line4: 68.267,
    line5: 70.419,
    line6: 72.572,
    line7: 74.724,
  },
  {
    age: 196,
    line1: 61.858,
    line2: 64.011,
    line3: 66.164,
    line4: 68.318,
    line5: 70.471,
    line6: 72.624,
    line7: 74.778,
  },
  {
    age: 197,
    line1: 61.904,
    line2: 64.059,
    line3: 66.214,
    line4: 68.368,
    line5: 70.523,
    line6: 72.678,
    line7: 74.833,
  },
  {
    age: 198,
    line1: 61.951,
    line2: 64.107,
    line3: 66.263,
    line4: 68.419,
    line5: 70.575,
    line6: 72.731,
    line7: 74.887,
  },
  {
    age: 199,
    line1: 61.999,
    line2: 64.156,
    line3: 66.313,
    line4: 68.47,
    line5: 70.626,
    line6: 72.783,
    line7: 74.94,
  },
  {
    age: 200,
    line1: 62.047,
    line2: 64.204,
    line3: 66.362,
    line4: 68.52,
    line5: 70.677,
    line6: 72.835,
    line7: 74.993,
  },
  {
    age: 201,
    line1: 62.094,
    line2: 64.253,
    line3: 66.411,
    line4: 68.57,
    line5: 70.728,
    line6: 72.887,
    line7: 75.046,
  },
  {
    age: 202,
    line1: 62.141,
    line2: 64.301,
    line3: 66.46,
    line4: 68.62,
    line5: 70.779,
    line6: 72.939,
    line7: 75.098,
  },
  {
    age: 203,
    line1: 62.187,
    line2: 64.348,
    line3: 66.509,
    line4: 68.67,
    line5: 70.831,
    line6: 72.992,
    line7: 75.153,
  },
  {
    age: 204,
    line1: 62.234,
    line2: 64.396,
    line3: 66.558,
    line4: 68.72,
    line5: 70.881,
    line6: 73.043,
    line7: 75.205,
  },
  {
    age: 205,
    line1: 62.281,
    line2: 64.444,
    line3: 66.606,
    line4: 68.769,
    line5: 70.932,
    line6: 73.095,
    line7: 75.257,
  },
  {
    age: 206,
    line1: 62.328,
    line2: 64.491,
    line3: 66.655,
    line4: 68.819,
    line5: 70.982,
    line6: 73.146,
    line7: 75.31,
  },
  {
    age: 207,
    line1: 62.372,
    line2: 64.537,
    line3: 66.703,
    line4: 68.868,
    line5: 71.033,
    line6: 73.198,
    line7: 75.364,
  },
  {
    age: 208,
    line1: 62.419,
    line2: 64.585,
    line3: 66.751,
    line4: 68.917,
    line5: 71.083,
    line6: 73.249,
    line7: 75.415,
  },
  {
    age: 209,
    line1: 62.465,
    line2: 64.632,
    line3: 66.799,
    line4: 68.966,
    line5: 71.133,
    line6: 73.3,
    line7: 75.467,
  },
  {
    age: 210,
    line1: 62.512,
    line2: 64.68,
    line3: 66.847,
    line4: 69.015,
    line5: 71.183,
    line6: 73.351,
    line7: 75.519,
  },
  {
    age: 211,
    line1: 62.556,
    line2: 64.725,
    line3: 66.895,
    line4: 69.064,
    line5: 71.233,
    line6: 73.403,
    line7: 75.572,
  },
  {
    age: 212,
    line1: 62.602,
    line2: 64.773,
    line3: 66.943,
    line4: 69.113,
    line5: 71.283,
    line6: 73.453,
    line7: 75.623,
  },
  {
    age: 213,
    line1: 62.649,
    line2: 64.82,
    line3: 66.991,
    line4: 69.162,
    line5: 71.332,
    line6: 73.503,
    line7: 75.674,
  },
  {
    age: 214,
    line1: 62.692,
    line2: 64.865,
    line3: 67.037,
    line4: 69.21,
    line5: 71.383,
    line6: 73.555,
    line7: 75.728,
  },
  {
    age: 215,
    line1: 62.738,
    line2: 64.912,
    line3: 67.085,
    line4: 69.258,
    line5: 71.432,
    line6: 73.605,
    line7: 75.778,
  },
  {
    age: 216,
    line1: 62.784,
    line2: 64.958,
    line3: 67.133,
    line4: 69.307,
    line5: 71.481,
    line6: 73.655,
    line7: 75.829,
  },
  {
    age: 217,
    line1: 62.828,
    line2: 65.004,
    line3: 67.179,
    line4: 69.355,
    line5: 71.531,
    line6: 73.706,
    line7: 75.882,
  },
  {
    age: 218,
    line1: 62.874,
    line2: 65.05,
    line3: 67.227,
    line4: 69.403,
    line5: 71.58,
    line6: 73.756,
    line7: 75.933,
  },
  {
    age: 219,
    line1: 62.917,
    line2: 65.095,
    line3: 67.273,
    line4: 69.451,
    line5: 71.629,
    line6: 73.807,
    line7: 75.985,
  },
  {
    age: 220,
    line1: 62.963,
    line2: 65.141,
    line3: 67.32,
    line4: 69.499,
    line5: 71.678,
    line6: 73.857,
    line7: 76.035,
  },
  {
    age: 221,
    line1: 63.008,
    line2: 65.188,
    line3: 67.367,
    line4: 69.547,
    line5: 71.726,
    line6: 73.906,
    line7: 76.086,
  },
  {
    age: 222,
    line1: 63.051,
    line2: 65.232,
    line3: 67.413,
    line4: 69.594,
    line5: 71.776,
    line6: 73.957,
    line7: 76.138,
  },
  {
    age: 223,
    line1: 63.096,
    line2: 65.278,
    line3: 67.46,
    line4: 69.642,
    line5: 71.824,
    line6: 74.006,
    line7: 76.188,
  },
  {
    age: 224,
    line1: 63.139,
    line2: 65.323,
    line3: 67.506,
    line4: 69.69,
    line5: 71.873,
    line6: 74.056,
    line7: 76.24,
  },
  {
    age: 225,
    line1: 63.185,
    line2: 65.369,
    line3: 67.553,
    line4: 69.737,
    line5: 71.921,
    line6: 74.105,
    line7: 76.289,
  },
  {
    age: 226,
    line1: 63.227,
    line2: 65.413,
    line3: 67.599,
    line4: 69.784,
    line5: 71.97,
    line6: 74.156,
    line7: 76.341,
  },
  {
    age: 227,
    line1: 63.272,
    line2: 65.459,
    line3: 67.645,
    line4: 69.832,
    line5: 72.018,
    line6: 74.204,
    line7: 76.391,
  },
  {
    age: 228,
    line1: 63.315,
    line2: 65.503,
    line3: 67.691,
    line4: 69.879,
    line5: 72.067,
    line6: 74.255,
    line7: 76.442,
  },
  {
    age: 229,
    line1: 63.36,
    line2: 65.548,
    line3: 67.737,
    line4: 69.926,
    line5: 72.114,
    line6: 74.303,
    line7: 76.492,
  },
  {
    age: 230,
    line1: 63.402,
    line2: 65.593,
    line3: 67.783,
    line4: 69.973,
    line5: 72.163,
    line6: 74.353,
    line7: 76.543,
  },
  {
    age: 231,
    line1: 63.447,
    line2: 65.638,
    line3: 67.829,
    line4: 70.02,
    line5: 72.211,
    line6: 74.402,
    line7: 76.592,
  },
  {
    age: 232,
    line1: 63.489,
    line2: 65.682,
    line3: 67.874,
    line4: 70.066,
    line5: 72.259,
    line6: 74.451,
    line7: 76.644,
  },
  {
    age: 233,
    line1: 63.534,
    line2: 65.727,
    line3: 67.92,
    line4: 70.113,
    line5: 72.306,
    line6: 74.499,
    line7: 76.693,
  },
  {
    age: 234,
    line1: 63.576,
    line2: 65.771,
    line3: 67.965,
    line4: 70.16,
    line5: 72.355,
    line6: 74.549,
    line7: 76.744,
  },
  {
    age: 235,
    line1: 63.62,
    line2: 65.816,
    line3: 68.011,
    line4: 70.206,
    line5: 72.402,
    line6: 74.597,
    line7: 76.792,
  },
  {
    age: 236,
    line1: 63.662,
    line2: 65.859,
    line3: 68.056,
    line4: 70.253,
    line5: 72.45,
    line6: 74.647,
    line7: 76.843,
  },
  {
    age: 237,
    line1: 63.707,
    line2: 65.904,
    line3: 68.102,
    line4: 70.299,
    line5: 72.497,
    line6: 74.695,
    line7: 76.892,
  },
  {
    age: 238,
    line1: 63.749,
    line2: 65.948,
    line3: 68.147,
    line4: 70.346,
    line5: 72.545,
    line6: 74.744,
    line7: 76.943,
  },
  {
    age: 239,
    line1: 63.791,
    line2: 65.991,
    line3: 68.192,
    line4: 70.392,
    line5: 72.592,
    line6: 74.793,
    line7: 76.993,
  },
  {
    age: 240,
    line1: 63.835,
    line2: 66.036,
    line3: 68.237,
    line4: 70.438,
    line5: 72.639,
    line6: 74.841,
    line7: 77.042,
  },
  {
    age: 241,
    line1: 63.876,
    line2: 66.079,
    line3: 68.282,
    line4: 70.484,
    line5: 72.687,
    line6: 74.89,
    line7: 77.092,
  },
  {
    age: 242,
    line1: 63.918,
    line2: 66.122,
    line3: 68.326,
    line4: 70.53,
    line5: 72.734,
    line6: 74.939,
    line7: 77.143,
  },
  {
    age: 243,
    line1: 63.962,
    line2: 66.167,
    line3: 68.372,
    line4: 70.576,
    line5: 72.781,
    line6: 74.986,
    line7: 77.191,
  },
  {
    age: 244,
    line1: 64.004,
    line2: 66.21,
    line3: 68.416,
    line4: 70.622,
    line5: 72.829,
    line6: 75.035,
    line7: 77.241,
  },
  {
    age: 245,
    line1: 64.047,
    line2: 66.254,
    line3: 68.461,
    line4: 70.668,
    line5: 72.875,
    line6: 75.082,
    line7: 77.289,
  },
  {
    age: 246,
    line1: 64.089,
    line2: 66.297,
    line3: 68.506,
    line4: 70.714,
    line5: 72.923,
    line6: 75.131,
    line7: 77.339,
  },
  {
    age: 247,
    line1: 64.13,
    line2: 66.34,
    line3: 68.55,
    line4: 70.76,
    line5: 72.97,
    line6: 75.179,
    line7: 77.389,
  },
  {
    age: 248,
    line1: 64.174,
    line2: 66.384,
    line3: 68.595,
    line4: 70.806,
    line5: 73.016,
    line6: 75.227,
    line7: 77.437,
  },
  {
    age: 249,
    line1: 64.215,
    line2: 66.427,
    line3: 68.639,
    line4: 70.851,
    line5: 73.063,
    line6: 75.275,
    line7: 77.487,
  },
  {
    age: 250,
    line1: 64.257,
    line2: 66.47,
    line3: 68.683,
    line4: 70.897,
    line5: 73.11,
    line6: 75.323,
    line7: 77.537,
  },
  {
    age: 251,
    line1: 64.298,
    line2: 66.513,
    line3: 68.727,
    line4: 70.942,
    line5: 73.157,
    line6: 75.372,
    line7: 77.587,
  },
  {
    age: 252,
    line1: 64.341,
    line2: 66.557,
    line3: 68.772,
    line4: 70.988,
    line5: 73.203,
    line6: 75.419,
    line7: 77.634,
  },
  {
    age: 253,
    line1: 64.382,
    line2: 66.599,
    line3: 68.816,
    line4: 71.033,
    line5: 73.25,
    line6: 75.467,
    line7: 77.684,
  },
  {
    age: 254,
    line1: 64.423,
    line2: 66.642,
    line3: 68.86,
    line4: 71.078,
    line5: 73.297,
    line6: 75.515,
    line7: 77.733,
  },
  {
    age: 255,
    line1: 64.464,
    line2: 66.684,
    line3: 68.904,
    line4: 71.124,
    line5: 73.343,
    line6: 75.563,
    line7: 77.783,
  },
  {
    age: 256,
    line1: 64.507,
    line2: 66.728,
    line3: 68.948,
    line4: 71.169,
    line5: 73.389,
    line6: 75.61,
    line7: 77.83,
  },
  {
    age: 257,
    line1: 64.548,
    line2: 66.77,
    line3: 68.992,
    line4: 71.214,
    line5: 73.436,
    line6: 75.658,
    line7: 77.879,
  },
  {
    age: 258,
    line1: 64.589,
    line2: 66.812,
    line3: 69.036,
    line4: 71.259,
    line5: 73.482,
    line6: 75.705,
    line7: 77.929,
  },
  {
    age: 259,
    line1: 64.63,
    line2: 66.855,
    line3: 69.079,
    line4: 71.304,
    line5: 73.529,
    line6: 75.753,
    line7: 77.978,
  },
  {
    age: 260,
    line1: 64.673,
    line2: 66.898,
    line3: 69.123,
    line4: 71.349,
    line5: 73.574,
    line6: 75.8,
    line7: 78.025,
  },
  {
    age: 261,
    line1: 64.713,
    line2: 66.94,
    line3: 69.167,
    line4: 71.394,
    line5: 73.62,
    line6: 75.847,
    line7: 78.074,
  },
  {
    age: 262,
    line1: 64.754,
    line2: 66.982,
    line3: 69.21,
    line4: 71.438,
    line5: 73.667,
    line6: 75.895,
    line7: 78.123,
  },
  {
    age: 263,
    line1: 64.795,
    line2: 67.024,
    line3: 69.254,
    line4: 71.483,
    line5: 73.713,
    line6: 75.942,
    line7: 78.172,
  },
  {
    age: 264,
    line1: 64.835,
    line2: 67.066,
    line3: 69.297,
    line4: 71.528,
    line5: 73.759,
    line6: 75.99,
    line7: 78.221,
  },
  {
    age: 265,
    line1: 64.878,
    line2: 67.109,
    line3: 69.341,
    line4: 71.572,
    line5: 73.804,
    line6: 76.036,
    line7: 78.267,
  },
  {
    age: 266,
    line1: 64.918,
    line2: 67.151,
    line3: 69.384,
    line4: 71.617,
    line5: 73.85,
    line6: 76.083,
    line7: 78.316,
  },
  {
    age: 267,
    line1: 64.958,
    line2: 67.193,
    line3: 69.427,
    line4: 71.662,
    line5: 73.896,
    line6: 76.13,
    line7: 78.365,
  },
  {
    age: 268,
    line1: 64.999,
    line2: 67.234,
    line3: 69.47,
    line4: 71.706,
    line5: 73.942,
    line6: 76.178,
    line7: 78.413,
  },
  {
    age: 269,
    line1: 65.039,
    line2: 67.276,
    line3: 69.513,
    line4: 71.75,
    line5: 73.988,
    line6: 76.225,
    line7: 78.462,
  },
  {
    age: 270,
    line1: 65.079,
    line2: 67.318,
    line3: 69.556,
    line4: 71.795,
    line5: 74.033,
    line6: 76.272,
    line7: 78.51,
  },
  {
    age: 271,
    line1: 65.119,
    line2: 67.359,
    line3: 69.599,
    line4: 71.839,
    line5: 74.079,
    line6: 76.319,
    line7: 78.559,
  },
  {
    age: 272,
    line1: 65.159,
    line2: 67.401,
    line3: 69.642,
    line4: 71.883,
    line5: 74.125,
    line6: 76.366,
    line7: 78.607,
  },
  {
    age: 273,
    line1: 65.201,
    line2: 67.443,
    line3: 69.685,
    line4: 71.927,
    line5: 74.169,
    line6: 76.411,
    line7: 78.653,
  },
  {
    age: 274,
    line1: 65.241,
    line2: 67.485,
    line3: 69.728,
    line4: 71.971,
    line5: 74.215,
    line6: 76.458,
    line7: 78.701,
  },
  {
    age: 275,
    line1: 65.281,
    line2: 67.526,
    line3: 69.771,
    line4: 72.015,
    line5: 74.26,
    line6: 76.505,
    line7: 78.75,
  },
  {
    age: 276,
    line1: 65.321,
    line2: 67.567,
    line3: 69.813,
    line4: 72.059,
    line5: 74.305,
    line6: 76.552,
    line7: 78.798,
  },
  {
    age: 277,
    line1: 65.361,
    line2: 67.608,
    line3: 69.856,
    line4: 72.103,
    line5: 74.351,
    line6: 76.598,
    line7: 78.846,
  },
  {
    age: 278,
    line1: 65.401,
    line2: 67.65,
    line3: 69.898,
    line4: 72.147,
    line5: 74.396,
    line6: 76.645,
    line7: 78.894,
  },
  {
    age: 279,
    line1: 65.44,
    line2: 67.691,
    line3: 69.941,
    line4: 72.191,
    line5: 74.441,
    line6: 76.691,
    line7: 78.941,
  },
  {
    age: 280,
    line1: 65.48,
    line2: 67.732,
    line3: 69.983,
    line4: 72.235,
    line5: 74.486,
    line6: 76.738,
    line7: 78.989,
  },
  {
    age: 281,
    line1: 65.52,
    line2: 67.772,
    line3: 70.025,
    line4: 72.278,
    line5: 74.531,
    line6: 76.784,
    line7: 79.037,
  },
  {
    age: 282,
    line1: 65.559,
    line2: 67.813,
    line3: 70.068,
    line4: 72.322,
    line5: 74.576,
    line6: 76.83,
    line7: 79.085,
  },
  {
    age: 283,
    line1: 65.599,
    line2: 67.854,
    line3: 70.11,
    line4: 72.366,
    line5: 74.621,
    line6: 76.877,
    line7: 79.132,
  },
  {
    age: 284,
    line1: 65.638,
    line2: 67.895,
    line3: 70.152,
    line4: 72.409,
    line5: 74.666,
    line6: 76.923,
    line7: 79.18,
  },
  {
    age: 285,
    line1: 65.677,
    line2: 67.936,
    line3: 70.194,
    line4: 72.452,
    line5: 74.711,
    line6: 76.969,
    line7: 79.227,
  },
  {
    age: 286,
    line1: 65.717,
    line2: 67.976,
    line3: 70.236,
    line4: 72.496,
    line5: 74.755,
    line6: 77.015,
    line7: 79.275,
  },
  {
    age: 287,
    line1: 65.756,
    line2: 68.017,
    line3: 70.278,
    line4: 72.539,
    line5: 74.8,
    line6: 77.061,
    line7: 79.322,
  },
  {
    age: 288,
    line1: 65.795,
    line2: 68.057,
    line3: 70.32,
    line4: 72.582,
    line5: 74.845,
    line6: 77.107,
    line7: 79.369,
  },
  {
    age: 289,
    line1: 65.834,
    line2: 68.098,
    line3: 70.362,
    line4: 72.625,
    line5: 74.889,
    line6: 77.153,
    line7: 79.416,
  },
  {
    age: 290,
    line1: 65.873,
    line2: 68.138,
    line3: 70.403,
    line4: 72.668,
    line5: 74.933,
    line6: 77.199,
    line7: 79.464,
  },
  {
    age: 291,
    line1: 65.912,
    line2: 68.179,
    line3: 70.445,
    line4: 72.712,
    line5: 74.978,
    line6: 77.244,
    line7: 79.511,
  },
  {
    age: 292,
    line1: 65.951,
    line2: 68.219,
    line3: 70.487,
    line4: 72.754,
    line5: 75.022,
    line6: 77.29,
    line7: 79.558,
  },
  {
    age: 293,
    line1: 65.99,
    line2: 68.259,
    line3: 70.528,
    line4: 72.797,
    line5: 75.066,
    line6: 77.336,
    line7: 79.605,
  },
  {
    age: 294,
    line1: 66.029,
    line2: 68.299,
    line3: 70.57,
    line4: 72.84,
    line5: 75.111,
    line6: 77.381,
    line7: 79.651,
  },
  {
    age: 295,
    line1: 66.068,
    line2: 68.339,
    line3: 70.611,
    line4: 72.883,
    line5: 75.155,
    line6: 77.427,
    line7: 79.698,
  },
  {
    age: 296,
    line1: 66.106,
    line2: 68.38,
    line3: 70.653,
    line4: 72.926,
    line5: 75.199,
    line6: 77.472,
    line7: 79.745,
  },
  {
    age: 297,
    line1: 66.145,
    line2: 68.42,
    line3: 70.694,
    line4: 72.968,
    line5: 75.243,
    line6: 77.517,
    line7: 79.792,
  },
  {
    age: 298,
    line1: 66.184,
    line2: 68.459,
    line3: 70.735,
    line4: 73.011,
    line5: 75.287,
    line6: 77.563,
    line7: 79.838,
  },
  {
    age: 299,
    line1: 66.22,
    line2: 68.498,
    line3: 70.776,
    line4: 73.054,
    line5: 75.331,
    line6: 77.609,
    line7: 79.887,
  },
  {
    age: 300,
    line1: 66.259,
    line2: 68.538,
    line3: 70.817,
    line4: 73.096,
    line5: 75.375,
    line6: 77.654,
    line7: 79.933,
  },
  {
    age: 301,
    line1: 66.297,
    line2: 68.577,
    line3: 70.858,
    line4: 73.138,
    line5: 75.419,
    line6: 77.699,
    line7: 79.98,
  },
  {
    age: 302,
    line1: 66.335,
    line2: 68.617,
    line3: 70.899,
    line4: 73.181,
    line5: 75.463,
    line6: 77.744,
    line7: 80.026,
  },
  {
    age: 303,
    line1: 66.374,
    line2: 68.657,
    line3: 70.94,
    line4: 73.223,
    line5: 75.506,
    line6: 77.789,
    line7: 80.072,
  },
  {
    age: 304,
    line1: 66.412,
    line2: 68.696,
    line3: 70.981,
    line4: 73.265,
    line5: 75.55,
    line6: 77.834,
    line7: 80.119,
  },
  {
    age: 305,
    line1: 66.45,
    line2: 68.736,
    line3: 71.022,
    line4: 73.308,
    line5: 75.593,
    line6: 77.879,
    line7: 80.165,
  },
  {
    age: 306,
    line1: 66.489,
    line2: 68.776,
    line3: 71.063,
    line4: 73.35,
    line5: 75.637,
    line6: 77.924,
    line7: 80.211,
  },
  {
    age: 307,
    line1: 66.524,
    line2: 68.814,
    line3: 71.103,
    line4: 73.392,
    line5: 75.681,
    line6: 77.97,
    line7: 80.259,
  },
  {
    age: 308,
    line1: 66.563,
    line2: 68.853,
    line3: 71.143,
    line4: 73.434,
    line5: 75.724,
    line6: 78.014,
    line7: 80.305,
  },
  {
    age: 309,
    line1: 66.601,
    line2: 68.892,
    line3: 71.184,
    line4: 73.476,
    line5: 75.767,
    line6: 78.059,
    line7: 80.351,
  },
  {
    age: 310,
    line1: 66.639,
    line2: 68.932,
    line3: 71.225,
    line4: 73.518,
    line5: 75.811,
    line6: 78.104,
    line7: 80.397,
  },
  {
    age: 311,
    line1: 66.676,
    line2: 68.971,
    line3: 71.265,
    line4: 73.559,
    line5: 75.854,
    line6: 78.148,
    line7: 80.442,
  },
  {
    age: 312,
    line1: 66.714,
    line2: 69.01,
    line3: 71.306,
    line4: 73.601,
    line5: 75.897,
    line6: 78.192,
    line7: 80.488,
  },
  {
    age: 313,
    line1: 66.75,
    line2: 69.048,
    line3: 71.345,
    line4: 73.643,
    line5: 75.941,
    line6: 78.238,
    line7: 80.536,
  },
  {
    age: 314,
    line1: 66.788,
    line2: 69.087,
    line3: 71.386,
    line4: 73.684,
    line5: 75.983,
    line6: 78.282,
    line7: 80.581,
  },
  {
    age: 315,
    line1: 66.825,
    line2: 69.126,
    line3: 71.426,
    line4: 73.726,
    line5: 76.026,
    line6: 78.327,
    line7: 80.627,
  },
  {
    age: 316,
    line1: 66.863,
    line2: 69.165,
    line3: 71.466,
    line4: 73.768,
    line5: 76.069,
    line6: 78.371,
    line7: 80.672,
  },
  {
    age: 317,
    line1: 66.901,
    line2: 69.203,
    line3: 71.506,
    line4: 73.809,
    line5: 76.112,
    line6: 78.415,
    line7: 80.718,
  },
  {
    age: 318,
    line1: 66.936,
    line2: 69.241,
    line3: 71.546,
    line4: 73.851,
    line5: 76.155,
    line6: 78.46,
    line7: 80.765,
  },
  {
    age: 319,
    line1: 66.973,
    line2: 69.28,
    line3: 71.586,
    line4: 73.892,
    line5: 76.198,
    line6: 78.504,
    line7: 80.81,
  },
  {
    age: 320,
    line1: 67.011,
    line2: 69.318,
    line3: 71.626,
    line4: 73.933,
    line5: 76.241,
    line6: 78.548,
    line7: 80.856,
  },
  {
    age: 321,
    line1: 67.048,
    line2: 69.357,
    line3: 71.666,
    line4: 73.974,
    line5: 76.283,
    line6: 78.592,
    line7: 80.901,
  },
  {
    age: 322,
    line1: 67.083,
    line2: 69.394,
    line3: 71.705,
    line4: 74.016,
    line5: 76.326,
    line6: 78.637,
    line7: 80.948,
  },
  {
    age: 323,
    line1: 67.121,
    line2: 69.433,
    line3: 71.745,
    line4: 74.057,
    line5: 76.369,
    line6: 78.681,
    line7: 80.993,
  },
  {
    age: 324,
    line1: 67.158,
    line2: 69.471,
    line3: 71.785,
    line4: 74.098,
    line5: 76.411,
    line6: 78.725,
    line7: 81.038,
  },
  {
    age: 325,
    line1: 67.195,
    line2: 69.51,
    line3: 71.824,
    line4: 74.139,
    line5: 76.454,
    line6: 78.768,
    line7: 81.083,
  },
  {
    age: 326,
    line1: 67.23,
    line2: 69.547,
    line3: 71.863,
    line4: 74.18,
    line5: 76.497,
    line6: 78.813,
    line7: 81.13,
  },
  {
    age: 327,
    line1: 67.267,
    line2: 69.585,
    line3: 71.903,
    line4: 74.221,
    line5: 76.539,
    line6: 78.857,
    line7: 81.175,
  },
  {
    age: 328,
    line1: 67.304,
    line2: 69.623,
    line3: 71.943,
    line4: 74.262,
    line5: 76.581,
    line6: 78.9,
    line7: 81.219,
  },
  {
    age: 329,
    line1: 67.339,
    line2: 69.66,
    line3: 71.981,
    line4: 74.303,
    line5: 76.624,
    line6: 78.945,
    line7: 81.266,
  },
  {
    age: 330,
    line1: 67.376,
    line2: 69.698,
    line3: 72.021,
    line4: 74.343,
    line5: 76.666,
    line6: 78.988,
    line7: 81.311,
  },
  {
    age: 331,
    line1: 67.413,
    line2: 69.737,
    line3: 72.06,
    line4: 74.384,
    line5: 76.708,
    line6: 79.032,
    line7: 81.355,
  },
  {
    age: 332,
    line1: 67.45,
    line2: 69.775,
    line3: 72.1,
    line4: 74.425,
    line5: 76.75,
    line6: 79.075,
    line7: 81.4,
  },
  {
    age: 333,
    line1: 67.484,
    line2: 69.811,
    line3: 72.138,
    line4: 74.465,
    line5: 76.792,
    line6: 79.119,
    line7: 81.446,
  },
  {
    age: 334,
    line1: 67.521,
    line2: 69.849,
    line3: 72.178,
    line4: 74.506,
    line5: 76.834,
    line6: 79.163,
    line7: 81.491,
  },
  {
    age: 335,
    line1: 67.558,
    line2: 69.887,
    line3: 72.217,
    line4: 74.546,
    line5: 76.876,
    line6: 79.206,
    line7: 81.535,
  },
  {
    age: 336,
    line1: 67.592,
    line2: 69.924,
    line3: 72.255,
    line4: 74.587,
    line5: 76.918,
    line6: 79.25,
    line7: 81.582,
  },
  {
    age: 337,
    line1: 67.629,
    line2: 69.962,
    line3: 72.294,
    line4: 74.627,
    line5: 76.96,
    line6: 79.293,
    line7: 81.626,
  },
  {
    age: 338,
    line1: 67.665,
    line2: 69.999,
    line3: 72.333,
    line4: 74.668,
    line5: 77.002,
    line6: 79.336,
    line7: 81.67,
  },
  {
    age: 339,
    line1: 67.7,
    line2: 70.036,
    line3: 72.372,
    line4: 74.708,
    line5: 77.044,
    line6: 79.38,
    line7: 81.716,
  },
  {
    age: 340,
    line1: 67.736,
    line2: 70.073,
    line3: 72.411,
    line4: 74.748,
    line5: 77.085,
    line6: 79.423,
    line7: 81.76,
  },
  {
    age: 341,
    line1: 67.772,
    line2: 70.111,
    line3: 72.45,
    line4: 74.788,
    line5: 77.127,
    line6: 79.466,
    line7: 81.804,
  },
  {
    age: 342,
    line1: 67.807,
    line2: 70.147,
    line3: 72.488,
    line4: 74.828,
    line5: 77.169,
    line6: 79.51,
    line7: 81.85,
  },
  {
    age: 343,
    line1: 67.843,
    line2: 70.185,
    line3: 72.527,
    line4: 74.869,
    line5: 77.21,
    line6: 79.552,
    line7: 81.894,
  },
  {
    age: 344,
    line1: 67.879,
    line2: 70.222,
    line3: 72.565,
    line4: 74.909,
    line5: 77.252,
    line6: 79.595,
    line7: 81.938,
  },
  {
    age: 345,
    line1: 67.913,
    line2: 70.258,
    line3: 72.603,
    line4: 74.949,
    line5: 77.294,
    line6: 79.639,
    line7: 81.984,
  },
  {
    age: 346,
    line1: 67.949,
    line2: 70.296,
    line3: 72.642,
    line4: 74.989,
    line5: 77.335,
    line6: 79.681,
    line7: 82.028,
  },
  {
    age: 347,
    line1: 67.983,
    line2: 70.332,
    line3: 72.68,
    line4: 75.028,
    line5: 77.377,
    line6: 79.725,
    line7: 82.074,
  },
  {
    age: 348,
    line1: 68.019,
    line2: 70.369,
    line3: 72.719,
    line4: 75.068,
    line5: 77.418,
    line6: 79.768,
    line7: 82.117,
  },
  {
    age: 349,
    line1: 68.055,
    line2: 70.406,
    line3: 72.757,
    line4: 75.108,
    line5: 77.459,
    line6: 79.81,
    line7: 82.161,
  },
  {
    age: 350,
    line1: 68.089,
    line2: 70.442,
    line3: 72.795,
    line4: 75.148,
    line5: 77.501,
    line6: 79.854,
    line7: 82.207,
  },
  {
    age: 351,
    line1: 68.125,
    line2: 70.479,
    line3: 72.833,
    line4: 75.188,
    line5: 77.542,
    line6: 79.896,
    line7: 82.25,
  },
  {
    age: 352,
    line1: 68.159,
    line2: 70.515,
    line3: 72.871,
    line4: 75.227,
    line5: 77.583,
    line6: 79.94,
    line7: 82.296,
  },
  {
    age: 353,
    line1: 68.195,
    line2: 70.552,
    line3: 72.91,
    line4: 75.267,
    line5: 77.624,
    line6: 79.982,
    line7: 82.339,
  },
  {
    age: 354,
    line1: 68.231,
    line2: 70.589,
    line3: 72.948,
    line4: 75.306,
    line5: 77.665,
    line6: 80.024,
    line7: 82.382,
  },
  {
    age: 355,
    line1: 68.264,
    line2: 70.625,
    line3: 72.985,
    line4: 75.346,
    line5: 77.707,
    line6: 80.067,
    line7: 82.428,
  },
  {
    age: 356,
    line1: 68.3,
    line2: 70.662,
    line3: 73.024,
    line4: 75.386,
    line5: 77.747,
    line6: 80.109,
    line7: 82.471,
  },
  {
    age: 357,
    line1: 68.334,
    line2: 70.697,
    line3: 73.061,
    line4: 75.425,
    line5: 77.789,
    line6: 80.153,
    line7: 82.516,
  },
  {
    age: 358,
    line1: 68.369,
    line2: 70.734,
    line3: 73.099,
    line4: 75.464,
    line5: 77.829,
    line6: 80.195,
    line7: 82.56,
  },
  {
    age: 359,
    line1: 68.403,
    line2: 70.77,
    line3: 73.137,
    line4: 75.504,
    line5: 77.871,
    line6: 80.238,
    line7: 82.605,
  },
  {
    age: 360,
    line1: 68.438,
    line2: 70.807,
    line3: 73.175,
    line4: 75.543,
    line5: 77.911,
    line6: 80.28,
    line7: 82.648,
  },
  {
    age: 361,
    line1: 68.472,
    line2: 70.842,
    line3: 73.212,
    line4: 75.582,
    line5: 77.953,
    line6: 80.323,
    line7: 82.693,
  },
  {
    age: 362,
    line1: 68.507,
    line2: 70.879,
    line3: 73.25,
    line4: 75.622,
    line5: 77.993,
    line6: 80.365,
    line7: 82.736,
  },
  {
    age: 363,
    line1: 68.543,
    line2: 70.915,
    line3: 73.288,
    line4: 75.661,
    line5: 78.034,
    line6: 80.406,
    line7: 82.779,
  },
  {
    age: 364,
    line1: 68.576,
    line2: 70.95,
    line3: 73.325,
    line4: 75.7,
    line5: 78.075,
    line6: 80.449,
    line7: 82.824,
  },
  {
    age: 365,
    line1: 68.611,
    line2: 70.987,
    line3: 73.363,
    line4: 75.739,
    line5: 78.115,
    line6: 80.491,
    line7: 82.867,
  },
  {
    age: 366,
    line1: 68.644,
    line2: 71.022,
    line3: 73.4,
    line4: 75.778,
    line5: 78.156,
    line6: 80.534,
    line7: 82.912,
  },
  {
    age: 367,
    line1: 68.68,
    line2: 71.059,
    line3: 73.438,
    line4: 75.817,
    line5: 78.196,
    line6: 80.575,
    line7: 82.955,
  },
  {
    age: 368,
    line1: 68.713,
    line2: 71.094,
    line3: 73.475,
    line4: 75.856,
    line5: 78.237,
    line6: 80.618,
    line7: 83,
  },
  {
    age: 369,
    line1: 68.748,
    line2: 71.13,
    line3: 73.513,
    line4: 75.895,
    line5: 78.277,
    line6: 80.66,
    line7: 83.042,
  },
  {
    age: 370,
    line1: 68.781,
    line2: 71.165,
    line3: 73.55,
    line4: 75.934,
    line5: 78.318,
    line6: 80.703,
    line7: 83.087,
  },
  {
    age: 371,
    line1: 68.816,
    line2: 71.202,
    line3: 73.587,
    line4: 75.973,
    line5: 78.358,
    line6: 80.744,
    line7: 83.13,
  },
  {
    age: 372,
    line1: 68.849,
    line2: 71.237,
    line3: 73.624,
    line4: 76.012,
    line5: 78.399,
    line6: 80.787,
    line7: 83.174,
  },
  {
    age: 373,
    line1: 68.884,
    line2: 71.273,
    line3: 73.662,
    line4: 76.05,
    line5: 78.439,
    line6: 80.828,
    line7: 83.217,
  },
  {
    age: 374,
    line1: 68.917,
    line2: 71.308,
    line3: 73.698,
    line4: 76.089,
    line5: 78.48,
    line6: 80.871,
    line7: 83.261,
  },
  {
    age: 375,
    line1: 68.952,
    line2: 71.344,
    line3: 73.736,
    line4: 76.128,
    line5: 78.52,
    line6: 80.912,
    line7: 83.304,
  },
  {
    age: 376,
    line1: 68.985,
    line2: 71.379,
    line3: 73.773,
    line4: 76.166,
    line5: 78.56,
    line6: 80.954,
    line7: 83.348,
  },
  {
    age: 377,
    line1: 69.02,
    line2: 71.415,
    line3: 73.81,
    line4: 76.205,
    line5: 78.6,
    line6: 80.995,
    line7: 83.39,
  },
  {
    age: 378,
    line1: 69.052,
    line2: 71.449,
    line3: 73.847,
    line4: 76.244,
    line5: 78.641,
    line6: 81.038,
    line7: 83.435,
  },
  {
    age: 379,
    line1: 69.087,
    line2: 71.486,
    line3: 73.884,
    line4: 76.282,
    line5: 78.681,
    line6: 81.079,
    line7: 83.477,
  },
  {
    age: 380,
    line1: 69.12,
    line2: 71.52,
    line3: 73.92,
    line4: 76.321,
    line5: 78.721,
    line6: 81.121,
    line7: 83.522,
  },
  {
    age: 381,
    line1: 69.152,
    line2: 71.555,
    line3: 73.957,
    line4: 76.359,
    line5: 78.761,
    line6: 81.164,
    line7: 83.566,
  },
  {
    age: 382,
    line1: 69.187,
    line2: 71.591,
    line3: 73.994,
    line4: 76.398,
    line5: 78.801,
    line6: 81.204,
    line7: 83.608,
  },
  {
    age: 383,
    line1: 69.219,
    line2: 71.625,
    line3: 74.03,
    line4: 76.436,
    line5: 78.841,
    line6: 81.247,
    line7: 83.652,
  },
  {
    age: 384,
    line1: 69.254,
    line2: 71.661,
    line3: 74.067,
    line4: 76.474,
    line5: 78.881,
    line6: 81.287,
    line7: 83.694,
  },
  {
    age: 385,
    line1: 69.287,
    line2: 71.695,
    line3: 74.104,
    line4: 76.512,
    line5: 78.921,
    line6: 81.33,
    line7: 83.738,
  },
  {
    age: 386,
    line1: 69.321,
    line2: 71.731,
    line3: 74.141,
    line4: 76.551,
    line5: 78.96,
    line6: 81.37,
    line7: 83.78,
  },
  {
    age: 387,
    line1: 69.353,
    line2: 71.765,
    line3: 74.177,
    line4: 76.589,
    line5: 79.001,
    line6: 81.412,
    line7: 83.824,
  },
  {
    age: 388,
    line1: 69.388,
    line2: 71.801,
    line3: 74.214,
    line4: 76.627,
    line5: 79.04,
    line6: 81.453,
    line7: 83.866,
  },
  {
    age: 389,
    line1: 69.42,
    line2: 71.835,
    line3: 74.25,
    line4: 76.665,
    line5: 79.08,
    line6: 81.495,
    line7: 83.91,
  },
  {
    age: 390,
    line1: 69.452,
    line2: 71.869,
    line3: 74.286,
    line4: 76.703,
    line5: 79.12,
    line6: 81.537,
    line7: 83.954,
  },
  {
    age: 391,
    line1: 69.487,
    line2: 71.905,
    line3: 74.323,
    line4: 76.741,
    line5: 79.159,
    line6: 81.577,
    line7: 83.995,
  },
  {
    age: 392,
    line1: 69.519,
    line2: 71.939,
    line3: 74.359,
    line4: 76.779,
    line5: 79.199,
    line6: 81.619,
    line7: 84.039,
  },
  {
    age: 393,
    line1: 69.553,
    line2: 71.974,
    line3: 74.396,
    line4: 76.817,
    line5: 79.238,
    line6: 81.659,
    line7: 84.081,
  },
  {
    age: 394,
    line1: 69.585,
    line2: 72.008,
    line3: 74.432,
    line4: 76.855,
    line5: 79.278,
    line6: 81.701,
    line7: 84.124,
  },
  {
    age: 395,
    line1: 69.617,
    line2: 72.042,
    line3: 74.467,
    line4: 76.893,
    line5: 79.318,
    line6: 81.743,
    line7: 84.168,
  },
  {
    age: 396,
    line1: 69.651,
    line2: 72.078,
    line3: 74.504,
    line4: 76.93,
    line5: 79.357,
    line6: 81.783,
    line7: 84.21,
  },
  {
    age: 397,
    line1: 69.683,
    line2: 72.112,
    line3: 74.54,
    line4: 76.968,
    line5: 79.397,
    line6: 81.825,
    line7: 84.253,
  },
  {
    age: 398,
    line1: 69.717,
    line2: 72.147,
    line3: 74.576,
    line4: 77.006,
    line5: 79.435,
    line6: 81.865,
    line7: 84.295,
  },
  {
    age: 399,
    line1: 69.749,
    line2: 72.181,
    line3: 74.612,
    line4: 77.044,
    line5: 79.475,
    line6: 81.906,
    line7: 84.338,
  },
  {
    age: 400,
    line1: 69.781,
    line2: 72.214,
    line3: 74.648,
    line4: 77.081,
    line5: 79.515,
    line6: 81.948,
    line7: 84.382,
  },
  {
    age: 401,
    line1: 69.815,
    line2: 72.249,
    line3: 74.684,
    line4: 77.119,
    line5: 79.553,
    line6: 81.988,
    line7: 84.423,
  },
  {
    age: 402,
    line1: 69.847,
    line2: 72.283,
    line3: 74.72,
    line4: 77.156,
    line5: 79.593,
    line6: 82.029,
    line7: 84.466,
  },
  {
    age: 403,
    line1: 69.878,
    line2: 72.317,
    line3: 74.755,
    line4: 77.194,
    line5: 79.632,
    line6: 82.071,
    line7: 84.509,
  },
  {
    age: 404,
    line1: 69.912,
    line2: 72.352,
    line3: 74.792,
    line4: 77.231,
    line5: 79.671,
    line6: 82.111,
    line7: 84.551,
  },
  {
    age: 405,
    line1: 69.944,
    line2: 72.385,
    line3: 74.827,
    line4: 77.269,
    line5: 79.71,
    line6: 82.152,
    line7: 84.594,
  },
  {
    age: 406,
    line1: 69.977,
    line2: 72.42,
    line3: 74.863,
    line4: 77.306,
    line5: 79.749,
    line6: 82.192,
    line7: 84.635,
  },
  {
    age: 407,
    line1: 70.009,
    line2: 72.454,
    line3: 74.899,
    line4: 77.343,
    line5: 79.788,
    line6: 82.233,
    line7: 84.678,
  },
  {
    age: 408,
    line1: 70.04,
    line2: 72.487,
    line3: 74.934,
    line4: 77.381,
    line5: 79.827,
    line6: 82.274,
    line7: 84.721,
  },
  {
    age: 409,
    line1: 70.074,
    line2: 72.522,
    line3: 74.97,
    line4: 77.418,
    line5: 79.866,
    line6: 82.314,
    line7: 84.762,
  },
  {
    age: 410,
    line1: 70.105,
    line2: 72.555,
    line3: 75.005,
    line4: 77.455,
    line5: 79.905,
    line6: 82.355,
    line7: 84.805,
  },
  {
    age: 411,
    line1: 70.137,
    line2: 72.589,
    line3: 75.04,
    line4: 77.492,
    line5: 79.944,
    line6: 82.396,
    line7: 84.848,
  },
  {
    age: 412,
    line1: 70.17,
    line2: 72.623,
    line3: 75.076,
    line4: 77.53,
    line5: 79.983,
    line6: 82.436,
    line7: 84.889,
  },
  {
    age: 413,
    line1: 70.202,
    line2: 72.657,
    line3: 75.112,
    line4: 77.566,
    line5: 80.021,
    line6: 82.476,
    line7: 84.931,
  },
  {
    age: 414,
    line1: 70.233,
    line2: 72.69,
    line3: 75.147,
    line4: 77.604,
    line5: 80.061,
    line6: 82.517,
    line7: 84.974,
  },
  {
    age: 415,
    line1: 70.266,
    line2: 72.724,
    line3: 75.182,
    line4: 77.641,
    line5: 80.099,
    line6: 82.557,
    line7: 85.015,
  },
  {
    age: 416,
    line1: 70.297,
    line2: 72.758,
    line3: 75.218,
    line4: 77.678,
    line5: 80.138,
    line6: 82.598,
    line7: 85.058,
  },
  {
    age: 417,
    line1: 70.329,
    line2: 72.791,
    line3: 75.253,
    line4: 77.714,
    line5: 80.176,
    line6: 82.638,
    line7: 85.1,
  },
  {
    age: 418,
    line1: 70.362,
    line2: 72.825,
    line3: 75.288,
    line4: 77.751,
    line5: 80.215,
    line6: 82.678,
    line7: 85.141,
  },
  {
    age: 419,
    line1: 70.393,
    line2: 72.858,
    line3: 75.323,
    line4: 77.788,
    line5: 80.253,
    line6: 82.719,
    line7: 85.184,
  },
  {
    age: 420,
    line1: 70.424,
    line2: 72.891,
    line3: 75.358,
    line4: 77.825,
    line5: 80.292,
    line6: 82.759,
    line7: 85.226,
  },
  {
    age: 421,
    line1: 70.457,
    line2: 72.925,
    line3: 75.394,
    line4: 77.862,
    line5: 80.33,
    line6: 82.798,
    line7: 85.266,
  },
  {
    age: 422,
    line1: 70.488,
    line2: 72.958,
    line3: 75.428,
    line4: 77.899,
    line5: 80.369,
    line6: 82.839,
    line7: 85.309,
  },
  {
    age: 423,
    line1: 70.519,
    line2: 72.991,
    line3: 75.463,
    line4: 77.935,
    line5: 80.407,
    line6: 82.88,
    line7: 85.352,
  },
  {
    age: 424,
    line1: 70.552,
    line2: 73.025,
    line3: 75.499,
    line4: 77.972,
    line5: 80.445,
    line6: 82.918,
    line7: 85.392,
  },
  {
    age: 425,
    line1: 70.583,
    line2: 73.058,
    line3: 75.533,
    line4: 78.008,
    line5: 80.484,
    line6: 82.959,
    line7: 85.434,
  },
  {
    age: 426,
    line1: 70.614,
    line2: 73.091,
    line3: 75.568,
    line4: 78.045,
    line5: 80.522,
    line6: 82.999,
    line7: 85.477,
  },
  {
    age: 427,
    line1: 70.644,
    line2: 73.124,
    line3: 75.603,
    line4: 78.082,
    line5: 80.561,
    line6: 83.04,
    line7: 85.519,
  },
  {
    age: 428,
    line1: 70.677,
    line2: 73.158,
    line3: 75.638,
    line4: 78.118,
    line5: 80.598,
    line6: 83.079,
    line7: 85.559,
  },
  {
    age: 429,
    line1: 70.708,
    line2: 73.19,
    line3: 75.672,
    line4: 78.155,
    line5: 80.637,
    line6: 83.119,
    line7: 85.601,
  },
  {
    age: 430,
    line1: 70.739,
    line2: 73.223,
    line3: 75.707,
    line4: 78.191,
    line5: 80.675,
    line6: 83.159,
    line7: 85.643,
  },
  {
    age: 431,
    line1: 70.772,
    line2: 73.257,
    line3: 75.742,
    line4: 78.228,
    line5: 80.713,
    line6: 83.198,
    line7: 85.683,
  },
  {
    age: 432,
    line1: 70.802,
    line2: 73.289,
    line3: 75.777,
    line4: 78.264,
    line5: 80.751,
    line6: 83.238,
    line7: 85.725,
  },
  {
    age: 433,
    line1: 70.833,
    line2: 73.322,
    line3: 75.811,
    line4: 78.3,
    line5: 80.789,
    line6: 83.278,
    line7: 85.768,
  },
  {
    age: 434,
    line1: 70.863,
    line2: 73.354,
    line3: 75.845,
    line4: 78.336,
    line5: 80.827,
    line6: 83.319,
    line7: 85.81,
  },
  {
    age: 435,
    line1: 70.896,
    line2: 73.388,
    line3: 75.88,
    line4: 78.373,
    line5: 80.865,
    line6: 83.357,
    line7: 85.849,
  },
  {
    age: 436,
    line1: 70.926,
    line2: 73.421,
    line3: 75.915,
    line4: 78.409,
    line5: 80.903,
    line6: 83.397,
    line7: 85.891,
  },
  {
    age: 437,
    line1: 70.957,
    line2: 73.453,
    line3: 75.949,
    line4: 78.445,
    line5: 80.941,
    line6: 83.437,
    line7: 85.933,
  },
  {
    age: 438,
    line1: 70.987,
    line2: 73.485,
    line3: 75.983,
    line4: 78.481,
    line5: 80.979,
    line6: 83.477,
    line7: 85.975,
  },
  {
    age: 439,
    line1: 71.02,
    line2: 73.519,
    line3: 76.018,
    line4: 78.517,
    line5: 81.017,
    line6: 83.516,
    line7: 86.015,
  },
  {
    age: 440,
    line1: 71.05,
    line2: 73.551,
    line3: 76.052,
    line4: 78.553,
    line5: 81.055,
    line6: 83.556,
    line7: 86.057,
  },
  {
    age: 441,
    line1: 71.08,
    line2: 73.583,
    line3: 76.086,
    line4: 78.589,
    line5: 81.092,
    line6: 83.596,
    line7: 86.099,
  },
  {
    age: 442,
    line1: 71.11,
    line2: 73.615,
    line3: 76.12,
    line4: 78.625,
    line5: 81.13,
    line6: 83.635,
    line7: 86.14,
  },
  {
    age: 443,
    line1: 71.143,
    line2: 73.649,
    line3: 76.155,
    line4: 78.661,
    line5: 81.168,
    line6: 83.674,
    line7: 86.18,
  },
  {
    age: 444,
    line1: 71.173,
    line2: 73.681,
    line3: 76.189,
    line4: 78.697,
    line5: 81.205,
    line6: 83.713,
    line7: 86.222,
  },
  {
    age: 445,
    line1: 71.203,
    line2: 73.713,
    line3: 76.223,
    line4: 78.733,
    line5: 81.243,
    line6: 83.753,
    line7: 86.263,
  },
  {
    age: 446,
    line1: 71.233,
    line2: 73.745,
    line3: 76.257,
    line4: 78.769,
    line5: 81.281,
    line6: 83.793,
    line7: 86.305,
  },
  {
    age: 447,
    line1: 71.266,
    line2: 73.779,
    line3: 76.292,
    line4: 78.805,
    line5: 81.318,
    line6: 83.831,
    line7: 86.344,
  },
  {
    age: 448,
    line1: 71.296,
    line2: 73.811,
    line3: 76.326,
    line4: 78.841,
    line5: 81.356,
    line6: 83.871,
    line7: 86.386,
  },
  {
    age: 449,
    line1: 71.326,
    line2: 73.843,
    line3: 76.359,
    line4: 78.876,
    line5: 81.393,
    line6: 83.91,
    line7: 86.427,
  },
  {
    age: 450,
    line1: 71.356,
    line2: 73.874,
    line3: 76.393,
    line4: 78.912,
    line5: 81.431,
    line6: 83.95,
    line7: 86.469,
  },
  {
    age: 451,
    line1: 71.388,
    line2: 73.908,
    line3: 76.428,
    line4: 78.948,
    line5: 81.468,
    line6: 83.988,
    line7: 86.508,
  },
  {
    age: 452,
    line1: 71.418,
    line2: 73.94,
    line3: 76.462,
    line4: 78.984,
    line5: 81.505,
    line6: 84.027,
    line7: 86.549,
  },
  {
    age: 453,
    line1: 71.447,
    line2: 73.971,
    line3: 76.495,
    line4: 79.019,
    line5: 81.543,
    line6: 84.067,
    line7: 86.591,
  },
  {
    age: 454,
    line1: 71.477,
    line2: 74.003,
    line3: 76.529,
    line4: 79.055,
    line5: 81.58,
    line6: 84.106,
    line7: 86.632,
  },
  {
    age: 455,
    line1: 71.507,
    line2: 74.035,
    line3: 76.563,
    line4: 79.09,
    line5: 81.618,
    line6: 84.146,
    line7: 86.673,
  },
  {
    age: 456,
    line1: 71.539,
    line2: 74.068,
    line3: 76.597,
    line4: 79.126,
    line5: 81.655,
    line6: 84.184,
    line7: 86.712,
  },
  {
    age: 457,
    line1: 71.569,
    line2: 74.1,
    line3: 76.631,
    line4: 79.161,
    line5: 81.692,
    line6: 84.223,
    line7: 86.754,
  },
  {
    age: 458,
    line1: 71.599,
    line2: 74.131,
    line3: 76.664,
    line4: 79.197,
    line5: 81.73,
    line6: 84.262,
    line7: 86.795,
  },
  {
    age: 459,
    line1: 71.628,
    line2: 74.163,
    line3: 76.698,
    line4: 79.232,
    line5: 81.767,
    line6: 84.301,
    line7: 86.836,
  },
  {
    age: 460,
    line1: 71.658,
    line2: 74.194,
    line3: 76.731,
    line4: 79.268,
    line5: 81.804,
    line6: 84.341,
    line7: 86.877,
  },
  {
    age: 461,
    line1: 71.69,
    line2: 74.228,
    line3: 76.765,
    line4: 79.303,
    line5: 81.841,
    line6: 84.378,
    line7: 86.916,
  },
  {
    age: 462,
    line1: 71.719,
    line2: 74.259,
    line3: 76.799,
    line4: 79.338,
    line5: 81.878,
    line6: 84.418,
    line7: 86.957,
  },
  {
    age: 463,
    line1: 71.749,
    line2: 74.291,
    line3: 76.832,
    line4: 79.374,
    line5: 81.915,
    line6: 84.457,
    line7: 86.998,
  },
  {
    age: 464,
    line1: 71.778,
    line2: 74.322,
    line3: 76.865,
    line4: 79.409,
    line5: 81.952,
    line6: 84.496,
    line7: 87.039,
  },
  {
    age: 465,
    line1: 71.808,
    line2: 74.353,
    line3: 76.899,
    line4: 79.444,
    line5: 81.989,
    line6: 84.535,
    line7: 87.08,
  },
  {
    age: 466,
    line1: 71.84,
    line2: 74.386,
    line3: 76.933,
    line4: 79.479,
    line5: 82.026,
    line6: 84.572,
    line7: 87.119,
  },
  {
    age: 467,
    line1: 71.869,
    line2: 74.418,
    line3: 76.966,
    line4: 79.514,
    line5: 82.063,
    line6: 84.611,
    line7: 87.16,
  },
  {
    age: 468,
    line1: 71.899,
    line2: 74.449,
    line3: 76.999,
    line4: 79.55,
    line5: 82.1,
    line6: 84.65,
    line7: 87.201,
  },
  {
    age: 469,
    line1: 71.928,
    line2: 74.48,
    line3: 77.032,
    line4: 79.585,
    line5: 82.137,
    line6: 84.689,
    line7: 87.242,
  },
  {
    age: 470,
    line1: 71.957,
    line2: 74.511,
    line3: 77.066,
    line4: 79.62,
    line5: 82.174,
    line6: 84.728,
    line7: 87.282,
  },
  {
    age: 471,
    line1: 71.986,
    line2: 74.543,
    line3: 77.099,
    line4: 79.655,
    line5: 82.211,
    line6: 84.767,
    line7: 87.323,
  },
  {
    age: 472,
    line1: 72.018,
    line2: 74.575,
    line3: 77.133,
    line4: 79.69,
    line5: 82.247,
    line6: 84.804,
    line7: 87.362,
  },
  {
    age: 473,
    line1: 72.047,
    line2: 74.606,
    line3: 77.166,
    line4: 79.725,
    line5: 82.284,
    line6: 84.843,
    line7: 87.402,
  },
  {
    age: 474,
    line1: 72.077,
    line2: 74.638,
    line3: 77.199,
    line4: 79.76,
    line5: 82.321,
    line6: 84.882,
    line7: 87.443,
  },
  {
    age: 475,
    line1: 72.106,
    line2: 74.669,
    line3: 77.232,
    line4: 79.795,
    line5: 82.358,
    line6: 84.921,
    line7: 87.484,
  },
  {
    age: 476,
    line1: 72.135,
    line2: 74.7,
    line3: 77.265,
    line4: 79.83,
    line5: 82.395,
    line6: 84.959,
    line7: 87.524,
  },
  {
    age: 477,
    line1: 72.164,
    line2: 74.731,
    line3: 77.298,
    line4: 79.864,
    line5: 82.431,
    line6: 84.998,
    line7: 87.565,
  },
  {
    age: 478,
    line1: 72.195,
    line2: 74.763,
    line3: 77.331,
    line4: 79.899,
    line5: 82.467,
    line6: 85.035,
    line7: 87.603,
  },
  {
    age: 479,
    line1: 72.224,
    line2: 74.794,
    line3: 77.364,
    line4: 79.934,
    line5: 82.504,
    line6: 85.074,
    line7: 87.644,
  },
  {
    age: 480,
    line1: 72.253,
    line2: 74.825,
    line3: 77.397,
    line4: 79.969,
    line5: 82.541,
    line6: 85.112,
    line7: 87.684,
  },
  {
    age: 481,
    line1: 72.282,
    line2: 74.856,
    line3: 77.43,
    line4: 80.004,
    line5: 82.577,
    line6: 85.151,
    line7: 87.725,
  },
  {
    age: 482,
    line1: 72.311,
    line2: 74.887,
    line3: 77.463,
    line4: 80.038,
    line5: 82.614,
    line6: 85.19,
    line7: 87.765,
  },
  {
    age: 483,
    line1: 72.34,
    line2: 74.918,
    line3: 77.495,
    line4: 80.073,
    line5: 82.65,
    line6: 85.228,
    line7: 87.806,
  },
  {
    age: 484,
    line1: 72.369,
    line2: 74.949,
    line3: 77.528,
    line4: 80.108,
    line5: 82.687,
    line6: 85.267,
    line7: 87.846,
  },
  {
    age: 485,
    line1: 72.4,
    line2: 74.981,
    line3: 77.562,
    line4: 80.142,
    line5: 82.723,
    line6: 85.303,
    line7: 87.884,
  },
  {
    age: 486,
    line1: 72.429,
    line2: 75.012,
    line3: 77.594,
    line4: 80.177,
    line5: 82.759,
    line6: 85.342,
    line7: 87.924,
  },
  {
    age: 487,
    line1: 72.458,
    line2: 75.042,
    line3: 77.627,
    line4: 80.211,
    line5: 82.796,
    line6: 85.38,
    line7: 87.965,
  },
  {
    age: 488,
    line1: 72.487,
    line2: 75.073,
    line3: 77.66,
    line4: 80.246,
    line5: 82.832,
    line6: 85.419,
    line7: 88.005,
  },
  {
    age: 489,
    line1: 72.516,
    line2: 75.104,
    line3: 77.692,
    line4: 80.28,
    line5: 82.869,
    line6: 85.457,
    line7: 88.045,
  },
  {
    age: 490,
    line1: 72.544,
    line2: 75.134,
    line3: 77.725,
    line4: 80.315,
    line5: 82.905,
    line6: 85.495,
    line7: 88.085,
  },
  {
    age: 491,
    line1: 72.573,
    line2: 75.165,
    line3: 77.757,
    line4: 80.349,
    line5: 82.941,
    line6: 85.533,
    line7: 88.126,
  },
  {
    age: 492,
    line1: 72.604,
    line2: 75.197,
    line3: 77.791,
    line4: 80.384,
    line5: 82.977,
    line6: 85.57,
    line7: 88.163,
  },
  {
    age: 493,
    line1: 72.633,
    line2: 75.228,
    line3: 77.823,
    line4: 80.418,
    line5: 83.013,
    line6: 85.608,
    line7: 88.203,
  },
  {
    age: 494,
    line1: 72.661,
    line2: 75.258,
    line3: 77.855,
    line4: 80.452,
    line5: 83.049,
    line6: 85.646,
    line7: 88.243,
  },
  {
    age: 495,
    line1: 72.69,
    line2: 75.289,
    line3: 77.888,
    line4: 80.487,
    line5: 83.086,
    line6: 85.685,
    line7: 88.283,
  },
  {
    age: 496,
    line1: 72.719,
    line2: 75.319,
    line3: 77.92,
    line4: 80.521,
    line5: 83.122,
    line6: 85.723,
    line7: 88.323,
  },
  {
    age: 497,
    line1: 72.747,
    line2: 75.35,
    line3: 77.953,
    line4: 80.555,
    line5: 83.158,
    line6: 85.761,
    line7: 88.364,
  },
  {
    age: 498,
    line1: 72.776,
    line2: 75.38,
    line3: 77.985,
    line4: 80.59,
    line5: 83.194,
    line6: 85.799,
    line7: 88.403,
  },
  {
    age: 499,
    line1: 72.804,
    line2: 75.411,
    line3: 78.017,
    line4: 80.624,
    line5: 83.23,
    line6: 85.837,
    line7: 88.443,
  },
  {
    age: 500,
    line1: 72.832,
    line2: 75.441,
    line3: 78.049,
    line4: 80.658,
    line5: 83.266,
    line6: 85.875,
    line7: 88.483,
  },
  {
    age: 501,
    line1: 72.863,
    line2: 75.473,
    line3: 78.082,
    line4: 80.692,
    line5: 83.302,
    line6: 85.911,
    line7: 88.521,
  },
  {
    age: 502,
    line1: 72.892,
    line2: 75.503,
    line3: 78.115,
    line4: 80.726,
    line5: 83.338,
    line6: 85.949,
    line7: 88.561,
  },
  {
    age: 503,
    line1: 72.92,
    line2: 75.533,
    line3: 78.147,
    line4: 80.76,
    line5: 83.374,
    line6: 85.987,
    line7: 88.6,
  },
  {
    age: 504,
    line1: 72.948,
    line2: 75.564,
    line3: 78.179,
    line4: 80.794,
    line5: 83.41,
    line6: 86.025,
    line7: 88.64,
  },
  {
    age: 505,
    line1: 72.977,
    line2: 75.594,
    line3: 78.211,
    line4: 80.828,
    line5: 83.445,
    line6: 86.063,
    line7: 88.68,
  },
  {
    age: 506,
    line1: 73.005,
    line2: 75.624,
    line3: 78.243,
    line4: 80.862,
    line5: 83.481,
    line6: 86.1,
    line7: 88.72,
  },
  {
    age: 507,
    line1: 73.033,
    line2: 75.654,
    line3: 78.275,
    line4: 80.896,
    line5: 83.517,
    line6: 86.138,
    line7: 88.759,
  },
  {
    age: 508,
    line1: 73.061,
    line2: 75.684,
    line3: 78.307,
    line4: 80.93,
    line5: 83.553,
    line6: 86.176,
    line7: 88.799,
  },
  {
    age: 509,
    line1: 73.089,
    line2: 75.714,
    line3: 78.339,
    line4: 80.964,
    line5: 83.589,
    line6: 86.214,
    line7: 88.839,
  },
  {
    age: 510,
    line1: 73.118,
    line2: 75.744,
    line3: 78.371,
    line4: 80.998,
    line5: 83.625,
    line6: 86.251,
    line7: 88.878,
  },
  {
    age: 511,
    line1: 73.146,
    line2: 75.774,
    line3: 78.403,
    line4: 81.032,
    line5: 83.66,
    line6: 86.289,
    line7: 88.918,
  },
  {
    age: 512,
    line1: 73.174,
    line2: 75.804,
    line3: 78.435,
    line4: 81.066,
    line5: 83.696,
    line6: 86.327,
    line7: 88.957,
  },
  {
    age: 513,
    line1: 73.204,
    line2: 75.836,
    line3: 78.468,
    line4: 81.099,
    line5: 83.731,
    line6: 86.363,
    line7: 88.994,
  },
  {
    age: 514,
    line1: 73.232,
    line2: 75.866,
    line3: 78.499,
    line4: 81.133,
    line5: 83.767,
    line6: 86.4,
    line7: 89.034,
  },
  {
    age: 515,
    line1: 73.26,
    line2: 75.896,
    line3: 78.531,
    line4: 81.167,
    line5: 83.802,
    line6: 86.438,
    line7: 89.073,
  },
  {
    age: 516,
    line1: 73.288,
    line2: 75.926,
    line3: 78.563,
    line4: 81.2,
    line5: 83.838,
    line6: 86.475,
    line7: 89.113,
  },
  {
    age: 517,
    line1: 73.316,
    line2: 75.955,
    line3: 78.595,
    line4: 81.234,
    line5: 83.873,
    line6: 86.513,
    line7: 89.152,
  },
  {
    age: 518,
    line1: 73.344,
    line2: 75.985,
    line3: 78.626,
    line4: 81.268,
    line5: 83.909,
    line6: 86.55,
    line7: 89.191,
  },
  {
    age: 519,
    line1: 73.372,
    line2: 76.015,
    line3: 78.658,
    line4: 81.301,
    line5: 83.944,
    line6: 86.588,
    line7: 89.231,
  },
  {
    age: 520,
    line1: 73.4,
    line2: 76.045,
    line3: 78.69,
    line4: 81.335,
    line5: 83.98,
    line6: 86.625,
    line7: 89.27,
  },
  {
    age: 521,
    line1: 73.428,
    line2: 76.075,
    line3: 78.721,
    line4: 81.368,
    line5: 84.015,
    line6: 86.662,
    line7: 89.309,
  },
  {
    age: 522,
    line1: 73.455,
    line2: 76.104,
    line3: 78.753,
    line4: 81.402,
    line5: 84.051,
    line6: 86.7,
    line7: 89.348,
  },
  {
    age: 523,
    line1: 73.483,
    line2: 76.134,
    line3: 78.785,
    line4: 81.435,
    line5: 84.086,
    line6: 86.737,
    line7: 89.387,
  },
  {
    age: 524,
    line1: 73.511,
    line2: 76.164,
    line3: 78.816,
    line4: 81.469,
    line5: 84.121,
    line6: 86.774,
    line7: 89.427,
  },
  {
    age: 525,
    line1: 73.539,
    line2: 76.193,
    line3: 78.848,
    line4: 81.502,
    line5: 84.157,
    line6: 86.811,
    line7: 89.466,
  },
  {
    age: 526,
    line1: 73.566,
    line2: 76.223,
    line3: 78.879,
    line4: 81.536,
    line5: 84.192,
    line6: 86.848,
    line7: 89.505,
  },
  {
    age: 527,
    line1: 73.594,
    line2: 76.252,
    line3: 78.911,
    line4: 81.569,
    line5: 84.227,
    line6: 86.886,
    line7: 89.544,
  },
  {
    age: 528,
    line1: 73.622,
    line2: 76.282,
    line3: 78.942,
    line4: 81.602,
    line5: 84.263,
    line6: 86.923,
    line7: 89.583,
  },
  {
    age: 529,
    line1: 73.649,
    line2: 76.311,
    line3: 78.973,
    line4: 81.636,
    line5: 84.298,
    line6: 86.96,
    line7: 89.622,
  },
  {
    age: 530,
    line1: 73.679,
    line2: 76.342,
    line3: 79.006,
    line4: 81.669,
    line5: 84.332,
    line6: 86.995,
    line7: 89.659,
  },
  {
    age: 531,
    line1: 73.707,
    line2: 76.372,
    line3: 79.037,
    line4: 81.702,
    line5: 84.367,
    line6: 87.032,
    line7: 89.697,
  },
  {
    age: 532,
    line1: 73.734,
    line2: 76.401,
    line3: 79.068,
    line4: 81.735,
    line5: 84.402,
    line6: 87.069,
    line7: 89.736,
  },
  {
    age: 533,
    line1: 73.762,
    line2: 76.431,
    line3: 79.1,
    line4: 81.768,
    line5: 84.437,
    line6: 87.106,
    line7: 89.775,
  },
  {
    age: 534,
    line1: 73.789,
    line2: 76.46,
    line3: 79.131,
    line4: 81.802,
    line5: 84.473,
    line6: 87.143,
    line7: 89.814,
  },
  {
    age: 535,
    line1: 73.817,
    line2: 76.489,
    line3: 79.162,
    line4: 81.835,
    line5: 84.508,
    line6: 87.18,
    line7: 89.853,
  },
  {
    age: 536,
    line1: 73.844,
    line2: 76.519,
    line3: 79.193,
    line4: 81.868,
    line5: 84.543,
    line6: 87.217,
    line7: 89.892,
  },
  {
    age: 537,
    line1: 73.871,
    line2: 76.548,
    line3: 79.224,
    line4: 81.901,
    line5: 84.577,
    line6: 87.254,
    line7: 89.93,
  },
  {
    age: 538,
    line1: 73.899,
    line2: 76.577,
    line3: 79.256,
    line4: 81.934,
    line5: 84.612,
    line6: 87.291,
    line7: 89.969,
  },
  {
    age: 539,
    line1: 73.926,
    line2: 76.606,
    line3: 79.287,
    line4: 81.967,
    line5: 84.647,
    line6: 87.328,
    line7: 90.008,
  },
  {
    age: 540,
    line1: 73.953,
    line2: 76.636,
    line3: 79.318,
    line4: 82,
    line5: 84.682,
    line6: 87.364,
    line7: 90.047,
  },
  {
    age: 541,
    line1: 73.981,
    line2: 76.665,
    line3: 79.349,
    line4: 82.033,
    line5: 84.717,
    line6: 87.401,
    line7: 90.085,
  },
  {
    age: 542,
    line1: 74.008,
    line2: 76.694,
    line3: 79.38,
    line4: 82.066,
    line5: 84.752,
    line6: 87.438,
    line7: 90.124,
  },
  {
    age: 543,
    line1: 74.035,
    line2: 76.723,
    line3: 79.411,
    line4: 82.099,
    line5: 84.787,
    line6: 87.475,
    line7: 90.162,
  },
  {
    age: 544,
    line1: 74.062,
    line2: 76.752,
    line3: 79.442,
    line4: 82.132,
    line5: 84.821,
    line6: 87.511,
    line7: 90.201,
  },
  {
    age: 545,
    line1: 74.089,
    line2: 76.781,
    line3: 79.473,
    line4: 82.164,
    line5: 84.856,
    line6: 87.548,
    line7: 90.24,
  },
  {
    age: 546,
    line1: 74.116,
    line2: 76.81,
    line3: 79.504,
    line4: 82.197,
    line5: 84.891,
    line6: 87.585,
    line7: 90.278,
  },
  {
    age: 547,
    line1: 74.144,
    line2: 76.839,
    line3: 79.535,
    line4: 82.23,
    line5: 84.925,
    line6: 87.621,
    line7: 90.316,
  },
  {
    age: 548,
    line1: 74.171,
    line2: 76.868,
    line3: 79.565,
    line4: 82.263,
    line5: 84.96,
    line6: 87.658,
    line7: 90.355,
  },
  {
    age: 549,
    line1: 74.198,
    line2: 76.897,
    line3: 79.596,
    line4: 82.296,
    line5: 84.995,
    line6: 87.694,
    line7: 90.393,
  },
  {
    age: 550,
    line1: 74.225,
    line2: 76.926,
    line3: 79.627,
    line4: 82.328,
    line5: 85.029,
    line6: 87.731,
    line7: 90.432,
  },
  {
    age: 551,
    line1: 74.252,
    line2: 76.955,
    line3: 79.658,
    line4: 82.361,
    line5: 85.064,
    line6: 87.767,
    line7: 90.47,
  },
  {
    age: 552,
    line1: 74.279,
    line2: 76.984,
    line3: 79.689,
    line4: 82.394,
    line5: 85.098,
    line6: 87.803,
    line7: 90.508,
  },
  {
    age: 553,
    line1: 74.305,
    line2: 77.012,
    line3: 79.719,
    line4: 82.426,
    line5: 85.133,
    line6: 87.84,
    line7: 90.547,
  },
  {
    age: 554,
    line1: 74.332,
    line2: 77.041,
    line3: 79.75,
    line4: 82.459,
    line5: 85.167,
    line6: 87.876,
    line7: 90.585,
  },
  {
    age: 555,
    line1: 74.359,
    line2: 77.07,
    line3: 79.781,
    line4: 82.491,
    line5: 85.202,
    line6: 87.913,
    line7: 90.623,
  },
  {
    age: 556,
    line1: 74.386,
    line2: 77.099,
    line3: 79.811,
    line4: 82.524,
    line5: 85.236,
    line6: 87.949,
    line7: 90.661,
  },
  {
    age: 557,
    line1: 74.413,
    line2: 77.127,
    line3: 79.842,
    line4: 82.556,
    line5: 85.271,
    line6: 87.985,
    line7: 90.7,
  },
  {
    age: 558,
    line1: 74.44,
    line2: 77.156,
    line3: 79.872,
    line4: 82.589,
    line5: 85.305,
    line6: 88.021,
    line7: 90.738,
  },
  {
    age: 559,
    line1: 74.466,
    line2: 77.185,
    line3: 79.903,
    line4: 82.621,
    line5: 85.339,
    line6: 88.058,
    line7: 90.776,
  },
  {
    age: 560,
    line1: 74.493,
    line2: 77.213,
    line3: 79.933,
    line4: 82.654,
    line5: 85.374,
    line6: 88.094,
    line7: 90.814,
  },
  {
    age: 561,
    line1: 74.52,
    line2: 77.242,
    line3: 79.964,
    line4: 82.686,
    line5: 85.408,
    line6: 88.13,
    line7: 90.852,
  },
  {
    age: 562,
    line1: 74.546,
    line2: 77.27,
    line3: 79.994,
    line4: 82.718,
    line5: 85.442,
    line6: 88.166,
    line7: 90.89,
  },
  {
    age: 563,
    line1: 74.573,
    line2: 77.299,
    line3: 80.025,
    line4: 82.75,
    line5: 85.476,
    line6: 88.202,
    line7: 90.928,
  },
  {
    age: 564,
    line1: 74.6,
    line2: 77.327,
    line3: 80.055,
    line4: 82.783,
    line5: 85.51,
    line6: 88.238,
    line7: 90.966,
  },
  {
    age: 565,
    line1: 74.626,
    line2: 77.356,
    line3: 80.086,
    line4: 82.815,
    line5: 85.545,
    line6: 88.274,
    line7: 91.004,
  },
  {
    age: 566,
    line1: 74.653,
    line2: 77.384,
    line3: 80.116,
    line4: 82.847,
    line5: 85.579,
    line6: 88.31,
    line7: 91.042,
  },
  {
    age: 567,
    line1: 74.679,
    line2: 77.413,
    line3: 80.146,
    line4: 82.88,
    line5: 85.613,
    line6: 88.346,
    line7: 91.08,
  },
  {
    age: 568,
    line1: 74.706,
    line2: 77.441,
    line3: 80.176,
    line4: 82.912,
    line5: 85.647,
    line6: 88.382,
    line7: 91.117,
  },
  {
    age: 569,
    line1: 74.732,
    line2: 77.47,
    line3: 80.207,
    line4: 82.944,
    line5: 85.681,
    line6: 88.418,
    line7: 91.155,
  },
  {
    age: 570,
    line1: 74.759,
    line2: 77.498,
    line3: 80.237,
    line4: 82.976,
    line5: 85.715,
    line6: 88.454,
    line7: 91.193,
  },
  {
    age: 571,
    line1: 74.785,
    line2: 77.526,
    line3: 80.267,
    line4: 83.008,
    line5: 85.749,
    line6: 88.49,
    line7: 91.231,
  },
  {
    age: 572,
    line1: 74.812,
    line2: 77.554,
    line3: 80.297,
    line4: 83.04,
    line5: 85.783,
    line6: 88.526,
    line7: 91.268,
  },
  {
    age: 573,
    line1: 74.838,
    line2: 77.583,
    line3: 80.327,
    line4: 83.072,
    line5: 85.817,
    line6: 88.562,
    line7: 91.306,
  },
  {
    age: 574,
    line1: 74.864,
    line2: 77.611,
    line3: 80.358,
    line4: 83.104,
    line5: 85.851,
    line6: 88.597,
    line7: 91.344,
  },
  {
    age: 575,
    line1: 74.891,
    line2: 77.639,
    line3: 80.388,
    line4: 83.136,
    line5: 85.884,
    line6: 88.633,
    line7: 91.381,
  },
  {
    age: 576,
    line1: 74.914,
    line2: 77.666,
    line3: 80.417,
    line4: 83.168,
    line5: 85.919,
    line6: 88.67,
    line7: 91.422,
  },
  {
    age: 577,
    line1: 74.941,
    line2: 77.694,
    line3: 80.447,
    line4: 83.2,
    line5: 85.953,
    line6: 88.706,
    line7: 91.459,
  },
  {
    age: 578,
    line1: 74.967,
    line2: 77.722,
    line3: 80.477,
    line4: 83.232,
    line5: 85.987,
    line6: 88.742,
    line7: 91.497,
  },
  {
    age: 579,
    line1: 74.993,
    line2: 77.75,
    line3: 80.507,
    line4: 83.264,
    line5: 86.021,
    line6: 88.777,
    line7: 91.534,
  },
  {
    age: 580,
    line1: 75.019,
    line2: 77.778,
    line3: 80.537,
    line4: 83.296,
    line5: 86.054,
    line6: 88.813,
    line7: 91.572,
  },
  {
    age: 581,
    line1: 75.045,
    line2: 77.806,
    line3: 80.567,
    line4: 83.327,
    line5: 86.088,
    line6: 88.849,
    line7: 91.609,
  },
  {
    age: 582,
    line1: 75.072,
    line2: 77.834,
    line3: 80.597,
    line4: 83.359,
    line5: 86.122,
    line6: 88.884,
    line7: 91.647,
  },
  {
    age: 583,
    line1: 75.098,
    line2: 77.862,
    line3: 80.626,
    line4: 83.391,
    line5: 86.155,
    line6: 88.92,
    line7: 91.684,
  },
  {
    age: 584,
    line1: 75.124,
    line2: 77.89,
    line3: 80.656,
    line4: 83.423,
    line5: 86.189,
    line6: 88.955,
    line7: 91.721,
  },
  {
    age: 585,
    line1: 75.15,
    line2: 77.918,
    line3: 80.686,
    line4: 83.454,
    line5: 86.222,
    line6: 88.991,
    line7: 91.759,
  },
  {
    age: 586,
    line1: 75.176,
    line2: 77.946,
    line3: 80.716,
    line4: 83.486,
    line5: 86.256,
    line6: 89.026,
    line7: 91.796,
  },
  {
    age: 587,
    line1: 75.202,
    line2: 77.974,
    line3: 80.746,
    line4: 83.518,
    line5: 86.29,
    line6: 89.061,
    line7: 91.833,
  },
  {
    age: 588,
    line1: 75.228,
    line2: 78.002,
    line3: 80.775,
    line4: 83.549,
    line5: 86.323,
    line6: 89.097,
    line7: 91.871,
  },
  {
    age: 589,
    line1: 75.254,
    line2: 78.029,
    line3: 80.805,
    line4: 83.581,
    line5: 86.357,
    line6: 89.132,
    line7: 91.908,
  },
  {
    age: 590,
    line1: 75.28,
    line2: 78.057,
    line3: 80.835,
    line4: 83.612,
    line5: 86.39,
    line6: 89.168,
    line7: 91.945,
  },
  {
    age: 591,
    line1: 75.305,
    line2: 78.085,
    line3: 80.864,
    line4: 83.644,
    line5: 86.423,
    line6: 89.203,
    line7: 91.982,
  },
  {
    age: 592,
    line1: 75.331,
    line2: 78.113,
    line3: 80.894,
    line4: 83.675,
    line5: 86.457,
    line6: 89.238,
    line7: 92.02,
  },
  {
    age: 593,
    line1: 75.357,
    line2: 78.14,
    line3: 80.924,
    line4: 83.707,
    line5: 86.49,
    line6: 89.273,
    line7: 92.057,
  },
  {
    age: 594,
    line1: 75.383,
    line2: 78.168,
    line3: 80.953,
    line4: 83.738,
    line5: 86.524,
    line6: 89.309,
    line7: 92.094,
  },
  {
    age: 595,
    line1: 75.409,
    line2: 78.196,
    line3: 80.983,
    line4: 83.77,
    line5: 86.557,
    line6: 89.344,
    line7: 92.131,
  },
  {
    age: 596,
    line1: 75.432,
    line2: 78.222,
    line3: 81.011,
    line4: 83.801,
    line5: 86.591,
    line6: 89.381,
    line7: 92.17,
  },
  {
    age: 597,
    line1: 75.458,
    line2: 78.249,
    line3: 81.041,
    line4: 83.833,
    line5: 86.624,
    line6: 89.416,
    line7: 92.207,
  },
  {
    age: 598,
    line1: 75.483,
    line2: 78.277,
    line3: 81.07,
    line4: 83.864,
    line5: 86.658,
    line6: 89.451,
    line7: 92.245,
  },
  {
    age: 599,
    line1: 75.509,
    line2: 78.305,
    line3: 81.1,
    line4: 83.895,
    line5: 86.691,
    line6: 89.486,
    line7: 92.281,
  },
  {
    age: 600,
    line1: 75.535,
    line2: 78.332,
    line3: 81.129,
    line4: 83.927,
    line5: 86.724,
    line6: 89.521,
    line7: 92.319,
  },
  {
    age: 601,
    line1: 75.56,
    line2: 78.36,
    line3: 81.159,
    line4: 83.958,
    line5: 86.757,
    line6: 89.556,
    line7: 92.355,
  },
  {
    age: 602,
    line1: 75.586,
    line2: 78.387,
    line3: 81.188,
    line4: 83.989,
    line5: 86.79,
    line6: 89.591,
    line7: 92.392,
  },
  {
    age: 603,
    line1: 75.612,
    line2: 78.415,
    line3: 81.218,
    line4: 84.02,
    line5: 86.823,
    line6: 89.626,
    line7: 92.429,
  },
  {
    age: 604,
    line1: 75.637,
    line2: 78.442,
    line3: 81.247,
    line4: 84.052,
    line5: 86.857,
    line6: 89.661,
    line7: 92.466,
  },
  {
    age: 605,
    line1: 75.663,
    line2: 78.47,
    line3: 81.276,
    line4: 84.083,
    line5: 86.89,
    line6: 89.696,
    line7: 92.503,
  },
  {
    age: 606,
    line1: 75.688,
    line2: 78.497,
    line3: 81.305,
    line4: 84.114,
    line5: 86.923,
    line6: 89.731,
    line7: 92.54,
  },
  {
    age: 607,
    line1: 75.714,
    line2: 78.524,
    line3: 81.335,
    line4: 84.145,
    line5: 86.956,
    line6: 89.766,
    line7: 92.577,
  },
  {
    age: 608,
    line1: 75.739,
    line2: 78.552,
    line3: 81.364,
    line4: 84.176,
    line5: 86.989,
    line6: 89.801,
    line7: 92.613,
  },
  {
    age: 609,
    line1: 75.765,
    line2: 78.579,
    line3: 81.393,
    line4: 84.207,
    line5: 87.022,
    line6: 89.836,
    line7: 92.65,
  },
  {
    age: 610,
    line1: 75.788,
    line2: 78.605,
    line3: 81.422,
    line4: 84.238,
    line5: 87.055,
    line6: 89.872,
    line7: 92.689,
  },
  {
    age: 611,
    line1: 75.813,
    line2: 78.632,
    line3: 81.451,
    line4: 84.27,
    line5: 87.088,
    line6: 89.907,
    line7: 92.726,
  },
  {
    age: 612,
    line1: 75.839,
    line2: 78.659,
    line3: 81.48,
    line4: 84.301,
    line5: 87.121,
    line6: 89.942,
    line7: 92.763,
  },
  {
    age: 613,
    line1: 75.864,
    line2: 78.686,
    line3: 81.509,
    line4: 84.332,
    line5: 87.154,
    line6: 89.977,
    line7: 92.799,
  },
  {
    age: 614,
    line1: 75.889,
    line2: 78.714,
    line3: 81.538,
    line4: 84.363,
    line5: 87.187,
    line6: 90.012,
    line7: 92.836,
  },
  {
    age: 615,
    line1: 75.914,
    line2: 78.741,
    line3: 81.567,
    line4: 84.394,
    line5: 87.22,
    line6: 90.046,
    line7: 92.873,
  },
  {
    age: 616,
    line1: 75.94,
    line2: 78.768,
    line3: 81.596,
    line4: 84.424,
    line5: 87.253,
    line6: 90.081,
    line7: 92.909,
  },
  {
    age: 617,
    line1: 75.965,
    line2: 78.795,
    line3: 81.625,
    line4: 84.455,
    line5: 87.286,
    line6: 90.116,
    line7: 92.946,
  },
  {
    age: 618,
    line1: 75.99,
    line2: 78.822,
    line3: 81.654,
    line4: 84.486,
    line5: 87.318,
    line6: 90.15,
    line7: 92.982,
  },
  {
    age: 619,
    line1: 76.016,
    line2: 78.849,
    line3: 81.683,
    line4: 84.517,
    line5: 87.351,
    line6: 90.185,
    line7: 93.019,
  },
  {
    age: 620,
    line1: 76.041,
    line2: 78.876,
    line3: 81.712,
    line4: 84.548,
    line5: 87.384,
    line6: 90.219,
    line7: 93.055,
  },
  {
    age: 621,
    line1: 76.063,
    line2: 78.902,
    line3: 81.74,
    line4: 84.579,
    line5: 87.417,
    line6: 90.256,
    line7: 93.094,
  },
  {
    age: 622,
    line1: 76.088,
    line2: 78.929,
    line3: 81.769,
    line4: 84.61,
    line5: 87.45,
    line6: 90.29,
    line7: 93.131,
  },
  {
    age: 623,
    line1: 76.114,
    line2: 78.956,
    line3: 81.798,
    line4: 84.64,
    line5: 87.483,
    line6: 90.325,
    line7: 93.167,
  },
  {
    age: 624,
    line1: 76.139,
    line2: 78.983,
    line3: 81.827,
    line4: 84.671,
    line5: 87.515,
    line6: 90.359,
    line7: 93.203,
  },
  {
    age: 625,
    line1: 76.164,
    line2: 79.01,
    line3: 81.856,
    line4: 84.702,
    line5: 87.548,
    line6: 90.394,
    line7: 93.24,
  },
  {
    age: 626,
    line1: 76.189,
    line2: 79.037,
    line3: 81.885,
    line4: 84.732,
    line5: 87.58,
    line6: 90.428,
    line7: 93.276,
  },
  {
    age: 627,
    line1: 76.214,
    line2: 79.064,
    line3: 81.913,
    line4: 84.763,
    line5: 87.613,
    line6: 90.463,
    line7: 93.312,
  },
  {
    age: 628,
    line1: 76.239,
    line2: 79.09,
    line3: 81.942,
    line4: 84.794,
    line5: 87.645,
    line6: 90.497,
    line7: 93.349,
  },
  {
    age: 629,
    line1: 76.264,
    line2: 79.117,
    line3: 81.971,
    line4: 84.824,
    line5: 87.678,
    line6: 90.531,
    line7: 93.385,
  },
  {
    age: 630,
    line1: 76.289,
    line2: 79.144,
    line3: 82,
    line4: 84.855,
    line5: 87.71,
    line6: 90.566,
    line7: 93.421,
  },
  {
    age: 631,
    line1: 76.311,
    line2: 79.169,
    line3: 82.027,
    line4: 84.886,
    line5: 87.744,
    line6: 90.602,
    line7: 93.46,
  },
  {
    age: 632,
    line1: 76.336,
    line2: 79.196,
    line3: 82.056,
    line4: 84.916,
    line5: 87.776,
    line6: 90.636,
    line7: 93.496,
  },
  {
    age: 633,
    line1: 76.361,
    line2: 79.223,
    line3: 82.085,
    line4: 84.946,
    line5: 87.808,
    line6: 90.67,
    line7: 93.532,
  },
  {
    age: 634,
    line1: 76.386,
    line2: 79.25,
    line3: 82.113,
    line4: 84.977,
    line5: 87.841,
    line6: 90.704,
    line7: 93.568,
  },
  {
    age: 635,
    line1: 76.411,
    line2: 79.276,
    line3: 82.142,
    line4: 85.008,
    line5: 87.873,
    line6: 90.739,
    line7: 93.604,
  },
  {
    age: 636,
    line1: 76.435,
    line2: 79.303,
    line3: 82.17,
    line4: 85.038,
    line5: 87.905,
    line6: 90.773,
    line7: 93.64,
  },
  {
    age: 637,
    line1: 76.46,
    line2: 79.33,
    line3: 82.199,
    line4: 85.068,
    line5: 87.938,
    line6: 90.807,
    line7: 93.676,
  },
  {
    age: 638,
    line1: 76.485,
    line2: 79.356,
    line3: 82.227,
    line4: 85.099,
    line5: 87.97,
    line6: 90.841,
    line7: 93.712,
  },
  {
    age: 639,
    line1: 76.51,
    line2: 79.383,
    line3: 82.256,
    line4: 85.129,
    line5: 88.002,
    line6: 90.875,
    line7: 93.748,
  },
  {
    age: 640,
    line1: 76.532,
    line2: 79.408,
    line3: 82.284,
    line4: 85.159,
    line5: 88.035,
    line6: 90.911,
    line7: 93.787,
  },
  {
    age: 641,
    line1: 76.557,
    line2: 79.434,
    line3: 82.312,
    line4: 85.19,
    line5: 88.067,
    line6: 90.945,
    line7: 93.823,
  },
  {
    age: 642,
    line1: 76.581,
    line2: 79.461,
    line3: 82.34,
    line4: 85.22,
    line5: 88.1,
    line6: 90.979,
    line7: 93.859,
  },
  {
    age: 643,
    line1: 76.606,
    line2: 79.487,
    line3: 82.369,
    line4: 85.25,
    line5: 88.132,
    line6: 91.013,
    line7: 93.895,
  },
  {
    age: 644,
    line1: 76.63,
    line2: 79.514,
    line3: 82.397,
    line4: 85.28,
    line5: 88.164,
    line6: 91.047,
    line7: 93.931,
  },
  {
    age: 645,
    line1: 76.655,
    line2: 79.54,
    line3: 82.426,
    line4: 85.311,
    line5: 88.196,
    line6: 91.081,
    line7: 93.966,
  },
  {
    age: 646,
    line1: 76.68,
    line2: 79.567,
    line3: 82.454,
    line4: 85.341,
    line5: 88.228,
    line6: 91.115,
    line7: 94.002,
  },
  {
    age: 647,
    line1: 76.704,
    line2: 79.593,
    line3: 82.482,
    line4: 85.371,
    line5: 88.26,
    line6: 91.149,
    line7: 94.038,
  },
  {
    age: 648,
    line1: 76.729,
    line2: 79.62,
    line3: 82.51,
    line4: 85.401,
    line5: 88.292,
    line6: 91.183,
    line7: 94.074,
  },
  {
    age: 649,
    line1: 76.751,
    line2: 79.644,
    line3: 82.538,
    line4: 85.431,
    line5: 88.325,
    line6: 91.219,
    line7: 94.112,
  },
  {
    age: 650,
    line1: 76.775,
    line2: 79.671,
    line3: 82.566,
    line4: 85.462,
    line5: 88.357,
    line6: 91.252,
    line7: 94.148,
  },
  {
    age: 651,
    line1: 76.8,
    line2: 79.697,
    line3: 82.594,
    line4: 85.492,
    line5: 88.389,
    line6: 91.286,
    line7: 94.184,
  },
  {
    age: 652,
    line1: 76.824,
    line2: 79.723,
    line3: 82.623,
    line4: 85.522,
    line5: 88.421,
    line6: 91.32,
    line7: 94.219,
  },
  {
    age: 653,
    line1: 76.849,
    line2: 79.75,
    line3: 82.651,
    line4: 85.552,
    line5: 88.453,
    line6: 91.354,
    line7: 94.255,
  },
  {
    age: 654,
    line1: 76.873,
    line2: 79.776,
    line3: 82.679,
    line4: 85.582,
    line5: 88.485,
    line6: 91.388,
    line7: 94.29,
  },
  {
    age: 655,
    line1: 76.897,
    line2: 79.802,
    line3: 82.707,
    line4: 85.612,
    line5: 88.517,
    line6: 91.421,
    line7: 94.326,
  },
  {
    age: 656,
    line1: 76.922,
    line2: 79.828,
    line3: 82.735,
    line4: 85.642,
    line5: 88.548,
    line6: 91.455,
    line7: 94.362,
  },
  {
    age: 657,
    line1: 76.943,
    line2: 79.853,
    line3: 82.762,
    line4: 85.672,
    line5: 88.581,
    line6: 91.49,
    line7: 94.4,
  },
  {
    age: 658,
    line1: 76.968,
    line2: 79.879,
    line3: 82.79,
    line4: 85.702,
    line5: 88.613,
    line6: 91.524,
    line7: 94.435,
  },
  {
    age: 659,
    line1: 76.992,
    line2: 79.905,
    line3: 82.818,
    line4: 85.731,
    line5: 88.645,
    line6: 91.558,
    line7: 94.471,
  },
  {
    age: 660,
    line1: 77.016,
    line2: 79.931,
    line3: 82.846,
    line4: 85.761,
    line5: 88.676,
    line6: 91.591,
    line7: 94.506,
  },
  {
    age: 661,
    line1: 77.04,
    line2: 79.957,
    line3: 82.874,
    line4: 85.791,
    line5: 88.708,
    line6: 91.625,
    line7: 94.542,
  },
  {
    age: 662,
    line1: 77.065,
    line2: 79.983,
    line3: 82.902,
    line4: 85.821,
    line5: 88.74,
    line6: 91.659,
    line7: 94.577,
  },
  {
    age: 663,
    line1: 77.089,
    line2: 80.01,
    line3: 82.93,
    line4: 85.851,
    line5: 88.771,
    line6: 91.692,
    line7: 94.613,
  },
  {
    age: 664,
    line1: 77.11,
    line2: 80.034,
    line3: 82.957,
    line4: 85.881,
    line5: 88.804,
    line6: 91.727,
    line7: 94.651,
  },
  {
    age: 665,
    line1: 77.135,
    line2: 80.06,
    line3: 82.985,
    line4: 85.91,
    line5: 88.836,
    line6: 91.761,
    line7: 94.686,
  },
  {
    age: 666,
    line1: 77.159,
    line2: 80.086,
    line3: 83.013,
    line4: 85.94,
    line5: 88.867,
    line6: 91.794,
    line7: 94.721,
  },
  {
    age: 667,
    line1: 77.183,
    line2: 80.112,
    line3: 83.041,
    line4: 85.97,
    line5: 88.899,
    line6: 91.828,
    line7: 94.757,
  },
  {
    age: 668,
    line1: 77.207,
    line2: 80.138,
    line3: 83.069,
    line4: 86,
    line5: 88.93,
    line6: 91.861,
    line7: 94.792,
  },
  {
    age: 669,
    line1: 77.231,
    line2: 80.164,
    line3: 83.096,
    line4: 86.029,
    line5: 88.962,
    line6: 91.895,
    line7: 94.827,
  },
  {
    age: 670,
    line1: 77.255,
    line2: 80.19,
    line3: 83.124,
    line4: 86.059,
    line5: 88.994,
    line6: 91.928,
    line7: 94.863,
  },
  {
    age: 671,
    line1: 77.279,
    line2: 80.216,
    line3: 83.152,
    line4: 86.088,
    line5: 89.025,
    line6: 91.961,
    line7: 94.898,
  },
  {
    age: 672,
    line1: 77.3,
    line2: 80.24,
    line3: 83.179,
    line4: 86.118,
    line5: 89.057,
    line6: 91.997,
    line7: 94.936,
  },
  {
    age: 673,
    line1: 77.324,
    line2: 80.266,
    line3: 83.207,
    line4: 86.148,
    line5: 89.089,
    line6: 92.03,
    line7: 94.971,
  },
  {
    age: 674,
    line1: 77.348,
    line2: 80.291,
    line3: 83.234,
    line4: 86.177,
    line5: 89.12,
    line6: 92.063,
    line7: 95.006,
  },
  {
    age: 675,
    line1: 77.372,
    line2: 80.317,
    line3: 83.262,
    line4: 86.207,
    line5: 89.152,
    line6: 92.096,
    line7: 95.041,
  },
  {
    age: 676,
    line1: 77.396,
    line2: 80.343,
    line3: 83.29,
    line4: 86.236,
    line5: 89.183,
    line6: 92.13,
    line7: 95.076,
  },
  {
    age: 677,
    line1: 77.42,
    line2: 80.369,
    line3: 83.317,
    line4: 86.266,
    line5: 89.214,
    line6: 92.163,
    line7: 95.112,
  },
  {
    age: 678,
    line1: 77.444,
    line2: 80.395,
    line3: 83.345,
    line4: 86.295,
    line5: 89.246,
    line6: 92.196,
    line7: 95.147,
  },
  {
    age: 679,
    line1: 77.465,
    line2: 80.418,
    line3: 83.372,
    line4: 86.325,
    line5: 89.278,
    line6: 92.231,
    line7: 95.184,
  },
  {
    age: 680,
    line1: 77.489,
    line2: 80.444,
    line3: 83.399,
    line4: 86.354,
    line5: 89.309,
    line6: 92.264,
    line7: 95.219,
  },
  {
    age: 681,
    line1: 77.513,
    line2: 80.47,
    line3: 83.427,
    line4: 86.384,
    line5: 89.341,
    line6: 92.298,
    line7: 95.254,
  },
  {
    age: 682,
    line1: 77.537,
    line2: 80.496,
    line3: 83.454,
    line4: 86.413,
    line5: 89.372,
    line6: 92.331,
    line7: 95.289,
  },
  {
    age: 683,
    line1: 77.561,
    line2: 80.521,
    line3: 83.482,
    line4: 86.442,
    line5: 89.403,
    line6: 92.364,
    line7: 95.324,
  },
  {
    age: 684,
    line1: 77.584,
    line2: 80.547,
    line3: 83.509,
    line4: 86.472,
    line5: 89.434,
    line6: 92.397,
    line7: 95.359,
  },
  {
    age: 685,
    line1: 77.608,
    line2: 80.572,
    line3: 83.537,
    line4: 86.501,
    line5: 89.466,
    line6: 92.43,
    line7: 95.394,
  },
  {
    age: 686,
    line1: 77.629,
    line2: 80.596,
    line3: 83.563,
    line4: 86.531,
    line5: 89.498,
    line6: 92.465,
    line7: 95.432,
  },
  {
    age: 687,
    line1: 77.653,
    line2: 80.622,
    line3: 83.591,
    line4: 86.56,
    line5: 89.529,
    line6: 92.498,
    line7: 95.467,
  },
  {
    age: 688,
    line1: 77.677,
    line2: 80.647,
    line3: 83.618,
    line4: 86.589,
    line5: 89.56,
    line6: 92.531,
    line7: 95.502,
  },
  {
    age: 689,
    line1: 77.7,
    line2: 80.673,
    line3: 83.646,
    line4: 86.618,
    line5: 89.591,
    line6: 92.564,
    line7: 95.537,
  },
  {
    age: 690,
    line1: 77.724,
    line2: 80.698,
    line3: 83.673,
    line4: 86.648,
    line5: 89.622,
    line6: 92.597,
    line7: 95.572,
  },
  {
    age: 691,
    line1: 77.747,
    line2: 80.724,
    line3: 83.7,
    line4: 86.677,
    line5: 89.653,
    line6: 92.63,
    line7: 95.606,
  },
  {
    age: 692,
    line1: 77.771,
    line2: 80.749,
    line3: 83.728,
    line4: 86.706,
    line5: 89.684,
    line6: 92.663,
    line7: 95.641,
  },
  {
    age: 693,
    line1: 77.792,
    line2: 80.773,
    line3: 83.754,
    line4: 86.735,
    line5: 89.716,
    line6: 92.697,
    line7: 95.679,
  },
  {
    age: 694,
    line1: 77.816,
    line2: 80.799,
    line3: 83.782,
    line4: 86.764,
    line5: 89.747,
    line6: 92.73,
    line7: 95.713,
  },
  {
    age: 695,
    line1: 77.839,
    line2: 80.824,
    line3: 83.809,
    line4: 86.794,
    line5: 89.779,
    line6: 92.763,
    line7: 95.748,
  },
  {
    age: 696,
    line1: 77.863,
    line2: 80.849,
    line3: 83.836,
    line4: 86.823,
    line5: 89.81,
    line6: 92.796,
    line7: 95.783,
  },
  {
    age: 697,
    line1: 77.886,
    line2: 80.875,
    line3: 83.863,
    line4: 86.852,
    line5: 89.84,
    line6: 92.829,
    line7: 95.818,
  },
  {
    age: 698,
    line1: 77.91,
    line2: 80.9,
    line3: 83.891,
    line4: 86.881,
    line5: 89.871,
    line6: 92.862,
    line7: 95.852,
  },
  {
    age: 699,
    line1: 77.933,
    line2: 80.925,
    line3: 83.918,
    line4: 86.91,
    line5: 89.902,
    line6: 92.895,
    line7: 95.887,
  },
  {
    age: 700,
    line1: 77.954,
    line2: 80.949,
    line3: 83.944,
    line4: 86.939,
    line5: 89.934,
    line6: 92.929,
    line7: 95.924,
  },
  {
    age: 701,
    line1: 77.977,
    line2: 80.974,
    line3: 83.971,
    line4: 86.968,
    line5: 89.965,
    line6: 92.962,
    line7: 95.959,
  },
  {
    age: 702,
    line1: 78.001,
    line2: 81,
    line3: 83.998,
    line4: 86.997,
    line5: 89.996,
    line6: 92.995,
    line7: 95.994,
  },
  {
    age: 703,
    line1: 78.024,
    line2: 81.025,
    line3: 84.026,
    line4: 87.026,
    line5: 90.027,
    line6: 93.028,
    line7: 96.028,
  },
  {
    age: 704,
    line1: 78.048,
    line2: 81.05,
    line3: 84.053,
    line4: 87.055,
    line5: 90.058,
    line6: 93.06,
    line7: 96.063,
  },
  {
    age: 705,
    line1: 78.071,
    line2: 81.075,
    line3: 84.08,
    line4: 87.084,
    line5: 90.089,
    line6: 93.093,
    line7: 96.097,
  },
  {
    age: 706,
    line1: 78.094,
    line2: 81.101,
    line3: 84.107,
    line4: 87.113,
    line5: 90.119,
    line6: 93.126,
    line7: 96.132,
  },
  {
    age: 707,
    line1: 78.115,
    line2: 81.124,
    line3: 84.133,
    line4: 87.142,
    line5: 90.151,
    line6: 93.16,
    line7: 96.169,
  },
  {
    age: 708,
    line1: 78.138,
    line2: 81.149,
    line3: 84.16,
    line4: 87.171,
    line5: 90.182,
    line6: 93.193,
    line7: 96.204,
  },
  {
    age: 709,
    line1: 78.162,
    line2: 81.174,
    line3: 84.187,
    line4: 87.2,
    line5: 90.213,
    line6: 93.225,
    line7: 96.238,
  },
  {
    age: 710,
    line1: 78.185,
    line2: 81.199,
    line3: 84.214,
    line4: 87.229,
    line5: 90.243,
    line6: 93.258,
    line7: 96.273,
  },
  {
    age: 711,
    line1: 78.208,
    line2: 81.225,
    line3: 84.241,
    line4: 87.258,
    line5: 90.274,
    line6: 93.29,
    line7: 96.307,
  },
  {
    age: 712,
    line1: 78.231,
    line2: 81.25,
    line3: 84.268,
    line4: 87.286,
    line5: 90.305,
    line6: 93.323,
    line7: 96.341,
  },
  {
    age: 713,
    line1: 78.254,
    line2: 81.275,
    line3: 84.295,
    line4: 87.315,
    line5: 90.335,
    line6: 93.356,
    line7: 96.376,
  },
  {
    age: 714,
    line1: 78.275,
    line2: 81.298,
    line3: 84.321,
    line4: 87.344,
    line5: 90.367,
    line6: 93.39,
    line7: 96.413,
  },
  {
    age: 715,
    line1: 78.298,
    line2: 81.323,
    line3: 84.348,
    line4: 87.373,
    line5: 90.398,
    line6: 93.422,
    line7: 96.447,
  },
  {
    age: 716,
    line1: 78.321,
    line2: 81.348,
    line3: 84.375,
    line4: 87.401,
    line5: 90.428,
    line6: 93.455,
    line7: 96.482,
  },
  {
    age: 717,
    line1: 78.344,
    line2: 81.373,
    line3: 84.402,
    line4: 87.43,
    line5: 90.459,
    line6: 93.487,
    line7: 96.516,
  },
  {
    age: 718,
    line1: 78.368,
    line2: 81.398,
    line3: 84.428,
    line4: 87.459,
    line5: 90.489,
    line6: 93.52,
    line7: 96.55,
  },
  {
    age: 719,
    line1: 78.391,
    line2: 81.423,
    line3: 84.455,
    line4: 87.488,
    line5: 90.52,
    line6: 93.552,
    line7: 96.585,
  },
  {
    age: 720,
    line1: 78.414,
    line2: 81.448,
    line3: 84.482,
    line4: 87.516,
    line5: 90.55,
    line6: 93.585,
    line7: 96.619,
  },
  {
    age: 721,
    line1: 78.434,
    line2: 81.471,
    line3: 84.508,
    line4: 87.545,
    line5: 90.582,
    line6: 93.619,
    line7: 96.656,
  },
  {
    age: 722,
    line1: 78.457,
    line2: 81.496,
    line3: 84.535,
    line4: 87.574,
    line5: 90.612,
    line6: 93.651,
    line7: 96.69,
  },
  {
    age: 723,
    line1: 78.48,
    line2: 81.521,
    line3: 84.561,
    line4: 87.602,
    line5: 90.643,
    line6: 93.683,
    line7: 96.724,
  },
  {
    age: 724,
    line1: 78.503,
    line2: 81.546,
    line3: 84.588,
    line4: 87.631,
    line5: 90.673,
    line6: 93.716,
    line7: 96.758,
  },
  {
    age: 725,
    line1: 78.526,
    line2: 81.57,
    line3: 84.615,
    line4: 87.659,
    line5: 90.704,
    line6: 93.748,
    line7: 96.793,
  },
  {
    age: 726,
    line1: 78.549,
    line2: 81.595,
    line3: 84.642,
    line4: 87.688,
    line5: 90.734,
    line6: 93.78,
    line7: 96.827,
  },
  {
    age: 727,
    line1: 78.572,
    line2: 81.62,
    line3: 84.668,
    line4: 87.716,
    line5: 90.765,
    line6: 93.813,
    line7: 96.861,
  },
  {
    age: 728,
    line1: 78.592,
    line2: 81.643,
    line3: 84.694,
    line4: 87.745,
    line5: 90.796,
    line6: 93.847,
    line7: 96.898,
  },
  {
    age: 729,
    line1: 78.615,
    line2: 81.668,
    line3: 84.721,
    line4: 87.773,
    line5: 90.826,
    line6: 93.879,
    line7: 96.932,
  },
  {
    age: 730,
    line1: 78.638,
    line2: 81.693,
    line3: 84.747,
    line4: 87.802,
    line5: 90.856,
    line6: 93.911,
    line7: 96.966,
  },
];

export const MATURE_GIRLS_LENGTH_LINE_COORDS = [
  {
    age: 0,
    line1: 43.56,
    line2: 45.422,
    line3: 47.285,
    line4: 49.148,
    line5: 51.01,
    line6: 52.873,
    line7: 54.736,
  },
  {
    age: 1,
    line1: 43.72,
    line2: 45.585,
    line3: 47.451,
    line4: 49.317,
    line5: 51.182,
    line6: 53.048,
    line7: 54.914,
  },
  {
    age: 2,
    line1: 43.88,
    line2: 45.748,
    line3: 47.617,
    line4: 49.485,
    line5: 51.354,
    line6: 53.223,
    line7: 55.091,
  },
  {
    age: 3,
    line1: 44.038,
    line2: 45.91,
    line3: 47.782,
    line4: 49.654,
    line5: 51.526,
    line6: 53.398,
    line7: 55.27,
  },
  {
    age: 4,
    line1: 44.199,
    line2: 46.074,
    line3: 47.948,
    line4: 49.823,
    line5: 51.698,
    line6: 53.573,
    line7: 55.448,
  },
  {
    age: 5,
    line1: 44.359,
    line2: 46.237,
    line3: 48.114,
    line4: 49.992,
    line5: 51.87,
    line6: 53.748,
    line7: 55.625,
  },
  {
    age: 6,
    line1: 44.519,
    line2: 46.4,
    line3: 48.28,
    line4: 50.161,
    line5: 52.041,
    line6: 53.922,
    line7: 55.802,
  },
  {
    age: 7,
    line1: 44.68,
    line2: 46.563,
    line3: 48.446,
    line4: 50.33,
    line5: 52.213,
    line6: 54.096,
    line7: 55.98,
  },
  {
    age: 8,
    line1: 44.84,
    line2: 46.726,
    line3: 48.613,
    line4: 50.499,
    line5: 52.385,
    line6: 54.271,
    line7: 56.157,
  },
  {
    age: 9,
    line1: 45.001,
    line2: 46.89,
    line3: 48.779,
    line4: 50.668,
    line5: 52.556,
    line6: 54.445,
    line7: 56.334,
  },
  {
    age: 10,
    line1: 45.16,
    line2: 47.052,
    line3: 48.944,
    line4: 50.836,
    line5: 52.729,
    line6: 54.621,
    line7: 56.513,
  },
  {
    age: 11,
    line1: 45.321,
    line2: 47.216,
    line3: 49.11,
    line4: 51.005,
    line5: 52.9,
    line6: 54.795,
    line7: 56.69,
  },
  {
    age: 12,
    line1: 45.482,
    line2: 47.379,
    line3: 49.277,
    line4: 51.174,
    line5: 53.072,
    line6: 54.969,
    line7: 56.867,
  },
  {
    age: 13,
    line1: 45.642,
    line2: 47.543,
    line3: 49.443,
    line4: 51.343,
    line5: 53.243,
    line6: 55.144,
    line7: 57.044,
  },
  {
    age: 14,
    line1: 45.803,
    line2: 47.706,
    line3: 49.609,
    line4: 51.512,
    line5: 53.415,
    line6: 55.318,
    line7: 57.221,
  },
  {
    age: 15,
    line1: 45.933,
    line2: 47.839,
    line3: 49.745,
    line4: 51.651,
    line5: 53.557,
    line6: 55.463,
    line7: 57.369,
  },
  {
    age: 16,
    line1: 46.061,
    line2: 47.971,
    line3: 49.88,
    line4: 51.79,
    line5: 53.699,
    line6: 55.608,
    line7: 57.518,
  },
  {
    age: 17,
    line1: 46.19,
    line2: 48.102,
    line3: 50.015,
    line4: 51.927,
    line5: 53.84,
    line6: 55.752,
    line7: 57.665,
  },
  {
    age: 18,
    line1: 46.316,
    line2: 48.232,
    line3: 50.148,
    line4: 52.064,
    line5: 53.98,
    line6: 55.896,
    line7: 57.812,
  },
  {
    age: 19,
    line1: 46.444,
    line2: 48.362,
    line3: 50.281,
    line4: 52.2,
    line5: 54.119,
    line6: 56.038,
    line7: 57.957,
  },
  {
    age: 20,
    line1: 46.568,
    line2: 48.491,
    line3: 50.413,
    line4: 52.335,
    line5: 54.258,
    line6: 56.18,
    line7: 58.102,
  },
  {
    age: 21,
    line1: 46.694,
    line2: 48.619,
    line3: 50.544,
    line4: 52.47,
    line5: 54.395,
    line6: 56.32,
    line7: 58.245,
  },
  {
    age: 22,
    line1: 46.817,
    line2: 48.746,
    line3: 50.674,
    line4: 52.603,
    line5: 54.531,
    line6: 56.46,
    line7: 58.388,
  },
  {
    age: 23,
    line1: 46.94,
    line2: 48.872,
    line3: 50.803,
    line4: 52.735,
    line5: 54.667,
    line6: 56.598,
    line7: 58.53,
  },
  {
    age: 24,
    line1: 47.061,
    line2: 48.996,
    line3: 50.931,
    line4: 52.866,
    line5: 54.801,
    line6: 56.736,
    line7: 58.671,
  },
  {
    age: 25,
    line1: 47.184,
    line2: 49.121,
    line3: 51.059,
    line4: 52.996,
    line5: 54.934,
    line6: 56.871,
    line7: 58.809,
  },
  {
    age: 26,
    line1: 47.303,
    line2: 49.244,
    line3: 51.185,
    line4: 53.126,
    line5: 55.066,
    line6: 57.007,
    line7: 58.948,
  },
  {
    age: 27,
    line1: 47.422,
    line2: 49.366,
    line3: 51.31,
    line4: 53.254,
    line5: 55.197,
    line6: 57.141,
    line7: 59.085,
  },
  {
    age: 28,
    line1: 47.54,
    line2: 49.487,
    line3: 51.434,
    line4: 53.381,
    line5: 55.328,
    line6: 57.275,
    line7: 59.221,
  },
  {
    age: 29,
    line1: 47.658,
    line2: 49.608,
    line3: 51.557,
    line4: 53.507,
    line5: 55.457,
    line6: 57.407,
    line7: 59.357,
  },
  {
    age: 30,
    line1: 47.774,
    line2: 49.727,
    line3: 51.68,
    line4: 53.633,
    line5: 55.585,
    line6: 57.538,
    line7: 59.491,
  },
  {
    age: 31,
    line1: 47.89,
    line2: 49.846,
    line3: 51.801,
    line4: 53.757,
    line5: 55.713,
    line6: 57.668,
    line7: 59.624,
  },
  {
    age: 32,
    line1: 48.003,
    line2: 49.962,
    line3: 51.922,
    line4: 53.881,
    line5: 55.84,
    line6: 57.799,
    line7: 59.758,
  },
  {
    age: 33,
    line1: 48.117,
    line2: 50.079,
    line3: 52.041,
    line4: 54.003,
    line5: 55.965,
    line6: 57.927,
    line7: 59.889,
  },
  {
    age: 34,
    line1: 48.231,
    line2: 50.195,
    line3: 52.16,
    line4: 54.125,
    line5: 56.089,
    line6: 58.054,
    line7: 60.019,
  },
  {
    age: 35,
    line1: 48.343,
    line2: 50.31,
    line3: 52.278,
    line4: 54.245,
    line5: 56.213,
    line6: 58.18,
    line7: 60.148,
  },
  {
    age: 36,
    line1: 48.453,
    line2: 50.424,
    line3: 52.394,
    line4: 54.365,
    line5: 56.336,
    line6: 58.307,
    line7: 60.277,
  },
  {
    age: 37,
    line1: 48.564,
    line2: 50.537,
    line3: 52.51,
    line4: 54.484,
    line5: 56.457,
    line6: 58.431,
    line7: 60.404,
  },
  {
    age: 38,
    line1: 48.674,
    line2: 50.65,
    line3: 52.626,
    line4: 54.602,
    line5: 56.578,
    line6: 58.554,
    line7: 60.53,
  },
  {
    age: 39,
    line1: 48.781,
    line2: 50.76,
    line3: 52.74,
    line4: 54.719,
    line5: 56.698,
    line6: 58.677,
    line7: 60.656,
  },
  {
    age: 40,
    line1: 48.89,
    line2: 50.871,
    line3: 52.853,
    line4: 54.835,
    line5: 56.817,
    line6: 58.798,
    line7: 60.78,
  },
  {
    age: 41,
    line1: 48.996,
    line2: 50.98,
    line3: 52.965,
    line4: 54.95,
    line5: 56.935,
    line6: 58.919,
    line7: 60.904,
  },
  {
    age: 42,
    line1: 49.102,
    line2: 51.09,
    line3: 53.077,
    line4: 55.064,
    line5: 57.051,
    line6: 59.039,
    line7: 61.026,
  },
  {
    age: 43,
    line1: 49.207,
    line2: 51.197,
    line3: 53.187,
    line4: 55.178,
    line5: 57.168,
    line6: 59.158,
    line7: 61.148,
  },
  {
    age: 44,
    line1: 49.312,
    line2: 51.305,
    line3: 53.298,
    line4: 55.29,
    line5: 57.283,
    line6: 59.276,
    line7: 61.268,
  },
  {
    age: 45,
    line1: 49.415,
    line2: 51.411,
    line3: 53.407,
    line4: 55.402,
    line5: 57.398,
    line6: 59.393,
    line7: 61.389,
  },
  {
    age: 46,
    line1: 49.518,
    line2: 51.516,
    line3: 53.515,
    line4: 55.513,
    line5: 57.511,
    line6: 59.51,
    line7: 61.508,
  },
  {
    age: 47,
    line1: 49.621,
    line2: 51.621,
    line3: 53.622,
    line4: 55.623,
    line5: 57.624,
    line6: 59.625,
    line7: 61.625,
  },
  {
    age: 48,
    line1: 49.721,
    line2: 51.725,
    line3: 53.729,
    line4: 55.732,
    line5: 57.736,
    line6: 59.739,
    line7: 61.743,
  },
  {
    age: 49,
    line1: 49.822,
    line2: 51.828,
    line3: 53.834,
    line4: 55.841,
    line5: 57.847,
    line6: 59.853,
    line7: 61.86,
  },
  {
    age: 50,
    line1: 49.921,
    line2: 51.93,
    line3: 53.939,
    line4: 55.948,
    line5: 57.957,
    line6: 59.966,
    line7: 61.975,
  },
  {
    age: 51,
    line1: 50.021,
    line2: 52.032,
    line3: 54.044,
    line4: 56.055,
    line5: 58.066,
    line6: 60.077,
    line7: 62.089,
  },
  {
    age: 52,
    line1: 50.119,
    line2: 52.133,
    line3: 54.147,
    line4: 56.161,
    line5: 58.175,
    line6: 60.189,
    line7: 62.203,
  },
  {
    age: 53,
    line1: 50.216,
    line2: 52.233,
    line3: 54.249,
    line4: 56.266,
    line5: 58.283,
    line6: 60.299,
    line7: 62.316,
  },
  {
    age: 54,
    line1: 50.313,
    line2: 52.332,
    line3: 54.351,
    line4: 56.37,
    line5: 58.39,
    line6: 60.409,
    line7: 62.428,
  },
  {
    age: 55,
    line1: 50.409,
    line2: 52.43,
    line3: 54.452,
    line4: 56.474,
    line5: 58.496,
    line6: 60.517,
    line7: 62.539,
  },
  {
    age: 56,
    line1: 50.504,
    line2: 52.528,
    line3: 54.552,
    line4: 56.577,
    line5: 58.601,
    line6: 60.625,
    line7: 62.65,
  },
  {
    age: 57,
    line1: 50.598,
    line2: 52.625,
    line3: 54.652,
    line4: 56.679,
    line5: 58.706,
    line6: 60.732,
    line7: 62.759,
  },
  {
    age: 58,
    line1: 50.692,
    line2: 52.721,
    line3: 54.751,
    line4: 56.78,
    line5: 58.809,
    line6: 60.839,
    line7: 62.868,
  },
  {
    age: 59,
    line1: 50.785,
    line2: 52.817,
    line3: 54.849,
    line4: 56.881,
    line5: 58.912,
    line6: 60.944,
    line7: 62.976,
  },
  {
    age: 60,
    line1: 50.878,
    line2: 52.912,
    line3: 54.946,
    line4: 56.98,
    line5: 59.015,
    line6: 61.049,
    line7: 63.083,
  },
  {
    age: 61,
    line1: 50.97,
    line2: 53.006,
    line3: 55.043,
    line4: 57.08,
    line5: 59.116,
    line6: 61.153,
    line7: 63.189,
  },
  {
    age: 62,
    line1: 51.061,
    line2: 53.1,
    line3: 55.139,
    line4: 57.178,
    line5: 59.217,
    line6: 61.256,
    line7: 63.295,
  },
  {
    age: 63,
    line1: 51.152,
    line2: 53.193,
    line3: 55.235,
    line4: 57.276,
    line5: 59.317,
    line6: 61.359,
    line7: 63.4,
  },
  {
    age: 64,
    line1: 51.242,
    line2: 53.286,
    line3: 55.33,
    line4: 57.373,
    line5: 59.417,
    line6: 61.461,
    line7: 63.504,
  },
  {
    age: 65,
    line1: 51.33,
    line2: 53.377,
    line3: 55.423,
    line4: 57.47,
    line5: 59.516,
    line6: 61.563,
    line7: 63.609,
  },
  {
    age: 66,
    line1: 51.42,
    line2: 53.468,
    line3: 55.517,
    line4: 57.566,
    line5: 59.615,
    line6: 61.663,
    line7: 63.712,
  },
  {
    age: 67,
    line1: 51.508,
    line2: 53.559,
    line3: 55.61,
    line4: 57.661,
    line5: 59.712,
    line6: 61.763,
    line7: 63.814,
  },
  {
    age: 68,
    line1: 51.596,
    line2: 53.65,
    line3: 55.703,
    line4: 57.756,
    line5: 59.809,
    line6: 61.862,
    line7: 63.916,
  },
  {
    age: 69,
    line1: 51.684,
    line2: 53.739,
    line3: 55.795,
    line4: 57.85,
    line5: 59.906,
    line6: 61.961,
    line7: 64.016,
  },
  {
    age: 70,
    line1: 51.769,
    line2: 53.827,
    line3: 55.885,
    line4: 57.944,
    line5: 60.002,
    line6: 62.06,
    line7: 64.118,
  },
  {
    age: 71,
    line1: 51.856,
    line2: 53.916,
    line3: 55.976,
    line4: 58.036,
    line5: 60.097,
    line6: 62.157,
    line7: 64.217,
  },
  {
    age: 72,
    line1: 51.942,
    line2: 54.004,
    line3: 56.066,
    line4: 58.129,
    line5: 60.191,
    line6: 62.254,
    line7: 64.316,
  },
  {
    age: 73,
    line1: 52.025,
    line2: 54.09,
    line3: 56.156,
    line4: 58.221,
    line5: 60.286,
    line6: 62.351,
    line7: 64.416,
  },
  {
    age: 74,
    line1: 52.11,
    line2: 54.177,
    line3: 56.245,
    line4: 58.312,
    line5: 60.379,
    line6: 62.446,
    line7: 64.513,
  },
  {
    age: 75,
    line1: 52.195,
    line2: 54.264,
    line3: 56.333,
    line4: 58.402,
    line5: 60.471,
    line6: 62.541,
    line7: 64.61,
  },
  {
    age: 76,
    line1: 52.277,
    line2: 54.349,
    line3: 56.42,
    line4: 58.492,
    line5: 60.564,
    line6: 62.636,
    line7: 64.708,
  },
  {
    age: 77,
    line1: 52.36,
    line2: 54.434,
    line3: 56.508,
    line4: 58.582,
    line5: 60.655,
    line6: 62.729,
    line7: 64.803,
  },
  {
    age: 78,
    line1: 52.441,
    line2: 54.518,
    line3: 56.594,
    line4: 58.67,
    line5: 60.747,
    line6: 62.823,
    line7: 64.9,
  },
  {
    age: 79,
    line1: 52.524,
    line2: 54.602,
    line3: 56.681,
    line4: 58.759,
    line5: 60.837,
    line6: 62.915,
    line7: 64.994,
  },
  {
    age: 80,
    line1: 52.604,
    line2: 54.685,
    line3: 56.766,
    line4: 58.846,
    line5: 60.927,
    line6: 63.008,
    line7: 65.089,
  },
  {
    age: 81,
    line1: 52.686,
    line2: 54.768,
    line3: 56.851,
    line4: 58.934,
    line5: 61.016,
    line6: 63.099,
    line7: 65.182,
  },
  {
    age: 82,
    line1: 52.765,
    line2: 54.85,
    line3: 56.935,
    line4: 59.02,
    line5: 61.106,
    line6: 63.191,
    line7: 65.276,
  },
  {
    age: 83,
    line1: 52.845,
    line2: 54.932,
    line3: 57.02,
    line4: 59.107,
    line5: 61.194,
    line6: 63.281,
    line7: 65.368,
  },
  {
    age: 84,
    line1: 52.924,
    line2: 55.013,
    line3: 57.103,
    line4: 59.192,
    line5: 61.282,
    line6: 63.371,
    line7: 65.461,
  },
  {
    age: 85,
    line1: 53.003,
    line2: 55.095,
    line3: 57.186,
    line4: 59.277,
    line5: 61.369,
    line6: 63.46,
    line7: 65.551,
  },
  {
    age: 86,
    line1: 53.081,
    line2: 55.175,
    line3: 57.268,
    line4: 59.362,
    line5: 61.456,
    line6: 63.549,
    line7: 65.643,
  },
  {
    age: 87,
    line1: 53.158,
    line2: 55.254,
    line3: 57.35,
    line4: 59.446,
    line5: 61.542,
    line6: 63.638,
    line7: 65.734,
  },
  {
    age: 88,
    line1: 53.236,
    line2: 55.334,
    line3: 57.432,
    line4: 59.53,
    line5: 61.627,
    line6: 63.725,
    line7: 65.823,
  },
  {
    age: 89,
    line1: 53.312,
    line2: 55.412,
    line3: 57.512,
    line4: 59.613,
    line5: 61.713,
    line6: 63.813,
    line7: 65.913,
  },
  {
    age: 90,
    line1: 53.39,
    line2: 55.491,
    line3: 57.593,
    line4: 59.695,
    line5: 61.797,
    line6: 63.899,
    line7: 66.001,
  },
  {
    age: 91,
    line1: 53.465,
    line2: 55.569,
    line3: 57.673,
    line4: 59.777,
    line5: 61.881,
    line6: 63.986,
    line7: 66.09,
  },
  {
    age: 92,
    line1: 53.54,
    line2: 55.646,
    line3: 57.752,
    line4: 59.859,
    line5: 61.965,
    line6: 64.072,
    line7: 66.178,
  },
  {
    age: 93,
    line1: 53.616,
    line2: 55.724,
    line3: 57.832,
    line4: 59.94,
    line5: 62.048,
    line6: 64.156,
    line7: 66.264,
  },
  {
    age: 94,
    line1: 53.69,
    line2: 55.8,
    line3: 57.911,
    line4: 60.021,
    line5: 62.131,
    line6: 64.242,
    line7: 66.352,
  },
  {
    age: 95,
    line1: 53.763,
    line2: 55.876,
    line3: 57.989,
    line4: 60.101,
    line5: 62.214,
    line6: 64.326,
    line7: 66.439,
  },
  {
    age: 96,
    line1: 53.837,
    line2: 55.951,
    line3: 58.066,
    line4: 60.181,
    line5: 62.296,
    line6: 64.411,
    line7: 66.525,
  },
  {
    age: 97,
    line1: 53.911,
    line2: 56.028,
    line3: 58.144,
    line4: 60.26,
    line5: 62.377,
    line6: 64.493,
    line7: 66.609,
  },
  {
    age: 98,
    line1: 53.984,
    line2: 56.102,
    line3: 58.221,
    line4: 60.339,
    line5: 62.458,
    line6: 64.576,
    line7: 66.695,
  },
  {
    age: 99,
    line1: 54.056,
    line2: 56.176,
    line3: 58.297,
    line4: 60.418,
    line5: 62.538,
    line6: 64.659,
    line7: 66.78,
  },
  {
    age: 100,
    line1: 54.127,
    line2: 56.25,
    line3: 58.373,
    line4: 60.496,
    line5: 62.619,
    line6: 64.741,
    line7: 66.864,
  },
  {
    age: 101,
    line1: 54.199,
    line2: 56.324,
    line3: 58.448,
    line4: 60.573,
    line5: 62.698,
    line6: 64.823,
    line7: 66.948,
  },
  {
    age: 102,
    line1: 54.271,
    line2: 56.398,
    line3: 58.524,
    line4: 60.651,
    line5: 62.777,
    line6: 64.903,
    line7: 67.03,
  },
  {
    age: 103,
    line1: 54.342,
    line2: 56.47,
    line3: 58.599,
    line4: 60.727,
    line5: 62.856,
    line6: 64.984,
    line7: 67.113,
  },
  {
    age: 104,
    line1: 54.412,
    line2: 56.542,
    line3: 58.673,
    line4: 60.804,
    line5: 62.934,
    line6: 65.065,
    line7: 67.195,
  },
  {
    age: 105,
    line1: 54.482,
    line2: 56.614,
    line3: 58.747,
    line4: 60.88,
    line5: 63.012,
    line6: 65.145,
    line7: 67.277,
  },
  {
    age: 106,
    line1: 54.551,
    line2: 56.686,
    line3: 58.82,
    line4: 60.955,
    line5: 63.09,
    line6: 65.224,
    line7: 67.359,
  },
  {
    age: 107,
    line1: 54.62,
    line2: 56.757,
    line3: 58.893,
    line4: 61.03,
    line5: 63.167,
    line6: 65.303,
    line7: 67.44,
  },
  {
    age: 108,
    line1: 54.689,
    line2: 56.827,
    line3: 58.966,
    line4: 61.105,
    line5: 63.243,
    line6: 65.382,
    line7: 67.521,
  },
  {
    age: 109,
    line1: 54.757,
    line2: 56.898,
    line3: 59.038,
    line4: 61.179,
    line5: 63.32,
    line6: 65.46,
    line7: 67.601,
  },
  {
    age: 110,
    line1: 54.827,
    line2: 56.969,
    line3: 59.111,
    line4: 61.253,
    line5: 63.395,
    line6: 65.537,
    line7: 67.679,
  },
  {
    age: 111,
    line1: 54.894,
    line2: 57.038,
    line3: 59.182,
    line4: 61.326,
    line5: 63.47,
    line6: 65.614,
    line7: 67.758,
  },
  {
    age: 112,
    line1: 54.961,
    line2: 57.107,
    line3: 59.253,
    line4: 61.399,
    line5: 63.545,
    line6: 65.691,
    line7: 67.837,
  },
  {
    age: 113,
    line1: 55.028,
    line2: 57.176,
    line3: 59.324,
    line4: 61.472,
    line5: 63.62,
    line6: 65.767,
    line7: 67.915,
  },
  {
    age: 114,
    line1: 55.095,
    line2: 57.244,
    line3: 59.394,
    line4: 61.544,
    line5: 63.694,
    line6: 65.843,
    line7: 67.993,
  },
  {
    age: 115,
    line1: 55.161,
    line2: 57.312,
    line3: 59.464,
    line4: 61.616,
    line5: 63.767,
    line6: 65.919,
    line7: 68.07,
  },
  {
    age: 116,
    line1: 55.227,
    line2: 57.38,
    line3: 59.534,
    line4: 61.687,
    line5: 63.84,
    line6: 65.994,
    line7: 68.147,
  },
  {
    age: 117,
    line1: 55.292,
    line2: 57.447,
    line3: 59.603,
    line4: 61.758,
    line5: 63.913,
    line6: 66.069,
    line7: 68.224,
  },
  {
    age: 118,
    line1: 55.357,
    line2: 57.514,
    line3: 59.671,
    line4: 61.829,
    line5: 63.986,
    line6: 66.143,
    line7: 68.3,
  },
  {
    age: 119,
    line1: 55.422,
    line2: 57.581,
    line3: 59.74,
    line4: 61.899,
    line5: 64.058,
    line6: 66.217,
    line7: 68.376,
  },
  {
    age: 120,
    line1: 55.486,
    line2: 57.647,
    line3: 59.808,
    line4: 61.969,
    line5: 64.129,
    line6: 66.29,
    line7: 68.451,
  },
  {
    age: 121,
    line1: 55.548,
    line2: 57.712,
    line3: 59.875,
    line4: 62.038,
    line5: 64.201,
    line6: 66.365,
    line7: 68.528,
  },
  {
    age: 122,
    line1: 55.612,
    line2: 57.777,
    line3: 59.942,
    line4: 62.107,
    line5: 64.272,
    line6: 66.437,
    line7: 68.602,
  },
  {
    age: 123,
    line1: 55.675,
    line2: 57.842,
    line3: 60.009,
    line4: 62.176,
    line5: 64.343,
    line6: 66.509,
    line7: 68.676,
  },
  {
    age: 124,
    line1: 55.738,
    line2: 57.907,
    line3: 60.076,
    line4: 62.244,
    line5: 64.413,
    line6: 66.581,
    line7: 68.75,
  },
  {
    age: 125,
    line1: 55.801,
    line2: 57.971,
    line3: 60.142,
    line4: 62.312,
    line5: 64.482,
    line6: 66.653,
    line7: 68.823,
  },
  {
    age: 126,
    line1: 55.863,
    line2: 58.035,
    line3: 60.207,
    line4: 62.38,
    line5: 64.552,
    line6: 66.724,
    line7: 68.896,
  },
  {
    age: 127,
    line1: 55.925,
    line2: 58.099,
    line3: 60.273,
    line4: 62.447,
    line5: 64.62,
    line6: 66.794,
    line7: 68.968,
  },
  {
    age: 128,
    line1: 55.987,
    line2: 58.163,
    line3: 60.338,
    line4: 62.514,
    line5: 64.689,
    line6: 66.864,
    line7: 69.04,
  },
  {
    age: 129,
    line1: 56.049,
    line2: 58.226,
    line3: 60.403,
    line4: 62.58,
    line5: 64.757,
    line6: 66.934,
    line7: 69.111,
  },
  {
    age: 130,
    line1: 56.108,
    line2: 58.287,
    line3: 60.467,
    line4: 62.646,
    line5: 64.826,
    line6: 67.005,
    line7: 69.184,
  },
  {
    age: 131,
    line1: 56.168,
    line2: 58.35,
    line3: 60.531,
    line4: 62.712,
    line5: 64.893,
    line6: 67.074,
    line7: 69.255,
  },
  {
    age: 132,
    line1: 56.229,
    line2: 58.412,
    line3: 60.594,
    line4: 62.777,
    line5: 64.96,
    line6: 67.143,
    line7: 69.325,
  },
  {
    age: 133,
    line1: 56.289,
    line2: 58.474,
    line3: 60.658,
    line4: 62.842,
    line5: 65.027,
    line6: 67.211,
    line7: 69.395,
  },
  {
    age: 134,
    line1: 56.349,
    line2: 58.535,
    line3: 60.721,
    line4: 62.907,
    line5: 65.093,
    line6: 67.279,
    line7: 69.465,
  },
  {
    age: 135,
    line1: 56.407,
    line2: 58.595,
    line3: 60.783,
    line4: 62.971,
    line5: 65.16,
    line6: 67.348,
    line7: 69.536,
  },
  {
    age: 136,
    line1: 56.466,
    line2: 58.656,
    line3: 60.846,
    line4: 63.035,
    line5: 65.225,
    line6: 67.415,
    line7: 69.605,
  },
  {
    age: 137,
    line1: 56.525,
    line2: 58.716,
    line3: 60.908,
    line4: 63.099,
    line5: 65.291,
    line6: 67.482,
    line7: 69.673,
  },
  {
    age: 138,
    line1: 56.584,
    line2: 58.777,
    line3: 60.97,
    line4: 63.163,
    line5: 65.356,
    line6: 67.549,
    line7: 69.742,
  },
  {
    age: 139,
    line1: 56.642,
    line2: 58.837,
    line3: 61.031,
    line4: 63.226,
    line5: 65.42,
    line6: 67.615,
    line7: 69.809,
  },
  {
    age: 140,
    line1: 56.698,
    line2: 58.895,
    line3: 61.092,
    line4: 63.288,
    line5: 65.485,
    line6: 67.682,
    line7: 69.879,
  },
  {
    age: 141,
    line1: 56.756,
    line2: 58.954,
    line3: 61.153,
    line4: 63.351,
    line5: 65.549,
    line6: 67.747,
    line7: 69.946,
  },
  {
    age: 142,
    line1: 56.814,
    line2: 59.013,
    line3: 61.213,
    line4: 63.413,
    line5: 65.613,
    line6: 67.813,
    line7: 70.013,
  },
  {
    age: 143,
    line1: 56.869,
    line2: 59.071,
    line3: 61.273,
    line4: 63.475,
    line5: 65.677,
    line6: 67.879,
    line7: 70.081,
  },
  {
    age: 144,
    line1: 56.926,
    line2: 59.13,
    line3: 61.333,
    line4: 63.536,
    line5: 65.74,
    line6: 67.943,
    line7: 70.147,
  },
  {
    age: 145,
    line1: 56.983,
    line2: 59.188,
    line3: 61.393,
    line4: 63.598,
    line5: 65.803,
    line6: 68.008,
    line7: 70.213,
  },
  {
    age: 146,
    line1: 57.038,
    line2: 59.245,
    line3: 61.452,
    line4: 63.659,
    line5: 65.866,
    line6: 68.073,
    line7: 70.28,
  },
  {
    age: 147,
    line1: 57.094,
    line2: 59.303,
    line3: 61.511,
    line4: 63.72,
    line5: 65.928,
    line6: 68.137,
    line7: 70.345,
  },
  {
    age: 148,
    line1: 57.15,
    line2: 59.36,
    line3: 61.57,
    line4: 63.78,
    line5: 65.99,
    line6: 68.2,
    line7: 70.41,
  },
  {
    age: 149,
    line1: 57.204,
    line2: 59.416,
    line3: 61.628,
    line4: 63.84,
    line5: 66.052,
    line6: 68.264,
    line7: 70.476,
  },
  {
    age: 150,
    line1: 57.26,
    line2: 59.473,
    line3: 61.687,
    line4: 63.9,
    line5: 66.113,
    line6: 68.327,
    line7: 70.54,
  },
  {
    age: 151,
    line1: 57.315,
    line2: 59.53,
    line3: 61.745,
    line4: 63.96,
    line5: 66.175,
    line6: 68.39,
    line7: 70.604,
  },
  {
    age: 152,
    line1: 57.368,
    line2: 59.585,
    line3: 61.802,
    line4: 64.019,
    line5: 66.236,
    line6: 68.453,
    line7: 70.67,
  },
  {
    age: 153,
    line1: 57.423,
    line2: 59.641,
    line3: 61.86,
    line4: 64.078,
    line5: 66.296,
    line6: 68.515,
    line7: 70.733,
  },
  {
    age: 154,
    line1: 57.478,
    line2: 59.697,
    line3: 61.917,
    line4: 64.137,
    line5: 66.357,
    line6: 68.577,
    line7: 70.796,
  },
  {
    age: 155,
    line1: 57.53,
    line2: 59.752,
    line3: 61.974,
    line4: 64.196,
    line5: 66.417,
    line6: 68.639,
    line7: 70.861,
  },
  {
    age: 156,
    line1: 57.584,
    line2: 59.808,
    line3: 62.031,
    line4: 64.254,
    line5: 66.477,
    line6: 68.7,
    line7: 70.923,
  },
  {
    age: 157,
    line1: 57.636,
    line2: 59.862,
    line3: 62.087,
    line4: 64.312,
    line5: 66.537,
    line6: 68.762,
    line7: 70.988,
  },
  {
    age: 158,
    line1: 57.69,
    line2: 59.917,
    line3: 62.143,
    line4: 64.37,
    line5: 66.596,
    line6: 68.823,
    line7: 71.05,
  },
  {
    age: 159,
    line1: 57.742,
    line2: 59.97,
    line3: 62.199,
    line4: 64.428,
    line5: 66.656,
    line6: 68.885,
    line7: 71.113,
  },
  {
    age: 160,
    line1: 57.795,
    line2: 60.025,
    line3: 62.255,
    line4: 64.485,
    line5: 66.715,
    line6: 68.945,
    line7: 71.175,
  },
  {
    age: 161,
    line1: 57.849,
    line2: 60.08,
    line3: 62.311,
    line4: 64.542,
    line5: 66.773,
    line6: 69.005,
    line7: 71.236,
  },
  {
    age: 162,
    line1: 57.9,
    line2: 60.133,
    line3: 62.366,
    line4: 64.599,
    line5: 66.832,
    line6: 69.065,
    line7: 71.299,
  },
  {
    age: 163,
    line1: 57.952,
    line2: 60.187,
    line3: 62.421,
    line4: 64.656,
    line5: 66.89,
    line6: 69.125,
    line7: 71.359,
  },
  {
    age: 164,
    line1: 58.003,
    line2: 60.239,
    line3: 62.476,
    line4: 64.712,
    line5: 66.949,
    line6: 69.185,
    line7: 71.422,
  },
  {
    age: 165,
    line1: 58.056,
    line2: 60.293,
    line3: 62.531,
    line4: 64.769,
    line5: 67.007,
    line6: 69.244,
    line7: 71.482,
  },
  {
    age: 166,
    line1: 58.106,
    line2: 60.345,
    line3: 62.585,
    line4: 64.825,
    line5: 67.065,
    line6: 69.304,
    line7: 71.544,
  },
  {
    age: 167,
    line1: 58.158,
    line2: 60.399,
    line3: 62.64,
    line4: 64.881,
    line5: 67.122,
    line6: 69.363,
    line7: 71.604,
  },
  {
    age: 168,
    line1: 58.208,
    line2: 60.451,
    line3: 62.694,
    line4: 64.937,
    line5: 67.18,
    line6: 69.422,
    line7: 71.665,
  },
  {
    age: 169,
    line1: 58.26,
    line2: 60.504,
    line3: 62.748,
    line4: 64.992,
    line5: 67.236,
    line6: 69.48,
    line7: 71.725,
  },
  {
    age: 170,
    line1: 58.309,
    line2: 60.555,
    line3: 62.801,
    line4: 65.047,
    line5: 67.293,
    line6: 69.54,
    line7: 71.786,
  },
  {
    age: 171,
    line1: 58.359,
    line2: 60.607,
    line3: 62.855,
    line4: 65.103,
    line5: 67.351,
    line6: 69.599,
    line7: 71.847,
  },
  {
    age: 172,
    line1: 58.41,
    line2: 60.659,
    line3: 62.908,
    line4: 65.158,
    line5: 67.407,
    line6: 69.656,
    line7: 71.905,
  },
  {
    age: 173,
    line1: 58.459,
    line2: 60.71,
    line3: 62.961,
    line4: 65.212,
    line5: 67.463,
    line6: 69.715,
    line7: 71.966,
  },
  {
    age: 174,
    line1: 58.51,
    line2: 60.762,
    line3: 63.015,
    line4: 65.267,
    line5: 67.519,
    line6: 69.772,
    line7: 72.024,
  },
  {
    age: 175,
    line1: 58.559,
    line2: 60.813,
    line3: 63.067,
    line4: 65.321,
    line5: 67.576,
    line6: 69.83,
    line7: 72.084,
  },
  {
    age: 176,
    line1: 58.609,
    line2: 60.865,
    line3: 63.12,
    line4: 65.376,
    line5: 67.631,
    line6: 69.887,
    line7: 72.142,
  },
  {
    age: 177,
    line1: 58.658,
    line2: 60.915,
    line3: 63.172,
    line4: 65.43,
    line5: 67.687,
    line6: 69.944,
    line7: 72.202,
  },
  {
    age: 178,
    line1: 58.706,
    line2: 60.965,
    line3: 63.225,
    line4: 65.484,
    line5: 67.743,
    line6: 70.002,
    line7: 72.261,
  },
  {
    age: 179,
    line1: 58.756,
    line2: 61.017,
    line3: 63.277,
    line4: 65.538,
    line5: 67.798,
    line6: 70.058,
    line7: 72.319,
  },
  {
    age: 180,
    line1: 58.804,
    line2: 61.067,
    line3: 63.329,
    line4: 65.591,
    line5: 67.853,
    line6: 70.116,
    line7: 72.378,
  },
  {
    age: 181,
    line1: 58.852,
    line2: 61.116,
    line3: 63.38,
    line4: 65.644,
    line5: 67.909,
    line6: 70.173,
    line7: 72.437,
  },
  {
    age: 182,
    line1: 58.902,
    line2: 61.167,
    line3: 63.433,
    line4: 65.698,
    line5: 67.963,
    line6: 70.228,
    line7: 72.494,
  },
  {
    age: 183,
    line1: 58.95,
    line2: 61.217,
    line3: 63.484,
    line4: 65.751,
    line5: 68.018,
    line6: 70.285,
    line7: 72.552,
  },
  {
    age: 184,
    line1: 58.999,
    line2: 61.267,
    line3: 63.536,
    line4: 65.804,
    line5: 68.072,
    line6: 70.341,
    line7: 72.609,
  },
  {
    age: 185,
    line1: 59.047,
    line2: 61.317,
    line3: 63.587,
    line4: 65.857,
    line5: 68.127,
    line6: 70.397,
    line7: 72.667,
  },
  {
    age: 186,
    line1: 59.094,
    line2: 61.366,
    line3: 63.638,
    line4: 65.91,
    line5: 68.181,
    line6: 70.453,
    line7: 72.725,
  },
  {
    age: 187,
    line1: 59.141,
    line2: 61.415,
    line3: 63.688,
    line4: 65.962,
    line5: 68.236,
    line6: 70.51,
    line7: 72.783,
  },
  {
    age: 188,
    line1: 59.19,
    line2: 61.465,
    line3: 63.74,
    line4: 66.014,
    line5: 68.289,
    line6: 70.564,
    line7: 72.839,
  },
  {
    age: 189,
    line1: 59.237,
    line2: 61.513,
    line3: 63.79,
    line4: 66.067,
    line5: 68.343,
    line6: 70.62,
    line7: 72.897,
  },
  {
    age: 190,
    line1: 59.284,
    line2: 61.562,
    line3: 63.84,
    line4: 66.119,
    line5: 68.397,
    line6: 70.676,
    line7: 72.954,
  },
  {
    age: 191,
    line1: 59.332,
    line2: 61.612,
    line3: 63.891,
    line4: 66.171,
    line5: 68.45,
    line6: 70.73,
    line7: 73.01,
  },
  {
    age: 192,
    line1: 59.379,
    line2: 61.66,
    line3: 63.941,
    line4: 66.223,
    line5: 68.504,
    line6: 70.786,
    line7: 73.067,
  },
  {
    age: 193,
    line1: 59.425,
    line2: 61.708,
    line3: 63.991,
    line4: 66.274,
    line5: 68.558,
    line6: 70.841,
    line7: 73.124,
  },
  {
    age: 194,
    line1: 59.473,
    line2: 61.758,
    line3: 64.042,
    line4: 66.326,
    line5: 68.61,
    line6: 70.895,
    line7: 73.179,
  },
  {
    age: 195,
    line1: 59.519,
    line2: 61.806,
    line3: 64.092,
    line4: 66.378,
    line5: 68.664,
    line6: 70.95,
    line7: 73.236,
  },
  {
    age: 196,
    line1: 59.566,
    line2: 61.853,
    line3: 64.141,
    line4: 66.429,
    line5: 68.717,
    line6: 71.005,
    line7: 73.292,
  },
  {
    age: 197,
    line1: 59.611,
    line2: 61.901,
    line3: 64.191,
    line4: 66.48,
    line5: 68.77,
    line6: 71.059,
    line7: 73.349,
  },
  {
    age: 198,
    line1: 59.657,
    line2: 61.949,
    line3: 64.24,
    line4: 66.531,
    line5: 68.823,
    line6: 71.114,
    line7: 73.405,
  },
  {
    age: 199,
    line1: 59.705,
    line2: 61.997,
    line3: 64.29,
    line4: 66.582,
    line5: 68.875,
    line6: 71.167,
    line7: 73.46,
  },
  {
    age: 200,
    line1: 59.751,
    line2: 62.045,
    line3: 64.339,
    line4: 66.633,
    line5: 68.927,
    line6: 71.221,
    line7: 73.516,
  },
  {
    age: 201,
    line1: 59.796,
    line2: 62.092,
    line3: 64.388,
    line4: 66.684,
    line5: 68.98,
    line6: 71.276,
    line7: 73.572,
  },
  {
    age: 202,
    line1: 59.841,
    line2: 62.139,
    line3: 64.437,
    line4: 66.734,
    line5: 69.032,
    line6: 71.33,
    line7: 73.628,
  },
  {
    age: 203,
    line1: 59.889,
    line2: 62.188,
    line3: 64.486,
    line4: 66.785,
    line5: 69.084,
    line6: 71.382,
    line7: 73.681,
  },
  {
    age: 204,
    line1: 59.934,
    line2: 62.234,
    line3: 64.535,
    line4: 66.835,
    line5: 69.136,
    line6: 71.436,
    line7: 73.737,
  },
  {
    age: 205,
    line1: 59.979,
    line2: 62.281,
    line3: 64.583,
    line4: 66.886,
    line5: 69.188,
    line6: 71.49,
    line7: 73.792,
  },
  {
    age: 206,
    line1: 60.024,
    line2: 62.328,
    line3: 64.632,
    line4: 66.936,
    line5: 69.24,
    line6: 71.544,
    line7: 73.848,
  },
  {
    age: 207,
    line1: 60.069,
    line2: 62.375,
    line3: 64.68,
    line4: 66.986,
    line5: 69.292,
    line6: 71.597,
    line7: 73.903,
  },
  {
    age: 208,
    line1: 60.114,
    line2: 62.421,
    line3: 64.729,
    line4: 67.036,
    line5: 69.343,
    line6: 71.651,
    line7: 73.958,
  },
  {
    age: 209,
    line1: 60.161,
    line2: 62.469,
    line3: 64.777,
    line4: 67.086,
    line5: 69.394,
    line6: 71.703,
    line7: 74.011,
  },
  {
    age: 210,
    line1: 60.205,
    line2: 62.515,
    line3: 64.825,
    line4: 67.136,
    line5: 69.446,
    line6: 71.756,
    line7: 74.066,
  },
  {
    age: 211,
    line1: 60.25,
    line2: 62.562,
    line3: 64.873,
    line4: 67.185,
    line5: 69.497,
    line6: 71.809,
    line7: 74.121,
  },
  {
    age: 212,
    line1: 60.294,
    line2: 62.608,
    line3: 64.921,
    line4: 67.235,
    line5: 69.548,
    line6: 71.862,
    line7: 74.175,
  },
  {
    age: 213,
    line1: 60.338,
    line2: 62.654,
    line3: 64.969,
    line4: 67.284,
    line5: 69.599,
    line6: 71.915,
    line7: 74.23,
  },
  {
    age: 214,
    line1: 60.383,
    line2: 62.7,
    line3: 65.017,
    line4: 67.334,
    line5: 69.65,
    line6: 71.967,
    line7: 74.284,
  },
  {
    age: 215,
    line1: 60.427,
    line2: 62.746,
    line3: 65.064,
    line4: 67.383,
    line5: 69.701,
    line6: 72.02,
    line7: 74.339,
  },
  {
    age: 216,
    line1: 60.471,
    line2: 62.791,
    line3: 65.112,
    line4: 67.432,
    line5: 69.752,
    line6: 72.073,
    line7: 74.393,
  },
  {
    age: 217,
    line1: 60.517,
    line2: 62.838,
    line3: 65.16,
    line4: 67.481,
    line5: 69.802,
    line6: 72.124,
    line7: 74.445,
  },
  {
    age: 218,
    line1: 60.561,
    line2: 62.884,
    line3: 65.207,
    line4: 67.53,
    line5: 69.853,
    line6: 72.176,
    line7: 74.499,
  },
  {
    age: 219,
    line1: 60.605,
    line2: 62.929,
    line3: 65.254,
    line4: 67.579,
    line5: 69.904,
    line6: 72.228,
    line7: 74.553,
  },
  {
    age: 220,
    line1: 60.649,
    line2: 62.975,
    line3: 65.301,
    line4: 67.628,
    line5: 69.954,
    line6: 72.28,
    line7: 74.607,
  },
  {
    age: 221,
    line1: 60.692,
    line2: 63.02,
    line3: 65.348,
    line4: 67.676,
    line5: 70.004,
    line6: 72.333,
    line7: 74.661,
  },
  {
    age: 222,
    line1: 60.736,
    line2: 63.066,
    line3: 65.395,
    line4: 67.725,
    line5: 70.055,
    line6: 72.384,
    line7: 74.714,
  },
  {
    age: 223,
    line1: 60.779,
    line2: 63.111,
    line3: 65.442,
    line4: 67.774,
    line5: 70.105,
    line6: 72.436,
    line7: 74.768,
  },
  {
    age: 224,
    line1: 60.823,
    line2: 63.156,
    line3: 65.489,
    line4: 67.822,
    line5: 70.155,
    line6: 72.488,
    line7: 74.821,
  },
  {
    age: 225,
    line1: 60.866,
    line2: 63.201,
    line3: 65.536,
    line4: 67.87,
    line5: 70.205,
    line6: 72.54,
    line7: 74.875,
  },
  {
    age: 226,
    line1: 60.909,
    line2: 63.246,
    line3: 65.582,
    line4: 67.918,
    line5: 70.255,
    line6: 72.591,
    line7: 74.928,
  },
  {
    age: 227,
    line1: 60.953,
    line2: 63.291,
    line3: 65.629,
    line4: 67.967,
    line5: 70.305,
    line6: 72.643,
    line7: 74.981,
  },
  {
    age: 228,
    line1: 60.996,
    line2: 63.335,
    line3: 65.675,
    line4: 68.015,
    line5: 70.355,
    line6: 72.694,
    line7: 75.034,
  },
  {
    age: 229,
    line1: 61.039,
    line2: 63.38,
    line3: 65.721,
    line4: 68.063,
    line5: 70.404,
    line6: 72.746,
    line7: 75.087,
  },
  {
    age: 230,
    line1: 61.082,
    line2: 63.425,
    line3: 65.768,
    line4: 68.111,
    line5: 70.454,
    line6: 72.797,
    line7: 75.14,
  },
  {
    age: 231,
    line1: 61.125,
    line2: 63.469,
    line3: 65.814,
    line4: 68.158,
    line5: 70.503,
    line6: 72.848,
    line7: 75.192,
  },
  {
    age: 232,
    line1: 61.167,
    line2: 63.514,
    line3: 65.86,
    line4: 68.206,
    line5: 70.553,
    line6: 72.899,
    line7: 75.245,
  },
  {
    age: 233,
    line1: 61.21,
    line2: 63.558,
    line3: 65.906,
    line4: 68.254,
    line5: 70.602,
    line6: 72.95,
    line7: 75.298,
  },
  {
    age: 234,
    line1: 61.253,
    line2: 63.602,
    line3: 65.952,
    line4: 68.302,
    line5: 70.651,
    line6: 73.001,
    line7: 75.35,
  },
  {
    age: 235,
    line1: 61.295,
    line2: 63.647,
    line3: 65.998,
    line4: 68.349,
    line5: 70.7,
    line6: 73.052,
    line7: 75.403,
  },
  {
    age: 236,
    line1: 61.338,
    line2: 63.691,
    line3: 66.044,
    line4: 68.396,
    line5: 70.749,
    line6: 73.102,
    line7: 75.455,
  },
  {
    age: 237,
    line1: 61.38,
    line2: 63.735,
    line3: 66.089,
    line4: 68.444,
    line5: 70.798,
    line6: 73.153,
    line7: 75.507,
  },
  {
    age: 238,
    line1: 61.423,
    line2: 63.779,
    line3: 66.135,
    line4: 68.491,
    line5: 70.847,
    line6: 73.203,
    line7: 75.559,
  },
  {
    age: 239,
    line1: 61.465,
    line2: 63.823,
    line3: 66.181,
    line4: 68.538,
    line5: 70.896,
    line6: 73.254,
    line7: 75.611,
  },
  {
    age: 240,
    line1: 61.507,
    line2: 63.867,
    line3: 66.226,
    line4: 68.586,
    line5: 70.945,
    line6: 73.304,
    line7: 75.664,
  },
  {
    age: 241,
    line1: 61.55,
    line2: 63.911,
    line3: 66.272,
    line4: 68.632,
    line5: 70.993,
    line6: 73.354,
    line7: 75.715,
  },
  {
    age: 242,
    line1: 61.592,
    line2: 63.954,
    line3: 66.317,
    line4: 68.68,
    line5: 71.042,
    line6: 73.405,
    line7: 75.767,
  },
  {
    age: 243,
    line1: 61.634,
    line2: 63.998,
    line3: 66.362,
    line4: 68.726,
    line5: 71.091,
    line6: 73.455,
    line7: 75.819,
  },
  {
    age: 244,
    line1: 61.676,
    line2: 64.042,
    line3: 66.407,
    line4: 68.773,
    line5: 71.139,
    line6: 73.505,
    line7: 75.871,
  },
  {
    age: 245,
    line1: 61.718,
    line2: 64.085,
    line3: 66.453,
    line4: 68.82,
    line5: 71.187,
    line6: 73.555,
    line7: 75.922,
  },
  {
    age: 246,
    line1: 61.76,
    line2: 64.129,
    line3: 66.498,
    line4: 68.867,
    line5: 71.236,
    line6: 73.605,
    line7: 75.974,
  },
  {
    age: 247,
    line1: 61.801,
    line2: 64.172,
    line3: 66.543,
    line4: 68.913,
    line5: 71.284,
    line6: 73.655,
    line7: 76.025,
  },
  {
    age: 248,
    line1: 61.843,
    line2: 64.215,
    line3: 66.588,
    line4: 68.96,
    line5: 71.332,
    line6: 73.704,
    line7: 76.076,
  },
  {
    age: 249,
    line1: 61.885,
    line2: 64.259,
    line3: 66.632,
    line4: 69.006,
    line5: 71.38,
    line6: 73.754,
    line7: 76.128,
  },
  {
    age: 250,
    line1: 61.926,
    line2: 64.302,
    line3: 66.677,
    line4: 69.053,
    line5: 71.428,
    line6: 73.804,
    line7: 76.179,
  },
  {
    age: 251,
    line1: 61.966,
    line2: 64.344,
    line3: 66.721,
    line4: 69.099,
    line5: 71.477,
    line6: 73.854,
    line7: 76.232,
  },
  {
    age: 252,
    line1: 62.007,
    line2: 64.387,
    line3: 66.766,
    line4: 69.145,
    line5: 71.524,
    line6: 73.904,
    line7: 76.283,
  },
  {
    age: 253,
    line1: 62.049,
    line2: 64.43,
    line3: 66.811,
    line4: 69.191,
    line5: 71.572,
    line6: 73.953,
    line7: 76.334,
  },
  {
    age: 254,
    line1: 62.09,
    line2: 64.473,
    line3: 66.855,
    line4: 69.238,
    line5: 71.62,
    line6: 74.003,
    line7: 76.385,
  },
  {
    age: 255,
    line1: 62.131,
    line2: 64.516,
    line3: 66.9,
    line4: 69.284,
    line5: 71.668,
    line6: 74.052,
    line7: 76.436,
  },
  {
    age: 256,
    line1: 62.173,
    line2: 64.558,
    line3: 66.944,
    line4: 69.33,
    line5: 71.715,
    line6: 74.101,
    line7: 76.486,
  },
  {
    age: 257,
    line1: 62.214,
    line2: 64.601,
    line3: 66.988,
    line4: 69.376,
    line5: 71.763,
    line6: 74.15,
    line7: 76.537,
  },
  {
    age: 258,
    line1: 62.255,
    line2: 64.644,
    line3: 67.033,
    line4: 69.421,
    line5: 71.81,
    line6: 74.199,
    line7: 76.588,
  },
  {
    age: 259,
    line1: 62.296,
    line2: 64.686,
    line3: 67.077,
    line4: 69.467,
    line5: 71.858,
    line6: 74.248,
    line7: 76.638,
  },
  {
    age: 260,
    line1: 62.335,
    line2: 64.728,
    line3: 67.12,
    line4: 69.513,
    line5: 71.906,
    line6: 74.298,
    line7: 76.691,
  },
  {
    age: 261,
    line1: 62.376,
    line2: 64.77,
    line3: 67.164,
    line4: 69.558,
    line5: 71.953,
    line6: 74.347,
    line7: 76.741,
  },
  {
    age: 262,
    line1: 62.417,
    line2: 64.813,
    line3: 67.208,
    line4: 69.604,
    line5: 72,
    line6: 74.396,
    line7: 76.791,
  },
  {
    age: 263,
    line1: 62.458,
    line2: 64.855,
    line3: 67.252,
    line4: 69.65,
    line5: 72.047,
    line6: 74.444,
    line7: 76.842,
  },
  {
    age: 264,
    line1: 62.498,
    line2: 64.897,
    line3: 67.296,
    line4: 69.695,
    line5: 72.094,
    line6: 74.493,
    line7: 76.892,
  },
  {
    age: 265,
    line1: 62.539,
    line2: 64.94,
    line3: 67.34,
    line4: 69.74,
    line5: 72.141,
    line6: 74.541,
    line7: 76.942,
  },
  {
    age: 266,
    line1: 62.578,
    line2: 64.98,
    line3: 67.383,
    line4: 69.786,
    line5: 72.189,
    line6: 74.591,
    line7: 76.994,
  },
  {
    age: 267,
    line1: 62.618,
    line2: 65.023,
    line3: 67.427,
    line4: 69.831,
    line5: 72.235,
    line6: 74.64,
    line7: 77.044,
  },
  {
    age: 268,
    line1: 62.659,
    line2: 65.065,
    line3: 67.47,
    line4: 69.876,
    line5: 72.282,
    line6: 74.688,
    line7: 77.094,
  },
  {
    age: 269,
    line1: 62.699,
    line2: 65.107,
    line3: 67.514,
    line4: 69.922,
    line5: 72.329,
    line6: 74.736,
    line7: 77.144,
  },
  {
    age: 270,
    line1: 62.74,
    line2: 65.149,
    line3: 67.558,
    line4: 69.967,
    line5: 72.376,
    line6: 74.785,
    line7: 77.193,
  },
  {
    age: 271,
    line1: 62.778,
    line2: 65.189,
    line3: 67.6,
    line4: 70.012,
    line5: 72.423,
    line6: 74.834,
    line7: 77.245,
  },
  {
    age: 272,
    line1: 62.818,
    line2: 65.231,
    line3: 67.644,
    line4: 70.057,
    line5: 72.469,
    line6: 74.882,
    line7: 77.295,
  },
  {
    age: 273,
    line1: 62.859,
    line2: 65.273,
    line3: 67.687,
    line4: 70.102,
    line5: 72.516,
    line6: 74.93,
    line7: 77.344,
  },
  {
    age: 274,
    line1: 62.899,
    line2: 65.315,
    line3: 67.73,
    line4: 70.146,
    line5: 72.562,
    line6: 74.978,
    line7: 77.394,
  },
  {
    age: 275,
    line1: 62.939,
    line2: 65.356,
    line3: 67.774,
    line4: 70.191,
    line5: 72.608,
    line6: 75.026,
    line7: 77.443,
  },
  {
    age: 276,
    line1: 62.977,
    line2: 65.397,
    line3: 67.816,
    line4: 70.236,
    line5: 72.655,
    line6: 75.075,
    line7: 77.495,
  },
  {
    age: 277,
    line1: 63.017,
    line2: 65.438,
    line3: 67.859,
    line4: 70.28,
    line5: 72.702,
    line6: 75.123,
    line7: 77.544,
  },
  {
    age: 278,
    line1: 63.057,
    line2: 65.48,
    line3: 67.902,
    line4: 70.325,
    line5: 72.748,
    line6: 75.17,
    line7: 77.593,
  },
  {
    age: 279,
    line1: 63.097,
    line2: 65.521,
    line3: 67.945,
    line4: 70.37,
    line5: 72.794,
    line6: 75.218,
    line7: 77.642,
  },
  {
    age: 280,
    line1: 63.137,
    line2: 65.563,
    line3: 67.988,
    line4: 70.414,
    line5: 72.84,
    line6: 75.266,
    line7: 77.692,
  },
  {
    age: 281,
    line1: 63.175,
    line2: 65.603,
    line3: 68.031,
    line4: 70.459,
    line5: 72.887,
    line6: 75.315,
    line7: 77.743,
  },
  {
    age: 282,
    line1: 63.214,
    line2: 65.644,
    line3: 68.073,
    line4: 70.503,
    line5: 72.933,
    line6: 75.362,
    line7: 77.792,
  },
  {
    age: 283,
    line1: 63.254,
    line2: 65.685,
    line3: 68.116,
    line4: 70.547,
    line5: 72.978,
    line6: 75.41,
    line7: 77.841,
  },
  {
    age: 284,
    line1: 63.294,
    line2: 65.727,
    line3: 68.159,
    line4: 70.592,
    line5: 73.024,
    line6: 75.457,
    line7: 77.889,
  },
  {
    age: 285,
    line1: 63.331,
    line2: 65.766,
    line3: 68.201,
    line4: 70.636,
    line5: 73.071,
    line6: 75.506,
    line7: 77.94,
  },
  {
    age: 286,
    line1: 63.371,
    line2: 65.807,
    line3: 68.244,
    line4: 70.68,
    line5: 73.116,
    line6: 75.553,
    line7: 77.989,
  },
  {
    age: 287,
    line1: 63.411,
    line2: 65.848,
    line3: 68.286,
    line4: 70.724,
    line5: 73.162,
    line6: 75.6,
    line7: 78.038,
  },
  {
    age: 288,
    line1: 63.448,
    line2: 65.888,
    line3: 68.328,
    line4: 70.768,
    line5: 73.208,
    line6: 75.648,
    line7: 78.088,
  },
  {
    age: 289,
    line1: 63.487,
    line2: 65.929,
    line3: 68.371,
    line4: 70.812,
    line5: 73.254,
    line6: 75.695,
    line7: 78.137,
  },
  {
    age: 290,
    line1: 63.527,
    line2: 65.97,
    line3: 68.413,
    line4: 70.856,
    line5: 73.299,
    line6: 75.742,
    line7: 78.185,
  },
  {
    age: 291,
    line1: 63.566,
    line2: 66.011,
    line3: 68.455,
    line4: 70.9,
    line5: 73.345,
    line6: 75.789,
    line7: 78.234,
  },
  {
    age: 292,
    line1: 63.603,
    line2: 66.05,
    line3: 68.497,
    line4: 70.944,
    line5: 73.391,
    line6: 75.838,
    line7: 78.284,
  },
  {
    age: 293,
    line1: 63.643,
    line2: 66.091,
    line3: 68.539,
    line4: 70.988,
    line5: 73.436,
    line6: 75.884,
    line7: 78.333,
  },
  {
    age: 294,
    line1: 63.682,
    line2: 66.132,
    line3: 68.582,
    line4: 71.031,
    line5: 73.481,
    line6: 75.931,
    line7: 78.381,
  },
  {
    age: 295,
    line1: 63.719,
    line2: 66.171,
    line3: 68.623,
    line4: 71.075,
    line5: 73.527,
    line6: 75.979,
    line7: 78.431,
  },
  {
    age: 296,
    line1: 63.758,
    line2: 66.212,
    line3: 68.665,
    line4: 71.119,
    line5: 73.572,
    line6: 76.026,
    line7: 78.479,
  },
  {
    age: 297,
    line1: 63.797,
    line2: 66.252,
    line3: 68.707,
    line4: 71.162,
    line5: 73.617,
    line6: 76.072,
    line7: 78.527,
  },
  {
    age: 298,
    line1: 63.836,
    line2: 66.293,
    line3: 68.749,
    line4: 71.206,
    line5: 73.662,
    line6: 76.119,
    line7: 78.575,
  },
  {
    age: 299,
    line1: 63.873,
    line2: 66.332,
    line3: 68.79,
    line4: 71.249,
    line5: 73.708,
    line6: 76.167,
    line7: 78.626,
  },
  {
    age: 300,
    line1: 63.912,
    line2: 66.372,
    line3: 68.832,
    line4: 71.293,
    line5: 73.753,
    line6: 76.213,
    line7: 78.674,
  },
  {
    age: 301,
    line1: 63.95,
    line2: 66.412,
    line3: 68.874,
    line4: 71.336,
    line5: 73.798,
    line6: 76.26,
    line7: 78.721,
  },
  {
    age: 302,
    line1: 63.987,
    line2: 66.451,
    line3: 68.915,
    line4: 71.379,
    line5: 73.843,
    line6: 76.307,
    line7: 78.771,
  },
  {
    age: 303,
    line1: 64.026,
    line2: 66.491,
    line3: 68.957,
    line4: 71.422,
    line5: 73.888,
    line6: 76.353,
    line7: 78.819,
  },
  {
    age: 304,
    line1: 64.065,
    line2: 66.532,
    line3: 68.999,
    line4: 71.466,
    line5: 73.933,
    line6: 76.4,
    line7: 78.867,
  },
  {
    age: 305,
    line1: 64.101,
    line2: 66.57,
    line3: 69.04,
    line4: 71.509,
    line5: 73.978,
    line6: 76.447,
    line7: 78.916,
  },
  {
    age: 306,
    line1: 64.14,
    line2: 66.61,
    line3: 69.081,
    line4: 71.552,
    line5: 74.022,
    line6: 76.493,
    line7: 78.964,
  },
  {
    age: 307,
    line1: 64.178,
    line2: 66.651,
    line3: 69.123,
    line4: 71.595,
    line5: 74.067,
    line6: 76.539,
    line7: 79.011,
  },
  {
    age: 308,
    line1: 64.215,
    line2: 66.689,
    line3: 69.163,
    line4: 71.638,
    line5: 74.112,
    line6: 76.587,
    line7: 79.061,
  },
  {
    age: 309,
    line1: 64.253,
    line2: 66.729,
    line3: 69.205,
    line4: 71.681,
    line5: 74.157,
    line6: 76.633,
    line7: 79.108,
  },
  {
    age: 310,
    line1: 64.292,
    line2: 66.769,
    line3: 69.246,
    line4: 71.724,
    line5: 74.201,
    line6: 76.678,
    line7: 79.156,
  },
  {
    age: 311,
    line1: 64.328,
    line2: 66.807,
    line3: 69.287,
    line4: 71.766,
    line5: 74.246,
    line6: 76.725,
    line7: 79.205,
  },
  {
    age: 312,
    line1: 64.366,
    line2: 66.847,
    line3: 69.328,
    line4: 71.809,
    line5: 74.29,
    line6: 76.771,
    line7: 79.252,
  },
  {
    age: 313,
    line1: 64.402,
    line2: 66.885,
    line3: 69.369,
    line4: 71.852,
    line5: 74.335,
    line6: 76.818,
    line7: 79.302,
  },
  {
    age: 314,
    line1: 64.441,
    line2: 66.925,
    line3: 69.41,
    line4: 71.895,
    line5: 74.379,
    line6: 76.864,
    line7: 79.349,
  },
  {
    age: 315,
    line1: 64.479,
    line2: 66.965,
    line3: 69.451,
    line4: 71.937,
    line5: 74.423,
    line6: 76.909,
    line7: 79.396,
  },
  {
    age: 316,
    line1: 64.515,
    line2: 67.003,
    line3: 69.491,
    line4: 71.98,
    line5: 74.468,
    line6: 76.956,
    line7: 79.445,
  },
  {
    age: 317,
    line1: 64.553,
    line2: 67.043,
    line3: 69.532,
    line4: 72.022,
    line5: 74.512,
    line6: 77.002,
    line7: 79.492,
  },
  {
    age: 318,
    line1: 64.591,
    line2: 67.082,
    line3: 69.573,
    line4: 72.065,
    line5: 74.556,
    line6: 77.047,
    line7: 79.539,
  },
  {
    age: 319,
    line1: 64.627,
    line2: 67.12,
    line3: 69.614,
    line4: 72.107,
    line5: 74.601,
    line6: 77.094,
    line7: 79.587,
  },
  {
    age: 320,
    line1: 64.665,
    line2: 67.16,
    line3: 69.655,
    line4: 72.15,
    line5: 74.644,
    line6: 77.139,
    line7: 79.634,
  },
  {
    age: 321,
    line1: 64.7,
    line2: 67.198,
    line3: 69.695,
    line4: 72.192,
    line5: 74.689,
    line6: 77.186,
    line7: 79.683,
  },
  {
    age: 322,
    line1: 64.738,
    line2: 67.237,
    line3: 69.735,
    line4: 72.234,
    line5: 74.733,
    line6: 77.231,
    line7: 79.73,
  },
  {
    age: 323,
    line1: 64.776,
    line2: 67.276,
    line3: 69.776,
    line4: 72.276,
    line5: 74.776,
    line6: 77.276,
    line7: 79.776,
  },
  {
    age: 324,
    line1: 64.812,
    line2: 67.314,
    line3: 69.816,
    line4: 72.318,
    line5: 74.821,
    line6: 77.323,
    line7: 79.825,
  },
  {
    age: 325,
    line1: 64.849,
    line2: 67.353,
    line3: 69.857,
    line4: 72.36,
    line5: 74.864,
    line6: 77.368,
    line7: 79.872,
  },
  {
    age: 326,
    line1: 64.885,
    line2: 67.391,
    line3: 69.897,
    line4: 72.402,
    line5: 74.908,
    line6: 77.414,
    line7: 79.92,
  },
  {
    age: 327,
    line1: 64.923,
    line2: 67.43,
    line3: 69.937,
    line4: 72.444,
    line5: 74.952,
    line6: 77.459,
    line7: 79.966,
  },
  {
    age: 328,
    line1: 64.96,
    line2: 67.469,
    line3: 69.978,
    line4: 72.486,
    line5: 74.995,
    line6: 77.504,
    line7: 80.013,
  },
  {
    age: 329,
    line1: 64.996,
    line2: 67.507,
    line3: 70.017,
    line4: 72.528,
    line5: 75.039,
    line6: 77.55,
    line7: 80.061,
  },
  {
    age: 330,
    line1: 65.033,
    line2: 67.545,
    line3: 70.058,
    line4: 72.57,
    line5: 75.083,
    line6: 77.595,
    line7: 80.107,
  },
  {
    age: 331,
    line1: 65.068,
    line2: 67.583,
    line3: 70.097,
    line4: 72.612,
    line5: 75.127,
    line6: 77.641,
    line7: 80.156,
  },
  {
    age: 332,
    line1: 65.106,
    line2: 67.622,
    line3: 70.138,
    line4: 72.654,
    line5: 75.17,
    line6: 77.686,
    line7: 80.202,
  },
  {
    age: 333,
    line1: 65.141,
    line2: 67.659,
    line3: 70.177,
    line4: 72.696,
    line5: 75.214,
    line6: 77.732,
    line7: 80.25,
  },
  {
    age: 334,
    line1: 65.178,
    line2: 67.698,
    line3: 70.218,
    line4: 72.737,
    line5: 75.257,
    line6: 77.776,
    line7: 80.296,
  },
  {
    age: 335,
    line1: 65.216,
    line2: 67.737,
    line3: 70.258,
    line4: 72.779,
    line5: 75.3,
    line6: 77.821,
    line7: 80.342,
  },
  {
    age: 336,
    line1: 65.251,
    line2: 67.774,
    line3: 70.297,
    line4: 72.82,
    line5: 75.344,
    line6: 77.867,
    line7: 80.39,
  },
  {
    age: 337,
    line1: 65.288,
    line2: 67.812,
    line3: 70.337,
    line4: 72.862,
    line5: 75.386,
    line6: 77.911,
    line7: 80.436,
  },
  {
    age: 338,
    line1: 65.323,
    line2: 67.85,
    line3: 70.376,
    line4: 72.903,
    line5: 75.43,
    line6: 77.957,
    line7: 80.484,
  },
  {
    age: 339,
    line1: 65.36,
    line2: 67.888,
    line3: 70.416,
    line4: 72.945,
    line5: 75.473,
    line6: 78.001,
    line7: 80.529,
  },
  {
    age: 340,
    line1: 65.395,
    line2: 67.925,
    line3: 70.456,
    line4: 72.986,
    line5: 75.517,
    line6: 78.047,
    line7: 80.577,
  },
  {
    age: 341,
    line1: 65.432,
    line2: 67.964,
    line3: 70.496,
    line4: 73.027,
    line5: 75.559,
    line6: 78.091,
    line7: 80.623,
  },
  {
    age: 342,
    line1: 65.467,
    line2: 68.001,
    line3: 70.535,
    line4: 73.069,
    line5: 75.603,
    line6: 78.137,
    line7: 80.671,
  },
  {
    age: 343,
    line1: 65.504,
    line2: 68.039,
    line3: 70.574,
    line4: 73.11,
    line5: 75.645,
    line6: 78.181,
    line7: 80.716,
  },
  {
    age: 344,
    line1: 65.538,
    line2: 68.076,
    line3: 70.613,
    line4: 73.151,
    line5: 75.689,
    line6: 78.226,
    line7: 80.764,
  },
  {
    age: 345,
    line1: 65.575,
    line2: 68.114,
    line3: 70.653,
    line4: 73.192,
    line5: 75.731,
    line6: 78.27,
    line7: 80.809,
  },
  {
    age: 346,
    line1: 65.612,
    line2: 68.152,
    line3: 70.693,
    line4: 73.233,
    line5: 75.774,
    line6: 78.314,
    line7: 80.855,
  },
  {
    age: 347,
    line1: 65.646,
    line2: 68.189,
    line3: 70.732,
    line4: 73.274,
    line5: 75.817,
    line6: 78.36,
    line7: 80.902,
  },
  {
    age: 348,
    line1: 65.683,
    line2: 68.227,
    line3: 70.771,
    line4: 73.315,
    line5: 75.859,
    line6: 78.403,
    line7: 80.947,
  },
  {
    age: 349,
    line1: 65.718,
    line2: 68.264,
    line3: 70.81,
    line4: 73.356,
    line5: 75.902,
    line6: 78.449,
    line7: 80.995,
  },
  {
    age: 350,
    line1: 65.754,
    line2: 68.302,
    line3: 70.849,
    line4: 73.397,
    line5: 75.945,
    line6: 78.492,
    line7: 81.04,
  },
  {
    age: 351,
    line1: 65.789,
    line2: 68.338,
    line3: 70.888,
    line4: 73.438,
    line5: 75.988,
    line6: 78.537,
    line7: 81.087,
  },
  {
    age: 352,
    line1: 65.825,
    line2: 68.376,
    line3: 70.928,
    line4: 73.479,
    line5: 76.03,
    line6: 78.581,
    line7: 81.132,
  },
  {
    age: 353,
    line1: 65.86,
    line2: 68.413,
    line3: 70.966,
    line4: 73.52,
    line5: 76.073,
    line6: 78.626,
    line7: 81.179,
  },
  {
    age: 354,
    line1: 65.896,
    line2: 68.451,
    line3: 71.005,
    line4: 73.56,
    line5: 76.115,
    line6: 78.67,
    line7: 81.224,
  },
  {
    age: 355,
    line1: 65.93,
    line2: 68.487,
    line3: 71.044,
    line4: 73.601,
    line5: 76.158,
    line6: 78.715,
    line7: 81.271,
  },
  {
    age: 356,
    line1: 65.966,
    line2: 68.525,
    line3: 71.083,
    line4: 73.641,
    line5: 76.2,
    line6: 78.758,
    line7: 81.316,
  },
  {
    age: 357,
    line1: 66.001,
    line2: 68.561,
    line3: 71.122,
    line4: 73.682,
    line5: 76.242,
    line6: 78.803,
    line7: 81.363,
  },
  {
    age: 358,
    line1: 66.037,
    line2: 68.599,
    line3: 71.161,
    line4: 73.722,
    line5: 76.284,
    line6: 78.846,
    line7: 81.408,
  },
  {
    age: 359,
    line1: 66.071,
    line2: 68.635,
    line3: 71.199,
    line4: 73.763,
    line5: 76.327,
    line6: 78.891,
    line7: 81.455,
  },
  {
    age: 360,
    line1: 66.107,
    line2: 68.673,
    line3: 71.238,
    line4: 73.803,
    line5: 76.369,
    line6: 78.934,
    line7: 81.5,
  },
  {
    age: 361,
    line1: 66.141,
    line2: 68.709,
    line3: 71.276,
    line4: 73.844,
    line5: 76.411,
    line6: 78.979,
    line7: 81.546,
  },
  {
    age: 362,
    line1: 66.177,
    line2: 68.746,
    line3: 71.315,
    line4: 73.884,
    line5: 76.453,
    line6: 79.022,
    line7: 81.591,
  },
  {
    age: 363,
    line1: 66.211,
    line2: 68.782,
    line3: 71.353,
    line4: 73.924,
    line5: 76.496,
    line6: 79.067,
    line7: 81.638,
  },
  {
    age: 364,
    line1: 66.247,
    line2: 68.82,
    line3: 71.392,
    line4: 73.965,
    line5: 76.537,
    line6: 79.11,
    line7: 81.682,
  },
  {
    age: 365,
    line1: 66.281,
    line2: 68.856,
    line3: 71.43,
    line4: 74.005,
    line5: 76.58,
    line6: 79.154,
    line7: 81.729,
  },
  {
    age: 366,
    line1: 66.317,
    line2: 68.893,
    line3: 71.469,
    line4: 74.045,
    line5: 76.621,
    line6: 79.197,
    line7: 81.773,
  },
  {
    age: 367,
    line1: 66.351,
    line2: 68.929,
    line3: 71.507,
    line4: 74.085,
    line5: 76.663,
    line6: 79.242,
    line7: 81.82,
  },
  {
    age: 368,
    line1: 66.387,
    line2: 68.966,
    line3: 71.546,
    line4: 74.125,
    line5: 76.705,
    line6: 79.284,
    line7: 81.864,
  },
  {
    age: 369,
    line1: 66.42,
    line2: 69.002,
    line3: 71.584,
    line4: 74.165,
    line5: 76.747,
    line6: 79.329,
    line7: 81.91,
  },
  {
    age: 370,
    line1: 66.454,
    line2: 69.038,
    line3: 71.621,
    line4: 74.205,
    line5: 76.789,
    line6: 79.373,
    line7: 81.957,
  },
  {
    age: 371,
    line1: 66.49,
    line2: 69.075,
    line3: 71.66,
    line4: 74.245,
    line5: 76.83,
    line6: 79.416,
    line7: 82.001,
  },
  {
    age: 372,
    line1: 66.523,
    line2: 69.11,
    line3: 71.698,
    line4: 74.285,
    line5: 76.872,
    line6: 79.46,
    line7: 82.047,
  },
  {
    age: 373,
    line1: 66.559,
    line2: 69.148,
    line3: 71.736,
    line4: 74.325,
    line5: 76.914,
    line6: 79.502,
    line7: 82.091,
  },
  {
    age: 374,
    line1: 66.592,
    line2: 69.183,
    line3: 71.774,
    line4: 74.365,
    line5: 76.956,
    line6: 79.547,
    line7: 82.137,
  },
  {
    age: 375,
    line1: 66.628,
    line2: 69.22,
    line3: 71.812,
    line4: 74.404,
    line5: 76.997,
    line6: 79.589,
    line7: 82.181,
  },
  {
    age: 376,
    line1: 66.661,
    line2: 69.256,
    line3: 71.85,
    line4: 74.444,
    line5: 77.039,
    line6: 79.633,
    line7: 82.227,
  },
  {
    age: 377,
    line1: 66.697,
    line2: 69.292,
    line3: 71.888,
    line4: 74.484,
    line5: 77.08,
    line6: 79.675,
    line7: 82.271,
  },
  {
    age: 378,
    line1: 66.73,
    line2: 69.328,
    line3: 71.926,
    line4: 74.524,
    line5: 77.121,
    line6: 79.719,
    line7: 82.317,
  },
  {
    age: 379,
    line1: 66.765,
    line2: 69.365,
    line3: 71.964,
    line4: 74.563,
    line5: 77.162,
    line6: 79.762,
    line7: 82.361,
  },
  {
    age: 380,
    line1: 66.799,
    line2: 69.4,
    line3: 72.001,
    line4: 74.603,
    line5: 77.204,
    line6: 79.805,
    line7: 82.407,
  },
  {
    age: 381,
    line1: 66.832,
    line2: 69.435,
    line3: 72.039,
    line4: 74.642,
    line5: 77.246,
    line6: 79.849,
    line7: 82.453,
  },
  {
    age: 382,
    line1: 66.867,
    line2: 69.472,
    line3: 72.077,
    line4: 74.682,
    line5: 77.287,
    line6: 79.891,
    line7: 82.496,
  },
  {
    age: 383,
    line1: 66.9,
    line2: 69.507,
    line3: 72.114,
    line4: 74.721,
    line5: 77.328,
    line6: 79.935,
    line7: 82.542,
  },
  {
    age: 384,
    line1: 66.935,
    line2: 69.544,
    line3: 72.152,
    line4: 74.76,
    line5: 77.369,
    line6: 79.977,
    line7: 82.586,
  },
  {
    age: 385,
    line1: 66.968,
    line2: 69.579,
    line3: 72.189,
    line4: 74.8,
    line5: 77.41,
    line6: 80.021,
    line7: 82.631,
  },
  {
    age: 386,
    line1: 67.003,
    line2: 69.615,
    line3: 72.227,
    line4: 74.839,
    line5: 77.451,
    line6: 80.063,
    line7: 82.675,
  },
  {
    age: 387,
    line1: 67.036,
    line2: 69.65,
    line3: 72.264,
    line4: 74.878,
    line5: 77.492,
    line6: 80.106,
    line7: 82.72,
  },
  {
    age: 388,
    line1: 67.071,
    line2: 69.687,
    line3: 72.302,
    line4: 74.918,
    line5: 77.533,
    line6: 80.148,
    line7: 82.764,
  },
  {
    age: 389,
    line1: 67.104,
    line2: 69.722,
    line3: 72.339,
    line4: 74.957,
    line5: 77.574,
    line6: 80.192,
    line7: 82.809,
  },
  {
    age: 390,
    line1: 67.137,
    line2: 69.757,
    line3: 72.376,
    line4: 74.996,
    line5: 77.616,
    line6: 80.235,
    line7: 82.855,
  },
  {
    age: 391,
    line1: 67.172,
    line2: 69.793,
    line3: 72.414,
    line4: 75.035,
    line5: 77.656,
    line6: 80.277,
    line7: 82.898,
  },
  {
    age: 392,
    line1: 67.205,
    line2: 69.828,
    line3: 72.451,
    line4: 75.074,
    line5: 77.697,
    line6: 80.32,
    line7: 82.943,
  },
  {
    age: 393,
    line1: 67.24,
    line2: 69.864,
    line3: 72.489,
    line4: 75.113,
    line5: 77.737,
    line6: 80.362,
    line7: 82.986,
  },
  {
    age: 394,
    line1: 67.272,
    line2: 69.899,
    line3: 72.525,
    line4: 75.152,
    line5: 77.778,
    line6: 80.405,
    line7: 83.032,
  },
  {
    age: 395,
    line1: 67.307,
    line2: 69.935,
    line3: 72.563,
    line4: 75.191,
    line5: 77.819,
    line6: 80.447,
    line7: 83.075,
  },
  {
    age: 396,
    line1: 67.34,
    line2: 69.97,
    line3: 72.6,
    line4: 75.23,
    line5: 77.86,
    line6: 80.49,
    line7: 83.12,
  },
  {
    age: 397,
    line1: 67.372,
    line2: 70.004,
    line3: 72.636,
    line4: 75.269,
    line5: 77.901,
    line6: 80.533,
    line7: 83.165,
  },
  {
    age: 398,
    line1: 67.407,
    line2: 70.04,
    line3: 72.674,
    line4: 75.307,
    line5: 77.941,
    line6: 80.574,
    line7: 83.208,
  },
  {
    age: 399,
    line1: 67.439,
    line2: 70.075,
    line3: 72.71,
    line4: 75.346,
    line5: 77.982,
    line6: 80.617,
    line7: 83.253,
  },
  {
    age: 400,
    line1: 67.474,
    line2: 70.111,
    line3: 72.748,
    line4: 75.385,
    line5: 78.022,
    line6: 80.659,
    line7: 83.296,
  },
  {
    age: 401,
    line1: 67.506,
    line2: 70.145,
    line3: 72.784,
    line4: 75.424,
    line5: 78.063,
    line6: 80.702,
    line7: 83.341,
  },
  {
    age: 402,
    line1: 67.539,
    line2: 70.18,
    line3: 72.821,
    line4: 75.462,
    line5: 78.103,
    line6: 80.744,
    line7: 83.386,
  },
  {
    age: 403,
    line1: 67.573,
    line2: 70.216,
    line3: 72.858,
    line4: 75.501,
    line5: 78.143,
    line6: 80.786,
    line7: 83.428,
  },
  {
    age: 404,
    line1: 67.605,
    line2: 70.25,
    line3: 72.895,
    line4: 75.539,
    line5: 78.184,
    line6: 80.828,
    line7: 83.473,
  },
  {
    age: 405,
    line1: 67.64,
    line2: 70.286,
    line3: 72.932,
    line4: 75.578,
    line5: 78.224,
    line6: 80.87,
    line7: 83.516,
  },
  {
    age: 406,
    line1: 67.672,
    line2: 70.32,
    line3: 72.968,
    line4: 75.616,
    line5: 78.264,
    line6: 80.912,
    line7: 83.56,
  },
  {
    age: 407,
    line1: 67.704,
    line2: 70.354,
    line3: 73.004,
    line4: 75.655,
    line5: 78.305,
    line6: 80.955,
    line7: 83.605,
  },
  {
    age: 408,
    line1: 67.738,
    line2: 70.39,
    line3: 73.041,
    line4: 75.693,
    line5: 78.345,
    line6: 80.996,
    line7: 83.648,
  },
  {
    age: 409,
    line1: 67.77,
    line2: 70.424,
    line3: 73.078,
    line4: 75.731,
    line5: 78.385,
    line6: 81.039,
    line7: 83.692,
  },
  {
    age: 410,
    line1: 67.805,
    line2: 70.46,
    line3: 73.115,
    line4: 75.77,
    line5: 78.425,
    line6: 81.08,
    line7: 83.735,
  },
  {
    age: 411,
    line1: 67.837,
    line2: 70.494,
    line3: 73.151,
    line4: 75.808,
    line5: 78.465,
    line6: 81.122,
    line7: 83.779,
  },
  {
    age: 412,
    line1: 67.869,
    line2: 70.528,
    line3: 73.187,
    line4: 75.846,
    line5: 78.505,
    line6: 81.164,
    line7: 83.824,
  },
  {
    age: 413,
    line1: 67.903,
    line2: 70.563,
    line3: 73.224,
    line4: 75.884,
    line5: 78.545,
    line6: 81.205,
    line7: 83.866,
  },
  {
    age: 414,
    line1: 67.935,
    line2: 70.597,
    line3: 73.26,
    line4: 75.922,
    line5: 78.585,
    line6: 81.248,
    line7: 83.91,
  },
  {
    age: 415,
    line1: 67.969,
    line2: 70.633,
    line3: 73.297,
    line4: 75.96,
    line5: 78.624,
    line6: 81.288,
    line7: 83.952,
  },
  {
    age: 416,
    line1: 68.001,
    line2: 70.667,
    line3: 73.333,
    line4: 75.999,
    line5: 78.665,
    line6: 81.331,
    line7: 83.997,
  },
  {
    age: 417,
    line1: 68.032,
    line2: 70.7,
    line3: 73.368,
    line4: 76.037,
    line5: 78.705,
    line6: 81.373,
    line7: 84.041,
  },
  {
    age: 418,
    line1: 68.066,
    line2: 70.736,
    line3: 73.405,
    line4: 76.075,
    line5: 78.744,
    line6: 81.414,
    line7: 84.083,
  },
  {
    age: 419,
    line1: 68.098,
    line2: 70.769,
    line3: 73.441,
    line4: 76.112,
    line5: 78.784,
    line6: 81.456,
    line7: 84.127,
  },
  {
    age: 420,
    line1: 68.129,
    line2: 70.803,
    line3: 73.477,
    line4: 76.15,
    line5: 78.824,
    line6: 81.498,
    line7: 84.171,
  },
  {
    age: 421,
    line1: 68.163,
    line2: 70.838,
    line3: 73.513,
    line4: 76.188,
    line5: 78.863,
    line6: 81.538,
    line7: 84.213,
  },
  {
    age: 422,
    line1: 68.195,
    line2: 70.872,
    line3: 73.549,
    line4: 76.226,
    line5: 78.903,
    line6: 81.58,
    line7: 84.257,
  },
  {
    age: 423,
    line1: 68.229,
    line2: 70.907,
    line3: 73.586,
    line4: 76.264,
    line5: 78.942,
    line6: 81.621,
    line7: 84.299,
  },
  {
    age: 424,
    line1: 68.26,
    line2: 70.941,
    line3: 73.621,
    line4: 76.302,
    line5: 78.982,
    line6: 81.663,
    line7: 84.343,
  },
  {
    age: 425,
    line1: 68.292,
    line2: 70.974,
    line3: 73.657,
    line4: 76.339,
    line5: 79.022,
    line6: 81.704,
    line7: 84.387,
  },
  {
    age: 426,
    line1: 68.325,
    line2: 71.009,
    line3: 73.693,
    line4: 76.377,
    line5: 79.061,
    line6: 81.745,
    line7: 84.429,
  },
  {
    age: 427,
    line1: 68.357,
    line2: 71.043,
    line3: 73.729,
    line4: 76.415,
    line5: 79.101,
    line6: 81.787,
    line7: 84.473,
  },
  {
    age: 428,
    line1: 68.388,
    line2: 71.076,
    line3: 73.764,
    line4: 76.452,
    line5: 79.14,
    line6: 81.828,
    line7: 84.516,
  },
  {
    age: 429,
    line1: 68.422,
    line2: 71.111,
    line3: 73.8,
    line4: 76.49,
    line5: 79.179,
    line6: 81.868,
    line7: 84.558,
  },
  {
    age: 430,
    line1: 68.453,
    line2: 71.144,
    line3: 73.836,
    line4: 76.527,
    line5: 79.219,
    line6: 81.91,
    line7: 84.602,
  },
  {
    age: 431,
    line1: 68.484,
    line2: 71.178,
    line3: 73.871,
    line4: 76.565,
    line5: 79.258,
    line6: 81.952,
    line7: 84.645,
  },
  {
    age: 432,
    line1: 68.518,
    line2: 71.212,
    line3: 73.907,
    line4: 76.602,
    line5: 79.297,
    line6: 81.992,
    line7: 84.687,
  },
  {
    age: 433,
    line1: 68.549,
    line2: 71.246,
    line3: 73.943,
    line4: 76.64,
    line5: 79.336,
    line6: 82.033,
    line7: 84.73,
  },
  {
    age: 434,
    line1: 68.582,
    line2: 71.28,
    line3: 73.979,
    line4: 76.677,
    line5: 79.375,
    line6: 82.073,
    line7: 84.772,
  },
  {
    age: 435,
    line1: 68.613,
    line2: 71.314,
    line3: 74.014,
    line4: 76.714,
    line5: 79.415,
    line6: 82.115,
    line7: 84.815,
  },
  {
    age: 436,
    line1: 68.644,
    line2: 71.347,
    line3: 74.049,
    line4: 76.752,
    line5: 79.454,
    line6: 82.156,
    line7: 84.859,
  },
  {
    age: 437,
    line1: 68.678,
    line2: 71.381,
    line3: 74.085,
    line4: 76.789,
    line5: 79.492,
    line6: 82.196,
    line7: 84.9,
  },
  {
    age: 438,
    line1: 68.708,
    line2: 71.414,
    line3: 74.12,
    line4: 76.826,
    line5: 79.532,
    line6: 82.238,
    line7: 84.943,
  },
  {
    age: 439,
    line1: 68.739,
    line2: 71.447,
    line3: 74.155,
    line4: 76.863,
    line5: 79.571,
    line6: 82.279,
    line7: 84.987,
  },
  {
    age: 440,
    line1: 68.773,
    line2: 71.482,
    line3: 74.191,
    line4: 76.9,
    line5: 79.609,
    line6: 82.319,
    line7: 85.028,
  },
  {
    age: 441,
    line1: 68.803,
    line2: 71.515,
    line3: 74.226,
    line4: 76.937,
    line5: 79.649,
    line6: 82.36,
    line7: 85.071,
  },
  {
    age: 442,
    line1: 68.834,
    line2: 71.548,
    line3: 74.261,
    line4: 76.974,
    line5: 79.688,
    line6: 82.401,
    line7: 85.114,
  },
  {
    age: 443,
    line1: 68.867,
    line2: 71.582,
    line3: 74.297,
    line4: 77.011,
    line5: 79.726,
    line6: 82.441,
    line7: 85.155,
  },
  {
    age: 444,
    line1: 68.898,
    line2: 71.615,
    line3: 74.332,
    line4: 77.048,
    line5: 79.765,
    line6: 82.482,
    line7: 85.199,
  },
  {
    age: 445,
    line1: 68.929,
    line2: 71.648,
    line3: 74.367,
    line4: 77.085,
    line5: 79.804,
    line6: 82.523,
    line7: 85.242,
  },
  {
    age: 446,
    line1: 68.962,
    line2: 71.682,
    line3: 74.402,
    line4: 77.122,
    line5: 79.842,
    line6: 82.562,
    line7: 85.282,
  },
  {
    age: 447,
    line1: 68.993,
    line2: 71.715,
    line3: 74.437,
    line4: 77.159,
    line5: 79.881,
    line6: 82.603,
    line7: 85.326,
  },
  {
    age: 448,
    line1: 69.023,
    line2: 71.747,
    line3: 74.472,
    line4: 77.196,
    line5: 79.92,
    line6: 82.644,
    line7: 85.369,
  },
  {
    age: 449,
    line1: 69.056,
    line2: 71.782,
    line3: 74.507,
    line4: 77.233,
    line5: 79.958,
    line6: 82.684,
    line7: 85.409,
  },
  {
    age: 450,
    line1: 69.087,
    line2: 71.814,
    line3: 74.542,
    line4: 77.27,
    line5: 79.997,
    line6: 82.725,
    line7: 85.452,
  },
  {
    age: 451,
    line1: 69.119,
    line2: 71.848,
    line3: 74.577,
    line4: 77.306,
    line5: 80.035,
    line6: 82.764,
    line7: 85.493,
  },
  {
    age: 452,
    line1: 69.15,
    line2: 71.881,
    line3: 74.612,
    line4: 77.343,
    line5: 80.074,
    line6: 82.805,
    line7: 85.536,
  },
  {
    age: 453,
    line1: 69.18,
    line2: 71.914,
    line3: 74.647,
    line4: 77.38,
    line5: 80.113,
    line6: 82.846,
    line7: 85.579,
  },
  {
    age: 454,
    line1: 69.213,
    line2: 71.948,
    line3: 74.682,
    line4: 77.416,
    line5: 80.151,
    line6: 82.885,
    line7: 85.619,
  },
  {
    age: 455,
    line1: 69.244,
    line2: 71.98,
    line3: 74.716,
    line4: 77.453,
    line5: 80.189,
    line6: 82.926,
    line7: 85.662,
  },
  {
    age: 456,
    line1: 69.274,
    line2: 72.012,
    line3: 74.751,
    line4: 77.489,
    line5: 80.228,
    line6: 82.966,
    line7: 85.705,
  },
  {
    age: 457,
    line1: 69.307,
    line2: 72.046,
    line3: 74.786,
    line4: 77.526,
    line5: 80.266,
    line6: 83.005,
    line7: 85.745,
  },
  {
    age: 458,
    line1: 69.337,
    line2: 72.079,
    line3: 74.82,
    line4: 77.562,
    line5: 80.304,
    line6: 83.046,
    line7: 85.788,
  },
  {
    age: 459,
    line1: 69.367,
    line2: 72.111,
    line3: 74.855,
    line4: 77.599,
    line5: 80.343,
    line6: 83.087,
    line7: 85.83,
  },
  {
    age: 460,
    line1: 69.4,
    line2: 72.145,
    line3: 74.89,
    line4: 77.635,
    line5: 80.38,
    line6: 83.126,
    line7: 85.871,
  },
  {
    age: 461,
    line1: 69.43,
    line2: 72.177,
    line3: 74.924,
    line4: 77.672,
    line5: 80.419,
    line6: 83.166,
    line7: 85.913,
  },
  {
    age: 462,
    line1: 69.46,
    line2: 72.209,
    line3: 74.959,
    line4: 77.708,
    line5: 80.457,
    line6: 83.207,
    line7: 85.956,
  },
  {
    age: 463,
    line1: 69.492,
    line2: 72.243,
    line3: 74.994,
    line4: 77.744,
    line5: 80.495,
    line6: 83.245,
    line7: 85.996,
  },
  {
    age: 464,
    line1: 69.523,
    line2: 72.275,
    line3: 75.028,
    line4: 77.78,
    line5: 80.533,
    line6: 83.286,
    line7: 86.038,
  },
  {
    age: 465,
    line1: 69.553,
    line2: 72.307,
    line3: 75.062,
    line4: 77.817,
    line5: 80.571,
    line6: 83.326,
    line7: 86.081,
  },
  {
    age: 466,
    line1: 69.585,
    line2: 72.341,
    line3: 75.097,
    line4: 77.853,
    line5: 80.609,
    line6: 83.365,
    line7: 86.121,
  },
  {
    age: 467,
    line1: 69.615,
    line2: 72.373,
    line3: 75.131,
    line4: 77.889,
    line5: 80.647,
    line6: 83.405,
    line7: 86.163,
  },
  {
    age: 468,
    line1: 69.645,
    line2: 72.405,
    line3: 75.165,
    line4: 77.925,
    line5: 80.685,
    line6: 83.445,
    line7: 86.206,
  },
  {
    age: 469,
    line1: 69.675,
    line2: 72.437,
    line3: 75.199,
    line4: 77.961,
    line5: 80.723,
    line6: 83.486,
    line7: 86.248,
  },
  {
    age: 470,
    line1: 69.707,
    line2: 72.471,
    line3: 75.234,
    line4: 77.997,
    line5: 80.761,
    line6: 83.524,
    line7: 86.288,
  },
  {
    age: 471,
    line1: 69.737,
    line2: 72.502,
    line3: 75.268,
    line4: 78.033,
    line5: 80.799,
    line6: 83.564,
    line7: 86.33,
  },
  {
    age: 472,
    line1: 69.767,
    line2: 72.534,
    line3: 75.302,
    line4: 78.069,
    line5: 80.837,
    line6: 83.605,
    line7: 86.372,
  },
  {
    age: 473,
    line1: 69.799,
    line2: 72.568,
    line3: 75.337,
    line4: 78.105,
    line5: 80.874,
    line6: 83.643,
    line7: 86.412,
  },
  {
    age: 474,
    line1: 69.829,
    line2: 72.6,
    line3: 75.37,
    line4: 78.141,
    line5: 80.912,
    line6: 83.683,
    line7: 86.454,
  },
  {
    age: 475,
    line1: 69.858,
    line2: 72.631,
    line3: 75.404,
    line4: 78.177,
    line5: 80.95,
    line6: 83.723,
    line7: 86.496,
  },
  {
    age: 476,
    line1: 69.89,
    line2: 72.665,
    line3: 75.439,
    line4: 78.213,
    line5: 80.987,
    line6: 83.762,
    line7: 86.536,
  },
  {
    age: 477,
    line1: 69.92,
    line2: 72.696,
    line3: 75.473,
    line4: 78.249,
    line5: 81.025,
    line6: 83.802,
    line7: 86.578,
  },
  {
    age: 478,
    line1: 69.95,
    line2: 72.728,
    line3: 75.506,
    line4: 78.285,
    line5: 81.063,
    line6: 83.841,
    line7: 86.62,
  },
  {
    age: 479,
    line1: 69.982,
    line2: 72.761,
    line3: 75.541,
    line4: 78.32,
    line5: 81.1,
    line6: 83.88,
    line7: 86.659,
  },
  {
    age: 480,
    line1: 70.011,
    line2: 72.793,
    line3: 75.575,
    line4: 78.356,
    line5: 81.138,
    line6: 83.92,
    line7: 86.701,
  },
  {
    age: 481,
    line1: 70.041,
    line2: 72.825,
    line3: 75.608,
    line4: 78.392,
    line5: 81.176,
    line6: 83.959,
    line7: 86.743,
  },
  {
    age: 482,
    line1: 70.073,
    line2: 72.858,
    line3: 75.643,
    line4: 78.428,
    line5: 81.213,
    line6: 83.998,
    line7: 86.782,
  },
  {
    age: 483,
    line1: 70.102,
    line2: 72.889,
    line3: 75.676,
    line4: 78.463,
    line5: 81.25,
    line6: 84.037,
    line7: 86.824,
  },
  {
    age: 484,
    line1: 70.132,
    line2: 72.921,
    line3: 75.71,
    line4: 78.499,
    line5: 81.288,
    line6: 84.077,
    line7: 86.866,
  },
  {
    age: 485,
    line1: 70.164,
    line2: 72.954,
    line3: 75.744,
    line4: 78.534,
    line5: 81.325,
    line6: 84.115,
    line7: 86.905,
  },
  {
    age: 486,
    line1: 70.193,
    line2: 72.985,
    line3: 75.778,
    line4: 78.57,
    line5: 81.362,
    line6: 84.155,
    line7: 86.947,
  },
  {
    age: 487,
    line1: 70.222,
    line2: 73.017,
    line3: 75.811,
    line4: 78.606,
    line5: 81.4,
    line6: 84.194,
    line7: 86.989,
  },
  {
    age: 488,
    line1: 70.252,
    line2: 73.048,
    line3: 75.845,
    line4: 78.641,
    line5: 81.437,
    line6: 84.234,
    line7: 87.03,
  },
  {
    age: 489,
    line1: 70.283,
    line2: 73.081,
    line3: 75.879,
    line4: 78.676,
    line5: 81.474,
    line6: 84.272,
    line7: 87.07,
  },
  {
    age: 490,
    line1: 70.312,
    line2: 73.112,
    line3: 75.912,
    line4: 78.712,
    line5: 81.512,
    line6: 84.311,
    line7: 87.111,
  },
  {
    age: 491,
    line1: 70.342,
    line2: 73.144,
    line3: 75.945,
    line4: 78.747,
    line5: 81.549,
    line6: 84.351,
    line7: 87.153,
  },
  {
    age: 492,
    line1: 70.373,
    line2: 73.176,
    line3: 75.98,
    line4: 78.783,
    line5: 81.586,
    line6: 84.389,
    line7: 87.192,
  },
  {
    age: 493,
    line1: 70.403,
    line2: 73.208,
    line3: 76.013,
    line4: 78.818,
    line5: 81.623,
    line6: 84.428,
    line7: 87.233,
  },
  {
    age: 494,
    line1: 70.432,
    line2: 73.239,
    line3: 76.046,
    line4: 78.853,
    line5: 81.66,
    line6: 84.468,
    line7: 87.275,
  },
  {
    age: 495,
    line1: 70.463,
    line2: 73.272,
    line3: 76.08,
    line4: 78.888,
    line5: 81.697,
    line6: 84.505,
    line7: 87.314,
  },
  {
    age: 496,
    line1: 70.492,
    line2: 73.303,
    line3: 76.113,
    line4: 78.924,
    line5: 81.734,
    line6: 84.545,
    line7: 87.355,
  },
  {
    age: 497,
    line1: 70.521,
    line2: 73.334,
    line3: 76.146,
    line4: 78.959,
    line5: 81.771,
    line6: 84.584,
    line7: 87.396,
  },
  {
    age: 498,
    line1: 70.553,
    line2: 73.366,
    line3: 76.18,
    line4: 78.994,
    line5: 81.808,
    line6: 84.622,
    line7: 87.435,
  },
  {
    age: 499,
    line1: 70.582,
    line2: 73.397,
    line3: 76.213,
    line4: 79.029,
    line5: 81.845,
    line6: 84.661,
    line7: 87.477,
  },
  {
    age: 500,
    line1: 70.611,
    line2: 73.429,
    line3: 76.246,
    line4: 79.064,
    line5: 81.882,
    line6: 84.7,
    line7: 87.518,
  },
  {
    age: 501,
    line1: 70.64,
    line2: 73.46,
    line3: 76.279,
    line4: 79.099,
    line5: 81.919,
    line6: 84.739,
    line7: 87.559,
  },
  {
    age: 502,
    line1: 70.671,
    line2: 73.492,
    line3: 76.313,
    line4: 79.134,
    line5: 81.955,
    line6: 84.777,
    line7: 87.598,
  },
  {
    age: 503,
    line1: 70.7,
    line2: 73.523,
    line3: 76.346,
    line4: 79.169,
    line5: 81.992,
    line6: 84.816,
    line7: 87.639,
  },
  {
    age: 504,
    line1: 70.729,
    line2: 73.554,
    line3: 76.379,
    line4: 79.204,
    line5: 82.029,
    line6: 84.855,
    line7: 87.68,
  },
  {
    age: 505,
    line1: 70.76,
    line2: 73.586,
    line3: 76.413,
    line4: 79.239,
    line5: 82.066,
    line6: 84.892,
    line7: 87.719,
  },
  {
    age: 506,
    line1: 70.789,
    line2: 73.617,
    line3: 76.446,
    line4: 79.274,
    line5: 82.103,
    line6: 84.931,
    line7: 87.76,
  },
  {
    age: 507,
    line1: 70.817,
    line2: 73.648,
    line3: 76.478,
    line4: 79.309,
    line5: 82.139,
    line6: 84.97,
    line7: 87.801,
  },
  {
    age: 508,
    line1: 70.848,
    line2: 73.68,
    line3: 76.512,
    line4: 79.344,
    line5: 82.176,
    line6: 85.007,
    line7: 87.839,
  },
  {
    age: 509,
    line1: 70.877,
    line2: 73.711,
    line3: 76.545,
    line4: 79.379,
    line5: 82.212,
    line6: 85.046,
    line7: 87.88,
  },
  {
    age: 510,
    line1: 70.906,
    line2: 73.742,
    line3: 76.578,
    line4: 79.413,
    line5: 82.249,
    line6: 85.085,
    line7: 87.921,
  },
  {
    age: 511,
    line1: 70.934,
    line2: 73.772,
    line3: 76.61,
    line4: 79.448,
    line5: 82.286,
    line6: 85.124,
    line7: 87.962,
  },
  {
    age: 512,
    line1: 70.965,
    line2: 73.805,
    line3: 76.644,
    line4: 79.483,
    line5: 82.322,
    line6: 85.161,
    line7: 88,
  },
  {
    age: 513,
    line1: 70.994,
    line2: 73.835,
    line3: 76.676,
    line4: 79.518,
    line5: 82.359,
    line6: 85.2,
    line7: 88.041,
  },
  {
    age: 514,
    line1: 71.023,
    line2: 73.866,
    line3: 76.709,
    line4: 79.552,
    line5: 82.395,
    line6: 85.238,
    line7: 88.082,
  },
  {
    age: 515,
    line1: 71.054,
    line2: 73.898,
    line3: 76.742,
    line4: 79.587,
    line5: 82.431,
    line6: 85.276,
    line7: 88.12,
  },
  {
    age: 516,
    line1: 71.082,
    line2: 73.928,
    line3: 76.775,
    line4: 79.621,
    line5: 82.468,
    line6: 85.314,
    line7: 88.161,
  },
  {
    age: 517,
    line1: 71.11,
    line2: 73.959,
    line3: 76.807,
    line4: 79.656,
    line5: 82.504,
    line6: 85.353,
    line7: 88.201,
  },
  {
    age: 518,
    line1: 71.139,
    line2: 73.989,
    line3: 76.84,
    line4: 79.69,
    line5: 82.541,
    line6: 85.391,
    line7: 88.242,
  },
  {
    age: 519,
    line1: 71.17,
    line2: 74.021,
    line3: 76.873,
    line4: 79.725,
    line5: 82.577,
    line6: 85.428,
    line7: 88.28,
  },
  {
    age: 520,
    line1: 71.198,
    line2: 74.052,
    line3: 76.906,
    line4: 79.759,
    line5: 82.613,
    line6: 85.467,
    line7: 88.321,
  },
  {
    age: 521,
    line1: 71.226,
    line2: 74.082,
    line3: 76.938,
    line4: 79.794,
    line5: 82.65,
    line6: 85.505,
    line7: 88.361,
  },
  {
    age: 522,
    line1: 71.257,
    line2: 74.114,
    line3: 76.971,
    line4: 79.828,
    line5: 82.685,
    line6: 85.542,
    line7: 88.399,
  },
  {
    age: 523,
    line1: 71.285,
    line2: 74.144,
    line3: 77.004,
    line4: 79.863,
    line5: 82.722,
    line6: 85.581,
    line7: 88.44,
  },
  {
    age: 524,
    line1: 71.314,
    line2: 74.175,
    line3: 77.036,
    line4: 79.897,
    line5: 82.758,
    line6: 85.619,
    line7: 88.48,
  },
  {
    age: 525,
    line1: 71.342,
    line2: 74.205,
    line3: 77.068,
    line4: 79.931,
    line5: 82.794,
    line6: 85.657,
    line7: 88.521,
  },
  {
    age: 526,
    line1: 71.372,
    line2: 74.237,
    line3: 77.101,
    line4: 79.966,
    line5: 82.83,
    line6: 85.694,
    line7: 88.559,
  },
  {
    age: 527,
    line1: 71.401,
    line2: 74.267,
    line3: 77.133,
    line4: 80,
    line5: 82.866,
    line6: 85.733,
    line7: 88.599,
  },
  {
    age: 528,
    line1: 71.429,
    line2: 74.297,
    line3: 77.166,
    line4: 80.034,
    line5: 82.902,
    line6: 85.771,
    line7: 88.639,
  },
  {
    age: 529,
    line1: 71.459,
    line2: 74.329,
    line3: 77.199,
    line4: 80.068,
    line5: 82.938,
    line6: 85.807,
    line7: 88.677,
  },
  {
    age: 530,
    line1: 71.487,
    line2: 74.359,
    line3: 77.231,
    line4: 80.102,
    line5: 82.974,
    line6: 85.846,
    line7: 88.717,
  },
  {
    age: 531,
    line1: 71.515,
    line2: 74.389,
    line3: 77.263,
    line4: 80.136,
    line5: 83.01,
    line6: 85.884,
    line7: 88.758,
  },
  {
    age: 532,
    line1: 71.543,
    line2: 74.419,
    line3: 77.295,
    line4: 80.171,
    line5: 83.046,
    line6: 85.922,
    line7: 88.798,
  },
  {
    age: 533,
    line1: 71.574,
    line2: 74.451,
    line3: 77.328,
    line4: 80.205,
    line5: 83.082,
    line6: 85.958,
    line7: 88.835,
  },
  {
    age: 534,
    line1: 71.602,
    line2: 74.481,
    line3: 77.36,
    line4: 80.239,
    line5: 83.118,
    line6: 85.997,
    line7: 88.876,
  },
  {
    age: 535,
    line1: 71.63,
    line2: 74.511,
    line3: 77.392,
    line4: 80.273,
    line5: 83.154,
    line6: 86.035,
    line7: 88.916,
  },
  {
    age: 536,
    line1: 71.66,
    line2: 74.542,
    line3: 77.424,
    line4: 80.307,
    line5: 83.189,
    line6: 86.071,
    line7: 88.953,
  },
  {
    age: 537,
    line1: 71.688,
    line2: 74.572,
    line3: 77.456,
    line4: 80.341,
    line5: 83.225,
    line6: 86.109,
    line7: 88.993,
  },
  {
    age: 538,
    line1: 71.716,
    line2: 74.602,
    line3: 77.488,
    line4: 80.374,
    line5: 83.261,
    line6: 86.147,
    line7: 89.033,
  },
  {
    age: 539,
    line1: 71.744,
    line2: 74.632,
    line3: 77.52,
    line4: 80.408,
    line5: 83.297,
    line6: 86.185,
    line7: 89.073,
  },
  {
    age: 540,
    line1: 71.774,
    line2: 74.663,
    line3: 77.553,
    line4: 80.442,
    line5: 83.332,
    line6: 86.221,
    line7: 89.111,
  },
  {
    age: 541,
    line1: 71.802,
    line2: 74.693,
    line3: 77.585,
    line4: 80.476,
    line5: 83.368,
    line6: 86.259,
    line7: 89.151,
  },
  {
    age: 542,
    line1: 71.829,
    line2: 74.723,
    line3: 77.616,
    line4: 80.51,
    line5: 83.403,
    line6: 86.297,
    line7: 89.19,
  },
  {
    age: 543,
    line1: 71.859,
    line2: 74.754,
    line3: 77.649,
    line4: 80.544,
    line5: 83.438,
    line6: 86.333,
    line7: 89.228,
  },
  {
    age: 544,
    line1: 71.887,
    line2: 74.784,
    line3: 77.681,
    line4: 80.577,
    line5: 83.474,
    line6: 86.371,
    line7: 89.268,
  },
  {
    age: 545,
    line1: 71.915,
    line2: 74.814,
    line3: 77.712,
    line4: 80.611,
    line5: 83.51,
    line6: 86.409,
    line7: 89.308,
  },
  {
    age: 546,
    line1: 71.942,
    line2: 74.843,
    line3: 77.744,
    line4: 80.645,
    line5: 83.546,
    line6: 86.446,
    line7: 89.347,
  },
  {
    age: 547,
    line1: 71.972,
    line2: 74.874,
    line3: 77.776,
    line4: 80.678,
    line5: 83.581,
    line6: 86.483,
    line7: 89.385,
  },
  {
    age: 548,
    line1: 72,
    line2: 74.904,
    line3: 77.808,
    line4: 80.712,
    line5: 83.616,
    line6: 86.52,
    line7: 89.424,
  },
  {
    age: 549,
    line1: 72.028,
    line2: 74.934,
    line3: 77.84,
    line4: 80.746,
    line5: 83.652,
    line6: 86.558,
    line7: 89.464,
  },
  {
    age: 550,
    line1: 72.055,
    line2: 74.963,
    line3: 77.871,
    line4: 80.779,
    line5: 83.687,
    line6: 86.595,
    line7: 89.503,
  },
  {
    age: 551,
    line1: 72.085,
    line2: 74.994,
    line3: 77.904,
    line4: 80.813,
    line5: 83.722,
    line6: 86.631,
    line7: 89.541,
  },
  {
    age: 552,
    line1: 72.113,
    line2: 75.024,
    line3: 77.935,
    line4: 80.846,
    line5: 83.758,
    line6: 86.669,
    line7: 89.58,
  },
  {
    age: 553,
    line1: 72.14,
    line2: 75.053,
    line3: 77.967,
    line4: 80.88,
    line5: 83.793,
    line6: 86.706,
    line7: 89.62,
  },
  {
    age: 554,
    line1: 72.17,
    line2: 75.084,
    line3: 77.999,
    line4: 80.913,
    line5: 83.828,
    line6: 86.742,
    line7: 89.657,
  },
  {
    age: 555,
    line1: 72.197,
    line2: 75.114,
    line3: 78.03,
    line4: 80.947,
    line5: 83.863,
    line6: 86.78,
    line7: 89.696,
  },
  {
    age: 556,
    line1: 72.225,
    line2: 75.143,
    line3: 78.062,
    line4: 80.98,
    line5: 83.899,
    line6: 86.817,
    line7: 89.736,
  },
  {
    age: 557,
    line1: 72.252,
    line2: 75.172,
    line3: 78.093,
    line4: 81.014,
    line5: 83.934,
    line6: 86.855,
    line7: 89.775,
  },
  {
    age: 558,
    line1: 72.282,
    line2: 75.203,
    line3: 78.125,
    line4: 81.047,
    line5: 83.969,
    line6: 86.89,
    line7: 89.812,
  },
  {
    age: 559,
    line1: 72.309,
    line2: 75.233,
    line3: 78.156,
    line4: 81.08,
    line5: 84.004,
    line6: 86.928,
    line7: 89.851,
  },
  {
    age: 560,
    line1: 72.336,
    line2: 75.262,
    line3: 78.188,
    line4: 81.113,
    line5: 84.039,
    line6: 86.965,
    line7: 89.891,
  },
  {
    age: 561,
    line1: 72.363,
    line2: 75.291,
    line3: 78.219,
    line4: 81.147,
    line5: 84.074,
    line6: 87.002,
    line7: 89.93,
  },
  {
    age: 562,
    line1: 72.393,
    line2: 75.322,
    line3: 78.251,
    line4: 81.18,
    line5: 84.109,
    line6: 87.038,
    line7: 89.967,
  },
  {
    age: 563,
    line1: 72.42,
    line2: 75.351,
    line3: 78.282,
    line4: 81.213,
    line5: 84.144,
    line6: 87.075,
    line7: 90.006,
  },
  {
    age: 564,
    line1: 72.447,
    line2: 75.38,
    line3: 78.313,
    line4: 81.246,
    line5: 84.179,
    line6: 87.112,
    line7: 90.045,
  },
  {
    age: 565,
    line1: 72.474,
    line2: 75.409,
    line3: 78.344,
    line4: 81.28,
    line5: 84.215,
    line6: 87.15,
    line7: 90.085,
  },
  {
    age: 566,
    line1: 72.504,
    line2: 75.44,
    line3: 78.376,
    line4: 81.313,
    line5: 84.249,
    line6: 87.185,
    line7: 90.121,
  },
  {
    age: 567,
    line1: 72.531,
    line2: 75.469,
    line3: 78.407,
    line4: 81.346,
    line5: 84.284,
    line6: 87.222,
    line7: 90.16,
  },
  {
    age: 568,
    line1: 72.558,
    line2: 75.498,
    line3: 78.439,
    line4: 81.379,
    line5: 84.319,
    line6: 87.259,
    line7: 90.199,
  },
  {
    age: 569,
    line1: 72.588,
    line2: 75.529,
    line3: 78.47,
    line4: 81.412,
    line5: 84.353,
    line6: 87.295,
    line7: 90.236,
  },
  {
    age: 570,
    line1: 72.615,
    line2: 75.558,
    line3: 78.501,
    line4: 81.445,
    line5: 84.388,
    line6: 87.332,
    line7: 90.275,
  },
  {
    age: 571,
    line1: 72.642,
    line2: 75.587,
    line3: 78.532,
    line4: 81.478,
    line5: 84.423,
    line6: 87.369,
    line7: 90.314,
  },
  {
    age: 572,
    line1: 72.669,
    line2: 75.616,
    line3: 78.563,
    line4: 81.511,
    line5: 84.458,
    line6: 87.406,
    line7: 90.353,
  },
  {
    age: 573,
    line1: 72.698,
    line2: 75.646,
    line3: 78.595,
    line4: 81.544,
    line5: 84.492,
    line6: 87.441,
    line7: 90.39,
  },
  {
    age: 574,
    line1: 72.725,
    line2: 75.675,
    line3: 78.626,
    line4: 81.577,
    line5: 84.527,
    line6: 87.478,
    line7: 90.428,
  },
  {
    age: 575,
    line1: 72.752,
    line2: 75.704,
    line3: 78.657,
    line4: 81.61,
    line5: 84.562,
    line6: 87.515,
    line7: 90.467,
  },
  {
    age: 576,
    line1: 72.778,
    line2: 75.733,
    line3: 78.688,
    line4: 81.642,
    line5: 84.597,
    line6: 87.552,
    line7: 90.506,
  },
  {
    age: 577,
    line1: 72.808,
    line2: 75.764,
    line3: 78.719,
    line4: 81.675,
    line5: 84.631,
    line6: 87.587,
    line7: 90.543,
  },
  {
    age: 578,
    line1: 72.835,
    line2: 75.792,
    line3: 78.75,
    line4: 81.708,
    line5: 84.666,
    line6: 87.624,
    line7: 90.581,
  },
  {
    age: 579,
    line1: 72.861,
    line2: 75.821,
    line3: 78.781,
    line4: 81.741,
    line5: 84.701,
    line6: 87.66,
    line7: 90.62,
  },
  {
    age: 580,
    line1: 72.888,
    line2: 75.85,
    line3: 78.812,
    line4: 81.774,
    line5: 84.735,
    line6: 87.697,
    line7: 90.659,
  },
  {
    age: 581,
    line1: 72.917,
    line2: 75.88,
    line3: 78.843,
    line4: 81.806,
    line5: 84.769,
    line6: 87.732,
    line7: 90.695,
  },
  {
    age: 582,
    line1: 72.944,
    line2: 75.909,
    line3: 78.874,
    line4: 81.839,
    line5: 84.804,
    line6: 87.769,
    line7: 90.734,
  },
  {
    age: 583,
    line1: 72.97,
    line2: 75.937,
    line3: 78.904,
    line4: 81.872,
    line5: 84.839,
    line6: 87.806,
    line7: 90.773,
  },
  {
    age: 584,
    line1: 73,
    line2: 75.968,
    line3: 78.936,
    line4: 81.904,
    line5: 84.872,
    line6: 87.841,
    line7: 90.809,
  },
  {
    age: 585,
    line1: 73.026,
    line2: 75.996,
    line3: 78.967,
    line4: 81.937,
    line5: 84.907,
    line6: 87.877,
    line7: 90.847,
  },
  {
    age: 586,
    line1: 73.053,
    line2: 76.025,
    line3: 78.997,
    line4: 81.969,
    line5: 84.942,
    line6: 87.914,
    line7: 90.886,
  },
  {
    age: 587,
    line1: 73.079,
    line2: 76.053,
    line3: 79.028,
    line4: 82.002,
    line5: 84.976,
    line6: 87.95,
    line7: 90.925,
  },
  {
    age: 588,
    line1: 73.108,
    line2: 76.084,
    line3: 79.059,
    line4: 82.034,
    line5: 85.01,
    line6: 87.985,
    line7: 90.961,
  },
  {
    age: 589,
    line1: 73.135,
    line2: 76.112,
    line3: 79.09,
    line4: 82.067,
    line5: 85.044,
    line6: 88.022,
    line7: 90.999,
  },
  {
    age: 590,
    line1: 73.161,
    line2: 76.141,
    line3: 79.12,
    line4: 82.099,
    line5: 85.079,
    line6: 88.058,
    line7: 91.038,
  },
  {
    age: 591,
    line1: 73.188,
    line2: 76.169,
    line3: 79.151,
    line4: 82.132,
    line5: 85.113,
    line6: 88.095,
    line7: 91.076,
  },
  {
    age: 592,
    line1: 73.217,
    line2: 76.199,
    line3: 79.182,
    line4: 82.164,
    line5: 85.147,
    line6: 88.129,
    line7: 91.112,
  },
  {
    age: 593,
    line1: 73.243,
    line2: 76.228,
    line3: 79.212,
    line4: 82.197,
    line5: 85.181,
    line6: 88.166,
    line7: 91.15,
  },
  {
    age: 594,
    line1: 73.269,
    line2: 76.256,
    line3: 79.243,
    line4: 82.229,
    line5: 85.216,
    line6: 88.202,
    line7: 91.189,
  },
  {
    age: 595,
    line1: 73.296,
    line2: 76.284,
    line3: 79.273,
    line4: 82.261,
    line5: 85.25,
    line6: 88.239,
    line7: 91.227,
  },
  {
    age: 596,
    line1: 73.325,
    line2: 76.314,
    line3: 79.304,
    line4: 82.294,
    line5: 85.284,
    line6: 88.273,
    line7: 91.263,
  },
  {
    age: 597,
    line1: 73.351,
    line2: 76.343,
    line3: 79.334,
    line4: 82.326,
    line5: 85.318,
    line6: 88.31,
    line7: 91.301,
  },
  {
    age: 598,
    line1: 73.377,
    line2: 76.371,
    line3: 79.365,
    line4: 82.358,
    line5: 85.352,
    line6: 88.346,
    line7: 91.339,
  },
  {
    age: 599,
    line1: 73.403,
    line2: 76.399,
    line3: 79.395,
    line4: 82.391,
    line5: 85.386,
    line6: 88.382,
    line7: 91.378,
  },
  {
    age: 600,
    line1: 73.432,
    line2: 76.429,
    line3: 79.426,
    line4: 82.423,
    line5: 85.42,
    line6: 88.417,
    line7: 91.413,
  },
  {
    age: 601,
    line1: 73.458,
    line2: 76.457,
    line3: 79.456,
    line4: 82.455,
    line5: 85.454,
    line6: 88.453,
    line7: 91.452,
  },
  {
    age: 602,
    line1: 73.485,
    line2: 76.485,
    line3: 79.486,
    line4: 82.487,
    line5: 85.488,
    line6: 88.489,
    line7: 91.49,
  },
  {
    age: 603,
    line1: 73.511,
    line2: 76.514,
    line3: 79.516,
    line4: 82.519,
    line5: 85.522,
    line6: 88.525,
    line7: 91.528,
  },
  {
    age: 604,
    line1: 73.539,
    line2: 76.543,
    line3: 79.547,
    line4: 82.551,
    line5: 85.555,
    line6: 88.559,
    line7: 91.564,
  },
  {
    age: 605,
    line1: 73.565,
    line2: 76.571,
    line3: 79.577,
    line4: 82.584,
    line5: 85.59,
    line6: 88.596,
    line7: 91.602,
  },
  {
    age: 606,
    line1: 73.591,
    line2: 76.6,
    line3: 79.608,
    line4: 82.616,
    line5: 85.624,
    line6: 88.632,
    line7: 91.64,
  },
  {
    age: 607,
    line1: 73.618,
    line2: 76.628,
    line3: 79.638,
    line4: 82.648,
    line5: 85.658,
    line6: 88.668,
    line7: 91.678,
  },
  {
    age: 608,
    line1: 73.646,
    line2: 76.657,
    line3: 79.668,
    line4: 82.68,
    line5: 85.691,
    line6: 88.702,
    line7: 91.713,
  },
  {
    age: 609,
    line1: 73.672,
    line2: 76.685,
    line3: 79.698,
    line4: 82.712,
    line5: 85.725,
    line6: 88.738,
    line7: 91.751,
  },
  {
    age: 610,
    line1: 73.698,
    line2: 76.713,
    line3: 79.728,
    line4: 82.744,
    line5: 85.759,
    line6: 88.774,
    line7: 91.789,
  },
  {
    age: 611,
    line1: 73.724,
    line2: 76.741,
    line3: 79.758,
    line4: 82.776,
    line5: 85.793,
    line6: 88.81,
    line7: 91.827,
  },
  {
    age: 612,
    line1: 73.752,
    line2: 76.771,
    line3: 79.789,
    line4: 82.807,
    line5: 85.826,
    line6: 88.844,
    line7: 91.862,
  },
  {
    age: 613,
    line1: 73.778,
    line2: 76.799,
    line3: 79.819,
    line4: 82.839,
    line5: 85.86,
    line6: 88.88,
    line7: 91.9,
  },
  {
    age: 614,
    line1: 73.804,
    line2: 76.827,
    line3: 79.849,
    line4: 82.871,
    line5: 85.894,
    line6: 88.916,
    line7: 91.938,
  },
  {
    age: 615,
    line1: 73.83,
    line2: 76.854,
    line3: 79.879,
    line4: 82.903,
    line5: 85.927,
    line6: 88.952,
    line7: 91.976,
  },
  {
    age: 616,
    line1: 73.858,
    line2: 76.884,
    line3: 79.909,
    line4: 82.935,
    line5: 85.96,
    line6: 88.986,
    line7: 92.011,
  },
  {
    age: 617,
    line1: 73.884,
    line2: 76.912,
    line3: 79.939,
    line4: 82.967,
    line5: 85.994,
    line6: 89.022,
    line7: 92.049,
  },
  {
    age: 618,
    line1: 73.91,
    line2: 76.94,
    line3: 79.969,
    line4: 82.998,
    line5: 86.028,
    line6: 89.057,
    line7: 92.087,
  },
  {
    age: 619,
    line1: 73.938,
    line2: 76.969,
    line3: 80,
    line4: 83.03,
    line5: 86.061,
    line6: 89.091,
    line7: 92.122,
  },
  {
    age: 620,
    line1: 73.964,
    line2: 76.997,
    line3: 80.029,
    line4: 83.062,
    line5: 86.094,
    line6: 89.127,
    line7: 92.16,
  },
  {
    age: 621,
    line1: 73.99,
    line2: 77.024,
    line3: 80.059,
    line4: 83.094,
    line5: 86.128,
    line6: 89.163,
    line7: 92.197,
  },
  {
    age: 622,
    line1: 74.015,
    line2: 77.052,
    line3: 80.089,
    line4: 83.125,
    line5: 86.162,
    line6: 89.198,
    line7: 92.235,
  },
  {
    age: 623,
    line1: 74.044,
    line2: 77.081,
    line3: 80.119,
    line4: 83.157,
    line5: 86.195,
    line6: 89.232,
    line7: 92.27,
  },
  {
    age: 624,
    line1: 74.069,
    line2: 77.109,
    line3: 80.149,
    line4: 83.188,
    line5: 86.228,
    line6: 89.268,
    line7: 92.308,
  },
  {
    age: 625,
    line1: 74.095,
    line2: 77.137,
    line3: 80.178,
    line4: 83.22,
    line5: 86.262,
    line6: 89.303,
    line7: 92.345,
  },
  {
    age: 626,
    line1: 74.12,
    line2: 77.164,
    line3: 80.208,
    line4: 83.252,
    line5: 86.295,
    line6: 89.339,
    line7: 92.383,
  },
  {
    age: 627,
    line1: 74.149,
    line2: 77.193,
    line3: 80.238,
    line4: 83.283,
    line5: 86.328,
    line6: 89.373,
    line7: 92.418,
  },
  {
    age: 628,
    line1: 74.174,
    line2: 77.221,
    line3: 80.268,
    line4: 83.315,
    line5: 86.361,
    line6: 89.408,
    line7: 92.455,
  },
  {
    age: 629,
    line1: 74.2,
    line2: 77.248,
    line3: 80.297,
    line4: 83.346,
    line5: 86.395,
    line6: 89.444,
    line7: 92.493,
  },
  {
    age: 630,
    line1: 74.225,
    line2: 77.276,
    line3: 80.327,
    line4: 83.378,
    line5: 86.428,
    line6: 89.479,
    line7: 92.53,
  },
  {
    age: 631,
    line1: 74.253,
    line2: 77.305,
    line3: 80.357,
    line4: 83.409,
    line5: 86.461,
    line6: 89.513,
    line7: 92.565,
  },
  {
    age: 632,
    line1: 74.279,
    line2: 77.332,
    line3: 80.386,
    line4: 83.44,
    line5: 86.494,
    line6: 89.548,
    line7: 92.602,
  },
  {
    age: 633,
    line1: 74.304,
    line2: 77.36,
    line3: 80.416,
    line4: 83.472,
    line5: 86.528,
    line6: 89.583,
    line7: 92.639,
  },
  {
    age: 634,
    line1: 74.329,
    line2: 77.387,
    line3: 80.445,
    line4: 83.503,
    line5: 86.561,
    line6: 89.619,
    line7: 92.677,
  },
  {
    age: 635,
    line1: 74.357,
    line2: 77.416,
    line3: 80.475,
    line4: 83.534,
    line5: 86.593,
    line6: 89.652,
    line7: 92.711,
  },
  {
    age: 636,
    line1: 74.383,
    line2: 77.444,
    line3: 80.505,
    line4: 83.566,
    line5: 86.627,
    line6: 89.688,
    line7: 92.749,
  },
  {
    age: 637,
    line1: 74.408,
    line2: 77.471,
    line3: 80.534,
    line4: 83.597,
    line5: 86.66,
    line6: 89.723,
    line7: 92.786,
  },
  {
    age: 638,
    line1: 74.433,
    line2: 77.498,
    line3: 80.563,
    line4: 83.628,
    line5: 86.693,
    line6: 89.758,
    line7: 92.823,
  },
  {
    age: 639,
    line1: 74.461,
    line2: 77.527,
    line3: 80.593,
    line4: 83.66,
    line5: 86.726,
    line6: 89.792,
    line7: 92.858,
  },
  {
    age: 640,
    line1: 74.486,
    line2: 77.554,
    line3: 80.623,
    line4: 83.691,
    line5: 86.759,
    line6: 89.827,
    line7: 92.895,
  },
  {
    age: 641,
    line1: 74.512,
    line2: 77.582,
    line3: 80.652,
    line4: 83.722,
    line5: 86.792,
    line6: 89.862,
    line7: 92.932,
  },
  {
    age: 642,
    line1: 74.537,
    line2: 77.609,
    line3: 80.681,
    line4: 83.753,
    line5: 86.825,
    line6: 89.897,
    line7: 92.969,
  },
  {
    age: 643,
    line1: 74.565,
    line2: 77.638,
    line3: 80.711,
    line4: 83.784,
    line5: 86.857,
    line6: 89.931,
    line7: 93.004,
  },
  {
    age: 644,
    line1: 74.59,
    line2: 77.665,
    line3: 80.74,
    line4: 83.815,
    line5: 86.89,
    line6: 89.966,
    line7: 93.041,
  },
  {
    age: 645,
    line1: 74.615,
    line2: 77.692,
    line3: 80.769,
    line4: 83.846,
    line5: 86.924,
    line6: 90.001,
    line7: 93.078,
  },
  {
    age: 646,
    line1: 74.64,
    line2: 77.719,
    line3: 80.798,
    line4: 83.877,
    line5: 86.957,
    line6: 90.036,
    line7: 93.115,
  },
  {
    age: 647,
    line1: 74.668,
    line2: 77.748,
    line3: 80.828,
    line4: 83.908,
    line5: 86.989,
    line6: 90.069,
    line7: 93.149,
  },
  {
    age: 648,
    line1: 74.693,
    line2: 77.775,
    line3: 80.857,
    line4: 83.94,
    line5: 87.022,
    line6: 90.104,
    line7: 93.186,
  },
  {
    age: 649,
    line1: 74.718,
    line2: 77.802,
    line3: 80.886,
    line4: 83.97,
    line5: 87.055,
    line6: 90.139,
    line7: 93.223,
  },
  {
    age: 650,
    line1: 74.743,
    line2: 77.829,
    line3: 80.915,
    line4: 84.001,
    line5: 87.088,
    line6: 90.174,
    line7: 93.26,
  },
  {
    age: 651,
    line1: 74.77,
    line2: 77.858,
    line3: 80.945,
    line4: 84.032,
    line5: 87.12,
    line6: 90.207,
    line7: 93.294,
  },
  {
    age: 652,
    line1: 74.795,
    line2: 77.885,
    line3: 80.974,
    line4: 84.063,
    line5: 87.153,
    line6: 90.242,
    line7: 93.331,
  },
  {
    age: 653,
    line1: 74.82,
    line2: 77.912,
    line3: 81.003,
    line4: 84.094,
    line5: 87.185,
    line6: 90.277,
    line7: 93.368,
  },
  {
    age: 654,
    line1: 74.845,
    line2: 77.938,
    line3: 81.032,
    line4: 84.125,
    line5: 87.218,
    line6: 90.312,
    line7: 93.405,
  },
  {
    age: 655,
    line1: 74.873,
    line2: 77.967,
    line3: 81.061,
    line4: 84.156,
    line5: 87.25,
    line6: 90.345,
    line7: 93.439,
  },
  {
    age: 656,
    line1: 74.898,
    line2: 77.994,
    line3: 81.09,
    line4: 84.187,
    line5: 87.283,
    line6: 90.379,
    line7: 93.476,
  },
  {
    age: 657,
    line1: 74.922,
    line2: 78.021,
    line3: 81.119,
    line4: 84.217,
    line5: 87.316,
    line6: 90.414,
    line7: 93.512,
  },
  {
    age: 658,
    line1: 74.947,
    line2: 78.048,
    line3: 81.148,
    line4: 84.248,
    line5: 87.349,
    line6: 90.449,
    line7: 93.549,
  },
  {
    age: 659,
    line1: 74.975,
    line2: 78.076,
    line3: 81.177,
    line4: 84.279,
    line5: 87.38,
    line6: 90.482,
    line7: 93.583,
  },
  {
    age: 660,
    line1: 74.999,
    line2: 78.103,
    line3: 81.206,
    line4: 84.31,
    line5: 87.413,
    line6: 90.516,
    line7: 93.62,
  },
  {
    age: 661,
    line1: 75.024,
    line2: 78.129,
    line3: 81.235,
    line4: 84.34,
    line5: 87.446,
    line6: 90.551,
    line7: 93.657,
  },
  {
    age: 662,
    line1: 75.049,
    line2: 78.156,
    line3: 81.264,
    line4: 84.371,
    line5: 87.478,
    line6: 90.586,
    line7: 93.693,
  },
  {
    age: 663,
    line1: 75.076,
    line2: 78.185,
    line3: 81.293,
    line4: 84.402,
    line5: 87.51,
    line6: 90.619,
    line7: 93.727,
  },
  {
    age: 664,
    line1: 75.101,
    line2: 78.211,
    line3: 81.322,
    line4: 84.432,
    line5: 87.543,
    line6: 90.653,
    line7: 93.764,
  },
  {
    age: 665,
    line1: 75.125,
    line2: 78.238,
    line3: 81.35,
    line4: 84.463,
    line5: 87.575,
    line6: 90.688,
    line7: 93.8,
  },
  {
    age: 666,
    line1: 75.15,
    line2: 78.265,
    line3: 81.379,
    line4: 84.493,
    line5: 87.608,
    line6: 90.722,
    line7: 93.837,
  },
  {
    age: 667,
    line1: 75.177,
    line2: 78.293,
    line3: 81.408,
    line4: 84.524,
    line5: 87.639,
    line6: 90.755,
    line7: 93.871,
  },
  {
    age: 668,
    line1: 75.202,
    line2: 78.319,
    line3: 81.437,
    line4: 84.554,
    line5: 87.672,
    line6: 90.79,
    line7: 93.907,
  },
  {
    age: 669,
    line1: 75.227,
    line2: 78.346,
    line3: 81.466,
    line4: 84.585,
    line5: 87.704,
    line6: 90.824,
    line7: 93.943,
  },
  {
    age: 670,
    line1: 75.251,
    line2: 78.372,
    line3: 81.494,
    line4: 84.615,
    line5: 87.737,
    line6: 90.858,
    line7: 93.98,
  },
  {
    age: 671,
    line1: 75.278,
    line2: 78.401,
    line3: 81.523,
    line4: 84.646,
    line5: 87.768,
    line6: 90.891,
    line7: 94.014,
  },
  {
    age: 672,
    line1: 75.303,
    line2: 78.427,
    line3: 81.552,
    line4: 84.676,
    line5: 87.801,
    line6: 90.925,
    line7: 94.05,
  },
  {
    age: 673,
    line1: 75.327,
    line2: 78.454,
    line3: 81.58,
    line4: 84.707,
    line5: 87.833,
    line6: 90.96,
    line7: 94.086,
  },
  {
    age: 674,
    line1: 75.352,
    line2: 78.48,
    line3: 81.609,
    line4: 84.737,
    line5: 87.865,
    line6: 90.994,
    line7: 94.122,
  },
  {
    age: 675,
    line1: 75.379,
    line2: 78.508,
    line3: 81.638,
    line4: 84.767,
    line5: 87.897,
    line6: 91.027,
    line7: 94.156,
  },
  {
    age: 676,
    line1: 75.403,
    line2: 78.535,
    line3: 81.666,
    line4: 84.798,
    line5: 87.929,
    line6: 91.061,
    line7: 94.192,
  },
  {
    age: 677,
    line1: 75.427,
    line2: 78.561,
    line3: 81.694,
    line4: 84.828,
    line5: 87.962,
    line6: 91.095,
    line7: 94.229,
  },
  {
    age: 678,
    line1: 75.452,
    line2: 78.587,
    line3: 81.723,
    line4: 84.858,
    line5: 87.994,
    line6: 91.129,
    line7: 94.265,
  },
  {
    age: 679,
    line1: 75.479,
    line2: 78.615,
    line3: 81.752,
    line4: 84.888,
    line5: 88.025,
    line6: 91.162,
    line7: 94.298,
  },
  {
    age: 680,
    line1: 75.503,
    line2: 78.642,
    line3: 81.78,
    line4: 84.919,
    line5: 88.057,
    line6: 91.196,
    line7: 94.335,
  },
  {
    age: 681,
    line1: 75.527,
    line2: 78.668,
    line3: 81.808,
    line4: 84.949,
    line5: 88.09,
    line6: 91.23,
    line7: 94.371,
  },
  {
    age: 682,
    line1: 75.552,
    line2: 78.694,
    line3: 81.837,
    line4: 84.979,
    line5: 88.122,
    line6: 91.264,
    line7: 94.407,
  },
  {
    age: 683,
    line1: 75.578,
    line2: 78.722,
    line3: 81.866,
    line4: 85.009,
    line5: 88.153,
    line6: 91.297,
    line7: 94.44,
  },
  {
    age: 684,
    line1: 75.603,
    line2: 78.748,
    line3: 81.894,
    line4: 85.039,
    line5: 88.185,
    line6: 91.331,
    line7: 94.476,
  },
  {
    age: 685,
    line1: 75.627,
    line2: 78.774,
    line3: 81.922,
    line4: 85.07,
    line5: 88.217,
    line6: 91.365,
    line7: 94.512,
  },
  {
    age: 686,
    line1: 75.651,
    line2: 78.801,
    line3: 81.95,
    line4: 85.1,
    line5: 88.249,
    line6: 91.399,
    line7: 94.548,
  },
  {
    age: 687,
    line1: 75.678,
    line2: 78.828,
    line3: 81.979,
    line4: 85.13,
    line5: 88.28,
    line6: 91.431,
    line7: 94.582,
  },
  {
    age: 688,
    line1: 75.702,
    line2: 78.854,
    line3: 82.007,
    line4: 85.16,
    line5: 88.312,
    line6: 91.465,
    line7: 94.618,
  },
  {
    age: 689,
    line1: 75.726,
    line2: 78.881,
    line3: 82.035,
    line4: 85.19,
    line5: 88.344,
    line6: 91.499,
    line7: 94.653,
  },
  {
    age: 690,
    line1: 75.75,
    line2: 78.907,
    line3: 82.063,
    line4: 85.22,
    line5: 88.376,
    line6: 91.533,
    line7: 94.689,
  },
  {
    age: 691,
    line1: 75.777,
    line2: 78.934,
    line3: 82.092,
    line4: 85.25,
    line5: 88.407,
    line6: 91.565,
    line7: 94.723,
  },
  {
    age: 692,
    line1: 75.801,
    line2: 78.96,
    line3: 82.12,
    line4: 85.28,
    line5: 88.439,
    line6: 91.599,
    line7: 94.758,
  },
  {
    age: 693,
    line1: 75.825,
    line2: 78.986,
    line3: 82.148,
    line4: 85.31,
    line5: 88.471,
    line6: 91.633,
    line7: 94.794,
  },
  {
    age: 694,
    line1: 75.851,
    line2: 79.014,
    line3: 82.177,
    line4: 85.34,
    line5: 88.502,
    line6: 91.665,
    line7: 94.828,
  },
  {
    age: 695,
    line1: 75.875,
    line2: 79.04,
    line3: 82.205,
    line4: 85.369,
    line5: 88.534,
    line6: 91.699,
    line7: 94.863,
  },
  {
    age: 696,
    line1: 75.899,
    line2: 79.066,
    line3: 82.233,
    line4: 85.399,
    line5: 88.566,
    line6: 91.732,
    line7: 94.899,
  },
  {
    age: 697,
    line1: 75.923,
    line2: 79.092,
    line3: 82.26,
    line4: 85.429,
    line5: 88.598,
    line6: 91.766,
    line7: 94.935,
  },
  {
    age: 698,
    line1: 75.95,
    line2: 79.119,
    line3: 82.289,
    line4: 85.459,
    line5: 88.628,
    line6: 91.798,
    line7: 94.968,
  },
  {
    age: 699,
    line1: 75.974,
    line2: 79.145,
    line3: 82.317,
    line4: 85.489,
    line5: 88.66,
    line6: 91.832,
    line7: 95.003,
  },
  {
    age: 700,
    line1: 75.998,
    line2: 79.171,
    line3: 82.345,
    line4: 85.518,
    line5: 88.692,
    line6: 91.866,
    line7: 95.039,
  },
  {
    age: 701,
    line1: 76.021,
    line2: 79.197,
    line3: 82.373,
    line4: 85.548,
    line5: 88.724,
    line6: 91.899,
    line7: 95.075,
  },
  {
    age: 702,
    line1: 76.048,
    line2: 79.225,
    line3: 82.401,
    line4: 85.578,
    line5: 88.754,
    line6: 91.931,
    line7: 95.108,
  },
  {
    age: 703,
    line1: 76.072,
    line2: 79.25,
    line3: 82.429,
    line4: 85.608,
    line5: 88.786,
    line6: 91.965,
    line7: 95.143,
  },
  {
    age: 704,
    line1: 76.096,
    line2: 79.276,
    line3: 82.457,
    line4: 85.637,
    line5: 88.818,
    line6: 91.998,
    line7: 95.179,
  },
  {
    age: 705,
    line1: 76.119,
    line2: 79.302,
    line3: 82.484,
    line4: 85.667,
    line5: 88.849,
    line6: 92.032,
    line7: 95.214,
  },
  {
    age: 706,
    line1: 76.146,
    line2: 79.329,
    line3: 82.513,
    line4: 85.696,
    line5: 88.88,
    line6: 92.064,
    line7: 95.247,
  },
  {
    age: 707,
    line1: 76.169,
    line2: 79.355,
    line3: 82.54,
    line4: 85.726,
    line5: 88.912,
    line6: 92.097,
    line7: 95.283,
  },
  {
    age: 708,
    line1: 76.193,
    line2: 79.381,
    line3: 82.568,
    line4: 85.756,
    line5: 88.943,
    line6: 92.131,
    line7: 95.318,
  },
  {
    age: 709,
    line1: 76.217,
    line2: 79.406,
    line3: 82.596,
    line4: 85.785,
    line5: 88.975,
    line6: 92.164,
    line7: 95.354,
  },
  {
    age: 710,
    line1: 76.243,
    line2: 79.434,
    line3: 82.624,
    line4: 85.815,
    line5: 89.005,
    line6: 92.196,
    line7: 95.386,
  },
  {
    age: 711,
    line1: 76.267,
    line2: 79.459,
    line3: 82.652,
    line4: 85.844,
    line5: 89.037,
    line6: 92.229,
    line7: 95.422,
  },
  {
    age: 712,
    line1: 76.29,
    line2: 79.485,
    line3: 82.679,
    line4: 85.874,
    line5: 89.068,
    line6: 92.263,
    line7: 95.457,
  },
  {
    age: 713,
    line1: 76.314,
    line2: 79.51,
    line3: 82.707,
    line4: 85.903,
    line5: 89.1,
    line6: 92.296,
    line7: 95.493,
  },
  {
    age: 714,
    line1: 76.34,
    line2: 79.537,
    line3: 82.735,
    line4: 85.933,
    line5: 89.13,
    line6: 92.328,
    line7: 95.525,
  },
  {
    age: 715,
    line1: 76.364,
    line2: 79.563,
    line3: 82.763,
    line4: 85.962,
    line5: 89.162,
    line6: 92.361,
    line7: 95.561,
  },
  {
    age: 716,
    line1: 76.387,
    line2: 79.589,
    line3: 82.79,
    line4: 85.992,
    line5: 89.193,
    line6: 92.394,
    line7: 95.596,
  },
  {
    age: 717,
    line1: 76.411,
    line2: 79.614,
    line3: 82.817,
    line4: 86.021,
    line5: 89.224,
    line6: 92.428,
    line7: 95.631,
  },
  {
    age: 718,
    line1: 76.437,
    line2: 79.641,
    line3: 82.846,
    line4: 86.05,
    line5: 89.255,
    line6: 92.459,
    line7: 95.664,
  },
  {
    age: 719,
    line1: 76.46,
    line2: 79.667,
    line3: 82.873,
    line4: 86.08,
    line5: 89.286,
    line6: 92.492,
    line7: 95.699,
  },
  {
    age: 720,
    line1: 76.484,
    line2: 79.692,
    line3: 82.9,
    line4: 86.109,
    line5: 89.317,
    line6: 92.526,
    line7: 95.734,
  },
  {
    age: 721,
    line1: 76.507,
    line2: 79.717,
    line3: 82.928,
    line4: 86.138,
    line5: 89.348,
    line6: 92.559,
    line7: 95.769,
  },
  {
    age: 722,
    line1: 76.533,
    line2: 79.744,
    line3: 82.956,
    line4: 86.167,
    line5: 89.379,
    line6: 92.59,
    line7: 95.802,
  },
  {
    age: 723,
    line1: 76.556,
    line2: 79.77,
    line3: 82.983,
    line4: 86.197,
    line5: 89.41,
    line6: 92.624,
    line7: 95.837,
  },
  {
    age: 724,
    line1: 76.58,
    line2: 79.795,
    line3: 83.011,
    line4: 86.226,
    line5: 89.441,
    line6: 92.657,
    line7: 95.872,
  },
  {
    age: 725,
    line1: 76.606,
    line2: 79.822,
    line3: 83.039,
    line4: 86.255,
    line5: 89.472,
    line6: 92.688,
    line7: 95.904,
  },
  {
    age: 726,
    line1: 76.629,
    line2: 79.847,
    line3: 83.066,
    line4: 86.284,
    line5: 89.503,
    line6: 92.721,
    line7: 95.94,
  },
  {
    age: 727,
    line1: 76.652,
    line2: 79.873,
    line3: 83.093,
    line4: 86.313,
    line5: 89.534,
    line6: 92.754,
    line7: 95.974,
  },
  {
    age: 728,
    line1: 76.676,
    line2: 79.898,
    line3: 83.12,
    line4: 86.343,
    line5: 89.565,
    line6: 92.787,
    line7: 96.01,
  },
  {
    age: 729,
    line1: 76.702,
    line2: 79.925,
    line3: 83.148,
    line4: 86.372,
    line5: 89.595,
    line6: 92.818,
    line7: 96.042,
  },
  {
    age: 730,
    line1: 76.725,
    line2: 79.95,
    line3: 83.175,
    line4: 86.401,
    line5: 89.626,
    line6: 92.851,
    line7: 96.077,
  },
];

// pre-term line co - ordinates

export const PRETERM_BOYS_WEIGHT_LINE_COORDS = [
  {
    age: 23,
    line1: 0.49,
    line2: 0.53,
    line3: 0.7,
    line4: 0.9,
    line5: 0.97,
  },
  {
    age: 24,
    line1: 0.57,
    line2: 0.61,
    line3: 0.81,
    line4: 1.05,
    line5: 1.14,
  },
  {
    age: 25,
    line1: 0.65,
    line2: 0.7,
    line3: 0.94,
    line4: 1.21,
    line5: 1.3,
  },
  {
    age: 26,
    line1: 0.73,
    line2: 0.79,
    line3: 1.06,
    line4: 1.37,
    line5: 1.49,
  },
  {
    age: 27,
    line1: 0.83,
    line2: 0.89,
    line3: 1.2,
    line4: 1.55,
    line5: 1.69,
  },
  {
    age: 28,
    line1: 0.94,
    line2: 1,
    line3: 1.35,
    line4: 1.75,
    line5: 1.9,
  },
  {
    age: 29,
    line1: 1.05,
    line2: 1.12,
    line3: 1.51,
    line4: 1.96,
    line5: 2.13,
  },
  {
    age: 30,
    line1: 1.18,
    line2: 1.25,
    line3: 1.68,
    line4: 2.18,
    line5: 2.38,
  },
  {
    age: 31,
    line1: 1.31,
    line2: 1.39,
    line3: 1.86,
    line4: 2.42,
    line5: 2.64,
  },
  {
    age: 32,
    line1: 1.46,
    line2: 1.54,
    line3: 2.05,
    line4: 2.69,
    line5: 2.92,
  },
  {
    age: 33,
    line1: 1.61,
    line2: 1.7,
    line3: 2.25,
    line4: 2.98,
    line5: 3.22,
  },
  {
    age: 34,
    line1: 1.78,
    line2: 1.86,
    line3: 2.44,
    line4: 3.27,
    line5: 3.53,
  },
  {
    age: 35,
    line1: 1.95,
    line2: 2.03,
    line3: 2.65,
    line4: 3.58,
    line5: 3.85,
  },
  {
    age: 36,
    line1: 2.12,
    line2: 2.2,
    line3: 2.87,
    line4: 3.9,
    line5: 4.17,
  },
  {
    age: 37,
    line1: 2.29,
    line2: 2.37,
    line3: 3.08,
    line4: 4.2,
    line5: 4.47,
  },
  {
    age: 38,
    line1: 2.44,
    line2: 2.52,
    line3: 3.29,
    line4: 4.49,
    line5: 4.76,
  },
  {
    age: 39,
    line1: 2.59,
    line2: 2.67,
    line3: 3.48,
    line4: 4.75,
    line5: 5.04,
  },
  {
    age: 40,
    line1: 2.73,
    line2: 2.81,
    line3: 3.65,
    line4: 4.99,
    line5: 5.28,
  },
  {
    age: 41,
    line1: 2.87,
    line2: 2.92,
    line3: 3.81,
    line4: 5.21,
    line5: 5.49,
  },
  {
    age: 42,
    line1: 2.98,
    line2: 3.02,
    line3: 3.93,
    line4: 5.39,
    line5: 5.67,
  },
];

export const PRETERM_GIRLS_WEIGHT_LINE_COORDS = [
  {
    age: 23,
    line1: 0.46,
    line2: 0.5,
    line3: 0.66,
    line4: 0.87,
    line5: 0.94,
  },
  {
    age: 24,
    line1: 0.54,
    line2: 0.58,
    line3: 0.77,
    line4: 1.02,
    line5: 1.1,
  },
  {
    age: 25,
    line1: 0.61,
    line2: 0.67,
    line3: 0.89,
    line4: 1.18,
    line5: 1.27,
  },
  {
    age: 26,
    line1: 0.7,
    line2: 0.76,
    line3: 1,
    line4: 1.35,
    line5: 1.47,
  },
  {
    age: 27,
    line1: 0.8,
    line2: 0.86,
    line3: 1.14,
    line4: 1.53,
    line5: 1.67,
  },
  {
    age: 28,
    line1: 0.9,
    line2: 0.97,
    line3: 1.28,
    line4: 1.74,
    line5: 1.88,
  },
  {
    age: 29,
    line1: 1.02,
    line2: 1.09,
    line3: 1.43,
    line4: 1.95,
    line5: 2.11,
  },
  {
    age: 30,
    line1: 1.15,
    line2: 1.22,
    line3: 1.58,
    line4: 2.18,
    line5: 2.35,
  },
  {
    age: 31,
    line1: 1.28,
    line2: 1.36,
    line3: 1.75,
    line4: 2.43,
    line5: 2.61,
  },
  {
    age: 32,
    line1: 1.43,
    line2: 1.51,
    line3: 1.92,
    line4: 2.7,
    line5: 2.88,
  },
  {
    age: 33,
    line1: 1.58,
    line2: 1.67,
    line3: 2.08,
    line4: 2.98,
    line5: 3.17,
  },
  {
    age: 34,
    line1: 1.75,
    line2: 1.83,
    line3: 2.25,
    line4: 3.27,
    line5: 3.48,
  },
  {
    age: 35,
    line1: 1.92,
    line2: 2.01,
    line3: 2.4,
    line4: 3.58,
    line5: 3.79,
  },
  {
    age: 36,
    line1: 2.08,
    line2: 2.18,
    line3: 2.55,
    line4: 3.88,
    line5: 4.1,
  },
  {
    age: 37,
    line1: 2.25,
    line2: 2.35,
    line3: 2.69,
    line4: 4.18,
    line5: 4.4,
  },
  {
    age: 38,
    line1: 2.4,
    line2: 2.5,
    line3: 2.81,
    line4: 4.46,
    line5: 4.68,
  },
  {
    age: 39,
    line1: 2.55,
    line2: 2.64,
    line3: 2.92,
    line4: 4.74,
    line5: 4.95,
  },
  {
    age: 40,
    line1: 2.69,
    line2: 2.77,
    line3: 3.02,
    line4: 4.98,
    line5: 5.18,
  },
  {
    age: 41,
    line1: 2.81,
    line2: 2.9,
    line3: 3.12,
    line4: 5.21,
    line5: 5.39,
  },
  {
    age: 42,
    line1: 2.92,
    line2: 3,
    line3: 3.19,
    line4: 5.42,
    line5: 5.59,
  },
];

export const PRETERM_BOYS_HEADCIRCUMFERENCE_LINE_COORDS = [
  {
    age: 23,
    line1: 17.7,
    line2: 18.3,
    line3: 19.3,
    line4: 20.4,
    line5: 20.9,
  },
  {
    age: 24,
    line1: 18.5,
    line2: 19.1,
    line3: 20.1,
    line4: 21.2,
    line5: 21.7,
  },
  {
    age: 25,
    line1: 19.3,
    line2: 19.9,
    line3: 20.9,
    line4: 22,
    line5: 22.5,
  },
  {
    age: 26,
    line1: 20,
    line2: 20.7,
    line3: 21.7,
    line4: 22.8,
    line5: 23.3,
  },
  {
    age: 27,
    line1: 20.7,
    line2: 21.4,
    line3: 22.4,
    line4: 23.6,
    line5: 24.1,
  },
  {
    age: 28,
    line1: 21.4,
    line2: 22.1,
    line3: 23.1,
    line4: 24.3,
    line5: 24.8,
  },
  {
    age: 29,
    line1: 22.1,
    line2: 22.8,
    line3: 23.8,
    line4: 25,
    line5: 25.5,
  },
  {
    age: 30,
    line1: 22.8,
    line2: 23.5,
    line3: 24.5,
    line4: 25.7,
    line5: 26.2,
  },
  {
    age: 31,
    line1: 23.5,
    line2: 24.2,
    line3: 25.2,
    line4: 26.4,
    line5: 26.9,
  },
  {
    age: 32,
    line1: 24.2,
    line2: 24.9,
    line3: 25.9,
    line4: 27.1,
    line5: 27.6,
  },
  {
    age: 33,
    line1: 24.9,
    line2: 25.6,
    line3: 26.6,
    line4: 27.8,
    line5: 28.3,
  },
  {
    age: 34,
    line1: 25.6,
    line2: 26.3,
    line3: 27.3,
    line4: 28.5,
    line5: 29,
  },
  {
    age: 35,
    line1: 26.3,
    line2: 27,
    line3: 28,
    line4: 29.2,
    line5: 29.7,
  },
  {
    age: 36,
    line1: 27,
    line2: 27.7,
    line3: 28.7,
    line4: 29.9,
    line5: 30.4,
  },
  {
    age: 37,
    line1: 27.7,
    line2: 28.4,
    line3: 29.4,
    line4: 30.6,
    line5: 31.1,
  },
  {
    age: 38,
    line1: 28.4,
    line2: 29.1,
    line3: 30.1,
    line4: 31.3,
    line5: 31.8,
  },
  {
    age: 39,
    line1: 29.1,
    line2: 29.8,
    line3: 30.8,
    line4: 32,
    line5: 32.5,
  },
  {
    age: 40,
    line1: 29.8,
    line2: 30.4,
    line3: 31.5,
    line4: 32.7,
    line5: 33.2,
  },
  {
    age: 41,
    line1: 30.4,
    line2: 31,
    line3: 32.1,
    line4: 33.3,
    line5: 33.8,
  },
  {
    age: 42,
    line1: 31,
    line2: 31.5,
    line3: 32.6,
    line4: 33.8,
    line5: 34.3,
  },
];

export const PRETERM_GIRLS_HEADCIRCUMFERENCE_LINE_COORDS = [
  {
    age: 23,
    line1: 17.6,
    line2: 18.3,
    line3: 19.3,
    line4: 20.2,
    line5: 20.7,
  },
  {
    age: 24,
    line1: 18.3,
    line2: 19,
    line3: 20.1,
    line4: 21,
    line5: 21.5,
  },
  {
    age: 25,
    line1: 19.1,
    line2: 19.8,
    line3: 20.9,
    line4: 21.8,
    line5: 22.3,
  },
  {
    age: 26,
    line1: 19.9,
    line2: 20.6,
    line3: 21.7,
    line4: 22.6,
    line5: 23.1,
  },
  {
    age: 27,
    line1: 20.6,
    line2: 21.3,
    line3: 22.4,
    line4: 23.4,
    line5: 23.9,
  },
  {
    age: 28,
    line1: 21.3,
    line2: 22,
    line3: 23.1,
    line4: 24.1,
    line5: 24.6,
  },
  {
    age: 29,
    line1: 22,
    line2: 22.7,
    line3: 23.8,
    line4: 24.8,
    line5: 25.3,
  },
  {
    age: 30,
    line1: 22.7,
    line2: 23.4,
    line3: 24.5,
    line4: 25.5,
    line5: 26,
  },
  {
    age: 31,
    line1: 23.4,
    line2: 24.1,
    line3: 25.2,
    line4: 26.2,
    line5: 26.7,
  },
  {
    age: 32,
    line1: 24.1,
    line2: 24.8,
    line3: 25.9,
    line4: 26.9,
    line5: 27.4,
  },
  {
    age: 33,
    line1: 24.8,
    line2: 25.5,
    line3: 26.6,
    line4: 27.6,
    line5: 28.1,
  },
  {
    age: 34,
    line1: 25.5,
    line2: 26.2,
    line3: 27.3,
    line4: 28.3,
    line5: 28.8,
  },
  {
    age: 35,
    line1: 26.2,
    line2: 26.9,
    line3: 28,
    line4: 29,
    line5: 29.5,
  },
  {
    age: 36,
    line1: 26.9,
    line2: 27.6,
    line3: 28.7,
    line4: 29.7,
    line5: 30.2,
  },
  {
    age: 37,
    line1: 27.6,
    line2: 28.3,
    line3: 29.4,
    line4: 30.4,
    line5: 30.9,
  },
  {
    age: 38,
    line1: 28.3,
    line2: 29,
    line3: 30.1,
    line4: 31.1,
    line5: 31.6,
  },
  {
    age: 39,
    line1: 29,
    line2: 29.6,
    line3: 30.8,
    line4: 31.8,
    line5: 32.2,
  },
  {
    age: 40,
    line1: 29.7,
    line2: 30.3,
    line3: 31.4,
    line4: 32.4,
    line5: 32.8,
  },
  {
    age: 41,
    line1: 30.3,
    line2: 30.9,
    line3: 32,
    line4: 33,
    line5: 33.4,
  },
  {
    age: 42,
    line1: 30.9,
    line2: 31.4,
    line3: 32.5,
    line4: 33.5,
    line5: 33.9,
  },
];

export const PRETERM_BOYS_LENGTH_LINE_COORDS = [
  {
    age: 23,
    line1: 24.7,
    line2: 25.4,
    line3: 27,
    line4: 28.7,
    line5: 29.6,
  },
  {
    age: 24,
    line1: 25.7,
    line2: 26.4,
    line3: 28.1,
    line4: 29.9,
    line5: 30.8,
  },
  {
    age: 25,
    line1: 26.7,
    line2: 27.4,
    line3: 29.2,
    line4: 31.2,
    line5: 32.1,
  },
  {
    age: 26,
    line1: 27.7,
    line2: 28.4,
    line3: 30.3,
    line4: 32.4,
    line5: 33.4,
  },
  {
    age: 27,
    line1: 28.7,
    line2: 29.4,
    line3: 31.4,
    line4: 33.6,
    line5: 34.6,
  },
  {
    age: 28,
    line1: 29.7,
    line2: 30.4,
    line3: 32.5,
    line4: 34.8,
    line5: 35.9,
  },
  {
    age: 29,
    line1: 30.6,
    line2: 31.3,
    line3: 33.6,
    line4: 35.9,
    line5: 37.1,
  },
  {
    age: 30,
    line1: 31.5,
    line2: 32.2,
    line3: 34.7,
    line4: 37.1,
    line5: 38.3,
  },
  {
    age: 31,
    line1: 32.4,
    line2: 33.1,
    line3: 35.7,
    line4: 38.1,
    line5: 39.4,
  },
  {
    age: 32,
    line1: 33.3,
    line2: 34,
    line3: 36.7,
    line4: 39.2,
    line5: 40.5,
  },
  {
    age: 33,
    line1: 34.1,
    line2: 34.8,
    line3: 37.7,
    line4: 40.2,
    line5: 41.6,
  },
  {
    age: 34,
    line1: 34.9,
    line2: 35.6,
    line3: 38.6,
    line4: 41.2,
    line5: 42.7,
  },
  {
    age: 35,
    line1: 35.7,
    line2: 36.3,
    line3: 39.5,
    line4: 42.2,
    line5: 43.7,
  },
  {
    age: 36,
    line1: 36.4,
    line2: 37,
    line3: 40.3,
    line4: 43.1,
    line5: 44.7,
  },
  {
    age: 37,
    line1: 37.1,
    line2: 37.7,
    line3: 41.1,
    line4: 43.9,
    line5: 45.6,
  },
  {
    age: 38,
    line1: 37.8,
    line2: 38.4,
    line3: 41.8,
    line4: 44.7,
    line5: 46.4,
  },
  {
    age: 39,
    line1: 38.5,
    line2: 39,
    line3: 42.5,
    line4: 45.5,
    line5: 47.2,
  },
  {
    age: 40,
    line1: 39.1,
    line2: 39.6,
    line3: 43.1,
    line4: 46.2,
    line5: 48,
  },
  {
    age: 41,
    line1: 39.7,
    line2: 40.2,
    line3: 43.7,
    line4: 46.9,
    line5: 48.7,
  },
  {
    age: 42,
    line1: 40.2,
    line2: 40.7,
    line3: 44.3,
    line4: 47.5,
    line5: 49.4,
  },
];

export const PRETERM_GIRLS_LENGTH_LINE_COORDS = [
  {
    age: 23,
    line1: 24.6,
    line2: 25.3,
    line3: 26.8,
    line4: 28.5,
    line5: 29.4,
  },
  {
    age: 24,
    line1: 25.6,
    line2: 26.3,
    line3: 27.9,
    line4: 29.7,
    line5: 30.6,
  },
  {
    age: 25,
    line1: 26.5,
    line2: 27.3,
    line3: 28.9,
    line4: 30.9,
    line5: 31.9,
  },
  {
    age: 26,
    line1: 27.4,
    line2: 28.2,
    line3: 29.9,
    line4: 32,
    line5: 33.1,
  },
  {
    age: 27,
    line1: 28.3,
    line2: 29.1,
    line3: 30.9,
    line4: 33.2,
    line5: 34.3,
  },
  {
    age: 28,
    line1: 29.1,
    line2: 30,
    line3: 31.9,
    line4: 34.3,
    line5: 35.5,
  },
  {
    age: 29,
    line1: 29.9,
    line2: 30.8,
    line3: 32.8,
    line4: 35.3,
    line5: 36.7,
  },
  {
    age: 30,
    line1: 30.7,
    line2: 31.6,
    line3: 33.7,
    line4: 36.4,
    line5: 37.8,
  },
  {
    age: 31,
    line1: 31.5,
    line2: 32.4,
    line3: 34.6,
    line4: 37.4,
    line5: 38.9,
  },
  {
    age: 32,
    line1: 32.3,
    line2: 33.1,
    line3: 35.5,
    line4: 38.3,
    line5: 39.9,
  },
  {
    age: 33,
    line1: 33,
    line2: 33.8,
    line3: 36.3,
    line4: 39.2,
    line5: 40.9,
  },
  {
    age: 34,
    line1: 33.7,
    line2: 34.5,
    line3: 37.1,
    line4: 40.1,
    line5: 41.8,
  },
  {
    age: 35,
    line1: 34.4,
    line2: 35.1,
    line3: 37.9,
    line4: 41,
    line5: 42.7,
  },
  {
    age: 36,
    line1: 35,
    line2: 35.8,
    line3: 38.7,
    line4: 41.8,
    line5: 43.6,
  },
  {
    age: 37,
    line1: 35.7,
    line2: 36.4,
    line3: 39.4,
    line4: 42.6,
    line5: 44.5,
  },
  {
    age: 38,
    line1: 36.3,
    line2: 37,
    line3: 40.1,
    line4: 43.4,
    line5: 45.3,
  },
  {
    age: 39,
    line1: 36.9,
    line2: 37.6,
    line3: 40.8,
    line4: 44.1,
    line5: 46.1,
  },
  {
    age: 40,
    line1: 37.5,
    line2: 38.2,
    line3: 41.4,
    line4: 44.8,
    line5: 46.8,
  },
  {
    age: 41,
    line1: 38,
    line2: 38.7,
    line3: 42,
    line4: 45.4,
    line5: 47.4,
  },
  {
    age: 42,
    line1: 38.6,
    line2: 39.2,
    line3: 42.6,
    line4: 46,
    line5: 48,
  },
];

/**
 * DO NOT DELETE THIS FUNCTION
 * @description used to tranform MATURE line coords
 * @author Rajat Saini
 * @date Jun 21, 2023
 */

var data = `0	30.326	31.51	32.694	33.879	35.063	36.247	37.432
1	30.429	31.611	32.793	33.975	35.157	36.339	37.521
2	30.534	31.713	32.892	34.071	35.251	36.43	37.609
3	30.637	31.814	32.991	34.168	35.344	36.521	37.698
4	30.742	31.916	33.09	34.264	35.438	36.612	37.786
5	30.846	32.018	33.189	34.36	35.532	36.703	37.874
6	30.951	32.12	33.288	34.457	35.625	36.793	37.962
7	31.055	32.221	33.387	34.553	35.719	36.885	38.05
8	31.161	32.324	33.486	34.649	35.812	36.975	38.138
9	31.265	32.425	33.585	34.746	35.906	37.066	38.226
10	31.371	32.528	33.685	34.842	35.999	37.156	38.313
11	31.475	32.629	33.784	34.938	36.093	37.247	38.401
12	31.581	32.732	33.883	35.034	36.186	37.337	38.488
13	31.686	32.834	33.982	35.131	36.279	37.428	38.576
14	31.791	32.937	34.082	35.227	36.372	37.518	38.663
15	31.879	33.027	34.174	35.321	36.468	37.616	38.763
16	31.966	33.115	34.264	35.413	36.562	37.711	38.86
17	32.05	33.201	34.352	35.503	36.654	37.805	38.956
18	32.132	33.285	34.438	35.591	36.743	37.896	39.049
19	32.213	33.368	34.522	35.677	36.831	37.986	39.14
20	32.292	33.448	34.605	35.761	36.917	38.073	39.229
21	32.369	33.527	34.685	35.843	37.001	38.159	39.317
22	32.445	33.604	34.764	35.924	37.083	38.243	39.403
23	32.518	33.68	34.841	36.003	37.164	38.326	39.487
24	32.592	33.755	34.917	36.08	37.243	38.406	39.569
25	32.663	33.827	34.992	36.156	37.321	38.485	39.65
26	32.732	33.898	35.065	36.231	37.397	38.563	39.73
27	32.8	33.968	35.136	36.304	37.472	38.64	39.808
28	32.868	34.037	35.207	36.376	37.546	38.715	39.885
29	32.934	34.105	35.276	36.447	37.618	38.789	39.96
30	32.999	34.171	35.344	36.516	37.689	38.861	40.034
31	33.063	34.237	35.411	36.585	37.759	38.933	40.107
32	33.126	34.301	35.476	36.652	37.827	39.003	40.178
33	33.186	34.364	35.541	36.718	37.895	39.072	40.25
34	33.248	34.426	35.605	36.783	37.962	39.14	40.319
35	33.308	34.488	35.667	36.847	38.027	39.207	40.387
36	33.367	34.548	35.729	36.91	38.092	39.273	40.454
37	33.424	34.607	35.79	36.973	38.155	39.338	40.521
38	33.482	34.666	35.85	37.034	38.218	39.402	40.586
39	33.538	34.723	35.909	37.094	38.28	39.466	40.651
40	33.594	34.781	35.967	37.154	38.341	39.528	40.714
41	33.648	34.837	36.025	37.213	38.401	39.589	40.778
42	33.703	34.892	36.082	37.271	38.46	39.65	40.839
43	33.756	34.947	36.138	37.328	38.519	39.71	40.901
44	33.81	35.001	36.193	37.385	38.577	39.769	40.961
45	33.861	35.055	36.248	37.441	38.634	39.828	41.021
46	33.912	35.107	36.302	37.496	38.691	39.886	41.08
47	33.964	35.16	36.355	37.551	38.747	39.942	41.138
48	34.014	35.211	36.408	37.605	38.802	39.999	41.196
49	34.064	35.262	36.46	37.658	38.857	40.055	41.253
50	34.114	35.313	36.512	37.711	38.91	40.11	41.309
51	34.162	35.362	36.563	37.764	38.964	40.165	41.365
52	34.21	35.412	36.613	37.815	39.017	40.219	41.421
53	34.257	35.46	36.663	37.866	39.069	40.272	41.475
54	34.304	35.508	36.713	37.917	39.121	40.325	41.53
55	34.352	35.557	36.762	37.967	39.172	40.377	41.582
56	34.398	35.604	36.81	38.017	39.223	40.429	41.636
57	34.443	35.651	36.858	38.066	39.273	40.481	41.688
58	34.489	35.697	36.906	38.114	39.323	40.532	41.74
59	34.534	35.743	36.953	38.163	39.373	40.582	41.792
60	34.578	35.789	37	38.211	39.421	40.632	41.843
61	34.622	35.834	37.046	38.258	39.47	40.682	41.894
62	34.666	35.879	37.092	38.305	39.518	40.731	41.944
63	34.709	35.923	37.137	38.352	39.566	40.78	41.994
64	34.753	35.968	37.183	38.398	39.613	40.828	42.043
65	34.796	36.012	37.228	38.444	39.66	40.876	42.092
66	34.838	36.055	37.272	38.489	39.706	40.923	42.14
67	34.88	36.098	37.316	38.534	39.752	40.97	42.188
68	34.922	36.141	37.36	38.579	39.798	41.017	42.236
69	34.963	36.183	37.403	38.623	39.843	41.064	42.284
70	35.004	36.225	37.446	38.667	39.888	41.11	42.331
71	35.044	36.266	37.489	38.711	39.933	41.156	42.378
72	35.084	36.307	37.531	38.754	39.978	41.201	42.425
73	35.124	36.348	37.573	38.797	40.022	41.246	42.471
74	35.164	36.389	37.615	38.84	40.065	41.291	42.516
75	35.203	36.43	37.656	38.882	40.109	41.335	42.561
76	35.243	36.47	37.697	38.924	40.152	41.379	42.606
77	35.281	36.51	37.738	38.966	40.194	41.423	42.651
78	35.32	36.549	37.778	39.008	40.237	41.466	42.695
79	35.359	36.589	37.819	39.049	40.279	41.509	42.739
80	35.397	36.628	37.859	39.09	40.32	41.551	42.782
81	35.433	36.666	37.898	39.13	40.362	41.595	42.827
82	35.471	36.704	37.937	39.17	40.403	41.637	42.87
83	35.509	36.742	37.976	39.21	40.444	41.678	42.912
84	35.546	36.78	38.015	39.25	40.485	41.72	42.955
85	35.583	36.818	38.054	39.29	40.525	41.761	42.997
86	35.619	36.856	38.092	39.329	40.565	41.802	43.038
87	35.655	36.892	38.13	39.368	40.605	41.843	43.081
88	35.691	36.929	38.168	39.406	40.645	41.883	43.122
89	35.727	36.966	38.205	39.445	40.684	41.924	43.163
90	35.763	37.003	38.243	39.483	40.723	41.963	43.203
91	35.798	37.039	38.28	39.521	40.762	42.003	43.244
92	35.832	37.074	38.317	39.559	40.801	42.043	43.285
93	35.867	37.11	38.353	39.596	40.839	42.082	43.325
94	35.902	37.146	38.39	39.634	40.877	42.121	43.365
95	35.937	37.182	38.426	39.67	40.915	42.159	43.404
96	35.97	37.216	38.462	39.707	40.953	42.199	43.444
97	36.005	37.251	38.497	39.744	40.99	42.237	43.483
98	36.039	37.286	38.533	39.78	41.027	42.274	43.522
99	36.073	37.321	38.568	39.816	41.064	42.312	43.56
100	36.105	37.354	38.603	39.852	41.101	42.35	43.599
101	36.139	37.389	38.638	39.888	41.138	42.387	43.637
102	36.172	37.423	38.673	39.923	41.174	42.424	43.674
103	36.205	37.456	38.707	39.959	41.21	42.461	43.712
104	36.237	37.489	38.741	39.994	41.246	42.498	43.75
105	36.27	37.523	38.776	40.028	41.281	42.534	43.787
106	36.302	37.556	38.809	40.063	41.317	42.57	43.824
107	36.333	37.588	38.843	40.097	41.352	42.607	43.861
108	36.366	37.621	38.876	40.132	41.387	42.642	43.898
109	36.398	37.654	38.91	40.166	41.422	42.678	43.934
110	36.428	37.685	38.942	40.199	41.456	42.713	43.971
111	36.46	37.718	38.975	40.233	41.491	42.748	44.006
112	36.491	37.75	39.008	40.266	41.525	42.783	44.041
113	36.521	37.781	39.04	40.3	41.559	42.818	44.078
114	36.553	37.813	39.073	40.332	41.592	42.852	44.112
115	36.583	37.844	39.105	40.365	41.626	42.887	44.147
116	36.613	37.875	39.136	40.398	41.66	42.921	44.183
117	36.644	37.906	39.168	40.43	41.693	42.955	44.217
118	36.674	37.937	39.2	40.462	41.725	42.988	44.251
119	36.703	37.967	39.231	40.495	41.758	43.022	44.286
120	36.733	37.998	39.262	40.526	41.791	43.055	44.32
121	36.762	38.027	39.293	40.558	41.824	43.089	44.354
122	36.792	38.058	39.324	40.59	41.855	43.121	44.387
123	36.821	38.088	39.354	40.621	41.887	43.154	44.42
124	36.849	38.117	39.384	40.652	41.919	43.187	44.454
125	36.879	38.147	39.415	40.683	41.951	43.219	44.487
126	36.906	38.176	39.445	40.714	41.983	43.252	44.521
127	36.935	38.205	39.475	40.744	42.014	43.283	44.553
128	36.964	38.234	39.504	40.775	42.045	43.315	44.585
129	36.992	38.263	39.534	40.805	42.076	43.347	44.618
130	37.02	38.292	39.563	40.835	42.106	43.378	44.65
131	37.047	38.32	39.592	40.865	42.137	43.41	44.682
132	37.075	38.348	39.621	40.894	42.167	43.44	44.714
133	37.103	38.377	39.65	40.924	42.197	43.471	44.745
134	37.13	38.404	39.679	40.953	42.228	43.502	44.777
135	37.158	38.432	39.707	40.982	42.257	43.532	44.807
136	37.184	38.46	39.736	41.012	42.287	43.563	44.839
137	37.211	38.488	39.764	41.04	42.317	43.593	44.869
138	37.237	38.515	39.792	41.069	42.346	43.623	44.901
139	37.264	38.542	39.82	41.098	42.375	43.653	44.931
140	37.291	38.57	39.848	41.126	42.404	43.682	44.96
141	37.317	38.596	39.875	41.154	42.433	43.712	44.991
142	37.344	38.623	39.903	41.182	42.462	43.741	45.021
143	37.369	38.649	39.93	41.21	42.49	43.771	45.051
144	37.395	38.676	39.957	41.238	42.519	43.799	45.08
145	37.42	38.702	39.984	41.265	42.547	43.829	45.11
146	37.446	38.728	40.011	41.293	42.575	43.857	45.139
147	37.471	38.754	40.037	41.32	42.603	43.886	45.169
148	37.497	38.78	40.064	41.347	42.631	43.914	45.197
149	37.521	38.806	40.09	41.374	42.658	43.943	45.227
150	37.547	38.832	40.116	41.401	42.686	43.97	45.255
151	37.571	38.857	40.142	41.428	42.713	43.998	45.284
152	37.596	38.882	40.168	41.454	42.74	44.026	45.312
153	37.62	38.907	40.194	41.48	42.767	44.054	45.341
154	37.645	38.932	40.219	41.507	42.794	44.081	45.368
155	37.669	38.957	40.245	41.533	42.821	44.109	45.396
156	37.694	38.982	40.27	41.559	42.847	44.135	45.424
157	37.718	39.007	40.296	41.584	42.873	44.162	45.451
158	37.742	39.031	40.321	41.61	42.899	44.189	45.478
159	37.766	39.056	40.346	41.636	42.925	44.215	45.505
160	37.789	39.08	40.37	41.661	42.952	44.242	45.533
161	37.812	39.103	40.395	41.686	42.978	44.269	45.56
162	37.836	39.128	40.419	41.711	43.003	44.295	45.587
163	37.858	39.151	40.444	41.736	43.029	44.321	45.614
164	37.882	39.175	40.468	41.761	43.054	44.347	45.64
165	37.905	39.198	40.492	41.786	43.079	44.373	45.667
166	37.928	39.222	40.516	41.81	43.104	44.398	45.692
167	37.95	39.245	40.54	41.835	43.129	44.424	45.719
168	37.974	39.269	40.564	41.859	43.154	44.449	45.744
169	37.996	39.291	40.587	41.883	43.179	44.475	45.771
170	38.019	39.315	40.611	41.907	43.203	44.499	45.796
171	38.04	39.337	40.634	41.931	43.228	44.525	45.822
172	38.063	39.36	40.658	41.955	43.252	44.549	45.847
173	38.084	39.382	40.68	41.978	43.276	44.574	45.872
174	38.107	39.405	40.704	42.002	43.3	44.598	45.897
175	38.128	39.427	40.726	42.025	43.324	44.623	45.922
176	38.151	39.45	40.749	42.048	43.348	44.647	45.946
177	38.172	39.472	40.772	42.072	43.372	44.672	45.972
178	38.194	39.494	40.794	42.095	43.395	44.695	45.996
179	38.215	39.516	40.817	42.118	43.419	44.72	46.021
180	38.235	39.537	40.839	42.14	43.442	44.744	46.045
181	38.257	39.559	40.861	42.163	43.465	44.767	46.069
182	38.277	39.58	40.883	42.186	43.488	44.791	46.094
183	38.299	39.602	40.905	42.208	43.511	44.814	46.117
184	38.319	39.623	40.927	42.23	43.534	44.837	46.141
185	38.341	39.644	40.948	42.252	43.556	44.86	46.164
186	38.361	39.665	40.97	42.274	43.579	44.884	46.188
187	38.382	39.687	40.991	42.296	43.601	44.906	46.211
188	38.402	39.707	41.013	42.318	43.624	44.929	46.235
189	38.421	39.727	41.034	42.34	43.646	44.952	46.258
190	38.442	39.749	41.055	42.361	43.668	44.974	46.281
191	38.462	39.769	41.076	42.383	43.69	44.997	46.304
192	38.482	39.79	41.097	42.404	43.712	45.019	46.326
193	38.502	39.81	41.118	42.426	43.733	45.041	46.349
194	38.522	39.83	41.138	42.447	43.755	45.063	46.371
195	38.541	39.85	41.159	42.468	43.776	45.085	46.394
196	38.56	39.87	41.179	42.488	43.798	45.107	46.417
197	38.58	39.89	41.2	42.509	43.819	45.129	46.438
198	38.599	39.909	41.22	42.53	43.84	45.151	46.461
199	38.619	39.929	41.24	42.551	43.861	45.172	46.482
200	38.638	39.949	41.26	42.571	43.882	45.193	46.505
201	38.657	39.969	41.28	42.592	43.903	45.214	46.526
202	38.676	39.988	41.3	42.612	43.924	45.236	46.548
203	38.694	40.007	41.319	42.632	43.945	45.257	46.57
204	38.713	40.026	41.339	42.652	43.965	45.278	46.59
205	38.732	40.045	41.358	42.672	43.985	45.299	46.612
206	38.751	40.064	41.378	42.692	44.005	45.319	46.633
207	38.769	40.083	41.397	42.712	44.026	45.34	46.654
208	38.787	40.101	41.416	42.731	44.046	45.361	46.676
209	38.806	40.121	41.436	42.751	44.066	45.381	46.696
210	38.823	40.139	41.454	42.77	44.086	45.401	46.717
211	38.842	40.158	41.474	42.789	44.105	45.421	46.737
212	38.86	40.176	41.492	42.809	44.125	45.441	46.758
213	38.877	40.194	41.511	42.828	44.145	45.462	46.779
214	38.896	40.213	41.53	42.847	44.164	45.481	46.798
215	38.913	40.23	41.548	42.866	44.183	45.501	46.819
216	38.931	40.249	41.567	42.885	44.202	45.52	46.838
217	38.948	40.267	41.585	42.903	44.222	45.54	46.859
218	38.965	40.284	41.603	42.922	44.241	45.56	46.879
219	38.983	40.302	41.621	42.941	44.26	45.579	46.898
220	39	40.32	41.639	42.959	44.279	45.599	46.918
221	39.017	40.337	41.657	42.977	44.298	45.618	46.938
222	39.035	40.355	41.675	42.996	44.316	45.636	46.957
223	39.051	40.372	41.693	43.014	44.335	45.656	46.977
224	39.069	40.39	41.711	43.032	44.353	45.674	46.995
225	39.085	40.407	41.728	43.05	44.372	45.693	47.015
226	39.101	40.424	41.746	43.068	44.39	45.712	47.034
227	39.119	40.441	41.763	43.086	44.408	45.73	47.053
228	39.135	40.458	41.781	43.103	44.426	45.749	47.072
229	39.151	40.474	41.798	43.121	44.444	45.768	47.091
230	39.168	40.492	41.815	43.139	44.462	45.786	47.109
231	39.184	40.508	41.832	43.156	44.48	45.804	47.128
232	39.201	40.525	41.849	43.173	44.498	45.822	47.146
233	39.217	40.541	41.866	43.191	44.515	45.84	47.165
234	39.232	40.558	41.883	43.208	44.533	45.858	47.183
235	39.249	40.574	41.9	43.225	44.55	45.876	47.201
236	39.265	40.59	41.916	43.242	44.568	45.894	47.22
237	39.28	40.606	41.933	43.259	44.585	45.912	47.238
238	39.297	40.623	41.949	43.276	44.602	45.929	47.255
239	39.312	40.639	41.966	43.293	44.62	45.946	47.273
240	39.327	40.654	41.982	43.309	44.637	45.964	47.292
241	39.343	40.671	41.998	43.326	44.653	45.981	47.308
242	39.358	40.686	42.014	43.342	44.67	45.998	47.326
243	39.375	40.703	42.031	43.359	44.687	46.015	47.343
244	39.39	40.718	42.047	43.375	44.704	46.032	47.361
245	39.404	40.733	42.062	43.392	44.721	46.05	47.379
246	39.42	40.749	42.079	43.408	44.737	46.066	47.395
247	39.435	40.765	42.094	43.424	44.754	46.083	47.413
248	39.45	40.78	42.11	43.44	44.77	46.1	47.43
249	39.465	40.796	42.126	43.456	44.786	46.116	47.446
250	39.48	40.81	42.141	43.472	44.802	46.133	47.464
251	39.494	40.825	42.156	43.488	44.819	46.15	47.481
252	39.51	40.841	42.172	43.503	44.835	46.166	47.497
253	39.524	40.856	42.187	43.519	44.851	46.182	47.514
254	39.538	40.87	42.202	43.534	44.867	46.199	47.531
255	39.553	40.886	42.218	43.55	44.882	46.214	47.547
256	39.567	40.9	42.233	43.565	44.898	46.231	47.563
257	39.581	40.915	42.248	43.581	44.914	46.247	47.58
258	39.597	40.93	42.263	43.596	44.929	46.262	47.596
259	39.61	40.944	42.278	43.611	44.945	46.279	47.612
260	39.624	40.958	42.292	43.626	44.96	46.295	47.629
261	39.639	40.973	42.307	43.642	44.976	46.31	47.644
262	39.653	40.987	42.322	43.656	44.991	46.326	47.66
263	39.666	41.001	42.336	43.671	45.006	46.341	47.677
264	39.681	41.016	42.351	43.686	45.021	46.356	47.691
265	39.694	41.03	42.365	43.701	45.037	46.372	47.708
266	39.708	41.044	42.38	43.716	45.052	46.388	47.724
267	39.722	41.058	42.394	43.73	45.066	46.402	47.738
268	39.736	41.072	42.408	43.745	45.081	46.418	47.754
269	39.749	41.086	42.423	43.759	45.096	46.433	47.77
270	39.763	41.1	42.437	43.774	45.111	46.448	47.784
271	39.776	41.114	42.451	43.788	45.125	46.463	47.8
272	39.789	41.127	42.465	43.802	45.14	46.478	47.816
273	39.804	41.141	42.479	43.817	45.154	46.492	47.83
274	39.816	41.155	42.493	43.831	45.169	46.507	47.845
275	39.829	41.168	42.506	43.845	45.184	46.522	47.861
276	39.843	41.182	42.52	43.859	45.198	46.536	47.875
277	39.856	41.195	42.534	43.873	45.212	46.551	47.89
278	39.869	41.208	42.547	43.887	45.226	46.566	47.905
279	39.882	41.222	42.561	43.901	45.24	46.58	47.919
280	39.895	41.235	42.575	43.914	45.254	46.594	47.934
281	39.907	41.248	42.588	43.928	45.268	46.609	47.949
282	39.921	41.261	42.602	43.942	45.282	46.622	47.963
283	39.934	41.274	42.615	43.956	45.296	46.637	47.977
284	39.946	41.287	42.628	43.969	45.31	46.651	47.992
285	39.959	41.3	42.641	43.982	45.323	46.664	48.005
286	39.972	41.313	42.654	43.996	45.337	46.679	48.02
287	39.984	41.326	42.667	44.009	45.351	46.693	48.035
288	39.996	41.338	42.68	44.022	45.365	46.707	48.049
289	40.009	41.351	42.693	44.036	45.378	46.72	48.062
290	40.021	41.364	42.706	44.049	45.392	46.734	48.077
291	40.033	41.376	42.719	44.062	45.405	46.748	48.091
292	40.046	41.389	42.732	44.075	45.418	46.761	48.104
293	40.058	41.401	42.745	44.088	45.431	46.775	48.118
294	40.07	41.413	42.757	44.101	45.445	46.789	48.132
295	40.083	41.426	42.77	44.114	45.458	46.801	48.145
296	40.094	41.439	42.783	44.127	45.471	46.815	48.159
297	40.106	41.451	42.795	44.14	45.484	46.828	48.173
298	40.119	41.463	42.808	44.152	45.497	46.841	48.186
299	40.13	41.475	42.82	44.165	45.51	46.854	48.199
300	40.142	41.487	42.832	44.177	45.523	46.868	48.213
301	40.153	41.499	42.844	44.19	45.536	46.881	48.227
302	40.166	41.511	42.857	44.202	45.548	46.894	48.239
303	40.177	41.523	42.869	44.215	45.561	46.907	48.253
304	40.188	41.535	42.881	44.227	45.574	46.92	48.266
305	40.201	41.547	42.893	44.24	45.586	46.932	48.278
306	40.212	41.559	42.905	44.252	45.598	46.945	48.292
307	40.223	41.57	42.917	44.264	45.611	46.958	48.305
308	40.234	41.582	42.929	44.276	45.624	46.971	48.318
309	40.247	41.594	42.941	44.288	45.636	46.983	48.33
310	40.258	41.605	42.953	44.3	45.648	46.996	48.343
311	40.269	41.617	42.965	44.312	45.66	47.008	48.356
312	40.281	41.629	42.977	44.324	45.672	47.02	48.368
313	40.292	41.64	42.988	44.336	45.685	47.033	48.381
314	40.302	41.651	43	44.348	45.697	47.046	48.394
315	40.314	41.663	43.012	44.36	45.709	47.057	48.406
316	40.325	41.674	43.023	44.372	45.721	47.07	48.419
317	40.336	41.685	43.034	44.384	45.733	47.082	48.431
318	40.346	41.696	43.046	44.395	45.745	47.094	48.444
319	40.358	41.708	43.057	44.407	45.756	47.106	48.455
320	40.369	41.719	43.069	44.418	45.768	47.118	48.468
321	40.379	41.73	43.08	44.43	45.78	47.13	48.481
322	40.391	41.741	43.091	44.441	45.792	47.142	48.492
323	40.401	41.752	43.102	44.453	45.803	47.154	48.504
324	40.412	41.763	43.113	44.464	45.815	47.166	48.517
325	40.422	41.773	43.124	44.476	45.827	47.178	48.529
326	40.434	41.785	43.136	44.487	45.838	47.189	48.54
327	40.444	41.795	43.147	44.498	45.85	47.201	48.552
328	40.454	41.806	43.158	44.509	45.861	47.213	48.565
329	40.464	41.816	43.168	44.52	45.873	47.225	48.577
330	40.476	41.828	43.18	44.532	45.884	47.236	48.588
331	40.486	41.838	43.19	44.543	45.895	47.247	48.6
332	40.496	41.848	43.201	44.554	45.906	47.259	48.612
333	40.507	41.86	43.212	44.565	45.917	47.27	48.622
334	40.517	41.87	43.223	44.576	45.928	47.281	48.634
335	40.527	41.88	43.233	44.586	45.94	47.293	48.646
336	40.537	41.89	43.244	44.597	45.951	47.304	48.658
337	40.548	41.901	43.255	44.608	45.962	47.315	48.668
338	40.558	41.911	43.265	44.619	45.973	47.326	48.68
339	40.567	41.921	43.276	44.63	45.984	47.338	48.692
340	40.577	41.931	43.286	44.64	45.995	47.349	48.703
341	40.588	41.942	43.297	44.651	46.005	47.359	48.714
342	40.598	41.952	43.307	44.662	46.016	47.371	48.725
343	40.607	41.962	43.317	44.672	46.027	47.382	48.737
344	40.618	41.973	43.328	44.682	46.037	47.392	48.747
345	40.628	41.983	43.338	44.693	46.048	47.403	48.758
346	40.637	41.993	43.348	44.703	46.059	47.414	48.77
347	40.647	42.002	43.358	44.714	46.07	47.425	48.781
348	40.657	42.013	43.369	44.724	46.08	47.435	48.791
349	40.667	42.023	43.379	44.734	46.09	47.446	48.802
350	40.676	42.032	43.388	44.745	46.101	47.457	48.813
351	40.685	42.042	43.398	44.755	46.111	47.468	48.824
352	40.696	42.052	43.409	44.765	46.121	47.478	48.834
353	40.705	42.062	43.419	44.775	46.132	47.489	48.845
354	40.714	42.071	43.428	44.785	46.142	47.499	48.856
355	40.723	42.081	43.438	44.795	46.153	47.51	48.867
356	40.734	42.091	43.448	44.805	46.163	47.52	48.877
357	40.743	42.1	43.458	44.815	46.173	47.53	48.888
358	40.752	42.11	43.468	44.825	46.183	47.541	48.899
359	40.762	42.12	43.478	44.835	46.193	47.551	48.908
360	40.771	42.129	43.487	44.845	46.203	47.561	48.919
361	40.78	42.139	43.497	44.855	46.213	47.571	48.93
362	40.789	42.148	43.506	44.865	46.223	47.582	48.94
363	40.8	42.158	43.516	44.875	46.233	47.591	48.95
364	40.808	42.167	43.526	44.884	46.243	47.602	48.96
365	40.817	42.176	43.535	44.894	46.253	47.612	48.971
366	40.826	42.185	43.544	44.904	46.263	47.622	48.981
367	40.836	42.195	43.554	44.913	46.272	47.631	48.991
368	40.845	42.204	43.564	44.923	46.282	47.642	49.001
369	40.854	42.213	43.573	44.932	46.292	47.652	49.011
370	40.862	42.222	43.582	44.942	46.302	47.662	49.022
371	40.872	42.232	43.592	44.952	46.311	47.671	49.031
372	40.881	42.241	43.601	44.961	46.321	47.681	49.041
373	40.889	42.25	43.61	44.97	46.331	47.691	49.051
374	40.898	42.258	43.619	44.98	46.34	47.701	49.062
375	40.908	42.268	43.629	44.989	46.35	47.71	49.071
376	40.916	42.277	43.638	44.998	46.359	47.72	49.081
377	40.925	42.286	43.647	45.008	46.369	47.73	49.091
378	40.933	42.294	43.656	45.017	46.378	47.74	49.101
379	40.943	42.304	43.665	45.026	46.387	47.748	49.11
380	40.951	42.312	43.674	45.035	46.397	47.758	49.12
381	40.959	42.321	43.683	45.044	46.406	47.768	49.13
382	40.968	42.33	43.692	45.054	46.416	47.778	49.14
383	40.977	42.339	43.701	45.063	46.424	47.786	49.148
384	40.985	42.348	43.71	45.072	46.434	47.796	49.158
385	40.994	42.356	43.718	45.081	46.443	47.805	49.168
386	41.002	42.364	43.727	45.09	46.452	47.815	49.178
387	41.011	42.374	43.736	45.099	46.461	47.824	49.186
388	41.019	42.382	43.745	45.108	46.47	47.833	49.196
389	41.028	42.391	43.754	45.116	46.479	47.842	49.205
390	41.036	42.399	43.762	45.125	46.489	47.852	49.215
391	41.045	42.408	43.771	45.134	46.497	47.86	49.223
392	41.053	42.416	43.78	45.143	46.506	47.87	49.233
393	41.061	42.425	43.788	45.152	46.515	47.879	49.243
394	41.069	42.433	43.797	45.16	46.524	47.888	49.252
395	41.078	42.442	43.806	45.169	46.533	47.897	49.26
396	41.086	42.45	43.814	45.178	46.542	47.906	49.27
397	41.094	42.458	43.822	45.187	46.551	47.915	49.279
398	41.102	42.466	43.831	45.195	46.56	47.924	49.289
399	41.11	42.474	43.839	45.204	46.569	47.933	49.298
400	41.119	42.483	43.848	45.212	46.577	47.941	49.306
401	41.127	42.491	43.856	45.221	46.586	47.95	49.315
402	41.134	42.499	43.864	45.229	46.594	47.959	49.324
403	41.142	42.507	43.873	45.238	46.603	47.968	49.334
404	41.151	42.516	43.881	45.246	46.611	47.976	49.342
405	41.159	42.524	43.889	45.255	46.62	47.985	49.351
406	41.166	42.532	43.898	45.263	46.629	47.994	49.36
407	41.174	42.54	43.906	45.272	46.637	48.003	49.369
408	41.183	42.549	43.914	45.28	46.646	48.011	49.377
409	41.191	42.556	43.922	45.288	46.654	48.02	49.386
410	41.198	42.564	43.93	45.296	46.663	48.029	49.395
411	41.206	42.572	43.938	45.305	46.671	48.037	49.404
412	41.214	42.581	43.947	45.313	46.679	48.045	49.412
413	41.222	42.588	43.955	45.321	46.688	48.054	49.421
414	41.229	42.596	43.963	45.329	46.696	48.063	49.429
415	41.237	42.604	43.971	45.338	46.704	48.071	49.438
416	41.244	42.611	43.978	45.346	46.713	48.08	49.447
417	41.253	42.62	43.987	45.354	46.721	48.088	49.455
418	41.26	42.627	43.995	45.362	46.729	48.096	49.463
419	41.268	42.635	44.002	45.37	46.737	48.105	49.472
420	41.275	42.643	44.01	45.378	46.746	48.113	49.481
421	41.283	42.651	44.018	45.386	46.753	48.121	49.488
422	41.291	42.658	44.026	45.394	46.762	48.129	49.497
423	41.298	42.666	44.034	45.402	46.77	48.138	49.506
424	41.305	42.673	44.042	45.41	46.778	48.146	49.514
425	41.312	42.681	44.049	45.418	46.786	48.154	49.523
426	41.321	42.689	44.057	45.426	46.794	48.162	49.53
427	41.328	42.696	44.065	45.433	46.802	48.17	49.539
428	41.335	42.704	44.073	45.441	46.81	48.179	49.547
429	41.342	42.711	44.08	45.449	46.818	48.187	49.556
430	41.351	42.719	44.088	45.457	46.826	48.194	49.563
431	41.358	42.727	44.096	45.464	46.833	48.202	49.571
432	41.365	42.734	44.103	45.472	46.841	48.211	49.58
433	41.372	42.741	44.111	45.48	46.849	48.219	49.588
434	41.38	42.749	44.118	45.488	46.857	48.226	49.595
435	41.387	42.756	44.126	45.495	46.865	48.234	49.604
436	41.394	42.764	44.133	45.503	46.873	48.242	49.612
437	41.401	42.771	44.141	45.51	46.88	48.25	49.62
438	41.408	42.778	44.148	45.518	46.888	48.258	49.628
439	41.416	42.786	44.156	45.526	46.896	48.265	49.635
440	41.423	42.793	44.163	45.533	46.903	48.273	49.643
441	41.43	42.8	44.17	45.541	46.911	48.281	49.652
442	41.437	42.807	44.178	45.548	46.919	48.289	49.66
443	41.445	42.815	44.185	45.556	46.926	48.296	49.667
444	41.452	42.822	44.193	45.563	46.934	48.304	49.675
445	41.458	42.829	44.2	45.571	46.941	48.312	49.683
446	41.465	42.836	44.207	45.578	46.949	48.32	49.691
447	41.472	42.843	44.214	45.585	46.957	48.328	49.699
448	41.48	42.851	44.222	45.593	46.964	48.335	49.706
449	41.487	42.858	44.229	45.6	46.971	48.343	49.714
450	41.493	42.865	44.236	45.608	46.979	48.35	49.722
451	41.5	42.872	44.243	45.615	46.986	48.358	49.73
452	41.507	42.878	44.25	45.622	46.994	48.366	49.738
453	41.514	42.886	44.258	45.629	47.001	48.373	49.744
454	41.521	42.893	44.265	45.637	47.008	48.38	49.752
455	41.528	42.9	44.272	45.644	47.016	48.388	49.76
456	41.534	42.906	44.279	45.651	47.023	48.396	49.768
457	41.542	42.914	44.286	45.658	47.03	48.402	49.774
458	41.549	42.921	44.293	45.665	47.038	48.41	49.782
459	41.555	42.928	44.3	45.672	47.045	48.417	49.79
460	41.562	42.934	44.307	45.68	47.052	48.425	49.798
461	41.568	42.941	44.314	45.687	47.06	48.432	49.805
462	41.576	42.949	44.321	45.694	47.066	48.439	49.812
463	41.582	42.955	44.328	45.701	47.074	48.447	49.819
464	41.589	42.962	44.335	45.708	47.081	48.454	49.827
465	41.595	42.968	44.342	45.715	47.088	48.462	49.835
466	41.602	42.975	44.349	45.722	47.095	48.469	49.842
467	41.609	42.983	44.356	45.729	47.102	48.475	49.849
468	41.616	42.989	44.362	45.736	47.109	48.483	49.856
469	41.622	42.996	44.369	45.743	47.117	48.49	49.864
470	41.628	43.002	44.376	45.75	47.124	48.498	49.871
471	41.634	43.009	44.383	45.757	47.131	48.505	49.879
472	41.642	43.016	44.39	45.764	47.137	48.511	49.885
473	41.648	43.022	44.396	45.77	47.145	48.519	49.893
474	41.655	43.029	44.403	45.777	47.152	48.526	49.9
475	41.661	43.035	44.41	45.784	47.159	48.533	49.908
476	41.668	43.043	44.417	45.791	47.165	48.539	49.914
477	41.675	43.049	44.423	45.798	47.172	48.547	49.921
478	41.681	43.055	44.43	45.805	47.179	48.554	49.928
479	41.687	43.062	44.437	45.811	47.186	48.561	49.936
480	41.693	43.068	44.443	45.818	47.193	48.568	49.943
481	41.701	43.075	44.45	45.825	47.2	48.574	49.949
482	41.707	43.082	44.457	45.832	47.207	48.581	49.956
483	41.713	43.088	44.463	45.838	47.213	48.589	49.964
484	41.719	43.094	44.47	45.845	47.22	48.596	49.971
485	41.725	43.101	44.476	45.852	47.227	48.603	49.978
486	41.732	43.108	44.483	45.858	47.234	48.609	49.984
487	41.739	43.114	44.49	45.865	47.24	48.616	49.991
488	41.745	43.12	44.496	45.872	47.247	48.623	49.999
489	41.751	43.126	44.502	45.878	47.254	48.63	50.006
490	41.757	43.133	44.509	45.885	47.261	48.637	50.013
491	41.764	43.14	44.516	45.891	47.267	48.643	50.019
492	41.77	43.146	44.522	45.898	47.274	48.65	50.026
493	41.776	43.152	44.528	45.904	47.281	48.657	50.033
494	41.782	43.158	44.535	45.911	47.287	48.664	50.04
495	41.788	43.164	44.541	45.918	47.294	48.671	50.047
496	41.795	43.171	44.548	45.924	47.3	48.677	50.053
497	41.801	43.177	44.554	45.93	47.307	48.684	50.06
498	41.807	43.184	44.56	45.937	47.314	48.69	50.067
499	41.813	43.19	44.566	45.943	47.32	48.697	50.074
500	41.819	43.196	44.573	45.95	47.327	48.704	50.081
501	41.826	43.203	44.579	45.956	47.333	48.71	50.087
502	41.832	43.209	44.586	45.963	47.34	48.717	50.094
503	41.837	43.215	44.592	45.969	47.346	48.724	50.101
504	41.843	43.221	44.598	45.976	47.353	48.73	50.108
505	41.849	43.227	44.604	45.982	47.359	48.737	50.115
506	41.856	43.234	44.611	45.988	47.366	48.743	50.12
507	41.862	43.239	44.617	45.994	47.372	48.75	50.127
508	41.868	43.245	44.623	46.001	47.379	48.756	50.134
509	41.873	43.251	44.629	46.007	47.385	48.763	50.141
510	41.879	43.257	44.635	46.013	47.392	48.77	50.148
511	41.886	43.264	44.642	46.02	47.398	48.775	50.153
512	41.892	43.27	44.648	46.026	47.404	48.782	50.16
513	41.898	43.276	44.654	46.032	47.41	48.789	50.167
514	41.903	43.282	44.66	46.038	47.417	48.795	50.174
515	41.909	43.288	44.666	46.045	47.423	48.802	50.18
516	41.916	43.294	44.673	46.051	47.429	48.808	50.186
517	41.922	43.3	44.679	46.057	47.436	48.814	50.193
518	41.927	43.306	44.685	46.063	47.442	48.821	50.199
519	41.933	43.312	44.691	46.069	47.448	48.827	50.206
520	41.938	43.318	44.697	46.076	47.455	48.834	50.213
521	41.945	43.324	44.703	46.082	47.461	48.839	50.218
522	41.951	43.33	44.709	46.088	47.467	48.846	50.225
523	41.957	43.336	44.715	46.094	47.473	48.852	50.231
524	41.962	43.341	44.721	46.1	47.479	48.859	50.238
525	41.968	43.347	44.727	46.106	47.486	48.865	50.245
526	41.973	43.353	44.733	46.112	47.492	48.872	50.251
527	41.98	43.36	44.739	46.118	47.498	48.877	50.257
528	41.986	43.365	44.745	46.124	47.504	48.884	50.263
529	41.991	43.371	44.751	46.13	47.51	48.89	50.27
530	41.997	43.377	44.757	46.136	47.516	48.896	50.276
531	42.002	43.382	44.762	46.142	47.523	48.903	50.283
532	42.009	43.389	44.769	46.148	47.528	48.908	50.288
533	42.014	43.394	44.774	46.154	47.535	48.915	50.295
534	42.02	43.4	44.78	46.16	47.541	48.921	50.301
535	42.025	43.406	44.786	46.166	47.547	48.927	50.308
536	42.031	43.411	44.792	46.172	47.553	48.934	50.314
537	42.038	43.418	44.798	46.178	47.559	48.939	50.319
538	42.043	43.423	44.804	46.184	47.565	48.945	50.326
539	42.048	43.429	44.81	46.19	47.571	48.952	50.332
540	42.054	43.435	44.815	46.196	47.577	48.958	50.339
541	42.059	43.44	44.821	46.202	47.583	48.964	50.345
542	42.065	43.446	44.827	46.208	47.589	48.97	50.351
543	42.071	43.452	44.833	46.214	47.595	48.976	50.357
544	42.077	43.458	44.839	46.22	47.601	48.982	50.363
545	42.082	43.463	44.844	46.226	47.607	48.988	50.369
546	42.087	43.469	44.85	46.232	47.613	48.994	50.376
547	42.093	43.474	44.856	46.237	47.619	49	50.382
548	42.099	43.481	44.862	46.243	47.624	49.006	50.387
549	42.105	43.486	44.868	46.249	47.63	49.012	50.393
550	42.11	43.492	44.873	46.255	47.636	49.018	50.4
551	42.115	43.497	44.879	46.261	47.642	49.024	50.406
552	42.12	43.502	44.884	46.266	47.648	49.03	50.412
553	42.127	43.509	44.89	46.272	47.654	49.035	50.417
554	42.132	43.514	44.896	46.278	47.66	49.042	50.423
555	42.138	43.52	44.902	46.284	47.666	49.048	50.43
556	42.143	43.525	44.907	46.289	47.672	49.054	50.436
557	42.148	43.53	44.913	46.295	47.677	49.06	50.442
558	42.153	43.536	44.918	46.301	47.683	49.066	50.449
559	42.16	43.542	44.924	46.307	47.689	49.071	50.453
560	42.165	43.547	44.93	46.312	47.695	49.077	50.46
561	42.17	43.553	44.935	46.318	47.701	49.083	50.466
562	42.175	43.558	44.941	46.324	47.706	49.089	50.472
563	42.181	43.564	44.946	46.329	47.712	49.095	50.478
564	42.187	43.57	44.952	46.335	47.718	49.1	50.483
565	42.192	43.575	44.958	46.341	47.724	49.106	50.489
566	42.197	43.58	44.963	46.346	47.729	49.112	50.495
567	42.203	43.586	44.969	46.352	47.735	49.118	50.501
568	42.208	43.591	44.974	46.358	47.741	49.124	50.508
569	42.213	43.596	44.98	46.363	47.747	49.13	50.514
570	42.219	43.603	44.986	46.369	47.752	49.135	50.518
571	42.224	43.608	44.991	46.374	47.758	49.141	50.525
572	42.23	43.613	44.997	46.38	47.764	49.147	50.531
573	42.235	43.618	45.002	46.386	47.769	49.153	50.537
574	42.24	43.624	45.007	46.391	47.775	49.159	50.543
575	42.245	43.629	45.013	46.397	47.781	49.165	50.549
576	42.251	43.635	45.019	46.402	47.786	49.17	50.554
577	42.256	43.64	45.024	46.408	47.792	49.176	50.56
578	42.261	43.645	45.029	46.414	47.798	49.182	50.566
579	42.266	43.651	45.035	46.419	47.803	49.187	50.572
580	42.271	43.656	45.04	46.424	47.809	49.193	50.578
581	42.278	43.662	45.046	46.43	47.814	49.198	50.582
582	42.283	43.667	45.051	46.436	47.82	49.204	50.588
583	42.288	43.672	45.057	46.441	47.826	49.21	50.594
584	42.293	43.677	45.062	46.447	47.831	49.216	50.6
585	42.298	43.683	45.067	46.452	47.837	49.222	50.606
586	42.303	43.688	45.073	46.458	47.842	49.227	50.612
587	42.309	43.694	45.078	46.463	47.848	49.232	50.617
588	42.314	43.699	45.084	46.468	47.853	49.238	50.623
589	42.319	43.704	45.089	46.474	47.859	49.244	50.629
590	42.324	43.709	45.094	46.479	47.864	49.25	50.635
591	42.329	43.714	45.1	46.485	47.87	49.255	50.641
592	42.334	43.719	45.105	46.49	47.876	49.261	50.646
593	42.34	43.725	45.111	46.496	47.881	49.266	50.651
594	42.345	43.731	45.116	46.501	47.886	49.272	50.657
595	42.35	43.736	45.121	46.506	47.892	49.277	50.663
596	42.355	43.741	45.126	46.512	47.897	49.283	50.669
597	42.36	43.746	45.132	46.517	47.903	49.289	50.675
598	42.366	43.752	45.137	46.523	47.908	49.294	50.679
599	42.371	43.757	45.142	46.528	47.914	49.299	50.685
600	42.376	43.762	45.148	46.533	47.919	49.305	50.691
601	42.381	43.767	45.153	46.539	47.925	49.311	50.697
602	42.386	43.772	45.158	46.544	47.93	49.316	50.702
603	42.391	43.777	45.163	46.55	47.936	49.322	50.708
604	42.397	43.783	45.169	46.555	47.941	49.327	50.713
605	42.402	43.788	45.174	46.56	47.946	49.332	50.718
606	42.407	43.793	45.179	46.566	47.952	49.338	50.724
607	42.412	43.798	45.184	46.571	47.957	49.344	50.73
608	42.416	43.803	45.19	46.576	47.963	49.349	50.736
609	42.421	43.808	45.195	46.581	47.968	49.355	50.742
610	42.427	43.814	45.2	46.587	47.973	49.36	50.746
611	42.432	43.819	45.205	46.592	47.979	49.365	50.752
612	42.437	43.824	45.211	46.597	47.984	49.371	50.758
613	42.442	43.829	45.216	46.603	47.989	49.376	50.763
614	42.447	43.834	45.221	46.608	47.995	49.382	50.769
615	42.451	43.839	45.226	46.613	48	49.388	50.775
616	42.458	43.845	45.231	46.618	48.005	49.392	50.779
617	42.462	43.849	45.237	46.624	48.011	49.398	50.785
618	42.467	43.854	45.242	46.629	48.016	49.403	50.79
619	42.472	43.859	45.247	46.634	48.021	49.409	50.796
620	42.477	43.864	45.252	46.639	48.027	49.414	50.802
621	42.481	43.869	45.257	46.644	48.032	49.42	50.808
622	42.488	43.875	45.262	46.65	48.037	49.424	50.812
623	42.492	43.88	45.267	46.655	48.043	49.43	50.818
624	42.497	43.885	45.273	46.66	48.048	49.436	50.823
625	42.502	43.89	45.278	46.665	48.053	49.441	50.829
626	42.507	43.895	45.283	46.671	48.059	49.447	50.835
627	42.511	43.899	45.288	46.676	48.064	49.452	50.84
628	42.517	43.905	45.293	46.681	48.069	49.457	50.844
629	42.522	43.91	45.298	46.686	48.074	49.462	50.85
630	42.527	43.915	45.303	46.691	48.079	49.468	50.856
631	42.532	43.92	45.308	46.696	48.085	49.473	50.861
632	42.536	43.925	45.313	46.702	48.09	49.478	50.867
633	42.541	43.93	45.318	46.707	48.095	49.484	50.872
634	42.547	43.935	45.324	46.712	48.1	49.488	50.877
635	42.552	43.94	45.329	46.717	48.105	49.494	50.882
636	42.556	43.945	45.334	46.722	48.111	49.499	50.888
637	42.561	43.95	45.339	46.727	48.116	49.505	50.894
638	42.566	43.955	45.344	46.732	48.121	49.51	50.899
639	42.57	43.959	45.348	46.738	48.127	49.516	50.905
640	42.576	43.965	45.354	46.743	48.131	49.52	50.909
641	42.581	43.97	45.359	46.748	48.137	49.525	50.914
642	42.586	43.975	45.364	46.753	48.142	49.531	50.92
643	42.59	43.98	45.369	46.758	48.147	49.536	50.925
644	42.595	43.984	45.374	46.763	48.152	49.542	50.931
645	42.6	43.989	45.379	46.768	48.157	49.547	50.936
646	42.606	43.995	45.384	46.773	48.162	49.551	50.941
647	42.61	44	45.389	46.778	48.168	49.557	50.946
648	42.615	44.004	45.394	46.783	48.173	49.562	50.952
649	42.619	44.009	45.399	46.788	48.178	49.568	50.957
650	42.624	44.014	45.404	46.793	48.183	49.573	50.963
651	42.629	44.019	45.408	46.798	48.188	49.578	50.968
652	42.633	44.023	45.413	46.803	48.193	49.584	50.974
653	42.639	44.029	45.419	46.808	48.198	49.588	50.978
654	42.644	44.034	45.424	46.814	48.203	49.593	50.983
655	42.648	44.038	45.428	46.818	48.209	49.599	50.989
656	42.653	44.043	45.433	46.824	48.214	49.604	50.994
657	42.657	44.048	45.438	46.828	48.219	49.609	51
658	42.662	44.053	45.443	46.834	48.224	49.614	51.005
659	42.668	44.058	45.448	46.838	48.229	49.619	51.009
660	42.673	44.063	45.453	46.844	48.234	49.624	51.014
661	42.677	44.068	45.458	46.848	48.239	49.629	51.02
662	42.682	44.072	45.463	46.854	48.244	49.635	51.025
663	42.686	44.077	45.468	46.858	48.249	49.64	51.031
664	42.691	44.082	45.472	46.863	48.254	49.645	51.036
665	42.697	44.087	45.478	46.868	48.259	49.65	51.04
666	42.701	44.092	45.483	46.873	48.264	49.655	51.045
667	42.706	44.097	45.487	46.878	48.269	49.66	51.051
668	42.71	44.101	45.492	46.883	48.274	49.665	51.056
669	42.715	44.106	45.497	46.888	48.279	49.671	51.062
670	42.719	44.11	45.502	46.893	48.284	49.676	51.067
671	42.725	44.116	45.507	46.898	48.289	49.68	51.071
672	42.73	44.121	45.512	46.903	48.294	49.685	51.076
673	42.734	44.125	45.517	46.908	48.299	49.69	51.082
674	42.738	44.13	45.521	46.913	48.304	49.696	51.087
675	42.743	44.135	45.526	46.918	48.309	49.701	51.092
676	42.748	44.139	45.531	46.923	48.314	49.706	51.098
677	42.752	44.144	45.536	46.928	48.319	49.711	51.103
678	42.758	44.149	45.541	46.932	48.324	49.716	51.107
679	42.762	44.154	45.546	46.937	48.329	49.721	51.112
680	42.767	44.159	45.55	46.942	48.334	49.726	51.118
681	42.771	44.163	45.555	46.947	48.339	49.731	51.123
682	42.776	44.168	45.56	46.952	48.344	49.736	51.128
683	42.78	44.172	45.565	46.957	48.349	49.741	51.134
684	42.786	44.178	45.57	46.962	48.354	49.746	51.138
685	42.79	44.182	45.575	46.967	48.359	49.751	51.143
686	42.795	44.187	45.579	46.971	48.364	49.756	51.148
687	42.799	44.192	45.584	46.976	48.369	49.761	51.153
688	42.804	44.196	45.589	46.981	48.374	49.766	51.159
689	42.808	44.201	45.593	46.986	48.379	49.771	51.164
690	42.812	44.205	45.598	46.991	48.384	49.776	51.169
691	42.818	44.211	45.603	46.996	48.388	49.781	51.173
692	42.823	44.215	45.608	47	48.393	49.786	51.178
693	42.827	44.22	45.613	47.005	48.398	49.791	51.184
694	42.831	44.224	45.617	47.01	48.403	49.796	51.189
695	42.836	44.229	45.622	47.015	48.408	49.801	51.194
696	42.84	44.233	45.627	47.02	48.413	49.806	51.199
697	42.846	44.239	45.632	47.024	48.417	49.81	51.203
698	42.85	44.243	45.636	47.029	48.422	49.815	51.208
699	42.855	44.248	45.641	47.034	48.427	49.82	51.214
700	42.859	44.252	45.646	47.039	48.432	49.825	51.219
701	42.863	44.257	45.65	47.044	48.437	49.83	51.224
702	42.868	44.261	45.655	47.048	48.442	49.836	51.229
703	42.872	44.266	45.659	47.053	48.447	49.841	51.234
704	42.878	44.271	45.665	47.058	48.451	49.845	51.238
705	42.882	44.276	45.669	47.063	48.456	49.85	51.243
706	42.886	44.28	45.674	47.067	48.461	49.855	51.248
707	42.891	44.285	45.678	47.072	48.466	49.86	51.254
708	42.895	44.289	45.683	47.077	48.471	49.865	51.259
709	42.899	44.293	45.688	47.082	48.476	49.87	51.264
710	42.904	44.298	45.692	47.086	48.481	49.875	51.269
711	42.909	44.303	45.697	47.091	48.485	49.879	51.273
712	42.914	44.308	45.702	47.096	48.49	49.884	51.278
713	42.918	44.312	45.706	47.1	48.495	49.889	51.283
714	42.922	44.317	45.711	47.105	48.5	49.894	51.288
715	42.927	44.321	45.715	47.11	48.504	49.899	51.293
716	42.931	44.325	45.72	47.115	48.509	49.904	51.298
717	42.937	44.331	45.725	47.119	48.514	49.908	51.302
718	42.941	44.335	45.73	47.124	48.518	49.913	51.307
719	42.945	44.34	45.734	47.129	48.523	49.918	51.312
720	42.949	44.344	45.739	47.133	48.528	49.923	51.317
721	42.954	44.348	45.743	47.138	48.533	49.928	51.322
722	42.958	44.353	45.748	47.143	48.538	49.933	51.328
723	42.962	44.357	45.752	47.147	48.542	49.938	51.333
724	42.968	44.362	45.757	47.152	48.547	49.942	51.336
725	42.972	44.367	45.762	47.157	48.552	49.946	51.341
726	42.976	44.371	45.766	47.161	48.556	49.951	51.346
727	42.98	44.376	45.771	47.166	48.561	49.956	51.352
728	42.985	44.38	45.775	47.171	48.566	49.961	51.357
729	42.989	44.384	45.78	47.175	48.571	49.966	51.362
730	42.993	44.389	45.784	47.18	48.575	49.971	51.367`;

var dataArray = data.split("\n");
var resultArray = [];

for (var i = 0; i < dataArray.length; i++) {
  var values = dataArray[i].split("\t");
  var obj = {
    age: parseInt(values[0]),
    line1: parseFloat(values[1]),
    line2: parseFloat(values[2]),
    line3: parseFloat(values[3]),
    line4: parseFloat(values[4]),
    line5: parseFloat(values[5]),
    line6: parseFloat(values[6]),
    line7: parseFloat(values[7]),
  };
  resultArray.push(obj);
}

/**
 * DO NOT DELETE THIS FUNCTION
 * @description used to transform PRETERM line coords
 * @author Rajat Saini
 * @date Jun 21, 2023
 */
let age = [
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42,
];
let w1 = [
  495, 570, 650, 735, 830, 935, 1050, 1175, 1310, 1460, 1615, 1780, 1950, 2120,
  2290, 2445, 2590, 2730, 2865, 2975,
];
let w2 = [
  530, 615, 700, 790, 890, 1000, 1120, 1250, 1390, 1540, 1695, 1860, 2030, 2200,
  2370, 2525, 2670, 2805, 2925, 3025,
];
let w3 = [
  700, 815, 935, 1060, 1200, 1350, 1510, 1680, 1860, 2050, 2245, 2445, 2655,
  2870, 3085, 3285, 3475, 3650, 3805, 3930,
];
let w4 = [
  900, 1050, 1205, 1370, 1550, 1745, 1955, 2180, 2425, 2690, 2975, 3275, 3585,
  3895, 4200, 4485, 4750, 4990, 5205, 5395,
];
let w5 = [
  970, 1135, 1305, 1485, 1685, 1895, 2125, 2375, 2640, 2920, 3220, 3530, 3850,
  4165, 4475, 4765, 5035, 5275, 5485, 5670,
];

let res = age.map((ageVal, i) => ({
  age: ageVal,
  line1: +(w1[i] / 1000).toFixed(2),
  line2: +(w2[i] / 1000).toFixed(2),
  line3: +(w3[i] / 1000).toFixed(2),
  line4: +(w4[i] / 1000).toFixed(2),
  line5: +(w5[i] / 1000).toFixed(2),
}));
