import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from 'src/app/graphs/bar-chart/bar-chart.component';
import { PieChartComponent } from 'src/app/graphs/pie-chart/pie-chart.component';

@NgModule({
    declarations: [
        BarChartComponent,
        PieChartComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BarChartComponent,
        PieChartComponent
    ]
})
export class GraphModule {}
