<div role="button"
     class="cp-btn cp-hvr-info-p text-decoration-none my-2"
     (click)="editedDevice = null; openModal(deviceFormModal)">
    New Device
</div>
<app-ralert-device-list
        [devices]="devices$ | async"
        (deleteClick)="onDelete($event)"
        (editClick)="onEdit($event, deviceFormModal)">
</app-ralert-device-list>

<ng-template #deviceFormModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title">Device</h6>
        <div class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </div>
    </div>
    <div class="modal-body">
        <app-ralert-device-form
                [hospitals]="hospitals"
                [data]="editedDevice"
                [loading]="loadingForm$ | async"
                (submitting)="onSubmit($event); modal.dismiss('Submitting')">
        </app-ralert-device-form>
    </div>
</ng-template>
