import { createAction, props } from "@ngrx/store";
import { FinalNotes } from "@notes-view/model/notes-model";
import { CTAType, NoteForms } from "@notes/models/notes.model";

export const LOAD_NOTE_CONTENTS = "[NOTE-INPUT-FORM-DATA] load note contents";
export const RESET_NOTE_STORE = "[NOTE-INPUT-FORM-DATA] reset note contents";
export const LOAD_NOTE_CONTENTS_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] load note contents success";

export const loadNoteContents = createAction(
  LOAD_NOTE_CONTENTS,
  props<{ noteFormContents: NoteForms }>()
);

export const loadNoteContentSuccess = createAction(
  LOAD_NOTE_CONTENTS_SUCCESS,
  props<{ noteContents: FinalNotes }>()
);

export const resetNoteContent = createAction(RESET_NOTE_STORE);

export const setCTA = createAction(
  "[CTA] Set CTA",
  props<{ cta: CTAType | null }>()
);
