import { Component } from "@notes/models/notes.model";

export interface AiraNote {
  summary: string;
  diagnosis: string;
  plan: string;
  todo: string;
  noteType: string;
  noteSubTemplate: string;
  reports: string;
  medication: string;
}

export interface AiraNoteResponse {
  component: Component[];
  notesType: {
    noteType: string;
    noteSubType: string;
  };
}
