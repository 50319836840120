import { OrderTime } from "../../OrderTime.model";

export interface VentForm {
  _id?: string;
  presetName?: string;
  patVentAirway: string;
  patVentTube: string;
  patVentOralAirway: string;
  patVentType: string;
  patIntType: string;
  patVentMode: string;
  patVentSubMode: string;
  patVentI: string;
  patVentE: string;
  patVentMV: string;
  patVentTubeSize: string;
  patVentFiO2: string;
  patVentVt: string;
  patVentRRTot: string;
  patVentRRset: string;
  patVentPplat: string;
  patVentPpeak: string;
  patVentMAP: string;
  patVentPinsp: string;
  patVentPsupport: string;
  patVentPEEP: string;
  patVentDate?: Date;
  patVentTime?: string;
  patFlowRate: string;
  patEttTieLevel: string;
  startNow?: Boolean;
  startTime?: OrderTime;
  endTime?: OrderTime;
  bedsideOrder?: null;
  displayAsShortcut?: boolean;
  instructions?: string;
  additionalInformation?: string;
  protocols?: string[];
  protocol?: string[];
  default?: boolean;
  patientType?: string;
}

export interface Vent {
  airway: string;
  tube: string;
  oralAirway: string;
  ventType: string;
  intType: string;
  mode: string;
  subMode: string;
  inspiratory: string;
  instructions: string;
  additionalInformation: string;
  expiratory: string;
  mv: string;
  tubeSize: string;
  fio2: string;
  vt: string;
  rrtot: string;
  rrset: string;
  pplat: string;
  ppeak: string;
  map: string;
  pinsp: string;
  psupport: string;
  peep: string;
  flowRate: string;
  ettTieLevel: string;
  bedsideOrder?: null;
  default?: boolean;
}

export interface VentOrderFE extends Vent {
  startTime?: OrderTime;
  endTime?: OrderTime;
  startNow?: Boolean;
}

export interface VentOrderBE extends Vent {
  _id?: string;
  startTime: OrderTime | null;
  endTime?: OrderTime | null;
  type: string;
  state: string;
  category: string;
  updatedAt: Date;
  toBeDiscarded?: boolean;
  signed?: string;
  discontinueReasons?: string[];
  discontinueReasonStatement?: string;
  day?: string;
  preset?: string;
  replaceOrderId?: string;
}

export interface VentVital {
  daysVentAirway: string;
  daysVentOralAirway: string;
  daysVentType: string;
  daysIntType: string;
  daysVentMode: string;
  daysVentSubMode: string;
  daysVentI: string;
  daysVentE: string;
  daysVentMV: string;
  daysFiO2: string;
  daysVentTubeSize: string;
  daysVentVt: string;
  daysVentRRset: string;
  daysVentPplat: string;
  daysVentPpeak: string;
  daysVentMAP: string;
  daysVentPinsp: string;
  daysVentPsupport: string;
  daysVentPEEP: string;
  daysVentTube: string;
  daysFlowRate: string;
  daysRR: string;
  daysEttTieLevel: string;
}
