import { AppState } from "src/app/reducers";

import * as fromHospitals from "./hospital-list.reducers";
import * as fromHospitalsView from "./hospital-view.reducers";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface hospitalState {
  data: fromHospitals.State;
  view: fromHospitalsView.State;
}

export interface State extends AppState {
  hospitals: hospitalState;
}

export const reducer: ActionReducerMap<hospitalState, any> = {
  data: fromHospitals.reducer,
  view: fromHospitalsView.reducer,
};

/** SELECTOR setup */
export const getHospitalState =
  createFeatureSelector<hospitalState>("hospitals");

export const getHospitalsData = createSelector(
  getHospitalState,
  (state: hospitalState) => state.data
);

export const getHospitals = createSelector(
  getHospitalsData,
  fromHospitals.selectAllHospitals
);

export const getHospitalsNameForMultiSelect = createSelector(
  getHospitals,
  (hospitals) => {
    return hospitals.reduce((acc, hospital) => {
      acc.push({ id: hospital.name, text: hospital.name });
      return acc;
    }, []);
  }
);

// Get all units
export const getHospitalUnits = createSelector(getHospitals, (hospitals) => {
  if (hospitals?.length != 0) {
    return hospitals.reduce((acc, hospital) => {
      if (hospital?.units?.length)
        acc.push(
          ...hospital.units.map((unit) => ({
            ...unit,
            hospitalName: hospital.name,
          }))
        );

      return acc;
    }, []);
  } else {
    return [];
  }
});

export const getHospitalView = createSelector(
  getHospitalState,
  (state) => state.view
);

// get unitAssignLoaderList

export const getUnitAssignLoaderList = createSelector(
  getHospitalView,
  (state) => state.unitAssignLoaderList
);

export const getWorkspaceLoaderList = createSelector(
  getHospitalView,
  (state) => state.workspaceAssignLoaderList
);

export const getAssignAllLoading = createSelector(
  getHospitalView,
  (state) => state.assignAllUnitsLoading
);
