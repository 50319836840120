import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  public getItem(key: string) {
    return localStorage.getItem(key);
  }

  public getObject(key: string) {
    const item = localStorage.getItem(key);
    if (!item) return null;
    return JSON.parse(localStorage.getItem(key));
  }

  public setItem(key: string, value: any) {
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(key, stringifiedValue);
  }
}
