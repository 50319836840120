import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { ClockService } from "../../../shared/clock/clock.service";
import { RAlertMessage } from "../../../models/RAlert/message.model";
import { AISocketService } from "src/app/notification-module/services/socket.service";
import { Topic } from "src/app/notification-module/enums/topic.enum";
import moment from "moment-timezone";
import { AudioService } from "src/app/shared/audio/audio.service";

@Component({
  selector: "app-ralert-message-list",
  templateUrl: "./ralert-message-list.component.html",
  styleUrls: ["./ralert-message-list.component.scss"],
})
export class RalertMessageListComponent implements OnInit, OnDestroy {
  @Input() messages: RAlertMessage[];
  @Output() acknowledge: EventEmitter<RAlertMessage> =
    new EventEmitter<RAlertMessage>();

  public alertTime = 300000; // in ms
  private clock$: Subscription;
  public time: any;
  public rAlertMessages: any = [];
  private subscription: Subscription;
  private currentUser: any;
  private hasConnection: boolean = false;

  constructor(
    public clockService: ClockService,
    private socketService: AISocketService,
    private _playAudio: AudioService
  ) {
    this.clock$ = this.clockService.getClock().subscribe((date) => {
      this.time = date;
    });
  }

  ngOnInit() {
    // first get user details
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const userData = {
      role: this.currentUser["role"],
      email: this.currentUser["email"],
    };
    // all data gethering services from ai server are initiated in code blue component
    this.subscription = this.socketService.readyState.subscribe((state) => {
      this.hasConnection = state;
      // ensure socket is live before sending message to server
      if (this.hasConnection && this.currentUser) {
        // get assigned units after geting user details
        this.socketService.send(Topic.getRalertCST, userData);
      }
    });

    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.setRalertCST)
        .subscribe((message) => {
          if (message) {
            // reset active r-alerts
            this.rAlertMessages = [];
            message["r_alerts_cst"].forEach((alert) => {
              alert.is_codeblue
                ? this._playAudio.playCodeBlueAlert()
                : this._playAudio.playCallAlert();

              const formatedAlert = {
                _id: alert["_id"],
                hospitalName: alert["hospital"],
                hospitalLogo: alert["hospital_logo"],
                unitName: alert["unit"],
                bedNo: alert["bed_number"],
                message: "call",
                timestamp: new Date(alert["raised_at"]),
                momentTimestamp: moment(alert["raised_at"]),
                raisedBy: {
                  name: alert["raised_by_name"],
                  role: alert["raised_by_role"],
                },
              };
              if (alert["is_codeblue"]) {
                formatedAlert["message"] = "code_blue";
              }
              this.rAlertMessages.push(formatedAlert);
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clock$.unsubscribe();
  }

  onAcknowledge(id: RAlertMessage) {
    this.acknowledge.emit(id);
  }

  onAcknowledgeAlert(message: any) {
    const userData = {
      role: this.currentUser["role"],
      email: this.currentUser["email"],
      _id: message["_id"],
    };
    this.socketService.send(Topic.completeRalertCST, userData);
  }
}
