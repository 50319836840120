import { createEntityAdapter } from "@ngrx/entity";
import { InfectionBundleReducerState } from "../state";
import { InfectionBundle } from "../../models";
import { createReducer, on } from "@ngrx/store";
import * as fromInfectionBundleActions from "../actions";
import moment from "moment-timezone";

export const infectionBundleAdaptor = createEntityAdapter<InfectionBundle>({
  selectId: selectInfectionBundleId,
  sortComparer: sortInfectionBundle,
});

function selectInfectionBundleId(a: InfectionBundle): string {
  return a._id;
}

function sortInfectionBundle(a: InfectionBundle, b: InfectionBundle) {
  return moment(b.timestamp).diff(moment(a.timestamp));
}

export const initialState: InfectionBundleReducerState =
  infectionBundleAdaptor.getInitialState({
    loading: null,
  });

const infectionBundleReducer = createReducer(
  initialState,
  on(fromInfectionBundleActions.getAllInfectionBundlesAction, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    fromInfectionBundleActions.getAllInfectionBundlesActionSuccess,
    (state, { infectionBundles }) =>
      infectionBundleAdaptor.addMany(infectionBundles, {
        ...state,
        loading: false,
      })
  ),
  on(fromInfectionBundleActions.updateLoaderStatus, (state, { status }) => ({
    ...state,
    loading: status,
  })),
  on(fromInfectionBundleActions.showError, (state) => ({
    ...state,
    loading: false,
  })),
  on(fromInfectionBundleActions.createInfBundChecklist, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromInfectionBundleActions.updateInfBundChecklist, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    fromInfectionBundleActions.createInfBundChecklistSuccess,
    (state, { checklist }) => ({ ...state, loading: false })
  ),
  on(
    fromInfectionBundleActions.updateInfBundChecklistSuccess,
    (state, { updatedChecklist }) => ({ ...state, loading: false })
  ),
  on(
    fromInfectionBundleActions.upsertInfectionBundle,
    (state, { checklist }) => {
      return infectionBundleAdaptor.upsertOne(checklist, state);
    }
  )
);

export function reducer(state: InfectionBundleReducerState, action) {
  return infectionBundleReducer(state, action);
}
