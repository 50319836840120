export enum TemplateDisplayName {
  Summary = "Summary",
  Assessment = "Assessment",
  Diagnosis = "Diagnosis",
  Active_medications = "Active medications",
  Labs_and_investigations = "Labs and investigations",
  ToDo = "To-do's",
  Medication = "Medication",
  Reports = "Reports",
  Plan_of_treatment = "Plan of treatment",
  History = "History",
  Admission_details = "Admission details",
  Systemic_examination = "Systemic examination",
}
