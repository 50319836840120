<div class="growth-chart-container">
  <div class="trends-header cp-p-1">
    <ng-container
      *ngIf="labNames.includes(labNameControl.value); else readonlyLabName"
    >
      <mat-select
        [formControl]="labNameControl"
        class="iris-input select-width"
      >
        <mat-option *ngFor="let labName of labNames" [value]="labName">{{
          labName
        }}</mat-option>
      </mat-select>
    </ng-container>

    <ng-template #readonlyLabName>
      <input
        class="iris-input font-weight-bold"
        matInput
        type="text"
        [readonly]="true"
        [value]="'Spotlight'"
      />
    </ng-template>

    <div class="close-icon">
      <img matDialogClose="cancel" src="assets/icons/close.svg" />
    </div>
  </div>
  <hr class="header-divider" />
  <div class="trends-container">
    <cp-lab-trends-left-panel
      [selectedLabAttributesArray]="selectedLabAttributesArray"
      [selectedAttribute]="selectedAttribute"
    ></cp-lab-trends-left-panel>
    <div class="graphs-container">
      <div
        class="body"
        *ngFor="let gConfig of labTrendsConfig$ | async; index as i"
      >
        <div class="chart-title-container">
          <div class="title-and-unit">
            <div class="graph-title">{{ gConfig?.graphName }}</div>
            <div class="graph-range">
              Normal range:
              <span class="graph-range-value"
                >{{ gConfig?.errorRange[0] }} -
                {{ gConfig?.errorRange[1] }}</span
              >
            </div>
          </div>
          <div class="recent-values" [ngStyle]="{ display: 'block' }">
            <div class="header">Recent</div>
            <div class="recent-items">
              <div class="label">Value:</div>
              <div class="value" *ngIf="gConfig?.graphData?.length">
                {{ gConfig?.graphData[0]["yValue"] }}
                <!-- {{
                getProperUnitName(
                  gConfig?.graphData[0]["yValue"],
                  GROWTH_OPTIONS_TO_HTML_POPOVER_LABEL_MAP[
                    gConfig?.graphName
                  ][patientInfo?.birthStatus]["yUnit"]
                )
              }} -->
              </div>
            </div>
            <div class="recent-items">
              <div class="label">Date & time:</div>
              <div class="value" *ngIf="gConfig?.graphData?.length">
                {{ gConfig?.graphData[0]["xValue"] | date: "MMM dd, H:mm" }}
                <cp-timezone-label
                  *ngIf="gConfig?.graphData[0]['xValue']"
                ></cp-timezone-label>
              </div>
            </div>
          </div>
        </div>
        <div class="d3-graph">
          <div id="{{ gConfig?.graphName }}" #canvas></div>
        </div>
      </div>
    </div>
    <ng-container> </ng-container>
  </div>
</div>
