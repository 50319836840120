<div
  #btnTagCont
  tabindex="1"
  [ngClass]="[disabled ? 'btn-tag-disabled' : 'btn-tag-container']"
  [class.selected]="selectedBtnTag?.selected"
  (click)="onSelected($event)"
>
  <div *ngIf="iconLeft" (click)="onIconClick($event, true)">
    <img class="icon" [src]="iconLeft" [matTooltip]="leftIconTooltip" />
  </div>
  <div class="text">
    {{ tagName }}
  </div>
  <div *ngIf="iconRight" (click)="onIconClick($event, false)">
    <img class="icon" [src]="iconRight" [matTooltip]="rightIconTooltip" />
  </div>
  <!-- <div (click)="onClose()">
    <img class="icon" src="assets/icons/cancel_rounded.svg" />
  </div> -->
</div>
