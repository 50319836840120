import { createAction, props } from '@ngrx/store';
import {
    PatientMonitor,
    PatientMonitorForm,
    PatientMonitorStatus
} from '../../models/patientMonitor/patientMonitor.model';

export const getPatientMonitors = createAction(
    '[Patient Monitor List Page] Get All Patient Monitor'
);

export const submitNewPatientMonitorForm = createAction(
    '[Patient Monitor Form Modal] Submit New Patient Monitor Form',
    props<{ data: PatientMonitorForm }>()
);

export const submitEditPatientMonitorForm = createAction(
    '[Patient Monitor Form Modal] Submit Edit RAlert Patient Monitor Form',
    props<{ data: PatientMonitorForm, id: string }>()
);

export const clickDeletePatientMonitor = createAction(
    '[Patient Monitor List Page] Click Patient Monitor Delete',
    props<{ id: string }>()
);

export const getAllPatientMonitorStatus = createAction(
    '[Patient Monitor List Page] Get All Patient Monitor Status',
    props<{ monitors: PatientMonitorStatus[] }>()
);

export const setPatientMonitorStatus = createAction(
    '[Patient Monitor List Page] Set Patient Monitor Status',
    props<{ monitor: PatientMonitor, online: boolean }>()
);
