import { createAction, props } from '@ngrx/store';
import { Order } from '../../../models/Order.model';

export const discontinueProtocol = createAction(
  '[Order View] Discontinue Protocol',
  props<{ orders: Order[] }>()
);

export const signProtocol = createAction(
  '[Order View] Sign Protocol',
  props<{ orders: Order[], user: string }>()
);

export const getProtocolFile = createAction(
  '[Order View] Get Protocol Files',
  props<{ protocol: string }>()
);
