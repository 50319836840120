import {
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
} from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";
// import { Socket } from "ngx-socket-io";

// Store
import { select, Store } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { User } from "src/app/models/user";
import { GlobalSearchService } from "src/app/services/global-search.service";
import { socket } from "../../config/socket";
import { displayAge } from "src/app/support-functions/calculateAge";

@Component({
  selector: "app-global-search-patient",
  templateUrl: "./global-search-patient.component.html",
  styleUrls: ["./global-search-patient.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchPatientComponent implements OnInit {
  // Store
  ngDestroyed$ = new Subject();
  getUser$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  search$ = new Subject();

  // type = 'search';

  public typeaheadList: any = [];
  public runnNo = -1;

  public searchText: any;
  public noDataMsg: Boolean = false;
  private focusIn = false;
  public typeTerms: any;
  private currentUser: User;
  public dropDown: Boolean = false;
  public isSocket: Boolean = false;

  // debounce timer
  public inDebounce;

  /* ICONS */
  faSearch = faSearch;

  @HostListener("click")
  clickInside() {
    this.focusIn = true;
  }

  @HostListener("document:click")
  clickOut() {
    if (!this.focusIn) {
      this.resetSearch();
    }
    this.focusIn = false;
  }

  constructor(
    private _globalSearchService: GlobalSearchService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.resetSearch();

    this.search$
      .pipe(
        distinctUntilChanged(
          (previousTerm, nextTerm) =>
            JSON.stringify(previousTerm) === JSON.stringify(nextTerm)
        ),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(async (search_terms) => {
        const typeAheadData =
          await this._globalSearchService.getAdmittedPatients(search_terms);

        this.typeaheadData(typeAheadData);
      });

    this.getUser$.pipe(takeUntil(this.ngDestroyed$)).subscribe((user) => {
      this.currentUser = user;
    });
  }

  typeaheadData(data) {
    if (data.docs == "NO DATA FOUND") {
      this.typeaheadList = [];
      this.noDataMsg = true;
    } else {
      this.noDataMsg = false;
      let patientsAdmitted = data.docs;
      this.typeaheadList = [];
      patientsAdmitted.forEach((data) => {
        let datas = {};
        if (data.age) {
          datas["age"] = data.age;
        } else datas["age"] = "";
        if (data.sex) {
          datas["sex"] = data.sex;
        } else datas["sex"] = "?";
        if (data.name) {
          datas["name"] = data.name;
        } else datas["name"] = "";
        if (data.lastName) {
          datas["lastName"] = data.lastName;
        } else datas["lastName"] = "";
        if (data.hospitalName) {
          datas["hospitalName"] = data.hospitalName;
        } else datas["hospitalName"] = "";
        if (data.unitName) {
          datas["unitName"] = data.unitName;
        } else datas["unitName"] = "";
        if (data.bedNo) {
          datas["bedNo"] = data.bedNo;
        } else datas["bedNo"] = "NA";
        datas["MRN"] = data.MRN;
        datas["CPMRN"] = data.CPMRN;
        datas["encounters"] = data.encounters;
        this.typeaheadList.push(datas);
      });

      // sorting based on the patient name

      this.typeaheadList.sort(function (x, y) {
        let a = x.name.toUpperCase(),
          b = y.name.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
    }
  }

  showSearch: boolean = false;
  showSearchBar() {
    this.showSearch = !this.showSearch;
  }

  searchPatients(term: string) {
    this.noDataMsg = false;
    let delay = 500;
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => {
      //clone of current user
      let user = JSON.parse(JSON.stringify(this.currentUser));
      if (term) {
        if (term.length < 3 || term.includes("..")) {
          this.dropDown = false;
          this.runnNo = -1;
        } else if (term.length >= 3) {
          this.dropDown = true;
          this.typeTerms = term;

          const search_data = {
            unit: {
              hospitalName: user.hospitals,
              unitName: user.units,
              email: user.email,
            },
            term: term,
          };

          this.search$.next(search_data);

          // socket.emit("setListOfAdmitedPatients", {
          //   unit: {
          //     hospitalName: user.hospitals,
          //     unitName: user.units,
          //     email: user.email,
          //   },
          //   term: term,
          // });
        }
      } else this.dropDown = false;
    }, delay);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  setPatient(patient: any): void {
    this.resetSearch();
    window.open("/patient/" + patient.CPMRN + "/" + patient.encounters);
  }

  setSelected(val) {
    if (this.runnNo >= 0) {
      this.typeaheadList[this.runnNo]["class"] = "";
    }
    this.runnNo = val;
    this.typeaheadList[this.runnNo]["class"] = "selected";
  }

  resetSearch() {
    this.dropDown = false;
    // this.typeaheadList = [];
    this.searchText = "";
  }

  getPatientAge(patient) {
    const ageObj = patient?.age;

    if (!ageObj) return "";

    return displayAge(ageObj, false, patient.patientType, patient.dob);
  }
}
