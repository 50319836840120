<div class="order-search-comp">
  <div class="blur-bg" *ngIf="loader">
    <div class="spinner">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
  <div class="input-cont">
    <img class="search-icon" src="assets/icons/search.svg" />
    <input
      matInput
      class="order-input"
      [placeholder]="placeholder"
      [(ngModel)]="searchText"
      (input)="searchOrders(searchText, $event)"
      autocomplete="off"
      aria-describedby="search-icon"
    />
  </div>
  <div class="list-cont" *ngIf="typeaheadList.length || errorMsg != null">
    <div class="row">
      <!-- <div class="col-md-8" *ngIf="typeaheadList.length && errorMsg == null"> -->
      <cdk-virtual-scroll-viewport
        *ngIf="typeaheadList.length && errorMsg == null"
        #optionContainer
        class="col-md-8 list-cont__option-list cp-mat-virtual-scroll"
        appendOnly
        itemSize="25"
      >
        <mat-option
          matRipple
          tabindex="0"
          class="list__item d-flex align-items-baseline"
          *cdkVirtualFor="let order of typeaheadList; index as i"
          [ngClass]="
            order.class && order.class == 'selected'
              ? 'list__item--selected'
              : ''
          "
        >
          <div
            (click)="setOrder(order.type, order)"
            (keydown.enter)="setOrder(order.type, order)"
            (mouseenter)="setSelected(i)"
          >
            <div class="search-result-text">
              <div class="image">
                <img
                  src="assets/icons/order_blood.svg"
                  *ngIf="order.type === 'blood'"
                  class="icon"
                />

                <fa-icon
                  [icon]="faComments"
                  size="s"
                  *ngIf="order.type === 'comm'"
                  class="icon icon--comm"
                >
                </fa-icon>

                <img
                  src="assets/icons/order_diet.svg"
                  *ngIf="order.type === 'diet'"
                  class="icon icon--diet"
                />

                <img
                  src="assets/icons/order_lab.svg"
                  *ngIf="order.type === 'lab'"
                  class="icon icon--lab"
                />

                <img
                  src="assets/icons/order_med.svg"
                  *ngIf="order.type === 'med'"
                  class="icon icon--med"
                />

                <img
                  src="assets/icons/order_procedure.svg"
                  *ngIf="order.type === 'procedure'"
                  class="icon icon--procedure"
                />

                <img
                  src="assets/icons/order_vent.svg"
                  class="icon cp-vent__icon"
                  *ngIf="order.type === 'vents'"
                />

                <fa-icon
                  [icon]="faProductHunt"
                  size="s"
                  *ngIf="order.type === 'protocol'"
                  class="icon icon--protocol"
                ></fa-icon>
              </div>
              <div class="search-result-text__order">
                <div
                  class="order-name search-result-text__order__text"
                  *ngIf="order.name"
                  [matTooltip]="order.name.length >= 30 ? order.name : ''"
                  matTooltipClass="cp-mat-tooltip"
                >
                  {{ order.name }}
                </div>
                <div class="search-result-text__order__icons mr">
                  <div>
                    <button
                      *ngIf="
                        order.patientType?.length == 1 &&
                        (order.patientType[0] == 'neonatal' ||
                          order.patientType[0] == 'pediatric')
                      "
                      [ngClass]="[
                        order.patientType[0] == 'neonatal'
                          ? 'neonatal'
                          : 'pediatric'
                      ]"
                      type="button"
                    >
                      {{ order.patientType[0] | titlecase }}
                    </button>
                  </div>
                  <div *ngIf="order.patientType?.length > 1">
                    <ng-container
                      *ngFor="let patienttype of order.patientType; index as j"
                    >
                      <button
                        *ngIf="order.patientType[j] !== 'adult'"
                        [ngClass]="[
                          order.patientType[j] == 'neonatal'
                            ? 'neonatal'
                            : 'pediatric'
                        ]"
                        type="button"
                      >
                        {{ order.patientType[j] | titlecase }}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- <div>
                <button *ngIf="false" class="pediatric" type="button">
                  Pediatric
                </button>
              </div> -->
            </div>
            <div
              class="brand display-flex flex-wrap"
              *ngIf="order.brands?.length > 0"
            >
              <ng-container *ngFor="let brand of order.brands; index as k">
                <button
                  class="brandText"
                  mat-stroked-button
                  type="submit"
                  color="primary"
                  *ngIf="k < 3 && order?.brands?.length"
                  (click)="setOrder(order.type, order, brand)"
                  (keydown.enter)="setOrder(order.type, order, brand)"
                  (mouseenter)="setSelected(i)"
                  [matTooltip]="brand.length > 30 ? brand : ''"
                  matTooltipClass="cp-mat-tooltip"
                >
                  {{ brand | titlecase }}
                </button>
              </ng-container>
              <button
                mat-stroked-button
                type="button"
                color="primary"
                *ngIf="order.brands?.length > 3"
                [matMenuTriggerFor]="matmenu"
                (click)="menuButton = true; $event.stopPropagation()"
              >
                <span class="brandText">All</span>
                <mat-icon style="color: #8b94b1; margin-right: 0px"
                  >keyboard_arrow_down</mat-icon
                >
              </button>
              <mat-menu
                #matmenu="matMenu"
                class="cp-menu-container"
                [overlapTrigger]="false"
              >
                <ng-container *ngFor="let brand of order.brands; index as k">
                  <button
                    mat-menu-item
                    type="submit"
                    *ngIf="
                      order.brands?.length > 3 && k > 2 && brand != undefined
                    "
                    (click)="setOrder(order.type, order, brand)"
                    (keydown.enter)="setOrder(order.type, order, brand)"
                    (mouseenter)="setSelected(i)"
                  >
                    <div
                      class="brandText-matmenu"
                      [matTooltip]="brand.length > 30 ? brand : ''"
                      matTooltipClass="cp-mat-tooltip"
                    >
                      {{ brand | titlecase }}
                    </div>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <hr />
        </mat-option>
      </cdk-virtual-scroll-viewport>
      <!-- </div> -->
      <div class="error col-md-8" *ngIf="errorMsg != null">
        <mat-error class="error_text">
          {{ errorMsg }}
        </mat-error>
      </div>
      <div
        class="col-md-4 filtercolumn"
        *ngIf="errorMsg == null || (errorMsg != null && filterInput.length > 0)"
      >
        <div class="filterheader">Filters by type</div>
        <div class="filtercheckbox">
          <div *ngFor="let type of ordertype; let i = index">
            <mat-checkbox
              class="example-margin"
              (change)="onChange($event)"
              [value]="type"
            >
              <span class="image">
                <img
                  src="assets/icons/filtericons/Transfusion-icon.svg"
                  *ngIf="type === 'Transfusion'"
                  class="filtericon"
                />

                <img
                  src="assets/icons/filtericons/order_diet.svg"
                  *ngIf="type === 'Diet'"
                  class="filtericon icon--diet"
                />

                <img
                  src="assets/icons/filtericons/order_lab.svg"
                  *ngIf="type === 'Labs'"
                  class="filtericon icon--lab"
                />

                <img
                  src="assets/icons/filtericons/Medication-icon.svg"
                  *ngIf="type === 'Medication'"
                  class="filtericon icon--med"
                />

                <img
                  src="assets/icons/filtericons/order_procedure.svg"
                  *ngIf="type === 'Procedure'"
                  class="filtericon icon--procedure"
                />

                <img
                  src="assets/icons/filtericons/order_vent.svg"
                  class="filtericon cp-vent__icon"
                  *ngIf="type === 'Vents'"
                />
                <img
                  src="assets/icons/filtericons/Protocol- Icon.svg"
                  class="filtericon"
                  *ngIf="type === 'Protocol'"
                /> </span
              >{{ type }}</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="request-cont button-primary"
    (click)="makeRequest()"
    *ngIf="enableRequest && requestBtnVisible"
  >
    Request an order
  </div>
</div>
