<cp-bed-unit-info
  buttonText="Raise {{ contextName }}"
  [headerColor]="headColor"
  hospitalLabel="Select the hospital to raise {{ contextName }}"
  (onSubmit)="handleSubmit($event)"
>
  <span class="heading display-flex justify-content-between">
    <div>
      <ng-container
        *ngIf="contextName == ECallPopupContext.ECall; else codeBlueIcon"
      >
        <img src="assets/icons/Ecall.svg" alt="Ecall" />
      </ng-container>
      <ng-template #codeBlueIcon>
        <img src="assets/icons/code_blue.svg" alt="code blue" />
      </ng-template>
      <div class="text">{{ contextName }}</div>
    </div>
    <div class="close-icon">
      <ng-container
        *ngIf="contextName == ECallPopupContext.ECall; else codeBlueCloseButton"
      >
        <img matDialogClose="cancel" src="assets/icons/close.svg" alt="close" />
      </ng-container>
      <ng-template #codeBlueCloseButton>
        <img
          matDialogClose="cancel"
          src="assets/icons/close_white.svg"
          alt="close_white"
        />
      </ng-template>
    </div>
  </span>
  <ng-container
    *ngIf="contextName == ECallPopupContext.ECall; else codeBlueIcon"
  >
    <img src="assets/icons/Ecall.svg" alt="ecall" />
  </ng-container>
  <ng-template #codeBlueIcon>
    <img src="assets/icons/code_blue.svg" alt="code_blue" />
  </ng-template>
  <div class="text">{{ contextName }}</div>
</cp-bed-unit-info>
