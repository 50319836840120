import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchBoxComponent } from "./components/search-box/search-box.component";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [SearchBoxComponent],
  imports: [CommonModule, MatIconModule, FormsModule],
  exports: [SearchBoxComponent],
})
export class SearchBoxModule {}
