// offset is given to transform it from tooltip height
const offsetTooltip = 10;
// offset to allign the tip of tooltip wrt element.
const offsetTip = 20;
import { ConnectedPosition } from "@angular/cdk/overlay";
import { ToolTipPositions } from "../model/tooltip.model";

export const TOP_START: ConnectedPosition = {
  originX: "center",
  originY: "top",
  overlayX: "start",
  overlayY: "bottom",
  offsetY: -1 * offsetTooltip,
  offsetX: -1 * offsetTip,
  panelClass: "cp-tooltip-top-start",
};

export const TOP_CENTER: ConnectedPosition = {
  originX: "center",
  originY: "top",
  overlayX: "center",
  overlayY: "bottom",
  offsetY: -1 * offsetTooltip,
  panelClass: "cp-tooltip-top-center",
};

export const TOP_END: ConnectedPosition = {
  originX: "center",
  originY: "top",
  overlayX: "end",
  overlayY: "bottom",
  offsetY: -1 * offsetTooltip,
  offsetX: offsetTip,
  panelClass: "cp-tooltip-top-end",
};

export const RIGHT_START: ConnectedPosition = {
  originX: "end",
  originY: "center",
  overlayX: "start",
  overlayY: "top",
  offsetX: offsetTooltip,
  offsetY: -1 * offsetTip,
  panelClass: "cp-tooltip-right-start",
};

export const RIGHT_CENTER: ConnectedPosition = {
  originX: "end",
  originY: "center",
  overlayX: "start",
  overlayY: "center",
  offsetX: offsetTooltip,
  panelClass: "cp-tooltip-right-center",
};

export const RIGHT_END: ConnectedPosition = {
  originX: "end",
  originY: "center",
  overlayX: "start",
  overlayY: "bottom",
  offsetX: offsetTooltip,
  offsetY: offsetTip,
  panelClass: "cp-tooltip-right-end",
};

export const BOTTOM_START: ConnectedPosition = {
  originX: "center",
  originY: "bottom",
  overlayX: "start",
  overlayY: "top",
  offsetY: offsetTooltip,
  offsetX: -1 * offsetTip,
  panelClass: "cp-tooltip-bottom-start",
};

export const BOTTOM_CENTER: ConnectedPosition = {
  originX: "center",
  originY: "bottom",
  overlayX: "center",
  overlayY: "top",
  offsetY: offsetTooltip,
  panelClass: "cp-tooltip-center-bottom",
};

export const BOTTOM_END: ConnectedPosition = {
  originX: "center",
  originY: "bottom",
  overlayX: "end",
  overlayY: "top",
  offsetY: offsetTooltip,
  offsetX: offsetTip,
  panelClass: "cp-tooltip-end-bottom",
};

export const LEFT_START: ConnectedPosition = {
  originX: "start",
  originY: "center",
  overlayX: "end",
  overlayY: "top",
  offsetX: -1 * offsetTooltip,
  offsetY: -1 * offsetTip,
  panelClass: "cp-tooltip-left-start",
};

export const LEFT_CENTER: ConnectedPosition = {
  originX: "start",
  originY: "center",
  overlayX: "end",
  overlayY: "center",
  offsetX: -1 * offsetTooltip,
  panelClass: "cp-tooltip-left-center",
};

export const LEFT_END: ConnectedPosition = {
  originX: "start",
  originY: "center",
  overlayX: "end",
  overlayY: "bottom",
  offsetX: -1 * offsetTooltip,
  offsetY: offsetTip,
  panelClass: "cp-tooltip-left-end",
};
export const positionData: ToolTipPositions = {
  "top-start": [TOP_START, BOTTOM_END],
  "top-center": [TOP_CENTER, BOTTOM_CENTER],
  "top-end": [TOP_END, BOTTOM_START],
  "right-start": [RIGHT_START, LEFT_END],
  "right-center": [RIGHT_CENTER, LEFT_CENTER],
  "right-end": [RIGHT_END, LEFT_START],
  "bottom-start": [BOTTOM_START, TOP_END],
  "bottom-center": [BOTTOM_CENTER, TOP_CENTER],
  "bottom-end": [BOTTOM_END, TOP_END],
  "left-start": [LEFT_START, RIGHT_END],
  "left-center": [LEFT_CENTER, RIGHT_CENTER],
  "left-end": [LEFT_END, RIGHT_START],
};

export const defaultPositioning = "top-start";
