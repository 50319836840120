import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { VitalsComponent } from "./vitals/vitals.component";
import { AssessmentNcpTabComponent } from "../assessment/assessment-ncp-tab/assessment-ncp-tab.component";
import { VitalRoutes } from "./models/Vital";

const routes: Routes = [
  {
    path: VitalRoutes.Vitals,
    component: VitalsComponent,
  },
  {
    path: VitalRoutes.Assessment,
    component: AssessmentNcpTabComponent,
  },
  {
    path: VitalRoutes.VitalPrint,
    component: VitalsComponent,
    data: {
      hideTabsAndPopup: true,
      hideVitalInput: true,
      collapseEmptyRows: true,
      hideGenerateButton: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VitalsRoutingModule {}
