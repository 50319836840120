import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";

import { AssignedUsersComponent } from "./components/assigned-users/assigned-users.component";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  declarations: [AssignedUsersComponent],
  imports: [CommonModule, IrisComponentsModule],
  exports: [AssignedUsersComponent],
})
export class AssignedUsersModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._matIconRegistry.addSvgIcon(
      `cp-team`,
      this._sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/team.svg")
    );
  }
}
