import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
  inject,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil, switchMap } from "rxjs/operators";
import * as fromTrendsStore from "../../store";
import * as fromLabsStore from "src/app/labs-scans-module/store";
import { AttrNamePipe } from "src/app/labs-scans-module/labs-scans/pipes/attr-name.pipe";
import {
  Category,
  DocumentViews,
  labViewsMap,
} from "src/app/labs-scans-module/labs-scans/models/labs-scans";
import { KeyValue } from "@angular/common";

import { TrendsBaseComponent } from "../trends-base/trends-base.component";
import { LabTrendsConfig } from "./lab-utility/lab-trends.config";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { TimezonePipe } from "@shared-modules/pipes/timezone-pipe/timezone.pipe";
import { TimezoneService } from "src/app/services/timezone.service";
import moment, { Moment } from "moment-timezone";

@Component({
  selector: "cp-lab-chart",
  templateUrl: "./lab-chart.component.html",
  styleUrls: ["./lab-chart.component.scss"],
  providers: [AttrNamePipe],
  encapsulation: ViewEncapsulation.None,
})
export class LabChartComponent
  extends TrendsBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  labNameControl = new FormControl("");
  labNames: string[] = [];
  selectedLabAttributesArray: string[] = [];
  public unsubscribe$: Subject<void> = new Subject<void>();
  protected _tz = inject(TimezoneService);
  @ViewChildren("canvas") public _canvasList: QueryList<any>;

  labTrendsConfig$ = this._trendStore.pipe(
    select(fromTrendsStore.getAllLabTrendsData),
    takeUntil(this.unsubscribe$)
  );

  trendCommonConfig$ = this._trendStore.pipe(
    select(fromTrendsStore.getTrendCommonConfig),
    takeUntil(this.unsubscribe$)
  );

  selectedGraphsNames$ = this._trendStore.pipe(
    select(fromTrendsStore.getAllLabTrendsNames),
    takeUntil(this.unsubscribe$)
  );

  labChartsConfigs: LabTrendsConfig[];
  selectedGraph: string[] = [];
  svgContainer: [] = [];
  isTrendActive: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      labKey: string;
      labItems: string[];
      attributes: string;
    },
    private _labStore: Store<fromLabsStore.LabState>,
    protected _store: Store<any>,
    private attrNamePipe: AttrNamePipe,
    private _trendsStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    private _trendStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    private _dialogRef: MatDialogRef<LabChartComponent>,
    private _cdrf: ChangeDetectorRef,
    public store: Store<{}>
  ) {
    super(_store);
  }

  ngOnInit(): void {
    this.subscribeToLabData();
    this.initializeLabNameControl();
    this.setupLabNameControlListener();
    this.trendCommonConfig$.subscribe((data) => {
      this.trendType = "Lab Trends";
    });
    this.selectedGraphsNames$.subscribe((data: string[]) => {
      this.selectedGraph = data;
    });
    // Resetting the store so that on re-open we can re-calculate the new data
    this._dialogRef.afterClosed().subscribe((_) => {
      this._trendStore.dispatch(fromTrendsStore.resetTrendConfig());
      this._trendStore.dispatch(fromTrendsStore.resetGrowthGraph());
    });
  }

  ngAfterViewInit(): void {
    this.labTrendsConfig$.subscribe((data: LabTrendsConfig[]) => {
      this.labChartsConfigs = data;

      // Manually running change detection
      // so that all graph div are rendered
      // in order to access them and append svg
      this._cdrf.detectChanges();
      this.clearAllSVGElements();
      this.generateGraphs();
    });
  }
  generateGraphs() {
    this.labChartsConfigs?.forEach((graphConfig, graphIndex) => {
      this.convertDates(graphConfig);
      const yDataPoints = graphConfig?.graphData?.map(
        (dataObj) => dataObj?.yValue
      );

      const currNativeEle = this._canvasList.find((ele, index) => {
        return ele.nativeElement.id === graphConfig?.graphName;
      });
      this.setGraphHeightWidht(this.trendType);
      const svg = this.attachSVGElement(currNativeEle.nativeElement);

      if (graphIndex == 0) this.makeTooltip(currNativeEle.nativeElement);
      if (graphConfig) {
        this.attachXAxis(svg, graphConfig?.graphConfig?.xAxisConfig);

        this.attachYAxis(
          svg,
          graphConfig?.graphConfig?.yAxisConfig,
          yDataPoints as []
        );

        this.plotNormalRanges(svg, graphConfig);
        this.makeLabMaxMinText(svg, graphConfig?.graphData);
        this.scatterDots(svg, graphConfig?.graphData);

        this.drawLineAndPath(svg, graphConfig?.graphData);

        this.plotALine(svg, graphConfig.graphName);
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._trendsStore.dispatch(fromTrendsStore.labTrendsActions.resetGraph());
  }

  get selectedAttribute() {
    return this._data.attributes;
  }

  private subscribeToLabData(): void {
    this.labData$.pipe(takeUntil(this.unsubscribe$)).subscribe((labDataMap) => {
      this.labNames = this.extractLabUniqueNames(labDataMap);
    });
  }

  private initializeLabNameControl(): void {
    if (this._data) {
      this.labNameControl.setValue(this._data.labKey);
      this.selectedLabAttributesArray = this._data.labItems;
    }
  }

  private setupLabNameControlListener(): void {
    this.labNameControl.valueChanges
      .pipe(
        switchMap((labSelected) =>
          this._labStore.pipe(
            select(fromLabsStore.getFilterByLabels([labSelected])),
            takeUntil(this.unsubscribe$)
          )
        )
      )
      .subscribe((data) => {
        this.fetchlabAttributesArray(data);
      });
  }

  private extractLabUniqueNames(labDataMap: labViewsMap): string[] {
    const uniqueLabNamesSet = new Set<string>();
    labDataMap.forEach((labDataArray) => {
      labDataArray.forEach((item) => {
        uniqueLabNamesSet.add(item.name);
      });
    });
    return Array.from(uniqueLabNamesSet);
  }

  private fetchlabAttributesArray(data: DocumentViews[]): void {
    this.selectedLabAttributesArray = this.attrNamePipe.transform(data);
  }

  private get labData$() {
    return this._labStore.pipe(
      select(fromLabsStore.getClassificationAndLabels(Category.LAB))
    );
  }

  convertDates(obj) {
    for (const key in obj) {
      if (key == "graphConfig") {
        if (
          obj[key].xAxisConfig.domainRange[0] ==
          obj[key].xAxisConfig.domainRange[1]
        ) {
          obj[key].xAxisConfig.domainRange[0] = moment(
            obj[key].xAxisConfig.domainRange[0]
          ).add(1, "day");
        }

        obj[key].xAxisConfig.domainRange.forEach((element, i) => {
          let dateString = this._tz
            .transformIntoTimezoneObj(element)
            .format("DD/MM/YYYY hh:mm A");

          // Split the date string into its components
          const [datePart, timePart, meridiem] = dateString.split(" ");

          // Extract day, month, year, hours, and minutes
          const [day, month, year] = datePart.split("/").map(Number);
          const [hours, minutes] = timePart.split(":").map(Number);

          // Convert the meridiem (AM/PM) to 24-hour format
          const hours24 = meridiem === "AM" ? hours : hours + 12;

          // Create a JavaScript Date object
          const dateObject = new Date(year, month - 1, day, hours24, minutes);

          obj[key].xAxisConfig.domainRange[i] = dateObject;
        });
      } else if (key == "graphData") {
        obj[key].forEach((element) => {
          let dateString = this._tz
            .transformIntoTimezoneObj(element.xValue)
            .format("DD/MM/YYYY hh:mm A");

          // Split the date string into its components
          const [datePart, timePart, meridiem] = dateString.split(" ");

          // Extract day, month, year, hours, and minutes
          const [day, month, year] = datePart.split("/").map(Number);
          const [hours, minutes] = timePart.split(":").map(Number);

          // Convert the meridiem (AM/PM) to 24-hour format
          const hours24 = meridiem === "AM" ? hours : hours + 12;

          // Create a JavaScript Date object
          const dateObject = new Date(year, month - 1, day, hours24, minutes);

          element.xValue = dateObject;

          // element.xValue = dateObject;
        });
      } else if (typeof obj[key] === "object") {
        this.convertDates(obj[key]);
      }
    }
  }
}
