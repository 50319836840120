import { createAction, props } from "@ngrx/store";

/** Action to get the current patient, this will cause a side effect */
export const getPatient = createAction(
  "[Patient chart] get selected patient",
  props<{ CPMRN: string; encounters: number; isHeader?: boolean }>()
);

/** Action to set the current patient in the store */
export const setPatient = createAction(
  "[Patient chart] set selected patient",
  props<{ patient: any }>()
);

export const amendPatient = createAction(
  "[Patient chart] reset to default then set the patient data",
  props<{ patient: any }>()
);

/** Action to reset the current patient in the store */
export const resetPatient = createAction(
  "[Patient chart] reset selected patient"
);

/** Action to discharge the patient, this will cause a side effect */
export const dischargePatient = createAction(
  "[Patient chart] discharge selected patient",
  props<{ patient: any }>()
);

/** Action to update the patient severity, this will cause a side effect */
export const updateSeverity = createAction(
  "[Patient chart] update patient severity",
  props<{ patient: any }>()
);

/** Action to set the patient severity */
export const setSeverity = createAction(
  "[Patient chart] set patient severity",
  props<{ severity: any }>()
);

/** Action to set the patient severity */
export const setCovid = createAction(
  "[Patient chart] set patient covid",
  props<{ covid: string }>()
);
