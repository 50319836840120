import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { User } from "./models/user";

@Injectable({
  providedIn: "root",
})
export class FamilyAuthGuardGuard implements CanActivate {
  constructor(private _router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const familyId = route.paramMap.get("familyId");
    let currUser: User = JSON.parse(localStorage.getItem("currentUser"));
    if (!currUser || !currUser.isFamilyUser) {
      this._router.navigate(["login", familyId]);
      return false;
    }

    return true;
  }
}
