export const vitalNormalRangesForPediatric = {
  daysTemperature: {
    default: {
      max: "99.3",
      min: "97.7",
    },
  },
  daysHR: {
    ageSpecific: true,
    hideNormalRangeGraph: true,
    hideNormalRangeText: true,
    ageSpecificRange: {
      // 'year specific'
      "1_year": {
        max: 160,
        min: 80,
      },
      "2_year": {
        max: 130,
        min: 80,
      },
      "4_year": {
        max: 120,
        min: 80,
      },
      "6_year": {
        max: 115,
        min: 75,
      },
      "8_year": {
        max: 110,
        min: 70,
      },
      "14_year": {
        max: 110,
        min: 70,
      },
      "18_year": {
        max: 110,
        min: 70,
      },
      default: {
        max: 110,
        min: 70,
      },
    },
  },
  daysRR: {
    ageSpecific: true,
    hideNormalRangeGraph: true,
    hideNormalRangeText: true,
    ageSpecificRange: {
      // 'year specific'
      "0.5_year": {
        max: 50,
        min: 30,
      },
      "1_year": {
        max: 75,
        min: 58,
      },
      "2_year": {
        max: 40,
        min: 30,
      },
      "4_year": {
        max: 42,
        min: 23,
      },
      "6_year": {
        max: 36,
        min: 19,
      },
      "8_year": {
        max: 30,
        min: 15,
      },
      "10_year": {
        max: 31,
        min: 15,
      },
      "12_year": {
        max: 28,
        min: 15,
      },
      "18_year": {
        max: 26,
        min: 18,
      },
      default: {
        max: 26,
        min: 18,
      },
    },
  },
  daysBP: {
    valuesInRange: true,
    hideNormalRangeGraph: true,
    hideNormalRangeText: true,
    ageSpecific: true,
    ageSpecificRange: {
      // 'year specific'
      // 6 months => 0.5 year
      "0.5_year": {
        range1: "87 - 105",
        range2: "53 - 65",
      },
      "2_year": {
        range1: "97 - 105",
        range2: "53 - 66",
      },
      "7_year": {
        range1: "97 - 112",
        range2: "57 - 71",
      },
      "18_year": {
        range1: "112 - 128",
        range2: "66 - 80",
      },
      default: {
        range1: "112 - 128",
        range2: "66 - 80",
      },
    },
  },
  daysSpO2: {
    default: {
      max: "100",
      min: "95",
    },
  },
};

export const vitalNormalRangesForNeonatal = {
  daysTemperature: {
    default: {
      max: "99.3",
      min: "97.7",
    },
  },
  daysHR: {
    default: {
      max: "190",
      min: "70",
    },
  },
  daysRR: {
    default: {
      max: "70",
      min: "28",
    },
  },
  daysBP: {
    valuesInRange: true,
    ageSpecific: true,
    hideNormalRangeGraph: true,
    hideNormalRangeText: true,
    ageSpecificRange: {
      "1_day": {
        "1_weight": {
          range1: "39 - 59",
          range2: "16 - 36",
        },
        "3_weight": {
          range1: "50 - 70",
          range2: "25 - 45",
        },
        default: {
          range1: "60 - 90",
          range2: "20 - 60",
        },
      },
      "28_day": {
        range1: "60 - 90",
        range2: "20 - 60",
      },
      default: {
        range1: "87 - 105",
        range2: "53 - 65",
      },
    },
  },
  daysSpO2: {
    default: {
      max: "100",
      min: "91",
    },
  },
};

// [['range'], showOnGraph]
const vitalNormalRangesForAdult: any = {
  daysTemperature: [[96, 101], true, "96 - 101"],
  daysBP: [undefined, false, "90 - 140", "60 - 80"],
  daysHR: [[61, 99], true, "61 - 99"],
  daysRR: [[9, 21], true, "9 - 21"],
  daysMAP: [[61, 99], true, "61 - 99"],
  daysCVP: [[0, 12], true, "0 - 12"],
  daysSpO2: [[91, 100], true, "91 - 100"],
};

export const getNormalRange = (pType, vitalName, ageObj, weight) => {
  if (!pType || pType === "adult")
    return vitalName in vitalNormalRangesForAdult
      ? vitalNormalRangesForAdult[vitalName]
      : undefined;

  // Pediatric & Neonatal
  const vitalRangeObj =
    pType === "neonatal"
      ? vitalNormalRangesForNeonatal[vitalName]
      : vitalNormalRangesForPediatric[vitalName];

  if (!vitalRangeObj) return null;

  if (pType == "neonatal") ageObj.day += +ageObj.month * 30;
  // for neonatal - converting age
  const currAge =
    pType === "neonatal"
      ? +ageObj.day + +(ageObj.hour / 24).toFixed(1)
      : +ageObj.year + +(ageObj.month / 12).toFixed(1);

  const isHideOnGraph = vitalRangeObj["hideNormalRangeGraph"] ? true : false;
  const isHideText = vitalRangeObj["hideNormalRangeText"] ? true : false;

  if (isHideOnGraph && isHideText) {
    return [null, false, null];
  }

  // return default value
  if (!vitalRangeObj["ageSpecific"]) {
    const { max, min } = vitalRangeObj["default"];
    return [
      isHideOnGraph ? undefined : [min, max],
      isHideOnGraph ? false : true,
      `${min} - ${max}`,
    ];
  }

  // return age specific values
  const ranges = vitalRangeObj["ageSpecificRange"];
  for (const [ageThresold, values] of Object.entries(ranges)) {
    if (currAge > +ageThresold.split("_")[0]) continue;

    if (vitalRangeObj["valuesInRange"]) {
      if (pType === "neonatal" && currAge <= 1) {
        // BP range - match with weight
        for (const [reqWeight, wValues] of Object.entries(values)) {
          if (weight > +reqWeight.split("_")[0]) continue;

          return [
            undefined,
            isHideOnGraph ? false : true,
            ...returnBPRange(wValues),
          ];
        }

        // return default range
        return [
          undefined,
          isHideOnGraph ? false : true,
          ...returnBPRange(values["default"]),
        ];
      } else {
        return [
          undefined,
          isHideOnGraph ? false : true,
          ...returnBPRange(values),
        ];
      }
    } else {
      return [
        isHideOnGraph ? undefined : [values["min"], values["max"]],
        isHideOnGraph ? false : true,
        `${values["min"]} - ${values["max"]}`,
      ];
    }
  }

  // return default values for age Specific vitals
  if (vitalRangeObj["valuesInRange"]) {
    return [
      undefined,
      isHideOnGraph ? false : true,
      ...returnBPRange(ranges["default"]),
    ];
  } else {
    const defaultObj = ranges["default"];
    return [
      isHideOnGraph ? undefined : [defaultObj["min"], defaultObj["max"]],
      isHideOnGraph ? false : true,
      `${defaultObj["min"]} - ${defaultObj["max"]}}`,
    ];
  }
};

const returnBPRange = (rangeObj) => {
  return [rangeObj["range1"], rangeObj["range2"]];
};
