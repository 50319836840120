<div
  class="cp-shift-toggle-button display-flex"
  *ngIf="shift$ | async as activeShift"
>
  <ng-container *ngIf="activeShift != shiftsEnum.ALL_DAY">
    <div
      class="cp-shift-toggle-button__button cp-cursor-pointer display-flex align-items-center"
      [ngClass]="{
        'cp-shift-toggle-button__button--active': activeShift === shift
      }"
      *ngFor="let shift of shifts"
      (click)="setToggleButton(shift)"
    >
      <mat-icon
        [svgIcon]="
          activeShift === shift ? shiftObj[shift] + '-active' : shiftObj[shift]
        "
        aria-hidden="false"
        aria-label="shift icon"
      ></mat-icon>
    </div>
  </ng-container>
</div>
