import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'backendError' })
export class BackendErrorPipe implements PipeTransform {
    transform(value: object, ...args): Object[] {
        let result = [];

        Object.keys(value)
            .map((key) => result = [ ...result, ...value[ key ] ]);

        return result;
    }
}
