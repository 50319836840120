import { UntypedFormGroup } from "@angular/forms";

export interface OrderableRequest {
  _id?: string;
  name?: string;
  type?: OrderableRequestType;
  status?: OrderableRequestStatus;
  creatorName?: OrderableRequestCreator;
}

export interface OrderableRequestTable extends OrderableRequest {
  snomedInput?: UntypedFormGroup;
}

interface OrderableRequestCreator {
  name: string;
  email: string;
  timestamp: Date;
}

export interface OrderableRequestBEApiResponse {
  pending: OrderableRequest[];
  rejected: OrderableRequest[];
}

export enum OrderableRequestStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum OrderableRequestType {
  Blood = "blood",
  Comm = "comm",
  Diet = "diet",
  Lab = "lab",
  Med = "med",
  Procedure = "procedure",
  Vent = "vents",
}

export interface OrderableRequestAcceptRequest {
  code: number;
}
