export const vitalrange = {
  neonatal: {
    "24H,1KG": {
      daysBP: {
        priorities: [
          {
            min: null,
            max: "39/16",
            priority: "high",
          },
          {
            min: "59/36",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "24H,3KG": {
      daysBP: {
        priorities: [
          {
            min: null,
            max: "50/25",
            priority: "high",
          },
          {
            min: "70/45",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "24H,default": {
      daysBP: {
        priorities: [
          {
            min: null,
            max: "60/20",
            priority: "high",
          },
          {
            min: "90/60",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "28D": {
      daysBP: {
        priorities: [
          {
            min: null,
            max: "60/20",
            priority: "high",
          },
          {
            min: "90/60",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    default: {
      daysTemperature: {
        priorities: [
          {
            min: null,
            max: 97.7,
            priority: "high",
          },
          {
            min: 99.32,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 125,
        },
      },
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 190,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 28,
            priority: "high",
          },
          {
            min: 70,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysFiO2: {
        priorities: [
          {
            min: null,
            max: 28,
            priority: "high",
          },
          {
            min: 70,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 21,
          max: 100,
        },
      },
      daysSpO2: {
        priorities: [
          {
            min: null,
            max: 91,
            priority: "high",
          },
          {
            min: 100,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysBP: {
        priorities: [
          {
            min: null,
            max: "87/53",
            priority: "high",
          },
          {
            min: "105/65",
            max: null,
            priority: "high",
          },
        ],
      },
    },
  },
  pediatric: {
    "6M": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 80,
            priority: "high",
          },
          {
            min: 160,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 30,
            priority: "high",
          },
          {
            min: 50,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysBP: {
        priorities: [
          {
            min: null,
            max: "87/53",
            priority: "high",
          },
          {
            min: "105/65",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "12M": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 80,
            priority: "high",
          },
          {
            min: 160,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 58,
            priority: "high",
          },
          {
            min: 75,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "2Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 80,
            priority: "high",
          },
          {
            min: 130,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 30,
            priority: "high",
          },
          {
            min: 40,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysBP: {
        priorities: [
          {
            min: null,
            max: "95/53",
            priority: "high",
          },
          {
            min: "105/66",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "4Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 80,
            priority: "high",
          },
          {
            min: 120,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 23,
            priority: "high",
          },
          {
            min: 42,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "6Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 75,
            priority: "high",
          },
          {
            min: 115,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 19,
            priority: "high",
          },
          {
            min: 36,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "7Y": {
      daysBP: {
        priorities: [
          {
            min: null,
            max: "97/57",
            priority: "high",
          },
          {
            min: "112/71",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    "8Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 110,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 15,
            priority: "high",
          },
          {
            min: 30,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "10Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 110,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 15,
            priority: "high",
          },
          {
            min: 31,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "12Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 110,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 15,
            priority: "high",
          },
          {
            min: 28,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "14Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 110,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 18,
            priority: "high",
          },
          {
            min: 26,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
    },
    "18Y": {
      daysHR: {
        priorities: [
          {
            min: null,
            max: 70,
            priority: "high",
          },
          {
            min: 110,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysTemperature: {
        priorities: [
          {
            min: null,
            max: 97.7,
            priority: "high",
          },
          {
            min: 99.32,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 125,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 18,
            priority: "high",
          },
          {
            min: 26,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysSpO2: {
        priorities: [
          {
            min: null,
            max: 95,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysBP: {
        priorities: [
          {
            min: null,
            max: "112/66",
            priority: "high",
          },
          {
            min: "128/80",
            max: null,
            priority: "high",
          },
        ],
      },
    },
    default: {
      daysTemperature: {
        priorities: [
          {
            min: null,
            max: 97.7,
            priority: "high",
          },
          {
            min: 99.32,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 125,
        },
      },
      daysFiO2: {
        priorities: [
          {
            min: null,
            max: 21,
            priority: "high",
          },
          {
            min: 100,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 21,
          max: 100,
        },
      },
      daysSpO2: {
        priorities: [
          {
            min: null,
            max: 95,
            priority: "high",
          },
          {
            min: 100,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
    },
  },
  others: {
    default: {
      daysTemperature: {
        priorities: [
          {
            min: null,
            max: 96,
            priority: "high", // <=max
          },
          {
            min: 96,
            max: 98,
            priority: "medium", //>min <=max
          },
          {
            min: 99,
            max: 100,
            priority: "medium", //>min <=max
          },
          {
            min: 100,
            max: null,
            priority: "high", //>min
          },
        ],
        ranges: {
          min: 0,
          max: 125,
        },
      },
      daysHR: {
        priorities: [
          {
            min: null,
            max: 40,
            priority: "high",
          },
          {
            min: 40,
            max: 60,
            priority: "medium",
          },
          {
            min: 100,
            max: 119,
            priority: "medium",
          },
          {
            min: 120,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 350,
        },
      },
      daysRR: {
        priorities: [
          {
            min: null,
            max: 6,
            priority: "high",
          },
          {
            min: 6,
            max: 9,
            priority: "medium",
          },
          {
            min: 22,
            max: 30,
            priority: "medium",
          },
          {
            min: 30,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysSpO2: {
        priorities: [
          {
            min: null,
            max: 80,
            priority: "high",
          },
          {
            min: 80,
            max: 90,
            priority: "medium",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentCuffPressure: {
        priorities: [
          {
            min: 30,
            max: 40,
            priority: "medium",
          },
          {
            min: 40,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysFalls: {
        priorities: [
          {
            min: 26,
            max: 45,
            priority: "medium",
          },
          {
            min: 45,
            max: 115,
            priority: "high",
          },
        ],
      },
      daysPain: {
        priorities: [
          {
            min: 3,
            max: 8,
            priority: "medium",
          },
          {
            min: 8,
            max: 10,
            priority: "high",
          },
        ],
      },
      daysBraden: {
        priorities: [
          {
            min: null,
            max: 12,
            priority: "high",
          },
          {
            min: 12,
            max: 18,
            priority: "medium",
          },
        ],
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentMAP: {
        priorities: [
          {
            min: null,
            max: 30,
            priority: "high",
          },
          {
            min: 50,
            max: 60,
            priority: "medium",
          },
          {
            min: 100,
            max: 120,
            priority: "medium",
          },
          {
            min: 120,
            max: null,
            priority: "high",
          },
        ],
        ranges: {
          min: 0,
          max: 500,
        },
      },
      "cam-icu": {
        priorities: [
          {
            data: "Positive",
            priority: "high",
          },
        ],
      },
      daysAVPU: {
        priorities: [
          {
            data: "V",
            priority: "medium",
          },
          {
            data: "P",
            priority: "medium",
          },
          {
            data: "U",
            priority: "high",
          },
        ],
      },
      daysFiO2: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysOxygenFlow: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentVt: {
        ranges: {
          min: 0,
          max: 2000,
        },
      },
      daysVentPEEP: {
        min: 0,
        max: 30,
      },
      daysVentRRset: {
        ranges: {
          min: 0,
          max: 200,
        },
      },
      daysVentPinsp: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentPsupport: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentPplat: {
        ranges: {
          min: 0,
          max: 80,
        },
      },
      daysVentPmean: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentPpeak: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentMV: {
        ranges: {
          min: 0,
          max: 100,
        },
      },
      daysVentI: {
        ranges: {
          min: 0,
          max: 10,
        },
      },
      daysVentE: {
        ranges: {
          min: 0,
          max: 10,
        },
      },
      daysEttTieLevel: {
        ranges: {
          min: 0,
          max: 50,
        },
      },
      daysVentTubeSize: {
        ranges: {
          min: 0,
          max: 10,
        },
      },
    },
  },
};
