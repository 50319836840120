import { Labs } from "../../labs-scans/models/labs-scans";
import * as fromLabs from "../actions/lab-forms.actions";

export interface LabFormState {
  entities: Labs["ordersArray"];
  loaded: boolean;
  loading: boolean;
}

export const initialState: LabFormState = {
  entities: [],
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action): LabFormState {
  switch (action.type) {
    case fromLabs.LOAD_LAB_FORMS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_FORMS_DELETE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_FORMS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_FORMS_SUCCESS: {
      const entities = [...state.entities, action.payload.document];
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromLabs.UPDATE_LAB_FORMS_SUCCESS: {
      const entities = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
    case fromLabs.LOAD_LAB_FORMS_DELETE_SUCCESS: {
      const filterData = state.entities.filter((data) => {
        if (data["alias"]?.length) {
          return data["alias"][0] != action.payload;
        } else return data["name"] != action.payload;
      });
      state.entities = Object.assign(filterData);
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case fromLabs.LOAD_LAB_FORM_SUCCESS_MESSAGE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case fromLabs.LOAD_LAB_FORMS_RESET: {
      return initialState;
    }

    case "[Root Actions] patient chart closed": {
      return initialState;
    }
  }

  return state;
}

export const getLabFormEntities = (state: LabFormState) => state.entities;
export const getLabFormLoading = (state: LabFormState) => state.loading;
export const getLabFormLoaded = (state: LabFormState) => state.loaded;
