import { TokenExpiredErrorComponent } from "../token-expired-error/token-expired-error.component";
import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as rootActions from "src/app/store/actions/root.actions";
import * as authActions from "src/app/store/actions/auth.actions";
import { Store } from "@ngrx/store";
import * as fromRoot from "src/app/reducers";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private store: Store<fromRoot.AppState>,
    private _authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has("DoNotIntercept")) {
      const authService = this.injector.get(AuthService);
      if (!authService.getToken()) {
        return next.handle(req).pipe(
          tap(null, (err) => {
            if (err && (err.status === 403 || err.status === 401)) {
              this.store.dispatch(rootActions.logout());
              this.store.dispatch(
                authActions.authError({
                  status: err.status,
                  message: "Unauthorized Action",
                })
              );
            }
          })
        );
      } else {
        let setHeaders = {
          Authorization: `Bearer ${authService.getToken()}`,
          "Content-Type": "application/json",
        };
        // if (!environment?.apiUrl?.includes("localhost")) {
        if (req.method === "GET") {
          setHeaders["Content-Encoding"] = "gzip";
        }

        const tokenizedReq = req.clone({ setHeaders });
        return next.handle(tokenizedReq).pipe(
          tap(null, async (err) => {
            if (err && (err.status === 403 || err.status === 401)) {
              console.log(err.error.message);
              if (
                err?.error?.message === "TokenExpiredError" &&
                localStorage.getItem("refreshToken") &&
                req.url.indexOf("/logout") === -1
              ) {
                console.log(err.error);
                // request for a new token; by sending refresh token as the header

                const response = await authService.getAccessToken();
                console.log(response.token);
                if (!response.token) {
                  this.store.dispatch(rootActions.logout());
                  this.store.dispatch(
                    authActions.authError({
                      status: err.status,
                      message: "Unauthorized Action",
                    })
                  );
                  return;
                }
                // set new token
                localStorage.setItem("token", response.token);
                localStorage.setItem("refreshToken", response.refreshToken);
                let oldUserData: any = localStorage.getItem("currentUser");
                oldUserData = JSON.parse(oldUserData);

                let localUserData = JSON.parse(
                  JSON.stringify({
                    ...response.userInfo,
                    wikiJwt: oldUserData?.wikiJwt,
                    currentUserState: true,
                  })
                );

                localStorage.setItem(
                  "currentUser",
                  JSON.stringify(localUserData)
                );
                // this.modalService.open(TokenExpiredErrorComponent, { windowClass: 'dark-modal' });
                this._authService.initiateTokenExpired(true);

                return;
              }
              this.store.dispatch(rootActions.logout());
              if (req.url.indexOf("/logout") === -1) {
                this.store.dispatch(
                  authActions.authError({
                    status: err.status,
                    message: "Unauthorized Action",
                  })
                );
              }
            }
          })
        );
      }
    } else {
      req = req.clone({
        headers: req.headers.delete("DoNotIntercept"),
      });
      return next.handle(req).pipe(
        tap(null, (err) => {
          if (err && err.status === 403) {
            this.store.dispatch(rootActions.logout());
            this.store.dispatch(
              authActions.authError({
                status: err.status,
                message: "Unauthorized Action",
              })
            );
          }
        })
      );
    }
  }
}
