<div class="filter-holder ml mt">
  <input
    matInput
    placeholder="Filter request"
    autocomplete="off"
    class="iris-input cp-form-input"
    [formControl]="filterSearch"
  />
  <span
    (click)="clearInput()"
    class="primary cp-cursor-pointer filter-clear"
    [ngClass]="{ 'filter-clear-disabled': isInputEmpty() }"
    >Clear All</span
  >
</div>
