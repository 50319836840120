import { OrderTime } from "./OrderTime.model";
import { OrderFrequency } from "./OrderFrequency.model";

export interface Med {
  _id?: string;
  name?: string;
  quantity?: string | number;
  brandName?: string;
  unit?: string;
  route?: string;
  numberOfDoses?: string | number;
  form?: string;
  urgency?: string;
  instructions?: string;
  additionalInformation?: string;
  everyFreRadio?: string | null;
  sosReason?: string;
  combination?: MedCombination[] | null;
  frequency?: OrderFrequency;
  sos?: boolean;
  concentration?: MedConcentration | null;
  doseRange?: boolean;
  bodyWeight?: number | null;
  maxDose?: number | null;
  noOfDays?: number;
}

export interface MedOrderFE extends Med {
  startTime: OrderTime;
  endTime: OrderTime;
  pta: boolean | null;
  bedsideOrder: boolean | null;
}

export interface MedOrderBE extends Med {
  startTime: Date | null;
  endTime: Date | null;
  pta: boolean | null;
  bedsideOrder: boolean | null;
}

export interface MedCombination {
  _id?: string;
  name: string;
  quantity: string | number;
  unit: string;
  concentration?: MedConcentration | null;
}

export interface MedConcentration {
  value: number;
  unit: string;
}

export const units = [
  "Billion CFU",
  "Capsules",
  "drops",
  "g",
  "g/kg",
  "IU",
  "IU/hr",
  "IU/kg",
  "IU/kg/hr",
  "IU/kg/min",
  "IU/min",
  "kg",
  "KIT",
  "l",
  "l/min",
  "mcg",
  "mcg/hr",
  "mcg/kg",
  "mcg/kg/hr",
  "mcg/kg/min",
  "mcg/min",
  "mEq",
  "mEq/hr",
  "mEq/kg",
  "mEq/kg/hr",
  "mEq/kg/min",
  "mg",
  "mg/hr",
  "mg/kg",
  "mg/kg/hr",
  "mg/kg/min",
  "mg/min",
  "mg/msq",
  "micrograms",
  "milligrams",
  "Million Units",
  "ml",
  "ml/hr",
  "ml/kg",
  "ml/kg/hr",
  "ml/min",
  "mmol",
  "mmol/kg",
  "mmol/kg/hr",
  "MU",
  "puff",
  "Sachets",
  "spray",
  "Tablet",
  "Vial",
];

export const concentrationUnits = [
  "IU/l",
  "IU/ml",
  "g/l",
  "mEq/l",
  "mEq/ml",
  "mcg/l",
  "mcg/ml",
  "mg/l",
  "mg/ml",
  "ng/l",
  "ng/ml",
];

export const routes = [
  "Epidural",
  "ID",
  "IM",
  "Inhalation",
  "Intradural",
  "Intraocular",
  "Intraspinal",
  "Intraventricular",
  "IO",
  "IV",
  "Jejunostomy",
  "Nasal Spray",
  "Nebulization",
  "NG",
  "OG",
  "Oral",
  "Patch",
  "PEG",
  "Peritoneal",
  "PO",
  "PR",
  "SC",
  "SL",
  "Topical",
  "Transdermal",
  "Vaginal",
];

export const urgency = ["STAT", "ASAP"];

export const everyFreRadio = ["4", "6", "8", "12", "24"];
