import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ReplaySubject, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { CustomRouterState } from "src/app/store/router/custom-serialize";
import { getCurrentRoute } from "src/app/store/router/selector";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CoreHttpService {
  env = environment;

  private _paramsSubject: { [key: string]: string };

  constructor(private _routeStore: Store<CustomRouterState>) {
    this.getRouterStateData()
      .pipe(map((routerState) => routerState?.params))
      .subscribe((params) => (this._paramsSubject = params));
  }

  private getRouterStateData() {
    return this._routeStore.select(getCurrentRoute);
  }

  get params() {
    return this._paramsSubject;
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    withCredentials: true,
  };

  public doNotInterceptHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      DoNotIntercept: "true",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }
}
