export const bloodConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  default: { present: false },
  protocols: { present: false },
  title: {
    present: true,
    display: false,
  },
  bedsideOrder: { present: true },
  protocol: { present: true },
  dose: {
    label: "Instances",
  },
  displayAsShortcut: { present: false },
  patientType: { present: false },
};

export const commConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  default: { present: false },
  protocols: { present: false },
  title: {
    present: true,
    display: false,
  },
  bedsideOrder: { present: true },
  protocol: { present: true },
  frequency: {
    data: ["continuous", "every", "once", "before", "after"],
  },
  dose: {
    label: "Instances",
  },
  displayAsShortcut: { present: false },
  patientType: { present: false },
};

export const dietConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  default: { present: false },
  protocols: { present: false },
  name: {
    present: true,
    display: false,
  },
  bedsideOrder: { present: true },
  protocol: { present: true },
  frequency: {
    data: ["continuous", "every", "once", "before", "after"],
  },
  dose: {
    label: "Instances",
  },
  displayAsShortcut: { present: false },
  patientType: { present: false },
};

export const labConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  investigation: {
    present: true,
    readOnly: true,
    display: false,
  },
  default: { present: false },
  protocols: { present: false },
  bedsideOrder: { present: true },
  protocol: { present: true },
  frequency: {
    data: ["continuous", "every", "once", "before", "after"],
  },
  dose: {
    label: "Instances",
  },
  displayAsShortcut: { present: false },
  patientType: { present: false },
};

export const medConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  name: {
    present: true,
    readOnly: true,
    display: false,
  },
  default: { present: false },
  protocols: { present: false },
  pta: { present: false },
  bedsideOrder: { present: true },
  comb: { present: true },
  protocol: { present: true },
  frequency: {
    data: ["continuous", "every", "once", "before", "after", "SOS"],
  },
  bodyWeight: { present: true },
  displayAsShortcut: { present: false },
  noOfDays: { present: true },
  patientType: { present: false },
};

export const procedureConfig = {
  _id: { present: true },
  presetName: { present: false },
  startTime: { present: true },
  endTime: { present: true },
  default: { present: false },
  protocols: { present: false },
  name: { present: false },
  pType: {
    present: true,
    display: false,
  },
  bedsideOrder: { present: true },
  protocol: { present: true },
  dose: {
    label: "Instances",
  },
  displayAsShortcut: { present: false },
  patientType: { present: false },
};

export const ventConfig = {
  _id: { present: true },
  startTime: { present: true },
  endTime: { present: false },
  defaultDate: { present: false },
  displayAsShortcut: { present: false },
  presetName: { present: false },
  protocols: { present: false },
  default: { present: false },
  patientType: { present: false },
};
