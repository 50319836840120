import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError, BehaviorSubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  env = environment;
  constructor(private _http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  // Patient Chart
  sendMessage(CPMRN, encounters, message) {
    let dataToSend = {
      CPMRN: CPMRN,
      encounters: encounters,
      message: message,
    };
    return this._http
      .post(this.env.apiUrl + "chat/" + "postMessage", dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  updateLastSeen(CPMRN, encounters, readReciept) {
    let dataToSend = {
      CPMRN: CPMRN,
      encounters: encounters,
      readReciept: readReciept,
    };

    return this._http
      .post(this.env.apiUrl + "chat/updateLastSeen", dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  // Unit list
  sendMessageUnitList(unit, message) {
    let dataToSend = {
      unitName: unit.name,
      hospitalName: unit.hospitalInfo?.name,
      message: message,
    };
    return this._http
      .post(this.env.apiUrl + "chat/" + "post_message_unit_list", dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  updateLastSeenUnitList(unit, readReciept) {
    let dataToSend = { unit, readReciept };

    return this._http
      .post(this.env.apiUrl + "chat/update_last_seen_unit_list", dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  getUnitChatMessages(hospName, unitName) {
    return this._http
      .get(`${this.env.apiUrl}chat/get_unit_messages/${hospName}/${unitName}`)
      .pipe(catchError(this.errorHandler));
  }

  // emit chat event for unit list
  private dataSource = new BehaviorSubject<any>({});
  dataListener = this.dataSource.asObservable();

  emitData(data: any) {
    this.dataSource.next(data);
  }

  strikeOffUnitChat(unit, message) {
    let dataToSend = { unit, message };

    return this._http
      .post(`${this.env.apiUrl}chat/strike_off_unitchat`, dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  strikeOffPatChat(CPMRN, encounters, message) {
    let dataToSend = { CPMRN, encounters, message };

    return this._http
      .post(`${this.env.apiUrl}chat/strike_off_patchat`, dataToSend)
      .pipe(catchError(this.errorHandler));
  }
}
