import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  hasPasswordError(group: any, controlName: string, errorName: string): boolean {
    if (!!controlName)
      return group.controls[controlName].hasError(errorName); // for nested controlller inside group
    else {
      return group.errors && group.errors[errorName] // for group errors
    }
  }
}
