import { User } from "../models/user";

export const tabCount = 8;

export const RadarGlobalConfig = {
  CLOUDPHYSICIANHOSPITALNAME: "Cloudphysician",
  IMPACTNOTEHOSPITAL: ["Cloudphysician", "Aster", "Motherhood"],
  DEFAULTPAGELIMIT: 15,
  isCloudphysicianUser: function (currentUser: User): boolean {
    return currentUser?.hospitals[0]?.name === this.CLOUDPHYSICIANHOSPITALNAME;
  },
  isImpactNoteHospital: function (currentUser: User): boolean {
    return this.IMPACTNOTEHOSPITAL.includes(currentUser?.hospitals[0]?.name);
  },
};
