import {
  Directive,
  EventEmitter,
  HostListener,
  Injectable,
  Output,
} from "@angular/core";
import { Subject } from "rxjs";
import {
  ProcessResponseData,
  processRichEditInWebWorker,
} from "./augnitoFunctions";
declare const GetAugnitoClient: any;
declare const AugnitoCMDs: any;
declare const CKEditorProcess: any;
declare const AugnitoCMDStatic: any;
declare const AugnitoCMDRegex: any;
@Injectable({
  providedIn: "root",
})
@Directive({
  selector: "[cpAugnitoButton]",
})
export class AugnitoButtonDirective {
  title = "augnito";

  @Output() augnitoText: EventEmitter<string> = new EventEmitter();
  @Output() micOffDialog: EventEmitter<boolean> = new EventEmitter();
  @Output() partialText: EventEmitter<object> = new EventEmitter();

  warning: boolean = false;

  appLogic: any = {};
  augnitoClient: any;
  webWorkerRichEdit: any;
  //properties used for applogic
  augnitoBtnActive = false;
  hyperText = {
    show: false,
    val: "",
  };
  constructor() {
    this.appLogic.car = "string";

    this.appLogic.OnChangeMicState = (isConnected) => {
      //This will be called when mic recording start OR stop to update UI interface mic button.
      if (isConnected) {
        this.hyperText = { show: false, val: "" };
        this.augnitoBtnActive = true;
      } else {
        this.hyperText = { show: false, val: "" };
        this.augnitoBtnActive = false;
      }
    };
    this.appLogic.InterfaceCommand = (ActionRecipe) => {
      // Any UI Command to be process here, before it reaches the editor
      if (AugnitoCMDs.STOP_MIC == ActionRecipe.Name) {
        this.augnitoClient.stopListening();
        return true;
      }
      return false;
    };
    this.appLogic.CommandAndControlCallback = (ActionRecipe) => {
      if (this.appLogic.InterfaceCommand(ActionRecipe)) {
        return;
      }
      var isCkEditor = CKEditorProcess.IsActiveElementCkEditor();
      if (isCkEditor) {
        CKEditorProcess.ProcessCommand(ActionRecipe);
      }
    };
    this.appLogic.FinalResultCallback = (ActionRecipe) => {
      // This final result that need to typed in Editor as dictated speech output.
      var isCkEditor = CKEditorProcess.IsActiveElementCkEditor();
      if (isCkEditor) {
        // Handled new line character
        var newProcessText = ActionRecipe.ReceivedText;
        newProcessText = newProcessText.replace(/\n/gi, "@newline@");
        CKEditorProcess.CkEditorWriter(newProcessText);
      }
    };
    this.appLogic.OnSessionEvent = (meta) => {
      // Session event will be called during speech session(between mic start and stop)
      // whenever server has to send information to client app which is not relaed to ASR output.
      var sessionMetaData = meta;
      if (this.appLogic.EnableLogs) {
      }
      var event = sessionMetaData.Event;
      if (event == "None") {
        // Error case
        return;
      }

      var eventType = sessionMetaData.Event.Type;
      var eventValue = sessionMetaData.Event.Value;
      if (eventType == "SESSION_CREATED") {
        // After successful authenticate, server creates an unique ID for each speech session and sends it back to client app for reference.
        // Client app can store this is it requires.
      } else if (eventType == "SERVICE_DOWN") {
        // Very rare, But This event will come when Speech server's any internal component down.
      } else if (eventType == "NO_DICTATION_STOP_MIC") {
        // Some time user start mic and forgot after it. start doing discussion with colleague or on phone.
        // In this case mic is on and user is not dictating any valid speech for trascription. Server can detect such situations and send an event to confirm from user.
        // HandleMicOff();
        alert("handleoffmic");
      } else if (eventType == "INVALID_AUTH_CREDENTIALS") {
        // This event happens when one of following is invalid.
        // AccountCode, AccessKey, Active subscription for trial or paid. lmid.
        // $("#MessageDialog").dialog("open");4
        alert("MessageDialog");
      } else if ("LOW_BANDWIDTH" == eventType) {
        // Speech API need continues upload speed of 32KBps if it raw audio data with 16k sampling rate.
        // If fluctuation in internet than speech output may be delayed. It's good to notify that speech may delayed due to poor network connection.
        // Client app can use this event to show un attendant popup to indicate network status.
      }
    };
    this.appLogic.onPartialResults = (response) => {
      // Partial output against audio stream started to server. This is not final and keep changing.
      // Use this to show user that system started listing, and Processing your voice.

      var partialText = response.Result.Transcript;
      if (partialText && partialText.length > 82) {
        partialText = ".." + partialText.substring(partialText.length - 80);
      }
      this.hyperText = {
        show: true,
        val: partialText,
      };
      this.partialText.emit(this.hyperText);
    };
    this.appLogic.onFinalResults = (response) => {
      // Prepare Action Recipe from speech output to pass in editor handle.
      // When System make output final this even will be call.
      // It can be either static command, or normal transcription.

      // appLogic.HyperTextControl.html('');
      // appLogic.HyperTextControl.hide();
      this.hyperText = { show: false, val: null };
      var ActionRecipe: any = new Object();
      var text = response.Result.Transcript;
      var Action = response.Result.Action;
      ActionRecipe.Name = text.replace(/\s+/g, "");
      ActionRecipe.SessionCode = response.SessionCode;
      ActionRecipe.Final = response.Result.Final;
      ActionRecipe.IsCommand = response.Result.IsCommand;
      if (ActionRecipe.IsCommand) {
        ActionRecipe.Action = Action.replace(/\s+/g, "");
      }
      ActionRecipe.ReceivedText = text;
      this.augnitoText.emit(ActionRecipe.ReceivedText);
      this.partialText.emit(this.hyperText);

      // const selection = this.editor.editor.getSelection(true);
      // this.editor.editor.insertText(selection.index, ActionRecipe.ReceivedText);
    };
    (this.appLogic.onReadyForSpeech = function () {
      // On socket connection established
      this.OnChangeMicState(true);
    }),
      (this.appLogic.onEndOfSession = function () {
        // On mic off and connection close
        this.OnChangeMicState(false);
      }),
      (this.appLogic.onError = (code, data) => {
        if (this.appLogic.EnableLogs) {
          console.log("ERR: " + code + ": " + (data || ""));
        }
      });

    this.augnitoClient = GetAugnitoClient(this.appLogic);

    this.webWorkerRichEdit = processRichEditInWebWorker(ProcessResponseData);
    this.webWorkerRichEdit.onmessage = (event) => {
      var ActionRecipe = event.data;
      if (ActionRecipe.IsCommand) {
        // Static commands , server will give action name
        ActionRecipe = AugnitoCMDStatic.PrepareRecipe(ActionRecipe);
        this.appLogic.CommandAndControlCallback(ActionRecipe);
      } else {
        // Look for dynamic commands
        ActionRecipe = AugnitoCMDRegex.PrepareRecipe(ActionRecipe);
        if (ActionRecipe.IsCommand) {
          this.appLogic.CommandAndControlCallback(ActionRecipe);
        } else {
          this.appLogic.FinalResultCallback(ActionRecipe);
        }
      }
    };
  }
  private disable: boolean;
  @HostListener("mouseup", ["$event"])
  @HostListener("mouseleave", ["$event"])
  @HostListener("window:keyup", ["$event"])
  onExit() {
    this.stopListening();
    this.micOffDialog.emit(false);
    this.disable = false;
    this.partialText.emit({ show: false, val: "" });
  }

  @HostListener("mousedown", ["$event"])
  onHold() {
    if (!this.disable) {
      this.startListening();
      this.micOffDialog.emit(true);
    }
    this.disable = true;
  }

  @HostListener("window:keydown", ["$event"]) // added keypress down alt for mic on
  onKeyDown(event) {
    if (event.ctrlKey && event.keyCode === 18) {
      if (!this.disable) {
        this.startListening();
        this.micOffDialog.emit(true);
      }
      this.disable = true;
    }
  }

  toggleListening() {
    this.augnitoClient.toggleListening();
  }

  startListening() {
    this.augnitoClient.startListening();
  }

  stopListening() {
    this.augnitoClient.stopListening();
  }
}
