import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "cp-choice-list",
  template: `
    <div *ngIf="label">
      <label [for]="label" [ngClass]="labelClass" class="cp-label-text">{{
        label
      }}</label>
    </div>
    <div class="choice-list">
      <div
        *ngFor="let option of options"
        class="choice-list__option"
        [class.active]="isSelected(option)"
        (click)="toggleChoice(option)"
      >
        {{ option | titlecase }}
      </div>
    </div>
  `,
  styleUrls: ["./choice-list.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChoiceListComponent),
      multi: true,
    },
  ],
})
export class ChoiceListComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.selctedChoice = this.value;
    }
  }
  ngOnInit(): void {
    this.selctedChoice = this.value;
  }

  @Input() label: string;
  @Input() labelClass: string;
  @Input() options: string | string[];
  @Input() value: string | string[];
  @Input() type: "checkbox" | "radio" = "checkbox";
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<string | string[]>();
  @Output() removedNewField = new EventEmitter<string | string[]>();

  public selctedChoice = null;

  onChange: (value: string[] | string) => void;
  onTouched: () => void;

  writeValue(obj: any): void {
    this.selctedChoice = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public isSelected(option): boolean {
    return this.choiceListFactory().isSelected(option);
  }

  public toggleChoice(value: string): void {
    if (this.disabled) return;
    const selection = this.choiceListFactory();
    selection.selectOption(value);
    this.onChange(this.selctedChoice);
    this.change.emit(this.selctedChoice);
    this.onTouched();
  }

  public choiceListFactory() {
    switch (this.type) {
      case "checkbox":
        return {
          isSelected: (choice) => {
            return this.selctedChoice?.includes(choice) || false;
          },
          selectOption: (choice) => {
            if (this.selctedChoice.includes(choice)) {
              this.selctedChoice = this.selctedChoice.filter(
                (type) => type != choice
              );
              this.removedNewField.emit(choice);
            } else {
              this.selctedChoice = [...this.selctedChoice, choice];
            }
          },
        };
      case "radio":
        return {
          isSelected: (choice) => {
            return this.selctedChoice == choice;
          },
          selectOption: (choice) => {
            this.selctedChoice = choice;
          },
        };
    }
  }
}
