import {
  Component,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { ZoomTransformService } from "../service/zoom-transform.service";
import { Subject } from "rxjs";

function clamp(n: number, min: number, max: number) {
  return Math.min(max, Math.max(min, n));
}

@Component({
  selector: "app-zoom-pan-container",
  templateUrl: "./zoom-pan-container.component.html",
  styleUrls: ["./zoom-pan-container.component.scss"],
  animations: [
    trigger("transformAnimation", [
      state("*", style({ transform: "{{transform}}" }), {
        params: { transform: "scale(1)" },
      }),
    ]),
  ],
})
export class ZoomPanContainerComponent implements OnInit, OnDestroy {
  private readonly zoomScale = 0.5;
  private readonly maxZoomScale = 5.0;
  private scale = 1;
  private translate: [number, number] = [0, 0];
  private translateOnPanStart: [number, number] = [0, 0];

  private ngDestroyed$ = new Subject();

  @Input("isLock") public isLock: boolean = false;

  @Output() public translateData = new EventEmitter<{
    deltaX: number;
    deltaY: number;
  }>();
  @Output() public translateStart = new EventEmitter(null);
  @Output() public zoomData = new EventEmitter<{
    newScale: number;
    zoomLocation: { xPos: number; yPos: number };
  }>();

  transformAnimationState = {
    value: null,
    params: {
      transform: "scale(1)",
    },
  };

  constructor(
    private elementRef: ElementRef,
    private _zoomTransformService: ZoomTransformService
  ) {}

  ngOnInit(): void {
    this._initailizeEvents();
  }

  private _initailizeEvents() {
    this._zoomTransformService.$zoomInImage
      .takeUntil(this.ngDestroyed$)
      .subscribe(() => {
        if (!this.isLock) return;
        this._zoomIn();
      });

    this._zoomTransformService.$zoomImage
      .takeUntil(this.ngDestroyed$)
      .subscribe((zoomData: { zoomMove; zoomLocation }) => {
        if (!this.isLock) return;
        this.setZoom(zoomData.zoomMove, zoomData.zoomLocation);
      });

    this._zoomTransformService.$zoomOutImage
      .takeUntil(this.ngDestroyed$)
      .subscribe(() => {
        if (!this.isLock) return;
        this._zoomOut();
      });

    this._zoomTransformService.$translateStart
      .takeUntil(this.ngDestroyed$)
      .subscribe(() => {
        if (!this.isLock) return;
        this.setStartTranlate();
      });

    this._zoomTransformService.$translateImage
      .takeUntil(this.ngDestroyed$)
      .subscribe((translateData: { deltaX: number; deltaY: number }) => {
        if (!this.isLock) return;
        this.setTranlate(translateData.deltaX, translateData.deltaY);
      });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  @HostListener("mousewheel", ["$event"])
  onMouseWheel(event) {
    const currentScale = this.scale;
    const zoomMove = Math.sign(event["wheelDelta"]) / 10.0;
    const newScale = this.zoomScaling(this.scale + zoomMove);
    const zoomLocation = this.getMousePosition(event);
    if (this.isLock && !this._isScaleLimit(zoomMove)) {
      return this._zoomTransformService.zoomImage({ zoomMove, zoomLocation });
    }
    this.zoomImage({ currentScale, newScale, zoomLocation });

    event.preventDefault();
  }

  private _isScaleLimit(moveScale: number): boolean {
    return (
      this.scale + moveScale > this.maxZoomScale || this.scale + moveScale < 1
    );
  }

  private getMousePosition(mouseEvent): { xPos: number; yPos: number } {
    return {
      xPos: mouseEvent.clientX - this.containerLeft,
      yPos: mouseEvent.clientY - this.containerTop,
    };
  }

  private getCenterPoint(): { xPos: number; yPos: number } {
    return {
      xPos: this.containerWidth / 2,
      yPos: this.containerHeight / 2,
    };
  }
  private get containerWidth() {
    return (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect().width;
  }

  private get containerLeft(): number {
    return (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect().left;
  }

  private get containerTop(): number {
    return (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect().top;
  }

  private get containerHeight() {
    return (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect().height;
  }

  public zoomInImage(): void {
    if (this.isLock && !this._isScaleLimit(+this.zoomScale)) {
      return this._zoomTransformService.zoomInImage();
    }
    this._zoomIn();
  }

  private _zoomIn(): void {
    const currentScale = this.scale;
    const newScale = this.zoomScaling(this.scale + this.zoomScale);
    this.zoomImage({
      currentScale,
      newScale,
      zoomLocation: this.getCenterPoint(),
    });
  }

  public zoomOutImage(): void {
    if (this.isLock && !this._isScaleLimit(-this.zoomScale)) {
      return this._zoomTransformService.zoomOutImage();
    }
    this._zoomOut();
  }

  private _zoomOut(): void {
    const currentScale = this.scale;
    const newScale = this.zoomScaling(this.scale - this.zoomScale);
    this.zoomImage({
      currentScale,
      newScale,
      zoomLocation: this.getCenterPoint(),
    });
  }

  private zoomScaling(zoomValue): number {
    return clamp(zoomValue, 1, this.maxZoomScale);
  }

  public setZoom(zoomMove, zoomLocation) {
    const newScale = this.zoomScaling(this.scale + zoomMove);
    this.zoomImage({ currentScale: this.scale, newScale, zoomLocation });
  }

  private zoomImage({ currentScale, newScale, zoomLocation }) {
    if (this.scale == newScale) return;
    this.translate = this.calculateTranslationToZoomPoint(
      currentScale,
      newScale,
      this.translate,
      zoomLocation
    );
    this.scale = newScale;

    this.updateTransformAnimationState();
  }

  private calculateTranslationToZoomPoint(
    currentScale: number,
    newScale: number,
    currentTranslation: [number, number],
    e: { xPos: number; yPos: number }
  ): [number, number] {
    const { xPos, yPos } = e;
    // kudos to this awesome answer on stackoverflow:
    // https://stackoverflow.com/a/27611642/1814576

    const xAtCurrentScale = (xPos - currentTranslation[0]) / currentScale;
    const yAtCurrentScale = (yPos - currentTranslation[1]) / currentScale;

    const xAtNewScale = xAtCurrentScale * newScale;
    const yAtNewScale = yAtCurrentScale * newScale;

    return [xPos - xAtNewScale, yPos - yAtNewScale];
  }

  private updateTransformAnimationState() {
    this.transformAnimationState = {
      value: this.scale + this.translate[0] + this.translate[1],
      params: {
        transform: `translate3d(${this.translate[0]}px, ${this.translate[1]}px, 0px) scale(${this.scale})`,
      },
    };
  }

  reset() {
    this.scale = 1;
    this.translate = [0, 0];
    this.updateTransformAnimationState();
  }

  @HostListener("panstart", ["$event"])
  onPanStart(e: Event) {
    if (this.isLock) {
      return this._zoomTransformService.translateStart();
    }

    this.setStartTranlate();
    e.preventDefault();
  }

  @HostListener("pan", ["$event"])
  onPan(e: Event & { deltaX: number; deltaY: number }) {
    if (this.isLock) {
      return this._zoomTransformService.translateImage(e.deltaX, e.deltaY);
    }

    this.setTranlate(e.deltaX, e.deltaY);
    e.preventDefault();
  }

  setStartTranlate(): void {
    this.translateOnPanStart = [...this.translate] as [number, number];
  }

  setTranlate(deltaX, deltaY): void {
    this.translate = [
      this.translateOnPanStart[0] + deltaX,
      this.translateOnPanStart[1] + deltaY,
    ];
    this.updateTransformAnimationState();
  }
}
