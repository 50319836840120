import { Action } from "@ngrx/store";
import { Labs } from "../../labs-scans/models/labs-scans";

// load lab values

export const LOAD_LAB_FORMS = "[LAB] Load Lab Forms";
export const LOAD_LAB_FORMS_FAIL = "[LAB] Load Lab Forms  Fail";
export const LOAD_LAB_FORMS_SUCCESS = "[LAB] Load Lab Forms  Success";
export const UPDATE_LAB_FORMS_SUCCESS = "[LAB] Update Lab Forms  Success";
export const LOAD_LAB_FORMS_RESET = "[LAB] Load Lab Forms Reset";
export const LOAD_LAB_FORMS_DELETE_SUCCESS =
  "[LAB] Load Lab Forms Delete Success";
export const LOAD_LAB_FORMS_DELETE = "[LAB] Load Lab Forms Delete";
export const LOAD_LAB_FORM_SUCCESS_MESSAGE =
  "[LAB] Load Lab Forms Success Messahe";

export class LoadLabForm implements Action {
  readonly type = LOAD_LAB_FORMS;
  constructor(
    public payload: {
      CPMRN: string;
      encounters: Number;
      labs: string;
    }
  ) {}
}

export class LoadLabFormFail implements Action {
  readonly type = LOAD_LAB_FORMS_FAIL;
  constructor(public payload: any) {}
}

export class LoadLabFormSuccess implements Action {
  readonly type = LOAD_LAB_FORMS_SUCCESS;
  constructor(public payload) {}
}

export class UpdateLabFormSuccess implements Action {
  readonly type = UPDATE_LAB_FORMS_SUCCESS;
  constructor(public payload) {}
}

export class LoadLabFormReset implements Action {
  readonly type = LOAD_LAB_FORMS_RESET;
  constructor(public payload) {}
}

export class LoadLabFormDeleteSuccess implements Action {
  readonly type = LOAD_LAB_FORMS_DELETE_SUCCESS;
  constructor(public payload) {}
}

export class LoadLabFormDelete implements Action {
  readonly type = LOAD_LAB_FORMS_DELETE;
  constructor(public payload) {}
}

export class LoadLabFormSuccessMessage implements Action {
  readonly type = LOAD_LAB_FORM_SUCCESS_MESSAGE;
  constructor() {}
}

export type LabFormAction =
  | LoadLabForm
  | LoadLabFormFail
  | LoadLabFormSuccess
  | LoadLabFormDeleteSuccess
  | LoadLabFormSuccessMessage
  | LoadLabFormDelete
  | UpdateLabFormSuccess
  | LoadLabFormReset;
