import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RemoveBlankEntriesPipe } from "./remove-blank-entries.pipe";

@NgModule({
  declarations: [RemoveBlankEntriesPipe],
  imports: [CommonModule],
  exports: [RemoveBlankEntriesPipe],
})
export class RemoveBlankEntriesPipeModule {}
