import { Component, ElementRef, Input, OnInit } from "@angular/core";

@Component({
  selector: "cp-readmore",
  templateUrl: "./readmore.component.html",
})
export class ReadmoreComponent implements OnInit {
  //the text that need to be put in the container
  @Input() text: string;

  @Input() maxCharectors: number | null = null;

  //set these to false to get the height of the expended container
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;
  displayText: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.trimText();
  }

  trimText() {
    this.displayText = this.text;
    const isLength = this.text.length > this.maxCharectors;
    if (!this.maxCharectors || !isLength) {
      this.displayText = this.text;
      return;
    }
    this.isCollapsable = true;
    this.displayText = this.text.slice(0, this.maxCharectors) + "...";
  }

  onReadMore() {
    this.displayText = this.text;
    this.isCollapsable = false;
  }
}
