import { Action, createReducer, on } from "@ngrx/store";
import { NcpResponse } from "../../type/ncp-data.interface";
import * as ncpCreateAction from "../action/ncp.action";

export interface State {
  error: any;
  loading: boolean;
  data: NcpResponse;
}

const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

const ncpCreateReducer = createReducer(
  initialState,

  on(
    ncpCreateAction.createNcpSuccess,

    (state, action) => {
      return { loading: false, error: null, data: action.data };
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return ncpCreateReducer(state, action);
}
