<div class="alert-cancel-box">
  <form [formGroup]="cancelReasonsForm" (ngSubmit)="onSubmit()">
    <div class="alert-cancel-text">
      <div class="alert-cancel-title">
        <div class="alert-cancel-title-warning">
          <img src="/assets/icons/warning_24px.svg" />
          <div>
            {{
              "Cancel " + (componentData.isCodeBlue ? " CodeBlue" : " eCall")
            }}
          </div>
        </div>
        <img
          src="/assets/icons/close_modal.svg"
          [style.cursor]="'pointer'"
          (click)="closeDialog()"
        />
      </div>
      <div class="alert-cancel-body">
        Please mention the reason for cancellation to continue.
      </div>
      <div class="alert-cancel-reason">
        <mat-form-field floatLabel="always" class="mat-form">
          <mat-label class="iris-label"> Reason: </mat-label>
          <input
            type="text"
            placeholder="Enter reason for cancellation or choose from the list"
            matInput
            required
            formControlName="reason"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let cancelReason of filteredCancelOptions | async"
              [value]="cancelReason"
            >
              {{ cancelReason }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="alert-cancel-confirm">
      <button
        class="button-primary"
        mat-button
        type="submit"
        [disabled]="!cancelReasonsForm.get('reason').value"
      >
        Confirm
      </button>
    </div>
  </form>
</div>
