import { Action, createAction, props } from "@ngrx/store";
import { Documents } from "../../labs-scans/models/labs-scans";

export const LOAD_DOCUMENTS = "[LAB] load documents";
export const LOAD_DOCUMENTS_SUCCESS = "[LAB] load documents success";
export const LOAD_SPOTLIGHT_SUCCESS = "[LAB] load spotlight success";

export const loadPosts = createAction(LOAD_DOCUMENTS);

export const loadPostsSuccess = createAction(
  LOAD_DOCUMENTS_SUCCESS,
  props<{ documents: any }>()
);

export const loadSpotLightRecords = createAction(
  LOAD_SPOTLIGHT_SUCCESS,
  props<{ spotlight: any }>()
);
