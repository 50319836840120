import { on, createReducer, Action } from "@ngrx/store";
import * as notificationActions from "../../actions/notifications/notifications";
import { EntityState, createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { Notification } from "src/app/models/notifications/notifications.model";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State extends EntityState<Notification> {}

export function selectNotificationId(a: Notification): string {
  return a._id;
}

export const adapter: EntityAdapter<Notification> =
  createEntityAdapter<Notification>({
    selectId: selectNotificationId,
  });

export const initialState: State = adapter.getInitialState();

const NotificationsReducer = createReducer(
  initialState,
  on(notificationActions.setNotification, (state, { notification }) => {
    return adapter.setAll(notification, state);
  }),
  on(notificationActions.updateNotification, (state, { notification }) => {
    return adapter.removeOne(notification._id, state);
  }),
  on(notificationActions.addNotification, (state, { notification }) => {
    return adapter.addOne(notification, state);
  }),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectAllNotification = selectAll;
export const selectAllNotificationIds = selectIds;
export const selectNotificationEntities = selectEntities;
export const selectNotificationCount = selectTotal;

export function reducer(state: State | undefined, action: Action) {
  return NotificationsReducer(state, action);
}
