import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PatientUrlSwitchService {
  isFamilyUser: boolean = false;
  constructor() {}

  env = environment;

  public setIsFamilyUser(isFamilyUser) {
    this.isFamilyUser = isFamilyUser;
  }

  public getPatientApiUrl(
    CPMRN = undefined,
    encounters = undefined,
    isPatientsEndpoint = true
  ) {
    let endPoint = "";
    if (this.isFamilyUser) {
      endPoint = "family/";
    } else if (isPatientsEndpoint) {
      endPoint = "patients/";
    }
    const setUrls = (param) => (param ? param + "/" : "");
    return `${
      this.env.apiUrl + endPoint + setUrls(CPMRN) + setUrls(encounters)
    }`;
  }
}
