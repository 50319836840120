<section class="vital-input-section">
  <mat-tab-group
    (selectedTabChange)="tabChanged($event)"
    mat-stretch-tabs
    animationDuration="0ms"
    [(selectedIndex)]="currentTabIndex"
  >
    <ng-container *ngIf="entryType === 'vital'">
      <mat-tab label="Vitals">
        <app-vitals-form
          *ngIf="currentTabIndex === 0"
          [clickedDay]="clickedDay"
          [currPatient]="currentPatient"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-vitals-form>
      </mat-tab>
      <mat-tab label="Vents">
        <app-vents
          *ngIf="currentTabIndex === 1"
          [clickedDay]="clickedDay"
          [currPatient]="currentPatient"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-vents>
      </mat-tab>
    </ng-container>
    <ng-container *ngIf="entryType === 'assessment'">
      <ng-container
        *ngIf="
          currentPatient?.patientType === PatientType.Neonatal;
          else adultBlock
        "
      >
        <mat-tab label="CRIB II">
          <app-crib
            *ngIf="currentTabIndex === 0"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-crib>
        </mat-tab>
        <mat-tab label="APGAR">
          <app-apgar-score
            *ngIf="currentTabIndex === 1"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-apgar-score>
        </mat-tab>
        <mat-tab label="SNAPPE II">
          <app-snapp-score
            *ngIf="currentTabIndex === 2"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-snapp-score>
        </mat-tab>
        <mat-tab label="THOMPSON">
          <app-thompson-score
            *ngIf="currentTabIndex === 3"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-thompson-score>
        </mat-tab>
      </ng-container>
      <ng-template #adultBlock>
        <mat-tab label="Pain">
          <app-pain
            *ngIf="currentTabIndex === 0"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-pain>
        </mat-tab>
        <mat-tab label="RASS">
          <app-rass
            *ngIf="currentTabIndex === 1"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-rass>
        </mat-tab>
        <mat-tab label="Fall">
          <app-fall-risk
            *ngIf="currentTabIndex === 2"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-fall-risk>
        </mat-tab>
        <mat-tab label="Braden">
          <app-braden
            *ngIf="currentTabIndex === 3"
            [clickedDay]="clickedDay"
            [currPatient]="currentPatient"
            [minDate]="minDate"
            [maxDate]="maxDate"
          ></app-braden>
        </mat-tab>
      </ng-template>
    </ng-container>
  </mat-tab-group>
</section>
