import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { CommunicationPreset } from "../../../models/preset/CommunicationPreset.model";

@Component({
  selector: "app-protocol-comm-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="comm.patientType"
        [ageGroup]="comm.patientType"
        [id]="comm._id"
      ></age-group-view>
      <div class="name"><strong>Title: </strong> {{ comm.title }}</div>

      <div class="" *ngIf="comm.numberOfDoses">
        <strong>Instances: </strong>{{ comm.numberOfDoses }}
      </div>
      <div class="" *ngIf="comm.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{ orderService.getFrequencyDisplayName(comm.frequency) }}</span>
      </div>
      <div class="" *ngIf="comm.urgency">
        <strong>Urgency: </strong>{{ comm.urgency }}
      </div>

      <div class="">
        <strong>GCS: </strong>
        <span>{{ comm.gcs }}</span>
      </div>

      <div class=" details" *ngIf="comm.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ comm.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="comm.instructions">
        <strong>Instructions</strong>:
        <em>{{ comm.instructions }}</em>
      </div>

      <div class=" details" *ngIf="comm.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ comm.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolCommViewComponent {
  @Input() comm: CommunicationPreset;

  constructor(public orderService: OrderService) {}
}
