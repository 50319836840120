export interface AssignedUserInterface {
  role: string;
  logo: string;
  name: string;
}

export interface AssignedUsersSocketInterface {
  patients: string[];
  unitIds: string[];
}

export enum SocketChannels {
  GET_ASSIGNED_USERS = "clearCacheForAssignedClinicalStaff-",
}
