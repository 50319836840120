import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";

import { UtilService } from "../../../../services/util.service";
import { CommunicationPresetBE } from "../../../../models/preset/CommunicationPreset.model";

@Component({
  selector: "app-communication-form",
  templateUrl: "./communication-form.component.html",
  styleUrls: ["./communication-form.component.scss"],
})
export class CommunicationFormComponent implements OnInit {
  @Input() value?: CommunicationPresetBE;
  @Input() config: any;
  @Input() formType: string;
  @Input() showBedsideForm = true;

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {}

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for presetName field.
   *
   * @returns {AbstractControl}
   */
  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  toggleStatus(type: string) {
    this.form.get(type).patchValue(!!!this.form.get(type).value);
  }
}
