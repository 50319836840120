<section class="iris-modal-container session-expired-wrapper">
  <div mat-dialog-title class="title">
    <mat-icon>warning</mat-icon>
    Session Expired
  </div>

  <mat-dialog-content class="content">
    Your current session has expired. Please refresh the page
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <small class="message">* Data typed in the last 5s will not be saved</small>

    <button mat-flat-button class="button-primary" (click)="refreshPage()">
      Refresh
    </button>
  </mat-dialog-actions>
</section>
