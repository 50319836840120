import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "htmlTagRemoveRegex",
})
export class HtmlTagRemoveRegexPipe implements PipeTransform {
  transform(value: string): String {
    var regex = /(<([^>]+)>)/gi;
    let message = value?.replace(regex, ""); // Replace all HTML tags
    return message?.trim();
  }
}
