import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  env = environment;

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  constructor(private http: HttpClient) { }

  getSignedUrl(CPMRN, docProps) {
    return this.http
      .post<any>(
        this.env.apiUrl + "patients/" + CPMRN + "/uploads",
        docProps,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadFileAWSS3(fileUploadUrl, contentType, file) {
    const headers = new HttpHeaders({
      "Content-Type": contentType,
      DoNotIntercept: "true"
    });

    const req = new HttpRequest("PUT", fileUploadUrl, file, {
      headers: headers,
      reportProgress: true
    });
    return this.http.request(req).pipe(catchError(this.errorHandler));
  }

  downloadImage(CPMRN, key) {
    return this.http.post(this.env.apiUrl + "patients/" + CPMRN + "/download/",
        { key: key },
        {
          observe: "response",
          responseType: "text"
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  saveFileNameToDb(obj) {
    return this.http.put<any>(this.env.apiUrl+'patients/save_summary', obj)
    .pipe(catchError(this.errorHandler));
  }
}
