<div class="dose-scheduler-comp" [formGroup]="form">
  <app-order-time
    [config]="config"
    (onStartTimeChange)="startTimeChangeHandler($event)"
    (onStartNowChange)="startNowChangeHandler($event)"
    [setTime]="startTime"
    [formType]="formType"
    [selectedUrgency]="urgency"
    [frequency]="form.get('frequency.fType').value"
    *ngIf="config.startTime.present || config.endTime.present"
  ></app-order-time>

  <div class="dose-schedule" *ngIf="skipSchedule?.controls.length">
    <span class="label">Dose schedule </span>
    <cp-timezone-label></cp-timezone-label>

    <div class="dose-list-cont" formArrayName="skipSchedule">
      <div
        class="dose-name"
        *ngFor="let scheduleControl of skipSchedule?.controls; index as i"
      >
        <div [formGroup]="skipSchedule?.controls[i]" class="schedule-control">
          <div class="time">
            {{ getDisplayTime(skipSchedule.controls[i].value.timeStamp) }}
          </div>
          <mat-checkbox
            *ngIf="
              i !== 0 ||
              checkIfSameDate(skipSchedule?.controls[i]?.value?.timeStamp)
            "
            formControlName="skip"
            class="skip-dose"
            >Skip dose</mat-checkbox
          >
        </div>
      </div>
    </div>
  </div>
</div>
