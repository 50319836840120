import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Hospital } from "../models/hospital";
import { AuthService } from "../services/auth.service";
import { UtilService } from "../services/util.service";
import { CustomValidators } from "../iris-components/validators/custom-validators";
import { SplashScreenService } from "src/app/services/splash-screen.service";
import { userRoles } from "../shared/accessControl/roles";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  public registerUserData = {
    title: String,
    name: String,
    qualification: String,
    email: String,
    password: String,
    role: String,
    code: String,
    hospitals: Array,
    phone: String,
  };
  public email = "";
  public password = "";
  public rRegisterForm: UntypedFormGroup;
  public nameAlert = "Name cannot be empty";
  public emailAlert = "Invalid email address";
  public passwordAlert = "Password required";
  public duplicateError;
  public codeError;
  public hospitals: Hospital[];

  public user = JSON.parse(localStorage.getItem("currentUser"));
  public authorType = ["Select Role", ...userRoles];
  @Input() hospTab; // hospital-admin module tabset

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private SplashScreenService: SplashScreenService,
    private route: ActivatedRoute,
    public utilService: UtilService
  ) {
    this.rRegisterForm = fb.group({
      title: [null],
      name: [null, Validators.required],
      qualification: [null, Validators.maxLength(50)],
      email: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true,
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            }
          ),
          Validators.minLength(8),
        ]),
      ],
      role: [
        "Select Role",
        Validators.pattern(
          /System Administrator|Medical Administrator|Super Billing Administrator|Billing Administrator|Dietitian|Nurse|Pharmacologist|Physician|Tv|Tv-Covid|Auditor|R-Alert|Lab Technician|Documentation Specialist/
        ),
      ],
      code: [null, Validators.required],
      hospitals: [null, Validators.required],
      phone: [
        null,
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^[1-9]\d{9}$/),
        ],
      ],
    });
  }

  ngOnInit() {
    if (this._auth.isLoggedIn()) {
      if (!(this.user && this.user.role == "System Administrator")) {
        this._router.navigate(["/patientlist"]);
      }
    }

    this.SplashScreenService.stopLoading();

    this.route.data.subscribe(
      (data: { hospitals: Hospital[] }) => (this.hospitals = data.hospitals)
    );
  }

  get passwordField(): AbstractControl {
    return this.rRegisterForm.get("password");
  }

  registerUser(userInfo) {
    this.registerUserData.title = userInfo.title;
    this.registerUserData.name = userInfo.name;
    this.registerUserData.qualification = userInfo.qualification;
    this.registerUserData.email = userInfo.email;
    this.registerUserData.password = userInfo.password;
    this.registerUserData.role = userInfo.role;
    this.registerUserData.code = userInfo.code;
    this.registerUserData.hospitals = userInfo.hospitals;
    this.registerUserData.phone = userInfo.phone;
    this._auth.registerUser(this.registerUserData).subscribe(
      (res) => {
        if (res.response == "success") {
          if (!(this.user && this.user.role == "System Administrator")) {
            this._router.navigate(["/login"]);
          } else {
            this.hospTab.select("manageUsers");
          }
        } else {
          this.duplicateError = res.message;
        }
      },
      (err) => {
        console.log(err);
        this.duplicateError = err.error.message || "";
      }
    );
  }

  isPasswordValid(check: string): boolean {
    return (
      !this.utilService.hasError(this.rRegisterForm, "password", "required") &&
      !this.utilService.hasError(this.rRegisterForm, "password", check)
    );
  }
}
