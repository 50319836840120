import { createSelector } from "@ngrx/store";
import * as fromTrendsReducer from "../reducers";
import * as fromLabTrendsReducer from "../reducers/lab-trends.reducer";

export const getLabTrendSelector = createSelector(
  fromTrendsReducer.getTrendsState,
  (state) => state.labTrends
);

export const {
  selectAll: getAllLabTrendsData,
  selectEntities: getAllLabTrendsDictionay,
  selectIds: getAllLabTrendsNames,
} = fromLabTrendsReducer.adapter.getSelectors(getLabTrendSelector);
