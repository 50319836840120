import { Action } from "@ngrx/store";
import { Addendum, Notes, NotesDraft } from "../../model/notes.model";

// Load final notes

export const LOAD_NOTE_SUCCESS = "[Patient Notes Module] Load Notes Success";
export const LOAD_NOTE_DRAFT_SOCKET_SUCCESS =
  "[Patient Notes Module] Load Notes Draft Socket Success";
export const LOAD_NOTE_FINAL_SOCKET_SUCCESS =
  "[Patient Notes Module] Load Notes final Socket Success";

export const CREATE_NOTE = "[Patient Notes Module] Create Notes";
export const CREATE_NOTE_SUCCESS =
  "[Patient Notes Module] Create Notes Success";
export const CREATE_NOTE_FAIL = "[Patient Notes Module] Create Notes Fail";

export const NOTE_AUTO_SAVE = "[Patient Notes Module] Note Auto Save";
export const NOTE_AUTO_SAVE_SUCCESS =
  "[Patient Notes Module] Note Auto Save Success";
export const NOTE_AUTO_SAVE_FAIL = "[Patient Notes Module] Note Auto Save Fail";

export const NOTE_UPDATE = "[Patient Notes Module] Note Update";
export const NOTE_UPDATE_SUCCESS = "[Patient Notes Module] Note Update Success";
export const NOTE_UPDATE_FAIL = "[Patient Notes Module] Note Update Fail";

export const NOTE_DELETE = "[Patient Notes Module] Note Delete";
export const NOTE_DELETE_SUCCESS = "[Patient Notes Module] Note Delete Success";
export const NOTE_DELETE_FAIL = "[Patient Notes Module] Note Delete Fail";
export const NOTE_DELETE_PENDED_SUCCESS =
  "[Patient Notes Module] Note Delete Pended Success";
export const ATTEST_NOTE = "[Patient Notes Module] Note Attest";
export const ATTEST_NOTE_SUCCESS = "[Patient Notes Module] Note Attest Success";
export const ATTEST_NOTE_FAIL = "[Patient Notes Module] Note Attest Fail";

export const NOTE_TAB_REFRESH = "[Patient Notes Module] Note Refresh Tab";

export class LoadNote implements Action {
  readonly type = LOAD_NOTE_SUCCESS;
  constructor(public payload: Notes) {}
}

export class LoadNoteDraftSocket implements Action {
  readonly type = LOAD_NOTE_DRAFT_SOCKET_SUCCESS;
  constructor(public payload: Notes) {}
}
export class LoadNoteFinalSocket implements Action {
  readonly type = LOAD_NOTE_FINAL_SOCKET_SUCCESS;
  constructor(public payload: Notes) {}
}

export class CreateNote implements Action {
  readonly type = CREATE_NOTE;
  constructor(
    public payload: { CPMRN: string; encounters: Number; notes: Notes }
  ) {}
}

export class CreateNoteSucess implements Action {
  readonly type = CREATE_NOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateNoteFail implements Action {
  readonly type = CREATE_NOTE_FAIL;
  constructor(public payload: any) {}
}

export class NoteAutoSave implements Action {
  readonly type = NOTE_AUTO_SAVE;
  constructor(
    public payload: {
      CPMRN: string;
      encounters: Number;
      draftNotes: NotesDraft;
    }
  ) {}
}

export class NoteAutoSaveSucess implements Action {
  readonly type = NOTE_AUTO_SAVE_SUCCESS;
  constructor(public payload: any) {}
}

export class NoteAutoSaveFail implements Action {
  readonly type = NOTE_AUTO_SAVE_FAIL;
  constructor(public payload: any) {}
}

export class NoteDelete implements Action {
  readonly type = NOTE_DELETE;
  constructor(
    public payload: {
      CPMRN: string;
      encounters: Number;
      refId: string;
    }
  ) {}
}

export class AttestNote implements Action {
  readonly type = ATTEST_NOTE;
  constructor(
    public payload: {
      CPMRN: string;
      encounters: Number;
      impactCase: boolean;
      chargeable: boolean;
      refId: string;
      cosign: object;
      addendumArray: Addendum[];
    }
  ) {}
}

export class AttestNoteSuccess implements Action {
  readonly type = ATTEST_NOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class AttestNoteFail implements Action {
  readonly type = ATTEST_NOTE_FAIL;
  constructor(public payload: any) {}
}
export class NoteDeletePendedSuccess implements Action {
  readonly type = NOTE_DELETE_PENDED_SUCCESS;
  constructor(
    public payload: {
      refId: string;
    }
  ) {}
}

export class NoteDeleteSucess implements Action {
  readonly type = NOTE_DELETE_SUCCESS;
  constructor(public payload: any) {}
}

export class NoteDeleteFail implements Action {
  readonly type = NOTE_DELETE_FAIL;
  constructor(public payload: any) {}
}

export class RefreshTabStatus implements Action {
  readonly type = NOTE_TAB_REFRESH;
  constructor(public payload: any) {}
}

export class UpdateNote implements Action {
  readonly type = NOTE_UPDATE;
  constructor(
    public payload: {
      CPMRN: string;
      encounters: Number;
      notes: Notes;
      refId: string;
    }
  ) {}
}

export class UpdateNoteSucess implements Action {
  readonly type = NOTE_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateNoteFail implements Action {
  readonly type = NOTE_UPDATE_FAIL;
  constructor(public payload: any) {}
}

export type NoteAction =
  | LoadNote
  | CreateNote
  | CreateNoteSucess
  | CreateNoteFail
  | LoadNoteDraftSocket
  | LoadNoteFinalSocket
  | NoteAutoSave
  | NoteAutoSaveSucess
  | NoteAutoSaveFail
  | NoteDelete
  | NoteDeleteSucess
  | NoteDeleteFail
  | RefreshTabStatus
  | AttestNote
  | AttestNoteSuccess
  | AttestNoteFail
  | UpdateNote
  | UpdateNoteFail
  | UpdateNoteSucess;
