import { createAction, props } from '@ngrx/store';
import { OrderableRequestAcceptRequest } from '../../../models/orderable/OrderableRequest.model';

export const getOrderableRequests = createAction(
  '[Orderable Request Page] Get Orderable Requests'
);

export const clickAcceptOrderableRequest = createAction(
  '[Orderable Request Page] Click Accept Orderable Request',
  props<{ id: string, data: OrderableRequestAcceptRequest, listType: string }>()
);

export const clickRejectOrderableRequest = createAction(
  '[Orderable Request Page] Click Reject Orderable Request',
  props<{ id: string }>()
);
