<section class="snapp-score-container input-form">
  <form
    class="iris-form snapp-form"
    autocomplete="off"
    [formGroup]="snappForm"
    (ngSubmit)="submitSnapp(snappForm.value)"
  >
    <div class="sub-tittle">SNAPPE II Score</div>
    <hr />
    <div class="input-form-header">
      <div class="score">
        <div>
          {{ snappe2Score ?? "NA" }}
        </div>
      </div>
    </div>

    <div class="form-fields">
      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Mean blood pressure*:</mat-label>
            <mat-select formControlName="meanBP">
              <mat-option
                *ngFor="let listOption of meanBloodPressureList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Lowest temperature (F)*:</mat-label>
            <mat-select formControlName="lowestTemp">
              <mat-option
                *ngFor="let listOption of lowestTempList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>PO<sub>2</sub>(mmHg)/FiO<sub>2</sub> (%)*:</mat-label>
            <mat-select formControlName="po2Fio2Ratio">
              <mat-option
                *ngFor="let listOption of po2Fio2List"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Lowest serum pH*:</mat-label>
            <mat-select formControlName="lowestSerumPh">
              <mat-option
                *ngFor="let listOption of lowestSerumList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Multiple seizures*:</mat-label>
            <mat-select formControlName="multipleSeizures">
              <mat-option
                *ngFor="let listOption of multipleSeizuresList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Urine output (mL/kg.h)*:</mat-label>
            <mat-select formControlName="urineOutput">
              <mat-option
                *ngFor="let listOption of urineOutputList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="display-flex justify-content-start"
        style="
          font-size: 16px;
          gap: 8px;
          margin-top: -1.5rem;
          margin-bottom: 24px;
        "
      >
        <div class="label primary">SNAP II:</div>
        <div class="value">{{ snap2Score ?? "NA" }}</div>
      </div>

      <div class="iris-v-row mt-4">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>APGAR score*:</mat-label>
            <mat-select formControlName="apgarScore">
              <mat-option
                *ngFor="let listOption of apgarScoreList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Birth weight*:</mat-label>
            <mat-select formControlName="birthWeight">
              <mat-option
                *ngFor="let listOption of birthWeightList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Gestational age*:</mat-label>
            <mat-select formControlName="gestationalAge">
              <mat-option *ngFor="let gAge of gesationAgeList" [value]="gAge">
                {{ gAge }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Small for gestational age*:</mat-label>
            <mat-select formControlName="smallForGestationalAge">
              <mat-option
                *ngFor="let listOption of smallForGestationAgeList"
                [value]="listOption.value"
              >
                {{ listOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="display-flex justify-content-start"
        style="font-size: 16px; gap: 8px; margin-top: -1.5rem"
      >
        <div class="label primary">Expected death:</div>
        <div class="value">{{ expectedDeath || "NA" }}</div>
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!snappForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'snappe-2'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
