import * as fromUploadFiles from "../actions/upload-file.action";

export interface VisionTaskStatus {
  entities: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: VisionTaskStatus = {
  entities: null,
  loaded: false,
  loading: false,
};

export function reducer(state = initialState, action): VisionTaskStatus {
  switch (action.type) {
    case fromUploadFiles.VISION_TASK_INIT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromUploadFiles.VISION_TASK_INIT_SUCCESS: {
      const entities = action.payload.output;
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromUploadFiles.VISION_TASK_INIT_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case "[Root Actions] patient chart closed": {
      return initialState;
    }
    case fromUploadFiles.UPLOAD_FILE_RESET: {
      return initialState;
    }
  }

  return state;
}

export const getVisionEntities = (state: VisionTaskStatus) => state.entities;
export const getVisionLoading = (state: VisionTaskStatus) => state.loading;
export const getVisionLoaded = (state: VisionTaskStatus) => state.loaded;
