import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterUnit",
})
//forfiltering units having time
export class FilterUnitPipe implements PipeTransform {
  private timeUnits = ["/hr", "/min", "/day", "/s"];
  transform(value: any, args?: any): unknown {
    const validateTimeUnit = (value) => {
      for (const unit of this.timeUnits) {
        if (value.includes(unit)) return true;
      }
      return false;
    };
    if (args.value == null || !value) return value;
    else {
      if (validateTimeUnit(args.value)) {
        return value.filter((it) => {
          return validateTimeUnit(it);
        });
      } else {
        return value;
      }
    }
  }
}
