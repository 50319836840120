import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  fileType,
  FileTypes,
  supportedFiles,
} from "src/app/models/file/File.model";
import { FileService } from "src/app/services/file.service";
import {
  AttachmentLinkInterface,
  UploadFileInterface,
} from "../../models/chat.model";

const fileValidationErrors = {
  type: "Invalid file type - Only pdf, png, jpeg, jpg, m4v, mp4, webm, ogv are allowed",
  duration: "Video duration cannot be greater than 2 minutes",
  size: "File size is larger than 20MB",
};

const validFileMime = supportedFiles;

const validFileSize = 20971520;

@Component({
  selector: "cp-file-upload",
  templateUrl: "./file-upload.component.html",
})
export class FileUploadComponent {
  fileTypes: any = FileTypes;
  isUploadLoader: boolean = false;
  @Output() uploadStart: EventEmitter<UploadFileInterface> =
    new EventEmitter<UploadFileInterface>();
  @Output() uploadedData: EventEmitter<UploadFileInterface> =
    new EventEmitter<UploadFileInterface>();
  @Output() fileValidationError: EventEmitter<string> =
    new EventEmitter<string>();
  attachmentLink: AttachmentLinkInterface = {
    key: "",
    name: "",
    size: "",
    fileType: "",
  };
  public attachNames: string = "";

  public validationErrors: string | null = null;

  maxVideoDuration: number = 120; //in seconds

  constructor(
    private _fileService: FileService,
    private sanitisor: DomSanitizer
  ) {}

  public attchObj: any = [];

  @Input("isDischargeTimeElapsed") isDischargeTimeElapsed: boolean = false;

  async upload(files) {
    this.uploadStart.emit();
    try {
      await this.fileList(files);
    } catch (err) {
      console.error(err);
    }
  }

  public async fileList(files): Promise<void> {
    this.resetErrorMessages();
    let attchObj: UploadFileInterface = null;
    let errorString = "";
    let doc = files[0];
    const isVideo = this.getFileType(doc.type) == FileTypes.VIDEO;
    attchObj = {
      id: Date.now(),
      file: doc,
      name: doc["name"],
      type: doc["type"],
      size: doc["size"],
      fileUrl: "",
      isFileLoading: true,
    };
    this.uploadStart.emit(attchObj);
    const duration = await this.getVideoDuration(doc);
    const durationExceeded =
      duration != null && duration > this.maxVideoDuration;
    if (validFileMime.indexOf(doc["type"]) === -1) {
      errorString = fileValidationErrors.type;
    } else if (durationExceeded) {
      errorString = fileValidationErrors.duration;
    } else if (doc["size"] > validFileSize && !isVideo) {
      errorString = fileValidationErrors.size;
    } else {
      this.resetErrorMessages();
      let reader = new FileReader();
      reader.readAsDataURL(doc);
      reader.onload = async (_event) => {
        this.onFileLoad({ reader, attchObj, isVideo, doc });
      };
    }
    if (errorString) {
      this.fileValidationError.emit(errorString);
      this.uploadStart.emit({ ...attchObj, isFileLoading: false });
    }
  }

  public onFileLoad({ reader, attchObj, isVideo, doc }): void {
    let result: any = reader.result;
    attchObj = {
      ...attchObj,
      fileUrl: result,
      isFileLoading: isVideo,
    };

    if (doc["type"].split("/")[1] === "pdf") {
      attchObj["fileUrl"] =
        this.sanitisor.bypassSecurityTrustResourceUrl(result);
    }
    this.uploadedData.emit(attchObj);
  }

  getVideoDuration(file: any): Promise<number | null> {
    return new Promise((resolve) => {
      if (this.getFileType(file.type) != FileTypes.VIDEO) {
        resolve(null);
        return;
      }
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.src = URL.createObjectURL(file);
    });
  }

  resetErrorMessages() {
    this.fileValidationError.emit("");
  }

  public getFileType(type: string): fileType {
    return this._fileService.getFileType(type);
  }
}
