import { Pipe, PipeTransform } from "@angular/core";
import { WorkSpace } from "src/app/models/workspace.model";
import { UnitListFilterPipe } from "./unit-list-filter.pipe";

@Pipe({
  name: "isWorkspaceEmpty",
})
export class IsWorkspaceEmptyPipe implements PipeTransform {
  constructor(private _unitListFilterPipe: UnitListFilterPipe) {}

  transform(workspaces: WorkSpace[], ...args): boolean {
    const filterText = args[0].toLowerCase();
    const unitIdMapping = args[1];
    const shift = args[2];
    if (!filterText.length) return false;
    return workspaces
      .filter((unit) => unit?.shift === shift || !unit?.shift)
      .every((workspace) => {
        return this._unitListFilterPipe
          .transform(workspace, filterText, unitIdMapping)
          .every((unit) => unit.isHidden);
      });
  }
}
