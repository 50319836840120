import { Component, Input, OnInit } from "@angular/core";
import { NcpList } from "../../type/ncp-data.interface";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as fromNcpSelector from "../../store/selector/ncp.selector";
import * as fromNcpAction from "../../store/action/ncp.action";
import * as fromNcpResetAction from "../../store/action/reset.action";
import { DatePipe } from "@angular/common";
import moment from "moment";

@Component({
  selector: "app-ncp-input-form",
  templateUrl: "./ncp-input-form.component.html",
  styleUrls: ["./ncp-input-form.component.scss"],
})
export class NcpInputFormComponent implements OnInit {
  @Input() currentPatient = null;

  ngDestroyed$ = new Subject();
  public loader: boolean = false;
  ncpList: NcpList[];
  isEdit: boolean = false;
  @Input()
  set _data(data: NcpList) {
    this.buildForm();
  }

  get ncpCreateSuccess$() {
    return this.store.pipe(select(fromNcpSelector.getNcpCreateSuccessData));
  }

  public data: NcpList[];
  private unsubscribe$: Subject<any> = new Subject<any>();
  get data$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpListData),
      takeUntil(this.unsubscribe$)
    );
  }

  form: FormGroup;

  date = this.fb.group({
    ncpDateAndTime: new UntypedFormControl("", [Validators.required]),
  });

  get ncpEditList$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpEditListData),
      takeUntil(this.unsubscribe$)
    );
  }
  get ncpIsEDitByAdd$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpIsEDitByAdd),
      takeUntil(this.unsubscribe$)
    );
  }

  constructor(
    public fb: FormBuilder,
    private store: Store<any>,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {}
  buildForm() {
    //to check if data is add by editing
    this.ncpIsEDitByAdd$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data) => {
        this.isEdit = data;
      });
    this.ncpEditList$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
      this.ncpList = data;
      //if already data is Added(edit) and newly added data is there will take both to this.data array.
      this.data$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
        this.data = data;
      });

      if (this.ncpList && this.ncpList?.length > 0 && !this.isEdit) {
        this.data = [...this.ncpList, ...this.data];
      } else {
        this.data$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
          this.data = data;
        });
      }

      this.form = this.fb.group({
        entries: this.fb.array([]),
      });

      const entriesArray = this.form.get("entries") as FormArray;

      this.data.forEach((entry) => {
        const entryGroup = this.fb.group({
          _id: [entry?._id],
          createdAt: [entry.createdAt],
          diagnose: [entry.diagnose],
          fields: this.fb.group({}),
        });

        const fieldsGroup = entryGroup.get("fields") as FormGroup;

        let transformedArray = entry.fields;
        // getting as key and value and setting key as formcontrol and value as formcontrols value

        const outputArray = Object.entries(transformedArray).map(
          ([key, value]) => ({ [key]: value })
        );

        outputArray.forEach((params) => {
          let key = Object.keys(params);
          let value = Object.values(params);
          fieldsGroup.addControl(key[0], new FormControl(value[0]));
        });

        entriesArray.push(entryGroup);
      });

      // populating the current time on edit
      this.date.get("ncpDateAndTime").setValue(moment());
    });
  }
  //for checking if all the values of parameters are empty
  get isButtonDisabled(): boolean {
    const fieldsGroup = this.form.get("entries") as FormArray;

    // Check if all fields groups have all fields empty
    return fieldsGroup.controls.every((entryGroup: FormGroup) => {
      const fields = entryGroup.get("fields") as FormGroup;
      return Object.values(fields.controls).every(
        (control) => control.value === ""
      );
    });
  }
  clearAll() {
    this.form.get("entries")["controls"].forEach((element) => {
      element.get("fields").reset();
    });
  }

  onSubmit() {
    //converting date to UTC format @sheethalkhan  08-07-2023
    const dateAndTime = this.datePipe.transform(
      this.date.get("ncpDateAndTime").value,
      "yyyy-MM-ddTHH:mm:ss.SSS'Z'",
      "UTC"
    );

    const entriesArray = this.form.get("entries") as FormArray;
    entriesArray.controls.forEach((entry) => {
      entry.get("createdAt").setValue(dateAndTime);
    });

    this.loader = true;

    // edit payload
    const updatePayload = this.form.controls.entries.value
      .filter((item) => item._id !== null)
      .map((item) => ({
        planId: item._id,
        fields: item.fields,
      }));
    // add payload
    const formArray = this.form.controls.entries.value
      .filter((item) => item._id == null)
      .map((item) => {
        const { _id, ...rest } = item;

        return {
          groupId: this.form.controls.entries.value[0]._id,
          ...rest,
        };
      });

    if (updatePayload.length > 0) {
      this.store.dispatch(
        fromNcpAction.updateNcp({
          data: updatePayload,
          cpmrn: this.currentPatient.CPMRN,
          encounter: this.currentPatient.encounters,
        })
      );
    }

    if (formArray.length > 0) {
      this.store.dispatch(
        fromNcpAction.createNcp({
          data: formArray,
          cpmrn: this.currentPatient.CPMRN,
          encounter: this.currentPatient.encounters,
        })
      );
    }
    setTimeout(() => {
      this.ncpCreateSuccess$
        .pipe(takeUntil(this.ngDestroyed$))
        .subscribe((data) => {
          this.loader = false;
          if (data) {
            this.store.dispatch(
              fromNcpAction.fetchNcpData({
                cpmrn: this.currentPatient.CPMRN,
                encounter: this.currentPatient.encounters,
              })
            );
            //resetting both edit and add data
            this.store.dispatch(fromNcpAction.resetState());
            this.store.dispatch(fromNcpResetAction.resetState());
          }
        });
    }, 500);
  }
}
