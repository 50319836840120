import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
} from "@angular/forms";
import { CrossFieldErrorMatcher } from "src/app/shared/validators/cross-field-error.validator";

import { padStart } from "lodash-es";
import { UtilService } from "../../../../services/util.service";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { OrderFormService } from "src/app/services/order-form.service";
import { TimezoneService } from "src/app/services/timezone.service";

@Component({
  selector: "app-order-time",
  templateUrl: "./order-time.component.html",
  styleUrls: ["./order-time.component.scss"],
})
export class OrderTimeComponent implements OnInit {
  startNowChecked: boolean;
  faCalendarAlt = faCalendarAlt;
  dateRangeError = new CrossFieldErrorMatcher("endDateLesser");
  private _tz = inject(TimezoneService);
  checked: boolean = true;
  public minTime: string = null;
  public maxTime: string = null;
  @Input() config: any;
  @Input() formType: string;
  @Input() frequency: string;

  private _selectedUrgency: string;
  @Input()
  set selectedUrgency(value: string) {
    this.cdr.detectChanges();
    if (this.formType == "new") {
      if (value == "STAT") {
        this.startNow.setValue(true);
        this.onStartNow(true);
        this.setStartDate();
        this.disableStartNow();
        this.startTime.disable();
      } else {
        this.startTime.enable();
        this.startNow.enable();
      }
    }
  }
  get selectedUrgency() {
    return this._selectedUrgency;
  }

  private _setTime;
  @Input() set setTime(time: Date) {
    this._setTime = new Date(time);
    if (time && this.startTime.enabled) {
      this.setStartDate(time);
    } else if (this.formType === "new" && this.form.dirty) {
      (this.form.get("skipSchedule") as UntypedFormArray).clear();
    }
  }
  get setTime() {
    return this._setTime;
  }

  @Output() onStartTimeChange: EventEmitter<string> =
    new EventEmitter<string>();

  @Output() onStartNowChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  get form() {
    // set validator here so that it doesn't get over ridden later
    // this.controlContainer.control.setValidators(this.utilService.endTimeValidator);
    return this.controlContainer.control;
  }

  get startTime(): AbstractControl {
    return this.form.get("startTime");
  }

  get endTime(): AbstractControl {
    return this.form.get("endTime");
  }

  get startNow(): AbstractControl {
    return this.form.get("startNow");
  }

  min = {};
  get minDate() {
    return (this.min = {
      year:
        this.startTime.value &&
        this.startTime.value.date &&
        this.startTime.value.date.getFullYear(),
      month:
        this.startTime.value &&
        this.startTime.value.date &&
        this.startTime.value.date.getMonth() + 1,
      day:
        this.startTime.value &&
        this.startTime.value.date &&
        this.startTime.value.date.getDate(),
    });
  }

  constructor(
    public utilService: UtilService,
    private _formService: OrderFormService,
    public controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.minTime = this._formService.getMinTime();
    this.maxTime = this._formService.getMaxTime();
    if (this.formType === "edit" && this.startTime.enabled) {
      this.setStartDate();
    }
  }

  get startTimeStatus() {
    return this.form?.get("startTime")?.disabled || false;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  disableStartNow() {
    this.startNow.disable({ emitEvent: false });
  }

  onStartTimeHourMinuteChange() {
    const hour = this.startTime.get("hour").value;
    const minute = this.startTime.get("minute").value;
    const date = this._tz
      .transformIntoTimezoneObj(this.startTime.get("date").value || new Date())
      .hours(hour)
      .minutes(minute);
    this.form.patchValue({
      startTime: {
        date: date,
        hour: padStart(+hour, 2, 0),
        minute: padStart(+minute, 2, 0),
      },
    });

    this.startNow.setValue(false);
    this.onStartNow(false);

    this.onStartTimeChange.emit(date.toISOString());
  }

  onStartDateChange(event) {
    if (event.value != new Date()) {
      this.checked = false;
    } else {
      this.checked = true;
    }
    const tzDateTime = this._tz.startOfDay(event.value).toLocaleString();
    this.startNow.setValue(false);
    this.onStartNow(false);
    this.onStartTimeChange.emit(tzDateTime);
  }

  /**
   * Updates startTime to current date and time
   */
  onStartNow(startNow: boolean): void {
    if (startNow == true) {
      this.checked = true;
    } else {
      this.checked = false;
    }
    this.startNowChecked = startNow;
    this.onStartNowChange.emit(startNow);
  }

  setStartDate(newDate: Date = new Date()) {
    const dateObj = new Date(newDate);
    let today = this._tz.transformIntoTimezoneObj(dateObj);

    if (!this.startNowChecked) {
      if (this.setTime) {
        today = this._tz.transformIntoTimezoneObj(this.setTime);
      } else {
        today.startOf("day");
      }
    }
    this.form.patchValue({
      startTime: {
        date: today,
        hour: padStart(today.hours(), 2, 0),
        minute: padStart(today.minutes(), 2, 0),
      },
    });

    this.onStartTimeChange.emit(today.toString());
  }

  resetDates() {
    this.startTime.reset();
    this.endTime.reset();
    this.startNow.reset();
    this.onStartTimeChange.emit(null);
  }

  setPaddedValue(value, parentControl, childControl) {
    let today = new Date();
    let hour = padStart(today.getHours(), 2, 0);
    let minute = padStart(today.getMinutes(), 2, 0);
    if (childControl === "hour") {
      if (value != hour) {
        this.checked = false;
      } else {
        this.checked = true;
      }
      value = +value % 24;
    }
    if (childControl === "minute") {
      if (value != minute) {
        this.checked = false;
      } else {
        this.checked = true;
      }
      value = +value % 60;
    }

    this.form
      .get(parentControl)
      .get(childControl)
      .setValue(padStart(value, 2, 0));
  }
}
