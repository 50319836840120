import { Action, createReducer, on } from "@ngrx/store";
import { OrderPaneActions } from "../../../actions/order";
import { OrderableApiActions } from "../../../actions/orderable";

export interface State {
  error: string | null;
  loading: boolean;
  completed: boolean;
}

export const initialState: State = {
  error: null,
  loading: false,
  completed: false,
};

const orderableRequestFormReducer = createReducer(
  initialState,
  on(OrderPaneActions.submitOrderableRequest, (state) => ({
    error: null,
    loading: true,
    completed: false,
  })),
  on(OrderableApiActions.submitOrderableRequestSuccess, (state) => ({
    error: null,
    loading: false,
    completed: true,
  })),
  on(OrderableApiActions.submitOrderableRequestFailure, (state, { error }) => ({
    loading: false,
    error,
    completed: false,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return orderableRequestFormReducer(state, action);
}

export const getOrderableRequestError = (state: State) => state.error;

export const getOrderableRequestLoading = (state: State) => state.loading;

export const getOrderableRequestCompleted = (state: State) => state.completed;
