import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

function checkDrain(control: AbstractControl): { [key: string]: any } | null {
  const cat = control.get("category");
  const site = control.get("site");
  const name = control.get("name");
  const lat = control.get("laterality");

  let errObj = {};

  if (
    (cat.value == "drain" ||
      cat.value == "procedure" ||
      cat.value == "dialysis") &&
    name.value == ""
  ) {
    errObj["nameRequired"] = true;
  }
  if ((cat.value == "drain" || cat.value == "procedure") && site.value == "") {
    errObj["siteRequired"] = true;
  }
  if (cat.value == "drain" && lat.value == "") {
    errObj["latRequired"] = true;
  }

  // check if errObj is empty
  if (Object.keys(errObj).length === 0) {
    return null;
  } else {
    return errObj;
  }
}

function checkCat(control: AbstractControl): { [key: string]: any } | null {
  const iCat = control.get("category");
  const sub = control.get("subCategory");

  let errObj = {};

  if (iCat.value != "others" && (iCat.value == "" || sub.value == "")) {
    errObj["subRequired"] = true;
  }

  // check if errObj is empty
  if (Object.keys(errObj).length === 0) {
    return null;
  } else {
    return errObj;
  }
}

function checkMinutes(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minutesExceed =
      parseInt(control.value) > 60 || parseInt(control.value) < 1;
    return minutesExceed ? { exceeded: { value: control.value } } : null;
  };
}

export { checkDrain, checkCat, checkMinutes };
