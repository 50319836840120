import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { InfectionBundleActionReducerState } from "../state";
import * as fromQuestionReducer from "./infections-questions.reducer";
import * as fromInfectionBundleReducer from "./infection-bundle.reducer";

export const reducer: ActionReducerMap<InfectionBundleActionReducerState> = {
  question: fromQuestionReducer.reducer,
  infectionBundle: fromInfectionBundleReducer.reducer,
};

export const getInfectionBundleState =
  createFeatureSelector<InfectionBundleActionReducerState>("infection-bundle");
