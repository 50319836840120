import { Mar } from "@mar/models/mar.model";
import { createAction, props } from "@ngrx/store";

export const setMarSOS = createAction(
  "[MAR] Set MAR - SOS medication",
  props<{ mar: Mar[] }>()
);

export const upsertMarSOS = createAction(
  "[MAR] Upsert MAR - SOS medication",
  props<{ mar: Mar[] }>()
);

export const resetMarSOS = createAction(
  "[MAR] Reset to initial state - SOS medication"
);
