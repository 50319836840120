import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { UtilService } from '../../../../services/util.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './detail.component.html',
  styleUrls: [ './detail.component.scss' ]
})
export class DetailComponent {
  @Input() value?: any;

  constructor(public utilService: UtilService,
              public controlContainer: ControlContainer) {
  }

  get form() {
    return this.controlContainer.control;
  }

  get instructions(): any {
    return this.form.get('instructions');
  }

  get additionalInformation(): any {
    return this.form.get('additionalInformation');
  }
}
