import { createAction, props } from "@ngrx/store";
import { Mar, Schedule } from "@mar/models/mar.model";

// <---- Common ---->

export const getMar = createAction(
  "[MAR API] Fetch all MAR data",
  props<{ cpmrn: string; encounter: number }>()
);

export const updateMarSchedule = createAction(
  "[MAR Schedule API] Update MAR schedule",
  props<{ marId: string; schedule: Schedule }>()
);

// <---- Discontinued MAR ---->

export const getDischargedMar = createAction(
  "[MAR API] Fetch all discontinued MAR data",
  props<{ cpmrn: string; encounter: number; marType?: string }>()
);

export const setInactiveMar = createAction(
  "[MAR] Set Inactive mar data",
  props<{ mar: any }>()
);

export const upsertInactiveMar = createAction(
  "[MAR] Upsert Inactive mar data",
  props<{ mar: Object }>()
);

export const setInactiveMarByType = createAction(
  "[MAR] Set Inactive mar data by type",
  props<{ mar: Mar[]; marType: string }>()
);

export const resetInactiveMar = createAction("[MAR] reset to initiale");
