import { createSelector } from "@ngrx/store";
import { NoteFormState, getNoteState } from "../reducers";
import { noteTypeAdaptor } from "../reducers/final-notes.reducers";
import { DraftNotes, FinalNotes } from "@notes-view/model/notes-model";

export const selectDraftNoteContent = createSelector(
  getNoteState,
  (state: NoteFormState) => state.draftNotes
);

export const { selectEntities: getDraftNoteEntities } =
  noteTypeAdaptor.getSelectors(selectDraftNoteContent);

export const { selectIds: getDraftNoteNoteById } = noteTypeAdaptor.getSelectors(
  selectDraftNoteContent
);

export const { selectAll: getAllDraftNotes, selectTotal: getDraftNoteCount } =
  noteTypeAdaptor.getSelectors(selectDraftNoteContent);

export const draftNoteSelected = (refId: string) =>
  createSelector(getAllDraftNotes, (notes) =>
    notes.find((note) => note.refId === refId)
  );

export const selectLatestNote = createSelector(getAllDraftNotes, (notes) =>
  notes.length ? getLatestNoteByTimestamp(notes) : null
);

function getLatestNoteByTimestamp(draftNotes: FinalNotes[]): FinalNotes {
  return draftNotes
    .map((note) => ({
      ...note,
      timestamp: new Date(note.timestamp),
    }))
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())[0];
}
