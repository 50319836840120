import { EntityState } from "@ngrx/entity";
import { TrendType } from "../../constants/trends.data";
import { GrowthTrendsConfig } from "../../models/growth-trends.model";
import { LabTrendsConfig } from "../../components/lab-chart/lab-utility/lab-trends.config";

export interface GrowthTrendsReducerInterface
  extends EntityState<GrowthTrendsConfig> {}

export interface labTrendsReducerInterface
  extends EntityState<LabTrendsConfig> {}

export interface TrendCommonReducerInterface {
  trendType: TrendType;
}

export interface TrendsActionReducerMapInterface {
  trendsCommon: TrendCommonReducerInterface;
  growthTrends: GrowthTrendsReducerInterface;
  labTrends: labTrendsReducerInterface;
}
