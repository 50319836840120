import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class InputPopupService {
  private openPopupSource = new Subject();

  public noteId = "notes";

  public openPopup$ = this.openPopupSource.asObservable();

  private openPopup(name: string) {
    if (name) {
      this.openPopupSource.next(name);
    }
  }

  public openNotes() {
    this.openPopup(this.noteId);
  }
}
