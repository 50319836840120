import { ValidationErrors, ValidatorFn, AbstractControl } from "@angular/forms";

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const newPass: string = control.get("newPass").value; // get password from our password form control
    const confNewPass: string = control.get("confNewPass").value; // get password from our confirmPassword form control
    // compare is the password math
    if (newPass !== confNewPass) {
      // if they don't match, set an error in our confirmPassword form control
      control.get("confNewPass").setErrors({ NoPassswordMatch: true });
    }
    return null;
  }

  static checkIfAdmin(control: AbstractControl) {
    const role = control.get("role").value;
    const hospitals = control.get("hospitals").value
      ? control.get("hospitals").value
      : [];
    const adminRoles = [
      "System Administrator",
      "Medical Administrator",
      "Billing Administrator",
      "Super Billing Administrator",
    ];

    let errObj = {};
    if (adminRoles.includes(role)) {
      return null;
    } else {
      if (!hospitals.length) errObj["hospitals"] = true;
    }

    return errObj;
  }

  static isOldPassword(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const oldPassword: string = control.get("currPass").value;
    const newPassword: string = control.get("newPass").value;

    if (oldPassword && newPassword && oldPassword === newPassword) {
      return {
        errors: "New password cannot be same as current password",
      };
    }

    return null;
  }
}
