<ng-container >
  <div  class="irisForm-group">
    <mat-label>{{placeholder}}:</mat-label>
    <mat-form-field appearance="outline">
      <input matInput [placeholder]="placeholder" [formControl]="formControlName" required>
      <input *ngIf="password" matInput [placeholder]="placeholder" [formControl]="formControlName" required>
      <mat-icon matSuffix>{{getIcon()}}</mat-icon>
      
    </mat-form-field>
  </div>
</ng-container>