import { Action, createReducer, on } from "@ngrx/store";

import * as ncpCreateAction from "../action/ncp.action";
import * as ncpResetAction from "../action/reset.action";
import { NcpList } from "../../type/ncp-data.interface";

export interface State {
  isEdit: boolean;
  loading: boolean;
  data: NcpList[];
}

const initialState: State = {
  isEdit: false,
  loading: false,
  data: [],
};

const ncpAddReducer = createReducer(
  initialState,

  on(
    ncpCreateAction.addInputData,

    (state, action) => {
      return { loading: false, isEdit: action.isEdit, data: action.data };
    }
  ),
  on(
    ncpCreateAction.createNcp,

    (state, action) => {
      return { ...state, loading: false, data: action.data };
    }
  ),

  on(ncpResetAction.resetState, () => {
    return initialState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ncpAddReducer(state, action);
}
