import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchableDropdownComponent } from "./components/searchable-dropdown/searchable-dropdown.component";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
  declarations: [SearchableDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatDividerModule,
  ],
  exports: [SearchableDropdownComponent],
})
export class SearchableDropdownModule {}
