<section
  class="fall-risk-container input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form fall-risk-form"
    autocomplete="off"
    [formGroup]="rFallsForm"
    (ngSubmit)="submitFalls(rFallsForm.value)"
  >
    <div class="sub-tittle" *ngIf="!admitFormIsParent">Fall Risk Score:</div>
    <hr *ngIf="!admitFormIsParent" />
    <div class="input-form-header">
      <div class="score">
        <div
          [ngClass]="{
            'background-success': fallScore <= 25 && fallScore > 0,
            'background-warning': fallScore > 25 && fallScore <= 45,
            'background-danger': fallScore > 45
          }"
        >
          {{ fallScore }}
        </div>
      </div>
      <div class="info">
        <div class="emoji-section">
          <div class="text-success">
            <b>0-25 Mild&nbsp;</b>
            <mat-icon inline="true">sentiment_satisfied</mat-icon>
          </div>

          <div class="text-warning">
            <b class="ml-1">26-45 Moderate&nbsp;</b>
            <mat-icon inline="true">sentiment_neutral</mat-icon>
          </div>
          <div class="text-danger">
            <b>46+ Strong</b>&nbsp;
            <mat-icon inline="true">sentiment_dissatisfied</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="form_fields">
      <!--History of Falling-->
      <div class="iris-form-row">
        <label for="fallHistory"
          >History of falling
          <small>&nbsp;(Immediate or previous):</small>
        </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="fallHistory"
          formControlName="fallHistory"
        >
          <mat-button-toggle [value]="0">No, zero falls</mat-button-toggle>
          <mat-button-toggle [value]="15"
            >Yes, fell in the past</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <!--Secondary Diagnosis-->
      <div class="iris-form-row">
        <label for="secDiagnoses"
          >Secondary diagnosis
          <small>&nbsp;(2 or more diagnoses in chart):</small>
        </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="secDiagnoses"
          formControlName="secDiagnoses"
        >
          <mat-button-toggle [value]="0"
            >No, only primary diagnosis</mat-button-toggle
          >
          <mat-button-toggle [value]="15"
            >Yes, secondary diagnosis exists</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <!--Ambulatory Aid-->
      <div class="iris-form-row">
        <label for="ambAssist"
          >Ambulatory aid
          <small>&nbsp;(Walking assistance):</small>
        </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="ambAssist"
          formControlName="ambAssist"
        >
          <mat-button-toggle [value]="0"
            >None/bedrest/nurse assist</mat-button-toggle
          >
          <mat-button-toggle [value]="15"
            >Crutches/cane/walker</mat-button-toggle
          >
          <mat-button-toggle [value]="30">Furniture</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--IV Therapy/Heparin Lock-->
      <div class="iris-form-row">
        <label for="ivTherapy">IV Therapy:</label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="ivTherapy"
          formControlName="ivTherapy"
        >
          <mat-button-toggle [value]="0"
            >No, patient not on IV</mat-button-toggle
          >
          <mat-button-toggle [value]="20">Yes, patient on IV</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--Gait-->
      <div class="iris-form-row">
        <label for="gait">Gait <small>&nbsp;(Walking status):</small> </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="gait"
          formControlName="gait"
        >
          <mat-button-toggle [value]="0"
            >Normal/Bedrest/Wheelchair</mat-button-toggle
          >
          <mat-button-toggle [value]="10">Weak</mat-button-toggle>
          <mat-button-toggle [value]="20">Impaired</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--Mental Status-->
      <div class="iris-form-row">
        <label for="mentalStatus">Mental status: </label>
        <mat-button-toggle-group
          (change)="onFallInput()"
          name="mentalStatus"
          formControlName="mentalStatus"
        >
          <mat-button-toggle [value]="0">Oriented</mat-button-toggle>
          <mat-button-toggle [value]="15">Disoriented</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--Date/Time and Submit button-->
      <div class="date-time-row" *ngIf="!admitFormIsParent">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!rFallsForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'fall'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
