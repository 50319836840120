import { SelectItem } from '../models/general.model';

export const airways: SelectItem[] = [
  { value: 'RA', text: 'Room Air' },
  { value: 'Cannula', text: 'Nasal Cannula' },
  { value: 'AMBU', text: 'AMBU bag' },
  { value: 'Trach', text: 'Trach' },
  { value: 'Trach+', text: 'Trach w/ suction' },
  { value: 'Trach + T-piece', text: 'Trach w/ T-piece' },
  { value: 'Trach Collar', text: 'Trach Collar' },
  { value: 'High Flow', text: 'High Flow Cannula' },
  { value: 'ETT', text: 'ETT' },
  { value: 'ETT+', text: 'ETT w/ suction' },
  { value: 'ETT + T-piece', text: 'ETT w/ T-piece' },
  { value: 'NRB', text: 'Non-rebreather' },
  { value: 'LMA', text: 'LMA' },
  { value: 'iGel', text: 'iGel' },
  { value: 'Supraglottic airway', text: 'Supraglottic airway' },
  { value: 'Mask', text: 'Mask' },
  { value: 'Venturi', text: 'Venturi Mask' },
  { value: 'Nasal Mask (NIV)', text: 'Nasal Mask (NIV)' },
  { value: 'Oral Mask (NIV)', text: 'Oral Mask (NIV)' },
  { value: 'Oronasal Mask (NIV)', text: 'Oronasal Mask (NIV)' },
  { value: 'Hood (NIV)', text: 'Hood (NIV)' },
  { value: 'Nasal Pillow (NIV)', text: 'Nasal Pillow (NIV)' },
  { value: 'Simple Mask', text: 'Simple Mask' },
];

export const intubatedAirways: SelectItem[] = [
  { value: 'Trach', text: 'Trach' },
  { value: 'Trach+', text: 'Trach w/ suction' },
  { value: 'Trach + T-piece', text: 'Trach w/ T-piece' },
  { value: 'Trach Collar', text: 'Trach Collar' },
  { value: 'ETT', text: 'ETT' },
  { value: 'ETT+', text: 'ETT w/ suction' },
  { value: 'ETT + T-piece', text: 'ETT w/ T-piece' },
  { value: 'LMA', text: 'LMA' },
];

export const tubes: SelectItem[] = [
  { value: 'neonate', text: 'Neonate (1)' },
  { value: 'infant', text: 'Infant (1½)' },
  { value: 'smPediatric', text: 'Small Pediatric (2)' },
  { value: 'lgPediatric', text: 'Large Pediatric (2½)' },
  { value: 'smAdult', text: 'Small Adult (3)' },
  { value: 'mdAdult', text: 'Medium Adult (4)' },
  { value: 'lgAdult', text: 'Large Adult (5)' },
  { value: 'other', text: 'Other' }
];
