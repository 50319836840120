import { Action, createReducer, on } from "@ngrx/store";
import { NcpData, NcpResponse } from "../../type/ncp-data.interface";
import * as ncpAction from "../action/ncp.action";

export interface State {
  loading: boolean;
  data: NcpData;
}

const initialState: State = {
  loading: false,
  data: null,
};

const ncpFetchReducer = createReducer(
  initialState,

  on(
    ncpAction.fetchNcpData,

    (state) => {
      return { ...state, loading: true };
    }
  ),
  on(
    ncpAction.fetchNcpDataSuccess,

    (state, action) => {
      return { loading: false, data: action.data };
    }
  ),
  on(
    ncpAction.fetchNcpDataFailure,

    (state) => {
      return { ...state, loading: false };
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return ncpFetchReducer(state, action);
}
