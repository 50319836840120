<section
  class="braden-container input-form"
  [ngClass]="{ 'padding-bottom-0': admitFormIsParent }"
>
  <form
    class="iris-form braden-form"
    autocomplete="off"
    [formGroup]="rBradenForm"
    (ngSubmit)="submitBraden(rBradenForm.value)"
  >
    <div *ngIf="!admitFormIsParent" class="sub-tittle">Braden Scale Score:</div>
    <hr *ngIf="!admitFormIsParent" />
    <div class="input-form-header">
      <div class="score">
        <div
          [ngClass]="{
            'background-success': bradenScore >= 19,
            'background-warning': bradenScore >= 12 && bradenScore < 19,
            'background-danger': bradenScore < 12 && bradenScore > 0
          }"
        >
          {{ bradenScore }}
        </div>
      </div>
      <div class="info">
        <div class="emoji-section">
          <div class="text-success">
            <b>19+ Mild&nbsp;</b>
            <mat-icon inline="true">sentiment_satisfied</mat-icon>
          </div>

          <div class="text-warning">
            <b class="ml-1">12-18 Moderate&nbsp;</b>
            <mat-icon inline="true">sentiment_neutral</mat-icon>
          </div>
          <div class="text-danger">
            <b>0-11 Strong</b>&nbsp;
            <mat-icon inline="true">sentiment_dissatisfied</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="form_fields">
      <!--Sensory Perception-->
      <div class="iris-form-row">
        <label for="sensory"
          >Sensory perception
          <small
            >&nbsp;(Ability to respond meaningfully to pressure-related
            discomfort):</small
          >
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="sensory"
          formControlName="sensory"
        >
          <mat-button-toggle [value]="1">Completely limited</mat-button-toggle>
          <mat-button-toggle [value]="2">Very limited</mat-button-toggle>
          <mat-button-toggle [value]="3">Slightly limited</mat-button-toggle>
          <mat-button-toggle [value]="4">No impairment</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Moisture-->
      <div class="iris-form-row">
        <label for="moisture"
          >Moisture
          <small>&nbsp;(Degree to which skin is exposed to moisture):</small>
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="moisture"
          formControlName="moisture"
        >
          <mat-button-toggle [value]="1">Constantly moist</mat-button-toggle>
          <mat-button-toggle [value]="2">Often moist</mat-button-toggle>
          <mat-button-toggle [value]="3">Occasionally moist</mat-button-toggle>
          <mat-button-toggle [value]="4">Rarely moist</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Activity-->
      <div class="iris-form-row">
        <label for="activity"
          >Activity
          <small>&nbsp;(Degree of physicial activity):</small>
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="activity"
          formControlName="activity"
        >
          <mat-button-toggle [value]="1">Bedfast</mat-button-toggle>
          <mat-button-toggle [value]="2">Chairfast</mat-button-toggle>
          <mat-button-toggle [value]="3">Walks occasionally</mat-button-toggle>
          <mat-button-toggle [value]="4">Walks frequently</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Mobility-->
      <div class="iris-form-row">
        <label for="mobility"
          >Mobility
          <small>&nbsp;(Ability to change or control body position):</small>
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="mobility"
          formControlName="mobility"
        >
          <mat-button-toggle [value]="1">Completely immobile</mat-button-toggle>
          <mat-button-toggle [value]="2">Very limited</mat-button-toggle>
          <mat-button-toggle [value]="3">Slightly limited</mat-button-toggle>
          <mat-button-toggle [value]="4">No limitations</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Nutrition-->
      <div class="iris-form-row">
        <label for="nutrition"
          >Nutrition
          <small>&nbsp;(Usual food intake pattern):</small>
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="nutrition"
          formControlName="nutrition"
        >
          <mat-button-toggle [value]="1">Very poor</mat-button-toggle>
          <mat-button-toggle [value]="2">Probably inadequate</mat-button-toggle>
          <mat-button-toggle [value]="3">Adequate</mat-button-toggle>
          <mat-button-toggle [value]="4">Excellent</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Friction and Shear-->
      <div class="iris-form-row">
        <label for="friction"
          >Friction and Shear
          <small>&nbsp;(Patient movement leading to friction/shear):</small>
        </label>
        <mat-button-toggle-group
          (change)="onBradenInput()"
          name="friction"
          formControlName="friction"
        >
          <mat-button-toggle [value]="1">Problem</mat-button-toggle>
          <mat-button-toggle [value]="2">Potential problem</mat-button-toggle>
          <mat-button-toggle [value]="3">No apparent problem</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row" *ngIf="!admitFormIsParent">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!rBradenForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'braden'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
