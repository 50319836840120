import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { EffectsModule } from "@ngrx/effects";
import { IrisComponentsModule } from "../iris-components/iris-components.module";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NotificationTabComponent } from "./notification-tab/notification-tab.component";
import { NotificationElementComponent } from "./notification-element/notification-element.component";
import { NetraMissedNotificationComponent } from "./netra-missed-notification/netra-missed-notification.component";
import { NetraNotVerifiedNotificationComponent } from "./netra-not-verified-notification/netra-not-verified-notification.component";
import { NotificationToasterComponent } from "./toasters/notification-toaster/notification-toaster.component";
import { CodeblueToasterComponent } from "./toasters/codeblue-toaster/codeblue-toaster.component";
import { ToasterEffects } from "./effects/toaster.effects";

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationTabComponent,
    NotificationElementComponent,
    NetraMissedNotificationComponent,
    NetraNotVerifiedNotificationComponent,
    NotificationToasterComponent,
    CodeblueToasterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSlideToggleModule,
    MatTabsModule,
    IrisComponentsModule,
    EffectsModule.forFeature([ToasterEffects]),
  ],
  providers: [],
  exports: [NotificationsComponent],
})
export class NotificationModule {}
