import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HospFilterPipe } from "./hosp-filter.pipe";

@NgModule({
  declarations: [HospFilterPipe],
  imports: [CommonModule],
  exports: [HospFilterPipe],
  providers: [HospFilterPipe],
})
export class HospFilterPipeModule {}
