import { createAction, props } from "@ngrx/store";
import { FileSigned } from "../../../../models/file/File.model";
import { Task, TaskBE } from "../../../../models/task/Task.model";

export const getDocuments = createAction(
  "[Patient Docs] get patient docs",
  props<{ CPMRN: string; encounters: number }>()
);

export const setDocuments = createAction(
  "[Patient Docs] set patient docs",
  props<{ docs: any }>()
);

// export const addDocuments = createAction(
//     '[Patient Docs] add docs',
//     props<{docs: Document[]}>()
// );

export const addDocToDb = createAction(
  "[Patient Docs] add doc to db",
  props<{ doc: Object }>()
);

// export const loadDocuments = createAction(
//     '[Patient Docs] load docs',
//     props<{payload: string}>()
// );

export const editDocument = createAction(
  "[Patient Docs] edit doc",
  props<{ doc: any }>()
);

export const removeDocument = createAction(
  "[Patient Docs] remove doc",
  props<{ doc: Object }>()
);

// export const documentRemoved = createAction(
//     '[Patient Docs] doc removed',
//     props<{doc: Object}>()
// );

export const submitForm = createAction(
  "[Patient Docs] doc form submit",
  props<{ loadType: string }>()
);

export const submitSuccess = createAction("[Patient Docs] doc form success");

export const hideSuccess = createAction("[Patient Docs] doc form hide success");

export const submitFail = createAction(
  "[Patient Docs] doc form fail",
  props<{ error: any }>()
);

export const uploadDocument = createAction(
  "[Patient Docs] Upload Document",
  props<{ file: FileSigned; fileData: File }>()
);

export const updateFilesToBeUploaded = createAction(
  "[Patient Docs] Update Files To Be Uploaded",
  props<{ id: number }>()
);

export const resetCompleteFileState = createAction(
  "[Patient Docs] Reset Complete File State"
);

export const createTask = createAction(
  "[Patient Docs] Create Task",
  props<{ task: Task }>()
);

export const sendTaskThroughSocket = createAction(
  "[Patient Docs] Send Task Through Socket",
  props<{ task: TaskBE }>()
);

export const sendVisionTask = createAction(
  "[Patient Docs] Send Vision Task",
  props<{ data: any; fileIds: number[] }>()
);

export const deleteVisionTask = createAction(
  "[Patient Docs] Delete Vision Task",
  props<{ id: number }>()
);
