import { Injectable } from "@angular/core";
import * as fromInfectionBundleActions from "../actions";
import { InfectionBundleService } from "../../services/infection-bundle.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, switchMap, tap } from "rxjs/operators";

@Injectable()
export class InfectionQuestionsEffect {
  constructor(
    private actions$: Actions,
    private _infectionBundleService: InfectionBundleService
  ) {}

  getAllInfectionQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromInfectionBundleActions.getAllQuestions),
      switchMap(({ CPMRN, encounters, pType }) => {
        return this._infectionBundleService
          .getInfectionQuestion(CPMRN, encounters, pType)
          .pipe(
            mergeMap((infectionBundles) => {
              return [
                fromInfectionBundleActions.setAllQuestions({
                  questionPayload: infectionBundles,
                }),
              ];
            }),
            catchError((response) => {
              return [
                fromInfectionBundleActions.showError({
                  msg: response?.error?.message || "error",
                }),
              ];
            })
          );
      })
    )
  );
}
