import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";
import "hammerjs";
import { PlayerModule } from "src/app/iris-components/player";
import { ChatComponent } from "./components/chat/chat.component";
import { ReplyMessageComponent } from "./components/reply-message/reply-message.component";
import { ReadmoreModule } from "src/app/iris-components/readmore";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { AvatarModule } from "src/app/iris-components/avatar/avatar.module";

@NgModule({
  declarations: [ChatComponent, ReplyMessageComponent, FileUploadComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReadmoreModule,
    PlayerModule,
    IrisComponentsModule,
    TimezonePipeModule,
    TimezoneLabelModule,
    AvatarModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [ChatComponent, IrisComponentsModule],
})
export class ChatModule {}
