import { createAction, props } from "@ngrx/store";
import { CreateInfBundChecklistPayload, InfectionBundle } from "../../models";

const GET_ALL_INFECTION_BUNDLES =
  "[INFECTION BUNDLES] get all infection bundles";
const GET_ALL_INFECTION_BUNDLES_SUCC =
  "[INFECTION BUNDLES] get all infection bundles success";
const CREATE_INF_BUND_CHECKLIST =
  "[INFECTION BUNDLES] create a infection bundle checklist";
const CREATE_INF_BUND_CHECKLIST_SUCC =
  "[INFECTION BUNDLES] create a infection bundle checklist success";
const UPDATE_INF_BUND_CHECKLIST =
  "[INFECTION BUNDLES] update a infection bundle checklist";
const UPDATE_INF_BUND_CHECKLIST_SUCC =
  "[INFECTION BUNDLES] update a infection bundle checklist success";
const UPDATE_LOADER_STATUS = "[INFECTION BUNDLES] update loader status";
const UPSERT_INFECTION_BUNDLE = "[INFECTION BUNDLES] upsert infection bundle";
const SHOW_ERR = "[INFECTION BUNDLES] show error";
const SHOW_SUCC = "[INFECTION BUNDLES] show success";

export const getAllInfectionBundlesAction = createAction(
  GET_ALL_INFECTION_BUNDLES,
  props<{ CPMRN: string; encounters: number }>()
);

export const getAllInfectionBundlesActionSuccess = createAction(
  GET_ALL_INFECTION_BUNDLES_SUCC,
  props<{ infectionBundles: InfectionBundle[] }>()
);

export const createInfBundChecklist = createAction(
  CREATE_INF_BUND_CHECKLIST,
  props<{ payload: CreateInfBundChecklistPayload }>()
);

export const createInfBundChecklistSuccess = createAction(
  CREATE_INF_BUND_CHECKLIST_SUCC,
  props<{ checklist: InfectionBundle }>()
);

export const updateInfBundChecklist = createAction(
  UPDATE_INF_BUND_CHECKLIST,
  props<{ payload: CreateInfBundChecklistPayload }>()
);

export const updateInfBundChecklistSuccess = createAction(
  UPDATE_INF_BUND_CHECKLIST_SUCC,
  props<{ updatedChecklist: InfectionBundle }>()
);

export const upsertInfectionBundle = createAction(
  UPSERT_INFECTION_BUNDLE,
  props<{ checklist: InfectionBundle }>()
);

export const updateLoaderStatus = createAction(
  UPDATE_LOADER_STATUS,
  props<{ status: boolean }>()
);

export const showError = createAction(SHOW_ERR, props<{ msg: string }>());

export const showSucc = createAction(SHOW_SUCC, props<{ msg: string }>());
