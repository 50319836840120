import { createAction, props } from '@ngrx/store';

export const addSbarAlert = createAction(
    '[Sbar alert] add',
    props<{sbar: any}>()
);

export const removeSbarAlert = createAction(
    '[Sbar alert] remove',
    props<{sbar: any}>()
);