import { setLoadingSpinner, setErrorMessage } from "./shared.actions";
import { createReducer, on } from "@ngrx/store";
import { initialState, SharedState } from "./shared.state";
import { LoaderNames, SHARED_STATE_NAME } from "./shared.selector";

const _sharedReducer = createReducer(
  initialState,
  on(setLoadingSpinner, (state, action) => {
    const { loader, status } = action;
    const updatedLoader = {
      ...state.loader,
      [loader || LoaderNames.DEFAULT_LOADER]: { showLoading: status },
    };
    return {
      ...state,
      loader: updatedLoader,
    };
  }),
  on(setErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: action.message,
    };
  })
);

export function SharedReducer(state, action) {
  return _sharedReducer(state, action);
}

export interface ShareState {
  [SHARED_STATE_NAME]: SharedState;
}
