/**
 * This sets up the url for the HIK vision direct login process
 * 
 * pass the cam object
 * cam = {
    *      ip
    *      username
    *      password
    *      channel
 *  }
 */

export function setUrl(cam) {
    return 'http://'+cam['ip']+'/dispatch.asp?user='+cam['userName']+'&pass='+cam['password']+'&page=preview.asp&slice=1&open={'+cam['channel']+'}';
}