<form [formGroup]="form" class="order-form-comp">
  <div
    [ngSwitch]="orderableType"
    [class.mt-3]="orderableType !== 'vents'"
    [class.mt-0]="orderableType === 'vents'"
  >
    <app-blood-form
      *ngSwitchCase="'blood'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-blood-form>
    <app-communication-form
      *ngSwitchCase="'comm'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-communication-form>
    <app-diet-form
      *ngSwitchCase="'diet'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-diet-form>
    <app-med-form
      *ngSwitchCase="'med'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [orderInHospital]="orderInHospital"
      [currentPatient]="currentPatient"
      [medSchedule]="medSchedule"
      [orderableName]="orderable && orderable.name ? orderable.name : null"
      [rate]="concentrationRate"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-med-form>
    <app-lab-form
      *ngSwitchCase="'lab'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-lab-form>
    <app-procedure-form
      *ngSwitchCase="'procedure'"
      [config]="config"
      [formGroup]="form"
      [formType]="formType"
      [showBedsideForm]="canPlaceBedsideOrder$ | async"
    ></app-procedure-form>
    <app-vent-form
      *ngSwitchCase="'vents'"
      [formGroup]="form"
      [config]="config"
      [value]="value"
      [formType]="formType"
      [minDate]="currentPatient?.formDates?.minDate"
      [maxDate]="currentPatient?.formDates?.maxDate"
      [currentPatient]="currentPatient"
    >
    </app-vent-form>
  </div>

  <div class="buttons-cont">
    <button
      mat-flat-button
      class="button-secondary mr"
      type="button"
      (click)="onCancel('cancel')"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      class="button-secondary mr"
      type="button"
      (click)="onReset()"
    >
      Reset
    </button>

    <button
      mat-flat-button
      class="button-primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="form.invalid || loading"
    >
      {{ orderFormService.getFormSubmitText(user.role) }}
      <app-loader [buttonLoader]="true" *ngIf="loading"></app-loader>
    </button>
  </div>
</form>
