import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  @ViewChild("buttonElem") buttonElement: ElementRef;

  @Input("buttonLoader") buttonLoader: boolean = false;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.buttonLoader) {
      this.buttonElement.nativeElement.parentElement.parentElement.style.position =
        "relative";
    }
  }

  onButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
