import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FindObjectInArrayWithKeyPipe } from "./pipe/find-object-in-array-with-key.pipe";

@NgModule({
  declarations: [FindObjectInArrayWithKeyPipe],
  imports: [CommonModule],
  providers: [FindObjectInArrayWithKeyPipe],
  exports: [FindObjectInArrayWithKeyPipe],
})
export class FindObjectInArrayWithKeyModule {}
