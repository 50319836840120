import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import * as _moment from 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment-timezone';

const moment = _rollupMoment || _moment;

@Injectable({ providedIn: 'root' })
export class ClockService {
  public clock: Observable<Moment>;

  constructor() {
    this.clock = interval(1000)
      .pipe(
        map(tick => moment.tz('Asia/Kolkata')),
        share()
      );
  }

  getClock(): Observable<Moment> {
    return this.clock;
  }

  getCurrentLocalTime() {
    return moment.tz('Asia/Kolkata');
  }
}
