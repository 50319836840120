import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "regexFilter",
})
export class RegexFilterPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\[.*/, "");
  }
}
