import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";

import { FetchFile, FileSigned } from "../labs-scans/models/File.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileService {
  public env = environment;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  /*
   * NAME: getFileData
   * PURPOSE: converts file data into data suitable to display in html
   * DESCRIPTION:
   * PARAMS: data:string - file data
   * RETURNS: SafeResourceUrl - bypasses data of file
   * USED BY: protocol-form.component.ts, orders-view.component.ts
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getFileData(data: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }

  getSignedUrl(file: FileSigned): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/getSignedUrl`, file, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getFile(key: string): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/getFile`, { key }, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getFiles(data: FetchFile[]): Observable<any> {
    return this.http
      .post<any>(
        `${this.env.apiUrl}file/getFile`,
        { key: data },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadFile(file: File, url: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": type,
      DoNotIntercept: "true",
    });

    const req = new HttpRequest("PUT", url, file, {
      headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(catchError(this.errorHandler));
  }

  sendVisionTask(task): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/hera`, task, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getClassifications(): Observable<any> {
    return this.http
      .get<any>(`${this.env.apiUrl}labs/get_classifications`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }
}
