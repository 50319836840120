/** 
 * This function converts base64 image to blob(file) type
 * 
 * Pass the base64 string to the function it will slice the prefixes and return the blob data
*/

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    var block = b64Data.split(";");
    b64Data = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM....";

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
}