import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";
import { Vitals } from "src/app/vitals/models/Vital";
import { CribFormService } from "./crib-form.service";
import * as fromVitalReducer from "../../../store/reducers";
import * as fromLabsReducer from "../../../../labs-scans-module/store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { sexList } from "../../../support/vitals.support";
@Component({
  selector: "app-crib",
  templateUrl: "./crib.component.html",
  styleUrls: ["./crib.component.scss"],
})
export class CribComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public currentPatient;
  public cribForm: FormGroup = this._cribFormService.initCribForm();
  public sexList = sexList;
  private vitals: Vitals[] = [];
  private baseExcessLabsData;

  get cribScore() {
    return this.cribForm.get("daysScore").value || 0;
  }

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  public vitalData$ = this.store.pipe(
    select(fromVitalReducer.getVitaldays),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  public labsData$ = this.store.pipe(
    select(fromLabsReducer.getAllLabs),
    takeUntil(this.unsubscribe$)
  );

  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;

      // set form if it's a new to get data of latest gestation/weight/gender/temperature/baseExcess values
      if (this.isFormToBeFillFromCurrentPatient) this.setInitialsForCrib();
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.isFormToBeFillFromCurrentPatient = false;
      this.setFormValues(day);
    } else {
      this.isFormToBeFillFromCurrentPatient = true;
    }
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  get cribInputFormInvalid() {
    return (
      this.cribForm.get("daysGestation").invalid ||
      this.cribForm.get("daysWeight").invalid ||
      this.cribForm.get("daysGender").invalid ||
      this.cribForm.get("daysTemperature").invalid ||
      this.cribForm.get("daysBaseExcess").invalid
    );
  }

  isFormToBeFillFromCurrentPatient: boolean = false;

  constructor(
    private _cribFormService: CribFormService,
    private store: Store<{}>,
    private _alertService: AlertService
  ) {
    this.vitalData$.subscribe((data) => {
      this.vitals = data;
    });

    this.labsData$.subscribe((data) => {
      this.baseExcessLabsData = data.find(
        (lab) =>
          (lab.name.includes("Base excess") && lab.attributes.value.value) ||
          (lab.name.includes("Gas panel") && lab.attributes["BE"].value)
      );
    });
  }

  ngOnInit(): void {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "CRIB II score updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });
    this.resetVitalTimestamp();
  }

  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.cribForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setFormValues(vitalData: Vitals | null) {
    this.cribForm.patchValue({
      daysScore: vitalData?.daysCrib?.daysScore,
      daysGestation: vitalData?.daysCrib?.daysGestation,
      daysWeight: vitalData?.daysCrib?.daysWeight,
      daysGender: vitalData?.daysCrib?.daysGender,
      daysTemperature: vitalData?.daysCrib?.daysTemperature,
      daysBaseExcess: vitalData?.daysCrib?.daysBaseExcess,
    });
    this.cribForm.get("timestamp").setValue(vitalData.timestamp);
  }

  setInitialsForCrib() {
    const cribGestation = Math.ceil(
      this.currentPatient?.gestationAge?.weeks +
        this.currentPatient?.gestationAge?.days / 7
    );
    const cribGender = this.currentPatient?.sex;
    const cribWeight = this.currentPatient?.birthWeightObj
      ? +(this.currentPatient?.birthWeightObj?.kg || 0) * 1000 +
        +this.currentPatient?.birthWeightObj?.gram
      : null;
    const [cribTemperature, cribBaseExcess] = this.fetchLatestTempLabValues();
    this.cribForm.patchValue({
      daysGestation: cribGestation,
      daysGender: cribGender,
      daysWeight: cribWeight,
      daysTemperature: cribTemperature,
      daysBaseExcess: cribBaseExcess,
    });
    // this.cribForm.markAllAsTouched();
  }

  fetchLatestTempLabValues() {
    const foundLatestTemperature = this.vitals.find(
      (vital) => vital.daysTemperature
    );
    let latestTempInCelcius;
    if (foundLatestTemperature && foundLatestTemperature?.daysTemperature) {
      latestTempInCelcius =
        foundLatestTemperature?.daysTemperatureUnit === "F"
          ? +((foundLatestTemperature?.daysTemperature - 32) * (5 / 9)).toFixed(
              2
            )
          : foundLatestTemperature?.daysTemperature;
    }
    let latestBaseExcessValue;
    if (this.baseExcessLabsData) {
      latestBaseExcessValue =
        "value" in this.baseExcessLabsData["attributes"]
          ? this.baseExcessLabsData["attributes"]["value"]["value"]
          : this.baseExcessLabsData["attributes"]["BE"]["value"];
    }
    return [latestTempInCelcius, latestBaseExcessValue];
  }

  calculateScoreLoading: boolean = false;
  errorMsg: string;
  calculateCribScore() {
    const {
      daysGestation,
      daysWeight,
      daysGender,
      daysTemperature,
      daysBaseExcess,
    } = this.cribForm.value;
    const payload = {
      gestation: daysGestation,
      weight: daysWeight,
      gender: daysGender,
      temperature: daysTemperature,
      baseExcess: daysBaseExcess,
    };
    this.calculateScoreLoading = true;
    this.errorMsg = null;

    this._cribFormService.getCRIBscore(payload).subscribe(
      ({ data }) => {
        this.cribForm.patchValue({ daysScore: data.score });
        this.calculateScoreLoading = false;
      },
      (err) => {
        this.calculateScoreLoading = false;
        this.errorMsg = err?.error?.message;
        console.log(err);
      }
    );
  }

  submitCrib(patDataFromForm) {
    this.store.dispatch(vitalActions.submitForm({ loadType: "crib2" }));
    const crib2Props = {
      timestamp: new Date(patDataFromForm.timestamp),
      daysCrib: {
        daysScore: patDataFromForm.daysScore,
        daysGestation: patDataFromForm.daysGestation,
        daysWeight: patDataFromForm.daysWeight,
        daysGender: patDataFromForm.daysGender,
        daysTemperature: patDataFromForm.daysTemperature,
        daysBaseExcess: patDataFromForm.daysBaseExcess,
      },
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: crib2Props },
      })
    );
  }

  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.cribForm.reset();
    this.formState.error = null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
