import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { LabsScansService } from "../../services/labs-scans.service";
import * as labDocumentActions from "../actions/lab-documents.actions";

@Injectable()
export class LabDocumentEffects {
  constructor(
    private actions$: Actions,
    private _labsScanService: LabsScansService,
    private _alertService: AlertService
  ) {}

  loadLabForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labDocumentActions.LOAD_LAB_DOCUMENTS),
      map((action: labDocumentActions.LoadLabDocument) => action.payload),
      switchMap(({ docs }) => {
        return this._labsScanService.addDocument(docs).pipe(
          map((code: []) => new labDocumentActions.LoadLabDocumentSuccess()),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Server error!",
            });
            this._labsScanService.loadingStatus(false);
            return of(new labDocumentActions.LoadLabDocumentFail(error));
          })
        );
      })
    )
  );

  updateLabForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(labDocumentActions.UPDATE_LAB_DOCUMENTS),
      map((action: labDocumentActions.UpdateLabDocument) => action.payload),
      switchMap(({ docs }) => {
        return this._labsScanService.updateDocument(docs).pipe(
          map((code: []) => new labDocumentActions.UpdateLabDocumentSuccess()),
          catchError((error) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Server error!",
            });
            this._labsScanService.loadingStatus(false);
            return of(new labDocumentActions.UpdateLabDocumentFail(error));
          })
        );
      })
    )
  );
}
