import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";

import { FormConfig } from "../../../models/FormConfig.model";
import { UtilService } from "../../../../services/util.service";
import { procedureSides, procedureTypes } from "../../../form.data";
import { ProcedurePresetBE } from "../../../../models/preset/ProcedurePreset.model";

@Component({
  selector: "app-procedure-form",
  templateUrl: "./procedure-form.component.html",
  styleUrls: ["./procedure-form.component.scss"],
})
export class ProcedureFormComponent implements OnInit {
  @Input() value?: ProcedurePresetBE;
  @Input() config: any;
  @Input() formType: string;
  @Input() showBedsideForm = true;

  public types = procedureTypes;
  public sides = procedureSides;

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {}

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for combination field.
   *
   * @returns {FormArray}
   */
  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  /**
   * Get accessor for pType field.
   *
   * @returns {FormArray}
   */
  get pType(): AbstractControl {
    return this.form.get("pType");
  }

  /**
   * Get accessor for name field.
   *
   * @returns {FormArray}
   */
  get name(): AbstractControl {
    return this.form.get("name");
  }

  /**
   * Get accessor for site field.
   *
   * @returns {FormArray}
   */
  get site(): AbstractControl {
    return this.form.get("site");
  }

  /**
   * Updates site and laterality based on PType value.
   *
   * @param {string} pType
   */
  foleySelected(pType: String): void {
    if (pType === "Foley") {
      this.form.patchValue({
        site: "Urethra",
        laterality: "NA",
      });
    } else if (pType === "SCD") {
      this.form.patchValue({
        site: "Legs",
        laterality: "Bilateral",
      });
    } else if (pType === "Stockings") {
      this.form.patchValue({
        site: "Legs",
        laterality: "Bilateral",
      });
    }
  }

  /**
   * If bedside is checked then resets home med checkbox.
   */
  onBedsideChange() {
    this.form
      .get("bedsideOrder")
      .patchValue(!!!this.form.get("bedsideOrder").value);
  }
}
