import { Injectable } from "@angular/core";
import { Med } from "../../models/Med.model";
import { MedRate } from "../../models/med/MedRate.model";
import { OrderFrequency } from "../../models/OrderFrequency.model";
import {
  validContinuousFrequencyUnits,
  validRateUnits,
} from "src/app/orders/form.data";

@Injectable({ providedIn: "root" })
export class MedService {
  private rateUnit = "ml/hr";
  private _validContinuousFrequencyUnits = validContinuousFrequencyUnits;

  /**
   * Calculate rate from the med.
   * Checks if given med is validate or not.
   * If not valid then returns the error message with rate as null.
   * If valid then calculates the rate.
   *
   * @param {Med} med - Med to calculate rate from.
   * @return {MedRate} - Validation Error will be null if rate can be calculated. Otherwise validationError will be present.
   */
  getRate(med: Med): MedRate {
    const validationError = this.validateRateValues(med);

    if (validationError) {
      return { validationError, rate: null };
    }

    const min = this.calculateRate(med, med.quantity);
    const max = med.maxDose ? this.calculateRate(med, med.maxDose) : null;

    return { validationError: null, rate: { min, max } };
  }

  /*
   * NAME: convertWeight
   * PURPOSE: converts w1 type to w2 type
   * DESCRIPTION:
   * PARAMS:
   *   - w1:object - { type: string, value: number }
   *   - w2:object - { type: string, value: number }
   * RETURNS: number - converted weight
   * USED BY: calculateRate()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  private convertWeight(w1, w2): number {
    if (w1.type === w2.type) {
      return w1.value;
    } else if (w1.type === "g" && w2.type === "mg") {
      return w1.value * 1000;
    } else if (w1.type === "g" && w2.type === "mcg") {
      return w1.value * 1000 * 1000;
    } else if (w1.type === "g" && w2.type === "ng") {
      return w1.value * 1000 * 1000 * 1000;
    } else if (w1.type === "mg" && w2.type === "g") {
      return w1.value / 1000;
    } else if (w1.type === "mg" && w2.type === "mcg") {
      return w1.value * 1000;
    } else if (w1.type === "mg" && w2.type === "ng") {
      return w1.value * 1000 * 1000;
    } else if (w1.type === "mcg" && w2.type === "g") {
      return w1.value / (1000 * 1000);
    } else if (w1.type === "mcg" && w2.type === "mg") {
      return w1.value / 1000;
    } else if (w1.type === "mcg" && w2.type === "ng") {
      return w1.value * 1000;
    } else if (w1.type === "ng" && w2.type === "g") {
      return w1.value / Math.pow(1000, 3);
    } else if (w1.type === "ng" && w2.type === "mg") {
      return w1.value / Math.pow(1000, 2);
    } else if (w1.type === "ng" && w2.type === "mcg") {
      return w1.value / 1000;
    } else if (w1.type === "IU") {
      return w1.value;
    }
  }

  /*
   * NAME: convertVolume
   * PURPOSE: converts l to ml
   * DESCRIPTION: if type is ml then return value w/o modifying
   *   - if l then converts it to ml
   * PARAMS: v1:object - { type: string, value: number }
   * RETURNS: number - converted volume
   * USED BY: calculateRate()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  public convertVolume(v1): number {
    if (v1.type === "ml") {
      return v1.value;
    } else {
      return v1.value / 1000;
    }
  }

  /*
   * NAME: convertTime
   * PURPOSE: converts time to hr
   * DESCRIPTION: if type is hr then return value w/o modifying
   *   - if min then converts it to hr
   * PARAMS: t1:object - { type: string, value: number }
   * RETURNS: number - converted time
   * USED BY: calculateRate()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  public convertTime(t1): number {
    if (t1.type === "hr") {
      return t1.value;
    } else if (t1.type === "day") {
      return t1.value / 24;
    } else if (t1.type === "s") {
      return t1.value * 60 * 60;
    } else {
      return t1.value * 60;
    }
  }

  /**
   * Checks if rate can be calculated.
   * Rate cannot be calculated if:
   *  - Med or concentration is not present.
   *  - Concentration unit or Concentration value is not present.
   *  - Frequency is not continuous.
   *  - Weight is required but not given.
   *  - Both unit and concentration unit are not of similar type.
   *  - If unit is not valid concentration unit.
   *
   * @param {Med} med - Med to calculate rate from.
   * @return {string|null} - null if its valid else string with error message.
   * @private
   */
  private validateRateValues(med: Med): string | null {
    if (!med || !med.concentration) {
      return "Concentration is required";
    }

    const concentrationValue = med.concentration.value;
    const concentrationUnit = med.concentration.unit;
    const unit = med.unit;
    const bodyWeight = med.bodyWeight;

    // if conc unit or conc value or unit is not present
    if (!concentrationUnit || !concentrationValue || !unit) {
      return "Concentration unit and value is required";
    }

    const concentrationUnitArray = concentrationUnit.split("/");
    const unitArray = unit.split("/");

    // if fType is not continuous
    if (med.frequency && med.frequency.fType !== "continuous") {
      return "Frequency need to be continuous";
    }

    // it unit has weight but weight is not present
    if (unit && unit.split("/").length === 3 && !bodyWeight) {
      return "Weight is required";
    }

    // If one of the unit is 'IU' then other should be 'IU' as well
    if (
      (unitArray[0] === "IU" && concentrationUnitArray[0] !== "IU") ||
      (unitArray[0] !== "IU" && concentrationUnitArray[0] === "IU")
    ) {
      return "Use the similar units to get the rate";
    }

    // If one of the unit is 'mEq' then other should be 'mEq' as well
    if (
      (unitArray[0] === "mEq" && concentrationUnitArray[0] !== "mEq") ||
      (unitArray[0] !== "mEq" && concentrationUnitArray[0] === "mEq")
    ) {
      return "Use the similar units to get the rate";
    }

    // if unit is not of concentration type
    if (validRateUnits.indexOf(unit) === -1) {
      return "Provided unit cannot be used to calculate rate.";
    }

    return null;
  }

  /*
   * NAME: calculateRate
   * PURPOSE: calculates rate for med
   * DESCRIPTION:
   *   - dose is converted to concentration units
   *   - eg. dose = 5 mcg/min, conc. = 10 mg/ml
   *         dose = .005 mg/min
   *   - time is converted to hr and concentration volume is converted to ml
   *   NOTE: rate is always returned in ml/hr
   *
   *   example:
   *     dose = 5 mcg/kg/min, conc = 10 mg/ml, weight = 70
   *     dose = .005 mg/kg/min (to mg)
   *     dose = .35 mg/min (multiply by weight)
   *     dose = 21 mg/hr (to hr)
   *     rate = dose / conc
   *          = 21 / 10
   *          = 2.1 ml/hr
   * PARAMS:
   *   - med:Med - med order
   *   - quantity:number - dose of med
   * RETURNS: string - rate of med
   * USED BY: getRate()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  public calculateRate(med: Med, quantity: string | number): any {
    let dose = quantity;
    const concentrationValue = med.concentration.value;
    const concentrationUnit = med.concentration.unit;
    const unit = med.unit;
    const bodyWeight = med.bodyWeight;
    let rate;

    const concentrationUnitArray = concentrationUnit.split("/");

    const unitArray = unit.split("/");
    // if weight is present multiply it with quantity to get new dose
    dose = unitArray.length === 3 ? +quantity * bodyWeight : quantity;

    if (unitArray[0] !== "ml" && unitArray[0] !== "l") {
      // converts dose weight to match concentration weight (in unit)
      // if conc. is in mg then dose is also converted to mg
      const newDoseWeight = this.convertWeight(
        { value: dose, type: unitArray[0] },
        { value: concentrationValue, type: concentrationUnitArray[0] }
      );

      // converts concentration volume to ml
      const newConcentrationVolume = this.convertVolume({
        value: concentrationValue,
        type: concentrationUnitArray[1],
      });
      // converts dose time to hr
      const newDoseTime = this.convertTime({
        value: newDoseWeight,
        type: unitArray.length === 3 ? unitArray[2] : unitArray[1],
      });

      rate = newDoseTime / newConcentrationVolume;
    } else {
      const newDoseVolume = this.convertVolume({
        value: dose,
        type: unitArray[0],
      });
      rate = this.convertTime({
        value: newDoseVolume,
        type: unitArray.length === 3 ? unitArray[2] : unitArray[1],
      });
    }

    return rate < 1 ? rate.toFixed(4) : rate.toFixed(1);
  }

  /*
   * NAME: isConcentrationUnit
   * PURPOSE: checks whether med unit is concentration unit
   * DESCRIPTION:  if it is concentration unit concentration fields are shown
   * PARAMS: unit:string - med unit
   * RETURNS: boolean
   * USED BY:
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  public isConcentrationUnit(unit: string): boolean {
    return validRateUnits.indexOf(unit) > -1;
  }

  /*
   * NAME: calculateNumberOfDoses
   * PURPOSE: calculate number of doses basis on frequency and noOfDays
   * DESCRIPTION:
   * PARAMS:
   *   frequency:OrderFrequency
   *   days:number
   * RETURNS: number | null
   * USED BY:
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  public calculateNumberOfDoses(
    frequency: OrderFrequency,
    days: number
  ): number | null {
    if (
      frequency?.fType == "every" &&
      (frequency?.days || frequency?.hours || frequency?.mins)
    ) {
      const hrs = +frequency.hours;
      const mins = frequency.mins ? +frequency.mins / 60 : 0;

      const totalHrs = hrs + mins > 24 ? 24 : hrs + mins;

      return Math.ceil((24 / totalHrs) * days);
    } else {
      return null;
    }
  }

  /*
   * NAME: calculateNumberOfDays
   * PURPOSE: calculate number of days basis on frequency and number of doses
   * DESCRIPTION:
   * PARAMS:
   *   frequency:OrderFrequency
   *   dose:number
   * RETURNS: number | null
   * USED BY:
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  public calculateNumberOfDays(
    frequency: OrderFrequency,
    dose: number
  ): number | null {
    if (
      frequency?.fType == "every" &&
      (frequency?.days || frequency?.hours || frequency?.mins)
    ) {
      const hrs = +frequency.hours;
      const mins = frequency.mins ? +frequency.mins / 60 : 0;

      const totalHrs = hrs + mins > 24 ? 24 : hrs + mins;

      const result = Math.ceil(dose / (24 / totalHrs));
      return result == 0 ? 1 : result;
    } else {
      return null;
    }
  }

  /**
   * Checks if unit is a valid unit for continuous frequency
   *
   * @param {string} unit - unit of the med
   * @return {boolean}
   */
  public validUnitForContinuousFrequency(unit: string): boolean {
    return this._validContinuousFrequencyUnits.includes(unit);
  }
}
