<div class="codeblue-ecall-alert-buttons" [class.disabled]="!hasConnection">
  <div class="ecall-button" (click)="raiseEcall()">
    <div class="ecall-text">
      {{ eCallButtonText }}
    </div>
  </div>
  <div
    class="code-blue-button"
    [class.flashing-button]="runnigCodeBlue?.length > 0"
    (click)="raiseCodeBlue()"
  >
    <div class="code-blue-text">
      {{ codeblueButtonText }}
    </div>
  </div>
</div>
