import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromFinalNotes from "./final-notes.reducer";
import * as fromCodeSheetNotes from "./codesheet.reducer";

export interface NoteState {
  finalNote: fromFinalNotes.FinalNoteState;
  codeSheetNote: fromCodeSheetNotes.CodeSheetState;
}

export const reducer: ActionReducerMap<NoteState> = {
  finalNote: fromFinalNotes.reducer,
  codeSheetNote: fromCodeSheetNotes.reducer,
};

export const getNoteState = createFeatureSelector<NoteState>("notesF");

//Final-note state
export const getFinalNoteState = createSelector(
  getNoteState,
  (state: NoteState) => state.finalNote
);

//Final code-sheet-note state
export const getCodeSheetNoteState = createSelector(
  getNoteState,
  (state: NoteState) => state.codeSheetNote
);

export const getAllSignedNotes = createSelector(
  getFinalNoteState,
  fromFinalNotes.getSignedNotes
);

export const getAllPendedNotes = createSelector(
  getFinalNoteState,
  fromFinalNotes.getPendedNotes
);

export const getAllDraftNotes = createSelector(
  getFinalNoteState,
  fromFinalNotes.getDraftNotes
);

export const getAllCodeSheetNotes = createSelector(
  getCodeSheetNoteState,
  fromCodeSheetNotes.getCodeSheetNotes
);

export const getAllCodeSheetDraftNotes = createSelector(
  getCodeSheetNoteState,
  fromCodeSheetNotes.getCodeSheetDraftNotes
);

export const refreshPopUpStaus = createSelector(
  getFinalNoteState,
  fromFinalNotes.gettabState
);

export const loadingStatus = createSelector(
  getFinalNoteState,
  fromFinalNotes.getloadingState
);

export const loadedStatus = createSelector(
  getFinalNoteState,
  fromFinalNotes.getloadedState
);

export const codeSheetStaus = createSelector(
  getCodeSheetNoteState,
  fromCodeSheetNotes.getCodeSheetLoadedState
);
