import { Component, Input } from "@angular/core";

@Component({
  selector: "cp-flag-icons",
  templateUrl: "./flag-icons.component.html",
  styleUrls: ["./flag-icons.component.scss"],
})
export class FlagIconsComponent {
  @Input("flagClass") flagClass: string = "ff-lg";
  @Input("isoCountryCode") isoCountryCode: string = "";
  @Input("borderRadius") borderRadius: string;
}
