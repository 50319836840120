import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "scheduleGenerator"
})
export class ScheduleGeneratorPipe implements PipeTransform {
  transform(med: any, args?: any): any {
    const start = new Date(med.startTime).getTime();
    const now = new Date().getTime();
    const frequency = med.frequency;
    let schedules = [];
    let endTime = null;
    if (med.endTime) {
      endTime = new Date(med.endTime).getTime();
    }
    switch (frequency.fType) {
      case "once":
        schedules.push(
          "Dose at " +
            new DatePipe("en-US").transform(new Date(start), "dd/MM/yy, HH:mm")
        );
        break;
      case "after":
        schedules.push("After " + frequency.timeOfDay);
        break;
      case "before":
        schedules.push("Before " + frequency.timeOfDay);
        break;
      case "every":
        schedules = this.generateSchedule(start, now, frequency, endTime);
      default:
        break;
    }
    return schedules;
  }

  generateSchedule(startTime, now, frequency, endTime) {
    let datepipe = new DatePipe("en-US");
    let schedules = [];
    const frequencyInMilliSec =
      frequency.hours * 3600000 + frequency.mins * 60000;
    let lastDose;
    // lastdose can be starttime or now frequency(when frequency time has passed)
    if (now - frequencyInMilliSec < startTime) {
      lastDose = startTime;
    } else {
      lastDose = now - frequencyInMilliSec;
    }
    let temp;
    //lastdose can be equal to starttime then you need to add to schedules ;
    while (schedules.length < 2 && lastDose >= startTime) {
      temp = datepipe.transform(new Date(lastDose), "d/M/yy, H:mm");
      //unshift to add schedule at beginning of array
      schedules.unshift("past dose at " + temp);
      lastDose = lastDose - frequencyInMilliSec;
    }
    let nextDose = now + frequencyInMilliSec;
    if (endTime != null) {
      while (schedules.length < 3 && nextDose <= endTime) {
        temp = datepipe.transform(new Date(nextDose), "d/M/yy, H:mm");
        schedules.push("next dose at " + temp);
        nextDose = nextDose + frequencyInMilliSec;
      }
    } else {
      while (schedules.length < 3) {
        temp = datepipe.transform(new Date(nextDose), "d/M/yy, H:mm");
        schedules.push("next dose at " + temp);
        nextDose = nextDose + frequencyInMilliSec;
      }
    }
    return schedules;
  }
}
