import { createReducer, on, Action } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";

import {
  FamilyUsersInterface,
  ConsultantUsersInterface,
} from "src/app/models/patient";

import * as fromPatientListActions from "src/app/store/actions/patients/patient-list.action";
import * as rootActions from "src/app/store/actions/root.actions";

interface Patient {
  CPMRN: String;
  ICUAdmitDate: String;
  MRN: String;
  PCP: String;
  age: String;
  bedNo: String;
  camera: String;
  cameraInfo: {
    _id: String;
    name: String;
    remark: String;
    ip: String;
  };
  dob: String;
  encounters: Number;
  hospitalLogo: String;
  hospitalName: String;
  unitName: String;
  hospitalID: string;
  unitID: string;
  commandCenterID: string;
  isCurrentlyAdmitted: Boolean;
  markedToWriteNotes: Boolean;
  isGcs: Boolean;
  isIntubated: {};
  isNIV: {};
  isOrderDiscarded: Boolean;
  isOrderPended: Boolean;
  isPressor: Boolean;
  isTrach: {};
  name: String;
  notes: {
    isPended: Boolean;
    isDraft: Boolean;
  };
  notesData: {};
  orderStates: [];
  patientImage: String;
  phone: String;
  request: {
    type: String;
    url: String;
  };
  sbar: [];
  severity: String;
  sex: String;
  state: String;
  _id: string;
  covid: string;
  assignedUsers?: [];
  profilePicture: string;
  familyUsers?: FamilyUsersInterface[];
  consultantUsers?: ConsultantUsersInterface[];
}

export interface State extends EntityState<Patient> {
  referralIds: string[];
}

export function selectPatientId(a: Patient): string {
  //In this case this would be optional since primary key is _id
  return a._id;
}

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>({
  selectId: selectPatientId,
});

export const initialState: State = adapter.getInitialState({
  referralIds: [],
});

const tranformState = (state, referrals) => {
  if (!referrals?.length) return state;
  return {
    ...state,
    referralIds: [...state.referralIds, ...referrals.map((ref) => ref?._id)],
  };
};

const patientListReducer = createReducer(
  initialState,
  on(fromPatientListActions.setPatients, (state, { patients, referrals }) => {
    return adapter.setAll(
      [...patients, ...(referrals || [])],
      tranformState(state, referrals)
    );
  }),
  on(fromPatientListActions.setOnePatient, (state, { patient }) => {
    return adapter.upsertOne(patient, state);
  }),
  on(fromPatientListActions.setManyPatients, (state, { patients }) => {
    return adapter.upsertMany(patients, state);
  }),
  on(fromPatientListActions.setManyReferral, (state, { referrals }) => {
    return adapter.upsertMany(referrals, tranformState(state, referrals));
  }),
  on(fromPatientListActions.removeOnePatient, (state, { patient }) => {
    return adapter.removeOne(patient["_id"], state);
  }),
  on(fromPatientListActions.resetpatients, (state) =>
    adapter.removeAll({ ...state })
  ),
  on(fromPatientListActions.setDrafts, (state, { draftArray, patients }) => {
    patients.forEach((patient) => {
      draftArray.find((draft) => {
        if (draft.CPMRN === patient.CPMRN) {
          patient["notes"]["isDraft"] = draft["draft"];
        }
      });
    });
    return adapter.updateMany(patients, state);
  }),
  on(rootActions.logout, (state) => adapter.removeAll({ ...state }))
);

const { selectAll } = adapter.getSelectors();

export const getAllPatients = selectAll;

export function reducer(state: State | undefined, action: Action) {
  return patientListReducer(state, action);
}
