import { UntypedFormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

/** Error when the parent is invalid or has a default error value */
export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private errorValue: string = null) {}
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const defaultValidation = control.touched && control.invalid;
    if (this.errorValue)
      return form.hasError(this.errorValue) || defaultValidation;

    return defaultValidation;
  }
}
