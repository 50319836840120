<div class="orders-form-row orders-form-row--divider" [formGroup]="form">
  <mat-form-field
    *ngIf="config?.presetName?.present"
    floatLabel="always"
    hideRequiredMarker="true"
  >
    <mat-label class="order-label">Preset Name*</mat-label>
    <input
      matInput
      class="preset-form-input"
      formControlName="presetName"
      type="text"
      autocomplete="off"
    />
    <mat-error *ngIf="presetName?.hasError('required')"
      >Preset Name is required</mat-error
    >
  </mat-form-field>
</div>
