export const NON_DRUG_CLASS_TO_TEXT_MAP = {
  labs: "Investigation",
  bloods: "Transfusion",
};

// make sure to make it in lower-case
export const NON_DRUG_TEXT_TO_CLASS_MAP = {
  investigation: "labs",
  transfusion: "bloods",
};
