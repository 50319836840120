import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { CdkPortal } from "@angular/cdk/portal";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
})
export class OverlayComponent implements OnDestroy {
  private _overlayTemplateRef: OverlayRef;

  private eventsSubscription: Subscription;

  private routerSubscription: Subscription;

  @Input() hasbackdrop: boolean = true;

  @Input() backdropClass: string;
  @Input() panelClass: string;

  @Input() backdropCloseOnClick: boolean = true;

  @ViewChild("overlayGlobalTemplate")
  templateGlobalPortals: CdkPortal;

  constructor(public overlay: Overlay, private router: Router) {
    this.routerSubscription = router.events.subscribe((val) => {
      this.closeOverlayPanelTemplate();
    });
  }

  public openModal(): void {
    this.showOverlayPanelTemplate();
  }

  public closeModal(): void {
    this.closeOverlayPanelTemplate();
  }

  private showOverlayPanelTemplate(): void {
    const config = new OverlayConfig();
    if (!this.backdropClass && !this.panelClass)
      config.positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    config.hasBackdrop = this.hasbackdrop;
    if (this.backdropClass) config.backdropClass = this.backdropClass;
    if (this.panelClass) config.panelClass = this.panelClass;
    this._overlayTemplateRef = this.overlay.create(config);
    this._overlayTemplateRef.attach(this.templateGlobalPortals);
    this._overlayTemplateRef.keydownEvents().subscribe((keys) => {
      if (keys.code == "Escape") {
        this._overlayTemplateRef.detach();
        this._overlayTemplateRef = null;
      }
    });
    if (this.backdropCloseOnClick) {
      this._overlayTemplateRef.backdropClick().subscribe(() => {
        this._overlayTemplateRef.detach();
        this._overlayTemplateRef = null;
      });
    }
  }

  private closeOverlayPanelTemplate(): void {
    if (this._overlayTemplateRef) {
      this._overlayTemplateRef.detach();
      this._overlayTemplateRef = null;
    }
  }

  ngOnDestroy() {
    this.closeOverlayPanelTemplate();
    this.eventsSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }
}
