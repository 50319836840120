export const frequencyOptions = [ 'once', 'before', 'after', 'every', 'continuous', 'SOS' ];

export const frequencyDisplayTitle = new Map([
  ['continuous', 'Continuous'],
  ['once', 'Once'],
  ['SOS', 'SOS'],
  ['after', 'After'],
  ['before', 'Before'],
  ['every', 'Every']
]);

export interface OrderFrequency {
  fType: string;
  hours: string | null;
  days: string | null;
  mins: string | null;
  timeOfDay: null;
}
