import { AppState } from "src/app/reducers";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromTask from "./task.reducer";

export interface TaskState {
  data: fromTask.State;
}

export interface State extends AppState {
  tasks: TaskState;
}

export const reducers: ActionReducerMap<TaskState, any> = {
  data: fromTask.reducer,
};

export const getTaskState = createFeatureSelector<TaskState>("tasks");

export const getTaskDataState = createSelector(
  getTaskState,
  (state: TaskState) => state.data
);

export const selectTaskEntities = createSelector(
  getTaskDataState,
  fromTask.selectTaskEntities
);

export const selectTaskById = createSelector(
  selectTaskEntities,
  (taskEntities, id) => taskEntities[id]
);
