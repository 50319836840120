import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogoService {
  constructor(private _http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "Server error");
  }

  /**
   * Get signed url for logo upload
   * @returns {Observable}
   */
  getSignedUrl(dataToSend: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/upload_logo`, dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * Delete the file from s3
   * @returns {Observable}
   */
  deleteLogo(dataToSend: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/delete_logo`, dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * @description To get the image url for the s3 public bucket
   * @param {string} key - s3 key
   * @returns {string}
   * @author Suraj shenoy
   * @date Sep 23 2021
   */
  formLogoUrl(key: string): string {
    return `https://${environment.s3bucket}.s3.${environment.s3region}.amazonaws.com/${key}`;
  }

  getLogoKey(url: string): string {
    let splitUrl = url.split(
      `https://${environment.s3bucket}.s3.${environment.s3region}.amazonaws.com/`
    );

    if (!splitUrl[1]) {
      splitUrl = url.split(
        `https://s3.${environment.s3region}.amazonaws.com/${environment.s3bucket}/`
      );
    }

    return splitUrl[1];
  }
}
