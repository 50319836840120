import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";

import {
  map,
  filter,
  mergeMap,
  switchMap,
  catchError,
  exhaustMap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { isEmpty } from "lodash-es";

import {
  MarSOSActions,
  MarDietActions,
  MarCommonActions,
  MarNormalActions,
  MarStatusActions,
  MarInfusionActions,
} from "@mar/store/actions/index";

import { MarService } from "@mar/services/mar.service";
import { AlertService } from "src/app/iris-components/service/alert.service";

@Injectable()
export class MarEffects {
  constructor(
    private actions$: Actions,
    private service: MarService,
    private _alertService: AlertService
  ) {}

  getMar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarCommonActions.getMar),
      filter(({ cpmrn, encounter }) => cpmrn != null || encounter != null),
      exhaustMap(({ cpmrn, encounter }) =>
        this.service.getAllMar(cpmrn, encounter).pipe(
          mergeMap((res: any) => {
            let dispatchActions: Array<any> = [
              MarStatusActions.setLoader({ loading: false }),
            ];

            if (res?.data?.normal) {
              if (isEmpty(res?.data?.normal)) {
                dispatchActions.push(MarNormalActions.resetMarNormal());
              } else {
                let normalMAR = [];

                for (const key in res?.data.normal) {
                  normalMAR.push({
                    day: key,
                    records: res?.data.normal[key],
                  });
                }

                dispatchActions.push(
                  MarNormalActions.setMarNormal({ mar: normalMAR })
                );
              }
            }

            if (res?.data?.sos) {
              if (isEmpty(res?.data?.sos)) {
                dispatchActions.push(MarSOSActions.resetMarSOS());
              } else {
                let sosMAR = [];
                for (const key in res?.data.sos) {
                  sosMAR.push({
                    day: key,
                    records: res?.data.sos[key],
                  });
                }

                dispatchActions.push(MarSOSActions.setMarSOS({ mar: sosMAR }));
              }
            }

            if (res?.data?.diet) {
              if (isEmpty(res?.data?.diet)) {
                dispatchActions.push(MarDietActions.resetMarDiet());
              } else {
                let dietMAR = [];
                for (const key in res?.data.diet) {
                  dietMAR.push({
                    day: key,
                    records: res?.data.diet[key],
                  });
                }

                dispatchActions.push(
                  MarDietActions.setMarDiet({ mar: dietMAR })
                );
              }
            }

            if (res?.data?.infusion) {
              if (isEmpty(res?.data?.infusion)) {
                dispatchActions.push(MarInfusionActions.resetMarInfusion());
              } else {
                let InfusionMAR = [];

                for (const key in res?.data.infusion) {
                  InfusionMAR.push({
                    day: key,
                    records: res?.data.infusion[key],
                  });
                }

                dispatchActions.push(
                  MarInfusionActions.setMarInfusion({ mar: InfusionMAR })
                );
              }
            }

            return dispatchActions;
          }),
          catchError((error: any) => {
            console.error(error);
            this._alertService.showNotification({
              type: "Error",
              message: "Error in fetching mar data",
            });
            return EMPTY;
          })
        )
      )
    )
  );

  checkForInfusionCompletion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MarInfusionActions.checkForInfusionCompletion),
        filter(({ cpmrn, encounter }) => cpmrn != null || encounter != null),
        switchMap(({ cpmrn, encounter }) =>
          this.service.checkForInfusionCompletion(cpmrn, encounter).pipe(
            catchError((error: any) => {
              console.error(error);
              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateMarSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarCommonActions.updateMarSchedule),
      switchMap(({ marId, schedule }) =>
        this.service.updateSchedule(marId, schedule).pipe(
          mergeMap((res: any) => {
            let dispatchActions: Array<any> = [
              MarStatusActions.setLoader({ loading: false }),
            ];

            if (res?.data?.normal) {
              let normalMAR = [];

              for (const key in res.data.normal) {
                normalMAR.push({
                  day: key,
                  records: res.data.normal[key],
                });
              }

              dispatchActions.push(
                MarNormalActions.upsertMarNormal({ mar: normalMAR })
              );
            }

            if (res?.data?.sos) {
              let sosMAR = [];
              for (const key in res.data.sos) {
                sosMAR.push({
                  day: key,
                  records: res.data.sos[key],
                });
              }

              dispatchActions.push(MarSOSActions.upsertMarSOS({ mar: sosMAR }));
            }

            if (res?.data?.diet) {
              let dietMAR = [];
              for (const key in res.data.diet) {
                dietMAR.push({
                  day: key,
                  records: res.data.diet[key],
                });
              }

              dispatchActions.push(
                MarDietActions.upsertMarDiet({ mar: dietMAR })
              );
            }

            this._alertService.showNotification({
              type: "Success",
              message: "Updated schedule data",
            });
            return dispatchActions;
          }),
          catchError((error: any) => {
            console.error(error);
            this._alertService.showNotification({
              type: "Error",
              message: "Error in updating mar data",
            });
            return EMPTY;
          })
        )
      )
    )
  );

  updateMarInfusionSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarInfusionActions.updateMarInfusionSchedule),
      switchMap(({ marId, schedule }) =>
        this.service.updateInfusionSchedule(marId, schedule).pipe(
          mergeMap((res: any) => {
            if (!isEmpty(res?.data?.infusion)) {
              let InfusionMAR = [];

              for (const key in res.data.infusion) {
                InfusionMAR.push({
                  day: key,
                  records: res.data.infusion[key],
                });
              }

              this._alertService.showNotification({
                type: "Success",
                message: "Schedule updated",
              });
              return [
                MarInfusionActions.upsertMarInfusion({ mar: InfusionMAR }),
                MarStatusActions.setLoader({ loading: false }),
              ];
            } else {
              this._alertService.showNotification({
                type: "Success",
                message: "Schedule updated",
              });
              return [
                MarInfusionActions.resetMarInfusion(),
                MarStatusActions.setLoader({ loading: false }),
              ];
            }
          }),
          catchError((error: any) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Error in updating mar data",
            });

            return EMPTY;
          })
        )
      )
    )
  );

  getMarDiscontinued$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MarCommonActions.getDischargedMar),
      filter(({ cpmrn, encounter }) => cpmrn !== null || encounter !== null),
      exhaustMap(({ cpmrn, encounter, marType }) =>
        this.service.getAllMarDiscontinued(cpmrn, encounter, marType).pipe(
          map((res: any) => {
            return MarCommonActions.upsertInactiveMar({ mar: res.data });
          }),
          catchError((error: any) => {
            console.error(error);

            this._alertService.showNotification({
              type: "Error",
              message: "Error in fetching discontinued mar data",
            });

            return EMPTY;
          })
        )
      )
    );
  });
}
