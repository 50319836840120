import { Pipe, PipeTransform, inject } from "@angular/core";
import { TimezoneService } from "src/app/services/timezone.service";

@Pipe({
  name: "timezone",
})
export class TimezonePipe implements PipeTransform {
  private _tz = inject(TimezoneService);

  transform(value: string, ...args): unknown {
    if (!value) return;
    if (!args?.length) return value;

    const [formatToBe = "DD-MM-YYYY", timeZone] = args;
    return this._tz
      .transformIntoTimezoneObj(value, timeZone || undefined)
      .format(formatToBe);
  }
}
