import { Pipe, PipeTransform } from "@angular/core";
import { find } from "lodash-es";

@Pipe({ name: "canEditOrder" })
export class CanEditOrderPipe implements PipeTransform {
  transform(
    orders: any,
    replaceOrderId: string,
    role: string,
    type: string
  ): boolean {
    const ordersByType =
      orders["pending"] && orders["pending"][type]
        ? orders["pending"][type]
        : [];

    return !!!find(
      ordersByType,
      (order) => order.replaceOrderId === replaceOrderId
    );
  }
}
