<section class="iris-modal-container">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2 title">Create/Edit plan</div>
    <div class="col-1-of-2">
      <mat-icon class="cp-cursor-pointer" (click)="onClose()">close</mat-icon>
    </div>
  </div>
  <form [formGroup]="ncpForm" class="iris-form">
    <div formGroupName="diagnose">
      <div class="iris-form-row">
        <mat-form-field class="cp-input-mat" floatLabel="always">
          <mat-label class="cp-text-normal">Select diagnoses *</mat-label>
          <cp-searchable-dropdown
            formClass="cp-input-mat"
            [formControl]="diagnosisName"
            panelClass="cp-ncp-dropdown"
            placeholder="Select diagnoses"
            [data]="diagnosisData | dropdownTransform: true"
            multiple
            [previousSelectedValues]="selectedDiagnose"
            [isOther]="
              isOther ? 'Other' + '(' + diagnosisValue.value + ')' : ''
            "
          >
            <div ncp *ngIf="isOther">
              <div class="input-div">
                <input
                  #otherInput
                  [formControl]="diagnosisValue"
                  (blur)="onOtherInputChange()"
                  class="iris-input input-height"
                  placeholder="Enter diagnosis here"
                  required
                  (keydown)="$event.stopImmediatePropagation()"
                />
              </div>
              <div class="info-text">
                <div class="info-text1">
                  Use a comma ( , ) to add multiple diagnoses.
                </div>
                <div>Eg: Diagnosis 1, Diagnosis 2, Diagnosis 3</div>
              </div>
            </div>
          </cp-searchable-dropdown>
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="iris-form-row">
      <mat-form-field class="cp-input-mat" floatLabel="always">
        <mat-label class="cp-text-normal">Select parameters *</mat-label>
        <cp-searchable-dropdown
          formClass="cp-input-mat"
          [formControl]="params"
          panelClass="cp-ncp-dropdown"
          placeholder="Select parameters"
          [data]="ncpParams | dropdownTransform: true"
          [searchPlaceholder]="'Search diagnoses'"
          multiple
          [previousSelectedValues]="tempSelectedParams"
        >
          <!-- <div class="dropdown-label">
          <span class="cp-text--normal">Select parameters *</span>
        </div> -->
        </cp-searchable-dropdown>
      </mat-form-field>
    </div>

    <div class="description mt">
      You can select the diagnoses and the parameters you want to include in the
      care plan.
    </div>

    <mat-dialog-actions align="end">
      <div *ngIf="false" class="iris-error mr">
        {{ serverError }}
      </div>
      <button
        type="button"
        class="button-secondary"
        mat-flat-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        type="submit"
        class="button-primary"
        [disabled]="!ncpForm.valid"
        mat-flat-button
        (click)="Submit()"
      >
        Create
        <app-loader [buttonLoader]="true" *ngIf="false"></app-loader>
      </button>
    </mat-dialog-actions>
  </form>
</section>
