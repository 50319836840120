import { Pipe, PipeTransform } from "@angular/core";
import { WorkSpace } from "src/app/models/workspace.model";

@Pipe({
  name: "unitListFilter",
})
export class UnitListFilterPipe implements PipeTransform {
  transform(workspace: WorkSpace, ...filterArgs: any) {
    const { unitIds } = workspace;
    if (!unitIds?.length) return [];
    const workspaceName = workspace.name.toLocaleLowerCase();
    const filterText = filterArgs[0].toLowerCase();
    const unitIdMapping = filterArgs[1];

    const unitsList = unitIds
      .map((id) => unitIdMapping[id])
      .filter((unit) => !!unit);
    let updatedUnitsList = unitsList.sort((a, b) =>
      a.hospitalInfo?.name.localeCompare(b.hospitalInfo?.name)
    );
    const usersFilter = [
      "docAssigned",
      "npAssigned",
      "rnAssigned",
      "ccrnAssigned",
      "specialistAssigned",
      "ccaPhysicianAssigned",
    ];

    updatedUnitsList = updatedUnitsList.filter((unit) => {
      const isMatchingUser = () =>
        usersFilter.some((user) =>
          unit[user]?.name?.toLowerCase()?.includes(filterText)
        );
      const isHospitalName = unit?.hospitalInfo?.name
        ?.toLowerCase()
        ?.includes(filterText);
      const isWorkspaceName = workspaceName.includes(filterText);
      const isHidden = !(isHospitalName || isWorkspaceName || isMatchingUser());
      unit.isHidden = isHidden;
      return unit;
    });

    // sorting uni-list : all assigned on top and all un-assigned on bottom
    updatedUnitsList = updatedUnitsList.sort(
      (a, b) => b.assignButtonText.length - a.assignButtonText.length
    );

    return updatedUnitsList;
  }
}
