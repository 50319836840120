import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import {
  TranslateInterface,
  ZoomScaleInterace,
} from "../model/zoom-transform.model";

@Injectable()
export class ZoomTransformService {
  private _zoomImageInSubject = new Subject();
  public $zoomInImage = this._zoomImageInSubject.asObservable();

  private _zoomImageOutSubject = new Subject();
  public $zoomOutImage = this._zoomImageOutSubject.asObservable();

  private _translateStart = new Subject();
  public $translateStart = this._translateStart.asObservable();

  private _translateSubject = new Subject<TranslateInterface>();
  public $translateImage = this._translateSubject.asObservable();

  private _zoomImageSubject = new Subject<ZoomScaleInterace>();
  public $zoomImage = this._zoomImageSubject.asObservable();

  public zoomImage({ zoomMove, zoomLocation }: ZoomScaleInterace): void {
    this._zoomImageSubject.next({ zoomMove, zoomLocation });
  }

  public zoomInImage(): void {
    this._zoomImageInSubject.next();
  }

  public zoomOutImage(): void {
    this._zoomImageOutSubject.next();
  }

  public translateStart(): void {
    this._translateStart.next();
  }

  public translateImage(deltaX: number, deltaY: number): void {
    this._translateSubject.next({ deltaX, deltaY });
  }
}
