import * as MarSOSActions from "./mar-sos.actions";
import * as MarDietActions from "./mar-diet.actions";
import * as MarNormalActions from "./mar-normal.actions";
import * as MarCommonActions from "./mar-common.actions";
import * as MarStatusActions from "./mar-status.actions";
import * as MarInfusionActions from "./mar-infusion.actions";

export {
  MarCommonActions,
  MarNormalActions,
  MarInfusionActions,
  MarStatusActions,
  MarSOSActions,
  MarDietActions,
};
