import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";

import * as formData from "../../../form.data";
import { UtilService } from "../../../../services/util.service";
import { BloodPresetBE } from "../../../../models/preset/BloodPreset.model";

@Component({
  selector: "app-blood-form",
  templateUrl: "./blood-form.component.html",
  styleUrls: ["./blood-form.component.scss"],
})
export class BloodFormComponent implements OnInit {
  @Input() value?: BloodPresetBE;
  @Input() config: any;
  @Input() formType: string;
  @Input() showBedsideForm = true;

  units: string[] = formData.bloodQuantityUnits;

  public titles = formData.bloodTitle;

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {}

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for presetName field.
   *
   * @returns {AbstractControl}
   */
  get presetName(): AbstractControl {
    return this.form.get("presetName");
  }

  /**
   * Get accessor for title field.
   *
   * @returns {AbstractControl}
   */
  get title(): AbstractControl {
    return this.form.get("title");
  }

  /**
   * Get accessor for quantity field.
   *
   * @returns {AbstractControl}
   */
  get quantity(): AbstractControl {
    return this.form.get("quantity");
  }

  /**
   * Get accessor for quantityUnit field.
   *
   * @returns {AbstractControl}
   */
  get quantityUnit(): AbstractControl {
    return this.form.get("quantityUnit");
  }

  /**
   * If bedside is checked then resets home med checkbox.
   */
  onBedsideChange() {
    this.form
      .get("bedsideOrder")
      .patchValue(!!!this.form.get("bedsideOrder").value);
  }
}
