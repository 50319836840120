import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-context-menu-comment-modal",
  template: `
    <div class="context-modal-popup">
      <div class="header">
        <div class="title" id="title">{{ system }}</div>
        <img
          class="cp-cursor-pointer"
          src="assets/icons/close.svg"
          (click)="onCancel()"
        />
      </div>
      <div class="body">
        <div class="form-group d-flex flex-column">
          <mat-form-field floatLabel="always">
            <mat-label for="diagnosis">Diagnosis</mat-label>
            <input
              matInput
              type="text"
              name="diagnosis"
              id="diagnosis"
              class="iris-input"
              [(ngModel)]="diagnosis"
              #diagnosisInput="ngModel"
              maxlength="500"
            />
          </mat-form-field>
        </div>
        <div class="form-group d-flex flex-column">
          <mat-form-field floatLabel="always">
            <mat-label for="comment">Comment</mat-label>
            <input
              matInput
              type="text"
              name="comment"
              id="comment"
              class="iris-input"
              [(ngModel)]="comment"
              #commentInput="ngModel"
              maxlength="500"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="footer">
        <button
          mat-flat-button
          type="submit"
          class="button-primary"
          [mat-dialog-close]="{ comment: comment, diagnosis: diagnosis }"
          [disabled]="
            (!diagnosis && !comment) || (!diagnosis?.trim() && !comment?.trim())
          "
        >
          Submit
        </button>
        <button
          mat-flat-button
          class="button-secondary"
          type="button"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  `,
  styleUrls: ["./context-menu-comment-modal.component.scss"],
})
export class ContextMenuCommentModalComponent {
  @Input() system: string;
  public comment: string;
  public diagnosis: string;

  constructor(
    public dialogRef: MatDialogRef<ContextMenuCommentModalComponent>
  ) {}

  onCancel() {
    this.dialogRef.close();
  }
}
