import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { ECallService } from "src/app/services/e-call.service";
import { BedUnitInfoInterface } from "../bed-unit-info";
import { headerColors } from "../bed-unit-info/bed-unit-info.model";

@Component({
  selector: "cp-e-call-popup",
  templateUrl: "./e-call-popup.component.html",
  styleUrls: ["./e-call-popup.component.scss"],
})
export class ECallPopupComponent {
  @Input() contextName: string = "";

  public ECallPopupContext: any = {
    ECall: "eCall",
    CodeBlue: "CodeBlue",
  };

  private ContextName = {
    eCall: "call",
    CodeBlue: "code_blue",
  };

  public get headColor(): string {
    if (this.contextName == this.ECallPopupContext.CodeBlue) {
      return headerColors.BLUE;
    }
    if (this.contextName == this.ECallPopupContext.ECall) {
      return headerColors.DEFAULT;
    }
    return "";
  }

  constructor(
    private activeModal: MatDialogRef<ECallPopupComponent>,
    private _eCallService: ECallService,
    private _alertService: AlertService
  ) {}

  handleSubmit(data: BedUnitInfoInterface) {
    const { hospitalName, unitName, deviceId, bedNo } = data;
    const payload = {
      message: this.ContextName[this.contextName],
      bedNo,
      deviceId,
    };

    this._eCallService.postEcall(payload).subscribe(
      (res) => {
        this.activeModal.close();
        this._alertService.showNotification({
          type: "Success",
          message: `Successfully raised rAlert for ${hospitalName} - ${unitName}`,
        });
      },
      (error) => {
        this._alertService.showNotification({
          type: "Error",
          message: `Server error!`,
        });
      }
    );
  }
}
