import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AuthService } from "../services/auth.service";
import { Location } from "@angular/common";

import { environment } from "src/environments/environment";

import * as fromUserReducer from "src/app/store/reducers/user";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as authActions from "src/app/store/actions/auth.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-role",
  templateUrl: "./change-role.component.html",
  styleUrls: ["./change-role.component.scss"],
})
export class ChangeRoleComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();
  public user$ = this.store.pipe(
    select(fromUserReducer.getCurrUser),
    takeUntil(this.unsubscribe$)
  );

  public currentUser;
  public showPasswordC: boolean = false;
  public changeRoleForm: UntypedFormGroup;

  public serverError: string = null;
  public isSubmitted: boolean = false;

  /* ICONS */
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  constructor(
    private _auth: AuthService,
    private _fb: UntypedFormBuilder,
    private store: Store<{}>,
    private _router: Router,
    private _location: Location
  ) {
    this.changeRoleForm = this.formBuilder();
  }

  formBuilder(): UntypedFormGroup {
    return this._fb.group({
      role: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.user$.subscribe((data) => {
      this.currentUser = data;
    });
  }

  onClose() {
    // this.activeModal.dismiss();
  }

  changeRole(userInfo) {
    this.isSubmitted = true;

    let user = {
      email: this.currentUser.email,
      password: userInfo.password
        ? this._auth.encryptPassword(userInfo.password)
        : null,
      role: userInfo.role,
    };

    this._auth.changeRole(user).subscribe(
      (data) => {
        if (data.success) {
          // reset the error
          this.serverError = null;

          let newUserDetails = { ...data.user, currentUserState: true };

          // set / reset localstorage
          localStorage.setItem("token", data.token);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("currentUser", JSON.stringify(newUserDetails));
          localStorage.removeItem("hospitalFilters");
          localStorage.removeItem("assignedPatientsFilter");

          this._auth.triggerRoleChange().subscribe(
            (response) => {
              // Refresh the page
              setTimeout(() => {
                location.reload();
              }, 200);
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        this.isSubmitted = false;
        this.serverError = err.error.message;

        // If user locked
        if (err.error.message === "Auth failed! User Locked") {
          this.onClose();
          this.store.dispatch(rootActions.logout());
          this.store.dispatch(
            authActions.authError({
              status: err.status,
              message: "User Locked",
            })
          );
        }
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe the observable
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
