import { Subject } from "rxjs";
import { pick } from "lodash-es";
import { take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ContextMenuService } from "ngx-contextmenu";

import * as fromUser from "../store/reducers/user";
import { ContextMenuContent } from "../models/contextMenu/contextMenu.model";
import { VentOrderBE } from "../models/vital/vent/Vent.model";

@Injectable({ providedIn: "root" })
export class CpContextMenuService {
  private dataSource = new Subject<any>();

  constructor(
    private _contextMenuService: ContextMenuService,
    private store: Store<fromUser.State>
  ) {}

  public data$ = this.dataSource.asObservable();

  public onContextMenu($event: MouseEvent, item: ContextMenuContent): void {
    const data: ContextMenuContent = {
      ...item,
      content: this.filterData(item),
    };

    this.store
      .pipe(select(fromUser.getUserPreferenceRoundar), take(1))
      .subscribe((roundar) => {
        if (roundar.on) {
          this.dataSource.next({ event: $event, item: data });
          $event.preventDefault();
          $event.stopPropagation();
        }
      });
  }

  private filterData(data: ContextMenuContent) {
    if (data.type === "order:med") {
      return pick(data.content, [
        "name",
        "quantity",
        "unit",
        "combination",
        "day",
      ]);
    } else if (data.type === "order:diet") {
      return pick(data.content, ["name", "day"]);
    } else if (data.type === "order:blood") {
      return pick(data.content, ["title", "quantity", "day"]);
    } else if (data.type === "order:comm") {
      return pick(data.content, ["title", "day"]);
    } else if (data.type === "order:lab") {
      return pick(data.content, ["investigation", "day"]);
    } else if (data.type === "order:procedure") {
      return pick(data.content, ["name", "day"]);
    } else if (data.type === "io:hour") {
      return { ...data.content, data: this.transformIO(data.content.data) };
    } else if (data.type === "vital:minute") {
      return {
        ...data.content,
        data: this.transformVitalMinute(data.content.data),
      };
    } else if (data.type === "order:vent") {
      return this.transformVentOrder(data.content);
    } else {
      return data.content;
    }
  }

  private transformIO(data) {
    let result = [];

    if (data.val || data.val == 0) {
      return [];
    }

    for (let key of Object.keys(data)) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      result.push({
        key,
        children: this.transformIO(data[key]),
        value: data[key].val,
      });
    }

    return result;
  }

  private transformVitalMinute(vital: any) {
    const result = [
      { key: "Temp", value: vital.daysTemperature + vital.daysTemperatureUnit },
      { key: "HR", value: vital.daysHR },
      { key: "RR", value: vital.daysRR },
      { key: "BP", value: vital.daysBP },
      { key: "MAP", value: vital.daysMAP },
      { key: "CVP", value: vital.daysCVP },
      {
        key: "SpO2 (FiO2)",
        value: vital.daysSpO2
          ? vital.daysSpO2 + (vital.daysFiO2 ? `(${vital.daysFiO2})` : "")
          : null,
      },
      { key: "AVPU", value: vital.daysAVPU },
      { key: "Fall", value: vital.daysFalls },
      { key: "Braden", value: vital.daysBraden },
      { key: "Pain", value: vital.daysPain },
      {
        key: "GCS (EVM)",
        value: vital.daysGCS
          ? vital.daysGCSverbal === 0
            ? `${vital.daysGCS}T (${vital.daysGCSeyes}T${vital.daysGCSmotor})`
            : `${vital.daysGCS} (${vital.daysGCSeyes}${vital.daysGCSverbal}${vital.daysGCSmotor})`
          : null,
      },
      { key: "RASS", value: vital.daysRass },
      {
        key: "CAM-ICU",
        value: vital.daysRass
          ? vital.daysRassCAMICU
            ? "Positive"
            : "Negative"
          : null,
      },
      {
        key: "Airway",
        value: this.getAirwayTextField(vital.daysVentAirway, vital.daysIntType),
      },
      { key: "Tube", value: vital.daysVentTube },
      { key: "Tube Size", value: vital.daysVentTubeSize },
      { key: "Oral Airway", value: vital.daysVentOralAirway },
      { key: "Type", value: vital.daysVentType },
      { key: "Mode", value: vital.daysVentMode },
      { key: "Sub-Mode", value: vital.daysVentSubMode },
      { key: "Vt", value: vital.daysVentVt },
      { key: "RRset", value: vital.daysVentRRset },
      { key: "PEEP/EPAP", value: vital.daysVentPEEP },
      { key: "FiO2", value: vital.daysFiO2 },
      { key: "Flow Rate", value: vital.daysFlowRate },
      { key: "Pinsp/IPAP", value: vital.daysVentPinsp },
      { key: "PS", value: vital.daysVentPsupport },
      { key: "Pplat", value: vital.daysVentPplat },
      { key: "Ppeak", value: vital.daysVentPpeak },
      { key: "MAP", value: vital.daysVentMAP },
      { key: "MV", value: vital.daysVentMV },
      { key: "RRtotal", value: vital.daysRR },
      {
        key: "I:E",
        value: vital.daysVentE
          ? `${vital.daysVentI}:${vital.daysVentE}`
          : vital.daysVentT,
      },
    ];

    return result.filter((obj) => {
      if (!obj.value || obj.value === "") {
        return false;
      }

      return true;
    });
  }

  private transformVentOrder(vent: VentOrderBE) {
    return {
      attributes: [
        {
          key: "Airway",
          value: this.getAirwayTextField(vent.airway, vent.intType),
        },
        { key: "Tube", value: vent.tube },
        { key: "Tube Size", value: vent.tubeSize },
        { key: "Oral Airway", value: vent.oralAirway },
        { key: "Type", value: vent.ventType },
        { key: "Mode", value: vent.mode },
        { key: "Sub-Mode", value: vent.subMode },
        { key: "Vt", value: vent.vt },
        { key: "RRset", value: vent.rrset },
        { key: "PEEP/EPAP", value: vent.peep },
        { key: "FiO2", value: vent.fio2 },
        { key: "Flow Rate", value: vent.flowRate },
        { key: "Pinsp/IPAP", value: vent.pinsp },
        { key: "PS", value: vent.psupport },
        { key: "Pplat", value: vent.pplat },
        { key: "Ppeak", value: vent.ppeak },
        { key: "MAP", value: vent.map },
        { key: "MV", value: vent.mv },
        { key: "RRtotal", value: vent.rrtot },
        {
          key: "I:E",
          value: vent.expiratory
            ? `${vent.inspiratory}:${vent.expiratory}`
            : null,
        },
      ],
      discontinueReasons: vent.discontinueReasons,
      discontinueReasonStatement: vent.discontinueReasonStatement,
      bedsideOrder: vent.bedsideOrder,
      signed: vent.signed,
      state: vent.state,
      day: vent.day,
      category: vent.category,
    };
  }

  public getAirwayTextField(airway: string, intType: string): string {
    let airwayText = airway;

    if (airway === "High Flow") {
      airwayText = "High Flow Cannula";
    } else if (airway === "Venturi") {
      airwayText = "Venturi Mask";
    } else if (airway === "Cannula") {
      airwayText = "Nasal Cannula";
    }

    if (intType) {
      return airwayText + ` (${intType})`;
    }

    return airwayText;
  }
}
