import { Injectable } from "@angular/core";
import {
  AsyncValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PatternValidator {
  private patterns: { [key: string]: string[] } = {};

  setPatterns(controlName: string, patterns: any[]) {
    this.patterns[controlName] = patterns;
  }

  validate(controlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const patterns = this.patterns[controlName];
      if (patterns) {
        const patternMatch = patterns.some((pattern) =>
          new RegExp(pattern).test(control.value)
        );
        return of(patternMatch ? null : { pattern: true });
      } else {
        return of(null);
      }
    };
  }
}
