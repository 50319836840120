import { Mar } from "@mar/models/mar.model";
import { createReducer, on, Action } from "@ngrx/store";
import { MarCommonActions } from "@mar/store/actions/index";

export interface State {
  normal: Mar[];
  sos: Mar[];
  diet: Mar[];
  infusion: Mar[];
}

const initialState: State = {
  normal: [],
  sos: [],
  diet: [],
  infusion: [],
};

const discontinedMarReducer = createReducer(
  initialState,
  on(MarCommonActions.setInactiveMar, (state, { mar }) => mar),
  on(MarCommonActions.upsertInactiveMar, (state, { mar }) => {
    return { ...state, ...mar };
  }),
  on(MarCommonActions.setInactiveMarByType, (state, { mar, marType }) => {
    if (marType == "ci") {
      return { ...state, infusion: mar };
    } else {
      return { ...state, [marType]: mar };
    }
  }),
  on(MarCommonActions.resetInactiveMar, () => initialState)
);

export function reducer(state: State, action: Action) {
  return discontinedMarReducer(state, action);
}
