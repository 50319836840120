import {
  PatientType,
  PatientWeightHistory,
  WeightObj,
} from "../models/patient";

const WEIGHT_UNITS = {
  kg: "kg",
  gram: "g",
};

export interface CALCULATE_WEIGHT {
  weightObj: WeightObj;
  patientType: string;
  weightInGrams?: boolean;
  weight?: number;
  addUnits?: boolean;
}

export interface CALCULATE_CURRENT_WEIGHT {
  weightHistory: [PatientWeightHistory];
  weightObj: WeightObj;
  patientType: string;
  weightInGrams?: boolean;
  weight?: number;
  addUnits?: boolean;
  returnWeightHistoryObj?: boolean;
}

export const calculateWeight = ({
  weightObj,
  patientType,
  weightInGrams = false,
  weight,
  addUnits = true,
}: CALCULATE_WEIGHT) => {
  if (!weightObj || !Object.keys(weightObj)?.length) return null;

  if (patientType == PatientType.Neonatal) {
    const wInGrams = +(weightObj?.kg || 0) * 1000 + +weightObj?.gram;
    return weightInGrams ? (addUnits ? wInGrams + " g" : wInGrams) : weight;
  }

  if (addUnits) {
    let weightString = "";
    for (const [key, value] of Object.entries(weightObj)) {
      +value > 0 && (weightString += `${+value} ${WEIGHT_UNITS[key]} `);
    }

    return weightString;
  }

  return weight;
};

export const calculateCurrentWeight = ({
  weightHistory,
  weightObj,
  patientType,
  weightInGrams = false,
  weight = null,
  addUnits = true,
  returnWeightHistoryObj = false,
}: CALCULATE_CURRENT_WEIGHT) => {
  const recentW = weightHistory?.find((w) => w.weightKg);
  if (!recentW) {
    return returnWeightHistoryObj
      ? [
          calculateWeight({
            weightObj,
            patientType,
            weightInGrams,
            weight,
            addUnits,
          }),
        ]
      : calculateWeight({
          weightObj,
          patientType,
          weightInGrams,
          weight,
          addUnits,
        });
  }

  let weightToReturn;
  const wInGrams = Math.round(recentW["weightKg"] * 1000);
  weightToReturn = weightInGrams
    ? addUnits
      ? wInGrams + " g"
      : wInGrams
    : +recentW["weightKg"];

  return returnWeightHistoryObj ? [weightToReturn, recentW] : weightToReturn;
};
