import { createReducer, on, Action } from "@ngrx/store";

import * as patientFormActions from "src/app/store/actions/patient-chart/patient-header/patient-form.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  // loadtype: indicates what is the type of content which is yet to be loaded
  loadType: string;
  loading: boolean;
  error: any;
  isFormSubmitted: boolean;

  // isNewPatient: if true, show success screens else don't
  isNewPatient: boolean;
  showSuccessScreenOne: boolean;
  showSuccessScreenTwo: boolean;
  currentPage: number;
  covidStatus: any;
}

const resetCommon = {
  loading: false,
  loadType: null,
  error: null,
  isFormSubmitted: false,
};

const initialState: State = {
  ...resetCommon,
  isNewPatient: false,
  currentPage: 1,
  showSuccessScreenOne: false,
  showSuccessScreenTwo: false,
  covidStatus: null,
};

const patientFormReducer = createReducer(
  initialState,
  on(patientFormActions.submitForm, (state, { loadType }) => ({
    ...state,
    loading: true,
    loadType: loadType,
  })),
  on(patientFormActions.submitSuccess, (state) => ({
    ...state,
    ...resetCommon,
    isFormSubmitted: true,
  })),
  on(patientFormActions.submitFail, (state, { error }) => ({
    ...state,
    ...resetCommon,
    error: error,
  })),
  on(patientFormActions.updateLoaderStatus, (state, { loadType, loading }) => ({
    ...state,
    loading,
    loadType,
  })),
  on(patientFormActions.updateAdmitStatus, (state, { isNewPatient }) => ({
    ...state,
    isNewPatient,
  })),
  on(patientFormActions.showSuccessScreenOne, (state, { status }) => ({
    ...state,
    showSuccessScreenOne: status,
  })),
  on(patientFormActions.showSuccessScreenTwo, (state, { status }) => ({
    ...state,
    showSuccessScreenTwo: status,
  })),
  on(patientFormActions.updateCurrentPage, (state, { currentPage }) => {
    if (currentPage) return { ...state, currentPage };
    else return { ...state, currentPage: state.currentPage + 1 };
  }),
  on(patientFormActions.updateCovidStatus, (state, { covidStatus }) => {
    if (covidStatus) return { ...state, covidStatus };
    else return { ...state, covidStatus: covidStatus };
  }),
  on(patientFormActions.formClose, (state) => initialState),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return patientFormReducer(state, action);
}
