export const GRAPH_MARGINS_NEW_CHART = {
  top: 33,
  right: 84,
  bottom: 65,
  left: 77,
};

export const TRENDS_DEFAULT_WIDTH = 1210;
export const TRENDS_DEFAULT_HEIGHT = 478;

export const GROWTH_TRENDS_HEIGHT = 590;
export const GROWTH_TRENDS_WIDTH = 1210;

export const GROWTH_MODAL_HEIGHT = "761px";
export const GROWTH_MODAL_WIDTH = "1430px";

export const LAB_TRENDS_HEIGHT = 496;
export const LAB_TRENDS_WIDTH = 1165;
