import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import {
  API,
  Cosign,
  CosignStatusType,
  SubmitTypes,
} from "@notes/models/notes.model";
import * as fromloaderStateReducer from "@shared-modules/store";
import * as fromNoteStore from "@notes/store";
import { take } from "rxjs/operators";
import { LoaderNames } from "@notes/utils/loader_name";
import { FinalNotes } from "@notes-view/model/notes-model";

@Injectable({
  providedIn: "root",
})
export class NoteActionsService {
  constructor(
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _loaderStore: Store<fromloaderStateReducer.SharedState>
  ) {}

  onSubmit(submitType: SubmitTypes, patientNotesForm: FormGroup) {
    this._noteStore
      .select(fromNoteStore.isDeleteEnabled(submitType))
      .pipe(take(1))
      .subscribe((isDeleteEnabled) => {
        patientNotesForm.patchValue({
          isDeleteEnabled,
          pendOrSigned: submitType,
        });
        const noteContents = patientNotesForm.value;
        const actionType = this.getActionType(noteContents);
        this.submitOrUpdateFinalNote(
          this.getLoaderName(submitType),
          actionType,
          noteContents
        );
      });
  }

  onAttest(patientNotesForm: FormGroup, attestType: CosignStatusType) {
    this._noteStore.pipe(take(1)).subscribe(() => {
      const noteContents = patientNotesForm.value;
      this.attestFinalNote(noteContents, attestType);
    });
  }

  onRequestAttestation(requestedInfo: Cosign, patientNotesForm: FormGroup) {
    const noteContents = {
      ...patientNotesForm.value,
      isDeleteEnabled: false,
      pendOrSigned: SubmitTypes.Sign,
      cosign: { requestedTo: requestedInfo },
    };
    const actionType = this.getActionType(noteContents);
    this.submitOrUpdateFinalNote(
      LoaderNames.REQUEST_ATTESTATION_NOTE_LOADER,
      actionType,
      noteContents
    );
  }

  private getActionType({ refId }: FinalNotes): API {
    return refId === "new" ? API.Create : API.Update;
  }

  private getLoaderName(submitType: SubmitTypes): LoaderNames {
    return submitType === SubmitTypes.Sign
      ? LoaderNames.SIGN_NOTE_LOADER
      : LoaderNames.PEND_NOTE_LOADER;
  }

  private submitOrUpdateFinalNote(
    loaderName: LoaderNames,
    actionType: API,
    noteContents: FinalNotes
  ) {
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({
        loader: loaderName,
        status: true,
      })
    );
    const action =
      actionType === API.Create
        ? fromNoteStore.submitFinalNote
        : fromNoteStore.updateFinalNote;

    this._noteStore.dispatch(action({ noteContents, loaderName }));
  }

  attestFinalNote(noteContents: FinalNotes, attestType: CosignStatusType) {
    const loaderName =
      attestType == CosignStatusType.Accepted
        ? LoaderNames.ATTEST_ACCEPTED_LOADER
        : LoaderNames.ATTEST_REJECTED_LOADER;
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({
        loader: loaderName,
        status: true,
      })
    );
    this._noteStore.dispatch(
      fromNoteStore.attestFinalNote({ noteContents, loaderName })
    );
  }
}
