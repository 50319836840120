import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromRoot from "../../store/reducers";
import * as fromPatientMonitor from "./patient-monitor.reducer";
import * as fromPatientMonitorVital from "./patient-monitor-vitals.reducer";

export interface PatientMonitorState {
  monitors: fromPatientMonitor.State;
  vitals: fromPatientMonitorVital.State;
}

export interface State extends fromRoot.AppState {
  patientMonitor: PatientMonitorState;
}

export const reducers: ActionReducerMap<PatientMonitorState, any> = {
  monitors: fromPatientMonitor.reducer,
  vitals: fromPatientMonitorVital.reducer,
};

export const getPatientMonitorState =
  createFeatureSelector<PatientMonitorState>("patientMonitor");

/*
 * Patient Monitor Monitors State
 * */
export const getPatientMonitorMonitorsState = createSelector(
  getPatientMonitorState,
  (state: PatientMonitorState) => state.monitors
);

export const getPatientMonitorIds = createSelector(
  getPatientMonitorMonitorsState,
  fromPatientMonitor.selectPatientMonitorIds
);

export const getAllPatientMonitors = createSelector(
  getPatientMonitorMonitorsState,
  fromPatientMonitor.selectAllPatientMonitors
);

export const getPatientMonitorEntities = createSelector(
  getPatientMonitorMonitorsState,
  fromPatientMonitor.selectPatientMonitorEntities
);

export const getPatientMonitorTotal = createSelector(
  getPatientMonitorMonitorsState,
  fromPatientMonitor.selectPatientMonitorTotal
);

export const getPatientMonitorByDeviceId = createSelector(
  getAllPatientMonitors,
  (monitors, deviceId: string) => {
    if (monitors && monitors.length === 0) {
      return null;
    }

    const monitor = monitors.filter((monitor) => monitor.deviceId === deviceId);

    if (monitor && monitor.length === 0) {
      return null;
    }

    return monitor[0];
  }
);

/*
 * Patient Monitor Vitals
 * */
export const getPatientMonitorVitalsState = createSelector(
  getPatientMonitorState,
  (state: PatientMonitorState) => state.vitals
);

export const getPatientMonitorVitalsLatestDataState = createSelector(
  getPatientMonitorVitalsState,
  (state) => state.latestData
);
