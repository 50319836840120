import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

export interface SelectedButtonTag {
  tagName: string;
  selected: boolean;
}

@Component({
  selector: "app-btn-tag",
  templateUrl: "./btn-tag.component.html",
  styleUrls: ["./btn-tag.component.scss"],
})
export class BtnTagComponent implements AfterViewInit {
  @ViewChild("btnTagCont") btnTagCont: ElementRef;

  /** To display the button tag name */
  @Input() tagName: string;

  /** Url of the icon to be displayed on the left side of tagnName */
  @Input() iconLeft?: string;

  @Input() leftIconTooltip: string;

  /** Url of the icon to be displayed on the right side of tagnName */
  @Input() iconRight?: string;

  @Input() rightIconTooltip: string;

  /** Set this property to true when btn-tag has to be disabled */
  @Input() disabled?: boolean;

  /** This property sends a callback whenever an icon is clicked.
   * Emits true if iconLeft is clicked and vice-versa.
   */
  @Output() iconClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** To send a callback when this button tag is clicked
   * @param tagName To identify which tag was clicked
   * @param selected To identity whether the current button tag is selected or not
   */
  @Output() selected: EventEmitter<SelectedButtonTag> =
    new EventEmitter<SelectedButtonTag>();

  tagSelected: boolean = false;
  selectedBtnTag: SelectedButtonTag;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.disabled) {
      this.btnTagCont.nativeElement.innerHTML = `<div>N/A</div>`;
    }
  }

  /** Sending a callback to the parent component. */
  onIconClick(event, isLefticonClicked: boolean) {
    this.iconClick.emit(isLefticonClicked);
    event.stopPropagation();
  }

  /** When a button tag is selected */
  onSelected(event) {
    this.tagSelected = !this.tagSelected;

    this.selectedBtnTag = {
      tagName: this.tagName,
      selected: this.tagSelected,
    };

    this.selected.emit(this.selectedBtnTag);
    event.stopPropagation();
  }

  /** To remove the btn-tag from the DOM. */
  onClose() {
    // this.btnTagCont.nativeElement.parentNode.remove();
  }
}
