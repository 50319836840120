import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { forkJoin, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuditService {
  env = environment;
  constructor(private http: HttpClient) {}

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }
  newAudit(props): any {
    return this.http
      .post(this.env.apiUrl + "v1/audits/new", props, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getDashboardsList(userName: string) {
    return forkJoin({
      dashboardList: this.dashboardList(userName),
      favoriteDashboardList: this.getfavoriteDashboards(userName),
    }).pipe(
      map((response) => {
        const dashboardList =
          response.dashboardList["data"]["DashboardSummaryList"];
        const favoriteDashboardList =
          response.favoriteDashboardList["data"]["dashboards"];
        dashboardList.map((dashboard) => {
          dashboard["isfavoriteDashboard"] = favoriteDashboardList.includes(
            dashboard.DashboardId
          );
        });
        return dashboardList;
      })
    );
    // return this.http
    // .get(this.env.apiUrl + `v1/audit/dashboards?userName=${userName}`)
    // .pipe(catchError(this.errorHandler));
  }

  dashboardList(userName) {
    return this.http
      .get(this.env.apiUrl + `v1/audit/dashboards?userName=${userName}`)
      .pipe(catchError(this.errorHandler));
  }

  getUserList() {
    return this.http
      .get(this.env.apiUrl + `v1/audit/listUsers`)
      .pipe(catchError(this.errorHandler));
  }

  getDashboardEmbededUrl(dashboardId: string, userName: string) {
    return this.http
      .get(
        this.env.apiUrl +
          `v1/audit/${dashboardId}/dashboard-embeded-url?userName=${userName}`
      )
      .pipe(catchError(this.errorHandler));
  }

  storeRecentViewDashboard(dashboardIds, userName) {
    return this.http
      .post(
        this.env.apiUrl +
          `v1/audit/recent-view-dashboards?userName=${userName}`,
        { dashboards: dashboardIds }
      )
      .pipe(catchError(this.errorHandler));
  }

  storefavoriteDashboards(dashboardIds, userName) {
    return this.http
      .post(
        this.env.apiUrl + `v1/audit/favorite-dashboards?userName=${userName}`,
        { dashboardIds: dashboardIds }
      )
      .pipe(catchError(this.errorHandler));
  }

  getfavoriteDashboards(userName: string) {
    return this.http
      .get(
        this.env.apiUrl + `v1/audit/favorite-dashboards?userName=${userName}`
      )
      .pipe(catchError(this.errorHandler));
  }

  getRecentVIewDasboards(userName: string) {
    return this.http
      .get(
        this.env.apiUrl + `v1/audit/recent-view-dashboards?userName=${userName}`
      )
      .pipe(catchError(this.errorHandler));
  }

  registerNewUser(userEmail: string) {
    return this.http
      .post(this.env.apiUrl + `v1/audit/register-user`, {
        userEmail: userEmail,
      })
      .pipe(catchError(this.errorHandler));
  }
}
