import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createFeatureSelector, createReducer, on } from "@ngrx/store";
import {
  TvPatStoreType,
  TvUnitInterface,
} from "src/app/models/tv-unit-view.model";

import * as unitTvViewActions from "src/app/store/actions/tv-unit-view";

export interface State extends EntityState<TvPatStoreType> {
  // additional entities state properties
  loading: boolean;
  error: string;
  unit: TvUnitInterface;
  unitName: string | null;
  selectId: string | null;
  apiSuccess: boolean;
}

export function selectPatientId(a: TvPatStoreType): string {
  //In this case this would be optional since primary key is _id
  return a.CPMRN;
}

export const adapter: EntityAdapter<TvPatStoreType> =
  createEntityAdapter<TvPatStoreType>({
    selectId: selectPatientId,
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loading: false,
  error: null,
  unit: {
    availableBeds: 0,
    occupiedBeds: 0,
    totalBeds: 0,
    doctors: null,
    nurses: null,
  },
  unitName: null,
  apiSuccess: false,
});

export const tvUnitViewReducer = createReducer(
  initialState,
  on(unitTvViewActions.getUnitTvInfoAction, (state, { unit }) => ({
    ...state,
    unitName: unit,
    loading: true,
  })),
  on(unitTvViewActions.setOnePatientAction, (state, { patient }) => {
    return adapter.setOne(patient, {
      ...state,
      unit: {
        ...state.unit,
        availableBeds:
          !state.entities[patient.CPMRN].bedNo && patient.bedNo
            ? state.unit.availableBeds - 1
            : state.unit.availableBeds,
        occupiedBeds:
          !state.entities[patient.CPMRN].bedNo && patient.bedNo
            ? state.unit.occupiedBeds + 1
            : state.unit.occupiedBeds,
      },
    });
  }),
  on(unitTvViewActions.removeOnePatientAction, (state, { patient }) => {
    return adapter.removeOne(patient.CPMRN, {
      ...state,
      unit: {
        ...state.unit,
        availableBeds: patient.bedNo
          ? state.unit.availableBeds + 1
          : state.unit.availableBeds,
        occupiedBeds: patient.bedNo
          ? state.unit.occupiedBeds - 1
          : state.unit.occupiedBeds,
      },
    });
  }),
  on(unitTvViewActions.addOnePatientAction, (state, { patient }) => {
    return adapter.addOne(patient, {
      ...state,
      unit: {
        ...state.unit,
        availableBeds: patient.bedNo
          ? state.unit.availableBeds - 1
          : state.unit.availableBeds,
        occupiedBeds: patient.bedNo
          ? state.unit.occupiedBeds + 1
          : state.unit.occupiedBeds,
      },
    });
  }),
  on(unitTvViewActions.getUnitTvInfoSuccessAction, (state, { data }) => {
    return adapter.addMany(data?.patient, {
      ...state,
      loading: false,
      unit: data?.unit,
      apiSuccess: true,
    });
  }),
  on(unitTvViewActions.getUnitTvInfoFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(unitTvViewActions.unitTvClearStore, () => ({
    ...initialState,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return tvUnitViewReducer(state, action);
}

/** SELECTOR setup */
export const getTVUnitState = createFeatureSelector<State>("tvUnitView");

export const getSelectedUserId = (state: State) => state.selectId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of user ids
export const selectPatientIds = selectIds;

// select the dictionary of user entities
export const selectPatientEntities = selectEntities;

// select the array of users
export const selectAllPatients = selectAll;

// select the total user count
export const selectPatientTotal = selectTotal;
