import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromRoot from "../../store/reducers";
import * as fromRAlertForm from "./ralert-form.reducer";
import * as fromRAlertDevice from "./ralert-device.reducer";
import * as fromRAlertMessage from "./ralert-message.reducer";

export interface RAlertState {
  messages: fromRAlertMessage.State;
  devices: fromRAlertDevice.State;
  form: fromRAlertForm.State;
}

export interface State extends fromRoot.AppState {
  ralert: RAlertState;
}

export const reducers: ActionReducerMap<RAlertState, any> = {
  messages: fromRAlertMessage.reducer,
  devices: fromRAlertDevice.reducer,
  form: fromRAlertForm.reducer,
};

export const getRAlertState = createFeatureSelector<RAlertState>("ralert");

/*
 * RAlert Message Reducers
 * */

export const getRAlertMessageState = createSelector(
  getRAlertState,
  (state: RAlertState) => state.messages
);

export const getRalertMessages = createSelector(
  getRAlertMessageState,
  fromRAlertMessage.selectAllMessages
);

export const getRAlertMessagesTotal = createSelector(
  getRAlertMessageState,
  fromRAlertMessage.selectMessageTotal
);

export const getRAlertMessageEntities = createSelector(
  getRAlertMessageState,
  fromRAlertMessage.selectMessageEntities
);

export const selectMessageById = createSelector(
  getRAlertMessageEntities,
  (entities, messageId) => messageId && entities[messageId]
);

/*
 * RAlert Device Reducers
 * */

export const getRAlertDeviceState = createSelector(
  getRAlertState,
  (state: RAlertState) => state.devices
);

export const getRalertDevices = createSelector(
  getRAlertDeviceState,
  fromRAlertDevice.selectAllDevices
);

export const getRalertDeviceByDeviceId = createSelector(
  getRalertDevices,
  (devices, deviceId: string) => {
    if (devices && devices.length === 0) {
      return null;
    }

    const device = devices.filter((device) => device.deviceId === deviceId);

    if (device && device.length === 0) {
      return null;
    }

    return device[0];
  }
);

/*
 * RAlert Form State selectors
 * */
export const getRAlertFormState = createSelector(
  getRAlertState,
  (state: RAlertState) => state.form
);

export const getRAlertFormError = createSelector(
  getRAlertFormState,
  fromRAlertForm.getRAlertFormError
);

export const getRAlertFormLoading = createSelector(
  getRAlertFormState,
  fromRAlertForm.getRAlertFormLoading
);
