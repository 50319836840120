import { Action, createReducer, on } from "@ngrx/store";
import { OrderPaneActions } from "src/app/store/actions/order";
import {
  CreateProtocolPageActions,
  ProtocolApiActions,
  EditProtocolPageActions,
} from "../actions";
import { ProtocolForm } from "../components/protocol-form/protocol-form.component";

export interface State {
  error: any;
  loading: boolean;
  data: ProtocolForm;
}

export const initialState: State = {
  error: null,
  loading: false,
  data: null,
};

const protocolFormReducer = createReducer(
  initialState,
  on(
    CreateProtocolPageActions.submitCreateProtocolForm,
    EditProtocolPageActions.submitEditProtocolForm,
    EditProtocolPageActions.deleteProtocol,
    OrderPaneActions.placeProtocol,
    (state) => ({ loading: true, error: [], data: null })
  ),
  on(
    ProtocolApiActions.createProtocolSuccess,
    ProtocolApiActions.updateProtocolSuccess,
    ProtocolApiActions.deleteProtocolsFailure,
    ProtocolApiActions.placeProtocolSuccess,
    ProtocolApiActions.placeProtocolFailure,
    (state) => ({ loading: false, error: [], data: null })
  ),
  on(
    ProtocolApiActions.createProtocolFailure,
    ProtocolApiActions.updateProtocolFailure,
    ProtocolApiActions.deleteProtocolsFailure,
    (state, { error }) => ({ ...state, loading: false, error })
  ),
  on(
    ProtocolApiActions.saveFormData,
    (state, { form, file, category, id }) => ({
      ...state,
      data: { form, file, category, id },
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return protocolFormReducer(state, action);
}

export const getProtocolFormError = (state: State) => state.error;

export const getProtocolFormLoading = (state: State) => state.loading;

export const getProtocolFormData = (state: State) => state.data;
