import { createAction, props } from '@ngrx/store';

// get all discharged patients
export const getAllDischargedPatients = createAction(
    '[tv-covid] get discharged patients',
    props<{ query?: { hospitalName?: string[], covid?: string[]} }>()
);

// set all discharged patients in store => patients => dischargePatients
export const setAllDischargedPatients = createAction(
    '[tv-covid] set discharged patients',
    props<{ patients: any }>()
);

export const removeAllDischargedPatients = createAction(
    '[tv-covid] remove all discharged patients',
);
