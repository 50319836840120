import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { RalertDeviceFormService } from "../../services/ralert-device-form.service";
import {
  RAlertDevice,
  RAlertDeviceForm,
  RAlertHospital,
  RAlertHospitalUnit,
} from "../../../models/RAlert/device.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Hospital } from "src/app/models/hospital";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RalertApiService } from "../../services/ralert-api.service";

@Component({
  selector: "app-ralert-device-form",
  templateUrl: "./ralert-device-form.component.html",
  styleUrls: ["./ralert-device-form.component.scss"],
})
export class RalertDeviceFormComponent implements OnInit {
  hospitals: Hospital[];
  ralertDeviceList: RAlertDevice | null = null;
  formLoading: boolean;

  public form: UntypedFormGroup;
  public units: any[] = [];
  public beds: string[] = [];
  public hospitalLogo: string;
  public serverError: string = "";
  isLoading: boolean = false;

  hospitalID: string | null = null;
  unitID: string | null = null;

  constructor(
    private _deviceFormService: RalertDeviceFormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RalertDeviceFormComponent>,
    private _ralertApiService: RalertApiService
  ) {}

  ngOnInit() {
    if (this.data?.ralert) {
      this.ralertDeviceList = this.data.ralert;
    }

    if (this.data?.hospitalList) {
      this.hospitals = this.data.hospitalList;
    }

    this.form = this._deviceFormService.getDeviceForm(this.ralertDeviceList);

    if (this.ralertDeviceList?.hospitalName) {
      this.onHospitalChange(this.ralertDeviceList.hospitalName);
    }

    if (this.ralertDeviceList?.unitName) {
      this.onUnitChange();
    }
  }

  get unitName(): AbstractControl {
    return this.form.get("unitName") as AbstractControl;
  }

  get wifi(): UntypedFormArray {
    return this.form.get("wifi") as UntypedFormArray;
  }

  /**
   * Following are the functions to get the abstract controls of the form
   * @param {number} index
   * @returns
   */
  getSsid(index: number): AbstractControl {
    return this.form.get(`wifi.${index}.ssid`) as AbstractControl;
  }

  getPassword(index: number): AbstractControl {
    return this.form.get(`wifi.${index}.psk`) as AbstractControl;
  }

  getDns(index: number): UntypedFormArray {
    return this.form.get(`wifi.${index}.dns`) as UntypedFormArray;
  }

  getIp(index: number): UntypedFormArray {
    return this.form.get(`wifi.${index}.ip`) as UntypedFormArray;
  }

  getSubnet(index: number): UntypedFormArray {
    return this.form.get(`wifi.${index}.subnet`) as UntypedFormArray;
  }

  getGateway(index: number): UntypedFormArray {
    return this.form.get(`wifi.${index}.gateway`) as UntypedFormArray;
  }

  getAdvance(index: number): UntypedFormArray {
    return this.form.get(`wifi.${index}.advanced`) as UntypedFormArray;
  }

  /**
   * Get a particular wifi field from passed controlName and index.
   *
   * @param {string} controlName
   * @param {number} index
   * @returns {AbstractControl}
   */
  getWifiField(controlName: string, index: number): AbstractControl {
    return this.form.get(`wifi.${index}.${controlName}`);
  }

  /**
   * Checks if the given field has a specific error.
   *
   * @param {string} controlName
   * @param {string} errorName
   * @param {number} index
   * @returns {boolean}
   */
  hasWifiError(controlName: string, errorName: string, index: number): boolean {
    return this.getWifiField(controlName, index).hasError(errorName);
  }

  /**
   * When hospital changes in the dropdown
   * @param {string} hospitalName
   * @returns void
   */
  onHospitalChange(hospitalName: string): void {
    if (!this.hospitals) {
      return;
    }

    const hospital = this.hospitals.filter(
      (hospital) => hospital.name === hospitalName
    );

    if (hospital && hospital.length === 1) {
      /** Set the hospital id */
      this.hospitalID = hospital[0]._id;

      this.units = hospital[0] && hospital[0].units ? hospital[0].units : [];
      this.hospitalLogo =
        hospital[0] && hospital[0].logo ? hospital[0].logo : null;

      this.unitName.setValue(null);

      for (const unit of this.units) {
        // set unit if device is being updated.
        if (
          this.ralertDeviceList &&
          unit.name === this.ralertDeviceList.unitName
        ) {
          this.unitName.setValue(unit.name);
          break;
        }

        // set first available unit in device form.
        if (
          !unit.ralertDeviceExists &&
          (!this.ralertDeviceList ||
            (this.ralertDeviceList &&
              hospitalName !== this.ralertDeviceList.hospitalName))
        ) {
          this.unitName.setValue(unit.name);
          this.onUnitChange();
          break;
        }
      }
    }
  }

  /**
   * @description This triggers an api to get all the beds which have no r alert devices attached
   * @author Suraj Shenoy
   * @date 30 July 2021
   */
  onUnitChange(): void {
    let hospital = this.form.get("hospitalName").value;
    let unit = this.unitName.value;

    let hospitalId = this.hospitals.find((hosp) => hosp.name === hospital)?._id;
    let unitId = this.units.find((unitInfo) => unitInfo.name === unit)?._id;

    if (hospitalId && unitId) {
      this.isLoading = true;
      this._ralertApiService
        .getBedNo(hospitalId, unitId)
        .toPromise()
        .then((response) => {
          this.isLoading = false;
          if (response.data?.length) {
            this.beds = response.data;
          }

          if (this.ralertDeviceList?.bedNo) {
            this.beds.push(this.ralertDeviceList.bedNo);
            this.form.get("bedNo").setValue(this.ralertDeviceList.bedNo);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  /**
   * To add WIFI controls to the form
   */
  addSecondWifi() {
    this.wifi.push(this._deviceFormService.getDeviceWifiForm(null));
  }

  /**
   * Delete the wifi controls from the form
   */
  removeSecondaryWifi() {
    this.wifi.removeAt(1);
  }

  /**
   * To add more controls to the wifi
   * @param {boolean} checked
   * @param {number} index
   */
  onAdvanceChange(checked: boolean, index: number) {
    if (checked) {
      this.setWifiValidators(index);
    } else {
      this.clearWifiValidators(index);
    }
  }

  /**
   * Add validation
   * @param {number} index
   */
  setWifiValidators(index) {
    const validators = [
      Validators.required,
      Validators.pattern(/\d/),
      Validators.min(0),
      Validators.max(999),
    ];

    for (let i = 0; i < 4; i++) {
      this.getDns(index).controls[i].setValidators(validators);
      this.getGateway(index).controls[i].setValidators(validators);
      this.getIp(index).controls[i].setValidators(validators);
      this.getSubnet(index).controls[i].setValidators(validators);
    }
  }

  /**
   * Remove validation
   * @param {number} index
   */
  clearWifiValidators(index) {
    for (let i = 0; i < 4; i++) {
      this.getDns(index).controls[i].clearValidators();
      this.getGateway(index).controls[i].clearValidators();
      this.getIp(index).controls[i].clearValidators();
      this.getSubnet(index).controls[i].clearValidators();
    }
  }

  /**
   * To submit the data
   * @param {any} value
   */
  onSubmit(value): void {
    this.formLoading = true;

    /**
     * Passing unit id
     */
    let unitID = null;
    let foundUnit = this.units.find(
      (unit) => unit.name === this.unitName.value
    );
    if (foundUnit) {
      unitID = foundUnit._id;
    }

    const formValue = {
      ...value,
      hospitalLogo: this.hospitalLogo,
      commandCenterID: this.data?.commandCenter?._id,
      hospitalID: this.hospitalID,
      unitID,
    };

    this.checkApi(formValue)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response?.data) {
            this.dialogRef.close(response.data);
          }
        },
        (err) => {
          console.log(err);
          this.formLoading = false;
          this.serverError = err?.error?.message
            ? err?.error?.message
            : "Something went wrong! Please try again later!";
        }
      );
  }

  /**
   * To get the API - Add / edit
   * @param {RAlertDevice} dataToSend
   * @returns Observable
   */
  checkApi(dataToSend: RAlertDevice): any {
    if (this.data.ralert) {
      return this._ralertApiService.updateDevice(
        dataToSend,
        this.ralertDeviceList._id
      );
    } else {
      return this._ralertApiService.newDevice(dataToSend);
    }
  }

  /**
   * To add predefined values
   * @param {string} type
   * @param {number} index
   */
  clickOnWifiShortcut(type: string, index: number): void {
    if (type === "robocraze") {
      this.getSsid(index).setValue("Robocraze");
      this.getPassword(index).setValue("Robocr@ze");
    } else if (type === "cp") {
      this.getSsid(index).setValue("cloud-Phy-EPB");
      this.getPassword(index).setValue("C!py6Ms3w3rg3nc@410");
    }
  }

  /** Subject to eliminate the subscription. */
  unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
