import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blood-history-view',
  template: `
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Quantity:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.quantity">
            {{ order.quantity }}
	          <span>{{ order.quantityUnit ? order.quantityUnit : 'unit' }}</span>
        </span>
			  <span *ngIf="!order?.quantity">-</span>
		  </div>
	  </div>
	  <app-history-common-view [data]="order"></app-history-common-view>
  `,
  styleUrls: [ '../details-modal-common.scss' ]
})
export class BloodHistoryViewComponent {
  @Input() order;
}
