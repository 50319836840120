import * as fromAuth from '../../actions/auditlineCharts.actions';
import {Action, createReducer, on} from '@ngrx/store'
import { DataModel } from 'src/app/data/data.model';
import * as fromAuditLineChartActions from '../../actions/auditlineCharts.actions';

export interface State {
    data: any
}

export const initialState: State = {
    data: null
}

const auditLineChartReducer = createReducer(
    initialState,
    on(
        fromAuditLineChartActions.addTable,
        (state, { data }) => ({ data })
    )
)

export function reducer(state: State | undefined, action: Action) {
    return auditLineChartReducer(state, action);
}

export const getData = (state: State) => state.data;
