import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  StatusChipInterface,
  chipColorType,
} from "src/app/iris-components/status-chip/models";

@Component({
  selector: "cp-status-chip",
  templateUrl: "./status-chip.component.html",
  styleUrls: ["./status-chip.component.scss"],
})
export class StatusChipComponent implements OnInit {
  @Input() statusConfig: StatusChipInterface;
  @Input() color: chipColorType = "";

  public tagClass: string = "";

  @Output() updateStatus = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.setTagClass();
  }

  changeStatus() {
    this.updateStatus.emit();
  }

  public setTagClass() {
    if (!this.color) return;
    this.tagClass = "cp-tag ";
    switch (this.color) {
      case "red":
        this.tagClass += "cp-tag--red";
        break;
      case "yellow":
        this.tagClass += "cp-tag--yellow";
        break;
      case "blue":
        this.tagClass += "cp-tag--blue";
        break;
      case "orange":
        this.tagClass += "cp-tag--orange";
        break;
    }
  }
}
