import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { ApgarFormService } from "./apgar-form.service";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { valExists } from "src/app/support-functions/util";
@Component({
  selector: "app-apgar-score",
  templateUrl: "./apgar-score.component.html",
  styleUrls: ["./apgar-score.component.scss"],
})
export class ApgarScoreComponent implements OnInit {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description fall risk form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  public apgarForm: FormGroup = this._apgarFormService.initAPGARScoreForm();

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }

  @Input() minDate;
  @Input() maxDate;

  // passing from initial-assessment form [ admit form ]
  @Input() admitFormIsParent: boolean = false;

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.apgarForm.get("timestamp");
  }

  get apgarScore() {
    return this.apgarForm.get("apgarScore").value || 0;
  }

  constructor(
    private store: Store<{}>,
    private _apgarFormService: ApgarFormService,
    private _alertService: AlertService
  ) {}

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    this.apgarForm.patchValue({
      apgarScore: valExists(vitalData?.apgar?.apgarScore)
        ? vitalData?.apgar?.apgarScore
        : null,
      activity: valExists(vitalData?.apgar?.activity)
        ? vitalData?.apgar?.activity
        : null,
      pulse: valExists(vitalData?.apgar?.pulse)
        ? vitalData?.apgar?.pulse
        : null,
      grimace: valExists(vitalData?.apgar?.grimace)
        ? vitalData?.apgar?.grimace
        : null,
      appearance: valExists(vitalData?.apgar?.appearance)
        ? vitalData?.apgar?.appearance
        : null,
      respiration: valExists(vitalData?.apgar?.respiration)
        ? vitalData?.apgar?.respiration
        : null,
    });

    if (vitalData.timestamp)
      setFormVal(this.apgarForm, "timestamp", vitalData.timestamp);
  }

  /**
   * @description This calculates the fall score based on the changes
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  onFallInput(): void {
    const { activity, pulse, grimace, appearance, respiration } =
      this.apgarForm.value;
    this.apgarForm.patchValue({
      apgarScore: activity + pulse + grimace + appearance + respiration,
    });
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset(): void {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.apgarForm.reset();
    this.apgarForm.get("timestamp").setValue(null);

    this.formState.error = null;
  }

  /**
   * @description To submit the pain score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitFalls(patDataFromForm): void {
    this.store.dispatch(vitalActions.submitForm({ loadType: "apgar" }));

    const apgarProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      apgar: {
        apgarScore: patDataFromForm.apgarScore,
        activity: patDataFromForm.activity,
        pulse: patDataFromForm.pulse,
        grimace: patDataFromForm.grimace,
        appearance: patDataFromForm.appearance,
        respiration: patDataFromForm.respiration,
      },
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: apgarProps },
      })
    );
  }

  ngOnInit(): void {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "APGAR score updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.apgarForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
