import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromMarStatus from "./mar-status.reducer";
import * as fromMarReducer from "./mar-normal.reducer";
import * as fromMarSOSReducer from "./mar-sos.reducer";
import * as fromMarDietReducer from "./mar-diet.reducer";
import * as fromInfusionMarReducer from "./infusion.reducer";
import * as fromDiscontinuedMarReducer from "./discontinued-mar.reducer";

export interface MarState {
  normal: fromMarReducer.State;
  sos: fromMarSOSReducer.State;
  diet: fromMarDietReducer.State;
  // beforeAndAfter: fromMarReducer.State;
  infusion: fromInfusionMarReducer.State;
  inactiveMar: fromDiscontinuedMarReducer.State;
  status: fromMarStatus.State;
}

export interface State {
  mar: MarState;
}

export const reducer: ActionReducerMap<any, any> = {
  normal: fromMarReducer.reducer,
  sos: fromMarSOSReducer.reducer,
  diet: fromMarDietReducer.reducer,
  // beforeAndAfter: fromMarReducer.reducer,

  infusion: fromInfusionMarReducer.reducer,
  inactiveMar: fromDiscontinuedMarReducer.reducer,
  status: fromMarStatus.reducer,
};

export const getMarState = createFeatureSelector<MarState>("mar");
