import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { catchError, switchMap, takeUntil, timeout } from "rxjs/operators";
import { NoteService } from "@notes/services/note.service";

import { CTAType } from "@notes/models/notes.model";
import { LoaderNames } from "@notes/utils/loader_name";
import { NoteEffectsHandler } from "./note-add-handlers";
import { DeleteNoteEffectsHandler } from "./delete-note-handler";
import * as fromNoteTypeActions from "../actions";
import { NoteTypelabels } from "@notes-view/note-view/note-sections/note-table.config";
import { of, throwError, timer } from "rxjs";

@Injectable()
export class NoteDispatchEffects {
  constructor(
    private actions$: Actions,
    private _noteService: NoteService,
    private _noteEffectsHandler: NoteEffectsHandler,
    private _deleteNoteEffectsHandler: DeleteNoteEffectsHandler
  ) {}

  delay = 3000;

  @Effect()
  saveDraftNote$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.submitDraftNote),
    switchMap(({ draftNotes }) =>
      timer(this.delay).pipe(
        switchMap(() => throwError({ type: "TIMEOUT" })),
        catchError(() => of({ type: "TIMEOUT" })),
        takeUntil(
          this.actions$.pipe(
            ofType(
              fromNoteTypeActions.submitFinalNote,
              fromNoteTypeActions.updateFinalNote,
              fromNoteTypeActions.deleteDraftNote,
              fromNoteTypeActions.attestFinalNote
            )
          )
        ),
        switchMap(() =>
          this._noteEffectsHandler.handleNoteEffect(
            () => this._noteService.updateDraftNote(draftNotes),
            "Notes submitted successfully!",
            CTAType.Edit,
            LoaderNames.SIGN_NOTE_LOADER,
            NoteTypelabels.Draft,
            false // reset note content set false
          )
        )
      )
    )
  );

  @Effect()
  submitNote$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.submitFinalNote),
    switchMap(({ noteContents, loaderName }) =>
      this._noteEffectsHandler.handleNoteEffect(
        () => this._noteService.createNote(noteContents),
        "Notes submitted successfully!",
        CTAType.ActionComplete,
        loaderName
      )
    )
  );

  @Effect()
  updateNote$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.updateFinalNote),
    switchMap(({ noteContents, loaderName }) =>
      this._noteEffectsHandler.handleNoteEffect(
        () => this._noteService.updateNote(noteContents),
        "Notes updated successfully!",
        CTAType.ActionComplete,
        loaderName
      )
    )
  );

  @Effect()
  deleteDraftNote$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.deleteDraftNote),
    switchMap(({ refId }) =>
      this._deleteNoteEffectsHandler.handleDeleteNoteEffect(
        () => this._noteService.deleteDraft(refId),
        "Notes deleted successfully!",
        CTAType.ActionComplete,
        LoaderNames.DELETE_NOTE_LOADER,
        refId
      )
    )
  );

  @Effect()
  attestNote$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.attestFinalNote),
    switchMap(({ noteContents, loaderName }) =>
      this._noteEffectsHandler.handleNoteEffect(
        () => this._noteService.attestNote(noteContents),
        "Notes attested successfully!",
        CTAType.ActionComplete,
        loaderName
      )
    )
  );
}
