//Aira module

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxPermissionsModule } from "ngx-permissions";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { SharedModule } from "../shared/shared.module";
import { AiraBtnComponent } from "./aira-btn/aira-btn.component";
import { IrisComponentsModule } from "../iris-components/iris-components.module";

@NgModule({
  declarations: [AiraBtnComponent],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    NgxPermissionsModule.forChild(),
    IrisComponentsModule,
  ],
  exports: [AiraBtnComponent],
})
export class AiraModule {}
