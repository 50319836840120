import { Action, createReducer, on } from '@ngrx/store';
import { FileApiActions } from '../../actions/file';
import { resetCompleteFileState } from '../../actions/patient-chart/documents/documents.actions';

export interface State {
  entities: any;
}

export const initialState: State = {
  entities: []
};

const fileDataReducer = createReducer(
  initialState,
  on(
    FileApiActions.saveFileToStore,
    (state, { file, id }) => ({ entities: [ ...state.entities, { file, id } ] })
  ),
  on(
    FileApiActions.updateFile,
    (state, { id, data }) => {
      const updatedEntities = state.entities.reduce((acc, entity) => {
        if (entity.id === id) {
          acc.push({ ...entity, ...data });
        } else {
          acc.push(entity);
        }

        return acc;
      }, []);

      return { entities: updatedEntities };
    }
  ),
  on(
    resetCompleteFileState,
    (state) => ({ ...initialState })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return fileDataReducer(state, action);
}

export const getFileDataEntities = (state: State) => state.entities;
