<div class="weight-cont display-flex">
  <div class="bW display-flex">
    <div class="label">Birth Wt:</div>
    <div class="value">{{ currPatient?.birthWeight * 1000 | number }} g</div>
  </div>
  <div class="bW display-flex">
    <div class="label">Admn Wt:</div>
    <div class="value">{{ currPatient?.weight * 1000 | number }} g</div>
  </div>
</div>
<div class="table-holder display-flex flex-column w-100">
  <table mat-table [dataSource]="tableData" class="cp-table-selection">
    <!-- age -->
    <ng-container matColumnDef="age">
      <th mat-header-cell *matHeaderCellDef>Age(days)</th>
      <td mat-cell *matCellDef="let row">
        {{ row.age }}
      </td>
    </ng-container>

    <!-- weight -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef>Weight(gm)</th>
      <td
        mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'bg-error': row?.formGroup?.get('weight')?.invalid
        }"
        [ngStyle]="{
          width: '13rem'
        }"
      >
        <ng-container>
          <cp-edit-input
            [formControl]="row?.formGroup?.get('weight')"
            #quantityComp
            [width]="8"
          ></cp-edit-input>
        </ng-container>
      </td>
    </ng-container>

    <!-- length -->
    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef>Length(cm)</th>
      <td
        mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'bg-error': row?.formGroup?.get('length')?.invalid
        }"
        [ngStyle]="{
          width: '13rem'
        }"
      >
        <ng-container>
          <cp-edit-input
            [formControl]="row?.formGroup?.get('length')"
            #quantityComp
            [width]="8"
          ></cp-edit-input>
        </ng-container>
      </td>
    </ng-container>

    <!-- Head circumference -->
    <ng-container matColumnDef="headCircumference">
      <th mat-header-cell *matHeaderCellDef>Head circumference(cm)</th>
      <td
        mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'bg-error': row?.formGroup?.get('headCircumference')?.invalid
        }"
        [ngStyle]="{
          width: '13rem'
        }"
      >
        <ng-container>
          <cp-edit-input
            [formControl]="row?.formGroup?.get('headCircumference')"
            #quantityComp
            [width]="8"
          ></cp-edit-input>
        </ng-container>
      </td>
    </ng-container>

    <!-- date -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date(dd-mm-yyyy)</th>
      <td mat-cell *matCellDef="let row">
        {{ row.weightMeasuredTime | timezone: "DD-MM-YYYY" }}
        <cp-timezone-label></cp-timezone-label>
      </td>
    </ng-container>

    <!-- time -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>Time</th>
      <td mat-cell *matCellDef="let row">
        {{ row.weightMeasuredTime | timezone: "HH:mm" }}
        <cp-timezone-label></cp-timezone-label>
      </td>
    </ng-container>

    <!-- info -->
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div
          class="icon-holder"
          popoverTrigger="hover"
          [cpPopover]="popoverItem"
          position="bottom-end"
          (mouseenter)="tooltipStore = row"
          (mouseleave)="tooltipStore = null"
        >
          <mat-icon inline="true">info</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="tabularTableColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      (click)="tableRowClick(i)"
      *matRowDef="let row; index as i; columns: tabularTableColumns"
    ></tr>
  </table>
</div>

<div class="tabular-footer display-flex justify-content-end" *ngIf="isDirty">
  <mat-dialog-actions class="dialog-actions">
    <button
      mat-flat-button
      class="button-secondary"
      color="primary"
      (click)="resetForm()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button-primary"
      color="warn"
      [disabled]="isInvalid || anyInputIsEmptyFromRow"
      (click)="updateWeightHistory()"
    >
      Save Changes
    </button>
  </mat-dialog-actions>
</div>

<ng-template #popoverItem>
  <div class="popover-cont">
    <div class="tp-head">Entered by</div>
    <p class="tp-body">
      {{ tooltipStore?.createdBy?.name || "-" }} on
      {{ tooltipStore?.createdBy?.at | timezone: "HH:mm, DD-MM-YYYY" }}
    </p>
    <ng-container *ngIf="tooltipStore?.updatedBy?.name">
      <div class="tp-head">Edited by</div>
      <p class="tp-body">
        {{ tooltipStore?.updatedBy?.name || "-" }} on
        {{ tooltipStore?.updatedBy?.at | timezone: "HH:mm, DD-MM-YYYY" }}
      </p>
    </ng-container>
  </div>
</ng-template>
