import { createSelector } from "@ngrx/store";
import * as fromInfectionReducer from "../reducers";
import * as fromInfectionBundleReducer from "../reducers/infection-bundle.reducer";

const getInfectionBundleState = createSelector(
  fromInfectionReducer.getInfectionBundleState,
  (state) => state.infectionBundle
);

export const getLoading = createSelector(
  getInfectionBundleState,
  (state) => state.loading
);

export const {
  selectAll: getAllInfectionBundlesData,
  selectEntities: getAllInfectionBundlesMap,
  selectIds: getAllInfectionBundleIds,
} = fromInfectionBundleReducer.infectionBundleAdaptor.getSelectors(
  getInfectionBundleState
);
