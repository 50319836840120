import { Action } from "@ngrx/store";
import { File, FileSigned } from "../../labs-scans/models/File.model";

// load lab values

// Meaning has been reversed upload actually here gets lab datas and load add the labdata to DB

export const LOAD_LAB_REQUEST = "[LAB] Load Lab Request";
export const LOAD_LAB_REQUEST_FAIL = "[LAB] Load Lab Request Fail";
export const LOAD_LAB_REQUEST_SUCCESS = "[LAB] Load Lab Request Success";

export const UPLOAD_LAB_REQUEST = "[LAB] Upload Lab Request";
export const UPLOAD_LAB_REQUEST_FAIL = "[LAB] Upload Lab Request Fail";
export const UPLOAD_LAB_REQUEST_SUCCESS = "[LAB] Upload Lab Request Success";

export const UPDATE_LAB_REQUEST = "[LAB] Update Lab Request";
export const UPDATE_LAB_REQUEST_FAIL = "[LAB] Update Lab Request Fail";
export const UPDATE_LAB_REQUEST_SUCCESS = "[LAB] Update Lab Request Success";
export const UPDATE_LAB_REQUEST_FAIL_MESSAGE_RESET =
  "[LAB] Update Lab Request Fail Message Reset";

export class LoadLabRequest implements Action {
  readonly type = LOAD_LAB_REQUEST;
  constructor(public payload: any) {}
}

export class LoadLabRequestFail implements Action {
  readonly type = LOAD_LAB_REQUEST_FAIL;
  constructor(public payload: any) {}
}

export class LoadLabRequestSuccess implements Action {
  readonly type = LOAD_LAB_REQUEST_SUCCESS;
  constructor(public payload) {}
}

export class UploadLabRequest implements Action {
  readonly type = UPLOAD_LAB_REQUEST;
  constructor(public payload: any) {}
}

export class UploadLabRequestFail implements Action {
  readonly type = UPLOAD_LAB_REQUEST_FAIL;
  constructor(public payload: any) {}
}

export class UpdateLabRequestSuccess implements Action {
  readonly type = UPDATE_LAB_REQUEST_SUCCESS;
  constructor(public payload) {}
}

export class UpdateLabRequest implements Action {
  readonly type = UPDATE_LAB_REQUEST;
  constructor(public payload: any) {}
}

export class UpdateLabRequestFail implements Action {
  readonly type = UPDATE_LAB_REQUEST_FAIL;
  constructor(public payload: any) {}
}

export class UploadLabRequestSuccess implements Action {
  readonly type = UPLOAD_LAB_REQUEST_SUCCESS;
  constructor(public payload) {}
}
export class UpdateLabRequestFailMessageReset implements Action {
  readonly type = UPDATE_LAB_REQUEST_FAIL_MESSAGE_RESET;
  constructor(public payload = null) {}
}

export type LoadLabRequestAction =
  | LoadLabRequest
  | LoadLabRequestFail
  | LoadLabRequestSuccess
  | UploadLabRequest
  | UploadLabRequestFail
  | UploadLabRequestSuccess
  | UpdateLabRequest
  | UpdateLabRequestFail
  | UpdateLabRequestSuccess
  | UpdateLabRequestFailMessageReset;
