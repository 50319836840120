import { LabDocumentEffects } from "./lab-document.effects";
import { LabFormEffects } from "./lab-forms.effects";
import { LabRequestEffects } from "./lab-request.effects";
import { UploadFileEffects } from "./upload-file.effects";

export const effects: any[] = [
  LabFormEffects,
  LabDocumentEffects,
  UploadFileEffects,
  LabRequestEffects,
];

export * from "./lab-forms.effects";
