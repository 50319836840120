import { Injectable } from '@angular/core';

/*
* All of the functions here manipulated the vent form.
* */
@Injectable({ providedIn: 'root' })
export class VentFormTemplateService {
  constructor() {
  }

  /*
  * NAME: canViewTube
  * PURPOSE: checks if tube size can be displayed
  * DESCRIPTION:
  * PARAMS: airway:string
  * RETURNS: boolean - if true then tube is displayed.
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewTube(airway: string): boolean {
    return airway === 'LMA' || airway === 'iGel' || airway === 'Supraglottic airway';
  }

  /*
  * NAME: canViewVentType
  * PURPOSE: checks if vent type can be displayed
  * DESCRIPTION:
  * PARAMS:
  *   - airway:string
  *   - ventType:string
  * RETURNS: boolean - if true then ventType is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewVentType(airway: string, ventType: string): boolean {
    if (ventType === 'Mech') {
      return airway === 'Trach' ||
        airway === 'Trach+' ||
        airway === 'Trach + T-piece' ||
        airway === 'Trach Collar' ||
        airway === 'ETT' ||
        airway === 'ETT+' ||
        airway === 'ETT + T-piece' ||
        airway === 'LMA' ||
        airway === 'iGel' ||
        airway === 'Supraglottic airway';
    } else if (ventType === 'NIV') {
      return airway !== 'RA';
    }

    return false;
  }

  /*
  * NAME: canViewTubeSize
  * PURPOSE: checks if tube size can be displayed
  * DESCRIPTION:
  * PARAMS:
  *   - airway:string
  *   - intType:string
  * RETURNS: boolean - if true then tubeSize is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewTubeSize(airway: string, intType: string): boolean {
    return airway === 'Trach' ||
      airway === 'Trach+' ||
      airway === 'Trach + T-piece' ||
      airway === 'Trach Collar' ||
      ((airway === 'ETT' ||
        airway === 'ETT+' ||
        airway === 'ETT + T-piece') && intType !== 'NA');
  }

  /*
  * NAME: canViewIntType
  * PURPOSE: checks if int type can be displayed
  * DESCRIPTION:
  * PARAMS:
  *   - airway:string
  * RETURNS: boolean - if true then int type is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewIntType(airway: string): boolean {
    return airway === 'ETT' ||
      airway === 'ETT+' ||
      airway === 'ETT + T-piece';
  }

  /*
  * NAME: canViewFlowRate
  * PURPOSE: checks if vent type can be displayed
  * DESCRIPTION:
  * PARAMS:
  *   - ventType:string
  * RETURNS: boolean - if true then vent type is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewFlowRate(ventType: string): boolean {
    return ventType === 'Heated High Flow';
  }

  /*
  * NAME: canViewBasicForm
  * PURPOSE: checks if rest of the form can be displayed
  * DESCRIPTION:
  *   - there are many fields which will be displayed or hidden together
  *   - so this function is a check for all of those fields
  * PARAMS:
  *   - ventType:string
  *   - airway:string
  * RETURNS: boolean - if true then form is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewBasicForm(ventType: string, airway: string): boolean {
    if ((ventType === 'Mech' || ventType === 'NIV') && !airway) {
      return true;
    } else if (this.canViewVentType(airway, ventType)) {
      return true;
    }

    return false;
  }

  /*
  * NAME: canViewFio2
  * PURPOSE: checks if FiO2 can be displayed
  * DESCRIPTION:
  * PARAMS:
  *   - ventType:string
  * RETURNS: boolean - if true then FiO2 is displayed
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  canViewFio2(ventType: string): boolean {
    return ventType && typeof ventType === 'string';
  }

  /*
  * NAME: viewTidalFirst
  * PURPOSE: checks if tidal is viewed first or IPAP
  * DESCRIPTION:
  *   - there are two fields: IPAP and Tidal Volume
  *   - if mode is PC then IPAP is displayed first, otherwise tidal volume is displayed
  * PARAMS:
  *   - mode:string
  * RETURNS: boolean - if true then tidal is displayed first
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  viewTidalFirst(mode: string): boolean {
    return mode !== 'PC';
  }

  /*
  * NAME: getModeSelectValues
  * PURPOSE: basis on the mode returns the breath sequence values
  * DESCRIPTION:
  * PARAMS: mode:string
  * RETURNS: string[] - values to be used in breath sequence select in html
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  getModeSelectValues(mode: string): string[] {
    if (!mode || typeof mode !== 'string') {
      return [];
    }

    const lowerCaseMode = mode.toLowerCase();

    if (lowerCaseMode == 'pc') {
      return [ 'CSV', 'CMV', 'IMV' ];
    } else if (lowerCaseMode == 'vc') {
      return [ 'CMV', 'IMV' ];
    }

    return [];
  }

  /*
  * NAME: getPeepLabel
  * PURPOSE: basis on ventType returns the label to be used in Peep field in html.
  * DESCRIPTION:
  * PARAMS: ventType:string
  * RETURNS: string - values to be used as a label in html
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  getPeepLabel(ventType: string): string {
    if (ventType === 'NIV') {
      return 'CPAP/EPAP';
    }

    return 'PEEP/EPAP';
  }

  /*
  * NAME: isIntTypeRequired
  * PURPOSE: basis on the airway checks if int type is required
  * DESCRIPTION:
  * PARAMS: airway:string
  * RETURNS: boolean - if true then int type is required
  * USED BY: vent-form.component.ts
  * CREATED DATE: 14/02/20
  * AUTHOR: Gunjit Agrawal
  */
  isIntTypeRequired(airway: string): boolean {
    return airway === 'ETT' ||
      airway === 'ETT+' ||
      airway === 'ETT + T-piece';
  }

  /**
   * Checks out if ETT Tie Level is visible in template or not.
   *
   * @param {string} airway - vital's airway field
   * @returns {boolean} - true of visible
   */
  canViewEttTieLevel(airway: string): boolean {
    return airway === 'ETT' ||
      airway === 'LMA' ||
      airway === 'ETT+' ||
      airway === 'ETT + T-piece';
  }
}
