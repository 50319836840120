import { createAction, props } from "@ngrx/store";
import {
  AuthResendOtpApi,
  AuthVerifyOtpApi,
} from "src/app/models/auth/Otp.model";

export const clickVerifyOtp = createAction(
  "[Otp Page] Click Verify Otp",
  props<{ data: AuthVerifyOtpApi }>()
);

export const clickResendOtp = createAction(
  "[Otp Page] Click Resend Otp",
  props<{ data: AuthResendOtpApi }>()
);
