import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { AiraNote } from "../model/AiraNote.model";

@Injectable({ providedIn: "root" })
export class AiraNoteService {
  private airaNoteSource = new Subject<AiraNote>();

  public airaNote$ = this.airaNoteSource.asObservable();

  public passAiraDataToNote(data: AiraNote) {
    if (data) {
      this.airaNoteSource.next(data);
    }
  }
}
