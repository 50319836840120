import { Injectable } from "@angular/core";
import { pullAt } from "lodash-es";
import { TwoFaRemember } from "../../models/auth/Auth.model";

@Injectable({ providedIn: "root" })
export class AuthLocalStorageService {
  private _max2faTokens = 15;
  private _keys = [
    "hospitalFilters",
    "currentUser",
    "token",
    "selectedAll",
    "assignedPatientsFilter",
    "unassignPatientsFilter",
    "selectedAllAssignedHosp",
    "selectedAllUnassignedHosp",
    "patientTypeFilter",
    "isPatientListTab",
    "isAssignedTabActive",
    "pinCPMRNFields",
  ];

  /**
   * Clears localstorage of the keys.
   */
  clearLocalStorage() {
    for (const key of this._keys) {
      localStorage.removeItem(key);
    }
  }

  get(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Save data in localstorage
   *
   * @param {string} key - name of the localstorage
   * @param {string} value - value that will be saved in localstorage
   */
  save(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Fetch the token by given email.
   * Used in multi factor authentication to remember the device.
   * Returns the value if found else null.
   *
   * @param {string} email - email of the user.
   * @returns {string | null}
   */
  get2faRemember(email: string): string | null {
    const tokens = this._getAll2faRememberTokens();
    const tokenByEmail = tokens.filter((token) => token.email === email);

    return tokenByEmail && tokenByEmail.length === 1
      ? tokenByEmail[0].token
      : null;
  }

  /**
   * Removes a token from storage by email.
   *
   * @param {string} email
   * @return {TwoFaRemember[]} - filtered tokens
   */
  remove2faRemember(email: string): TwoFaRemember[] {
    const tokens = this._getAll2faRememberTokens();

    const updatedTokens = tokens.filter((token) => token.email !== email);

    this.save(this._get2faRememberKey(), JSON.stringify(updatedTokens));

    return updatedTokens;
  }

  /**
   * Saves the token in localstorage
   * If there are more then allowed tokens then removes the oldest used.
   * If token is already exists then removes it.
   *
   * @param {TwoFaRemember} token
   */
  save2faRemember(token: TwoFaRemember) {
    let tokens = this._getAll2faRememberTokens();

    if (!tokens) {
      tokens = [];
    }

    if (tokens.length >= this._max2faTokens) {
      this._removeOld2faToken();
    }

    tokens = this.remove2faRemember(token.email);

    tokens.push(token);

    this.save(this._get2faRememberKey(), JSON.stringify(tokens));
  }

  /**
   * Updates the timestamp of the token by email
   *
   * @param {string} email
   * @param {Date} timestamp
   */
  update2faRememberTimestamp(email: string, timestamp: Date): void {
    const tokens = this._getAll2faRememberTokens();

    const updatedTokens = tokens.map((token) => {
      if (token.email === email) {
        token.timestamp = timestamp;
      }
      return token;
    });

    this.save(this._get2faRememberKey(), JSON.stringify(updatedTokens));
  }

  /**
   * Retunrs 2faRemember key
   *
   * @return {string}
   */
  _get2faRememberKey(): string {
    return "2faRemember";
  }

  /**
   * Removes the token which is last recently used
   *
   * @return {TwoFaRemember[]}
   */
  _removeOld2faToken(): TwoFaRemember[] {
    const tokens = this._getAll2faRememberTokens();
    let oldestTime;
    let index = -1;

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (!token.timestamp) {
        continue;
      }

      const unixTimestamp = new Date(token.timestamp).getTime();

      if (!oldestTime) {
        oldestTime = unixTimestamp;
        index = i;
        continue;
      }

      if (unixTimestamp < oldestTime) {
        oldestTime = unixTimestamp;
        index = i;
      }
    }

    if (index >= 0) {
      pullAt(tokens, [index]);
    }

    this.save(this._get2faRememberKey(), JSON.stringify(tokens));

    return tokens;
  }

  /**
   * Fetches all 2fa tokens.
   *
   * @returns {TwoFaRemember[]}
   */
  _getAll2faRememberTokens(): TwoFaRemember[] {
    try {
      const tokenString = this.get("2faRemember");

      return !tokenString ? [] : JSON.parse(tokenString);
    } catch (e) {
      return [];
    }
  }
}
