import { createAction, props } from "@ngrx/store";

export const getVitals = createAction(
    '[Patient Vitals] get vital data',
    props<{CPMRN: string, encounters: number}>()
)

export const setVitals = createAction(
    '[Patient Vitals] set vital data',
    props<{vitals: any}>()
)

export const setClickedTime = createAction(
    '[Patient Vitals] set clicked day',
    props<{day: any}>()
)

export const resetClickedTime = createAction(
    '[Patient Vitals] reset clicked day',
)

export const setChangedTime = createAction(
    '[Patient Vitals] set changed time',
    props<{dateObj: any}>()
)

export const updateVitals = createAction(
    '[Patient Vitals] update vitals',
    props<{CPMRN, encounters, vitalData}>()
)

export const submitForm = createAction(
    '[Patient Vitals] vital form submit',
    props<{loadType: string}>()
)

export const submitSuccess = createAction(
    '[Patient Vitals] vital form success',
)

export const setSubmittedData = createAction(
    '[Patient Vitals] set submitted data',
    props<{vitData: any}>()
)

export const hideSuccess = createAction(
    '[Patient Vitals] vital form hide success',
)

export const submitFail = createAction(
    '[Patient Vitals] vital form fail',
    props<{error: any}>()
)

export const resetPatientMonitorVital = createAction(
    '[Patient Vitals] Reset Patient Monitor Vital'
)
