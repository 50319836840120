import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-med-history-view',
  template: `
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Quantity:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.quantity">
            {{ order.quantity }}
	        <span *ngIf="order.maxDose">to {{ order.maxDose }}</span>
	          <span>{{ order.unit }}</span>
        </span>
			  <span *ngIf="!order?.quantity">-</span>
		  </div>
	  </div>
	  
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Route:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.route">
            {{ order.route }}
        </span>
			  <span *ngIf="!order?.route">-</span>
		  </div>
	  </div>
	  
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Form:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.form">
            {{ order.form }}
        </span>
			  <span *ngIf="!order?.form">-</span>
		  </div>
	  </div>
	  
	  <div class="details-modal__text" *ngIf="order?.concentration?.value">
		  <div class="details-modal__text__title">
			  Concentration:
		  </div>
		  <div class="details-modal__text__detail">
			  {{ order.concentration.value }} {{ order.concentration.unit }}
		  </div>
	  </div>
	  
	  <div class="details-modal__text" *ngIf="order?.concentration?.value">
		  <div class="details-modal__text__title">
			  Rate:
		  </div>
		  
		  <div class="details-modal__text__detail">
          <span *ngIf="(order | medRate)?.rate as rate">
            <span class="mr-1">{{ rate.min }} </span>
            <span *ngIf="rate.max" class="mr-1"> - {{ rate.max }}</span>
            <span>(ml/hr)</span>
        </span>
		  </div>
	  </div>
	  
	  <div class="details-modal__text" *ngIf="order?.concentration?.value && order.bodyWeight">
		  <div class="details-modal__text__title">
			  Body Weight:
		  </div>
		  <div class="details-modal__text__detail">
			  {{ order.bodyWeight }}
		  </div>
	  </div>
	  
	  <div class="details-modal__text">
		  <span class="details-modal__text__title">Days:</span>
		  <div class="details-modal__text__content">
        <span *ngIf="order?.noOfDays">
            {{ order.noOfDays }}
        </span>
			  <span *ngIf="!order?.noOfDays">-</span>
		  </div>
	  </div>
	  
	  <div class="details-modal__text" *ngIf="order?.combination?.length > 0">
		  <span class="details-modal__text__title">Combination:</span>
		  <div class="details-modal__text__content ml-3">
			  <div *ngFor="let comb of order.combination">
				  <div class="details-modal__text">
					  <div class="details-modal__text__title">{{ comb.name }}</div>
					  <div class="details-modal__text__content">{{ comb.quantity }} {{ comb.unit }}</div>
				  </div>
			  </div>
		  </div>
	  </div>
	  
	  <app-history-common-view [data]="order"></app-history-common-view>
  `,
  styleUrls: [ '../details-modal-common.scss' ]
})
export class MedHistoryViewComponent {
  @Input() order;
}

