import { Action, createReducer, on } from '@ngrx/store';
import { RAlertApiActions, RAlertListPageActions } from '../actions';

export interface State {
  error: any;
  loading: boolean;
}

export const initialState: State = {
  error: null,
  loading: false
};

const rAlertFormReducer = createReducer(
  initialState,
  on(
    RAlertListPageActions.submitNewRalertDeviceForm,
    RAlertListPageActions.submitEditRalertDeviceForm,
    RAlertListPageActions.clickDeleteRAlertDevice,
    (state) => ({ loading: true, error: [] })
  ),
  on(
    RAlertApiActions.addRalertDevice,
    RAlertApiActions.updateRalertDevice,
    RAlertApiActions.deleteRalertDevice,
    (state) => ({ loading: false, error: [] })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return rAlertFormReducer(state, action);
}

export const getRAlertFormError = (state: State) => state.error;

export const getRAlertFormLoading = (state: State) => state.loading;
