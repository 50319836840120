import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromFinalActions from "../actions/final-notes.actions";
import { FinalNotes } from "@notes-view/model/notes-model";

export interface FinalNoteState extends EntityState<FinalNotes> {}

export const noteTypeAdaptor = createEntityAdapter<FinalNotes>({
  selectId: selectNoteId,
});

export const initialState: FinalNoteState = noteTypeAdaptor.getInitialState({});

export function selectNoteId(a: FinalNotes): string {
  return a.refId;
}

const _noteTypeReducer = createReducer(
  initialState,
  on(FromFinalActions.loadFinalNote, (state, { notes }) =>
    noteTypeAdaptor.setAll(notes, state)
  ),
  on(FromFinalActions.addFinalNote, (state, { note }) =>
    noteTypeAdaptor.upsertOne(note, state)
  ),
  on(FromFinalActions.deleteFinalNote, (state, { refId }) =>
    noteTypeAdaptor.removeOne(refId, state)
  ),
  on(FromFinalActions.resetFinalNoteContent, () => initialState)
);

export function reducer(state: FinalNoteState, action: Action) {
  return _noteTypeReducer(state, action);
}
