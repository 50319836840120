<img
  *ngIf="!isDischargeTimeElapsed"
  matRipple
  class="cp-cursor-pointer"
  src="assets/icons/attach_file.svg"
  alt="attachment"
  matTooltip="Open doc"
  (click)="attachmentInput.click()"
/>

<img
  *ngIf="isDischargeTimeElapsed"
  alt="attachment-disabled"
  class="disabled-attach-icon"
  src="assets/icons/attach_file_disabled.svg"
/>

<input
  (change)="upload($event.target.files)"
  id="attachmentField"
  #attachmentInput
  accept="image/x-png, image/jpeg, application/pdf,video/mp4,video/x-m4v,video/*"
  type="file"
  hidden
/>
