/**
 * Notifications Component.
 * Displays all the application notifications.
 * @author Pradip Solanki
 * @example
 * <app-notifications></app-notifications>
 */

import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { AISocketService } from "../services/socket.service";
import { Topic } from "../enums/topic.enum";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private currentUser: any;
  private allNotifications: any = [];
  private subscription: Subscription;
  // variable to indicate socket connection state
  public hasConnection: boolean = false;
  // variable to indicate new notification on top of bell icon
  public haveNewNotification: boolean = false;
  // variable for opening notification panel with different tabs and change color of bell icon
  public isNotificationOpen: boolean = false;
  // variable to show unread notifications
  public showUnreadNotification: boolean = true;
  // list of notifications for each tab
  public generalNotifications: any = [];
  public alertNotifications: any = [];
  public alertCount: number = 0;
  public generalCount: number = 0;
  public selectedTabIndex: number = 0;

  constructor(private socketService: AISocketService) {}

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent) {
    // check if the click event target is outside of the div
    if ((event.target as HTMLElement).closest(".notification-center-icon")) {
      if (this.isNotificationOpen) {
        this.isNotificationOpen = false;
      } else {
        this.isNotificationOpen = true;
      }
    } else if (
      this.isNotificationOpen &&
      !(event.target as HTMLElement).closest(".notification-box")
    ) {
      this.isNotificationOpen = false;
    }
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.subscription = this.socketService.readyState.subscribe((state) => {
      this.hasConnection = state;
    });
    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.connected)
        .subscribe((message) => {
          const userData = {
            role: this.currentUser["role"],
            email: this.currentUser["email"],
          };
          if (message) {
            // once socket is connected get notification and toaster data
            this.socketService.send(Topic.getNotification, userData);
            this.socketService.send(Topic.getAlertToaster, userData);
          }
        })
    );
    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.setNotification)
        .subscribe((message) => {
          if (message) {
            this.allNotifications = message;
            this.setNotifications();
          }
        })
    );
  }

  onToggleChange() {
    this.setNotifications();
  }

  ngOnDestroy(): void {
    // remove socket on close
    this.socketService.close();
    this.subscription.unsubscribe();
  }

  setNotifications(): void {
    this.haveNewNotification = false;
    if (this.showUnreadNotification) {
      this.alertNotifications = this.getUnreadNotifications(
        this.allNotifications["alert_notifications"]
      );
      this.generalNotifications = this.getUnreadNotifications(
        this.allNotifications["general_notifications"]
      );
    } else {
      this.alertNotifications = this.allNotifications["alert_notifications"];
      this.generalNotifications =
        this.allNotifications["general_notifications"];
    }
    this.alertCount = this.allNotifications["new_alert_count"];
    this.generalCount = this.allNotifications["new_general_count"];
    if (this.alertCount + this.generalCount > 0) {
      this.haveNewNotification = true;
    }
  }

  getUnreadNotifications(notifications: any): any {
    const oldNotifications = [];
    const recentNotifications = [];
    // filter recent notifictions
    notifications["recent"].forEach((Notification) => {
      if (!Notification["is_read"]) {
        recentNotifications.push(Notification);
      }
    });
    // filter old notifictions
    notifications["old"].forEach((Notification) => {
      if (!Notification["is_read"]) {
        oldNotifications.push(Notification);
      }
    });
    return { old: oldNotifications, recent: recentNotifications };
  }
}
