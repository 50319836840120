import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";

import { Schedule } from "@mar/models/mar.model";
import { environment as env } from "src/environments/environment";

interface apiResponse {
  success: number;
  message: string;
  data: Notification[] | Notification | string | Schedule | any;
}

@Injectable({
  providedIn: "root",
})
export class MarService {
  marBaseURL = "mar/";
  currentVersion = `v1/${this.marBaseURL}`;

  constructor(private http: HttpClient, private store: Store<{}>) {}

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getAllMar(cpmrn: string, encounter: number): Observable<apiResponse> {
    return this.http
      .get<apiResponse>(
        `${env.apiUrl}${this.currentVersion}${cpmrn}/${encounter}`
      )
      .pipe(catchError(this.errorHandler));
  }

  getAllMarDiscontinued(
    cpmrn: string,
    encounter: number,
    marType: string = null
  ): Observable<apiResponse> {
    let params: { marType?: string } = {};

    if (marType) {
      params.marType = marType;
    }

    return this.http
      .get<apiResponse>(
        `${env.apiUrl}${this.currentVersion}${cpmrn}/${encounter}?isDiscontinued=true`,
        { params }
      )
      .pipe(catchError(this.errorHandler));
  }

  updateSchedule(marId: string, schedule: Schedule): Observable<apiResponse> {
    return this.http
      .put<apiResponse>(
        `${env.apiUrl}${this.currentVersion}${marId}/schedule/${schedule._id}`,
        schedule
      )
      .pipe(catchError(this.errorHandler));
  }

  checkForInfusionCompletion(
    cpmrn: string,
    encounter: number
  ): Observable<apiResponse> {
    return this.http
      .get<apiResponse>(
        `${env.apiUrl}${this.currentVersion}${cpmrn}/${encounter}/check-infusion-completion`
      )
      .pipe(catchError(this.errorHandler));
  }

  updateInfusionSchedule(
    marId: string,
    schedule: Schedule
  ): Observable<apiResponse> {
    return this.http
      .put<apiResponse>(
        `${env.apiUrl}${this.currentVersion}${marId}/infusion-schedule/${schedule._id}`,
        schedule
      )
      .pipe(catchError(this.errorHandler));
  }
}
