<div class="left-panel-container">
  <div
    class="graph-label cursor-pointer"
    (mouseenter)="currHoverGraph = labAttribute"
    (mouseleave)="currHoverGraph = null"
    [ngClass]="{
      'graph-label--active': (selectedLabAttributeNames$ | async)?.includes(
        labAttribute
      ),
      'graph-label--hover': currHoverGraph === labAttribute
    }"
    *ngFor="let labAttribute of selectedLabAttributesArray"
    (click)="addRemoveGraph(labAttribute)"
  >
    <div class="label-text">
      {{ labAttribute }}
    </div>
    <div class="label-add-icon">
      <ng-container *ngIf="currHoverGraph === labAttribute">
        <mat-icon
          (click)="$event.stopPropagation(); addGraph(labAttribute)"
          *ngIf="
            !(selectedLabAttributeNames$ | async)?.includes(labAttribute);
            else removeIcon
          "
          >add</mat-icon
        >
        <ng-template #removeIcon>
          <mat-icon
            (click)="(removeGraph)"
            *ngIf="(selectedLabAttributeNames$ | async)?.length > 1"
            >remove</mat-icon
          >
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
