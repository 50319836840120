<age-group-view
  *ngIf="vent.patientType"
  [ageGroup]="vent.patientType"
  [id]="vent._id"
></age-group-view>
<div class="row">
  <div class="col-4" *ngIf="vent.patVentAirway">
    <span class="font-weight-bold">Airway: </span>
    <span *ngIf="vent.patVentAirway === 'High Flow'">High Flow Cannula</span>
    <span *ngIf="vent.patVentAirway === 'Venturi'">Venturi Mask</span>
    <span *ngIf="vent.patVentAirway === 'Cannula'">Nasal Cannula</span>
    <span
      *ngIf="
        vent.patVentAirway !== 'High Flow' &&
        vent.patVentAirway !== 'Venturi' &&
        vent.patVentAirway !== 'Cannula'
      "
    >
      {{ vent.patVentAirway }}
    </span>
  </div>
  <div class="col-4" *ngIf="vent.patEttTieLevel">
    <span class="font-weight-bold">ETT Tie Level</span>
    {{ vent.patEttTieLevel }}
  </div>
  <div class="col-4">
    <span class="font-weight-bold">Vent Type: </span>
    <span *ngIf="vent.patVentType !== 'Mech'; else elseBlock">{{
      vent.patVentType
    }}</span>
    <ng-template #elseBlock>
      <span>Mech Invasive</span>
    </ng-template>
  </div>
  <div class="col-6" *ngIf="vent.patVentMode">
    <span class="font-weight-bold">Mode: </span>
    {{ vent.patVentMode }}
  </div>
  <div class="col-6" *ngIf="vent.patVentSubMode">
    <span class="font-weight-bold">Breath Sequence: </span>
    {{ vent.patVentSubMode }}
  </div>
  <div class="col-6" *ngIf="vent.patVentFiO2">
    <span class="font-weight-bold">FiO<sub>2</sub>: </span>
    {{ vent.patVentFiO2 }}
  </div>
  <div class="col-6" *ngIf="vent.patVentTubeSize || vent.patVentTube">
    <span class="font-weight-bold">Tube Size: </span>
    <ng-container [ngSwitch]="vent.patVentTube">
      <span *ngSwitchCase="'neonate'">Neonate (1)</span>
      <span *ngSwitchCase="'infant'">Infant (1½)</span>
      <span *ngSwitchCase="'smPediatric'">Small Pediatric (2)</span>
      <span *ngSwitchCase="'lgPediatric'">Large Pediatric (2½)</span>
      <span *ngSwitchCase="'smAdult'">Small Adult (3)</span>
      <span *ngSwitchCase="'mdAdult'">Medium Adult (4)</span>
      <span *ngSwitchCase="'lgAdult'">Large Adult (5)</span>
      <span *ngSwitchCase="'other'">Other</span>
      <span *ngSwitchDefault></span>
    </ng-container>
    {{ vent.patVentTubeSize }}
  </div>
  <div class="col-6" *ngIf="vent.patIntType">
    <span class="font-weight-bold">Intubation Type: </span>
    {{ vent.patIntType }}
  </div>
  <div
    class="col-6"
    *ngIf="vent.patVentOralAirway && vent.patVentOralAirway !== 'none'"
  >
    <span class="font-weight-bold">Oral Airway: </span>
    {{ vent.patVentOralAirway }}
  </div>
  <div class="col-6" *ngIf="vent.patVentPinsp">
    <span class="font-weight-bold">P<sub>insp</sub>/IPAP: </span>
    {{ vent.patVentPinsp }}
  </div>
  <div class="col-6" *ngIf="vent.patVentRRset">
    <span class="font-weight-bold">RR<sub>set</sub>: </span>
    {{ vent.patVentRRset }}
  </div>
  <div class="col-6" *ngIf="vent.patVentPEEP">
    <span *ngIf="vent.patVentType === 'NIV'"
      ><span class="font-weight-bold">CPAP/EPAP:</span>
      {{ vent.patVentPEEP }}</span
    >
    <span *ngIf="vent.patVentType === 'Mech'"
      ><span class="font-weight-bold">PEEP/EPAP:</span>
      {{ vent.patVentPEEP }}</span
    >
  </div>
  <div class="col-6" *ngIf="vent.patFlowRate">
    <span class="font-weight-bold">Flow Rate:</span>
    {{ vent.patFlowRate }}
  </div>
  <div class="col-6" *ngIf="vent.patVentVt">
    <span class="font-weight-bold">Tidal Volume (V<sub>t</sub>):</span>
    {{ vent.patVentVt }}
  </div>
  <div class="col-6" *ngIf="vent.patVentPsupport">
    <span class="font-weight-bold">PS:</span>
    {{ vent.patVentPsupport }}
  </div>
  <div class="col-6" *ngIf="vent.patVentPplat">
    <span class="font-weight-bold">P<sub>plat</sub>:</span>
    {{ vent.patVentPplat }}
  </div>
  <div class="col-6" *ngIf="vent.patVentMAP">
    <span class="font-weight-bold">MAP (Airway): </span>
    {{ vent.patVentMAP }}
  </div>
  <div class="col-6" *ngIf="vent.patVentMV">
    <span class="font-weight-bold">MV:</span>
    {{ vent.patVentMV }}
  </div>
  <div class="col-6" *ngIf="vent.patVentRRTot">
    <span class="font-weight-bold">RR<sub>total</sub>: </span>
    {{ vent.patVentRRTot }}
  </div>
  <div class="col-6" *ngIf="vent.patVentPpeak">
    <span class="font-weight-bold">P<sub>peak</sub>: </span>
    {{ vent.patVentPpeak }}
  </div>
  <div class="col-6" *ngIf="vent.patVentI || vent.patVentE">
    <span class="font-weight-bold">I:E -</span> {{ vent.patVentI }}:{{
      vent.patVentE
    }}
  </div>
</div>
