<div>
	<form autocomplete="off" [formGroup]="form" (submit)="onSubmit(form.value)">
		<div class="f-row mb-1">
			<label for="deviceId" class="mr-3">Device Id*:</label>
			<input type="text" class="cp-input" formControlName="deviceId" id="deviceId">
			<span class="error ml-1"
			      *ngIf="utilService.hasError(form, 'deviceId', 'required')
                  && (deviceId.touched || deviceId.dirty)">
                This field is required
            </span>
			<span class="error ml-1"
			      *ngIf="utilService.hasError(form, 'deviceId', 'unique')
                  && (deviceId.touched || deviceId.dirty)">
                Device id is not unique.
            </span>
		</div>
		<div class="f-row mb-1">
			<label for="hospitalName" class="mr-3">Hospital Name*:</label>
			<select formControlName="hospitalName" id="hospitalName" class="cp-select"
			        (change)="onHospitalChange($event.target.value)">
				<option *ngFor="let hospital of hospitals"
				        [value]="hospital.name">
					{{ hospital.name }}
				</option>
			</select>
			<small class="error ml-1"
			       *ngIf="form.get('hospitalName').invalid && form.get('hospitalName').touched">
				This field is required
			</small>
		</div>
		
		<div class="f-row mb-1" *ngIf="units?.length > 0">
			<label for="unitName" class="mr-3">Unit Name*:</label>
			<select formControlName="unitName" id="unitName" class="cp-select">
				<option *ngFor="let unit of units"
				        [value]="unit.name">
					{{ unit.name }}
				</option>
			</select>
			<small class="error ml-1"
			       *ngIf="form.get('unitName').invalid && form.get('unitName').touched">
				This field is required
			</small>
		</div>
		
		<div class="f-row mt-3">
			<button type="submit" class="cp-btn cp-hvr-secondary" [disabled]="loading || !form.valid">
				Submit
			</button>
		</div>
	</form>
</div>
