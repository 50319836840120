import { padStart } from "lodash-es";
import { Injectable } from "@angular/core";
import { VentForm, VentOrderBE } from "../../models/vital/vent/Vent.model";

@Injectable({ providedIn: "root" })
export class VentOrderService {
  /*
   * NAME: convertVentOrderToVentForm
   * PURPOSE: converts value of VentOrderBE type to VentForm
   * DESCRIPTION:  used when editing a vent in order section
   * PARAMS: vent:VentOrderBE - value taken from vent order
   * RETURNS: VentForm - value compatible with vent form
   * USED BY: orders-input.component.ts
   * CREATED DATE: 18/02/20
   * AUTHOR: Gunjit Agrawal
   */
  public convertVentOrderToVentForm(vent: any): VentForm {
    return {
      patFlowRate: vent.flowRate,
      patIntType: vent.intType,
      patVentAirway: vent.airway,
      patVentE: vent.expiratory,
      patVentFiO2: vent.fio2,
      patVentI: vent.inspiratory,
      patVentMAP: vent.map,
      patVentMV: vent.mv,
      patVentMode: vent.mode,
      patVentOralAirway: vent.oralAirway,
      patVentPEEP: vent.peep,
      patVentPinsp: vent.pinsp,
      patVentPpeak: vent.ppeak,
      patVentPplat: vent.pplat,
      patVentPsupport: vent.psupport,
      patVentRRTot: vent.rrtot,
      patVentRRset: vent.rrset,
      patVentSubMode: vent.subMode,
      patVentTube: vent.tube,
      patVentTubeSize: vent.tubeSize,
      patVentType: vent.ventType,
      instructions: vent.instructions,
      additionalInformation: vent.additionalInformation,
      patVentVt: vent.vt,
      startTime: vent.startTime,
      startNow: vent.startNow,
      patEttTieLevel: vent.ettTieLevel,
    };
  }
}
