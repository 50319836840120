import { Component, Input, OnInit } from '@angular/core';
import { VentForm } from '../../../models/vital/vent/Vent.model';

@Component({
  selector: 'app-vital-preset-view',
  templateUrl: './vital-preset-view.component.html',
  styleUrls: [ './vital-preset-view.component.scss' ]
})
export class VitalPresetViewComponent implements OnInit {
  @Input() vent: VentForm;

  constructor() {
  }

  ngOnInit() {
  }

}
