import { Action, createReducer, on } from "@ngrx/store";
import { labTrendsReducerInterface } from "../state";
import * as fromGrowthTrendsAction from "../actions/lab-trends.actions";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { LabTrendsConfig } from "../../components/lab-chart/lab-utility/lab-trends.config";

export const adapter: EntityAdapter<LabTrendsConfig> =
  createEntityAdapter<LabTrendsConfig>({
    selectId: selectGrowthDataGraphName,
    sortComparer: sortGrowthDataGraph,
  });

function selectGrowthDataGraphName(a: LabTrendsConfig) {
  return a.graphName;
}

function sortGrowthDataGraph(a: LabTrendsConfig, b: LabTrendsConfig) {
  return a.graphName.localeCompare(b.graphName);
}

const initialState: labTrendsReducerInterface = adapter.getInitialState({});

const growthTrendsReducer = createReducer(
  initialState,
  on(fromGrowthTrendsAction.resetGraph, (state) => adapter.removeAll(state)),
  on(
    fromGrowthTrendsAction.setInitialGraphAndData,
    (state, { graphConfigs }) => {
      return adapter.setAll([graphConfigs], state);
    }
  ),
  on(fromGrowthTrendsAction.addNewGrowthGraph, (state, { graphConfigs }) => {
    return adapter.upsertOne(graphConfigs, state);
  }),
  on(
    fromGrowthTrendsAction.updateManyGrowthGraph,
    (state, { graphConfigs }) => {
      return adapter.upsertMany(graphConfigs, state);
    }
  ),
  on(fromGrowthTrendsAction.removeGrowthGraph, (state, { graphName }) => {
    return adapter.removeOne(graphName, state);
  })
);

export function reducer(state: labTrendsReducerInterface, action: Action) {
  return growthTrendsReducer(state, action);
}
