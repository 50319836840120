import { FocusMonitor } from "@angular/cdk/a11y";
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormGroup,
  NgControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-iris-input",
  templateUrl: "./iris-input.component.html",
  styleUrls: ["./iris-input.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: IrisInputComponent },
  ],
})
export class IrisInputComponent
  implements
    OnInit,
    OnDestroy,
    MatFormFieldControl<string>,
    ControlValueAccessor
{
  private _irisFormType: "Password" | "Search" | "Text";
  public password: boolean = false;
  private _placeholder: string;

  @ViewChild(MatInput, { read: ElementRef, static: true })
  input: ElementRef;
  private _required: boolean;

  public hide = true;

  @Input()
  set irisFormType(form) {
    console.log(form, "formsdads");
    this._irisFormType = form;
    if (form === "Password") {
      this.password = true;
    }
    this.stateChanges.next();
  }

  get irisFormType() {
    return this._irisFormType;
  }

  @Input()
  set value(value: string) {
    console.log(value, "value");
    this.formControlName.setValue(value);
    this.stateChanges.next();
  }

  get value() {
    return this.formControlName.value;
  }

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set required(required: boolean) {
    this._required = required;
  }

  get required() {
    return this._required;
  }

  @Input()
  disabled: boolean;

  formControlName = new UntypedFormControl("");

  constructor(
    private focusMonitor: FocusMonitor,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    console.log(this.disabled, "dissfdsdfm");
  }
  writeValue(obj: string): void {
    console.log(obj, "inside value");
    this.value = obj;
  }

  get errorState(): boolean {
    return true;
  }

  onChange: (value: string) => void;
  onTouch: () => void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    console.log(isDisabled, "isdiwsdfnhdfj");
    this.disabled = isDisabled;
    this.formControlName.disable();
    this.stateChanges.next();
  }
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  stateChanges = new Subject<void>();
  id: string;
  focused: boolean = true;
  @HostBinding("class.label")
  shouldLabelFloat: boolean = true;
  controlType?: string;

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
    this.focusMonitor.monitor(this.input).subscribe((focused) => {
      this.focused = !!focused;
      this.stateChanges.next();
    });

    console.log(this.password, "password");

    // this.formControlName.valueChanges.subscribe((value) => this.onChange(value));
  }

  get empty(): boolean {
    return !this.value;
  }

  getIcon() {
    switch (this.irisFormType) {
      case "Search":
        return "search";
      case "Password":
        return "password";
    }
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.input);
    this.stateChanges.complete();
  }
}
