import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class HttpErrorHandlerService {
  handleError() {
    return (error: HttpErrorResponse): Observable<any> => {
      const message = (error.error instanceof ErrorEvent) ?
        console.error(error.error.message) :
        `server returned code ${error.status} with body "${error.error.message}"`;

      return from([]);
    };
  }
}
