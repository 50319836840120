<!-- <app-splash-screen></app-splash-screen> -->
<app-loader *ngIf="loadingData"></app-loader>
<app-sbar-alert></app-sbar-alert>

<div
  *ngIf="showData"
  [ngClass]="{
    'patient-data-wrapper': !isSpecificRouteActive && !hideTabsAndPopup
  }"
  class="bg-light"
>
  <div
    class="navigation-tabs"
    *ngIf="!isSpecificRouteActive && !hideTabsAndPopup"
  >
    <!-- Vertical navigation tabs for activities (Notes, Orders etc.)  -->
    <app-emr-nav-tabs
      [currentUser]="currentUser"
      [pendingCultureCount]="
        (activeCulturesCount$ | async) - (cultureDocumentCount$ | async)
      "
      [pendingCovidCount]="
        (activeCovidOrdersCount$ | async) - (covidDocumentCount$ | async)
      "
    >
    </app-emr-nav-tabs>
  </div>
  <div
    class="patient-info"
    [ngClass]="{
      'cp-m-1': !!isSpecificRouteActive
    }"
  >
    <div class="patient-header">
      <app-patient-header
        (clickedNotificationUser)="setCurrentNotificationUser($event)"
        *ngIf="showHeader && !isSpecificRouteActive"
      ></app-patient-header>
      <cp-patient-card *ngIf="isSpecificRouteActive"></cp-patient-card>
    </div>

    <div class="patient-body">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="vertical-tab" *ngIf="!isSpecificRouteActive && !hideTabsAndPopup">
    <app-input-popup
      [isDischargeTimeElapsed]="isDischargeTimeElapsed"
    ></app-input-popup>
  </div>
</div>
<!-- <section class="radar_policy" *ngIf="!showData">
  <h1>Due to patient safety considerations, RADAR policy is not to allow more than {{ noOfTabs }} Patient charts at the same time!</h1>
</section> -->
