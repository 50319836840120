import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import { default as _rollupMoment } from "moment";
const moment = _rollupMoment || _moment;

import { Mar } from "@mar/models/mar.model";
import { MarSOSActions } from "@mar/store/actions/index";
import { on, createReducer, Action } from "@ngrx/store";
import { EntityState, createEntityAdapter, EntityAdapter } from "@ngrx/entity";

export interface State extends EntityState<Mar> {}

export function selectMarId(a: Mar): string {
  return a.day;
}

export function sortByDate(a: Mar, b: Mar): number {
  return moment(a.day, "DD-MM-YYYY").diff(moment(b.day, "DD-MM-YYYY"));
}

export const adapter: EntityAdapter<Mar> = createEntityAdapter<Mar>({
  selectId: selectMarId,
  sortComparer: sortByDate,
});

export const initialState: State = adapter.getInitialState({});

const MarReducer = createReducer(
  initialState,
  on(MarSOSActions.setMarSOS, (state, { mar }) => {
    return adapter.setAll(mar, { ...state });
  }),
  on(MarSOSActions.upsertMarSOS, (state, { mar }) => {
    return adapter.upsertMany(mar, state);
  }),
  on(MarSOSActions.resetMarSOS, (state) => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectAllMar = selectAll;
export const selectAllMarIds = selectIds;
export const selectMarEntities = selectEntities;
export const selectMarCount = selectTotal;

export function reducer(state: State | undefined, action: Action) {
  return MarReducer(state, action);
}
