/**
 * Notification Tab Component.
 * Responsible for showing all notifications.
 * @author Pradip Solanki
 */

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AISocketService } from "../services/socket.service";
import { Topic } from "../enums/topic.enum";
import { NotificationType } from "../enums/notificationtype.enum";

@Component({
  selector: "app-notification-tab",
  templateUrl: "./notification-tab.component.html",
  styleUrls: ["./notification-tab.component.scss"],
})
export class NotificationTabComponent implements OnChanges {
  @Input() notificationsData: any;
  public recentNotifications: any = [];
  public olderNotifications: any = [];

  constructor(private socketService: AISocketService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notificationsData) {
      this.recentNotifications = this.notificationsData["recent"];
      this.olderNotifications = this.notificationsData["old"];
    }
  }

  markRead(notificationIds: any): void {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const notificationData = {
      role: currentUser["role"],
      email: currentUser["email"],
      notification_ids: notificationIds,
    };
    this.socketService.send(Topic.markReadNotification, notificationData);
  }

  markAllRead(): void {
    const notificationIds: any = [];
    this.recentNotifications.forEach((element) => {
      if (
        element["type"] === NotificationType.netraMissedVital ||
        element["type"] === NotificationType.netraNotVerified
      ) {
        element["notification_data"].forEach((item) => {
          notificationIds.push(item["_id"]);
        });
      } else {
        notificationIds.push(element["_id"]);
      }
    });
    this.olderNotifications.forEach((element) => {
      if (
        element["type"] === NotificationType.netraMissedVital ||
        element["type"] === NotificationType.netraNotVerified
      ) {
        element["notification_data"].forEach((item) => {
          notificationIds.push(item["_id"]);
        });
      } else {
        notificationIds.push(element["_id"]);
      }
    });
    this.markRead(notificationIds);
  }

  handleNotificationClicked(element: any): void {
    if (
      element["type"] === NotificationType.netraMissedVital ||
      element["type"] === NotificationType.netraNotVerified
    ) {
      const notificationIds: any = [];
      element["notification_data"].forEach((item) => {
        notificationIds.push(item["_id"]);
      });
      this.markRead(notificationIds);
    } else {
      this.markRead([element["_id"]]);
    }
  }
}
