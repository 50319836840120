<div class="toggle-header">
  <span
    [ngClass]="{ active: activeTab === tab.value }"
    *ngFor="let tab of tabs; index as i"
    (click)="changeTab(tab.value)"
    >{{ tab.label }}</span
  >
  <span [ngClass]="{ active: activeTab === customTabValue }"
    ><ng-content></ng-content
  ></span>
</div>
