import { createSelector } from "@ngrx/store";
import { NoteFormState, getNoteState } from "../reducers";
import { Cosign, Intensivist, SubmitTypes } from "@notes/models/notes.model";

export const selectAllNoteContentData = createSelector(
  getNoteState,
  (state: NoteFormState) => state.noteFormContents
);

export const isDeleteEnabled = (submitType: SubmitTypes) =>
  createSelector(selectAllNoteContentData, (notes) =>
    notes.isDeleteEnabled ? true : submitType == SubmitTypes.Pend ? false : true
  );

export const isInitialState = createSelector(
  selectAllNoteContentData,
  (notes) => {
    return (
      notes.refId === "new" &&
      notes.noteType === "" &&
      notes.noteSubType === "" &&
      notes.components.length === 0 &&
      notes.addendum.length === 0
    );
  }
);

export const getSelectedIntensivist = createSelector(
  selectAllNoteContentData,
  (notes): Intensivist | null => {
    let selectedIntensivist: Intensivist | null = null;
    const cosignDetails = notes.cosign;

    if (
      cosignDetails &&
      cosignDetails.requestedTo &&
      cosignDetails.requestedTo.uniqueID
    ) {
      selectedIntensivist = {
        author: cosignDetails.requestedTo.author || null,
        name: cosignDetails.requestedTo.uniqueID.name || null,
        email: cosignDetails.requestedTo.uniqueID.email || null,
      };

      if (cosignDetails.status) {
        selectedIntensivist.status = cosignDetails.status;
      }

      if (cosignDetails.physicianMessage) {
        selectedIntensivist.physicianMessage = cosignDetails.physicianMessage;
      }
    }

    return selectedIntensivist;
  }
);

export const checkCosignMessage = createSelector(
  selectAllNoteContentData,
  (notes): string => {
    const cosignDetails = notes.cosign;
    if (cosignDetails && cosignDetails.physicianMessage) {
      return cosignDetails.physicianMessage;
    }
  }
);
