import { Action, createReducer, on } from "@ngrx/store";
import * as FromTypesActions from "../actions/note-dipatch.actions";

export const initialState = {
  loaded: false,
  loading: false,
};

const _notedispatchReducer = createReducer(
  initialState,
  on(FromTypesActions.submitDraftNote, (state) => ({
    ...state,
    loading: true,
  })),
  on(FromTypesActions.submitFinalNote, (state) => ({
    ...state,
    loading: true,
  })),
  on(FromTypesActions.deleteDraftNote, (state) => ({
    ...state,
    loading: true,
  })),
  on(FromTypesActions.attestFinalNote, (state) => ({
    ...state,
    loading: true,
  })),
  on(FromTypesActions.noteSubmissionSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),
  on(FromTypesActions.noteSubmissionFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  }))
);

export function reducer(
  state: {
    loaded: boolean;
    loading: boolean;
  },
  action: Action
) {
  return _notedispatchReducer(state, action);
}
