import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "cp-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchBoxComponent,
      multi: true,
    },
  ],
})
export class SearchBoxComponent implements OnInit, ControlValueAccessor {
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();
  @Output() search: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("searchInput") private searchInput: ElementRef;

  @Input() placeholder = "Search";

  @Input() width: number = 16;

  onChange: (value: string) => void;
  onTouched: () => void;

  public disabled: boolean = false;

  public showSearch: boolean = false;

  public searchText = "";

  writeValue(searchText: string): void {
    this.searchText = searchText;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  onSearch() {
    this.search.emit();
  }

  setInput() {
    this.onChange(this.searchText);
  }

  focusInput() {
    setTimeout(() => this.searchInput?.nativeElement?.focus());
  }

  resetSearch() {
    this.searchText = "";
    this.onChange("");
    this.reset.emit();
  }
}
