import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SbarAlertComponent } from "./sbar-alert.component";

@NgModule({
  declarations: [SbarAlertComponent],
  imports: [CommonModule],
  exports: [SbarAlertComponent],
})
export class SBarAlertModule {}
