import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { UtilService } from "../../../../services/util.service";
import { MedService } from "../../../../services/order/med.service";

@Component({
  selector: "app-order-frequency",
  templateUrl: "./frequency.component.html",
  styleUrls: ["./frequency.component.scss"],
})
export class FrequencyComponent implements OnInit, OnChanges {
  @Input() config;
  @Input() unit?: string;
  @Input() formType;
  @Input() selectedurgency;
  @Input() validateFtype = false;
  @Output() frequencyChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() daysChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() doseChange: EventEmitter<number> = new EventEmitter<number>();

  public period: string[] = ["before", "after"];

  public frequencyLabel = "Frequency";
  public scheduleSelected;
  public prevSelectedFrequency;
  public selectedUrgency;

  constructor(
    private utilService: UtilService,
    public controlContainer: ControlContainer,
    private _medService: MedService
  ) {}

  ngOnInit(): void {
    this.setFreqHourSelected();
    this.selectedUrgency = this.form.get("urgency").value;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedUrgency = this.selectedurgency;
    if (changes?.unit?.currentValue) {
      this._unitChange(changes.unit.currentValue);
    }
  }

  get form() {
    return this.controlContainer.control;
  }

  get numberOfDoses(): AbstractControl {
    return this.form.get("numberOfDoses");
  }

  get startTime(): AbstractControl {
    return this.form.get("startTime");
  }

  get noOfDays(): AbstractControl {
    return this.form.get("noOfDays");
  }

  get frequencyType(): AbstractControl {
    return this.form.get("frequency.fType");
  }

  get frequencyHours(): AbstractControl {
    return this.form.get("frequency.hours");
  }

  get frequencyDays(): AbstractControl {
    return this.form.get("frequency.days");
  }

  get frequencyMins(): AbstractControl {
    return this.form.get("frequency.mins");
  }

  get everyFreqRadio(): AbstractControl {
    return this.form.get("everyFreRadio");
  }

  get sosReason(): AbstractControl {
    return this.form.get("sosReason");
  }

  get sos(): AbstractControl {
    return this.form.get("sos");
  }

  onEveryFreqChange(hours: string): void {
    this.frequencyType?.setValue("every");

    this.frequencyDays?.setValue(null);
    this.frequencyMins?.setValue(null);

    this.frequencyHours?.setValue(hours);
    this.setFreqHourSelected();
    (this.form.get("skipSchedule") as UntypedFormArray).clear();
  }

  fDaysUpdated(): void {
    this.checkDosesAndDays();
  }

  fHoursUpdated(hours: string): void {
    if (this.frequencyMins.value == 0 || this.frequencyMins.value == null) {
      this.everyFreqRadio.setValue(hours);
    }

    this.setFreqHourSelected();
    this.checkDosesAndDays();
  }

  fMinsUpdated(mins): void {
    if (mins == 0 || mins == null) {
      this.everyFreqRadio.setValue(this.frequencyHours.value + "");
      this.setFreqHourSelected();
    } else {
      this.setFreqHourSelected();
      this.everyFreqRadio.setValue(null);
    }

    this.checkDosesAndDays();
  }

  onFrequencyChange(val: string): void {
    if (val !== "every") {
      this.everyFreqRadio?.setValue(null);
      this.frequencyDays?.setValue(null);
      this.frequencyHours?.setValue(null);
      this.frequencyMins?.setValue(null);
      this.numberOfDoses?.setValue(null);
      this.setFreqHourSelected();
    }

    if (val === "every") {
      this.everyFreqRadio.setValue(null);
      this.checkDosesAndDays();
    }

    if (val === "once") {
      this.noOfDays?.setValue(null);
    }

    this.frequencyChange.emit(val);
  }

  /**
   * Executes the function when sos changes.
   * If SOS is set then: frequency is required
   * else frequency is not required and sos reason is set to null.
   */
  onSosChange(): void {
    this.sos.patchValue(!this.sos.value);

    if (!this.sos.value) {
      this.sosReason.patchValue(null);
      this.frequencyType.clearValidators();
      this.frequencyType.updateValueAndValidity();
      this.frequencyLabel = "Frequency";
    } else {
      this.frequencyType.setValidators([Validators.required]);
      this.frequencyType.updateValueAndValidity();
      this.frequencyLabel = "Frequency *";
    }
  }

  /**
   * Sets frequency type and resets frequency to once if unit is not continuous and frequency is continuous.
   *
   * @param unit
   * @private
   */
  private _unitChange(unit: string): void {
    const isCont = this.frequencyType?.value == "continuous";
    const isValidContUnt =
      this._medService.validUnitForContinuousFrequency(unit);
    const isSelectContinuous = !isCont && isValidContUnt;
    const isSelectOnce = isCont && !isValidContUnt;
    if (isSelectContinuous) {
      this._resetFrequencyTo("continuous");
    } else if (isSelectOnce) {
      this._resetFrequencyTo("once");
      this.everyFreqRadio?.setValue("once");
    }
  }

  /**
   * Resets the frequency to once.
   *
   * @private
   */
  private _resetFrequencyTo(type): void {
    this.everyFreqRadio.setValue(null);
    this.frequencyType.setValue(type);
    this.frequencyDays.setValue(null);
    this.frequencyHours.setValue(null);
    this.frequencyMins.setValue(null);
    this._resetDoses();
  }

  /**
   * Resets the doses to null.
   *
   * @private
   */
  private _resetDoses() {
    this.form.dirty && this.numberOfDoses.setValue(null);
  }

  /**
   * Checks if doses and days should be disabled or not
   *
   * @private
   */

  private checkDosesAndDays(): void {
    if (
      !this.frequencyHours?.value &&
      !this.frequencyMins?.value &&
      !this.frequencyDays.value
    ) {
      this.disableDosesAndDays();
    } else this.enableDosesAndDays();
  }

  private enableDosesAndDays(): any {
    if (!this.noOfDays || !this.numberOfDoses) return;
    this.noOfDays?.enable();
    this.numberOfDoses?.enable();
  }

  private disableDosesAndDays(): any {
    if (!this.noOfDays || !this.numberOfDoses) return;
    this.noOfDays?.reset();
    this.numberOfDoses?.reset();
    // this.noOfDays?.disable();
    // this.numberOfDoses?.disable();
  }

  /*
   * NAME: onDaysChange
   * PURPOSE: emits new days through daysChange emitter
   * DESCRIPTION:
   * PARAMS: days:number
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDaysChange(days: number): void {
    this.daysChange.emit(+days);
  }

  /*
   * NAME: onDoseChange
   * PURPOSE: emits new dose through daysChdoseChangeange emitter
   * DESCRIPTION:
   * PARAMS: dose:number
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDoseChange(dose: number): void {
    this.doseChange.emit(+dose);
  }

  onScheduleChange(schedule) {
    this.scheduleSelected = schedule;
  }

  onUrgencyChange(value) {
    this.selectedUrgency = value;
  }

  setFreqHourSelected() {
    if (this.frequencyMins.value == 0 || this.frequencyMins.value == null)
      return;

    this.everyFreqRadio.setValue(null);
  }

  get freqHourSelected(): string {
    const hours = this.everyFreqRadio.value;
    switch (hours?.toString()) {
      case "24":
        return "od";

      case "12":
        return "bid";

      case "8":
        return "tid";

      case "6":
        return "qid";

      default:
        return null;
    }
  }
}
