export enum PatientTypes {
  ADULT = "adult",
  PEDIATRIC = "pediatric",
  NEONATAL = "neonatal",
}

export const patientTypeLabels = [
  PatientTypes.ADULT,
  PatientTypes.PEDIATRIC,
  PatientTypes.NEONATAL,
];
