import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { InfectionBundle } from "../../models";
import { Store, select } from "@ngrx/store";
import * as fromInfectionReducer from "../../store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { QUESTION_TYPE } from "../../constants";

@Component({
  selector: "app-inf-table",
  templateUrl: "./inf-table.component.html",
  styleUrls: ["./inf-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfTableComponent implements OnInit, OnDestroy {
  @ViewChild("infectionRecords") infectionTable: ElementRef;
  @ViewChild("commentInputViewBlock") commentInputViewBlock: ElementRef;
  private unsubscribe$ = new Subject();

  @Input() defaultQuestions: string[] = [];
  @Input() infectionBundle: InfectionBundle;
  @Input() isEditTable: boolean = false;
  @Input() editCheckListStore: any = null;

  @Output() enableEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitComment: EventEmitter<any> = new EventEmitter<any>();

  public infectionLoading$ = this._infStore.pipe(
    select(fromInfectionReducer.getLoading),
    takeUntil(this.unsubscribe$)
  );

  constructor(
    private _infStore: Store<fromInfectionReducer.InfectionBundleReducerState>
  ) {}

  questionTypes = QUESTION_TYPE;

  tooltipStore: any;
  commentColumnDataStore: any;
  commentFormControl = new FormControl("", Validators.required);

  // ngOnChanges(change) {
  //   console.log(change);
  // }

  get isEditInfectionBundleEmpty() {
    return (
      !this.editCheckListStore ||
      !this.editCheckListStore?.questions?.value?.some(
        (questObj) => questObj?.value
      )
    );
  }

  @HostListener("document:click", ["$event"])
  onClick(event) {
    // if we click outside of comment icon or comment block, we reset
    if (
      !(
        this.commentInputViewBlock?.nativeElement?.contains(event.target) ||
        this.infectionTable?.nativeElement
          ?.querySelector(".mat-icon-comment")
          ?.contains(event.target)
      )
    ) {
      this.isCommentEdit = false;
    }
  }

  ngOnInit(): void {}

  editCheckList() {
    this.enableEdit.emit();
  }

  submitCheckList() {
    this.submitTable.emit();
  }

  isCommentEdit: boolean = false;
  triggerCommentEdit(event) {
    event.stopPropagation();
    this.isCommentEdit = true;
    this.commentFormControl.setValue(this.commentColumnDataStore?.comment);
  }

  saveInputComment() {
    if (!this.commentFormControl?.dirty || !this.commentFormControl?.valid)
      return;

    const checklist: Omit<
      InfectionBundle,
      "createdBy" | "updatedBy" | "dayNum"
    > = {
      _id: this.commentColumnDataStore._id,
      timestamp: this.commentColumnDataStore.timestamp,
      comment: this.commentFormControl?.value,
      type: this.commentColumnDataStore.type,
      questions: this.commentColumnDataStore.questions,
    };

    this.submitComment.emit(checklist);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
